import React, { ChangeEvent, FC, useEffect, useState, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, DialogWrapper } from 'app/shared/styles/createForm';
import { StyledTextField } from 'app/mui/TextField';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import {
  maxLengthHelperText,
  maxNameLength,
} from 'app/shared/components/CreateDatasetDialog/CreateDatasetDialog';
import { CustomSubsetSelect } from 'app/shared/components/CustomSubsetSelect/CustomSubsetSelect';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { StyledToggleButton, StyledToggleButtonGroup } from './CreateGroupDialog.styles';
import { GroupOptions } from './GroupOptions';
import { FeatureItemType } from '../Analysis.types';

export type Rows = {
  feature_name: string;
  id: number | string;
};

type Props = {
  openDialog: boolean;
  handleClose: () => void;
  groupCreationType?: string;
};

export const CreateGroupDialog: FC<Props> = ({ openDialog, handleClose, groupCreationType }) => {
  const { analysisId } = useParams();
  const {
    createGroup,
    currentAnalysis,
    selectedDataRows,
    selectedDataFilters,
    selectedFeatureFilters,
    selectedFeatures,
    getFeaturesList,
    features,
    dataPointsCount,
  } = useAnalysis();

  const [groupNameFeatures, setGroupNameFeatures] = useState('');
  const [groupNameDataPoints, setGroupNameDataPoints] = useState('');
  const [groupType, setGroupType] = useState(groupCreationType || GroupCreationEnum.FEATURE_GROUP);
  const [creationTypeFeatures, setCreationTypeFeatures] = useState<string | undefined>(undefined);
  const [creationTypeDataPoints, setCreationTypeDataPoints] = useState<string | undefined>(
    undefined
  );
  const [featureRows, setFeatureRows] = useState<number[]>([]);
  const [defaultFeatureRows, setDefaultFeatureRows] = useState<any[] | undefined>(undefined);
  const [dataRows, setDataRows] = useState<number[]>([]);
  const [defaultDataRows, setDefaultDataRows] = useState<any[] | undefined>(undefined);
  const [dataPoints, setDataPoints] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    getFeaturesList(Number(analysisId));
  }, [analysisId]);

  useEffect(() => {
    if (groupCreationType) {
      setGroupType(groupCreationType);
    }
  }, [groupCreationType]);

  useEffect(() => {
    if (dataPointsCount) {
      const allDataPoints = Array.from(Array(dataPointsCount).keys()).map((dataPoint) => ({
        feature_name: dataPoint.toString(),
        id: dataPoint,
      }));
      setDataPoints(allDataPoints);
    }
  }, [dataPointsCount]);

  const handleChangeGroupType = (event: React.MouseEvent<HTMLElement>, newType: string): void => {
    setGroupType(newType);
  };

  const isDataGroupType = groupType === GroupCreationEnum.DATA_GROUP;

  const isFeatureGroupType = groupType === GroupCreationEnum.FEATURE_GROUP;

  const handleChangeCreationTypeFeatures = (event: ChangeEvent<HTMLInputElement>): void => {
    setCreationTypeFeatures((event.target as HTMLInputElement).value);
  };

  const handleChangeCreationTypeDataPoints = (event: ChangeEvent<HTMLInputElement>): void => {
    setCreationTypeDataPoints((event.target as HTMLInputElement).value);
  };

  const handleChangeNameDataPoints = (event: ChangeEvent<HTMLInputElement>): void => {
    setGroupNameDataPoints(event.target.value);
  };

  const handleChangeNameFeatures = (event: ChangeEvent<HTMLInputElement>): void => {
    setGroupNameFeatures(event.target.value);
  };

  const onRowsChange = (value: Rows[] | []): void => {
    const selectedRows = value.map((row: any) => row.id);
    if (isDataGroupType) {
      setDataRows(selectedRows);
      setDefaultDataRows(value);
    } else {
      setFeatureRows(selectedRows);
      setDefaultFeatureRows(value);
    }
  };

  const getGroupType = (): string => {
    const isCreationTypeByFeatureRows =
      creationTypeFeatures === GroupCreationEnum.CURRENTLY_SELECTED ||
      creationTypeFeatures === GroupCreationEnum.CUSTOM;

    const isCreationTypeByDataRows =
      creationTypeDataPoints === GroupCreationEnum.CURRENTLY_SELECTED ||
      creationTypeDataPoints === GroupCreationEnum.CUSTOM;

    if (isFeatureGroupType && isCreationTypeByFeatureRows) {
      return GroupCreationEnum.FEATURE_ROWS;
    }
    if (isFeatureGroupType && creationTypeFeatures === GroupCreationEnum.FILTER_RESULTS) {
      return GroupCreationEnum.FILTERED_FEATURE_ROWS;
    }
    if (isDataGroupType && isCreationTypeByDataRows) {
      return GroupCreationEnum.DATA_ROWS;
    }
    if (isDataGroupType && creationTypeDataPoints === GroupCreationEnum.FILTER_RESULTS) {
      return GroupCreationEnum.FILTERED_DATA_ROWS;
    }
    return '';
  };

  const getFormattedDataRows = useCallback(
    (selectedRows: number[]) =>
      selectedRows.map((selectedRow) => ({
        index: selectedRow,
        has_missing: currentAnalysis.dataset.has_missing,
      })),
    [currentAnalysis.dataset.has_missing]
  );

  const getFormattedFeatureRows = useCallback(
    (selectedRows: number[], allRows: FeatureItemType[]) => {
      const allRowsMap = allRows.reduce(
        (acc, row) => ({
          ...acc,
          [row.id]: row.has_missing,
        }),
        {}
      ) as Record<number, boolean>;

      return selectedRows.map((selectedRow: number) => ({
        index: selectedRow,
        has_missing: allRowsMap[selectedRow],
      }));
    },
    []
  );

  const getRows = (): { index: number; has_missing: boolean }[] | [] => {
    if (isDataGroupType) {
      if (creationTypeDataPoints === GroupCreationEnum.CUSTOM) {
        return getFormattedDataRows(dataRows);
      }

      return getFormattedDataRows(selectedDataRows);
    }

    if (isFeatureGroupType) {
      if (creationTypeFeatures === GroupCreationEnum.CUSTOM) {
        return getFormattedFeatureRows(featureRows, features);
      }

      return getFormattedFeatureRows(selectedFeatures, features);
    }

    return [];
  };

  const onClose = (): void => {
    setGroupNameFeatures('');
    setGroupNameDataPoints('');
    setCreationTypeFeatures(undefined);
    setCreationTypeDataPoints(undefined);
    setDataRows([]);
    setDefaultDataRows(undefined);
    setFeatureRows([]);
    setDefaultFeatureRows(undefined);
    handleClose();
  };

  const handleSave = (): void => {
    if (analysisId) {
      const groupName = isDataGroupType ? groupNameDataPoints : groupNameFeatures;
      const filters = isDataGroupType ? selectedDataFilters : selectedFeatureFilters;

      createGroup({
        analysisId: Number(analysisId),
        name: groupName,
        group_type: getGroupType(),
        rows: getRows(),
        filters,
      });
      onClose();
    }
  };

  const isCurrentlySelectedDisabled =
    (isFeatureGroupType && selectedFeatures.length === 0) ||
    (isDataGroupType && selectedDataRows.length === 0);

  const isGroupResultsDisabled =
    // @ts-ignore: Unreachable code error
    (isDataGroupType && selectedDataFilters.length === 0) ||
    // @ts-ignore: Unreachable code error
    (isFeatureGroupType && selectedFeatureFilters.length === 0);

  const isSaveFeatureGroupDisabled =
    isFeatureGroupType &&
    (!creationTypeFeatures ||
      !groupNameFeatures ||
      groupNameFeatures.length > maxNameLength ||
      (creationTypeFeatures !== GroupCreationEnum.FILTER_RESULTS && getRows().length === 0) ||
      (creationTypeFeatures === GroupCreationEnum.FILTER_RESULTS &&
        selectedFeatureFilters.length === 0));

  const isSaveDataGroupDisabled =
    isDataGroupType &&
    (!creationTypeDataPoints ||
      !groupNameDataPoints ||
      groupNameDataPoints.length > maxNameLength ||
      (creationTypeDataPoints !== GroupCreationEnum.FILTER_RESULTS && getRows().length === 0) ||
      (creationTypeDataPoints === GroupCreationEnum.FILTER_RESULTS &&
        selectedDataFilters.length === 0));

  const isSaveGroupDisabled = isSaveDataGroupDisabled || isSaveFeatureGroupDisabled;

  return (
    <DialogWrapper open={openDialog} onClose={onClose} TransitionComponent={Transition}>
      <Box width='841px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              Create New Group
            </Typography>
            <StyledCloseIcon onClick={onClose} size={24} />
          </Box>

          <StyledToggleButtonGroup
            value={groupType}
            exclusive
            onChange={handleChangeGroupType}
            aria-label='text alignment'
          >
            <StyledToggleButton
              value={GroupCreationEnum.FEATURE_GROUP}
              aria-label={GroupCreationEnum.FEATURE_GROUP}
            >
              {GroupCreationEnum.FEATURE_GROUP}
            </StyledToggleButton>
            <StyledToggleButton
              value={GroupCreationEnum.DATA_GROUP}
              aria-label={GroupCreationEnum.DATA_GROUP}
            >
              {GroupCreationEnum.DATA_GROUP}
            </StyledToggleButton>
          </StyledToggleButtonGroup>

          {isDataGroupType ? (
            <StyledTextField
              required
              error={groupNameDataPoints.length > maxNameLength}
              helperText={maxLengthHelperText('group', groupNameDataPoints.length)}
              color='secondary'
              placeholder='Group name'
              label='Group name'
              value={groupNameDataPoints}
              onChange={handleChangeNameDataPoints}
              fullWidth
              sx={{
                mt: 3,
                mb: 3,
              }}
            />
          ) : (
            <StyledTextField
              required
              error={groupNameFeatures.length > maxNameLength}
              helperText={maxLengthHelperText('group', groupNameFeatures.length)}
              color='secondary'
              placeholder='Group name'
              label='Group name'
              value={groupNameFeatures}
              onChange={handleChangeNameFeatures}
              fullWidth
              sx={{
                mt: 3,
                mb: 3,
              }}
            />
          )}
          <Typography variant='subtitle1'>Create from</Typography>
          {isFeatureGroupType && (
            <GroupOptions
              creationType={creationTypeFeatures}
              handleChangeCreationType={handleChangeCreationTypeFeatures}
              groupType={groupType}
              isCurrentlySelectedDisabled={isCurrentlySelectedDisabled}
              isGroupResultsDisabled={isGroupResultsDisabled}
            />
          )}
          {isDataGroupType && (
            <GroupOptions
              creationType={creationTypeDataPoints}
              handleChangeCreationType={handleChangeCreationTypeDataPoints}
              groupType={groupType}
              isCurrentlySelectedDisabled={isCurrentlySelectedDisabled}
              isGroupResultsDisabled={isGroupResultsDisabled}
            />
          )}

          {isDataGroupType &&
            creationTypeDataPoints &&
            creationTypeDataPoints === GroupCreationEnum.CUSTOM &&
            features &&
            dataPoints && (
              <Box mt={3}>
                <CustomSubsetSelect
                  rows={dataPoints}
                  onRowsChange={onRowsChange}
                  label='Data points'
                  defaultValues={defaultDataRows}
                  showSelect
                />
              </Box>
            )}

          {isFeatureGroupType &&
            creationTypeFeatures &&
            creationTypeFeatures === GroupCreationEnum.CUSTOM &&
            features &&
            dataPoints && (
              <Box mt={3}>
                <CustomSubsetSelect
                  rows={features}
                  onRowsChange={onRowsChange}
                  label='Features'
                  defaultValues={defaultFeatureRows}
                  showSelect
                />
              </Box>
            )}

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant='contained' disabled={isSaveGroupDisabled}>
              Create Group
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
};

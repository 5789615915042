import React, { FC, useEffect } from 'react';
import { Clock } from 'react-feather';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from 'app/mui/Tooltip';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { Tab, TabPanel, Tabs } from 'app/shared/components/Tabs/Tabs';
import { Explore } from './Explore/Explore';
import { SIDEBAR_TABS } from '../Analysis.types';
import { useAnalysis } from '../Analysis.hooks';
import { ConfigureVisualizations } from './Configure/ConfigureVisualizations/ConfigureVisualizations';

type AnalysisSidebarProps = {
  analysisId: number;
  tab: SIDEBAR_TABS;
  setTab: (tab: SIDEBAR_TABS) => void;
};

export const AnalysisSidebar: FC<AnalysisSidebarProps> = ({ analysisId, tab, setTab }) => {
  const theme = useTheme();
  const { currentAnalysisDataset, lastCreatedGroup, selectedGraph, selectedChart } = useAnalysis();
  const { navigateToDatasetPreview } = useNavigation();

  const handleTabChange = (event: React.SyntheticEvent, value: SIDEBAR_TABS): void => {
    setTab(value);
  };

  useEffect(() => {
    if (lastCreatedGroup) {
      setTab(SIDEBAR_TABS.EXPLORE);
    }
  }, [lastCreatedGroup]);

  useEffect(() => {
    if (tab === SIDEBAR_TABS.EXPLORE) {
      setTab(SIDEBAR_TABS.CONFIGURE);
    }
  }, [selectedGraph, selectedChart]);

  return (
    <>
      <Box display='flex' alignItems='center' px={4} py={3}>
        <Clock size={12} color={theme.palette.primary.light} />
        <Tooltip title={currentAnalysisDataset.name} placement='bottom-start'>
          <Typography
            noWrap
            className='break-text'
            ml={1}
            fontSize='1.2rem'
            color='primary.light'
            sx={{
              cursor: 'pointer',
              maxWidth: '200px',
            }}
            onClick={() =>
              navigateToDatasetPreview(currentAnalysisDataset.id, currentAnalysisDataset.is_example)
            }
          >
            {currentAnalysisDataset.name}
          </Typography>
        </Tooltip>
      </Box>
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label='Sidebar tabs'
          variant='scrollable'
          scrollButtons={false}
          sx={{
            display: 'flex',
            pl: 4,
          }}
        >
          <Tab label='Configure' sx={{ fontSize: '1.4rem' }} />
          <Tab label='Explore' sx={{ fontSize: '1.4rem' }} />
        </Tabs>
        <TabPanel flexGrow={1} value={tab} index={SIDEBAR_TABS.CONFIGURE}>
          <ConfigureVisualizations analysisId={analysisId} />
        </TabPanel>
        <TabPanel value={tab} index={SIDEBAR_TABS.EXPLORE}>
          <Explore tab={tab} />
        </TabPanel>
      </Box>
    </>
  );
};

import styled from '@emotion/styled/macro';
import { Button } from 'app/mui/Button';
import Box from '@mui/material/Box';

export const StyledButton = styled(Button)<{ width: string }>(({ width, theme }) => ({
  height: '40px',
  width,
  minWidth: 'auto',
  whiteSpace: 'nowrap',
  borderRadius: '4px',
  '&.highlight': { background: theme.palette.blocksBackground.hover },
  '&.MuiButton-outlined': { borderColor: theme.palette.state.restingBorder },
  '&.MuiButton-outlined.highlight': { borderColor: theme.palette.primary.light },
}));

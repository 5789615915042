import React, { Fragment, FC, ReactElement, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import Box from '@mui/material/Box';

import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { VisualizationLoader } from 'app/screens/Analysis/Visualizations/VisualizationLoader';
import {
  ChartDataType,
  VisualisationsStatus,
  ColoringConfig,
  SelectedFeatureType,
} from 'app/screens/Analysis/Analysis.types';
import { ColorLegendType } from 'app/screens/Analysis/Coloring/Coloring.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { Histogram } from 'app/screens/Analysis/Visualizations/Charts/Histogram';
import { ScatterPlot } from 'app/screens/Analysis/Visualizations/Charts/ScatterPlot';
import { ViolinPlot } from 'app/screens/Analysis/Visualizations/Charts/ViolinPlot';
import { Heatmap } from 'app/screens/Analysis/Visualizations/Charts/Heatmap';
import { FailedChart } from 'app/screens/Analysis/Visualizations/Charts/FailedChart';

import { DEFAULT_CHART_HEIGHT } from './Chart.config';

import './Chart.scss';

type ChartProps = {
  id: number;
  analysisId: number;
  type: VisualisationType;
  panelId: string;
  coloring?: ColoringConfig;
  isLargePan?: boolean;
  shouldReload?: boolean;
  shouldLayoutUpdate?: boolean;
  setShouldLayoutUpdate?: (shouldLayoutUpdate: boolean) => void;
  isGenerateSvg: boolean;
  onSvgGenerate: (chart: ChartDataType) => Promise<void>;
  setColoringTypeRef: (coloringType: SelectedFeatureType | null) => void;
  setLegendRef: (legend: ColorLegendType | null) => void;
};

export const Chart: FC<ChartProps> = ({
  type,
  id,
  panelId,
  analysisId,
  coloring,
  isLargePan,
  shouldLayoutUpdate,
  setShouldLayoutUpdate,
  shouldReload,
  isGenerateSvg,
  onSvgGenerate,
  setColoringTypeRef,
  setLegendRef,
}) => {
  const [isMounted, setMounted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();
  const [data, setData] = useState<ChartDataType | undefined>(undefined);
  const [currentHeight, setCurrentHeight] = useState(DEFAULT_CHART_HEIGHT);

  const { getChart, reloadVisualization, deleteVisualization } = useAnalysis();

  useEffect(() => {
    if (shouldLayoutUpdate && setShouldLayoutUpdate) {
      setCurrentHeight(height - 10);
      setShouldLayoutUpdate(false);
    }
  }, [height, shouldLayoutUpdate, setShouldLayoutUpdate]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && setShouldLayoutUpdate) {
      setCurrentHeight(height - 10);
    }
  }, [isMounted, setShouldLayoutUpdate]);

  const getChartData = async (analysisEntityId: number, chartId: number): Promise<void> => {
    try {
      setLoading(true);

      const [response] = (await getChart(analysisEntityId, [chartId].join(','))) as ChartDataType[];

      setData(response);

      if (setShouldLayoutUpdate) {
        setShouldLayoutUpdate(true);
      }
    } catch {
      throw Error(`Can't retrieve chart with id(${chartId})`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && analysisId && shouldReload) {
      getChartData(analysisId, id);
      reloadVisualization(id, false);
    }
  }, [id, analysisId, shouldReload]);

  useEffect(() => {
    if (id && analysisId) {
      getChartData(analysisId, id);
    }
  }, [id, analysisId]);

  const getChartElement = (
    chart: ChartDataType,
    chartType: VisualisationType
  ): ReactElement | null => {
    switch (chartType) {
      case VisualisationType.HISTOGRAM:
        return (
          <Histogram
            chart={chart}
            width={width}
            height={currentHeight}
            isGenerateSvg={isGenerateSvg}
            onSvgGenerate={onSvgGenerate}
          />
        );

      case VisualisationType.SCATTER_PLOT:
        return (
          <ScatterPlot
            panelId={panelId}
            analysisId={analysisId}
            id={id}
            type={type}
            chart={chart}
            width={width}
            height={currentHeight}
            coloring={coloring}
            isLargePan={isLargePan}
            isGenerateSvg={isGenerateSvg}
            onSvgGenerate={onSvgGenerate}
            setColoringTypeRef={setColoringTypeRef}
            setLegendRef={setLegendRef}
          />
        );

      case VisualisationType.VIOLIN_PLOT:
        return (
          <ViolinPlot
            chart={chart}
            width={width}
            height={currentHeight}
            isGenerateSvg={isGenerateSvg}
            onSvgGenerate={onSvgGenerate}
          />
        );

      case VisualisationType.HEATMAP:
        return (
          <Heatmap
            chart={chart}
            width={width}
            height={currentHeight}
            isGenerateSvg={isGenerateSvg}
            onSvgGenerate={onSvgGenerate}
          />
        );

      default:
        return null;
    }
  };

  const onDeleteChart = (): void => {
    if (analysisId && id) {
      deleteVisualization(analysisId, id);
    }
  };

  const showLoader = isLoading || !id || !data;
  const showFailed = data?.status === VisualisationsStatus.FAILED;
  const showChart = data && !showFailed && data.status === VisualisationsStatus.COMPLETED;

  return (
    <Box position='absolute' height='100%' width='100%' ref={ref}>
      {showFailed && data.error && (
        <FailedChart onDeleteChart={onDeleteChart} errorMessage={data.error} />
      )}
      {showLoader && !showFailed && <VisualizationLoader />}
      {showChart && <Fragment key={data?.id}>{getChartElement(data, type)}</Fragment>}
    </Box>
  );
};

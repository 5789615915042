import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Trash2 } from 'react-feather';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import { CircularProgress } from 'app/mui/CircularProgress';
import datasetImage from 'app/assets/images/datasets/dataset-image.svg';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { Popup } from 'app/shared/components/Popup/Popup';
import { formatBytes } from 'app/shared/utils/formatBytes';
import { CreateDatasetDialog } from 'app/shared/components/CreateDatasetDialog/CreateDatasetDialog';
import { Order } from 'app/shared/components/SortSelect/SortSelect';
import { ListRow } from './DataLibraryList.styles';
import { useDataLibrary } from '../DataLibrary.hooks';
import { EditDataLibraryDialog } from '../EditDataLibraryDialog/EditDataLibraryDialog';
import { Dataset, EditDataset } from '../DataLibrary.types';
import { PublishedDataset } from '../PublishedDataset/PublishedDataset';
import { FailedDataset } from '../FailedDataset/FailedDataset';

export function descendingComparator<T extends Record<string, any>>(
  a: T,
  b: T,
  orderBy: keyof T
): number {
  const left = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const right = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

  if (right < left) {
    return -1;
  }
  if (right > left) {
    return 1;
  }
  return 0;
}

export function getComparator(order: Order, orderBy: string): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export type SelectedDataset =
  | {
      id?: number;
      name?: string;
      isSample: boolean;
      previewId?: number;
      description?: string;
    }
  | undefined;

type DataLibraryListProps = {
  order: Order;
  orderBy: string;
  setSelectedDataset: Dispatch<SetStateAction<any>>;
  handlePreviewOpen: (id: number) => void;
  handlePreviewClose: () => void;
  selectedDataset: SelectedDataset;
};

export const DataLibraryList: FC<DataLibraryListProps> = ({
  order,
  orderBy,
  setSelectedDataset,
  handlePreviewOpen,
  handlePreviewClose,
  selectedDataset,
}) => {
  const { datasets, showPublishedDataset, showFailedDataset, deleteDataset, editDataset } =
    useDataLibrary();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isStartAnalysisModalOpen, setIsStartAnalysisModalOpen] = useState(false);

  const closeEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const handleSave = (values: EditDataset): void => {
    editDataset({
      id: values.id,
      name: values.name,
      description: values.description,
      onSuccess: closeEditDialog,
    });
    handlePreviewClose();
  };

  const onDeleteDataset = useCallback((id: number) => {
    deleteDataset(id);
    setIsDeleteDialogOpen(false);
    handlePreviewClose();
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const openDeleteDialog = useCallback((id: number, name: string) => {
    setSelectedDataset((dataset: Dataset) => ({
      ...dataset,
      id,
      name,
    }));
    setIsDeleteDialogOpen(true);
  }, []);

  const closeAnalysisModal = useCallback(() => {
    setIsStartAnalysisModalOpen(false);
  }, []);

  const handleOpenAnalysisModal = useCallback(() => {
    setIsStartAnalysisModalOpen(true);
  }, []);

  const getDeleteDatasetDescription = useCallback(
    (selectedDatasetId: number) => {
      const datasetToDelete = datasets.find((dataset) => dataset.id === selectedDatasetId);

      if (datasetToDelete) {
        const projectCount = datasetToDelete.projects?.length ?? 0;
        const projectNames = datasetToDelete.projects?.map((project) => project.name) ?? [];
        const projectList = projectNames.join(', ');

        if (projectCount === 0) {
          return 'Delete dataset from Data Library, this dataset is not used in any projects. Are you sure you want to delete?';
        }

        return `Delete dataset from Data Library with all related analysis, used in ${
          projectCount === 1 ? 'project' : 'projects'
        }: ${projectList}. Are you sure you want to delete?`;
      }

      return 'All analyses based on this dataset will also be deleted. Are you sure you want to proceed?';
    },
    [datasets]
  );

  return (
    <Box>
      {datasets
        .slice()
        .sort(getComparator(order, orderBy))
        .map((row, index) => {
          const labelId = `dataset-${index}`;

          if (showPublishedDataset(row.status)) {
            return (
              <PublishedDataset
                key={row.id}
                setSelectedDataset={setSelectedDataset}
                setIsEditDialogOpen={setIsEditDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                row={row}
                selectedDataset={selectedDataset}
                handlePreviewOpen={handlePreviewOpen}
                labelId={labelId}
                handleOpenAnalysisModal={handleOpenAnalysisModal}
              />
            );
          }

          if (showFailedDataset(row.status)) {
            return (
              <FailedDataset
                key={row.id}
                openDeleteDialog={openDeleteDialog}
                selectedDataset={selectedDataset}
                handlePreviewOpen={handlePreviewOpen}
                row={row}
                labelId={labelId}
              />
            );
          }

          return (
            <ListRow key={row.id} className='publishing'>
              <Box display='flex' alignItems='center' minWidth={0}>
                <Box borderRadius='4px' component='img' src={datasetImage} alt={row.name} />
                <Box display='flex' ml={2} flexDirection='column' rowGap='12px' minWidth={0}>
                  <Tooltip title={row.name} placement='bottom-start'>
                    <Typography
                      noWrap
                      component='h5'
                      variant='h5'
                      id={labelId}
                      className='break-text'
                    >
                      {row.name}
                    </Typography>
                  </Tooltip>
                  <Typography component='div' variant='body1' color='text.secondary' display='flex'>
                    {formatBytes(row.dataset_metadata?.size)}
                    <Typography mx={2}>&bull;</Typography>
                    Updated {lastUpdated(row.updated_at)}
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' alignItems='center'>
                <Typography mr={2} variant='caption'>
                  Publishing...
                </Typography>
                <Box p={1}>
                  <CircularProgress />
                </Box>
                <Box
                  p={1}
                  onClick={() => openDeleteDialog(row.id, row.name)}
                  style={{ cursor: 'pointer' }}
                >
                  <Trash2 size={22} />
                </Box>
              </Box>
            </ListRow>
          );
        })}
      {selectedDataset && selectedDataset.id && selectedDataset.name && (
        <>
          <EditDataLibraryDialog
            open={isEditDialogOpen}
            id={selectedDataset.id}
            name={selectedDataset.name}
            description={selectedDataset.description}
            handleClose={closeEditDialog}
            onSubmit={handleSave}
          />
          <Popup
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            onConfirm={() => selectedDataset.id && onDeleteDataset(selectedDataset.id)}
            state='Delete'
            title={`Delete ${selectedDataset.name}?`}
            description={getDeleteDatasetDescription(selectedDataset.id)}
          />
          {isStartAnalysisModalOpen && (
            <CreateDatasetDialog
              openDialog={isStartAnalysisModalOpen}
              handleClose={closeAnalysisModal}
              selectedDataset={{ id: selectedDataset.id, name: selectedDataset.name }}
            />
          )}
        </>
      )}
    </Box>
  );
};

import React, { FC } from 'react';
import { Check } from 'react-feather';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from 'app/mui/CircularProgress';
import { UploadingDatasetStepType } from 'app/screens/DataLibrary/DataLibrary.types';
import { Step } from '../ProgressBar.styles';

type UploadingStepProps = {
  activeStatus: UploadingDatasetStepType;
  currentStatus: UploadingDatasetStepType;
};

export const UploadingStep: FC<UploadingStepProps> = ({ currentStatus, activeStatus }) => {
  const theme = useTheme();

  return (
    <Box display='flex' alignItems='center' whiteSpace='nowrap'>
      {activeStatus.step < currentStatus.step && (
        <Step>
          <Typography variant='caption'>{currentStatus.step}</Typography>
        </Step>
      )}
      {activeStatus.step === currentStatus.step && <CircularProgress />}
      {activeStatus.step > currentStatus.step && (
        <Check
          size='2rem'
          style={{
            borderRadius: '50%',
            background: '#1CB359',
            color: '#fff',
            padding: '2px',
          }}
        />
      )}
      <Typography
        variant='body1'
        ml={1}
        color={currentStatus.step >= activeStatus.step ? 'primary' : 'text.secondary'}
      >
        {currentStatus.status}
      </Typography>
    </Box>
  );
};

import { styled } from '@mui/material/styles';
import { SIDEBAR_WIDTH, SIDEBAR_MOBILE_WIDTH } from 'app/shared/styles/constants';
import Box from '@mui/material/Box';

export const SidebarPanel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  top: 60,
  zIndex: 2,
  width: `${SIDEBAR_WIDTH}px`,
  paddingTop: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.down('laptop')]: {
    width: `${SIDEBAR_MOBILE_WIDTH}px`,
    padding: theme.spacing(3, 0, 3),
    '& .desktop': {
      display: 'none',
    },
    '& .projects::before': {
      left: 0,
      width: '100%!important',
    },
    '& .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary': {
      paddingLeft: '26px',
      minWidth: '100%',
    },
  },
}));

export const SidebarNav = styled('ul')(({ theme }) => ({
  width: '100%',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  '& li': {
    position: 'relative',
    display: 'flex',
    marginBottom: '2px',
    '& a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: theme.spacing(1, 4),
    },
    '&.is-active': {
      background: '#BDDDE8',
    },
    '&:hover': {
      background: 'rgba(189, 221, 232, 0.4)',
    },
    '&.non-clickable': {
      pointerEvents: 'none',
    },
    '&.projects': {
      marginTop: theme.spacing(2),
      '&::before': {
        content: '""',
        background: theme.palette.action.disabledBackground,
        position: 'absolute',
        display: 'block',
        width: '75%',
        left: '32px',
        height: '1px',
        marginTop: theme.spacing(-2),
      },
    },
  },
  '& .MuiButton-root.MuiButton-outlined': {
    border: 'none',
    justifyContent: 'left',
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
    },
  },
}));

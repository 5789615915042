import React, { useEffect, useState, ChangeEvent, FC, ReactElement } from 'react';
import { ChevronUp, ChevronDown, ChevronLeft, ChevronRight, Check, Info } from 'react-feather';
import Box from '@mui/material/Box';
import { Typography, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import { IconButton } from 'app/mui/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItemContent } from 'app/shared/components/SortSelect/SortSelect.styles';
import {
  handleNumericDecrementChange,
  handleNumericValueChange,
} from 'app/screens/Analysis/Analysis.utils';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import {
  advancedConfigurationTooptip,
  automaticParameterizationTooltip,
  getSafeString,
} from 'app/screens/Analysis/AnalysisSidebar/Configure/ConfigureForms/ConfigureLandscape';
import { StyledTextField } from 'app/mui/TextField';
import { FormControl } from 'app/mui/FormControl';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { NeighborGraphConfigType, NerveGraphConfig } from 'app/screens/Analysis/Analysis.types';
import { Tooltip } from 'app/mui/Tooltip';
import { Checkbox } from 'app/mui/Checkbox';
import { DataLandscapeMetric, FeatureLandscapeMetric } from './Configurations.constants';

import 'swiper/swiper.min.css';

export type DistanceItemType = {
  name: string;
  displayName: string;
  description: string | ReactElement;
};

export type ConfigurationsType = {
  neighbor_graph_config: NeighborGraphConfigType;
  nerve_graph_config: NerveGraphConfig;
};

type ConfigurationsProps = {
  type: string;
  onConfigurationsChange: (value: ConfigurationsType) => void;
};

export const Configurations: FC<ConfigurationsProps> = ({ type, onConfigurationsChange }) => {
  const theme = useTheme();
  const { defaultDataLandscapeConfig, defaultFeatureLandscapeConfig } = useAnalysis();

  const [expanded, setExpanded] = useState(false);

  const config =
    type === VisualisationType.DATA_LANDSCAPE
      ? defaultDataLandscapeConfig
      : defaultFeatureLandscapeConfig;

  const { distance_metric, K, M, min_neighbors, use_default } = config.neighbor_graph_config;
  const { L_components, L_cover } = config.nerve_graph_config;

  const [metricValue, setMetricValue] = useState<string>(distance_metric);
  const [coarseness, setCoarseness] = useState<string>(getSafeString(L_components));
  const [connectivity, setConnectivity] = useState<string>(getSafeString(L_cover));

  const [useDefault, setUseDefault] = useState(use_default);
  const [size, setSize] = useState<string>(getSafeString(K));
  const [slack, setSlack] = useState<string>(getSafeString(M));
  const [minNeighbors, setMinNeighbors] = useState<string>(getSafeString(min_neighbors));

  useEffect(() => {
    setMetricValue(distance_metric);
    setCoarseness(getSafeString(L_components));
    setConnectivity(getSafeString(L_cover));
    setUseDefault(use_default);
    setSize(getSafeString(K));
    setSlack(getSafeString(K !== null && M !== null ? M - K : null));
    setMinNeighbors(getSafeString(min_neighbors));
  }, [K, L_components, L_cover, M, type, distance_metric, min_neighbors, use_default]);

  useEffect(() => {
    onConfigurationsChange({
      neighbor_graph_config: {
        distance_metric: metricValue,
        use_default: useDefault,
        K: size !== '' ? Number(size) : null,
        M: slack !== '' ? Number(slack) : null,
        min_neighbors: minNeighbors !== '' ? Number(minNeighbors) : null,
      },
      nerve_graph_config: {
        L_components: Number(coarseness),
        L_cover: Number(connectivity),
      },
    });
  }, [size, slack, metricValue, coarseness, connectivity, minNeighbors, useDefault]);

  const handleChangeSize = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setSize);
  };

  const handleChangeSlack = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setSlack);
  };

  const handleChangeMinNeighbors = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setMinNeighbors);
  };

  const handleChangeCoarseness = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setCoarseness);
  };

  const handleChangeConnectivity = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setConnectivity);
  };

  const handleChangeMetric = (value: string): void => {
    setMetricValue(value);
  };

  const sizeDecrement = (): void => {
    handleNumericDecrementChange(size, 0, setSize);
  };

  const sizeIncrement = (): void => {
    setSize((Number(size) + 1).toString());
  };

  const slackDecrement = (): void => {
    handleNumericDecrementChange(slack, 0, setSlack);
  };

  const slackIncrement = (): void => {
    setSlack((Number(slack) + 1).toString());
  };

  const coarsenessDecrement = (): void => {
    handleNumericDecrementChange(coarseness, 0, setCoarseness);
  };

  const coarsenessIncrement = (): void => {
    setCoarseness((Number(coarseness) + 1).toString());
  };

  const connectivityDecrement = (): void => {
    handleNumericDecrementChange(connectivity, 0, setConnectivity);
  };

  const connectivityIncrement = (): void => {
    setConnectivity((Number(connectivity) + 1).toString());
  };

  const minNeighborsDecrement = (): void => {
    handleNumericDecrementChange(minNeighbors, 0, setMinNeighbors);
  };

  const minNeighborsIncrement = (): void => {
    setMinNeighbors((Number(minNeighbors) + 1).toString());
  };

  const metric =
    type === VisualisationType.DATA_LANDSCAPE ? DataLandscapeMetric : FeatureLandscapeMetric;

  return (
    <>
      <Box>
        <Box mt={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center' gap={2} mb={1}>
            <FormControl
              className='initial'
              sx={{
                width: '304px',
              }}
            >
              <InputLabel>Distance</InputLabel>
              <Select
                value={metricValue}
                onChange={(e) => handleChangeMetric(e.target.value)}
                label='Distance'
                sx={{
                  '& .MuiSelect-select svg': {
                    display: 'none',
                  },
                }}
              >
                {metric.map((distanceItem: DistanceItemType) => (
                  <MenuItem key={distanceItem.name} value={distanceItem.name} sx={{ py: 1 }}>
                    <MenuItemContent>
                      {distanceItem.displayName}
                      <Box display='flex'>
                        {metricValue === distanceItem.name && (
                          <Check size='24' color={theme.palette.success.main} />
                        )}
                        <Box sx={{ pl: 1 }}>
                          <Tooltip title={distanceItem.description}>
                            <Info />
                          </Tooltip>
                        </Box>
                      </Box>
                    </MenuItemContent>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <StyledTextField
              id='outlined-end-adornment'
              type='number'
              value={coarseness}
              label='Coarseness'
              onChange={handleChangeCoarseness}
              fullWidth
              sx={{
                width: '144px',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      className='configArrows'
                      disabled={Number(coarseness) <= 0}
                      onClick={coarsenessDecrement}
                    >
                      <ChevronLeft size={16} className='arrow' />
                    </IconButton>
                    <IconButton className='configArrows' onClick={coarsenessIncrement}>
                      <ChevronRight size={16} className='arrow' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <StyledTextField
              id='outlined-end-adornment'
              type='number'
              value={connectivity}
              label='Connectivity'
              onChange={handleChangeConnectivity}
              fullWidth
              sx={{
                width: '144px',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      className='configArrows'
                      disabled={Number(connectivity) <= 0}
                      onClick={connectivityDecrement}
                    >
                      <ChevronLeft size={16} className='arrow' />
                    </IconButton>
                    <IconButton className='configArrows' onClick={connectivityIncrement}>
                      <ChevronRight size={16} className='arrow' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Box
          onClick={() => setExpanded(!expanded)}
          display='flex'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
        >
          {expanded ? <ChevronUp /> : <ChevronDown />}
          <Typography variant='subtitle1' ml={1} mr={1}>
            Advanced Configuration
          </Typography>
          <Tooltip title={advancedConfigurationTooptip}>
            <Info size={20} />
          </Tooltip>
        </Box>
        {expanded && (
          <Box>
            <Tooltip title={automaticParameterizationTooltip}>
              <FormControlLabel
                label='Automatic parameterization'
                sx={{
                  marginBottom: 1,
                  marginTop: 1,
                }}
                control={
                  <Checkbox
                    value={useDefault}
                    checked={useDefault}
                    onChange={() => setUseDefault((prevState) => !prevState)}
                  />
                }
              />
            </Tooltip>

            <Box display='flex' justifyContent='space-between' alignItems='center' gap={2}>
              <StyledTextField
                type='number'
                className='initial'
                label='Neighborhood size'
                value={size}
                disabled={useDefault}
                onChange={handleChangeSize}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        className='configArrows'
                        disabled={Number(size) <= 0 || useDefault}
                        onClick={sizeDecrement}
                      >
                        <ChevronLeft size={16} className='arrow' />
                      </IconButton>
                      <IconButton
                        className='configArrows'
                        onClick={sizeIncrement}
                        disabled={useDefault}
                      >
                        <ChevronRight size={16} className='arrow' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                type='number'
                className='initial'
                value={slack}
                disabled={useDefault}
                label='Neighborhood slack'
                onChange={handleChangeSlack}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        className='configArrows'
                        disabled={Number(slack) <= 0 || useDefault}
                        onClick={slackDecrement}
                      >
                        <ChevronLeft size={16} className='arrow' />
                      </IconButton>
                      <IconButton
                        className='configArrows'
                        onClick={slackIncrement}
                        disabled={useDefault}
                      >
                        <ChevronRight size={16} className='arrow' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledTextField
                type='number'
                className='initial'
                value={minNeighbors}
                disabled={useDefault}
                label='Min. neighbors'
                onChange={handleChangeMinNeighbors}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        className='configArrows'
                        disabled={Number(minNeighbors) <= 0 || useDefault}
                        onClick={minNeighborsDecrement}
                      >
                        <ChevronLeft size={16} className='arrow' />
                      </IconButton>
                      <IconButton
                        className='configArrows'
                        onClick={minNeighborsIncrement}
                        disabled={useDefault}
                      >
                        <ChevronRight size={16} className='arrow' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

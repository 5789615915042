import React, { FC } from 'react';
import { Box } from '@mui/material';

import statisticsError from 'app/assets/images/analysis/statistics-error.svg';

type StatisticsErrorProps = {
  error: JSX.Element;
};

export const StatisticsError: FC<StatisticsErrorProps> = ({ error }) => (
  <Box
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignItems='center'
    textAlign='center'
  >
    <img src={statisticsError} alt='Statistics Error' />
    {error}
  </Box>
);

import { scientificColorMaps } from './scientificMaps';
import { colorcetMaps } from './colorcetMaps';

export type ColorPair = [number, string];
export type ColorScheme = ColorPair[];
export type ColorSchemeLibrary = Record<string, ColorScheme>;

export { scientificColorMaps, colorcetMaps };

export const scales: ColorSchemeLibrary = {
  Greys: [
    [0, 'rgb(0,0,0)'],
    [1, 'rgb(255,255,255)'],
  ],

  YlGnBu: [
    [0, 'rgb(8,29,88)'],
    [0.125, 'rgb(37,52,148)'],
    [0.25, 'rgb(34,94,168)'],
    [0.375, 'rgb(29,145,192)'],
    [0.5, 'rgb(65,182,196)'],
    [0.625, 'rgb(127,205,187)'],
    [0.75, 'rgb(199,233,180)'],
    [0.875, 'rgb(237,248,217)'],
    [1, 'rgb(255,255,217)'],
  ],

  Greens: [
    [0, 'rgb(0,68,27)'],
    [0.125, 'rgb(0,109,44)'],
    [0.25, 'rgb(35,139,69)'],
    [0.375, 'rgb(65,171,93)'],
    [0.5, 'rgb(116,196,118)'],
    [0.625, 'rgb(161,217,155)'],
    [0.75, 'rgb(199,233,192)'],
    [0.875, 'rgb(229,245,224)'],
    [1, 'rgb(247,252,245)'],
  ],

  YlOrRd: [
    [0, 'rgb(128,0,38)'],
    [0.125, 'rgb(189,0,38)'],
    [0.25, 'rgb(227,26,28)'],
    [0.375, 'rgb(252,78,42)'],
    [0.5, 'rgb(253,141,60)'],
    [0.625, 'rgb(254,178,76)'],
    [0.75, 'rgb(254,217,118)'],
    [0.875, 'rgb(255,237,160)'],
    [1, 'rgb(255,255,204)'],
  ],

  Bluered: [
    [0, 'rgb(0,0,255)'],
    [1, 'rgb(255,0,0)'],
  ],

  // modified RdBu based on
  // http://www.kennethmoreland.com/color-maps/
  RdBu: [
    [0, 'rgb(5,10,172)'],
    [0.35, 'rgb(106,137,247)'],
    [0.5, 'rgb(190,190,190)'],
    [0.6, 'rgb(220,170,132)'],
    [0.7, 'rgb(230,145,90)'],
    [1, 'rgb(178,10,28)'],
  ],

  // Scale for non-negative numeric values
  Reds: [
    [0, 'rgb(220,220,220)'],
    [0.2, 'rgb(245,195,157)'],
    [0.4, 'rgb(245,160,105)'],
    [1, 'rgb(178,10,28)'],
  ],

  // Scale for non-positive numeric values
  Blues: [
    [0, 'rgb(5,10,172)'],
    [0.35, 'rgb(40,60,190)'],
    [0.5, 'rgb(70,100,245)'],
    [0.6, 'rgb(90,120,245)'],
    [0.7, 'rgb(106,137,247)'],
    [1, 'rgb(220,220,220)'],
  ],

  Picnic: [
    [0, 'rgb(0,0,255)'],
    [0.1, 'rgb(51,153,255)'],
    [0.2, 'rgb(102,204,255)'],
    [0.3, 'rgb(153,204,255)'],
    [0.4, 'rgb(204,204,255)'],
    [0.5, 'rgb(255,255,255)'],
    [0.6, 'rgb(255,204,255)'],
    [0.7, 'rgb(255,153,255)'],
    [0.8, 'rgb(255,102,204)'],
    [0.9, 'rgb(255,102,102)'],
    [1, 'rgb(255,0,0)'],
  ],

  Rainbow: [
    [0, 'rgb(150,0,90)'],
    [0.125, 'rgb(0,0,200)'],
    [0.25, 'rgb(0,25,255)'],
    [0.375, 'rgb(0,152,255)'],
    [0.5, 'rgb(44,255,150)'],
    [0.625, 'rgb(151,255,0)'],
    [0.75, 'rgb(255,234,0)'],
    [0.875, 'rgb(255,111,0)'],
    [1, 'rgb(255,0,0)'],
  ],

  Portland: [
    [0, 'rgb(12,51,131)'],
    [0.25, 'rgb(10,136,186)'],
    [0.5, 'rgb(242,211,56)'],
    [0.75, 'rgb(242,143,56)'],
    [1, 'rgb(217,30,30)'],
  ],

  Jet: [
    [0, 'rgb(0,0,131)'],
    [0.125, 'rgb(0,60,170)'],
    [0.375, 'rgb(5,255,255)'],
    [0.625, 'rgb(255,255,0)'],
    [0.875, 'rgb(250,0,0)'],
    [1, 'rgb(128,0,0)'],
  ],

  Hot: [
    [0, 'rgb(0,0,0)'],
    [0.3, 'rgb(230,0,0)'],
    [0.6, 'rgb(255,210,0)'],
    [1, 'rgb(255,255,255)'],
  ],

  Blackbody: [
    [0, 'rgb(0,0,0)'],
    [0.2, 'rgb(230,0,0)'],
    [0.4, 'rgb(230,210,0)'],
    [0.7, 'rgb(255,255,255)'],
    [1, 'rgb(160,200,255)'],
  ],

  Earth: [
    [0, 'rgb(0,0,130)'],
    [0.1, 'rgb(0,180,180)'],
    [0.2, 'rgb(40,210,40)'],
    [0.4, 'rgb(230,230,50)'],
    [0.6, 'rgb(120,70,20)'],
    [1, 'rgb(255,255,255)'],
  ],

  Electric: [
    [0, 'rgb(0,0,0)'],
    [0.15, 'rgb(30,0,100)'],
    [0.4, 'rgb(120,0,100)'],
    [0.6, 'rgb(160,90,0)'],
    [0.8, 'rgb(230,200,0)'],
    [1, 'rgb(255,250,220)'],
  ],

  Viridis: [
    [0, '#440154'],
    [0.06274509803921569, '#48186a'],
    [0.12549019607843137, '#472d7b'],
    [0.18823529411764706, '#424086'],
    [0.25098039215686274, '#3b528b'],
    [0.3137254901960784, '#33638d'],
    [0.3764705882352941, '#2c728e'],
    [0.4392156862745098, '#26828e'],
    [0.5019607843137255, '#21918c'],
    [0.5647058823529412, '#1fa088'],
    [0.6274509803921569, '#28ae80'],
    [0.6901960784313725, '#3fbc73'],
    [0.7529411764705882, '#5ec962'],
    [0.8156862745098039, '#84d44b'],
    [0.8784313725490196, '#addc30'],
    [0.9411764705882353, '#d8e219'],
    [1, '#fde725'],
  ],

  Cividis: [
    [0.0, 'rgb(0,32,76)'],
    [0.058824, 'rgb(0,42,102)'],
    [0.117647, 'rgb(0,52,110)'],
    [0.176471, 'rgb(39,63,108)'],
    [0.235294, 'rgb(60,74,107)'],
    [0.294118, 'rgb(76,85,107)'],
    [0.352941, 'rgb(91,95,109)'],
    [0.411765, 'rgb(104,106,112)'],
    [0.470588, 'rgb(117,117,117)'],
    [0.529412, 'rgb(131,129,120)'],
    [0.588235, 'rgb(146,140,120)'],
    [0.647059, 'rgb(161,152,118)'],
    [0.705882, 'rgb(176,165,114)'],
    [0.764706, 'rgb(192,177,109)'],
    [0.823529, 'rgb(209,191,102)'],
    [0.882353, 'rgb(225,204,92)'],
    [0.941176, 'rgb(243,219,79)'],
    [1.0, 'rgb(255,233,69)'],
  ],
  CET_L20_truncated: [
    [0.0, '#373344'],
    [0.004484304932735426, '#373347'],
    [0.008968609865470852, '#383349'],
    [0.013452914798206279, '#39344c'],
    [0.017937219730941704, '#3a344e'],
    [0.02242152466367713, '#3a3451'],
    [0.026905829596412557, '#3b3553'],
    [0.03139013452914798, '#3b3556'],
    [0.03587443946188341, '#3c3558'],
    [0.04035874439461883, '#3c365b'],
    [0.04484304932735426, '#3d365d'],
    [0.04932735426008968, '#3d3760'],
    [0.053811659192825115, '#3e3762'],
    [0.05829596412556054, '#3e3765'],
    [0.06278026905829596, '#3f3867'],
    [0.06726457399103139, '#3f386a'],
    [0.07174887892376682, '#40396c'],
    [0.07623318385650224, '#40396e'],
    [0.08071748878923767, '#403971'],
    [0.08520179372197309, '#403a73'],
    [0.08968609865470852, '#413a76'],
    [0.09417040358744394, '#413b78'],
    [0.09865470852017937, '#413b7a'],
    [0.10313901345291479, '#413c7d'],
    [0.10762331838565023, '#423c7f'],
    [0.11210762331838565, '#423d81'],
    [0.11659192825112108, '#423d84'],
    [0.1210762331838565, '#423e86'],
    [0.12556053811659193, '#423e88'],
    [0.13004484304932734, '#423f8a'],
    [0.13452914798206278, '#423f8c'],
    [0.1390134529147982, '#42408f'],
    [0.14349775784753363, '#424091'],
    [0.14798206278026907, '#434193'],
    [0.15246636771300448, '#434195'],
    [0.15695067264573992, '#424297'],
    [0.16143497757847533, '#424299'],
    [0.16591928251121077, '#42439b'],
    [0.17040358744394618, '#42449d'],
    [0.17488789237668162, '#42449f'],
    [0.17937219730941703, '#4245a1'],
    [0.18385650224215247, '#4245a3'],
    [0.18834080717488788, '#4246a5'],
    [0.19282511210762332, '#4247a7'],
    [0.19730941704035873, '#4147a9'],
    [0.20179372197309417, '#4148ab'],
    [0.20627802690582958, '#4149ac'],
    [0.21076233183856502, '#4149ae'],
    [0.21524663677130046, '#414ab0'],
    [0.21973094170403587, '#404bb2'],
    [0.2242152466367713, '#404cb3'],
    [0.22869955156950672, '#404cb5'],
    [0.23318385650224216, '#3f4db6'],
    [0.23766816143497757, '#3f4eb8'],
    [0.242152466367713, '#3f4fb9'],
    [0.24663677130044842, '#3e50bb'],
    [0.25112107623318386, '#3e50bc'],
    [0.2556053811659193, '#3e51bd'],
    [0.2600896860986547, '#3d52be'],
    [0.2645739910313901, '#3d53c0'],
    [0.26905829596412556, '#3d54c1'],
    [0.273542600896861, '#3c55c2'],
    [0.2780269058295964, '#3c56c3'],
    [0.2825112107623318, '#3b57c4'],
    [0.28699551569506726, '#3b58c4'],
    [0.2914798206278027, '#3a59c5'],
    [0.29596412556053814, '#3a5ac6'],
    [0.3004484304932735, '#3a5bc6'],
    [0.30493273542600896, '#395cc7'],
    [0.3094170403587444, '#395dc7'],
    [0.31390134529147984, '#385ec8'],
    [0.3183856502242152, '#385fc8'],
    [0.32286995515695066, '#3760c8'],
    [0.3273542600896861, '#3661c8'],
    [0.33183856502242154, '#3663c8'],
    [0.3363228699551569, '#3564c8'],
    [0.34080717488789236, '#3565c8'],
    [0.3452914798206278, '#3466c7'],
    [0.34977578475336324, '#3368c6'],
    [0.3542600896860986, '#3369c6'],
    [0.35874439461883406, '#326ac5'],
    [0.3632286995515695, '#316cc4'],
    [0.36771300448430494, '#306dc2'],
    [0.3721973094170404, '#2f6ec1'],
    [0.37668161434977576, '#2e70bf'],
    [0.3811659192825112, '#2c72bd'],
    [0.38565022421524664, '#2b73bb'],
    [0.3901345291479821, '#2a75b9'],
    [0.39461883408071746, '#2876b6'],
    [0.3991031390134529, '#2778b4'],
    [0.40358744394618834, '#2679b2'],
    [0.4080717488789238, '#257ab0'],
    [0.41255605381165916, '#247cae'],
    [0.4170403587443946, '#237dac'],
    [0.42152466367713004, '#227ea9'],
    [0.4260089686098655, '#2280a7'],
    [0.4304932735426009, '#2281a5'],
    [0.4349775784753363, '#2182a3'],
    [0.43946188340807174, '#2183a1'],
    [0.4439461883408072, '#22859f'],
    [0.4484304932735426, '#22869d'],
    [0.452914798206278, '#22879b'],
    [0.45739910313901344, '#238899'],
    [0.4618834080717489, '#248997'],
    [0.4663677130044843, '#258a95'],
    [0.4708520179372197, '#268b93'],
    [0.47533632286995514, '#278d91'],
    [0.4798206278026906, '#288e8e'],
    [0.484304932735426, '#2a8f8c'],
    [0.4887892376681614, '#2b908b'],
    [0.49327354260089684, '#2d9189'],
    [0.4977578475336323, '#2f9287'],
    [0.5022421524663677, '#309385'],
    [0.5067264573991032, '#329383'],
    [0.5112107623318386, '#349481'],
    [0.515695067264574, '#36957f'],
    [0.5201793721973094, '#38967d'],
    [0.5246636771300448, '#3a977b'],
    [0.5291479820627802, '#3d9879'],
    [0.5336322869955157, '#3f9977'],
    [0.5381165919282511, '#419a75'],
    [0.5426008968609866, '#439a73'],
    [0.547085201793722, '#469b71'],
    [0.5515695067264574, '#489c6f'],
    [0.5560538116591928, '#4b9d6e'],
    [0.5605381165919282, '#4d9e6c'],
    [0.5650224215246636, '#509e6a'],
    [0.5695067264573991, '#529f68'],
    [0.5739910313901345, '#55a066'],
    [0.57847533632287, '#58a064'],
    [0.5829596412556054, '#5aa162'],
    [0.5874439461883408, '#5da260'],
    [0.5919282511210763, '#60a25e'],
    [0.5964125560538116, '#63a35d'],
    [0.600896860986547, '#65a45b'],
    [0.6053811659192825, '#68a459'],
    [0.6098654708520179, '#6ba557'],
    [0.6143497757847534, '#6ea555'],
    [0.6188340807174888, '#71a653'],
    [0.6233183856502242, '#74a651'],
    [0.6278026905829597, '#77a74f'],
    [0.632286995515695, '#79a74e'],
    [0.6367713004484304, '#7ca84c'],
    [0.6412556053811659, '#7fa84a'],
    [0.6457399103139013, '#81a948'],
    [0.6502242152466368, '#84a946'],
    [0.6547085201793722, '#86aa44'],
    [0.6591928251121076, '#89aa42'],
    [0.6636771300448431, '#8bab40'],
    [0.6681614349775785, '#8eab3f'],
    [0.6726457399103138, '#91ac3d'],
    [0.6771300448430493, '#93ac3b'],
    [0.6816143497757847, '#95ad39'],
    [0.6860986547085202, '#98ad37'],
    [0.6905829596412556, '#9aad35'],
    [0.695067264573991, '#9dae34'],
    [0.6995515695067265, '#9fae32'],
    [0.7040358744394619, '#a2af30'],
    [0.7085201793721972, '#a4af2e'],
    [0.7130044843049327, '#a7b02c'],
    [0.7174887892376681, '#a9b02a'],
    [0.7219730941704036, '#acb028'],
    [0.726457399103139, '#aeb127'],
    [0.7309417040358744, '#b1b125'],
    [0.7354260089686099, '#b3b123'],
    [0.7399103139013453, '#b6b221'],
    [0.7443946188340808, '#b8b220'],
    [0.7488789237668161, '#bbb21e'],
    [0.7533632286995515, '#bdb31c'],
    [0.757847533632287, '#c0b31a'],
    [0.7623318385650224, '#c2b319'],
    [0.7668161434977578, '#c5b317'],
    [0.7713004484304933, '#c8b416'],
    [0.7757847533632287, '#cab415'],
    [0.7802690582959642, '#cdb414'],
    [0.7847533632286995, '#cfb413'],
    [0.7892376681614349, '#d2b412'],
    [0.7937219730941704, '#d5b512'],
    [0.7982062780269058, '#d8b511'],
    [0.8026905829596412, '#dab512'],
    [0.8071748878923767, '#ddb512'],
    [0.8116591928251121, '#dfb512'],
    [0.8161434977578476, '#e2b612'],
    [0.820627802690583, '#e4b613'],
    [0.8251121076233183, '#e6b613'],
    [0.8295964125560538, '#e7b713'],
    [0.8340807174887892, '#e9b713'],
    [0.8385650224215246, '#ebb813'],
    [0.8430493273542601, '#ecb813'],
    [0.8475336322869955, '#edb913'],
    [0.852017937219731, '#efba13'],
    [0.8565022421524664, '#f0ba13'],
    [0.8609865470852018, '#f1bb14'],
    [0.8654708520179372, '#f2bc14'],
    [0.8699551569506726, '#f3bd14'],
    [0.874439461883408, '#f4bd14'],
    [0.8789237668161435, '#f5be14'],
    [0.8834080717488789, '#f5bf14'],
    [0.8878923766816144, '#f6c014'],
    [0.8923766816143498, '#f7c114'],
    [0.8968609865470852, '#f8c214'],
    [0.9013452914798206, '#f8c314'],
    [0.905829596412556, '#f9c413'],
    [0.9103139013452914, '#f9c513'],
    [0.9147982062780269, '#fac613'],
    [0.9192825112107623, '#fac713'],
    [0.9237668161434978, '#fbc813'],
    [0.9282511210762332, '#fbc913'],
    [0.9327354260089686, '#fcca13'],
    [0.9372197309417041, '#fccb13'],
    [0.9417040358744394, '#fccc13'],
    [0.9461883408071748, '#fdcd13'],
    [0.9506726457399103, '#fdce13'],
    [0.9551569506726457, '#fdcf12'],
    [0.9596412556053812, '#fdd012'],
    [0.9641255605381166, '#fed112'],
    [0.968609865470852, '#fed312'],
    [0.9730941704035875, '#fed412'],
    [0.9775784753363228, '#fed512'],
    [0.9820627802690582, '#fed612'],
    [0.9865470852017937, '#fed711'],
    [0.9910313901345291, '#fed811'],
    [0.9955156950672646, '#fed911'],
    [1.0, '#fedb11'],
  ],
  categoricalMain: [
    [0, '#003CB6'],
    [1, '#B90003'],
    [2, '#1EC9F9'],
    [3, '#820003'],
    [4, '#008DF9'],
    [5, '#D55E00'],
    [6, '#819DFB'],
    [7, '#000000'],
    [8, '#8B00D2'],
    [9, '#EBAA1A'],
    [10, '#BFBFBF'],
  ],
};

import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&.MuiButton-root': {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    minWidth: '100px',
    '&.MuiButton-sizeLarge': {
      fontSize: '1.5rem',
      minWidth: '96px',
    },
    '&.MuiButton-sizeSmall': {
      fontSize: '1.3rem',
      minWidth: '62px',
    },
  },
  '&.MuiButton-contained': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&.MuiButton-containedSecondary': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.MuiButton-containedInfo': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.hover.primary,
    },
    '&.MuiButton-containedSuccess': {
      backgroundColor: theme.palette.success.main,
    },
    '&.MuiButton-containedError': {
      backgroundColor: theme.palette.error.main,
    },
    '&.MuiButton-containedWarning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  '&.MuiButton-outlined': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.text.secondary,
    '&.MuiButton-outlinedSecondary': {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    '&.MuiButton-outlinedInfo': {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.hover.primary,
      borderColor: theme.palette.hover.primary,
      backgroundColor: theme.palette.state.hoverBackground,
    },
    '&.button-gradient:not(.Mui-disabled)': {
      borderColor: 'transparent',
      background: `linear-gradient(#fff, #fff) padding-box, 
      ${theme.palette.gradient.darkBlue} border-box`,
      '&:hover': {
        background: `linear-gradient(#f2fcff, #f2fcff) padding-box, 
        ${theme.palette.gradient.darkBlue} border-box`,
      },
    },
    '&.MuiButton-outlinedSuccess': {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
    '&.MuiButton-outlinedError': {
      color: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
    '&.MuiButton-outlinedWarning': {
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.action.disabledBackground,
    },
  },
  '&.MuiButton-text': {
    color: theme.palette.primary.main,
    '&.MuiButton-textSecondary': {
      color: theme.palette.secondary.main,
    },
    '&.MuiButton-textInfo': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.hover.primary,
      backgroundColor: 'transparent',
    },
    '&.MuiButton-textSuccess': {
      color: theme.palette.success.main,
    },
    '&.MuiButton-textError': {
      color: theme.palette.error.main,
    },
    '&.MuiButton-textWarning': {
      color: theme.palette.warning.main,
    },
  },
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
  '.MuiButton-iconSizeLarge svg': {
    width: '22px',
    height: '22px',
  },
  '.MuiButton-iconSizeMedium svg': {
    width: '20px',
    height: '20px',
  },
  '.MuiButton-iconSizeSmall svg': {
    width: '18px',
    height: '18px',
  },
}));

export { StyledButton as Button };

import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const DatasetItem = styled(Box)(({ theme }) => ({
  width: '377px',
  height: '75px',
  padding: 2,
  display: 'flex',
  background: '#E4F5FF',
  borderRadius: '8px',
  boxSizing: 'border-box',
  '&.highlight': {
    background: '#D5EEFC',
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  '&.publishing': {
    background: theme.palette.background.default,
  },
  '&.failed': {
    background: theme.palette.error.light,
  },
}));

import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Trash2 } from 'react-feather';
import { Box } from '@mui/material';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import analysisImage from 'app/assets/images/analysis/failed-analysis-image.svg';
import { ListRow } from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList.styles';
import { Status } from 'app/shared/components/Status/Status';
import { ANALYSIS_STATUSES } from 'app/shared/enum/analysis';
import { ProjectAnalysis } from 'app/screens/Projects/Projects.types';
import { SelectedAnalysis } from '../AnalysisLayout';

type FailedAnalysisItemProps = {
  labelId: string;
  row: ProjectAnalysis;
  setSelectedAnalysis: Dispatch<SetStateAction<any>>;
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  selectedAnalysis: SelectedAnalysis;
  handlePreviewOpen: (id: number) => void;
};

export const FailedAnalysisItem: FC<FailedAnalysisItemProps> = ({
  row,
  labelId,
  setSelectedAnalysis,
  setIsDeleteDialogOpen,
  selectedAnalysis,
  handlePreviewOpen,
}) => {
  const openDeleteDialog = useCallback((rowId: number, name: string, description: string) => {
    setSelectedAnalysis((analysis: SelectedAnalysis) => ({
      ...analysis,
      id: rowId,
      name,
      description,
    }));
    setIsDeleteDialogOpen(true);
  }, []);

  return (
    <ListRow
      className={classNames('failed', {
        highlight: row.id === selectedAnalysis?.previewId,
      })}
      onClick={() => handlePreviewOpen(row.id)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box borderRadius='4px' component='img' src={analysisImage} alt={row.name} />
        <Box
          sx={{
            ml: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            flexGrow: 1,
            minWidth: 0,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={row.name} placement='bottom-start'>
              <Typography noWrap component='h5' variant='h5' id={labelId} className='break-text'>
                {row.name}
              </Typography>
            </Tooltip>
            <Box ml={2}>
              <Status status={ANALYSIS_STATUSES.FAILED} />
            </Box>
          </Box>
          <Typography
            noWrap
            component='div'
            className='info'
            variant='body1'
            color='text.secondary'
            display='flex'
          >
            {row.dataset.name}
          </Typography>
        </Box>
      </Box>
      <Box
        p={1}
        display='flex'
        onClick={(e) => {
          e.stopPropagation();
          openDeleteDialog(row.id, row.name, row.description);
        }}
        sx={{ cursor: 'pointer' }}
      >
        <Trash2 />
      </Box>
    </ListRow>
  );
};

export enum CategoricalColorMaps {
  Default = 'default',
  Light = 'light',
  Monochromatic = 'monochromatic',
}

export type ColorLegendType = {
  visible: boolean;
  type: 'line' | 'categories';
  colorMap?: CategoricalColorMaps;
  dataBounds?: number[];
  colorBounds?: string[];
  functionName?: string;
  categories?: Record<string, string | null>[];
};

export type ChartColoringType = {
  colors: string[] | string;
  symbols?: string[] | string;
  legend?: Pick<
    ColorLegendType,
    'type' | 'colorBounds' | 'dataBounds' | 'functionName' | 'categories' | 'colorMap'
  >;
};

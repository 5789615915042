import React, { FC, useState } from 'react';
import styled from '@emotion/styled/macro';
import { RotateCcw } from 'react-feather';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import failed from 'app/assets/images/visualization/failed-visualization.svg';
import { Button } from 'app/mui/Button';

type FailedGraphProps = {
  errorMessage?: string;
  isRetryFailed?: boolean;
  isLargePan?: boolean;
  onResetGraph: () => Promise<void>;
};

const StyledFailedGraph = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    maxWidth: '30%',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  '& p': {
    color: theme.palette.error.dark,
    width: '335px',
    textAlign: 'center',
  },
}));

const StyledAlert = styled(Alert)<AlertProps & { isLargePan?: boolean }>(
  ({ theme, isLargePan }) => ({
    '&.MuiAlert-standard': {
      backgroundColor: theme.palette.grey.A200,
      position: 'absolute',
      bottom: isLargePan ? '10%' : '22px',
      color: theme.palette.text.primary,
      '& a': {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
      },
      '&.MuiAlert-standardError': {
        '&:before': {
          backgroundColor: theme.palette.error.main,
        },
        '.MuiTypography-root': {
          color: theme.palette.error.main,
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '20px',
        },
        '.MuiAlert-icon': {
          color: theme.palette.error.main,
        },
        '.MuiAlert-action': {
          color: theme.palette.error.main,
        },
      },
    },
  })
);

export const FailedGraph: FC<FailedGraphProps> = ({
  isRetryFailed,
  onResetGraph,
  errorMessage,
  isLargePan,
}) => {
  const theme = useTheme();
  const [showAlert, setShowAlert] = useState(true);

  return (
    <Box height='100%' position='relative' display='flex' justifyContent='center'>
      <StyledFailedGraph>
        <Box component='img' src={failed} alt='failed' />
        {!!errorMessage && !isRetryFailed && (
          <Typography variant='body1'>{errorMessage}</Typography>
        )}
        <Button onClick={onResetGraph} size='large' variant='contained' sx={{ mt: 5 }}>
          <RotateCcw style={{ marginRight: theme.spacing(1) }} /> Reset to defaults
        </Button>
      </StyledFailedGraph>
      {isRetryFailed && showAlert && (
        <StyledAlert severity='error' onClose={() => setShowAlert(false)} isLargePan={isLargePan}>
          <AlertTitle>Landscape generation failed</AlertTitle>
          Please contact us via <a href='mailto:support@bluelightai.com'>support@bluelightai.com</a>
        </StyledAlert>
      )}
    </Box>
  );
};

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import type { DataGridHandle, SortColumn } from 'react-data-grid';
import DataGrid, { CellClickArgs, CellMouseEvent, SelectColumn } from 'react-data-grid';
import { useAlert } from 'app/shared/hooks/useAlert';
import { SortIcon } from './components/SortIcon';
import {
  CheckboxFormatter,
  ColumnFormatter,
  ColumnHeaderCheckboxFormatter,
} from './components/CheckboxFormatter';
import { DataExplorerColumn } from './Explorer.types';
import { dataExplorerRowKey, getSortedRows } from './Explorer.utils';
import './Explorer.scss';
import { useAnalysis } from '../Analysis.hooks';
import { SELECTION_SOURCES } from '../Analysis.types';

type DataExplorerProps = {
  data: any;
  height?: number;
  onScroll: (event: React.UIEvent<HTMLDivElement>) => Promise<boolean>;
  selectedRows: number[];
  setSelectedRows: (selectedRows: number[]) => void;
  selectedColumns: number[];
  setSelectedColumns: (selectedRows: number[]) => void;
};

const getColumns = (
  columns: DataExplorerColumn[],
  handleChange: (value: number, isShiftKey?: boolean) => void,
  selectedFeatures: number[]
): any =>
  columns.reduce((acc: any, column: DataExplorerColumn, index: number) => {
    if (index === 0) {
      return [
        ...acc,
        SelectColumn,
        {
          key: 'row_index',
          name: 'ID',
          sortable: true,
          width: 80,
        },
      ];
    }

    return [
      ...acc,
      {
        key: column.column_id,
        name: column.name,
        sortable: false,
        width: 120,
        headerRenderer: (props: any) => (
          <ColumnHeaderCheckboxFormatter
            props={props}
            handleChange={handleChange}
            selectedFeatures={selectedFeatures}
          />
        ),
        formatter: (props: any) => (
          <ColumnFormatter props={props} selectedFeatures={selectedFeatures} />
        ),
      },
    ];
  }, []);

export const DataExplorer: FC<DataExplorerProps> = ({
  data,
  height,
  onScroll,
  selectedRows,
  setSelectedRows,
  selectedColumns,
  setSelectedColumns,
}) => {
  const [rows, setRows] = useState<any[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const { showErrorMessage } = useAlert();
  const gridRef = useRef<DataGridHandle>(null);

  const {
    featureSelectionSource,
    dataPointsSelectionSource,
    setFeaturesSelectionSource,
    setDataSelectionSource,
  } = useAnalysis();

  const handleChange = (value: number, isShiftKey?: boolean): void => {
    const isExplorerSource = featureSelectionSource?.source === SELECTION_SOURCES.EXPLORER;
    const previousSelected = selectedColumns[selectedColumns.length - 1];

    if ((!isExplorerSource || previousSelected === undefined) && isShiftKey) {
      showErrorMessage({
        title: 'Select features on explorer before using bulk selection.',
      });
      return;
    }

    let bulkSelectedValues: number[] = [];

    if (isShiftKey) {
      const lastSelectedIndex = data.columns.findIndex(
        (col: DataExplorerColumn) => col.column_id === previousSelected
      );
      const currentSelectedIndex = data.columns.findIndex(
        (col: DataExplorerColumn) => col.column_id === value
      );

      const isLTR = currentSelectedIndex > lastSelectedIndex;

      const bulkSelected = isLTR
        ? data.columns.slice(lastSelectedIndex, currentSelectedIndex + 1)
        : data.columns.slice(currentSelectedIndex, lastSelectedIndex + 1);

      bulkSelectedValues = [...bulkSelected.map((col: DataExplorerColumn) => col.column_id), value];
    }

    let columnSelection: number[] = [...selectedColumns];

    if (isShiftKey) {
      columnSelection = [...columnSelection, ...bulkSelectedValues];
    } else if (columnSelection.includes(value)) {
      columnSelection.splice(columnSelection.indexOf(value), 1);
    } else {
      columnSelection = [...columnSelection, value];
    }

    setSelectedColumns(Array.from(new Set(columnSelection)));
    setFeaturesSelectionSource({
      source: SELECTION_SOURCES.EXPLORER,
    });
  };

  const columns = getColumns(data.columns, handleChange, selectedColumns);

  useEffect(() => {
    setRows(data.rows);
  }, [data.rows]);

  const sortedRows = getSortedRows(rows, sortColumns);

  const selectedRowsSet = useMemo(() => new Set(selectedRows), [selectedRows]);

  const handleSelectedRowsChange = (rowsSet: Set<number>): void => {
    setSelectedRows(Array.from(rowsSet));
    setDataSelectionSource({
      source: SELECTION_SOURCES.EXPLORER,
    });
  };

  const isChangeEnabled = (isShiftKey?: boolean): boolean => {
    const isExplorerSource = dataPointsSelectionSource?.source === SELECTION_SOURCES.EXPLORER;
    const hasSelected = !!selectedRows.length;

    if ((!isExplorerSource || !hasSelected) && isShiftKey) {
      showErrorMessage({
        title: 'Select data points on explorer before using bulk selection.',
      });

      return false;
    }

    return true;
  };

  const CheckboxFormatterComponent = CheckboxFormatter(isChangeEnabled);

  const onCellClick = (args: CellClickArgs<any>, event: CellMouseEvent): void => {
    event.preventGridDefault();
  };

  const handleOnScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const focusedGridItem = gridRef.current?.element?.querySelector('[aria-selected="true"]');

    if (focusedGridItem) {
      gridRef.current?.selectCell({ idx: 0, rowIdx: -1 });
    }

    await onScroll(event);
  };

  return (
    <DataGrid
      ref={gridRef}
      columns={columns}
      rows={sortedRows}
      rowKeyGetter={dataExplorerRowKey}
      onRowsChange={setRows}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
      selectedRows={selectedRowsSet}
      onSelectedRowsChange={handleSelectedRowsChange}
      onCellClick={onCellClick}
      onScroll={handleOnScroll}
      renderers={{
        sortStatus: SortIcon,
        checkboxFormatter: CheckboxFormatterComponent,
      }}
      className='data-explorer rdg-light fill-grid'
      style={{ height }}
    />
  );
};

import ky from 'ky';
import { Auth } from 'aws-amplify';
import { StatusCodeEnum } from 'app/shared/enum/status-code';
import { setAlert } from 'app/store/modules/alert';
import { RootState, StoreType } from 'app/store/store';
import { getJWTTokenFromUserSession } from './amplify';
import { getAccessToken, setAccessToken } from './token';

const REQUEST_TIMEOUT = 60 * 1000; // 60 sec

let store: StoreType;

export const injectStore = (data: StoreType): RootState => {
  store = data;
};

export const getCommonHeaders = (): Headers => {
  const headers = new Headers();
  const accessToken = getAccessToken();

  try {
    if (accessToken) {
      headers.append('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const api = ky.create({
  prefixUrl: process.env.REACT_APP_API_URL,
  retry: {
    limit: 2,
    methods: ['get', 'put', 'delete', 'options'],
    statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
  },
  timeout: REQUEST_TIMEOUT,
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (response.status === StatusCodeEnum.ClientErrorUnauthorized) {
          try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = cognitoUser.signInUserSession;
            await new Promise((res) => {
              cognitoUser.refreshSession(currentSession.refreshToken, (err: any, session: any) => {
                const accessToken = getJWTTokenFromUserSession(session);
                request.headers.set('Authorization', `Bearer ${accessToken}`);
                setAccessToken(accessToken);
                return res(api(request));
              });
            });
          } catch (e) {
            setAccessToken('');
            window.location.replace('/login');
          }
        } else if (response.status >= 400 && response.status < 500) {
          const error = await response.json();
          const isArrayError = Array.isArray(error.detail);

          const errorMessage = isArrayError
            ? error.detail.map((i: any) => `"${i.loc[i.loc.length - 1]}" ${i.msg}`).join('\r\n')
            : error.detail;

          if (store) {
            store.dispatch(
              setAlert({
                type: 'error',
                variant: 'filled',
                title: errorMessage,
                withCloseOption: true,
              })
            );
          }
        }
      },
    ],
  },
});

export const webSocketUrl = (): string => {
  const origin = process.env.REACT_APP_WS_URL;
  const token = getAccessToken();

  return `${origin}ws/${token}`;
};

import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const ColoringWrapper = styled(Box)(() => ({
  width: '252px',
  top: '40px',
  padding: '8px 16px',
  background: '#FFFFFF',
  borderRadius: '4px',
  zIndex: 1,
}));

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { Layout } from 'app/shared/components/Layout/Layout';
import { DATE_SORTING_FIELD } from 'app/shared/utils/date';
import { Order, SortSelect } from 'app/shared/components/SortSelect/SortSelect';
import {
  ContentWrapper,
  EllipseBackground,
  PageContent,
  PageWrapper,
} from 'app/shared/styles/common';
import { useAlert } from 'app/shared/hooks/useAlert';
import type { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { LastJsonMessage, SOCKET_ENTITY, useSocket } from 'app/shared/hooks/useSocket';
import { Tab, TabPanel, Tabs } from 'app/shared/components/Tabs/Tabs';
import { CreateDatasetDialog } from 'app/shared/components/CreateDatasetDialog/CreateDatasetDialog';
import { NoResults } from 'app/shared/components/NoResults/NoResults';
import { Header } from 'app/shared/components/Header/Header';
import { Sidebar } from 'app/shared/components/Sidebar/Sidebar';
import noResultsThumb from 'app/assets/images/thumbnails/no-results-datasets.svg';

import { useDataLibrary } from './DataLibrary.hooks';
import { DataLibraryList, SelectedDataset } from './DataLibraryList/DataLibraryList';
import DataLibraryPreview from './DataLibraryPreview/DataLibraryPreview';
import { DataLibrarySamplesList } from './DataLibrarySamplesList/DataLibrarySamplesList';

interface CustomizedState {
  datasetId: number;
  isExample: boolean;
}

export const DataLibrary: FC = () => {
  const {
    layout,
    datasets,
    getDatasets,
    setLayout,
    getDatasetSamples,
    datasetSamples,
    showPublishedDataset,
    showFailedDataset,
  } = useDataLibrary();

  const location = useLocation();
  const [isDatasetModalOpen, setIsDatasetModalOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState(DATE_SORTING_FIELD);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState<SelectedDataset>(undefined);

  useEffect(() => {
    getDatasets();
    getDatasetSamples();
  }, []);

  const { showErrorMessage } = useAlert();

  const { lastJsonMessage } = useSocket();

  useEffect(() => {
    if (lastJsonMessage) {
      const { type, payload } = lastJsonMessage as LastJsonMessage;

      if (type === SOCKET_ENTITY.DATASET) {
        const dataset = payload as Dataset;

        if (showPublishedDataset(dataset.status) || showFailedDataset(dataset.status)) {
          getDatasets();
        }

        if (showFailedDataset(dataset.status)) {
          if (dataset.error) {
            showErrorMessage({ title: dataset.error });
          }
        }
      }
    }
  }, [lastJsonMessage]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTab(newValue);
    setSelectedDataset(undefined);
  };

  const handlePreviewOpen = useCallback((id: number): void => {
    setOpenPreview(true);
    setSelectedDataset((dataset) => ({
      ...dataset,
      id: undefined,
      previewId: id,
      name: undefined,
      isSample: false,
      description: undefined,
    }));
  }, []);

  const selectSampleDataset = useCallback((id: number, name?: string) => {
    const datasetName = name || '';
    setSelectedDataset((dataset) => ({
      ...dataset,
      id: undefined,
      previewId: id,
      name: datasetName,
      isSample: true,
      description: undefined,
    }));
  }, []);

  const handleSamplePreviewOpen = useCallback(
    (id: number): void => {
      setOpenPreview(true);
      selectSampleDataset(id);
    },
    [selectSampleDataset]
  );

  useEffect(() => {
    const state = location.state as CustomizedState;
    if (state !== null) {
      const { datasetId, isExample } = state;

      if (isExample) {
        setTab(1);
        handleSamplePreviewOpen(datasetId);
      } else {
        handlePreviewOpen(datasetId);
      }
    }
  }, [location]);

  const handlePreviewClose = useCallback((): void => {
    setOpenPreview(false);
    setSelectedDataset(undefined);
  }, []);

  const handleOpenDatasetModal = useCallback(() => {
    setIsDatasetModalOpen(true);
  }, []);

  const closeDatasetModal = useCallback(() => {
    setIsDatasetModalOpen(false);
    getDatasets();
  }, []);

  const onViewChange = useCallback(
    (view: string) => {
      setLayout(view);
    },
    [setLayout]
  );

  const selectedSampleDataset = useMemo(() => {
    if (selectedDataset && selectedDataset.previewId && selectedDataset.name) {
      return { id: selectedDataset.previewId, name: selectedDataset.name };
    }
    return undefined;
  }, [selectedDataset, selectedDataset?.previewId, selectSampleDataset]);

  return (
    <>
      <Header withUser />
      <PageWrapper>
        <Sidebar />
        <ContentWrapper>
          <PageContent>
            <EllipseBackground />
            <Layout
              heading='Data Library'
              caption='Data library contains all datasets you have access to.'
              onViewChange={onViewChange}
              type={layout}
              onButtonClick={handleOpenDatasetModal}
              buttonTitle='Add Dataset'
              isEmpty={datasets.length === 0}
            >
              <>
                <Box display='flex' justifyContent='space-between'>
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label='Data Library tabs'
                    variant='scrollable'
                    scrollButtons={false}
                  >
                    <Tab
                      label='User Datasets'
                      icon={<span>{datasets.length}</span>}
                      iconPosition='end'
                    />
                    <Tab
                      label='Sample Datasets'
                      icon={<span>{datasetSamples?.length}</span>}
                      iconPosition='end'
                    />
                  </Tabs>
                  {datasets.length > 0 && (
                    <SortSelect
                      order={order}
                      orderBy={orderBy}
                      setOrder={setOrder}
                      setOrderBy={setOrderBy}
                    />
                  )}
                </Box>
                {datasets.length > 0 ? (
                  <>
                    <TabPanel value={tab} index={0}>
                      <DataLibraryList
                        order={order}
                        orderBy={orderBy}
                        selectedDataset={selectedDataset}
                        setSelectedDataset={setSelectedDataset}
                        handlePreviewOpen={handlePreviewOpen}
                        handlePreviewClose={handlePreviewClose}
                      />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <DataLibrarySamplesList
                        order={order}
                        orderBy={orderBy}
                        selectedDataset={selectedSampleDataset}
                        handlePreviewOpen={handleSamplePreviewOpen}
                        selectSampleDataset={selectSampleDataset}
                      />
                    </TabPanel>
                  </>
                ) : (
                  <>
                    <TabPanel value={tab} index={0}>
                      <NoResults
                        thumb={noResultsThumb}
                        title="You haven't uploaded any datasets yet"
                        description='Add dataset to your catalog or discover our dataset samples. '
                        buttonText='Add Dataset'
                        onButtonClick={handleOpenDatasetModal}
                      />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <DataLibrarySamplesList
                        order={order}
                        orderBy={orderBy}
                        selectedDataset={selectedSampleDataset}
                        handlePreviewOpen={handleSamplePreviewOpen}
                        selectSampleDataset={selectSampleDataset}
                      />
                    </TabPanel>
                  </>
                )}
              </>
            </Layout>
          </PageContent>
          {isDatasetModalOpen && (
            <CreateDatasetDialog openDialog={isDatasetModalOpen} handleClose={closeDatasetModal} />
          )}
          {selectedDataset && (
            <DataLibraryPreview
              open={openPreview}
              onClose={handlePreviewClose}
              datasetId={selectedDataset.previewId}
              isSample={selectedDataset.isSample && selectedDataset.isSample}
            />
          )}
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

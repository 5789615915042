import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAppDispatch } from 'app/store/hooks';
import {
  fetchCurrentUser,
  setCognitoUser,
  setCurrentEmail,
  resetAccount,
  User,
} from 'app/store/modules/account';
import { useNavigate, RoutePath } from 'app/navigation';
import { useAlert } from 'app/shared/hooks/useAlert';
import { getJWTTokenFromUserSession } from '../utils/amplify';
import { setAccessToken } from '../utils/token';

interface IUseAuthentication {
  loading: boolean;
  login: (email: string, password: string) => void;
  resetPassword: (email: string) => Promise<void>;
  changePassword: (email: string, code: string, newPassword: string) => Promise<void>;
  completeNewPassword: (user: any, newPassword: string) => Promise<void>;
  logout: () => void;
}

export const useAuthentication = (): IUseAuthentication => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showErrorMessage } = useAlert();

  const login = async (email: string, password: string): Promise<void> => {
    try {
      setLoading(true);

      const user = await Auth.signIn(email, password);

      // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(setCognitoUser(user));
        navigate(RoutePath.CHANGE_PASSWORD);
      } else {
        const accessToken = getJWTTokenFromUserSession(user.getSignInUserSession());
        setAccessToken(accessToken);

        dispatch(fetchCurrentUser())
          .unwrap()
          .then((currentUser: User) => {
            if (currentUser.first_login) {
              navigate(RoutePath.WELCOME);
            } else {
              navigate(RoutePath.PROJECTS);
            }
          })
          .catch(() => {
            throw Error('Email or password is incorrect');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch {
      throw Error('Email or password is incorrect');
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (email: string): Promise<void> => {
    try {
      setLoading(true);

      dispatch(setCurrentEmail(email));
      await Auth.forgotPassword(email);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(email, code, newPassword);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const completeNewPassword = async (user: any, newPassword: string): Promise<void> => {
    try {
      setLoading(true);
      await Auth.completeNewPassword(user, newPassword);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await Auth.signOut({ global: true });
      dispatch(resetAccount());
      navigate(RoutePath.LOGIN);
      localStorage.clear();
    } catch (e) {
      showErrorMessage({
        title: 'An error occurred',
      });
    }
  };

  return {
    loading,
    login,
    resetPassword,
    changePassword,
    completeNewPassword,
    logout,
  };
};

import React, { ChangeEvent, FC, useEffect, useMemo } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { useAnalysis } from '../Analysis.hooks';

type GroupOptionsProps = {
  creationType: string | undefined;
  handleChangeCreationType: (event: ChangeEvent<HTMLInputElement>) => void;
  groupType: string;
  isCurrentlySelectedDisabled: boolean;
  isGroupResultsDisabled: boolean;
};

export const GroupOptions: FC<GroupOptionsProps> = ({
  creationType,
  handleChangeCreationType,
  groupType,
  isCurrentlySelectedDisabled,
  isGroupResultsDisabled,
}) => {
  const { selectedDataRows, selectedDataFilters, selectedFeatureFilters, selectedFeatures } =
    useAnalysis();

  const defaultValue = useMemo(() => {
    if (groupType === GroupCreationEnum.FEATURE_GROUP) {
      if (selectedFeatures.length > 0) {
        return GroupCreationEnum.CURRENTLY_SELECTED;
      }
      if (selectedFeatureFilters.length > 0) {
        return GroupCreationEnum.FILTER_RESULTS;
      }
      return GroupCreationEnum.CUSTOM;
    }
    if (groupType === GroupCreationEnum.DATA_GROUP) {
      if (selectedDataRows.length > 0) {
        return GroupCreationEnum.CURRENTLY_SELECTED;
      }
      if (selectedDataFilters.length > 0) {
        return GroupCreationEnum.FILTER_RESULTS;
      }
      return GroupCreationEnum.CUSTOM;
    }
  }, [groupType]);

  useEffect(() => {
    if (defaultValue) {
      handleChangeCreationType({
        target: { value: defaultValue },
      } as ChangeEvent<HTMLInputElement>);
    }
  }, [defaultValue]);

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby='radio-buttons-group-options-label'
        defaultValue={defaultValue}
        name='radio-buttons-group'
        sx={{ flexDirection: 'initial', mt: 1 }}
        onChange={handleChangeCreationType}
      >
        <FormControlLabel
          value={GroupCreationEnum.CURRENTLY_SELECTED}
          disabled={isCurrentlySelectedDisabled}
          control={<Radio />}
          label={GroupCreationEnum.CURRENTLY_SELECTED}
        />
        <FormControlLabel
          value={GroupCreationEnum.FILTER_RESULTS}
          control={<Radio />}
          disabled={isGroupResultsDisabled}
          label={
            groupType === GroupCreationEnum.FEATURE_GROUP
              ? 'Feature Explorer filter results'
              : 'Data Explorer filter results'
          }
        />
        <FormControlLabel
          value={GroupCreationEnum.CUSTOM}
          control={<Radio />}
          label={GroupCreationEnum.CUSTOM}
        />
      </RadioGroup>
    </FormControl>
  );
};

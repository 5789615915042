import React, { ChangeEvent, FC, SyntheticEvent, useState } from 'react';
import Box from '@mui/material/Box';
import { Tab, TabPanel, Tabs } from 'app/shared/components/Tabs/Tabs';
import {
  Row,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { LandscapeVisualizationType } from 'app/screens/Analysis/Analysis.types';
import { Configurations, ConfigurationsType } from '../Configurations/Configurations';
import { SourceData } from '../SourceData/SourceData';
import { DataPartition } from '../DataPartition/DataPartition';
import { FeaturePartition, PartitionRow } from '../FeaturePartition/FeaturePartition';

const tabStyles = { fontSize: '1.4rem', width: '190px' };

export enum VisualisationCreationType {
  SOURCE_DATA = 'Source Data',
  CONFIGURATIONS = 'Configurations',
  DATA_PARTITION = 'Data Partition',
  FEATURE_PARTITION = 'Feature Partition',
}

export enum VISUALIZATION_TABS {
  SOURCE_DATA = 0,
  CONFIGURATIONS = 1,
  PARTITION = 2,
}

type LandscapeOptionsProps = {
  sourceDataDataRow: Row;
  sourceDataFeatureRow: Row;
  sourceDataCustomDataRows: number[] | undefined;
  sourceDataCustomFeatureRows: number[] | undefined;
  type: string;
  handleSaveSourceData: (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ) => void;
  visualization: LandscapeVisualizationType;
  onConfigurationsChange: (value: ConfigurationsType) => void;
  onDataPartitionFeatureChange: (value: Row) => void;
  onDataPartitionDataChange: (value: Row) => void;
  onDataPartitionGlobalChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDataPartitionMetadataChange: (value: PartitionRow) => void;
  handleChangeBins: (event: ChangeEvent<HTMLInputElement>) => void;
  binsDecrement: () => void;
  binsIncrement: () => void;
  handleChangeEqualize: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeDistance: (event: ChangeEvent<HTMLInputElement>) => void;
  distanceDecrement: () => void;
  distanceIncrement: () => void;
  bins: string;
  distance: string;
  checkedEqualize: boolean;
  dataPartitionFeature: Row;
  dataPartitionData: Row;
  dataPartitionGlobal: VisualizationRow;
  dataPartitionMetadata: PartitionRow | undefined;
  handleChangeGroupType: (event: React.MouseEvent<HTMLElement>, newType: string) => void;
  groupType: string;
};

export const LandscapeOptions: FC<LandscapeOptionsProps> = ({
  sourceDataDataRow,
  sourceDataFeatureRow,
  sourceDataCustomDataRows,
  sourceDataCustomFeatureRows,
  type,
  handleSaveSourceData,
  onConfigurationsChange,
  visualization,
  onDataPartitionFeatureChange,
  onDataPartitionDataChange,
  onDataPartitionGlobalChange,
  onDataPartitionMetadataChange,
  handleChangeBins,
  binsDecrement,
  binsIncrement,
  handleChangeEqualize,
  handleChangeDistance,
  distanceDecrement,
  distanceIncrement,
  bins,
  distance,
  checkedEqualize,
  dataPartitionFeature,
  dataPartitionData,
  dataPartitionGlobal,
  dataPartitionMetadata,
  handleChangeGroupType,
  groupType,
}) => {
  const [creationType, setCreationType] = useState<number>(VISUALIZATION_TABS.SOURCE_DATA);

  return (
    <Box width='100%' minHeight='178px'>
      <Tabs
        value={creationType}
        onChange={(event: SyntheticEvent, value: VISUALIZATION_TABS) => setCreationType(value)}
        aria-label='visualization tabs'
        variant='scrollable'
        scrollButtons={false}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Tab label={VisualisationCreationType.SOURCE_DATA} sx={tabStyles} />
        <Tab label={VisualisationCreationType.CONFIGURATIONS} sx={tabStyles} />
        <Tab
          label={
            type === VisualisationType.DATA_LANDSCAPE
              ? VisualisationCreationType.DATA_PARTITION
              : VisualisationCreationType.FEATURE_PARTITION
          }
          sx={tabStyles}
        />
      </Tabs>
      <TabPanel value={creationType} index={VISUALIZATION_TABS.SOURCE_DATA}>
        <SourceData
          type={type}
          visualization={visualization}
          dataRow={sourceDataDataRow}
          handleSave={handleSaveSourceData}
          customDataRows={sourceDataCustomDataRows}
          featureRow={sourceDataFeatureRow}
          customFeatureRows={sourceDataCustomFeatureRows}
        />
      </TabPanel>
      <TabPanel value={creationType} index={VISUALIZATION_TABS.CONFIGURATIONS}>
        <Configurations type={type} onConfigurationsChange={onConfigurationsChange} />
      </TabPanel>
      <TabPanel value={creationType} index={VISUALIZATION_TABS.PARTITION}>
        {type === VisualisationType.DATA_LANDSCAPE ? (
          <DataPartition
            onDataPartitionFeatureChange={onDataPartitionFeatureChange}
            onDataPartitionGlobalChange={onDataPartitionGlobalChange}
            handleChangeBins={handleChangeBins}
            binsDecrement={binsDecrement}
            binsIncrement={binsIncrement}
            handleChangeEqualize={handleChangeEqualize}
            handleChangeDistance={handleChangeDistance}
            distanceDecrement={distanceDecrement}
            distanceIncrement={distanceIncrement}
            bins={bins}
            distance={distance}
            checkedEqualize={checkedEqualize}
            dataPartitionFeature={dataPartitionFeature}
            dataPartitionGlobal={dataPartitionGlobal}
            handleChangeGroupType={handleChangeGroupType}
            groupType={groupType}
          />
        ) : (
          <FeaturePartition
            onDataPartitionDataChange={onDataPartitionDataChange}
            onDataPartitionGlobalChange={onDataPartitionGlobalChange}
            onDataPartitionMetadataChange={onDataPartitionMetadataChange}
            handleChangeBins={handleChangeBins}
            binsDecrement={binsDecrement}
            binsIncrement={binsIncrement}
            handleChangeEqualize={handleChangeEqualize}
            handleChangeDistance={handleChangeDistance}
            distanceDecrement={distanceDecrement}
            distanceIncrement={distanceIncrement}
            bins={bins}
            distance={distance}
            checkedEqualize={checkedEqualize}
            dataPartitionData={dataPartitionData}
            dataPartitionGlobal={dataPartitionGlobal}
            dataPartitionMetadata={dataPartitionMetadata}
            handleChangeGroupType={handleChangeGroupType}
            groupType={groupType}
          />
        )}
      </TabPanel>
    </Box>
  );
};

import React, { FC } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { BrowserRouter, Routes, Route, RoutePath, RequireAuth } from 'app/navigation';
import { GlobalAlert } from 'app/shared/components/GlobalAlert/GlobalAlert';
import { theme } from 'app/shared/styles/theme';
import { Login } from 'app/screens/Login/Login';
import { ForgotPassword } from 'app/screens/ForgotPassword/ForgotPassword';
import { ChangePassword } from 'app/screens/ChangePassword/ChangePassword';
import { Welcome } from 'app/screens/Welcome/Welcome';
import { DataLibrary } from 'app/screens/DataLibrary/DataLibrary';
import { UploadDataset } from 'app/screens/UploadDataset/UploadDataset';
import { Analysis } from 'app/screens/Analysis/Analysis';
import { Projects } from 'app/screens/Projects/Projects';
import { ProjectOverview } from 'app/screens/ProjectOverview/ProjectOverview';

import { GlobalStyle } from './App.styles';
import 'react-data-grid/lib/styles.css';

export const App: FC = () => (
  <MuiThemeProvider theme={theme}>
    <EmotionThemeProvider theme={theme}>
      {GlobalStyle}
      <GlobalAlert />
      <BrowserRouter>
        <Routes>
          <Route path={RoutePath.LOGIN} element={<Login />} />
          <Route path={RoutePath.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={RoutePath.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route
            path={RoutePath.WELCOME}
            element={
              <RequireAuth>
                <Welcome />
              </RequireAuth>
            }
          />
          <Route
            path={RoutePath.DATA_LIBRARY}
            element={
              <RequireAuth>
                <DataLibrary />
              </RequireAuth>
            }
          />
          <Route
            path={RoutePath.UPLOAD_DATASET}
            element={
              <RequireAuth>
                <UploadDataset />
              </RequireAuth>
            }
          />
          <Route
            path={RoutePath.PROJECTS}
            element={
              <RequireAuth>
                <Projects />
              </RequireAuth>
            }
          />
          <Route
            path={RoutePath.PROJECT}
            element={
              <RequireAuth>
                <ProjectOverview />
              </RequireAuth>
            }
          />
          <Route
            path={RoutePath.ANALYSIS}
            element={
              <RequireAuth>
                <Analysis />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </EmotionThemeProvider>
  </MuiThemeProvider>
);

const YEAR_IN_SECONDS = 31536000;
const MONTH_IN_SECONDS = 2592000;
const DAY_IN_SECONDS = 86400;
const HOUR_IN_SECONDS = 3600;
const MINUTE_IN_SECONDS = 60;

function convertUTCDateToLocalDate(date: string): Date {
  const newDate = new Date(date);
  const localDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000);

  return localDate;
}

export const lastUpdated = (updated_at: string): string => {
  if (!updated_at) return 'N/A';

  const date = convertUTCDateToLocalDate(updated_at);

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / YEAR_IN_SECONDS);

  if (interval > 1) {
    return `${interval} years ago`;
  }

  interval = Math.floor(seconds / MONTH_IN_SECONDS);
  if (interval > 1) {
    return `${interval} months ago`;
  }

  interval = Math.floor(seconds / DAY_IN_SECONDS);
  if (interval > 1) {
    return `${interval} days ago`;
  }

  interval = Math.floor(seconds / HOUR_IN_SECONDS);
  if (interval > 1) {
    return `${interval} hours ago`;
  }

  interval = Math.floor(seconds / MINUTE_IN_SECONDS);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }

  return `${Math.floor(seconds)} seconds ago`;
};

import React, { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import { CustomSubsetSelect } from 'app/shared/components/CustomSubsetSelect/CustomSubsetSelect';
import { useLandscapeSourceData } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceData.hooks';
import {
  Row,
  SourceDataLandscapesOptions,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { SourceDataSearchSelect } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceDataSearchSelect';
import { LandscapeVisualizationType } from 'app/screens/Analysis/Analysis.types';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';

type SourceDataProps = {
  type: string;
  visualization: LandscapeVisualizationType;
  dataRow: Row;
  customDataRows: number[] | undefined;
  featureRow: Row;
  customFeatureRows: number[] | undefined;
  handleSave: (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ) => void;
};

export const SourceData: FC<SourceDataProps> = ({
  type,
  visualization,
  dataRow,
  customDataRows,
  featureRow,
  customFeatureRows,
  handleSave,
}) => {
  const { analysisId } = useParams();

  const {
    getFeaturesList,
    features,
    dataPointsCount,
    groups,
    selectedDataRows,
    selectedFeatures,
    defaultDataLandscapeConfig,
    defaultFeatureLandscapeConfig,
  } = useAnalysis();

  const {
    dataPointSubset,
    featureSubset,
    dataPoints,
    dataRowValue,
    featureRowValue,
    customDataRowsValue,
    customFeatureRowsValue,
    onDataRowChange,
    onFeatureRowChange,
    onCustomDataRowsChange,
    onCustomFeatureRowsChange,
  } = useLandscapeSourceData({
    visualization,
    groups,
    dataPointsCount,
    dataRow,
    featureRow,
    customDataRows,
    customFeatureRows,
    features,
    selectedDataRows,
    selectedFeatures,
  });

  const config =
    type === VisualisationType.DATA_LANDSCAPE
      ? defaultDataLandscapeConfig
      : defaultFeatureLandscapeConfig;

  const defaultFeatureSubset = featureSubset?.find(
    (i) => i.id === config.mapper_matrix_config.features_group_id
  );

  const defaultDataPointsSubset = dataPointSubset?.find(
    (i) => i.id === config.mapper_matrix_config.rows_group_id
  );

  useEffect(() => {
    getFeaturesList(Number(analysisId));
  }, []);

  useEffect(() => {
    handleSave(customDataRowsValue, customFeatureRowsValue, dataRowValue, featureRowValue);
  }, [customDataRowsValue, customFeatureRowsValue, dataRowValue, featureRowValue]);

  return (
    <Box>
      {featureSubset && dataPointSubset && dataPoints && features && (
        <>
          <Box mt={3}>
            <CustomSubsetSelect
              rows={features}
              onRowsChange={(value: VisualizationRow[]) => onCustomFeatureRowsChange(value)}
              label='Features'
              showSelect={featureRowValue?.feature_name === SourceDataLandscapesOptions.CUSTOM}
            >
              <SourceDataSearchSelect
                rows={featureSubset}
                rowLabel='Feature subset'
                onRowChange={(value) => onFeatureRowChange(value)}
                defaultValue={defaultFeatureSubset}
              />
            </CustomSubsetSelect>
          </Box>
          <Box mt={3}>
            <CustomSubsetSelect
              rows={dataPoints}
              onRowsChange={(value: VisualizationRow[]) => onCustomDataRowsChange(value)}
              label='Data points'
              showSelect={dataRowValue?.feature_name === SourceDataLandscapesOptions.CUSTOM}
            >
              <SourceDataSearchSelect
                rows={dataPointSubset}
                rowLabel='Data points subset'
                onRowChange={(value) => onDataRowChange(value)}
                defaultValue={defaultDataPointsSubset}
              />
            </CustomSubsetSelect>
          </Box>
        </>
      )}
    </Box>
  );
};

import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Autocomplete } from 'app/mui/Autocomplete';
import { Button } from 'app/mui/Button';
import { Checkbox } from 'app/mui/Checkbox';
import { FormControl } from 'app/mui/FormControl';
import { Radio } from 'app/mui/Radio';
import { Tooltip } from 'app/mui/Tooltip';
import { SourceDataSearchSelect } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceDataSearchSelect';

import type {
  DataExplorerColumn,
  FeatureExplorerColumn,
  Operator,
} from 'app/screens/Analysis/Explorer/Explorer.types';
import { FILTER_FEATURE_TYPE, isOperatorCategorical, OPERATOR } from '../Explorer.utils';

type FilterFormProps = {
  columns: any;
  onCancel: () => void;
  onApply: (column: any, operator: Operator, value: string) => void;
};

const OPTION_HEIGHT = 32;
const MAX_AUTOCOMPLETE_LIST_HEIGHT = 430;

const ExplorerFilter: FC<FilterFormProps> = ({ columns, onCancel, onApply }) => {
  const [column, setColumn] = useState<any>(null);
  const [operator, setOperator] = useState(OPERATOR.SUBSTR);
  const [value, setValue] = useState('');
  const [matchEntireCell, setMatchEntireCell] = useState(false);
  const [matchCase, setMatchCase] = useState(false);
  const [categoricalFeatureValues, setCategoricalFeatureValues] = useState([]);

  const categoricalFeatureOptions = useMemo(() => {
    if (column?.type === FILTER_FEATURE_TYPE.CATEGORICAL) {
      return column.unique_values.map((option: string) => ({
        id: option,
        feature_name: option,
      }));
    }
  }, [column]);

  useEffect(() => {
    setValue(column?.type === FILTER_FEATURE_TYPE.NUMERIC ? '0' : '');

    if (column?.type === FILTER_FEATURE_TYPE.NUMERIC) {
      setOperator(OPERATOR.LESS);
    } else if (column?.type === FILTER_FEATURE_TYPE.CATEGORICAL) {
      setOperator(OPERATOR.IN);
    }

    if (column?.type !== FILTER_FEATURE_TYPE.STRING) {
      setMatchEntireCell(false);
      setMatchCase(false);
    }
  }, [column?.type]);

  useEffect(() => {
    if (column?.type === FILTER_FEATURE_TYPE.STRING) {
      if (!matchCase && !matchEntireCell) {
        setOperator(OPERATOR.SUBSTR);
      } else if (matchCase && !matchEntireCell) {
        setOperator(OPERATOR.SUBSTR_MC);
      } else if (matchEntireCell && !matchCase) {
        setOperator(OPERATOR.SUBSTR_FULL);
      } else if (matchCase && matchEntireCell) {
        setOperator(OPERATOR.SUBSTR_MC_FULL);
      }
    }
  }, [column?.type, matchEntireCell, matchCase]);

  const handleChangeColumn = (newValue: any): void => {
    setColumn(newValue);
  };

  const handleChangeOperator = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formValue = event.target.value as OPERATOR;
    setOperator(formValue);
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const applyFilter = (): void => {
    const filterValue = isOperatorCategorical(operator)
      ? categoricalFeatureValues
          .map((featureValue: { feature_name: string; id: string }) => featureValue.id)
          .join(', ')
      : value;

    onApply(column, operator, filterValue);
  };

  const isDisabledByCategorical =
    column?.type === FILTER_FEATURE_TYPE.CATEGORICAL && !categoricalFeatureValues.length;
  const isDisabledByText = column?.type === FILTER_FEATURE_TYPE.STRING && !value.length;
  const disabledApplyButton = !column || isDisabledByCategorical || isDisabledByText;

  return (
    <Box>
      <Typography variant='caption'>Filter by column:</Typography>
      <Autocomplete
        useVirtual
        id='columns'
        options={columns}
        getOptionLabel={(filterColumn) => filterColumn.feature_name || filterColumn.name}
        value={column}
        optionHeight={OPTION_HEIGHT}
        maxHeight={MAX_AUTOCOMPLETE_LIST_HEIGHT}
        onChange={(event, newValue: DataExplorerColumn | FeatureExplorerColumn | null) => {
          handleChangeColumn(newValue);
        }}
        renderOption={(props, option, _, style = {}) => (
          <li {...props} style={style}>
            <Tooltip title={option.feature_name || option.name} placement='right'>
              <Typography
                sx={{
                  width: 200,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {option.feature_name || option.name}
              </Typography>
            </Tooltip>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            color='secondary'
            label='Column name'
            size='small'
            sx={{
              mt: 2,
              mb: 2,
            }}
          />
        )}
      />
      {column?.type === FILTER_FEATURE_TYPE.NUMERIC && (
        <>
          <FormControl sx={{ mb: 2 }}>
            <RadioGroup name='filter-values' value={operator} onChange={handleChangeOperator}>
              <FormControlLabel value={OPERATOR.LESS} control={<Radio />} label='less than' />
              <FormControlLabel value={OPERATOR.MORE} control={<Radio />} label='more than' />
              <FormControlLabel value={OPERATOR.EQUAL} control={<Radio />} label='equal' />
              <FormControlLabel
                value={OPERATOR.LESS_THAN}
                control={<Radio />}
                label='less than or equal'
              />
              <FormControlLabel
                value={OPERATOR.MORE_THAN}
                control={<Radio />}
                label='more than or equal'
              />
            </RadioGroup>
          </FormControl>
          <TextField
            fullWidth
            type='number'
            color='secondary'
            placeholder='Value'
            label='Value'
            value={value}
            size='small'
            onChange={handleChangeValue}
            sx={{
              mb: 2,
            }}
          />
        </>
      )}
      {column?.type === FILTER_FEATURE_TYPE.STRING && (
        <>
          <TextField
            fullWidth
            type='string'
            color='secondary'
            placeholder='Contains'
            label='Contains'
            value={value}
            size='small'
            onChange={handleChangeValue}
            sx={{
              mb: 1,
            }}
          />
          <FormControlLabel
            sx={{
              height: 30,
            }}
            control={
              <Checkbox
                value={matchEntireCell}
                checked={matchEntireCell}
                onChange={(e) => setMatchEntireCell(e.target.checked)}
              />
            }
            label='Match entire cell'
          />
          <FormControlLabel
            sx={{
              height: 30,
              mb: 1,
            }}
            control={
              <Checkbox
                value={matchCase}
                checked={matchCase}
                onChange={(e) => setMatchCase(e.target.checked)}
              />
            }
            label='Match case'
          />
        </>
      )}
      {column?.type === FILTER_FEATURE_TYPE.CATEGORICAL && (
        <Box mb={2}>
          <SourceDataSearchSelect
            multiple
            limit={10}
            rows={categoricalFeatureOptions}
            rowLabel='Is'
            onRowChange={(option) => setCategoricalFeatureValues(option)}
            defaultValues={[]}
          />
        </Box>
      )}
      <Box display='flex' justifyContent='space-between'>
        <Button variant='outlined' size='small' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={applyFilter}
          disabled={disabledApplyButton}
        >
          Apply Filter
        </Button>
      </Box>
    </Box>
  );
};

export default memo(ExplorerFilter);

import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const Card = styled(Box)(({ theme }) => ({
  width: '234px',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey['300']}`,
  borderRadius: '8px',
  cursor: 'pointer',
  overflow: 'hidden',
  '&:not(:last-child)': {
    marginRight: theme.spacing(2),
  },
  '&:hover, &.highlight': {
    background: theme.palette.blocksBackground.hover,
  },
}));

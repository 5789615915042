import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { LOADING_STATE } from 'app/store/constants';
import { LayoutEnum } from 'app/shared/enum/layout';
import {
  AnalysisType,
  GroupType,
  FeatureItemType,
  newGroupType,
  AnalysisConfig,
  ChartDataType,
  PanConfig,
  HighlightedVisualizationType,
  SelectionSourceType,
  DescriptiveStatistics,
  ComparativeStatistics,
} from 'app/screens/Analysis/Analysis.types';
import { ExplorerFilterType } from 'app/screens/Analysis/Explorer/Explorer.types';
import { RootState } from '../store';

type AnalysisSlice = {
  loading: LOADING_STATE;
  analysis: AnalysisType[];
  dataRowsTotal: number;
  featureRowsTotal: number;
  features: FeatureItemType[];
  currentAnalysis?: AnalysisType;
  analysisPreview?: AnalysisType;
  panConfig: PanConfig;
  selectedDataRows: [];
  selectedGraph?: AnalysisConfig;
  selectedChart?: ChartDataType;
  highlightedVisualization?: HighlightedVisualizationType;
  selectedDataExplorerFilters: ExplorerFilterType[];
  selectedFeatureExplorerFilters: ExplorerFilterType[];
  selectedFeatures: [];
  dataLandscapes: AnalysisConfig[];
  featureLandscapes: AnalysisConfig[];
  groups: GroupType[];
  lastCreatedGroup: newGroupType | undefined;
  layout: LayoutEnum.GRID | LayoutEnum.TABLE;
  featureSelectionSource: SelectionSourceType | null;
  dataPointsSelectionSource: SelectionSourceType | null;
  comparativeStatistics: any | null;
  descriptiveStatistics: any | null;
};

export const fetchAnalyses = createAsyncThunk(
  'analysis/fetchAnalyses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('analysis/', {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCurrentAnalysis = createAsyncThunk(
  'analysis/fetchCurrentAnalysis',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`analysis/${id}`, {
        headers: getCommonHeaders(),
      });

      return (await response.json()) as AnalysisType;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAnalysisPreview = createAsyncThunk(
  'analysis/fetchAnalysisPreview',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`analysis/${id}`, {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchGroups = createAsyncThunk(
  'analysis/fetchGroups',
  async (analysisId: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`analysis/${analysisId}/group`, {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFeatureList = createAsyncThunk(
  'analysis/fetchFeatureList',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`analysis/${id}/features`, {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const initialState = {
  layout: LayoutEnum.TABLE,
  loading: LOADING_STATE.IDLE,
  analysis: [],
  dataRowsTotal: 0,
  featureRowsTotal: 0,
  features: [],
  currentAnalysis: undefined,
  analysisPreview: undefined,
  panConfig: Array(10)
    .fill('')
    .map((_, i) => ({ panel: `panel_${i + 1}` })),
  selectedDataRows: [],
  selectedFeatures: [],
  selectedGraph: undefined,
  selectedChart: undefined,
  highlightedVisualization: undefined,
  selectedDataExplorerFilters: [],
  selectedFeatureExplorerFilters: [],
  groups: [],
  dataLandscapes: [],
  featureLandscapes: [],
  lastCreatedGroup: undefined,
  featureSelectionSource: null,
  dataPointsSelectionSource: null,
  descriptiveStatistics: null,
  comparativeStatistics: null,
} as AnalysisSlice;

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    resetAnalysisState: () => initialState,
    setAnalysisLayout: (state, action) => {
      state.layout = action.payload;
    },
    setSelectedDataExplorerRows: (state, action) => {
      state.selectedDataRows = action.payload;
    },
    setSelectedFeatureExplorerRows: (state, action) => {
      state.selectedFeatures = action.payload;
    },
    setSelectedGraphVisualization: (state, action) => {
      state.selectedGraph = action.payload;
    },
    setSelectedChartVisualization: (state, action) => {
      state.selectedChart = action.payload;
    },
    setVisualizationHighlighted: (state, action) => {
      state.highlightedVisualization = action.payload;
    },
    setSelectedDataExplorerFilters: (state, action) => {
      state.selectedDataExplorerFilters = action.payload;
    },
    setSelectedFeatureExplorerFilters: (state, action) => {
      state.selectedFeatureExplorerFilters = action.payload;
    },
    setCurrentAnalysis: (state, action) => {
      state.currentAnalysis = action.payload;
    },
    setPanConfig: (state, action) => {
      state.panConfig = action.payload;
    },
    setAnalysisPreview: (state, action) => {
      state.analysisPreview = action.payload;
    },
    setDataRowsTotal: (state, action) => {
      state.dataRowsTotal = action.payload;
    },
    setFeatureRowsTotal: (state, action) => {
      state.featureRowsTotal = action.payload;
    },
    setFeatureSelectionSource: (state, action) => {
      state.featureSelectionSource = action.payload;
    },
    setDataPointsSelectionSource: (state, action) => {
      state.dataPointsSelectionSource = action.payload;
    },
    setCreatedExplorerGroup: (state, action) => {
      state.lastCreatedGroup = action.payload;
    },
    setDataLandscapes: (state, action) => {
      state.dataLandscapes = action.payload;
    },
    setFeatureLandscapes: (state, action) => {
      state.featureLandscapes = action.payload;
    },
    setComparativeStatistics: (state, action) => {
      state.comparativeStatistics = action.payload;
    },
    setDescriptiveStatistics: (state, action) => {
      state.descriptiveStatistics = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyses.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchAnalyses.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchAnalyses.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.analysis = action.payload;
    });
    builder.addCase(fetchCurrentAnalysis.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchCurrentAnalysis.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchCurrentAnalysis.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.currentAnalysis = action.payload;
      state.dataLandscapes = action.payload.config.visual.data_landscape;
      state.featureLandscapes = action.payload.config.visual.feature_landscape;
    });
    builder.addCase(fetchAnalysisPreview.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchAnalysisPreview.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchAnalysisPreview.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.analysisPreview = action.payload;
    });
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
    builder.addCase(fetchFeatureList.fulfilled, (state, action) => {
      state.features = action.payload;
    });
  },
});

// actions
export const {
  resetAnalysisState,
  setAnalysisLayout,
  setSelectedDataExplorerRows,
  setSelectedFeatureExplorerRows,
  setSelectedDataExplorerFilters,
  setSelectedChartVisualization,
  setVisualizationHighlighted,
  setSelectedGraphVisualization,
  setSelectedFeatureExplorerFilters,
  setCurrentAnalysis,
  setDataRowsTotal,
  setFeatureRowsTotal,
  setFeatureSelectionSource,
  setDataPointsSelectionSource,
  setCreatedExplorerGroup,
  setPanConfig,
  setDataLandscapes,
  setFeatureLandscapes,
  setComparativeStatistics,
  setDescriptiveStatistics,
} = analysisSlice.actions;

// selectors
export const selectAnalysisLoading = (state: RootState): LOADING_STATE => state.analysis.loading;
export const selectAnalysis = (state: RootState): AnalysisType[] => state.analysis.analysis;
export const selectCurrentAnalysis = (state: RootState): AnalysisType =>
  state.analysis.currentAnalysis;
export const selectAnalysisPreview = (state: RootState): AnalysisType =>
  state.analysis.analysisPreview;
export const selectDataRowsTotal = (state: RootState): number => state.analysis.dataRowsTotal;
export const selectFeatureRowsTotal = (state: RootState): number => state.analysis.featureRowsTotal;
export const selectAnalysisLayout = (state: RootState): string => state.analysis.layout;
export const selectPanConfig = (state: RootState): PanConfig => state.analysis.panConfig;
export const selectSelectedDataExplorerRows = (state: RootState): [] =>
  state.analysis.selectedDataRows;
export const selectSelectedFeatureExplorerRows = (state: RootState): [] =>
  state.analysis.selectedFeatures;
export const retrieveSelectedVisualizationGraph = (state: RootState): AnalysisConfig =>
  state.analysis.selectedGraph;
export const retrieveSelectedVisualizationChart = (state: RootState): ChartDataType =>
  state.analysis.selectedChart;
export const selectHighlightedVisualization = (state: RootState): HighlightedVisualizationType =>
  state.analysis.highlightedVisualization;
export const selectSelectedDataExplorerFilters = (state: RootState): [] =>
  state.analysis.selectedDataExplorerFilters;
export const selectSelectedFeatureExplorerFilters = (state: RootState): [] =>
  state.analysis.selectedFeatureExplorerFilters;
export const selectGroups = (state: RootState): GroupType[] => state.analysis.groups;
export const selectLastCreatedGroup = (state: RootState): newGroupType | undefined =>
  state.analysis.lastCreatedGroup;
export const selectDefaultDataLandscapeConfig = createSelector(
  [selectCurrentAnalysis],
  (analysis) => analysis?.config?.default?.data_landscape
);
export const selectDefaultFeatureLandscapeConfig = createSelector(
  selectCurrentAnalysis,
  (analysis) => analysis?.config?.default?.feature_landscape
);
export const selectDataPointsCount = createSelector(
  selectCurrentAnalysis,
  (analysis) => analysis?.dataset?.dataset_metadata?.extra_metadata.num_of_rows
);
export const selectChartConfig = createSelector(
  selectCurrentAnalysis,
  (analysis) => analysis?.config?.visual?.chart
);
export const selectDataLandscapeVisualization = createSelector(
  [retrieveSelectedVisualizationGraph],
  (analysis) => analysis?.config?.mapper_matrix_config
);
export const selectDataLandscapePartition = createSelector(
  [retrieveSelectedVisualizationGraph],
  (analysis) => analysis?.config?.filters
);
export const selectFeatureLandscapeVisualization = createSelector(
  [retrieveSelectedVisualizationGraph],
  (analysis) => analysis?.config?.mapper_matrix_config
);
export const selectFeatureLandscapePartition = createSelector(
  [retrieveSelectedVisualizationGraph],
  (analysis) => analysis?.config?.filters
);
export const selectScatterPlotVisualization = createSelector(
  [retrieveSelectedVisualizationChart],
  (analysis) => analysis && analysis.config
);
export const selectHeatmapVisualization = createSelector(
  [retrieveSelectedVisualizationChart],
  (analysis) => analysis && analysis.config
);
export const selectViolinPlotVisualization = createSelector(
  [retrieveSelectedVisualizationChart],
  (analysis) => analysis && analysis.config
);
export const selectHistogramVisualization = createSelector(
  [retrieveSelectedVisualizationChart],
  (analysis) => analysis && analysis.config
);
export const selectCurrentAnalysisDataset = createSelector(
  [selectCurrentAnalysis],
  (analysis) => analysis?.dataset
);
export const selectDataLandscapeVisualizations = (state: RootState): AnalysisConfig[] =>
  state.analysis.dataLandscapes;
export const selectFeatureLandscapeVisualizations = (state: RootState): AnalysisConfig[] =>
  state.analysis.featureLandscapes;
export const selectScatterPlotVisualizations = createSelector(
  [selectCurrentAnalysis],
  (analysis) => analysis?.config?.visual.chart.scatter_plot
);
export const selectHistogramVisualizations = createSelector(
  [selectCurrentAnalysis],
  (analysis) => analysis?.config?.visual.chart.histogram
);
export const selectHeatmapVisualizations = createSelector(
  [selectCurrentAnalysis],
  (analysis) => analysis?.config?.visual.chart.heatmap
);
export const selectViolinPlotVisualizations = createSelector(
  [selectCurrentAnalysis],
  (analysis) => analysis?.config?.visual.chart.violin_plot
);

export const selectFeatures = (state: RootState): [] => state.analysis.features;
export const selectFeaturesSelectionSource = (state: RootState): SelectionSourceType | null =>
  state.analysis.featureSelectionSource;
export const selectDataPointsSelectionSource = (state: RootState): SelectionSourceType | null =>
  state.analysis.dataPointsSelectionSource;

export const selectComparativeStatistics = (state: RootState): ComparativeStatistics | null =>
  state.analysis.comparativeStatistics;
export const selectDescriptiveStatistics = (state: RootState): DescriptiveStatistics | null =>
  state.analysis.descriptiveStatistics;

// reducer
export const analysisReducer = analysisSlice.reducer;

import { RoutePath, useNavigate } from 'app/navigation';
import { useCallback } from 'react';

type UseNavigationType = {
  navigateToLogin: () => void;
  navigateToHome: () => void;
  navigateToForgotPassword: () => void;
  navigateToProjects: () => void;
  navigateToProjectOverview: (projectId: number) => void;
  navigateToDatasetPreview: (datasetId: number, isExample?: boolean) => void;
  navigateToAnalysis: (projectId: number, analysisId: number) => void;
};

export const useNavigation = (): UseNavigationType => {
  const navigate = useNavigate();

  const navigateToLogin = useCallback(() => {
    navigate(RoutePath.LOGIN);
  }, []);

  const navigateToHome = useCallback(() => {
    navigate(RoutePath.WELCOME);
  }, []);

  const navigateToForgotPassword = useCallback(() => {
    navigate(RoutePath.FORGOT_PASSWORD);
  }, []);

  const navigateToProjectOverview = useCallback((projectId: number): void => {
    navigate(`/projects/${projectId}`);
  }, []);

  const navigateToProjects = useCallback((): void => {
    navigate(RoutePath.PROJECTS);
  }, []);

  const navigateToDatasetPreview = useCallback((datasetId: number, isExample = false): void => {
    navigate(RoutePath.DATA_LIBRARY, {
      state: {
        datasetId,
        isExample,
      },
    });
  }, []);

  const navigateToAnalysis = useCallback((projectId: number, analysisId: number) => {
    navigate(`/projects/${projectId}/analysis/${analysisId}`);
  }, []);

  return {
    navigateToLogin,
    navigateToHome,
    navigateToForgotPassword,
    navigateToProjects,
    navigateToProjectOverview,
    navigateToDatasetPreview,
    navigateToAnalysis,
  };
};

import React, {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Graph from 'graphology';
import type AbstractGraph from 'graphology-types';
import { NODE_ENV_DEV } from 'app/shared/utils/environment';
import forceAtlas2 from 'graphology-layout-forceatlas2';

import {
  defaultSettings,
  ForceAtlasAnimator,
  GraphWidget,
  initialGraphLayout,
  NodeBorderProgram,
} from 'app/shared/components/GraphWidget';

import type { Edge } from 'app/shared/components/GraphWidget/types';
import type { LandscapeData, LandscapeNode } from 'app/screens/Analysis/Analysis.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import {
  AnalysisConfig,
  SELECTION_SOURCES,
  SelectionSourceType,
} from 'app/screens/Analysis/Analysis.types';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';

const nodeProgramClasses = {
  border: NodeBorderProgram,
};

enum NodeScalingMode {
  None,
  A,
  B,
  C,
}

type LandscapeGraphWidgetProps = {
  type: VisualisationType;
  data: LandscapeData;
  selectionSource: SelectionSourceType | null;
  metadata: AnalysisConfig;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedIndices?: (type: VisualisationType, rows: number[]) => void;
  shouldLayoutUpdate?: boolean;
  setShouldLayoutUpdate?: (shouldLayoutUpdate: boolean) => void;
  isUpdate: boolean;
  setUpdate: (update: boolean) => void;
  isGenerateSvg: boolean;
  onSvgGenerate: (graph: AbstractGraph, metadata: AnalysisConfig) => void;
};

export const LandscapeGraphWidget: FC<LandscapeGraphWidgetProps> = ({
  setLoading,
  data,
  type,
  selectionSource,
  metadata,
  setSelectedIndices,
  shouldLayoutUpdate,
  setShouldLayoutUpdate,
  isUpdate,
  setUpdate,
  isGenerateSvg,
  onSvgGenerate,
}) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [nodeScalingMode, setNodeScalingMode] = useState(NodeScalingMode.B);
  const [selectedNodes, setSelectedNodes] = useState<number[]>([]);

  const [graph, setGraph] = useState<Graph | null>(null);
  const [animator, setAnimator] = useState<ForceAtlasAnimator | null>(null);
  const [clearSigma, setClearSigma] = useState(false);

  const graphLoaded = useRef(false);

  const { features } = useAnalysis();
  const updateSelectedIndicesFromSelectedNodes = (selection: number[]): void => {
    if (setSelectedIndices) {
      const selectedData = selection.map((n) => data.nodes[n].attributes?.data || []).flat();
      setSelectedIndices(type, selectedData);
    }
  };
  const setSelectedNodesFromGraph = (nodes: number[]): void => {
    setSelectedNodes(nodes);
    updateSelectedIndicesFromSelectedNodes(nodes);
  };
  const addSelectedNodesFromGraph = (nodes: number[]): void => {
    const newSelectedNodes = [
      ...selectedNodes,
      ...nodes.filter((n) => !selectedNodes.some((i) => i === n)),
    ];
    setSelectedNodesFromGraph(newSelectedNodes);
  };

  const setLoaded = useCallback(() => setLoading(false), []);
  const applyNodeScaling = (mode: NodeScalingMode, graphToScale: AbstractGraph): void => {
    const DEFAULT_NODE_SIZE = 4;

    const nodeSizes = data.nodes.map((n) => n.attributes.data.length);
    const minSamples = Math.min(...nodeSizes);
    const maxSamples = Math.max(...nodeSizes);

    const setNodeSize = (key: number, value: number): void => {
      graphToScale.setNodeAttribute(key, 'size', value);
    };

    if (maxSamples === minSamples) {
      data.nodes.forEach((node: LandscapeNode) => {
        setNodeSize(node.key, DEFAULT_NODE_SIZE);
      });
      return;
    }

    const meanSamples =
      nodeSizes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
      nodeSizes.length;

    const SCALE_MIN = 0.75;
    const SCALE_MAX = 4.0;
    const alpha = Math.log(SCALE_MAX / SCALE_MIN) / Math.log(maxSamples / minSamples);

    data.nodes.forEach((node: LandscapeNode) => {
      if (mode === NodeScalingMode.None) {
        setNodeSize(node.key, DEFAULT_NODE_SIZE);
      } else if (mode === NodeScalingMode.A) {
        let scalingFactor;
        if (node.attributes.data.length < meanSamples) {
          scalingFactor =
            1 -
            (1 - SCALE_MIN) *
              ((meanSamples - node.attributes.data.length) / (meanSamples - minSamples));
        } else {
          scalingFactor =
            1 +
            (SCALE_MAX - 1) *
              ((node.attributes.data.length - meanSamples) / (maxSamples - meanSamples));
        }
        setNodeSize(node.key, DEFAULT_NODE_SIZE * Math.sqrt(scalingFactor));
      } else if (mode === NodeScalingMode.B) {
        const val = SCALE_MIN * (node.attributes.data.length / minSamples) ** alpha;
        setNodeSize(node.key, DEFAULT_NODE_SIZE * Math.sqrt(val));
      } else if (mode === NodeScalingMode.C) {
        const harlanR = 0.5 * (1 + Math.log(node.attributes.data.length / minSamples));
        setNodeSize(node.key, DEFAULT_NODE_SIZE * Math.sqrt(harlanR));
      }
    });
  };

  const setOriginalNodePosition = (graphToInit: AbstractGraph) => {
    graphToInit.forEachNode((node: string) => {
      graphToInit.updateNodeAttributes(node, (attr) => ({
        ...attr,
        originalX: attr.x,
        originalY: attr.y,
      }));
    });
  };

  const initGraph = async (
    sourceData: LandscapeData,
    graphToInit: AbstractGraph,
    useWorker = true
  ): Promise<void> => {
    sourceData.nodes.forEach((node: LandscapeNode) => {
      graphToInit.addNode(node.key, node.attributes);

      const coloredLabel = node.attributes.colored_label;

      const dataPoints = graphToInit.getNodeAttribute(node.key, 'data');
      const isFeatureLandscape = sourceData.params.mapper_matrix_config.transpose;
      let label = '';
      if (isFeatureLandscape) {
        const featureNames = dataPoints.map(
          (index: number) => features[Number(index)].feature_name
        );
        label = featureNames.length === 1 ? featureNames[0] : `${featureNames.length} features`;
      } else {
        const dataPointCount = dataPoints.length;
        label = `${
          dataPointCount === 1 ? `Data point ID: ${dataPoints[0]}` : `${dataPointCount} data points`
        }`;
      }
      graphToInit.setNodeAttribute(node.key, 'defaultLabel', label);
      if (coloredLabel !== undefined) {
        graphToInit.setNodeAttribute(node.key, 'labelToDisplay', coloredLabel);
      } else {
        graphToInit.setNodeAttribute(node.key, 'labelToDisplay', label);
      }
    });

    sourceData.edges.forEach((edge: Edge) => {
      graphToInit.addEdge(edge.source, edge.target);
    });

    const initialSettings = { ...defaultSettings, ...forceAtlas2.inferSettings(graphToInit) };

    await initialGraphLayout(
      graphToInit,
      { iterations: 500, settings: initialSettings },
      useWorker
    );

    setGraph(graphToInit);
    graphLoaded.current = true;
    setLoaded();

    // We kill becaues it could loaded in a totally new graph.
    // i.e there could have already been a graph that was being animated.
    if (animator) {
      animator.kill();
    }
    const newAnimator = new ForceAtlasAnimator(
      graphToInit,
      { settings: { ...defaultSettings, ...forceAtlas2.inferSettings(graphToInit) } },
      { maxiters: 500, miniters: 200, convergenceThreshold: 0.1 }
    );
    newAnimator.start();
    setAnimator(newAnimator);

    setOriginalNodePosition(graphToInit);
  };

  useEffect(() => {
    if (!data) return;
    if (!graphLoaded.current) {
      const newGraph = new Graph();
      initGraph(data, newGraph);
      applyNodeScaling(nodeScalingMode, newGraph);
    } else {
      if (!graph) return;
      data.nodes.forEach((node: LandscapeNode) => {
        if (node.attributes.color) {
          graph.setNodeAttribute(node.key, 'color', node.attributes.color);
        } else {
          graph.removeNodeAttribute(node.key, 'color');
        }

        if (node.attributes.colored_label) {
          const text = node.attributes.colored_label;
          graph.setNodeAttribute(node.key, 'labelToDisplay', text);
        } else {
          const defaultLabel = graph.getNodeAttribute(node.key, 'defaultLabel');
          graph.removeNodeAttribute(node.key, 'colored_label');
          graph.setNodeAttribute(node.key, 'labelToDisplay', defaultLabel);
        }

        if (node.attributes.border) {
          graph.setNodeAttribute(node.key, 'border', node.attributes.border);
        } else {
          graph.removeNodeAttribute(node.key, 'border');
        }
      });
      applyNodeScaling(nodeScalingMode, graph);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdate && graphLoaded.current && animator) {
      animator.kill();
      setClearSigma(true);
      graphLoaded.current = false;
    }

    if (isUpdate) {
      setUpdate(false);
    }
  }, [isUpdate]);

  useEffect(() => {
    if (graphLoaded.current && graph) {
      applyNodeScaling(nodeScalingMode, graph);
    }
  }, [nodeScalingMode]);

  useEffect(() => {
    const isLandscapeSource =
      selectionSource?.source === SELECTION_SOURCES.LANDSCAPE &&
      selectionSource?.id === metadata.id;

    if (!isLandscapeSource) {
      setSelectedNodes([]);
    }
  }, [selectionSource]);

  const toggleFullScreen = useCallback(() => setFullScreen((prevState) => !prevState), []);

  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullScreen) {
        toggleFullScreen();
      }
    },
    [isFullScreen, toggleFullScreen]
  );

  useEffect(() => {
    if (isFullScreen) {
      document.addEventListener('keyup', handleEscKey);
    }

    return () => {
      if (isFullScreen) {
        document.removeEventListener('keyup', handleEscKey);
      }
    };
  }, [isFullScreen, handleEscKey]);

  const handleNodeKey = useCallback(
    NODE_ENV_DEV
      ? (event: KeyboardEvent) => {
          if (event.key === 'ArrowRight' && event.ctrlKey && event.shiftKey) {
            setNodeScalingMode((prevMode) =>
              prevMode + 1 in NodeScalingMode ? prevMode + 1 : prevMode
            );
          } else if (event.key === 'ArrowLeft' && event.ctrlKey && event.shiftKey) {
            setNodeScalingMode((prevMode) =>
              prevMode - 1 in NodeScalingMode ? prevMode - 1 : prevMode
            );
          }
        }
      : () => {},
    [setNodeScalingMode]
  );

  useEffect(
    NODE_ENV_DEV
      ? () => {
          document.addEventListener('keydown', handleNodeKey);

          return () => {
            document.removeEventListener('keydown', handleNodeKey);
          };
        }
      : () => {},
    [handleNodeKey]
  );

  useEffect(() => {
    if (!data) {
      setLoading(true);
    }
  }, [data]);

  const fullScreenStyles = useMemo<Partial<CSSProperties>>(
    () =>
      isFullScreen
        ? {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1400,
          }
        : {},
    [isFullScreen]
  );

  const shouldAnimate = true;

  const settings = useMemo(() => ({ nodeProgramClasses }), []);

  useEffect(() => {
    if (isGenerateSvg && graph) {
      onSvgGenerate(graph, metadata);
    }
  }, [isGenerateSvg, graph]);

  if (!graph || !animator) return null;

  return (
    <GraphWidget
      containerStyle={fullScreenStyles}
      sigmaSettings={settings}
      graph={graph}
      animator={animator}
      animate={shouldAnimate}
      selectedNodes={selectedNodes}
      setSelectedNodes={setSelectedNodesFromGraph}
      addSelectedNodes={addSelectedNodesFromGraph}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
      shouldLayoutUpdate={shouldLayoutUpdate}
      setShouldLayoutUpdate={setShouldLayoutUpdate}
      clearSigma={clearSigma}
      setClearSigma={setClearSigma}
    />
  );
};

import { styled } from '@mui/material/styles';
import { ArrowLeft } from 'react-feather';

export const StyledArrowLeft = styled(ArrowLeft)(() => ({
  cursor: 'pointer',
  ' :hover': { transform: 'scale(1.2)' },
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(4),

  '&:hover': {
    cursor: 'pointer',
  },

  '&.is-active': {
    svg: {
      path: {
        fill: 'url(#svgGradient)',
      },
    },
  },

  '&.grid-icon.is-active': {
    svg: {
      transform: 'scale(1.5)',
    },
  },

  '&.list-icon.is-active': {
    svg: {
      transform: 'scale(2)',
    },
  },
}));

export const ItemsCount = styled('div')(({ theme }) => ({
  width: '24px',
  height: '24px',
  background: theme.palette.grey['100'],
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
}));

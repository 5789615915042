import { styled } from '@mui/material/styles';
import FormControl, { FormControlProps } from '@mui/material/FormControl';

const StyledFormControl = styled(FormControl)<FormControlProps>(({ theme }) => ({
  '&.small': {
    width: '150px',
    '& .MuiOutlinedInput-root': {
      height: '30px',
      '& .MuiSelect-select': {
        height: '21px',
        paddingLeft: theme.spacing(1),
      },
    },
  },
  '&.initial': {
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& .MuiSelect-select': {
        height: '21px',
        paddingLeft: theme.spacing(1),
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiSelect-select': {
      height: '23px',
    },
    '& fieldset': {
      borderColor: `${theme.palette.state.restingBorder}`,
    },
    '&:not(.Mui-focused):hover fieldset': {
      backgroundColor: theme.palette.state.hoverBackground,
    },
    '&:not(.Mui-focused):not(.Mui-error):hover fieldset': {
      borderColor: theme.palette.state.hoverBorder,
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-input': {
        color: theme.palette.text.primary,
      },
      '& fieldset': {
        borderColor: theme.palette.text.primary,
      },
    },
  },
}));

export { StyledFormControl as FormControl };

import React, { FC, useEffect, useState } from 'react';
import { Search } from 'react-feather';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { Typography } from '@mui/material';
import emptyStateImage from 'app/assets/images/visualization/visualization-empty-state.svg';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { Popup } from 'app/shared/components/Popup/Popup';
import { LastJsonMessage, SOCKET_ENTITY, useSocket } from 'app/shared/hooks/useSocket';
import { StyledTextField } from 'app/mui/TextField';
import { AnalysisConfig, ChartDataType } from 'app/screens/Analysis/Analysis.types';
import { useVisualizationsMap } from 'app/screens/Analysis/Analysis.utils';
import { ConfigureVisualizationItem } from './ConfigureVisualizationItem/ConfigureVisualizationItem';
import { Configure, VisualisationType } from '../Configure';

type ConfigureVisualizationsProps = {
  analysisId: number;
};

export const ConfigureVisualizations: FC<ConfigureVisualizationsProps> = ({ analysisId }) => {
  const [selectedVisualization, setSelectedVisualization] = useState<
    AnalysisConfig | ChartDataType | undefined
  >(undefined);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);

  const [visualizationNameSearch, setVisualizationNameSearch] = useState('');
  const [visualizationName, setVisualizationName] = useState('');
  const [visualizationId, setVisualizationId] = useState<number | null>(null);
  const [visualizationType, setVisualizationType] = useState<VisualisationType | null>(null);

  const {
    dataLandscapeVisualizations,
    featureLandscapeVisualizations,
    scatterPlotVisualizations,
    histogramVisualizations,
    heatmapVisualizations,
    violinPlotVisualizations,
    deleteVisualization,
    setSelectedGraph,
    setSelectedChart,
    selectedGraph,
    selectedChart,
    clearSelection,
  } = useAnalysis();

  const handleSelectVisualization = (
    visualization: AnalysisConfig | ChartDataType,
    type: VisualisationType
  ): void => {
    const newVisualization = { ...visualization, type };
    if (type === VisualisationType.DATA_LANDSCAPE || type === VisualisationType.FEATURE_LANDSCAPE) {
      setSelectedGraph(newVisualization as AnalysisConfig);
    } else {
      setSelectedChart(newVisualization as ChartDataType);
    }
  };

  const { lastJsonMessage } = useSocket();

  useEffect(() => {
    if (lastJsonMessage) {
      const { type, payload } = lastJsonMessage as LastJsonMessage;
      const currentVisualization = payload as any;

      if (
        type === SOCKET_ENTITY.RETRIEVE_GRAPH &&
        selectedVisualization &&
        currentVisualization.id === selectedVisualization.id
      ) {
        setSelectedVisualization(currentVisualization);
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (selectedGraph) {
      setSelectedVisualization({ ...selectedGraph });
    } else if (selectedChart) {
      setSelectedVisualization({ ...selectedChart });
    }
  }, [selectedGraph, selectedChart]);

  useEffect(() => {
    if (selectedVisualization) {
      setIsConfigurationOpen(true);
    }
  }, [selectedVisualization]);

  const handleChangeSearchVisualization = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVisualizationNameSearch(event.target.value);
  };

  const handleCloseConfiguration = (): void => {
    setIsConfigurationOpen(false);
    setSelectedVisualization(undefined);
    setSelectedGraph(undefined);
    setSelectedChart(undefined);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setVisualizationId(null);
    setVisualizationType(null);
    setVisualizationName('');
  };

  const onDeleteVisualization = (id: number, type: VisualisationType) => {
    deleteVisualization(analysisId, id);
    clearSelection(id, type);
    closeDeleteDialog();
  };

  const onDelete = (id: number, name: string, type: VisualisationType) => {
    setVisualizationId(id);
    setVisualizationType(type);
    setVisualizationName(name);
    setIsDeleteDialogOpen(true);
  };

  const visualizationsMap = useVisualizationsMap();

  useEffect(() => {
    if (selectedVisualization) {
      const isVisualizationExists = (
        visualizationsMap[selectedVisualization.type] as { id: number }[]
      ).find((v) => v.id === selectedVisualization.id);

      if (!isVisualizationExists) {
        handleCloseConfiguration();
      }
    }
  }, [visualizationsMap, selectedVisualization]);

  const isEmpty =
    dataLandscapeVisualizations.length === 0 &&
    featureLandscapeVisualizations.length === 0 &&
    !scatterPlotVisualizations &&
    !histogramVisualizations &&
    !heatmapVisualizations &&
    !violinPlotVisualizations;

  return (
    <Box padding='0.1px' display='flex' flexDirection='column' height='100%'>
      {isConfigurationOpen && selectedVisualization ? (
        <Configure
          analysisId={analysisId}
          handleCloseConfiguration={handleCloseConfiguration}
          selectedVisualization={selectedVisualization}
        />
      ) : (
        <Box mt={3}>
          {isEmpty ? (
            <Box textAlign='center' px={8}>
              <Box
                component='img'
                src={emptyStateImage}
                mt={9}
                mb={3}
                alt='There are no visualizations in this analysis'
              />
              <Typography variant='body1' color='text.secondary'>
                There are no visualizations in this analysis yet
              </Typography>
            </Box>
          ) : (
            <>
              <Box px={4}>
                <StyledTextField
                  id='outlined-start-adornment'
                  placeholder='Search visualizations'
                  value={visualizationNameSearch}
                  onChange={handleChangeSearchVisualization}
                  size='small'
                  fullWidth
                  sx={{
                    mb: 1,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <ConfigureVisualizationItem
                visualizations={dataLandscapeVisualizations}
                title='Data Landscapes'
                type={VisualisationType.DATA_LANDSCAPE}
                nameSearch={visualizationNameSearch}
                handleOpenConfiguration={handleSelectVisualization}
                onDelete={onDelete}
              />
              <ConfigureVisualizationItem
                visualizations={featureLandscapeVisualizations}
                title='Feature Landscapes'
                type={VisualisationType.FEATURE_LANDSCAPE}
                nameSearch={visualizationNameSearch}
                handleOpenConfiguration={handleSelectVisualization}
                onDelete={onDelete}
              />
              <ConfigureVisualizationItem
                visualizations={scatterPlotVisualizations}
                title='Scatter Plots'
                type={VisualisationType.SCATTER_PLOT}
                nameSearch={visualizationNameSearch}
                handleOpenConfiguration={handleSelectVisualization}
                onDelete={onDelete}
              />
              <ConfigureVisualizationItem
                visualizations={histogramVisualizations}
                title='Histograms'
                type={VisualisationType.HISTOGRAM}
                nameSearch={visualizationNameSearch}
                handleOpenConfiguration={handleSelectVisualization}
                onDelete={onDelete}
              />
              <ConfigureVisualizationItem
                visualizations={heatmapVisualizations}
                title='Heatmaps'
                type={VisualisationType.HEATMAP}
                nameSearch={visualizationNameSearch}
                handleOpenConfiguration={handleSelectVisualization}
                onDelete={onDelete}
              />
              <ConfigureVisualizationItem
                visualizations={violinPlotVisualizations}
                title='Violin Plots'
                type={VisualisationType.VIOLIN_PLOT}
                nameSearch={visualizationNameSearch}
                handleOpenConfiguration={handleSelectVisualization}
                onDelete={onDelete}
              />
              {isDeleteDialogOpen &&
                !!visualizationName &&
                !!visualizationId &&
                !!visualizationType && (
                  <Popup
                    open={isDeleteDialogOpen}
                    onClose={closeDeleteDialog}
                    onConfirm={() => onDeleteVisualization(visualizationId, visualizationType)}
                    state='Delete'
                    title={`Delete ${visualizationName}?`}
                    description='You will not be able to recover it.'
                  />
                )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

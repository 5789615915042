import React, { FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { Eye, EyeOff, Mail } from 'react-feather';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { useAuthentication } from 'app/shared/hooks/useAuthentication';
import { TextFieldForm } from 'app/mui/TextFieldForm';
import { Button } from 'app/mui/Button';
import { IconButton } from 'app/mui/IconButton';
import { GradientBackground, AuthWrapper } from 'app/shared/styles/common';
import { Header } from 'app/shared/components/Header/Header';
import { Terms } from 'app/shared/components/Terms/Terms';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { useAppSelector } from 'app/store/hooks';
import { selectCurrentEmail } from 'app/store/modules/account';

interface Values {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string()
    .min(8, 'Password should be at least 8 characters long')
    .required('Password is required'),
});

export const Login: FC = () => {
  const { loading, login } = useAuthentication();
  const { navigateToForgotPassword } = useNavigation();
  const currentEmail = useAppSelector(selectCurrentEmail);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleSubmit = useCallback(
    async (values: Values, setErrors: (errors: FormikErrors<Values>) => void) => {
      try {
        await login(values.email, values.password);
      } catch (e) {
        setErrors({
          email: ' ',
          password: (e as Error).message,
        });
      }
    },
    [login]
  );

  return (
    <>
      <Header isTransparent />
      <GradientBackground>
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='text.secondary'
        >
          <AuthWrapper>
            <Typography component='h3' variant='h3' textAlign='center' color='primary.main'>
              Sign in
            </Typography>
            <Formik
              initialValues={{
                email: currentEmail || '',
                password: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                handleSubmit(values, setErrors).then(() => {
                  setSubmitting(false);
                });
              }}
            >
              {({ submitForm, isSubmitting, setFieldTouched }) => (
                <Form>
                  <Box mt={4} sx={{ height: 75 }}>
                    <Field
                      component={TextFieldForm}
                      name='email'
                      type='email'
                      label='Email'
                      placeholder='example@mail.com'
                      fullWidth
                      onBlur={() => setFieldTouched('email', true)}
                      onFocus={() => setFieldTouched('email', false)}
                      InputProps={{
                        endAdornment: <Mail size={22} />,
                      }}
                    />
                  </Box>
                  <Box mb={2}>
                    <Field
                      component={TextFieldForm}
                      type={showPassword ? 'text' : 'password'}
                      label='Password'
                      placeholder='********'
                      name='password'
                      onBlur={() => setFieldTouched('password', true)}
                      onFocus={() => setFieldTouched('password', false)}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            color='primary'
                            size='small'
                            sx={{
                              padding: 0,
                            }}
                          >
                            {showPassword ? <EyeOff /> : <Eye />}
                          </IconButton>
                        ),
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box display='flex' alignItems='center' justifyContent='space-between' my={4}>
                    <FormControlLabel control={<Checkbox value='remember' />} label='Remember me' />
                    <Link
                      component='a'
                      variant='body2'
                      fontWeight='bold'
                      onClick={navigateToForgotPassword}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Box>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    type='submit'
                    disabled={isSubmitting || loading}
                    onClick={submitForm}
                    fullWidth
                  >
                    Sign in
                  </Button>
                </Form>
              )}
            </Formik>
          </AuthWrapper>
        </Box>
        <Terms />
      </GradientBackground>
    </>
  );
};

import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Layout } from 'app/shared/components/Layout/Layout';
import { DATE_SORTING_FIELD } from 'app/shared/utils/date';
import { Order, SortSelect } from 'app/shared/components/SortSelect/SortSelect';
import { Sidebar } from 'app/shared/components/Sidebar/Sidebar';
import {
  PageWrapper,
  PageContent,
  EllipseBackground,
  ContentWrapper,
} from 'app/shared/styles/common';
import noResultsThumb from 'app/assets/images/thumbnails/no-results-projects.svg';
import { NoResults } from 'app/shared/components/NoResults/NoResults';
import { Header } from 'app/shared/components/Header/Header';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { useProjects } from './Projects.hooks';
import { ProjectsList } from './ProjectsList/ProjectsList';
import { ProjectActionsDialog } from './ProjectActionsDialog/ProjectActionsDialog';
import { Project, ProjectActionsPayload } from './Projects.types';

export type SelectedProject =
  | {
      id: number | undefined;
      name: string | undefined;
      description: string | undefined;
      is_private: boolean;
    }
  | undefined;

export const isPrivate = true;

export const Projects: FC = () => {
  const { layout, projects, getProjects, setLayout, createProject } = useProjects();
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState(DATE_SORTING_FIELD);
  const [selectedProject, setSelectedProject] = useState<SelectedProject>(undefined);

  const { navigateToProjectOverview } = useNavigation();

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenProjectModal = useCallback(() => {
    setIsProjectModalOpen(true);
  }, []);

  const closeProjectModal = useCallback(() => {
    setIsProjectModalOpen(false);
  }, []);

  const handleSave = async (values: ProjectActionsPayload): Promise<void> => {
    const response = (await createProject({
      name: values.name,
      is_private: values.type,
      description: values.description,
      onSuccess: closeProjectModal,
    })) as Project;

    if (response.id) {
      navigateToProjectOverview(response.id);
    }
  };

  const onViewChange = useCallback(
    (view: string) => {
      setLayout(view);
    },
    [setLayout]
  );

  return (
    <>
      <Header withUser />
      <PageWrapper>
        <Sidebar />
        <ContentWrapper>
          <PageContent>
            <EllipseBackground />
            <Layout
              heading='Projects'
              onViewChange={onViewChange}
              type={layout}
              onButtonClick={handleOpenProjectModal}
              buttonTitle='Create Project'
              isEmpty={projects.length === 0}
              sortSelect={
                projects.length > 0 ? (
                  <Box mr={5}>
                    <SortSelect
                      order={order}
                      orderBy={orderBy}
                      setOrder={setOrder}
                      setOrderBy={setOrderBy}
                    />
                  </Box>
                ) : undefined
              }
            >
              {projects.length > 0 ? (
                <ProjectsList
                  order={order}
                  orderBy={orderBy}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                />
              ) : (
                <NoResults
                  thumb={noResultsThumb}
                  title='Get started by creating Project '
                  buttonText='Create Project'
                  onButtonClick={handleOpenProjectModal}
                />
              )}
            </Layout>
          </PageContent>
        </ContentWrapper>
      </PageWrapper>
      <ProjectActionsDialog
        title='Create Project'
        open={isProjectModalOpen}
        id={0}
        name=''
        description=''
        is_private={isPrivate}
        handleClose={closeProjectModal}
        onSubmit={handleSave}
      />
    </>
  );
};

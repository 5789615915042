import React, { FC, useEffect, useMemo, useState } from 'react';
import type { SortColumn } from 'react-data-grid';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { useAlert } from 'app/shared/hooks/useAlert';
import { SortIcon } from './components/SortIcon';
import { CheckboxFormatter } from './components/CheckboxFormatter';
import { featureExplorerRowKey, getSortedRows } from './Explorer.utils';
import './Explorer.scss';
import { useAnalysis } from '../Analysis.hooks';
import { SELECTION_SOURCES } from '../Analysis.types';

type FeatureExplorerProps = {
  data: any;
  height?: number;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => Promise<void>;
  selectedRows: number[];
  setSelectedRows: (selectedRows: number[]) => void;
};

const columns = [
  SelectColumn,
  {
    key: 'id',
    name: 'ID',
    sortable: true,
  },
  {
    key: 'feature_name',
    name: 'Feature name',
    width: 120,
  },
  {
    key: 'type',
    name: 'Type',
    width: 120,
  },
  {
    key: 'count',
    name: 'Count',
    width: 120,
    sortable: true,
  },
  {
    key: 'unique',
    name: 'Unique',
    formatter: ({ row }: any) => (row.unique ? row.unique : '-'),
  },
  {
    key: 'mode',
    name: 'Mode',
    width: 120,
  },
  {
    key: 'freq',
    name: 'Freq',
    formatter: ({ row }: any) => (row.unique ? row.unique : '-'),
  },
  {
    key: 'mean',
    name: 'Mean',
    width: 120,
    sortable: true,
  },
  {
    key: 'std',
    name: 'Std',
    width: 150,
    sortable: true,
  },
  {
    key: 'min',
    name: 'Min',
    width: 120,
    sortable: true,
  },
  {
    key: 'percent_25',
    name: '25%',
    width: 120,
    sortable: true,
  },
  {
    key: 'percent_50',
    name: '50%',
    width: 120,
    sortable: true,
  },
  {
    key: 'percent_75',
    name: '75%',
    width: 120,
    sortable: true,
  },
  {
    key: 'max',
    name: 'Max',
    width: 120,
    sortable: true,
  },
  {
    key: 'non_nan',
    name: 'Non-0 count',
    width: 120,
    sortable: true,
  },
  {
    key: 'nan',
    name: 'NaN count',
    width: 120,
    sortable: true,
  },
];

export const featureExplorerColumns = [
  {
    id: 'count',
    feature_name: 'Count',
    type: 'numeric',
  },
  {
    id: 'mean',
    feature_name: 'Mean',
    type: 'numeric',
  },
  {
    id: 'std',
    feature_name: 'Std',
    type: 'numeric',
  },
  {
    id: 'min',
    feature_name: 'Min',
    type: 'numeric',
  },
  {
    id: 'percent_25',
    feature_name: '25%',
    type: 'numeric',
  },
  {
    id: 'percent_50',
    feature_name: '50%',
    type: 'numeric',
  },
  {
    id: 'percent_75',
    feature_name: '75%',
    type: 'numeric',
  },
  {
    id: 'max',
    feature_name: 'Max',
    type: 'numeric',
  },
  {
    id: 'non_nan',
    feature_name: 'Non-0 count',
    type: 'numeric',
  },
  {
    id: 'nan',
    feature_name: 'NaN count',
    type: 'numeric',
  },
];

export const FeatureExplorer: FC<FeatureExplorerProps> = ({
  data,
  height,
  onScroll,
  selectedRows,
  setSelectedRows,
}) => {
  const [rows, setRows] = useState<any[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const { featureSelectionSource, setFeaturesSelectionSource } = useAnalysis();

  const { showErrorMessage } = useAlert();

  useEffect(() => {
    setRows(data.rows);
  }, [data.rows]);

  const sortedRows = getSortedRows(rows, sortColumns);

  const selectedRowsSet = useMemo(() => new Set(selectedRows), [selectedRows]);

  const handleSelectedRowsChange = (rowsSet: Set<number>): void => {
    setSelectedRows(Array.from(rowsSet));
    setFeaturesSelectionSource({
      source: SELECTION_SOURCES.EXPLORER,
    });
  };

  const isChangeEnabled = (isShiftKey?: boolean): boolean => {
    const isExplorerSource = featureSelectionSource?.source === SELECTION_SOURCES.EXPLORER;
    const hasSelected = selectedRows.length;

    if ((!isExplorerSource || !hasSelected) && isShiftKey) {
      showErrorMessage({
        title: 'Select features on explorer before using bulk selection.',
      });

      return false;
    }

    return true;
  };

  const CheckboxFormatterComponent = CheckboxFormatter(isChangeEnabled);

  return (
    <DataGrid
      columns={columns}
      rows={sortedRows}
      rowKeyGetter={featureExplorerRowKey}
      onRowsChange={setRows}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
      selectedRows={selectedRowsSet}
      onSelectedRowsChange={handleSelectedRowsChange}
      onScroll={onScroll}
      renderers={{ sortStatus: SortIcon, checkboxFormatter: CheckboxFormatterComponent }}
      className='data-explorer rdg-light fill-grid'
      style={{ height }}
    />
  );
};

import { useCallback } from 'react';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { useAlert } from 'app/shared/hooks/useAlert';
import { DATASET_STATUSES } from 'app/shared/enum/dataset';
import {
  fetchDatasets,
  fetchDatasetSamples,
  selectDataLibraryLayout,
  selectDatasets,
  selectDatasetSamples,
  setDataLibraryLayout,
} from 'app/store/modules/dataset';
import type { Dataset, DatasetPayload } from './DataLibrary.types';

interface IUseDataLibrary {
  datasets: Dataset[];
  datasetSamples: Dataset[];
  getDataset: (id: number) => Promise<unknown>;
  getDatasets: () => void;
  getDatasetSamples: () => void;
  deleteDataset: (id: number, onDatasetDeleted?: () => void) => void;
  cancelUpload: (id: number) => void;
  editDataset: (data: DatasetPayload) => Promise<unknown>;
  showPublishedDataset: (status: string) => boolean;
  showFailedDataset: (status: string) => boolean;
  showProcessing: (status: string) => boolean;
  showMetadataExtraction: (status: string) => boolean;
  showTransformExtraction: (status: string) => boolean;
  layout: string;
  setLayout: (type: string) => void;
}

export const useDataLibrary = (): IUseDataLibrary => {
  const { showSuccessMessage } = useAlert();
  const dispatch = useAppDispatch();

  const layout = useAppSelector(selectDataLibraryLayout);
  const datasets = useAppSelector(selectDatasets);
  const datasetSamples = useAppSelector(selectDatasetSamples);

  const getDataset = useCallback(async (id: number) => {
    const response = await api
      .get(`dataset/${id}`, {
        headers: getCommonHeaders(),
      })
      .json();

    return response;
  }, []);

  const getDatasets = useCallback(() => {
    dispatch(fetchDatasets());
  }, [dispatch]);

  const getDatasetSamples = useCallback(() => {
    dispatch(fetchDatasetSamples());
  }, [dispatch]);

  const onDeleteDataset = useCallback(async (id: number, onDatasetDeleted?: () => void) => {
    try {
      await api
        .delete(`dataset/?ids=${id}`, {
          headers: getCommonHeaders(),
        })
        .json();

      getDatasets();

      if (onDatasetDeleted) {
        onDatasetDeleted();
      } else {
        showSuccessMessage({
          title: 'Dataset has been deleted.',
        });
      }
    } catch (error) {
      return error;
    }
  }, []);

  const onCancelUpload = useCallback(async (id: number) => {
    try {
      await api
        .delete(`dataset/${id}/upload-cancel`, {
          headers: getCommonHeaders(),
        })
        .json();

      showSuccessMessage({
        title: 'Dataset upload canceled',
      });
    } catch (error) {
      return error;
    }
  }, []);

  const onEditDataset = useCallback(async (data: DatasetPayload) => {
    try {
      const { id, name, description } = data;

      await api
        .put(`dataset/${id}`, {
          headers: getCommonHeaders(),
          json: {
            name,
            description,
          },
        })
        .json();

      getDatasets();

      showSuccessMessage({
        title: 'Dataset has been edited',
      });
    } catch (error) {
      return error;
    }
  }, []);

  const setLayout = useCallback(
    (type: string) => {
      dispatch(setDataLibraryLayout(type));
    },
    [dispatch]
  );

  const showPublishedDataset = (status: string): boolean =>
    status === DATASET_STATUSES.METADATA_FINISHED;
  // showPublishedDataset has a temporary value and must be changed.

  const showFailedDataset = (status: string): boolean => status === DATASET_STATUSES.UPLOAD_FAILED;

  const showProcessing = (status: string): boolean =>
    status === DATASET_STATUSES.UPLOADED || status === DATASET_STATUSES.PROCESSING;

  const showMetadataExtraction = (status: string): boolean =>
    status === DATASET_STATUSES.METADATA_STARTED || status === DATASET_STATUSES.METADATA_FINISHED;

  const showTransformExtraction = (status: string): boolean =>
    status === DATASET_STATUSES.TRANSFORM_STARTED || status === DATASET_STATUSES.TRANSFORM_FINISHED;

  return {
    datasets,
    datasetSamples,
    getDataset,
    getDatasets,
    getDatasetSamples,
    layout,
    setLayout,
    deleteDataset: onDeleteDataset,
    cancelUpload: onCancelUpload,
    editDataset: onEditDataset,
    showPublishedDataset,
    showFailedDataset,
    showProcessing,
    showMetadataExtraction,
    showTransformExtraction,
  };
};

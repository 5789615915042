import React, { FC } from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
};

export const Plus: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M9 16H23' stroke='#122239' strokeWidth='2' strokeLinecap='round' />
    <path d='M16 9L16 23' stroke='#122239' strokeWidth='2' strokeLinecap='round' />
  </svg>
);

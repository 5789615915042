import {
  ChartConfigType,
  GroupType,
  LandscapeVisualizationType,
} from 'app/screens/Analysis/Analysis.types';
import {
  Row,
  SourceDataLandscapesOptions,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { VisualisationType } from '../Configure/Configure';

export const getDefaultDataPoints = (
  groups: GroupType[],
  visualization?: ChartConfigType,
  groupId?: number | null
): string => {
  if (groupId) {
    const selectedGroup = groups.find((row) => row?.id === groupId);
    if (selectedGroup) {
      return selectedGroup.name;
    }
    return 'All';
  }
  if (visualization?.data_points.length === 0) {
    return 'All';
  }
  if (visualization) {
    return visualization.data_points.length.toString();
  }
  return 'All';
};

export const getDefaultLandscapeDataPoints = (
  groupId: number | undefined | null,
  groups: GroupType[],
  visualization?: LandscapeVisualizationType
): string => {
  if (visualization && groupId === null) {
    if (visualization.rows.length === 0) {
      return 'All';
    }
    return visualization.rows.length.toString();
  }
  if (groupId) {
    const selectedGroup = groups?.find((row) => row?.id === groupId);
    if (selectedGroup) {
      return selectedGroup.name;
    }
    return 'All';
  }
  if (visualization?.rows.length === 0) {
    return 'All';
  }
  if (visualization) {
    return visualization.rows.length.toString();
  }
  return 'All';
};

export const getDefaultLandscapeFeatures = (
  groupId: number | undefined | null,
  groups: GroupType[],
  visualization?: LandscapeVisualizationType
): string => {
  if (visualization && groupId === null) {
    if (visualization.cols.length === 0) {
      return 'All';
    }
    return visualization.cols.length.toString();
  }
  if (groupId) {
    const selectedGroup = groups?.find((row) => row?.id === groupId);
    if (selectedGroup) {
      return selectedGroup.name;
    }
    return 'All';
  }
  if (visualization?.cols.length === 0) {
    return 'All';
  }
  if (visualization) {
    return visualization.cols.length.toString();
  }
  return 'All';
};

export const getDefaultSelectValue = (
  groupId: number | null | undefined,
  subset: VisualizationRow[] | undefined,
  visualization: ChartConfigType | LandscapeVisualizationType | undefined,
  selectedRow?: Row
) => {
  const defaultDataPointSubsetOption = subset?.find(
    (row) => row?.feature_name === SourceDataLandscapesOptions.CUSTOM
  );
  if (selectedRow) {
    return selectedRow;
  }
  if (!visualization && subset) {
    return subset[0];
  }
  if (groupId === null) {
    return defaultDataPointSubsetOption;
  }
  if (groupId) {
    return subset?.find((row) => row?.id === groupId);
  }

  return defaultDataPointSubsetOption;
};

export const getDefaultCustomDataPoints = (
  rowGroupId: number | null | undefined,
  dataPoints: any[] | undefined,
  visualization: ChartConfigType | undefined
) => {
  if (!visualization || rowGroupId !== null || rowGroupId) {
    return [];
  }
  if (rowGroupId === null && visualization?.data_points.length === 0) {
    return dataPoints;
  }
  if (rowGroupId === null && visualization?.data_points.length === dataPoints?.length) {
    return dataPoints;
  }
  if (rowGroupId === null && visualization?.data_points.length > 0) {
    return dataPoints?.filter((row) => row && visualization?.data_points.includes(row.id));
  }
  if (
    !rowGroupId &&
    dataPoints?.filter((row) => row && visualization?.data_points.includes(row.id)).length === 0
  ) {
    return dataPoints;
  }
  return dataPoints?.filter((row) => row && visualization?.data_points.includes(row.id));
};

export const getDefaultCustomLandscapeDataPoints = (
  rowGroupId: number | null | undefined,
  dataPoints: any[] | undefined,
  visualization: LandscapeVisualizationType | undefined,
  customRows?: number[]
) => {
  if (customRows && customRows.length > 0) {
    return dataPoints?.filter((row) => row && customRows.includes(row.id));
  }
  if (!visualization || rowGroupId !== null || rowGroupId) {
    return [];
  }
  if (rowGroupId === null && visualization?.rows.length === 0) {
    return dataPoints;
  }
  if (rowGroupId === null && visualization?.rows.length === dataPoints?.length) {
    return dataPoints;
  }
  if (rowGroupId === null && visualization?.rows.length > 0) {
    return dataPoints?.filter((row) => row && visualization?.rows.includes(row.id));
  }
  if (
    !rowGroupId &&
    dataPoints?.filter((row) => row && visualization?.rows.includes(row.id)).length === 0
  ) {
    return dataPoints;
  }
  return dataPoints?.filter((row) => row && visualization?.rows.includes(row.id));
};

export const getDefaultCustomLandscapeFeatures = (
  colsGroupId: number | null | undefined,
  features: any[] | undefined,
  visualization: LandscapeVisualizationType | undefined,
  customRows?: number[]
) => {
  if (customRows && customRows.length > 0) {
    return features?.filter((feature) => customRows.includes(feature.id));
  }
  if (!visualization || colsGroupId !== null || colsGroupId) {
    return [];
  }
  if (colsGroupId === null && visualization?.cols.length === 0) {
    return features;
  }
  if (colsGroupId === null && visualization?.cols.length === features?.length) {
    return features;
  }
  if (colsGroupId === null && visualization?.cols.length > 0) {
    return features?.filter((row) => row && visualization?.cols.includes(row.id));
  }
  if (
    !colsGroupId &&
    features?.filter((row) => row && visualization?.cols.includes(row.id)).length === 0
  ) {
    return features;
  }
  return features?.filter((row) => row && visualization?.cols.includes(row.id));
};

export const mapperMatrixConfigRows = async (
  dataRow: VisualizationRow | undefined,
  customDataRows: number[] | undefined,
  analysisId: number,
  selectedDataRows: any[],
  getGroup: (analysis_id: number, group_id: number) => Promise<unknown>,
  totalRows: number
) => {
  if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA) {
    return Array.from(Array(totalRows).keys());
  }
  if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA) {
    return selectedDataRows;
  }
  if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM) {
    return customDataRows;
  }
  if (dataRow) {
    const response = (await getGroup(analysisId, dataRow.id)) as GroupType;
    return response.rows;
  }
  return [];
};

export const mapperMatrixLandscapeRows = async (
  dataRow: VisualizationRow | undefined,
  customDataRows: number[] | undefined,
  analysisId: number,
  selectedDataRows: any[],
  getGroup: (analysis_id: number, group_id: number) => Promise<unknown>,
  dataLandscapeVisualization: LandscapeVisualizationType | undefined,
  featureLandscapeVisualization: LandscapeVisualizationType | undefined,
  type: string,
  totalRows: number
) => {
  if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA) {
    return Array.from(Array(totalRows).keys());
  }
  if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA) {
    return selectedDataRows;
  }
  if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM) {
    return customDataRows;
  }
  if (dataRow) {
    const response = (await getGroup(analysisId, dataRow.id)) as GroupType;
    return response.rows;
  }
  if (type === VisualisationType.DATA_LANDSCAPE) {
    if (dataLandscapeVisualization) {
      return dataLandscapeVisualization.rows;
    }
    return [];
  }
  if (type === VisualisationType.FEATURE_LANDSCAPE) {
    if (featureLandscapeVisualization) {
      return featureLandscapeVisualization.rows;
    }
    return [];
  }
  return [];
};

export const getSelectedLandscapeFeatures = (
  featureRow: VisualizationRow,
  selectedFeatures: number[],
  customFeatureRows: number[] | undefined
): string => {
  if (featureRow.feature_name === SourceDataLandscapesOptions.ALL_FEATURES && featureRow.id === 0) {
    return 'All';
  }
  if (featureRow.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES) {
    return `${selectedFeatures.length} selected`;
  }
  if (featureRow.feature_name === SourceDataLandscapesOptions.CUSTOM && customFeatureRows) {
    return `${customFeatureRows.length} selected`;
  }
  return `${featureRow.feature_name} group`;
};

export const getSelectedLandscapeDataPoints = (
  dataRow: VisualizationRow,
  selectedDataRows: number[],
  customDataRows: number[] | undefined
): string => {
  if (dataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA) {
    return 'All';
  }
  if (dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA) {
    return `${selectedDataRows.length} selected`;
  }
  if (dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM && customDataRows) {
    return `${customDataRows.length} selected`;
  }
  return `${dataRow.feature_name} group`;
};

import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Check,
  ChevronDown,
  ChevronRight,
  Download,
  EyeOff,
  Plus,
  Search,
  Settings,
  Trash2,
} from 'react-feather';
import { Box, Divider, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from 'app/mui/Tooltip';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';
import { NestedMenu } from 'app/shared/components/NestedMenu/NestedMenu';
import { shortName } from 'app/shared/utils/visualization';
import { StyledTextField } from 'app/mui/TextField';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { Actions, ListItem, MenuButton } from './VisualizationPan.styles';
import { VisualisationType } from '../AnalysisSidebar/Configure/Configure';
import { useAnalysis } from '../Analysis.hooks';
import { AnalysisConfig, ChartDataType, PanMenuListItem } from '../Analysis.types';

const MAX_VISIBLE_LANDSCAPES = 5;

type VisualizationMenuProps = {
  panelId: string;
  visualizationId?: number;
  visualizationType?: VisualisationType;
  openVisualizationDialog: (panel: string) => void;
  onDelete: () => void;
  setMetadata: (metadata: AnalysisConfig | ChartDataType | null) => void;
  setSvgGenerating: (value: boolean) => void;
};

export const VisualizationMenu: FC<VisualizationMenuProps> = ({
  panelId,
  visualizationId,
  visualizationType,
  openVisualizationDialog,
  onDelete,
  setMetadata,
  setSvgGenerating,
}) => {
  const [visualizationNameSearch, setVisualizationNameSearch] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorEl);
  const { project } = useProjects();

  const theme = useTheme();

  const {
    panConfig,
    currentAnalysis,
    updatePanConfig,
    setSelectedGraph,
    setSelectedChart,
    dataLandscapeVisualizations,
    featureLandscapeVisualizations,
    scatterPlotVisualizations,
    clearSelection,
    exportJSON,
  } = useAnalysis();

  const visualizationsMap: Partial<Record<VisualisationType, AnalysisConfig[] | ChartDataType[]>> =
    useMemo(
      () => ({
        [VisualisationType.DATA_LANDSCAPE]: dataLandscapeVisualizations,
        [VisualisationType.FEATURE_LANDSCAPE]: featureLandscapeVisualizations,
        [VisualisationType.SCATTER_PLOT]: scatterPlotVisualizations,
      }),
      [dataLandscapeVisualizations, featureLandscapeVisualizations, scatterPlotVisualizations]
    );

  useEffect(() => {
    setVisualizationNameSearch('');
  }, [openMenu]);

  const handleChangeSearchVisualization = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setVisualizationNameSearch(event.target.value);
  };

  const getVisualizations = useCallback(
    (type: VisualisationType): AnalysisConfig[] | ChartDataType[] => {
      const isLandscape = [
        VisualisationType.DATA_LANDSCAPE,
        VisualisationType.FEATURE_LANDSCAPE,
      ].includes(type);

      const visualList = (
        isLandscape ? visualizationsMap : currentAnalysis?.config?.visual?.chart
      ) as Record<VisualisationType, AnalysisConfig[] | ChartDataType[]>;

      return visualList?.[type] || [];
    },
    [currentAnalysis?.config?.visual, visualizationsMap]
  );

  const getVisualizationMetadata = useCallback(
    (type: VisualisationType): PanMenuListItem[] => {
      const visualList = getVisualizations(type);

      return (
        visualList.map(({ id, name, created_at, updated_at }: AnalysisConfig | ChartDataType) => ({
          type,
          id,
          name,
          created_at,
          updated_at,
        })) || []
      );
    },
    [getVisualizations]
  );

  const visuals = useMemo<PanMenuListItem[]>(
    () =>
      [
        VisualisationType.DATA_LANDSCAPE,
        VisualisationType.FEATURE_LANDSCAPE,
        VisualisationType.SCATTER_PLOT,
        VisualisationType.HISTOGRAM,
        VisualisationType.VIOLIN_PLOT,
        VisualisationType.HEATMAP,
      ]
        .map(getVisualizationMetadata)
        .flat(),
    [getVisualizationMetadata]
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const isEmptyState = !visualizationId || !visualizationType;
  const isEmptyAnalysis = !visuals.length;

  const currentVisualization = useMemo<AnalysisConfig | ChartDataType | null>(() => {
    if (visualizationType && visualizationId) {
      const list = getVisualizations(visualizationType) as any[];

      return list.find((v: { id: number }) => v.id === visualizationId);
    }

    return null;
  }, [getVisualizations, visualizationId, visualizationType]);

  useEffect(() => {
    setMetadata(currentVisualization);
  }, [currentVisualization]);

  const handleMenuClose = () => setMenuAnchorEl(null);

  const openDialog = () => {
    openVisualizationDialog(panelId);

    handleMenuClose();
  };

  const onMenuClick = (event: MouseEvent<HTMLDivElement>): void => {
    if (!isEmptyAnalysis) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      openDialog();
    }
  };

  const handleHide = () => {
    updatePanConfig(currentAnalysis.id, panelId);
    clearSelection(currentVisualization?.id, currentVisualization?.type);

    handleMenuClose();
  };

  const handleVisualizationChange = (config: PanMenuListItem) => {
    const isCurrentVisualization = config.id === visualizationId;

    if (!isCurrentVisualization) {
      const isCopy = panConfig.some(({ id }) => id === config.id);
      const visualization = (visualizationsMap[config.type] as any[])?.find(
        (v) => v.id === config.id
      );
      const coloring = visualization ? { coloring: visualization.config.coloring } : {};

      updatePanConfig(currentAnalysis.id, panelId, {
        id: config.id,
        type: config.type,
        ...(isCopy ? { is_copy: isCopy } : {}),
        ...(coloring && !isCopy ? coloring : {}),
      });

      clearSelection(currentVisualization?.id, currentVisualization?.type);
    }

    handleMenuClose();
  };

  const handleDelete = () => {
    if (currentVisualization) {
      onDelete();
    }

    handleMenuClose();
  };

  const handleModify = () => {
    if (!currentVisualization) {
      handleMenuClose();

      return;
    }

    const newVisualization = { ...currentVisualization, type: visualizationType };

    if (
      visualizationType === VisualisationType.DATA_LANDSCAPE ||
      visualizationType === VisualisationType.FEATURE_LANDSCAPE
    ) {
      setSelectedGraph(newVisualization as AnalysisConfig);
      setSelectedChart(undefined);
    } else {
      setSelectedChart(newVisualization as ChartDataType);
      setSelectedGraph(undefined);
    }

    handleMenuClose();
  };

  const isDisabled = (config: PanMenuListItem): boolean => {
    const landscapeTypes = [VisualisationType.DATA_LANDSCAPE, VisualisationType.FEATURE_LANDSCAPE];
    const isLandscapeItem = landscapeTypes.includes(config.type);
    const isCurrentLandscape = visualizationType && landscapeTypes.includes(visualizationType);
    const numberOfVisibleLandscapes = panConfig.filter(
      (pan) => pan?.type && landscapeTypes.includes(pan.type)
    ).length;

    return (
      isLandscapeItem && !isCurrentLandscape && numberOfVisibleLandscapes >= MAX_VISIBLE_LANDSCAPES
    );
  };

  const getTooltipText = (config: PanMenuListItem) => {
    const isDisabledItem = isDisabled(config);

    return isDisabledItem
      ? 'No more than 5 landscapes can be displayed on canvas simultaneously'
      : config.name || 'Unnamed';
  };

  const createButtonTitle = isEmptyState ? 'Create New' : 'Replace with New';

  const visualizationsList = visuals?.filter((visualization: PanMenuListItem) =>
    visualization.name?.toLowerCase().includes(visualizationNameSearch.toLowerCase())
  );

  const saveToSVG = () => {
    setSvgGenerating(true);

    handleMenuClose();
  };

  const saveToJson = () => {
    if (currentVisualization) {
      exportJSON({
        analysisId: currentAnalysis.id,
        visualizationId: currentVisualization.id,
        visualizationName: currentVisualization.name,
        projectName: project.name,
        analysisName: currentAnalysis.name,
      });
    }

    handleMenuClose();
  };

  const isLandscape =
    visualizationType &&
    [VisualisationType.DATA_LANDSCAPE, VisualisationType.FEATURE_LANDSCAPE].includes(
      visualizationType
    );

  return (
    <>
      <MenuButton isEmpty={isEmptyState} onClick={onMenuClick}>
        {!!currentVisualization && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: theme.palette.background.default,
              padding: '4px 8px 4px 8px',
              height: '30px',
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                marginRight: '5px',
              }}
            >
              <Tooltip title={currentVisualization.name} placement='bottom-start'>
                <Typography color='text.primary' fontWeight={500} noWrap className='break-text'>
                  {shortName(currentVisualization.name, 250, true)}
                </Typography>
              </Tooltip>
            </Box>

            <ChevronDown width={18} height={18} color={theme.palette.primary.main} />
          </Box>
        )}
        {isEmptyState && !isEmptyAnalysis && (
          <>
            <Box marginRight='5px'>Add Visualization</Box>
            <ChevronDown width={18} height={18} />
          </>
        )}
        {isEmptyAnalysis && (
          <>
            <Plus width={18} height={18} />
            <Box marginLeft='5px'>Create Visualization</Box>
          </>
        )}
      </MenuButton>
      <DotsMenu
        className='visualizationList'
        anchorEl={menuAnchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Actions>
          <Box paddingY={1} paddingLeft={2}>
            <Typography variant='subtitle1'>Select from Existing</Typography>
          </Box>
          <Box px={2}>
            <StyledTextField
              id='outlined-start-adornment'
              placeholder='Search visualizations'
              value={visualizationNameSearch}
              onKeyDown={onKeyDown}
              onChange={handleChangeSearchVisualization}
              size='small'
              sx={{
                mb: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box overflow='auto' maxHeight={130}>
            {visualizationsList.map((v) => (
              <Tooltip key={v.id} title={getTooltipText(v)} placement='left'>
                <Box>
                  <ListItem
                    className='list'
                    isSelected={v.id === visualizationId}
                    onClick={() => handleVisualizationChange(v)}
                    disabled={isDisabled(v)}
                  >
                    <span className='text'>{shortName(v.name, 195) || 'Unnamed'}</span>
                    {v.id === visualizationId && <Check color='#1CB359' />}
                  </ListItem>
                </Box>
              </Tooltip>
            ))}
          </Box>
          <Divider variant='middle' />
          <ListItem onClick={openDialog}>
            <Plus />
            <span className='text'>{createButtonTitle}</span>
          </ListItem>
          {!!currentVisualization && (
            <>
              <ListItem onClick={handleModify}>
                <Settings />
                <span className='text'>Modify</span>
              </ListItem>
              <ListItem onClick={handleHide}>
                <EyeOff />
                <span className='text'>Hide from Canvas</span>
              </ListItem>
              <NestedMenu
                parentMenuOpen={openMenu}
                menuClassName='visualizationList'
                MenuButton={
                  <ListItem>
                    <Download />
                    <span className='text'>Export to...</span>
                    <Box marginLeft='auto'>
                      <ChevronRight />
                    </Box>
                  </ListItem>
                }
              >
                <Actions>
                  <ListItem onClick={saveToSVG}>
                    <span className='text'>Scalable Vector Graphics (.svg)</span>
                  </ListItem>
                  {isLandscape && (
                    <ListItem onClick={saveToJson}>
                      <span className='text'>JavaScript Object Notation (.json)</span>
                    </ListItem>
                  )}
                </Actions>
              </NestedMenu>
              <Divider variant='middle' />
              <ListItem className='delete' onClick={handleDelete}>
                <Trash2 />
                <span className='text'>Delete</span>
              </ListItem>
            </>
          )}
        </Actions>
      </DotsMenu>
    </>
  );
};

import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import { BoxTypeMap } from '@mui/material/Box/Box';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Typography from '@mui/material/Typography';
import { Button } from 'app/mui/Button';

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ColorLegendWrapper = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  zIndex: 10,
});

export const DataBoundsBlock = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: '-6px',
  marginTop: '-6px',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'space-between',
}));

export const LegendBlock = styled(Box)({
  display: 'flex',
  alignItems: 'stretch',
});

export const LegendButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isCategories',
})<{ isCategories?: boolean }>(({ theme, isCategories }) => ({
  padding: '4px 8px',
  backgroundColor: theme.palette.legend.background,
  transition: 'background-color 0.15s',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: isCategories ? '4px 0 0 4px' : '4px',
  '&.MuiButton-text:hover': {
    color: 'initial',
    backgroundColor: theme.palette.legend.background,
  },
})) as ExtendButtonBase<ButtonTypeMap<{ isCategories: boolean }>>;

export const ColorPickerButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  padding: '4px',
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.legend.hover,
  transition: 'background-color 0.15s',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0 4px 4px 0',
  '&.MuiButton-root.MuiButton-sizeSmall': {
    minWidth: 'auto',
  },
  '&.MuiButton-text': {
    color: isActive ? theme.palette.common.white : theme.palette.primary.main,
    '&:hover': {
      color: isActive ? theme.palette.common.white : theme.palette.primary.main,
      backgroundColor: isActive ? theme.palette.primary.main : theme.palette.legend.hover,
    },
  },
})) as ExtendButtonBase<ButtonTypeMap<{ isActive: boolean }>>;

export const LineColorBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '120px',
  background: theme.palette.common.white,
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.16)',
  borderRadius: '4px',
  overflow: 'hidden',
}));

export const CategoriesColorBlock = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  width: '192px',
  background: theme.palette.common.white,
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.16)',
  borderRadius: '4px',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

export const ColorCircle = styled(Box)<{ borderColor?: string }>(
  ({ borderColor = 'transparent' }) => ({
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    flexShrink: 0,
    border: `2px solid ${borderColor}`,
  })
) as OverridableComponent<BoxTypeMap<{ borderColor?: string }>>;

export const ColorPickerBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '253px',
  background: theme.palette.common.white,
  boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.16)',
  borderRadius: '4px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const ColorSelectButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  transition: 'border-color 0.15s',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: isActive ? theme.palette.primary.main : theme.palette.action.disabledBackground,
  '&.MuiButton-root.MuiButton-sizeSmall': {
    minWidth: 'auto',
  },
  '&.MuiButton-text:hover': {
    backgroundColor: theme.palette.blocksBackground.hover,
  },
})) as ExtendButtonBase<ButtonTypeMap<{ isActive: boolean }>>;

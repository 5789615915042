import React, { FC, memo, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Drawer } from 'app/mui/Drawer';
import { formatDate } from 'app/shared/utils/date';
import { Status } from 'app/shared/components/Status/Status';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { formatBytes } from 'app/shared/utils/formatBytes';
import { StatusEnum } from 'app/shared/enum/status';
import { ProjectCardPreview } from 'app/shared/components/ProjectCardPreview/ProjectCardPreview';
import { PreviewCircular } from 'app/mui/CircularProgress';
import { ShowMoreButton } from 'app/shared/components/ShowMoreButton/ShowMoreButton';
import { Tooltip } from 'app/mui/Tooltip';
import { useDataLibrary } from '../DataLibrary.hooks';
import { Dataset } from '../DataLibrary.types';

const DESCRIPTION_LIMIT = 90;

export const DrawerSubHeading = styled('strong')(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(3, 0, 2),
  fontSize: '1.2rem',
  fontWeight: '400',
  color: theme.palette.text.secondary,
}));

export const DrawerItem = styled(Box)(({ theme }) => ({
  boxShadow: 'inset 0px -1px 0px rgba(18, 34, 57, 0.12)',
  paddingBottom: theme.spacing(1),
}));

export type DatasetPreviewProps = {
  open: boolean;
  onClose: () => void;
  datasetId?: number;
  projectId?: number;
  isSample: boolean;
};

const DataLibraryPreview: FC<DatasetPreviewProps> = ({ open, onClose, datasetId, isSample }) => {
  const { getDataset, datasetSamples, getDatasetSamples, showPublishedDataset, showFailedDataset } =
    useDataLibrary();

  const [dataset, setDataset] = useState<Dataset | undefined>(undefined);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isSample) {
      getDatasetSamples();
    }
  }, [isSample]);

  useEffect(() => {
    async function getDatasetPreview(dataset_id: number): Promise<void> {
      try {
        const response = (await getDataset(dataset_id)) as Dataset;
        setDataset(response);
      } catch (error) {
        console.error(error);
      }
    }

    if (datasetId && !isSample) {
      getDatasetPreview(datasetId);
    }
    if (datasetId && isSample) {
      const sampleDataset = datasetSamples.filter((n) => n.id === datasetId)[0];
      setDataset(sampleDataset);
    }
  }, [datasetId, open, isSample, getDataset]);

  return (
    <Drawer title={dataset ? dataset.name : ''} open={open} onClose={onClose}>
      {dataset ? (
        <>
          <DrawerItem>
            <DrawerSubHeading>Status:</DrawerSubHeading>
            <Box>
              <Status
                status={
                  showPublishedDataset(dataset.status)
                    ? StatusEnum.RAW
                    : showFailedDataset(dataset.status)
                    ? StatusEnum.FAILED
                    : dataset.status
                }
              />
            </Box>
          </DrawerItem>
          {dataset?.error && (
            <DrawerItem>
              <DrawerSubHeading>Failure Cause:</DrawerSubHeading>
              <Typography>{dataset.error}</Typography>
            </DrawerItem>
          )}
          {!dataset?.error && (
            <DrawerItem>
              <DrawerSubHeading>Size:</DrawerSubHeading>
              <Typography>
                {dataset.dataset_metadata?.size ? formatBytes(dataset.dataset_metadata.size) : '--'}
              </Typography>
            </DrawerItem>
          )}
          {!isSample && (
            <>
              <DrawerItem>
                <DrawerSubHeading>Last Updated:</DrawerSubHeading>
                <Typography>{lastUpdated(dataset.updated_at)}</Typography>
              </DrawerItem>
              <DrawerItem>
                <DrawerSubHeading>Creation Date:</DrawerSubHeading>
                <Typography>{formatDate(dataset.created_at)}</Typography>
              </DrawerItem>
            </>
          )}
          {dataset.dataset_metadata?.extra_metadata?.num_of_files && (
            <DrawerItem>
              <DrawerSubHeading>Number of Files:</DrawerSubHeading>
              <Typography>{dataset.dataset_metadata.extra_metadata.num_of_files}</Typography>
            </DrawerItem>
          )}
          {!dataset?.error && (
            <DrawerItem>
              <DrawerSubHeading>Type of File:</DrawerSubHeading>
              <Typography>{dataset.dataset_metadata?.type_of_file}</Typography>
            </DrawerItem>
          )}
          {dataset.dataset_metadata?.extra_metadata?.num_of_rows && (
            <DrawerItem>
              <DrawerSubHeading>Number of Rows/Columns:</DrawerSubHeading>
              <Typography>
                {dataset.dataset_metadata.extra_metadata.num_of_rows}/
                {dataset.dataset_metadata.extra_metadata.num_of_columns}
              </Typography>
            </DrawerItem>
          )}
          {!isSample && (
            <DrawerItem>
              <DrawerSubHeading>Author:</DrawerSubHeading>
              <Typography>
                {dataset.creator?.firstname} {dataset.creator?.lastname}
              </Typography>
            </DrawerItem>
          )}
          {!dataset?.error && (
            <DrawerItem>
              <DrawerSubHeading>Description:</DrawerSubHeading>
              <Box display='flex' alignItems='center'>
                {expanded ? (
                  <Typography
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {dataset.description || '--'}
                  </Typography>
                ) : (
                  <Box>
                    {dataset.description ? (
                      <Tooltip title={dataset.description} placement='bottom-start'>
                        <Typography
                          sx={{
                            wordBreak: 'break-word',
                          }}
                        >
                          {dataset.description.substring(0, DESCRIPTION_LIMIT)}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography>--</Typography>
                    )}
                  </Box>
                )}
                {dataset.description?.length > DESCRIPTION_LIMIT && (
                  <ShowMoreButton setExpanded={setExpanded} expanded={expanded} chevronSize={16} />
                )}
              </Box>
            </DrawerItem>
          )}
          {dataset?.projects && !isSample && (
            <DrawerItem>
              <DrawerSubHeading>Used in Projects:</DrawerSubHeading>
              {dataset.projects.map(
                (project) =>
                  project && (
                    <ProjectCardPreview key={project.id} id={project.id} name={project.name} />
                  )
              )}
            </DrawerItem>
          )}
        </>
      ) : (
        <PreviewCircular size={30} />
      )}
    </Drawer>
  );
};

export default memo(DataLibraryPreview);

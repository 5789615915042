import resolveDefaults from 'graphology-utils/defaults';
import { Attributes, AbstractGraph } from 'graphology-types';
import cloneDeep from 'lodash/cloneDeep';

import { DEFAULT_NODE_COLOR } from 'app/screens/Analysis/Coloring/Coloring.utils';

interface GraphToSvgRendererDefaults {
  margin: number;
  width: number;
  height: number;
  nodes: {
    reducer: any;
    defaultColor: string;
  };
  edges: {
    reducer: any;
    defaultColor: string;
  };
}

const DEFAULTS: GraphToSvgRendererDefaults = {
  margin: 24,
  width: 1920,
  height: 1080,
  nodes: {
    reducer: null,
    defaultColor: DEFAULT_NODE_COLOR,
  },
  edges: {
    reducer: null,
    defaultColor: '#cccccc',
  },
};

const defaultEdgeReducer = (settings: Partial<GraphToSvgRendererDefaults>, attr: Attributes) => ({
  type: attr.type || 'line',
  size: attr.size || 1,
  color: attr.color || settings.edges?.defaultColor,
});

const defaultNodeReducer = (
  settings: Partial<GraphToSvgRendererDefaults>,
  node: string,
  attr: Attributes
) => {
  const reduced = {
    type: attr.type || 'circle',
    labelType: attr.labelType || 'default',
    label: attr.label || node,
    x: attr.originalX,
    y: attr.originalY,
    size: attr.size || 1,
    color: attr.color || settings.nodes?.defaultColor,
    border: attr.border || settings.nodes?.defaultColor,
  };

  if (typeof reduced.x !== 'number' || typeof reduced.y !== 'number')
    throw new Error(
      `svg export: the "${node}" node has no valid x or y position. Expecting a number.`
    );

  return reduced;
};

const reduceNodes = (
  graph: AbstractGraph,
  settings: GraphToSvgRendererDefaults
): Record<string, Attributes> => {
  const { width } = settings;
  const { height } = settings;

  let xBarycenter = 0;
  let yBarycenter = 0;
  let totalWeight = 0;

  const data: Record<string, Attributes> = {};

  graph.forEachNode((node, attr) => {
    let attributes = cloneDeep(attr);
    // Applying user's reducing logic
    if (typeof settings.nodes.reducer === 'function') {
      attributes = settings.nodes.reducer(settings, node, attributes);
    }

    attributes = defaultNodeReducer(settings, node, attributes);
    data[node] = attributes;

    // Computing rescaling items
    xBarycenter += attributes.size * attributes.x;
    yBarycenter += attributes.size * attributes.y;
    totalWeight += attributes.size;
  });

  xBarycenter /= totalWeight;
  yBarycenter /= totalWeight;

  let d;
  let n;
  let dMax = -Infinity;

  Object.keys(data).forEach((k) => {
    n = data[k];
    d = (n.x - xBarycenter) ** 2 + (n.y - yBarycenter) ** 2;

    if (d > dMax) dMax = d;
  });

  const ratio = dMax ? (Math.min(width, height) - 2 * settings.margin) / (2 * Math.sqrt(dMax)) : 1;
  const nodeSizeScaleRatio = 1;

  Object.keys(data).forEach((k) => {
    n = data[k];

    n.x = width / 1.5 + (n.x - xBarycenter) * ratio;
    n.y = height / 2 + (-n.y - yBarycenter) * ratio;

    n.size *= nodeSizeScaleRatio;
  });

  return data;
};

const drawNode = (data: Attributes): string =>
  `<circle 
    cx="${data.x}" 
    cy="${data.y}" 
    r="${data.size}" 
    fill="${data.color}" 
    stroke-width="${data.color === data.border ? 0 : 2}"
    stroke="${data.border}"
  />`;

const drawEdge = (data: Attributes, sourceData: Attributes, targetData: Attributes): string =>
  // eslint-disable-next-line max-len
  `<line x1="${sourceData.x}" y1="${sourceData.y}" x2="${targetData.x}" y2="${targetData.y}" stroke="${data.color}" stroke-width="${data.size}" />`;

const components = {
  nodes: drawNode,
  edges: drawEdge,
};

export const graphToSvgRenderer = (
  graph: AbstractGraph,
  config: Partial<GraphToSvgRendererDefaults> = {}
): string => {
  const settings = resolveDefaults(config, DEFAULTS) as GraphToSvgRendererDefaults;
  // Reducing nodes
  const nodeData = reduceNodes(graph, settings);

  // Drawing edges
  const edgesStrings: string[] = [];
  graph.forEachEdge((edge, attr, source, target) => {
    let attributes = cloneDeep(attr);
    // Reducing edge
    if (typeof settings.edges.reducer === 'function') {
      attributes = settings.edges.reducer(settings, edge, attributes);
    }

    attributes = defaultEdgeReducer(settings, attributes);

    edgesStrings.push(components.edges(attributes, nodeData[source], nodeData[target]));
  });

  // Drawing nodes and labels
  const nodesStrings: string[] = [];

  Object.keys(nodeData).forEach((k) => {
    nodesStrings.push(components.nodes(nodeData[k]));
  });

  return `<g>${edgesStrings.join('')}</g><g>${nodesStrings.join('')}</g>`;
};

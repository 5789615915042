import React, { FC, useCallback, useState } from 'react';
import { Database, Briefcase, Plus } from 'react-feather';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, RoutePath, useLocation } from 'app/navigation';
import { Button } from 'app/mui/Button';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { ProjectActionsDialog } from 'app/screens/Projects/ProjectActionsDialog/ProjectActionsDialog';
import { isPrivate } from 'app/screens/Projects/Projects';
import { Project, ProjectActionsPayload } from 'app/screens/Projects/Projects.types';
import { SidebarNav, SidebarPanel } from './Sidebar.styles';

export const Sidebar: FC = () => {
  const location = useLocation();
  const theme = useTheme();
  const { navigateToProjectOverview } = useNavigation();

  const { projects, createProject } = useProjects();

  const NAVIGATION_LIST = [
    {
      path: RoutePath.DATA_LIBRARY,
      title: 'Data Library',
      class: 'data-library',
      icon: <Database size='16' color={theme.palette.primary.main} />,
      enabled: true,
    },
    {
      path: RoutePath.PROJECTS,
      title: 'Projects',
      class: 'projects',
      icon: <Briefcase size='16' color={theme.palette.primary.main} />,
      enabled: true,
    },
  ];

  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);

  const handleOpenProjectModal = useCallback(() => {
    setIsProjectModalOpen(true);
  }, []);

  const closeProjectModal = useCallback(() => {
    setIsProjectModalOpen(false);
  }, []);

  const handleSave = async (values: ProjectActionsPayload): Promise<void> => {
    try {
      const response = (await createProject({
        name: values.name,
        is_private: values.type,
        description: values.description,
        onSuccess: closeProjectModal,
      })) as Project;

      if (response.id) {
        closeProjectModal();
        navigateToProjectOverview(response.id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isActiveItem = useCallback(
    (routePath: string) => location.pathname === routePath,
    [location.pathname]
  );

  const showCreateProjectButton = !(isActiveItem(RoutePath.PROJECTS) && projects.length === 0);

  return (
    <>
      <SidebarPanel>
        <SidebarNav>
          {NAVIGATION_LIST.map((item) => (
            <li
              key={item.title}
              className={classNames(
                {
                  'is-active': isActiveItem(item.path),
                  'non-clickable': !item.enabled,
                },
                item.class
              )}
            >
              <Link to={item.path} title={item.title}>
                {item.icon}
                <Typography
                  className='desktop'
                  variant='overline'
                  fontWeight='bold'
                  color='text.primary'
                  ml={1}
                >
                  {item.title}
                </Typography>
              </Link>
            </li>
          ))}
          {projects.slice(0, 5).map((project) => (
            <li
              key={project.id}
              className={classNames(
                {
                  'is-active': isActiveItem(`/projects/${project.id}`),
                  'non-clickable': false,
                },
                'desktop'
              )}
            >
              <Link to={`/projects/${project.id}`} title={project.name}>
                <Typography noWrap variant='caption' color='text.primary' ml={1}>
                  {project.name}
                </Typography>
              </Link>
            </li>
          ))}
          {showCreateProjectButton && (
            <Button variant='outlined' fullWidth onClick={handleOpenProjectModal}>
              <Plus size='24px' style={{ marginRight: '8px' }} />
              <span className='desktop'>Create Project</span>
            </Button>
          )}
        </SidebarNav>
      </SidebarPanel>
      {isProjectModalOpen && (
        <ProjectActionsDialog
          id={0}
          open={isProjectModalOpen}
          title='Create Project'
          name=''
          description=''
          is_private={isPrivate}
          handleClose={closeProjectModal}
          onSubmit={handleSave}
        />
      )}
    </>
  );
};

import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from 'app/mui/IconButton';
import { StyledTextField } from 'app/mui/TextField';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from 'app/screens/Analysis/CreateGroupDialog/CreateGroupDialog.styles';
import {
  Row,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { SourceDataSearchSelect } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceDataSearchSelect';
import { parameterOptions } from './DataPartition.constants';

export type PartitionRow = {
  feature_name: string;
  id: number | string;
};

type DataPartitionProps = {
  onDataPartitionFeatureChange: (value: Row) => void;
  onDataPartitionGlobalChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeBins: (event: ChangeEvent<HTMLInputElement>) => void;
  binsDecrement: () => void;
  binsIncrement: () => void;
  handleChangeEqualize: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeDistance: (event: ChangeEvent<HTMLInputElement>) => void;
  distanceDecrement: () => void;
  distanceIncrement: () => void;
  bins: string;
  distance: string;
  checkedEqualize: boolean;
  dataPartitionFeature: Row;
  dataPartitionGlobal: VisualizationRow;
  handleChangeGroupType: (event: React.MouseEvent<HTMLElement>, newType: string) => void;
  groupType: string;
};

export enum DataPartitionEnum {
  FEATURE = 'Feature',
  DATA = 'Data',
  METADATA = 'Metadata',
  GLOBAL = 'Global',
}

export const DataPartition: FC<DataPartitionProps> = ({
  onDataPartitionFeatureChange,
  onDataPartitionGlobalChange,
  handleChangeBins,
  binsDecrement,
  binsIncrement,
  handleChangeEqualize,
  handleChangeDistance,
  distanceDecrement,
  distanceIncrement,
  bins,
  distance,
  checkedEqualize,
  dataPartitionFeature,
  dataPartitionGlobal,
  handleChangeGroupType,
  groupType,
}) => {
  const { analysisId } = useParams();

  const [parameter] = useState<VisualizationRow[]>(parameterOptions);

  const { getFeaturesList, features } = useAnalysis();

  useEffect(() => {
    getFeaturesList(Number(analysisId));
  }, []);

  return (
    <Box>
      <StyledToggleButtonGroup
        value={groupType}
        exclusive
        onChange={handleChangeGroupType}
        aria-label='text alignment'
      >
        <StyledToggleButton value={DataPartitionEnum.GLOBAL} aria-label={DataPartitionEnum.GLOBAL}>
          {DataPartitionEnum.GLOBAL}
        </StyledToggleButton>
        <StyledToggleButton
          value={DataPartitionEnum.FEATURE}
          aria-label={DataPartitionEnum.FEATURE}
        >
          {DataPartitionEnum.FEATURE}
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      {features && (
        <Box mt={3}>
          {groupType === DataPartitionEnum.GLOBAL && (
            <>
              <Typography variant='subtitle1' mb={2}>
                Parameter
              </Typography>

              <FormControl>
                <RadioGroup
                  aria-labelledby='radio-buttons-group-options-label'
                  name='radio-buttons-group'
                  sx={{ flexDirection: 'initial' }}
                  value={dataPartitionGlobal.feature_name}
                  onChange={onDataPartitionGlobalChange}
                >
                  {parameter.map((param) => (
                    <FormControlLabel
                      key={param.feature_name}
                      value={param.feature_name}
                      control={<Radio />}
                      label={param.feature_name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          )}
          {groupType === DataPartitionEnum.FEATURE && (
            <SourceDataSearchSelect
              rows={features}
              rowLabel='Feature (only 1 should be selected)'
              onRowChange={onDataPartitionFeatureChange}
              defaultValue={dataPartitionFeature}
            />
          )}
        </Box>
      )}

      <Box display='flex' justifyContent='space-between' alignItems='center' gap={2} mt={3}>
        <StyledTextField
          id='outlined-end-adornment'
          type='number'
          className='largeNumber'
          value={bins}
          label='Number of bins'
          onChange={handleChangeBins}
          size='small'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  className='configArrows'
                  disabled={Number(bins) <= 1}
                  onClick={binsDecrement}
                >
                  <ChevronLeft size={16} className='arrow' />
                </IconButton>
                <IconButton className='configArrows' onClick={binsIncrement}>
                  <ChevronRight size={16} className='arrow' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <StyledTextField
          id='outlined-end-adornment'
          type='number'
          className='largeNumber'
          value={distance}
          label='Max link distance'
          onChange={handleChangeDistance}
          size='small'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  className='configArrows'
                  disabled={Number(distance) <= 0}
                  onClick={distanceDecrement}
                >
                  <ChevronLeft size={16} className='arrow' />
                </IconButton>
                <IconButton className='configArrows' onClick={distanceIncrement}>
                  <ChevronRight size={16} className='arrow' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={<Checkbox value={checkedEqualize} onChange={handleChangeEqualize} />}
          label='Equalize histogram'
        />
      </Box>
    </Box>
  );
};

import { styled } from '@mui/material/styles';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle, { AlertTitleProps } from '@mui/material/AlertTitle';

const StyledAlert = styled(Alert)<AlertProps>(({ theme }) => ({
  '&.MuiAlert-standard': {
    backgroundColor: theme.palette.grey.A200,
    position: 'relative',
    color: theme.palette.text.primary,
    '&:not(.alert-big)': {
      '&:before': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '56px',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px 0 0 4px',
        content: '""',
      },
      '&.MuiAlert-standardError': {
        '&:before': {
          backgroundColor: theme.palette.error.main,
        },
      },
      '&.MuiAlert-standardSuccess': {
        '&:before': {
          backgroundColor: theme.palette.success.main,
        },
      },
      '&.MuiAlert-standardInfo': {
        '&:before': {
          backgroundColor: theme.palette.info.main,
        },
      },
      '.MuiAlert-icon': {
        marginRight: theme.spacing(4),
        color: theme.palette.common.white,
      },
    },
    '&.alert-big': {
      '&.MuiAlert-standardError': {
        '.MuiAlert-icon, .MuiAlertTitle-root': {
          color: theme.palette.error.main,
        },
      },
      '&.MuiAlert-standardSuccess': {
        '.MuiAlert-icon, .MuiAlertTitle-root': {
          color: theme.palette.success.main,
        },
      },
      '&.MuiAlert-standardInfo': {
        '.MuiAlert-icon, .MuiAlertTitle-root': {
          color: theme.palette.info.main,
        },
      },
      '&.MuiAlert-standardWarning': {
        '.MuiAlert-icon, .MuiAlertTitle-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  '&.MuiAlert-filled': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '.MuiAlert-icon': {
      padding: '3px 0',
      '.MuiSvgIcon-root': {
        width: '30px',
        height: '30px',
      },
    },
    '&.MuiAlert-filledError': {
      '.MuiAlert-icon': {
        color: theme.palette.error.main,
      },
    },
    '&.MuiAlert-filledSuccess': {
      '.MuiAlert-icon': {
        color: theme.palette.success.main,
      },
    },
    '&.MuiAlert-filledInfo': {
      '.MuiAlert-icon': {
        color: theme.palette.info.light,
      },
    },
    '&.MuiAlert-filledWarning': {
      '.MuiAlert-icon': {
        color: theme.palette.primary.contrastText,
      },
    },
    '.MuiAlert-action .MuiButtonBase-root': {
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  '.MuiAlert-action .MuiButtonBase-root': {
    color: theme.palette.action.active,
    '&:hover': {
      color: theme.palette.hover.primary,
    },
  },
  '.MuiAlert-message': {
    whiteSpace: 'break-spaces',
  },
}));

const StyledAlertTitle = styled(AlertTitle)<AlertTitleProps>(() => ({
  fontWeight: '700',
}));

export { StyledAlert as Alert };
export { StyledAlertTitle as AlertTitle };

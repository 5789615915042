import { styled } from '@mui/material/styles';

export const NoResultsWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(8)} 0`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  '.gradient-bg &': {
    marginTop: theme.spacing(16),
  },
  '& p': {
    maxWidth: '570px',
  },
}));

export const Thumb = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& img': {
    display: 'block',
  },
}));

import React, { FC, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { Popup } from 'app/shared/components/Popup/Popup';
import { Order } from 'app/shared/components/SortSelect/SortSelect';
import { getComparator } from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList';
import { SelectedProject } from '../Projects';
import { useProjects } from '../Projects.hooks';
import { ProjectActionsDialog } from '../ProjectActionsDialog/ProjectActionsDialog';
import { ProjectItem } from '../ProjectItem/ProjectItem';
import { ProjectActionsPayload } from '../Projects.types';

type ProjectsListProps = {
  order: Order;
  orderBy: string;
  setSelectedProject: any;
  selectedProject: SelectedProject;
};

export const ProjectsList: FC<ProjectsListProps> = ({
  order,
  orderBy,
  setSelectedProject,
  selectedProject,
}) => {
  const { projects, editProject, deleteProject } = useProjects();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const closeEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const handleSave = async (values: ProjectActionsPayload): Promise<void> => {
    editProject({
      id: values.id,
      name: values.name,
      is_private: values.type,
      description: values.description,
      onSuccess: closeEditDialog,
    });
  };

  const onDeleteProject = useCallback((id: number) => {
    deleteProject(id);
    setIsDeleteDialogOpen(false);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  return (
    <Box>
      {projects
        .slice()
        .sort(getComparator(order, orderBy))
        .map((row, index) => {
          const labelId = `table-checkbox-${index}`;

          return (
            <ProjectItem
              key={row.id}
              setSelectedProject={setSelectedProject}
              setIsEditDialogOpen={setIsEditDialogOpen}
              setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              row={row}
              labelId={labelId}
            />
          );
        })}
      {selectedProject && selectedProject.id && selectedProject.name && (
        <>
          <ProjectActionsDialog
            title='Edit Project'
            open={isEditDialogOpen}
            id={selectedProject.id}
            name={selectedProject.name}
            description={selectedProject.description}
            is_private={selectedProject.is_private}
            handleClose={closeEditDialog}
            onSubmit={handleSave}
          />
          <Popup
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            onConfirm={() => selectedProject.id && onDeleteProject(selectedProject.id)}
            state='Delete'
            title={`Delete ${selectedProject.name}?`}
            description='You will not be able to recover it.'
          />
        </>
      )}
    </Box>
  );
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createTheme, Palette as MuiPalette } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  // @ts-ignore
  interface Palette extends MuiPalette {
    gradient: {
      darkBlue: string;
      whiteBlue: string;
    };
    state: {
      hoverBackground: string;
      restingBorder: string;
      hoverBorder: string;
    };
    hover: {
      primary: string;
    };
    blocksBackground: {
      dark: string;
      transparent: string;
      hover: string;
    };
    overlay: {
      main: string;
      light: string;
    };
    legend: {
      background: string;
      hover: string;
    };
  }

  interface PaletteOptions {
    gradient: {
      darkBlue: string;
      whiteBlue: string;
    };
    state: {
      hoverBackground: string;
      restingBorder: string;
      hoverBorder: string;
    };
    hover: {
      primary: string;
    };
    blocksBackground: {
      dark: string;
      transparent: string;
      hover: string;
    };
    overlay: {
      main: string;
      light: string;
    };
    legend: {
      background: string;
      hover: string;
    };
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
    desktopLarge: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1710,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      desktopLarge: 1440,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: '#414C64',
      main: '#122239',
      dark: '#1D2735',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#57657A',
      main: '#33455F',
      dark: '#29374C',
      contrastText: '#FFF',
    },
    error: {
      light: '#FFF2F2',
      main: '#D22E2E',
      dark: '#A70101',
    },
    success: {
      light: '#54A776',
      main: '#1CB359',
      dark: '#127139',
    },
    info: {
      light: '#79AEE3',
      main: '#075678',
    },
    warning: {
      main: '#ed6c02',
    },
    grey: {
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      700: '#616161',
    },
    text: {
      primary: '#122239',
      secondary: '#596474',
      disabled: '#A5ABB4',
    },
    divider: '#E7E8E8',
    background: {
      paper: '#FFFFFF',
      default: '#F8F8F8',
    },
    blocksBackground: {
      dark: '#122239',
      transparent: 'rgba(255, 255, 255, 0.002)',
      hover: '#F2FCFF',
    },
    action: {
      active: '#677280',
      hover: '#FAFEFF',
      disabled: '#C1C6CC',
      disabledBackground: '#E2E4E7',
    },
    state: {
      hoverBackground: 'rgba(0, 209, 255, 0.05)',
      restingBorder: 'rgba(18, 34, 57, 0.7)',
      hoverBorder: 'rgba(7, 86, 120, 0.7)',
    },
    gradient: {
      darkBlue: 'linear-gradient(180deg, #122839 0%, #1EB0E9 100%)',
      whiteBlue: 'linear-gradient(270deg, #FFFFFF 0.25%, #113950 99.63%)',
    },
    overlay: {
      main: 'rgba(18, 34, 57, 0.5)',
      light: 'rgba(rgba(255, 255, 255, .7))',
    },
    hover: {
      primary: '#075678',
    },
    legend: {
      background: '#F2F2F2',
      hover: '#E1E3E5',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '7rem',
      fontWeight: 500,
      lineHeight: '112px',
    },
    h2: {
      fontSize: '4.8rem',
      lineHeight: '56px',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 500,
      lineHeight: '35px',
    },
    h4: {
      fontSize: '2.4rem',
      lineHeight: '28px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.8rem',
      lineHeight: '24px',
      fontWeight: '600',
    },
    h6: {
      fontSize: '1.6rem',
      lineHeight: '22px',
      fontWeight: '400',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '1.4rem',
      lineHeight: '21px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '1.8rem',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '1.4rem',
      lineHeight: '20px',
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: '400',
    },
    overline: {
      fontSize: '1.2rem',
      letterSpacing: '0.2px',
      lineHeight: '32px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: '#D22E2E' },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
  },
});

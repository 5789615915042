import React, { FC } from 'react';
import { ControlsContainer, useCamera } from '@react-sigma/core';

import {
  Lasso,
  Plus,
  Minus,
  Reset,
  FullScreenEnter,
  FullScreenExit,
  ClearSelection,
  Invert,
} from './icons';

export type GraphControlsProps = {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  isLassoActive: boolean;
  setIsLassoActive: (update: (isActive: boolean) => boolean) => void;
  onClearSelection: () => void;
  onInvertSelection: () => void;
};

export const GraphControls: FC<GraphControlsProps> = ({
  isFullScreen,
  toggleFullScreen,
  isLassoActive,
  setIsLassoActive,
  onClearSelection,
  onInvertSelection,
}) => {
  const { zoomIn, zoomOut, reset } = useCamera({ duration: 200, factor: 1.5 });
  return (
    <ControlsContainer position='bottom-right'>
      <div className='react-sigma-control'>
        <button type='button' data-tooltip='Zoom in' className='zoom-in' onClick={() => zoomIn()}>
          <Plus />
        </button>
      </div>
      <div className='react-sigma-control'>
        <button
          type='button'
          data-tooltip='Zoom out'
          className='zoom-out'
          onClick={() => zoomOut()}
        >
          <Minus />
        </button>
      </div>
      <div className='react-sigma-control'>
        <button
          type='button'
          data-tooltip='Reset scale'
          className='reset-scale'
          onClick={() => reset()}
        >
          <Reset />
        </button>
      </div>
      <div className='react-sigma-control'>
        <button
          type='button'
          data-tooltip={isFullScreen ? 'Scale back' : 'Expand'}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <FullScreenExit /> : <FullScreenEnter />}
        </button>
      </div>
      <div className='react-sigma-control'>
        <button
          type='button'
          data-tooltip='Lasso select'
          className={`lasso-selection ${isLassoActive ? 'active' : ''}`}
          onClick={() => setIsLassoActive((prevState) => !prevState)}
        >
          <Lasso />
        </button>
      </div>
      <div className='react-sigma-control'>
        <button type='button' data-tooltip='Clear selection' onClick={onClearSelection}>
          <ClearSelection />
        </button>
      </div>
      <div className='react-sigma-control'>
        <button
          type='button'
          data-tooltip='Invert selection'
          className='invert-selection'
          onClick={onInvertSelection}
        >
          <Invert />
        </button>
      </div>
    </ControlsContainer>
  );
};

import React, { FC } from 'react';
import { Sliders, BookOpen, LogOut } from 'react-feather';
import MenuItem from '@mui/material/MenuItem';
import { useAuthentication } from 'app/shared/hooks/useAuthentication';

export const AccountMenu: FC = () => {
  const { logout } = useAuthentication();

  return (
    <>
      <MenuItem onClick={() => {}} disabled>
        <Sliders />
        <span>Profile settings</span>
      </MenuItem>
      <MenuItem onClick={() => {}} disabled divider>
        <BookOpen />
        <span>Documentation</span>
      </MenuItem>
      <MenuItem onClick={logout}>
        <LogOut />
        <span>Log Out</span>
      </MenuItem>
    </>
  );
};

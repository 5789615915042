import React, { FC, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, VisualizationDialogWrapper } from 'app/shared/styles/createForm';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import { CustomSubsetSelect } from 'app/shared/components/CustomSubsetSelect/CustomSubsetSelect';
import { SourceDataSearchSelect } from './SourceDataSearchSelect';
import { useLandscapeSourceData } from './SourceData.hooks';

export type Row =
  | {
      feature_name: string;
      id: number;
    }
  | undefined;

export type VisualizationRow = {
  feature_name: string;
  id: number;
};

export enum SourceDataLandscapesOptions {
  ALL_FEATURES = 'All features',
  SELECTED_FEATURES = 'Currently selected features',
  ALL_DATA = 'All data points',
  SELECTED_DATA = 'Currently selected data points',
  CUSTOM = 'Custom subset',
}

export const dataLandscapeDefaultFeatures = [
  {
    feature_name: `${SourceDataLandscapesOptions.ALL_FEATURES}`,
    id: 0,
  },
  {
    feature_name: `${SourceDataLandscapesOptions.SELECTED_FEATURES}`,
    id: 1,
  },
  {
    feature_name: `${SourceDataLandscapesOptions.CUSTOM}`,
    id: 2,
  },
];

export const dataLandscapeDefaultDataPoints = [
  {
    feature_name: `${SourceDataLandscapesOptions.ALL_DATA}`,
    id: 0,
  },
  {
    feature_name: `${SourceDataLandscapesOptions.SELECTED_DATA}`,
    id: 1,
  },
  {
    feature_name: `${SourceDataLandscapesOptions.CUSTOM}`,
    id: 2,
  },
];

type DataLandscapeSourceDataDialogProps = {
  openDialog: boolean;
  handleClose: () => void;
  handleSaveSourceData: (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ) => void;
  dataRow: Row;
  customDataRows: number[] | undefined;
  featureRow: Row;
  customFeatureRows: number[] | undefined;
};

export const DataLandscapeSourceDataDialog: FC<DataLandscapeSourceDataDialogProps> = ({
  openDialog,
  handleClose,
  handleSaveSourceData,
  dataRow,
  customDataRows,
  featureRow,
  customFeatureRows,
}) => {
  const { analysisId } = useParams();

  const {
    getFeaturesList,
    features,
    dataPointsCount,
    groups,
    dataLandscapeVisualization,
    selectedDataRows,
    selectedFeatures,
  } = useAnalysis();

  const {
    dataPointSubset,
    featureSubset,
    dataPoints,
    dataRowValue,
    featureRowValue,
    customDataRowsValue,
    customFeatureRowsValue,
    defaultDataPointsLandscape,
    defaultFeaturesLandscape,
    defaultCustomDataPointsLandscape,
    defaultCustomFeaturesLandscape,
    isLandscapeSourceDataDisabled,
    onDataRowChange,
    onFeatureRowChange,
    onCustomDataRowsChange,
    onCustomFeatureRowsChange,
  } = useLandscapeSourceData({
    visualization: dataLandscapeVisualization,
    groups,
    dataPointsCount,
    dataRow,
    featureRow,
    customDataRows,
    customFeatureRows,
    features,
    selectedDataRows,
    selectedFeatures,
  });

  useEffect(() => {
    getFeaturesList(Number(analysisId));
  }, []);

  return (
    <VisualizationDialogWrapper
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box width='841px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              Modify Source Data
            </Typography>
            <StyledCloseIcon onClick={handleClose} size={24} />
          </Box>
          {featureSubset && dataPointSubset && dataPoints && features && (
            <>
              <Box mt={3}>
                <CustomSubsetSelect
                  rows={features}
                  onRowsChange={(value: VisualizationRow[]) => onCustomFeatureRowsChange(value)}
                  label='Features'
                  defaultValues={defaultCustomFeaturesLandscape}
                  showSelect={featureRowValue?.feature_name === SourceDataLandscapesOptions.CUSTOM}
                  maxHeight={115}
                >
                  <SourceDataSearchSelect
                    rows={featureSubset}
                    rowLabel='Feature subset'
                    onRowChange={(value) => onFeatureRowChange(value)}
                    defaultValue={defaultFeaturesLandscape}
                  />
                </CustomSubsetSelect>
              </Box>
              <Box mt={3}>
                <CustomSubsetSelect
                  rows={dataPoints}
                  onRowsChange={(value: VisualizationRow[]) => onCustomDataRowsChange(value)}
                  label='Data points'
                  defaultValues={defaultCustomDataPointsLandscape}
                  showSelect={dataRowValue?.feature_name === SourceDataLandscapesOptions.CUSTOM}
                  maxHeight={115}
                >
                  <SourceDataSearchSelect
                    rows={dataPointSubset}
                    rowLabel='Data points subset'
                    onRowChange={(value) => onDataRowChange(value)}
                    defaultValue={defaultDataPointsLandscape}
                  />
                </CustomSubsetSelect>
              </Box>
            </>
          )}

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() =>
                handleSaveSourceData(
                  customDataRowsValue,
                  customFeatureRowsValue,
                  dataRowValue,
                  featureRowValue
                )
              }
              variant='contained'
              disabled={isLandscapeSourceDataDisabled}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </VisualizationDialogWrapper>
  );
};

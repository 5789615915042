import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import type AbstractGraph from 'graphology-types';
import emptyVisualizationImage from 'app/assets/images/analysis/empty-visualization.svg';
import { Button } from 'app/mui/Button';
import { Popup } from 'app/shared/components/Popup/Popup';
import { PanHeightContext } from 'app/shared/context/PanHeightContext/PanHeightContext';
import { exportChartSvg, exportLandscapeSvg } from 'app/shared/utils/exportSvg';
import {
  AnalysisConfig,
  ChartDataType,
  ColoringConfig,
  SelectedFeatureType,
} from 'app/screens/Analysis/Analysis.types';
import { ColorLegendType } from 'app/screens/Analysis/Coloring/Coloring.types';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { VisualizationMenu } from './VisualizationMenu';
import { ImageBox, MenuBox, Wrapper, ErrorStateBox } from './VisualizationPan.styles';
import { useAnalysis } from '../Analysis.hooks';
import { VisualisationType } from '../AnalysisSidebar/Configure/Configure';
import { Chart } from '../Visualizations/Wrappers/Chart';
import { Landscape } from '../Visualizations/Wrappers/Landscape';

type VisualizationPanProps = {
  id: string;
  analysisId: number;
  isLargePan?: boolean;
  error?: string;
  coloring?: ColoringConfig;
  shouldReload?: boolean;
  visualizationId?: number;
  visualizationType?: VisualisationType;
  shouldLayoutUpdate?: boolean;
  onSelection: (type: VisualisationType, hasNodes: boolean) => void;
  openVisualizationDialog: (panel: string) => void;
  setShouldLayoutUpdate?: (shouldLayoutUpdate: boolean) => void;
};

export const VisualizationPan: FC<VisualizationPanProps> = ({
  id,
  analysisId,
  visualizationId,
  visualizationType,
  coloring,
  error,
  shouldLayoutUpdate,
  onSelection,
  setShouldLayoutUpdate,
  openVisualizationDialog,
  isLargePan = false,
  shouldReload,
}) => {
  const [isDeleteDialog, setDeleteDialog] = useState(false);
  const [visualizationMetadata, setVisualizationMetadata] = useState<
    AnalysisConfig | ChartDataType | null
  >(null);
  const isEmptyState = !analysisId || !visualizationId || !visualizationType;
  const isErrorState = Boolean(error);
  const isLandscape =
    visualizationType === VisualisationType.DATA_LANDSCAPE ||
    visualizationType === VisualisationType.FEATURE_LANDSCAPE;
  const [isSvgGenerating, setSvgGenerating] = useState(false);
  const legendRef = useRef<ColorLegendType | null>(null);
  const coloringTypeRef = useRef<SelectedFeatureType | null>(null);

  const [panHeight, setPanHeight] = useState<number>(0);

  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current && isLargePan) {
      const elementHeight = elementRef.current.offsetHeight;
      setPanHeight(elementHeight);
    }
  }, [isLargePan, elementRef.current?.offsetHeight]);

  const panHeightContextValue = useMemo(
    () => ({
      panHeight,
      setPanHeight,
    }),
    [panHeight, setPanHeight]
  );

  const { project } = useProjects();

  const {
    features,
    deleteVisualization,
    currentAnalysis,
    highlightedVisualization,
    setHighlightedVisualization,
    removePanelError,
    clearSelection,
  } = useAnalysis();

  const isHighlighted = highlightedVisualization?.panel && highlightedVisualization.panel === id;

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    if (visualizationId) {
      deleteVisualization(analysisId, visualizationId);
      clearSelection(visualizationId, visualizationType);
    }

    closeDeleteDialog();
  };

  const setMetadata = (metadata: AnalysisConfig | ChartDataType | null) =>
    setVisualizationMetadata(metadata);

  const handleGraphSvgGenerating = (graph: AbstractGraph, metadata: AnalysisConfig) => {
    exportLandscapeSvg(
      graph,
      metadata,
      coloringTypeRef.current,
      legendRef.current,
      project.name,
      currentAnalysis.name
    );
    setSvgGenerating(false);
  };

  const handleChartSvgGenerating = async (chart: ChartDataType) => {
    await exportChartSvg(
      visualizationType!,
      chart,
      coloringTypeRef.current,
      legendRef.current,
      project.name,
      currentAnalysis.name
    );
    setSvgGenerating(false);
  };

  const setColoringTypeRef = useCallback((coloringType: SelectedFeatureType | null): void => {
    coloringTypeRef.current = coloringType;
  }, []);

  const setLegendRef = useCallback((legend: ColorLegendType | null): void => {
    legendRef.current = legend;
  }, []);

  useEffect(() => {
    const isColoringVisualization =
      visualizationType &&
      [
        VisualisationType.FEATURE_LANDSCAPE,
        VisualisationType.DATA_LANDSCAPE,
        VisualisationType.SCATTER_PLOT,
      ].includes(visualizationType);

    if (!isColoringVisualization) {
      setLegendRef(null);
      setColoringTypeRef(null);
    }
  }, [visualizationType]);

  const chartUpdateActions = isLargePan
    ? {
        shouldLayoutUpdate,
        setShouldLayoutUpdate,
        isLargePan,
      }
    : {};

  const showLandscape = !!features.length && isLandscape && !!visualizationMetadata;
  const showChart =
    !!visualizationId &&
    !!features.length &&
    visualizationType &&
    !isLandscape &&
    !!visualizationMetadata;

  return (
    <PanHeightContext.Provider value={panHeightContextValue}>
      <Wrapper
        ref={elementRef}
        isEmpty={isEmptyState}
        onAnimationEnd={() => setHighlightedVisualization(undefined)}
        className={classNames({ highlight: isHighlighted })}
      >
        {isErrorState && (
          <ErrorStateBox>
            <Typography width='80%' maxWidth='382px' textAlign='center'>
              The landscape you’ve just created is saved in the Configure panel, but can’t be
              displayed here because you already have the maximum of 5 landscapes on canvas
            </Typography>
            <Button onClick={() => removePanelError(id)} size='small' variant='contained'>
              Got it
            </Button>
          </ErrorStateBox>
        )}
        <MenuBox isEmpty={isEmptyState}>
          <VisualizationMenu
            panelId={id}
            visualizationId={visualizationId}
            visualizationType={visualizationType}
            openVisualizationDialog={openVisualizationDialog}
            onDelete={openDeleteDialog}
            setMetadata={setMetadata}
            setSvgGenerating={setSvgGenerating}
          />
          {isDeleteDialog && !!visualizationMetadata && (
            <Popup
              open={isDeleteDialog}
              title={`Delete ${visualizationMetadata.name}?`}
              description='You will not be able to recover it.'
              state='Delete'
              onClose={closeDeleteDialog}
              onConfirm={handleDelete}
            />
          )}
        </MenuBox>
        {isEmptyState && (
          <ImageBox
            isLarge={isLargePan}
            component='img'
            src={emptyVisualizationImage}
            alt={`${id}-empty-visualization`}
          />
        )}
        {showLandscape && (
          <Landscape
            key={visualizationType}
            panelId={id}
            id={visualizationId!}
            analysisId={analysisId}
            type={visualizationType}
            metadata={visualizationMetadata as AnalysisConfig}
            onSelection={onSelection}
            shouldLayoutUpdate={shouldLayoutUpdate}
            setShouldLayoutUpdate={setShouldLayoutUpdate}
            coloring={coloring}
            isLargePan={isLargePan}
            isGenerateSvg={isSvgGenerating}
            onSvgGenerate={handleGraphSvgGenerating}
            setColoringTypeRef={setColoringTypeRef}
            setLegendRef={setLegendRef}
          />
        )}
        {showChart && (
          <Chart
            key={visualizationId}
            id={visualizationId}
            panelId={id}
            type={visualizationType}
            analysisId={analysisId}
            shouldReload={shouldReload}
            coloring={coloring}
            isGenerateSvg={isSvgGenerating}
            onSvgGenerate={handleChartSvgGenerating}
            setColoringTypeRef={setColoringTypeRef}
            setLegendRef={setLegendRef}
            {...chartUpdateActions}
          />
        )}
      </Wrapper>
    </PanHeightContext.Provider>
  );
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Download, Search, ArrowLeft } from 'react-feather';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, DialogWrapper } from 'app/shared/styles/createForm';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { StyledTextField } from 'app/mui/TextField';
import { Tooltip } from 'app/mui/Tooltip';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { useDataLibrary } from 'app/screens/DataLibrary/DataLibrary.hooks';
import { UploadForm } from 'app/shared/components/UploadForm/UploadForm';
import { DatasetCardDialog } from 'app/shared/components/DatasetCardDialog/DatasetCardDialog';
import { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { DEFAULT_TIMEOUT } from 'app/shared/utils/timeout';
import { StatusEnum } from 'app/shared/enum/status';
import { SearchNoResults } from 'app/shared/components/SearchNoResults/SearchNoResults';
import { StyledButton } from './ManageDatasetAndAnalysis.styles';
import { useAnalysis } from '../Analysis.hooks';
import { AnalysisType } from '../Analysis.types';

type CreateAnalysisDialogProps = {
  openDialog: boolean;
  handleClose: (projectReload?: boolean) => void;
  projectId: number;
  hasAnalysisNameInput?: boolean;
};

const maxNameLength = 100;
const ANALYSIS_MODAL_UPLOADING_TEXT =
  'It’s safe to start analysis, uploading will be completed in the background';
const ANALYSIS_MODAL_UPLOADED_TEXT =
  'Dataset is ready for analysis. If you close this window instead, the file will just be kept in Data Library.';

const DATASET_MODAL_UPLOADING_TEXT =
  'It’s safe to assign it to the project, uploading will be completed in the background';
const DATASET_MODAL_UPLOADED_TEXT =
  'You can assign it to the project and start analysis. If you close this window instead, the file will just be kept in Data Library.';

const maxLengthHelperText = (name: string, nameLength: number): string => {
  if (nameLength > maxNameLength) {
    return `The length of ${name} name must be ${maxNameLength} characters or fewer`;
  }
  return '';
};

export const ManageDatasetAndAnalysis: FC<CreateAnalysisDialogProps> = ({
  openDialog,
  handleClose,
  projectId,
  hasAnalysisNameInput,
}) => {
  const { navigateToAnalysis } = useNavigation();
  const { getDatasets, datasets } = useDataLibrary();
  const { createAnalysis } = useAnalysis();
  const { addDatasetToProject, getProject } = useProjects();

  const [datasetNameSearch, setDatasetNameSearch] = useState('');
  const [selectedDatasetName, setSelectedDatasetName] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [analysisName, setAnalysisName] = useState('');
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [selectedDatasetId, setSelectedDatasetId] = useState<number | undefined>(undefined);
  const [uploadedDataset, setUploadedDataset] = useState<Dataset | undefined>(undefined);
  const [shouldCancelUpload, setShouldCancelUpload] = useState(false);

  const currentDatasetId = selectedDatasetId || uploadedDataset?.id;

  const currentAnalysisName = useMemo(() => {
    if (hasAnalysisNameInput) {
      if (analysisName.length === 0) {
        if (selectedDatasetName) {
          return selectedDatasetName;
        }
        return uploadedDataset?.name;
      }
      return analysisName;
    }

    if (selectedDatasetId) return selectedDatasetName;

    return uploadedDataset?.name;
  }, [
    analysisName,
    selectedDatasetName,
    selectedDatasetId,
    uploadedDataset?.name,
    hasAnalysisNameInput,
  ]);

  useEffect(() => {
    getDatasets();
  }, []);

  const onSelectDataset = (id: number, name: string): void => {
    if (selectedDatasetId === id) {
      setSelectedDatasetName('');
      setSelectedDatasetId(undefined);
    } else {
      setSelectedDatasetName(name);
      setSelectedDatasetId(id);
    }
  };

  const onUploadNewDataset = (): void => {
    setSelectedDatasetId(undefined);
    setShowUploadForm(true);
  };

  const addDataset = (project_id: number, dataset_id: number): void => {
    addDatasetToProject(project_id, dataset_id);

    setTimeout(() => {
      handleClose();
    }, 50);
  };

  const onCreateAnalysis = async (
    project_id: number,
    dataset_id: number,
    name: string
  ): Promise<void> => {
    try {
      if (selectedDatasetId) {
        await addDatasetToProject(project_id, selectedDatasetId);
      }

      const createdAnalysis: AnalysisType = await createAnalysis(project_id, dataset_id, name);

      setTimeout(() => {
        navigateToAnalysis(project_id, createdAnalysis.id);
      }, DEFAULT_TIMEOUT);
    } catch (error) {
      console.error(error);
    }
  };

  const onUploadingStarted = (dataset: Dataset): void => {
    setIsUploading(true);
    if (!datasetName) {
      setDatasetName(dataset.name);
    }
  };

  const onUploadFinished = (dataset: Dataset | undefined): void => {
    setUploadedDataset(dataset);
    setIsUploading(false);

    if (!dataset) {
      setDatasetName('');
    }
  };

  const onUploadFailed = (): void => {
    setIsUploadFailed(true);
  };

  const onModalClose = (): void => {
    handleClose(!!uploadedDataset);
  };

  const isReadyToStartAnalysis = currentDatasetId && !isUploadFailed;

  const filteredDatasets =
    datasets &&
    datasets
      .filter((dataset) => dataset.status !== StatusEnum.UPLOAD_FAILED)
      .filter((dataset) => dataset.name.toLowerCase().includes(datasetNameSearch.toLowerCase()));

  const UPLOADED_TEXT = hasAnalysisNameInput
    ? ANALYSIS_MODAL_UPLOADED_TEXT
    : DATASET_MODAL_UPLOADED_TEXT;
  const UPLOADING_TEXT = hasAnalysisNameInput
    ? ANALYSIS_MODAL_UPLOADING_TEXT
    : DATASET_MODAL_UPLOADING_TEXT;

  return (
    <DialogWrapper open={openDialog} onClose={onModalClose} TransitionComponent={Transition}>
      <Box width='1080px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between' mb={!hasAnalysisNameInput ? 3 : 0}>
            <Typography component='h5' variant='h5'>
              {hasAnalysisNameInput ? 'Start New Analysis' : 'Add Dataset'}
            </Typography>
            <StyledCloseIcon onClick={onModalClose} size={24} />
          </Box>
          {hasAnalysisNameInput && (
            <StyledTextField
              color='secondary'
              placeholder='New analysis name'
              label='New analysis name'
              helperText={maxLengthHelperText('analysis', analysisName.length)}
              value={analysisName}
              onChange={(event) => setAnalysisName(event.target.value)}
              fullWidth
              sx={{
                mt: 3,
                mb: 3,
              }}
            />
          )}

          {showUploadForm ? (
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='subtitle1'>Upload new dataset</Typography>
              <StyledButton
                width='243px'
                variant='outlined'
                disabled={isUploading}
                onClick={() => setShowUploadForm(false)}
              >
                <ArrowLeft size='24px' style={{ marginRight: '8px' }} />
                <Typography variant='caption' textAlign='left' fontWeight='600'>
                  Select Existing Dataset
                </Typography>
              </StyledButton>
            </Box>
          ) : (
            <Box display='flex'>
              <StyledTextField
                id='outlined-start-adornment'
                label='Search datasets'
                placeholder='Search datasets'
                value={datasetNameSearch}
                onChange={(e) => setDatasetNameSearch(e.target.value)}
                size='small'
                fullWidth
                sx={{
                  mr: 2,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <StyledButton
                width='222px'
                variant='outlined'
                className={classNames({ highlight: showUploadForm })}
                onClick={onUploadNewDataset}
              >
                <Download size='24px' style={{ marginRight: '8px' }} />
                <Typography variant='caption' textAlign='left' fontWeight='600'>
                  Upload New Dataset
                </Typography>
              </StyledButton>
            </Box>
          )}
          {!showUploadForm && (
            <Box display='flex' mt={2}>
              {filteredDatasets.length > 0 ? (
                filteredDatasets
                  .slice(0, 4)
                  .map(
                    (dataset) =>
                      dataset && (
                        <DatasetCardDialog
                          key={dataset.id}
                          id={dataset.id}
                          name={dataset.name}
                          status={dataset.status}
                          selectDataset={onSelectDataset}
                          selectedDatasetId={selectedDatasetId}
                        />
                      )
                  )
              ) : (
                <SearchNoResults text='No datasets found' />
              )}
            </Box>
          )}

          <Box hidden={!showUploadForm}>
            <Box display='flex' mt={3} mb={2} justifyContent='space-between' gap={2} width='100%'>
              <StyledTextField
                error={datasetName.length > maxNameLength}
                helperText={maxLengthHelperText('analysis', datasetName.length)}
                color='secondary'
                placeholder='New dataset name'
                label='New dataset name'
                value={datasetName}
                disabled={isUploading}
                fullWidth
                onChange={(e) => setDatasetName(e.target.value)}
              />
            </Box>
            <UploadForm
              isFormValid
              onUploadingStarted={onUploadingStarted}
              onUploadFinished={onUploadFinished}
              onUploadFailed={onUploadFailed}
              onDatasetDeletion={() => getProject(projectId)}
              projectId={projectId}
              datasetName={datasetName}
              shouldCancelUpload={shouldCancelUpload}
              uploadedDatasetText={UPLOADED_TEXT}
              uploadingDatasetText={UPLOADING_TEXT}
            />
          </Box>

          <Box display='flex' justifyContent='end' alignItems='center' mt={3}>
            <Box display='flex'>
              {hasAnalysisNameInput ? (
                isReadyToStartAnalysis ? (
                  <Button
                    variant='contained'
                    onClick={() =>
                      currentDatasetId &&
                      currentAnalysisName &&
                      onCreateAnalysis(projectId, currentDatasetId, currentAnalysisName)
                    }
                  >
                    Start Analysis
                  </Button>
                ) : (
                  <Tooltip title='Select a dataset or upload a new one' placement='top-start'>
                    <Box>
                      <Button disabled variant='contained'>
                        Start Analysis
                      </Button>
                    </Box>
                  </Tooltip>
                )
              ) : (
                <>
                  <Button
                    disabled={!isReadyToStartAnalysis}
                    onClick={() => currentDatasetId && addDataset(projectId, currentDatasetId)}
                    variant='outlined'
                    sx={{
                      mr: 3,
                    }}
                  >
                    Assign to Project
                  </Button>
                  <Button
                    disabled={!isReadyToStartAnalysis}
                    onClick={() =>
                      currentDatasetId &&
                      currentAnalysisName &&
                      onCreateAnalysis(projectId, currentDatasetId, currentAnalysisName)
                    }
                    variant='contained'
                  >
                    Assign and Start Analysis
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
};

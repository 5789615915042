import React, { FC } from 'react';
import { Edit3 } from 'react-feather';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { AnalysisIcon, UnpinIcon } from 'app/shared/icons';

type DataLibraryProps = {
  onCreateAnalysis: () => Promise<void>;
  onEditDataset?: () => void;
  onDetachDataset: () => void;
};

export const DataLibraryActions: FC<DataLibraryProps> = ({
  onCreateAnalysis,
  onEditDataset,
  onDetachDataset,
}) => (
  <Box width='185px'>
    <MenuItem onClick={onCreateAnalysis}>
      <AnalysisIcon />
      <span>Start Analysis</span>
    </MenuItem>
    {!!onEditDataset && (
      <MenuItem onClick={onEditDataset}>
        <Edit3 />
        <span>Edit</span>
      </MenuItem>
    )}
    <MenuItem onClick={onDetachDataset}>
      <UnpinIcon />
      <span>Detach from project</span>
    </MenuItem>
  </Box>
);

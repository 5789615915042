import { ANALYSIS_STATUSES } from 'app/shared/enum/analysis';

export type ProjectDatasets = {
  id: number;
  name: string;
  description: string;
  domain: string;
  status: string;
  is_example: boolean | null;
};

export type ProjectAnalysis = {
  dataset: {
    id: number;
    name: string;
    status: string;
  };
  status: ANALYSIS_STATUSES;
  id: number;
  name: string;
  description: string;
  updated_at: string;
};

export type Project = {
  creator: {
    firstname: string;
    lastname: string;
  };
  id: number;
  name: string;
  is_private: boolean;
  description: string;
  status: string;
  created_at: string;
  updated_at: string;
  datasets: ProjectDatasets[];
  analyses: ProjectAnalysis[];
};

export type ProjectPayload = {
  id?: number;
  name: string;
  description: string;
  onSuccess: () => void;
  is_private?: boolean;
};

export type ProjectActionsPayload = {
  name: string;
  description: string;
  id: number;
  type: boolean;
};

export enum ProjectTypes {
  Public = 'Public',
  Private = 'Private',
}

import React, { ReactNode, RefObject } from 'react';
import { CheckboxFormatterProps } from 'react-data-grid';
import classNames from 'classnames';
import { Checkbox } from 'app/mui/Checkbox';
import { Tooltip } from 'app/mui/Tooltip';
import { Column } from '../Explorer.styles';

export type CheckboxFormatterType = (
  isChangeEnabled: (isShiftKey?: boolean) => boolean
) => (data: CheckboxFormatterProps, ref: RefObject<any>) => ReactNode;

export const CheckboxFormatter: CheckboxFormatterType = (isChangeEnabled) => (data, ref) => {
  const { onChange, ...otherProps } = data;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (isChangeEnabled((e.nativeEvent as MouseEvent).shiftKey)) {
      onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
    }
  };

  return (
    <Checkbox
      ref={ref}
      {...otherProps}
      onChange={handleChange}
      size='small'
      style={{ width: 35, height: 35 }}
    />
  );
};

const checkSelectedColumns = (selectedFeatures: number[], columnKey: number): boolean =>
  selectedFeatures.includes(columnKey);

export const ColumnHeaderCheckboxFormatter = ({ props, handleChange, selectedFeatures }: any) => {
  const { key } = props.column;

  const isChecked = checkSelectedColumns(selectedFeatures, key);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(Number(event.target.value), (event.nativeEvent as MouseEvent).shiftKey);
  };

  return (
    <Tooltip title={props.column.name} placement='top'>
      <Column className={classNames({ highlight: isChecked })}>
        <Checkbox
          checked={isChecked}
          inputProps={{ 'aria-label': 'controlled' }}
          value={key}
          onChange={onCheckboxChange}
          size='small'
          style={{ width: 35, height: 35 }}
        />
        {props.column.name}
      </Column>
    </Tooltip>
  );
};

export const ColumnFormatter = ({ props, selectedFeatures }: any) => {
  const { key } = props.column;
  const value = props.row[props.column.key];

  const isChecked = checkSelectedColumns(selectedFeatures, key);

  return <Column className={classNames({ highlight: isChecked })}>{value}</Column>;
};

import React, { FC } from 'react';
import { Plus } from 'react-feather';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { RoutePath, useNavigate } from 'app/navigation';
import { Button } from 'app/mui/Button';
import { GradientBackground } from 'app/shared/styles/common';
import { Header } from 'app/shared/components/Header/Header';
import { Terms } from 'app/shared/components/Terms/Terms';
import { useHeader } from 'app/shared/components/Header/Header.hooks';
import { NamesEnum } from 'app/shared/enum/names';

export const Welcome: FC = () => {
  const navigate = useNavigate();
  const { user } = useHeader();
  const theme = useTheme();

  return (
    <>
      <Header withHome isTransparent />
      <GradientBackground>
        <Box
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          color='text.secondary'
        >
          <Box
            sx={{
              width: '774px',
              zIndex: '2',
              background: 'rgba(255, 255, 255, 0.5)',
              borderRadius: '8px',
              p: 13,
            }}
          >
            <Typography component='h3' variant='h3' textAlign='center' color='primary.main'>
              Welcome to {NamesEnum.BLUELIGHT}, {user?.firstname}!
            </Typography>
            <Typography component='h6' variant='h6' textAlign='center' mt={3} mb={7}>
              Get started by creating your first analysis
            </Typography>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => navigate(RoutePath.UPLOAD_DATASET)}
              fullWidth
              sx={{ maxWidth: '284px', display: 'flex', m: '0 auto' }}
            >
              <Plus size='24' style={{ marginRight: theme.spacing(1) }} />
              Upload Dataset
            </Button>
          </Box>
        </Box>
        <Terms />
      </GradientBackground>
    </>
  );
};

import React, { FC } from 'react';
import logo from 'app/assets/images/logo.svg';
import logoIcon from 'app/assets/images/logo-icon.svg';
import { LogoImg } from './Logo.styles';

type Logo = {
  width?: number;
  height?: number;
  variant?: 'icon' | 'default';
};

export const Logo: FC<Logo> = ({ width = 32, height = 32, variant }) => {
  if (variant === 'icon') return <LogoImg src={logoIcon} width={width} height={width} />;

  return <LogoImg src={logo} width={width} height={height} />;
};

export enum ANALYSIS_STATUSES {
  NOT_READY = 'not_ready',
  INGEST_STARTED = 'ingest_started',
  INGEST_FINISHED = 'ingest_finished',
  READY = 'ready',
  FAILED = 'failed',
}

export enum COLORING_OPTIONS {
  none = 'none',
  selectedFeatures = 'selected_features',
  selectedDataPoints = 'selected_data_points',
  differentialColoring = 'differential_coloring',
  other = 'other',
}

export enum OPTIONS {
  selectAll = 'select-all',
}

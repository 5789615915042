import { RESET_SCALE_ICON, CLEAR_SELECTION_ICON, INVERT_SELECTION_ICON } from './Chart.config';

// Group One - ScatterPlot, Histogram
// Group Two - Heatmap, ViolinPlot

// TODO: Change 'any' type to ModeBarDefaultButtons[] | ModeBarButton[] when custom buttons are added to plotly types
export const getModebarButtonsForGroupOne = ({
  onResetScale,
  onClearSelection,
  onInvertSelection,
}: {
  onResetScale: () => void;
  onClearSelection: () => void;
  onInvertSelection: () => void;
}): any => [
  ['blaiZoomIn', 'blaiZoomOut'],
  [
    {
      name: 'Reset scale',
      title: 'Reset scale',
      icon: {
        svg: RESET_SCALE_ICON,
      },
      click: onResetScale,
    },
  ],
  ['blaiLasso', 'blaiSelectBox'],
  [
    {
      name: 'Clear selection',
      title: 'Clear selection',
      icon: {
        svg: CLEAR_SELECTION_ICON,
      },
      click: onClearSelection,
    },
  ],
  [
    {
      name: 'Invert selection',
      title: 'Invert selection',
      icon: {
        svg: INVERT_SELECTION_ICON,
      },
      click: onInvertSelection,
    },
  ],
];

export const isInSelectionMode = (dragmode: string | boolean | undefined): boolean =>
  dragmode === 'lasso' || dragmode === 'select';

import type AbstractGraph from 'graphology-types';
// @ts-ignore
import Plotly from 'plotly.js/dist/plotly';

import { graphToSvgRenderer } from 'app/shared/utils/graphToSvgRenderer';
import {
  AnalysisConfig,
  ChartDataType,
  SelectedFeatureType,
} from 'app/screens/Analysis/Analysis.types';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import {
  CategoricalColorMaps,
  ColorLegendType,
} from 'app/screens/Analysis/Coloring/Coloring.types';

const ESCAPE_PATTERN = /["'<>&]/g;

const ESCAPE_MAP: Record<string, string> = {
  '"': '&quot;',
  "'": '&apos;',
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;',
};

const escapeReplacer = (char: string): string => ESCAPE_MAP[char];

const escapeString = (string: string): string => string.replace(ESCAPE_PATTERN, escapeReplacer);

const DEFAULTS = {
  margin: 24,
  width: 1280,
  height: 720,
  scale: 0.75,
};

const downloadFile = (fileName: string, file: string): void => {
  const a = document.createElement('a');
  a.href = file;
  a.download = fileName;
  a.click();
};

const generateSvg = (content: string[]): string => {
  const contentString = content.join('');

  return `<?xml version="1.0" encoding="utf-8"?>
    <svg 
      width="${DEFAULTS.width}" 
      height="${DEFAULTS.height}" 
      viewBox="0 0 ${DEFAULTS.width} ${DEFAULTS.height}" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg"
    >
      ${contentString}
    </svg>`;
};

interface LabelOpts {
  wrapperWidth?: number;
  wrapperHeight?: number;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  textAnchor?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number;
  prefix?: string;
}

const generateLabel = (text: string, opts: LabelOpts): string => {
  const {
    wrapperWidth = DEFAULTS.width,
    wrapperHeight = DEFAULTS.height,
    top = 10,
    left = 10,
    bottom,
    right,
    textAnchor = 'start',
    fontFamily = 'sans-serif',
    fontSize = 14,
    fontWeight = 500,
    prefix,
  } = opts;

  const x = right ? wrapperWidth - right : left;
  const y = bottom ? wrapperHeight - bottom : top;

  return `
    <text 
      x="${x}" 
      y="${y}" 
      color="#122239"
      font-family="${fontFamily}"
      font-size="${fontSize}" 
      font-weight="${fontWeight}"
      text-anchor="${textAnchor}"
    >
      ${
        prefix
          ? `<tspan font-weight="300">${escapeString(prefix)}</tspan> ${escapeString(text)}`
          : escapeString(text)
      }
    </text>
  `;
};

const generateNumericLegend = (legend: ColorLegendType | null): string => {
  if (!legend) return '';

  const { colorBounds = [], functionName = '', dataBounds = [] } = legend;
  const offset = Number((100 / colorBounds.length).toFixed(2));
  const linearGradientArray = colorBounds.map((color, index) => {
    const offsetNumber = index + 1 === colorBounds.length ? 100 : offset * index;

    return `<stop offset="${offsetNumber.toFixed(1)}%" stop-color="${color}" />`;
  });
  const gradientId = escapeString(
    `gradient_${functionName}_${colorBounds.length}`.split(' ').join('_')
  );

  const legendWidth = 11;
  const legendHeight = 350;
  const heightBottomPosition = DEFAULTS.height - 60;
  const boundValueLeftPosition = 44;

  const gradientDefs = `<defs>
    <linearGradient 
      gradientTransform="rotate(90%)"
      id="${gradientId}" 
      x1="${DEFAULTS.margin}" 
      y1="${heightBottomPosition}" 
      x2="${DEFAULTS.margin + legendWidth}" 
      y2="${heightBottomPosition - legendHeight}" 
      gradientUnits="userSpaceOnUse"
    >
      ${linearGradientArray.join('')}
    </linearGradient>
  </defs>`;

  const line = `
    <line 
      fill="none" 
      x1="${DEFAULTS.margin}" 
      y1="${heightBottomPosition}" 
      x2="${DEFAULTS.margin}" 
      y2="${heightBottomPosition - legendHeight}" 
      stroke-width="${legendWidth}" 
      stroke="url('#${gradientId}')"
    />`;

  const dataBoundsArray = dataBounds
    .map((bound, index) =>
      generateLabel(bound.toFixed(2), {
        bottom: index ? legendHeight + 54 : 57,
        left: boundValueLeftPosition,
      })
    )
    .join('');

  const functionNameText = generateLabel(functionName, {
    bottom: DEFAULTS.margin,
    left: DEFAULTS.margin,
  });

  return [gradientDefs, line, dataBoundsArray, functionNameText].join('');
};

const generateCategoryLegend = (legend: ColorLegendType | null): string => {
  if (!legend) return '';

  const { categories = [], functionName = '', colorMap } = legend;
  const reversedList = categories.slice().reverse();
  const circleRadius = 6;
  const bottomMargin = 60;
  const bottomValueMargin = 56;
  const heightBottomPosition = DEFAULTS.height - bottomMargin;
  const colorMargin = 28;
  const boundValueLeftPosition = 40;
  const isMonochromatic = colorMap === CategoricalColorMaps.Monochromatic;

  const categoriesArray = reversedList.map(
    (category, index) => `
    <g>
      <circle 
        r="${circleRadius}" 
        cx="${DEFAULTS.margin}" 
        cy="${heightBottomPosition - colorMargin * index}" 
        fill="${category.color}"
        stroke-width="${isMonochromatic && !index ? 2 : 0}"
        stroke="black"
      />
      ${generateLabel(category.value || '', {
        left: boundValueLeftPosition,
        bottom: bottomValueMargin + colorMargin * index,
      })}
    </g>
  `
  );

  const functionNameText = generateLabel(functionName, {
    bottom: DEFAULTS.margin,
    left: DEFAULTS.margin,
  });

  return `<g>${[...categoriesArray, functionNameText].join('')}</g>`;
};

export const exportLandscapeSvg = (
  graph: AbstractGraph,
  metadata: AnalysisConfig,
  coloringType: SelectedFeatureType | null,
  legend: ColorLegendType | null,
  projectName: string,
  currentAnalysisName: string
): void => {
  const graphContent = graphToSvgRenderer(graph, DEFAULTS);
  const graphNameContent = generateLabel(metadata.name, {
    top: DEFAULTS.margin,
    left: DEFAULTS.margin,
  });
  const coloringContent = generateLabel(coloringType?.feature_name || '-', {
    top: DEFAULTS.margin,
    right: DEFAULTS.margin,
    prefix: 'Colored by',
    textAnchor: 'end',
  });

  const legendSvg =
    legend?.type === 'line' ? generateNumericLegend(legend) : generateCategoryLegend(legend);
  const svgContent = generateSvg([legendSvg, graphContent, graphNameContent, coloringContent]);
  const fileName = `${metadata.name}-${currentAnalysisName}-${projectName}.svg`;
  const file = new File([svgContent], fileName, { type: 'image/svg+xml' });
  const dataUrl = window.URL.createObjectURL(file);

  downloadFile(fileName, dataUrl);

  window.URL.revokeObjectURL(dataUrl);
};

const generateChartSvgString = async (chart: ChartDataType): Promise<string> => {
  const divId = `${chart.type}_${chart.id}`;
  const plot = document.getElementById(divId);

  const chartDataUrl = await Plotly.toImage(plot, {
    format: 'svg',
    width: DEFAULTS.width,
    height: DEFAULTS.height,
    scale: DEFAULTS.scale,
  });

  const chartUrl = chartDataUrl.split(',')[1];
  const chartString = decodeURIComponent(chartUrl);

  const parser = new DOMParser();
  const doc = parser.parseFromString(chartString, 'text/html');
  const svgHtml = doc.getElementsByTagName('body')[0].innerHTML;
  const svgEncoded = encodeURIComponent(svgHtml);

  return `${chartDataUrl.split(',')[0]},${svgEncoded}`;
};

export const exportChartSvg = async (
  visualizationType: VisualisationType,
  chart: ChartDataType,
  coloringType: SelectedFeatureType | null,
  legend: ColorLegendType | null,
  projectName: string,
  currentAnalysisName: string
): Promise<void> => {
  const chartDataUrl = await generateChartSvgString(chart);

  const isScatterPlot = visualizationType === VisualisationType.SCATTER_PLOT;

  const graphNameContent = generateLabel(chart.name, {
    top: DEFAULTS.margin,
    left: DEFAULTS.margin,
  });

  const coloringContent = isScatterPlot
    ? generateLabel(coloringType?.feature_name || '-', {
        top: DEFAULTS.margin,
        right: DEFAULTS.margin,
        prefix: 'Colored by',
        textAnchor: 'end',
      })
    : '';
  const legendSvg =
    legend?.type === 'line' ? generateNumericLegend(legend) : generateCategoryLegend(legend);

  const chartWidth = DEFAULTS.width * DEFAULTS.scale;
  const chartHeight = DEFAULTS.height * DEFAULTS.scale;
  const chartLeftPosition = DEFAULTS.width - chartWidth;
  const chartTopPosition = (DEFAULTS.height - chartHeight) / 2;

  const image = `
  <image 
    x="${chartLeftPosition}" 
    y="${chartTopPosition}" 
    width="${chartWidth}" 
    height="${chartHeight}" 
    href="${chartDataUrl}"
  />
  `;

  const svgContent = generateSvg([graphNameContent, coloringContent, legendSvg, image]);
  const fileName = `${chart.name}-${currentAnalysisName}-${projectName}.svg`;
  const file = new File([svgContent], fileName, { type: 'image/svg+xml' });
  const fileUrl = window.URL.createObjectURL(file);

  downloadFile(fileName, fileUrl);
};

export enum RoutePath {
  WELCOME = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/change-password',
  UPLOAD_DATASET = '/upload-dataset',
  DATA_LIBRARY = '/data-library',
  PROJECTS = '/projects',
  PROJECT = '/projects/:id',
  ANALYSIS = '/projects/:projectId/analysis/:analysisId',
}

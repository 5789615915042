import React, { FC } from 'react';
import styled from '@emotion/styled/macro';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import retryVisualizationImg from 'app/assets/images/visualization/retry-visualization.svg';

const StyledRetryGraph = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    maxWidth: '30%',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  '& p': {
    color: theme.palette.error.dark,
    width: '335px',
    textAlign: 'center',
  },
}));
export const RetryGraph: FC = () => (
  <Box height='100%'>
    <StyledRetryGraph>
      <Box component='img' src={retryVisualizationImg} alt='retry visualization' />
      <Typography variant='body1'>
        Graph generation failed. Please note that retry process is in progress, it may take some
        time.
      </Typography>
    </StyledRetryGraph>
  </Box>
);

import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, VisualizationDialogWrapper } from 'app/shared/styles/createForm';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import { CustomSubsetSelect } from 'app/shared/components/CustomSubsetSelect/CustomSubsetSelect';
import { FilterType, GroupType } from 'app/screens/Analysis/Analysis.types';
import {
  handleNumericDecrementChange,
  handleNumericValueChange,
  MISSING_VALUE_ERROR_MESSAGE,
} from 'app/screens/Analysis/Analysis.utils';
import { OPTIONS } from 'app/shared/enum/analysis';
import { StyledTextField } from 'app/mui/TextField';
import { Tooltip } from 'app/mui/Tooltip';
import { useAlert } from 'app/shared/hooks/useAlert';
import { useChartSourceData } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceData.hooks';
import { VisualizationTypes } from './VisualizationType/VisualizationType';
import { ConfigurationsType } from './Configurations/Configurations';
import { LandscapeOptions } from './LanscapeOptions/LanscapeOptions';
import { parameterOptions } from './DataPartition/DataPartition.constants';
import { mapperMatrixLandscapeRows } from '../AnalysisSidebar/SourceDataDialog/SourceData.utils';
import { SourceDataSearchSelect } from '../AnalysisSidebar/SourceDataDialog/SourceDataSearchSelect';
import { VisualisationType } from '../AnalysisSidebar/Configure/Configure';
import {
  Row,
  SourceDataLandscapesOptions,
  VisualizationRow,
} from '../AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import {
  DataPartitionEnum,
  PartitionRow,
} from '../AnalysisSidebar/DataPartitionsDialog/DataPartitionsDialog';
import {
  maxLengthHelperText,
  maxNameLength,
  nameLimitTooltipText,
} from '../AnalysisSidebar/Configure/ConfigureForms/ConfigureLandscape';

type VisualizationDialogProps = {
  currentPanel: string;
  openDialog: boolean;
  handleClose: () => void;
};

type CreateGraphType = { graph_id: string; task_id: string };

const DEFAULT_CONFIG = {
  filters: [
    {
      equalize_histogram: false,
      filter_values_idx: null,
      group_id: null,
      group_name: null,
      max_link_distance: 2,
      metadata_name: null,
      n_bins: 8,
      parameter: 'L1 eccentricity',
    },
  ],
  neighbor_graph_config: {
    distance_metric: 'euclidean',
    use_default: true,
    K: null,
    M: null,
    min_neighbors: null,
  },
  nerve_graph_config: {
    L_components: 5,
    L_cover: 5,
  },
};

export const VisualizationDialog: FC<VisualizationDialogProps> = ({
  currentPanel,
  openDialog,
  handleClose,
}) => {
  const { defaultDataLandscapeConfig, defaultFeatureLandscapeConfig } = useAnalysis();

  const [type, setType] = useState<string>(VisualisationType.DATA_LANDSCAPE);

  const config =
    type === VisualisationType.DATA_LANDSCAPE
      ? defaultDataLandscapeConfig
      : defaultFeatureLandscapeConfig;

  const defaultBins =
    (config?.filters && config.filters[0].n_bins.toString()) ||
    DEFAULT_CONFIG.filters[0].n_bins.toString();

  const defaultDistance =
    (config?.filters && config.filters[0].max_link_distance.toString()) ||
    DEFAULT_CONFIG.filters[0].max_link_distance.toString();

  const defaultEqualizeHistogram =
    (config?.filters && config.filters[0].equalize_histogram) ||
    DEFAULT_CONFIG.filters[0].equalize_histogram;

  const defaultNeighborGraphConfig =
    config?.neighbor_graph_config || DEFAULT_CONFIG.neighbor_graph_config;
  const defaultNerveGraphConfig = config?.nerve_graph_config || DEFAULT_CONFIG.nerve_graph_config;

  const [visualizationName, setVisualizationName] = useState<string>('');

  const [dataRowDataLandscape, setDataRowDataLandscape] = useState<Row>(undefined);
  const [dataRowFeatureLandscape, setDataRowFeatureLandscape] = useState<Row>(undefined);

  const [featureRowDataLandscape, setFeatureRowDataLandscape] = useState<Row>(undefined);
  const [featureRowFeatureLandscape, setFeatureRowFeatureLandscape] = useState<Row>(undefined);
  const [customDataRowsDataLandscape, setCustomDataRowsDataLandscape] = useState<number[]>([]);
  const [customDataRowsFeatureLandscape, setCustomDataRowsFeatureLandscape] = useState<number[]>(
    []
  );
  const [customFeatureRowsDataLandscape, setCustomFeatureRowsDataLandscape] = useState<number[]>(
    []
  );
  const [customFeatureRowsFeatureLandscape, setCustomFeatureRowsFeatureLandscape] = useState<
    number[]
  >([]);

  const [dataPartitionFeature, setDataPartitionFeature] = useState<Row>(undefined);
  const [dataPartitionData, setDataPartitionData] = useState<Row>(undefined);
  const [dataPartitionGlobal, setDataPartitionGlobal] = useState<VisualizationRow>(
    parameterOptions[0]
  );
  const [dataPartitionMetadata, setDataPartitionMetadata] = useState<PartitionRow | undefined>(
    undefined
  );
  const [groupType, setGroupType] = useState<string>(DataPartitionEnum.GLOBAL);

  const [bins, setBins] = useState<string>(defaultBins);
  const [distance, setDistance] = useState<string>(defaultDistance);
  const [checkedEqualize, setCheckedEqualize] = useState(defaultEqualizeHistogram);

  const [configurations, setConfigurations] = useState<ConfigurationsType>({
    neighbor_graph_config: defaultNeighborGraphConfig,
    nerve_graph_config: defaultNerveGraphConfig,
  });

  const [dataFilters, setDataFilters] = useState<FilterType[]>([]);
  const [featureFilters, setFeatureFilters] = useState<FilterType[]>([]);

  const { analysisId } = useParams();
  const { showErrorMessage } = useAlert();

  const {
    getFeaturesList,
    features,
    dataPointsCount,
    groups,
    addChart,
    setChart,
    selectedDataRows,
    getGroup,
    dataLandscapeVisualization,
    featureLandscapeVisualization,
    createGraph,
    selectedFeatures,
    isFeatureWithMissingValues,
    clearSelection,
    panConfig,
  } = useAnalysis();

  const numericFeatures = useMemo(() => features.filter((f) => f.type === 'numeric'), [features]);

  const isDataLandscape = type === VisualisationType.DATA_LANDSCAPE;

  const isFeatureLandscape = type === VisualisationType.FEATURE_LANDSCAPE;

  const isScatterPlot = type === VisualisationType.SCATTER_PLOT;

  const isHistogram = type === VisualisationType.HISTOGRAM;

  const isHeatmap = type === VisualisationType.HEATMAP;

  const isViolinPlot = type === VisualisationType.VIOLIN_PLOT;

  const isChart = isScatterPlot || isHistogram || isHeatmap || isViolinPlot;

  const onDataRow = (): Row | undefined => {
    if (isDataLandscape) {
      return dataRowDataLandscape;
    }
    if (isFeatureLandscape) {
      return dataRowFeatureLandscape;
    }
    return undefined;
  };

  const sourceDataDataRow = onDataRow();

  const onCustomDataRows = (): number[] | undefined => {
    if (isDataLandscape) {
      return customDataRowsDataLandscape;
    }
    if (isFeatureLandscape) {
      return customDataRowsFeatureLandscape;
    }
    return undefined;
  };

  const sourceDataCustomDataRows = onCustomDataRows();

  const onCustomFeatureRows = (): number[] | undefined => {
    if (isDataLandscape) {
      return customFeatureRowsDataLandscape;
    }
    if (isFeatureLandscape) {
      return customFeatureRowsFeatureLandscape;
    }
    return undefined;
  };

  const sourceDataCustomFeatureRows = onCustomFeatureRows();

  const onFeatureRow = (): Row | undefined => {
    if (isDataLandscape) {
      return featureRowDataLandscape;
    }
    if (isFeatureLandscape) {
      return featureRowFeatureLandscape;
    }
    return undefined;
  };

  const sourceDataFeatureRow = onFeatureRow();

  const {
    dataPointSubset,
    dataPoints,
    featureX,
    featureY,
    feature,
    setFeatureX,
    setFeatureY,
    setFeature,
    customDataRows,
    dataRow,
    featureRow,
    onFeatureRowChange,
    onDataRowChange,
    onCustomDataRowsChange,
    isSourceDataGroupOption,
    sourceDataRowsGroupId,
    featuresGroupId,
  } = useChartSourceData({
    groups,
    dataPointsCount,
    sourceDataDataRow,
    sourceDataFeatureRow,
    type,
  });

  useEffect(() => {
    getFeaturesList(Number(analysisId));
  }, []);

  const onConfigurationsChange = (value: ConfigurationsType): void => {
    setConfigurations(value);
  };

  const handleChangeGroupType = (event: React.MouseEvent<HTMLElement>, newType: string): void => {
    setGroupType(newType);
  };

  const onTypeChange = (value: string): void => {
    setType(value);
    setGroupType(DataPartitionEnum.GLOBAL);
  };

  const onFeatureXChange = (value: Row): void => {
    setFeatureX(value);
  };

  const onFeatureYChange = (value: Row): void => {
    setFeatureY(value);
  };

  const onFeatureHistogramChange = (value: Row): void => {
    setFeature(value);
  };

  const onDataPartitionFeatureChange = (value: Row): void => {
    setDataPartitionFeature(value);
  };

  const onDataPartitionGlobalChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const dataPartitionGlobalValue = {
      feature_name: (event.target as HTMLInputElement).value,
      id: 0,
    };
    setDataPartitionGlobal(dataPartitionGlobalValue);
  };

  const handleChangeBins = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 1, setBins);
  };

  const binsDecrement = (): void => {
    handleNumericDecrementChange(bins, 1, setBins);
  };

  const binsIncrement = (): void => {
    setBins((Number(bins) + 1).toString());
  };

  const handleChangeEqualize = (event: ChangeEvent<HTMLInputElement>): void => {
    setCheckedEqualize(event.target.checked);
  };

  const handleChangeDistance = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setDistance);
  };

  const distanceDecrement = (): void => {
    handleNumericDecrementChange(distance, 0, setDistance);
  };

  const distanceIncrement = (): void => {
    setDistance((Number(distance) + 1).toString());
  };

  const onDataPartitionDataChange = (value: Row): void => {
    setDataPartitionData(value);
  };

  const onDataPartitionMetadataChange = (value: PartitionRow): void => {
    setDataPartitionMetadata(value);
  };

  const mapperMatrixConfigRows = async (): Promise<number[] | []> => {
    if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA) {
      return Array.from(Array(dataPointsCount).keys());
    }
    if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA) {
      return selectedDataRows;
    }
    if (dataRow && dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM) {
      return customDataRows;
    }
    if (dataRow) {
      const response = (await getGroup(Number(analysisId), dataRow.id)) as GroupType;
      return response.rows;
    }
    return [];
  };

  const visualizationFeatures = (): number[] | [] => {
    if (type === VisualisationType.SCATTER_PLOT && featureX && featureY) {
      return [featureX.id, featureY.id];
    }
    if (type === VisualisationType.HEATMAP && featureRow) {
      return featureRow.map((f) => f.id);
    }
    if (type === VisualisationType.VIOLIN_PLOT && featureRow) {
      return featureRow.map((f) => f.id);
    }
    if (type === VisualisationType.HISTOGRAM && feature) {
      return [feature.id];
    }
    return [];
  };

  const rowsGroupId = isSourceDataGroupOption ? dataRow?.id : undefined;

  const mapperMatrixConfigCols = async () => {
    if (
      sourceDataFeatureRow &&
      sourceDataFeatureRow.feature_name === SourceDataLandscapesOptions.ALL_FEATURES
    ) {
      const allFeatures = features.map((row: VisualizationRow) => row.id);
      return allFeatures;
    }
    if (
      sourceDataFeatureRow &&
      sourceDataFeatureRow.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES
    ) {
      return selectedFeatures;
    }
    if (
      sourceDataFeatureRow &&
      sourceDataFeatureRow.feature_name === SourceDataLandscapesOptions.CUSTOM
    ) {
      return sourceDataCustomFeatureRows;
    }
    if (sourceDataFeatureRow) {
      const response = (await getGroup(Number(analysisId), sourceDataFeatureRow.id)) as GroupType;
      return response.features;
    }
    if (isDataLandscape) {
      if (dataLandscapeVisualization) {
        return dataLandscapeVisualization.cols;
      }
      return [];
    }
    if (isFeatureLandscape) {
      if (featureLandscapeVisualization) {
        return featureLandscapeVisualization.cols;
      }
      return [];
    }
    return [];
  };

  const getCustomFilterParam = useMemo(() => {
    if (groupType === DataPartitionEnum.FEATURE && dataPartitionFeature) {
      return {
        filter_values_idx: dataPartitionFeature.id,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    if (groupType === DataPartitionEnum.DATA && dataPartitionData) {
      return {
        group_id: dataPartitionData.id,
        group_name: dataPartitionData.feature_name,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    if (groupType === DataPartitionEnum.METADATA && dataPartitionMetadata) {
      return {
        metadata_name: dataPartitionMetadata.id as string,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    if (groupType === DataPartitionEnum.GLOBAL && dataPartitionGlobal) {
      return {
        parameter: dataPartitionGlobal.feature_name,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    return undefined;
  }, [
    dataPartitionFeature,
    dataPartitionGlobal,
    dataPartitionMetadata,
    dataPartitionData,
    bins,
    distance,
    checkedEqualize,
    type,
    groupType,
  ]);

  const getFilters = (): FilterType[] => {
    if (isDataLandscape) {
      return dataFilters;
    }
    return featureFilters;
  };

  const filters = getFilters();

  useEffect(() => {
    if (isDataLandscape || isFeatureLandscape) {
      const customFilters = getCustomFilterParam;

      if (isDataLandscape && customFilters) {
        setDataFilters([customFilters]);
      }
      if (isFeatureLandscape && customFilters) {
        setFeatureFilters([customFilters]);
      }
    }
  }, [
    dataPartitionFeature,
    dataPartitionGlobal,
    dataPartitionMetadata,
    dataPartitionData,
    bins,
    distance,
    checkedEqualize,
    type,
  ]);

  const handleSaveSourceDataDataLandscape = async (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ): Promise<void> => {
    setDataRowDataLandscape(dataRowValue);
    setFeatureRowDataLandscape(featureRowValue);
    if (customDataRowsValue && customDataRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomDataPoints = Array.from(Array(dataPointsCount).keys());
      setCustomDataRowsDataLandscape(allCustomDataPoints);
    } else if (customDataRowsValue) {
      const selectedRows = customDataRowsValue.map((row: VisualizationRow) => row.id);
      setCustomDataRowsDataLandscape(selectedRows);
    }
    if (customFeatureRowsValue && customFeatureRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomFeatures = features.map((row: VisualizationRow) => row.id) as number[];
      setCustomFeatureRowsDataLandscape(allCustomFeatures);
    } else if (customFeatureRowsValue) {
      const selectedRows = customFeatureRowsValue.map((row: VisualizationRow) => row.id);
      setCustomFeatureRowsDataLandscape(selectedRows);
    }
  };

  const handleSaveSourceDataFeatureLandscape = async (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ): Promise<void> => {
    setDataRowFeatureLandscape(dataRowValue);
    setFeatureRowFeatureLandscape(featureRowValue);
    if (customDataRowsValue && customDataRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomDataPoints = Array.from(Array(dataPointsCount).keys());
      setCustomDataRowsFeatureLandscape(allCustomDataPoints);
    } else if (customDataRowsValue) {
      const selectedRows = customDataRowsValue.map((row: VisualizationRow) => row.id);
      setCustomDataRowsFeatureLandscape(selectedRows);
    }
    if (customFeatureRowsValue && customFeatureRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomFeatures = features.map((row: VisualizationRow) => row.id) as number[];
      setCustomFeatureRowsFeatureLandscape(allCustomFeatures);
    } else if (customFeatureRowsValue) {
      const selectedRows = customFeatureRowsValue.map((row: VisualizationRow) => row.id);
      setCustomFeatureRowsFeatureLandscape(selectedRows);
    }
  };

  const createVisualization = async (): Promise<void> => {
    try {
      if (analysisId && isChart) {
        const dataPointRows = await mapperMatrixConfigRows();

        const chartDefaultName =
          type === VisualisationType.HISTOGRAM && feature
            ? `Histogram: ${feature.feature_name}`
            : visualizationName;

        const chartName = visualizationName.length === 0 ? chartDefaultName : visualizationName;

        const response = await addChart(Number(analysisId), {
          feature_ids: visualizationFeatures(),
          data_points: dataPointRows,
          chart_type: type,
          data_filters: [], // TODO: Should be filter data
          rows_group_id: rowsGroupId,
          panel: currentPanel,
          name: chartName,
          default_name: chartDefaultName,
        });

        if (response.id) {
          setChart(type, response);
          handleClose();
        }
      }

      if (analysisId && (isFeatureLandscape || isDataLandscape)) {
        if (dataPointsCount) {
          const rows = await mapperMatrixLandscapeRows(
            sourceDataDataRow,
            sourceDataCustomDataRows,
            Number(analysisId),
            selectedDataRows,
            getGroup,
            dataLandscapeVisualization,
            featureLandscapeVisualization,
            type,
            dataPointsCount
          );

          const cols = await mapperMatrixConfigCols();

          if (cols) {
            const hasMissingValuesInSourceData = isFeatureWithMissingValues(cols, features);

            if (hasMissingValuesInSourceData) {
              throw new Error(MISSING_VALUE_ERROR_MESSAGE);
            }
          }

          if (filters.length && filters[0].filter_values_idx) {
            const hasMissingValuesInDataPartition = isFeatureWithMissingValues(
              [filters[0].filter_values_idx],
              features
            );

            if (hasMissingValuesInDataPartition) {
              throw new Error(MISSING_VALUE_ERROR_MESSAGE);
            }
          }

          const rows_group_id = sourceDataRowsGroupId;

          const features_group_id = featuresGroupId;

          const { distance_metric, K, M, min_neighbors, use_default } =
            configurations.neighbor_graph_config;

          const neighborGraphConfig = {
            distance_metric,
            use_default,
            K: use_default || K === null ? null : Number(K),
            M: use_default || M === null ? null : Number(K) + Number(M),
            min_neighbors: use_default || min_neighbors === null ? null : Number(min_neighbors),
          };

          const response = (await createGraph({
            analysis_id: Number(analysisId),
            type,
            name: visualizationName,
            panel: currentPanel,
            config: {
              mapper_matrix_config: {
                rows,
                cols,
                rows_group_id,
                features_group_id,
              },
              neighbor_graph_config: neighborGraphConfig,
              nerve_graph_config: configurations.nerve_graph_config,
              filters: filters.length > 0 ? filters : undefined,
            },
          })) as CreateGraphType;

          const currentPanelConfig = panConfig.find((p) => p.panel === currentPanel);

          if (currentPanelConfig?.id) {
            clearSelection(currentPanelConfig.id, currentPanelConfig.type);
          }

          if (response.task_id) {
            handleClose();
          }
        }
      }
    } catch (e: any) {
      showErrorMessage({
        title: e.message,
      });
    }
  };

  const isDisabledBySelectedData =
    dataRow &&
    dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA &&
    selectedDataRows.length === 0;
  const isDisabledByCustomRows =
    dataRow &&
    dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM &&
    customDataRows.length === 0;
  const isDisabledByName = visualizationName.length > maxNameLength;
  const isDisabledForScatterPlot = (isScatterPlot && !featureX) || (isScatterPlot && !featureY);
  const isDisabledForDataLandscape =
    isDataLandscape &&
    (!dataRowDataLandscape ||
      !featureRowDataLandscape ||
      (dataRowDataLandscape?.feature_name === SourceDataLandscapesOptions.CUSTOM &&
        customDataRowsDataLandscape.length === 0) ||
      (featureRowDataLandscape?.feature_name === SourceDataLandscapesOptions.CUSTOM &&
        customFeatureRowsDataLandscape.length === 0) ||
      (featureRowDataLandscape?.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES &&
        selectedFeatures.length === 0) ||
      (dataRowDataLandscape?.feature_name === SourceDataLandscapesOptions.SELECTED_DATA &&
        selectedDataRows.length === 0));
  const isDisabledForFeatureLandscape =
    isFeatureLandscape &&
    (!dataRowFeatureLandscape ||
      !featureRowFeatureLandscape ||
      (dataRowFeatureLandscape?.feature_name === SourceDataLandscapesOptions.CUSTOM &&
        customDataRowsFeatureLandscape.length === 0) ||
      (featureRowFeatureLandscape?.feature_name === SourceDataLandscapesOptions.CUSTOM &&
        customFeatureRowsFeatureLandscape.length === 0) ||
      (featureRowFeatureLandscape?.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES &&
        selectedFeatures.length === 0) ||
      (dataRowFeatureLandscape?.feature_name === SourceDataLandscapesOptions.SELECTED_DATA &&
        selectedDataRows.length === 0));
  const isDisabledForHeatmap = isHeatmap && featureRow && featureRow.length < 2;
  const isDisabledForViolinPlot = isViolinPlot && featureRow && featureRow.length < 1;
  const isDisabledForHistogram = isHistogram && !feature;

  const isUpdateVisualizationDisabled =
    ((!dataRow ||
      isDisabledBySelectedData ||
      isDisabledByCustomRows ||
      isDisabledForScatterPlot ||
      isDisabledForHeatmap ||
      isDisabledForViolinPlot ||
      isDisabledForHistogram) &&
      isChart) ||
    isDisabledByName ||
    isDisabledForDataLandscape ||
    isDisabledForFeatureLandscape;

  const textFieldRef = useRef(null);

  return (
    <VisualizationDialogWrapper
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box width='720px' minHeight='575px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              Create Visualization
            </Typography>
            <StyledCloseIcon onClick={handleClose} size={24} />
          </Box>
          <VisualizationTypes type={type} onTypeChange={onTypeChange} />

          <Box mt={3}>
            <StyledTextField
              inputRef={textFieldRef}
              error={visualizationName.length > maxNameLength}
              helperText={maxLengthHelperText(visualizationName.length)}
              label='Visualization name'
              value={visualizationName}
              onChange={(e) => setVisualizationName(e.target.value)}
            />
          </Box>

          {(isViolinPlot || isHeatmap || isHistogram || isScatterPlot) && (
            <Typography variant='subtitle1' mt={3}>
              Source Data
            </Typography>
          )}
          {features && (
            <>
              <Box mt={2} display='flex' justifyContent='space-between' gap='16px'>
                {isDataLandscape && (
                  <LandscapeOptions
                    type={type}
                    handleSaveSourceData={handleSaveSourceDataDataLandscape}
                    sourceDataDataRow={sourceDataDataRow}
                    sourceDataCustomDataRows={sourceDataCustomDataRows}
                    sourceDataFeatureRow={sourceDataFeatureRow}
                    sourceDataCustomFeatureRows={sourceDataCustomFeatureRows}
                    onConfigurationsChange={onConfigurationsChange}
                    visualization={dataLandscapeVisualization}
                    onDataPartitionFeatureChange={onDataPartitionFeatureChange}
                    onDataPartitionDataChange={onDataPartitionDataChange}
                    onDataPartitionGlobalChange={onDataPartitionGlobalChange}
                    onDataPartitionMetadataChange={onDataPartitionMetadataChange}
                    handleChangeBins={handleChangeBins}
                    binsDecrement={binsDecrement}
                    binsIncrement={binsIncrement}
                    handleChangeEqualize={handleChangeEqualize}
                    handleChangeDistance={handleChangeDistance}
                    distanceDecrement={distanceDecrement}
                    distanceIncrement={distanceIncrement}
                    bins={bins}
                    distance={distance}
                    checkedEqualize={checkedEqualize}
                    dataPartitionFeature={dataPartitionFeature}
                    dataPartitionData={dataPartitionData}
                    dataPartitionGlobal={dataPartitionGlobal}
                    dataPartitionMetadata={dataPartitionMetadata}
                    handleChangeGroupType={handleChangeGroupType}
                    groupType={groupType}
                  />
                )}
                {isFeatureLandscape && (
                  <LandscapeOptions
                    type={type}
                    handleSaveSourceData={handleSaveSourceDataFeatureLandscape}
                    sourceDataDataRow={sourceDataDataRow}
                    sourceDataCustomDataRows={sourceDataCustomDataRows}
                    sourceDataFeatureRow={sourceDataFeatureRow}
                    sourceDataCustomFeatureRows={sourceDataCustomFeatureRows}
                    onConfigurationsChange={onConfigurationsChange}
                    visualization={featureLandscapeVisualization}
                    onDataPartitionFeatureChange={onDataPartitionFeatureChange}
                    onDataPartitionDataChange={onDataPartitionDataChange}
                    onDataPartitionGlobalChange={onDataPartitionGlobalChange}
                    onDataPartitionMetadataChange={onDataPartitionMetadataChange}
                    handleChangeBins={handleChangeBins}
                    binsDecrement={binsDecrement}
                    binsIncrement={binsIncrement}
                    handleChangeEqualize={handleChangeEqualize}
                    handleChangeDistance={handleChangeDistance}
                    distanceDecrement={distanceDecrement}
                    distanceIncrement={distanceIncrement}
                    bins={bins}
                    distance={distance}
                    checkedEqualize={checkedEqualize}
                    dataPartitionFeature={dataPartitionFeature}
                    dataPartitionData={dataPartitionData}
                    dataPartitionGlobal={dataPartitionGlobal}
                    dataPartitionMetadata={dataPartitionMetadata}
                    handleChangeGroupType={handleChangeGroupType}
                    groupType={groupType}
                  />
                )}
                {isScatterPlot && (
                  <>
                    <SourceDataSearchSelect
                      rows={features}
                      rowLabel='Feature x'
                      onRowChange={onFeatureXChange}
                    />
                    <SourceDataSearchSelect
                      rows={features}
                      rowLabel='Feature y'
                      onRowChange={onFeatureYChange}
                    />
                  </>
                )}
                {isHistogram && (
                  <SourceDataSearchSelect
                    rows={features}
                    rowLabel='Feature (only 1 should be selected)'
                    onRowChange={onFeatureHistogramChange}
                  />
                )}
                {isHeatmap && (
                  <SourceDataSearchSelect
                    multiple
                    limit={5}
                    rows={numericFeatures}
                    rowLabel='Feature (from 2 to 5 should be selected)'
                    onRowChange={onFeatureRowChange}
                  />
                )}
                {isViolinPlot && (
                  <SourceDataSearchSelect
                    multiple
                    limit={5}
                    rows={numericFeatures}
                    rowLabel='Feature (from 1 to 5 should be selected)'
                    onRowChange={onFeatureRowChange}
                  />
                )}
              </Box>
              <Box mt={3}>
                {dataPointSubset &&
                  dataPoints &&
                  (isViolinPlot || isHeatmap || isHistogram || isScatterPlot) && (
                    <CustomSubsetSelect
                      rows={dataPoints}
                      onRowsChange={onCustomDataRowsChange}
                      label='Data points'
                      showSelect={dataRow?.feature_name === SourceDataLandscapesOptions.CUSTOM}
                      maxHeight={115}
                    >
                      <SourceDataSearchSelect
                        rows={dataPointSubset}
                        rowLabel='Data points subset'
                        onRowChange={onDataRowChange}
                        defaultValue={dataPointSubset[0]}
                      />
                    </CustomSubsetSelect>
                  )}
              </Box>
            </>
          )}

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Tooltip
              title={visualizationName.length > maxNameLength ? nameLimitTooltipText : ''}
              placement='top-start'
            >
              <Box>
                <Button
                  onClick={createVisualization}
                  variant='contained'
                  disabled={isUpdateVisualizationDisabled}
                >
                  Create Visualization
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </VisualizationDialogWrapper>
  );
};

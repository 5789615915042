import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import noResultsImage from 'app/assets/images/projects/project-failed-search.svg';

type SearchNoResultsProps = {
  text: string;
};

export const SearchNoResults: FC<SearchNoResultsProps> = ({ text }) => (
  <Box textAlign='center' width='100%' height='86px'>
    <Box component='img' src={noResultsImage} mt={1} mb={1} alt={text} />
    <Typography color='text.secondary' variant='body1'>
      {text}
    </Typography>
  </Box>
);

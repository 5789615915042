import React, { FC } from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
};

export const FullScreenExit: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_9590_459423)'>
      <path
        d='M14.9999 15.9987C15.2448 15.9987 15.4812 16.0887 15.6643 16.2514C15.8473 16.4142 15.9642 16.6384 15.9929 16.8817L15.9999 16.9987V21.9987C15.9996 22.2536 15.902 22.4987 15.727 22.6841C15.5521 22.8694 15.3129 22.9809 15.0585 22.9959C14.804 23.0108 14.5535 22.928 14.3581 22.7644C14.1626 22.6008 14.037 22.3688 14.0069 22.1157L13.9999 21.9987V19.4127L8.70688 24.7057C8.52693 24.885 8.28545 24.9892 8.0315 24.9969C7.77755 25.0047 7.53017 24.9155 7.33961 24.7474C7.14904 24.5794 7.02958 24.3451 7.00549 24.0922C6.98139 23.8393 7.05447 23.5867 7.20988 23.3857L7.29288 23.2917L12.5859 17.9987H9.99988C9.745 17.9984 9.49985 17.9008 9.31452 17.7258C9.12918 17.5509 9.01765 17.3117 9.00271 17.0573C8.98778 16.8029 9.07056 16.5523 9.23415 16.3569C9.39774 16.1614 9.62979 16.0358 9.88288 16.0057L9.99988 15.9987H14.9999ZM23.2929 7.29169C23.4728 7.11235 23.7143 7.00822 23.9683 7.00047C24.2222 6.99271 24.4696 7.08191 24.6602 7.24994C24.8507 7.41798 24.9702 7.65225 24.9943 7.90517C25.0184 8.15809 24.9453 8.4107 24.7899 8.61169L24.7069 8.70569L19.4139 13.9987H21.9999C22.2548 13.999 22.4999 14.0966 22.6853 14.2715C22.8706 14.4465 22.9821 14.6856 22.9971 14.9401C23.012 15.1945 22.9292 15.4451 22.7656 15.6405C22.602 15.836 22.37 15.9616 22.1169 15.9917L21.9999 15.9987H16.9999C16.755 15.9987 16.5185 15.9087 16.3355 15.746C16.1525 15.5832 16.0355 15.3589 16.0069 15.1157L15.9999 14.9987V9.99869C16.0002 9.74381 16.0978 9.49866 16.2727 9.31333C16.4477 9.12799 16.6868 9.01646 16.9413 9.00152C17.1957 8.98659 17.4463 9.06937 17.6417 9.23296C17.8372 9.39655 17.9628 9.6286 17.9929 9.88169L17.9999 9.99869V12.5847L23.2929 7.29169Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_9590_459423'>
        <rect width='24' height='24' fill='white' transform='translate(4 4)' />
      </clipPath>
    </defs>
  </svg>
);

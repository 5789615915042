import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const Line = styled(Box)(({ theme }) => ({
  background: theme.palette.action.disabledBackground,
  height: '1px',
  margin: theme.spacing(1),
}));

export const Actions = styled(Box)(({ theme }) => ({
  width: '221px',
  '& img': {
    width: '28px',
    marginRight: theme.spacing(1),
    padding: '4px',
  },
  '& li': {
    height: '40px',
  },
}));

import React, { FC, useEffect, useMemo, useState } from 'react';
import Plot from 'app/shared/components/Plotly/index.js';
import { Layout, PlotMouseEvent, PlotSelectionEvent } from 'plotly.js';

import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { ChartDataType, SELECTION_SOURCES } from 'app/screens/Analysis/Analysis.types';
import {
  DEFAULT_CHART_HEIGHT,
  histogramConfig,
} from 'app/screens/Analysis/Visualizations/Wrappers/Chart.config';
import {
  getModebarButtonsForGroupOne,
  isInSelectionMode,
} from 'app/screens/Analysis/Visualizations/Wrappers/Chart.utils';

type Props = {
  chart: ChartDataType;
  width: number;
  height?: number;
  isGenerateSvg: boolean;
  onSvgGenerate: (chart: ChartDataType) => Promise<void>;
};

export const Histogram: FC<Props> = ({
  chart,
  width,
  height = DEFAULT_CHART_HEIGHT,
  isGenerateSvg,
  onSvgGenerate,
}) => {
  const [shouldInvertSelection, setShouldInvertSelection] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState<number[] | undefined>(undefined);

  const [chartLayoutState, setChartLayoutState] = useState<Partial<Layout>>({
    height,
    width,
    bargroupgap: 0.2,
    xaxis: { title: chart.features[0].feature_name },
    yaxis: { title: 'count' },
  });
  const {
    setSelectedDataRows,
    setDataSelectionSource,
    selectedDataRows,
    dataPointsSelectionSource,
  } = useAnalysis();

  const isCurrentChartSelection = useMemo(
    () =>
      dataPointsSelectionSource?.source === SELECTION_SOURCES.HISTOGRAM &&
      dataPointsSelectionSource?.id === chart.id,
    [chart.id, dataPointsSelectionSource?.id, dataPointsSelectionSource?.source]
  );

  const clearSelectionMode = (): void => {
    setChartLayoutState((prevState) => ({
      ...prevState,
      selections: undefined,
    }));
  };

  useEffect(() => {
    if (chart) {
      setChartLayoutState((prevState) => ({
        ...prevState,
        xaxis: { title: chart.features[0].feature_name },
      }));
    }
  }, [chart]);

  useEffect(() => {
    setChartLayoutState((prevState) => ({
      ...prevState,
      width,
      height,
    }));
  }, [width, height]);

  const handleDoubleClick = (event: Readonly<PlotMouseEvent>): void => {
    if (!event?.points) return;

    if (isInSelectionMode(chartLayoutState.dragmode)) return;

    // @ts-ignore
    const dataIndices = event.points.map((data) => data.pointNumbers).flat();
    const dataPoints = dataIndices.map((index) => chart.config.data_points[index] ?? index);

    const alreadySelectedRows = isCurrentChartSelection ? selectedDataRows : [];
    const isShiftKey = (event.event as MouseEvent).shiftKey;
    const isAlreadySelected = dataPoints.every((point) => alreadySelectedRows.includes(point));

    if (isAlreadySelected) {
      const filteredDataPoints = alreadySelectedRows.filter((i) => !dataPoints.includes(i));
      setSelectedDataRows(filteredDataPoints);
    } else if (isShiftKey) {
      const selectedPointsSet = new Set([...alreadySelectedRows, ...dataPoints]);
      setSelectedDataRows(Array.from(selectedPointsSet));
    } else {
      setSelectedDataRows(dataPoints);
    }

    setDataSelectionSource({
      source: SELECTION_SOURCES.HISTOGRAM,
      id: chart.id,
      name: chart.name,
    });

    clearSelectionMode();
  };

  const handleSelected = (event: Readonly<PlotSelectionEvent>): void => {
    if (!event?.points) return;

    // @ts-ignore
    const dataIndices = event.points.map((data) => data.pointNumbers).flat();
    const dataPoints = dataIndices.map((index) => chart.config.data_points[index] ?? index);

    if (dataPoints.length) {
      setSelectedDataRows(dataPoints);
      setDataSelectionSource({
        source: SELECTION_SOURCES.HISTOGRAM,
        id: chart.id,
        name: chart.name,
      });
    }
  };

  useEffect(() => {
    if (!isCurrentChartSelection) {
      setChartLayoutState({
        height,
        width,
        bargroupgap: 0.2,
        xaxis: { title: chart.features[0].feature_name },
        yaxis: { title: 'count' },
      });
    }
  }, [width, height, isCurrentChartSelection, chart]);

  useEffect(() => {
    const hasSelected = !!selectedDataRows.length;
    const selectedIndices = selectedDataRows.map((row) => {
      const rowIndex = chart.config.data_points.findIndex((point) => row === point);

      return rowIndex >= 0 ? rowIndex : row;
    });

    const points = isCurrentChartSelection && hasSelected ? selectedIndices : undefined;
    setSelectedPoints(points);
  }, [chart, isCurrentChartSelection, selectedDataRows]);

  const handleDeselect = (): void => {
    setSelectedDataRows([]);
    setDataSelectionSource(null);
  };

  const onResetScale = (): void => {
    setChartLayoutState({
      height,
      width,
      bargroupgap: 0.2,
      xaxis: { title: chart.features[0].feature_name },
      yaxis: { title: 'count' },
    });
  };

  const onClearSelection = (): void => {
    onResetScale();
    handleDeselect();
  };

  const onInvertSelection = (): void => {
    setShouldInvertSelection(true);
  };

  useEffect(() => {
    if (shouldInvertSelection && selectedPoints) {
      const invertedIndices = chart.config.data_points.filter(
        (point) => selectedPoints.indexOf(point) === -1
      );
      const invertedDataPoints = invertedIndices.map(
        (index) => chart.config.data_points[index] ?? index
      );

      clearSelectionMode();
      setSelectedPoints(invertedIndices);
      setSelectedDataRows(invertedDataPoints);
      setShouldInvertSelection(false);
    } else {
      // State of the Plotly component remains the same, the onClick event will not have access to the updated state.
      // We should manually reset "shouldInvertSelection" if "selectedPoints" if undefined
      setShouldInvertSelection(false);
    }
  }, [chart, selectedPoints, shouldInvertSelection]);

  useEffect(() => {
    if (isGenerateSvg && chart) {
      onSvgGenerate(chart);
    }
  }, [isGenerateSvg, chart]);

  return (
    <div className='chart-container'>
      {
        // @ts-ignore
        <Plot
          divId={`${chart.type}_${chart.id}`}
          data={[
            {
              x: chart.features[0].values,
              type: 'histogram',
              selectedpoints: selectedPoints,
            },
          ]}
          layout={chartLayoutState}
          config={{
            modeBarButtons: getModebarButtonsForGroupOne({
              onResetScale,
              onClearSelection,
              onInvertSelection,
            }),
            doubleClick: false,
            ...histogramConfig,
          }}
          onSelected={handleSelected}
          onDeselect={handleDeselect}
          onDoubleClick={handleDoubleClick}
        />
      }
    </div>
  );
};

import { CategoricalColorMaps } from 'app/screens/Analysis/Coloring/Coloring.types';

export type ColorPair = [number, string];

export const categoricalMaps: Record<CategoricalColorMaps, ColorPair[]> = {
  [CategoricalColorMaps.Default]: [
    [0, '#003CB6'],
    [1, '#B90003'],
    [2, '#1EC9F9'],
    [3, '#820003'],
    [4, '#008DF9'],
    [5, '#D55E00'],
    [6, '#819DFB'],
    [7, '#000000'],
    [8, '#8B00D2'],
    [9, '#EBAA1A'],
    [10, '#BFBFBF'],
  ],
  [CategoricalColorMaps.Light]: [
    [0, '#007822'],
    [1, '#F84200'],
    [2, '#0038FC'],
    [3, '#1EC9F9'],
    [4, '#FF00FF'],
    [5, '#EBAA1A'],
    [6, '#990000'],
    [7, '#9DCC37'],
    [8, '#9900FF'],
    [9, '#000000'],
    [10, '#BFBFBF'],
  ],
  [CategoricalColorMaps.Monochromatic]: [
    [0, '#000000'],
    [1, '#ABABAB'],
    [2, '#6B6B6B'],
    [3, '#D6D6D6'],
    [4, '#565656'],
    [5, '#818181'],
    [6, '#2B2B2B'],
    [7, '#C1C1C1'],
    [8, '#404040'],
    [9, '#969696'],
    [10, '#FFFFFF'],
  ],
};

import React, { FC } from 'react';
import { AccountMenu } from 'app/shared/components/Header/AccountMenu/AccountMenu';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';
import { UserInitials } from 'app/shared/components/ProfileIcon/ProfileIcon';
import { ProfileWrapper, StyledTypography } from './Profile.styles';
import { useHeader } from '../Header.hooks';

export const getInitials = (data: UserInitials): string => {
  if (!data || !data.firstname || !data.lastname) return '';

  return `${data.firstname.charAt(0)}${data.lastname.charAt(0)}`;
};

export const Profile: FC = () => {
  const { user } = useHeader();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleAccountMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <ProfileWrapper className='profile-avatar'>
      {user && <StyledTypography onClick={handleAccountMenu}>{getInitials(user)}</StyledTypography>}
      <DotsMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <AccountMenu />
      </DotsMenu>
    </ProfileWrapper>
  );
};

import React, { FC } from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
};

export const RadialLoader: FC<Props> = ({ width = 40, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <linearGradient id='radialLoaderGradient-secondHalf'>
        <stop offset='0%' stopOpacity='0' stopColor='currentColor' />
        <stop offset='100%' stopOpacity='0.5' stopColor='currentColor' />
      </linearGradient>
      <linearGradient id='radialLoaderGradient-firstHalf'>
        <stop offset='0%' stopOpacity='1' stopColor='currentColor' />
        <stop offset='100%' stopOpacity='0.5' stopColor='currentColor' />
      </linearGradient>
    </defs>
    <g strokeWidth='4'>
      <path stroke='url(#radialLoaderGradient-secondHalf)' d='M 8 20 A 12 12 0 0 1 32 20' />
      <path stroke='url(#radialLoaderGradient-firstHalf)' d='M 32 20 A 12 12 0 0 1 8 20' />
    </g>
  </svg>
);

import { useMemo } from 'react';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';

export type Column = {
  column_id: number;
  name: string;
  width?: number;
};

export type ProcessedColumn = {
  key: number | string;
  name: string;
  width?: number;
};

type Row = { [key: number]: number; row_index: string };

export type ProcessedRow = {
  feature_name: string;
  '0': string | number;
  '1': string | number;
};

export const SORT_COLUMN_KEY = 1;

export const getColumns = (columns: Column[], isSmallTable?: boolean): ProcessedColumn[] =>
  columns.reduce(
    (acc: ProcessedColumn[], column: Column) => [
      ...acc,
      {
        key: column.column_id,
        name: column.name,
        sortable: column.column_id === 1,
      },
    ],
    [
      {
        key: 'feature_name',
        name: 'Feature',
        ...(isSmallTable ? { width: 80 } : { width: 375 }),
      },
    ]
  );

const formatNumber = (value: number | string): string => {
  if (value === 0) return '0';

  if (typeof value === 'number') return value.toFixed(2);

  if (typeof value === 'string') return '-';

  return value;
};

const formatPValue = (value: number): string => {
  if (value === 0) return '0';

  if (value < 0.001) {
    const scientificNotation = value.toExponential(1);
    return scientificNotation.replace(/\.0+e/, 'e');
  }

  return formatNumber(value);
};

export const getComparativeStatisticsRows = (rows: Row[]): ProcessedRow[] =>
  rows.map((row: Row) => ({
    feature_name: row.row_index,
    '0': formatNumber(row[0]),
    '1': formatPValue(row[1]),
  }));

export const getDescriptiveStatisticsRows = (rows: Row[]): ProcessedRow[] =>
  rows.map((row: Row) => ({
    feature_name: row.row_index,
    '0': formatNumber(row[0]),
    '1': formatNumber(row[1]),
  }));

type UseStatistics = {
  comparativeStatisticsColumns: any;
  comparativeStatisticsRows: ProcessedRow[] | [];
  descriptiveStatisticsColumns: any;
  descriptiveStatisticsRows: ProcessedRow[] | [];
};

type UseStatisticsProps = {
  isSmallTable: boolean;
};

export const useStatistics = (props: Partial<UseStatisticsProps> = {}): UseStatistics => {
  const { isSmallTable = false } = props;
  const { comparativeStatistics, descriptiveStatistics } = useAnalysis();

  const comparativeStatisticsColumns = useMemo(
    () => (comparativeStatistics ? getColumns(comparativeStatistics?.columns, isSmallTable) : []),
    [comparativeStatistics, isSmallTable]
  );

  const comparativeStatisticsRows = useMemo(
    () => (comparativeStatistics ? getComparativeStatisticsRows(comparativeStatistics?.rows) : []),
    [comparativeStatistics]
  );

  const descriptiveStatisticsColumns = useMemo(
    () => (descriptiveStatistics ? getColumns(descriptiveStatistics?.columns, isSmallTable) : []),
    [descriptiveStatistics, isSmallTable]
  );

  const descriptiveStatisticsRows = useMemo(
    () => (descriptiveStatistics ? getDescriptiveStatisticsRows(descriptiveStatistics?.rows) : []),
    [descriptiveStatistics]
  );

  return {
    comparativeStatisticsColumns,
    comparativeStatisticsRows,
    descriptiveStatisticsColumns,
    descriptiveStatisticsRows,
  };
};

import React, { Dispatch, FC, SetStateAction } from 'react';
import { Check } from 'react-feather';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SortEnum } from 'app/shared/enum/sort';
import Typography from '@mui/material/Typography';
import { MenuItemContent, StyledFormControl } from './SortSelect.styles';

export type Order = 'asc' | 'desc';

type Props = {
  order: Order;
  orderBy: string;
  setOrder: Dispatch<SetStateAction<Order>>;
  setOrderBy: Dispatch<SetStateAction<string>>;
};

export const SortSelect: FC<Props> = ({ order, orderBy, setOrder, setOrderBy }) => {
  const theme = useTheme();

  const [sortValue, setSortValue] = React.useState('Activity (from last updated)');

  const handleChange = (event: SelectChangeEvent): void => {
    setSortValue(event.target.value as string);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <Box display='flex' alignItems='center'>
      <Typography fontSize='1.4rem' color='text.secondary'>
        Sort by:
      </Typography>
      <StyledFormControl>
        <Select onChange={handleChange} value={sortValue}>
          <MenuItem value={SortEnum.updated_at} onClick={createSortHandler('updated_at')}>
            <MenuItemContent>
              {sortValue === SortEnum.updated_at ? (
                <>
                  Activity (from last updated){' '}
                  <Check size='24' color={theme.palette.success.main} />
                </>
              ) : (
                SortEnum.updated_at
              )}
            </MenuItemContent>
          </MenuItem>
          <MenuItem value={SortEnum.name} onClick={createSortHandler('name')}>
            <MenuItemContent>
              {sortValue === SortEnum.name ? (
                <>
                  {SortEnum.name} <Check size='24' color={theme.palette.success.main} />
                </>
              ) : (
                SortEnum.name
              )}
            </MenuItemContent>
          </MenuItem>
        </Select>
      </StyledFormControl>
    </Box>
  );
};

import styled from '@emotion/styled/macro';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

export const MenuItemContent = styled(Box)(() => ({
  fontSize: '1.4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledFormControl = styled(FormControl)(() => ({
  width: '235px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& span': {
    fontSize: '1.4rem',
    lineHeight: '20px',
  },
  '& .MuiSelect-select svg': {
    display: 'none',
  },
}));

import React, { FC, ReactElement, ReactNode } from 'react';
import { Plus } from 'react-feather';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import { Button } from 'app/mui/Button';
import { LayoutEnum } from 'app/shared/enum/layout';
import { SvgGradient, Grid, List } from 'app/shared/icons';
import { IconWrapper, ItemsCount } from './Layout.styles';

interface LayoutProps {
  heading: string;
  children: ReactNode;
  caption?: string;
  type?: string;
  onViewChange?: (type: string) => void;
  onButtonClick?: () => void;
  buttonTitle?: string;
  toolbar?: ReactElement<any, any>;
  sortSelect?: ReactElement<any, any>;
  isEmpty?: boolean;
  itemsCount?: number;
}

export const Layout: FC<LayoutProps> = ({
  heading,
  caption,
  type,
  onViewChange,
  onButtonClick,
  buttonTitle,
  children,
  toolbar,
  sortSelect,
  isEmpty,
  itemsCount,
}) => {
  const viewIconsJSX = onViewChange && (
    <>
      <IconWrapper
        className={classNames('list-icon', { 'is-active': type === LayoutEnum.TABLE })}
        onClick={() => onViewChange(LayoutEnum.TABLE)}
      >
        <List />
      </IconWrapper>
      <IconWrapper className={classNames('grid-icon', { 'is-active': type === LayoutEnum.GRID })}>
        <Grid />
      </IconWrapper>
      <SvgGradient />
    </>
  );

  return (
    <>
      <Box display='flex' alignItems='center' mb={2}>
        <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <Typography component='h4' variant='h4'>
              {heading}
            </Typography>
            {!isEmpty && itemsCount && (
              <ItemsCount>
                <Typography variant='subtitle1'>{itemsCount}</Typography>
              </ItemsCount>
            )}
          </Box>
          {toolbar}
          {!isEmpty && (
            <Box display='flex' alignItems='center'>
              {sortSelect}
              {onButtonClick && (
                <Button variant='outlined' onClick={onButtonClick}>
                  <Plus size='24px' style={{ marginRight: '8px' }} />
                  {buttonTitle}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {caption && (
        <Typography variant='body1' mb={6}>
          {caption}
        </Typography>
      )}
      <Box zIndex={1}>{children}</Box>
    </>
  );
};

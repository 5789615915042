import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(4),
  padding: theme.spacing(0, 3),
}));

import React, { ChangeEvent, FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import { Divider, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { shortName } from 'app/shared/utils/visualization';
import { FormControl } from 'app/mui/FormControl';
import { Autocomplete } from 'app/mui/Autocomplete';
import { COLORING_OPTIONS } from 'app/shared/enum/analysis';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { Radio } from 'app/mui/Radio';
import { Tooltip } from 'app/mui/Tooltip';
import { useAnalysis } from '../Analysis.hooks';
import {
  aggregationTypeOptions,
  defaultColoringOptions,
  noColoringOption,
  normalizationTypeOptions,
} from '../Analysis.utils';
import { GroupType, OptionType, SelectedFeatureType } from '../Analysis.types';
import { ColoringOptions } from './Coloring.hooks';
import { ColoringWrapper } from './Coloring.styles';

type ColoringMenuProps = {
  type: VisualisationType;
  colorOption: SelectedFeatureType | null;
  featureOption: SelectedFeatureType | null;
  normalizationType: OptionType;
  aggregationType: OptionType;
  diffOptionOne: OptionType | SelectedFeatureType | null;
  diffOptionTwo: OptionType | SelectedFeatureType | null;
  featureType: string;
  onFeatureApply: (feature: SelectedFeatureType) => void;
  onColorOptionChange: (colorOption: SelectedFeatureType) => void;
  onNormalizationType: (option: OptionType) => void;
  onAggregationType: (option: OptionType) => void;
  onDiffOptionApply: (
    type: keyof ColoringOptions,
    option: OptionType | SelectedFeatureType
  ) => void;
};

const OPTION_HEIGHT = 32;
const MAX_AUTOCOMPLETE_LIST_HEIGHT = 430;

export const getGroup = (
  group: GroupType
): { id: number; feature_name: string; groupType: string } => ({
  id: Number(group.id),
  feature_name: group.name,
  groupType: group.type,
});

export const ColoringMenu: FC<ColoringMenuProps> = ({
  normalizationType,
  aggregationType,
  type,
  featureType,
  colorOption,
  diffOptionOne,
  diffOptionTwo,
  featureOption,
  onFeatureApply,
  onColorOptionChange,
  onNormalizationType,
  onAggregationType,
  onDiffOptionApply,
}) => {
  const { features, groups } = useAnalysis();
  const isFeatureLandscape = type === VisualisationType.FEATURE_LANDSCAPE;

  const dataPointGroups = useMemo(
    () =>
      groups
        .filter((group) => group.type === GroupCreationEnum.ROWS && group.rows?.length)
        .map((group) => getGroup(group)),
    [groups]
  );

  const featureGroups = useMemo(
    () =>
      groups
        .filter((group) => group.type === GroupCreationEnum.FEATURES && group.features?.length)
        .map((group) => getGroup(group)),
    [groups]
  );

  const dataPointsGroupSelect = useMemo(
    () => (dataPointGroups.length ? dataPointGroups : []),
    [dataPointGroups]
  );

  const featureGroupSelect = useMemo(
    () => (featureGroups.length ? featureGroups : []),
    [featureGroups]
  );

  const coloringFeatures = useMemo(
    () => features.filter((row) => ['numeric', 'categorical'].includes(row.type)),
    [features]
  );

  const [featuresOption, dataPointsOption] = defaultColoringOptions;

  const defaultColoringOption = useMemo(
    () => (isFeatureLandscape ? dataPointsOption : featuresOption),
    [isFeatureLandscape]
  );

  const onSwitch = (): void => {
    if (colorOption.id !== COLORING_OPTIONS.none) {
      onColorOptionChange(noColoringOption);
    } else {
      onColorOptionChange(defaultColoringOption);
    }
  };

  const isColoringEnabled = colorOption.id !== COLORING_OPTIONS.none;
  const isOther = colorOption.id === COLORING_OPTIONS.other;
  const isDifferentialColoring = colorOption.id === COLORING_OPTIONS.differentialColoring;
  const isCategoricalFeature = featureType === 'category';

  const handleChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    const selectedOption = defaultColoringOptions.find((f) => f.id === value);

    if (selectedOption) {
      onColorOptionChange(selectedOption);
    }
  };

  const diffColoringList = useMemo(
    () =>
      isFeatureLandscape
        ? [
            defaultColoringOption,
            ...(dataPointsGroupSelect.length
              ? [
                  {
                    feature_name: 'Divider 1',
                    isDivider: true,
                    withDivider: true,
                    label: 'Data points groups',
                  },
                  ...dataPointsGroupSelect,
                ]
              : []),
          ]
        : [
            defaultColoringOption,
            {
              feature_name: 'Divider 1',
              isDivider: true,
              withDivider: true,
              label: 'Feature groups',
            },
            ...featureGroupSelect,
            {
              feature_name: 'Divider 3',
              isDivider: true,
              withDivider: true,
              label: 'Features',
            },
            ...coloringFeatures,
          ],
    [
      defaultColoringOption,
      isFeatureLandscape,
      dataPointsGroupSelect,
      featureGroupSelect,
      coloringFeatures,
    ]
  );

  const otherColoringList = useMemo(
    () => [
      {
        feature_name: 'Divider 1',
        isDivider: true,
        withDivider: false,
        label: 'Groups',
      },
      ...dataPointsGroupSelect,
      ...featureGroupSelect,
      {
        feature_name: 'Divider 3',
        isDivider: true,
        withDivider: true,
        label: 'Features',
      },
      ...coloringFeatures,
    ],
    [dataPointsGroupSelect, featureGroupSelect, coloringFeatures]
  );

  const showColoringFunctions = useMemo(() => {
    const isSelectedFeaturesOnFeatureLandscape =
      isFeatureLandscape && colorOption.id === COLORING_OPTIONS.selectedFeatures;
    const isSelectedDataPointsOnDataVisualizations =
      !isFeatureLandscape && colorOption.id === COLORING_OPTIONS.selectedDataPoints;
    const isFeaturesOnFeatureLandscape =
      isFeatureLandscape &&
      colorOption.id === COLORING_OPTIONS.other &&
      (featureOption?.type || featureOption?.groupType === 'features');
    const isDataPointsOnDataVisualizations =
      !isFeatureLandscape &&
      colorOption.id === COLORING_OPTIONS.other &&
      featureOption?.groupType === 'rows';

    return !(
      isSelectedFeaturesOnFeatureLandscape ||
      isSelectedDataPointsOnDataVisualizations ||
      isFeaturesOnFeatureLandscape ||
      isDataPointsOnDataVisualizations
    );
  }, [isFeatureLandscape, colorOption, featureOption]);

  return (
    <ColoringWrapper>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='body1'>Colored by</Typography>
        <Switch onChange={onSwitch} checked={isColoringEnabled} size='small' />
      </Box>
      {isColoringEnabled && (
        <>
          <FormControl>
            <RadioGroup
              aria-labelledby='coloring-option-select'
              name='coloring-option-select'
              value={colorOption.id}
              onChange={handleChange}
            >
              {defaultColoringOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  label={option.feature_name}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {isDifferentialColoring && (
            <>
              <Autocomplete
                useVirtual
                id='diffOptionOne'
                disableClearable
                options={diffColoringList}
                optionHeight={OPTION_HEIGHT}
                maxHeight={MAX_AUTOCOMPLETE_LIST_HEIGHT}
                renderOption={(props, option: any, _, style = {}) => (
                  <Box key={option.feature_name} style={{ ...style }}>
                    {option.isDivider ? (
                      <>
                        {option.withDivider && <Divider />}
                        <Typography variant='caption' ml={2} color='text.secondary'>
                          {option.label}
                        </Typography>
                      </>
                    ) : (
                      <li {...props}>
                        <Tooltip title={option.feature_name} placement='right'>
                          <Typography
                            sx={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {shortName(option.feature_name, 190)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  </Box>
                )}
                getOptionLabel={(featureItem: SelectedFeatureType) => featureItem.feature_name}
                getOptionDisabled={(featureItem: SelectedFeatureType) =>
                  featureItem.id === diffOptionTwo?.id
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={diffOptionOne}
                onChange={(event, option: SelectedFeatureType) => {
                  onDiffOptionApply('diffOptionOne', option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color='secondary'
                    label='Select first source'
                    size='small'
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  />
                )}
              />
              <Autocomplete
                useVirtual
                id='features'
                disableClearable
                options={diffColoringList}
                optionHeight={OPTION_HEIGHT}
                maxHeight={MAX_AUTOCOMPLETE_LIST_HEIGHT}
                renderOption={(props, option: any, _, style = {}) => (
                  <Box key={option.feature_name} style={{ ...style }}>
                    {option.isDivider ? (
                      <>
                        {option.withDivider && <Divider />}
                        <Typography variant='caption' ml={2} color='text.secondary'>
                          {option.label}
                        </Typography>
                      </>
                    ) : (
                      <li {...props}>
                        <Tooltip title={option.feature_name} placement='right'>
                          <Typography
                            sx={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {shortName(option.feature_name, 190)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  </Box>
                )}
                getOptionLabel={(featureItem: SelectedFeatureType) => featureItem.feature_name}
                getOptionDisabled={(featureItem: SelectedFeatureType) =>
                  featureItem.id === diffOptionOne?.id
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={diffOptionTwo}
                onChange={(event, option: SelectedFeatureType) => {
                  onDiffOptionApply('diffOptionTwo', option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color='secondary'
                    label='Select second source'
                    size='small'
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  />
                )}
              />
            </>
          )}
          {isOther && (
            <Autocomplete
              useVirtual
              id='features'
              disableClearable
              optionHeight={OPTION_HEIGHT}
              maxHeight={MAX_AUTOCOMPLETE_LIST_HEIGHT}
              options={otherColoringList}
              renderOption={(props, option: any, _, style = {}) => (
                <Box key={option.feature_name} style={style}>
                  {option.isDivider ? (
                    <>
                      {option.withDivider && <Divider />}
                      <Typography variant='caption' ml={2} color='text.secondary'>
                        {option.label}
                      </Typography>
                    </>
                  ) : (
                    <li {...props}>
                      <Tooltip title={option.feature_name} placement='right'>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {shortName(option.feature_name, 190)}
                        </Typography>
                      </Tooltip>
                    </li>
                  )}
                </Box>
              )}
              getOptionLabel={(featureItem: SelectedFeatureType) => featureItem.feature_name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={featureOption}
              onChange={(event, option: SelectedFeatureType) => {
                onFeatureApply(option);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color='secondary'
                  label='Search groups/features'
                  size='small'
                  sx={{
                    mt: 2,
                    mb: 2,
                  }}
                />
              )}
            />
          )}
          {showColoringFunctions && isCategoricalFeature && (
            <>
              <Divider />
              <Box mt={1}>
                <Typography variant='caption' color='text.secondary'>
                  Color value
                </Typography>
                <Typography>Raw values</Typography>
              </Box>
              <Box mt={1}>
                <Typography variant='caption' color='text.secondary'>
                  Aggregation function
                </Typography>
                <Typography>Cartesian</Typography>
              </Box>
            </>
          )}
          {showColoringFunctions && !isCategoricalFeature && (
            <>
              <Divider />
              <Autocomplete
                id='normalizationTypes'
                disableClearable
                options={normalizationTypeOptions}
                getOptionLabel={(item: OptionType) => item.label}
                value={normalizationType}
                onChange={(event, option: OptionType) => {
                  onNormalizationType(option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color='secondary'
                    label='Color value'
                    size='small'
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  />
                )}
                renderOption={(props, option: any) => (
                  <Box key={option.label}>
                    <li {...props}>
                      <Typography
                        sx={{
                          width: 200,
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {option.label}
                      </Typography>
                    </li>
                  </Box>
                )}
              />
              <Autocomplete
                id='aggregationTypes'
                disableClearable
                options={aggregationTypeOptions}
                getOptionLabel={(item: OptionType) => item.label}
                value={aggregationType}
                onChange={(event, option: OptionType) => {
                  onAggregationType(option);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color='secondary'
                    label='Aggregation function'
                    size='small'
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  />
                )}
                renderOption={(props, option: any) => (
                  <Box key={option.label}>
                    <li {...props}>
                      <Typography
                        sx={{
                          width: 200,
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {option.label}
                      </Typography>
                    </li>
                  </Box>
                )}
              />
            </>
          )}
        </>
      )}
    </ColoringWrapper>
  );
};

import React, { FC, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from 'app/mui/Alert';
import { useAlert } from 'app/shared/hooks/useAlert';

const AUTO_HIDE_DURATION = 4000;

export const GlobalAlert: FC = () => {
  const { alert, resetAlert } = useAlert();

  useEffect(() => {
    if (alert.type !== 'error') {
      setTimeout(() => {
        if (alert.title) {
          resetAlert();
        }
      }, AUTO_HIDE_DURATION);
    }
  }, [alert.type, alert.title, resetAlert]);

  return alert.title ? (
    <Snackbar
      open={Boolean(alert.title)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={resetAlert}
    >
      <Alert
        variant={alert.variant}
        severity={alert.type}
        onClose={alert.withCloseOption ? resetAlert : undefined}
      >
        {alert.title}
      </Alert>
    </Snackbar>
  ) : null;
};

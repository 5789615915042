import { styled, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const textFieldStyles = (theme: Theme): Record<string, unknown> => ({
  width: '100%',
  '& label': {
    fontSize: '1.4rem',
    top: '-6px',
    color: theme.palette.text.secondary,
  },
  '& label.Mui-focused': {
    top: 0,
    color: theme.palette.text.primary,
  },
  '& .arrow': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  '&.number': {
    width: '105px',
    '& .MuiOutlinedInput-root': {
      paddingRight: theme.spacing(1),
      height: '30px',
    },
  },
  '&.largeNumber': {
    width: '197px',
  },
  '& #outlined-start-adornment-label, & .MuiFormLabel-filled': {
    top: 0,
  },
  '.MuiOutlinedInput-input': {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '1.4rem',
    color: theme.palette.text.primary,
    '&.MuiInputBase-inputSizeSmall': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  '& textarea.MuiOutlinedInput-input': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '.MuiOutlinedInput-root': {
    fontSize: '1.4rem',
    color: theme.palette.text.primary,
    '& fieldset': {
      borderColor: `${theme.palette.state.restingBorder}`,
    },
    '&:not(.Mui-focused):hover fieldset': {
      backgroundColor: theme.palette.state.hoverBackground,
    },
    '&:not(.Mui-focused):not(.Mui-error):hover fieldset': {
      borderColor: theme.palette.state.hoverBorder,
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-input': {
        color: theme.palette.text.primary,
      },
      '& fieldset': {
        borderColor: theme.palette.text.primary,
      },
    },
    '&.Mui-error fieldset': {
      borderColor: theme.palette.error.main,
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      '& fieldset': {
        border: `1px dotted ${theme.palette.state.restingBorder}`,
      },
    },
    '&.MuiInputBase-colorSuccess, &.MuiInputBase-colorSuccess.Mui-focused': {
      '& fieldset': {
        borderColor: theme.palette.success.main,
      },
    },
    '&.MuiInputBase-colorWarning, &.MuiInputBase-colorWarning.Mui-focused ': {
      '& fieldset': {
        borderColor: theme.palette.warning.main,
      },
    },
    '&.Mui-disabled .MuiIconButton-root': {
      color: theme.palette.action.active,
      cursor: 'default',
    },
    '.MuiIconButton-root': {
      color: theme.palette.action.active,
      '&:hover': {
        color: theme.palette.hover.primary,
      },
    },
  },
  '.MuiInputAdornment-root, .MuiInputBase-adornedEnd, .MuiInputBase-adornedStart': {
    color: theme.palette.action.active,
  },
  '.MuiSvgIcon-root.MuiSelect-icon': {
    color: theme.palette.action.active,
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
    },
  },
  '.MuiInputLabel-root.Mui-error': {
    color: theme.palette.text.secondary,
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: theme.palette.error.main,
    },
  },
  '.MuiInputLabel-root.MuiFormLabel-colorSuccess': {
    color: theme.palette.text.secondary,
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: theme.palette.success.main,
    },
  },
  '.MuiInputLabel-root.MuiFormLabel-colorWarning': {
    color: theme.palette.text.secondary,
    '&.MuiFormLabel-filled, &.Mui-focused': {
      color: theme.palette.warning.main,
    },
  },
  '.MuiInputLabel-root.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
});

export const StyledTextField = styled(TextField)(({ theme }) => textFieldStyles(theme));

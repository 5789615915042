import React, { FC } from 'react';
import DataGrid from 'react-data-grid';

import { useStatistics } from './Statistics.hooks';
import './Statistics.scss';

export const ComparativeStatistics: FC = () => {
  const { comparativeStatisticsColumns, comparativeStatisticsRows } = useStatistics({
    isSmallTable: true,
  });

  return (
    <DataGrid
      columns={comparativeStatisticsColumns}
      rows={comparativeStatisticsRows}
      className='rdg-light statistics-table side-panel'
    />
  );
};

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  background: 'blocksBackground.hover',
  borderRadius: '8px',
  cursor: 'pointer',
  marginBottom: theme.spacing(1),
}));

export const SVGBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& svg': {
    marginRight: theme.spacing(1),
  },
}));

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const LoadingMoreRows = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  background: black;
  color: white;
  opacity: 0.8;
  padding: 16px;
`;

export const Column = styled(Box)(({ theme }) => ({
  '&.highlight': {
    background: '#dbecfa',
    width: '120px',
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(0, 1),
  },
}));

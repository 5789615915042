import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps } from 'formik-mui';
import { textFieldStyles } from 'app/mui/TextField';

export const StyledTextArea = styled(TextField)(({ theme }) => textFieldStyles(theme));

export const FormTextArea = React.forwardRef(
  (props: TextFieldProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <StyledTextArea multiline rows={4} {...props} ref={ref} />
  )
);

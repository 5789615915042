import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { IconButton } from 'app/mui/IconButton';
import { StyledTextField } from 'app/mui/TextField';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from 'app/screens/Analysis/CreateGroupDialog/CreateGroupDialog.styles';
import {
  Row,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { SourceDataSearchSelect } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceDataSearchSelect';
import { DataPartitionEnum } from '../DataPartition/DataPartition';
import { metadataRows, parameterOptions } from '../DataPartition/DataPartition.constants';

export type PartitionRow = {
  feature_name: string;
  id: number | string;
};

type FeaturePartitionProps = {
  onDataPartitionDataChange: (value: Row) => void;
  onDataPartitionGlobalChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDataPartitionMetadataChange: (value: PartitionRow) => void;
  handleChangeBins: (event: ChangeEvent<HTMLInputElement>) => void;
  binsDecrement: () => void;
  binsIncrement: () => void;
  handleChangeEqualize: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeDistance: (event: ChangeEvent<HTMLInputElement>) => void;
  distanceDecrement: () => void;
  distanceIncrement: () => void;
  bins: string;
  distance: string;
  checkedEqualize: boolean;
  dataPartitionData: Row;
  dataPartitionGlobal: VisualizationRow;
  dataPartitionMetadata: PartitionRow | undefined;
  handleChangeGroupType: (event: React.MouseEvent<HTMLElement>, newType: string) => void;
  groupType: string;
};

export const FeaturePartition: FC<FeaturePartitionProps> = ({
  onDataPartitionDataChange,
  onDataPartitionGlobalChange,
  onDataPartitionMetadataChange,
  handleChangeBins,
  binsDecrement,
  binsIncrement,
  handleChangeEqualize,
  handleChangeDistance,
  distanceDecrement,
  distanceIncrement,
  bins,
  distance,
  checkedEqualize,
  dataPartitionData,
  dataPartitionGlobal,
  dataPartitionMetadata,
  handleChangeGroupType,
  groupType,
}) => {
  const { analysisId } = useParams();
  const { groups } = useAnalysis();

  const [metadata] = useState<PartitionRow[]>(metadataRows);
  const [dataGroups, setDataGroups] = useState<VisualizationRow[] | undefined>(undefined);
  const [parameter] = useState<VisualizationRow[]>(parameterOptions);

  const { getFeaturesList, features } = useAnalysis();

  useEffect(() => {
    getFeaturesList(Number(analysisId));

    const featureGroupList = groups
      .filter((group) => group.type === GroupCreationEnum.ROWS)
      .map((group) => ({
        feature_name: group.name,
        id: group.id,
      }));
    setDataGroups(featureGroupList);
  }, []);

  return (
    <Box>
      <StyledToggleButtonGroup
        value={groupType}
        exclusive
        onChange={handleChangeGroupType}
        aria-label='text alignment'
      >
        <StyledToggleButton value={DataPartitionEnum.GLOBAL} aria-label={DataPartitionEnum.GLOBAL}>
          {DataPartitionEnum.GLOBAL}
        </StyledToggleButton>
        <StyledToggleButton value={DataPartitionEnum.DATA} aria-label={DataPartitionEnum.DATA}>
          {DataPartitionEnum.DATA}
        </StyledToggleButton>
        <StyledToggleButton
          value={DataPartitionEnum.METADATA}
          aria-label={DataPartitionEnum.METADATA}
        >
          {DataPartitionEnum.METADATA}
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      {features && (
        <Box mt={3}>
          {groupType === DataPartitionEnum.GLOBAL && (
            <>
              <Typography variant='subtitle1' mb={2}>
                Parameter
              </Typography>

              <FormControl>
                <RadioGroup
                  aria-labelledby='radio-buttons-group-options-label'
                  name='radio-buttons-group'
                  sx={{ flexDirection: 'initial' }}
                  value={dataPartitionGlobal.feature_name}
                  onChange={onDataPartitionGlobalChange}
                >
                  {parameter.map((param) => (
                    <FormControlLabel
                      value={param.feature_name}
                      control={<Radio />}
                      label={param.feature_name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          )}
          {groupType === DataPartitionEnum.DATA && dataGroups && (
            <SourceDataSearchSelect
              rows={dataGroups}
              rowLabel='Data group'
              onRowChange={onDataPartitionDataChange}
              defaultValue={dataPartitionData}
            />
          )}
          {groupType === DataPartitionEnum.METADATA && (
            <SourceDataSearchSelect
              rows={metadata}
              rowLabel='Feature (only 1 should be selected)'
              onRowChange={onDataPartitionMetadataChange}
              defaultValue={dataPartitionMetadata}
            />
          )}
        </Box>
      )}

      <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
        <StyledTextField
          id='outlined-end-adornment'
          type='number'
          className='largeNumber'
          value={bins}
          label='Number of bins'
          onChange={handleChangeBins}
          size='small'
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  className='configArrows'
                  disabled={Number(bins) <= 1}
                  onClick={binsDecrement}
                >
                  <ChevronLeft size={16} className='arrow' />
                </IconButton>
                <IconButton className='configArrows' onClick={binsIncrement}>
                  <ChevronRight size={16} className='arrow' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <StyledTextField
          id='outlined-end-adornment'
          type='number'
          className='largeNumber'
          value={distance}
          label='Max link distance'
          onChange={handleChangeDistance}
          size='small'
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  className='configArrows'
                  disabled={Number(distance) <= 0}
                  onClick={distanceDecrement}
                >
                  <ChevronLeft size={16} className='arrow' />
                </IconButton>
                <IconButton className='configArrows' onClick={distanceIncrement}>
                  <ChevronRight size={16} className='arrow' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={<Checkbox value={checkedEqualize} onChange={handleChangeEqualize} />}
          label='Equalize histogram'
        />
      </Box>
    </Box>
  );
};

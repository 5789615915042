import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const PageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  background: theme.palette.background.default,
}));

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  minHeight: '100vh',
  width: '100%',
}));

export const PageContent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  minWidth: 0,
  background: theme.palette.background.paper,
  padding: `${theme.spacing(4)} ${theme.spacing(5)} ${theme.spacing(10)}`,
  marginTop: '60px',
  '&.gradient-bg:before': {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '-1',
    width: '100%',
    height: '317px',
    content: '""',
    background: 'linear-gradient(135deg, #F8F8F8 0%, #F8F8F8 24%, #edf7f6 46%, #ceedf0 100% )',
  },

  '&.loading': { opacity: 0.3, filter: 'blur(3px)', pointerEvents: 'none' },

  '&.gradient-bg.tabs:before': {
    height: '397px',
  },

  [theme.breakpoints.up('laptop')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(7)} ${theme.spacing(10)}`,
  },

  [theme.breakpoints.up('desktopLarge')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
}));

export const EllipseBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '667.01px',
  height: '615.02px',
  left: '55.01px',
  top: '-331.6px',
  background: '#00D1FF',
  opacity: '0.15',
  filter: 'blur(400px)',
  transform: 'rotate(36.95deg)',
  [theme.breakpoints.down('laptop')]: {
    width: '367px',
  },
}));

export const GradientBackground = styled.div`
  display: flex;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 24%,
    rgba(214, 250, 240, 1) 46%,
    rgba(147, 227, 224, 1) 77%,
    rgba(165, 201, 217, 1) 100%
  );
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  ${({ theme }) => theme.breakpoints.up('laptop')} {
    background: ${({ theme }) => theme.palette.background.paper};
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      min-height: 100vw;
      background: radial-gradient(
        50% 50% at 50% 60%,
        #8ebeff 25%,
        #a5c9d9 50%,
        #93e7e5 75%,
        #fff 100%
      );
      background-blend-mode: overlay, normal;
      content: '';
      background-size: 300% 300%;
      animation: bgAnim 15s ease infinite;
    }
  }

  @keyframes bgAnim {
    0% {
      background-position: 0 30%;
    }
    33% {
      background-position: 70% 10%;
    }
    66% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0 30%;
    }
  }
`;

export const CroppedText = styled(Typography)(() => ({
  wordBreak: 'break-all',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const AuthWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '400px',
  width: '774px',
  zIndex: '2',
  background: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '8px',
  padding: theme.spacing(4, 25),
  '&.no-padding-x': {
    padding: theme.spacing(4),
  },
  '&.centered': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type Alert = {
  type?: 'error' | 'success' | 'warning' | 'info';
  title: string;
  variant?: 'filled';
  description?: string;
  withCloseOption?: boolean;
};

export const initialState = {
  title: '',
  variant: undefined,
  description: undefined,
  withCloseOption: false,
} as Alert;

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    resetAlert: () => initialState,
    setAlert: (state, action) => {
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.variant = action.payload.variant;
      state.description = action.payload.description;
      state.withCloseOption = action.payload.withCloseOption;
    },
  },
});

// actions
export const { resetAlert, setAlert } = alertSlice.actions;

// selectors
export const selectAlert = (state: RootState): Alert => state.alert;

// reducer
export const alertReducer = alertSlice.reducer;

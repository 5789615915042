import React, { FC } from 'react';
import { Check, Lock, Unlock } from 'react-feather';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from 'app/mui/Tooltip';
import { CroppedText } from 'app/shared/styles/common';
import { LockIcon } from 'app/shared/styles/createForm';

import { Card } from '../DatasetCardDialog/DatasetCardDialog.styles';

type ProjectCardDialogProps = {
  id: number;
  name: string;
  is_private: boolean;
  selectProject: (id: number, name: string) => void;
  selectedProjectId?: number;
};

export const ProjectCardDialog: FC<ProjectCardDialogProps> = ({
  name,
  id,
  is_private,
  selectProject,
  selectedProjectId,
}) => {
  const theme = useTheme();

  // Will be needed later
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const projectTypeJSX = (
    <Tooltip title={is_private ? 'Private' : 'Public'} placement='bottom'>
      <LockIcon>{is_private ? <Lock size={24} /> : <Unlock size={24} />}</LockIcon>
    </Tooltip>
  );

  return (
    <Card
      key={id}
      onClick={() => selectProject(id, name)}
      className={classNames({ highlight: selectedProjectId === id })}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <CroppedText maxWidth='170px' variant='caption'>
          {name}
        </CroppedText>
        {selectedProjectId === id && <Check size='16' color={theme.palette.success.main} />}
      </Box>
    </Card>
  );
};

import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export const StyledProgress = styled(LinearProgress)(() => ({
  background: 'linear-gradient(180deg, rgba(18, 40, 57, 0.6) 0%, rgba(30, 176, 233, 0.6) 100%)',
  '.MuiLinearProgress-bar': {
    background: 'linear-gradient(180deg, #122839 0%, #1EB0E9 100%)',
  },
}));

export const Step = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  background: theme.palette.text.disabled,
  width: '20px',
  minWidth: '20px',
  height: '20px',
  color: theme.palette.secondary.contrastText,
  display: 'flex',
  justifyContent: 'center',
}));

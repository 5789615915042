import React from 'react';
import { Global, css } from '@emotion/react';

import InterRegular from 'app/assets/fonts/Inter/Inter-Regular.ttf';

export const GlobalStyle = (
  <Global
    styles={css`
      @font-face {
        font-family: 'Inter';
        src: url(${InterRegular}) format('truetype');
      }
      :root {
        --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }
      /* fix css layers in new version of react-data-grid */
      /* this also broke react-sigma-control */
      *:not(.rdg *, .react-sigma-control) {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
      }
      html {
        font-size: 62.5%;
      }
      body,
      html {
        height: 100%;
        min-height: 100%;
      }
      body {
        font-family: var(--font-family);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.4rem;
        line-height: 1.6;
        color: #122239;
      }
      a {
        text-decoration: none;
        transition: color 0.3s, background-color 0.3s;

        &:visited {
          color: #122239;
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .clickable {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    `}
  />
);

import React, { FC } from 'react';
import { Trash2, X, CheckCircle } from 'react-feather';
import { useTheme } from '@mui/material/styles';
import { Button } from 'app/mui/Button';
import { UnpinIcon } from 'app/shared/icons';

import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogActions,
  IconWrap,
} from './Popup.styles';

enum States {
  Success = 'Success',
  Error = 'Error',
  Delete = 'Delete',
  Detach = 'Detach',
}

type Props = {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onConfirm: () => void;
  state?: 'Success' | 'Error' | 'Delete' | 'Detach';
};

export const Popup: FC<Props> = ({ open, state, title, description, onClose, onConfirm }) => {
  const theme = useTheme();

  return (
    <StyledDialog
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      open={open}
      onClose={onClose}
    >
      <IconWrap>
        {state === States.Success && <CheckCircle size='28' color={theme.palette.success.main} />}
        {state === States.Error && <X size='28' color={theme.palette.error.main} />}
        {state === States.Delete && <Trash2 size='28' color={theme.palette.error.main} />}
        {state === States.Detach && (
          <UnpinIcon width={28} height={28} color={theme.palette.error.main} />
        )}
      </IconWrap>
      <StyledDialogTitle id='alert-dialog-title'>{title}</StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText id='alert-dialog-description'>
          {description}
        </StyledDialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        {state === States.Delete || state === States.Detach ? (
          <>
            <Button variant='text' size='large' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='error' size='large' onClick={onConfirm}>
              {state === States.Delete && 'Delete'}
              {state === States.Detach && 'Detach'}
            </Button>
          </>
        ) : (
          <Button variant='contained' size='large' onClick={onClose}>
            Ok
          </Button>
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};

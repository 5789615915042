import React from 'react';
import { styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';

export const StyledDotsMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  '.MuiList-root': {
    padding: theme.spacing(1),
  },
  '&.visualizationList .MuiList-root': {
    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
  },
  '.MuiPaper-root': {
    maxWidth: '272px',
    boxShadow:
      '0px 5px 5px -3px rgba(18, 34, 57, 0.2), 0px 8px 10px 1px rgba(18, 34, 57, 0.14), 0px 3px 14px 2px rgba(18, 34, 57, 0.12)',
  },
  '.MuiMenuItem-root': {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '1.4rem',
    lineHeight: '1.7',
    color: theme.palette.action.active,
    '& span': {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      backgroundColor: theme.palette.blocksBackground.hover,
      color: theme.palette.hover.primary,
    },
    '+ .MuiDivider-root': {
      margin: `0 ${theme.spacing(2)}`,
    },
    '+ .MuiDivider-root.MuiDivider-fullWidth': {
      margin: `0`,
    },
    '& svg': {
      display: 'block',
      width: '20px',
      height: '20px',
      marginRight: theme.spacing(1),
    },
    '& svg.inline': {
      display: 'block',
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(0),
    },
    '&.delete': {
      color: theme.palette.error.dark,
      '& span': {
        color: theme.palette.error.dark,
      },
    },
  },
}));

export const DotsMenu = React.forwardRef((props: MenuProps, ref: React.ForwardedRef<any>) => (
  <StyledDotsMenu
    ref={ref}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

import React, { FC, useEffect, useMemo, useState } from 'react';
import Plot from 'app/shared/components/Plotly/index.js';
import { Layout, PlotMouseEvent, PlotSelectionEvent } from 'plotly.js';

import {
  DEFAULT_NODE_COLOR,
  DEFAULT_SCATTER_PLOT_SYMBOL,
} from 'app/screens/Analysis/Coloring/Coloring.utils';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { Coloring } from 'app/screens/Analysis/Coloring/Coloring';
import {
  ChartDataType,
  ColoringConfig,
  SelectedFeatureType,
  SELECTION_SOURCES,
} from 'app/screens/Analysis/Analysis.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import {
  scatterPlotConfig,
  DEFAULT_CHART_HEIGHT,
} from 'app/screens/Analysis/Visualizations/Wrappers/Chart.config';
import {
  getModebarButtonsForGroupOne,
  isInSelectionMode,
} from 'app/screens/Analysis/Visualizations/Wrappers/Chart.utils';
import { ColorLegendType } from 'app/screens/Analysis/Coloring/Coloring.types';

type Props = {
  id: number;
  analysisId: number;
  type: VisualisationType;
  panelId: string;
  chart: ChartDataType;
  width: number;
  height?: number;
  coloring?: ColoringConfig;
  isLargePan?: boolean;
  isGenerateSvg: boolean;
  onSvgGenerate: (chart: ChartDataType) => void;
  setColoringTypeRef: (coloringType: SelectedFeatureType | null) => void;
  setLegendRef: (legend: ColorLegendType | null) => void;
};

export const ScatterPlot: FC<Props> = ({
  type,
  panelId,
  id,
  analysisId,
  chart,
  coloring,
  isLargePan,
  width,
  height = DEFAULT_CHART_HEIGHT,
  isGenerateSvg,
  onSvgGenerate,
  setColoringTypeRef,
  setLegendRef,
}) => {
  const [shouldInvertSelection, setShouldInvertSelection] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState<number[] | undefined>(undefined);
  const [colors, setColors] = useState<string[] | string>(DEFAULT_NODE_COLOR);
  const [symbols, setSymbols] = useState<string[] | string>(DEFAULT_SCATTER_PLOT_SYMBOL);
  const [chartLayoutState, setChartLayoutState] = useState<Partial<Layout>>({
    height,
    width,
    xaxis: { title: chart.features[0].feature_name },
    yaxis: { title: chart.features[1].feature_name },
  });

  const {
    setSelectedDataRows,
    setDataSelectionSource,
    selectedDataRows,
    dataPointsSelectionSource,
  } = useAnalysis();

  const isCurrentChartSelection = useMemo(
    () =>
      dataPointsSelectionSource?.source === SELECTION_SOURCES.SCATTER_PLOT &&
      dataPointsSelectionSource?.id === chart.id,
    [chart.id, dataPointsSelectionSource?.id, dataPointsSelectionSource?.source]
  );

  useEffect(() => {
    if (chart) {
      setChartLayoutState((prevState) => ({
        ...prevState,
        xaxis: { title: chart.features[0].feature_name },
        yaxis: { title: chart.features[1].feature_name },
      }));
    }
  }, [chart]);

  useEffect(() => {
    setChartLayoutState((prevState) => ({
      ...prevState,
      width,
      height,
    }));
  }, [width, height]);

  useEffect(() => {
    if (!isCurrentChartSelection) {
      setChartLayoutState({
        width,
        height,
        xaxis: { title: chart.features[0].feature_name },
        yaxis: { title: chart.features[1].feature_name },
      });
    }
  }, [width, height, isCurrentChartSelection, chart]);

  const setDefaultColors = (): void => {
    setColors(DEFAULT_NODE_COLOR);
    setSymbols(DEFAULT_SCATTER_PLOT_SYMBOL);
  };

  const setColoring = (colorsList: string[] | string, symbolsList?: string[] | string): void => {
    setColors(colorsList);
    setSymbols(symbolsList || DEFAULT_SCATTER_PLOT_SYMBOL);
  };

  const clearSelectionMode = (): void => {
    setChartLayoutState((prevState) => ({
      ...prevState,
      selections: undefined,
    }));
  };

  const handleDoubleClick = (event: Readonly<PlotMouseEvent>): void => {
    if (!event?.points) return;

    if (isInSelectionMode(chartLayoutState.dragmode)) return;

    const dataIndices = event.points.map((data) => data.pointNumber);
    const dataPoints = dataIndices.map((index) => chart.config.data_points[index] ?? index);

    const alreadySelectedRows = isCurrentChartSelection ? selectedDataRows : [];
    const isShiftKey = (event.event as MouseEvent).shiftKey;
    const isAlreadySelected = alreadySelectedRows.includes(dataPoints[0]);

    if (isAlreadySelected) {
      const filteredDataPoints = alreadySelectedRows.filter((i) => i !== dataPoints[0]);
      setSelectedDataRows(filteredDataPoints);
    } else if (isShiftKey) {
      const selectedPointsSet = new Set([...alreadySelectedRows, ...dataPoints]);
      setSelectedDataRows(Array.from(selectedPointsSet));
    } else {
      setSelectedDataRows(dataPoints);
    }

    setDataSelectionSource({
      source: SELECTION_SOURCES.SCATTER_PLOT,
      id: chart.id,
      name: chart.name,
    });

    clearSelectionMode();
  };

  const handleSelected = (event: Readonly<PlotSelectionEvent>): void => {
    if (!event?.points) return;

    const dataIndices = event.points.map((data) => data.pointNumber);
    const dataPoints = dataIndices.map((index) => chart.config.data_points[index] ?? index);

    if (dataPoints.length) {
      setSelectedDataRows(dataPoints);
      setDataSelectionSource({
        source: SELECTION_SOURCES.SCATTER_PLOT,
        id: chart.id,
        name: chart.name,
      });
    }
  };

  useEffect(() => {
    const hasSelected = !!selectedDataRows.length;
    const selectedIndices = selectedDataRows.map((row) => {
      const rowIndex = chart.config.data_points.findIndex((point) => row === point);

      return rowIndex >= 0 ? rowIndex : row;
    });

    const points = isCurrentChartSelection && hasSelected ? selectedIndices : undefined;
    setSelectedPoints(points);
  }, [chart, isCurrentChartSelection, selectedDataRows]);

  const handleDeselect = (): void => {
    setSelectedDataRows([]);
    setDataSelectionSource(null);
  };

  const onResetScale = (): void => {
    setChartLayoutState({
      height,
      width,
      xaxis: { title: chart.features[0].feature_name },
      yaxis: { title: chart.features[1].feature_name },
    });
  };

  const onClearSelection = (): void => {
    onResetScale();
    handleDeselect();
  };

  const onInvertSelection = (): void => {
    setShouldInvertSelection(true);
  };

  useEffect(() => {
    if (shouldInvertSelection && selectedPoints) {
      const invertedIndices = chart.config.data_points.filter(
        (point) => selectedPoints.indexOf(point) === -1
      );
      const invertedDataPoints = invertedIndices.map(
        (index) => chart.config.data_points[index] ?? index
      );

      clearSelectionMode();
      setSelectedPoints(invertedIndices);
      setSelectedDataRows(invertedDataPoints);
      setShouldInvertSelection(false);
    } else {
      // State of the Plotly component remains the same, the onClick event will not have access to the updated state.
      // We should manually reset "shouldInvertSelection" if "selectedPoints" if undefined
      setShouldInvertSelection(false);
    }
  }, [chart, selectedPoints, shouldInvertSelection]);

  useEffect(() => {
    if (isGenerateSvg && chart) {
      onSvgGenerate(chart);
    }
  }, [isGenerateSvg, chart]);

  return (
    <>
      <Coloring
        key={id}
        type={type}
        panelId={panelId}
        analysisId={analysisId}
        visualizationId={id}
        chart={chart}
        coloring={coloring}
        isLargePan={isLargePan}
        onChartColoringUpdate={setColoring}
        setInitialColoringData={setDefaultColors}
        setColoringTypeRef={setColoringTypeRef}
        setLegendRef={setLegendRef}
      />
      <div className='chart-container scatter-plot'>
        {
          // @ts-ignore
          <Plot
            divId={`${chart.type}_${chart.id}`}
            data={[
              {
                x: chart.features[0].values,
                y: chart.features[1].values,
                type: 'scatter',
                mode: 'markers',
                selectedpoints: selectedPoints,
                marker: {
                  color: colors,
                  symbol: symbols,
                },
              },
            ]}
            layout={chartLayoutState}
            config={{
              modeBarButtons: getModebarButtonsForGroupOne({
                onResetScale,
                onClearSelection,
                onInvertSelection,
              }),
              doubleClick: false,
              ...scatterPlotConfig,
            }}
            onSelected={handleSelected}
            onDeselect={handleDeselect}
            onDoubleClick={handleDoubleClick}
          />
        }
      </div>
    </>
  );
};

import React, { FC, useCallback, useState, useMemo } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { Eye, EyeOff, Mail, ArrowLeft } from 'react-feather';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useAuthentication } from 'app/shared/hooks/useAuthentication';
import { TextFieldForm } from 'app/mui/TextFieldForm';
import { Button } from 'app/mui/Button';
import { IconButton } from 'app/mui/IconButton';
import { AuthWrapper, GradientBackground } from 'app/shared/styles/common';
import { Header } from 'app/shared/components/Header/Header';
import { Terms } from 'app/shared/components/Terms/Terms';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { useAlert } from 'app/shared/hooks/useAlert';
import { useAppSelector } from 'app/store/hooks';
import successIcon from 'app/assets/images/auth/success.svg';
import { selectCurrentEmail } from 'app/store/modules/account';
import PasswordRegex from 'app/shared/components/PasswordRegex/PasswordRegex';

interface ResetPasswordValues {
  email: string;
}

interface ChangePasswordValues {
  code: string;
  newPassword: string;
}

const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
});

const changePasswordValidationSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      'Password should match pattern'
    ),
});

enum FORGOT_PASSWORD_STEPS {
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  SIGN_IN,
}

const backButtonStyles = {
  position: 'absolute',
  top: 29,
  left: 29,
};

const getTitle = (step: FORGOT_PASSWORD_STEPS): string => {
  switch (step) {
    case FORGOT_PASSWORD_STEPS.RESET_PASSWORD:
      return 'Reset Password';

    case FORGOT_PASSWORD_STEPS.CHANGE_PASSWORD:
      return 'Email Sent';

    case FORGOT_PASSWORD_STEPS.SIGN_IN:
      return 'Password Changed';

    default:
      return 'Reset Password';
  }
};

const getSubtitle = (step: FORGOT_PASSWORD_STEPS): string => {
  switch (step) {
    case FORGOT_PASSWORD_STEPS.RESET_PASSWORD:
      return 'Enter the email address associated with your account to get a reset code.';

    case FORGOT_PASSWORD_STEPS.CHANGE_PASSWORD:
      return 'We sent a password reset code to your inbox. Please enter it below to proceed.';

    case FORGOT_PASSWORD_STEPS.SIGN_IN:
      return 'Sign in now';

    default:
      return 'Enter the email address associated with your account to get a reset code.';
  }
};

export const ForgotPassword: FC = () => {
  const { navigateToLogin } = useNavigation();
  const { showSuccessMessage, showErrorMessage } = useAlert();
  const { loading, resetPassword, changePassword } = useAuthentication();

  const currentEmail = useAppSelector(selectCurrentEmail);

  const [step, setStep] = useState(FORGOT_PASSWORD_STEPS.RESET_PASSWORD);

  const [showCode, setShowCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isPasswordActive, setIsPasswordActive] = useState(false);

  const [isValidated, setIsValidated] = useState(false);

  const showHeader = useMemo(
    () =>
      step === FORGOT_PASSWORD_STEPS.CHANGE_PASSWORD ||
      step === FORGOT_PASSWORD_STEPS.RESET_PASSWORD,
    [step]
  );

  const goBack = useCallback(() => {
    if (step === FORGOT_PASSWORD_STEPS.RESET_PASSWORD) {
      navigateToLogin();
    } else if (step === FORGOT_PASSWORD_STEPS.CHANGE_PASSWORD) {
      setStep(FORGOT_PASSWORD_STEPS.RESET_PASSWORD);
    }
  }, [step]);

  const handleClickShowCode = useCallback(() => {
    setShowCode((prevState) => !prevState);
  }, [setShowCode]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  const handleMouseDownCode = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleResetPassword = useCallback(
    async (
      values: ResetPasswordValues,
      setErrors: (errors: FormikErrors<ResetPasswordValues>) => void
    ) => {
      try {
        await resetPassword(values.email);
        setStep(FORGOT_PASSWORD_STEPS.CHANGE_PASSWORD);
      } catch (e: any) {
        setErrors({
          email: e.message,
        });
      }
    },
    []
  );

  const handleChangePassword = useCallback(
    async (
      values: ChangePasswordValues,
      setErrors: (errors: FormikErrors<ChangePasswordValues>) => void
    ) => {
      try {
        await changePassword(currentEmail, values.code, values.newPassword);
        setStep(FORGOT_PASSWORD_STEPS.SIGN_IN);
      } catch (e: any) {
        const error = e.toString().split(':');

        const type = error[0];
        const message = error[1];

        const codeMessage = type === 'CodeMismatchException' ? message : '';
        const newPasswordMessage = type === 'LimitExceededException' ? message : '';

        setErrors({
          code: codeMessage,
          newPassword: newPasswordMessage,
        });
      }
    },
    [currentEmail]
  );

  const resendEmail = useCallback(async () => {
    try {
      await resetPassword(currentEmail);

      showSuccessMessage({
        title: 'We sent a password reset code to your inbox.',
      });
    } catch (e: any) {
      showErrorMessage({
        title: e.message,
      });
    }
  }, [currentEmail]);

  const onBlurPassword = useCallback(() => {
    setIsPasswordActive(false);
  }, []);

  const onFocusPassword = useCallback(() => {
    setIsPasswordActive(true);
  }, []);

  return (
    <>
      <Header isTransparent />
      <GradientBackground>
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='text.secondary'
        >
          <AuthWrapper className={classNames({ 'centered no-padding-x': !showHeader })}>
            {showHeader && (
              <>
                <IconButton onClick={goBack} sx={backButtonStyles}>
                  <ArrowLeft />
                </IconButton>
                <Typography
                  component='h3'
                  variant='h3'
                  mb={3}
                  textAlign='center'
                  color='primary.main'
                >
                  {getTitle(step)}
                </Typography>
                <Typography component='p' textAlign='center' color='text.secondary'>
                  {getSubtitle(step)}
                </Typography>
              </>
            )}
            {step === FORGOT_PASSWORD_STEPS.RESET_PASSWORD && (
              <>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={resetPasswordValidationSchema}
                  onSubmit={(values, { setErrors, setSubmitting }) => {
                    handleResetPassword(values, setErrors).then(() => {
                      setSubmitting(false);
                    });
                  }}
                >
                  {({ submitForm, isSubmitting, setFieldTouched }) => (
                    <Form>
                      <Box mt={4} height={75}>
                        <Field
                          component={TextFieldForm}
                          name='email'
                          type='email'
                          label='Email'
                          placeholder='example@mail.com'
                          fullWidth
                          onBlur={() => setFieldTouched('email', true)}
                          onFocus={() => setFieldTouched('email', false)}
                          InputProps={{
                            endAdornment: <Mail size={22} />,
                          }}
                        />
                      </Box>
                      <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        type='submit'
                        disabled={isSubmitting || loading}
                        onClick={submitForm}
                        fullWidth
                      >
                        Reset Password
                      </Button>
                    </Form>
                  )}
                </Formik>
                <Box mt={4} textAlign='center'>
                  <Typography component='span' fontSize='14' mr={1} color='text.secondary'>
                    Remembered password?
                  </Typography>
                  <Link
                    component='button'
                    variant='body2'
                    fontWeight='bold'
                    onClick={navigateToLogin}
                  >
                    Sign in
                  </Link>
                </Box>
              </>
            )}
            {step === FORGOT_PASSWORD_STEPS.CHANGE_PASSWORD && (
              <>
                <Formik
                  initialValues={{
                    code: '',
                    newPassword: '',
                  }}
                  validationSchema={changePasswordValidationSchema}
                  onSubmit={(values, { setErrors, setSubmitting }) => {
                    handleChangePassword(values, setErrors).then(() => {
                      setSubmitting(false);
                    });
                  }}
                >
                  {({ submitForm, isSubmitting, values }) => (
                    <Form>
                      <Box mt={4} height={75}>
                        <Field
                          component={TextFieldForm}
                          type={showCode ? 'text' : 'password'}
                          label='Code'
                          placeholder='Code'
                          name='code'
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label='toggle code visibility'
                                onClick={handleClickShowCode}
                                onMouseDown={handleMouseDownCode}
                                color='primary'
                                size='small'
                                sx={{
                                  padding: 0,
                                }}
                              >
                                {showCode ? <EyeOff /> : <Eye />}
                              </IconButton>
                            ),
                          }}
                          fullWidth
                        />
                      </Box>
                      <Box height={75} display='flex' position='relative'>
                        <Field
                          component={TextFieldForm}
                          type={showPassword ? 'text' : 'password'}
                          label='New Password'
                          placeholder='New Password'
                          name='newPassword'
                          onBlur={onBlurPassword}
                          onFocus={onFocusPassword}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                color='primary'
                                size='small'
                                sx={{
                                  padding: 0,
                                }}
                              >
                                {showPassword ? <EyeOff /> : <Eye />}
                              </IconButton>
                            ),
                          }}
                          fullWidth
                        />
                        {isPasswordActive && (
                          <Box
                            sx={{
                              position: 'absolute',
                              right: '-220px',
                              top: '-46px',
                              borderRadius: '8px',
                              background: '#F2FCFF',
                              padding: '16px',
                            }}
                          >
                            <PasswordRegex
                              password={values.newPassword}
                              isValidated={isValidated}
                            />
                          </Box>
                        )}
                      </Box>
                      <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        type='submit'
                        disabled={isSubmitting || loading}
                        onClick={() => {
                          submitForm().then(() => {
                            setIsValidated(true);
                          });
                        }}
                        fullWidth
                      >
                        Change Password
                      </Button>
                    </Form>
                  )}
                </Formik>
                <Box mt={4} textAlign='center'>
                  <Typography component='span' fontSize='14' mr={1} color='text.secondary'>
                    Didn't receive the email?
                  </Typography>
                  <Link component='button' variant='body2' fontWeight='bold' onClick={resendEmail}>
                    Resend
                  </Link>
                </Box>
              </>
            )}
            {step === FORGOT_PASSWORD_STEPS.SIGN_IN && (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Box
                  component='img'
                  src={successIcon}
                  alt='success icon'
                  sx={{
                    width: 54,
                    height: 54,
                  }}
                />
                <Typography component='h3' variant='h3' color='primary.main' my={5}>
                  Password Successfully Changed
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  type='submit'
                  onClick={navigateToLogin}
                  sx={{
                    width: 250,
                  }}
                >
                  Sign in
                </Button>
              </Box>
            )}
          </AuthWrapper>
        </Box>
        <Terms />
      </GradientBackground>
    </>
  );
};

/* eslint no-constant-condition: 0 */
/* eslint no-restricted-properties: "warn" */
/* eslint prefer-destructuring: "warn" */
/* eslint no-param-reassign: "warn" */
/**
 * Graphology ForceAtlas2 Iteration
 * =================================
 *
 * Function used to perform a single iteration of the algorithm.
 */
import { ForceAtlas2Settings } from 'graphology-layout-forceatlas2';
import {
  NODE_X,
  NODE_Y,
  NODE_DX,
  NODE_DY,
  NODE_FIXED,
  NODE_SIZE,
  NODE_MASS,
  NODE_CONVERGENCE,
  NODE_OLD_DX,
  NODE_OLD_DY,
  PPN,
  PPE,
  EDGE_SOURCE,
  EDGE_TARGET,
  EDGE_WEIGHT,
} from './matrixProps';

const MAX_FORCE = 10;

/**
 * Function used to calculate dx, dy for each node due to repulsion forces.
 *
 * @param  {object}       options    - Layout options.
 * @param  {Float32Array} NodeMatrix - Node data. (operates in place)
 * @param  {string[][]} graphComponents - Graph data.
 * @return {object}                  - Some metadata.
 */
function getMovementDifferentialsDueToRepulsion(
  options: ForceAtlas2Settings,
  NodeMatrix: Float32Array,
  graphComponents: string[][]
) {
  const coefficient = options.scalingRatio ?? 1;
  const adjustSizes = options.adjustSizes;

  let xDist;
  let yDist;
  let distance;
  let factor;

  let n1;
  let n2;
  if (adjustSizes === true) {
    graphComponents.forEach((component) => {
      for (let i = 0; i < component.length; i += 1) {
        n1 = Number(component[i]) * PPN;
        for (let j = 0; j < i; j += 1) {
          n2 = Number(component[j]) * PPN;

          xDist = NodeMatrix[n1 + NODE_X] - NodeMatrix[n2 + NODE_X];
          yDist = NodeMatrix[n1 + NODE_Y] - NodeMatrix[n2 + NODE_Y];

          // -- Anticollision Linear Repulsion
          distance =
            Math.sqrt(xDist * xDist + yDist * yDist) -
            NodeMatrix[n1 + NODE_SIZE] -
            NodeMatrix[n2 + NODE_SIZE];

          if (distance > 0) {
            factor =
              (coefficient * NodeMatrix[n1 + NODE_MASS] * NodeMatrix[n2 + NODE_MASS]) /
              distance /
              distance;

            // Updating nodes' dx and dy
            NodeMatrix[n1 + NODE_DX] += xDist * factor;
            NodeMatrix[n1 + NODE_DY] += yDist * factor;

            NodeMatrix[n2 + NODE_DX] -= xDist * factor;
            NodeMatrix[n2 + NODE_DY] -= yDist * factor;
          } else if (distance < 0) {
            factor = 100 * coefficient * NodeMatrix[n1 + NODE_MASS] * NodeMatrix[n2 + NODE_MASS];

            // Updating nodes' dx and dy
            NodeMatrix[n1 + NODE_DX] += xDist * factor;
            NodeMatrix[n1 + NODE_DY] += yDist * factor;

            NodeMatrix[n2 + NODE_DX] -= xDist * factor;
            NodeMatrix[n2 + NODE_DY] -= yDist * factor;
          }
        }
      }
    });
  } else {
    graphComponents.forEach((component) => {
      for (let i = 0; i < component.length; i += 1) {
        n1 = Number(component[i]) * PPN;
        for (let j = 0; j < i; j += 1) {
          n2 = Number(component[j]) * PPN;

          xDist = NodeMatrix[n1 + NODE_X] - NodeMatrix[n2 + NODE_X];
          yDist = NodeMatrix[n1 + NODE_Y] - NodeMatrix[n2 + NODE_Y];

          // -- Linear Repulsion
          const distance_squared = xDist * xDist + yDist * yDist;

          if (distance_squared > 0) {
            distance = Math.sqrt(distance_squared);

            factor =
              (coefficient * NodeMatrix[n1 + NODE_MASS] * NodeMatrix[n2 + NODE_MASS]) /
              distance_squared;

            const norm_x = xDist / distance;
            const norm_y = yDist / distance;

            // Updating nodes' dx and dy
            NodeMatrix[n1 + NODE_DX] += norm_x * factor;
            NodeMatrix[n1 + NODE_DY] += norm_y * factor;

            NodeMatrix[n2 + NODE_DX] -= norm_x * factor;
            NodeMatrix[n2 + NODE_DY] -= norm_y * factor;
          }
        }
      }
    });
  }
}

/**
 * Function used to perform a single interation of the algorithm.
 *
 * @param  {object}       options    - Layout options.
 * @param  {Float32Array} NodeMatrix - Node data.
 * @param  {Float32Array} EdgeMatrix - Edge data.
 * @param  {string[][]}   graphComponents - Graph Components
 * @return {object}                  - Some metadata.
 */ export function iterateNoIntercomponentRepel(
  options: ForceAtlas2Settings,
  NodeMatrix: Float32Array,
  EdgeMatrix: Float32Array,
  graphComponents: string[][]
) {
  // Initializing variables
  let n;
  let n1;
  let n2;
  let e;

  const adjustSizes = options.adjustSizes;
  let outboundAttCompensation;
  let xDist;
  let yDist;
  let ewc;
  let distance;
  let factor = 0;
  let force;
  let swinging;
  let traction;
  let nodespeed;

  // 1) Initializing layout data
  //-----------------------------

  // Resetting movements.
  for (n = 0; n < NodeMatrix.length; n += PPN) {
    NodeMatrix[n + NODE_OLD_DX] = NodeMatrix[n + NODE_DX];
    NodeMatrix[n + NODE_OLD_DY] = NodeMatrix[n + NODE_DY];
    NodeMatrix[n + NODE_DX] = 0;
    NodeMatrix[n + NODE_DY] = 0;
  }

  const numberVertices = NodeMatrix.length / PPN;

  // If outbound attraction distribution, compensate
  if (options.outboundAttractionDistribution) {
    outboundAttCompensation = 0;
    for (n = 0; n < NodeMatrix.length; n += PPN) {
      outboundAttCompensation += NodeMatrix[n + NODE_MASS];
    }

    outboundAttCompensation /= numberVertices;
  }

  // Repulsion
  //--------------
  // NOTES: adjustSizes = antiCollision & scalingRatio = coefficient

  // Writes to NodeMatrix.
  getMovementDifferentialsDueToRepulsion(options, NodeMatrix, graphComponents);

  // 4) Attraction
  //---------------
  const coefficient = options.outboundAttractionDistribution ? outboundAttCompensation : 1;
  if (!coefficient) return;
  const size = EdgeMatrix.length;
  for (e = 0; e < size; e += PPE) {
    n1 = EdgeMatrix[e + EDGE_SOURCE];
    n2 = EdgeMatrix[e + EDGE_TARGET];
    const w = EdgeMatrix[e + EDGE_WEIGHT];

    // Edge weight influence
    ewc = w ** (options.edgeWeightInfluence ?? 1);

    // Common measures
    xDist = NodeMatrix[n1 + NODE_X] - NodeMatrix[n2 + NODE_X];
    yDist = NodeMatrix[n1 + NODE_Y] - NodeMatrix[n2 + NODE_Y];

    // Applying attraction to nodes
    if (adjustSizes === true) {
      distance =
        Math.sqrt(xDist * xDist + yDist * yDist) -
        NodeMatrix[n1 + NODE_SIZE] -
        NodeMatrix[n2 + NODE_SIZE];

      if (options.linLogMode) {
        if (options.outboundAttractionDistribution) {
          // -- LinLog Degree Distributed Anti-collision Attraction
          if (distance > 0) {
            factor =
              (-coefficient * ewc * Math.log(1 + distance)) / distance / NodeMatrix[n1 + NODE_MASS];
          }
        } else if (distance > 0) {
          // -- LinLog Anti-collision Attraction
          factor = (-coefficient * ewc * Math.log(1 + distance)) / distance;
        }
      } else if (options.outboundAttractionDistribution) {
        // -- Linear Degree Distributed Anti-collision Attraction
        if (distance > 0) {
          factor = (-coefficient * ewc) / NodeMatrix[n1 + NODE_MASS];
        }
      } else if (distance > 0) {
        // -- Linear Anti-collision Attraction
        factor = -coefficient * ewc;
      }
    } else {
      distance = Math.sqrt(xDist * xDist + yDist * yDist);

      if (options.linLogMode) {
        if (options.outboundAttractionDistribution) {
          // -- LinLog Degree Distributed Attraction
          if (distance > 0) {
            factor =
              (-coefficient * ewc * Math.log(1 + distance)) / distance / NodeMatrix[n1 + NODE_MASS];
          }
        } else if (distance > 0) {
          factor = (-coefficient * ewc * Math.log(1 + distance)) / distance;
        }
      } else if (options.outboundAttractionDistribution) {
        // -- Linear Attraction Mass Distributed
        // NOTE: Distance is set to 1 to override next condition
        distance = 1;
        factor = (-coefficient * ewc) / NodeMatrix[n1 + NODE_MASS];
      } else {
        // -- Linear Attraction
        // NOTE: Distance is set to 1 to override next condition
        distance = 1;
        factor = -coefficient * ewc;
      }
    }

    // Updating nodes' dx and dy
    // TODO: if condition or factor = 1?
    if (distance > 0) {
      // Updating nodes' dx and dy
      NodeMatrix[n1 + NODE_DX] += xDist * factor;
      NodeMatrix[n1 + NODE_DY] += yDist * factor;

      NodeMatrix[n2 + NODE_DX] -= xDist * factor;
      NodeMatrix[n2 + NODE_DY] -= yDist * factor;
    }
  }

  // Apply Forces
  // -----------------
  // MATH: sqrt and square distances
  if (adjustSizes === true) {
    for (n = 0; n < NodeMatrix.length; n += PPN) {
      if (NodeMatrix[n + NODE_FIXED] !== 1) {
        force = Math.sqrt(NodeMatrix[n + NODE_DX] ** 2 + NodeMatrix[n + NODE_DY] ** 2);

        if (force > MAX_FORCE) {
          NodeMatrix[n + NODE_DX] = (NodeMatrix[n + NODE_DX] * MAX_FORCE) / force;
          NodeMatrix[n + NODE_DY] = (NodeMatrix[n + NODE_DY] * MAX_FORCE) / force;
        }

        swinging =
          NodeMatrix[n + NODE_MASS] *
          Math.sqrt(
            (NodeMatrix[n + NODE_OLD_DX] - NodeMatrix[n + NODE_DX]) *
              (NodeMatrix[n + NODE_OLD_DX] - NodeMatrix[n + NODE_DX]) +
              (NodeMatrix[n + NODE_OLD_DY] - NodeMatrix[n + NODE_DY]) *
                (NodeMatrix[n + NODE_OLD_DY] - NodeMatrix[n + NODE_DY])
          );

        traction =
          Math.sqrt(
            (NodeMatrix[n + NODE_OLD_DX] + NodeMatrix[n + NODE_DX]) *
              (NodeMatrix[n + NODE_OLD_DX] + NodeMatrix[n + NODE_DX]) +
              (NodeMatrix[n + NODE_OLD_DY] + NodeMatrix[n + NODE_DY]) *
                (NodeMatrix[n + NODE_OLD_DY] + NodeMatrix[n + NODE_DY])
          ) / 2;

        nodespeed = (0.1 * Math.log(1 + traction)) / (1 + Math.sqrt(swinging));

        // Updating node's positon
        const deltaX = NodeMatrix[n + NODE_DX] * (nodespeed / (options.slowDown ?? 1));
        NodeMatrix[n + NODE_X] += deltaX;

        const deltaY = NodeMatrix[n + NODE_DY] * (nodespeed / (options.slowDown ?? 1));
        NodeMatrix[n + NODE_Y] += deltaY;
      }
    }
  } else {
    for (n = 0; n < NodeMatrix.length; n += PPN) {
      if (NodeMatrix[n + NODE_FIXED] !== 1) {
        const ddx = NodeMatrix[n + NODE_OLD_DX] - NodeMatrix[n + NODE_DX];
        const ddy = NodeMatrix[n + NODE_OLD_DY] - NodeMatrix[n + NODE_DY];

        swinging = NodeMatrix[n + NODE_MASS] * Math.sqrt(ddx ** 2 + ddy ** 2);

        const dxSum = NodeMatrix[n + NODE_OLD_DX] + NodeMatrix[n + NODE_DX];

        const dySum = NodeMatrix[n + NODE_OLD_DY] + NodeMatrix[n + NODE_DY];
        const swingingNormTerm = 1 + Math.sqrt(swinging);
        traction = Math.sqrt(dxSum ** 2 + dySum ** 2) / 2;

        nodespeed = (NodeMatrix[n + NODE_CONVERGENCE] * Math.log(1 + traction)) / swingingNormTerm;

        // Updating node convergence
        NodeMatrix[n + NODE_CONVERGENCE] = Math.min(
          1,
          Math.sqrt(
            (nodespeed * (NodeMatrix[n + NODE_DX] ** 2 + NodeMatrix[n + NODE_DY] ** 2)) /
              swingingNormTerm
          )
        );

        // Updating node's positon
        const stepSize = nodespeed / (options.slowDown ?? 1);
        NodeMatrix[n + NODE_X] += NodeMatrix[n + NODE_DX] * stepSize;
        NodeMatrix[n + NODE_Y] += NodeMatrix[n + NODE_DY] * stepSize;
      }
    }
  }

  // We return the information about the layout (no need to return the matrices)
  return {};
}

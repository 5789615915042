export const parameterOptions = [
  {
    feature_name: 'L1 eccentricity',
    id: 0,
  },
  {
    feature_name: 'L-inf eccentricity',
    id: 1,
  },
  {
    feature_name: 'Gaussian density',
    id: 2,
  },
];

export const metadataRows = [
  {
    feature_name: 'Сount',
    id: 'count',
  },
  {
    feature_name: 'Mean',
    id: 'mean',
  },
  {
    feature_name: 'Std',
    id: 'std',
  },
  {
    feature_name: 'Min',
    id: 'min',
  },
  {
    feature_name: '25%',
    id: 'percent_25',
  },
  {
    feature_name: '50%',
    id: 'percent_50',
  },
  {
    feature_name: '75%',
    id: 'percent_75',
  },
  {
    feature_name: 'Max',
    id: 'max',
  },
  {
    feature_name: 'Non-0 count',
    id: 'non_nan',
  },
  {
    feature_name: 'NaN count',
    id: 'nan',
  },
];

import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  '&.MuiCheckbox-colorPrimary': {
    '&:not(.Mui-checked)': {
      color: theme.palette.state.restingBorder,
    },
    '&:hover': {
      color: theme.palette.hover.primary,
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
    },
  },
  '+ .MuiFormControlLabel-label': {
    fontSize: '1.4rem',
  },
}));

export { StyledCheckbox as Checkbox };

/**
 * Matrices properties accessors.
 */
export const NODE_X = 0;
export const NODE_Y = 1;
export const NODE_DX = 2;
export const NODE_DY = 3;
export const NODE_OLD_DX = 4;
export const NODE_OLD_DY = 5;
export const NODE_MASS = 6;
export const NODE_CONVERGENCE = 7;
export const NODE_SIZE = 8;
export const NODE_FIXED = 9;
export const PPN = 10;
export const PPE = 3;

export const EDGE_SOURCE = 0;
export const EDGE_TARGET = 1;
export const EDGE_WEIGHT = 2;

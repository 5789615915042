import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NamesEnum } from 'app/shared/enum/names';
import { TermsContent } from './Tersm.styles';

const year = new Date().getFullYear();

export const Terms: FC = () => (
  <Box position='absolute' display='flex' bottom={26} left={0} justifyContent='center' width='100%'>
    <TermsContent>
      <Typography color='text.secondary' mr={6}>
        &copy; {year} {NamesEnum.BLUELIGHT} - All Rights Reserved.
      </Typography>
      <Typography color='text.secondary'>Terms and Conditions</Typography>
    </TermsContent>
  </Box>
);

import React, { FC } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Button } from 'app/mui/Button';
import failedUpload from 'app/assets/images/datasets/failed-upload.svg';
import type { DatasetErrorType } from 'app/shared/components/UploadForm/UploadForm';

type Props = {
  error: DatasetErrorType;
  onDelete: () => void;
};

export const FailedUploadCard: FC<Props> = ({ error, onDelete }) => (
  <Box>
    <Box display='flex' alignItems='center' mb={1}>
      <Typography variant='h5' mr={2} color='error.dark'>
        {error.type}
      </Typography>
      <img src={failedUpload} alt='Failed upload icon' />
    </Box>
    <Typography variant='body1'>{error.message}</Typography>
    <Typography variant='body1'>
      Failed file cannot be used for analysis, would you like to delete it?
    </Typography>
    <Button
      variant='contained'
      onClick={onDelete}
      sx={{
        mt: 2,
      }}
    >
      Delete failed dataset
    </Button>
  </Box>
);

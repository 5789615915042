import React, { FC } from 'react';
import { Popover, Typography } from '@mui/material';
import { PopoverContent } from 'app/screens/Analysis/AnalysisSidebar/Configure/ConfigureVisualizations/ConfigureVisualizations.styles';

type VisualizationPopoverProps = {
  open: boolean;
  openVisualization: any;
  visualizationId: string;
  visualizationType?: string;
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  isNoFreeSlot: boolean;
  noFreeSlotText: string;
  freeSlotText: string;
};

export const VisualizationPopover: FC<VisualizationPopoverProps> = ({
  open,
  isOpen,
  openVisualization,
  visualizationId,
  visualizationType,
  anchorEl,
  handleClose,
  isNoFreeSlot,
  noFreeSlotText,
  freeSlotText,
}) => (
  <Popover
    id={open ? visualizationId : undefined}
    open={isOpen}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <PopoverContent>
      {isNoFreeSlot ? (
        <Typography variant='caption'>{noFreeSlotText}</Typography>
      ) : (
        <>
          <Typography variant='caption'>{freeSlotText}</Typography>
          <Typography
            fontWeight='600'
            fontSize='1.3rem'
            onClick={() => openVisualization(visualizationId, visualizationType)}
            sx={{ cursor: 'pointer' }}
          >
            Recover on Canvas
          </Typography>
        </>
      )}
    </PopoverContent>
  </Popover>
);

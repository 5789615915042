import React, { FC } from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
};

export const FullScreenEnter: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_9590_459383)'>
      <path
        d='M13.793 16.793C13.973 16.6137 14.2144 16.5095 14.4684 16.5018C14.7223 16.494 14.9697 16.5832 15.1603 16.7512C15.3508 16.9193 15.4703 17.1536 15.4944 17.4065C15.5185 17.6594 15.4454 17.912 15.29 18.113L15.207 18.207L10.414 23H13C13.2549 23.0003 13.5 23.0979 13.6854 23.2728C13.8707 23.4478 13.9822 23.687 13.9972 23.9414C14.0121 24.1958 13.9293 24.4464 13.7657 24.6418C13.6021 24.8373 13.3701 24.9629 13.117 24.993L13 25H8C7.75507 25 7.51866 24.91 7.33563 24.7473C7.15259 24.5845 7.03566 24.3603 7.007 24.117L7 24V19C7.00028 18.7451 7.09788 18.5 7.27285 18.3146C7.44782 18.1293 7.68695 18.0178 7.94139 18.0028C8.19584 17.9879 8.44638 18.0707 8.64183 18.2343C8.83729 18.3979 8.9629 18.6299 8.993 18.883L9 19V21.586L13.793 16.793ZM24 7C24.2449 7.00003 24.4813 7.08996 24.6644 7.25272C24.8474 7.41547 24.9643 7.63975 24.993 7.883L25 8V13C24.9997 13.2549 24.9021 13.5 24.7272 13.6854C24.5522 13.8707 24.313 13.9822 24.0586 13.9972C23.8042 14.0121 23.5536 13.9293 23.3582 13.7657C23.1627 13.6021 23.0371 13.3701 23.007 13.117L23 13V10.414L18.207 15.207C18.027 15.3863 17.7856 15.4905 17.5316 15.4982C17.2777 15.506 17.0303 15.4168 16.8397 15.2488C16.6492 15.0807 16.5297 14.8464 16.5056 14.5935C16.4815 14.3406 16.5546 14.088 16.71 13.887L16.793 13.793L21.586 9H19C18.7451 8.99972 18.5 8.90212 18.3146 8.72715C18.1293 8.55218 18.0178 8.31305 18.0028 8.05861C17.9879 7.80416 18.0707 7.55362 18.2343 7.35817C18.3979 7.16271 18.6299 7.0371 18.883 7.007L19 7H24Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_9590_459383'>
        <rect width='24' height='24' fill='white' transform='translate(4 4)' />
      </clipPath>
    </defs>
  </svg>
);

import React, {
  ChangeEvent,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Info,
  Trash2,
} from 'react-feather';
import {
  Box,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useTheme } from '@mui/material/styles';
import { MenuItemContent } from 'app/shared/components/SortSelect/SortSelect.styles';
import { Button } from 'app/mui/Button';
import { Checkbox } from 'app/mui/Checkbox';
import { FormControl } from 'app/mui/FormControl';
import { IconButton } from 'app/mui/IconButton';
import { StyledTextField } from 'app/mui/TextField';
import { Tooltip } from 'app/mui/Tooltip';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { OPTIONS } from 'app/shared/enum/analysis';
import { AnalysisConfig, FilterType, GroupType } from 'app/screens/Analysis/Analysis.types';
import {
  DataLandscapeMetric,
  FeatureLandscapeMetric,
} from 'app/screens/Analysis/VisualizationDialog/Configurations/Configurations.constants';
import { StyledTableContainer } from 'app/screens/Analysis/AnalysisSidebar/AnalysisSidebar.styles';
import {
  DataPartitionEnum,
  DataPartitionsDialog,
  metadataRows,
  PartitionRow,
} from 'app/screens/Analysis/AnalysisSidebar/DataPartitionsDialog/DataPartitionsDialog';
import {
  DataLandscapeSourceDataDialog,
  Row,
  SourceDataLandscapesOptions,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { VisualizationPopover } from 'app/shared/components/VisualizationPopover/VisualizationPopover';
import { FeatureLandscapeSourceDataDialog } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/FeatureLandscapeSourceDataDialog';
import {
  handleNumericDecrementChange,
  handleNumericValueChange,
  MISSING_VALUE_ERROR_MESSAGE,
  useVisualizationsMap,
} from 'app/screens/Analysis/Analysis.utils';
import {
  getDefaultLandscapeFeatures,
  getSelectedLandscapeFeatures,
  mapperMatrixLandscapeRows,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceData.utils';
import { useAlert } from 'app/shared/hooks/useAlert';
import { useConfigureForms } from './ConfigureForms.hooks';
import { VisualisationType } from '../Configure';
import { useConfigure } from '../Configure.hooks';
import { DefaultNameWrapper, GroupNameWrapper } from '../ConfigureNameWrapper';

export const maxNameLength = 70;

export const nameLimitTooltipText = 'Visualization name should have up to 70 characters';

export const maxLengthHelperText = (nameLength: number): string => {
  if (nameLength === 0) {
    return '70 characters maximum';
  }
  if (nameLength > 0 && nameLength <= maxNameLength) {
    return `${nameLength} of 70 characters`;
  }
  if (nameLength > maxNameLength) {
    return `Character limit exceeded (${nameLength} of 70)`;
  }
  return '';
};

export const getSafeString = (value: number | null): string =>
  value !== null ? value.toString() : '';

export const advancedConfigurationTooptip =
  'These parameters control the creation of a graph on the data points that is then refined and coarsened to produce the output graph. For each data point, the nearest "Neighborhood size" neighbors are found, giving edges for the graph. Some of these edges are removed, depending on the "Neighborhood slack" parameter. However, at least "Min. neighbors" edges are always kept for each data point.';

export const automaticParameterizationTooltip =
  'These parameters automatically adjust when graph is generated';

type ConfigureLandscapeProps = {
  analysisId: number;
  handleCloseConfiguration: () => void;
  selectedVisualization: AnalysisConfig;
};

const partitionDefaultConfig = {
  bins: 8,
  max_link_distance: 2,
};

export const ConfigureLandscape: FC<ConfigureLandscapeProps> = ({
  analysisId,
  handleCloseConfiguration,
  selectedVisualization,
}) => {
  const theme = useTheme();
  const {
    editGraph,
    selectedFeatures,
    selectedDataRows,
    getGroup,
    dataLandscapePartition,
    featureLandscapePartition,
    features,
    dataPointsCount,
    groups,
    dataLandscapeVisualization,
    featureLandscapeVisualization,
    isFeatureWithMissingValues,
    clearSelection,
  } = useAnalysis();

  const { showErrorMessage } = useAlert();

  const [expanded, setExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLandscapeModalOpen, setIsDataLandscapeModalOpen] = useState(false);
  const [isFeatureLandscapeModalOpen, setIsFeatureLandscapeModalOpen] = useState(false);
  const [isDataPartitionsModalOpen, setIsDataPartitionsModalOpen] = useState(false);
  const [isDataLandscapeReadyForUpdate, setIsDataLandscapeReadyForUpdate] = useState(false);
  const [isFeatureLandscapeReadyForUpdate, setIsFeatureLandscapeReadyForUpdate] = useState(false);
  const [visualizationName, setVisualizationName] = useState<string>(selectedVisualization.name);
  const [dataFilters, setDataFilters] = useState<FilterType[]>([]);
  const [featureFilters, setFeatureFilters] = useState<FilterType[]>([]);
  const [dataRowDataLandscape, setDataRowDataLandscape] = useState<Row>(undefined);
  const [dataRowFeatureLandscape, setDataRowFeatureLandscape] = useState<Row>(undefined);
  const [featureRowDataLandscape, setFeatureRowDataLandscape] = useState<Row>(undefined);
  const [featureRowFeatureLandscape, setFeatureRowFeatureLandscape] = useState<Row>(undefined);
  const [customDataRowsDataLandscape, setCustomDataRowsDataLandscape] = useState<number[]>([]);
  const [customDataRowsFeatureLandscape, setCustomDataRowsFeatureLandscape] = useState<number[]>(
    []
  );
  const [customFeatureRowsDataLandscape, setCustomFeatureRowsDataLandscape] = useState<number[]>(
    []
  );
  const [customFeatureRowsFeatureLandscape, setCustomFeatureRowsFeatureLandscape] = useState<
    number[]
  >([]);
  const [dataPartitionFeature, setDataPartitionFeature] = useState<Row>(undefined);
  const [dataPartitionData, setDataPartitionData] = useState<Row>(undefined);
  const [dataPartitionGlobal, setDataPartitionGlobal] = useState<Row>(undefined);
  const [dataPartitionMetadata, setDataPartitionMetadata] = useState<PartitionRow | undefined>(
    undefined
  );
  const [bins, setBins] = useState<string>(partitionDefaultConfig.bins.toString());
  const [distance, setDistance] = useState<string>(
    partitionDefaultConfig.max_link_distance.toString()
  );
  const [checkedEqualize, setCheckedEqualize] = useState(false);

  const { type, config } = selectedVisualization;

  const visualizationsMap = useVisualizationsMap([
    VisualisationType.DATA_LANDSCAPE,
    VisualisationType.FEATURE_LANDSCAPE,
  ]);

  const {
    distance_metric,
    use_default = false,
    K,
    M,
    min_neighbors = 0,
  } = config.neighbor_graph_config;
  const { L_components, L_cover } = config.nerve_graph_config;

  const [metricValue, setMetricValue] = useState<string>(distance_metric);
  const [coarseness, setCoarseness] = useState<string>(getSafeString(L_components));
  const [connectivity, setConnectivity] = useState<string>(getSafeString(L_cover));

  const [useDefault, setUseDefault] = useState(use_default);
  const [size, setSize] = useState<string>(getSafeString(K));
  const [slack, setSlack] = useState<string>(getSafeString(M));
  const [minNeighbors, setMinNeighbors] = useState<string>(getSafeString(min_neighbors));

  const [isNoFreeSlot, setIsNoFreeSlot] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const onDataRow = (): Row | undefined => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      return dataRowDataLandscape;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      return dataRowFeatureLandscape;
    }
    return undefined;
  };

  const dataRow = onDataRow();

  const onCustomDataRows = (): number[] | undefined => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      return customDataRowsDataLandscape;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      return customDataRowsFeatureLandscape;
    }
    return undefined;
  };

  const customDataRows = onCustomDataRows();

  const onCustomFeatureRows = (): number[] | undefined => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      return customFeatureRowsDataLandscape;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      return customFeatureRowsFeatureLandscape;
    }
    return undefined;
  };

  const customFeatureRows = onCustomFeatureRows();

  const onFeatureRow = (): Row | undefined => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      return featureRowDataLandscape;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      return featureRowFeatureLandscape;
    }
    return undefined;
  };

  const featureRow = onFeatureRow();

  const {
    rowsGroupId,
    featuresGroupId,
    getDataLandscapeSourceDataDataPointOption,
    getFeatureLandscapeSourceDataDataPointOption,
    removeItems,
  } = useConfigure({
    dataRow,
    featureRow,
    dataRowDataLandscape,
    dataRowFeatureLandscape,
    customDataRows,
    type,
  });

  useEffect(() => {
    setMetricValue(distance_metric);
    setCoarseness(getSafeString(L_components));
    setConnectivity(getSafeString(L_cover));
    setUseDefault(use_default);
    setSize(getSafeString(K));
    setSlack(getSafeString(K !== null && M !== null ? M - K : null));
    setMinNeighbors(getSafeString(min_neighbors));
  }, [K, L_components, L_cover, M, type, distance_metric, min_neighbors, use_default]);

  const openSourceDataModal = (): void => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      setIsDataLandscapeModalOpen(true);
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      setIsFeatureLandscapeModalOpen(true);
    }
  };

  useEffect(() => {
    const dataPartition = dataLandscapePartition.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    const featurePartition = featureLandscapePartition.map((item, index) => ({
      ...item,
      id: index + 1,
    }));

    setDataFilters(dataPartition);
    setFeatureFilters(featurePartition);
  }, []);

  const dataLandscapeColsGroupId = dataLandscapeVisualization?.features_group_id;
  const featureLandscapeColsGroupId = featureLandscapeVisualization?.features_group_id;

  const closeDataLandscapeModal = useCallback(() => {
    setIsDataLandscapeModalOpen(false);
  }, []);

  const closeFeatureLandscapeModal = useCallback(() => {
    setIsFeatureLandscapeModalOpen(false);
  }, []);

  const openDataPartitionsModal = useCallback(() => {
    setIsDataPartitionsModalOpen(true);
  }, []);

  const closeDataPartitionsModal = useCallback(() => {
    setIsDataPartitionsModalOpen(false);
  }, []);

  const setLandscapeForUpdate = (): void => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      setIsDataLandscapeReadyForUpdate(true);
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      setIsFeatureLandscapeReadyForUpdate(true);
    }
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setVisualizationName(event.target.value);
    setLandscapeForUpdate();
  };

  const handleChangeSize = (event: ChangeEvent<HTMLInputElement>): void => {
    const updateValue = handleNumericValueChange(event.target.value, 0, setSize);

    if (Number(size) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const handleChangeSlack = (event: ChangeEvent<HTMLInputElement>): void => {
    const updateValue = handleNumericValueChange(event.target.value, 0, setSlack);

    if (Number(slack) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const handleChangeMinNeighbors = (event: ChangeEvent<HTMLInputElement>): void => {
    const updateValue = handleNumericValueChange(event.target.value, 0, setMinNeighbors);

    if (Number(minNeighbors) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const handleChangeCoarseness = (event: ChangeEvent<HTMLInputElement>): void => {
    const updateValue = handleNumericValueChange(event.target.value, 0, setCoarseness);

    if (Number(coarseness) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const handleChangeConnectivity = (event: ChangeEvent<HTMLInputElement>): void => {
    const updateValue = handleNumericValueChange(event.target.value, 0, setConnectivity);

    if (Number(connectivity) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const handleChangeMetric = (value: string): void => {
    setMetricValue(value);
    setLandscapeForUpdate();
  };

  const sizeDecrement = (): void => {
    const updateValue = handleNumericDecrementChange(size, 0, setSize);

    if (Number(size) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const sizeIncrement = (): void => {
    setSize((Number(size) + 1).toString());
    setLandscapeForUpdate();
  };

  const slackDecrement = (): void => {
    const updateValue = handleNumericDecrementChange(slack, 0, setSlack);

    if (Number(slack) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const slackIncrement = (): void => {
    setSlack((Number(slack) + 1).toString());
    setLandscapeForUpdate();
  };

  const minNeighborsDecrement = (): void => {
    const updateValue = handleNumericDecrementChange(minNeighbors, 0, setMinNeighbors);

    if (Number(minNeighbors) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const minNeighborsIncrement = (): void => {
    setMinNeighbors((Number(minNeighbors) + 1).toString());
    setLandscapeForUpdate();
  };

  const coarsenessDecrement = (): void => {
    const updateValue = handleNumericDecrementChange(coarseness, 0, setCoarseness);

    if (Number(coarseness) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const coarsenessIncrement = (): void => {
    setCoarseness((Number(coarseness) + 1).toString());
    setLandscapeForUpdate();
  };

  const connectivityDecrement = (): void => {
    const updateValue = handleNumericDecrementChange(connectivity, 0, setConnectivity);

    if (Number(connectivity) !== updateValue) {
      setLandscapeForUpdate();
    }
  };

  const connectivityIncrement = (): void => {
    setConnectivity((Number(connectivity) + 1).toString());
    setLandscapeForUpdate();
  };

  const handleUseDefaultChange = (): void => {
    setUseDefault((prevState) => !prevState);
    setLandscapeForUpdate();
  };

  const onDataPartitionFeatureChange = (value: Row): void => {
    setDataPartitionFeature(value);
  };

  const onDataPartitionDataChange = (value: Row): void => {
    setDataPartitionData(value);
  };

  const onDataPartitionGlobalChange = (value: Row): void => {
    setDataPartitionGlobal(value);
  };

  const onDataPartitionMetadataChange = (value: PartitionRow): void => {
    setDataPartitionMetadata(value);
  };

  const handleChangeBins = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 1, setBins);
  };

  const binsDecrement = (): void => {
    handleNumericDecrementChange(bins, 1, setBins);
  };

  const binsIncrement = (): void => {
    setBins((Number(bins) + 1).toString());
  };

  const handleChangeEqualize = (event: ChangeEvent<HTMLInputElement>): void => {
    setCheckedEqualize(event.target.checked);
  };

  const handleChangeDistance = (event: ChangeEvent<HTMLInputElement>): void => {
    handleNumericValueChange(event.target.value, 0, setDistance);
  };

  const distanceDecrement = (): void => {
    handleNumericDecrementChange(distance, 0, setDistance);
  };

  const distanceIncrement = (): void => {
    setDistance((Number(distance) + 1).toString());
  };

  const clearFields = (): void => {
    setDataPartitionFeature(undefined);
    setDataPartitionData(undefined);
    setDataPartitionGlobal(undefined);
    setDataPartitionMetadata(undefined);
    setBins(partitionDefaultConfig.bins.toString());
    setDistance(partitionDefaultConfig.max_link_distance.toString());
    setCheckedEqualize(false);
  };

  const mapperMatrixConfigCols = async () => {
    if (featureRow && featureRow.feature_name === SourceDataLandscapesOptions.ALL_FEATURES) {
      const allFeatures = features.map((row: VisualizationRow) => row.id);
      return allFeatures;
    }
    if (featureRow && featureRow.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES) {
      return selectedFeatures;
    }
    if (featureRow && featureRow.feature_name === SourceDataLandscapesOptions.CUSTOM) {
      return customFeatureRows;
    }
    if (featureRow) {
      const response = (await getGroup(Number(analysisId), featureRow.id)) as GroupType;
      return response.features;
    }
    if (type === VisualisationType.DATA_LANDSCAPE) {
      if (dataLandscapeVisualization) {
        return dataLandscapeVisualization.cols;
      }
      return [];
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      if (featureLandscapeVisualization) {
        return featureLandscapeVisualization.cols;
      }
      return [];
    }
    return [];
  };

  const getCustomFilterParam = (partitionType: string) => {
    if (partitionType === DataPartitionEnum.FEATURE && dataPartitionFeature) {
      return {
        filter_values_idx: dataPartitionFeature.id,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    if (partitionType === DataPartitionEnum.DATA && dataPartitionData) {
      return {
        group_id: dataPartitionData.id,
        group_name: dataPartitionData.feature_name,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    if (partitionType === DataPartitionEnum.METADATA && dataPartitionMetadata) {
      return {
        metadata_name: dataPartitionMetadata.id as string,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    if (partitionType === DataPartitionEnum.GLOBAL && dataPartitionGlobal) {
      return {
        parameter: dataPartitionGlobal.feature_name,
        n_bins: Number(bins),
        max_link_distance: Number(distance),
        equalize_histogram: checkedEqualize,
      };
    }
    return undefined;
  };

  const getFilters = (): FilterType[] => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      return dataFilters;
    }
    return featureFilters;
  };

  const filters = getFilters();

  const addFilter = (partitionType: string): void => {
    const customFilters = getCustomFilterParam(partitionType);

    if (type === VisualisationType.DATA_LANDSCAPE && customFilters) {
      setDataFilters([...filters, customFilters]);
      setIsDataLandscapeReadyForUpdate(true);
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE && customFilters) {
      setFeatureFilters([...filters, customFilters]);
      setIsFeatureLandscapeReadyForUpdate(true);
    }
  };

  const { openVisualization, handleOpenPopover, handleClose } = useConfigureForms({
    selectedVisualization,
    analysisId,
    setAnchorEl,
    setIsNoFreeSlot,
  });

  const generateGraph = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    handleOpenPopover(event);
    try {
      setIsLoading(true);
      if (dataPointsCount) {
        const rows = await mapperMatrixLandscapeRows(
          dataRow,
          customDataRows,
          Number(analysisId),
          selectedDataRows,
          getGroup,
          dataLandscapeVisualization,
          featureLandscapeVisualization,
          type,
          dataPointsCount
        );
        const cols = await mapperMatrixConfigCols();

        if (cols) {
          const hasMissingValuesInSourceData = isFeatureWithMissingValues(cols, features);

          if (hasMissingValuesInSourceData) {
            throw new Error(MISSING_VALUE_ERROR_MESSAGE);
          }
        }

        if (filters.length) {
          const hasMissingValuesInDataPartition = filters
            .filter((f) => f.filter_values_idx)
            .some((featureFilter: any) =>
              isFeatureWithMissingValues([featureFilter.filter_values_idx], features)
            );

          if (hasMissingValuesInDataPartition) {
            throw new Error(MISSING_VALUE_ERROR_MESSAGE);
          }
        }

        const rows_group_id = rowsGroupId;

        const features_group_id = featuresGroupId;

        const coloring = (visualizationsMap[type] as AnalysisConfig[]).find(
          (v) => v.id === selectedVisualization.id
        )?.config?.coloring;

        await editGraph({
          visualisation_id: selectedVisualization.id,
          analysis_id: analysisId,
          name: visualizationName,
          config: {
            mapper_matrix_config: {
              rows,
              cols,
              rows_group_id,
              features_group_id,
            },
            neighbor_graph_config: {
              distance_metric: metricValue,
              use_default: useDefault,
              K: useDefault ? null : Number(size),
              M: useDefault ? null : Number(size) + Number(slack),
              min_neighbors: useDefault ? null : Number(minNeighbors),
            },
            nerve_graph_config: {
              L_components: Number(coarseness),
              L_cover: Number(connectivity),
            },
            filters: filters.length > 0 ? filters : undefined,
            ...(coloring ? { coloring } : {}),
          },
        });
      }

      clearSelection(selectedVisualization.id, selectedVisualization.type);

      setIsLoading(false);
      setIsDataLandscapeReadyForUpdate(false);
      setIsFeatureLandscapeReadyForUpdate(false);
    } catch (e: any) {
      showErrorMessage({
        title: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const partitionItemName = (row: FilterType) => {
    if (type === VisualisationType.DATA_LANDSCAPE && Number.isInteger(row.filter_values_idx)) {
      const featureName = features.find(
        (feature) => feature.id === row.filter_values_idx
      )?.feature_name;
      return featureName;
    }
    if (type === VisualisationType.DATA_LANDSCAPE) {
      return Object.values(row)[0];
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      if (row.group_name) {
        return row.group_name;
      }
      if (row.group_id) {
        return row.group_id;
      }
      if (row.parameter) {
        return row.parameter;
      }
      const metadataRow = metadataRows.find((metadata) => metadata.id === row.metadata_name);
      return metadataRow?.feature_name;
    }
    return Object.values(row)[0];
  };

  const handleSaveSourceDataDataLandscape = async (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ): Promise<void> => {
    setLandscapeForUpdate();
    closeDataLandscapeModal();
    setDataRowDataLandscape(dataRowValue);
    setFeatureRowDataLandscape(featureRowValue);
    if (customDataRowsValue && customDataRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomDataPoints = Array.from(Array(dataPointsCount).keys());
      setCustomDataRowsDataLandscape(allCustomDataPoints);
    } else if (customDataRowsValue) {
      const selectedRows = customDataRowsValue.map((row: VisualizationRow) => row.id);
      setCustomDataRowsDataLandscape(selectedRows);
    }
    if (customFeatureRowsValue && customFeatureRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomFeatures = features.map((row: VisualizationRow) => row.id) as number[];
      setCustomFeatureRowsDataLandscape(allCustomFeatures);
    } else if (customFeatureRowsValue) {
      const selectedRows = customFeatureRowsValue.map((row: VisualizationRow) => row.id);
      setCustomFeatureRowsDataLandscape(selectedRows);
    }
  };

  const handleSaveSourceDataFeatureLandscape = async (
    customDataRowsValue: any[] | undefined,
    customFeatureRowsValue: any[] | undefined,
    dataRowValue: Row,
    featureRowValue: Row
  ): Promise<void> => {
    setLandscapeForUpdate();
    closeFeatureLandscapeModal();
    setDataRowFeatureLandscape(dataRowValue);
    setFeatureRowFeatureLandscape(featureRowValue);
    if (customDataRowsValue && customDataRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomDataPoints = Array.from(Array(dataPointsCount).keys());
      setCustomDataRowsFeatureLandscape(allCustomDataPoints);
    } else if (customDataRowsValue) {
      const selectedRows = customDataRowsValue.map((row: VisualizationRow) => row.id);
      setCustomDataRowsFeatureLandscape(selectedRows);
    }
    if (customFeatureRowsValue && customFeatureRowsValue.includes(OPTIONS.selectAll)) {
      const allCustomFeatures = features.map((row: VisualizationRow) => row.id) as number[];
      setCustomFeatureRowsFeatureLandscape(allCustomFeatures);
    } else if (customFeatureRowsValue) {
      const selectedRows = customFeatureRowsValue.map((row: VisualizationRow) => row.id);
      setCustomFeatureRowsFeatureLandscape(selectedRows);
    }
  };

  const getDataLandscapeSourceDataFeatureOption = (): ReactElement | string => {
    if (featureRowDataLandscape) {
      return DefaultNameWrapper(
        getSelectedLandscapeFeatures(featureRowDataLandscape, selectedFeatures, customFeatureRows)
      );
    }
    if (dataLandscapeColsGroupId) {
      return GroupNameWrapper(
        getDefaultLandscapeFeatures(
          dataLandscapeColsGroupId,
          groups,
          dataLandscapeVisualization
        ) as string
      );
    }
    return `${getDefaultLandscapeFeatures(
      dataLandscapeColsGroupId,
      groups,
      dataLandscapeVisualization
    )} selected`;
  };

  const getFeatureLandscapeSourceDataFeatureOption = (): ReactElement | string => {
    if (featureRowFeatureLandscape) {
      return DefaultNameWrapper(
        getSelectedLandscapeFeatures(
          featureRowFeatureLandscape,
          selectedFeatures,
          customFeatureRows
        )
      );
    }
    if (featureLandscapeColsGroupId) {
      return GroupNameWrapper(
        getDefaultLandscapeFeatures(
          featureLandscapeColsGroupId,
          groups,
          featureLandscapeVisualization
        ) as string
      );
    }
    return `${getDefaultLandscapeFeatures(
      featureLandscapeColsGroupId,
      groups,
      featureLandscapeVisualization
    )} selected`;
  };

  const deleteFilter = (row: FilterType): void => {
    if (type === VisualisationType.DATA_LANDSCAPE) {
      const currentFilters = [...dataFilters];
      setDataFilters(removeItems(currentFilters, row));
      setIsDataLandscapeReadyForUpdate(true);
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE) {
      const currentFilters = [...featureFilters];
      setFeatureFilters(removeItems(currentFilters, row));
      setIsFeatureLandscapeReadyForUpdate(true);
    }
  };

  const isDisabledByName = visualizationName.length > maxNameLength;

  const isUpdateDataDisabled =
    isLoading || (type === VisualisationType.DATA_LANDSCAPE && !isDataLandscapeReadyForUpdate);

  const isUpdateFeatureDisabled =
    isLoading ||
    (type === VisualisationType.FEATURE_LANDSCAPE && !isFeatureLandscapeReadyForUpdate);

  const isUpdateDisabled = isUpdateDataDisabled || isUpdateFeatureDisabled || isDisabledByName;

  const metric =
    type === VisualisationType.DATA_LANDSCAPE ? DataLandscapeMetric : FeatureLandscapeMetric;

  return (
    <>
      <Box mt={3} mb={2} px={4}>
        <Box
          display='flex'
          alignItems='center'
          mb={3}
          onClick={handleCloseConfiguration}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronLeft style={{ minWidth: '24px' }} />
          <Typography
            variant='subtitle1'
            ml={1}
            sx={{ maxWidth: '200px', overflowWrap: 'break-word' }}
          >
            {selectedVisualization.name}
          </Typography>
        </Box>
        <StyledTextField
          color='secondary'
          error={visualizationName.length > maxNameLength}
          helperText={maxLengthHelperText(visualizationName.length)}
          placeholder='Visualization name'
          label='Visualization name'
          value={visualizationName}
          onChange={handleChangeName}
          fullWidth
        />
      </Box>
      <Box px={4}>
        <Divider />
      </Box>
      <Box mx={4}>
        <Box my={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center' my={2}>
            <Typography variant='subtitle1'>Source Data</Typography>
            <Button variant='outlined' size='small' onClick={openSourceDataModal}>
              Modify
            </Button>
          </Box>
          <Box mt={2}>
            {type === VisualisationType.DATA_LANDSCAPE && (
              <>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Features</Typography>
                  <Box>{getDataLandscapeSourceDataFeatureOption()}</Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='caption'>Data points</Typography>
                  <Box>{getDataLandscapeSourceDataDataPointOption() as ReactNode}</Box>
                </Box>
              </>
            )}
            {type === VisualisationType.FEATURE_LANDSCAPE && (
              <>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Features</Typography>
                  <Box>{getFeatureLandscapeSourceDataFeatureOption()}</Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='caption'>Data Points</Typography>
                  <Box>{getFeatureLandscapeSourceDataDataPointOption() as ReactNode}</Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography variant='subtitle1'>Configuration</Typography>
          <Box mt={2}>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography variant='caption'>Distance</Typography>
              <FormControl
                className='small'
                sx={{
                  width: '160px',
                }}
              >
                <Select
                  value={metricValue}
                  onChange={(e) => handleChangeMetric(e.target.value)}
                  sx={{
                    '& .MuiSelect-select svg': {
                      display: 'none',
                    },
                  }}
                >
                  {metric.map(
                    (distanceItem: {
                      name: string;
                      displayName: string;
                      description: string | ReactElement;
                    }) => (
                      <MenuItem
                        key={distanceItem.name}
                        value={distanceItem.name}
                        sx={{ py: 1, width: 220 }}
                      >
                        <MenuItemContent>
                          {distanceItem.displayName}
                          <Box display='flex'>
                            {metricValue === distanceItem.name && (
                              <Check size='24' color={theme.palette.success.main} />
                            )}
                            <Box sx={{ pl: 1 }}>
                              <Tooltip title={distanceItem.description}>
                                <Info />
                              </Tooltip>
                            </Box>
                          </Box>
                        </MenuItemContent>
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
              <Typography variant='caption'>Coarseness</Typography>
              <StyledTextField
                id='outlined-end-adornment'
                type='number'
                className='number'
                value={coarseness}
                onChange={handleChangeCoarseness}
                size='small'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        className='configArrows'
                        disabled={Number(coarseness) <= 0}
                        onClick={coarsenessDecrement}
                      >
                        <ChevronLeft size={16} className='arrow' />
                      </IconButton>
                      <IconButton className='configArrows' onClick={coarsenessIncrement}>
                        <ChevronRight size={16} className='arrow' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='caption'>Connectivity</Typography>
              <StyledTextField
                id='outlined-end-adornment'
                type='number'
                className='number'
                value={connectivity}
                onChange={handleChangeConnectivity}
                size='small'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        className='configArrows'
                        disabled={Number(connectivity) <= 0}
                        onClick={connectivityDecrement}
                      >
                        <ChevronLeft size={16} className='arrow' />
                      </IconButton>
                      <IconButton className='configArrows' onClick={connectivityIncrement}>
                        <ChevronRight size={16} className='arrow' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box my={2}>
          <Box
            onClick={() => setExpanded(!expanded)}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
          >
            <Typography variant='subtitle1'>Advanced Configuration</Typography>
            <Tooltip title={advancedConfigurationTooptip}>
              <Info size={18} />
            </Tooltip>
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </Box>
          {expanded && (
            <Box>
              <Tooltip title={automaticParameterizationTooltip}>
                <FormControlLabel
                  label='Automatic parameterization'
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontSize: '1.2rem',
                    },
                  }}
                  control={
                    <Checkbox
                      value={useDefault}
                      checked={useDefault}
                      onChange={handleUseDefaultChange}
                    />
                  }
                />
              </Tooltip>
              <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                <Typography variant='caption'>Neighborhood size</Typography>
                <StyledTextField
                  id='outlined-end-adornment'
                  type='number'
                  className='number'
                  value={size}
                  disabled={useDefault}
                  onChange={handleChangeSize}
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          className='configArrows'
                          disabled={Number(size) <= 0 || useDefault}
                          onClick={sizeDecrement}
                        >
                          <ChevronLeft size={16} className='arrow' />
                        </IconButton>
                        <IconButton
                          className='configArrows'
                          onClick={sizeIncrement}
                          disabled={useDefault}
                        >
                          <ChevronRight size={16} className='arrow' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                <Typography variant='caption'>Neighborhood slack</Typography>
                <StyledTextField
                  id='outlined-end-adornment'
                  type='number'
                  className='number'
                  value={slack}
                  disabled={useDefault}
                  onChange={handleChangeSlack}
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          className='configArrows'
                          disabled={Number(slack) <= 0 || useDefault}
                          onClick={slackDecrement}
                        >
                          <ChevronLeft size={16} className='arrow' />
                        </IconButton>
                        <IconButton
                          className='configArrows'
                          onClick={slackIncrement}
                          disabled={useDefault}
                        >
                          <ChevronRight size={16} className='arrow' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='caption'>Min. neighbors</Typography>
                <StyledTextField
                  id='outlined-end-adornment'
                  type='number'
                  className='number'
                  value={minNeighbors}
                  disabled={useDefault}
                  onChange={handleChangeMinNeighbors}
                  size='small'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          className='configArrows'
                          disabled={Number(minNeighbors) <= 0 || useDefault}
                          onClick={minNeighborsDecrement}
                        >
                          <ChevronLeft size={16} className='arrow' />
                        </IconButton>
                        <IconButton
                          className='configArrows'
                          onClick={minNeighborsIncrement}
                          disabled={useDefault}
                        >
                          <ChevronRight size={16} className='arrow' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box display='flex' justifyContent='space-between' alignItems='center' my={2}>
          <Typography variant='subtitle1'>
            {type === VisualisationType.DATA_LANDSCAPE ? <>Data </> : <>Feature </>}
            Partitions
          </Typography>
          <Button variant='outlined' size='small' onClick={openDataPartitionsModal}>
            Add
          </Button>
        </Box>
        {!!filters.length && (
          <StyledTableContainer key={type}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  <TableCell>#bins</TableCell>
                  <TableCell>Equal size</TableCell>
                  <TableCell>Link distance</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filters.map((row: FilterType) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Typography variant='caption'>{partitionItemName(row)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='caption'>{row.n_bins}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='caption'>
                        {row.equalize_histogram && <Check size='16' />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='caption'>{row.max_link_distance}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box onClick={() => deleteFilter(row)} className='delete'>
                        <Trash2 size='16' />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
        <Box position='sticky' bottom={0} py={2}>
          <Tooltip
            title={visualizationName.length > maxNameLength ? nameLimitTooltipText : ''}
            placement='top-start'
          >
            <Box>
              <Button
                onClick={(event) => generateGraph(event)}
                aria-describedby={selectedVisualization.id.toString()}
                size='large'
                variant='contained'
                disabled={isUpdateDisabled}
                fullWidth
              >
                Update Landscape
              </Button>
            </Box>
          </Tooltip>
          <VisualizationPopover
            open={open}
            isOpen={open}
            openVisualization={openVisualization}
            visualizationId={selectedVisualization.id.toString()}
            anchorEl={anchorEl}
            handleClose={handleClose}
            isNoFreeSlot={isNoFreeSlot}
            noFreeSlotText='This visualization has been updated, but it’s still hidden. To bring it back to
                    canvas select it from the menu in any of the visualization slots.'
            freeSlotText='This visualization has been updated, but it’s still hidden. Bring it to
                      canvas?'
          />
        </Box>
      </Box>
      {isDataLandscapeModalOpen && (
        <DataLandscapeSourceDataDialog
          openDialog={isDataLandscapeModalOpen}
          handleClose={closeDataLandscapeModal}
          handleSaveSourceData={handleSaveSourceDataDataLandscape}
          dataRow={dataRow}
          customDataRows={customDataRows}
          featureRow={featureRow}
          customFeatureRows={customFeatureRows}
        />
      )}
      {isFeatureLandscapeModalOpen && (
        <FeatureLandscapeSourceDataDialog
          openDialog={isFeatureLandscapeModalOpen}
          handleClose={closeFeatureLandscapeModal}
          handleSaveSourceData={handleSaveSourceDataFeatureLandscape}
          dataRow={dataRow}
          customDataRows={customDataRows}
          featureRow={featureRow}
          customFeatureRows={customFeatureRows}
        />
      )}
      {isDataPartitionsModalOpen && (
        <DataPartitionsDialog
          openDialog={isDataPartitionsModalOpen}
          handleClose={closeDataPartitionsModal}
          type={type}
          onDataPartitionFeatureChange={onDataPartitionFeatureChange}
          onDataPartitionDataChange={onDataPartitionDataChange}
          onDataPartitionGlobalChange={onDataPartitionGlobalChange}
          onDataPartitionMetadataChange={onDataPartitionMetadataChange}
          handleChangeBins={handleChangeBins}
          binsDecrement={binsDecrement}
          binsIncrement={binsIncrement}
          handleChangeEqualize={handleChangeEqualize}
          handleChangeDistance={handleChangeDistance}
          clearFields={clearFields}
          distanceDecrement={distanceDecrement}
          distanceIncrement={distanceIncrement}
          bins={bins}
          distance={distance}
          checkedEqualize={checkedEqualize}
          addFilter={addFilter}
          dataPartitionFeature={dataPartitionFeature}
          dataPartitionData={dataPartitionData}
          dataPartitionGlobal={dataPartitionGlobal}
          dataPartitionMetadata={dataPartitionMetadata}
        />
      )}
    </>
  );
};

import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { Tooltip } from 'app/mui/Tooltip';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { Wrapper } from './AnalysisNavigation.styles';

export const AnalysisNavigation: FC = () => {
  const { project } = useProjects();
  const { currentAnalysis } = useAnalysis();

  const { navigateToProjectOverview } = useNavigation();

  return (
    <Wrapper>
      <Tooltip title={project.name} placement='bottom-start'>
        <Typography
          noWrap
          className='break-text'
          color='text.secondary'
          variant='subtitle1'
          sx={{
            cursor: 'pointer',
            maxWidth: '150px',
          }}
          onClick={() => navigateToProjectOverview(project.id)}
        >
          {project.name}
        </Typography>
      </Tooltip>
      <Typography mx={2} color='text.secondary' variant='subtitle1'>
        /
      </Typography>
      <Tooltip title={currentAnalysis.name} placement='bottom-start'>
        <Typography
          noWrap
          className='break-text'
          color='text.primary'
          variant='subtitle1'
          sx={{
            maxWidth: '150px',
          }}
        >
          {currentAnalysis.name}
        </Typography>
      </Tooltip>
    </Wrapper>
  );
};

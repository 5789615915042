import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';

const StyledRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  '&.MuiRadio-colorPrimary': {
    '&:not(.Mui-checked)': {
      color: theme.palette.state.restingBorder,
    },
    '&:hover': {
      color: theme.palette.hover.primary,
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
    },
  },
  '+ .MuiFormControlLabel-label': {
    fontSize: '1.4rem',
  },
}));

export { StyledRadio as Radio };

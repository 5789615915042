import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box } from '@mui/material';
import { Layout } from 'app/shared/components/Layout/Layout';
import { DATE_SORTING_FIELD } from 'app/shared/utils/date';
import { Order, SortSelect } from 'app/shared/components/SortSelect/SortSelect';
import noResultsThumb from 'app/assets/images/thumbnails/no-results-datasets.svg';
import { NoResults } from 'app/shared/components/NoResults/NoResults';
import { SelectedDataset } from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList';
import { ProjectDatasets } from 'app/screens/Projects/Projects.types';
import { DatasetCards } from './DatasetCards/DatasetCards';

type DatasetsProps = {
  datasets: ProjectDatasets[];
  selectedDataset: SelectedDataset;
  setSelectedDataset: Dispatch<SetStateAction<SelectedDataset>>;
  handlePreviewOpen: (id: number, isExample: boolean | null) => void;
  handlePreviewClose: () => void;
  projectId: number;
  handleOpenDatasetModal: () => void;
};

export const Datasets: FC<DatasetsProps> = ({
  datasets,
  selectedDataset,
  setSelectedDataset,
  handlePreviewOpen,
  handlePreviewClose,
  projectId,
  handleOpenDatasetModal,
}) => {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState(DATE_SORTING_FIELD);

  return (
    <Layout
      heading='Datasets'
      onButtonClick={handleOpenDatasetModal}
      buttonTitle='Add Dataset'
      isEmpty={datasets.length === 0}
      itemsCount={datasets.length}
      sortSelect={
        <Box mr={4}>
          <SortSelect order={order} orderBy={orderBy} setOrder={setOrder} setOrderBy={setOrderBy} />
        </Box>
      }
    >
      {datasets.length > 0 ? (
        <DatasetCards
          datasets={datasets}
          order={order}
          orderBy={orderBy}
          selectedDataset={selectedDataset}
          setSelectedDataset={setSelectedDataset}
          handlePreviewOpen={handlePreviewOpen}
          handlePreviewClose={handlePreviewClose}
          projectId={projectId}
        />
      ) : (
        <NoResults
          thumb={noResultsThumb}
          title="You haven't uploaded any datasets yet"
          description='Add dataset to your catalog or discover our dataset samples. '
          buttonText='Add Dataset'
          onButtonClick={handleOpenDatasetModal}
        />
      )}
    </Layout>
  );
};

import React, { FC, ReactElement } from 'react';
import { useLocation, Navigate, RoutePath } from 'app/navigation/index';
import { getAccessToken } from 'app/shared/utils/token';

type RequireAuth = {
  children: ReactElement;
};

export const RequireAuth: FC<RequireAuth> = ({ children }) => {
  const accessToken = getAccessToken();
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to={RoutePath.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};

import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import { ChevronDown, Info } from 'react-feather';
import { useDebounce } from 'react-use';
import Box from '@mui/material/Box';
import { Divider, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { IconButton } from 'app/mui/IconButton';
import { Tooltip } from 'app/mui/Tooltip';
import { useParams } from 'app/navigation';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { FullScreenEnter } from 'app/shared/icons';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from 'app/screens/Analysis/AnalysisSidebar/AnalysisSidebar.styles';
import { Loader } from 'app/shared/components/Loader/Loader';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { useAlert } from 'app/shared/hooks/useAlert';
import { shortName } from 'app/shared/utils/visualization';

import { ComparativeStatistics } from './ComparativeStatistics';
import { DescriptiveStatistics } from './DescriptiveStatistics';
import { ComparativeStatisticsModal } from './ComparativeStatisticsModal';
import { DescriptiveStatisticsModal } from './DescriptiveStatisticsModal';
import { StatisticsError } from './StatisticsError';

export type ComparativeStatisticsGroupOption = {
  id: string;
  name: string;
  type: string;
  rows?: number[];
};

export const STATISTICAL_TEST_OPTIONS = [
  {
    id: 'ks',
    name: 'KS score',
    description:
      'Compares the distribution of a feature between two groups.  The statistic is the maximum absolute difference between the empirical distribution functions of the samples. Larger values for the unsigned distance (score) imply a larger difference between the two distributions.',
  },
  {
    id: 't',
    name: 't score',
    description:
      'Compares the means of two groups. Features that have high magnitude t-scores have large differences in mean relative to the variance in that feature. Features that have p-value less than a threshold chosen a priori are deemed statistically significant.',
  },
  {
    id: 'f',
    name: 'F score',
    description:
      'Compares the variances of two groups. The F-value is the ratio of the two populations’ variances and the p-value is computed assuming a normal distribution in the underlying feature. Be careful with interpretation of the p-value.',
  },
];

export const SIDEDNESS_OPTIONS = [
  {
    id: 'two-sided',
    name: 'Two-sided',
  },
  {
    id: 'less',
    name: 'Less',
  },
];

const CURRENTLY_SELECTED_OPTION = {
  id: 'currently_selected',
  name: 'Currently selected',
  type: GroupCreationEnum.CUSTOM,
};

const REST_OPTION = {
  id: 'rest',
  name: 'Rest',
  type: GroupCreationEnum.CUSTOM,
};

const ALL_OPTION = {
  id: 'all',
  name: 'All',
  type: GroupCreationEnum.CUSTOM,
};

const FIXED_SUBSET = 'fixed_subset';

export const COMPARATIVE_STATISTICS_ERROR = (
  <Typography component='p' variant='body1' color='error.dark' mt={2}>
    Comparative statistics table has failed. Please try again. If the problem persists, contact
    customer support via <a href='mailto:support@bluelightai.com'>support@bluelightai.com</a>
  </Typography>
);

export const DESCRIPTIVE_STATISTICS_ERROR = (
  <Typography component='p' variant='body1' color='error.dark' mt={2}>
    Descriptive statistics table has failed. Please try again. If the problem persists, contact
    customer support via <a href='mailto:support@bluelightai.com'>support@bluelightai.com</a>
  </Typography>
);

export const Statistics: FC = () => {
  const theme = useTheme();
  const { analysisId } = useParams();
  const { resetAlert } = useAlert();

  const {
    groups,
    selectedDataRows,
    getComparativeStatistics,
    getDescriptiveStatistics,
    comparativeStatistics,
  } = useAnalysis();

  const [isComparativeLoading, setComparativeLoading] = useState(false);
  const [isDescriptiveLoading, setDescriptiveLoading] = useState(false);

  const [comparativeStatisticsError, setComparativeStatisticsError] = useState('');
  const [descriptiveStatisticsError, setDescriptiveStatisticsError] = useState('');

  const dataPointsGroups = useMemo(
    () =>
      groups.reduce((acc, group) => {
        if (group.type === GroupCreationEnum.ROWS && !group.is_default) {
          return [
            ...acc,
            {
              id: group.id.toString(),
              name: group.name,
              type: group.type,
              rows: group.rows,
            },
          ];
        }
        return acc;
      }, [] as ComparativeStatisticsGroupOption[]),
    [groups]
  );

  const dataPointsGroupsMap = useMemo(
    () => new Map(dataPointsGroups.map((option) => [option.id, option.rows])),
    [dataPointsGroups]
  );

  const groupAOptions = useMemo(
    () => [CURRENTLY_SELECTED_OPTION, ...dataPointsGroups],
    [dataPointsGroups]
  );

  const groupBOptions = useMemo(
    () => [ALL_OPTION, REST_OPTION, CURRENTLY_SELECTED_OPTION, ...dataPointsGroups],
    [dataPointsGroups]
  );

  const [expandedComparativeStatistics, setExpandedComparativeStatistics] = useState(true);
  const [expandedDescriptiveStatistics, setExpandedDescriptiveStatistics] = useState(true);

  const [isComparativeStatisticsModalOpen, setIsComparativeStatisticsModalOpen] = useState(false);
  const [isDescriptiveStatisticsModalOpen, setIsDescriptiveStatisticsModalOpen] = useState(false);

  const [groupA, setGroupA] = useState(groupAOptions[0].id);
  const [groupB, setGroupB] = useState(groupBOptions[0].id);
  const [statisticalTest, setStatisticalTest] = useState<string>(STATISTICAL_TEST_OPTIONS[1].id);

  useEffect(() => {
    const isGroupAExisting = groupAOptions.find((dpGroup) => dpGroup.id === groupA);
    const isGroupBExisting = groupBOptions.find((dpGroup) => dpGroup.id === groupB);

    if (!isGroupAExisting) {
      setGroupA(CURRENTLY_SELECTED_OPTION.id);
    }

    if (!isGroupBExisting) {
      setGroupB(ALL_OPTION.id);
    }
  }, [groupA, groupAOptions, groupB, groupBOptions]);

  const fetchDescriptiveStatistics = useCallback(
    async (dataRows: number[]): Promise<void> => {
      try {
        setDescriptiveLoading(true);
        setDescriptiveStatisticsError('');

        await getDescriptiveStatistics({ id: Number(analysisId), dataPoints: dataRows });

        setDescriptiveLoading(false);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          setDescriptiveLoading(false);
        }

        resetAlert();
        const parsedError = await e.response.json();
        setDescriptiveStatisticsError(parsedError.detail);
      }
    },
    [analysisId]
  );

  useDebounce(
    () => {
      if (selectedDataRows.length > 0) {
        fetchDescriptiveStatistics(selectedDataRows);
      }
    },
    1000,
    [selectedDataRows]
  );

  const fetchComparativeStatistics = useCallback(
    async (selectedRows: number[] = []): Promise<void> => {
      try {
        setComparativeLoading(true);
        setComparativeStatisticsError('');

        const inGroup = (
          groupA === CURRENTLY_SELECTED_OPTION.id ? selectedRows : dataPointsGroupsMap.get(groupA)
        ) as number[];

        const comparisonGroup =
          groupB === ALL_OPTION.id || groupB === REST_OPTION.id ? groupB : FIXED_SUBSET;

        const fixedSubset =
          groupB === CURRENTLY_SELECTED_OPTION.id
            ? selectedRows
            : (dataPointsGroupsMap.get(groupB) as number[]);

        await getComparativeStatistics({
          id: Number(analysisId),
          test_type: statisticalTest,
          in_group: inGroup,
          comparison_group: comparisonGroup,
          fixed_subset: comparisonGroup === FIXED_SUBSET ? fixedSubset : undefined,
        });

        setComparativeLoading(false);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          setComparativeLoading(false);
        }

        resetAlert();
        const parsedError = await e.response.json();
        setComparativeStatisticsError(parsedError.detail);
      }
    },
    [statisticalTest, groupA, groupB, dataPointsGroupsMap]
  );

  useDebounce(
    () => {
      const isCurrentlySelected =
        groupA === CURRENTLY_SELECTED_OPTION.id || groupB === CURRENTLY_SELECTED_OPTION.id;

      if (selectedDataRows.length > 0 && isCurrentlySelected) {
        fetchComparativeStatistics(selectedDataRows);
      }
    },
    1000,
    [selectedDataRows, fetchComparativeStatistics]
  );

  useDebounce(
    () => {
      const isCurrentlySelected =
        groupA === CURRENTLY_SELECTED_OPTION.id || groupB === CURRENTLY_SELECTED_OPTION.id;

      if (!isCurrentlySelected) {
        fetchComparativeStatistics();
      }
    },
    1000,
    [fetchComparativeStatistics]
  );

  const handleGroupAChange = useCallback((e: SelectChangeEvent<string>): void => {
    setGroupA(e.target.value);
  }, []);

  const handleGroupBChange = useCallback((e: SelectChangeEvent<string>): void => {
    setGroupB(e.target.value);
  }, []);

  const handleStatisticalTestChange = useCallback((e: SelectChangeEvent<string>): void => {
    setStatisticalTest(e.target.value);
  }, []);

  const groupAToDisplay = useMemo(
    () => new Map(groupAOptions.map((option) => [option.id, option.name])).get(groupA),
    [groupA, groupAOptions]
  );

  const groupBToDisplay = useMemo(
    () => new Map(groupBOptions.map((option) => [option.id, option.name])).get(groupB),
    [groupB, groupBOptions]
  );

  const statisticalTestToDisplay = useMemo(
    () => STATISTICAL_TEST_OPTIONS.find((option) => option.id === statisticalTest),
    [statisticalTest]
  );

  const sidednessToDisplay = useMemo(() => {
    if (!comparativeStatistics) return '';

    return new Map(SIDEDNESS_OPTIONS.map((option) => [option.id, option.name])).get(
      comparativeStatistics.sidedness
    );
  }, [comparativeStatistics]);

  const showComparativeStatistics = !isComparativeLoading && !comparativeStatisticsError;
  const showDescriptiveStatistics = !isDescriptiveLoading && !descriptiveStatisticsError;

  if (selectedDataRows.length === 0) return null;

  return (
    <div>
      <StyledAccordion expanded={expandedComparativeStatistics} disableGutters elevation={0}>
        <StyledAccordionSummary
          className='chevronUp'
          style={{ paddingBottom: theme.spacing(1) }}
          expandIcon={
            <ChevronDown
              onClick={() => setExpandedComparativeStatistics((prevState) => !prevState)}
              size='20'
              color={theme.palette.primary.main}
            />
          }
        >
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography
              variant='h6'
              fontWeight='600'
              width='100%'
              onClick={() => setExpandedComparativeStatistics((prevState) => !prevState)}
            >
              Comparative statistics
            </Typography>
            {!comparativeStatisticsError && (
              <Tooltip title={comparativeStatistics?.textual_description || ''}>
                <Info size={18} />
              </Tooltip>
            )}
          </Box>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box display='flex' justifyContent='space-between' alignItems='flex-start' mb={2}>
            <Box>
              {groupAToDisplay && groupBToDisplay && (
                <Typography variant='caption' component='div'>
                  Comparing <b>{shortName(groupAToDisplay, 200)}</b> vs{' '}
                  <b>{shortName(groupBToDisplay, 200)}</b>
                </Typography>
              )}
              <Typography variant='caption' component='div'>
                Statistical test:{' '}
                <b>
                  {statisticalTestToDisplay?.name}, {sidednessToDisplay}
                </b>
              </Typography>
            </Box>
            <IconButton
              size='small'
              onClick={() => setIsComparativeStatisticsModalOpen(true)}
              sx={{
                background: theme.palette.legend.background,
                borderRadius: '4px',
                visibility: 'visible',
              }}
            >
              <FullScreenEnter />
            </IconButton>
          </Box>
          {isComparativeLoading && (
            <Box height={200}>
              <Loader />
            </Box>
          )}
          {comparativeStatisticsError && (
            <Box height={200}>
              <StatisticsError error={COMPARATIVE_STATISTICS_ERROR} />
            </Box>
          )}
          {showComparativeStatistics && <ComparativeStatistics />}
        </StyledAccordionDetails>
      </StyledAccordion>
      <Divider
        sx={{
          margin: `${theme.spacing(2)} 0`,
        }}
      />
      <StyledAccordion expanded={expandedDescriptiveStatistics} disableGutters elevation={0}>
        <StyledAccordionSummary
          className='chevronUp'
          style={{ paddingBottom: theme.spacing(1) }}
          expandIcon={
            <ChevronDown
              onClick={() => setExpandedDescriptiveStatistics((prevState) => !prevState)}
              size='20'
              color={theme.palette.primary.main}
            />
          }
        >
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography
              variant='h6'
              fontWeight='600'
              width='100%'
              onClick={() => setExpandedDescriptiveStatistics((prevState) => !prevState)}
            >
              Descriptive statistics
            </Typography>
          </Box>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box display='flex' mb={1}>
            <IconButton
              size='small'
              onClick={() => setIsDescriptiveStatisticsModalOpen(true)}
              sx={{
                marginLeft: 'auto',
                background: theme.palette.legend.background,
                borderRadius: '4px',
                visibility: 'visible',
              }}
            >
              <FullScreenEnter />
            </IconButton>
          </Box>
          {isDescriptiveLoading && <Loader />}
          {descriptiveStatisticsError && <StatisticsError error={DESCRIPTIVE_STATISTICS_ERROR} />}
          {showDescriptiveStatistics && (
            <DescriptiveStatistics className='side-panel' isSmallTable />
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
      <ComparativeStatisticsModal
        open={isComparativeStatisticsModalOpen}
        onClose={() => setIsComparativeStatisticsModalOpen(false)}
        isLoading={isComparativeLoading}
        groupAOptions={groupAOptions}
        groupBOptions={groupBOptions}
        groupA={groupA}
        groupB={groupB}
        statisticalTest={statisticalTest}
        handleGroupAChange={handleGroupAChange}
        handleGroupBChange={handleGroupBChange}
        handleStatisticalTestChange={handleStatisticalTestChange}
        error={comparativeStatisticsError}
      />
      <DescriptiveStatisticsModal
        open={isDescriptiveStatisticsModalOpen}
        isLoading={isComparativeLoading}
        onClose={() => setIsDescriptiveStatisticsModalOpen(false)}
        error={descriptiveStatisticsError}
      />
    </div>
  );
};

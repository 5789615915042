import React, { FC } from 'react';

export const Grid: FC = () => (
  <svg width='32' height='32' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.8334 6.49999C14.8334 6.03975 15.2065 5.66666 15.6667 5.66666H21.5C21.9603 5.66666 22.3334 6.03975 22.3334 6.49999V12.3333C22.3334 12.7936 21.9603 13.1667 21.5 13.1667H15.6667C15.2065 13.1667 14.8334 12.7936 14.8334 12.3333V6.49999ZM16.5 7.33332V11.5H20.6667V7.33332H16.5ZM6.50002 14.8333C6.03978 14.8333 5.66669 15.2064 5.66669 15.6667V21.5C5.66669 21.9602 6.03978 22.3333 6.50002 22.3333H12.3334C12.7936 22.3333 13.1667 21.9602 13.1667 21.5V15.6667C13.1667 15.2064 12.7936 14.8333 12.3334 14.8333H6.50002ZM7.33335 20.6667V16.5H11.5V20.6667H7.33335ZM15.6667 14.8333C15.2065 14.8333 14.8334 15.2064 14.8334 15.6667V21.5C14.8334 21.9602 15.2065 22.3333 15.6667 22.3333H21.5C21.9603 22.3333 22.3334 21.9602 22.3334 21.5V15.6667C22.3334 15.2064 21.9603 14.8333 21.5 14.8333H15.6667ZM16.5 20.6667V16.5H20.6667V20.6667H16.5ZM6.50002 5.66666C6.03978 5.66666 5.66669 6.03975 5.66669 6.49999V12.3333C5.66669 12.7936 6.03978 13.1667 6.50002 13.1667H12.3334C12.7936 13.1667 13.1667 12.7936 13.1667 12.3333V6.49999C13.1667 6.03975 12.7936 5.66666 12.3334 5.66666H6.50002ZM7.33335 11.5V7.33332H11.5V11.5H7.33335Z'
      fill='#122239'
    />
  </svg>
);

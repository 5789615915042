import React, { FC, useState } from 'react';
import { Folder, X } from 'react-feather';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StyledTextField } from 'app/mui/TextField';
import { Button } from 'app/mui/Button';
import { formatBytes } from 'app/shared/utils/formatBytes';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { StatusEnum } from 'app/shared/enum/status';
import { UploadForm } from 'app/shared/components/UploadForm/UploadForm';
import { Status } from 'app/shared/components/Status/Status';
import { Tooltip } from 'app/mui/Tooltip';
import datasetImage from 'app/assets/images/datasets/dataset-image.svg';
import { disabledStyles } from 'app/shared/components/CreateDatasetDialog/CreateDatasetDialog';
import { DEFAULT_TIMEOUT } from 'app/shared/utils/timeout';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { SelectedSampleDataset } from './UploadDataset';
import { useDataLibrary } from '../DataLibrary/DataLibrary.hooks';
import { useAnalysis } from '../Analysis/Analysis.hooks';
import { Project } from '../Projects/Projects.types';
import { Dataset } from '../DataLibrary/DataLibrary.types';
import { AnalysisType } from '../Analysis/Analysis.types';

type Props = {
  handleOpenSamplesScreen: () => void;
  selectedSampleDataset?: SelectedSampleDataset;
  setSelectedSampleDataset: any;
};

export const UploadContainer: FC<Props> = ({
  handleOpenSamplesScreen,
  selectedSampleDataset,
  setSelectedSampleDataset,
}) => {
  const theme = useTheme();
  const { navigateToAnalysis } = useNavigation();
  const { createAnalysis } = useAnalysis();
  const { showPublishedDataset } = useDataLibrary();

  const [projectName, setProjectName] = useState<string>('New Project');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState<boolean>(false);
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [uploadedDataset, setUploadedDataset] = useState<Dataset | undefined>(undefined);

  const cancelSampleDataset = (): void => {
    setSelectedSampleDataset(undefined);
  };

  const onUploadingStarted = (): void => {
    setIsUploading(true);
    setIsFieldDisabled(true);
  };

  const onResetAfterCancel = (): void => {
    setIsFieldDisabled(false);
  };

  const onUploadFinished = (dataset: Dataset | undefined): void => {
    setIsUploading(false);
    setUploadedDataset(dataset);
  };

  const onUploadFailed = (): void => {
    setIsUploadFailed(true);
  };

  const onCreateAnalysis = async (
    projectId: number,
    datasetId: number,
    name: string
  ): Promise<void> => {
    const analysis: AnalysisType = await createAnalysis(projectId, datasetId, name);

    setTimeout(() => {
      navigateToAnalysis(projectId, analysis.id);
    }, DEFAULT_TIMEOUT);
  };

  const onCreateAnalysisWithSample = async (dataset_id: number, name: string): Promise<any> => {
    try {
      const project: Project = await api
        .post('project/', {
          headers: getCommonHeaders(),
          json: {
            name: projectName,
            description: '',
            is_private: true,
          },
        })
        .json();

      await api
        .post(`project/${project.id}/add-dataset`, {
          headers: getCommonHeaders(),
          json: {
            dataset_id,
          },
        })
        .json();

      const analysis: AnalysisType = await createAnalysis(project.id, dataset_id, name);

      setTimeout(() => {
        navigateToAnalysis(project.id, analysis.id);
      }, DEFAULT_TIMEOUT);
    } catch (error) {
      return error;
    }
  };

  const onCreate = (): void => {
    if (uploadedDataset) {
      const projectId = (uploadedDataset.projects && uploadedDataset.projects[0].id) as number;
      onCreateAnalysis(projectId, uploadedDataset.id, uploadedDataset.name);
    } else if (selectedSampleDataset) {
      onCreateAnalysisWithSample(selectedSampleDataset.dataset_id, selectedSampleDataset.name);
    }
  };

  const isReadyToStartAnalysis =
    projectName && !isUploading && (uploadedDataset || selectedSampleDataset) && !isUploadFailed;

  return (
    <Box mb={2}>
      <Box sx={isFieldDisabled ? disabledStyles : {}}>
        <Typography variant='caption' color='text.secondary'>
          <Typography color='warning.main' component='span'>
            *
          </Typography>
          Mandatory fields
        </Typography>
        <Box display='flex' my={2}>
          <StyledTextField
            required
            label='New project name'
            value={projectName}
            disabled={isUploading}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </Box>
      </Box>
      {!selectedSampleDataset && (
        <UploadForm
          isFormValid={Boolean(projectName)}
          projectName={projectName}
          onUploadingStarted={onUploadingStarted}
          onUploadFinished={onUploadFinished}
          onUploadFailed={onUploadFailed}
          onResetAfterCancel={onResetAfterCancel}
        />
      )}

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mt={2}
        sx={isFieldDisabled ? disabledStyles : {}}
      >
        <Typography variant='h5' component='div'>
          Or use a sample dataset from Data Library
        </Typography>
        <Button
          variant='outlined'
          onClick={handleOpenSamplesScreen}
          disabled={isUploading}
          startIcon={<Folder />}
        >
          Select Sample
        </Button>
      </Box>
      {selectedSampleDataset && (
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={4} mb={8}>
          <Box display='flex' alignItems='center'>
            <Box
              borderRadius='4px'
              component='img'
              src={datasetImage}
              alt={selectedSampleDataset.name}
            />
            <Box ml={2} display='flex' flexDirection='column' rowGap='12px'>
              <Box display='flex'>
                <Tooltip title={selectedSampleDataset.name} placement='bottom-start'>
                  <Typography component='h5' variant='h5' className='break-text'>
                    {selectedSampleDataset.name}
                  </Typography>
                </Tooltip>
                <Box ml={2}>
                  <Status
                    status={
                      showPublishedDataset(selectedSampleDataset.status)
                        ? StatusEnum.RAW
                        : selectedSampleDataset.status
                    }
                  />
                </Box>
              </Box>
              <Typography variant='body1' color='text.secondary'>
                {formatBytes(selectedSampleDataset.size)}
              </Typography>
            </Box>
          </Box>
          `
          <Box display='flex' alignItems='center'>
            <Box p={1} onClick={cancelSampleDataset} display='flex' sx={{ cursor: 'pointer' }}>
              <X size='24' color={theme.palette.action.active} />
            </Box>
          </Box>
        </Box>
      )}
      <Box display='flex' justifyContent='center' mt={3}>
        <Button disabled={!isReadyToStartAnalysis} variant='contained' onClick={onCreate}>
          Start Analysis
        </Button>
      </Box>
    </Box>
  );
};

import React, { Dispatch, FC, SetStateAction } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type ShowMoreButtonProps = {
  setExpanded: Dispatch<SetStateAction<boolean>>;
  expanded: boolean;
  withText?: boolean;
  chevronSize?: number;
};

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({
  setExpanded,
  expanded,
  withText,
  chevronSize,
}) => {
  const theme = useTheme();

  return (
    <Box
      onClick={() => setExpanded(!expanded)}
      sx={{
        cursor: 'pointer',
        '& svg': { marginRight: theme.spacing(1) },
      }}
    >
      <Typography display='flex' alignItems='center' variant='subtitle1'>
        {expanded ? (
          <>
            <ChevronUp size={chevronSize} /> {withText && 'Show Less'}
          </>
        ) : (
          <>
            <ChevronDown size={chevronSize} /> {withText && 'Show More'}
          </>
        )}
      </Typography>
    </Box>
  );
};

import scatterPlotImage from 'app/assets/images/visualization/scatter-plot.svg';
import heatmapImage from 'app/assets/images/visualization/heatmap.svg';
import violinPlotImage from 'app/assets/images/visualization/violin-plot.svg';
import dataLandscapeImage from 'app/assets/images/visualization/data-landscape.svg';
import featureLandscapeImage from 'app/assets/images/visualization/feature-landscape.svg';
import histogramImage from 'app/assets/images/visualization/histogram.svg';
import { VisualisationType } from 'app/screens/Analysis//AnalysisSidebar/Configure/Configure';

export type VisualizationType = {
  name: string;
  img: string;
  type: string;
};

export const visualizations: VisualizationType[] = [
  {
    name: 'Data Landscape',
    img: dataLandscapeImage,
    type: VisualisationType.DATA_LANDSCAPE,
  },
  {
    name: 'Feature Landscape',
    img: featureLandscapeImage,
    type: VisualisationType.FEATURE_LANDSCAPE,
  },
  {
    name: 'Scatter Plot',
    img: scatterPlotImage,
    type: VisualisationType.SCATTER_PLOT,
  },
  {
    name: 'Heatmap',
    img: heatmapImage,
    type: VisualisationType.HEATMAP,
  },
  {
    name: 'Violin Plot',
    img: violinPlotImage,
    type: VisualisationType.VIOLIN_PLOT,
  },
  {
    name: 'Histogram',
    img: histogramImage,
    type: VisualisationType.HISTOGRAM,
  },
];

import React, { useState } from 'react';
import { Operator } from './Explorer.types';

export enum FILTER_FEATURE_TYPE {
  NUMERIC = 'numeric',
  STRING = 'unstructured',
  CATEGORICAL = 'categorical',
}

export enum OPERATOR {
  MORE = '>',
  MORE_THAN = '>=',
  LESS = '<',
  LESS_THAN = '<=',
  EQUAL = '=',
  IN = 'in',
  SUBSTR = 'substr', // any substring, case-insensitive
  SUBSTR_MC = 'substr_mc', // any substring, case-sensitive
  SUBSTR_FULL = 'substr_full', // exact string, case-insensitive
  SUBSTR_MC_FULL = 'substr_mc_full', // exact string, case-sensitive
}

export const isOperatorCategorical = (operator: Operator): boolean => operator === OPERATOR.IN;

export const isOperatorString = (operator: Operator): boolean =>
  operator === OPERATOR.SUBSTR ||
  operator === OPERATOR.SUBSTR_MC ||
  operator === OPERATOR.SUBSTR_FULL ||
  operator === OPERATOR.SUBSTR_MC_FULL;

export const getOperatorDisplayText = (operator: Operator): string => {
  if (isOperatorCategorical(operator)) {
    return ' is ';
  }

  if (isOperatorString(operator)) {
    return ' contains ';
  }

  return operator;
};

export const dataExplorerRowKey = (row: any): number => row.row_index;

export const featureExplorerRowKey = (row: any): number => row.id;

export const explorerComparator = (a: number, b: number): number => a - b;

export const isFeatureTypeNumeric = (type: string): boolean => type === FILTER_FEATURE_TYPE.NUMERIC;

export const isAtBottom = ({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean =>
  currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;

export const getSortedRows = (rows: any, sortColumns: any): readonly any[] => {
  if (sortColumns.length === 0) return rows;

  return [...rows].sort((a, b) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const sort of sortColumns) {
      const compResult = explorerComparator(a[sort.columnKey], b[sort.columnKey]);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
};

export const useScrollDirection = () => {
  const [lastXPosition, setLastXPosition] = useState(0);
  const [lastYPosition, setLastYPosition] = useState(0);

  return ({ currentTarget, target }: React.UIEvent<HTMLDivElement>) => {
    const scrollTarget = currentTarget || target;

    const yPosition = scrollTarget.scrollTop;
    const xPosition = scrollTarget.scrollLeft;

    let direction = 'still';

    if (yPosition > lastYPosition) {
      direction = 'bottom';
    } else if (yPosition < lastYPosition) {
      direction = 'top';
    }

    if (xPosition > lastXPosition) {
      direction = 'right';
    } else if (xPosition < lastXPosition) {
      direction = 'left';
    }

    setLastYPosition(yPosition);
    setLastXPosition(xPosition);

    return { direction, yPosition, xPosition };
  };
};

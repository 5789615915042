import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const TermsContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: `calc(100% - 92px)`,
  [theme.breakpoints.up('xl')]: {
    width: '1624px',
  },
}));

import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export const ProfileWrapper = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  borderRadius: '50%',
  background: theme.palette.blocksBackground.dark,
  color: theme.palette.secondary.contrastText,
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '1rem',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DATE_SORTING_FIELD = 'updated_at';

export const formatDate = (date?: string): string => {
  if (!date) return 'N/A';

  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

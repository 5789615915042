import React, { FC, useEffect, useMemo, useContext } from 'react';
import { Info } from 'react-feather';
import { Box, Typography } from '@mui/material';

import { Tooltip } from 'app/mui/Tooltip';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';
import { COLORING_OPTIONS } from 'app/shared/enum/analysis';
import { shortName } from 'app/shared/utils/visualization';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { ColoringMenu } from 'app/screens/Analysis/Coloring/ColoringMenu';
import { ColorLegend } from 'app/screens/Analysis/ColorLegend/ColorLegend';
import { PanHeightContext } from 'app/shared/context/PanHeightContext/PanHeightContext';
import { useColoring } from './Coloring.hooks';
import { ColorLegendType } from './Coloring.types';
import {
  ChartDataType,
  ColoringConfig,
  LandscapeData,
  RetrieveGraphType,
  SelectedFeatureType,
} from '../Analysis.types';
import { ColoringLabel } from '../Analysis.styles';

const hintTexts: Record<string, string> = {
  numeric:
    'When both quantitative, categorical and/or unstructured text  features are selected, only coloring by the quantitative ones gets applied.',
  category:
    'When both unstructured text and categorical features are selected, only coloring by the categorical ones gets applied. Coloring by unstructured text features is impossible.',
};

type ColoringProps = {
  type: VisualisationType;
  panelId: string;
  analysisId: number;
  visualizationId: number;
  isLargePan?: boolean;
  coloring?: ColoringConfig;
  chart?: ChartDataType;
  retrieveGraphData?: RetrieveGraphType;
  onDataUpdate?: (data: LandscapeData) => void;
  onChartColoringUpdate?: (colors: string[] | string) => void;
  setInitialColoringData: (graph?: RetrieveGraphType) => void;
  setColoringTypeRef?: (coloringType: SelectedFeatureType | null) => void;
  setLegendRef?: (legend: ColorLegendType | null) => void;
};

const COLOR_LEGEND_LARGE_PAN_HEIGHT = 360;

const COLOR_LEGEND_PAN_HEIGHT = 235;

const COLOR_LEGEND_LARGE_PAN_OFFSET = 100;

export const Coloring: FC<ColoringProps> = ({
  visualizationId,
  type,
  panelId,
  analysisId,
  coloring,
  chart,
  retrieveGraphData,
  isLargePan,
  onDataUpdate,
  onChartColoringUpdate,
  setInitialColoringData,
  setLegendRef,
  setColoringTypeRef,
}) => {
  const {
    colorOption,
    featureOption,
    aggregationType,
    normalizationType,
    diffOptionOne,
    diffOptionTwo,
    legend,
    featureType,
    hintTextType,
    coloringAnchorEl,
    openColoring,
    showColorPicker,
    handleColoringOpen,
    handleColoringClose,
    handleColorOption,
    handleFeatureApply,
    handleDiffOptionApply,
    handleNormalizationType,
    handleAggregationType,
    handleLegendChange,
    handleColorPicker,
    handleColorMapChange,
  } = useColoring({
    type,
    coloring,
    panelId,
    analysisId,
    visualizationId,
    chart,
    retrieveGraphData,
    onDataUpdate,
    onChartColoringUpdate,
    setInitialColoringData,
  });

  const { panHeight } = useContext(PanHeightContext);

  const colorLegendHeight = useMemo(() => {
    if (isLargePan && panHeight) {
      const customColorLegendHeight = panHeight - COLOR_LEGEND_LARGE_PAN_OFFSET;

      return customColorLegendHeight < COLOR_LEGEND_LARGE_PAN_HEIGHT
        ? customColorLegendHeight
        : COLOR_LEGEND_LARGE_PAN_HEIGHT;
    }

    return COLOR_LEGEND_PAN_HEIGHT;
  }, [isLargePan, panHeight]);

  const showLegend = !!legend?.dataBounds || !!legend?.categories;
  const isFeatureLandscape = type === VisualisationType.FEATURE_LANDSCAPE;
  const showHintText = !isFeatureLandscape && !!hintTextType;

  useEffect(() => {
    if (setColoringTypeRef) {
      setColoringTypeRef(featureOption || colorOption);
    }
  }, [setColoringTypeRef, colorOption, featureOption]);

  useEffect(() => {
    if (setLegendRef) {
      setLegendRef(legend);
    }
  }, [setLegendRef, legend]);

  return (
    <>
      <ColoringLabel onClick={handleColoringOpen}>
        Colored by &nbsp;
        {colorOption?.id !== COLORING_OPTIONS.none ? (
          <Tooltip
            title={featureOption?.feature_name || colorOption?.feature_name}
            placement='bottom-start'
          >
            <Typography noWrap className='break-text'>
              <b>
                {shortName(featureOption?.feature_name || colorOption?.feature_name, 195, true)}
              </b>
            </Typography>
          </Tooltip>
        ) : (
          <b>—</b>
        )}
        {showHintText && (
          <Tooltip title={hintTexts[hintTextType]} placement='top-end'>
            <Box display='flex' ml='6px'>
              <Info width={16} height={16} color='#ED6C02' />
            </Box>
          </Tooltip>
        )}
      </ColoringLabel>
      <DotsMenu anchorEl={coloringAnchorEl} open={openColoring} onClose={handleColoringClose}>
        <ColoringMenu
          type={type}
          colorOption={colorOption}
          normalizationType={normalizationType}
          aggregationType={aggregationType}
          featureOption={featureOption}
          featureType={featureType}
          diffOptionOne={diffOptionOne}
          diffOptionTwo={diffOptionTwo}
          onColorOptionChange={handleColorOption}
          onFeatureApply={handleFeatureApply}
          onNormalizationType={handleNormalizationType}
          onAggregationType={handleAggregationType}
          onDiffOptionApply={handleDiffOptionApply}
        />
      </DotsMenu>
      {!!legend && showLegend && (
        <ColorLegend
          {...legend}
          height={colorLegendHeight}
          width={11}
          showColorPicker={showColorPicker}
          onLegendChange={handleLegendChange}
          handleColorPicker={handleColorPicker}
          handleColorMapChange={handleColorMapChange}
        />
      )}
    </>
  );
};

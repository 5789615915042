import React, { FC, useCallback, useEffect, useState } from 'react';
import type AbstractGraph from 'graphology-types';

import { LastJsonMessage, SOCKET_ENTITY, useSocket } from 'app/shared/hooks/useSocket';
import { Coloring } from 'app/screens/Analysis/Coloring/Coloring';
import { ColorLegendType } from 'app/screens/Analysis/Coloring/Coloring.types';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { VisualizationLoader } from 'app/screens/Analysis/Visualizations/VisualizationLoader';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { FailedGraph } from 'app/screens/Analysis/Visualizations/Graph/FailedGraph';
import { RetryGraph } from 'app/screens/Analysis/Visualizations/Graph/RetryGraph';
import {
  AnalysisConfig,
  ColoringConfig,
  LandscapeData,
  LandscapeGraphPayload,
  RetrieveGraphType,
  SelectedFeatureType,
  SELECTION_SOURCES,
  VisualisationsStatus,
} from 'app/screens/Analysis/Analysis.types';
import { LandscapeGraphWidget } from './LandscapeGraphWidget';

type LandscapeProps = {
  id: number;
  type: VisualisationType;
  metadata: AnalysisConfig;
  panelId: string;
  analysisId: number;
  isLargePan?: boolean;
  coloring?: ColoringConfig;
  shouldLayoutUpdate?: boolean;
  onSelection: (type: VisualisationType, hasNodes: boolean) => void;
  setShouldLayoutUpdate?: (shouldLayoutUpdate: boolean) => void;
  isGenerateSvg: boolean;
  onSvgGenerate: (graph: AbstractGraph, metadata: AnalysisConfig) => void;
  setColoringTypeRef: (coloringType: SelectedFeatureType | null) => void;
  setLegendRef: (legend: ColorLegendType | null) => void;
};

export const Landscape: FC<LandscapeProps> = ({
  id,
  analysisId,
  type,
  panelId,
  metadata,
  coloring,
  isLargePan,
  shouldLayoutUpdate,
  setShouldLayoutUpdate,
  onSelection,
  isGenerateSvg,
  onSvgGenerate,
  setColoringTypeRef,
  setLegendRef,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [initialData, setInitialData] = useState<RetrieveGraphType | undefined>(undefined);
  const [currentStatus, setCurrentStatus] = useState<VisualisationsStatus | null>(null);
  const [data, setData] = useState<LandscapeData | undefined>(undefined);

  const {
    retrieveGraph,
    getGraphLayoutData,
    dataPointsSelectionSource,
    featureSelectionSource,
    defaultDataLandscapeConfig,
    defaultFeatureLandscapeConfig,
    setSelectedDataRows,
    setSelectedFeatures,
    setDataSelectionSource,
    setFeaturesSelectionSource,
    editGraph,
    updateLandscapeConfig,
  } = useAnalysis();

  const selectionSource =
    type === VisualisationType.DATA_LANDSCAPE ? dataPointsSelectionSource : featureSelectionSource;

  const setDefaultLandscapeData = useCallback(
    (graph?: RetrieveGraphType) => {
      const landscape = graph || initialData;

      if (landscape) {
        const landscapeLayout = getGraphLayoutData(landscape.task_result) as LandscapeData;
        setData(landscapeLayout);
      }
    },
    [initialData, getGraphLayoutData]
  );

  const updateLandscapeParams = useCallback(
    (config: LandscapeGraphPayload, status: VisualisationsStatus): void => {
      if (status === VisualisationsStatus.COMPLETED) {
        updateLandscapeConfig(id, type, {
          neighbor_graph_config: config.params.neighbor_graph_config,
        });
      }
    },
    [id, type, updateLandscapeConfig]
  );

  const retrieveGraphData = useCallback(
    async (analysisEntityId: number, graphId: number) => {
      try {
        setLoading(true);

        const [response] = (await retrieveGraph(
          analysisEntityId,
          [graphId].join(',')
        )) as RetrieveGraphType[];

        if (response.task_result) {
          setInitialData(response);
          setCurrentStatus(response.task_status as VisualisationsStatus);

          updateLandscapeParams(response.task_result, response.task_status as VisualisationsStatus);
        }
      } catch (e) {
        setLoading(false);
        throw Error(`Can't retrieve graph with id(${graphId})`);
      }
    },
    [retrieveGraph, updateLandscapeParams]
  );

  const { lastJsonMessage } = useSocket();

  useEffect(() => {
    if (lastJsonMessage) {
      const { type: command, payload } = lastJsonMessage as LastJsonMessage;

      if (command === SOCKET_ENTITY.RETRIEVE_GRAPH) {
        const config = payload as AnalysisConfig;

        const isAlreadyCompleted = currentStatus === VisualisationsStatus.COMPLETED;
        const isCurrentAnalysis = config.analysis_id === analysisId;
        const isCurrentGraph = config.id === id;
        const isCompleted = config.status === VisualisationsStatus.COMPLETED;
        const isInProgress = config.status === VisualisationsStatus.IN_PROGRESS;

        if (isCurrentAnalysis && isCurrentGraph && isInProgress) {
          setCurrentStatus(config.status);
          setLoading(true);
        }

        if (!isAlreadyCompleted && isCurrentAnalysis && isCompleted && isCurrentGraph) {
          setUpdate(true);
          retrieveGraphData(analysisId, config.id);
        }
      }
    }
  }, [lastJsonMessage, retrieveGraphData, currentStatus]);

  useEffect(() => {
    if (id && data) {
      setUpdate(true);
    }

    if (id && analysisId) {
      setCurrentStatus(null);
      retrieveGraphData(analysisId, id);
    }
  }, [id, analysisId]);

  const handleSelection = useCallback(
    (landscapeType: VisualisationType, nodes: number[]) => {
      if (landscapeType === VisualisationType.DATA_LANDSCAPE) {
        const selectedNodesSet = new Set(nodes);
        const selectedNodesArray = Array.from(selectedNodesSet);

        setSelectedDataRows(selectedNodesArray);
        setDataSelectionSource(
          selectedNodesArray.length
            ? {
                source: SELECTION_SOURCES.LANDSCAPE,
                id,
                name: metadata.name,
              }
            : null
        );
      } else if (landscapeType === VisualisationType.FEATURE_LANDSCAPE) {
        const selectedFeatureNodes = new Set(nodes);
        const selectedFeaturesArray = Array.from(selectedFeatureNodes);

        setSelectedFeatures(selectedFeaturesArray);
        setFeaturesSelectionSource(
          selectedFeaturesArray.length
            ? {
                source: SELECTION_SOURCES.LANDSCAPE,
                id,
                name: metadata.name,
              }
            : null
        );
      }

      onSelection(landscapeType, !!nodes.length);
    },
    [data, id, metadata]
  );

  const onResetGraph = async (): Promise<void> => {
    const defaultConfig =
      type === VisualisationType.DATA_LANDSCAPE
        ? defaultDataLandscapeConfig
        : defaultFeatureLandscapeConfig;

    await editGraph({
      visualisation_id: id,
      analysis_id: analysisId,
      name: metadata.name,
      config: defaultConfig,
    });
  };

  const isFailed = metadata.status === VisualisationsStatus.FAILED;
  const isRetryFailed = metadata.status === VisualisationsStatus.RETRY_FAILED;
  const showFailed = isRetryFailed || (isFailed && metadata.error);
  const showRetry = metadata.status === VisualisationsStatus.RETRY;
  const showLoader = (isLoading || !id || !data) && !isFailed && !isRetryFailed && !showRetry;
  const showLandscape = !!data && !showRetry && !isFailed && !isRetryFailed;
  const showColoring = !isFailed && !isRetryFailed;

  return (
    <>
      {showRetry && <RetryGraph />}
      {showFailed && (
        <FailedGraph
          isLargePan={isLargePan}
          isRetryFailed={isRetryFailed}
          onResetGraph={onResetGraph}
          errorMessage={metadata.error}
        />
      )}
      {showLoader && <VisualizationLoader />}
      {showColoring && (
        <Coloring
          type={type}
          panelId={panelId}
          analysisId={analysisId}
          visualizationId={id}
          isLargePan={isLargePan}
          onDataUpdate={setData}
          coloring={coloring}
          retrieveGraphData={initialData}
          setInitialColoringData={setDefaultLandscapeData}
          setColoringTypeRef={setColoringTypeRef}
          setLegendRef={setLegendRef}
        />
      )}
      {showLandscape && (
        <LandscapeGraphWidget
          type={type}
          data={data}
          metadata={metadata}
          selectionSource={selectionSource}
          setSelectedIndices={handleSelection}
          shouldLayoutUpdate={shouldLayoutUpdate}
          setShouldLayoutUpdate={setShouldLayoutUpdate}
          setLoading={setLoading}
          setUpdate={setUpdate}
          isUpdate={isUpdate}
          isGenerateSvg={isGenerateSvg}
          onSvgGenerate={onSvgGenerate}
        />
      )}
    </>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { LOADING_STATE } from 'app/store/constants';
import type { Project, ProjectAnalysis } from 'app/screens/Projects/Projects.types';
import { LayoutEnum } from 'app/shared/enum/layout';
import { RootState } from '../store';

type ProjectSlice = {
  loading: LOADING_STATE;
  projects: Project[];
  project: Project;
  layout: LayoutEnum.GRID | LayoutEnum.TABLE;
};

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('project/', {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProject = createAsyncThunk(
  'project/fetchProject',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`project/${id}`, {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAnalysesByProject = createAsyncThunk(
  'project/fetchAnalyses',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`project/${id}/analyses`, {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: LOADING_STATE.IDLE,
  projects: [],
  project: {
    creator: {
      firstname: '',
      lastname: '',
    },
    id: 0,
    name: '',
    is_private: false,
    description: '',
    status: '',
    created_at: '',
    updated_at: '',
    datasets: [],
    analyses: [],
  },
  layout: LayoutEnum.TABLE,
} as ProjectSlice;

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectsLayout: (state, action) => {
      state.layout = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchProjects.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.projects = action.payload;
    });
    builder.addCase(fetchProject.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchProject.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      const { analyses, ...project } = action.payload;

      state.project = {
        ...state.project,
        ...project,
      };
    });
    builder.addCase(fetchAnalysesByProject.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchAnalysesByProject.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchAnalysesByProject.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.project = {
        ...state.project,
        analyses: action.payload,
      };
    });
  },
});

// actions
export const { setProjectsLayout } = projectSlice.actions;

// selectors
export const selectProjectsLoading = (state: RootState): boolean =>
  state.projects.loading === LOADING_STATE.PENDING;
export const selectProjectActionSuccess = (state: RootState): boolean =>
  state.projects.loading === LOADING_STATE.SUCCESS;
export const selectProjects = (state: RootState): Project[] => state.projects.projects;
export const selectProjectsLayout = (state: RootState): string => state.projects.layout;

export const selectProject = (state: RootState): Project => state.projects.project;
export const selectProjectAnalyses = (state: RootState): ProjectAnalysis[] =>
  state.projects.project.analyses;

// reducer
export const projectsReducer = projectSlice.reducer;

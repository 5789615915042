import React, { FC, Fragment, useMemo } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Box } from '@mui/material';
import { Tooltip } from 'app/mui/Tooltip';
import { Palette } from 'app/shared/icons';
import { CroppedText } from 'app/shared/styles/common';

import { categoricalMaps, ColorPair } from 'app/shared/styles/categoricalMaps';
import { CategoricalColorMaps, ColorLegendType } from '../Coloring/Coloring.types';
import {
  CategoriesColorBlock,
  ColorCircle,
  ColorLegendWrapper,
  ColorPickerBlock,
  ColorPickerButton,
  ColorSelectButton,
  DataBoundsBlock,
  LegendBlock,
  LegendButton,
  LineColorBlock,
  Text,
} from './ColorLegend.styles';

interface Props extends ColorLegendType {
  showColorPicker: boolean;
  height?: number;
  width?: number;
  position?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  onLegendChange: (update: Partial<ColorLegendType>) => void;
  handleColorPicker: () => void;
  handleColorMapChange: (colorMapType: CategoricalColorMaps) => void;
}

export const ColorLegend: FC<Props> = ({
  type,
  colorMap,
  visible,
  colorBounds,
  dataBounds,
  functionName,
  categories,
  showColorPicker,
  height = 300,
  width = 11,
  position,
  onLegendChange,
  handleColorMapChange,
  handleColorPicker,
}) => {
  const legendPosition = useMemo(
    () =>
      position || {
        bottom: 5,
        left: 16,
      },
    [position]
  );

  const legendBackground = useMemo<string | null>(() => {
    if (colorBounds?.length) {
      const colorGradient = colorBounds?.join(', ');
      return colorBounds.length > 1 ? `linear-gradient(to top, ${colorGradient})` : colorGradient;
    }

    return null;
  }, [colorBounds]);

  const toggleLegend = () => {
    onLegendChange({
      visible: !visible,
    });
  };

  const isCategories = type === 'categories';
  const showLine = !isCategories && legendBackground && !!dataBounds?.length;
  const showCategories = isCategories && !!categories;
  const isMonochromatic = colorMap === CategoricalColorMaps.Monochromatic;

  const getLegendCircleStyles = (cat: Record<string, string | null>) => {
    if (isMonochromatic && cat.value === 'no dominant category') {
      return {
        borderColor: '#000000',
      };
    }

    return {
      backgroundColor: cat.color!,
    };
  };

  const getColorCircleStyles = (colorMapValue: CategoricalColorMaps, [value, color]: ColorPair) => {
    if (colorMapValue === CategoricalColorMaps.Monochromatic && value === 10) {
      return {
        borderColor: '#000000',
      };
    }

    return {
      backgroundColor: color,
    };
  };

  const colorsList = useMemo<{ value: CategoricalColorMaps; colors: ColorPair[] }[]>(
    () =>
      (Object.keys(categoricalMaps) as CategoricalColorMaps[]).map((key) => ({
        value: key,
        colors: categoricalMaps[key],
      })),
    []
  );

  return (
    <ColorLegendWrapper {...legendPosition}>
      {visible && !showColorPicker && (
        <>
          {showLine && (
            <LineColorBlock height={height}>
              <Tooltip title={functionName || ''} placement='top'>
                <Box height='100%' display='flex' flexDirection='column' alignItems='flex-start'>
                  {!!functionName && (
                    <Box width='100%'>
                      <CroppedText mb={1} variant='body1' color='text.secondary'>
                        {functionName}
                      </CroppedText>
                    </Box>
                  )}
                  <LegendBlock flexGrow={1} display='flex' alignItems='stretch'>
                    <Box
                      width={width}
                      style={{
                        background: legendBackground,
                      }}
                    />
                    <DataBoundsBlock>
                      {dataBounds.map((data, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Text key={`${data}/${index}`} variant='body1'>
                          {data.toFixed(2)}
                        </Text>
                      ))}
                    </DataBoundsBlock>
                  </LegendBlock>
                </Box>
              </Tooltip>
            </LineColorBlock>
          )}
          {showCategories && (
            <CategoriesColorBlock maxHeight={height}>
              <Tooltip title={functionName || ''} placement='top'>
                <Box>
                  {!!functionName && (
                    <Box width='100%'>
                      <CroppedText mb={1} variant='body1' color='text.secondary'>
                        {functionName}
                      </CroppedText>
                    </Box>
                  )}
                </Box>
              </Tooltip>
              {categories?.map((cat) => (
                <Fragment key={cat.value!}>
                  <Tooltip title={cat.value!} placement='top'>
                    <Box display='flex' alignItems='center' mt={1} mb={1}>
                      <ColorCircle marginRight='8px' {...getLegendCircleStyles(cat)} />
                      <CroppedText color='text.secondary'>{cat.value}</CroppedText>
                    </Box>
                  </Tooltip>
                </Fragment>
              ))}
            </CategoriesColorBlock>
          )}
        </>
      )}
      {showCategories && showColorPicker && (
        <ColorPickerBlock>
          {colorsList.map(({ value, colors }) => (
            <ColorSelectButton
              key={value}
              isActive={value === colorMap}
              size='small'
              onClick={() => handleColorMapChange(value)}
            >
              {colors.map((color) => (
                <ColorCircle key={color[1]} {...getColorCircleStyles(value, color)} />
              ))}
            </ColorSelectButton>
          ))}
        </ColorPickerBlock>
      )}
      <Box mt={1} display='flex'>
        <LegendButton
          isCategories={showCategories}
          size='small'
          onClick={toggleLegend}
          endIcon={visible ? <EyeOff /> : <Eye />}
        >
          Legend
        </LegendButton>
        {showCategories && (
          <ColorPickerButton isActive={showColorPicker} size='small' onClick={handleColorPicker}>
            <Palette width={20} height={20} />
          </ColorPickerButton>
        )}
      </Box>
    </ColorLegendWrapper>
  );
};

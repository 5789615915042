import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Lock, Unlock, Edit3, Trash2 } from 'react-feather';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  ListRow,
  ListRowInfo,
} from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList.styles';
import projectImage from 'app/assets/images/projects/project-image.svg';
import { Tooltip } from 'app/mui/Tooltip';
import { useNavigate } from 'app/navigation';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { IconButton } from 'app/mui/IconButton';
import { Project, ProjectTypes } from '../Projects.types';

type ProjectItemProps = {
  setSelectedProject: Dispatch<SetStateAction<Project>>;
  setIsEditDialogOpen: any;
  setIsDeleteDialogOpen: any;
  row: Project;
  labelId: string;
};

export const ProjectItem: FC<ProjectItemProps> = ({
  setSelectedProject,
  setIsEditDialogOpen,
  setIsDeleteDialogOpen,
  row,
  labelId,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToOverview = useCallback((id: number) => {
    navigate(id.toString());
  }, []);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    name: string,
    description: string,
    is_private: boolean
  ): void => {
    setSelectedProject((project: Project) => ({
      ...project,
      id,
      name,
      description,
      is_private,
    }));
  };

  const onEditDialogOpen = useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);

  const openDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  // Will be needed later
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const projectTypeJSX = (
    <Box ml={2}>
      <Tooltip
        title={row.is_private ? ProjectTypes.Private : ProjectTypes.Public}
        placement='bottom'
      >
        {row.is_private ? (
          <Lock color={theme.palette.action.active} size={24} />
        ) : (
          <Unlock color={theme.palette.action.active} size={24} />
        )}
      </Tooltip>
    </Box>
  );

  return (
    <ListRow onClick={() => navigateToOverview(row.id)}>
      <ListRowInfo>
        <Box borderRadius='4px' component='img' src={projectImage} alt={row.name} />
        <Box
          sx={{
            ml: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            minWidth: 0,
          }}
        >
          <Box display='flex'>
            <Tooltip title={row.name} placement='bottom-start'>
              <Typography noWrap component='h5' variant='h5' id={labelId} className='break-text'>
                {row.name}
              </Typography>
            </Tooltip>
          </Box>
          <Typography component='div' variant='body1' color='text.secondary' display='flex'>
            {row.analyses.length} Analyses, {row.datasets ? row.datasets.length : '0'} Dataset
            <Typography mx={2}>&bull;</Typography>
            Updated {lastUpdated(row.updated_at)}
          </Typography>
        </Box>
      </ListRowInfo>
      <Box display='flex' alignItems='center'>
        <Box display='flex' marginLeft='24px'>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu(event, row.id, row.name, row.description, row.is_private);
              onEditDialogOpen();
            }}
          >
            <Edit3 />
          </IconButton>
        </Box>
        <Box display='flex' marginLeft='24px'>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu(event, row.id, row.name, row.description, row.is_private);
              openDeleteDialog();
            }}
          >
            <Trash2 />
          </IconButton>
        </Box>
      </Box>
    </ListRow>
  );
};

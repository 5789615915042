import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Trash2 } from 'react-feather';
import classNames from 'classnames';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import { StatusEnum } from 'app/shared/enum/status';
import { Status } from 'app/shared/components/Status/Status';
import { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { SelectedDataset } from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList';
import { DATASET_STATUSES } from 'app/shared/enum/dataset';
import { ProjectDatasets } from 'app/screens/Projects/Projects.types';
import { DatasetItem } from '../DatasetCard/DatasetItem.stylex';

type FailedDatasetCardProps = {
  setSelectedDataset: Dispatch<SetStateAction<any>>;
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  row: ProjectDatasets;
  labelId: string;
  handlePreviewOpen: (id: number, isExample: boolean | null) => void;
  selectedDataset: SelectedDataset;
};

export const FailedDatasetCard: FC<FailedDatasetCardProps> = ({
  setSelectedDataset,
  setIsDeleteDialogOpen,
  row,
  labelId,
  handlePreviewOpen,
  selectedDataset,
}) => {
  const openDeleteDialog = useCallback((id: number, name: string) => {
    setSelectedDataset((dataset: Dataset) => ({
      ...dataset,
      id,
      name,
    }));
    setIsDeleteDialogOpen(true);
  }, []);

  return (
    <DatasetItem
      className={classNames('failed', { highlight: row.id === selectedDataset?.previewId })}
      onClick={() => handlePreviewOpen(row.id, row.is_example)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flex: 1,
          minWidth: 0,
          cursor: 'pointer',
        }}
      >
        <Box ml={2} display='flex' flexDirection='column' rowGap='12px' flex={1} minWidth={0}>
          <Box>
            <Tooltip title={row.name} placement='bottom-start'>
              <Typography noWrap variant='subtitle1' id={labelId} className='break-text'>
                {row.name}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        <Box ml={2}>
          <Status
            status={row.status === DATASET_STATUSES.UPLOAD_FAILED ? StatusEnum.FAILED : row.status}
          />
        </Box>

        <Box
          p={1}
          display='flex'
          onClick={(e) => {
            e.stopPropagation();
            openDeleteDialog(row.id, row.name);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Trash2 />
        </Box>
      </Box>
    </DatasetItem>
  );
};

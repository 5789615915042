import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { X } from 'react-feather';
import { styled } from '@mui/material/styles';
import { IconButton } from 'app/mui/IconButton';

const DrawerWrapper = styled(Box)(({ theme }) => ({
  width: '0px',
  display: 'none',
  boxShadow: 'inset 1px 0px 0px #E2E4E7',
  padding: theme.spacing(1, 3),
  background: theme.palette.background.paper,
  position: 'relative',
  '&.visible': {
    width: '425px',
    display: 'block',
    position: 'sticky',
    top: '60px',
    marginTop: '60px',
    height: '100vh',
  },
}));

const DrawerHeading = styled('h3')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  margin: '0',
  fontSize: '1.8rem',
  fontWeight: '700',
  wordBreak: 'break-all',
  maxWidth: '275px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '.MuiIconButton-root': {
    position: 'absolute',
    top: theme.spacing(1),
    right: '0',
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: 'calc(100vh - 120px)',
  paddingBottom: theme.spacing(1),
}));

interface DrawerProps {
  title: string;
  subtitle?: string;
  onClose?: () => void;
  children: ReactNode;
  open: boolean;
}

export const Drawer: FC<DrawerProps> = ({ title, onClose, children, open, ...props }) => (
  <DrawerWrapper {...props} className={classNames({ visible: open })}>
    <DrawerHeading>
      {title}
      <IconButton size='small' color='primary' onClick={onClose}>
        <X />
      </IconButton>
    </DrawerHeading>
    <DrawerContent>{children}</DrawerContent>
  </DrawerWrapper>
);

import React, { FC, useCallback, useState } from 'react';
import { PlusCircle } from 'react-feather';
import Box from '@mui/material/Box';
import { IconButton } from 'app/mui/IconButton';
import { CreateGroupDialog } from 'app/screens/Analysis/CreateGroupDialog/CreateGroupDialog';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';
import { Line, Plus, SelectionButton, Typography } from './Selections.styles';
import { SelectionActions } from './SelectionActions/SelectionActions';
import { useAnalysis } from '../Analysis.hooks';
import { DatasetMetadata } from '../Analysis.types';

type SelectionsProps = {
  showSelectedDataRows: () => void;
  resetDataRows: () => void;
  showSelectedFeatureRows: () => void;
  resetFeatures: () => void;
  isDataRowsChecked: boolean;
  isDataColumnsChecked: boolean;
  isFeatureRowsChecked: boolean;
  isDataExplorerTab: boolean;
  clearDataRowsSelections: () => void;
  clearFeatureSelections: () => void;
  isShowSelectedDataRows: boolean;
  isShowSelectedFeatureRows: boolean;
  isShowSelectedColumns: boolean;
  dataset?: DatasetMetadata;
};

export const Selections: FC<SelectionsProps> = ({
  isDataRowsChecked,
  isDataColumnsChecked,
  isFeatureRowsChecked,
  isDataExplorerTab,
  showSelectedDataRows,
  resetDataRows,
  showSelectedFeatureRows,
  resetFeatures,
  clearDataRowsSelections,
  clearFeatureSelections,
  isShowSelectedDataRows,
  isShowSelectedFeatureRows,
  isShowSelectedColumns,
  dataset,
}) => {
  const { selectedDataRows, selectedFeatures } = useAnalysis();

  const [dataAnchorEl, setDataAnchorEl] = useState<null | HTMLElement>(null);
  const [featureAnchorEl, setFeatureAnchorEl] = useState<null | HTMLElement>(null);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState<boolean>(false);
  const [openMenuType, setOpenMenuType] = useState<string | undefined>(undefined);

  const openData = Boolean(dataAnchorEl);
  const openFeature = Boolean(featureAnchorEl);

  const handleClickDataMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setDataAnchorEl(event.currentTarget);
  };
  const handleClickFeatureMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setFeatureAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setDataAnchorEl(null);
    setFeatureAnchorEl(null);
  };

  const openGroupModal = useCallback(() => {
    setIsGroupModalOpen(true);
    handleClose();
  }, []);

  const closeGroupModal = useCallback(() => {
    setIsGroupModalOpen(false);
  }, []);

  const featuresCount = dataset?.extra_metadata.num_of_columns || 'All';
  const dataPointsCount = dataset?.extra_metadata.num_of_rows || 'All';

  return (
    <Box display='flex' alignItems='center'>
      <SelectionButton>
        <Box display='flex' flexDirection='column' alignItems='flex-end'>
          <Typography className='count'>
            {selectedFeatures.length > 0 ? `${selectedFeatures.length} of` : ''} {featuresCount}{' '}
          </Typography>
          <Typography className='name'>features</Typography>
        </Box>
        <IconButton onClick={(event) => handleClickFeatureMenu(event)}>
          <Plus>
            <PlusCircle />
          </Plus>
        </IconButton>
      </SelectionButton>

      <DotsMenu anchorEl={featureAnchorEl} open={openFeature} onClose={handleClose}>
        <SelectionActions
          isFeatureMenu
          setOpenMenuType={setOpenMenuType}
          isDataRowsChecked={isDataRowsChecked}
          isDataColumnsChecked={isDataColumnsChecked}
          isFeatureRowsChecked={isFeatureRowsChecked}
          isDataExplorerTab={isDataExplorerTab}
          showSelectedFeatureRows={showSelectedFeatureRows}
          resetFeatures={resetFeatures}
          clearFeatureSelections={clearFeatureSelections}
          isShowSelectedFeatureRows={isShowSelectedFeatureRows}
          isShowSelectedColumns={isShowSelectedColumns}
          openGroupModal={openGroupModal}
          handleMenuClose={handleClose}
        />
      </DotsMenu>

      <Line />

      <SelectionButton>
        <Box display='flex' flexDirection='column' alignItems='flex-end'>
          <Typography className='count'>
            {selectedDataRows.length > 0 ? `${selectedDataRows.length} of` : ''} {dataPointsCount}{' '}
          </Typography>
          <Typography className='name'>data points</Typography>
        </Box>
        <IconButton onClick={(event) => handleClickDataMenu(event)}>
          <Plus>
            <PlusCircle />
          </Plus>
        </IconButton>
      </SelectionButton>

      <DotsMenu anchorEl={dataAnchorEl} open={openData} onClose={handleClose}>
        <SelectionActions
          setOpenMenuType={setOpenMenuType}
          isDataExplorerTab={isDataExplorerTab}
          isDataRowsChecked={isDataRowsChecked}
          isDataColumnsChecked={isDataColumnsChecked}
          isFeatureRowsChecked={isFeatureRowsChecked}
          showSelectedFeatureRows={showSelectedFeatureRows}
          showSelectedDataRows={showSelectedDataRows}
          resetDataRows={resetDataRows}
          resetFeatures={resetFeatures}
          clearDataRowsSelections={clearDataRowsSelections}
          isShowSelectedDataRows={isShowSelectedDataRows}
          openGroupModal={openGroupModal}
          handleMenuClose={handleClose}
        />
      </DotsMenu>

      {isGroupModalOpen && (
        <CreateGroupDialog
          openDialog={isGroupModalOpen}
          handleClose={closeGroupModal}
          groupCreationType={openMenuType}
        />
      )}
    </Box>
  );
};

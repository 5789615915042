import React, { FC, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikErrors } from 'formik';
import classNames from 'classnames';
import { Eye, EyeOff } from 'react-feather';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuthentication } from 'app/shared/hooks/useAuthentication';
import { TextFieldForm } from 'app/mui/TextFieldForm';
import { Button } from 'app/mui/Button';
import { IconButton } from 'app/mui/IconButton';
import { AuthWrapper, GradientBackground } from 'app/shared/styles/common';
import { Header } from 'app/shared/components/Header/Header';
import { Terms } from 'app/shared/components/Terms/Terms';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import successIcon from 'app/assets/images/auth/success.svg';
import { selectCognitoUser, setCognitoUser } from 'app/store/modules/account';
import PasswordRegex from 'app/shared/components/PasswordRegex/PasswordRegex';

interface ChangePasswordValues {
  newPassword: string;
}

const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      'Password should match pattern'
    ),
});

enum CHANGE_PASSWORD_STEPS {
  CHANGE_PASSWORD,
  SIGN_IN,
}

export const ChangePassword: FC = () => {
  const { loading, completeNewPassword, login } = useAuthentication();

  const dispatch = useAppDispatch();
  const cognitoUser = useAppSelector(selectCognitoUser);

  const [step, setStep] = useState(CHANGE_PASSWORD_STEPS.CHANGE_PASSWORD);

  const [showPassword, setShowPassword] = useState(false);

  const [isPasswordActive, setIsPasswordActive] = useState(false);

  const [isValidated, setIsValidated] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleChangePassword = useCallback(
    async (
      values: ChangePasswordValues,
      setErrors: (errors: FormikErrors<ChangePasswordValues>) => void
    ) => {
      try {
        await completeNewPassword(cognitoUser, values.newPassword);
        setStep(CHANGE_PASSWORD_STEPS.SIGN_IN);

        setTimeout(async () => {
          await login(cognitoUser.username, values.newPassword);
          dispatch(setCognitoUser(undefined));
        }, 2000);
      } catch (e: any) {
        const error = e.toString().split(':');
        const message = error[1];

        setErrors({
          newPassword: message,
        });
      }
    },
    [cognitoUser]
  );

  const onBlurPassword = useCallback(() => {
    setIsPasswordActive(false);
  }, []);

  const onFocusPassword = useCallback(() => {
    setIsPasswordActive(true);
  }, []);

  return (
    <>
      <Header isTransparent />
      <GradientBackground>
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='text.secondary'
        >
          <AuthWrapper
            className={classNames('centered', {
              'no-padding-x': step === CHANGE_PASSWORD_STEPS.SIGN_IN,
            })}
          >
            <Box>
              {step === CHANGE_PASSWORD_STEPS.CHANGE_PASSWORD && (
                <>
                  <Box mb={3}>
                    <Typography
                      component='h3'
                      variant='h3'
                      mb={3}
                      textAlign='center'
                      color='primary.main'
                    >
                      Change Password
                    </Typography>
                    <Typography component='p' textAlign='center' color='text.secondary'>
                      To protect your account, please set a new password instead of the temporary
                      one.
                    </Typography>
                  </Box>
                  <Formik
                    initialValues={{
                      newPassword: '',
                    }}
                    validationSchema={changePasswordValidationSchema}
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      handleChangePassword(values, setErrors).then(() => {
                        setSubmitting(false);
                      });
                    }}
                  >
                    {({ submitForm, isSubmitting, values }) => (
                      <Form>
                        <Box height={90} display='flex' position='relative'>
                          <Field
                            component={TextFieldForm}
                            type={showPassword ? 'text' : 'password'}
                            label='New password'
                            placeholder='New password'
                            name='newPassword'
                            onBlur={onBlurPassword}
                            onFocus={onFocusPassword}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  color='primary'
                                  size='small'
                                  sx={{
                                    padding: 0,
                                  }}
                                >
                                  {showPassword ? <EyeOff /> : <Eye />}
                                </IconButton>
                              ),
                            }}
                            fullWidth
                          />
                          {isPasswordActive && (
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '-220px',
                                top: '-46px',
                                borderRadius: '8px',
                                background: '#F2FCFF',
                                padding: '16px',
                              }}
                            >
                              <PasswordRegex
                                password={values.newPassword}
                                isValidated={isValidated}
                              />
                            </Box>
                          )}
                        </Box>
                        <Button
                          variant='contained'
                          color='primary'
                          size='large'
                          type='submit'
                          disabled={isSubmitting || loading}
                          onClick={() => {
                            submitForm().then(() => {
                              setIsValidated(true);
                            });
                          }}
                          fullWidth
                        >
                          Change Password
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
              {step === CHANGE_PASSWORD_STEPS.SIGN_IN && (
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Box
                    component='img'
                    src={successIcon}
                    alt='success icon'
                    sx={{
                      width: 54,
                      height: 54,
                    }}
                  />
                  <Typography component='h3' variant='h3' color='primary.main' my={5}>
                    Password Successfully Changed
                  </Typography>
                </Box>
              )}
            </Box>
          </AuthWrapper>
        </Box>
        <Terms />
      </GradientBackground>
    </>
  );
};

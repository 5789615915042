import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CircularProgress } from 'app/mui/CircularProgress';
import { Popup } from 'app/shared/components/Popup/Popup';
import { Order } from 'app/shared/components/SortSelect/SortSelect';
import {
  getComparator,
  SelectedDataset,
} from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList';
import { EditDataLibraryDialog } from 'app/screens/DataLibrary/EditDataLibraryDialog/EditDataLibraryDialog';
import { useDataLibrary } from 'app/screens/DataLibrary/DataLibrary.hooks';
import { ProjectDatasets } from 'app/screens/Projects/Projects.types';
import { ShowMoreButton } from 'app/shared/components/ShowMoreButton/ShowMoreButton';
import { EditDataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { Tooltip } from 'app/mui/Tooltip';
import { Status } from 'app/shared/components/Status/Status';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { DEFAULT_TIMEOUT } from 'app/shared/utils/timeout';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { DatasetCard } from '../DatasetCard/DatasetCard';
import { DatasetItem } from '../DatasetCard/DatasetItem.stylex';
import { FailedDatasetCard } from '../FailedDatasetCard/FailedDatasetCard';

const DATASETS_SHOW_LIMIT = 4;

type DatasetCardsProps = {
  datasets: ProjectDatasets[];
  order: Order;
  orderBy: string;
  setSelectedDataset: Dispatch<SetStateAction<SelectedDataset>>;
  handlePreviewOpen: (id: number, isExample: boolean | null) => void;
  handlePreviewClose: () => void;
  selectedDataset: SelectedDataset;
  projectId: number;
};

export const DatasetCards: FC<DatasetCardsProps> = ({
  datasets,
  order,
  orderBy,
  setSelectedDataset,
  handlePreviewOpen,
  handlePreviewClose,
  selectedDataset,
  projectId,
}) => {
  const { deleteDataset, editDataset, showPublishedDataset, showFailedDataset } = useDataLibrary();
  const { detachDataset, getProject, getProjectAnalyses } = useProjects();
  const { createAnalysis } = useAnalysis();
  const { navigateToAnalysis } = useNavigation();

  const [expanded, setExpanded] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetachDialogOpen, setIsDetachDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const datasetsToDisplay = expanded ? datasets.slice() : datasets.slice(0, DATASETS_SHOW_LIMIT);

  const closeEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const onEditDataset = (values: EditDataset): void => {
    editDataset({
      id: values.id,
      name: values.name,
      description: values.description,
      onSuccess: closeEditDialog,
    }).then(() => {
      getProject(projectId);
      getProjectAnalyses(projectId);
      handlePreviewClose();
    });
  };

  const closeDetachDialog = useCallback(() => {
    setIsDetachDialogOpen(false);
  }, []);

  const onDetachDataset = (id: number): void => {
    detachDataset(projectId, id).then(() => {
      getProject(projectId);
      getProjectAnalyses(projectId);
      setIsDetachDialogOpen(false);
      handlePreviewClose();
    });
  };

  const closeDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const onDeleteDataset = (id: number): void => {
    deleteDataset(id, () => {
      getProjectAnalyses(projectId);
      getProject(projectId);
      setIsDeleteDialogOpen(false);
      handlePreviewClose();
    });
  };

  const startAnalysis = async (): Promise<void> => {
    if (projectId && selectedDataset && selectedDataset.name && selectedDataset.id) {
      const response = (await createAnalysis(
        projectId,
        selectedDataset.id,
        selectedDataset.name
      )) as any;
      setTimeout(() => {
        navigateToAnalysis(projectId, response.id);
      }, DEFAULT_TIMEOUT);
    }
  };

  return (
    <>
      <Box display='flex' flexWrap='wrap' gap='26px'>
        {datasetsToDisplay.sort(getComparator(order, orderBy)).map((row, index) => {
          const labelId = `dataset-item-${index}`;

          if (showPublishedDataset(row.status)) {
            return (
              <DatasetCard
                key={row.id}
                row={row}
                labelId={labelId}
                selectedDataset={selectedDataset}
                setSelectedDataset={setSelectedDataset}
                startAnalysis={startAnalysis}
                setIsEditDialogOpen={setIsEditDialogOpen}
                setIsDetachDialogOpen={setIsDetachDialogOpen}
                handlePreviewOpen={handlePreviewOpen}
              />
            );
          }

          if (showFailedDataset(row.status)) {
            return (
              <FailedDatasetCard
                key={row.id}
                row={row}
                labelId={labelId}
                setSelectedDataset={setSelectedDataset}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                selectedDataset={selectedDataset}
                handlePreviewOpen={handlePreviewOpen}
              />
            );
          }

          return (
            <DatasetItem className='publishing' key={row.id}>
              <Box display='flex' alignItems='center' width='100%' flex={1} minWidth={0}>
                <Box
                  ml={2}
                  display='flex'
                  flexDirection='column'
                  rowGap='12px'
                  flex={1}
                  minWidth={0}
                >
                  <Tooltip title={row.name} placement='bottom-start'>
                    <Typography noWrap variant='subtitle1' id={labelId} className='break-text'>
                      {row.name}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
              <Box display='flex' alignItems='center'>
                <Box display='flex' alignItems='center'>
                  <Box mr={2}>
                    <Status status='Publishing' />
                  </Box>
                  <Box p={1}>
                    <CircularProgress />
                  </Box>
                </Box>
              </Box>
            </DatasetItem>
          );
        })}
        {selectedDataset && selectedDataset.id && selectedDataset.name && (
          <>
            <EditDataLibraryDialog
              open={isEditDialogOpen}
              id={selectedDataset.id}
              name={selectedDataset.name}
              description={selectedDataset.description}
              handleClose={closeEditDialog}
              onSubmit={onEditDataset}
            />
            <Popup
              open={isDetachDialogOpen}
              onClose={closeDetachDialog}
              onConfirm={() => selectedDataset.id && onDetachDataset(selectedDataset.id)}
              state='Detach'
              title={`Detach ${selectedDataset.name}?`}
              description='Detach dataset and related analyses from the project. Are you sure you want to proceed?'
            />
            <Popup
              open={isDeleteDialogOpen}
              onClose={closeDeleteDialog}
              onConfirm={() => selectedDataset.id && onDeleteDataset(selectedDataset.id)}
              state='Delete'
              title={`Delete ${selectedDataset.name}?`}
              description='All analyses based on this dataset will also be deleted. Are you sure you want to proceed?'
            />
          </>
        )}
      </Box>
      {datasets.length > DATASETS_SHOW_LIMIT && (
        <Box mt={3}>
          <ShowMoreButton expanded={expanded} setExpanded={setExpanded} withText />
        </Box>
      )}
    </>
  );
};

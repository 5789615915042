import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { UploadingDatasetStepType } from 'app/screens/DataLibrary/DataLibrary.types';
import { DATASET_UPLOADING_STATUS } from 'app/shared/enum/dataset';
import { UploadingStep } from './UploadingStep/UploadingStep';

const UploadingSteps = [
  { step: 1, status: DATASET_UPLOADING_STATUS.UPLOADING },
  { step: 2, status: DATASET_UPLOADING_STATUS.PROCESSING },
  { step: 3, status: DATASET_UPLOADING_STATUS.METADATA_EXTRACTION },
];

type ProgressBarProps = {
  activeStatus: UploadingDatasetStepType;
};

export const ProgressBar: FC<ProgressBarProps> = ({ activeStatus }) => {
  const theme = useTheme();

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      {UploadingSteps.map((uploadingStep: UploadingDatasetStepType) => (
        <>
          <UploadingStep currentStatus={uploadingStep} activeStatus={activeStatus} />
          {uploadingStep.step !== 3 && (
            <Box
              height='1px'
              width='100%'
              mx={1}
              sx={{
                background:
                  uploadingStep.step < activeStatus.step
                    ? theme.palette.blocksBackground.dark
                    : theme.palette.text.disabled,
              }}
            />
          )}
        </>
      ))}
    </Box>
  );
};

import { ColorSchemeLibrary } from './scales';

export const colorcetMaps: ColorSchemeLibrary = {
  CET_C1: [
    [0.0, '#f884f7'],
    [0.00392156862745098, '#f983f5'],
    [0.00784313725490196, '#fa82f3'],
    [0.011764705882352941, '#fb81f0'],
    [0.01568627450980392, '#fc80ee'],
    [0.0196078431372549, '#fc7feb'],
    [0.023529411764705882, '#fc7de8'],
    [0.027450980392156862, '#fd7be5'],
    [0.03137254901960784, '#fd7ae1'],
    [0.03529411764705882, '#fc78de'],
    [0.0392156862745098, '#fc76da'],
    [0.043137254901960784, '#fc73d7'],
    [0.047058823529411764, '#fb71d3'],
    [0.050980392156862744, '#fb6fcf'],
    [0.054901960784313725, '#fa6dcc'],
    [0.058823529411764705, '#fa6bc8'],
    [0.06274509803921569, '#f968c4'],
    [0.06666666666666667, '#f866c0'],
    [0.07058823529411765, '#f864bc'],
    [0.07450980392156863, '#f761b9'],
    [0.0784313725490196, '#f65fb5'],
    [0.08235294117647059, '#f55db1'],
    [0.08627450980392157, '#f55aad'],
    [0.09019607843137255, '#f458aa'],
    [0.09411764705882353, '#f355a6'],
    [0.09803921568627451, '#f253a2'],
    [0.10196078431372549, '#f1519e'],
    [0.10588235294117647, '#f04e9b'],
    [0.10980392156862745, '#ef4c97'],
    [0.11372549019607843, '#ee4a93'],
    [0.11764705882352941, '#ec478f'],
    [0.12156862745098039, '#eb458b'],
    [0.12549019607843137, '#ea4388'],
    [0.12941176470588234, '#e94184'],
    [0.13333333333333333, '#e73f80'],
    [0.13725490196078433, '#e63c7c'],
    [0.1411764705882353, '#e43a78'],
    [0.14509803921568626, '#e33875'],
    [0.14901960784313725, '#e13671'],
    [0.15294117647058825, '#df346d'],
    [0.1568627450980392, '#de3269'],
    [0.16078431372549018, '#dc3165'],
    [0.16470588235294117, '#da2f61'],
    [0.16862745098039217, '#d82d5e'],
    [0.17254901960784313, '#d62b5a'],
    [0.1764705882352941, '#d42956'],
    [0.1803921568627451, '#d32752'],
    [0.1843137254901961, '#d1264e'],
    [0.18823529411764706, '#cf244b'],
    [0.19215686274509802, '#cd2247'],
    [0.19607843137254902, '#cb2043'],
    [0.2, '#c91f3f'],
    [0.20392156862745098, '#c71d3c'],
    [0.20784313725490194, '#c51b38'],
    [0.21176470588235294, '#c31a35'],
    [0.21568627450980393, '#c11931'],
    [0.2196078431372549, '#c0182e'],
    [0.22352941176470587, '#be172a'],
    [0.22745098039215686, '#bc1627'],
    [0.23137254901960785, '#bb1624'],
    [0.23529411764705882, '#b91621'],
    [0.2392156862745098, '#b8171e'],
    [0.24313725490196078, '#b7181b'],
    [0.24705882352941178, '#b61918'],
    [0.25098039215686274, '#b51a15'],
    [0.2549019607843137, '#b51c13'],
    [0.2588235294117647, '#b51e11'],
    [0.2627450980392157, '#b4210f'],
    [0.26666666666666666, '#b4230d'],
    [0.27058823529411763, '#b4260b'],
    [0.27450980392156865, '#b52809'],
    [0.2784313725490196, '#b52b08'],
    [0.2823529411764706, '#b62d07'],
    [0.28627450980392155, '#b63006'],
    [0.2901960784313725, '#b73305'],
    [0.29411764705882354, '#b83505'],
    [0.2980392156862745, '#b93804'],
    [0.30196078431372547, '#ba3b04'],
    [0.3058823529411765, '#bb3d04'],
    [0.30980392156862746, '#bc4004'],
    [0.3137254901960784, '#bd4304'],
    [0.3176470588235294, '#be4504'],
    [0.32156862745098036, '#bf4804'],
    [0.3254901960784314, '#c04a04'],
    [0.32941176470588235, '#c14d04'],
    [0.3333333333333333, '#c24f04'],
    [0.33725490196078434, '#c35204'],
    [0.3411764705882353, '#c45404'],
    [0.34509803921568627, '#c55604'],
    [0.34901960784313724, '#c65904'],
    [0.3529411764705882, '#c75b04'],
    [0.3568627450980392, '#c75d04'],
    [0.3607843137254902, '#c86004'],
    [0.36470588235294116, '#c96204'],
    [0.3686274509803922, '#ca6504'],
    [0.37254901960784315, '#cb6704'],
    [0.3764705882352941, '#cc6904'],
    [0.3803921568627451, '#cc6c04'],
    [0.38431372549019605, '#cd6e04'],
    [0.38823529411764707, '#ce7004'],
    [0.39215686274509803, '#ce7304'],
    [0.396078431372549, '#cf7504'],
    [0.4, '#d07804'],
    [0.403921568627451, '#d07a04'],
    [0.40784313725490196, '#d17c04'],
    [0.4117647058823529, '#d17f04'],
    [0.4156862745098039, '#d28104'],
    [0.4196078431372549, '#d28304'],
    [0.4235294117647059, '#d38604'],
    [0.42745098039215684, '#d38804'],
    [0.43137254901960786, '#d48a04'],
    [0.43529411764705883, '#d48d04'],
    [0.4392156862745098, '#d58f04'],
    [0.44313725490196076, '#d59104'],
    [0.44705882352941173, '#d59304'],
    [0.45098039215686275, '#d69605'],
    [0.4549019607843137, '#d69805'],
    [0.4588235294117647, '#d69a06'],
    [0.4627450980392157, '#d69c08'],
    [0.4666666666666667, '#d69e0a'],
    [0.47058823529411764, '#d6a00c'],
    [0.4745098039215686, '#d6a20f'],
    [0.4784313725490196, '#d5a311'],
    [0.4823529411764706, '#d5a515'],
    [0.48627450980392156, '#d4a618'],
    [0.49019607843137253, '#d3a71b'],
    [0.49411764705882355, '#d2a81f'],
    [0.4980392156862745, '#d1a923'],
    [0.5019607843137255, '#cfaa27'],
    [0.5058823529411764, '#cdaa2a'],
    [0.5098039215686274, '#cbaa2e'],
    [0.5137254901960784, '#c9aa32'],
    [0.5176470588235293, '#c7aa36'],
    [0.5215686274509804, '#c4a93a'],
    [0.5254901960784314, '#c2a93e'],
    [0.5294117647058824, '#bfa842'],
    [0.5333333333333333, '#bca746'],
    [0.5372549019607843, '#b9a64a'],
    [0.5411764705882353, '#b6a54d'],
    [0.5450980392156862, '#b2a451'],
    [0.5490196078431373, '#afa355'],
    [0.5529411764705883, '#aca258'],
    [0.5568627450980392, '#a8a15c'],
    [0.5607843137254902, '#a4a05f'],
    [0.5647058823529412, '#a19f62'],
    [0.5686274509803921, '#9d9d66'],
    [0.5725490196078431, '#999c69'],
    [0.5764705882352941, '#959b6c'],
    [0.580392156862745, '#919a6f'],
    [0.5843137254901961, '#8d9872'],
    [0.5882352941176471, '#899775'],
    [0.592156862745098, '#859679'],
    [0.596078431372549, '#80957c'],
    [0.6, '#7c937f'],
    [0.6039215686274509, '#779282'],
    [0.6078431372549019, '#729185'],
    [0.611764705882353, '#6d9087'],
    [0.615686274509804, '#688e8a'],
    [0.6196078431372549, '#638d8d'],
    [0.6235294117647059, '#5e8c90'],
    [0.6274509803921569, '#588a93'],
    [0.6313725490196078, '#538996'],
    [0.6352941176470588, '#4d8799'],
    [0.6392156862745098, '#48869c'],
    [0.6431372549019607, '#43849f'],
    [0.6470588235294118, '#3d82a2'],
    [0.6509803921568628, '#3880a5'],
    [0.6549019607843137, '#337ea8'],
    [0.6588235294117647, '#2f7cab'],
    [0.6627450980392157, '#2b7aae'],
    [0.6666666666666666, '#2878b1'],
    [0.6705882352941176, '#2576b4'],
    [0.6745098039215687, '#2373b8'],
    [0.6784313725490196, '#2271bb'],
    [0.6823529411764706, '#216ebe'],
    [0.6862745098039216, '#216cc1'],
    [0.6901960784313725, '#2269c4'],
    [0.6941176470588235, '#2366c7'],
    [0.6980392156862745, '#2463ca'],
    [0.7019607843137254, '#2660ce'],
    [0.7058823529411764, '#275dd1'],
    [0.7098039215686275, '#295ad4'],
    [0.7137254901960784, '#2b57d7'],
    [0.7176470588235294, '#2c54da'],
    [0.7215686274509804, '#2e51dd'],
    [0.7254901960784313, '#2f4ee0'],
    [0.7294117647058823, '#314be2'],
    [0.7333333333333333, '#3348e5'],
    [0.7372549019607844, '#3546e7'],
    [0.7411764705882353, '#3743ea'],
    [0.7450980392156863, '#3941ec'],
    [0.7490196078431373, '#3b3fee'],
    [0.7529411764705882, '#3e3ef0'],
    [0.7568627450980392, '#403df1'],
    [0.7607843137254902, '#433cf3'],
    [0.7647058823529411, '#463cf4'],
    [0.7686274509803921, '#493cf5'],
    [0.7725490196078432, '#4c3df6'],
    [0.7764705882352941, '#4f3df6'],
    [0.7803921568627451, '#523ef7'],
    [0.7843137254901961, '#5540f7'],
    [0.788235294117647, '#5841f8'],
    [0.792156862745098, '#5b43f8'],
    [0.796078431372549, '#5d44f8'],
    [0.8, '#6046f9'],
    [0.803921568627451, '#6348f9'],
    [0.807843137254902, '#664af9'],
    [0.8117647058823529, '#684cf9'],
    [0.8156862745098039, '#6b4ef9'],
    [0.8196078431372549, '#6d50f9'],
    [0.8235294117647058, '#7052f9'],
    [0.8274509803921568, '#7254f9'],
    [0.8313725490196078, '#7556f9'],
    [0.8352941176470589, '#7758f9'],
    [0.8392156862745098, '#795af9'],
    [0.8431372549019608, '#7c5cf9'],
    [0.8470588235294118, '#7e5ef9'],
    [0.8509803921568627, '#8160f9'],
    [0.8549019607843137, '#8461f9'],
    [0.8588235294117647, '#8663f9'],
    [0.8627450980392157, '#8965f9'],
    [0.8666666666666667, '#8c66fa'],
    [0.8705882352941177, '#8f68fa'],
    [0.8745098039215686, '#926afa'],
    [0.8784313725490196, '#956bfa'],
    [0.8823529411764706, '#996cfa'],
    [0.8862745098039215, '#9c6efa'],
    [0.8901960784313725, '#9f6ffb'],
    [0.8941176470588235, '#a370fb'],
    [0.8980392156862745, '#a671fb'],
    [0.9019607843137255, '#aa73fb'],
    [0.9058823529411765, '#ad74fc'],
    [0.9098039215686274, '#b175fc'],
    [0.9137254901960784, '#b476fc'],
    [0.9176470588235294, '#b877fd'],
    [0.9215686274509803, '#bc78fd'],
    [0.9254901960784314, '#bf79fd'],
    [0.9294117647058824, '#c37afd'],
    [0.9333333333333333, '#c67bfe'],
    [0.9372549019607843, '#ca7cfe'],
    [0.9411764705882353, '#cd7dfe'],
    [0.9450980392156862, '#d17eff'],
    [0.9490196078431372, '#d47fff'],
    [0.9529411764705882, '#d880ff'],
    [0.9568627450980391, '#db81ff'],
    [0.9607843137254902, '#de82ff'],
    [0.9647058823529412, '#e182ff'],
    [0.9686274509803922, '#e483ff'],
    [0.9725490196078431, '#e784ff'],
    [0.9764705882352941, '#ea84ff'],
    [0.9803921568627451, '#ed85fe'],
    [0.984313725490196, '#ef85fd'],
    [0.9882352941176471, '#f185fd'],
    [0.9921568627450981, '#f385fc'],
    [0.996078431372549, '#f585fa'],
    [1.0, '#f785f9'],
  ],
  CET_C10: [
    [0.0, '#d99084'],
    [0.00392156862745098, '#d99083'],
    [0.00784313725490196, '#d89182'],
    [0.011764705882352941, '#d89181'],
    [0.01568627450980392, '#d79180'],
    [0.0196078431372549, '#d7927f'],
    [0.023529411764705882, '#d6927e'],
    [0.027450980392156862, '#d5927d'],
    [0.03137254901960784, '#d5937c'],
    [0.03529411764705882, '#d4937b'],
    [0.0392156862745098, '#d3947a'],
    [0.043137254901960784, '#d3947a'],
    [0.047058823529411764, '#d29479'],
    [0.050980392156862744, '#d19578'],
    [0.054901960784313725, '#d09577'],
    [0.058823529411764705, '#cf9677'],
    [0.06274509803921569, '#cf9676'],
    [0.06666666666666667, '#ce9775'],
    [0.07058823529411765, '#cd9775'],
    [0.07450980392156863, '#cc9874'],
    [0.0784313725490196, '#cb9873'],
    [0.08235294117647059, '#ca9873'],
    [0.08627450980392157, '#c99972'],
    [0.09019607843137255, '#c89972'],
    [0.09411764705882353, '#c79a71'],
    [0.09803921568627451, '#c69a71'],
    [0.10196078431372549, '#c59b71'],
    [0.10588235294117647, '#c49b70'],
    [0.10980392156862745, '#c39c70'],
    [0.11372549019607843, '#c29c6f'],
    [0.11764705882352941, '#c09d6f'],
    [0.12156862745098039, '#c09d6f'],
    [0.12549019607843137, '#be9d6e'],
    [0.12941176470588234, '#bd9e6e'],
    [0.13333333333333333, '#bc9e6e'],
    [0.13725490196078433, '#bb9f6e'],
    [0.1411764705882353, '#ba9f6d'],
    [0.14509803921568626, '#b9a06d'],
    [0.14901960784313725, '#b7a06d'],
    [0.15294117647058825, '#b6a16d'],
    [0.1568627450980392, '#b5a16d'],
    [0.16078431372549018, '#b4a16c'],
    [0.16470588235294117, '#b2a26c'],
    [0.16862745098039217, '#b1a26c'],
    [0.17254901960784313, '#b0a36c'],
    [0.1764705882352941, '#afa36c'],
    [0.1803921568627451, '#ada36c'],
    [0.1843137254901961, '#aca46c'],
    [0.18823529411764706, '#aba46c'],
    [0.19215686274509802, '#a9a56c'],
    [0.19607843137254902, '#a8a56c'],
    [0.2, '#a7a66d'],
    [0.20392156862745098, '#a5a66d'],
    [0.20784313725490194, '#a4a66d'],
    [0.21176470588235294, '#a3a76d'],
    [0.21568627450980393, '#a1a76e'],
    [0.2196078431372549, '#a0a76e'],
    [0.22352941176470587, '#9ea86f'],
    [0.22745098039215686, '#9da86f'],
    [0.23137254901960785, '#9ba970'],
    [0.23529411764705882, '#9aa970'],
    [0.2392156862745098, '#98a971'],
    [0.24313725490196078, '#97aa71'],
    [0.24705882352941178, '#95aa72'],
    [0.25098039215686274, '#94aa73'],
    [0.2549019607843137, '#93ab73'],
    [0.2588235294117647, '#91ab74'],
    [0.2627450980392157, '#90ab75'],
    [0.26666666666666666, '#8eac76'],
    [0.27058823529411763, '#8dac77'],
    [0.27450980392156865, '#8bac78'],
    [0.2784313725490196, '#8aac79'],
    [0.2823529411764706, '#88ad7a'],
    [0.28627450980392155, '#87ad7b'],
    [0.2901960784313725, '#85ad7c'],
    [0.29411764705882354, '#84ad7d'],
    [0.2980392156862745, '#82ae7e'],
    [0.30196078431372547, '#81ae7f'],
    [0.3058823529411765, '#80ae81'],
    [0.30980392156862746, '#7eae82'],
    [0.3137254901960784, '#7dae83'],
    [0.3176470588235294, '#7baf84'],
    [0.32156862745098036, '#7aaf85'],
    [0.3254901960784314, '#79af87'],
    [0.32941176470588235, '#77af88'],
    [0.3333333333333333, '#76af89'],
    [0.33725490196078434, '#74af8a'],
    [0.3411764705882353, '#73b08c'],
    [0.34509803921568627, '#72b08d'],
    [0.34901960784313724, '#70b08e'],
    [0.3529411764705882, '#6fb090'],
    [0.3568627450980392, '#6db091'],
    [0.3607843137254902, '#6cb092'],
    [0.36470588235294116, '#6bb093'],
    [0.3686274509803922, '#69b095'],
    [0.37254901960784315, '#68b196'],
    [0.3764705882352941, '#66b197'],
    [0.3803921568627451, '#65b199'],
    [0.38431372549019605, '#63b19a'],
    [0.38823529411764707, '#62b19b'],
    [0.39215686274509803, '#61b19d'],
    [0.396078431372549, '#5fb19e'],
    [0.4, '#5eb19f'],
    [0.403921568627451, '#5cb1a1'],
    [0.40784313725490196, '#5bb1a2'],
    [0.4117647058823529, '#59b1a3'],
    [0.4156862745098039, '#58b1a5'],
    [0.4196078431372549, '#56b2a6'],
    [0.4235294117647059, '#55b2a7'],
    [0.42745098039215684, '#53b2a9'],
    [0.43137254901960786, '#52b2aa'],
    [0.43529411764705883, '#50b2ac'],
    [0.4392156862745098, '#4fb2ad'],
    [0.44313725490196076, '#4db2ae'],
    [0.44705882352941173, '#4cb2b0'],
    [0.45098039215686275, '#4bb2b1'],
    [0.4549019607843137, '#49b2b2'],
    [0.4588235294117647, '#48b2b4'],
    [0.4627450980392157, '#47b2b5'],
    [0.4666666666666667, '#46b2b6'],
    [0.47058823529411764, '#45b2b7'],
    [0.4745098039215686, '#44b2b9'],
    [0.4784313725490196, '#43b2ba'],
    [0.4823529411764706, '#43b1bb'],
    [0.48627450980392156, '#42b1bc'],
    [0.49019607843137253, '#42b1be'],
    [0.49411764705882355, '#41b1bf'],
    [0.4980392156862745, '#41b1c0'],
    [0.5019607843137255, '#41b1c1'],
    [0.5058823529411764, '#41b1c2'],
    [0.5098039215686274, '#42b1c3'],
    [0.5137254901960784, '#42b0c4'],
    [0.5176470588235293, '#43b0c6'],
    [0.5215686274509804, '#43b0c7'],
    [0.5254901960784314, '#44b0c7'],
    [0.5294117647058824, '#45b0c8'],
    [0.5333333333333333, '#46afc9'],
    [0.5372549019607843, '#48afca'],
    [0.5411764705882353, '#49afcb'],
    [0.5450980392156862, '#4aafcc'],
    [0.5490196078431373, '#4cafcd'],
    [0.5529411764705883, '#4daecd'],
    [0.5568627450980392, '#4faece'],
    [0.5607843137254902, '#51aecf'],
    [0.5647058823529412, '#52adcf'],
    [0.5686274509803921, '#54add0'],
    [0.5725490196078431, '#56add1'],
    [0.5764705882352941, '#58add1'],
    [0.580392156862745, '#5aacd2'],
    [0.5843137254901961, '#5cacd2'],
    [0.5882352941176471, '#5dacd3'],
    [0.592156862745098, '#5fabd3'],
    [0.596078431372549, '#61abd4'],
    [0.6, '#63abd4'],
    [0.6039215686274509, '#65aad4'],
    [0.6078431372549019, '#67aad5'],
    [0.611764705882353, '#69aad5'],
    [0.615686274509804, '#6ba9d6'],
    [0.6196078431372549, '#6da9d6'],
    [0.6235294117647059, '#6fa8d6'],
    [0.6274509803921569, '#71a8d6'],
    [0.6313725490196078, '#72a8d7'],
    [0.6352941176470588, '#74a7d7'],
    [0.6392156862745098, '#76a7d7'],
    [0.6431372549019607, '#78a7d7'],
    [0.6470588235294118, '#7aa6d8'],
    [0.6509803921568628, '#7ca6d8'],
    [0.6549019607843137, '#7ea5d8'],
    [0.6588235294117647, '#80a5d8'],
    [0.6627450980392157, '#81a4d8'],
    [0.6666666666666666, '#83a4d8'],
    [0.6705882352941176, '#85a4d8'],
    [0.6745098039215687, '#87a3d8'],
    [0.6784313725490196, '#89a3d8'],
    [0.6823529411764706, '#8ba2d8'],
    [0.6862745098039216, '#8da2d8'],
    [0.6901960784313725, '#8ea1d8'],
    [0.6941176470588235, '#90a1d8'],
    [0.6980392156862745, '#92a1d8'],
    [0.7019607843137254, '#94a0d8'],
    [0.7058823529411764, '#96a0d7'],
    [0.7098039215686275, '#989fd7'],
    [0.7137254901960784, '#999fd7'],
    [0.7176470588235294, '#9b9ed7'],
    [0.7215686274509804, '#9d9ed6'],
    [0.7254901960784313, '#9f9dd6'],
    [0.7294117647058823, '#a19dd5'],
    [0.7333333333333333, '#a39cd5'],
    [0.7372549019607844, '#a49cd4'],
    [0.7411764705882353, '#a69bd4'],
    [0.7450980392156863, '#a89bd3'],
    [0.7490196078431373, '#aa9ad2'],
    [0.7529411764705882, '#ab9ad2'],
    [0.7568627450980392, '#ad99d1'],
    [0.7607843137254902, '#af99d0'],
    [0.7647058823529411, '#b099cf'],
    [0.7686274509803921, '#b298ce'],
    [0.7725490196078432, '#b398cd'],
    [0.7764705882352941, '#b597cc'],
    [0.7803921568627451, '#b697cb'],
    [0.7843137254901961, '#b896ca'],
    [0.788235294117647, '#b996c9'],
    [0.792156862745098, '#bb96c8'],
    [0.796078431372549, '#bc95c7'],
    [0.8, '#bd95c6'],
    [0.803921568627451, '#bf94c5'],
    [0.807843137254902, '#c094c4'],
    [0.8117647058823529, '#c194c3'],
    [0.8156862745098039, '#c293c2'],
    [0.8196078431372549, '#c393c0'],
    [0.8235294117647058, '#c493bf'],
    [0.8274509803921568, '#c693be'],
    [0.8313725490196078, '#c792bd'],
    [0.8352941176470589, '#c892bc'],
    [0.8392156862745098, '#c992ba'],
    [0.8431372549019608, '#ca91b9'],
    [0.8470588235294118, '#ca91b8'],
    [0.8509803921568627, '#cb91b7'],
    [0.8549019607843137, '#cc91b5'],
    [0.8588235294117647, '#cd91b4'],
    [0.8627450980392157, '#ce90b3'],
    [0.8666666666666667, '#cf90b1'],
    [0.8705882352941177, '#cf90b0'],
    [0.8745098039215686, '#d090af'],
    [0.8784313725490196, '#d190ae'],
    [0.8823529411764706, '#d28fac'],
    [0.8862745098039215, '#d28fab'],
    [0.8901960784313725, '#d38faa'],
    [0.8941176470588235, '#d48fa8'],
    [0.8980392156862745, '#d48fa7'],
    [0.9019607843137255, '#d58fa6'],
    [0.9058823529411765, '#d58ea4'],
    [0.9098039215686274, '#d68ea3'],
    [0.9137254901960784, '#d68ea2'],
    [0.9176470588235294, '#d78ea0'],
    [0.9215686274509803, '#d78e9f'],
    [0.9254901960784314, '#d88e9e'],
    [0.9294117647058824, '#d88e9c'],
    [0.9333333333333333, '#d98e9b'],
    [0.9372549019607843, '#d98e9a'],
    [0.9411764705882353, '#d98e98'],
    [0.9450980392156862, '#d98e97'],
    [0.9490196078431372, '#da8e96'],
    [0.9529411764705882, '#da8e94'],
    [0.9568627450980391, '#da8e93'],
    [0.9607843137254902, '#da8e92'],
    [0.9647058823529412, '#da8e91'],
    [0.9686274509803922, '#da8e8f'],
    [0.9725490196078431, '#da8e8e'],
    [0.9764705882352941, '#da8f8d'],
    [0.9803921568627451, '#da8f8b'],
    [0.984313725490196, '#da8f8a'],
    [0.9882352941176471, '#da8f89'],
    [0.9921568627450981, '#da8f88'],
    [0.996078431372549, '#d99087'],
    [1.0, '#d99085'],
  ],
  CET_C10s: [
    [0.0, '#ab9ad2'],
    [0.00392156862745098, '#ad99d1'],
    [0.00784313725490196, '#af99d0'],
    [0.011764705882352941, '#b099cf'],
    [0.01568627450980392, '#b298ce'],
    [0.0196078431372549, '#b398cd'],
    [0.023529411764705882, '#b597cc'],
    [0.027450980392156862, '#b697cb'],
    [0.03137254901960784, '#b896ca'],
    [0.03529411764705882, '#b996c9'],
    [0.0392156862745098, '#bb96c8'],
    [0.043137254901960784, '#bc95c7'],
    [0.047058823529411764, '#bd95c6'],
    [0.050980392156862744, '#bf94c5'],
    [0.054901960784313725, '#c094c4'],
    [0.058823529411764705, '#c194c3'],
    [0.06274509803921569, '#c293c2'],
    [0.06666666666666667, '#c393c0'],
    [0.07058823529411765, '#c493bf'],
    [0.07450980392156863, '#c693be'],
    [0.0784313725490196, '#c792bd'],
    [0.08235294117647059, '#c892bc'],
    [0.08627450980392157, '#c992ba'],
    [0.09019607843137255, '#ca91b9'],
    [0.09411764705882353, '#ca91b8'],
    [0.09803921568627451, '#cb91b7'],
    [0.10196078431372549, '#cc91b5'],
    [0.10588235294117647, '#cd91b4'],
    [0.10980392156862745, '#ce90b3'],
    [0.11372549019607843, '#cf90b1'],
    [0.11764705882352941, '#cf90b0'],
    [0.12156862745098039, '#d090af'],
    [0.12549019607843137, '#d190ae'],
    [0.12941176470588234, '#d28fac'],
    [0.13333333333333333, '#d28fab'],
    [0.13725490196078433, '#d38faa'],
    [0.1411764705882353, '#d48fa8'],
    [0.14509803921568626, '#d48fa7'],
    [0.14901960784313725, '#d58fa6'],
    [0.15294117647058825, '#d58ea4'],
    [0.1568627450980392, '#d68ea3'],
    [0.16078431372549018, '#d68ea2'],
    [0.16470588235294117, '#d78ea0'],
    [0.16862745098039217, '#d78e9f'],
    [0.17254901960784313, '#d88e9e'],
    [0.1764705882352941, '#d88e9c'],
    [0.1803921568627451, '#d98e9b'],
    [0.1843137254901961, '#d98e9a'],
    [0.18823529411764706, '#d98e98'],
    [0.19215686274509802, '#d98e97'],
    [0.19607843137254902, '#da8e96'],
    [0.2, '#da8e94'],
    [0.20392156862745098, '#da8e93'],
    [0.20784313725490194, '#da8e92'],
    [0.21176470588235294, '#da8e91'],
    [0.21568627450980393, '#da8e8f'],
    [0.2196078431372549, '#da8e8e'],
    [0.22352941176470587, '#da8f8d'],
    [0.22745098039215686, '#da8f8b'],
    [0.23137254901960785, '#da8f8a'],
    [0.23529411764705882, '#da8f89'],
    [0.2392156862745098, '#da8f88'],
    [0.24313725490196078, '#d99087'],
    [0.24705882352941178, '#d99085'],
    [0.25098039215686274, '#d99084'],
    [0.2549019607843137, '#d99083'],
    [0.2588235294117647, '#d89182'],
    [0.2627450980392157, '#d89181'],
    [0.26666666666666666, '#d79180'],
    [0.27058823529411763, '#d7927f'],
    [0.27450980392156865, '#d6927e'],
    [0.2784313725490196, '#d5927d'],
    [0.2823529411764706, '#d5937c'],
    [0.28627450980392155, '#d4937b'],
    [0.2901960784313725, '#d3947a'],
    [0.29411764705882354, '#d3947a'],
    [0.2980392156862745, '#d29479'],
    [0.30196078431372547, '#d19578'],
    [0.3058823529411765, '#d09577'],
    [0.30980392156862746, '#cf9677'],
    [0.3137254901960784, '#cf9676'],
    [0.3176470588235294, '#ce9775'],
    [0.32156862745098036, '#cd9775'],
    [0.3254901960784314, '#cc9874'],
    [0.32941176470588235, '#cb9873'],
    [0.3333333333333333, '#ca9873'],
    [0.33725490196078434, '#c99972'],
    [0.3411764705882353, '#c89972'],
    [0.34509803921568627, '#c79a71'],
    [0.34901960784313724, '#c69a71'],
    [0.3529411764705882, '#c59b71'],
    [0.3568627450980392, '#c49b70'],
    [0.3607843137254902, '#c39c70'],
    [0.36470588235294116, '#c29c6f'],
    [0.3686274509803922, '#c09d6f'],
    [0.37254901960784315, '#c09d6f'],
    [0.3764705882352941, '#be9d6e'],
    [0.3803921568627451, '#bd9e6e'],
    [0.38431372549019605, '#bc9e6e'],
    [0.38823529411764707, '#bb9f6e'],
    [0.39215686274509803, '#ba9f6d'],
    [0.396078431372549, '#b9a06d'],
    [0.4, '#b7a06d'],
    [0.403921568627451, '#b6a16d'],
    [0.40784313725490196, '#b5a16d'],
    [0.4117647058823529, '#b4a16c'],
    [0.4156862745098039, '#b2a26c'],
    [0.4196078431372549, '#b1a26c'],
    [0.4235294117647059, '#b0a36c'],
    [0.42745098039215684, '#afa36c'],
    [0.43137254901960786, '#ada36c'],
    [0.43529411764705883, '#aca46c'],
    [0.4392156862745098, '#aba46c'],
    [0.44313725490196076, '#a9a56c'],
    [0.44705882352941173, '#a8a56c'],
    [0.45098039215686275, '#a7a66d'],
    [0.4549019607843137, '#a5a66d'],
    [0.4588235294117647, '#a4a66d'],
    [0.4627450980392157, '#a3a76d'],
    [0.4666666666666667, '#a1a76e'],
    [0.47058823529411764, '#a0a76e'],
    [0.4745098039215686, '#9ea86f'],
    [0.4784313725490196, '#9da86f'],
    [0.4823529411764706, '#9ba970'],
    [0.48627450980392156, '#9aa970'],
    [0.49019607843137253, '#98a971'],
    [0.49411764705882355, '#97aa71'],
    [0.4980392156862745, '#95aa72'],
    [0.5019607843137255, '#94aa73'],
    [0.5058823529411764, '#93ab73'],
    [0.5098039215686274, '#91ab74'],
    [0.5137254901960784, '#90ab75'],
    [0.5176470588235293, '#8eac76'],
    [0.5215686274509804, '#8dac77'],
    [0.5254901960784314, '#8bac78'],
    [0.5294117647058824, '#8aac79'],
    [0.5333333333333333, '#88ad7a'],
    [0.5372549019607843, '#87ad7b'],
    [0.5411764705882353, '#85ad7c'],
    [0.5450980392156862, '#84ad7d'],
    [0.5490196078431373, '#82ae7e'],
    [0.5529411764705883, '#81ae7f'],
    [0.5568627450980392, '#80ae81'],
    [0.5607843137254902, '#7eae82'],
    [0.5647058823529412, '#7dae83'],
    [0.5686274509803921, '#7baf84'],
    [0.5725490196078431, '#7aaf85'],
    [0.5764705882352941, '#79af87'],
    [0.580392156862745, '#77af88'],
    [0.5843137254901961, '#76af89'],
    [0.5882352941176471, '#74af8a'],
    [0.592156862745098, '#73b08c'],
    [0.596078431372549, '#72b08d'],
    [0.6, '#70b08e'],
    [0.6039215686274509, '#6fb090'],
    [0.6078431372549019, '#6db091'],
    [0.611764705882353, '#6cb092'],
    [0.615686274509804, '#6bb093'],
    [0.6196078431372549, '#69b095'],
    [0.6235294117647059, '#68b196'],
    [0.6274509803921569, '#66b197'],
    [0.6313725490196078, '#65b199'],
    [0.6352941176470588, '#63b19a'],
    [0.6392156862745098, '#62b19b'],
    [0.6431372549019607, '#61b19d'],
    [0.6470588235294118, '#5fb19e'],
    [0.6509803921568628, '#5eb19f'],
    [0.6549019607843137, '#5cb1a1'],
    [0.6588235294117647, '#5bb1a2'],
    [0.6627450980392157, '#59b1a3'],
    [0.6666666666666666, '#58b1a5'],
    [0.6705882352941176, '#56b2a6'],
    [0.6745098039215687, '#55b2a7'],
    [0.6784313725490196, '#53b2a9'],
    [0.6823529411764706, '#52b2aa'],
    [0.6862745098039216, '#50b2ac'],
    [0.6901960784313725, '#4fb2ad'],
    [0.6941176470588235, '#4db2ae'],
    [0.6980392156862745, '#4cb2b0'],
    [0.7019607843137254, '#4bb2b1'],
    [0.7058823529411764, '#49b2b2'],
    [0.7098039215686275, '#48b2b4'],
    [0.7137254901960784, '#47b2b5'],
    [0.7176470588235294, '#46b2b6'],
    [0.7215686274509804, '#45b2b7'],
    [0.7254901960784313, '#44b2b9'],
    [0.7294117647058823, '#43b2ba'],
    [0.7333333333333333, '#43b1bb'],
    [0.7372549019607844, '#42b1bc'],
    [0.7411764705882353, '#42b1be'],
    [0.7450980392156863, '#41b1bf'],
    [0.7490196078431373, '#41b1c0'],
    [0.7529411764705882, '#41b1c1'],
    [0.7568627450980392, '#41b1c2'],
    [0.7607843137254902, '#42b1c3'],
    [0.7647058823529411, '#42b0c4'],
    [0.7686274509803921, '#43b0c6'],
    [0.7725490196078432, '#43b0c7'],
    [0.7764705882352941, '#44b0c7'],
    [0.7803921568627451, '#45b0c8'],
    [0.7843137254901961, '#46afc9'],
    [0.788235294117647, '#48afca'],
    [0.792156862745098, '#49afcb'],
    [0.796078431372549, '#4aafcc'],
    [0.8, '#4cafcd'],
    [0.803921568627451, '#4daecd'],
    [0.807843137254902, '#4faece'],
    [0.8117647058823529, '#51aecf'],
    [0.8156862745098039, '#52adcf'],
    [0.8196078431372549, '#54add0'],
    [0.8235294117647058, '#56add1'],
    [0.8274509803921568, '#58add1'],
    [0.8313725490196078, '#5aacd2'],
    [0.8352941176470589, '#5cacd2'],
    [0.8392156862745098, '#5dacd3'],
    [0.8431372549019608, '#5fabd3'],
    [0.8470588235294118, '#61abd4'],
    [0.8509803921568627, '#63abd4'],
    [0.8549019607843137, '#65aad4'],
    [0.8588235294117647, '#67aad5'],
    [0.8627450980392157, '#69aad5'],
    [0.8666666666666667, '#6ba9d6'],
    [0.8705882352941177, '#6da9d6'],
    [0.8745098039215686, '#6fa8d6'],
    [0.8784313725490196, '#71a8d6'],
    [0.8823529411764706, '#72a8d7'],
    [0.8862745098039215, '#74a7d7'],
    [0.8901960784313725, '#76a7d7'],
    [0.8941176470588235, '#78a7d7'],
    [0.8980392156862745, '#7aa6d8'],
    [0.9019607843137255, '#7ca6d8'],
    [0.9058823529411765, '#7ea5d8'],
    [0.9098039215686274, '#80a5d8'],
    [0.9137254901960784, '#81a4d8'],
    [0.9176470588235294, '#83a4d8'],
    [0.9215686274509803, '#85a4d8'],
    [0.9254901960784314, '#87a3d8'],
    [0.9294117647058824, '#89a3d8'],
    [0.9333333333333333, '#8ba2d8'],
    [0.9372549019607843, '#8da2d8'],
    [0.9411764705882353, '#8ea1d8'],
    [0.9450980392156862, '#90a1d8'],
    [0.9490196078431372, '#92a1d8'],
    [0.9529411764705882, '#94a0d8'],
    [0.9568627450980391, '#96a0d7'],
    [0.9607843137254902, '#989fd7'],
    [0.9647058823529412, '#999fd7'],
    [0.9686274509803922, '#9b9ed7'],
    [0.9725490196078431, '#9d9ed6'],
    [0.9764705882352941, '#9f9dd6'],
    [0.9803921568627451, '#a19dd5'],
    [0.984313725490196, '#a39cd5'],
    [0.9882352941176471, '#a49cd4'],
    [0.9921568627450981, '#a69bd4'],
    [0.996078431372549, '#a89bd3'],
    [1.0, '#aa9ad2'],
  ],
  CET_C11: [
    [0.0, '#4730f1'],
    [0.00392156862745098, '#4732ef'],
    [0.00784313725490196, '#4734ee'],
    [0.011764705882352941, '#4836ec'],
    [0.01568627450980392, '#4938ea'],
    [0.0196078431372549, '#4b3ae8'],
    [0.023529411764705882, '#4c3ce6'],
    [0.027450980392156862, '#4e3fe3'],
    [0.03137254901960784, '#5041e1'],
    [0.03529411764705882, '#5244de'],
    [0.0392156862745098, '#5446dc'],
    [0.043137254901960784, '#5649d9'],
    [0.047058823529411764, '#584bd6'],
    [0.050980392156862744, '#5a4ed4'],
    [0.054901960784313725, '#5c51d1'],
    [0.058823529411764705, '#5e53ce'],
    [0.06274509803921569, '#5f56cb'],
    [0.06666666666666667, '#6158c8'],
    [0.07058823529411765, '#625bc6'],
    [0.07450980392156863, '#635dc3'],
    [0.0784313725490196, '#6560c0'],
    [0.08235294117647059, '#6662bd'],
    [0.08627450980392157, '#6765ba'],
    [0.09019607843137255, '#6767b7'],
    [0.09411764705882353, '#686ab5'],
    [0.09803921568627451, '#696cb2'],
    [0.10196078431372549, '#696eaf'],
    [0.10588235294117647, '#6a71ac'],
    [0.10980392156862745, '#6a73a9'],
    [0.11372549019607843, '#6a75a6'],
    [0.11764705882352941, '#6a78a4'],
    [0.12156862745098039, '#6a7aa1'],
    [0.12549019607843137, '#6a7c9e'],
    [0.12941176470588234, '#6a7f9b'],
    [0.13333333333333333, '#6a8198'],
    [0.13725490196078433, '#6a8395'],
    [0.1411764705882353, '#698592'],
    [0.14509803921568626, '#69888f'],
    [0.14901960784313725, '#698a8c'],
    [0.15294117647058825, '#688c89'],
    [0.1568627450980392, '#678e86'],
    [0.16078431372549018, '#669083'],
    [0.16470588235294117, '#659380'],
    [0.16862745098039217, '#64957d'],
    [0.17254901960784313, '#63977a'],
    [0.1764705882352941, '#629977'],
    [0.1803921568627451, '#619b74'],
    [0.1843137254901961, '#5f9e71'],
    [0.18823529411764706, '#5ea06d'],
    [0.19215686274509802, '#5ca26a'],
    [0.19607843137254902, '#5aa467'],
    [0.2, '#59a663'],
    [0.20392156862745098, '#57a860'],
    [0.20784313725490194, '#55aa5c'],
    [0.21176470588235294, '#53ac59'],
    [0.21568627450980393, '#51ae55'],
    [0.2196078431372549, '#4fb052'],
    [0.22352941176470587, '#4db14e'],
    [0.22745098039215686, '#4bb34a'],
    [0.23137254901960785, '#4ab447'],
    [0.23529411764705882, '#48b543'],
    [0.2392156862745098, '#47b63f'],
    [0.24313725490196078, '#47b73b'],
    [0.24705882352941178, '#47b738'],
    [0.25098039215686274, '#48b734'],
    [0.2549019607843137, '#49b731'],
    [0.2588235294117647, '#4ab72d'],
    [0.2627450980392157, '#4cb62a'],
    [0.26666666666666666, '#4eb627'],
    [0.27058823529411763, '#51b524'],
    [0.27450980392156865, '#54b322'],
    [0.2784313725490196, '#57b21f'],
    [0.2823529411764706, '#5ab01d'],
    [0.28627450980392155, '#5daf1b'],
    [0.2901960784313725, '#60ad19'],
    [0.29411764705882354, '#63ab18'],
    [0.2980392156862745, '#66a916'],
    [0.30196078431372547, '#69a715'],
    [0.3058823529411765, '#6ca514'],
    [0.30980392156862746, '#6fa312'],
    [0.3137254901960784, '#72a111'],
    [0.3176470588235294, '#749f10'],
    [0.32156862745098036, '#779c10'],
    [0.3254901960784314, '#799a0f'],
    [0.32941176470588235, '#7b980e'],
    [0.3333333333333333, '#7e960d'],
    [0.33725490196078434, '#80930c'],
    [0.3411764705882353, '#82910b'],
    [0.34509803921568627, '#848f0a'],
    [0.34901960784313724, '#868c09'],
    [0.3529411764705882, '#888a08'],
    [0.3568627450980392, '#898808'],
    [0.3607843137254902, '#8b8507'],
    [0.36470588235294116, '#8d8306'],
    [0.3686274509803922, '#8e8005'],
    [0.37254901960784315, '#907e05'],
    [0.3764705882352941, '#917c04'],
    [0.3803921568627451, '#937904'],
    [0.38431372549019605, '#947703'],
    [0.38823529411764707, '#967403'],
    [0.39215686274509803, '#977202'],
    [0.396078431372549, '#986f02'],
    [0.4, '#996c01'],
    [0.403921568627451, '#9b6a01'],
    [0.40784313725490196, '#9c6700'],
    [0.4117647058823529, '#9d6400'],
    [0.4156862745098039, '#9e6200'],
    [0.4196078431372549, '#9f5f00'],
    [0.4235294117647059, '#a05c00'],
    [0.42745098039215684, '#a15900'],
    [0.43137254901960786, '#a25600'],
    [0.43529411764705883, '#a35300'],
    [0.4392156862745098, '#a45000'],
    [0.44313725490196076, '#a54d00'],
    [0.44705882352941173, '#a64a00'],
    [0.45098039215686275, '#a74700'],
    [0.4549019607843137, '#a84300'],
    [0.4588235294117647, '#a84000'],
    [0.4627450980392157, '#a93c01'],
    [0.4666666666666667, '#aa3901'],
    [0.47058823529411764, '#ab3502'],
    [0.4745098039215686, '#ac3103'],
    [0.4784313725490196, '#ad2d04'],
    [0.4823529411764706, '#ae2a06'],
    [0.48627450980392156, '#af2608'],
    [0.49019607843137253, '#b0210a'],
    [0.49411764705882355, '#b11d0c'],
    [0.4980392156862745, '#b3190e'],
    [0.5019607843137255, '#b41511'],
    [0.5058823529411764, '#b51113'],
    [0.5098039215686274, '#b70c16'],
    [0.5137254901960784, '#b80819'],
    [0.5176470588235293, '#ba051c'],
    [0.5215686274509804, '#bb021f'],
    [0.5254901960784314, '#bd0122'],
    [0.5294117647058824, '#be0025'],
    [0.5333333333333333, '#c00128'],
    [0.5372549019607843, '#c2022b'],
    [0.5411764705882353, '#c3032e'],
    [0.5450980392156862, '#c50532'],
    [0.5490196078431373, '#c70835'],
    [0.5529411764705883, '#c80b39'],
    [0.5568627450980392, '#ca0e3c'],
    [0.5607843137254902, '#cb1140'],
    [0.5647058823529412, '#cd1443'],
    [0.5686274509803921, '#cf1647'],
    [0.5725490196078431, '#d0194a'],
    [0.5764705882352941, '#d21b4e'],
    [0.580392156862745, '#d31e51'],
    [0.5843137254901961, '#d52055'],
    [0.5882352941176471, '#d62259'],
    [0.592156862745098, '#d8245c'],
    [0.596078431372549, '#d92660'],
    [0.6, '#db2864'],
    [0.6039215686274509, '#dc2a67'],
    [0.6078431372549019, '#dd2c6b'],
    [0.611764705882353, '#df2e6f'],
    [0.615686274509804, '#e03072'],
    [0.6196078431372549, '#e23276'],
    [0.6235294117647059, '#e3347a'],
    [0.6274509803921569, '#e4367e'],
    [0.6313725490196078, '#e63781'],
    [0.6352941176470588, '#e73985'],
    [0.6392156862745098, '#e83b89'],
    [0.6431372549019607, '#e93d8d'],
    [0.6470588235294118, '#eb3e91'],
    [0.6509803921568628, '#ec4094'],
    [0.6549019607843137, '#ed4298'],
    [0.6588235294117647, '#ee449c'],
    [0.6627450980392157, '#ef45a0'],
    [0.6666666666666666, '#f147a4'],
    [0.6705882352941176, '#f249a8'],
    [0.6745098039215687, '#f34aac'],
    [0.6784313725490196, '#f44cb0'],
    [0.6823529411764706, '#f54eb4'],
    [0.6862745098039216, '#f64fb8'],
    [0.6901960784313725, '#f751bc'],
    [0.6941176470588235, '#f853c0'],
    [0.6980392156862745, '#f954c4'],
    [0.7019607843137254, '#fa56c8'],
    [0.7058823529411764, '#fa57cc'],
    [0.7098039215686275, '#fb59d0'],
    [0.7137254901960784, '#fb5ad3'],
    [0.7176470588235294, '#fc5cd7'],
    [0.7215686274509804, '#fc5ddb'],
    [0.7254901960784313, '#fc5edf'],
    [0.7294117647058823, '#fc5fe2'],
    [0.7333333333333333, '#fc60e5'],
    [0.7372549019607844, '#fc61e9'],
    [0.7411764705882353, '#fb61ec'],
    [0.7450980392156863, '#fb62ee'],
    [0.7490196078431373, '#fa62f1'],
    [0.7529411764705882, '#f962f3'],
    [0.7568627450980392, '#f762f5'],
    [0.7607843137254902, '#f662f7'],
    [0.7647058823529411, '#f462f8'],
    [0.7686274509803921, '#f261fa'],
    [0.7725490196078432, '#f061fb'],
    [0.7764705882352941, '#ee60fc'],
    [0.7803921568627451, '#ec5ffc'],
    [0.7843137254901961, '#ea5efd'],
    [0.788235294117647, '#e75dfd'],
    [0.792156862745098, '#e55cfe'],
    [0.796078431372549, '#e35bfe'],
    [0.8, '#e05afe'],
    [0.803921568627451, '#de59fe'],
    [0.807843137254902, '#db58fe'],
    [0.8117647058823529, '#d956fe'],
    [0.8156862745098039, '#d655fe'],
    [0.8196078431372549, '#d354fe'],
    [0.8235294117647058, '#d153fe'],
    [0.8274509803921568, '#ce52fe'],
    [0.8313725490196078, '#cc50fe'],
    [0.8352941176470589, '#c94ffe'],
    [0.8392156862745098, '#c64efd'],
    [0.8431372549019608, '#c44dfd'],
    [0.8470588235294118, '#c14cfd'],
    [0.8509803921568627, '#be4afd'],
    [0.8549019607843137, '#bb49fd'],
    [0.8588235294117647, '#b948fd'],
    [0.8627450980392157, '#b647fd'],
    [0.8666666666666667, '#b346fd'],
    [0.8705882352941177, '#b045fd'],
    [0.8745098039215686, '#ad43fd'],
    [0.8784313725490196, '#aa42fc'],
    [0.8823529411764706, '#a841fc'],
    [0.8862745098039215, '#a540fc'],
    [0.8901960784313725, '#a23ffc'],
    [0.8941176470588235, '#9f3efc'],
    [0.8980392156862745, '#9c3dfc'],
    [0.9019607843137255, '#993cfc'],
    [0.9058823529411765, '#953afc'],
    [0.9098039215686274, '#9239fc'],
    [0.9137254901960784, '#8f38fb'],
    [0.9176470588235294, '#8c37fb'],
    [0.9215686274509803, '#8936fb'],
    [0.9254901960784314, '#8535fb'],
    [0.9294117647058824, '#8234fb'],
    [0.9333333333333333, '#7e33fb'],
    [0.9372549019607843, '#7b32fb'],
    [0.9411764705882353, '#7731fa'],
    [0.9450980392156862, '#7330fa'],
    [0.9490196078431372, '#702ffa'],
    [0.9529411764705882, '#6c2efa'],
    [0.9568627450980391, '#682efa'],
    [0.9607843137254902, '#642df9'],
    [0.9647058823529412, '#612cf9'],
    [0.9686274509803922, '#5d2cf9'],
    [0.9725490196078431, '#592cf8'],
    [0.9764705882352941, '#562cf8'],
    [0.9803921568627451, '#532cf7'],
    [0.984313725490196, '#502cf7'],
    [0.9882352941176471, '#4d2cf6'],
    [0.9921568627450981, '#4b2df5'],
    [0.996078431372549, '#492ef4'],
    [1.0, '#482ff2'],
  ],
  CET_C11s: [
    [0.0, '#f962f3'],
    [0.00392156862745098, '#f762f5'],
    [0.00784313725490196, '#f662f7'],
    [0.011764705882352941, '#f462f8'],
    [0.01568627450980392, '#f261fa'],
    [0.0196078431372549, '#f061fb'],
    [0.023529411764705882, '#ee60fc'],
    [0.027450980392156862, '#ec5ffc'],
    [0.03137254901960784, '#ea5efd'],
    [0.03529411764705882, '#e75dfd'],
    [0.0392156862745098, '#e55cfe'],
    [0.043137254901960784, '#e35bfe'],
    [0.047058823529411764, '#e05afe'],
    [0.050980392156862744, '#de59fe'],
    [0.054901960784313725, '#db58fe'],
    [0.058823529411764705, '#d956fe'],
    [0.06274509803921569, '#d655fe'],
    [0.06666666666666667, '#d354fe'],
    [0.07058823529411765, '#d153fe'],
    [0.07450980392156863, '#ce52fe'],
    [0.0784313725490196, '#cc50fe'],
    [0.08235294117647059, '#c94ffe'],
    [0.08627450980392157, '#c64efd'],
    [0.09019607843137255, '#c44dfd'],
    [0.09411764705882353, '#c14cfd'],
    [0.09803921568627451, '#be4afd'],
    [0.10196078431372549, '#bb49fd'],
    [0.10588235294117647, '#b948fd'],
    [0.10980392156862745, '#b647fd'],
    [0.11372549019607843, '#b346fd'],
    [0.11764705882352941, '#b045fd'],
    [0.12156862745098039, '#ad43fd'],
    [0.12549019607843137, '#aa42fc'],
    [0.12941176470588234, '#a841fc'],
    [0.13333333333333333, '#a540fc'],
    [0.13725490196078433, '#a23ffc'],
    [0.1411764705882353, '#9f3efc'],
    [0.14509803921568626, '#9c3dfc'],
    [0.14901960784313725, '#993cfc'],
    [0.15294117647058825, '#953afc'],
    [0.1568627450980392, '#9239fc'],
    [0.16078431372549018, '#8f38fb'],
    [0.16470588235294117, '#8c37fb'],
    [0.16862745098039217, '#8936fb'],
    [0.17254901960784313, '#8535fb'],
    [0.1764705882352941, '#8234fb'],
    [0.1803921568627451, '#7e33fb'],
    [0.1843137254901961, '#7b32fb'],
    [0.18823529411764706, '#7731fa'],
    [0.19215686274509802, '#7330fa'],
    [0.19607843137254902, '#702ffa'],
    [0.2, '#6c2efa'],
    [0.20392156862745098, '#682efa'],
    [0.20784313725490194, '#642df9'],
    [0.21176470588235294, '#612cf9'],
    [0.21568627450980393, '#5d2cf9'],
    [0.2196078431372549, '#592cf8'],
    [0.22352941176470587, '#562cf8'],
    [0.22745098039215686, '#532cf7'],
    [0.23137254901960785, '#502cf7'],
    [0.23529411764705882, '#4d2cf6'],
    [0.2392156862745098, '#4b2df5'],
    [0.24313725490196078, '#492ef4'],
    [0.24705882352941178, '#482ff2'],
    [0.25098039215686274, '#4730f1'],
    [0.2549019607843137, '#4732ef'],
    [0.2588235294117647, '#4734ee'],
    [0.2627450980392157, '#4836ec'],
    [0.26666666666666666, '#4938ea'],
    [0.27058823529411763, '#4b3ae8'],
    [0.27450980392156865, '#4c3ce6'],
    [0.2784313725490196, '#4e3fe3'],
    [0.2823529411764706, '#5041e1'],
    [0.28627450980392155, '#5244de'],
    [0.2901960784313725, '#5446dc'],
    [0.29411764705882354, '#5649d9'],
    [0.2980392156862745, '#584bd6'],
    [0.30196078431372547, '#5a4ed4'],
    [0.3058823529411765, '#5c51d1'],
    [0.30980392156862746, '#5e53ce'],
    [0.3137254901960784, '#5f56cb'],
    [0.3176470588235294, '#6158c8'],
    [0.32156862745098036, '#625bc6'],
    [0.3254901960784314, '#635dc3'],
    [0.32941176470588235, '#6560c0'],
    [0.3333333333333333, '#6662bd'],
    [0.33725490196078434, '#6765ba'],
    [0.3411764705882353, '#6767b7'],
    [0.34509803921568627, '#686ab5'],
    [0.34901960784313724, '#696cb2'],
    [0.3529411764705882, '#696eaf'],
    [0.3568627450980392, '#6a71ac'],
    [0.3607843137254902, '#6a73a9'],
    [0.36470588235294116, '#6a75a6'],
    [0.3686274509803922, '#6a78a4'],
    [0.37254901960784315, '#6a7aa1'],
    [0.3764705882352941, '#6a7c9e'],
    [0.3803921568627451, '#6a7f9b'],
    [0.38431372549019605, '#6a8198'],
    [0.38823529411764707, '#6a8395'],
    [0.39215686274509803, '#698592'],
    [0.396078431372549, '#69888f'],
    [0.4, '#698a8c'],
    [0.403921568627451, '#688c89'],
    [0.40784313725490196, '#678e86'],
    [0.4117647058823529, '#669083'],
    [0.4156862745098039, '#659380'],
    [0.4196078431372549, '#64957d'],
    [0.4235294117647059, '#63977a'],
    [0.42745098039215684, '#629977'],
    [0.43137254901960786, '#619b74'],
    [0.43529411764705883, '#5f9e71'],
    [0.4392156862745098, '#5ea06d'],
    [0.44313725490196076, '#5ca26a'],
    [0.44705882352941173, '#5aa467'],
    [0.45098039215686275, '#59a663'],
    [0.4549019607843137, '#57a860'],
    [0.4588235294117647, '#55aa5c'],
    [0.4627450980392157, '#53ac59'],
    [0.4666666666666667, '#51ae55'],
    [0.47058823529411764, '#4fb052'],
    [0.4745098039215686, '#4db14e'],
    [0.4784313725490196, '#4bb34a'],
    [0.4823529411764706, '#4ab447'],
    [0.48627450980392156, '#48b543'],
    [0.49019607843137253, '#47b63f'],
    [0.49411764705882355, '#47b73b'],
    [0.4980392156862745, '#47b738'],
    [0.5019607843137255, '#48b734'],
    [0.5058823529411764, '#49b731'],
    [0.5098039215686274, '#4ab72d'],
    [0.5137254901960784, '#4cb62a'],
    [0.5176470588235293, '#4eb627'],
    [0.5215686274509804, '#51b524'],
    [0.5254901960784314, '#54b322'],
    [0.5294117647058824, '#57b21f'],
    [0.5333333333333333, '#5ab01d'],
    [0.5372549019607843, '#5daf1b'],
    [0.5411764705882353, '#60ad19'],
    [0.5450980392156862, '#63ab18'],
    [0.5490196078431373, '#66a916'],
    [0.5529411764705883, '#69a715'],
    [0.5568627450980392, '#6ca514'],
    [0.5607843137254902, '#6fa312'],
    [0.5647058823529412, '#72a111'],
    [0.5686274509803921, '#749f10'],
    [0.5725490196078431, '#779c10'],
    [0.5764705882352941, '#799a0f'],
    [0.580392156862745, '#7b980e'],
    [0.5843137254901961, '#7e960d'],
    [0.5882352941176471, '#80930c'],
    [0.592156862745098, '#82910b'],
    [0.596078431372549, '#848f0a'],
    [0.6, '#868c09'],
    [0.6039215686274509, '#888a08'],
    [0.6078431372549019, '#898808'],
    [0.611764705882353, '#8b8507'],
    [0.615686274509804, '#8d8306'],
    [0.6196078431372549, '#8e8005'],
    [0.6235294117647059, '#907e05'],
    [0.6274509803921569, '#917c04'],
    [0.6313725490196078, '#937904'],
    [0.6352941176470588, '#947703'],
    [0.6392156862745098, '#967403'],
    [0.6431372549019607, '#977202'],
    [0.6470588235294118, '#986f02'],
    [0.6509803921568628, '#996c01'],
    [0.6549019607843137, '#9b6a01'],
    [0.6588235294117647, '#9c6700'],
    [0.6627450980392157, '#9d6400'],
    [0.6666666666666666, '#9e6200'],
    [0.6705882352941176, '#9f5f00'],
    [0.6745098039215687, '#a05c00'],
    [0.6784313725490196, '#a15900'],
    [0.6823529411764706, '#a25600'],
    [0.6862745098039216, '#a35300'],
    [0.6901960784313725, '#a45000'],
    [0.6941176470588235, '#a54d00'],
    [0.6980392156862745, '#a64a00'],
    [0.7019607843137254, '#a74700'],
    [0.7058823529411764, '#a84300'],
    [0.7098039215686275, '#a84000'],
    [0.7137254901960784, '#a93c01'],
    [0.7176470588235294, '#aa3901'],
    [0.7215686274509804, '#ab3502'],
    [0.7254901960784313, '#ac3103'],
    [0.7294117647058823, '#ad2d04'],
    [0.7333333333333333, '#ae2a06'],
    [0.7372549019607844, '#af2608'],
    [0.7411764705882353, '#b0210a'],
    [0.7450980392156863, '#b11d0c'],
    [0.7490196078431373, '#b3190e'],
    [0.7529411764705882, '#b41511'],
    [0.7568627450980392, '#b51113'],
    [0.7607843137254902, '#b70c16'],
    [0.7647058823529411, '#b80819'],
    [0.7686274509803921, '#ba051c'],
    [0.7725490196078432, '#bb021f'],
    [0.7764705882352941, '#bd0122'],
    [0.7803921568627451, '#be0025'],
    [0.7843137254901961, '#c00128'],
    [0.788235294117647, '#c2022b'],
    [0.792156862745098, '#c3032e'],
    [0.796078431372549, '#c50532'],
    [0.8, '#c70835'],
    [0.803921568627451, '#c80b39'],
    [0.807843137254902, '#ca0e3c'],
    [0.8117647058823529, '#cb1140'],
    [0.8156862745098039, '#cd1443'],
    [0.8196078431372549, '#cf1647'],
    [0.8235294117647058, '#d0194a'],
    [0.8274509803921568, '#d21b4e'],
    [0.8313725490196078, '#d31e51'],
    [0.8352941176470589, '#d52055'],
    [0.8392156862745098, '#d62259'],
    [0.8431372549019608, '#d8245c'],
    [0.8470588235294118, '#d92660'],
    [0.8509803921568627, '#db2864'],
    [0.8549019607843137, '#dc2a67'],
    [0.8588235294117647, '#dd2c6b'],
    [0.8627450980392157, '#df2e6f'],
    [0.8666666666666667, '#e03072'],
    [0.8705882352941177, '#e23276'],
    [0.8745098039215686, '#e3347a'],
    [0.8784313725490196, '#e4367e'],
    [0.8823529411764706, '#e63781'],
    [0.8862745098039215, '#e73985'],
    [0.8901960784313725, '#e83b89'],
    [0.8941176470588235, '#e93d8d'],
    [0.8980392156862745, '#eb3e91'],
    [0.9019607843137255, '#ec4094'],
    [0.9058823529411765, '#ed4298'],
    [0.9098039215686274, '#ee449c'],
    [0.9137254901960784, '#ef45a0'],
    [0.9176470588235294, '#f147a4'],
    [0.9215686274509803, '#f249a8'],
    [0.9254901960784314, '#f34aac'],
    [0.9294117647058824, '#f44cb0'],
    [0.9333333333333333, '#f54eb4'],
    [0.9372549019607843, '#f64fb8'],
    [0.9411764705882353, '#f751bc'],
    [0.9450980392156862, '#f853c0'],
    [0.9490196078431372, '#f954c4'],
    [0.9529411764705882, '#fa56c8'],
    [0.9568627450980391, '#fa57cc'],
    [0.9607843137254902, '#fb59d0'],
    [0.9647058823529412, '#fb5ad3'],
    [0.9686274509803922, '#fc5cd7'],
    [0.9725490196078431, '#fc5ddb'],
    [0.9764705882352941, '#fc5edf'],
    [0.9803921568627451, '#fc5fe2'],
    [0.984313725490196, '#fc60e5'],
    [0.9882352941176471, '#fc61e9'],
    [0.9921568627450981, '#fb61ec'],
    [0.996078431372549, '#fb62ee'],
    [1.0, '#fa62f1'],
  ],
  CET_C1s: [
    [0.0, '#3e3ef0'],
    [0.00392156862745098, '#403df1'],
    [0.00784313725490196, '#433cf3'],
    [0.011764705882352941, '#463cf4'],
    [0.01568627450980392, '#493cf5'],
    [0.0196078431372549, '#4c3df6'],
    [0.023529411764705882, '#4f3df6'],
    [0.027450980392156862, '#523ef7'],
    [0.03137254901960784, '#5540f7'],
    [0.03529411764705882, '#5841f8'],
    [0.0392156862745098, '#5b43f8'],
    [0.043137254901960784, '#5d44f8'],
    [0.047058823529411764, '#6046f9'],
    [0.050980392156862744, '#6348f9'],
    [0.054901960784313725, '#664af9'],
    [0.058823529411764705, '#684cf9'],
    [0.06274509803921569, '#6b4ef9'],
    [0.06666666666666667, '#6d50f9'],
    [0.07058823529411765, '#7052f9'],
    [0.07450980392156863, '#7254f9'],
    [0.0784313725490196, '#7556f9'],
    [0.08235294117647059, '#7758f9'],
    [0.08627450980392157, '#795af9'],
    [0.09019607843137255, '#7c5cf9'],
    [0.09411764705882353, '#7e5ef9'],
    [0.09803921568627451, '#8160f9'],
    [0.10196078431372549, '#8461f9'],
    [0.10588235294117647, '#8663f9'],
    [0.10980392156862745, '#8965f9'],
    [0.11372549019607843, '#8c66fa'],
    [0.11764705882352941, '#8f68fa'],
    [0.12156862745098039, '#926afa'],
    [0.12549019607843137, '#956bfa'],
    [0.12941176470588234, '#996cfa'],
    [0.13333333333333333, '#9c6efa'],
    [0.13725490196078433, '#9f6ffb'],
    [0.1411764705882353, '#a370fb'],
    [0.14509803921568626, '#a671fb'],
    [0.14901960784313725, '#aa73fb'],
    [0.15294117647058825, '#ad74fc'],
    [0.1568627450980392, '#b175fc'],
    [0.16078431372549018, '#b476fc'],
    [0.16470588235294117, '#b877fd'],
    [0.16862745098039217, '#bc78fd'],
    [0.17254901960784313, '#bf79fd'],
    [0.1764705882352941, '#c37afd'],
    [0.1803921568627451, '#c67bfe'],
    [0.1843137254901961, '#ca7cfe'],
    [0.18823529411764706, '#cd7dfe'],
    [0.19215686274509802, '#d17eff'],
    [0.19607843137254902, '#d47fff'],
    [0.2, '#d880ff'],
    [0.20392156862745098, '#db81ff'],
    [0.20784313725490194, '#de82ff'],
    [0.21176470588235294, '#e182ff'],
    [0.21568627450980393, '#e483ff'],
    [0.2196078431372549, '#e784ff'],
    [0.22352941176470587, '#ea84ff'],
    [0.22745098039215686, '#ed85fe'],
    [0.23137254901960785, '#ef85fd'],
    [0.23529411764705882, '#f185fd'],
    [0.2392156862745098, '#f385fc'],
    [0.24313725490196078, '#f585fa'],
    [0.24705882352941178, '#f785f9'],
    [0.25098039215686274, '#f884f7'],
    [0.2549019607843137, '#f983f5'],
    [0.2588235294117647, '#fa82f3'],
    [0.2627450980392157, '#fb81f0'],
    [0.26666666666666666, '#fc80ee'],
    [0.27058823529411763, '#fc7feb'],
    [0.27450980392156865, '#fc7de8'],
    [0.2784313725490196, '#fd7be5'],
    [0.2823529411764706, '#fd7ae1'],
    [0.28627450980392155, '#fc78de'],
    [0.2901960784313725, '#fc76da'],
    [0.29411764705882354, '#fc73d7'],
    [0.2980392156862745, '#fb71d3'],
    [0.30196078431372547, '#fb6fcf'],
    [0.3058823529411765, '#fa6dcc'],
    [0.30980392156862746, '#fa6bc8'],
    [0.3137254901960784, '#f968c4'],
    [0.3176470588235294, '#f866c0'],
    [0.32156862745098036, '#f864bc'],
    [0.3254901960784314, '#f761b9'],
    [0.32941176470588235, '#f65fb5'],
    [0.3333333333333333, '#f55db1'],
    [0.33725490196078434, '#f55aad'],
    [0.3411764705882353, '#f458aa'],
    [0.34509803921568627, '#f355a6'],
    [0.34901960784313724, '#f253a2'],
    [0.3529411764705882, '#f1519e'],
    [0.3568627450980392, '#f04e9b'],
    [0.3607843137254902, '#ef4c97'],
    [0.36470588235294116, '#ee4a93'],
    [0.3686274509803922, '#ec478f'],
    [0.37254901960784315, '#eb458b'],
    [0.3764705882352941, '#ea4388'],
    [0.3803921568627451, '#e94184'],
    [0.38431372549019605, '#e73f80'],
    [0.38823529411764707, '#e63c7c'],
    [0.39215686274509803, '#e43a78'],
    [0.396078431372549, '#e33875'],
    [0.4, '#e13671'],
    [0.403921568627451, '#df346d'],
    [0.40784313725490196, '#de3269'],
    [0.4117647058823529, '#dc3165'],
    [0.4156862745098039, '#da2f61'],
    [0.4196078431372549, '#d82d5e'],
    [0.4235294117647059, '#d62b5a'],
    [0.42745098039215684, '#d42956'],
    [0.43137254901960786, '#d32752'],
    [0.43529411764705883, '#d1264e'],
    [0.4392156862745098, '#cf244b'],
    [0.44313725490196076, '#cd2247'],
    [0.44705882352941173, '#cb2043'],
    [0.45098039215686275, '#c91f3f'],
    [0.4549019607843137, '#c71d3c'],
    [0.4588235294117647, '#c51b38'],
    [0.4627450980392157, '#c31a35'],
    [0.4666666666666667, '#c11931'],
    [0.47058823529411764, '#c0182e'],
    [0.4745098039215686, '#be172a'],
    [0.4784313725490196, '#bc1627'],
    [0.4823529411764706, '#bb1624'],
    [0.48627450980392156, '#b91621'],
    [0.49019607843137253, '#b8171e'],
    [0.49411764705882355, '#b7181b'],
    [0.4980392156862745, '#b61918'],
    [0.5019607843137255, '#b51a15'],
    [0.5058823529411764, '#b51c13'],
    [0.5098039215686274, '#b51e11'],
    [0.5137254901960784, '#b4210f'],
    [0.5176470588235293, '#b4230d'],
    [0.5215686274509804, '#b4260b'],
    [0.5254901960784314, '#b52809'],
    [0.5294117647058824, '#b52b08'],
    [0.5333333333333333, '#b62d07'],
    [0.5372549019607843, '#b63006'],
    [0.5411764705882353, '#b73305'],
    [0.5450980392156862, '#b83505'],
    [0.5490196078431373, '#b93804'],
    [0.5529411764705883, '#ba3b04'],
    [0.5568627450980392, '#bb3d04'],
    [0.5607843137254902, '#bc4004'],
    [0.5647058823529412, '#bd4304'],
    [0.5686274509803921, '#be4504'],
    [0.5725490196078431, '#bf4804'],
    [0.5764705882352941, '#c04a04'],
    [0.580392156862745, '#c14d04'],
    [0.5843137254901961, '#c24f04'],
    [0.5882352941176471, '#c35204'],
    [0.592156862745098, '#c45404'],
    [0.596078431372549, '#c55604'],
    [0.6, '#c65904'],
    [0.6039215686274509, '#c75b04'],
    [0.6078431372549019, '#c75d04'],
    [0.611764705882353, '#c86004'],
    [0.615686274509804, '#c96204'],
    [0.6196078431372549, '#ca6504'],
    [0.6235294117647059, '#cb6704'],
    [0.6274509803921569, '#cc6904'],
    [0.6313725490196078, '#cc6c04'],
    [0.6352941176470588, '#cd6e04'],
    [0.6392156862745098, '#ce7004'],
    [0.6431372549019607, '#ce7304'],
    [0.6470588235294118, '#cf7504'],
    [0.6509803921568628, '#d07804'],
    [0.6549019607843137, '#d07a04'],
    [0.6588235294117647, '#d17c04'],
    [0.6627450980392157, '#d17f04'],
    [0.6666666666666666, '#d28104'],
    [0.6705882352941176, '#d28304'],
    [0.6745098039215687, '#d38604'],
    [0.6784313725490196, '#d38804'],
    [0.6823529411764706, '#d48a04'],
    [0.6862745098039216, '#d48d04'],
    [0.6901960784313725, '#d58f04'],
    [0.6941176470588235, '#d59104'],
    [0.6980392156862745, '#d59304'],
    [0.7019607843137254, '#d69605'],
    [0.7058823529411764, '#d69805'],
    [0.7098039215686275, '#d69a06'],
    [0.7137254901960784, '#d69c08'],
    [0.7176470588235294, '#d69e0a'],
    [0.7215686274509804, '#d6a00c'],
    [0.7254901960784313, '#d6a20f'],
    [0.7294117647058823, '#d5a311'],
    [0.7333333333333333, '#d5a515'],
    [0.7372549019607844, '#d4a618'],
    [0.7411764705882353, '#d3a71b'],
    [0.7450980392156863, '#d2a81f'],
    [0.7490196078431373, '#d1a923'],
    [0.7529411764705882, '#cfaa27'],
    [0.7568627450980392, '#cdaa2a'],
    [0.7607843137254902, '#cbaa2e'],
    [0.7647058823529411, '#c9aa32'],
    [0.7686274509803921, '#c7aa36'],
    [0.7725490196078432, '#c4a93a'],
    [0.7764705882352941, '#c2a93e'],
    [0.7803921568627451, '#bfa842'],
    [0.7843137254901961, '#bca746'],
    [0.788235294117647, '#b9a64a'],
    [0.792156862745098, '#b6a54d'],
    [0.796078431372549, '#b2a451'],
    [0.8, '#afa355'],
    [0.803921568627451, '#aca258'],
    [0.807843137254902, '#a8a15c'],
    [0.8117647058823529, '#a4a05f'],
    [0.8156862745098039, '#a19f62'],
    [0.8196078431372549, '#9d9d66'],
    [0.8235294117647058, '#999c69'],
    [0.8274509803921568, '#959b6c'],
    [0.8313725490196078, '#919a6f'],
    [0.8352941176470589, '#8d9872'],
    [0.8392156862745098, '#899775'],
    [0.8431372549019608, '#859679'],
    [0.8470588235294118, '#80957c'],
    [0.8509803921568627, '#7c937f'],
    [0.8549019607843137, '#779282'],
    [0.8588235294117647, '#729185'],
    [0.8627450980392157, '#6d9087'],
    [0.8666666666666667, '#688e8a'],
    [0.8705882352941177, '#638d8d'],
    [0.8745098039215686, '#5e8c90'],
    [0.8784313725490196, '#588a93'],
    [0.8823529411764706, '#538996'],
    [0.8862745098039215, '#4d8799'],
    [0.8901960784313725, '#48869c'],
    [0.8941176470588235, '#43849f'],
    [0.8980392156862745, '#3d82a2'],
    [0.9019607843137255, '#3880a5'],
    [0.9058823529411765, '#337ea8'],
    [0.9098039215686274, '#2f7cab'],
    [0.9137254901960784, '#2b7aae'],
    [0.9176470588235294, '#2878b1'],
    [0.9215686274509803, '#2576b4'],
    [0.9254901960784314, '#2373b8'],
    [0.9294117647058824, '#2271bb'],
    [0.9333333333333333, '#216ebe'],
    [0.9372549019607843, '#216cc1'],
    [0.9411764705882353, '#2269c4'],
    [0.9450980392156862, '#2366c7'],
    [0.9490196078431372, '#2463ca'],
    [0.9529411764705882, '#2660ce'],
    [0.9568627450980391, '#275dd1'],
    [0.9607843137254902, '#295ad4'],
    [0.9647058823529412, '#2b57d7'],
    [0.9686274509803922, '#2c54da'],
    [0.9725490196078431, '#2e51dd'],
    [0.9764705882352941, '#2f4ee0'],
    [0.9803921568627451, '#314be2'],
    [0.984313725490196, '#3348e5'],
    [0.9882352941176471, '#3546e7'],
    [0.9921568627450981, '#3743ea'],
    [0.996078431372549, '#3941ec'],
    [1.0, '#3b3fee'],
  ],
  CET_C2: [
    [0.0, '#ef55f1'],
    [0.00392156862745098, '#f057f0'],
    [0.00784313725490196, '#f259ee'],
    [0.011764705882352941, '#f35cec'],
    [0.01568627450980392, '#f45feb'],
    [0.0196078431372549, '#f562e9'],
    [0.023529411764705882, '#f665e6'],
    [0.027450980392156862, '#f768e4'],
    [0.03137254901960784, '#f76be2'],
    [0.03529411764705882, '#f86ee0'],
    [0.0392156862745098, '#f971dd'],
    [0.043137254901960784, '#f974db'],
    [0.047058823529411764, '#f977d8'],
    [0.050980392156862744, '#fa7ad6'],
    [0.054901960784313725, '#fa7ed3'],
    [0.058823529411764705, '#fa81d0'],
    [0.06274509803921569, '#fb84ce'],
    [0.06666666666666667, '#fb87cb'],
    [0.07058823529411765, '#fb89c8'],
    [0.07450980392156863, '#fb8cc5'],
    [0.0784313725490196, '#fb8fc3'],
    [0.08235294117647059, '#fb92c0'],
    [0.08627450980392157, '#fb95bd'],
    [0.09019607843137255, '#fb98ba'],
    [0.09411764705882353, '#fb9ab8'],
    [0.09803921568627451, '#fb9db5'],
    [0.10196078431372549, '#fba0b2'],
    [0.10588235294117647, '#fba2af'],
    [0.10980392156862745, '#fba5ad'],
    [0.11372549019607843, '#fba8aa'],
    [0.11764705882352941, '#fbaaa7'],
    [0.12156862745098039, '#fbada4'],
    [0.12549019607843137, '#fbafa1'],
    [0.12941176470588234, '#fbb29e'],
    [0.13333333333333333, '#fbb49c'],
    [0.13725490196078433, '#fbb799'],
    [0.1411764705882353, '#fbb996'],
    [0.14509803921568626, '#fbbb93'],
    [0.14901960784313725, '#fbbe90'],
    [0.15294117647058825, '#fbc08d'],
    [0.1568627450980392, '#fbc28a'],
    [0.16078431372549018, '#fbc487'],
    [0.16470588235294117, '#fbc784'],
    [0.16862745098039217, '#fbc981'],
    [0.17254901960784313, '#fbcb7e'],
    [0.1764705882352941, '#fccd7b'],
    [0.1803921568627451, '#fccf77'],
    [0.1843137254901961, '#fcd274'],
    [0.18823529411764706, '#fcd471'],
    [0.19215686274509802, '#fcd66e'],
    [0.19607843137254902, '#fcd86a'],
    [0.2, '#fbda67'],
    [0.20392156862745098, '#fbdc63'],
    [0.20784313725490194, '#fbde5f'],
    [0.21176470588235294, '#fbe05c'],
    [0.21568627450980393, '#fae258'],
    [0.2196078431372549, '#fae454'],
    [0.22352941176470587, '#f9e550'],
    [0.22745098039215686, '#f8e74c'],
    [0.23137254901960785, '#f7e848'],
    [0.23529411764705882, '#f6ea44'],
    [0.2392156862745098, '#f5eb40'],
    [0.24313725490196078, '#f3ec3c'],
    [0.24705882352941178, '#f2ed39'],
    [0.25098039215686274, '#f0ed35'],
    [0.2549019607843137, '#eeee31'],
    [0.2588235294117647, '#ecee2e'],
    [0.2627450980392157, '#eaee2b'],
    [0.26666666666666666, '#e8ee28'],
    [0.27058823529411763, '#e5ee25'],
    [0.27450980392156865, '#e3ed22'],
    [0.2784313725490196, '#e0ed20'],
    [0.2823529411764706, '#ddec1e'],
    [0.28627450980392155, '#dbeb1c'],
    [0.2901960784313725, '#d8eb1b'],
    [0.29411764705882354, '#d5ea1a'],
    [0.2980392156862745, '#d2e919'],
    [0.30196078431372547, '#cfe818'],
    [0.3058823529411765, '#cce717'],
    [0.30980392156862746, '#c9e616'],
    [0.3137254901960784, '#c6e516'],
    [0.3176470588235294, '#c4e415'],
    [0.32156862745098036, '#c1e315'],
    [0.3254901960784314, '#bee214'],
    [0.32941176470588235, '#bbe014'],
    [0.3333333333333333, '#b8df14'],
    [0.33725490196078434, '#b5de13'],
    [0.3411764705882353, '#b2dd13'],
    [0.34509803921568627, '#afdc13'],
    [0.34901960784313724, '#acdb12'],
    [0.3529411764705882, '#a9da12'],
    [0.3568627450980392, '#a6d912'],
    [0.3607843137254902, '#a3d811'],
    [0.36470588235294116, '#a0d711'],
    [0.3686274509803922, '#9cd511'],
    [0.37254901960784315, '#99d410'],
    [0.3764705882352941, '#96d310'],
    [0.3803921568627451, '#93d210'],
    [0.38431372549019605, '#90d10f'],
    [0.38823529411764707, '#8dd00f'],
    [0.39215686274509803, '#8acf0f'],
    [0.396078431372549, '#86ce0e'],
    [0.4, '#83cd0e'],
    [0.403921568627451, '#80cb0e'],
    [0.40784313725490196, '#7dca0d'],
    [0.4117647058823529, '#79c90d'],
    [0.4156862745098039, '#76c80d'],
    [0.4196078431372549, '#73c70c'],
    [0.4235294117647059, '#6fc60c'],
    [0.42745098039215684, '#6cc50c'],
    [0.43137254901960786, '#68c30c'],
    [0.43529411764705883, '#65c20b'],
    [0.4392156862745098, '#61c10b'],
    [0.44313725490196076, '#5dc00b'],
    [0.44705882352941173, '#5abf0c'],
    [0.45098039215686275, '#56be0c'],
    [0.4549019607843137, '#52bc0c'],
    [0.4588235294117647, '#4fbb0d'],
    [0.4627450980392157, '#4bba0e'],
    [0.4666666666666667, '#47b910'],
    [0.47058823529411764, '#44b711'],
    [0.4745098039215686, '#40b613'],
    [0.4784313725490196, '#3db516'],
    [0.4823529411764706, '#3ab318'],
    [0.48627450980392156, '#38b21b'],
    [0.49019607843137253, '#35b11e'],
    [0.49411764705882355, '#34af21'],
    [0.4980392156862745, '#32ae24'],
    [0.5019607843137255, '#31ac28'],
    [0.5058823529411764, '#31ab2b'],
    [0.5098039215686274, '#31a92f'],
    [0.5137254901960784, '#32a733'],
    [0.5176470588235293, '#33a636'],
    [0.5215686274509804, '#34a43a'],
    [0.5254901960784314, '#36a23e'],
    [0.5294117647058824, '#37a042'],
    [0.5333333333333333, '#399e46'],
    [0.5372549019607843, '#3a9d4a'],
    [0.5411764705882353, '#3c9b4d'],
    [0.5450980392156862, '#3d9951'],
    [0.5490196078431373, '#3e9755'],
    [0.5529411764705883, '#409559'],
    [0.5568627450980392, '#41935c'],
    [0.5607843137254902, '#429160'],
    [0.5647058823529412, '#439064'],
    [0.5686274509803921, '#438e67'],
    [0.5725490196078431, '#448c6b'],
    [0.5764705882352941, '#448a6e'],
    [0.580392156862745, '#458872'],
    [0.5843137254901961, '#458675'],
    [0.5882352941176471, '#458479'],
    [0.592156862745098, '#45827c'],
    [0.596078431372549, '#448080'],
    [0.6, '#447f83'],
    [0.6039215686274509, '#437d86'],
    [0.6078431372549019, '#437b8a'],
    [0.611764705882353, '#42798d'],
    [0.615686274509804, '#417790'],
    [0.6196078431372549, '#407594'],
    [0.6235294117647059, '#3e7397'],
    [0.6274509803921569, '#3d719a'],
    [0.6313725490196078, '#3c6f9d'],
    [0.6352941176470588, '#3a6da0'],
    [0.6392156862745098, '#396ba4'],
    [0.6431372549019607, '#3769a7'],
    [0.6470588235294118, '#3567aa'],
    [0.6509803921568628, '#3465ad'],
    [0.6549019607843137, '#3263b0'],
    [0.6588235294117647, '#3161b3'],
    [0.6627450980392157, '#2f5fb5'],
    [0.6666666666666666, '#2e5db8'],
    [0.6705882352941176, '#2d5abb'],
    [0.6745098039215687, '#2c58be'],
    [0.6784313725490196, '#2b55c0'],
    [0.6823529411764706, '#2a53c3'],
    [0.6862745098039216, '#2950c5'],
    [0.6901960784313725, '#284ec8'],
    [0.6941176470588235, '#284bca'],
    [0.6980392156862745, '#2748cd'],
    [0.7019607843137254, '#2646cf'],
    [0.7058823529411764, '#2643d2'],
    [0.7098039215686275, '#2540d4'],
    [0.7137254901960784, '#253dd6'],
    [0.7176470588235294, '#243ad9'],
    [0.7215686274509804, '#2437db'],
    [0.7254901960784313, '#2434dd'],
    [0.7294117647058823, '#2431df'],
    [0.7333333333333333, '#252ee1'],
    [0.7372549019607844, '#262be3'],
    [0.7411764705882353, '#2729e5'],
    [0.7450980392156863, '#2926e7'],
    [0.7490196078431373, '#2b24e8'],
    [0.7529411764705882, '#2e21ea'],
    [0.7568627450980392, '#301feb'],
    [0.7607843137254902, '#331eec'],
    [0.7647058823529411, '#371cee'],
    [0.7686274509803921, '#3a1bef'],
    [0.7725490196078432, '#3e1aef'],
    [0.7764705882352941, '#411af0'],
    [0.7803921568627451, '#451af1'],
    [0.7843137254901961, '#491af2'],
    [0.788235294117647, '#4c1bf2'],
    [0.792156862745098, '#501cf3'],
    [0.796078431372549, '#531df3'],
    [0.8, '#571ef4'],
    [0.803921568627451, '#5a1ff4'],
    [0.807843137254902, '#5d21f5'],
    [0.8117647058823529, '#6022f5'],
    [0.8156862745098039, '#6324f5'],
    [0.8196078431372549, '#6625f6'],
    [0.8235294117647058, '#6927f6'],
    [0.8274509803921568, '#6c28f6'],
    [0.8313725490196078, '#6f2af7'],
    [0.8352941176470589, '#722bf7'],
    [0.8392156862745098, '#752df8'],
    [0.8431372549019608, '#782ef8'],
    [0.8470588235294118, '#7a2ff8'],
    [0.8509803921568627, '#7d31f8'],
    [0.8549019607843137, '#8032f9'],
    [0.8588235294117647, '#8333f9'],
    [0.8627450980392157, '#8535f9'],
    [0.8666666666666667, '#8836fa'],
    [0.8705882352941177, '#8b37fa'],
    [0.8745098039215686, '#8e38fa'],
    [0.8784313725490196, '#9139fa'],
    [0.8823529411764706, '#943afa'],
    [0.8862745098039215, '#973bfa'],
    [0.8901960784313725, '#9a3cfb'],
    [0.8941176470588235, '#9d3dfb'],
    [0.8980392156862745, '#a03dfb'],
    [0.9019607843137255, '#a43efb'],
    [0.9058823529411765, '#a73ffb'],
    [0.9098039215686274, '#aa3ffb'],
    [0.9137254901960784, '#ae40fb'],
    [0.9176470588235294, '#b140fb'],
    [0.9215686274509803, '#b441fb'],
    [0.9254901960784314, '#b741fb'],
    [0.9294117647058824, '#bb42fa'],
    [0.9333333333333333, '#be42fa'],
    [0.9372549019607843, '#c143fa'],
    [0.9411764705882353, '#c543fa'],
    [0.9450980392156862, '#c844fa'],
    [0.9490196078431372, '#cb44fa'],
    [0.9529411764705882, '#ce45fa'],
    [0.9568627450980391, '#d145f9'],
    [0.9607843137254902, '#d446f9'],
    [0.9647058823529412, '#d746f9'],
    [0.9686274509803922, '#da47f9'],
    [0.9725490196078431, '#dd48f8'],
    [0.9764705882352941, '#e049f8'],
    [0.9803921568627451, '#e24af7'],
    [0.984313725490196, '#e54bf6'],
    [0.9882352941176471, '#e74df6'],
    [0.9921568627450981, '#e94ff5'],
    [0.996078431372549, '#eb50f4'],
    [1.0, '#ed52f3'],
  ],
  CET_C2s: [
    [0.0, '#2e21ea'],
    [0.00392156862745098, '#301feb'],
    [0.00784313725490196, '#331eec'],
    [0.011764705882352941, '#371cee'],
    [0.01568627450980392, '#3a1bef'],
    [0.0196078431372549, '#3e1aef'],
    [0.023529411764705882, '#411af0'],
    [0.027450980392156862, '#451af1'],
    [0.03137254901960784, '#491af2'],
    [0.03529411764705882, '#4c1bf2'],
    [0.0392156862745098, '#501cf3'],
    [0.043137254901960784, '#531df3'],
    [0.047058823529411764, '#571ef4'],
    [0.050980392156862744, '#5a1ff4'],
    [0.054901960784313725, '#5d21f5'],
    [0.058823529411764705, '#6022f5'],
    [0.06274509803921569, '#6324f5'],
    [0.06666666666666667, '#6625f6'],
    [0.07058823529411765, '#6927f6'],
    [0.07450980392156863, '#6c28f6'],
    [0.0784313725490196, '#6f2af7'],
    [0.08235294117647059, '#722bf7'],
    [0.08627450980392157, '#752df8'],
    [0.09019607843137255, '#782ef8'],
    [0.09411764705882353, '#7a2ff8'],
    [0.09803921568627451, '#7d31f8'],
    [0.10196078431372549, '#8032f9'],
    [0.10588235294117647, '#8333f9'],
    [0.10980392156862745, '#8535f9'],
    [0.11372549019607843, '#8836fa'],
    [0.11764705882352941, '#8b37fa'],
    [0.12156862745098039, '#8e38fa'],
    [0.12549019607843137, '#9139fa'],
    [0.12941176470588234, '#943afa'],
    [0.13333333333333333, '#973bfa'],
    [0.13725490196078433, '#9a3cfb'],
    [0.1411764705882353, '#9d3dfb'],
    [0.14509803921568626, '#a03dfb'],
    [0.14901960784313725, '#a43efb'],
    [0.15294117647058825, '#a73ffb'],
    [0.1568627450980392, '#aa3ffb'],
    [0.16078431372549018, '#ae40fb'],
    [0.16470588235294117, '#b140fb'],
    [0.16862745098039217, '#b441fb'],
    [0.17254901960784313, '#b741fb'],
    [0.1764705882352941, '#bb42fa'],
    [0.1803921568627451, '#be42fa'],
    [0.1843137254901961, '#c143fa'],
    [0.18823529411764706, '#c543fa'],
    [0.19215686274509802, '#c844fa'],
    [0.19607843137254902, '#cb44fa'],
    [0.2, '#ce45fa'],
    [0.20392156862745098, '#d145f9'],
    [0.20784313725490194, '#d446f9'],
    [0.21176470588235294, '#d746f9'],
    [0.21568627450980393, '#da47f9'],
    [0.2196078431372549, '#dd48f8'],
    [0.22352941176470587, '#e049f8'],
    [0.22745098039215686, '#e24af7'],
    [0.23137254901960785, '#e54bf6'],
    [0.23529411764705882, '#e74df6'],
    [0.2392156862745098, '#e94ff5'],
    [0.24313725490196078, '#eb50f4'],
    [0.24705882352941178, '#ed52f3'],
    [0.25098039215686274, '#ef55f1'],
    [0.2549019607843137, '#f057f0'],
    [0.2588235294117647, '#f259ee'],
    [0.2627450980392157, '#f35cec'],
    [0.26666666666666666, '#f45feb'],
    [0.27058823529411763, '#f562e9'],
    [0.27450980392156865, '#f665e6'],
    [0.2784313725490196, '#f768e4'],
    [0.2823529411764706, '#f76be2'],
    [0.28627450980392155, '#f86ee0'],
    [0.2901960784313725, '#f971dd'],
    [0.29411764705882354, '#f974db'],
    [0.2980392156862745, '#f977d8'],
    [0.30196078431372547, '#fa7ad6'],
    [0.3058823529411765, '#fa7ed3'],
    [0.30980392156862746, '#fa81d0'],
    [0.3137254901960784, '#fb84ce'],
    [0.3176470588235294, '#fb87cb'],
    [0.32156862745098036, '#fb89c8'],
    [0.3254901960784314, '#fb8cc5'],
    [0.32941176470588235, '#fb8fc3'],
    [0.3333333333333333, '#fb92c0'],
    [0.33725490196078434, '#fb95bd'],
    [0.3411764705882353, '#fb98ba'],
    [0.34509803921568627, '#fb9ab8'],
    [0.34901960784313724, '#fb9db5'],
    [0.3529411764705882, '#fba0b2'],
    [0.3568627450980392, '#fba2af'],
    [0.3607843137254902, '#fba5ad'],
    [0.36470588235294116, '#fba8aa'],
    [0.3686274509803922, '#fbaaa7'],
    [0.37254901960784315, '#fbada4'],
    [0.3764705882352941, '#fbafa1'],
    [0.3803921568627451, '#fbb29e'],
    [0.38431372549019605, '#fbb49c'],
    [0.38823529411764707, '#fbb799'],
    [0.39215686274509803, '#fbb996'],
    [0.396078431372549, '#fbbb93'],
    [0.4, '#fbbe90'],
    [0.403921568627451, '#fbc08d'],
    [0.40784313725490196, '#fbc28a'],
    [0.4117647058823529, '#fbc487'],
    [0.4156862745098039, '#fbc784'],
    [0.4196078431372549, '#fbc981'],
    [0.4235294117647059, '#fbcb7e'],
    [0.42745098039215684, '#fccd7b'],
    [0.43137254901960786, '#fccf77'],
    [0.43529411764705883, '#fcd274'],
    [0.4392156862745098, '#fcd471'],
    [0.44313725490196076, '#fcd66e'],
    [0.44705882352941173, '#fcd86a'],
    [0.45098039215686275, '#fbda67'],
    [0.4549019607843137, '#fbdc63'],
    [0.4588235294117647, '#fbde5f'],
    [0.4627450980392157, '#fbe05c'],
    [0.4666666666666667, '#fae258'],
    [0.47058823529411764, '#fae454'],
    [0.4745098039215686, '#f9e550'],
    [0.4784313725490196, '#f8e74c'],
    [0.4823529411764706, '#f7e848'],
    [0.48627450980392156, '#f6ea44'],
    [0.49019607843137253, '#f5eb40'],
    [0.49411764705882355, '#f3ec3c'],
    [0.4980392156862745, '#f2ed39'],
    [0.5019607843137255, '#f0ed35'],
    [0.5058823529411764, '#eeee31'],
    [0.5098039215686274, '#ecee2e'],
    [0.5137254901960784, '#eaee2b'],
    [0.5176470588235293, '#e8ee28'],
    [0.5215686274509804, '#e5ee25'],
    [0.5254901960784314, '#e3ed22'],
    [0.5294117647058824, '#e0ed20'],
    [0.5333333333333333, '#ddec1e'],
    [0.5372549019607843, '#dbeb1c'],
    [0.5411764705882353, '#d8eb1b'],
    [0.5450980392156862, '#d5ea1a'],
    [0.5490196078431373, '#d2e919'],
    [0.5529411764705883, '#cfe818'],
    [0.5568627450980392, '#cce717'],
    [0.5607843137254902, '#c9e616'],
    [0.5647058823529412, '#c6e516'],
    [0.5686274509803921, '#c4e415'],
    [0.5725490196078431, '#c1e315'],
    [0.5764705882352941, '#bee214'],
    [0.580392156862745, '#bbe014'],
    [0.5843137254901961, '#b8df14'],
    [0.5882352941176471, '#b5de13'],
    [0.592156862745098, '#b2dd13'],
    [0.596078431372549, '#afdc13'],
    [0.6, '#acdb12'],
    [0.6039215686274509, '#a9da12'],
    [0.6078431372549019, '#a6d912'],
    [0.611764705882353, '#a3d811'],
    [0.615686274509804, '#a0d711'],
    [0.6196078431372549, '#9cd511'],
    [0.6235294117647059, '#99d410'],
    [0.6274509803921569, '#96d310'],
    [0.6313725490196078, '#93d210'],
    [0.6352941176470588, '#90d10f'],
    [0.6392156862745098, '#8dd00f'],
    [0.6431372549019607, '#8acf0f'],
    [0.6470588235294118, '#86ce0e'],
    [0.6509803921568628, '#83cd0e'],
    [0.6549019607843137, '#80cb0e'],
    [0.6588235294117647, '#7dca0d'],
    [0.6627450980392157, '#79c90d'],
    [0.6666666666666666, '#76c80d'],
    [0.6705882352941176, '#73c70c'],
    [0.6745098039215687, '#6fc60c'],
    [0.6784313725490196, '#6cc50c'],
    [0.6823529411764706, '#68c30c'],
    [0.6862745098039216, '#65c20b'],
    [0.6901960784313725, '#61c10b'],
    [0.6941176470588235, '#5dc00b'],
    [0.6980392156862745, '#5abf0c'],
    [0.7019607843137254, '#56be0c'],
    [0.7058823529411764, '#52bc0c'],
    [0.7098039215686275, '#4fbb0d'],
    [0.7137254901960784, '#4bba0e'],
    [0.7176470588235294, '#47b910'],
    [0.7215686274509804, '#44b711'],
    [0.7254901960784313, '#40b613'],
    [0.7294117647058823, '#3db516'],
    [0.7333333333333333, '#3ab318'],
    [0.7372549019607844, '#38b21b'],
    [0.7411764705882353, '#35b11e'],
    [0.7450980392156863, '#34af21'],
    [0.7490196078431373, '#32ae24'],
    [0.7529411764705882, '#31ac28'],
    [0.7568627450980392, '#31ab2b'],
    [0.7607843137254902, '#31a92f'],
    [0.7647058823529411, '#32a733'],
    [0.7686274509803921, '#33a636'],
    [0.7725490196078432, '#34a43a'],
    [0.7764705882352941, '#36a23e'],
    [0.7803921568627451, '#37a042'],
    [0.7843137254901961, '#399e46'],
    [0.788235294117647, '#3a9d4a'],
    [0.792156862745098, '#3c9b4d'],
    [0.796078431372549, '#3d9951'],
    [0.8, '#3e9755'],
    [0.803921568627451, '#409559'],
    [0.807843137254902, '#41935c'],
    [0.8117647058823529, '#429160'],
    [0.8156862745098039, '#439064'],
    [0.8196078431372549, '#438e67'],
    [0.8235294117647058, '#448c6b'],
    [0.8274509803921568, '#448a6e'],
    [0.8313725490196078, '#458872'],
    [0.8352941176470589, '#458675'],
    [0.8392156862745098, '#458479'],
    [0.8431372549019608, '#45827c'],
    [0.8470588235294118, '#448080'],
    [0.8509803921568627, '#447f83'],
    [0.8549019607843137, '#437d86'],
    [0.8588235294117647, '#437b8a'],
    [0.8627450980392157, '#42798d'],
    [0.8666666666666667, '#417790'],
    [0.8705882352941177, '#407594'],
    [0.8745098039215686, '#3e7397'],
    [0.8784313725490196, '#3d719a'],
    [0.8823529411764706, '#3c6f9d'],
    [0.8862745098039215, '#3a6da0'],
    [0.8901960784313725, '#396ba4'],
    [0.8941176470588235, '#3769a7'],
    [0.8980392156862745, '#3567aa'],
    [0.9019607843137255, '#3465ad'],
    [0.9058823529411765, '#3263b0'],
    [0.9098039215686274, '#3161b3'],
    [0.9137254901960784, '#2f5fb5'],
    [0.9176470588235294, '#2e5db8'],
    [0.9215686274509803, '#2d5abb'],
    [0.9254901960784314, '#2c58be'],
    [0.9294117647058824, '#2b55c0'],
    [0.9333333333333333, '#2a53c3'],
    [0.9372549019607843, '#2950c5'],
    [0.9411764705882353, '#284ec8'],
    [0.9450980392156862, '#284bca'],
    [0.9490196078431372, '#2748cd'],
    [0.9529411764705882, '#2646cf'],
    [0.9568627450980391, '#2643d2'],
    [0.9607843137254902, '#2540d4'],
    [0.9647058823529412, '#253dd6'],
    [0.9686274509803922, '#243ad9'],
    [0.9725490196078431, '#2437db'],
    [0.9764705882352941, '#2434dd'],
    [0.9803921568627451, '#2431df'],
    [0.984313725490196, '#252ee1'],
    [0.9882352941176471, '#262be3'],
    [0.9921568627450981, '#2729e5'],
    [0.996078431372549, '#2926e7'],
    [1.0, '#2b24e8'],
  ],
  CET_C3: [
    [0.0, '#e0d7da'],
    [0.00392156862745098, '#e2d6d8'],
    [0.00784313725490196, '#e3d5d6'],
    [0.011764705882352941, '#e4d4d3'],
    [0.01568627450980392, '#e5d3d1'],
    [0.0196078431372549, '#e6d2ce'],
    [0.023529411764705882, '#e7d0cc'],
    [0.027450980392156862, '#e8cfc9'],
    [0.03137254901960784, '#e9cdc6'],
    [0.03529411764705882, '#e9cbc3'],
    [0.0392156862745098, '#eac9c0'],
    [0.043137254901960784, '#ebc6bd'],
    [0.047058823529411764, '#ebc4ba'],
    [0.050980392156862744, '#ecc2b6'],
    [0.054901960784313725, '#ecbfb3'],
    [0.058823529411764705, '#edbdb0'],
    [0.06274509803921569, '#edbbad'],
    [0.06666666666666667, '#eeb8aa'],
    [0.07058823529411765, '#eeb6a6'],
    [0.07450980392156863, '#eeb3a3'],
    [0.0784313725490196, '#eeb1a0'],
    [0.08235294117647059, '#efae9d'],
    [0.08627450980392157, '#efac99'],
    [0.09019607843137255, '#efa996'],
    [0.09411764705882353, '#efa793'],
    [0.09803921568627451, '#efa490'],
    [0.10196078431372549, '#efa28d'],
    [0.10588235294117647, '#f09f8a'],
    [0.10980392156862745, '#f09d86'],
    [0.11372549019607843, '#f09a83'],
    [0.11764705882352941, '#f09880'],
    [0.12156862745098039, '#ef957d'],
    [0.12549019607843137, '#ef937a'],
    [0.12941176470588234, '#ef9077'],
    [0.13333333333333333, '#ef8e74'],
    [0.13725490196078433, '#ef8b71'],
    [0.1411764705882353, '#ef886e'],
    [0.14509803921568626, '#ef866a'],
    [0.14901960784313725, '#ee8367'],
    [0.15294117647058825, '#ee8164'],
    [0.1568627450980392, '#ee7e61'],
    [0.16078431372549018, '#ee7b5e'],
    [0.16470588235294117, '#ed795b'],
    [0.16862745098039217, '#ed7658'],
    [0.17254901960784313, '#ed7355'],
    [0.1764705882352941, '#ec7052'],
    [0.1803921568627451, '#ec6e4f'],
    [0.1843137254901961, '#eb6b4c'],
    [0.18823529411764706, '#eb6849'],
    [0.19215686274509802, '#ea6546'],
    [0.19607843137254902, '#ea6243'],
    [0.2, '#e96040'],
    [0.20392156862745098, '#e95d3d'],
    [0.20784313725490194, '#e85a3a'],
    [0.21176470588235294, '#e75738'],
    [0.21568627450980393, '#e65435'],
    [0.2196078431372549, '#e55132'],
    [0.22352941176470587, '#e44e2f'],
    [0.22745098039215686, '#e34c2d'],
    [0.23137254901960785, '#e2492a'],
    [0.23529411764705882, '#e14628'],
    [0.2392156862745098, '#df4426'],
    [0.24313725490196078, '#de4224'],
    [0.24705882352941178, '#dc3f22'],
    [0.25098039215686274, '#da3d21'],
    [0.2549019607843137, '#d83b1f'],
    [0.2588235294117647, '#d63a1e'],
    [0.2627450980392157, '#d3381d'],
    [0.26666666666666666, '#d1371c'],
    [0.27058823529411763, '#ce361b'],
    [0.27450980392156865, '#cc351b'],
    [0.2784313725490196, '#c9351a'],
    [0.2823529411764706, '#c6341a'],
    [0.28627450980392155, '#c3331a'],
    [0.2901960784313725, '#c0331a'],
    [0.29411764705882354, '#bd331a'],
    [0.2980392156862745, '#ba321a'],
    [0.30196078431372547, '#b7321a'],
    [0.3058823529411765, '#b4321a'],
    [0.30980392156862746, '#b0321a'],
    [0.3137254901960784, '#ad321a'],
    [0.3176470588235294, '#aa321a'],
    [0.32156862745098036, '#a7311a'],
    [0.3254901960784314, '#a4311b'],
    [0.32941176470588235, '#a1311b'],
    [0.3333333333333333, '#9d311b'],
    [0.33725490196078434, '#9a311b'],
    [0.3411764705882353, '#97301b'],
    [0.34509803921568627, '#94301b'],
    [0.34901960784313724, '#91301b'],
    [0.3529411764705882, '#8e2f1b'],
    [0.3568627450980392, '#8b2f1c'],
    [0.3607843137254902, '#882f1c'],
    [0.36470588235294116, '#842f1c'],
    [0.3686274509803922, '#812e1c'],
    [0.37254901960784315, '#7e2e1c'],
    [0.3764705882352941, '#7b2d1c'],
    [0.3803921568627451, '#782d1c'],
    [0.38431372549019605, '#752d1c'],
    [0.38823529411764707, '#722c1c'],
    [0.39215686274509803, '#6f2c1c'],
    [0.396078431372549, '#6c2b1c'],
    [0.4, '#692b1c'],
    [0.403921568627451, '#662b1c'],
    [0.40784313725490196, '#632a1c'],
    [0.4117647058823529, '#602a1c'],
    [0.4156862745098039, '#5d291c'],
    [0.4196078431372549, '#5a291c'],
    [0.4235294117647059, '#57281c'],
    [0.42745098039215684, '#54281c'],
    [0.43137254901960786, '#51271c'],
    [0.43529411764705883, '#4f261c'],
    [0.4392156862745098, '#4c261c'],
    [0.44313725490196076, '#49251c'],
    [0.44705882352941173, '#46251c'],
    [0.45098039215686275, '#43241c'],
    [0.4549019607843137, '#40241d'],
    [0.4588235294117647, '#3e231d'],
    [0.4627450980392157, '#3b231d'],
    [0.4666666666666667, '#39221d'],
    [0.47058823529411764, '#36221e'],
    [0.4745098039215686, '#34211e'],
    [0.4784313725490196, '#32211f'],
    [0.4823529411764706, '#30211f'],
    [0.48627450980392156, '#2e2120'],
    [0.49019607843137253, '#2c2121'],
    [0.49411764705882355, '#2b2123'],
    [0.4980392156862745, '#2a2124'],
    [0.5019607843137255, '#292126'],
    [0.5058823529411764, '#282127'],
    [0.5098039215686274, '#272229'],
    [0.5137254901960784, '#26222c'],
    [0.5176470588235293, '#26232e'],
    [0.5215686274509804, '#262430'],
    [0.5254901960784314, '#262433'],
    [0.5294117647058824, '#262536'],
    [0.5333333333333333, '#262638'],
    [0.5372549019607843, '#27273b'],
    [0.5411764705882353, '#27283e'],
    [0.5450980392156862, '#282941'],
    [0.5490196078431373, '#282a45'],
    [0.5529411764705883, '#292c48'],
    [0.5568627450980392, '#292d4b'],
    [0.5607843137254902, '#2a2e4e'],
    [0.5647058823529412, '#2a2f51'],
    [0.5686274509803921, '#2b3055'],
    [0.5725490196078431, '#2b3258'],
    [0.5764705882352941, '#2c335c'],
    [0.580392156862745, '#2c345f'],
    [0.5843137254901961, '#2d3562'],
    [0.5882352941176471, '#2d3766'],
    [0.592156862745098, '#2e3869'],
    [0.596078431372549, '#2e396d'],
    [0.6, '#2e3a70'],
    [0.6039215686274509, '#2f3c74'],
    [0.6078431372549019, '#2f3d77'],
    [0.611764705882353, '#2f3e7b'],
    [0.615686274509804, '#2f3f7f'],
    [0.6196078431372549, '#2f4182'],
    [0.6235294117647059, '#304286'],
    [0.6274509803921569, '#304389'],
    [0.6313725490196078, '#30458d'],
    [0.6352941176470588, '#304691'],
    [0.6392156862745098, '#2f4795'],
    [0.6431372549019607, '#2f4898'],
    [0.6470588235294118, '#2f4a9c'],
    [0.6509803921568628, '#2f4ba0'],
    [0.6549019607843137, '#2e4ca3'],
    [0.6588235294117647, '#2e4ea7'],
    [0.6627450980392157, '#2e4fab'],
    [0.6666666666666666, '#2d50af'],
    [0.6705882352941176, '#2d52b3'],
    [0.6745098039215687, '#2c53b6'],
    [0.6784313725490196, '#2b55ba'],
    [0.6823529411764706, '#2a56be'],
    [0.6862745098039216, '#2a57c2'],
    [0.6901960784313725, '#2959c6'],
    [0.6941176470588235, '#285aca'],
    [0.6980392156862745, '#275bcd'],
    [0.7019607843137254, '#265dd1'],
    [0.7058823529411764, '#255ed5'],
    [0.7098039215686275, '#2460d8'],
    [0.7137254901960784, '#2361dc'],
    [0.7176470588235294, '#2362e0'],
    [0.7215686274509804, '#2364e3'],
    [0.7254901960784313, '#2365e6'],
    [0.7294117647058823, '#2367e9'],
    [0.7333333333333333, '#2568ec'],
    [0.7372549019607844, '#266aef'],
    [0.7411764705882353, '#296bf1'],
    [0.7450980392156863, '#2c6df3'],
    [0.7490196078431373, '#2f6ef5'],
    [0.7529411764705882, '#3370f7'],
    [0.7568627450980392, '#3671f8'],
    [0.7607843137254902, '#3b73f9'],
    [0.7647058823529411, '#3f74fa'],
    [0.7686274509803921, '#4376fb'],
    [0.7725490196078432, '#4778fb'],
    [0.7764705882352941, '#4c79fc'],
    [0.7803921568627451, '#507bfc'],
    [0.7843137254901961, '#547dfc'],
    [0.788235294117647, '#587efc'],
    [0.792156862745098, '#5c80fc'],
    [0.796078431372549, '#6082fc'],
    [0.8, '#6483fb'],
    [0.803921568627451, '#6885fb'],
    [0.807843137254902, '#6b87fb'],
    [0.8117647058823529, '#6f88fa'],
    [0.8156862745098039, '#728afa'],
    [0.8196078431372549, '#768cf9'],
    [0.8235294117647058, '#798ef9'],
    [0.8274509803921568, '#7c8ff9'],
    [0.8313725490196078, '#7f91f8'],
    [0.8352941176470589, '#8293f8'],
    [0.8392156862745098, '#8595f7'],
    [0.8431372549019608, '#8897f7'],
    [0.8470588235294118, '#8b98f6'],
    [0.8509803921568627, '#8e9af6'],
    [0.8549019607843137, '#909cf5'],
    [0.8588235294117647, '#939ef5'],
    [0.8627450980392157, '#96a0f5'],
    [0.8666666666666667, '#98a1f4'],
    [0.8705882352941177, '#9ba3f4'],
    [0.8745098039215686, '#9da5f3'],
    [0.8784313725490196, '#a0a7f3'],
    [0.8823529411764706, '#a2a9f2'],
    [0.8862745098039215, '#a5aaf2'],
    [0.8901960784313725, '#a7acf1'],
    [0.8941176470588235, '#a9aef1'],
    [0.8980392156862745, '#acb0f0'],
    [0.9019607843137255, '#aeb2f0'],
    [0.9058823529411765, '#b0b4ef'],
    [0.9098039215686274, '#b3b6ef'],
    [0.9137254901960784, '#b5b8ee'],
    [0.9176470588235294, '#b7b9ee'],
    [0.9215686274509803, '#b9bbed'],
    [0.9254901960784314, '#bbbded'],
    [0.9294117647058824, '#bebfec'],
    [0.9333333333333333, '#c0c1eb'],
    [0.9372549019607843, '#c2c3eb'],
    [0.9411764705882353, '#c4c5ea'],
    [0.9450980392156862, '#c6c7ea'],
    [0.9490196078431372, '#c8c8e9'],
    [0.9529411764705882, '#cacae9'],
    [0.9568627450980391, '#cccce8'],
    [0.9607843137254902, '#cecee7'],
    [0.9647058823529412, '#d0cfe7'],
    [0.9686274509803922, '#d2d1e6'],
    [0.9725490196078431, '#d4d2e5'],
    [0.9764705882352941, '#d5d3e4'],
    [0.9803921568627451, '#d7d4e3'],
    [0.984313725490196, '#d9d5e2'],
    [0.9882352941176471, '#dad6e0'],
    [0.9921568627450981, '#dcd7df'],
    [0.996078431372549, '#ddd7dd'],
    [1.0, '#dfd7dc'],
  ],
  CET_C3s: [
    [0.0, '#3370f7'],
    [0.00392156862745098, '#3671f8'],
    [0.00784313725490196, '#3b73f9'],
    [0.011764705882352941, '#3f74fa'],
    [0.01568627450980392, '#4376fb'],
    [0.0196078431372549, '#4778fb'],
    [0.023529411764705882, '#4c79fc'],
    [0.027450980392156862, '#507bfc'],
    [0.03137254901960784, '#547dfc'],
    [0.03529411764705882, '#587efc'],
    [0.0392156862745098, '#5c80fc'],
    [0.043137254901960784, '#6082fc'],
    [0.047058823529411764, '#6483fb'],
    [0.050980392156862744, '#6885fb'],
    [0.054901960784313725, '#6b87fb'],
    [0.058823529411764705, '#6f88fa'],
    [0.06274509803921569, '#728afa'],
    [0.06666666666666667, '#768cf9'],
    [0.07058823529411765, '#798ef9'],
    [0.07450980392156863, '#7c8ff9'],
    [0.0784313725490196, '#7f91f8'],
    [0.08235294117647059, '#8293f8'],
    [0.08627450980392157, '#8595f7'],
    [0.09019607843137255, '#8897f7'],
    [0.09411764705882353, '#8b98f6'],
    [0.09803921568627451, '#8e9af6'],
    [0.10196078431372549, '#909cf5'],
    [0.10588235294117647, '#939ef5'],
    [0.10980392156862745, '#96a0f5'],
    [0.11372549019607843, '#98a1f4'],
    [0.11764705882352941, '#9ba3f4'],
    [0.12156862745098039, '#9da5f3'],
    [0.12549019607843137, '#a0a7f3'],
    [0.12941176470588234, '#a2a9f2'],
    [0.13333333333333333, '#a5aaf2'],
    [0.13725490196078433, '#a7acf1'],
    [0.1411764705882353, '#a9aef1'],
    [0.14509803921568626, '#acb0f0'],
    [0.14901960784313725, '#aeb2f0'],
    [0.15294117647058825, '#b0b4ef'],
    [0.1568627450980392, '#b3b6ef'],
    [0.16078431372549018, '#b5b8ee'],
    [0.16470588235294117, '#b7b9ee'],
    [0.16862745098039217, '#b9bbed'],
    [0.17254901960784313, '#bbbded'],
    [0.1764705882352941, '#bebfec'],
    [0.1803921568627451, '#c0c1eb'],
    [0.1843137254901961, '#c2c3eb'],
    [0.18823529411764706, '#c4c5ea'],
    [0.19215686274509802, '#c6c7ea'],
    [0.19607843137254902, '#c8c8e9'],
    [0.2, '#cacae9'],
    [0.20392156862745098, '#cccce8'],
    [0.20784313725490194, '#cecee7'],
    [0.21176470588235294, '#d0cfe7'],
    [0.21568627450980393, '#d2d1e6'],
    [0.2196078431372549, '#d4d2e5'],
    [0.22352941176470587, '#d5d3e4'],
    [0.22745098039215686, '#d7d4e3'],
    [0.23137254901960785, '#d9d5e2'],
    [0.23529411764705882, '#dad6e0'],
    [0.2392156862745098, '#dcd7df'],
    [0.24313725490196078, '#ddd7dd'],
    [0.24705882352941178, '#dfd7dc'],
    [0.25098039215686274, '#e0d7da'],
    [0.2549019607843137, '#e2d6d8'],
    [0.2588235294117647, '#e3d5d6'],
    [0.2627450980392157, '#e4d4d3'],
    [0.26666666666666666, '#e5d3d1'],
    [0.27058823529411763, '#e6d2ce'],
    [0.27450980392156865, '#e7d0cc'],
    [0.2784313725490196, '#e8cfc9'],
    [0.2823529411764706, '#e9cdc6'],
    [0.28627450980392155, '#e9cbc3'],
    [0.2901960784313725, '#eac9c0'],
    [0.29411764705882354, '#ebc6bd'],
    [0.2980392156862745, '#ebc4ba'],
    [0.30196078431372547, '#ecc2b6'],
    [0.3058823529411765, '#ecbfb3'],
    [0.30980392156862746, '#edbdb0'],
    [0.3137254901960784, '#edbbad'],
    [0.3176470588235294, '#eeb8aa'],
    [0.32156862745098036, '#eeb6a6'],
    [0.3254901960784314, '#eeb3a3'],
    [0.32941176470588235, '#eeb1a0'],
    [0.3333333333333333, '#efae9d'],
    [0.33725490196078434, '#efac99'],
    [0.3411764705882353, '#efa996'],
    [0.34509803921568627, '#efa793'],
    [0.34901960784313724, '#efa490'],
    [0.3529411764705882, '#efa28d'],
    [0.3568627450980392, '#f09f8a'],
    [0.3607843137254902, '#f09d86'],
    [0.36470588235294116, '#f09a83'],
    [0.3686274509803922, '#f09880'],
    [0.37254901960784315, '#ef957d'],
    [0.3764705882352941, '#ef937a'],
    [0.3803921568627451, '#ef9077'],
    [0.38431372549019605, '#ef8e74'],
    [0.38823529411764707, '#ef8b71'],
    [0.39215686274509803, '#ef886e'],
    [0.396078431372549, '#ef866a'],
    [0.4, '#ee8367'],
    [0.403921568627451, '#ee8164'],
    [0.40784313725490196, '#ee7e61'],
    [0.4117647058823529, '#ee7b5e'],
    [0.4156862745098039, '#ed795b'],
    [0.4196078431372549, '#ed7658'],
    [0.4235294117647059, '#ed7355'],
    [0.42745098039215684, '#ec7052'],
    [0.43137254901960786, '#ec6e4f'],
    [0.43529411764705883, '#eb6b4c'],
    [0.4392156862745098, '#eb6849'],
    [0.44313725490196076, '#ea6546'],
    [0.44705882352941173, '#ea6243'],
    [0.45098039215686275, '#e96040'],
    [0.4549019607843137, '#e95d3d'],
    [0.4588235294117647, '#e85a3a'],
    [0.4627450980392157, '#e75738'],
    [0.4666666666666667, '#e65435'],
    [0.47058823529411764, '#e55132'],
    [0.4745098039215686, '#e44e2f'],
    [0.4784313725490196, '#e34c2d'],
    [0.4823529411764706, '#e2492a'],
    [0.48627450980392156, '#e14628'],
    [0.49019607843137253, '#df4426'],
    [0.49411764705882355, '#de4224'],
    [0.4980392156862745, '#dc3f22'],
    [0.5019607843137255, '#da3d21'],
    [0.5058823529411764, '#d83b1f'],
    [0.5098039215686274, '#d63a1e'],
    [0.5137254901960784, '#d3381d'],
    [0.5176470588235293, '#d1371c'],
    [0.5215686274509804, '#ce361b'],
    [0.5254901960784314, '#cc351b'],
    [0.5294117647058824, '#c9351a'],
    [0.5333333333333333, '#c6341a'],
    [0.5372549019607843, '#c3331a'],
    [0.5411764705882353, '#c0331a'],
    [0.5450980392156862, '#bd331a'],
    [0.5490196078431373, '#ba321a'],
    [0.5529411764705883, '#b7321a'],
    [0.5568627450980392, '#b4321a'],
    [0.5607843137254902, '#b0321a'],
    [0.5647058823529412, '#ad321a'],
    [0.5686274509803921, '#aa321a'],
    [0.5725490196078431, '#a7311a'],
    [0.5764705882352941, '#a4311b'],
    [0.580392156862745, '#a1311b'],
    [0.5843137254901961, '#9d311b'],
    [0.5882352941176471, '#9a311b'],
    [0.592156862745098, '#97301b'],
    [0.596078431372549, '#94301b'],
    [0.6, '#91301b'],
    [0.6039215686274509, '#8e2f1b'],
    [0.6078431372549019, '#8b2f1c'],
    [0.611764705882353, '#882f1c'],
    [0.615686274509804, '#842f1c'],
    [0.6196078431372549, '#812e1c'],
    [0.6235294117647059, '#7e2e1c'],
    [0.6274509803921569, '#7b2d1c'],
    [0.6313725490196078, '#782d1c'],
    [0.6352941176470588, '#752d1c'],
    [0.6392156862745098, '#722c1c'],
    [0.6431372549019607, '#6f2c1c'],
    [0.6470588235294118, '#6c2b1c'],
    [0.6509803921568628, '#692b1c'],
    [0.6549019607843137, '#662b1c'],
    [0.6588235294117647, '#632a1c'],
    [0.6627450980392157, '#602a1c'],
    [0.6666666666666666, '#5d291c'],
    [0.6705882352941176, '#5a291c'],
    [0.6745098039215687, '#57281c'],
    [0.6784313725490196, '#54281c'],
    [0.6823529411764706, '#51271c'],
    [0.6862745098039216, '#4f261c'],
    [0.6901960784313725, '#4c261c'],
    [0.6941176470588235, '#49251c'],
    [0.6980392156862745, '#46251c'],
    [0.7019607843137254, '#43241c'],
    [0.7058823529411764, '#40241d'],
    [0.7098039215686275, '#3e231d'],
    [0.7137254901960784, '#3b231d'],
    [0.7176470588235294, '#39221d'],
    [0.7215686274509804, '#36221e'],
    [0.7254901960784313, '#34211e'],
    [0.7294117647058823, '#32211f'],
    [0.7333333333333333, '#30211f'],
    [0.7372549019607844, '#2e2120'],
    [0.7411764705882353, '#2c2121'],
    [0.7450980392156863, '#2b2123'],
    [0.7490196078431373, '#2a2124'],
    [0.7529411764705882, '#292126'],
    [0.7568627450980392, '#282127'],
    [0.7607843137254902, '#272229'],
    [0.7647058823529411, '#26222c'],
    [0.7686274509803921, '#26232e'],
    [0.7725490196078432, '#262430'],
    [0.7764705882352941, '#262433'],
    [0.7803921568627451, '#262536'],
    [0.7843137254901961, '#262638'],
    [0.788235294117647, '#27273b'],
    [0.792156862745098, '#27283e'],
    [0.796078431372549, '#282941'],
    [0.8, '#282a45'],
    [0.803921568627451, '#292c48'],
    [0.807843137254902, '#292d4b'],
    [0.8117647058823529, '#2a2e4e'],
    [0.8156862745098039, '#2a2f51'],
    [0.8196078431372549, '#2b3055'],
    [0.8235294117647058, '#2b3258'],
    [0.8274509803921568, '#2c335c'],
    [0.8313725490196078, '#2c345f'],
    [0.8352941176470589, '#2d3562'],
    [0.8392156862745098, '#2d3766'],
    [0.8431372549019608, '#2e3869'],
    [0.8470588235294118, '#2e396d'],
    [0.8509803921568627, '#2e3a70'],
    [0.8549019607843137, '#2f3c74'],
    [0.8588235294117647, '#2f3d77'],
    [0.8627450980392157, '#2f3e7b'],
    [0.8666666666666667, '#2f3f7f'],
    [0.8705882352941177, '#2f4182'],
    [0.8745098039215686, '#304286'],
    [0.8784313725490196, '#304389'],
    [0.8823529411764706, '#30458d'],
    [0.8862745098039215, '#304691'],
    [0.8901960784313725, '#2f4795'],
    [0.8941176470588235, '#2f4898'],
    [0.8980392156862745, '#2f4a9c'],
    [0.9019607843137255, '#2f4ba0'],
    [0.9058823529411765, '#2e4ca3'],
    [0.9098039215686274, '#2e4ea7'],
    [0.9137254901960784, '#2e4fab'],
    [0.9176470588235294, '#2d50af'],
    [0.9215686274509803, '#2d52b3'],
    [0.9254901960784314, '#2c53b6'],
    [0.9294117647058824, '#2b55ba'],
    [0.9333333333333333, '#2a56be'],
    [0.9372549019607843, '#2a57c2'],
    [0.9411764705882353, '#2959c6'],
    [0.9450980392156862, '#285aca'],
    [0.9490196078431372, '#275bcd'],
    [0.9529411764705882, '#265dd1'],
    [0.9568627450980391, '#255ed5'],
    [0.9607843137254902, '#2460d8'],
    [0.9647058823529412, '#2361dc'],
    [0.9686274509803922, '#2362e0'],
    [0.9725490196078431, '#2364e3'],
    [0.9764705882352941, '#2365e6'],
    [0.9803921568627451, '#2367e9'],
    [0.984313725490196, '#2568ec'],
    [0.9882352941176471, '#266aef'],
    [0.9921568627450981, '#296bf1'],
    [0.996078431372549, '#2c6df3'],
    [1.0, '#2f6ef5'],
  ],
  CET_C4: [
    [0.0, '#ded5d8'],
    [0.00392156862745098, '#dfd4d6'],
    [0.00784313725490196, '#e0d3d4'],
    [0.011764705882352941, '#e1d2d1'],
    [0.01568627450980392, '#e2d1cf'],
    [0.0196078431372549, '#e3cfcc'],
    [0.023529411764705882, '#e4cec9'],
    [0.027450980392156862, '#e4ccc6'],
    [0.03137254901960784, '#e5c9c3'],
    [0.03529411764705882, '#e5c7c0'],
    [0.0392156862745098, '#e5c5bc'],
    [0.043137254901960784, '#e6c2b9'],
    [0.047058823529411764, '#e6c0b5'],
    [0.050980392156862744, '#e6bdb2'],
    [0.054901960784313725, '#e6baae'],
    [0.058823529411764705, '#e6b8ab'],
    [0.06274509803921569, '#e6b5a7'],
    [0.06666666666666667, '#e6b2a4'],
    [0.07058823529411765, '#e6afa0'],
    [0.07450980392156863, '#e6ac9d'],
    [0.0784313725490196, '#e5aa99'],
    [0.08235294117647059, '#e5a796'],
    [0.08627450980392157, '#e5a492'],
    [0.09019607843137255, '#e5a18f'],
    [0.09411764705882353, '#e49e8b'],
    [0.09803921568627451, '#e49b88'],
    [0.10196078431372549, '#e49984'],
    [0.10588235294117647, '#e39681'],
    [0.10980392156862745, '#e3937d'],
    [0.11372549019607843, '#e2907a'],
    [0.11764705882352941, '#e28d77'],
    [0.12156862745098039, '#e18a73'],
    [0.12549019607843137, '#e18870'],
    [0.12941176470588234, '#e0856c'],
    [0.13333333333333333, '#e08269'],
    [0.13725490196078433, '#df7f66'],
    [0.1411764705882353, '#de7c62'],
    [0.14509803921568626, '#de795f'],
    [0.14901960784313725, '#dd765c'],
    [0.15294117647058825, '#dc7358'],
    [0.1568627450980392, '#db7055'],
    [0.16078431372549018, '#db6d52'],
    [0.16470588235294117, '#da6a4f'],
    [0.16862745098039217, '#d9674b'],
    [0.17254901960784313, '#d86448'],
    [0.1764705882352941, '#d76145'],
    [0.1803921568627451, '#d65e41'],
    [0.1843137254901961, '#d55b3e'],
    [0.18823529411764706, '#d5583b'],
    [0.19215686274509802, '#d45538'],
    [0.19607843137254902, '#d35135'],
    [0.2, '#d24e32'],
    [0.20392156862745098, '#d14b2e'],
    [0.20784313725490194, '#d0482b'],
    [0.21176470588235294, '#cf4529'],
    [0.21568627450980393, '#ce4226'],
    [0.2196078431372549, '#cd3f23'],
    [0.22352941176470587, '#cc3c21'],
    [0.22745098039215686, '#cb391e'],
    [0.23137254901960785, '#cb371c'],
    [0.23529411764705882, '#ca351a'],
    [0.2392156862745098, '#c93319'],
    [0.24313725490196078, '#c93218'],
    [0.24705882352941178, '#c93117'],
    [0.25098039215686274, '#c93016'],
    [0.2549019607843137, '#c93017'],
    [0.2588235294117647, '#c93117'],
    [0.2627450980392157, '#c93218'],
    [0.26666666666666666, '#ca3319'],
    [0.27058823529411763, '#ca351b'],
    [0.27450980392156865, '#cb371d'],
    [0.2784313725490196, '#cb3a1f'],
    [0.2823529411764706, '#cc3d21'],
    [0.28627450980392155, '#cd4024'],
    [0.2901960784313725, '#ce4226'],
    [0.29411764705882354, '#cf4629'],
    [0.2980392156862745, '#d0492c'],
    [0.30196078431372547, '#d14c2f'],
    [0.3058823529411765, '#d24f32'],
    [0.30980392156862746, '#d35236'],
    [0.3137254901960784, '#d45539'],
    [0.3176470588235294, '#d5593c'],
    [0.32156862745098036, '#d65c3f'],
    [0.3254901960784314, '#d75f42'],
    [0.32941176470588235, '#d86246'],
    [0.3333333333333333, '#d86549'],
    [0.33725490196078434, '#d9684c'],
    [0.3411764705882353, '#da6b4f'],
    [0.34509803921568627, '#db6e53'],
    [0.34901960784313724, '#dc7156'],
    [0.3529411764705882, '#dc7459'],
    [0.3568627450980392, '#dd775d'],
    [0.3607843137254902, '#de7a60'],
    [0.36470588235294116, '#df7d63'],
    [0.3686274509803922, '#df8067'],
    [0.37254901960784315, '#e0836a'],
    [0.3764705882352941, '#e0856d'],
    [0.3803921568627451, '#e18871'],
    [0.38431372549019605, '#e28b74'],
    [0.38823529411764707, '#e28e78'],
    [0.39215686274509803, '#e2917b'],
    [0.396078431372549, '#e3947e'],
    [0.4, '#e39782'],
    [0.403921568627451, '#e49985'],
    [0.40784313725490196, '#e49c89'],
    [0.4117647058823529, '#e49f8c'],
    [0.4156862745098039, '#e5a290'],
    [0.4196078431372549, '#e5a593'],
    [0.4235294117647059, '#e5a897'],
    [0.42745098039215684, '#e5aa9a'],
    [0.43137254901960786, '#e6ad9e'],
    [0.43529411764705883, '#e6b0a1'],
    [0.4392156862745098, '#e6b3a5'],
    [0.44313725490196076, '#e6b5a8'],
    [0.44705882352941173, '#e6b8ac'],
    [0.45098039215686275, '#e6bbaf'],
    [0.4549019607843137, '#e6bdb3'],
    [0.4588235294117647, '#e5c0b6'],
    [0.4627450980392157, '#e5c2ba'],
    [0.4666666666666667, '#e5c5bd'],
    [0.47058823529411764, '#e4c7c1'],
    [0.4745098039215686, '#e4c9c4'],
    [0.4784313725490196, '#e3cbc7'],
    [0.4823529411764706, '#e2cdca'],
    [0.48627450980392156, '#e1cecd'],
    [0.49019607843137253, '#e0cfd0'],
    [0.49411764705882355, '#dfd0d2'],
    [0.4980392156862745, '#ddd1d5'],
    [0.5019607843137255, '#dcd1d7'],
    [0.5058823529411764, '#dad1d9'],
    [0.5098039215686274, '#d8d1db'],
    [0.5137254901960784, '#d7d0dc'],
    [0.5176470588235293, '#d5d0de'],
    [0.5215686274509804, '#d3cfdf'],
    [0.5254901960784314, '#d0cde0'],
    [0.5294117647058824, '#cecce1'],
    [0.5333333333333333, '#cccae1'],
    [0.5372549019607843, '#cac9e2'],
    [0.5411764705882353, '#c7c7e2'],
    [0.5450980392156862, '#c5c5e3'],
    [0.5490196078431373, '#c3c3e3'],
    [0.5529411764705883, '#c0c1e3'],
    [0.5568627450980392, '#bebee4'],
    [0.5607843137254902, '#bbbce4'],
    [0.5647058823529412, '#b9bae4'],
    [0.5686274509803921, '#b6b8e4'],
    [0.5725490196078431, '#b4b6e4'],
    [0.5764705882352941, '#b1b3e4'],
    [0.580392156862745, '#afb1e4'],
    [0.5843137254901961, '#acafe4'],
    [0.5882352941176471, '#aaade4'],
    [0.592156862745098, '#a7aae4'],
    [0.596078431372549, '#a4a8e4'],
    [0.6, '#a2a6e5'],
    [0.6039215686274509, '#9fa4e5'],
    [0.6078431372549019, '#9ca2e5'],
    [0.611764705882353, '#999fe5'],
    [0.615686274509804, '#979de5'],
    [0.6196078431372549, '#949be5'],
    [0.6235294117647059, '#9199e5'],
    [0.6274509803921569, '#8e97e5'],
    [0.6313725490196078, '#8b95e5'],
    [0.6352941176470588, '#8892e5'],
    [0.6392156862745098, '#8590e5'],
    [0.6431372549019607, '#828ee5'],
    [0.6470588235294118, '#7f8ce5'],
    [0.6509803921568628, '#7c8ae5'],
    [0.6549019607843137, '#7988e5'],
    [0.6588235294117647, '#7586e5'],
    [0.6627450980392157, '#7284e5'],
    [0.6666666666666666, '#6e82e5'],
    [0.6705882352941176, '#6b7fe5'],
    [0.6745098039215687, '#677de5'],
    [0.6784313725490196, '#637be5'],
    [0.6823529411764706, '#5f79e5'],
    [0.6862745098039216, '#5b77e5'],
    [0.6901960784313725, '#5775e5'],
    [0.6941176470588235, '#5373e5'],
    [0.6980392156862745, '#4f72e5'],
    [0.7019607843137254, '#4a70e5'],
    [0.7058823529411764, '#456ee4'],
    [0.7098039215686275, '#416ce4'],
    [0.7137254901960784, '#3c6be4'],
    [0.7176470588235294, '#3769e4'],
    [0.7215686274509804, '#3268e4'],
    [0.7254901960784313, '#2d66e4'],
    [0.7294117647058823, '#2865e4'],
    [0.7333333333333333, '#2364e4'],
    [0.7372549019607844, '#1f64e4'],
    [0.7411764705882353, '#1b63e4'],
    [0.7450980392156863, '#1963e4'],
    [0.7490196078431373, '#1963e4'],
    [0.7529411764705882, '#1a63e4'],
    [0.7568627450980392, '#1c63e4'],
    [0.7607843137254902, '#2064e4'],
    [0.7647058823529411, '#2465e4'],
    [0.7686274509803921, '#2966e4'],
    [0.7725490196078432, '#2e67e4'],
    [0.7764705882352941, '#3368e4'],
    [0.7803921568627451, '#3869e4'],
    [0.7843137254901961, '#3d6be4'],
    [0.788235294117647, '#426de4'],
    [0.792156862745098, '#466ee5'],
    [0.796078431372549, '#4b70e5'],
    [0.8, '#5072e5'],
    [0.803921568627451, '#5474e5'],
    [0.807843137254902, '#5876e5'],
    [0.8117647058823529, '#5c78e5'],
    [0.8156862745098039, '#607ae5'],
    [0.8196078431372549, '#647ce5'],
    [0.8235294117647058, '#687ee5'],
    [0.8274509803921568, '#6c80e5'],
    [0.8313725490196078, '#6f82e5'],
    [0.8352941176470589, '#7384e5'],
    [0.8392156862745098, '#7686e5'],
    [0.8431372549019608, '#7988e5'],
    [0.8470588235294118, '#7c8ae5'],
    [0.8509803921568627, '#808de5'],
    [0.8549019607843137, '#838fe5'],
    [0.8588235294117647, '#8691e5'],
    [0.8627450980392157, '#8993e5'],
    [0.8666666666666667, '#8c95e5'],
    [0.8705882352941177, '#8f97e5'],
    [0.8745098039215686, '#9299e5'],
    [0.8784313725490196, '#949ce5'],
    [0.8823529411764706, '#979ee5'],
    [0.8862745098039215, '#9aa0e5'],
    [0.8901960784313725, '#9da2e5'],
    [0.8941176470588235, '#a0a4e5'],
    [0.8980392156862745, '#a2a6e5'],
    [0.9019607843137255, '#a5a9e4'],
    [0.9058823529411765, '#a8abe4'],
    [0.9098039215686274, '#aaade4'],
    [0.9137254901960784, '#adafe4'],
    [0.9176470588235294, '#afb2e4'],
    [0.9215686274509803, '#b2b4e4'],
    [0.9254901960784314, '#b4b6e4'],
    [0.9294117647058824, '#b7b8e4'],
    [0.9333333333333333, '#b9bbe4'],
    [0.9372549019607843, '#bcbde4'],
    [0.9411764705882353, '#bebfe4'],
    [0.9450980392156862, '#c1c1e4'],
    [0.9490196078431372, '#c3c3e3'],
    [0.9529411764705882, '#c5c6e3'],
    [0.9568627450980391, '#c8c8e3'],
    [0.9607843137254902, '#cacae3'],
    [0.9647058823529412, '#cccce2'],
    [0.9686274509803922, '#cecde2'],
    [0.9725490196078431, '#d1cfe1'],
    [0.9764705882352941, '#d3d1e1'],
    [0.9803921568627451, '#d5d2e0'],
    [0.984313725490196, '#d6d3df'],
    [0.9882352941176471, '#d8d4de'],
    [0.9921568627450981, '#dad4dd'],
    [0.996078431372549, '#dbd5db'],
    [1.0, '#ddd5da'],
  ],
  CET_C4s: [
    [0.0, '#1a63e4'],
    [0.00392156862745098, '#1c63e4'],
    [0.00784313725490196, '#2064e4'],
    [0.011764705882352941, '#2465e4'],
    [0.01568627450980392, '#2966e4'],
    [0.0196078431372549, '#2e67e4'],
    [0.023529411764705882, '#3368e4'],
    [0.027450980392156862, '#3869e4'],
    [0.03137254901960784, '#3d6be4'],
    [0.03529411764705882, '#426de4'],
    [0.0392156862745098, '#466ee5'],
    [0.043137254901960784, '#4b70e5'],
    [0.047058823529411764, '#5072e5'],
    [0.050980392156862744, '#5474e5'],
    [0.054901960784313725, '#5876e5'],
    [0.058823529411764705, '#5c78e5'],
    [0.06274509803921569, '#607ae5'],
    [0.06666666666666667, '#647ce5'],
    [0.07058823529411765, '#687ee5'],
    [0.07450980392156863, '#6c80e5'],
    [0.0784313725490196, '#6f82e5'],
    [0.08235294117647059, '#7384e5'],
    [0.08627450980392157, '#7686e5'],
    [0.09019607843137255, '#7988e5'],
    [0.09411764705882353, '#7c8ae5'],
    [0.09803921568627451, '#808de5'],
    [0.10196078431372549, '#838fe5'],
    [0.10588235294117647, '#8691e5'],
    [0.10980392156862745, '#8993e5'],
    [0.11372549019607843, '#8c95e5'],
    [0.11764705882352941, '#8f97e5'],
    [0.12156862745098039, '#9299e5'],
    [0.12549019607843137, '#949ce5'],
    [0.12941176470588234, '#979ee5'],
    [0.13333333333333333, '#9aa0e5'],
    [0.13725490196078433, '#9da2e5'],
    [0.1411764705882353, '#a0a4e5'],
    [0.14509803921568626, '#a2a6e5'],
    [0.14901960784313725, '#a5a9e4'],
    [0.15294117647058825, '#a8abe4'],
    [0.1568627450980392, '#aaade4'],
    [0.16078431372549018, '#adafe4'],
    [0.16470588235294117, '#afb2e4'],
    [0.16862745098039217, '#b2b4e4'],
    [0.17254901960784313, '#b4b6e4'],
    [0.1764705882352941, '#b7b8e4'],
    [0.1803921568627451, '#b9bbe4'],
    [0.1843137254901961, '#bcbde4'],
    [0.18823529411764706, '#bebfe4'],
    [0.19215686274509802, '#c1c1e4'],
    [0.19607843137254902, '#c3c3e3'],
    [0.2, '#c5c6e3'],
    [0.20392156862745098, '#c8c8e3'],
    [0.20784313725490194, '#cacae3'],
    [0.21176470588235294, '#cccce2'],
    [0.21568627450980393, '#cecde2'],
    [0.2196078431372549, '#d1cfe1'],
    [0.22352941176470587, '#d3d1e1'],
    [0.22745098039215686, '#d5d2e0'],
    [0.23137254901960785, '#d6d3df'],
    [0.23529411764705882, '#d8d4de'],
    [0.2392156862745098, '#dad4dd'],
    [0.24313725490196078, '#dbd5db'],
    [0.24705882352941178, '#ddd5da'],
    [0.25098039215686274, '#ded5d8'],
    [0.2549019607843137, '#dfd4d6'],
    [0.2588235294117647, '#e0d3d4'],
    [0.2627450980392157, '#e1d2d1'],
    [0.26666666666666666, '#e2d1cf'],
    [0.27058823529411763, '#e3cfcc'],
    [0.27450980392156865, '#e4cec9'],
    [0.2784313725490196, '#e4ccc6'],
    [0.2823529411764706, '#e5c9c3'],
    [0.28627450980392155, '#e5c7c0'],
    [0.2901960784313725, '#e5c5bc'],
    [0.29411764705882354, '#e6c2b9'],
    [0.2980392156862745, '#e6c0b5'],
    [0.30196078431372547, '#e6bdb2'],
    [0.3058823529411765, '#e6baae'],
    [0.30980392156862746, '#e6b8ab'],
    [0.3137254901960784, '#e6b5a7'],
    [0.3176470588235294, '#e6b2a4'],
    [0.32156862745098036, '#e6afa0'],
    [0.3254901960784314, '#e6ac9d'],
    [0.32941176470588235, '#e5aa99'],
    [0.3333333333333333, '#e5a796'],
    [0.33725490196078434, '#e5a492'],
    [0.3411764705882353, '#e5a18f'],
    [0.34509803921568627, '#e49e8b'],
    [0.34901960784313724, '#e49b88'],
    [0.3529411764705882, '#e49984'],
    [0.3568627450980392, '#e39681'],
    [0.3607843137254902, '#e3937d'],
    [0.36470588235294116, '#e2907a'],
    [0.3686274509803922, '#e28d77'],
    [0.37254901960784315, '#e18a73'],
    [0.3764705882352941, '#e18870'],
    [0.3803921568627451, '#e0856c'],
    [0.38431372549019605, '#e08269'],
    [0.38823529411764707, '#df7f66'],
    [0.39215686274509803, '#de7c62'],
    [0.396078431372549, '#de795f'],
    [0.4, '#dd765c'],
    [0.403921568627451, '#dc7358'],
    [0.40784313725490196, '#db7055'],
    [0.4117647058823529, '#db6d52'],
    [0.4156862745098039, '#da6a4f'],
    [0.4196078431372549, '#d9674b'],
    [0.4235294117647059, '#d86448'],
    [0.42745098039215684, '#d76145'],
    [0.43137254901960786, '#d65e41'],
    [0.43529411764705883, '#d55b3e'],
    [0.4392156862745098, '#d5583b'],
    [0.44313725490196076, '#d45538'],
    [0.44705882352941173, '#d35135'],
    [0.45098039215686275, '#d24e32'],
    [0.4549019607843137, '#d14b2e'],
    [0.4588235294117647, '#d0482b'],
    [0.4627450980392157, '#cf4529'],
    [0.4666666666666667, '#ce4226'],
    [0.47058823529411764, '#cd3f23'],
    [0.4745098039215686, '#cc3c21'],
    [0.4784313725490196, '#cb391e'],
    [0.4823529411764706, '#cb371c'],
    [0.48627450980392156, '#ca351a'],
    [0.49019607843137253, '#c93319'],
    [0.49411764705882355, '#c93218'],
    [0.4980392156862745, '#c93117'],
    [0.5019607843137255, '#c93016'],
    [0.5058823529411764, '#c93017'],
    [0.5098039215686274, '#c93117'],
    [0.5137254901960784, '#c93218'],
    [0.5176470588235293, '#ca3319'],
    [0.5215686274509804, '#ca351b'],
    [0.5254901960784314, '#cb371d'],
    [0.5294117647058824, '#cb3a1f'],
    [0.5333333333333333, '#cc3d21'],
    [0.5372549019607843, '#cd4024'],
    [0.5411764705882353, '#ce4226'],
    [0.5450980392156862, '#cf4629'],
    [0.5490196078431373, '#d0492c'],
    [0.5529411764705883, '#d14c2f'],
    [0.5568627450980392, '#d24f32'],
    [0.5607843137254902, '#d35236'],
    [0.5647058823529412, '#d45539'],
    [0.5686274509803921, '#d5593c'],
    [0.5725490196078431, '#d65c3f'],
    [0.5764705882352941, '#d75f42'],
    [0.580392156862745, '#d86246'],
    [0.5843137254901961, '#d86549'],
    [0.5882352941176471, '#d9684c'],
    [0.592156862745098, '#da6b4f'],
    [0.596078431372549, '#db6e53'],
    [0.6, '#dc7156'],
    [0.6039215686274509, '#dc7459'],
    [0.6078431372549019, '#dd775d'],
    [0.611764705882353, '#de7a60'],
    [0.615686274509804, '#df7d63'],
    [0.6196078431372549, '#df8067'],
    [0.6235294117647059, '#e0836a'],
    [0.6274509803921569, '#e0856d'],
    [0.6313725490196078, '#e18871'],
    [0.6352941176470588, '#e28b74'],
    [0.6392156862745098, '#e28e78'],
    [0.6431372549019607, '#e2917b'],
    [0.6470588235294118, '#e3947e'],
    [0.6509803921568628, '#e39782'],
    [0.6549019607843137, '#e49985'],
    [0.6588235294117647, '#e49c89'],
    [0.6627450980392157, '#e49f8c'],
    [0.6666666666666666, '#e5a290'],
    [0.6705882352941176, '#e5a593'],
    [0.6745098039215687, '#e5a897'],
    [0.6784313725490196, '#e5aa9a'],
    [0.6823529411764706, '#e6ad9e'],
    [0.6862745098039216, '#e6b0a1'],
    [0.6901960784313725, '#e6b3a5'],
    [0.6941176470588235, '#e6b5a8'],
    [0.6980392156862745, '#e6b8ac'],
    [0.7019607843137254, '#e6bbaf'],
    [0.7058823529411764, '#e6bdb3'],
    [0.7098039215686275, '#e5c0b6'],
    [0.7137254901960784, '#e5c2ba'],
    [0.7176470588235294, '#e5c5bd'],
    [0.7215686274509804, '#e4c7c1'],
    [0.7254901960784313, '#e4c9c4'],
    [0.7294117647058823, '#e3cbc7'],
    [0.7333333333333333, '#e2cdca'],
    [0.7372549019607844, '#e1cecd'],
    [0.7411764705882353, '#e0cfd0'],
    [0.7450980392156863, '#dfd0d2'],
    [0.7490196078431373, '#ddd1d5'],
    [0.7529411764705882, '#dcd1d7'],
    [0.7568627450980392, '#dad1d9'],
    [0.7607843137254902, '#d8d1db'],
    [0.7647058823529411, '#d7d0dc'],
    [0.7686274509803921, '#d5d0de'],
    [0.7725490196078432, '#d3cfdf'],
    [0.7764705882352941, '#d0cde0'],
    [0.7803921568627451, '#cecce1'],
    [0.7843137254901961, '#cccae1'],
    [0.788235294117647, '#cac9e2'],
    [0.792156862745098, '#c7c7e2'],
    [0.796078431372549, '#c5c5e3'],
    [0.8, '#c3c3e3'],
    [0.803921568627451, '#c0c1e3'],
    [0.807843137254902, '#bebee4'],
    [0.8117647058823529, '#bbbce4'],
    [0.8156862745098039, '#b9bae4'],
    [0.8196078431372549, '#b6b8e4'],
    [0.8235294117647058, '#b4b6e4'],
    [0.8274509803921568, '#b1b3e4'],
    [0.8313725490196078, '#afb1e4'],
    [0.8352941176470589, '#acafe4'],
    [0.8392156862745098, '#aaade4'],
    [0.8431372549019608, '#a7aae4'],
    [0.8470588235294118, '#a4a8e4'],
    [0.8509803921568627, '#a2a6e5'],
    [0.8549019607843137, '#9fa4e5'],
    [0.8588235294117647, '#9ca2e5'],
    [0.8627450980392157, '#999fe5'],
    [0.8666666666666667, '#979de5'],
    [0.8705882352941177, '#949be5'],
    [0.8745098039215686, '#9199e5'],
    [0.8784313725490196, '#8e97e5'],
    [0.8823529411764706, '#8b95e5'],
    [0.8862745098039215, '#8892e5'],
    [0.8901960784313725, '#8590e5'],
    [0.8941176470588235, '#828ee5'],
    [0.8980392156862745, '#7f8ce5'],
    [0.9019607843137255, '#7c8ae5'],
    [0.9058823529411765, '#7988e5'],
    [0.9098039215686274, '#7586e5'],
    [0.9137254901960784, '#7284e5'],
    [0.9176470588235294, '#6e82e5'],
    [0.9215686274509803, '#6b7fe5'],
    [0.9254901960784314, '#677de5'],
    [0.9294117647058824, '#637be5'],
    [0.9333333333333333, '#5f79e5'],
    [0.9372549019607843, '#5b77e5'],
    [0.9411764705882353, '#5775e5'],
    [0.9450980392156862, '#5373e5'],
    [0.9490196078431372, '#4f72e5'],
    [0.9529411764705882, '#4a70e5'],
    [0.9568627450980391, '#456ee4'],
    [0.9607843137254902, '#416ce4'],
    [0.9647058823529412, '#3c6be4'],
    [0.9686274509803922, '#3769e4'],
    [0.9725490196078431, '#3268e4'],
    [0.9764705882352941, '#2d66e4'],
    [0.9803921568627451, '#2865e4'],
    [0.984313725490196, '#2364e4'],
    [0.9882352941176471, '#1f64e4'],
    [0.9921568627450981, '#1b63e4'],
    [0.996078431372549, '#1963e4'],
    [1.0, '#1963e4'],
  ],
  CET_C5: [
    [0.0, '#777777'],
    [0.00392156862745098, '#787878'],
    [0.00784313725490196, '#7a7a7a'],
    [0.011764705882352941, '#7b7b7b'],
    [0.01568627450980392, '#7c7c7c'],
    [0.0196078431372549, '#7e7e7e'],
    [0.023529411764705882, '#7f7f7f'],
    [0.027450980392156862, '#808080'],
    [0.03137254901960784, '#828282'],
    [0.03529411764705882, '#838383'],
    [0.0392156862745098, '#848484'],
    [0.043137254901960784, '#868686'],
    [0.047058823529411764, '#878787'],
    [0.050980392156862744, '#888888'],
    [0.054901960784313725, '#8a8a8a'],
    [0.058823529411764705, '#8b8b8b'],
    [0.06274509803921569, '#8d8d8d'],
    [0.06666666666666667, '#8e8e8e'],
    [0.07058823529411765, '#8f8f8f'],
    [0.07450980392156863, '#919191'],
    [0.0784313725490196, '#929292'],
    [0.08235294117647059, '#949494'],
    [0.08627450980392157, '#959595'],
    [0.09019607843137255, '#969696'],
    [0.09411764705882353, '#989898'],
    [0.09803921568627451, '#999999'],
    [0.10196078431372549, '#9b9b9b'],
    [0.10588235294117647, '#9c9c9c'],
    [0.10980392156862745, '#9e9e9e'],
    [0.11372549019607843, '#9f9f9f'],
    [0.11764705882352941, '#a0a0a0'],
    [0.12156862745098039, '#a2a2a2'],
    [0.12549019607843137, '#a3a3a3'],
    [0.12941176470588234, '#a5a5a5'],
    [0.13333333333333333, '#a6a6a6'],
    [0.13725490196078433, '#a8a8a8'],
    [0.1411764705882353, '#a9a9a9'],
    [0.14509803921568626, '#aaabab'],
    [0.14901960784313725, '#acacac'],
    [0.15294117647058825, '#adadad'],
    [0.1568627450980392, '#afafaf'],
    [0.16078431372549018, '#b0b0b0'],
    [0.16470588235294117, '#b2b2b2'],
    [0.16862745098039217, '#b3b3b3'],
    [0.17254901960784313, '#b5b5b5'],
    [0.1764705882352941, '#b6b6b6'],
    [0.1803921568627451, '#b8b8b8'],
    [0.1843137254901961, '#b9b9b9'],
    [0.18823529411764706, '#bbbbbb'],
    [0.19215686274509802, '#bcbcbc'],
    [0.19607843137254902, '#bdbdbd'],
    [0.2, '#bfbfbf'],
    [0.20392156862745098, '#c0c0c0'],
    [0.20784313725490194, '#c1c2c2'],
    [0.21176470588235294, '#c3c3c3'],
    [0.21568627450980393, '#c4c4c4'],
    [0.2196078431372549, '#c5c5c5'],
    [0.22352941176470587, '#c6c6c6'],
    [0.22745098039215686, '#c7c7c7'],
    [0.23137254901960785, '#c8c8c8'],
    [0.23529411764705882, '#c9c9c9'],
    [0.2392156862745098, '#c9c9c9'],
    [0.24313725490196078, '#c9c9c9'],
    [0.24705882352941178, '#cacaca'],
    [0.25098039215686274, '#cacaca'],
    [0.2549019607843137, '#c9c9c9'],
    [0.2588235294117647, '#c9c9c9'],
    [0.2627450980392157, '#c9c9c9'],
    [0.26666666666666666, '#c8c8c8'],
    [0.27058823529411763, '#c7c7c7'],
    [0.27450980392156865, '#c6c6c6'],
    [0.2784313725490196, '#c5c5c5'],
    [0.2823529411764706, '#c4c4c4'],
    [0.28627450980392155, '#c3c3c3'],
    [0.2901960784313725, '#c2c2c2'],
    [0.29411764705882354, '#c0c1c1'],
    [0.2980392156862745, '#bfbfbf'],
    [0.30196078431372547, '#bebebe'],
    [0.3058823529411765, '#bcbcbc'],
    [0.30980392156862746, '#bbbbbb'],
    [0.3137254901960784, '#b9b9b9'],
    [0.3176470588235294, '#b8b8b8'],
    [0.32156862745098036, '#b7b7b7'],
    [0.3254901960784314, '#b5b5b5'],
    [0.32941176470588235, '#b4b4b4'],
    [0.3333333333333333, '#b2b2b2'],
    [0.33725490196078434, '#b1b1b1'],
    [0.3411764705882353, '#afafaf'],
    [0.34509803921568627, '#aeaeae'],
    [0.34901960784313724, '#acacac'],
    [0.3529411764705882, '#ababab'],
    [0.3568627450980392, '#a9a9a9'],
    [0.3607843137254902, '#a8a8a8'],
    [0.36470588235294116, '#a6a7a7'],
    [0.3686274509803922, '#a5a5a5'],
    [0.37254901960784315, '#a4a4a4'],
    [0.3764705882352941, '#a2a2a2'],
    [0.3803921568627451, '#a1a1a1'],
    [0.38431372549019605, '#9f9f9f'],
    [0.38823529411764707, '#9e9e9e'],
    [0.39215686274509803, '#9c9c9c'],
    [0.396078431372549, '#9b9b9b'],
    [0.4, '#9a9a9a'],
    [0.403921568627451, '#989898'],
    [0.40784313725490196, '#979797'],
    [0.4117647058823529, '#959595'],
    [0.4156862745098039, '#949494'],
    [0.4196078431372549, '#929292'],
    [0.4235294117647059, '#919191'],
    [0.42745098039215684, '#909090'],
    [0.43137254901960786, '#8e8e8e'],
    [0.43529411764705883, '#8d8d8d'],
    [0.4392156862745098, '#8b8b8b'],
    [0.44313725490196076, '#8a8a8a'],
    [0.44705882352941173, '#898989'],
    [0.45098039215686275, '#878787'],
    [0.4549019607843137, '#868686'],
    [0.4588235294117647, '#848484'],
    [0.4627450980392157, '#838383'],
    [0.4666666666666667, '#828282'],
    [0.47058823529411764, '#808080'],
    [0.4745098039215686, '#7f7f7f'],
    [0.4784313725490196, '#7e7e7e'],
    [0.4823529411764706, '#7c7c7c'],
    [0.48627450980392156, '#7b7b7b'],
    [0.49019607843137253, '#797979'],
    [0.49411764705882355, '#787878'],
    [0.4980392156862745, '#777777'],
    [0.5019607843137255, '#757575'],
    [0.5058823529411764, '#747474'],
    [0.5098039215686274, '#737373'],
    [0.5137254901960784, '#717171'],
    [0.5176470588235293, '#707070'],
    [0.5215686274509804, '#6f6f6f'],
    [0.5254901960784314, '#6d6d6d'],
    [0.5294117647058824, '#6c6c6c'],
    [0.5333333333333333, '#6b6b6b'],
    [0.5372549019607843, '#696969'],
    [0.5411764705882353, '#686868'],
    [0.5450980392156862, '#676767'],
    [0.5490196078431373, '#656565'],
    [0.5529411764705883, '#646464'],
    [0.5568627450980392, '#636363'],
    [0.5607843137254902, '#616161'],
    [0.5647058823529412, '#606060'],
    [0.5686274509803921, '#5f5f5f'],
    [0.5725490196078431, '#5d5d5d'],
    [0.5764705882352941, '#5c5c5c'],
    [0.580392156862745, '#5b5b5b'],
    [0.5843137254901961, '#595a5a'],
    [0.5882352941176471, '#585858'],
    [0.592156862745098, '#575757'],
    [0.596078431372549, '#565656'],
    [0.6, '#545454'],
    [0.6039215686274509, '#535353'],
    [0.6078431372549019, '#525252'],
    [0.611764705882353, '#515151'],
    [0.615686274509804, '#4f4f4f'],
    [0.6196078431372549, '#4e4e4e'],
    [0.6235294117647059, '#4d4d4d'],
    [0.6274509803921569, '#4b4b4b'],
    [0.6313725490196078, '#4a4a4a'],
    [0.6352941176470588, '#494949'],
    [0.6392156862745098, '#484848'],
    [0.6431372549019607, '#464646'],
    [0.6470588235294118, '#454545'],
    [0.6509803921568628, '#444444'],
    [0.6549019607843137, '#434343'],
    [0.6588235294117647, '#424242'],
    [0.6627450980392157, '#404040'],
    [0.6666666666666666, '#3f3f3f'],
    [0.6705882352941176, '#3e3e3e'],
    [0.6745098039215687, '#3d3d3d'],
    [0.6784313725490196, '#3b3b3b'],
    [0.6823529411764706, '#3a3a3a'],
    [0.6862745098039216, '#393939'],
    [0.6901960784313725, '#383838'],
    [0.6941176470588235, '#373737'],
    [0.6980392156862745, '#363636'],
    [0.7019607843137254, '#343434'],
    [0.7058823529411764, '#333333'],
    [0.7098039215686275, '#323232'],
    [0.7137254901960784, '#313131'],
    [0.7176470588235294, '#303030'],
    [0.7215686274509804, '#303030'],
    [0.7254901960784313, '#2f2f2f'],
    [0.7294117647058823, '#2e2e2e'],
    [0.7333333333333333, '#2e2e2e'],
    [0.7372549019607844, '#2d2d2d'],
    [0.7411764705882353, '#2d2d2d'],
    [0.7450980392156863, '#2d2d2d'],
    [0.7490196078431373, '#2d2d2d'],
    [0.7529411764705882, '#2d2d2d'],
    [0.7568627450980392, '#2d2d2d'],
    [0.7607843137254902, '#2d2d2d'],
    [0.7647058823529411, '#2d2e2e'],
    [0.7686274509803921, '#2e2e2e'],
    [0.7725490196078432, '#2f2f2f'],
    [0.7764705882352941, '#2f2f2f'],
    [0.7803921568627451, '#303030'],
    [0.7843137254901961, '#313131'],
    [0.788235294117647, '#323232'],
    [0.792156862745098, '#333333'],
    [0.796078431372549, '#343434'],
    [0.8, '#353535'],
    [0.803921568627451, '#363636'],
    [0.807843137254902, '#383838'],
    [0.8117647058823529, '#393939'],
    [0.8156862745098039, '#3a3a3a'],
    [0.8196078431372549, '#3b3b3b'],
    [0.8235294117647058, '#3c3c3c'],
    [0.8274509803921568, '#3e3e3e'],
    [0.8313725490196078, '#3f3f3f'],
    [0.8352941176470589, '#404040'],
    [0.8392156862745098, '#414141'],
    [0.8431372549019608, '#424242'],
    [0.8470588235294118, '#444444'],
    [0.8509803921568627, '#454545'],
    [0.8549019607843137, '#464646'],
    [0.8588235294117647, '#474747'],
    [0.8627450980392157, '#494949'],
    [0.8666666666666667, '#4a4a4a'],
    [0.8705882352941177, '#4b4b4b'],
    [0.8745098039215686, '#4c4c4c'],
    [0.8784313725490196, '#4e4e4e'],
    [0.8823529411764706, '#4f4f4f'],
    [0.8862745098039215, '#505050'],
    [0.8901960784313725, '#515151'],
    [0.8941176470588235, '#535353'],
    [0.8980392156862745, '#545454'],
    [0.9019607843137255, '#555555'],
    [0.9058823529411765, '#575757'],
    [0.9098039215686274, '#585858'],
    [0.9137254901960784, '#595959'],
    [0.9176470588235294, '#5a5b5a'],
    [0.9215686274509803, '#5c5c5c'],
    [0.9254901960784314, '#5d5d5d'],
    [0.9294117647058824, '#5e5e5e'],
    [0.9333333333333333, '#606060'],
    [0.9372549019607843, '#616161'],
    [0.9411764705882353, '#626262'],
    [0.9450980392156862, '#646464'],
    [0.9490196078431372, '#656565'],
    [0.9529411764705882, '#666666'],
    [0.9568627450980391, '#686868'],
    [0.9607843137254902, '#696969'],
    [0.9647058823529412, '#6a6a6a'],
    [0.9686274509803922, '#6b6b6b'],
    [0.9725490196078431, '#6d6d6d'],
    [0.9764705882352941, '#6e6e6e'],
    [0.9803921568627451, '#6f6f6f'],
    [0.984313725490196, '#717171'],
    [0.9882352941176471, '#727272'],
    [0.9921568627450981, '#737373'],
    [0.996078431372549, '#747474'],
    [1.0, '#767676'],
  ],
  CET_C5s: [
    [0.0, '#2d2d2d'],
    [0.00392156862745098, '#2d2d2d'],
    [0.00784313725490196, '#2d2d2d'],
    [0.011764705882352941, '#2d2e2e'],
    [0.01568627450980392, '#2e2e2e'],
    [0.0196078431372549, '#2f2f2f'],
    [0.023529411764705882, '#2f2f2f'],
    [0.027450980392156862, '#303030'],
    [0.03137254901960784, '#313131'],
    [0.03529411764705882, '#323232'],
    [0.0392156862745098, '#333333'],
    [0.043137254901960784, '#343434'],
    [0.047058823529411764, '#353535'],
    [0.050980392156862744, '#363636'],
    [0.054901960784313725, '#383838'],
    [0.058823529411764705, '#393939'],
    [0.06274509803921569, '#3a3a3a'],
    [0.06666666666666667, '#3b3b3b'],
    [0.07058823529411765, '#3c3c3c'],
    [0.07450980392156863, '#3e3e3e'],
    [0.0784313725490196, '#3f3f3f'],
    [0.08235294117647059, '#404040'],
    [0.08627450980392157, '#414141'],
    [0.09019607843137255, '#424242'],
    [0.09411764705882353, '#444444'],
    [0.09803921568627451, '#454545'],
    [0.10196078431372549, '#464646'],
    [0.10588235294117647, '#474747'],
    [0.10980392156862745, '#494949'],
    [0.11372549019607843, '#4a4a4a'],
    [0.11764705882352941, '#4b4b4b'],
    [0.12156862745098039, '#4c4c4c'],
    [0.12549019607843137, '#4e4e4e'],
    [0.12941176470588234, '#4f4f4f'],
    [0.13333333333333333, '#505050'],
    [0.13725490196078433, '#515151'],
    [0.1411764705882353, '#535353'],
    [0.14509803921568626, '#545454'],
    [0.14901960784313725, '#555555'],
    [0.15294117647058825, '#575757'],
    [0.1568627450980392, '#585858'],
    [0.16078431372549018, '#595959'],
    [0.16470588235294117, '#5a5b5a'],
    [0.16862745098039217, '#5c5c5c'],
    [0.17254901960784313, '#5d5d5d'],
    [0.1764705882352941, '#5e5e5e'],
    [0.1803921568627451, '#606060'],
    [0.1843137254901961, '#616161'],
    [0.18823529411764706, '#626262'],
    [0.19215686274509802, '#646464'],
    [0.19607843137254902, '#656565'],
    [0.2, '#666666'],
    [0.20392156862745098, '#686868'],
    [0.20784313725490194, '#696969'],
    [0.21176470588235294, '#6a6a6a'],
    [0.21568627450980393, '#6b6b6b'],
    [0.2196078431372549, '#6d6d6d'],
    [0.22352941176470587, '#6e6e6e'],
    [0.22745098039215686, '#6f6f6f'],
    [0.23137254901960785, '#717171'],
    [0.23529411764705882, '#727272'],
    [0.2392156862745098, '#737373'],
    [0.24313725490196078, '#747474'],
    [0.24705882352941178, '#767676'],
    [0.25098039215686274, '#777777'],
    [0.2549019607843137, '#787878'],
    [0.2588235294117647, '#7a7a7a'],
    [0.2627450980392157, '#7b7b7b'],
    [0.26666666666666666, '#7c7c7c'],
    [0.27058823529411763, '#7e7e7e'],
    [0.27450980392156865, '#7f7f7f'],
    [0.2784313725490196, '#808080'],
    [0.2823529411764706, '#828282'],
    [0.28627450980392155, '#838383'],
    [0.2901960784313725, '#848484'],
    [0.29411764705882354, '#868686'],
    [0.2980392156862745, '#878787'],
    [0.30196078431372547, '#888888'],
    [0.3058823529411765, '#8a8a8a'],
    [0.30980392156862746, '#8b8b8b'],
    [0.3137254901960784, '#8d8d8d'],
    [0.3176470588235294, '#8e8e8e'],
    [0.32156862745098036, '#8f8f8f'],
    [0.3254901960784314, '#919191'],
    [0.32941176470588235, '#929292'],
    [0.3333333333333333, '#949494'],
    [0.33725490196078434, '#959595'],
    [0.3411764705882353, '#969696'],
    [0.34509803921568627, '#989898'],
    [0.34901960784313724, '#999999'],
    [0.3529411764705882, '#9b9b9b'],
    [0.3568627450980392, '#9c9c9c'],
    [0.3607843137254902, '#9e9e9e'],
    [0.36470588235294116, '#9f9f9f'],
    [0.3686274509803922, '#a0a0a0'],
    [0.37254901960784315, '#a2a2a2'],
    [0.3764705882352941, '#a3a3a3'],
    [0.3803921568627451, '#a5a5a5'],
    [0.38431372549019605, '#a6a6a6'],
    [0.38823529411764707, '#a8a8a8'],
    [0.39215686274509803, '#a9a9a9'],
    [0.396078431372549, '#aaabab'],
    [0.4, '#acacac'],
    [0.403921568627451, '#adadad'],
    [0.40784313725490196, '#afafaf'],
    [0.4117647058823529, '#b0b0b0'],
    [0.4156862745098039, '#b2b2b2'],
    [0.4196078431372549, '#b3b3b3'],
    [0.4235294117647059, '#b5b5b5'],
    [0.42745098039215684, '#b6b6b6'],
    [0.43137254901960786, '#b8b8b8'],
    [0.43529411764705883, '#b9b9b9'],
    [0.4392156862745098, '#bbbbbb'],
    [0.44313725490196076, '#bcbcbc'],
    [0.44705882352941173, '#bdbdbd'],
    [0.45098039215686275, '#bfbfbf'],
    [0.4549019607843137, '#c0c0c0'],
    [0.4588235294117647, '#c1c2c2'],
    [0.4627450980392157, '#c3c3c3'],
    [0.4666666666666667, '#c4c4c4'],
    [0.47058823529411764, '#c5c5c5'],
    [0.4745098039215686, '#c6c6c6'],
    [0.4784313725490196, '#c7c7c7'],
    [0.4823529411764706, '#c8c8c8'],
    [0.48627450980392156, '#c9c9c9'],
    [0.49019607843137253, '#c9c9c9'],
    [0.49411764705882355, '#c9c9c9'],
    [0.4980392156862745, '#cacaca'],
    [0.5019607843137255, '#cacaca'],
    [0.5058823529411764, '#c9c9c9'],
    [0.5098039215686274, '#c9c9c9'],
    [0.5137254901960784, '#c9c9c9'],
    [0.5176470588235293, '#c8c8c8'],
    [0.5215686274509804, '#c7c7c7'],
    [0.5254901960784314, '#c6c6c6'],
    [0.5294117647058824, '#c5c5c5'],
    [0.5333333333333333, '#c4c4c4'],
    [0.5372549019607843, '#c3c3c3'],
    [0.5411764705882353, '#c2c2c2'],
    [0.5450980392156862, '#c0c1c1'],
    [0.5490196078431373, '#bfbfbf'],
    [0.5529411764705883, '#bebebe'],
    [0.5568627450980392, '#bcbcbc'],
    [0.5607843137254902, '#bbbbbb'],
    [0.5647058823529412, '#b9b9b9'],
    [0.5686274509803921, '#b8b8b8'],
    [0.5725490196078431, '#b7b7b7'],
    [0.5764705882352941, '#b5b5b5'],
    [0.580392156862745, '#b4b4b4'],
    [0.5843137254901961, '#b2b2b2'],
    [0.5882352941176471, '#b1b1b1'],
    [0.592156862745098, '#afafaf'],
    [0.596078431372549, '#aeaeae'],
    [0.6, '#acacac'],
    [0.6039215686274509, '#ababab'],
    [0.6078431372549019, '#a9a9a9'],
    [0.611764705882353, '#a8a8a8'],
    [0.615686274509804, '#a6a7a7'],
    [0.6196078431372549, '#a5a5a5'],
    [0.6235294117647059, '#a4a4a4'],
    [0.6274509803921569, '#a2a2a2'],
    [0.6313725490196078, '#a1a1a1'],
    [0.6352941176470588, '#9f9f9f'],
    [0.6392156862745098, '#9e9e9e'],
    [0.6431372549019607, '#9c9c9c'],
    [0.6470588235294118, '#9b9b9b'],
    [0.6509803921568628, '#9a9a9a'],
    [0.6549019607843137, '#989898'],
    [0.6588235294117647, '#979797'],
    [0.6627450980392157, '#959595'],
    [0.6666666666666666, '#949494'],
    [0.6705882352941176, '#929292'],
    [0.6745098039215687, '#919191'],
    [0.6784313725490196, '#909090'],
    [0.6823529411764706, '#8e8e8e'],
    [0.6862745098039216, '#8d8d8d'],
    [0.6901960784313725, '#8b8b8b'],
    [0.6941176470588235, '#8a8a8a'],
    [0.6980392156862745, '#898989'],
    [0.7019607843137254, '#878787'],
    [0.7058823529411764, '#868686'],
    [0.7098039215686275, '#848484'],
    [0.7137254901960784, '#838383'],
    [0.7176470588235294, '#828282'],
    [0.7215686274509804, '#808080'],
    [0.7254901960784313, '#7f7f7f'],
    [0.7294117647058823, '#7e7e7e'],
    [0.7333333333333333, '#7c7c7c'],
    [0.7372549019607844, '#7b7b7b'],
    [0.7411764705882353, '#797979'],
    [0.7450980392156863, '#787878'],
    [0.7490196078431373, '#777777'],
    [0.7529411764705882, '#757575'],
    [0.7568627450980392, '#747474'],
    [0.7607843137254902, '#737373'],
    [0.7647058823529411, '#717171'],
    [0.7686274509803921, '#707070'],
    [0.7725490196078432, '#6f6f6f'],
    [0.7764705882352941, '#6d6d6d'],
    [0.7803921568627451, '#6c6c6c'],
    [0.7843137254901961, '#6b6b6b'],
    [0.788235294117647, '#696969'],
    [0.792156862745098, '#686868'],
    [0.796078431372549, '#676767'],
    [0.8, '#656565'],
    [0.803921568627451, '#646464'],
    [0.807843137254902, '#636363'],
    [0.8117647058823529, '#616161'],
    [0.8156862745098039, '#606060'],
    [0.8196078431372549, '#5f5f5f'],
    [0.8235294117647058, '#5d5d5d'],
    [0.8274509803921568, '#5c5c5c'],
    [0.8313725490196078, '#5b5b5b'],
    [0.8352941176470589, '#595a5a'],
    [0.8392156862745098, '#585858'],
    [0.8431372549019608, '#575757'],
    [0.8470588235294118, '#565656'],
    [0.8509803921568627, '#545454'],
    [0.8549019607843137, '#535353'],
    [0.8588235294117647, '#525252'],
    [0.8627450980392157, '#515151'],
    [0.8666666666666667, '#4f4f4f'],
    [0.8705882352941177, '#4e4e4e'],
    [0.8745098039215686, '#4d4d4d'],
    [0.8784313725490196, '#4b4b4b'],
    [0.8823529411764706, '#4a4a4a'],
    [0.8862745098039215, '#494949'],
    [0.8901960784313725, '#484848'],
    [0.8941176470588235, '#464646'],
    [0.8980392156862745, '#454545'],
    [0.9019607843137255, '#444444'],
    [0.9058823529411765, '#434343'],
    [0.9098039215686274, '#424242'],
    [0.9137254901960784, '#404040'],
    [0.9176470588235294, '#3f3f3f'],
    [0.9215686274509803, '#3e3e3e'],
    [0.9254901960784314, '#3d3d3d'],
    [0.9294117647058824, '#3b3b3b'],
    [0.9333333333333333, '#3a3a3a'],
    [0.9372549019607843, '#393939'],
    [0.9411764705882353, '#383838'],
    [0.9450980392156862, '#373737'],
    [0.9490196078431372, '#363636'],
    [0.9529411764705882, '#343434'],
    [0.9568627450980391, '#333333'],
    [0.9607843137254902, '#323232'],
    [0.9647058823529412, '#313131'],
    [0.9686274509803922, '#303030'],
    [0.9725490196078431, '#303030'],
    [0.9764705882352941, '#2f2f2f'],
    [0.9803921568627451, '#2e2e2e'],
    [0.984313725490196, '#2e2e2e'],
    [0.9882352941176471, '#2d2d2d'],
    [0.9921568627450981, '#2d2d2d'],
    [0.996078431372549, '#2d2d2d'],
    [1.0, '#2d2d2d'],
  ],
  CET_C6: [
    [0.0, '#f6361a'],
    [0.00392156862745098, '#f63817'],
    [0.00784313725490196, '#f63a14'],
    [0.011764705882352941, '#f63c12'],
    [0.01568627450980392, '#f64010'],
    [0.0196078431372549, '#f6430d'],
    [0.023529411764705882, '#f7470b'],
    [0.027450980392156862, '#f74c0a'],
    [0.03137254901960784, '#f75008'],
    [0.03529411764705882, '#f85406'],
    [0.0392156862745098, '#f85905'],
    [0.043137254901960784, '#f95d04'],
    [0.047058823529411764, '#fa6203'],
    [0.050980392156862744, '#fa6603'],
    [0.054901960784313725, '#fb6b02'],
    [0.058823529411764705, '#fb6f01'],
    [0.06274509803921569, '#fc7401'],
    [0.06666666666666667, '#fc7800'],
    [0.07058823529411765, '#fd7c00'],
    [0.07450980392156863, '#fd8000'],
    [0.0784313725490196, '#fd8400'],
    [0.08235294117647059, '#fe8900'],
    [0.08627450980392157, '#fe8d00'],
    [0.09019607843137255, '#ff9100'],
    [0.09411764705882353, '#ff9500'],
    [0.09803921568627451, '#ff9900'],
    [0.10196078431372549, '#ff9c00'],
    [0.10588235294117647, '#ffa000'],
    [0.10980392156862745, '#ffa400'],
    [0.11372549019607843, '#ffa800'],
    [0.11764705882352941, '#ffac00'],
    [0.12156862745098039, '#ffaf00'],
    [0.12549019607843137, '#ffb300'],
    [0.12941176470588234, '#ffb600'],
    [0.13333333333333333, '#ffba00'],
    [0.13725490196078433, '#ffbd00'],
    [0.1411764705882353, '#fec000'],
    [0.14509803921568626, '#fdc300'],
    [0.14901960784313725, '#fcc600'],
    [0.15294117647058825, '#fac800'],
    [0.1568627450980392, '#f8ca00'],
    [0.16078431372549018, '#f6cc00'],
    [0.16470588235294117, '#f4ce00'],
    [0.16862745098039217, '#f1cf00'],
    [0.17254901960784313, '#efd000'],
    [0.1764705882352941, '#ebd100'],
    [0.1803921568627451, '#e8d100'],
    [0.1843137254901961, '#e5d100'],
    [0.18823529411764706, '#e1d100'],
    [0.19215686274509802, '#ddd000'],
    [0.19607843137254902, '#d9cf00'],
    [0.2, '#d4ce00'],
    [0.20392156862745098, '#d0cd00'],
    [0.20784313725490194, '#cccc00'],
    [0.21176470588235294, '#c7ca00'],
    [0.21568627450980393, '#c3c800'],
    [0.2196078431372549, '#bec700'],
    [0.22352941176470587, '#b9c500'],
    [0.22745098039215686, '#b5c300'],
    [0.23137254901960785, '#b0c100'],
    [0.23529411764705882, '#abbf00'],
    [0.2392156862745098, '#a7bd00'],
    [0.24313725490196078, '#a2bc00'],
    [0.24705882352941178, '#9dba00'],
    [0.25098039215686274, '#98b800'],
    [0.2549019607843137, '#94b600'],
    [0.2588235294117647, '#8fb400'],
    [0.2627450980392157, '#8ab200'],
    [0.26666666666666666, '#85b001'],
    [0.27058823529411763, '#80ae01'],
    [0.27450980392156865, '#7bac02'],
    [0.2784313725490196, '#77aa02'],
    [0.2823529411764706, '#72a803'],
    [0.28627450980392155, '#6da604'],
    [0.2901960784313725, '#68a405'],
    [0.29411764705882354, '#63a306'],
    [0.2980392156862745, '#5ea108'],
    [0.30196078431372547, '#599f0a'],
    [0.3058823529411765, '#559e0d'],
    [0.30980392156862746, '#509d0f'],
    [0.3137254901960784, '#4c9b12'],
    [0.3176470588235294, '#479a15'],
    [0.32156862745098036, '#439a18'],
    [0.3254901960784314, '#3f991b'],
    [0.32941176470588235, '#3b991e'],
    [0.3333333333333333, '#389921'],
    [0.33725490196078434, '#359925'],
    [0.3411764705882353, '#329929'],
    [0.34509803921568627, '#309a2d'],
    [0.34901960784313724, '#2e9b31'],
    [0.3529411764705882, '#2d9c35'],
    [0.3568627450980392, '#2c9d39'],
    [0.3607843137254902, '#2b9f3e'],
    [0.36470588235294116, '#2ba142'],
    [0.3686274509803922, '#2aa347'],
    [0.37254901960784315, '#2ba54c'],
    [0.3764705882352941, '#2ba751'],
    [0.3803921568627451, '#2ca955'],
    [0.38431372549019605, '#2cab5a'],
    [0.38823529411764707, '#2dae5f'],
    [0.39215686274509803, '#2eb064'],
    [0.396078431372549, '#2fb269'],
    [0.4, '#2fb56e'],
    [0.403921568627451, '#30b773'],
    [0.40784313725490196, '#30ba78'],
    [0.4117647058823529, '#31bc7d'],
    [0.4156862745098039, '#31bf82'],
    [0.4196078431372549, '#32c187'],
    [0.4235294117647059, '#32c48c'],
    [0.42745098039215684, '#32c691'],
    [0.43137254901960786, '#32c997'],
    [0.43529411764705883, '#32cc9c'],
    [0.4392156862745098, '#31cea1'],
    [0.44313725490196076, '#31d1a6'],
    [0.44705882352941173, '#31d3ab'],
    [0.45098039215686275, '#30d5b0'],
    [0.4549019607843137, '#2fd8b5'],
    [0.4588235294117647, '#2edaba'],
    [0.4627450980392157, '#2ddcbf'],
    [0.4666666666666667, '#2cdec4'],
    [0.47058823529411764, '#2be0c9'],
    [0.4745098039215686, '#2ae2ce'],
    [0.4784313725490196, '#29e4d2'],
    [0.4823529411764706, '#28e5d7'],
    [0.48627450980392156, '#27e6db'],
    [0.49019607843137253, '#26e7df'],
    [0.49411764705882355, '#25e8e3'],
    [0.4980392156862745, '#25e8e7'],
    [0.5019607843137255, '#25e8ea'],
    [0.5058823529411764, '#25e7ed'],
    [0.5098039215686274, '#25e6f0'],
    [0.5137254901960784, '#26e5f2'],
    [0.5176470588235293, '#26e4f5'],
    [0.5215686274509804, '#27e2f6'],
    [0.5254901960784314, '#28e0f8'],
    [0.5294117647058824, '#29def9'],
    [0.5333333333333333, '#2adcfb'],
    [0.5372549019607843, '#2bd9fc'],
    [0.5411764705882353, '#2cd6fc'],
    [0.5450980392156862, '#2dd4fd'],
    [0.5490196078431373, '#2ed1fe'],
    [0.5529411764705883, '#2fcefe'],
    [0.5568627450980392, '#30cbff'],
    [0.5607843137254902, '#30c8ff'],
    [0.5647058823529412, '#30c5ff'],
    [0.5686274509803921, '#31c1ff'],
    [0.5725490196078431, '#31beff'],
    [0.5764705882352941, '#31bbff'],
    [0.580392156862745, '#30b8ff'],
    [0.5843137254901961, '#30b5ff'],
    [0.5882352941176471, '#30b2ff'],
    [0.592156862745098, '#2fafff'],
    [0.596078431372549, '#2eabff'],
    [0.6, '#2ea8ff'],
    [0.6039215686274509, '#2da5ff'],
    [0.6078431372549019, '#2ca2ff'],
    [0.611764705882353, '#2b9fff'],
    [0.615686274509804, '#2a9cff'],
    [0.6196078431372549, '#2999ff'],
    [0.6235294117647059, '#2896ff'],
    [0.6274509803921569, '#2793ff'],
    [0.6313725490196078, '#2791ff'],
    [0.6352941176470588, '#278eff'],
    [0.6392156862745098, '#288cff'],
    [0.6431372549019607, '#2989ff'],
    [0.6470588235294118, '#2b87ff'],
    [0.6509803921568628, '#2d85ff'],
    [0.6549019607843137, '#3184ff'],
    [0.6588235294117647, '#3482ff'],
    [0.6627450980392157, '#3981ff'],
    [0.6666666666666666, '#3d80ff'],
    [0.6705882352941176, '#4280ff'],
    [0.6745098039215687, '#4880ff'],
    [0.6784313725490196, '#4d80ff'],
    [0.6823529411764706, '#5380ff'],
    [0.6862745098039216, '#5881ff'],
    [0.6901960784313725, '#5e82ff'],
    [0.6941176470588235, '#6383ff'],
    [0.6980392156862745, '#6984ff'],
    [0.7019607843137254, '#6e85ff'],
    [0.7058823529411764, '#7487ff'],
    [0.7098039215686275, '#7989ff'],
    [0.7137254901960784, '#7f8bff'],
    [0.7176470588235294, '#848dff'],
    [0.7215686274509804, '#898fff'],
    [0.7254901960784313, '#8e91ff'],
    [0.7294117647058823, '#9393ff'],
    [0.7333333333333333, '#9795ff'],
    [0.7372549019607844, '#9c97ff'],
    [0.7411764705882353, '#a199ff'],
    [0.7450980392156863, '#a59cff'],
    [0.7490196078431373, '#aa9eff'],
    [0.7529411764705882, '#aea0ff'],
    [0.7568627450980392, '#b2a3ff'],
    [0.7607843137254902, '#b7a5ff'],
    [0.7647058823529411, '#bba7ff'],
    [0.7686274509803921, '#bfa9ff'],
    [0.7725490196078432, '#c3acff'],
    [0.7764705882352941, '#c7aeff'],
    [0.7803921568627451, '#cbb0ff'],
    [0.7843137254901961, '#cfb2ff'],
    [0.788235294117647, '#d3b4ff'],
    [0.792156862745098, '#d7b6ff'],
    [0.796078431372549, '#dbb8ff'],
    [0.8, '#dfbaff'],
    [0.803921568627451, '#e3bbfe'],
    [0.807843137254902, '#e6bcfd'],
    [0.8117647058823529, '#eabdfb'],
    [0.8156862745098039, '#edbef9'],
    [0.8196078431372549, '#f0bff7'],
    [0.8235294117647058, '#f4bff4'],
    [0.8274509803921568, '#f7bef1'],
    [0.8313725490196078, '#f9beee'],
    [0.8352941176470589, '#fcbdea'],
    [0.8392156862745098, '#febce6'],
    [0.8431372549019608, '#ffbae2'],
    [0.8470588235294118, '#ffb8dd'],
    [0.8509803921568627, '#ffb6d9'],
    [0.8549019607843137, '#ffb4d4'],
    [0.8588235294117647, '#ffb1cf'],
    [0.8627450980392157, '#ffaec9'],
    [0.8666666666666667, '#ffabc4'],
    [0.8705882352941177, '#ffa8bf'],
    [0.8745098039215686, '#ffa5b9'],
    [0.8784313725490196, '#ffa1b4'],
    [0.8823529411764706, '#ff9eae'],
    [0.8862745098039215, '#ff9aa9'],
    [0.8901960784313725, '#ff97a3'],
    [0.8941176470588235, '#ff939e'],
    [0.8980392156862745, '#ff8f98'],
    [0.9019607843137255, '#ff8c93'],
    [0.9058823529411765, '#ff888e'],
    [0.9098039215686274, '#ff8488'],
    [0.9137254901960784, '#ff8083'],
    [0.9176470588235294, '#ff7c7d'],
    [0.9215686274509803, '#ff7878'],
    [0.9254901960784314, '#ff7473'],
    [0.9294117647058824, '#ff706d'],
    [0.9333333333333333, '#ff6c68'],
    [0.9372549019607843, '#ff6863'],
    [0.9411764705882353, '#ff645e'],
    [0.9450980392156862, '#ff6059'],
    [0.9490196078431372, '#ff5c54'],
    [0.9529411764705882, '#ff584f'],
    [0.9568627450980391, '#ff534a'],
    [0.9607843137254902, '#fe4f45'],
    [0.9647058823529412, '#fd4b40'],
    [0.9686274509803922, '#fc473c'],
    [0.9725490196078431, '#fb4437'],
    [0.9764705882352941, '#fa4033'],
    [0.9803921568627451, '#f93d2f'],
    [0.984313725490196, '#f93a2b'],
    [0.9882352941176471, '#f83827'],
    [0.9921568627450981, '#f73623'],
    [0.996078431372549, '#f73520'],
    [1.0, '#f6351d'],
  ],
  CET_C6s: [
    [0.0, '#aea0ff'],
    [0.00392156862745098, '#b2a3ff'],
    [0.00784313725490196, '#b7a5ff'],
    [0.011764705882352941, '#bba7ff'],
    [0.01568627450980392, '#bfa9ff'],
    [0.0196078431372549, '#c3acff'],
    [0.023529411764705882, '#c7aeff'],
    [0.027450980392156862, '#cbb0ff'],
    [0.03137254901960784, '#cfb2ff'],
    [0.03529411764705882, '#d3b4ff'],
    [0.0392156862745098, '#d7b6ff'],
    [0.043137254901960784, '#dbb8ff'],
    [0.047058823529411764, '#dfbaff'],
    [0.050980392156862744, '#e3bbfe'],
    [0.054901960784313725, '#e6bcfd'],
    [0.058823529411764705, '#eabdfb'],
    [0.06274509803921569, '#edbef9'],
    [0.06666666666666667, '#f0bff7'],
    [0.07058823529411765, '#f4bff4'],
    [0.07450980392156863, '#f7bef1'],
    [0.0784313725490196, '#f9beee'],
    [0.08235294117647059, '#fcbdea'],
    [0.08627450980392157, '#febce6'],
    [0.09019607843137255, '#ffbae2'],
    [0.09411764705882353, '#ffb8dd'],
    [0.09803921568627451, '#ffb6d9'],
    [0.10196078431372549, '#ffb4d4'],
    [0.10588235294117647, '#ffb1cf'],
    [0.10980392156862745, '#ffaec9'],
    [0.11372549019607843, '#ffabc4'],
    [0.11764705882352941, '#ffa8bf'],
    [0.12156862745098039, '#ffa5b9'],
    [0.12549019607843137, '#ffa1b4'],
    [0.12941176470588234, '#ff9eae'],
    [0.13333333333333333, '#ff9aa9'],
    [0.13725490196078433, '#ff97a3'],
    [0.1411764705882353, '#ff939e'],
    [0.14509803921568626, '#ff8f98'],
    [0.14901960784313725, '#ff8c93'],
    [0.15294117647058825, '#ff888e'],
    [0.1568627450980392, '#ff8488'],
    [0.16078431372549018, '#ff8083'],
    [0.16470588235294117, '#ff7c7d'],
    [0.16862745098039217, '#ff7878'],
    [0.17254901960784313, '#ff7473'],
    [0.1764705882352941, '#ff706d'],
    [0.1803921568627451, '#ff6c68'],
    [0.1843137254901961, '#ff6863'],
    [0.18823529411764706, '#ff645e'],
    [0.19215686274509802, '#ff6059'],
    [0.19607843137254902, '#ff5c54'],
    [0.2, '#ff584f'],
    [0.20392156862745098, '#ff534a'],
    [0.20784313725490194, '#fe4f45'],
    [0.21176470588235294, '#fd4b40'],
    [0.21568627450980393, '#fc473c'],
    [0.2196078431372549, '#fb4437'],
    [0.22352941176470587, '#fa4033'],
    [0.22745098039215686, '#f93d2f'],
    [0.23137254901960785, '#f93a2b'],
    [0.23529411764705882, '#f83827'],
    [0.2392156862745098, '#f73623'],
    [0.24313725490196078, '#f73520'],
    [0.24705882352941178, '#f6351d'],
    [0.25098039215686274, '#f6361a'],
    [0.2549019607843137, '#f63817'],
    [0.2588235294117647, '#f63a14'],
    [0.2627450980392157, '#f63c12'],
    [0.26666666666666666, '#f64010'],
    [0.27058823529411763, '#f6430d'],
    [0.27450980392156865, '#f7470b'],
    [0.2784313725490196, '#f74c0a'],
    [0.2823529411764706, '#f75008'],
    [0.28627450980392155, '#f85406'],
    [0.2901960784313725, '#f85905'],
    [0.29411764705882354, '#f95d04'],
    [0.2980392156862745, '#fa6203'],
    [0.30196078431372547, '#fa6603'],
    [0.3058823529411765, '#fb6b02'],
    [0.30980392156862746, '#fb6f01'],
    [0.3137254901960784, '#fc7401'],
    [0.3176470588235294, '#fc7800'],
    [0.32156862745098036, '#fd7c00'],
    [0.3254901960784314, '#fd8000'],
    [0.32941176470588235, '#fd8400'],
    [0.3333333333333333, '#fe8900'],
    [0.33725490196078434, '#fe8d00'],
    [0.3411764705882353, '#ff9100'],
    [0.34509803921568627, '#ff9500'],
    [0.34901960784313724, '#ff9900'],
    [0.3529411764705882, '#ff9c00'],
    [0.3568627450980392, '#ffa000'],
    [0.3607843137254902, '#ffa400'],
    [0.36470588235294116, '#ffa800'],
    [0.3686274509803922, '#ffac00'],
    [0.37254901960784315, '#ffaf00'],
    [0.3764705882352941, '#ffb300'],
    [0.3803921568627451, '#ffb600'],
    [0.38431372549019605, '#ffba00'],
    [0.38823529411764707, '#ffbd00'],
    [0.39215686274509803, '#fec000'],
    [0.396078431372549, '#fdc300'],
    [0.4, '#fcc600'],
    [0.403921568627451, '#fac800'],
    [0.40784313725490196, '#f8ca00'],
    [0.4117647058823529, '#f6cc00'],
    [0.4156862745098039, '#f4ce00'],
    [0.4196078431372549, '#f1cf00'],
    [0.4235294117647059, '#efd000'],
    [0.42745098039215684, '#ebd100'],
    [0.43137254901960786, '#e8d100'],
    [0.43529411764705883, '#e5d100'],
    [0.4392156862745098, '#e1d100'],
    [0.44313725490196076, '#ddd000'],
    [0.44705882352941173, '#d9cf00'],
    [0.45098039215686275, '#d4ce00'],
    [0.4549019607843137, '#d0cd00'],
    [0.4588235294117647, '#cccc00'],
    [0.4627450980392157, '#c7ca00'],
    [0.4666666666666667, '#c3c800'],
    [0.47058823529411764, '#bec700'],
    [0.4745098039215686, '#b9c500'],
    [0.4784313725490196, '#b5c300'],
    [0.4823529411764706, '#b0c100'],
    [0.48627450980392156, '#abbf00'],
    [0.49019607843137253, '#a7bd00'],
    [0.49411764705882355, '#a2bc00'],
    [0.4980392156862745, '#9dba00'],
    [0.5019607843137255, '#98b800'],
    [0.5058823529411764, '#94b600'],
    [0.5098039215686274, '#8fb400'],
    [0.5137254901960784, '#8ab200'],
    [0.5176470588235293, '#85b001'],
    [0.5215686274509804, '#80ae01'],
    [0.5254901960784314, '#7bac02'],
    [0.5294117647058824, '#77aa02'],
    [0.5333333333333333, '#72a803'],
    [0.5372549019607843, '#6da604'],
    [0.5411764705882353, '#68a405'],
    [0.5450980392156862, '#63a306'],
    [0.5490196078431373, '#5ea108'],
    [0.5529411764705883, '#599f0a'],
    [0.5568627450980392, '#559e0d'],
    [0.5607843137254902, '#509d0f'],
    [0.5647058823529412, '#4c9b12'],
    [0.5686274509803921, '#479a15'],
    [0.5725490196078431, '#439a18'],
    [0.5764705882352941, '#3f991b'],
    [0.580392156862745, '#3b991e'],
    [0.5843137254901961, '#389921'],
    [0.5882352941176471, '#359925'],
    [0.592156862745098, '#329929'],
    [0.596078431372549, '#309a2d'],
    [0.6, '#2e9b31'],
    [0.6039215686274509, '#2d9c35'],
    [0.6078431372549019, '#2c9d39'],
    [0.611764705882353, '#2b9f3e'],
    [0.615686274509804, '#2ba142'],
    [0.6196078431372549, '#2aa347'],
    [0.6235294117647059, '#2ba54c'],
    [0.6274509803921569, '#2ba751'],
    [0.6313725490196078, '#2ca955'],
    [0.6352941176470588, '#2cab5a'],
    [0.6392156862745098, '#2dae5f'],
    [0.6431372549019607, '#2eb064'],
    [0.6470588235294118, '#2fb269'],
    [0.6509803921568628, '#2fb56e'],
    [0.6549019607843137, '#30b773'],
    [0.6588235294117647, '#30ba78'],
    [0.6627450980392157, '#31bc7d'],
    [0.6666666666666666, '#31bf82'],
    [0.6705882352941176, '#32c187'],
    [0.6745098039215687, '#32c48c'],
    [0.6784313725490196, '#32c691'],
    [0.6823529411764706, '#32c997'],
    [0.6862745098039216, '#32cc9c'],
    [0.6901960784313725, '#31cea1'],
    [0.6941176470588235, '#31d1a6'],
    [0.6980392156862745, '#31d3ab'],
    [0.7019607843137254, '#30d5b0'],
    [0.7058823529411764, '#2fd8b5'],
    [0.7098039215686275, '#2edaba'],
    [0.7137254901960784, '#2ddcbf'],
    [0.7176470588235294, '#2cdec4'],
    [0.7215686274509804, '#2be0c9'],
    [0.7254901960784313, '#2ae2ce'],
    [0.7294117647058823, '#29e4d2'],
    [0.7333333333333333, '#28e5d7'],
    [0.7372549019607844, '#27e6db'],
    [0.7411764705882353, '#26e7df'],
    [0.7450980392156863, '#25e8e3'],
    [0.7490196078431373, '#25e8e7'],
    [0.7529411764705882, '#25e8ea'],
    [0.7568627450980392, '#25e7ed'],
    [0.7607843137254902, '#25e6f0'],
    [0.7647058823529411, '#26e5f2'],
    [0.7686274509803921, '#26e4f5'],
    [0.7725490196078432, '#27e2f6'],
    [0.7764705882352941, '#28e0f8'],
    [0.7803921568627451, '#29def9'],
    [0.7843137254901961, '#2adcfb'],
    [0.788235294117647, '#2bd9fc'],
    [0.792156862745098, '#2cd6fc'],
    [0.796078431372549, '#2dd4fd'],
    [0.8, '#2ed1fe'],
    [0.803921568627451, '#2fcefe'],
    [0.807843137254902, '#30cbff'],
    [0.8117647058823529, '#30c8ff'],
    [0.8156862745098039, '#30c5ff'],
    [0.8196078431372549, '#31c1ff'],
    [0.8235294117647058, '#31beff'],
    [0.8274509803921568, '#31bbff'],
    [0.8313725490196078, '#30b8ff'],
    [0.8352941176470589, '#30b5ff'],
    [0.8392156862745098, '#30b2ff'],
    [0.8431372549019608, '#2fafff'],
    [0.8470588235294118, '#2eabff'],
    [0.8509803921568627, '#2ea8ff'],
    [0.8549019607843137, '#2da5ff'],
    [0.8588235294117647, '#2ca2ff'],
    [0.8627450980392157, '#2b9fff'],
    [0.8666666666666667, '#2a9cff'],
    [0.8705882352941177, '#2999ff'],
    [0.8745098039215686, '#2896ff'],
    [0.8784313725490196, '#2793ff'],
    [0.8823529411764706, '#2791ff'],
    [0.8862745098039215, '#278eff'],
    [0.8901960784313725, '#288cff'],
    [0.8941176470588235, '#2989ff'],
    [0.8980392156862745, '#2b87ff'],
    [0.9019607843137255, '#2d85ff'],
    [0.9058823529411765, '#3184ff'],
    [0.9098039215686274, '#3482ff'],
    [0.9137254901960784, '#3981ff'],
    [0.9176470588235294, '#3d80ff'],
    [0.9215686274509803, '#4280ff'],
    [0.9254901960784314, '#4880ff'],
    [0.9294117647058824, '#4d80ff'],
    [0.9333333333333333, '#5380ff'],
    [0.9372549019607843, '#5881ff'],
    [0.9411764705882353, '#5e82ff'],
    [0.9450980392156862, '#6383ff'],
    [0.9490196078431372, '#6984ff'],
    [0.9529411764705882, '#6e85ff'],
    [0.9568627450980391, '#7487ff'],
    [0.9607843137254902, '#7989ff'],
    [0.9647058823529412, '#7f8bff'],
    [0.9686274509803922, '#848dff'],
    [0.9725490196078431, '#898fff'],
    [0.9764705882352941, '#8e91ff'],
    [0.9803921568627451, '#9393ff'],
    [0.984313725490196, '#9795ff'],
    [0.9882352941176471, '#9c97ff'],
    [0.9921568627450981, '#a199ff'],
    [0.996078431372549, '#a59cff'],
    [1.0, '#aa9eff'],
  ],
  CET_C7: [
    [0.0, '#e8e419'],
    [0.00392156862745098, '#eae31f'],
    [0.00784313725490196, '#ece225'],
    [0.011764705882352941, '#ede02b'],
    [0.01568627450980392, '#eedf31'],
    [0.0196078431372549, '#f0dd36'],
    [0.023529411764705882, '#f1db3c'],
    [0.027450980392156862, '#f2d941'],
    [0.03137254901960784, '#f3d746'],
    [0.03529411764705882, '#f4d54b'],
    [0.0392156862745098, '#f5d34f'],
    [0.043137254901960784, '#f6d154'],
    [0.047058823529411764, '#f7ce58'],
    [0.050980392156862744, '#f8cc5c'],
    [0.054901960784313725, '#f9ca60'],
    [0.058823529411764705, '#f9c764'],
    [0.06274509803921569, '#fac568'],
    [0.06666666666666667, '#fbc36b'],
    [0.07058823529411765, '#fcc06f'],
    [0.07450980392156863, '#fcbe73'],
    [0.0784313725490196, '#fdbb76'],
    [0.08235294117647059, '#fdb97a'],
    [0.08627450980392157, '#feb77d'],
    [0.09019607843137255, '#ffb480'],
    [0.09411764705882353, '#ffb284'],
    [0.09803921568627451, '#ffaf87'],
    [0.10196078431372549, '#ffad8a'],
    [0.10588235294117647, '#ffaa8d'],
    [0.10980392156862745, '#ffa890'],
    [0.11372549019607843, '#ffa594'],
    [0.11764705882352941, '#ffa397'],
    [0.12156862745098039, '#ffa09a'],
    [0.12549019607843137, '#ff9d9d'],
    [0.12941176470588234, '#ff9ba0'],
    [0.13333333333333333, '#ff98a3'],
    [0.13725490196078433, '#ff95a6'],
    [0.1411764705882353, '#ff93a9'],
    [0.14509803921568626, '#ff90ac'],
    [0.14901960784313725, '#ff8daf'],
    [0.15294117647058825, '#ff8ab2'],
    [0.1568627450980392, '#ff87b5'],
    [0.16078431372549018, '#ff85b8'],
    [0.16470588235294117, '#ff82bb'],
    [0.16862745098039217, '#ff7fbd'],
    [0.17254901960784313, '#ff7bc0'],
    [0.1764705882352941, '#ff78c3'],
    [0.1803921568627451, '#ff75c6'],
    [0.1843137254901961, '#ff72c9'],
    [0.18823529411764706, '#ff6fcc'],
    [0.19215686274509802, '#ff6bcf'],
    [0.19607843137254902, '#ff68d2'],
    [0.2, '#ff64d4'],
    [0.20392156862745098, '#ff61d7'],
    [0.20784313725490194, '#ff5dda'],
    [0.21176470588235294, '#ff59dd'],
    [0.21568627450980393, '#ff55e0'],
    [0.2196078431372549, '#ff51e3'],
    [0.22352941176470587, '#ff4de5'],
    [0.22745098039215686, '#ff4ae8'],
    [0.23137254901960785, '#ff46eb'],
    [0.23529411764705882, '#ff43ed'],
    [0.2392156862745098, '#fe41f0'],
    [0.24313725490196078, '#fd3ff2'],
    [0.24705882352941178, '#fc3ef4'],
    [0.25098039215686274, '#fb3ef6'],
    [0.2549019607843137, '#fa3ff8'],
    [0.2588235294117647, '#f941f9'],
    [0.2627450980392157, '#f844fa'],
    [0.26666666666666666, '#f648fb'],
    [0.27058823529411763, '#f54cfc'],
    [0.27450980392156865, '#f350fd'],
    [0.2784313725490196, '#f255fd'],
    [0.2823529411764706, '#f059fd'],
    [0.28627450980392155, '#ef5dfe'],
    [0.2901960784313725, '#ed62fe'],
    [0.29411764705882354, '#ec66fe'],
    [0.2980392156862745, '#ea6afe'],
    [0.30196078431372547, '#e86efe'],
    [0.3058823529411765, '#e772fe'],
    [0.30980392156862746, '#e576fe'],
    [0.3137254901960784, '#e37afe'],
    [0.3176470588235294, '#e27dfe'],
    [0.32156862745098036, '#e081fe'],
    [0.3254901960784314, '#de84fe'],
    [0.32941176470588235, '#dc88fe'],
    [0.3333333333333333, '#da8bfe'],
    [0.33725490196078434, '#d88efe'],
    [0.3411764705882353, '#d691fe'],
    [0.34509803921568627, '#d494fe'],
    [0.34901960784313724, '#d298fe'],
    [0.3529411764705882, '#d09bfe'],
    [0.3568627450980392, '#ce9dfd'],
    [0.3607843137254902, '#cca0fd'],
    [0.36470588235294116, '#caa3fd'],
    [0.3686274509803922, '#c7a6fd'],
    [0.37254901960784315, '#c5a9fd'],
    [0.3764705882352941, '#c3acfd'],
    [0.3803921568627451, '#c0aefd'],
    [0.38431372549019605, '#beb1fd'],
    [0.38823529411764707, '#bbb4fd'],
    [0.39215686274509803, '#b9b6fd'],
    [0.396078431372549, '#b6b9fd'],
    [0.4, '#b3bcfd'],
    [0.403921568627451, '#b1befd'],
    [0.40784313725490196, '#aec1fd'],
    [0.4117647058823529, '#abc3fd'],
    [0.4156862745098039, '#a8c6fd'],
    [0.4196078431372549, '#a4c8fd'],
    [0.4235294117647059, '#a1cbfd'],
    [0.42745098039215684, '#9ecdfd'],
    [0.43137254901960786, '#9ad0fd'],
    [0.43529411764705883, '#97d2fc'],
    [0.4392156862745098, '#93d5fc'],
    [0.44313725490196076, '#8fd7fc'],
    [0.44705882352941173, '#8bd9fc'],
    [0.45098039215686275, '#86dcfc'],
    [0.4549019607843137, '#82defc'],
    [0.4588235294117647, '#7de0fc'],
    [0.4627450980392157, '#78e3fc'],
    [0.4666666666666667, '#73e5fc'],
    [0.47058823529411764, '#6de7fb'],
    [0.4745098039215686, '#68e9fb'],
    [0.4784313725490196, '#62ebfa'],
    [0.4823529411764706, '#5bedf9'],
    [0.48627450980392156, '#55eff8'],
    [0.49019607843137253, '#4ef0f7'],
    [0.49411764705882355, '#47f1f6'],
    [0.4980392156862745, '#41f2f4'],
    [0.5019607843137255, '#3bf3f2'],
    [0.5058823529411764, '#35f3f0'],
    [0.5098039215686274, '#30f3ed'],
    [0.5137254901960784, '#2cf3eb'],
    [0.5176470588235293, '#28f2e8'],
    [0.5215686274509804, '#26f2e5'],
    [0.5254901960784314, '#25f1e1'],
    [0.5294117647058824, '#25f0de'],
    [0.5333333333333333, '#26efdb'],
    [0.5372549019607843, '#26eed7'],
    [0.5411764705882353, '#28ecd4'],
    [0.5450980392156862, '#29ebd1'],
    [0.5490196078431373, '#2aeacd'],
    [0.5529411764705883, '#2be9ca'],
    [0.5568627450980392, '#2de7c6'],
    [0.5607843137254902, '#2ee6c3'],
    [0.5647058823529412, '#2fe5c0'],
    [0.5686274509803921, '#2fe3bc'],
    [0.5725490196078431, '#30e2b9'],
    [0.5764705882352941, '#31e1b5'],
    [0.580392156862745, '#31e0b2'],
    [0.5843137254901961, '#32deaf'],
    [0.5882352941176471, '#32ddab'],
    [0.592156862745098, '#33dca8'],
    [0.596078431372549, '#33daa4'],
    [0.6, '#33d9a1'],
    [0.6039215686274509, '#33d89e'],
    [0.6078431372549019, '#33d79a'],
    [0.611764705882353, '#33d597'],
    [0.615686274509804, '#33d494'],
    [0.6196078431372549, '#33d390'],
    [0.6235294117647059, '#33d18d'],
    [0.6274509803921569, '#33d089'],
    [0.6313725490196078, '#32cf86'],
    [0.6352941176470588, '#32ce83'],
    [0.6392156862745098, '#31cc7f'],
    [0.6431372549019607, '#31cb7c'],
    [0.6470588235294118, '#30ca79'],
    [0.6509803921568628, '#30c975'],
    [0.6549019607843137, '#2fc772'],
    [0.6588235294117647, '#2ec66e'],
    [0.6627450980392157, '#2ec56b'],
    [0.6666666666666666, '#2dc468'],
    [0.6705882352941176, '#2cc264'],
    [0.6745098039215687, '#2bc161'],
    [0.6784313725490196, '#2ac05d'],
    [0.6823529411764706, '#29bf5a'],
    [0.6862745098039216, '#27bd56'],
    [0.6901960784313725, '#26bc53'],
    [0.6941176470588235, '#25bb4f'],
    [0.6980392156862745, '#23ba4c'],
    [0.7019607843137254, '#22b948'],
    [0.7058823529411764, '#20b744'],
    [0.7098039215686275, '#1fb640'],
    [0.7137254901960784, '#1db53d'],
    [0.7176470588235294, '#1cb439'],
    [0.7215686274509804, '#1bb335'],
    [0.7254901960784313, '#1ab231'],
    [0.7294117647058823, '#1ab12d'],
    [0.7333333333333333, '#1ab028'],
    [0.7372549019607844, '#1baf24'],
    [0.7411764705882353, '#1daf20'],
    [0.7450980392156863, '#20ae1c'],
    [0.7490196078431373, '#23ae18'],
    [0.7529411764705882, '#26ae13'],
    [0.7568627450980392, '#2aaf0f'],
    [0.7607843137254902, '#2eaf0b'],
    [0.7647058823529411, '#32af07'],
    [0.7686274509803921, '#37b004'],
    [0.7725490196078432, '#3bb102'],
    [0.7764705882352941, '#3fb200'],
    [0.7803921568627451, '#43b200'],
    [0.7843137254901961, '#47b300'],
    [0.788235294117647, '#4bb400'],
    [0.792156862745098, '#4fb500'],
    [0.796078431372549, '#53b600'],
    [0.8, '#56b700'],
    [0.803921568627451, '#5ab800'],
    [0.807843137254902, '#5db900'],
    [0.8117647058823529, '#61ba00'],
    [0.8156862745098039, '#64bb00'],
    [0.8196078431372549, '#67bd00'],
    [0.8235294117647058, '#6bbe00'],
    [0.8274509803921568, '#6ebf00'],
    [0.8313725490196078, '#71c000'],
    [0.8352941176470589, '#74c100'],
    [0.8392156862745098, '#77c200'],
    [0.8431372549019608, '#7ac300'],
    [0.8470588235294118, '#7dc400'],
    [0.8509803921568627, '#81c500'],
    [0.8549019607843137, '#83c600'],
    [0.8588235294117647, '#86c700'],
    [0.8627450980392157, '#89c800'],
    [0.8666666666666667, '#8cc900'],
    [0.8705882352941177, '#8fca00'],
    [0.8745098039215686, '#92cb00'],
    [0.8784313725490196, '#95cc00'],
    [0.8823529411764706, '#98cd00'],
    [0.8862745098039215, '#9bce00'],
    [0.8901960784313725, '#9ecf00'],
    [0.8941176470588235, '#a0cf00'],
    [0.8980392156862745, '#a3d000'],
    [0.9019607843137255, '#a6d100'],
    [0.9058823529411765, '#a9d200'],
    [0.9098039215686274, '#acd300'],
    [0.9137254901960784, '#aed400'],
    [0.9176470588235294, '#b1d500'],
    [0.9215686274509803, '#b4d600'],
    [0.9254901960784314, '#b7d700'],
    [0.9294117647058824, '#b9d800'],
    [0.9333333333333333, '#bcd900'],
    [0.9372549019607843, '#bfda00'],
    [0.9411764705882353, '#c2db00'],
    [0.9450980392156862, '#c4dc00'],
    [0.9490196078431372, '#c7dd00'],
    [0.9529411764705882, '#cade00'],
    [0.9568627450980391, '#ccdf00'],
    [0.9607843137254902, '#cfe000'],
    [0.9647058823529412, '#d2e100'],
    [0.9686274509803922, '#d4e200'],
    [0.9725490196078431, '#d7e200'],
    [0.9764705882352941, '#d9e300'],
    [0.9803921568627451, '#dce400'],
    [0.984313725490196, '#dee400'],
    [0.9882352941176471, '#e1e400'],
    [0.9921568627450981, '#e3e503'],
    [0.996078431372549, '#e5e40a'],
    [1.0, '#e7e412'],
  ],
  CET_C7s: [
    [0.0, '#26ae13'],
    [0.00392156862745098, '#2aaf0f'],
    [0.00784313725490196, '#2eaf0b'],
    [0.011764705882352941, '#32af07'],
    [0.01568627450980392, '#37b004'],
    [0.0196078431372549, '#3bb102'],
    [0.023529411764705882, '#3fb200'],
    [0.027450980392156862, '#43b200'],
    [0.03137254901960784, '#47b300'],
    [0.03529411764705882, '#4bb400'],
    [0.0392156862745098, '#4fb500'],
    [0.043137254901960784, '#53b600'],
    [0.047058823529411764, '#56b700'],
    [0.050980392156862744, '#5ab800'],
    [0.054901960784313725, '#5db900'],
    [0.058823529411764705, '#61ba00'],
    [0.06274509803921569, '#64bb00'],
    [0.06666666666666667, '#67bd00'],
    [0.07058823529411765, '#6bbe00'],
    [0.07450980392156863, '#6ebf00'],
    [0.0784313725490196, '#71c000'],
    [0.08235294117647059, '#74c100'],
    [0.08627450980392157, '#77c200'],
    [0.09019607843137255, '#7ac300'],
    [0.09411764705882353, '#7dc400'],
    [0.09803921568627451, '#81c500'],
    [0.10196078431372549, '#83c600'],
    [0.10588235294117647, '#86c700'],
    [0.10980392156862745, '#89c800'],
    [0.11372549019607843, '#8cc900'],
    [0.11764705882352941, '#8fca00'],
    [0.12156862745098039, '#92cb00'],
    [0.12549019607843137, '#95cc00'],
    [0.12941176470588234, '#98cd00'],
    [0.13333333333333333, '#9bce00'],
    [0.13725490196078433, '#9ecf00'],
    [0.1411764705882353, '#a0cf00'],
    [0.14509803921568626, '#a3d000'],
    [0.14901960784313725, '#a6d100'],
    [0.15294117647058825, '#a9d200'],
    [0.1568627450980392, '#acd300'],
    [0.16078431372549018, '#aed400'],
    [0.16470588235294117, '#b1d500'],
    [0.16862745098039217, '#b4d600'],
    [0.17254901960784313, '#b7d700'],
    [0.1764705882352941, '#b9d800'],
    [0.1803921568627451, '#bcd900'],
    [0.1843137254901961, '#bfda00'],
    [0.18823529411764706, '#c2db00'],
    [0.19215686274509802, '#c4dc00'],
    [0.19607843137254902, '#c7dd00'],
    [0.2, '#cade00'],
    [0.20392156862745098, '#ccdf00'],
    [0.20784313725490194, '#cfe000'],
    [0.21176470588235294, '#d2e100'],
    [0.21568627450980393, '#d4e200'],
    [0.2196078431372549, '#d7e200'],
    [0.22352941176470587, '#d9e300'],
    [0.22745098039215686, '#dce400'],
    [0.23137254901960785, '#dee400'],
    [0.23529411764705882, '#e1e400'],
    [0.2392156862745098, '#e3e503'],
    [0.24313725490196078, '#e5e40a'],
    [0.24705882352941178, '#e7e412'],
    [0.25098039215686274, '#e8e419'],
    [0.2549019607843137, '#eae31f'],
    [0.2588235294117647, '#ece225'],
    [0.2627450980392157, '#ede02b'],
    [0.26666666666666666, '#eedf31'],
    [0.27058823529411763, '#f0dd36'],
    [0.27450980392156865, '#f1db3c'],
    [0.2784313725490196, '#f2d941'],
    [0.2823529411764706, '#f3d746'],
    [0.28627450980392155, '#f4d54b'],
    [0.2901960784313725, '#f5d34f'],
    [0.29411764705882354, '#f6d154'],
    [0.2980392156862745, '#f7ce58'],
    [0.30196078431372547, '#f8cc5c'],
    [0.3058823529411765, '#f9ca60'],
    [0.30980392156862746, '#f9c764'],
    [0.3137254901960784, '#fac568'],
    [0.3176470588235294, '#fbc36b'],
    [0.32156862745098036, '#fcc06f'],
    [0.3254901960784314, '#fcbe73'],
    [0.32941176470588235, '#fdbb76'],
    [0.3333333333333333, '#fdb97a'],
    [0.33725490196078434, '#feb77d'],
    [0.3411764705882353, '#ffb480'],
    [0.34509803921568627, '#ffb284'],
    [0.34901960784313724, '#ffaf87'],
    [0.3529411764705882, '#ffad8a'],
    [0.3568627450980392, '#ffaa8d'],
    [0.3607843137254902, '#ffa890'],
    [0.36470588235294116, '#ffa594'],
    [0.3686274509803922, '#ffa397'],
    [0.37254901960784315, '#ffa09a'],
    [0.3764705882352941, '#ff9d9d'],
    [0.3803921568627451, '#ff9ba0'],
    [0.38431372549019605, '#ff98a3'],
    [0.38823529411764707, '#ff95a6'],
    [0.39215686274509803, '#ff93a9'],
    [0.396078431372549, '#ff90ac'],
    [0.4, '#ff8daf'],
    [0.403921568627451, '#ff8ab2'],
    [0.40784313725490196, '#ff87b5'],
    [0.4117647058823529, '#ff85b8'],
    [0.4156862745098039, '#ff82bb'],
    [0.4196078431372549, '#ff7fbd'],
    [0.4235294117647059, '#ff7bc0'],
    [0.42745098039215684, '#ff78c3'],
    [0.43137254901960786, '#ff75c6'],
    [0.43529411764705883, '#ff72c9'],
    [0.4392156862745098, '#ff6fcc'],
    [0.44313725490196076, '#ff6bcf'],
    [0.44705882352941173, '#ff68d2'],
    [0.45098039215686275, '#ff64d4'],
    [0.4549019607843137, '#ff61d7'],
    [0.4588235294117647, '#ff5dda'],
    [0.4627450980392157, '#ff59dd'],
    [0.4666666666666667, '#ff55e0'],
    [0.47058823529411764, '#ff51e3'],
    [0.4745098039215686, '#ff4de5'],
    [0.4784313725490196, '#ff4ae8'],
    [0.4823529411764706, '#ff46eb'],
    [0.48627450980392156, '#ff43ed'],
    [0.49019607843137253, '#fe41f0'],
    [0.49411764705882355, '#fd3ff2'],
    [0.4980392156862745, '#fc3ef4'],
    [0.5019607843137255, '#fb3ef6'],
    [0.5058823529411764, '#fa3ff8'],
    [0.5098039215686274, '#f941f9'],
    [0.5137254901960784, '#f844fa'],
    [0.5176470588235293, '#f648fb'],
    [0.5215686274509804, '#f54cfc'],
    [0.5254901960784314, '#f350fd'],
    [0.5294117647058824, '#f255fd'],
    [0.5333333333333333, '#f059fd'],
    [0.5372549019607843, '#ef5dfe'],
    [0.5411764705882353, '#ed62fe'],
    [0.5450980392156862, '#ec66fe'],
    [0.5490196078431373, '#ea6afe'],
    [0.5529411764705883, '#e86efe'],
    [0.5568627450980392, '#e772fe'],
    [0.5607843137254902, '#e576fe'],
    [0.5647058823529412, '#e37afe'],
    [0.5686274509803921, '#e27dfe'],
    [0.5725490196078431, '#e081fe'],
    [0.5764705882352941, '#de84fe'],
    [0.580392156862745, '#dc88fe'],
    [0.5843137254901961, '#da8bfe'],
    [0.5882352941176471, '#d88efe'],
    [0.592156862745098, '#d691fe'],
    [0.596078431372549, '#d494fe'],
    [0.6, '#d298fe'],
    [0.6039215686274509, '#d09bfe'],
    [0.6078431372549019, '#ce9dfd'],
    [0.611764705882353, '#cca0fd'],
    [0.615686274509804, '#caa3fd'],
    [0.6196078431372549, '#c7a6fd'],
    [0.6235294117647059, '#c5a9fd'],
    [0.6274509803921569, '#c3acfd'],
    [0.6313725490196078, '#c0aefd'],
    [0.6352941176470588, '#beb1fd'],
    [0.6392156862745098, '#bbb4fd'],
    [0.6431372549019607, '#b9b6fd'],
    [0.6470588235294118, '#b6b9fd'],
    [0.6509803921568628, '#b3bcfd'],
    [0.6549019607843137, '#b1befd'],
    [0.6588235294117647, '#aec1fd'],
    [0.6627450980392157, '#abc3fd'],
    [0.6666666666666666, '#a8c6fd'],
    [0.6705882352941176, '#a4c8fd'],
    [0.6745098039215687, '#a1cbfd'],
    [0.6784313725490196, '#9ecdfd'],
    [0.6823529411764706, '#9ad0fd'],
    [0.6862745098039216, '#97d2fc'],
    [0.6901960784313725, '#93d5fc'],
    [0.6941176470588235, '#8fd7fc'],
    [0.6980392156862745, '#8bd9fc'],
    [0.7019607843137254, '#86dcfc'],
    [0.7058823529411764, '#82defc'],
    [0.7098039215686275, '#7de0fc'],
    [0.7137254901960784, '#78e3fc'],
    [0.7176470588235294, '#73e5fc'],
    [0.7215686274509804, '#6de7fb'],
    [0.7254901960784313, '#68e9fb'],
    [0.7294117647058823, '#62ebfa'],
    [0.7333333333333333, '#5bedf9'],
    [0.7372549019607844, '#55eff8'],
    [0.7411764705882353, '#4ef0f7'],
    [0.7450980392156863, '#47f1f6'],
    [0.7490196078431373, '#41f2f4'],
    [0.7529411764705882, '#3bf3f2'],
    [0.7568627450980392, '#35f3f0'],
    [0.7607843137254902, '#30f3ed'],
    [0.7647058823529411, '#2cf3eb'],
    [0.7686274509803921, '#28f2e8'],
    [0.7725490196078432, '#26f2e5'],
    [0.7764705882352941, '#25f1e1'],
    [0.7803921568627451, '#25f0de'],
    [0.7843137254901961, '#26efdb'],
    [0.788235294117647, '#26eed7'],
    [0.792156862745098, '#28ecd4'],
    [0.796078431372549, '#29ebd1'],
    [0.8, '#2aeacd'],
    [0.803921568627451, '#2be9ca'],
    [0.807843137254902, '#2de7c6'],
    [0.8117647058823529, '#2ee6c3'],
    [0.8156862745098039, '#2fe5c0'],
    [0.8196078431372549, '#2fe3bc'],
    [0.8235294117647058, '#30e2b9'],
    [0.8274509803921568, '#31e1b5'],
    [0.8313725490196078, '#31e0b2'],
    [0.8352941176470589, '#32deaf'],
    [0.8392156862745098, '#32ddab'],
    [0.8431372549019608, '#33dca8'],
    [0.8470588235294118, '#33daa4'],
    [0.8509803921568627, '#33d9a1'],
    [0.8549019607843137, '#33d89e'],
    [0.8588235294117647, '#33d79a'],
    [0.8627450980392157, '#33d597'],
    [0.8666666666666667, '#33d494'],
    [0.8705882352941177, '#33d390'],
    [0.8745098039215686, '#33d18d'],
    [0.8784313725490196, '#33d089'],
    [0.8823529411764706, '#32cf86'],
    [0.8862745098039215, '#32ce83'],
    [0.8901960784313725, '#31cc7f'],
    [0.8941176470588235, '#31cb7c'],
    [0.8980392156862745, '#30ca79'],
    [0.9019607843137255, '#30c975'],
    [0.9058823529411765, '#2fc772'],
    [0.9098039215686274, '#2ec66e'],
    [0.9137254901960784, '#2ec56b'],
    [0.9176470588235294, '#2dc468'],
    [0.9215686274509803, '#2cc264'],
    [0.9254901960784314, '#2bc161'],
    [0.9294117647058824, '#2ac05d'],
    [0.9333333333333333, '#29bf5a'],
    [0.9372549019607843, '#27bd56'],
    [0.9411764705882353, '#26bc53'],
    [0.9450980392156862, '#25bb4f'],
    [0.9490196078431372, '#23ba4c'],
    [0.9529411764705882, '#22b948'],
    [0.9568627450980391, '#20b744'],
    [0.9607843137254902, '#1fb640'],
    [0.9647058823529412, '#1db53d'],
    [0.9686274509803922, '#1cb439'],
    [0.9725490196078431, '#1bb335'],
    [0.9764705882352941, '#1ab231'],
    [0.9803921568627451, '#1ab12d'],
    [0.984313725490196, '#1ab028'],
    [0.9882352941176471, '#1baf24'],
    [0.9921568627450981, '#1daf20'],
    [0.996078431372549, '#20ae1c'],
    [1.0, '#23ae18'],
  ],
  CET_C8: [
    [0.0, '#e89495'],
    [0.00392156862745098, '#e99594'],
    [0.00784313725490196, '#ea9692'],
    [0.011764705882352941, '#ea9791'],
    [0.01568627450980392, '#eb9890'],
    [0.0196078431372549, '#eb998f'],
    [0.023529411764705882, '#ec9a8e'],
    [0.027450980392156862, '#ec9b8d'],
    [0.03137254901960784, '#ed9c8b'],
    [0.03529411764705882, '#ed9d8a'],
    [0.0392156862745098, '#ed9f89'],
    [0.043137254901960784, '#eea088'],
    [0.047058823529411764, '#eea187'],
    [0.050980392156862744, '#eea286'],
    [0.054901960784313725, '#efa385'],
    [0.058823529411764705, '#efa584'],
    [0.06274509803921569, '#efa683'],
    [0.06666666666666667, '#efa782'],
    [0.07058823529411765, '#efa880'],
    [0.07450980392156863, '#efa97f'],
    [0.0784313725490196, '#efab7e'],
    [0.08235294117647059, '#efac7d'],
    [0.08627450980392157, '#efad7c'],
    [0.09019607843137255, '#efae7b'],
    [0.09411764705882353, '#efaf7a'],
    [0.09803921568627451, '#efb179'],
    [0.10196078431372549, '#efb278'],
    [0.10588235294117647, '#efb377'],
    [0.10980392156862745, '#eeb476'],
    [0.11372549019607843, '#eeb675'],
    [0.11764705882352941, '#eeb774'],
    [0.12156862745098039, '#eeb874'],
    [0.12549019607843137, '#edb973'],
    [0.12941176470588234, '#edbb72'],
    [0.13333333333333333, '#edbc71'],
    [0.13725490196078433, '#ecbd70'],
    [0.1411764705882353, '#ecbe6f'],
    [0.14509803921568626, '#ebc06e'],
    [0.14901960784313725, '#ebc16d'],
    [0.15294117647058825, '#eac26c'],
    [0.1568627450980392, '#eac36b'],
    [0.16078431372549018, '#e9c56b'],
    [0.16470588235294117, '#e9c66a'],
    [0.16862745098039217, '#e8c769'],
    [0.17254901960784313, '#e7c868'],
    [0.1764705882352941, '#e6c967'],
    [0.1803921568627451, '#e6cb66'],
    [0.1843137254901961, '#e5cc66'],
    [0.18823529411764706, '#e4cd65'],
    [0.19215686274509802, '#e3ce64'],
    [0.19607843137254902, '#e2cf64'],
    [0.2, '#e1d163'],
    [0.20392156862745098, '#e0d262'],
    [0.20784313725490194, '#dfd362'],
    [0.21176470588235294, '#ded461'],
    [0.21568627450980393, '#dcd561'],
    [0.2196078431372549, '#dbd661'],
    [0.22352941176470587, '#d9d761'],
    [0.22745098039215686, '#d8d860'],
    [0.23137254901960785, '#d6d961'],
    [0.23529411764705882, '#d4da61'],
    [0.2392156862745098, '#d2db61'],
    [0.24313725490196078, '#d0dc61'],
    [0.24705882352941178, '#cedd62'],
    [0.25098039215686274, '#ccdd63'],
    [0.2549019607843137, '#c9de64'],
    [0.2588235294117647, '#c7de65'],
    [0.2627450980392157, '#c5df66'],
    [0.26666666666666666, '#c2df68'],
    [0.27058823529411763, '#c0df69'],
    [0.27450980392156865, '#bedf6a'],
    [0.2784313725490196, '#bbdf6c'],
    [0.2823529411764706, '#b9df6e'],
    [0.28627450980392155, '#b7df6f'],
    [0.2901960784313725, '#b4df71'],
    [0.29411764705882354, '#b2df73'],
    [0.2980392156862745, '#b0de74'],
    [0.30196078431372547, '#aede76'],
    [0.3058823529411765, '#abde78'],
    [0.30980392156862746, '#a9dd79'],
    [0.3137254901960784, '#a7dd7b'],
    [0.3176470588235294, '#a5dc7d'],
    [0.32156862745098036, '#a3dc7e'],
    [0.3254901960784314, '#a1db80'],
    [0.32941176470588235, '#9fdb82'],
    [0.3333333333333333, '#9dda83'],
    [0.33725490196078434, '#9bda85'],
    [0.3411764705882353, '#98d986'],
    [0.34509803921568627, '#96d988'],
    [0.34901960784313724, '#94d88a'],
    [0.3529411764705882, '#92d88b'],
    [0.3568627450980392, '#90d78d'],
    [0.3607843137254902, '#8ed78e'],
    [0.36470588235294116, '#8cd690'],
    [0.3686274509803922, '#8ad592'],
    [0.37254901960784315, '#88d593'],
    [0.3764705882352941, '#86d495'],
    [0.3803921568627451, '#84d396'],
    [0.38431372549019605, '#81d398'],
    [0.38823529411764707, '#7fd299'],
    [0.39215686274509803, '#7dd19b'],
    [0.396078431372549, '#7bd19c'],
    [0.4, '#79d09e'],
    [0.403921568627451, '#77cf9f'],
    [0.40784313725490196, '#74cfa1'],
    [0.4117647058823529, '#72cea2'],
    [0.4156862745098039, '#70cda4'],
    [0.4196078431372549, '#6ecda5'],
    [0.4235294117647059, '#6bcca6'],
    [0.42745098039215684, '#69cba8'],
    [0.43137254901960786, '#67caa9'],
    [0.43529411764705883, '#65caab'],
    [0.4392156862745098, '#62c9ac'],
    [0.44313725490196076, '#60c8ad'],
    [0.44705882352941173, '#5ec7af'],
    [0.45098039215686275, '#5cc6b0'],
    [0.4549019607843137, '#59c6b2'],
    [0.4588235294117647, '#57c5b3'],
    [0.4627450980392157, '#55c4b4'],
    [0.4666666666666667, '#52c3b5'],
    [0.47058823529411764, '#50c2b7'],
    [0.4745098039215686, '#4ec2b8'],
    [0.4784313725490196, '#4bc1b9'],
    [0.4823529411764706, '#49c0bb'],
    [0.48627450980392156, '#47bfbc'],
    [0.49019607843137253, '#44bebd'],
    [0.49411764705882355, '#42bdbe'],
    [0.4980392156862745, '#3fbcc0'],
    [0.5019607843137255, '#3dbbc1'],
    [0.5058823529411764, '#3bbbc2'],
    [0.5098039215686274, '#38bac3'],
    [0.5137254901960784, '#36b9c4'],
    [0.5176470588235293, '#33b8c5'],
    [0.5215686274509804, '#31b7c6'],
    [0.5254901960784314, '#2eb6c8'],
    [0.5294117647058824, '#2cb5c9'],
    [0.5333333333333333, '#29b4ca'],
    [0.5372549019607843, '#27b3cb'],
    [0.5411764705882353, '#24b2cc'],
    [0.5450980392156862, '#22b1cd'],
    [0.5490196078431373, '#20b0ce'],
    [0.5529411764705883, '#1dafcf'],
    [0.5568627450980392, '#1aafd0'],
    [0.5607843137254902, '#18aed1'],
    [0.5647058823529412, '#16add2'],
    [0.5686274509803921, '#13acd3'],
    [0.5725490196078431, '#11abd4'],
    [0.5764705882352941, '#0eaad4'],
    [0.580392156862745, '#0ca9d5'],
    [0.5843137254901961, '#09a8d6'],
    [0.5882352941176471, '#07a7d7'],
    [0.592156862745098, '#06a6d8'],
    [0.596078431372549, '#04a5d9'],
    [0.6, '#04a4d9'],
    [0.6039215686274509, '#03a3da'],
    [0.6078431372549019, '#03a2db'],
    [0.611764705882353, '#03a1dc'],
    [0.615686274509804, '#04a0dd'],
    [0.6196078431372549, '#059fdd'],
    [0.6235294117647059, '#069ede'],
    [0.6274509803921569, '#089ddf'],
    [0.6313725490196078, '#0a9cdf'],
    [0.6352941176470588, '#0c9be0'],
    [0.6392156862745098, '#0f9ae1'],
    [0.6431372549019607, '#1199e1'],
    [0.6470588235294118, '#1398e2'],
    [0.6509803921568628, '#1597e2'],
    [0.6549019607843137, '#1796e3'],
    [0.6588235294117647, '#1a94e4'],
    [0.6627450980392157, '#1c93e4'],
    [0.6666666666666666, '#1e92e5'],
    [0.6705882352941176, '#2191e5'],
    [0.6745098039215687, '#2390e6'],
    [0.6784313725490196, '#258fe6'],
    [0.6823529411764706, '#288ee7'],
    [0.6862745098039216, '#2a8de7'],
    [0.6901960784313725, '#2d8ce8'],
    [0.6941176470588235, '#308be8'],
    [0.6980392156862745, '#328ae8'],
    [0.7019607843137254, '#3589e9'],
    [0.7058823529411764, '#3888e9'],
    [0.7098039215686275, '#3b87e9'],
    [0.7137254901960784, '#3e86e9'],
    [0.7176470588235294, '#4285e9'],
    [0.7215686274509804, '#4583e9'],
    [0.7254901960784313, '#4982e9'],
    [0.7294117647058823, '#4c81e9'],
    [0.7333333333333333, '#5080e9'],
    [0.7372549019607844, '#547fe9'],
    [0.7411764705882353, '#587ee8'],
    [0.7450980392156863, '#5c7de8'],
    [0.7490196078431373, '#617ce7'],
    [0.7529411764705882, '#657be7'],
    [0.7568627450980392, '#697be6'],
    [0.7607843137254902, '#6d7ae5'],
    [0.7647058823529411, '#7279e4'],
    [0.7686274509803921, '#7679e3'],
    [0.7725490196078432, '#7a78e2'],
    [0.7764705882352941, '#7e78e1'],
    [0.7803921568627451, '#8177df'],
    [0.7843137254901961, '#8577de'],
    [0.788235294117647, '#8877dd'],
    [0.792156862745098, '#8c77dc'],
    [0.796078431372549, '#8f76da'],
    [0.8, '#9276d9'],
    [0.803921568627451, '#9576d8'],
    [0.807843137254902, '#9876d6'],
    [0.8117647058823529, '#9b77d5'],
    [0.8156862745098039, '#9d77d4'],
    [0.8196078431372549, '#a077d2'],
    [0.8235294117647058, '#a277d1'],
    [0.8274509803921568, '#a577d0'],
    [0.8313725490196078, '#a777ce'],
    [0.8352941176470589, '#aa78cd'],
    [0.8392156862745098, '#ac78cc'],
    [0.8431372549019608, '#ae78ca'],
    [0.8470588235294118, '#b078c9'],
    [0.8509803921568627, '#b279c8'],
    [0.8549019607843137, '#b579c6'],
    [0.8588235294117647, '#b779c5'],
    [0.8627450980392157, '#b97ac4'],
    [0.8666666666666667, '#bb7ac2'],
    [0.8705882352941177, '#bc7bc1'],
    [0.8745098039215686, '#be7bbf'],
    [0.8784313725490196, '#c07bbe'],
    [0.8823529411764706, '#c27cbd'],
    [0.8862745098039215, '#c47cbb'],
    [0.8901960784313725, '#c57dba'],
    [0.8941176470588235, '#c77db9'],
    [0.8980392156862745, '#c97eb7'],
    [0.9019607843137255, '#ca7eb6'],
    [0.9058823529411765, '#cc7fb5'],
    [0.9098039215686274, '#ce80b4'],
    [0.9137254901960784, '#cf80b2'],
    [0.9176470588235294, '#d081b1'],
    [0.9215686274509803, '#d282b0'],
    [0.9254901960784314, '#d382ae'],
    [0.9294117647058824, '#d583ad'],
    [0.9333333333333333, '#d684ac'],
    [0.9372549019607843, '#d784aa'],
    [0.9411764705882353, '#d985a9'],
    [0.9450980392156862, '#da86a8'],
    [0.9490196078431372, '#db87a6'],
    [0.9529411764705882, '#dc87a5'],
    [0.9568627450980391, '#dd88a4'],
    [0.9607843137254902, '#de89a3'],
    [0.9647058823529412, '#e08aa1'],
    [0.9686274509803922, '#e18ba0'],
    [0.9725490196078431, '#e28c9f'],
    [0.9764705882352941, '#e38d9e'],
    [0.9803921568627451, '#e38e9c'],
    [0.984313725490196, '#e48f9b'],
    [0.9882352941176471, '#e5909a'],
    [0.9921568627450981, '#e69099'],
    [0.996078431372549, '#e79197'],
    [1.0, '#e89296'],
  ],
  CET_C8s: [
    [0.0, '#657be7'],
    [0.00392156862745098, '#697be6'],
    [0.00784313725490196, '#6d7ae5'],
    [0.011764705882352941, '#7279e4'],
    [0.01568627450980392, '#7679e3'],
    [0.0196078431372549, '#7a78e2'],
    [0.023529411764705882, '#7e78e1'],
    [0.027450980392156862, '#8177df'],
    [0.03137254901960784, '#8577de'],
    [0.03529411764705882, '#8877dd'],
    [0.0392156862745098, '#8c77dc'],
    [0.043137254901960784, '#8f76da'],
    [0.047058823529411764, '#9276d9'],
    [0.050980392156862744, '#9576d8'],
    [0.054901960784313725, '#9876d6'],
    [0.058823529411764705, '#9b77d5'],
    [0.06274509803921569, '#9d77d4'],
    [0.06666666666666667, '#a077d2'],
    [0.07058823529411765, '#a277d1'],
    [0.07450980392156863, '#a577d0'],
    [0.0784313725490196, '#a777ce'],
    [0.08235294117647059, '#aa78cd'],
    [0.08627450980392157, '#ac78cc'],
    [0.09019607843137255, '#ae78ca'],
    [0.09411764705882353, '#b078c9'],
    [0.09803921568627451, '#b279c8'],
    [0.10196078431372549, '#b579c6'],
    [0.10588235294117647, '#b779c5'],
    [0.10980392156862745, '#b97ac4'],
    [0.11372549019607843, '#bb7ac2'],
    [0.11764705882352941, '#bc7bc1'],
    [0.12156862745098039, '#be7bbf'],
    [0.12549019607843137, '#c07bbe'],
    [0.12941176470588234, '#c27cbd'],
    [0.13333333333333333, '#c47cbb'],
    [0.13725490196078433, '#c57dba'],
    [0.1411764705882353, '#c77db9'],
    [0.14509803921568626, '#c97eb7'],
    [0.14901960784313725, '#ca7eb6'],
    [0.15294117647058825, '#cc7fb5'],
    [0.1568627450980392, '#ce80b4'],
    [0.16078431372549018, '#cf80b2'],
    [0.16470588235294117, '#d081b1'],
    [0.16862745098039217, '#d282b0'],
    [0.17254901960784313, '#d382ae'],
    [0.1764705882352941, '#d583ad'],
    [0.1803921568627451, '#d684ac'],
    [0.1843137254901961, '#d784aa'],
    [0.18823529411764706, '#d985a9'],
    [0.19215686274509802, '#da86a8'],
    [0.19607843137254902, '#db87a6'],
    [0.2, '#dc87a5'],
    [0.20392156862745098, '#dd88a4'],
    [0.20784313725490194, '#de89a3'],
    [0.21176470588235294, '#e08aa1'],
    [0.21568627450980393, '#e18ba0'],
    [0.2196078431372549, '#e28c9f'],
    [0.22352941176470587, '#e38d9e'],
    [0.22745098039215686, '#e38e9c'],
    [0.23137254901960785, '#e48f9b'],
    [0.23529411764705882, '#e5909a'],
    [0.2392156862745098, '#e69099'],
    [0.24313725490196078, '#e79197'],
    [0.24705882352941178, '#e89296'],
    [0.25098039215686274, '#e89495'],
    [0.2549019607843137, '#e99594'],
    [0.2588235294117647, '#ea9692'],
    [0.2627450980392157, '#ea9791'],
    [0.26666666666666666, '#eb9890'],
    [0.27058823529411763, '#eb998f'],
    [0.27450980392156865, '#ec9a8e'],
    [0.2784313725490196, '#ec9b8d'],
    [0.2823529411764706, '#ed9c8b'],
    [0.28627450980392155, '#ed9d8a'],
    [0.2901960784313725, '#ed9f89'],
    [0.29411764705882354, '#eea088'],
    [0.2980392156862745, '#eea187'],
    [0.30196078431372547, '#eea286'],
    [0.3058823529411765, '#efa385'],
    [0.30980392156862746, '#efa584'],
    [0.3137254901960784, '#efa683'],
    [0.3176470588235294, '#efa782'],
    [0.32156862745098036, '#efa880'],
    [0.3254901960784314, '#efa97f'],
    [0.32941176470588235, '#efab7e'],
    [0.3333333333333333, '#efac7d'],
    [0.33725490196078434, '#efad7c'],
    [0.3411764705882353, '#efae7b'],
    [0.34509803921568627, '#efaf7a'],
    [0.34901960784313724, '#efb179'],
    [0.3529411764705882, '#efb278'],
    [0.3568627450980392, '#efb377'],
    [0.3607843137254902, '#eeb476'],
    [0.36470588235294116, '#eeb675'],
    [0.3686274509803922, '#eeb774'],
    [0.37254901960784315, '#eeb874'],
    [0.3764705882352941, '#edb973'],
    [0.3803921568627451, '#edbb72'],
    [0.38431372549019605, '#edbc71'],
    [0.38823529411764707, '#ecbd70'],
    [0.39215686274509803, '#ecbe6f'],
    [0.396078431372549, '#ebc06e'],
    [0.4, '#ebc16d'],
    [0.403921568627451, '#eac26c'],
    [0.40784313725490196, '#eac36b'],
    [0.4117647058823529, '#e9c56b'],
    [0.4156862745098039, '#e9c66a'],
    [0.4196078431372549, '#e8c769'],
    [0.4235294117647059, '#e7c868'],
    [0.42745098039215684, '#e6c967'],
    [0.43137254901960786, '#e6cb66'],
    [0.43529411764705883, '#e5cc66'],
    [0.4392156862745098, '#e4cd65'],
    [0.44313725490196076, '#e3ce64'],
    [0.44705882352941173, '#e2cf64'],
    [0.45098039215686275, '#e1d163'],
    [0.4549019607843137, '#e0d262'],
    [0.4588235294117647, '#dfd362'],
    [0.4627450980392157, '#ded461'],
    [0.4666666666666667, '#dcd561'],
    [0.47058823529411764, '#dbd661'],
    [0.4745098039215686, '#d9d761'],
    [0.4784313725490196, '#d8d860'],
    [0.4823529411764706, '#d6d961'],
    [0.48627450980392156, '#d4da61'],
    [0.49019607843137253, '#d2db61'],
    [0.49411764705882355, '#d0dc61'],
    [0.4980392156862745, '#cedd62'],
    [0.5019607843137255, '#ccdd63'],
    [0.5058823529411764, '#c9de64'],
    [0.5098039215686274, '#c7de65'],
    [0.5137254901960784, '#c5df66'],
    [0.5176470588235293, '#c2df68'],
    [0.5215686274509804, '#c0df69'],
    [0.5254901960784314, '#bedf6a'],
    [0.5294117647058824, '#bbdf6c'],
    [0.5333333333333333, '#b9df6e'],
    [0.5372549019607843, '#b7df6f'],
    [0.5411764705882353, '#b4df71'],
    [0.5450980392156862, '#b2df73'],
    [0.5490196078431373, '#b0de74'],
    [0.5529411764705883, '#aede76'],
    [0.5568627450980392, '#abde78'],
    [0.5607843137254902, '#a9dd79'],
    [0.5647058823529412, '#a7dd7b'],
    [0.5686274509803921, '#a5dc7d'],
    [0.5725490196078431, '#a3dc7e'],
    [0.5764705882352941, '#a1db80'],
    [0.580392156862745, '#9fdb82'],
    [0.5843137254901961, '#9dda83'],
    [0.5882352941176471, '#9bda85'],
    [0.592156862745098, '#98d986'],
    [0.596078431372549, '#96d988'],
    [0.6, '#94d88a'],
    [0.6039215686274509, '#92d88b'],
    [0.6078431372549019, '#90d78d'],
    [0.611764705882353, '#8ed78e'],
    [0.615686274509804, '#8cd690'],
    [0.6196078431372549, '#8ad592'],
    [0.6235294117647059, '#88d593'],
    [0.6274509803921569, '#86d495'],
    [0.6313725490196078, '#84d396'],
    [0.6352941176470588, '#81d398'],
    [0.6392156862745098, '#7fd299'],
    [0.6431372549019607, '#7dd19b'],
    [0.6470588235294118, '#7bd19c'],
    [0.6509803921568628, '#79d09e'],
    [0.6549019607843137, '#77cf9f'],
    [0.6588235294117647, '#74cfa1'],
    [0.6627450980392157, '#72cea2'],
    [0.6666666666666666, '#70cda4'],
    [0.6705882352941176, '#6ecda5'],
    [0.6745098039215687, '#6bcca6'],
    [0.6784313725490196, '#69cba8'],
    [0.6823529411764706, '#67caa9'],
    [0.6862745098039216, '#65caab'],
    [0.6901960784313725, '#62c9ac'],
    [0.6941176470588235, '#60c8ad'],
    [0.6980392156862745, '#5ec7af'],
    [0.7019607843137254, '#5cc6b0'],
    [0.7058823529411764, '#59c6b2'],
    [0.7098039215686275, '#57c5b3'],
    [0.7137254901960784, '#55c4b4'],
    [0.7176470588235294, '#52c3b5'],
    [0.7215686274509804, '#50c2b7'],
    [0.7254901960784313, '#4ec2b8'],
    [0.7294117647058823, '#4bc1b9'],
    [0.7333333333333333, '#49c0bb'],
    [0.7372549019607844, '#47bfbc'],
    [0.7411764705882353, '#44bebd'],
    [0.7450980392156863, '#42bdbe'],
    [0.7490196078431373, '#3fbcc0'],
    [0.7529411764705882, '#3dbbc1'],
    [0.7568627450980392, '#3bbbc2'],
    [0.7607843137254902, '#38bac3'],
    [0.7647058823529411, '#36b9c4'],
    [0.7686274509803921, '#33b8c5'],
    [0.7725490196078432, '#31b7c6'],
    [0.7764705882352941, '#2eb6c8'],
    [0.7803921568627451, '#2cb5c9'],
    [0.7843137254901961, '#29b4ca'],
    [0.788235294117647, '#27b3cb'],
    [0.792156862745098, '#24b2cc'],
    [0.796078431372549, '#22b1cd'],
    [0.8, '#20b0ce'],
    [0.803921568627451, '#1dafcf'],
    [0.807843137254902, '#1aafd0'],
    [0.8117647058823529, '#18aed1'],
    [0.8156862745098039, '#16add2'],
    [0.8196078431372549, '#13acd3'],
    [0.8235294117647058, '#11abd4'],
    [0.8274509803921568, '#0eaad4'],
    [0.8313725490196078, '#0ca9d5'],
    [0.8352941176470589, '#09a8d6'],
    [0.8392156862745098, '#07a7d7'],
    [0.8431372549019608, '#06a6d8'],
    [0.8470588235294118, '#04a5d9'],
    [0.8509803921568627, '#04a4d9'],
    [0.8549019607843137, '#03a3da'],
    [0.8588235294117647, '#03a2db'],
    [0.8627450980392157, '#03a1dc'],
    [0.8666666666666667, '#04a0dd'],
    [0.8705882352941177, '#059fdd'],
    [0.8745098039215686, '#069ede'],
    [0.8784313725490196, '#089ddf'],
    [0.8823529411764706, '#0a9cdf'],
    [0.8862745098039215, '#0c9be0'],
    [0.8901960784313725, '#0f9ae1'],
    [0.8941176470588235, '#1199e1'],
    [0.8980392156862745, '#1398e2'],
    [0.9019607843137255, '#1597e2'],
    [0.9058823529411765, '#1796e3'],
    [0.9098039215686274, '#1a94e4'],
    [0.9137254901960784, '#1c93e4'],
    [0.9176470588235294, '#1e92e5'],
    [0.9215686274509803, '#2191e5'],
    [0.9254901960784314, '#2390e6'],
    [0.9294117647058824, '#258fe6'],
    [0.9333333333333333, '#288ee7'],
    [0.9372549019607843, '#2a8de7'],
    [0.9411764705882353, '#2d8ce8'],
    [0.9450980392156862, '#308be8'],
    [0.9490196078431372, '#328ae8'],
    [0.9529411764705882, '#3589e9'],
    [0.9568627450980391, '#3888e9'],
    [0.9607843137254902, '#3b87e9'],
    [0.9647058823529412, '#3e86e9'],
    [0.9686274509803922, '#4285e9'],
    [0.9725490196078431, '#4583e9'],
    [0.9764705882352941, '#4982e9'],
    [0.9803921568627451, '#4c81e9'],
    [0.984313725490196, '#5080e9'],
    [0.9882352941176471, '#547fe9'],
    [0.9921568627450981, '#587ee8'],
    [0.996078431372549, '#5c7de8'],
    [1.0, '#617ce7'],
  ],
  CET_C9: [
    [0.0, '#c27f74'],
    [0.00392156862745098, '#c38074'],
    [0.00784313725490196, '#c38273'],
    [0.011764705882352941, '#c48473'],
    [0.01568627450980392, '#c58672'],
    [0.0196078431372549, '#c68871'],
    [0.023529411764705882, '#c78a71'],
    [0.027450980392156862, '#c78c70'],
    [0.03137254901960784, '#c88e70'],
    [0.03529411764705882, '#c98f6f'],
    [0.0392156862745098, '#ca916f'],
    [0.043137254901960784, '#ca936e'],
    [0.047058823529411764, '#cb956e'],
    [0.050980392156862744, '#cb976d'],
    [0.054901960784313725, '#cc996d'],
    [0.058823529411764705, '#cc9b6c'],
    [0.06274509803921569, '#cd9d6c'],
    [0.06666666666666667, '#cd9f6b'],
    [0.07058823529411765, '#cea16b'],
    [0.07450980392156863, '#cea26a'],
    [0.0784313725490196, '#cfa46a'],
    [0.08235294117647059, '#cfa66a'],
    [0.08627450980392157, '#cfa869'],
    [0.09019607843137255, '#d0aa69'],
    [0.09411764705882353, '#d0ac68'],
    [0.09803921568627451, '#d0ae68'],
    [0.10196078431372549, '#d0b067'],
    [0.10588235294117647, '#d1b267'],
    [0.10980392156862745, '#d1b467'],
    [0.11372549019607843, '#d1b666'],
    [0.11764705882352941, '#d1b866'],
    [0.12156862745098039, '#d1ba65'],
    [0.12549019607843137, '#d1bb65'],
    [0.12941176470588234, '#d1bd65'],
    [0.13333333333333333, '#d1bf64'],
    [0.13725490196078433, '#d1c164'],
    [0.1411764705882353, '#d1c364'],
    [0.14509803921568626, '#d1c563'],
    [0.14901960784313725, '#d1c763'],
    [0.15294117647058825, '#d1c963'],
    [0.1568627450980392, '#d0cb62'],
    [0.16078431372549018, '#d0cd62'],
    [0.16470588235294117, '#d0cf62'],
    [0.16862745098039217, '#cfd161'],
    [0.17254901960784313, '#cfd261'],
    [0.1764705882352941, '#cfd461'],
    [0.1803921568627451, '#ced661'],
    [0.1843137254901961, '#cdd861'],
    [0.18823529411764706, '#cdda61'],
    [0.19215686274509802, '#ccdc60'],
    [0.19607843137254902, '#cbde60'],
    [0.2, '#cbdf61'],
    [0.20392156862745098, '#cae161'],
    [0.20784313725490194, '#c9e361'],
    [0.21176470588235294, '#c7e461'],
    [0.21568627450980393, '#c6e661'],
    [0.2196078431372549, '#c5e762'],
    [0.22352941176470587, '#c3e962'],
    [0.22745098039215686, '#c2ea63'],
    [0.23137254901960785, '#c0eb64'],
    [0.23529411764705882, '#beec65'],
    [0.2392156862745098, '#bced65'],
    [0.24313725490196078, '#baee66'],
    [0.24705882352941178, '#b8ef68'],
    [0.25098039215686274, '#b6ef69'],
    [0.2549019607843137, '#b4f06a'],
    [0.2588235294117647, '#b1f06c'],
    [0.2627450980392157, '#aff06d'],
    [0.26666666666666666, '#acf06e'],
    [0.27058823529411763, '#aaf070'],
    [0.27450980392156865, '#a8ef72'],
    [0.2784313725490196, '#a5ef73'],
    [0.2823529411764706, '#a3ee75'],
    [0.28627450980392155, '#a0ee76'],
    [0.2901960784313725, '#9eed78'],
    [0.29411764705882354, '#9bec79'],
    [0.2980392156862745, '#99eb7b'],
    [0.30196078431372547, '#97ea7c'],
    [0.3058823529411765, '#94e97e'],
    [0.30980392156862746, '#92e77f'],
    [0.3137254901960784, '#90e681'],
    [0.3176470588235294, '#8ee582'],
    [0.32156862745098036, '#8ce484'],
    [0.3254901960784314, '#8ae285'],
    [0.32941176470588235, '#87e186'],
    [0.3333333333333333, '#85e088'],
    [0.33725490196078434, '#83de89'],
    [0.3411764705882353, '#81dd8a'],
    [0.34509803921568627, '#7fdb8b'],
    [0.34901960784313724, '#7dda8c'],
    [0.3529411764705882, '#7bd98e'],
    [0.3568627450980392, '#79d78f'],
    [0.3607843137254902, '#77d690'],
    [0.36470588235294116, '#75d491'],
    [0.3686274509803922, '#74d392'],
    [0.37254901960784315, '#72d193'],
    [0.3764705882352941, '#70d094'],
    [0.3803921568627451, '#6ece95'],
    [0.38431372549019605, '#6ccd96'],
    [0.38823529411764707, '#6acb97'],
    [0.39215686274509803, '#68ca98'],
    [0.396078431372549, '#66c899'],
    [0.4, '#64c79a'],
    [0.403921568627451, '#62c59b'],
    [0.40784313725490196, '#60c49c'],
    [0.4117647058823529, '#5ec29d'],
    [0.4156862745098039, '#5dc19e'],
    [0.4196078431372549, '#5bbf9f'],
    [0.4235294117647059, '#59bd9f'],
    [0.42745098039215684, '#57bca0'],
    [0.43137254901960786, '#55baa1'],
    [0.43529411764705883, '#53b9a2'],
    [0.4392156862745098, '#51b7a3'],
    [0.44313725490196076, '#4fb6a3'],
    [0.44705882352941173, '#4eb4a4'],
    [0.45098039215686275, '#4cb3a5'],
    [0.4549019607843137, '#4ab1a5'],
    [0.4588235294117647, '#48afa6'],
    [0.4627450980392157, '#46aea7'],
    [0.4666666666666667, '#44aca7'],
    [0.47058823529411764, '#43aba8'],
    [0.4745098039215686, '#41a9a9'],
    [0.4784313725490196, '#3fa7a9'],
    [0.4823529411764706, '#3da6aa'],
    [0.48627450980392156, '#3ba4aa'],
    [0.49019607843137253, '#3aa3ab'],
    [0.49411764705882355, '#38a1ab'],
    [0.4980392156862745, '#369fac'],
    [0.5019607843137255, '#349eac'],
    [0.5058823529411764, '#329cad'],
    [0.5098039215686274, '#319aad'],
    [0.5137254901960784, '#2f99ae'],
    [0.5176470588235293, '#2d97ae'],
    [0.5215686274509804, '#2b96ae'],
    [0.5254901960784314, '#2a94af'],
    [0.5294117647058824, '#2892af'],
    [0.5333333333333333, '#2691b0'],
    [0.5372549019607843, '#258fb0'],
    [0.5411764705882353, '#238db0'],
    [0.5450980392156862, '#218cb0'],
    [0.5490196078431373, '#208ab1'],
    [0.5529411764705883, '#1e89b1'],
    [0.5568627450980392, '#1c87b1'],
    [0.5607843137254902, '#1b85b2'],
    [0.5647058823529412, '#1984b2'],
    [0.5686274509803921, '#1782b2'],
    [0.5725490196078431, '#1680b2'],
    [0.5764705882352941, '#147fb2'],
    [0.580392156862745, '#137db2'],
    [0.5843137254901961, '#117bb3'],
    [0.5882352941176471, '#107ab3'],
    [0.592156862745098, '#0f78b3'],
    [0.596078431372549, '#0d76b3'],
    [0.6, '#0c75b3'],
    [0.6039215686274509, '#0b73b3'],
    [0.6078431372549019, '#0a72b3'],
    [0.611764705882353, '#0970b3'],
    [0.615686274509804, '#086eb3'],
    [0.6196078431372549, '#076db3'],
    [0.6235294117647059, '#076bb3'],
    [0.6274509803921569, '#0669b3'],
    [0.6313725490196078, '#0668b3'],
    [0.6352941176470588, '#0666b3'],
    [0.6392156862745098, '#0664b3'],
    [0.6431372549019607, '#0763b3'],
    [0.6470588235294118, '#0761b3'],
    [0.6509803921568628, '#085fb3'],
    [0.6549019607843137, '#095eb3'],
    [0.6588235294117647, '#0a5cb3'],
    [0.6627450980392157, '#0b5ab2'],
    [0.6666666666666666, '#0c59b2'],
    [0.6705882352941176, '#0e57b2'],
    [0.6745098039215687, '#1055b2'],
    [0.6784313725490196, '#1154b2'],
    [0.6823529411764706, '#1352b1'],
    [0.6862745098039216, '#1550b1'],
    [0.6901960784313725, '#174fb1'],
    [0.6941176470588235, '#194db1'],
    [0.6980392156862745, '#1c4bb0'],
    [0.7019607843137254, '#1e4ab0'],
    [0.7058823529411764, '#2148af'],
    [0.7098039215686275, '#2346af'],
    [0.7137254901960784, '#2644ae'],
    [0.7176470588235294, '#2943ae'],
    [0.7215686274509804, '#2c41ad'],
    [0.7254901960784313, '#2f40ac'],
    [0.7294117647058823, '#323eac'],
    [0.7333333333333333, '#363dab'],
    [0.7372549019607844, '#393baa'],
    [0.7411764705882353, '#3d3aa9'],
    [0.7450980392156863, '#4038a8'],
    [0.7490196078431373, '#4337a8'],
    [0.7529411764705882, '#4736a7'],
    [0.7568627450980392, '#4a35a6'],
    [0.7607843137254902, '#4e34a5'],
    [0.7647058823529411, '#5133a4'],
    [0.7686274509803921, '#5533a3'],
    [0.7725490196078432, '#5832a2'],
    [0.7764705882352941, '#5b32a0'],
    [0.7803921568627451, '#5e329f'],
    [0.7843137254901961, '#61329e'],
    [0.788235294117647, '#64329d'],
    [0.792156862745098, '#67329c'],
    [0.796078431372549, '#6a329b'],
    [0.8, '#6d339a'],
    [0.803921568627451, '#703399'],
    [0.807843137254902, '#723498'],
    [0.8117647058823529, '#753598'],
    [0.8156862745098039, '#773597'],
    [0.8196078431372549, '#7a3696'],
    [0.8235294117647058, '#7c3795'],
    [0.8274509803921568, '#7e3894'],
    [0.8313725490196078, '#813993'],
    [0.8352941176470589, '#833b92'],
    [0.8392156862745098, '#853c91'],
    [0.8431372549019608, '#873d91'],
    [0.8470588235294118, '#893e90'],
    [0.8509803921568627, '#8b408f'],
    [0.8549019607843137, '#8d418e'],
    [0.8588235294117647, '#8f428d'],
    [0.8627450980392157, '#91448d'],
    [0.8666666666666667, '#92458c'],
    [0.8705882352941177, '#94468b'],
    [0.8745098039215686, '#96488a'],
    [0.8784313725490196, '#98498a'],
    [0.8823529411764706, '#9a4b89'],
    [0.8862745098039215, '#9b4c88'],
    [0.8901960784313725, '#9d4e87'],
    [0.8941176470588235, '#9e4f87'],
    [0.8980392156862745, '#a05186'],
    [0.9019607843137255, '#a25285'],
    [0.9058823529411765, '#a35484'],
    [0.9098039215686274, '#a55584'],
    [0.9137254901960784, '#a65783'],
    [0.9176470588235294, '#a85882'],
    [0.9215686274509803, '#a95a82'],
    [0.9254901960784314, '#ab5c81'],
    [0.9294117647058824, '#ac5d80'],
    [0.9333333333333333, '#ad5f80'],
    [0.9372549019607843, '#af617f'],
    [0.9411764705882353, '#b0627e'],
    [0.9450980392156862, '#b1647e'],
    [0.9490196078431372, '#b3667d'],
    [0.9529411764705882, '#b4677c'],
    [0.9568627450980391, '#b5697c'],
    [0.9607843137254902, '#b66b7b'],
    [0.9647058823529412, '#b76d7a'],
    [0.9686274509803922, '#b86e7a'],
    [0.9725490196078431, '#ba7079'],
    [0.9764705882352941, '#bb7278'],
    [0.9803921568627451, '#bc7478'],
    [0.984313725490196, '#bd7577'],
    [0.9882352941176471, '#be7777'],
    [0.9921568627450981, '#bf7976'],
    [0.996078431372549, '#c07b75'],
    [1.0, '#c17d75'],
  ],
  CET_C9s: [
    [0.0, '#4736a7'],
    [0.00392156862745098, '#4a35a6'],
    [0.00784313725490196, '#4e34a5'],
    [0.011764705882352941, '#5133a4'],
    [0.01568627450980392, '#5533a3'],
    [0.0196078431372549, '#5832a2'],
    [0.023529411764705882, '#5b32a0'],
    [0.027450980392156862, '#5e329f'],
    [0.03137254901960784, '#61329e'],
    [0.03529411764705882, '#64329d'],
    [0.0392156862745098, '#67329c'],
    [0.043137254901960784, '#6a329b'],
    [0.047058823529411764, '#6d339a'],
    [0.050980392156862744, '#703399'],
    [0.054901960784313725, '#723498'],
    [0.058823529411764705, '#753598'],
    [0.06274509803921569, '#773597'],
    [0.06666666666666667, '#7a3696'],
    [0.07058823529411765, '#7c3795'],
    [0.07450980392156863, '#7e3894'],
    [0.0784313725490196, '#813993'],
    [0.08235294117647059, '#833b92'],
    [0.08627450980392157, '#853c91'],
    [0.09019607843137255, '#873d91'],
    [0.09411764705882353, '#893e90'],
    [0.09803921568627451, '#8b408f'],
    [0.10196078431372549, '#8d418e'],
    [0.10588235294117647, '#8f428d'],
    [0.10980392156862745, '#91448d'],
    [0.11372549019607843, '#92458c'],
    [0.11764705882352941, '#94468b'],
    [0.12156862745098039, '#96488a'],
    [0.12549019607843137, '#98498a'],
    [0.12941176470588234, '#9a4b89'],
    [0.13333333333333333, '#9b4c88'],
    [0.13725490196078433, '#9d4e87'],
    [0.1411764705882353, '#9e4f87'],
    [0.14509803921568626, '#a05186'],
    [0.14901960784313725, '#a25285'],
    [0.15294117647058825, '#a35484'],
    [0.1568627450980392, '#a55584'],
    [0.16078431372549018, '#a65783'],
    [0.16470588235294117, '#a85882'],
    [0.16862745098039217, '#a95a82'],
    [0.17254901960784313, '#ab5c81'],
    [0.1764705882352941, '#ac5d80'],
    [0.1803921568627451, '#ad5f80'],
    [0.1843137254901961, '#af617f'],
    [0.18823529411764706, '#b0627e'],
    [0.19215686274509802, '#b1647e'],
    [0.19607843137254902, '#b3667d'],
    [0.2, '#b4677c'],
    [0.20392156862745098, '#b5697c'],
    [0.20784313725490194, '#b66b7b'],
    [0.21176470588235294, '#b76d7a'],
    [0.21568627450980393, '#b86e7a'],
    [0.2196078431372549, '#ba7079'],
    [0.22352941176470587, '#bb7278'],
    [0.22745098039215686, '#bc7478'],
    [0.23137254901960785, '#bd7577'],
    [0.23529411764705882, '#be7777'],
    [0.2392156862745098, '#bf7976'],
    [0.24313725490196078, '#c07b75'],
    [0.24705882352941178, '#c17d75'],
    [0.25098039215686274, '#c27f74'],
    [0.2549019607843137, '#c38074'],
    [0.2588235294117647, '#c38273'],
    [0.2627450980392157, '#c48473'],
    [0.26666666666666666, '#c58672'],
    [0.27058823529411763, '#c68871'],
    [0.27450980392156865, '#c78a71'],
    [0.2784313725490196, '#c78c70'],
    [0.2823529411764706, '#c88e70'],
    [0.28627450980392155, '#c98f6f'],
    [0.2901960784313725, '#ca916f'],
    [0.29411764705882354, '#ca936e'],
    [0.2980392156862745, '#cb956e'],
    [0.30196078431372547, '#cb976d'],
    [0.3058823529411765, '#cc996d'],
    [0.30980392156862746, '#cc9b6c'],
    [0.3137254901960784, '#cd9d6c'],
    [0.3176470588235294, '#cd9f6b'],
    [0.32156862745098036, '#cea16b'],
    [0.3254901960784314, '#cea26a'],
    [0.32941176470588235, '#cfa46a'],
    [0.3333333333333333, '#cfa66a'],
    [0.33725490196078434, '#cfa869'],
    [0.3411764705882353, '#d0aa69'],
    [0.34509803921568627, '#d0ac68'],
    [0.34901960784313724, '#d0ae68'],
    [0.3529411764705882, '#d0b067'],
    [0.3568627450980392, '#d1b267'],
    [0.3607843137254902, '#d1b467'],
    [0.36470588235294116, '#d1b666'],
    [0.3686274509803922, '#d1b866'],
    [0.37254901960784315, '#d1ba65'],
    [0.3764705882352941, '#d1bb65'],
    [0.3803921568627451, '#d1bd65'],
    [0.38431372549019605, '#d1bf64'],
    [0.38823529411764707, '#d1c164'],
    [0.39215686274509803, '#d1c364'],
    [0.396078431372549, '#d1c563'],
    [0.4, '#d1c763'],
    [0.403921568627451, '#d1c963'],
    [0.40784313725490196, '#d0cb62'],
    [0.4117647058823529, '#d0cd62'],
    [0.4156862745098039, '#d0cf62'],
    [0.4196078431372549, '#cfd161'],
    [0.4235294117647059, '#cfd261'],
    [0.42745098039215684, '#cfd461'],
    [0.43137254901960786, '#ced661'],
    [0.43529411764705883, '#cdd861'],
    [0.4392156862745098, '#cdda61'],
    [0.44313725490196076, '#ccdc60'],
    [0.44705882352941173, '#cbde60'],
    [0.45098039215686275, '#cbdf61'],
    [0.4549019607843137, '#cae161'],
    [0.4588235294117647, '#c9e361'],
    [0.4627450980392157, '#c7e461'],
    [0.4666666666666667, '#c6e661'],
    [0.47058823529411764, '#c5e762'],
    [0.4745098039215686, '#c3e962'],
    [0.4784313725490196, '#c2ea63'],
    [0.4823529411764706, '#c0eb64'],
    [0.48627450980392156, '#beec65'],
    [0.49019607843137253, '#bced65'],
    [0.49411764705882355, '#baee66'],
    [0.4980392156862745, '#b8ef68'],
    [0.5019607843137255, '#b6ef69'],
    [0.5058823529411764, '#b4f06a'],
    [0.5098039215686274, '#b1f06c'],
    [0.5137254901960784, '#aff06d'],
    [0.5176470588235293, '#acf06e'],
    [0.5215686274509804, '#aaf070'],
    [0.5254901960784314, '#a8ef72'],
    [0.5294117647058824, '#a5ef73'],
    [0.5333333333333333, '#a3ee75'],
    [0.5372549019607843, '#a0ee76'],
    [0.5411764705882353, '#9eed78'],
    [0.5450980392156862, '#9bec79'],
    [0.5490196078431373, '#99eb7b'],
    [0.5529411764705883, '#97ea7c'],
    [0.5568627450980392, '#94e97e'],
    [0.5607843137254902, '#92e77f'],
    [0.5647058823529412, '#90e681'],
    [0.5686274509803921, '#8ee582'],
    [0.5725490196078431, '#8ce484'],
    [0.5764705882352941, '#8ae285'],
    [0.580392156862745, '#87e186'],
    [0.5843137254901961, '#85e088'],
    [0.5882352941176471, '#83de89'],
    [0.592156862745098, '#81dd8a'],
    [0.596078431372549, '#7fdb8b'],
    [0.6, '#7dda8c'],
    [0.6039215686274509, '#7bd98e'],
    [0.6078431372549019, '#79d78f'],
    [0.611764705882353, '#77d690'],
    [0.615686274509804, '#75d491'],
    [0.6196078431372549, '#74d392'],
    [0.6235294117647059, '#72d193'],
    [0.6274509803921569, '#70d094'],
    [0.6313725490196078, '#6ece95'],
    [0.6352941176470588, '#6ccd96'],
    [0.6392156862745098, '#6acb97'],
    [0.6431372549019607, '#68ca98'],
    [0.6470588235294118, '#66c899'],
    [0.6509803921568628, '#64c79a'],
    [0.6549019607843137, '#62c59b'],
    [0.6588235294117647, '#60c49c'],
    [0.6627450980392157, '#5ec29d'],
    [0.6666666666666666, '#5dc19e'],
    [0.6705882352941176, '#5bbf9f'],
    [0.6745098039215687, '#59bd9f'],
    [0.6784313725490196, '#57bca0'],
    [0.6823529411764706, '#55baa1'],
    [0.6862745098039216, '#53b9a2'],
    [0.6901960784313725, '#51b7a3'],
    [0.6941176470588235, '#4fb6a3'],
    [0.6980392156862745, '#4eb4a4'],
    [0.7019607843137254, '#4cb3a5'],
    [0.7058823529411764, '#4ab1a5'],
    [0.7098039215686275, '#48afa6'],
    [0.7137254901960784, '#46aea7'],
    [0.7176470588235294, '#44aca7'],
    [0.7215686274509804, '#43aba8'],
    [0.7254901960784313, '#41a9a9'],
    [0.7294117647058823, '#3fa7a9'],
    [0.7333333333333333, '#3da6aa'],
    [0.7372549019607844, '#3ba4aa'],
    [0.7411764705882353, '#3aa3ab'],
    [0.7450980392156863, '#38a1ab'],
    [0.7490196078431373, '#369fac'],
    [0.7529411764705882, '#349eac'],
    [0.7568627450980392, '#329cad'],
    [0.7607843137254902, '#319aad'],
    [0.7647058823529411, '#2f99ae'],
    [0.7686274509803921, '#2d97ae'],
    [0.7725490196078432, '#2b96ae'],
    [0.7764705882352941, '#2a94af'],
    [0.7803921568627451, '#2892af'],
    [0.7843137254901961, '#2691b0'],
    [0.788235294117647, '#258fb0'],
    [0.792156862745098, '#238db0'],
    [0.796078431372549, '#218cb0'],
    [0.8, '#208ab1'],
    [0.803921568627451, '#1e89b1'],
    [0.807843137254902, '#1c87b1'],
    [0.8117647058823529, '#1b85b2'],
    [0.8156862745098039, '#1984b2'],
    [0.8196078431372549, '#1782b2'],
    [0.8235294117647058, '#1680b2'],
    [0.8274509803921568, '#147fb2'],
    [0.8313725490196078, '#137db2'],
    [0.8352941176470589, '#117bb3'],
    [0.8392156862745098, '#107ab3'],
    [0.8431372549019608, '#0f78b3'],
    [0.8470588235294118, '#0d76b3'],
    [0.8509803921568627, '#0c75b3'],
    [0.8549019607843137, '#0b73b3'],
    [0.8588235294117647, '#0a72b3'],
    [0.8627450980392157, '#0970b3'],
    [0.8666666666666667, '#086eb3'],
    [0.8705882352941177, '#076db3'],
    [0.8745098039215686, '#076bb3'],
    [0.8784313725490196, '#0669b3'],
    [0.8823529411764706, '#0668b3'],
    [0.8862745098039215, '#0666b3'],
    [0.8901960784313725, '#0664b3'],
    [0.8941176470588235, '#0763b3'],
    [0.8980392156862745, '#0761b3'],
    [0.9019607843137255, '#085fb3'],
    [0.9058823529411765, '#095eb3'],
    [0.9098039215686274, '#0a5cb3'],
    [0.9137254901960784, '#0b5ab2'],
    [0.9176470588235294, '#0c59b2'],
    [0.9215686274509803, '#0e57b2'],
    [0.9254901960784314, '#1055b2'],
    [0.9294117647058824, '#1154b2'],
    [0.9333333333333333, '#1352b1'],
    [0.9372549019607843, '#1550b1'],
    [0.9411764705882353, '#174fb1'],
    [0.9450980392156862, '#194db1'],
    [0.9490196078431372, '#1c4bb0'],
    [0.9529411764705882, '#1e4ab0'],
    [0.9568627450980391, '#2148af'],
    [0.9607843137254902, '#2346af'],
    [0.9647058823529412, '#2644ae'],
    [0.9686274509803922, '#2943ae'],
    [0.9725490196078431, '#2c41ad'],
    [0.9764705882352941, '#2f40ac'],
    [0.9803921568627451, '#323eac'],
    [0.984313725490196, '#363dab'],
    [0.9882352941176471, '#393baa'],
    [0.9921568627450981, '#3d3aa9'],
    [0.996078431372549, '#4038a8'],
    [1.0, '#4337a8'],
  ],
  CET_CBC1: [
    [0.0, '#3e86ea'],
    [0.00392156862745098, '#4088eb'],
    [0.00784313725490196, '#4389ec'],
    [0.011764705882352941, '#478aed'],
    [0.01568627450980392, '#4b8cee'],
    [0.0196078431372549, '#4e8dee'],
    [0.023529411764705882, '#528fef'],
    [0.027450980392156862, '#5690ef'],
    [0.03137254901960784, '#5a92ef'],
    [0.03529411764705882, '#5e94ef'],
    [0.0392156862745098, '#6295f0'],
    [0.043137254901960784, '#6597f0'],
    [0.047058823529411764, '#6998f0'],
    [0.050980392156862744, '#6c9af0'],
    [0.054901960784313725, '#709cf0'],
    [0.058823529411764705, '#739df0'],
    [0.06274509803921569, '#769ff0'],
    [0.06666666666666667, '#79a0f0'],
    [0.07058823529411765, '#7da2f1'],
    [0.07450980392156863, '#80a4f1'],
    [0.0784313725490196, '#83a5f1'],
    [0.08235294117647059, '#86a7f1'],
    [0.08627450980392157, '#89a9f1'],
    [0.09019607843137255, '#8caaf1'],
    [0.09411764705882353, '#8eacf1'],
    [0.09803921568627451, '#91adf1'],
    [0.10196078431372549, '#94aff1'],
    [0.10588235294117647, '#97b1f1'],
    [0.10980392156862745, '#9ab2f2'],
    [0.11372549019607843, '#9cb4f2'],
    [0.11764705882352941, '#9fb6f2'],
    [0.12156862745098039, '#a2b8f2'],
    [0.12549019607843137, '#a4b9f2'],
    [0.12941176470588234, '#a7bbf2'],
    [0.13333333333333333, '#a9bdf2'],
    [0.13725490196078433, '#acbef2'],
    [0.1411764705882353, '#aec0f2'],
    [0.14509803921568626, '#b1c2f2'],
    [0.14901960784313725, '#b4c3f2'],
    [0.15294117647058825, '#b6c5f2'],
    [0.1568627450980392, '#b9c7f2'],
    [0.16078431372549018, '#bbc9f2'],
    [0.16470588235294117, '#bdcaf2'],
    [0.16862745098039217, '#c0ccf2'],
    [0.17254901960784313, '#c2cef3'],
    [0.1764705882352941, '#c5d0f3'],
    [0.1803921568627451, '#c7d1f3'],
    [0.1843137254901961, '#c9d3f3'],
    [0.18823529411764706, '#ccd5f3'],
    [0.19215686274509802, '#ced6f3'],
    [0.19607843137254902, '#d1d8f3'],
    [0.2, '#d3daf3'],
    [0.20392156862745098, '#d5dcf3'],
    [0.20784313725490194, '#d8ddf3'],
    [0.21176470588235294, '#dadff3'],
    [0.21568627450980393, '#dce1f2'],
    [0.2196078431372549, '#dee2f2'],
    [0.22352941176470587, '#e0e4f2'],
    [0.22745098039215686, '#e2e5f1'],
    [0.23137254901960785, '#e4e7f0'],
    [0.23529411764705882, '#e6e8ef'],
    [0.2392156862745098, '#e7e8ee'],
    [0.24313725490196078, '#e9e9ed'],
    [0.24705882352941178, '#eae9eb'],
    [0.25098039215686274, '#ebe9e9'],
    [0.2549019607843137, '#ebe9e6'],
    [0.2588235294117647, '#ebe9e4'],
    [0.2627450980392157, '#ebe8e1'],
    [0.26666666666666666, '#ebe7de'],
    [0.27058823529411763, '#ebe6db'],
    [0.27450980392156865, '#eae4d8'],
    [0.2784313725490196, '#e9e3d4'],
    [0.2823529411764706, '#e8e1d1'],
    [0.28627450980392155, '#e7e0cd'],
    [0.2901960784313725, '#e6deca'],
    [0.29411764705882354, '#e5dcc6'],
    [0.2980392156862745, '#e4dbc3'],
    [0.30196078431372547, '#e3d9bf'],
    [0.3058823529411765, '#e2d7bc'],
    [0.30980392156862746, '#e1d5b9'],
    [0.3137254901960784, '#dfd3b5'],
    [0.3176470588235294, '#ded2b2'],
    [0.32156862745098036, '#ddd0ae'],
    [0.3254901960784314, '#dcceab'],
    [0.32941176470588235, '#dbcda7'],
    [0.3333333333333333, '#d9cba4'],
    [0.33725490196078434, '#d8c9a1'],
    [0.3411764705882353, '#d7c79d'],
    [0.34509803921568627, '#d6c69a'],
    [0.34901960784313724, '#d4c496'],
    [0.3529411764705882, '#d3c293'],
    [0.3568627450980392, '#d2c090'],
    [0.3607843137254902, '#d0bf8c'],
    [0.36470588235294116, '#cfbd89'],
    [0.3686274509803922, '#cebb85'],
    [0.37254901960784315, '#ccba82'],
    [0.3764705882352941, '#cbb87f'],
    [0.3803921568627451, '#cab67b'],
    [0.38431372549019605, '#c8b578'],
    [0.38823529411764707, '#c7b375'],
    [0.39215686274509803, '#c5b171'],
    [0.396078431372549, '#c4af6e'],
    [0.4, '#c3ae6b'],
    [0.403921568627451, '#c1ac67'],
    [0.40784313725490196, '#c0aa64'],
    [0.4117647058823529, '#bea960'],
    [0.4156862745098039, '#bda75d'],
    [0.4196078431372549, '#bba55a'],
    [0.4235294117647059, '#baa456'],
    [0.42745098039215684, '#b8a253'],
    [0.43137254901960786, '#b7a14f'],
    [0.43529411764705883, '#b59f4c'],
    [0.4392156862745098, '#b49d48'],
    [0.44313725490196076, '#b29c45'],
    [0.44705882352941173, '#b19a41'],
    [0.45098039215686275, '#af983e'],
    [0.4549019607843137, '#ae973a'],
    [0.4588235294117647, '#ac9536'],
    [0.4627450980392157, '#ab9433'],
    [0.4666666666666667, '#a9922f'],
    [0.47058823529411764, '#a7902b'],
    [0.4745098039215686, '#a68f28'],
    [0.4784313725490196, '#a48d24'],
    [0.4823529411764706, '#a28c20'],
    [0.48627450980392156, '#a18a1d'],
    [0.49019607843137253, '#9f8819'],
    [0.49411764705882355, '#9d8716'],
    [0.4980392156862745, '#9c8514'],
    [0.5019607843137255, '#9a8411'],
    [0.5058823529411764, '#988210'],
    [0.5098039215686274, '#96810f'],
    [0.5137254901960784, '#947f0e'],
    [0.5176470588235293, '#937d0e'],
    [0.5215686274509804, '#917c0e'],
    [0.5254901960784314, '#8f7a0f'],
    [0.5294117647058824, '#8d7910'],
    [0.5333333333333333, '#8b7711'],
    [0.5372549019607843, '#8a7612'],
    [0.5411764705882353, '#887413'],
    [0.5450980392156862, '#867314'],
    [0.5490196078431373, '#847115'],
    [0.5529411764705883, '#827016'],
    [0.5568627450980392, '#806e17'],
    [0.5607843137254902, '#7f6d18'],
    [0.5647058823529412, '#7d6b19'],
    [0.5686274509803921, '#7b6a1a'],
    [0.5725490196078431, '#79681a'],
    [0.5764705882352941, '#77661b'],
    [0.580392156862745, '#76651c'],
    [0.5843137254901961, '#74631c'],
    [0.5882352941176471, '#72621d'],
    [0.592156862745098, '#70611e'],
    [0.596078431372549, '#6f5f1e'],
    [0.6, '#6d5e1f'],
    [0.6039215686274509, '#6b5c1f'],
    [0.6078431372549019, '#695b20'],
    [0.611764705882353, '#675920'],
    [0.615686274509804, '#665821'],
    [0.6196078431372549, '#645621'],
    [0.6235294117647059, '#625521'],
    [0.6274509803921569, '#605322'],
    [0.6313725490196078, '#5f5222'],
    [0.6352941176470588, '#5d5022'],
    [0.6392156862745098, '#5b4f23'],
    [0.6431372549019607, '#594e23'],
    [0.6470588235294118, '#584c23'],
    [0.6509803921568628, '#564b24'],
    [0.6549019607843137, '#544924'],
    [0.6588235294117647, '#524824'],
    [0.6627450980392157, '#514624'],
    [0.6666666666666666, '#4f4525'],
    [0.6705882352941176, '#4d4425'],
    [0.6745098039215687, '#4b4225'],
    [0.6784313725490196, '#4a4125'],
    [0.6823529411764706, '#484025'],
    [0.6862745098039216, '#463e26'],
    [0.6901960784313725, '#443d26'],
    [0.6941176470588235, '#433b26'],
    [0.6980392156862745, '#413a26'],
    [0.7019607843137254, '#3f3926'],
    [0.7058823529411764, '#3d3726'],
    [0.7098039215686275, '#3c3627'],
    [0.7137254901960784, '#3a3527'],
    [0.7176470588235294, '#383427'],
    [0.7215686274509804, '#373228'],
    [0.7254901960784313, '#353128'],
    [0.7294117647058823, '#343028'],
    [0.7333333333333333, '#323029'],
    [0.7372549019607844, '#312f2a'],
    [0.7411764705882353, '#302e2b'],
    [0.7450980392156863, '#2f2e2c'],
    [0.7490196078431373, '#2f2e2e'],
    [0.7529411764705882, '#2e2e2f'],
    [0.7568627450980392, '#2e2e31'],
    [0.7607843137254902, '#2e2f33'],
    [0.7647058823529411, '#2e2f36'],
    [0.7686274509803921, '#2e3038'],
    [0.7725490196078432, '#2e313a'],
    [0.7764705882352941, '#2f323d'],
    [0.7803921568627451, '#2f333f'],
    [0.7843137254901961, '#303442'],
    [0.788235294117647, '#313645'],
    [0.792156862745098, '#313748'],
    [0.796078431372549, '#32384a'],
    [0.8, '#333a4d'],
    [0.803921568627451, '#333b50'],
    [0.807843137254902, '#343c53'],
    [0.8117647058823529, '#343e56'],
    [0.8156862745098039, '#353f59'],
    [0.8196078431372549, '#36415c'],
    [0.8235294117647058, '#36425f'],
    [0.8274509803921568, '#374361'],
    [0.8313725490196078, '#374564'],
    [0.8352941176470589, '#384667'],
    [0.8392156862745098, '#38486a'],
    [0.8431372549019608, '#38496d'],
    [0.8470588235294118, '#394a70'],
    [0.8509803921568627, '#394c73'],
    [0.8549019607843137, '#3a4d76'],
    [0.8588235294117647, '#3a4f79'],
    [0.8627450980392157, '#3a507c'],
    [0.8666666666666667, '#3b527f'],
    [0.8705882352941177, '#3b5383'],
    [0.8745098039215686, '#3b5486'],
    [0.8784313725490196, '#3b5689'],
    [0.8823529411764706, '#3b578c'],
    [0.8862745098039215, '#3c598f'],
    [0.8901960784313725, '#3c5a92'],
    [0.8941176470588235, '#3c5c95'],
    [0.8980392156862745, '#3c5d98'],
    [0.9019607843137255, '#3c5f9c'],
    [0.9058823529411765, '#3c609f'],
    [0.9098039215686274, '#3c62a2'],
    [0.9137254901960784, '#3c63a5'],
    [0.9176470588235294, '#3c65a8'],
    [0.9215686274509803, '#3c66ac'],
    [0.9254901960784314, '#3c68af'],
    [0.9294117647058824, '#3b69b2'],
    [0.9333333333333333, '#3b6bb5'],
    [0.9372549019607843, '#3b6cb9'],
    [0.9411764705882353, '#3b6ebc'],
    [0.9450980392156862, '#3a70bf'],
    [0.9490196078431372, '#3a71c3'],
    [0.9529411764705882, '#3973c6'],
    [0.9568627450980391, '#3974c9'],
    [0.9607843137254902, '#3876cc'],
    [0.9647058823529412, '#3877d0'],
    [0.9686274509803922, '#3779d3'],
    [0.9725490196078431, '#377ad6'],
    [0.9764705882352941, '#377cd9'],
    [0.9803921568627451, '#377ddc'],
    [0.984313725490196, '#377fdf'],
    [0.9882352941176471, '#3780e1'],
    [0.9921568627450981, '#3882e4'],
    [0.996078431372549, '#3a83e6'],
    [1.0, '#3b85e8'],
  ],
  CET_CBC2: [
    [0.0, '#eeedec'],
    [0.00392156862745098, '#eeecea'],
    [0.00784313725490196, '#eeece7'],
    [0.011764705882352941, '#eeebe5'],
    [0.01568627450980392, '#edeae2'],
    [0.0196078431372549, '#ede9df'],
    [0.023529411764705882, '#ece7db'],
    [0.027450980392156862, '#ebe6d8'],
    [0.03137254901960784, '#ebe4d5'],
    [0.03529411764705882, '#eae2d1'],
    [0.0392156862745098, '#e9e1cd'],
    [0.043137254901960784, '#e8dfca'],
    [0.047058823529411764, '#e6ddc6'],
    [0.050980392156862744, '#e5dbc2'],
    [0.054901960784313725, '#e4dabf'],
    [0.058823529411764705, '#e3d8bb'],
    [0.06274509803921569, '#e2d6b7'],
    [0.06666666666666667, '#e1d4b4'],
    [0.07058823529411765, '#e0d2b0'],
    [0.07450980392156863, '#ded1ad'],
    [0.0784313725490196, '#ddcfa9'],
    [0.08235294117647059, '#dccda5'],
    [0.08627450980392157, '#dbcba2'],
    [0.09019607843137255, '#d9ca9e'],
    [0.09411764705882353, '#d8c89a'],
    [0.09803921568627451, '#d7c697'],
    [0.10196078431372549, '#d6c493'],
    [0.10588235294117647, '#d4c390'],
    [0.10980392156862745, '#d3c18c'],
    [0.11372549019607843, '#d2bf88'],
    [0.11764705882352941, '#d0bd85'],
    [0.12156862745098039, '#cfbc81'],
    [0.12549019607843137, '#ceba7e'],
    [0.12941176470588234, '#ccb87a'],
    [0.13333333333333333, '#cbb676'],
    [0.13725490196078433, '#c9b573'],
    [0.1411764705882353, '#c8b36f'],
    [0.14509803921568626, '#c7b16c'],
    [0.14901960784313725, '#c5b068'],
    [0.15294117647058825, '#c4ae64'],
    [0.1568627450980392, '#c2ac61'],
    [0.16078431372549018, '#c1ab5d'],
    [0.16470588235294117, '#bfa95a'],
    [0.16862745098039217, '#bea756'],
    [0.17254901960784313, '#bca552'],
    [0.1764705882352941, '#bba44f'],
    [0.1803921568627451, '#b9a24b'],
    [0.1843137254901961, '#b8a047'],
    [0.18823529411764706, '#b69f43'],
    [0.19215686274509802, '#b59d3f'],
    [0.19607843137254902, '#b39c3c'],
    [0.2, '#b29a38'],
    [0.20392156862745098, '#b09833'],
    [0.20784313725490194, '#ae972f'],
    [0.21176470588235294, '#ad952b'],
    [0.21568627450980393, '#ab9427'],
    [0.2196078431372549, '#aa9222'],
    [0.22352941176470587, '#a8911e'],
    [0.22745098039215686, '#a78f19'],
    [0.23137254901960785, '#a68e14'],
    [0.23529411764705882, '#a58d0f'],
    [0.2392156862745098, '#a48c0a'],
    [0.24313725490196078, '#a38b05'],
    [0.24705882352941178, '#a28b02'],
    [0.25098039215686274, '#a28b01'],
    [0.2549019607843137, '#a28b01'],
    [0.2588235294117647, '#a28b03'],
    [0.2627450980392157, '#a38b06'],
    [0.26666666666666666, '#a48c0b'],
    [0.27058823529411763, '#a58d10'],
    [0.27450980392156865, '#a68e15'],
    [0.2784313725490196, '#a7901a'],
    [0.2823529411764706, '#a9911f'],
    [0.28627450980392155, '#aa9223'],
    [0.2901960784313725, '#ac9428'],
    [0.29411764705882354, '#ad952c'],
    [0.2980392156862745, '#af9730'],
    [0.30196078431372547, '#b09935'],
    [0.3058823529411765, '#b29a39'],
    [0.30980392156862746, '#b49c3d'],
    [0.3137254901960784, '#b59e40'],
    [0.3176470588235294, '#b79f44'],
    [0.32156862745098036, '#b8a148'],
    [0.3254901960784314, '#baa34c'],
    [0.32941176470588235, '#bba450'],
    [0.3333333333333333, '#bda653'],
    [0.33725490196078434, '#bea857'],
    [0.3411764705882353, '#c0a95b'],
    [0.34509803921568627, '#c1ab5e'],
    [0.34901960784313724, '#c3ad62'],
    [0.3529411764705882, '#c4ae65'],
    [0.3568627450980392, '#c6b069'],
    [0.3607843137254902, '#c7b26d'],
    [0.36470588235294116, '#c8b370'],
    [0.3686274509803922, '#cab574'],
    [0.37254901960784315, '#cbb777'],
    [0.3764705882352941, '#cdb97b'],
    [0.3803921568627451, '#ceba7f'],
    [0.38431372549019605, '#cfbc82'],
    [0.38823529411764707, '#d1be86'],
    [0.39215686274509803, '#d2c089'],
    [0.396078431372549, '#d3c18d'],
    [0.4, '#d5c391'],
    [0.403921568627451, '#d6c594'],
    [0.40784313725490196, '#d7c698'],
    [0.4117647058823529, '#d9c89b'],
    [0.4156862745098039, '#daca9f'],
    [0.4196078431372549, '#dbcca3'],
    [0.4235294117647059, '#dccea6'],
    [0.42745098039215684, '#ddcfaa'],
    [0.43137254901960786, '#dfd1ad'],
    [0.43529411764705883, '#e0d3b1'],
    [0.4392156862745098, '#e1d5b5'],
    [0.44313725490196076, '#e2d6b8'],
    [0.44705882352941173, '#e3d8bc'],
    [0.45098039215686275, '#e5dac0'],
    [0.4549019607843137, '#e6dcc3'],
    [0.4588235294117647, '#e7dec7'],
    [0.4627450980392157, '#e8dfcb'],
    [0.4666666666666667, '#e9e1ce'],
    [0.47058823529411764, '#eae3d2'],
    [0.4745098039215686, '#eae4d6'],
    [0.4784313725490196, '#ebe6d9'],
    [0.4823529411764706, '#ece7dd'],
    [0.48627450980392156, '#ece8e0'],
    [0.49019607843137253, '#ece9e3'],
    [0.49411764705882355, '#ece9e6'],
    [0.4980392156862745, '#ebeae8'],
    [0.5019607843137255, '#ebeaeb'],
    [0.5058823529411764, '#eaeaed'],
    [0.5098039215686274, '#e8e9ef'],
    [0.5137254901960784, '#e7e8f0'],
    [0.5176470588235293, '#e5e7f1'],
    [0.5215686274509804, '#e3e6f2'],
    [0.5254901960784314, '#e1e5f3'],
    [0.5294117647058824, '#dfe3f3'],
    [0.5333333333333333, '#dde2f4'],
    [0.5372549019607843, '#dbe0f4'],
    [0.5411764705882353, '#d8def4'],
    [0.5450980392156862, '#d6ddf5'],
    [0.5490196078431373, '#d4dbf5'],
    [0.5529411764705883, '#d1d9f5'],
    [0.5568627450980392, '#cfd7f5'],
    [0.5607843137254902, '#ccd6f5'],
    [0.5647058823529412, '#cad4f5'],
    [0.5686274509803921, '#c7d2f5'],
    [0.5725490196078431, '#c5d0f6'],
    [0.5764705882352941, '#c2cff6'],
    [0.580392156862745, '#c0cdf6'],
    [0.5843137254901961, '#bdcbf6'],
    [0.5882352941176471, '#bac9f6'],
    [0.592156862745098, '#b8c8f6'],
    [0.596078431372549, '#b5c6f6'],
    [0.6, '#b3c4f6'],
    [0.6039215686274509, '#b0c2f6'],
    [0.6078431372549019, '#adc1f6'],
    [0.611764705882353, '#abbff6'],
    [0.615686274509804, '#a8bdf7'],
    [0.6196078431372549, '#a5bbf7'],
    [0.6235294117647059, '#a2baf7'],
    [0.6274509803921569, '#a0b8f7'],
    [0.6313725490196078, '#9db6f7'],
    [0.6352941176470588, '#9ab5f7'],
    [0.6392156862745098, '#97b3f7'],
    [0.6431372549019607, '#94b1f7'],
    [0.6470588235294118, '#91b0f7'],
    [0.6509803921568628, '#8eaef7'],
    [0.6549019607843137, '#8bacf7'],
    [0.6588235294117647, '#88aaf7'],
    [0.6627450980392157, '#85a9f7'],
    [0.6666666666666666, '#81a7f7'],
    [0.6705882352941176, '#7ea5f7'],
    [0.6745098039215687, '#7ba4f7'],
    [0.6784313725490196, '#77a2f7'],
    [0.6823529411764706, '#74a1f8'],
    [0.6862745098039216, '#709ff8'],
    [0.6901960784313725, '#6c9df8'],
    [0.6941176470588235, '#689cf8'],
    [0.6980392156862745, '#649af8'],
    [0.7019607843137254, '#6098f8'],
    [0.7058823529411764, '#5c97f8'],
    [0.7098039215686275, '#5895f8'],
    [0.7137254901960784, '#5394f8'],
    [0.7176470588235294, '#4f92f8'],
    [0.7215686274509804, '#4a91f8'],
    [0.7254901960784313, '#4690f8'],
    [0.7294117647058823, '#428ff8'],
    [0.7333333333333333, '#3e8ef8'],
    [0.7372549019607844, '#3a8df8'],
    [0.7411764705882353, '#388cf8'],
    [0.7450980392156863, '#368cf8'],
    [0.7490196078431373, '#368cf8'],
    [0.7529411764705882, '#368cf8'],
    [0.7568627450980392, '#388cf8'],
    [0.7607843137254902, '#3b8df8'],
    [0.7647058823529411, '#3e8ef8'],
    [0.7686274509803921, '#438ff8'],
    [0.7725490196078432, '#4790f8'],
    [0.7764705882352941, '#4b91f8'],
    [0.7803921568627451, '#5093f8'],
    [0.7843137254901961, '#5494f8'],
    [0.788235294117647, '#5996f8'],
    [0.792156862745098, '#5d97f8'],
    [0.796078431372549, '#6199f8'],
    [0.8, '#659af8'],
    [0.803921568627451, '#699cf8'],
    [0.807843137254902, '#6d9ef8'],
    [0.8117647058823529, '#719ff8'],
    [0.8156862745098039, '#74a1f8'],
    [0.8196078431372549, '#78a3f7'],
    [0.8235294117647058, '#7ba4f7'],
    [0.8274509803921568, '#7fa6f7'],
    [0.8313725490196078, '#82a8f7'],
    [0.8352941176470589, '#85a9f7'],
    [0.8392156862745098, '#88abf7'],
    [0.8431372549019608, '#8cadf7'],
    [0.8470588235294118, '#8faef7'],
    [0.8509803921568627, '#92b0f7'],
    [0.8549019607843137, '#95b2f7'],
    [0.8588235294117647, '#98b3f7'],
    [0.8627450980392157, '#9ab5f7'],
    [0.8666666666666667, '#9db7f7'],
    [0.8705882352941177, '#a0b8f7'],
    [0.8745098039215686, '#a3baf7'],
    [0.8784313725490196, '#a6bcf7'],
    [0.8823529411764706, '#a8bef7'],
    [0.8862745098039215, '#abbff6'],
    [0.8901960784313725, '#aec1f6'],
    [0.8941176470588235, '#b1c3f6'],
    [0.8980392156862745, '#b3c4f6'],
    [0.9019607843137255, '#b6c6f6'],
    [0.9058823529411765, '#b8c8f6'],
    [0.9098039215686274, '#bbcaf6'],
    [0.9137254901960784, '#becbf6'],
    [0.9176470588235294, '#c0cdf6'],
    [0.9215686274509803, '#c3cff6'],
    [0.9254901960784314, '#c5d1f6'],
    [0.9294117647058824, '#c8d2f5'],
    [0.9333333333333333, '#cad4f5'],
    [0.9372549019607843, '#cdd6f5'],
    [0.9411764705882353, '#cfd8f5'],
    [0.9450980392156862, '#d2daf5'],
    [0.9490196078431372, '#d4dbf5'],
    [0.9529411764705882, '#d6ddf5'],
    [0.9568627450980391, '#d9dff5'],
    [0.9607843137254902, '#dbe1f4'],
    [0.9647058823529412, '#dee2f4'],
    [0.9686274509803922, '#e0e4f4'],
    [0.9725490196078431, '#e2e6f4'],
    [0.9764705882352941, '#e4e7f3'],
    [0.9803921568627451, '#e6e9f3'],
    [0.984313725490196, '#e8eaf2'],
    [0.9882352941176471, '#e9ebf1'],
    [0.9921568627450981, '#ebecf0'],
    [0.996078431372549, '#ececef'],
    [1.0, '#ededee'],
  ],
  CET_CBD1: [
    [0.0, '#3a90fe'],
    [0.00392156862745098, '#3d91fe'],
    [0.00784313725490196, '#4091fe'],
    [0.011764705882352941, '#4392fd'],
    [0.01568627450980392, '#4693fd'],
    [0.0196078431372549, '#4893fd'],
    [0.023529411764705882, '#4b94fd'],
    [0.027450980392156862, '#4d95fd'],
    [0.03137254901960784, '#5095fd'],
    [0.03529411764705882, '#5296fd'],
    [0.0392156862745098, '#5497fd'],
    [0.043137254901960784, '#5797fd'],
    [0.047058823529411764, '#5998fd'],
    [0.050980392156862744, '#5b99fd'],
    [0.054901960784313725, '#5d9afd'],
    [0.058823529411764705, '#5f9afd'],
    [0.06274509803921569, '#619bfc'],
    [0.06666666666666667, '#639cfc'],
    [0.07058823529411765, '#659cfc'],
    [0.07450980392156863, '#679dfc'],
    [0.0784313725490196, '#699efc'],
    [0.08235294117647059, '#6a9ffc'],
    [0.08627450980392157, '#6c9ffc'],
    [0.09019607843137255, '#6ea0fc'],
    [0.09411764705882353, '#70a1fc'],
    [0.09803921568627451, '#71a1fc'],
    [0.10196078431372549, '#73a2fc'],
    [0.10588235294117647, '#75a3fc'],
    [0.10980392156862745, '#76a4fb'],
    [0.11372549019607843, '#78a4fb'],
    [0.11764705882352941, '#79a5fb'],
    [0.12156862745098039, '#7ba6fb'],
    [0.12549019607843137, '#7da6fb'],
    [0.12941176470588234, '#7ea7fb'],
    [0.13333333333333333, '#80a8fb'],
    [0.13725490196078433, '#81a9fb'],
    [0.1411764705882353, '#83a9fb'],
    [0.14509803921568626, '#84aafb'],
    [0.14901960784313725, '#86abfb'],
    [0.15294117647058825, '#87abfa'],
    [0.1568627450980392, '#89acfa'],
    [0.16078431372549018, '#8aadfa'],
    [0.16470588235294117, '#8baefa'],
    [0.16862745098039217, '#8daefa'],
    [0.17254901960784313, '#8eaffa'],
    [0.1764705882352941, '#90b0fa'],
    [0.1803921568627451, '#91b1fa'],
    [0.1843137254901961, '#92b1fa'],
    [0.18823529411764706, '#94b2fa'],
    [0.19215686274509802, '#95b3fa'],
    [0.19607843137254902, '#96b4f9'],
    [0.2, '#98b4f9'],
    [0.20392156862745098, '#99b5f9'],
    [0.20784313725490194, '#9ab6f9'],
    [0.21176470588235294, '#9cb7f9'],
    [0.21568627450980393, '#9db7f9'],
    [0.2196078431372549, '#9eb8f9'],
    [0.22352941176470587, '#a0b9f9'],
    [0.22745098039215686, '#a1b9f9'],
    [0.23137254901960785, '#a2baf9'],
    [0.23529411764705882, '#a3bbf8'],
    [0.2392156862745098, '#a5bcf8'],
    [0.24313725490196078, '#a6bcf8'],
    [0.24705882352941178, '#a7bdf8'],
    [0.25098039215686274, '#a8bef8'],
    [0.2549019607843137, '#aabff8'],
    [0.2588235294117647, '#abc0f8'],
    [0.2627450980392157, '#acc0f8'],
    [0.26666666666666666, '#adc1f8'],
    [0.27058823529411763, '#afc2f7'],
    [0.27450980392156865, '#b0c3f7'],
    [0.2784313725490196, '#b1c3f7'],
    [0.2823529411764706, '#b2c4f7'],
    [0.28627450980392155, '#b3c5f7'],
    [0.2901960784313725, '#b5c6f7'],
    [0.29411764705882354, '#b6c6f7'],
    [0.2980392156862745, '#b7c7f7'],
    [0.30196078431372547, '#b8c8f7'],
    [0.3058823529411765, '#b9c9f7'],
    [0.30980392156862746, '#bac9f6'],
    [0.3137254901960784, '#bccaf6'],
    [0.3176470588235294, '#bdcbf6'],
    [0.32156862745098036, '#beccf6'],
    [0.3254901960784314, '#bfccf6'],
    [0.32941176470588235, '#c0cdf6'],
    [0.3333333333333333, '#c1cef6'],
    [0.33725490196078434, '#c2cff6'],
    [0.3411764705882353, '#c4d0f5'],
    [0.34509803921568627, '#c5d0f5'],
    [0.34901960784313724, '#c6d1f5'],
    [0.3529411764705882, '#c7d2f5'],
    [0.3568627450980392, '#c8d3f5'],
    [0.3607843137254902, '#c9d3f5'],
    [0.36470588235294116, '#cad4f5'],
    [0.3686274509803922, '#cbd5f5'],
    [0.37254901960784315, '#cdd6f5'],
    [0.3764705882352941, '#ced7f4'],
    [0.3803921568627451, '#cfd7f4'],
    [0.38431372549019605, '#d0d8f4'],
    [0.38823529411764707, '#d1d9f4'],
    [0.39215686274509803, '#d2daf4'],
    [0.396078431372549, '#d3daf4'],
    [0.4, '#d4dbf4'],
    [0.403921568627451, '#d5dcf4'],
    [0.40784313725490196, '#d6ddf3'],
    [0.4117647058823529, '#d7def3'],
    [0.4156862745098039, '#d9def3'],
    [0.4196078431372549, '#dadff3'],
    [0.4235294117647059, '#dbe0f3'],
    [0.42745098039215684, '#dce1f3'],
    [0.43137254901960786, '#dde1f3'],
    [0.43529411764705883, '#dee2f2'],
    [0.4392156862745098, '#dfe3f2'],
    [0.44313725490196076, '#e0e4f2'],
    [0.44705882352941173, '#e1e5f2'],
    [0.45098039215686275, '#e2e5f2'],
    [0.4549019607843137, '#e3e6f2'],
    [0.4588235294117647, '#e4e7f2'],
    [0.4627450980392157, '#e5e8f1'],
    [0.4666666666666667, '#e6e8f1'],
    [0.47058823529411764, '#e7e9f1'],
    [0.4745098039215686, '#e8eaf1'],
    [0.4784313725490196, '#e9eaf0'],
    [0.4823529411764706, '#eaebf0'],
    [0.48627450980392156, '#ebebef'],
    [0.49019607843137253, '#ebecef'],
    [0.49411764705882355, '#ececee'],
    [0.4980392156862745, '#ececed'],
    [0.5019607843137255, '#edecec'],
    [0.5058823529411764, '#edeceb'],
    [0.5098039215686274, '#edece9'],
    [0.5137254901960784, '#edebe8'],
    [0.5176470588235293, '#edebe6'],
    [0.5215686274509804, '#edeae5'],
    [0.5254901960784314, '#edeae3'],
    [0.5294117647058824, '#ece9e2'],
    [0.5333333333333333, '#ece8e0'],
    [0.5372549019607843, '#ece8de'],
    [0.5411764705882353, '#ebe7dd'],
    [0.5450980392156862, '#ebe6db'],
    [0.5490196078431373, '#ebe5d9'],
    [0.5529411764705883, '#eae5d8'],
    [0.5568627450980392, '#eae4d6'],
    [0.5607843137254902, '#e9e3d4'],
    [0.5647058823529412, '#e9e2d2'],
    [0.5686274509803921, '#e8e1d1'],
    [0.5725490196078431, '#e8e1cf'],
    [0.5764705882352941, '#e8e0cd'],
    [0.580392156862745, '#e7dfcc'],
    [0.5843137254901961, '#e7deca'],
    [0.5882352941176471, '#e6ddc8'],
    [0.592156862745098, '#e6ddc7'],
    [0.596078431372549, '#e5dcc5'],
    [0.6, '#e5dbc3'],
    [0.6039215686274509, '#e4dac2'],
    [0.6078431372549019, '#e4dac0'],
    [0.611764705882353, '#e3d9be'],
    [0.615686274509804, '#e3d8bd'],
    [0.6196078431372549, '#e2d7bb'],
    [0.6235294117647059, '#e2d6b9'],
    [0.6274509803921569, '#e2d6b8'],
    [0.6313725490196078, '#e1d5b6'],
    [0.6352941176470588, '#e1d4b4'],
    [0.6392156862745098, '#e0d3b2'],
    [0.6431372549019607, '#e0d3b1'],
    [0.6470588235294118, '#dfd2af'],
    [0.6509803921568628, '#dfd1ae'],
    [0.6549019607843137, '#ded0ac'],
    [0.6588235294117647, '#decfaa'],
    [0.6627450980392157, '#ddcfa9'],
    [0.6666666666666666, '#ddcea7'],
    [0.6705882352941176, '#dccda5'],
    [0.6745098039215687, '#dbcca4'],
    [0.6784313725490196, '#dbcca2'],
    [0.6823529411764706, '#dacba0'],
    [0.6862745098039216, '#daca9f'],
    [0.6901960784313725, '#d9c99d'],
    [0.6941176470588235, '#d9c89b'],
    [0.6980392156862745, '#d8c89a'],
    [0.7019607843137254, '#d8c798'],
    [0.7058823529411764, '#d7c696'],
    [0.7098039215686275, '#d7c595'],
    [0.7137254901960784, '#d6c593'],
    [0.7176470588235294, '#d6c491'],
    [0.7215686274509804, '#d5c390'],
    [0.7254901960784313, '#d4c28e'],
    [0.7294117647058823, '#d4c28c'],
    [0.7333333333333333, '#d3c18b'],
    [0.7372549019607844, '#d3c089'],
    [0.7411764705882353, '#d2bf88'],
    [0.7450980392156863, '#d2bf86'],
    [0.7490196078431373, '#d1be84'],
    [0.7529411764705882, '#d1bd83'],
    [0.7568627450980392, '#d0bc81'],
    [0.7607843137254902, '#cfbc7f'],
    [0.7647058823529411, '#cfbb7e'],
    [0.7686274509803921, '#ceba7c'],
    [0.7725490196078432, '#ceb97a'],
    [0.7764705882352941, '#cdb979'],
    [0.7803921568627451, '#ccb877'],
    [0.7843137254901961, '#ccb775'],
    [0.788235294117647, '#cbb674'],
    [0.792156862745098, '#cbb672'],
    [0.796078431372549, '#cab571'],
    [0.8, '#c9b46f'],
    [0.803921568627451, '#c9b36d'],
    [0.807843137254902, '#c8b36c'],
    [0.8117647058823529, '#c8b26a'],
    [0.8156862745098039, '#c7b168'],
    [0.8196078431372549, '#c6b067'],
    [0.8235294117647058, '#c6b065'],
    [0.8274509803921568, '#c5af63'],
    [0.8313725490196078, '#c4ae62'],
    [0.8352941176470589, '#c4ad60'],
    [0.8392156862745098, '#c3ad5e'],
    [0.8431372549019608, '#c3ac5d'],
    [0.8470588235294118, '#c2ab5b'],
    [0.8509803921568627, '#c1aa59'],
    [0.8549019607843137, '#c1aa58'],
    [0.8588235294117647, '#c0a956'],
    [0.8627450980392157, '#bfa854'],
    [0.8666666666666667, '#bfa753'],
    [0.8705882352941177, '#bea751'],
    [0.8745098039215686, '#bda64f'],
    [0.8784313725490196, '#bda54e'],
    [0.8823529411764706, '#bca54c'],
    [0.8862745098039215, '#bba44a'],
    [0.8901960784313725, '#bba348'],
    [0.8941176470588235, '#baa247'],
    [0.8980392156862745, '#b9a245'],
    [0.9019607843137255, '#b9a143'],
    [0.9058823529411765, '#b8a041'],
    [0.9098039215686274, '#b79f40'],
    [0.9137254901960784, '#b79f3e'],
    [0.9176470588235294, '#b69e3c'],
    [0.9215686274509803, '#b59d3a'],
    [0.9254901960784314, '#b59d38'],
    [0.9294117647058824, '#b49c36'],
    [0.9333333333333333, '#b39b34'],
    [0.9372549019607843, '#b39a32'],
    [0.9411764705882353, '#b29a30'],
    [0.9450980392156862, '#b1992e'],
    [0.9490196078431372, '#b0982c'],
    [0.9529411764705882, '#b0982a'],
    [0.9568627450980391, '#af9728'],
    [0.9607843137254902, '#ae9626'],
    [0.9647058823529412, '#ae9524'],
    [0.9686274509803922, '#ad9521'],
    [0.9725490196078431, '#ac941f'],
    [0.9764705882352941, '#ac931d'],
    [0.9803921568627451, '#ab931a'],
    [0.984313725490196, '#aa9217'],
    [0.9882352941176471, '#a99114'],
    [0.9921568627450981, '#a99010'],
    [0.996078431372549, '#a8900d'],
    [1.0, '#a78f08'],
  ],
  CET_CBD2: [
    [0.0, '#0488fc'],
    [0.00392156862745098, '#1089fb'],
    [0.00784313725490196, '#1789fb'],
    [0.011764705882352941, '#1d89fa'],
    [0.01568627450980392, '#228afa'],
    [0.0196078431372549, '#268af9'],
    [0.023529411764705882, '#2a8af9'],
    [0.027450980392156862, '#2e8af8'],
    [0.03137254901960784, '#318bf7'],
    [0.03529411764705882, '#348bf7'],
    [0.0392156862745098, '#378bf6'],
    [0.043137254901960784, '#3a8cf6'],
    [0.047058823529411764, '#3c8cf5'],
    [0.050980392156862744, '#3f8cf5'],
    [0.054901960784313725, '#418df4'],
    [0.058823529411764705, '#438df4'],
    [0.06274509803921569, '#458df3'],
    [0.06666666666666667, '#488df2'],
    [0.07058823529411765, '#4a8ef2'],
    [0.07450980392156863, '#4c8ef1'],
    [0.0784313725490196, '#4d8ef1'],
    [0.08235294117647059, '#4f8ff0'],
    [0.08627450980392157, '#518ff0'],
    [0.09019607843137255, '#538fef'],
    [0.09411764705882353, '#5490ee'],
    [0.09803921568627451, '#5690ee'],
    [0.10196078431372549, '#5890ed'],
    [0.10588235294117647, '#5991ed'],
    [0.10980392156862745, '#5b91ec'],
    [0.11372549019607843, '#5c91ec'],
    [0.11764705882352941, '#5e92eb'],
    [0.12156862745098039, '#5f92ea'],
    [0.12549019607843137, '#6192ea'],
    [0.12941176470588234, '#6292e9'],
    [0.13333333333333333, '#6393e9'],
    [0.13725490196078433, '#6593e8'],
    [0.1411764705882353, '#6693e8'],
    [0.14509803921568626, '#6794e7'],
    [0.14901960784313725, '#6994e6'],
    [0.15294117647058825, '#6a94e6'],
    [0.1568627450980392, '#6b95e5'],
    [0.16078431372549018, '#6c95e5'],
    [0.16470588235294117, '#6e95e4'],
    [0.16862745098039217, '#6f96e4'],
    [0.17254901960784313, '#7096e3'],
    [0.1764705882352941, '#7196e3'],
    [0.1803921568627451, '#7297e2'],
    [0.1843137254901961, '#7397e1'],
    [0.18823529411764706, '#7497e1'],
    [0.19215686274509802, '#7698e0'],
    [0.19607843137254902, '#7798e0'],
    [0.2, '#7898df'],
    [0.20392156862745098, '#7999df'],
    [0.20784313725490194, '#7a99de'],
    [0.21176470588235294, '#7b99dd'],
    [0.21568627450980393, '#7c9add'],
    [0.2196078431372549, '#7d9adc'],
    [0.22352941176470587, '#7e9adc'],
    [0.22745098039215686, '#7f9bdb'],
    [0.23137254901960785, '#809bdb'],
    [0.23529411764705882, '#819bda'],
    [0.2392156862745098, '#829cd9'],
    [0.24313725490196078, '#839cd9'],
    [0.24705882352941178, '#849cd8'],
    [0.25098039215686274, '#849dd8'],
    [0.2549019607843137, '#859dd7'],
    [0.2588235294117647, '#869dd7'],
    [0.2627450980392157, '#879ed6'],
    [0.26666666666666666, '#889ed5'],
    [0.27058823529411763, '#899ed5'],
    [0.27450980392156865, '#8a9fd4'],
    [0.2784313725490196, '#8b9fd4'],
    [0.2823529411764706, '#8c9fd3'],
    [0.28627450980392155, '#8ca0d3'],
    [0.2901960784313725, '#8da0d2'],
    [0.29411764705882354, '#8ea0d1'],
    [0.2980392156862745, '#8fa1d1'],
    [0.30196078431372547, '#90a1d0'],
    [0.3058823529411765, '#91a1d0'],
    [0.30980392156862746, '#91a2cf'],
    [0.3137254901960784, '#92a2ce'],
    [0.3176470588235294, '#93a2ce'],
    [0.32156862745098036, '#94a3cd'],
    [0.3254901960784314, '#95a3cd'],
    [0.32941176470588235, '#95a3cc'],
    [0.3333333333333333, '#96a4cc'],
    [0.33725490196078434, '#97a4cb'],
    [0.3411764705882353, '#98a4ca'],
    [0.34509803921568627, '#98a5ca'],
    [0.34901960784313724, '#99a5c9'],
    [0.3529411764705882, '#9aa5c9'],
    [0.3568627450980392, '#9ba6c8'],
    [0.3607843137254902, '#9ba6c8'],
    [0.36470588235294116, '#9ca6c7'],
    [0.3686274509803922, '#9da7c6'],
    [0.37254901960784315, '#9ea7c6'],
    [0.3764705882352941, '#9ea7c5'],
    [0.3803921568627451, '#9fa8c5'],
    [0.38431372549019605, '#a0a8c4'],
    [0.38823529411764707, '#a0a9c3'],
    [0.39215686274509803, '#a1a9c3'],
    [0.396078431372549, '#a2a9c2'],
    [0.4, '#a2aac2'],
    [0.403921568627451, '#a3aac1'],
    [0.40784313725490196, '#a4aac1'],
    [0.4117647058823529, '#a4abc0'],
    [0.4156862745098039, '#a5abbf'],
    [0.4196078431372549, '#a6abbf'],
    [0.4235294117647059, '#a6acbe'],
    [0.42745098039215684, '#a7acbe'],
    [0.43137254901960786, '#a8acbd'],
    [0.43529411764705883, '#a8adbc'],
    [0.4392156862745098, '#a9adbc'],
    [0.44313725490196076, '#aaadbb'],
    [0.44705882352941173, '#aaaebb'],
    [0.45098039215686275, '#abaeba'],
    [0.4549019607843137, '#acafb9'],
    [0.4588235294117647, '#acafb9'],
    [0.4627450980392157, '#adafb8'],
    [0.4666666666666667, '#adb0b8'],
    [0.47058823529411764, '#aeb0b7'],
    [0.4745098039215686, '#afb0b7'],
    [0.4784313725490196, '#afb1b6'],
    [0.4823529411764706, '#b0b1b5'],
    [0.48627450980392156, '#b1b1b5'],
    [0.49019607843137253, '#b1b2b4'],
    [0.49411764705882355, '#b2b2b4'],
    [0.4980392156862745, '#b2b2b3'],
    [0.5019607843137255, '#b3b3b2'],
    [0.5058823529411764, '#b4b3b2'],
    [0.5098039215686274, '#b4b3b1'],
    [0.5137254901960784, '#b5b4b1'],
    [0.5176470588235293, '#b6b4b0'],
    [0.5215686274509804, '#b7b4af'],
    [0.5254901960784314, '#b7b5af'],
    [0.5294117647058824, '#b8b5ae'],
    [0.5333333333333333, '#b9b5ad'],
    [0.5372549019607843, '#bab6ad'],
    [0.5411764705882353, '#bab6ac'],
    [0.5450980392156862, '#bbb6ac'],
    [0.5490196078431373, '#bcb7ab'],
    [0.5529411764705883, '#bcb7aa'],
    [0.5568627450980392, '#bdb7aa'],
    [0.5607843137254902, '#beb8a9'],
    [0.5647058823529412, '#beb8a8'],
    [0.5686274509803921, '#bfb8a8'],
    [0.5725490196078431, '#c0b9a7'],
    [0.5764705882352941, '#c0b9a7'],
    [0.580392156862745, '#c1b9a6'],
    [0.5843137254901961, '#c2baa5'],
    [0.5882352941176471, '#c2baa5'],
    [0.592156862745098, '#c3baa4'],
    [0.596078431372549, '#c4bba3'],
    [0.6, '#c4bba3'],
    [0.6039215686274509, '#c5bba2'],
    [0.6078431372549019, '#c6bca2'],
    [0.611764705882353, '#c6bca1'],
    [0.615686274509804, '#c7bca0'],
    [0.6196078431372549, '#c8bca0'],
    [0.6235294117647059, '#c8bd9f'],
    [0.6274509803921569, '#c9bd9e'],
    [0.6313725490196078, '#cabd9e'],
    [0.6352941176470588, '#cabe9d'],
    [0.6392156862745098, '#cbbe9c'],
    [0.6431372549019607, '#ccbe9c'],
    [0.6470588235294118, '#ccbf9b'],
    [0.6509803921568628, '#cdbf9b'],
    [0.6549019607843137, '#cdbf9a'],
    [0.6588235294117647, '#cec099'],
    [0.6627450980392157, '#cfc099'],
    [0.6666666666666666, '#cfc098'],
    [0.6705882352941176, '#d0c197'],
    [0.6745098039215687, '#d1c197'],
    [0.6784313725490196, '#d1c196'],
    [0.6823529411764706, '#d2c295'],
    [0.6862745098039216, '#d2c295'],
    [0.6901960784313725, '#d3c294'],
    [0.6941176470588235, '#d4c393'],
    [0.6980392156862745, '#d4c393'],
    [0.7019607843137254, '#d5c392'],
    [0.7058823529411764, '#d5c491'],
    [0.7098039215686275, '#d6c491'],
    [0.7137254901960784, '#d7c490'],
    [0.7176470588235294, '#d7c58f'],
    [0.7215686274509804, '#d8c58f'],
    [0.7254901960784313, '#d8c58e'],
    [0.7294117647058823, '#d9c68d'],
    [0.7333333333333333, '#dac68d'],
    [0.7372549019607844, '#dac68c'],
    [0.7411764705882353, '#dbc78b'],
    [0.7450980392156863, '#dbc78b'],
    [0.7490196078431373, '#dcc78a'],
    [0.7529411764705882, '#dcc889'],
    [0.7568627450980392, '#ddc889'],
    [0.7607843137254902, '#dec888'],
    [0.7647058823529411, '#dec987'],
    [0.7686274509803921, '#dfc987'],
    [0.7725490196078432, '#dfc986'],
    [0.7764705882352941, '#e0ca85'],
    [0.7803921568627451, '#e0ca85'],
    [0.7843137254901961, '#e1ca84'],
    [0.788235294117647, '#e2cb83'],
    [0.792156862745098, '#e2cb83'],
    [0.796078431372549, '#e3cc82'],
    [0.8, '#e3cc81'],
    [0.803921568627451, '#e4cc80'],
    [0.807843137254902, '#e4cd80'],
    [0.8117647058823529, '#e5cd7f'],
    [0.8156862745098039, '#e6cd7e'],
    [0.8196078431372549, '#e6ce7e'],
    [0.8235294117647058, '#e7ce7d'],
    [0.8274509803921568, '#e7ce7c'],
    [0.8313725490196078, '#e8cf7b'],
    [0.8352941176470589, '#e8cf7b'],
    [0.8392156862745098, '#e9cf7a'],
    [0.8431372549019608, '#e9d079'],
    [0.8470588235294118, '#ead078'],
    [0.8509803921568627, '#ead078'],
    [0.8549019607843137, '#ebd177'],
    [0.8588235294117647, '#ecd176'],
    [0.8627450980392157, '#ecd176'],
    [0.8666666666666667, '#edd275'],
    [0.8705882352941177, '#edd274'],
    [0.8745098039215686, '#eed273'],
    [0.8784313725490196, '#eed373'],
    [0.8823529411764706, '#efd372'],
    [0.8862745098039215, '#efd371'],
    [0.8901960784313725, '#f0d470'],
    [0.8941176470588235, '#f0d46f'],
    [0.8980392156862745, '#f1d46f'],
    [0.9019607843137255, '#f1d56e'],
    [0.9058823529411765, '#f2d56d'],
    [0.9098039215686274, '#f2d56c'],
    [0.9137254901960784, '#f3d66c'],
    [0.9176470588235294, '#f3d66b'],
    [0.9215686274509803, '#f4d66a'],
    [0.9254901960784314, '#f5d769'],
    [0.9294117647058824, '#f5d768'],
    [0.9333333333333333, '#f6d868'],
    [0.9372549019607843, '#f6d867'],
    [0.9411764705882353, '#f7d866'],
    [0.9450980392156862, '#f7d965'],
    [0.9490196078431372, '#f8d964'],
    [0.9529411764705882, '#f8d963'],
    [0.9568627450980391, '#f9da63'],
    [0.9607843137254902, '#f9da62'],
    [0.9647058823529412, '#fada61'],
    [0.9686274509803922, '#fadb60'],
    [0.9725490196078431, '#fbdb5f'],
    [0.9764705882352941, '#fbdb5e'],
    [0.9803921568627451, '#fcdc5d'],
    [0.984313725490196, '#fcdc5d'],
    [0.9882352941176471, '#fddc5c'],
    [0.9921568627450981, '#fddd5b'],
    [0.996078431372549, '#fedd5a'],
    [1.0, '#fedd59'],
  ],
  CET_CBL1: [
    [0.0, '#101010'],
    [0.00392156862745098, '#111113'],
    [0.00784313725490196, '#111215'],
    [0.011764705882352941, '#121317'],
    [0.01568627450980392, '#121419'],
    [0.0196078431372549, '#13141a'],
    [0.023529411764705882, '#13151c'],
    [0.027450980392156862, '#13161e'],
    [0.03137254901960784, '#131720'],
    [0.03529411764705882, '#131722'],
    [0.0392156862745098, '#131824'],
    [0.043137254901960784, '#131926'],
    [0.047058823529411764, '#131927'],
    [0.050980392156862744, '#131a29'],
    [0.054901960784313725, '#131b2b'],
    [0.058823529411764705, '#131b2d'],
    [0.06274509803921569, '#131c2f'],
    [0.06666666666666667, '#131d31'],
    [0.07058823529411765, '#131e33'],
    [0.07450980392156863, '#131e34'],
    [0.0784313725490196, '#131f36'],
    [0.08235294117647059, '#122038'],
    [0.08627450980392157, '#12203a'],
    [0.09019607843137255, '#12213c'],
    [0.09411764705882353, '#11223e'],
    [0.09803921568627451, '#11233f'],
    [0.10196078431372549, '#112341'],
    [0.10588235294117647, '#102443'],
    [0.10980392156862745, '#102545'],
    [0.11372549019607843, '#0f2647'],
    [0.11764705882352941, '#0e2649'],
    [0.12156862745098039, '#0e274a'],
    [0.12549019607843137, '#0d284c'],
    [0.12941176470588234, '#0d294e'],
    [0.13333333333333333, '#0c2950'],
    [0.13725490196078433, '#0b2a51'],
    [0.1411764705882353, '#0a2b53'],
    [0.14509803921568626, '#092c55'],
    [0.14901960784313725, '#092c57'],
    [0.15294117647058825, '#082d58'],
    [0.1568627450980392, '#072e5a'],
    [0.16078431372549018, '#062f5c'],
    [0.16470588235294117, '#052f5d'],
    [0.16862745098039217, '#04305f'],
    [0.17254901960784313, '#033161'],
    [0.1764705882352941, '#033262'],
    [0.1803921568627451, '#023264'],
    [0.1843137254901961, '#013365'],
    [0.18823529411764706, '#003467'],
    [0.19215686274509802, '#003569'],
    [0.19607843137254902, '#00366a'],
    [0.2, '#00366c'],
    [0.20392156862745098, '#00376d'],
    [0.20784313725490194, '#00386f'],
    [0.21176470588235294, '#003970'],
    [0.21568627450980393, '#003a71'],
    [0.2196078431372549, '#003a73'],
    [0.22352941176470587, '#003b74'],
    [0.22745098039215686, '#003c76'],
    [0.23137254901960785, '#003d77'],
    [0.23529411764705882, '#003d78'],
    [0.2392156862745098, '#003e7a'],
    [0.24313725490196078, '#003f7b'],
    [0.24705882352941178, '#00407c'],
    [0.25098039215686274, '#01417d'],
    [0.2549019607843137, '#02417e'],
    [0.2588235294117647, '#034280'],
    [0.2627450980392157, '#054381'],
    [0.26666666666666666, '#074482'],
    [0.27058823529411763, '#084583'],
    [0.27450980392156865, '#0b4584'],
    [0.2784313725490196, '#0d4685'],
    [0.2823529411764706, '#0f4786'],
    [0.28627450980392155, '#114887'],
    [0.2901960784313725, '#134987'],
    [0.29411764705882354, '#154988'],
    [0.2980392156862745, '#174a89'],
    [0.30196078431372547, '#194b8a'],
    [0.3058823529411765, '#1b4c8a'],
    [0.30980392156862746, '#1d4d8b'],
    [0.3137254901960784, '#1f4d8c'],
    [0.3176470588235294, '#214e8c'],
    [0.32156862745098036, '#244f8d'],
    [0.3254901960784314, '#26508d'],
    [0.32941176470588235, '#28518d'],
    [0.3333333333333333, '#2a528e'],
    [0.33725490196078434, '#2c528e'],
    [0.3411764705882353, '#2e538e'],
    [0.34509803921568627, '#30548e'],
    [0.34901960784313724, '#32558e'],
    [0.3529411764705882, '#35568e'],
    [0.3568627450980392, '#37568e'],
    [0.3607843137254902, '#39578e'],
    [0.36470588235294116, '#3b588e'],
    [0.3686274509803922, '#3e598d'],
    [0.37254901960784315, '#405a8d'],
    [0.3764705882352941, '#425b8c'],
    [0.3803921568627451, '#445b8b'],
    [0.38431372549019605, '#475c8b'],
    [0.38823529411764707, '#495d8a'],
    [0.39215686274509803, '#4b5e89'],
    [0.396078431372549, '#4d5f89'],
    [0.4, '#4f6088'],
    [0.403921568627451, '#516187'],
    [0.40784313725490196, '#536187'],
    [0.4117647058823529, '#546286'],
    [0.4156862745098039, '#566385'],
    [0.4196078431372549, '#586485'],
    [0.4235294117647059, '#5a6584'],
    [0.42745098039215684, '#5c6683'],
    [0.43137254901960786, '#5d6783'],
    [0.43529411764705883, '#5f6782'],
    [0.4392156862745098, '#616881'],
    [0.44313725490196076, '#626981'],
    [0.44705882352941173, '#646a80'],
    [0.45098039215686275, '#666b7f'],
    [0.4549019607843137, '#676c7f'],
    [0.4588235294117647, '#696d7e'],
    [0.4627450980392157, '#6a6d7d'],
    [0.4666666666666667, '#6c6e7c'],
    [0.47058823529411764, '#6d6f7c'],
    [0.4745098039215686, '#6f707b'],
    [0.4784313725490196, '#70717a'],
    [0.4823529411764706, '#72727a'],
    [0.48627450980392156, '#737379'],
    [0.49019607843137253, '#757478'],
    [0.49411764705882355, '#767577'],
    [0.4980392156862745, '#787577'],
    [0.5019607843137255, '#797676'],
    [0.5058823529411764, '#7b7775'],
    [0.5098039215686274, '#7c7874'],
    [0.5137254901960784, '#7d7974'],
    [0.5176470588235293, '#7f7a73'],
    [0.5215686274509804, '#807b72'],
    [0.5254901960784314, '#827c71'],
    [0.5294117647058824, '#837c71'],
    [0.5333333333333333, '#847d70'],
    [0.5372549019607843, '#867e6f'],
    [0.5411764705882353, '#877f6e'],
    [0.5450980392156862, '#88806d'],
    [0.5490196078431373, '#8a816d'],
    [0.5529411764705883, '#8b826c'],
    [0.5568627450980392, '#8d836b'],
    [0.5607843137254902, '#8e846a'],
    [0.5647058823529412, '#8f8569'],
    [0.5686274509803921, '#918569'],
    [0.5725490196078431, '#928668'],
    [0.5764705882352941, '#938767'],
    [0.580392156862745, '#958866'],
    [0.5843137254901961, '#968965'],
    [0.5882352941176471, '#978a64'],
    [0.592156862745098, '#998b63'],
    [0.596078431372549, '#9a8c63'],
    [0.6, '#9b8d62'],
    [0.6039215686274509, '#9d8e61'],
    [0.6078431372549019, '#9e8e60'],
    [0.611764705882353, '#9f8f5f'],
    [0.615686274509804, '#a1905e'],
    [0.6196078431372549, '#a2915d'],
    [0.6235294117647059, '#a3925c'],
    [0.6274509803921569, '#a4935b'],
    [0.6313725490196078, '#a6945b'],
    [0.6352941176470588, '#a7955a'],
    [0.6392156862745098, '#a8965a'],
    [0.6431372549019607, '#a99759'],
    [0.6470588235294118, '#ab9859'],
    [0.6509803921568628, '#ac9858'],
    [0.6549019607843137, '#ad9958'],
    [0.6588235294117647, '#ae9a58'],
    [0.6627450980392157, '#af9b58'],
    [0.6666666666666666, '#b09c58'],
    [0.6705882352941176, '#b19d58'],
    [0.6745098039215687, '#b29e58'],
    [0.6784313725490196, '#b49f59'],
    [0.6823529411764706, '#b5a059'],
    [0.6862745098039216, '#b6a159'],
    [0.6901960784313725, '#b7a25a'],
    [0.6941176470588235, '#b8a35a'],
    [0.6980392156862745, '#b9a45b'],
    [0.7019607843137254, '#baa55c'],
    [0.7058823529411764, '#bba65c'],
    [0.7098039215686275, '#bca65d'],
    [0.7137254901960784, '#bda75e'],
    [0.7176470588235294, '#bea85f'],
    [0.7215686274509804, '#bfa960'],
    [0.7254901960784313, '#c0aa61'],
    [0.7294117647058823, '#c1ab62'],
    [0.7333333333333333, '#c2ac63'],
    [0.7372549019607844, '#c3ad64'],
    [0.7411764705882353, '#c4ae65'],
    [0.7450980392156863, '#c5af66'],
    [0.7490196078431373, '#c6b067'],
    [0.7529411764705882, '#c7b169'],
    [0.7568627450980392, '#c8b26a'],
    [0.7607843137254902, '#c9b36b'],
    [0.7647058823529411, '#cab46d'],
    [0.7686274509803921, '#cab56e'],
    [0.7725490196078432, '#cbb66f'],
    [0.7764705882352941, '#ccb771'],
    [0.7803921568627451, '#cdb872'],
    [0.7843137254901961, '#ceb974'],
    [0.788235294117647, '#cfba76'],
    [0.792156862745098, '#d0bb77'],
    [0.796078431372549, '#d1bc79'],
    [0.8, '#d2bc7b'],
    [0.803921568627451, '#d2bd7c'],
    [0.807843137254902, '#d3be7e'],
    [0.8117647058823529, '#d4bf80'],
    [0.8156862745098039, '#d5c082'],
    [0.8196078431372549, '#d6c183'],
    [0.8235294117647058, '#d7c285'],
    [0.8274509803921568, '#d7c387'],
    [0.8313725490196078, '#d8c489'],
    [0.8352941176470589, '#d9c58b'],
    [0.8392156862745098, '#dac68d'],
    [0.8431372549019608, '#dbc78f'],
    [0.8470588235294118, '#dbc891'],
    [0.8509803921568627, '#dcc993'],
    [0.8549019607843137, '#ddca95'],
    [0.8588235294117647, '#decb97'],
    [0.8627450980392157, '#decc99'],
    [0.8666666666666667, '#dfcd9b'],
    [0.8705882352941177, '#e0ce9e'],
    [0.8745098039215686, '#e0cfa0'],
    [0.8784313725490196, '#e1d0a2'],
    [0.8823529411764706, '#e2d1a4'],
    [0.8862745098039215, '#e2d2a6'],
    [0.8901960784313725, '#e3d3a9'],
    [0.8941176470588235, '#e4d4ab'],
    [0.8980392156862745, '#e4d5ad'],
    [0.9019607843137255, '#e5d6b0'],
    [0.9058823529411765, '#e6d7b2'],
    [0.9098039215686274, '#e6d8b4'],
    [0.9137254901960784, '#e7d9b7'],
    [0.9176470588235294, '#e7dab9'],
    [0.9215686274509803, '#e8dbbc'],
    [0.9254901960784314, '#e8dcbe'],
    [0.9294117647058824, '#e9ddc0'],
    [0.9333333333333333, '#e9dec3'],
    [0.9372549019607843, '#eadfc5'],
    [0.9411764705882353, '#eae0c8'],
    [0.9450980392156862, '#ebe1cb'],
    [0.9490196078431372, '#ebe3cd'],
    [0.9529411764705882, '#ece4d0'],
    [0.9568627450980391, '#ece5d2'],
    [0.9607843137254902, '#ede6d5'],
    [0.9647058823529412, '#ede7d8'],
    [0.9686274509803922, '#ede8da'],
    [0.9725490196078431, '#eee9dd'],
    [0.9764705882352941, '#eeeae0'],
    [0.9803921568627451, '#efebe2'],
    [0.984313725490196, '#efece5'],
    [0.9882352941176471, '#efede8'],
    [0.9921568627450981, '#f0eeeb'],
    [0.996078431372549, '#f0efed'],
    [1.0, '#f0f0f0'],
  ],
  CET_CBL2: [
    [0.0, '#101010'],
    [0.00392156862745098, '#111112'],
    [0.00784313725490196, '#121214'],
    [0.011764705882352941, '#121316'],
    [0.01568627450980392, '#131418'],
    [0.0196078431372549, '#131519'],
    [0.023529411764705882, '#14151b'],
    [0.027450980392156862, '#14161d'],
    [0.03137254901960784, '#14171e'],
    [0.03529411764705882, '#151720'],
    [0.0392156862745098, '#151822'],
    [0.043137254901960784, '#151923'],
    [0.047058823529411764, '#151a25'],
    [0.050980392156862744, '#151a27'],
    [0.054901960784313725, '#161b29'],
    [0.058823529411764705, '#161c2a'],
    [0.06274509803921569, '#161c2c'],
    [0.06666666666666667, '#161d2e'],
    [0.07058823529411765, '#161e30'],
    [0.07450980392156863, '#161f31'],
    [0.0784313725490196, '#161f33'],
    [0.08235294117647059, '#162035'],
    [0.08627450980392157, '#162137'],
    [0.09019607843137255, '#162239'],
    [0.09411764705882353, '#16223a'],
    [0.09803921568627451, '#16233c'],
    [0.10196078431372549, '#16243e'],
    [0.10588235294117647, '#162540'],
    [0.10980392156862745, '#152542'],
    [0.11372549019607843, '#152644'],
    [0.11764705882352941, '#152745'],
    [0.12156862745098039, '#152847'],
    [0.12549019607843137, '#142949'],
    [0.12941176470588234, '#14294b'],
    [0.13333333333333333, '#142a4d'],
    [0.13725490196078433, '#142b4f'],
    [0.1411764705882353, '#132c50'],
    [0.14509803921568626, '#132c52'],
    [0.14901960784313725, '#122d54'],
    [0.15294117647058825, '#122e56'],
    [0.1568627450980392, '#122f57'],
    [0.16078431372549018, '#113059'],
    [0.16470588235294117, '#11305b'],
    [0.16862745098039217, '#11315c'],
    [0.17254901960784313, '#11325e'],
    [0.1764705882352941, '#103360'],
    [0.1803921568627451, '#103461'],
    [0.1843137254901961, '#103463'],
    [0.18823529411764706, '#103564'],
    [0.19215686274509802, '#0f3666'],
    [0.19607843137254902, '#0f3768'],
    [0.2, '#0f3869'],
    [0.20392156862745098, '#0f386b'],
    [0.20784313725490194, '#0f396c'],
    [0.21176470588235294, '#0f3a6e'],
    [0.21568627450980393, '#0f3b6f'],
    [0.2196078431372549, '#0f3c71'],
    [0.22352941176470587, '#0f3c72'],
    [0.22745098039215686, '#0f3d74'],
    [0.23137254901960785, '#0f3e75'],
    [0.23529411764705882, '#0f3f77'],
    [0.2392156862745098, '#0f4078'],
    [0.24313725490196078, '#0f417a'],
    [0.24705882352941178, '#0f417c'],
    [0.25098039215686274, '#0f427d'],
    [0.2549019607843137, '#0f437f'],
    [0.2588235294117647, '#0f4480'],
    [0.2627450980392157, '#0f4582'],
    [0.26666666666666666, '#0f4683'],
    [0.27058823529411763, '#0f4685'],
    [0.27450980392156865, '#0f4786'],
    [0.2784313725490196, '#0f4888'],
    [0.2823529411764706, '#0f4989'],
    [0.28627450980392155, '#0e4a8b'],
    [0.2901960784313725, '#0e4b8c'],
    [0.29411764705882354, '#0e4c8e'],
    [0.2980392156862745, '#0e4c90'],
    [0.30196078431372547, '#0e4d91'],
    [0.3058823529411765, '#0e4e93'],
    [0.30980392156862746, '#0e4f94'],
    [0.3137254901960784, '#0e5096'],
    [0.3176470588235294, '#0e5197'],
    [0.32156862745098036, '#0e5299'],
    [0.3254901960784314, '#0e529b'],
    [0.32941176470588235, '#0e539c'],
    [0.3333333333333333, '#0d549e'],
    [0.33725490196078434, '#0d559f'],
    [0.3411764705882353, '#0d56a1'],
    [0.34509803921568627, '#0d57a3'],
    [0.34901960784313724, '#0d58a4'],
    [0.3529411764705882, '#0d58a6'],
    [0.3568627450980392, '#0c59a7'],
    [0.3607843137254902, '#0c5aa9'],
    [0.36470588235294116, '#0c5bab'],
    [0.3686274509803922, '#0c5cac'],
    [0.37254901960784315, '#0c5dae'],
    [0.3764705882352941, '#0b5eaf'],
    [0.3803921568627451, '#0b5fb1'],
    [0.38431372549019605, '#0b60b3'],
    [0.38823529411764707, '#0b60b4'],
    [0.39215686274509803, '#0a61b6'],
    [0.396078431372549, '#0a62b8'],
    [0.4, '#0a63b9'],
    [0.403921568627451, '#0964bb'],
    [0.40784313725490196, '#0965bc'],
    [0.4117647058823529, '#0966be'],
    [0.4156862745098039, '#0867c0'],
    [0.4196078431372549, '#0868c1'],
    [0.4235294117647059, '#0768c3'],
    [0.42745098039215684, '#0769c5'],
    [0.43137254901960786, '#076ac6'],
    [0.43529411764705883, '#066bc8'],
    [0.4392156862745098, '#066cca'],
    [0.44313725490196076, '#056dcb'],
    [0.44705882352941173, '#056ecd'],
    [0.45098039215686275, '#046fcf'],
    [0.4549019607843137, '#0470d0'],
    [0.4588235294117647, '#0371d2'],
    [0.4627450980392157, '#0372d4'],
    [0.4666666666666667, '#0272d5'],
    [0.47058823529411764, '#0273d7'],
    [0.4745098039215686, '#0174d9'],
    [0.4784313725490196, '#0175da'],
    [0.4823529411764706, '#0076dc'],
    [0.48627450980392156, '#0077de'],
    [0.49019607843137253, '#0078df'],
    [0.49411764705882355, '#0079e1'],
    [0.4980392156862745, '#007ae3'],
    [0.5019607843137255, '#007be4'],
    [0.5058823529411764, '#017ce6'],
    [0.5098039215686274, '#037de7'],
    [0.5137254901960784, '#047de9'],
    [0.5176470588235293, '#077eea'],
    [0.5215686274509804, '#0a7feb'],
    [0.5254901960784314, '#0e80ed'],
    [0.5294117647058824, '#1281ee'],
    [0.5333333333333333, '#1682ef'],
    [0.5372549019607843, '#1a83f0'],
    [0.5411764705882353, '#1e84f0'],
    [0.5450980392156862, '#2385f1'],
    [0.5490196078431373, '#2786f1'],
    [0.5529411764705883, '#2c87f2'],
    [0.5568627450980392, '#3188f2'],
    [0.5607843137254902, '#3688f1'],
    [0.5647058823529412, '#3b89f1'],
    [0.5686274509803921, '#408af0'],
    [0.5725490196078431, '#458bf0'],
    [0.5764705882352941, '#4a8cee'],
    [0.580392156862745, '#4f8ded'],
    [0.5843137254901961, '#538eec'],
    [0.5882352941176471, '#588fea'],
    [0.592156862745098, '#5d90e8'],
    [0.596078431372549, '#6291e6'],
    [0.6, '#6692e3'],
    [0.6039215686274509, '#6a92e1'],
    [0.6078431372549019, '#6f93de'],
    [0.611764705882353, '#7394dc'],
    [0.615686274509804, '#7795d9'],
    [0.6196078431372549, '#7b96d6'],
    [0.6235294117647059, '#7e97d3'],
    [0.6274509803921569, '#8298d0'],
    [0.6313725490196078, '#8599cd'],
    [0.6352941176470588, '#899aca'],
    [0.6392156862745098, '#8c9bc7'],
    [0.6431372549019607, '#8f9cc3'],
    [0.6470588235294118, '#929dc0'],
    [0.6509803921568628, '#959ebd'],
    [0.6549019607843137, '#989fba'],
    [0.6588235294117647, '#9ba0b7'],
    [0.6627450980392157, '#9da1b3'],
    [0.6666666666666666, '#a0a2b0'],
    [0.6705882352941176, '#a2a3ad'],
    [0.6745098039215687, '#a5a4aa'],
    [0.6784313725490196, '#a7a5a7'],
    [0.6823529411764706, '#aaa6a4'],
    [0.6862745098039216, '#aca7a1'],
    [0.6901960784313725, '#aea89e'],
    [0.6941176470588235, '#b0a99b'],
    [0.6980392156862745, '#b2aa98'],
    [0.7019607843137254, '#b5ab94'],
    [0.7058823529411764, '#b7ac91'],
    [0.7098039215686275, '#b9ac8e'],
    [0.7137254901960784, '#bbad8b'],
    [0.7176470588235294, '#bdae89'],
    [0.7215686274509804, '#bfaf86'],
    [0.7254901960784313, '#c1b082'],
    [0.7294117647058823, '#c3b17f'],
    [0.7333333333333333, '#c4b27c'],
    [0.7372549019607844, '#c6b379'],
    [0.7411764705882353, '#c8b476'],
    [0.7450980392156863, '#cab573'],
    [0.7490196078431373, '#ccb670'],
    [0.7529411764705882, '#cdb76d'],
    [0.7568627450980392, '#cfb86a'],
    [0.7607843137254902, '#d1b966'],
    [0.7647058823529411, '#d3ba63'],
    [0.7686274509803921, '#d4bb60'],
    [0.7725490196078432, '#d6bc5c'],
    [0.7764705882352941, '#d8bd59'],
    [0.7803921568627451, '#d9be56'],
    [0.7843137254901961, '#dbbf52'],
    [0.788235294117647, '#dcc04e'],
    [0.792156862745098, '#dec14b'],
    [0.796078431372549, '#dfc247'],
    [0.8, '#e1c343'],
    [0.803921568627451, '#e2c440'],
    [0.807843137254902, '#e4c53c'],
    [0.8117647058823529, '#e5c638'],
    [0.8156862745098039, '#e6c734'],
    [0.8196078431372549, '#e8c831'],
    [0.8235294117647058, '#e9c92d'],
    [0.8274509803921568, '#eaca2a'],
    [0.8313725490196078, '#eccb27'],
    [0.8352941176470589, '#edcc25'],
    [0.8392156862745098, '#eecd23'],
    [0.8431372549019608, '#efce22'],
    [0.8470588235294118, '#f0cf22'],
    [0.8509803921568627, '#f2d022'],
    [0.8549019607843137, '#f3d124'],
    [0.8588235294117647, '#f4d227'],
    [0.8627450980392157, '#f5d32a'],
    [0.8666666666666667, '#f6d42f'],
    [0.8705882352941177, '#f7d533'],
    [0.8745098039215686, '#f8d638'],
    [0.8784313725490196, '#f9d73e'],
    [0.8823529411764706, '#f9d844'],
    [0.8862745098039215, '#fad949'],
    [0.8901960784313725, '#fbda50'],
    [0.8941176470588235, '#fcdb56'],
    [0.8980392156862745, '#fcdc5c'],
    [0.9019607843137255, '#fddd63'],
    [0.9058823529411765, '#fdde69'],
    [0.9098039215686274, '#fedf70'],
    [0.9137254901960784, '#fee077'],
    [0.9176470588235294, '#fee17d'],
    [0.9215686274509803, '#ffe284'],
    [0.9254901960784314, '#ffe38b'],
    [0.9294117647058824, '#ffe591'],
    [0.9333333333333333, '#ffe698'],
    [0.9372549019607843, '#ffe79e'],
    [0.9411764705882353, '#ffe8a5'],
    [0.9450980392156862, '#fee9ab'],
    [0.9490196078431372, '#feeab1'],
    [0.9529411764705882, '#feebb7'],
    [0.9568627450980391, '#feecbd'],
    [0.9607843137254902, '#feedc3'],
    [0.9647058823529412, '#fdeec8'],
    [0.9686274509803922, '#fdf0ce'],
    [0.9725490196078431, '#fdf1d3'],
    [0.9764705882352941, '#fcf2d8'],
    [0.9803921568627451, '#fcf3dd'],
    [0.984313725490196, '#fcf4e1'],
    [0.9882352941176471, '#fcf5e6'],
    [0.9921568627450981, '#fcf6ea'],
    [0.996078431372549, '#fbf7ef'],
    [1.0, '#fbf9f3'],
  ],
  CET_CBL3: [
    [0.0, '#101010'],
    [0.00392156862745098, '#111112'],
    [0.00784313725490196, '#121214'],
    [0.011764705882352941, '#121315'],
    [0.01568627450980392, '#131416'],
    [0.0196078431372549, '#141418'],
    [0.023529411764705882, '#141519'],
    [0.027450980392156862, '#15161a'],
    [0.03137254901960784, '#15171c'],
    [0.03529411764705882, '#16171d'],
    [0.0392156862745098, '#16181e'],
    [0.043137254901960784, '#16191f'],
    [0.047058823529411764, '#171921'],
    [0.050980392156862744, '#171a22'],
    [0.054901960784313725, '#181b24'],
    [0.058823529411764705, '#181b25'],
    [0.06274509803921569, '#191c26'],
    [0.06666666666666667, '#191d28'],
    [0.07058823529411765, '#191e29'],
    [0.07450980392156863, '#1a1e2a'],
    [0.0784313725490196, '#1a1f2c'],
    [0.08235294117647059, '#1b202d'],
    [0.08627450980392157, '#1b202f'],
    [0.09019607843137255, '#1b2130'],
    [0.09411764705882353, '#1c2231'],
    [0.09803921568627451, '#1c2333'],
    [0.10196078431372549, '#1c2334'],
    [0.10588235294117647, '#1d2436'],
    [0.10980392156862745, '#1d2537'],
    [0.11372549019607843, '#1d2538'],
    [0.11764705882352941, '#1e263a'],
    [0.12156862745098039, '#1e273b'],
    [0.12549019607843137, '#1e283d'],
    [0.12941176470588234, '#1f283e'],
    [0.13333333333333333, '#1f2940'],
    [0.13725490196078433, '#1f2a41'],
    [0.1411764705882353, '#202b43'],
    [0.14509803921568626, '#202b44'],
    [0.14901960784313725, '#202c46'],
    [0.15294117647058825, '#202d47'],
    [0.1568627450980392, '#212e49'],
    [0.16078431372549018, '#212e4a'],
    [0.16470588235294117, '#212f4c'],
    [0.16862745098039217, '#21304d'],
    [0.17254901960784313, '#22314f'],
    [0.1764705882352941, '#223150'],
    [0.1803921568627451, '#223252'],
    [0.1843137254901961, '#223353'],
    [0.18823529411764706, '#233455'],
    [0.19215686274509802, '#233556'],
    [0.19607843137254902, '#233558'],
    [0.2, '#233659'],
    [0.20392156862745098, '#23375b'],
    [0.20784313725490194, '#24385d'],
    [0.21176470588235294, '#24385e'],
    [0.21568627450980393, '#243960'],
    [0.2196078431372549, '#243a61'],
    [0.22352941176470587, '#243b63'],
    [0.22745098039215686, '#243c64'],
    [0.23137254901960785, '#243c66'],
    [0.23529411764705882, '#253d68'],
    [0.2392156862745098, '#253e69'],
    [0.24313725490196078, '#253f6b'],
    [0.24705882352941178, '#25406c'],
    [0.25098039215686274, '#25406e'],
    [0.2549019607843137, '#254170'],
    [0.2588235294117647, '#254271'],
    [0.2627450980392157, '#254373'],
    [0.26666666666666666, '#254475'],
    [0.27058823529411763, '#254476'],
    [0.27450980392156865, '#254578'],
    [0.2784313725490196, '#254679'],
    [0.2823529411764706, '#25477b'],
    [0.28627450980392155, '#25487d'],
    [0.2901960784313725, '#25487e'],
    [0.29411764705882354, '#254980'],
    [0.2980392156862745, '#254a82'],
    [0.30196078431372547, '#254b83'],
    [0.3058823529411765, '#254c85'],
    [0.30980392156862746, '#254d87'],
    [0.3137254901960784, '#254d88'],
    [0.3176470588235294, '#254e8a'],
    [0.32156862745098036, '#254f8c'],
    [0.3254901960784314, '#25508d'],
    [0.32941176470588235, '#25518f'],
    [0.3333333333333333, '#255291'],
    [0.33725490196078434, '#255292'],
    [0.3411764705882353, '#255394'],
    [0.34509803921568627, '#255496'],
    [0.34901960784313724, '#245597'],
    [0.3529411764705882, '#245699'],
    [0.3568627450980392, '#24579b'],
    [0.3607843137254902, '#24579d'],
    [0.36470588235294116, '#24589e'],
    [0.3686274509803922, '#2359a0'],
    [0.37254901960784315, '#235aa2'],
    [0.3764705882352941, '#235ba3'],
    [0.3803921568627451, '#235ca5'],
    [0.38431372549019605, '#225da7'],
    [0.38823529411764707, '#225da9'],
    [0.39215686274509803, '#225eaa'],
    [0.396078431372549, '#215fac'],
    [0.4, '#2160ae'],
    [0.403921568627451, '#2161b0'],
    [0.40784313725490196, '#2062b1'],
    [0.4117647058823529, '#2063b3'],
    [0.4156862745098039, '#1f63b5'],
    [0.4196078431372549, '#1f64b7'],
    [0.4235294117647059, '#1e65b8'],
    [0.42745098039215684, '#1e66ba'],
    [0.43137254901960786, '#1d67bc'],
    [0.43529411764705883, '#1d68be'],
    [0.4392156862745098, '#1c69c0'],
    [0.44313725490196076, '#1c6ac1'],
    [0.44705882352941173, '#1b6ac3'],
    [0.45098039215686275, '#1a6bc5'],
    [0.4549019607843137, '#196cc7'],
    [0.4588235294117647, '#196dc8'],
    [0.4627450980392157, '#186eca'],
    [0.4666666666666667, '#176fcc'],
    [0.47058823529411764, '#1670ce'],
    [0.4745098039215686, '#1571d0'],
    [0.4784313725490196, '#1472d1'],
    [0.4823529411764706, '#1372d3'],
    [0.48627450980392156, '#1273d5'],
    [0.49019607843137253, '#1074d7'],
    [0.49411764705882355, '#0f75d9'],
    [0.4980392156862745, '#0e76da'],
    [0.5019607843137255, '#0c77dc'],
    [0.5058823529411764, '#0b78de'],
    [0.5098039215686274, '#0a79e0'],
    [0.5137254901960784, '#097ae1'],
    [0.5176470588235293, '#087ae3'],
    [0.5215686274509804, '#077be5'],
    [0.5254901960784314, '#077ce6'],
    [0.5294117647058824, '#077de8'],
    [0.5333333333333333, '#077ee9'],
    [0.5372549019607843, '#087feb'],
    [0.5411764705882353, '#0a80ec'],
    [0.5450980392156862, '#0c81ee'],
    [0.5490196078431373, '#0f82ef'],
    [0.5529411764705883, '#1183f0'],
    [0.5568627450980392, '#1583f1'],
    [0.5607843137254902, '#1884f2'],
    [0.5647058823529412, '#1b85f3'],
    [0.5686274509803921, '#1e86f4'],
    [0.5725490196078431, '#2287f5'],
    [0.5764705882352941, '#2588f6'],
    [0.580392156862745, '#2989f7'],
    [0.5843137254901961, '#2c8af7'],
    [0.5882352941176471, '#308bf8'],
    [0.592156862745098, '#338bf8'],
    [0.596078431372549, '#378cf8'],
    [0.6, '#3a8df9'],
    [0.6039215686274509, '#3d8ef9'],
    [0.6078431372549019, '#408ff9'],
    [0.611764705882353, '#4490f9'],
    [0.615686274509804, '#4791f9'],
    [0.6196078431372549, '#4a92fa'],
    [0.6235294117647059, '#4d93fa'],
    [0.6274509803921569, '#4f93fa'],
    [0.6313725490196078, '#5294fa'],
    [0.6352941176470588, '#5595fa'],
    [0.6392156862745098, '#5896fa'],
    [0.6431372549019607, '#5a97fa'],
    [0.6470588235294118, '#5d98fa'],
    [0.6509803921568628, '#5f99f9'],
    [0.6549019607843137, '#629af9'],
    [0.6588235294117647, '#649bf9'],
    [0.6627450980392157, '#679cf9'],
    [0.6666666666666666, '#699df9'],
    [0.6705882352941176, '#6b9ef9'],
    [0.6745098039215687, '#6d9ef9'],
    [0.6784313725490196, '#6f9ff9'],
    [0.6823529411764706, '#72a0f9'],
    [0.6862745098039216, '#74a1f9'],
    [0.6901960784313725, '#76a2f9'],
    [0.6941176470588235, '#78a3f9'],
    [0.6980392156862745, '#7aa4f9'],
    [0.7019607843137254, '#7ca5f9'],
    [0.7058823529411764, '#7ea6f8'],
    [0.7098039215686275, '#80a7f8'],
    [0.7137254901960784, '#82a8f8'],
    [0.7176470588235294, '#84a9f8'],
    [0.7215686274509804, '#85aaf8'],
    [0.7254901960784313, '#87abf8'],
    [0.7294117647058823, '#89acf8'],
    [0.7333333333333333, '#8bacf8'],
    [0.7372549019607844, '#8dadf8'],
    [0.7411764705882353, '#8eaef8'],
    [0.7450980392156863, '#90aff8'],
    [0.7490196078431373, '#92b0f8'],
    [0.7529411764705882, '#94b1f7'],
    [0.7568627450980392, '#95b2f7'],
    [0.7607843137254902, '#97b3f7'],
    [0.7647058823529411, '#99b4f7'],
    [0.7686274509803921, '#9ab5f7'],
    [0.7725490196078432, '#9cb6f7'],
    [0.7764705882352941, '#9eb7f7'],
    [0.7803921568627451, '#9fb8f7'],
    [0.7843137254901961, '#a1b9f7'],
    [0.788235294117647, '#a3baf7'],
    [0.792156862745098, '#a4bbf7'],
    [0.796078431372549, '#a6bcf6'],
    [0.8, '#a7bdf6'],
    [0.803921568627451, '#a9bef6'],
    [0.807843137254902, '#abbff6'],
    [0.8117647058823529, '#acc0f6'],
    [0.8156862745098039, '#aec1f6'],
    [0.8196078431372549, '#afc2f6'],
    [0.8235294117647058, '#b1c3f6'],
    [0.8274509803921568, '#b2c4f6'],
    [0.8313725490196078, '#b4c5f6'],
    [0.8352941176470589, '#b5c6f5'],
    [0.8392156862745098, '#b7c7f5'],
    [0.8431372549019608, '#b8c8f5'],
    [0.8470588235294118, '#bac9f5'],
    [0.8509803921568627, '#bbcaf5'],
    [0.8549019607843137, '#bdcbf5'],
    [0.8588235294117647, '#beccf5'],
    [0.8627450980392157, '#c0cdf5'],
    [0.8666666666666667, '#c1cef5'],
    [0.8705882352941177, '#c3cff4'],
    [0.8745098039215686, '#c4d0f4'],
    [0.8784313725490196, '#c5d1f4'],
    [0.8823529411764706, '#c7d2f4'],
    [0.8862745098039215, '#c8d3f4'],
    [0.8901960784313725, '#cad4f4'],
    [0.8941176470588235, '#cbd5f4'],
    [0.8980392156862745, '#cdd6f4'],
    [0.9019607843137255, '#ced7f3'],
    [0.9058823529411765, '#cfd8f3'],
    [0.9098039215686274, '#d1d9f3'],
    [0.9137254901960784, '#d2daf3'],
    [0.9176470588235294, '#d4dbf3'],
    [0.9215686274509803, '#d5dcf3'],
    [0.9254901960784314, '#d6ddf3'],
    [0.9294117647058824, '#d8def3'],
    [0.9333333333333333, '#d9dff2'],
    [0.9372549019607843, '#dbe0f2'],
    [0.9411764705882353, '#dce1f2'],
    [0.9450980392156862, '#dde2f2'],
    [0.9490196078431372, '#dfe3f2'],
    [0.9529411764705882, '#e0e4f2'],
    [0.9568627450980391, '#e1e5f2'],
    [0.9607843137254902, '#e3e6f2'],
    [0.9647058823529412, '#e4e7f1'],
    [0.9686274509803922, '#e5e8f1'],
    [0.9725490196078431, '#e7e9f1'],
    [0.9764705882352941, '#e8eaf1'],
    [0.9803921568627451, '#e9ebf1'],
    [0.984313725490196, '#ebecf1'],
    [0.9882352941176471, '#ecedf1'],
    [0.9921568627450981, '#edeef0'],
    [0.996078431372549, '#efeff0'],
    [1.0, '#f0f0f0'],
  ],
  CET_CBL4: [
    [0.0, '#101010'],
    [0.00392156862745098, '#121110'],
    [0.00784313725490196, '#131210'],
    [0.011764705882352941, '#141310'],
    [0.01568627450980392, '#151410'],
    [0.0196078431372549, '#161410'],
    [0.023529411764705882, '#171510'],
    [0.027450980392156862, '#18160f'],
    [0.03137254901960784, '#19170f'],
    [0.03529411764705882, '#1a170f'],
    [0.0392156862745098, '#1b180f'],
    [0.043137254901960784, '#1c190f'],
    [0.047058823529411764, '#1d190f'],
    [0.050980392156862744, '#1e1a0f'],
    [0.054901960784313725, '#1f1b0e'],
    [0.058823529411764705, '#201b0e'],
    [0.06274509803921569, '#211c0e'],
    [0.06666666666666667, '#211d0e'],
    [0.07058823529411765, '#221d0e'],
    [0.07450980392156863, '#231e0e'],
    [0.0784313725490196, '#241f0d'],
    [0.08235294117647059, '#25200d'],
    [0.08627450980392157, '#26200d'],
    [0.09019607843137255, '#27210d'],
    [0.09411764705882353, '#28220d'],
    [0.09803921568627451, '#29220c'],
    [0.10196078431372549, '#2a230c'],
    [0.10588235294117647, '#2a240c'],
    [0.10980392156862745, '#2b250c'],
    [0.11372549019607843, '#2c250b'],
    [0.11764705882352941, '#2d260b'],
    [0.12156862745098039, '#2e270b'],
    [0.12549019607843137, '#2f280a'],
    [0.12941176470588234, '#30280a'],
    [0.13333333333333333, '#31290a'],
    [0.13725490196078433, '#322a09'],
    [0.1411764705882353, '#332a09'],
    [0.14509803921568626, '#332b09'],
    [0.14901960784313725, '#342c08'],
    [0.15294117647058825, '#352d08'],
    [0.1568627450980392, '#362d08'],
    [0.16078431372549018, '#372e07'],
    [0.16470588235294117, '#382f07'],
    [0.16862745098039217, '#393006'],
    [0.17254901960784313, '#3a3106'],
    [0.1764705882352941, '#3b3106'],
    [0.1803921568627451, '#3c3205'],
    [0.1843137254901961, '#3d3305'],
    [0.18823529411764706, '#3d3405'],
    [0.19215686274509802, '#3e3404'],
    [0.19607843137254902, '#3f3504'],
    [0.2, '#403604'],
    [0.20392156862745098, '#413703'],
    [0.20784313725490194, '#423703'],
    [0.21176470588235294, '#433803'],
    [0.21568627450980393, '#443903'],
    [0.2196078431372549, '#453a02'],
    [0.22352941176470587, '#463b02'],
    [0.22745098039215686, '#473b02'],
    [0.23137254901960785, '#483c02'],
    [0.23529411764705882, '#483d02'],
    [0.2392156862745098, '#493e02'],
    [0.24313725490196078, '#4a3f01'],
    [0.24705882352941178, '#4b3f01'],
    [0.25098039215686274, '#4c4001'],
    [0.2549019607843137, '#4d4101'],
    [0.2588235294117647, '#4e4201'],
    [0.2627450980392157, '#4f4301'],
    [0.26666666666666666, '#504301'],
    [0.27058823529411763, '#514401'],
    [0.27450980392156865, '#524501'],
    [0.2784313725490196, '#534601'],
    [0.2823529411764706, '#544701'],
    [0.28627450980392155, '#544701'],
    [0.2901960784313725, '#554801'],
    [0.29411764705882354, '#564901'],
    [0.2980392156862745, '#574a01'],
    [0.30196078431372547, '#584b01'],
    [0.3058823529411765, '#594b01'],
    [0.30980392156862746, '#5a4c01'],
    [0.3137254901960784, '#5b4d01'],
    [0.3176470588235294, '#5c4e01'],
    [0.32156862745098036, '#5d4f01'],
    [0.3254901960784314, '#5e5001'],
    [0.32941176470588235, '#5f5001'],
    [0.3333333333333333, '#605101'],
    [0.33725490196078434, '#615201'],
    [0.3411764705882353, '#625301'],
    [0.34509803921568627, '#635401'],
    [0.34901960784313724, '#645501'],
    [0.3529411764705882, '#655501'],
    [0.3568627450980392, '#665601'],
    [0.3607843137254902, '#675701'],
    [0.36470588235294116, '#685801'],
    [0.3686274509803922, '#695901'],
    [0.37254901960784315, '#695a01'],
    [0.3764705882352941, '#6a5a01'],
    [0.3803921568627451, '#6b5b01'],
    [0.38431372549019605, '#6c5c01'],
    [0.38823529411764707, '#6d5d01'],
    [0.39215686274509803, '#6e5e01'],
    [0.396078431372549, '#6f5f01'],
    [0.4, '#705f01'],
    [0.403921568627451, '#716001'],
    [0.40784313725490196, '#726101'],
    [0.4117647058823529, '#736201'],
    [0.4156862745098039, '#746301'],
    [0.4196078431372549, '#756401'],
    [0.4235294117647059, '#766501'],
    [0.42745098039215684, '#776501'],
    [0.43137254901960786, '#786601'],
    [0.43529411764705883, '#796701'],
    [0.4392156862745098, '#7a6801'],
    [0.44313725490196076, '#7b6901'],
    [0.44705882352941173, '#7c6a01'],
    [0.45098039215686275, '#7d6b01'],
    [0.4549019607843137, '#7e6c01'],
    [0.4588235294117647, '#7f6c01'],
    [0.4627450980392157, '#806d01'],
    [0.4666666666666667, '#816e01'],
    [0.47058823529411764, '#826f01'],
    [0.4745098039215686, '#837001'],
    [0.4784313725490196, '#847101'],
    [0.4823529411764706, '#857201'],
    [0.48627450980392156, '#867301'],
    [0.49019607843137253, '#877301'],
    [0.49411764705882355, '#887401'],
    [0.4980392156862745, '#897501'],
    [0.5019607843137255, '#8a7601'],
    [0.5058823529411764, '#8b7701'],
    [0.5098039215686274, '#8c7801'],
    [0.5137254901960784, '#8d7901'],
    [0.5176470588235293, '#8e7a01'],
    [0.5215686274509804, '#8f7a01'],
    [0.5254901960784314, '#917b01'],
    [0.5294117647058824, '#927c01'],
    [0.5333333333333333, '#937d01'],
    [0.5372549019607843, '#947e01'],
    [0.5411764705882353, '#957f01'],
    [0.5450980392156862, '#968001'],
    [0.5490196078431373, '#978101'],
    [0.5529411764705883, '#988201'],
    [0.5568627450980392, '#998301'],
    [0.5607843137254902, '#9a8301'],
    [0.5647058823529412, '#9b8401'],
    [0.5686274509803921, '#9c8501'],
    [0.5725490196078431, '#9d8601'],
    [0.5764705882352941, '#9e8701'],
    [0.580392156862745, '#9f8801'],
    [0.5843137254901961, '#a08901'],
    [0.5882352941176471, '#a18a01'],
    [0.592156862745098, '#a28b01'],
    [0.596078431372549, '#a38c01'],
    [0.6, '#a48c01'],
    [0.6039215686274509, '#a58d01'],
    [0.6078431372549019, '#a68e01'],
    [0.611764705882353, '#a78f01'],
    [0.615686274509804, '#a89001'],
    [0.6196078431372549, '#aa9101'],
    [0.6235294117647059, '#ab9201'],
    [0.6274509803921569, '#ac9301'],
    [0.6313725490196078, '#ad9401'],
    [0.6352941176470588, '#ae9501'],
    [0.6392156862745098, '#af9601'],
    [0.6431372549019607, '#b09701'],
    [0.6470588235294118, '#b19801'],
    [0.6509803921568628, '#b29801'],
    [0.6549019607843137, '#b39901'],
    [0.6588235294117647, '#b49a01'],
    [0.6627450980392157, '#b59b01'],
    [0.6666666666666666, '#b69c01'],
    [0.6705882352941176, '#b79d01'],
    [0.6745098039215687, '#b89e01'],
    [0.6784313725490196, '#ba9f01'],
    [0.6823529411764706, '#bba001'],
    [0.6862745098039216, '#bca101'],
    [0.6901960784313725, '#bda201'],
    [0.6941176470588235, '#bea301'],
    [0.6980392156862745, '#bfa401'],
    [0.7019607843137254, '#c0a501'],
    [0.7058823529411764, '#c1a501'],
    [0.7098039215686275, '#c2a601'],
    [0.7137254901960784, '#c3a701'],
    [0.7176470588235294, '#c4a801'],
    [0.7215686274509804, '#c5a901'],
    [0.7254901960784313, '#c7aa01'],
    [0.7294117647058823, '#c8ab01'],
    [0.7333333333333333, '#c9ac01'],
    [0.7372549019607844, '#caad01'],
    [0.7411764705882353, '#cbae01'],
    [0.7450980392156863, '#ccaf01'],
    [0.7490196078431373, '#cdb001'],
    [0.7529411764705882, '#ceb101'],
    [0.7568627450980392, '#cfb201'],
    [0.7607843137254902, '#d0b301'],
    [0.7647058823529411, '#d2b401'],
    [0.7686274509803921, '#d3b501'],
    [0.7725490196078432, '#d4b601'],
    [0.7764705882352941, '#d5b701'],
    [0.7803921568627451, '#d6b801'],
    [0.7843137254901961, '#d7b801'],
    [0.788235294117647, '#d8b901'],
    [0.792156862745098, '#d9ba01'],
    [0.796078431372549, '#dabb01'],
    [0.8, '#dbbc01'],
    [0.803921568627451, '#ddbd01'],
    [0.807843137254902, '#debe01'],
    [0.8117647058823529, '#dfbf00'],
    [0.8156862745098039, '#e0c000'],
    [0.8196078431372549, '#e1c100'],
    [0.8235294117647058, '#e2c200'],
    [0.8274509803921568, '#e3c301'],
    [0.8313725490196078, '#e4c401'],
    [0.8352941176470589, '#e5c501'],
    [0.8392156862745098, '#e7c601'],
    [0.8431372549019608, '#e8c702'],
    [0.8470588235294118, '#e9c802'],
    [0.8509803921568627, '#eac903'],
    [0.8549019607843137, '#ebca04'],
    [0.8588235294117647, '#eccb06'],
    [0.8627450980392157, '#edcc08'],
    [0.8666666666666667, '#eecd0a'],
    [0.8705882352941177, '#efce0d'],
    [0.8745098039215686, '#f1cf10'],
    [0.8784313725490196, '#f2d013'],
    [0.8823529411764706, '#f3d117'],
    [0.8862745098039215, '#f4d21b'],
    [0.8901960784313725, '#f5d31f'],
    [0.8941176470588235, '#f6d423'],
    [0.8980392156862745, '#f7d528'],
    [0.9019607843137255, '#f8d62d'],
    [0.9058823529411765, '#f9d732'],
    [0.9098039215686274, '#fad837'],
    [0.9137254901960784, '#fad93d'],
    [0.9176470588235294, '#fbda42'],
    [0.9215686274509803, '#fcdb48'],
    [0.9254901960784314, '#fddb4f'],
    [0.9294117647058824, '#fddc55'],
    [0.9333333333333333, '#fedd5c'],
    [0.9372549019607843, '#fede63'],
    [0.9411764705882353, '#ffdf6a'],
    [0.9450980392156862, '#ffe172'],
    [0.9490196078431372, '#ffe279'],
    [0.9529411764705882, '#ffe381'],
    [0.9568627450980391, '#ffe489'],
    [0.9607843137254902, '#ffe591'],
    [0.9647058823529412, '#ffe699'],
    [0.9686274509803922, '#fee7a1'],
    [0.9725490196078431, '#fde8aa'],
    [0.9764705882352941, '#fde9b2'],
    [0.9803921568627451, '#fceabb'],
    [0.984313725490196, '#faebc4'],
    [0.9882352941176471, '#f9eccc'],
    [0.9921568627450981, '#f8eed5'],
    [0.996078431372549, '#f6efde'],
    [1.0, '#f4f0e7'],
  ],
  CET_CBTC1: [
    [0.0, '#26bbd6'],
    [0.00392156862745098, '#29bcd7'],
    [0.00784313725490196, '#2dbdd8'],
    [0.011764705882352941, '#32bed9'],
    [0.01568627450980392, '#37bfda'],
    [0.0196078431372549, '#3cc0db'],
    [0.023529411764705882, '#41c1dc'],
    [0.027450980392156862, '#46c3dc'],
    [0.03137254901960784, '#4bc4dd'],
    [0.03529411764705882, '#50c5dd'],
    [0.0392156862745098, '#54c6de'],
    [0.043137254901960784, '#59c7df'],
    [0.047058823529411764, '#5dc8df'],
    [0.050980392156862744, '#62c9e0'],
    [0.054901960784313725, '#66cae0'],
    [0.058823529411764705, '#6acbe1'],
    [0.06274509803921569, '#6ecce2'],
    [0.06666666666666667, '#71cde2'],
    [0.07058823529411765, '#75cee3'],
    [0.07450980392156863, '#79cfe3'],
    [0.0784313725490196, '#7cd0e4'],
    [0.08235294117647059, '#80d1e5'],
    [0.08627450980392157, '#83d2e5'],
    [0.09019607843137255, '#86d3e6'],
    [0.09411764705882353, '#8ad4e6'],
    [0.09803921568627451, '#8dd5e7'],
    [0.10196078431372549, '#90d6e8'],
    [0.10588235294117647, '#93d7e8'],
    [0.10980392156862745, '#97d8e9'],
    [0.11372549019607843, '#9ad9e9'],
    [0.11764705882352941, '#9ddaea'],
    [0.12156862745098039, '#a0dbea'],
    [0.12549019607843137, '#a3dceb'],
    [0.12941176470588234, '#a6deec'],
    [0.13333333333333333, '#a9dfec'],
    [0.13725490196078433, '#ace0ed'],
    [0.1411764705882353, '#afe1ed'],
    [0.14509803921568626, '#b2e2ee'],
    [0.14901960784313725, '#b5e3ef'],
    [0.15294117647058825, '#b8e4ef'],
    [0.1568627450980392, '#bae5f0'],
    [0.16078431372549018, '#bde6f0'],
    [0.16470588235294117, '#c0e7f1'],
    [0.16862745098039217, '#c3e8f2'],
    [0.17254901960784313, '#c6e9f2'],
    [0.1764705882352941, '#c9eaf3'],
    [0.1803921568627451, '#cbebf3'],
    [0.1843137254901961, '#ceecf4'],
    [0.18823529411764706, '#d1edf5'],
    [0.19215686274509802, '#d4eef5'],
    [0.19607843137254902, '#d6eff6'],
    [0.2, '#d9f0f6'],
    [0.20392156862745098, '#dcf1f7'],
    [0.20784313725490194, '#def2f7'],
    [0.21176470588235294, '#e1f3f8'],
    [0.21568627450980393, '#e4f4f8'],
    [0.2196078431372549, '#e6f5f9'],
    [0.22352941176470587, '#e9f6f9'],
    [0.22745098039215686, '#ecf6f9'],
    [0.23137254901960785, '#eef7f9'],
    [0.23529411764705882, '#f0f7f9'],
    [0.2392156862745098, '#f3f8f9'],
    [0.24313725490196078, '#f5f8f9'],
    [0.24705882352941178, '#f7f8f8'],
    [0.25098039215686274, '#f8f7f8'],
    [0.2549019607843137, '#faf7f7'],
    [0.2588235294117647, '#fbf6f5'],
    [0.2627450980392157, '#fcf5f4'],
    [0.26666666666666666, '#fcf4f3'],
    [0.27058823529411763, '#fdf3f1'],
    [0.27450980392156865, '#fdf1ef'],
    [0.2784313725490196, '#fef0ee'],
    [0.2823529411764706, '#feeeec'],
    [0.28627450980392155, '#feedea'],
    [0.2901960784313725, '#feebe8'],
    [0.29411764705882354, '#fee9e6'],
    [0.2980392156862745, '#fee8e4'],
    [0.30196078431372547, '#fee6e2'],
    [0.3058823529411765, '#fde4e0'],
    [0.30980392156862746, '#fde3df'],
    [0.3137254901960784, '#fde1dd'],
    [0.3176470588235294, '#fddfdb'],
    [0.32156862745098036, '#fdded9'],
    [0.3254901960784314, '#fddcd7'],
    [0.32941176470588235, '#fcdad5'],
    [0.3333333333333333, '#fcd9d3'],
    [0.33725490196078434, '#fcd7d1'],
    [0.3411764705882353, '#fcd5cf'],
    [0.34509803921568627, '#fcd4ce'],
    [0.34901960784313724, '#fbd2cc'],
    [0.3529411764705882, '#fbd0ca'],
    [0.3568627450980392, '#fbcfc8'],
    [0.3607843137254902, '#fbcdc6'],
    [0.36470588235294116, '#facbc4'],
    [0.3686274509803922, '#facac2'],
    [0.37254901960784315, '#fac8c1'],
    [0.3764705882352941, '#fac6bf'],
    [0.3803921568627451, '#f9c5bd'],
    [0.38431372549019605, '#f9c3bb'],
    [0.38823529411764707, '#f9c2b9'],
    [0.39215686274509803, '#f8c0b7'],
    [0.396078431372549, '#f8beb6'],
    [0.4, '#f8bdb4'],
    [0.403921568627451, '#f8bbb2'],
    [0.40784313725490196, '#f7b9b0'],
    [0.4117647058823529, '#f7b8ae'],
    [0.4156862745098039, '#f6b6ac'],
    [0.4196078431372549, '#f6b4ab'],
    [0.4235294117647059, '#f6b3a9'],
    [0.42745098039215684, '#f5b1a7'],
    [0.43137254901960786, '#f5afa5'],
    [0.43529411764705883, '#f5aea3'],
    [0.4392156862745098, '#f4aca2'],
    [0.44313725490196076, '#f4aaa0'],
    [0.44705882352941173, '#f3a99e'],
    [0.45098039215686275, '#f3a79c'],
    [0.4549019607843137, '#f3a59a'],
    [0.4588235294117647, '#f2a499'],
    [0.4627450980392157, '#f2a297'],
    [0.4666666666666667, '#f1a095'],
    [0.47058823529411764, '#f19f94'],
    [0.4745098039215686, '#f09d92'],
    [0.4784313725490196, '#ef9c90'],
    [0.4823529411764706, '#ee9a8f'],
    [0.48627450980392156, '#ee998d'],
    [0.49019607843137253, '#ed978c'],
    [0.49411764705882355, '#ec968a'],
    [0.4980392156862745, '#ea9589'],
    [0.5019607843137255, '#e99388'],
    [0.5058823529411764, '#e79287'],
    [0.5098039215686274, '#e69186'],
    [0.5137254901960784, '#e49085'],
    [0.5176470588235293, '#e28f84'],
    [0.5215686274509804, '#e08e83'],
    [0.5254901960784314, '#de8d82'],
    [0.5294117647058824, '#dc8c82'],
    [0.5333333333333333, '#da8c81'],
    [0.5372549019607843, '#d88b80'],
    [0.5411764705882353, '#d58a80'],
    [0.5450980392156862, '#d3897f'],
    [0.5490196078431373, '#d1887e'],
    [0.5529411764705883, '#cf887e'],
    [0.5568627450980392, '#cc877d'],
    [0.5607843137254902, '#ca867d'],
    [0.5647058823529412, '#c8857c'],
    [0.5686274509803921, '#c6847b'],
    [0.5725490196078431, '#c4847b'],
    [0.5764705882352941, '#c1837a'],
    [0.580392156862745, '#bf8279'],
    [0.5843137254901961, '#bd8179'],
    [0.5882352941176471, '#bb8078'],
    [0.592156862745098, '#b88077'],
    [0.596078431372549, '#b67f77'],
    [0.6, '#b47e76'],
    [0.6039215686274509, '#b27d76'],
    [0.6078431372549019, '#b07c75'],
    [0.611764705882353, '#ad7c74'],
    [0.615686274509804, '#ab7b74'],
    [0.6196078431372549, '#a97a73'],
    [0.6235294117647059, '#a77972'],
    [0.6274509803921569, '#a57872'],
    [0.6313725490196078, '#a27771'],
    [0.6352941176470588, '#a07771'],
    [0.6392156862745098, '#9e7670'],
    [0.6431372549019607, '#9c756f'],
    [0.6470588235294118, '#9a746f'],
    [0.6509803921568628, '#98736e'],
    [0.6549019607843137, '#95736d'],
    [0.6588235294117647, '#93726d'],
    [0.6627450980392157, '#91716c'],
    [0.6666666666666666, '#8f706c'],
    [0.6705882352941176, '#8d6f6b'],
    [0.6745098039215687, '#8a6f6a'],
    [0.6784313725490196, '#886e6a'],
    [0.6823529411764706, '#866d69'],
    [0.6862745098039216, '#846c68'],
    [0.6901960784313725, '#826b68'],
    [0.6941176470588235, '#7f6a67'],
    [0.6980392156862745, '#7d6a67'],
    [0.7019607843137254, '#7b6966'],
    [0.7058823529411764, '#796865'],
    [0.7098039215686275, '#776765'],
    [0.7137254901960784, '#756664'],
    [0.7176470588235294, '#726664'],
    [0.7215686274509804, '#706563'],
    [0.7254901960784313, '#6e6463'],
    [0.7294117647058823, '#6c6463'],
    [0.7333333333333333, '#6a6463'],
    [0.7372549019607844, '#686363'],
    [0.7411764705882353, '#676363'],
    [0.7450980392156863, '#656363'],
    [0.7490196078431373, '#646364'],
    [0.7529411764705882, '#626464'],
    [0.7568627450980392, '#616465'],
    [0.7607843137254902, '#606566'],
    [0.7647058823529411, '#606668'],
    [0.7686274509803921, '#5f6769'],
    [0.7725490196078432, '#5e686b'],
    [0.7764705882352941, '#5e696c'],
    [0.7803921568627451, '#5e6a6e'],
    [0.7843137254901961, '#5d6c70'],
    [0.788235294117647, '#5d6d71'],
    [0.792156862745098, '#5d6e73'],
    [0.796078431372549, '#5d7075'],
    [0.8, '#5c7177'],
    [0.803921568627451, '#5c7378'],
    [0.807843137254902, '#5c747a'],
    [0.8117647058823529, '#5c757c'],
    [0.8156862745098039, '#5b777e'],
    [0.8196078431372549, '#5b7880'],
    [0.8235294117647058, '#5b7a82'],
    [0.8274509803921568, '#5a7b83'],
    [0.8313725490196078, '#5a7c85'],
    [0.8352941176470589, '#597e87'],
    [0.8392156862745098, '#597f89'],
    [0.8431372549019608, '#58818b'],
    [0.8470588235294118, '#58828d'],
    [0.8509803921568627, '#57848e'],
    [0.8549019607843137, '#578590'],
    [0.8588235294117647, '#568692'],
    [0.8627450980392157, '#558894'],
    [0.8666666666666667, '#558996'],
    [0.8705882352941177, '#548b98'],
    [0.8745098039215686, '#538c9a'],
    [0.8784313725490196, '#528e9c'],
    [0.8823529411764706, '#528f9e'],
    [0.8862745098039215, '#51909f'],
    [0.8901960784313725, '#5092a1'],
    [0.8941176470588235, '#4f93a3'],
    [0.8980392156862745, '#4e95a5'],
    [0.9019607843137255, '#4d96a7'],
    [0.9058823529411765, '#4c98a9'],
    [0.9098039215686274, '#4a99ab'],
    [0.9137254901960784, '#499bad'],
    [0.9176470588235294, '#489caf'],
    [0.9215686274509803, '#469eb1'],
    [0.9254901960784314, '#459fb3'],
    [0.9294117647058824, '#43a0b5'],
    [0.9333333333333333, '#42a2b7'],
    [0.9372549019607843, '#40a3b8'],
    [0.9411764705882353, '#3ea5ba'],
    [0.9450980392156862, '#3ca6bc'],
    [0.9490196078431372, '#3aa8be'],
    [0.9529411764705882, '#38a9c0'],
    [0.9568627450980391, '#36abc2'],
    [0.9607843137254902, '#33acc4'],
    [0.9647058823529412, '#31aec6'],
    [0.9686274509803922, '#2eafc8'],
    [0.9725490196078431, '#2bb1ca'],
    [0.9764705882352941, '#28b2cc'],
    [0.9803921568627451, '#26b3ce'],
    [0.984313725490196, '#24b5cf'],
    [0.9882352941176471, '#22b6d1'],
    [0.9921568627450981, '#21b7d2'],
    [0.996078431372549, '#22b9d4'],
    [1.0, '#23bad5'],
  ],
  CET_CBTC2: [
    [0.0, '#fbfafa'],
    [0.00392156862745098, '#fcf9f9'],
    [0.00784313725490196, '#fcf8f8'],
    [0.011764705882352941, '#fdf8f7'],
    [0.01568627450980392, '#fef6f5'],
    [0.0196078431372549, '#fef5f4'],
    [0.023529411764705882, '#fef4f2'],
    [0.027450980392156862, '#fef2f1'],
    [0.03137254901960784, '#fff1ef'],
    [0.03529411764705882, '#ffefed'],
    [0.0392156862745098, '#ffeeeb'],
    [0.043137254901960784, '#ffece9'],
    [0.047058823529411764, '#ffeae7'],
    [0.050980392156862744, '#ffe9e5'],
    [0.054901960784313725, '#ffe7e3'],
    [0.058823529411764705, '#fee5e2'],
    [0.06274509803921569, '#fee4e0'],
    [0.06666666666666667, '#fee2de'],
    [0.07058823529411765, '#fee0dc'],
    [0.07450980392156863, '#fedfda'],
    [0.0784313725490196, '#feddd8'],
    [0.08235294117647059, '#fedbd6'],
    [0.08627450980392157, '#fedad4'],
    [0.09019607843137255, '#fed8d2'],
    [0.09411764705882353, '#fdd6d0'],
    [0.09803921568627451, '#fdd5cf'],
    [0.10196078431372549, '#fdd3cd'],
    [0.10588235294117647, '#fdd1cb'],
    [0.10980392156862745, '#fdd0c9'],
    [0.11372549019607843, '#fdcec7'],
    [0.11764705882352941, '#fcccc5'],
    [0.12156862745098039, '#fccbc3'],
    [0.12549019607843137, '#fcc9c1'],
    [0.12941176470588234, '#fcc8c0'],
    [0.13333333333333333, '#fbc6be'],
    [0.13725490196078433, '#fbc4bc'],
    [0.1411764705882353, '#fbc3ba'],
    [0.14509803921568626, '#fbc1b8'],
    [0.14901960784313725, '#fabfb6'],
    [0.15294117647058825, '#fabeb5'],
    [0.1568627450980392, '#fabcb3'],
    [0.16078431372549018, '#fabab1'],
    [0.16470588235294117, '#f9b9af'],
    [0.16862745098039217, '#f9b7ad'],
    [0.17254901960784313, '#f9b5ab'],
    [0.1764705882352941, '#f8b4aa'],
    [0.1803921568627451, '#f8b2a8'],
    [0.1843137254901961, '#f8b0a6'],
    [0.18823529411764706, '#f7afa4'],
    [0.19215686274509802, '#f7ada2'],
    [0.19607843137254902, '#f7aba0'],
    [0.2, '#f6aa9f'],
    [0.20392156862745098, '#f6a89d'],
    [0.20784313725490194, '#f6a69b'],
    [0.21176470588235294, '#f5a599'],
    [0.21568627450980393, '#f5a398'],
    [0.2196078431372549, '#f5a296'],
    [0.22352941176470587, '#f4a094'],
    [0.22745098039215686, '#f49f93'],
    [0.23137254901960785, '#f39d92'],
    [0.23529411764705882, '#f39c90'],
    [0.2392156862745098, '#f39b8f'],
    [0.24313725490196078, '#f39b8f'],
    [0.24705882352941178, '#f39a8e'],
    [0.25098039215686274, '#f39a8e'],
    [0.2549019607843137, '#f39a8e'],
    [0.2588235294117647, '#f39a8e'],
    [0.2627450980392157, '#f39b8f'],
    [0.26666666666666666, '#f39b90'],
    [0.27058823529411763, '#f39c91'],
    [0.27450980392156865, '#f49e92'],
    [0.2784313725490196, '#f49f93'],
    [0.2823529411764706, '#f4a095'],
    [0.28627450980392155, '#f5a296'],
    [0.2901960784313725, '#f5a398'],
    [0.29411764705882354, '#f5a59a'],
    [0.2980392156862745, '#f6a79c'],
    [0.30196078431372547, '#f6a89d'],
    [0.3058823529411765, '#f6aa9f'],
    [0.30980392156862746, '#f7aca1'],
    [0.3137254901960784, '#f7ada3'],
    [0.3176470588235294, '#f8afa5'],
    [0.32156862745098036, '#f8b1a6'],
    [0.3254901960784314, '#f8b2a8'],
    [0.32941176470588235, '#f9b4aa'],
    [0.3333333333333333, '#f9b6ac'],
    [0.33725490196078434, '#f9b7ae'],
    [0.3411764705882353, '#f9b9b0'],
    [0.34509803921568627, '#fabbb1'],
    [0.34901960784313724, '#fabcb3'],
    [0.3529411764705882, '#fabeb5'],
    [0.3568627450980392, '#fbc0b7'],
    [0.3607843137254902, '#fbc1b9'],
    [0.36470588235294116, '#fbc3bb'],
    [0.3686274509803922, '#fbc5bc'],
    [0.37254901960784315, '#fcc6be'],
    [0.3764705882352941, '#fcc8c0'],
    [0.3803921568627451, '#fccac2'],
    [0.38431372549019605, '#fccbc4'],
    [0.38823529411764707, '#fccdc6'],
    [0.39215686274509803, '#fdcfc8'],
    [0.396078431372549, '#fdd0c9'],
    [0.4, '#fdd2cb'],
    [0.403921568627451, '#fdd4cd'],
    [0.40784313725490196, '#fdd5cf'],
    [0.4117647058823529, '#fdd7d1'],
    [0.4156862745098039, '#fed9d3'],
    [0.4196078431372549, '#fedad5'],
    [0.4235294117647059, '#fedcd7'],
    [0.42745098039215684, '#feded9'],
    [0.43137254901960786, '#fedfda'],
    [0.43529411764705883, '#fee1dc'],
    [0.4392156862745098, '#fee3de'],
    [0.44313725490196076, '#fee4e0'],
    [0.44705882352941173, '#fee6e2'],
    [0.45098039215686275, '#ffe8e4'],
    [0.4549019607843137, '#ffe9e6'],
    [0.4588235294117647, '#ffebe8'],
    [0.4627450980392157, '#ffecea'],
    [0.4666666666666667, '#feeeec'],
    [0.47058823529411764, '#fef0ed'],
    [0.4745098039215686, '#fef1ef'],
    [0.4784313725490196, '#fef3f1'],
    [0.4823529411764706, '#fdf4f3'],
    [0.48627450980392156, '#fcf5f4'],
    [0.49019607843137253, '#fcf6f5'],
    [0.49411764705882355, '#faf7f7'],
    [0.4980392156862745, '#f9f8f8'],
    [0.5019607843137255, '#f8f8f9'],
    [0.5058823529411764, '#f6f8f9'],
    [0.5098039215686274, '#f4f8fa'],
    [0.5137254901960784, '#f2f8fa'],
    [0.5176470588235293, '#f0f8fa'],
    [0.5215686274509804, '#edf7fa'],
    [0.5254901960784314, '#ebf7fa'],
    [0.5294117647058824, '#e8f6fa'],
    [0.5333333333333333, '#e5f5f9'],
    [0.5372549019607843, '#e3f4f9'],
    [0.5411764705882353, '#e0f3f8'],
    [0.5450980392156862, '#ddf2f8'],
    [0.5490196078431373, '#dbf1f7'],
    [0.5529411764705883, '#d8f0f7'],
    [0.5568627450980392, '#d5eff6'],
    [0.5607843137254902, '#d2eef6'],
    [0.5647058823529412, '#cfedf5'],
    [0.5686274509803921, '#cdecf5'],
    [0.5725490196078431, '#caebf4'],
    [0.5764705882352941, '#c7eaf3'],
    [0.580392156862745, '#c4e9f3'],
    [0.5843137254901961, '#c1e8f2'],
    [0.5882352941176471, '#bee7f2'],
    [0.592156862745098, '#bbe6f1'],
    [0.596078431372549, '#b8e5f1'],
    [0.6, '#b6e4f0'],
    [0.6039215686274509, '#b3e3f0'],
    [0.6078431372549019, '#b0e2ef'],
    [0.611764705882353, '#ade1ef'],
    [0.615686274509804, '#aae0ee'],
    [0.6196078431372549, '#a7dfed'],
    [0.6235294117647059, '#a3deed'],
    [0.6274509803921569, '#a0ddec'],
    [0.6313725490196078, '#9ddcec'],
    [0.6352941176470588, '#9adbeb'],
    [0.6392156862745098, '#97daeb'],
    [0.6431372549019607, '#94d9ea'],
    [0.6470588235294118, '#90d8ea'],
    [0.6509803921568628, '#8dd7e9'],
    [0.6549019607843137, '#8ad6e9'],
    [0.6588235294117647, '#86d5e8'],
    [0.6627450980392157, '#83d4e7'],
    [0.6666666666666666, '#7fd3e7'],
    [0.6705882352941176, '#7cd2e6'],
    [0.6745098039215687, '#78d1e6'],
    [0.6784313725490196, '#74d0e5'],
    [0.6823529411764706, '#70cfe5'],
    [0.6862745098039216, '#6ccee4'],
    [0.6901960784313725, '#68cde4'],
    [0.6941176470588235, '#64cce3'],
    [0.6980392156862745, '#60cbe3'],
    [0.7019607843137254, '#5bcae2'],
    [0.7058823529411764, '#57c9e1'],
    [0.7098039215686275, '#52c8e1'],
    [0.7137254901960784, '#4dc7e0'],
    [0.7176470588235294, '#48c6e0'],
    [0.7215686274509804, '#43c5df'],
    [0.7254901960784313, '#3ec4df'],
    [0.7294117647058823, '#39c3df'],
    [0.7333333333333333, '#35c3de'],
    [0.7372549019607844, '#31c2de'],
    [0.7411764705882353, '#2ec2de'],
    [0.7450980392156863, '#2cc2de'],
    [0.7490196078431373, '#2bc2de'],
    [0.7529411764705882, '#2cc2de'],
    [0.7568627450980392, '#2ec2de'],
    [0.7607843137254902, '#32c2de'],
    [0.7647058823529411, '#36c3de'],
    [0.7686274509803921, '#3ac4df'],
    [0.7725490196078432, '#3fc4df'],
    [0.7764705882352941, '#44c5e0'],
    [0.7803921568627451, '#49c6e0'],
    [0.7843137254901961, '#4ec7e1'],
    [0.788235294117647, '#53c8e1'],
    [0.792156862745098, '#58c9e2'],
    [0.796078431372549, '#5ccae2'],
    [0.8, '#61cbe3'],
    [0.803921568627451, '#65cce3'],
    [0.807843137254902, '#69cde4'],
    [0.8117647058823529, '#6dcee4'],
    [0.8156862745098039, '#71cfe5'],
    [0.8196078431372549, '#75d0e5'],
    [0.8235294117647058, '#79d1e6'],
    [0.8274509803921568, '#7cd2e6'],
    [0.8313725490196078, '#80d3e7'],
    [0.8352941176470589, '#83d4e8'],
    [0.8392156862745098, '#87d5e8'],
    [0.8431372549019608, '#8ad6e9'],
    [0.8470588235294118, '#8ed7e9'],
    [0.8509803921568627, '#91d8ea'],
    [0.8549019607843137, '#94d9ea'],
    [0.8588235294117647, '#98daeb'],
    [0.8627450980392157, '#9bdbeb'],
    [0.8666666666666667, '#9edcec'],
    [0.8705882352941177, '#a1dded'],
    [0.8745098039215686, '#a4deed'],
    [0.8784313725490196, '#a7dfee'],
    [0.8823529411764706, '#aae0ee'],
    [0.8862745098039215, '#ade1ef'],
    [0.8901960784313725, '#b0e2ef'],
    [0.8941176470588235, '#b3e3f0'],
    [0.8980392156862745, '#b6e4f0'],
    [0.9019607843137255, '#b9e5f1'],
    [0.9058823529411765, '#bce6f1'],
    [0.9098039215686274, '#bfe7f2'],
    [0.9137254901960784, '#c2e8f3'],
    [0.9176470588235294, '#c5e9f3'],
    [0.9215686274509803, '#c8eaf4'],
    [0.9254901960784314, '#caebf4'],
    [0.9294117647058824, '#cdecf5'],
    [0.9333333333333333, '#d0edf5'],
    [0.9372549019607843, '#d3eef6'],
    [0.9411764705882353, '#d6eff6'],
    [0.9450980392156862, '#d8f0f7'],
    [0.9490196078431372, '#dbf1f7'],
    [0.9529411764705882, '#def2f8'],
    [0.9568627450980391, '#e1f3f8'],
    [0.9607843137254902, '#e3f4f9'],
    [0.9647058823529412, '#e6f5fa'],
    [0.9686274509803922, '#e9f6fa'],
    [0.9725490196078431, '#ebf7fa'],
    [0.9764705882352941, '#eef8fb'],
    [0.9803921568627451, '#f0f9fb'],
    [0.984313725490196, '#f2f9fb'],
    [0.9882352941176471, '#f4fafb'],
    [0.9921568627450981, '#f6fafb'],
    [0.996078431372549, '#f8fafb'],
    [1.0, '#f9fafa'],
  ],
  CET_CBTD1: [
    [0.0, '#29c9e6'],
    [0.00392156862745098, '#2dc9e7'],
    [0.00784313725490196, '#31cae7'],
    [0.011764705882352941, '#35cae7'],
    [0.01568627450980392, '#39cbe7'],
    [0.0196078431372549, '#3ccbe7'],
    [0.023529411764705882, '#3fcbe7'],
    [0.027450980392156862, '#42cce7'],
    [0.03137254901960784, '#45cce8'],
    [0.03529411764705882, '#48cde8'],
    [0.0392156862745098, '#4acde8'],
    [0.043137254901960784, '#4dcde8'],
    [0.047058823529411764, '#50cee8'],
    [0.050980392156862744, '#52cee8'],
    [0.054901960784313725, '#54cee8'],
    [0.058823529411764705, '#57cfe9'],
    [0.06274509803921569, '#59cfe9'],
    [0.06666666666666667, '#5bd0e9'],
    [0.07058823529411765, '#5dd0e9'],
    [0.07450980392156863, '#5fd0e9'],
    [0.0784313725490196, '#61d1e9'],
    [0.08235294117647059, '#63d1e9'],
    [0.08627450980392157, '#65d1ea'],
    [0.09019607843137255, '#67d2ea'],
    [0.09411764705882353, '#69d2ea'],
    [0.09803921568627451, '#6bd3ea'],
    [0.10196078431372549, '#6dd3ea'],
    [0.10588235294117647, '#6fd3ea'],
    [0.10980392156862745, '#71d4ea'],
    [0.11372549019607843, '#73d4eb'],
    [0.11764705882352941, '#74d4eb'],
    [0.12156862745098039, '#76d5eb'],
    [0.12549019607843137, '#78d5eb'],
    [0.12941176470588234, '#7ad6eb'],
    [0.13333333333333333, '#7bd6eb'],
    [0.13725490196078433, '#7dd6eb'],
    [0.1411764705882353, '#7fd7ec'],
    [0.14509803921568626, '#80d7ec'],
    [0.14901960784313725, '#82d7ec'],
    [0.15294117647058825, '#84d8ec'],
    [0.1568627450980392, '#85d8ec'],
    [0.16078431372549018, '#87d9ec'],
    [0.16470588235294117, '#88d9ec'],
    [0.16862745098039217, '#8ad9ed'],
    [0.17254901960784313, '#8bdaed'],
    [0.1764705882352941, '#8ddaed'],
    [0.1803921568627451, '#8edaed'],
    [0.1843137254901961, '#90dbed'],
    [0.18823529411764706, '#91dbed'],
    [0.19215686274509802, '#93dced'],
    [0.19607843137254902, '#94dcee'],
    [0.2, '#96dcee'],
    [0.20392156862745098, '#97ddee'],
    [0.20784313725490194, '#99ddee'],
    [0.21176470588235294, '#9addee'],
    [0.21568627450980393, '#9cdeee'],
    [0.2196078431372549, '#9ddeee'],
    [0.22352941176470587, '#9fdfef'],
    [0.22745098039215686, '#a0dfef'],
    [0.23137254901960785, '#a1dfef'],
    [0.23529411764705882, '#a3e0ef'],
    [0.2392156862745098, '#a4e0ef'],
    [0.24313725490196078, '#a6e0ef'],
    [0.24705882352941178, '#a7e1ef'],
    [0.25098039215686274, '#a8e1f0'],
    [0.2549019607843137, '#aae2f0'],
    [0.2588235294117647, '#abe2f0'],
    [0.2627450980392157, '#ace2f0'],
    [0.26666666666666666, '#aee3f0'],
    [0.27058823529411763, '#afe3f0'],
    [0.27450980392156865, '#b0e3f0'],
    [0.2784313725490196, '#b2e4f1'],
    [0.2823529411764706, '#b3e4f1'],
    [0.28627450980392155, '#b4e4f1'],
    [0.2901960784313725, '#b6e5f1'],
    [0.29411764705882354, '#b7e5f1'],
    [0.2980392156862745, '#b8e6f1'],
    [0.30196078431372547, '#bae6f1'],
    [0.3058823529411765, '#bbe6f2'],
    [0.30980392156862746, '#bce7f2'],
    [0.3137254901960784, '#bee7f2'],
    [0.3176470588235294, '#bfe7f2'],
    [0.32156862745098036, '#c0e8f2'],
    [0.3254901960784314, '#c1e8f2'],
    [0.32941176470588235, '#c3e9f2'],
    [0.3333333333333333, '#c4e9f3'],
    [0.33725490196078434, '#c5e9f3'],
    [0.3411764705882353, '#c7eaf3'],
    [0.34509803921568627, '#c8eaf3'],
    [0.34901960784313724, '#c9eaf3'],
    [0.3529411764705882, '#caebf3'],
    [0.3568627450980392, '#ccebf3'],
    [0.3607843137254902, '#cdebf4'],
    [0.36470588235294116, '#ceecf4'],
    [0.3686274509803922, '#cfecf4'],
    [0.37254901960784315, '#d1edf4'],
    [0.3764705882352941, '#d2edf4'],
    [0.3803921568627451, '#d3edf4'],
    [0.38431372549019605, '#d4eef4'],
    [0.38823529411764707, '#d6eef5'],
    [0.39215686274509803, '#d7eef5'],
    [0.396078431372549, '#d8eff5'],
    [0.4, '#d9eff5'],
    [0.403921568627451, '#daf0f5'],
    [0.40784313725490196, '#dcf0f5'],
    [0.4117647058823529, '#ddf0f5'],
    [0.4156862745098039, '#def1f6'],
    [0.4196078431372549, '#dff1f6'],
    [0.4235294117647059, '#e0f1f6'],
    [0.42745098039215684, '#e2f2f6'],
    [0.43137254901960786, '#e3f2f6'],
    [0.43529411764705883, '#e4f2f6'],
    [0.4392156862745098, '#e5f3f6'],
    [0.44313725490196076, '#e6f3f7'],
    [0.44705882352941173, '#e8f4f7'],
    [0.45098039215686275, '#e9f4f7'],
    [0.4549019607843137, '#eaf4f7'],
    [0.4588235294117647, '#ebf5f7'],
    [0.4627450980392157, '#ecf5f7'],
    [0.4666666666666667, '#eef5f7'],
    [0.47058823529411764, '#eff6f7'],
    [0.4745098039215686, '#f0f6f7'],
    [0.4784313725490196, '#f1f6f7'],
    [0.4823529411764706, '#f2f6f7'],
    [0.48627450980392156, '#f3f6f7'],
    [0.49019607843137253, '#f4f6f7'],
    [0.49411764705882355, '#f5f6f7'],
    [0.4980392156862745, '#f6f6f7'],
    [0.5019607843137255, '#f7f6f6'],
    [0.5058823529411764, '#f7f6f6'],
    [0.5098039215686274, '#f8f5f5'],
    [0.5137254901960784, '#f8f5f5'],
    [0.5176470588235293, '#f9f5f4'],
    [0.5215686274509804, '#f9f4f3'],
    [0.5254901960784314, '#f9f3f3'],
    [0.5294117647058824, '#faf3f2'],
    [0.5333333333333333, '#faf2f1'],
    [0.5372549019607843, '#faf2f0'],
    [0.5411764705882353, '#faf1ef'],
    [0.5450980392156862, '#faf0ef'],
    [0.5490196078431373, '#fbf0ee'],
    [0.5529411764705883, '#fbefed'],
    [0.5568627450980392, '#fbeeec'],
    [0.5607843137254902, '#fbedeb'],
    [0.5647058823529412, '#fbedeb'],
    [0.5686274509803921, '#fbecea'],
    [0.5725490196078431, '#fbebe9'],
    [0.5764705882352941, '#fbebe8'],
    [0.580392156862745, '#fceae7'],
    [0.5843137254901961, '#fce9e6'],
    [0.5882352941176471, '#fce9e6'],
    [0.592156862745098, '#fce8e5'],
    [0.596078431372549, '#fce7e4'],
    [0.6, '#fce7e3'],
    [0.6039215686274509, '#fce6e2'],
    [0.6078431372549019, '#fce5e2'],
    [0.611764705882353, '#fce5e1'],
    [0.615686274509804, '#fce4e0'],
    [0.6196078431372549, '#fde3df'],
    [0.6235294117647059, '#fde2de'],
    [0.6274509803921569, '#fde2de'],
    [0.6313725490196078, '#fde1dd'],
    [0.6352941176470588, '#fde0dc'],
    [0.6392156862745098, '#fde0db'],
    [0.6431372549019607, '#fddfda'],
    [0.6470588235294118, '#fddeda'],
    [0.6509803921568628, '#fdded9'],
    [0.6549019607843137, '#fdddd8'],
    [0.6588235294117647, '#fddcd7'],
    [0.6627450980392157, '#fddcd6'],
    [0.6666666666666666, '#fddbd6'],
    [0.6705882352941176, '#fedad5'],
    [0.6745098039215687, '#fedad4'],
    [0.6784313725490196, '#fed9d3'],
    [0.6823529411764706, '#fed8d2'],
    [0.6862745098039216, '#fed7d2'],
    [0.6901960784313725, '#fed7d1'],
    [0.6941176470588235, '#fed6d0'],
    [0.6980392156862745, '#fed5cf'],
    [0.7019607843137254, '#fed5ce'],
    [0.7058823529411764, '#fed4ce'],
    [0.7098039215686275, '#fed3cd'],
    [0.7137254901960784, '#fed3cc'],
    [0.7176470588235294, '#fed2cb'],
    [0.7215686274509804, '#fed1ca'],
    [0.7254901960784313, '#fed1ca'],
    [0.7294117647058823, '#fed0c9'],
    [0.7333333333333333, '#fecfc8'],
    [0.7372549019607844, '#fecfc7'],
    [0.7411764705882353, '#fecec6'],
    [0.7450980392156863, '#fecdc6'],
    [0.7490196078431373, '#feccc5'],
    [0.7529411764705882, '#feccc4'],
    [0.7568627450980392, '#fecbc3'],
    [0.7607843137254902, '#fecac3'],
    [0.7647058823529411, '#fecac2'],
    [0.7686274509803921, '#fec9c1'],
    [0.7725490196078432, '#fec8c0'],
    [0.7764705882352941, '#fec8bf'],
    [0.7803921568627451, '#ffc7bf'],
    [0.7843137254901961, '#ffc6be'],
    [0.788235294117647, '#ffc6bd'],
    [0.792156862745098, '#ffc5bc'],
    [0.796078431372549, '#ffc4bb'],
    [0.8, '#ffc3bb'],
    [0.803921568627451, '#ffc3ba'],
    [0.807843137254902, '#ffc2b9'],
    [0.8117647058823529, '#ffc1b8'],
    [0.8156862745098039, '#ffc1b8'],
    [0.8196078431372549, '#ffc0b7'],
    [0.8235294117647058, '#febfb6'],
    [0.8274509803921568, '#febfb5'],
    [0.8313725490196078, '#febeb4'],
    [0.8352941176470589, '#febdb4'],
    [0.8392156862745098, '#febdb3'],
    [0.8431372549019608, '#febcb2'],
    [0.8470588235294118, '#febbb1'],
    [0.8509803921568627, '#febab1'],
    [0.8549019607843137, '#febab0'],
    [0.8588235294117647, '#feb9af'],
    [0.8627450980392157, '#feb8ae'],
    [0.8666666666666667, '#feb8ad'],
    [0.8705882352941177, '#feb7ad'],
    [0.8745098039215686, '#feb6ac'],
    [0.8784313725490196, '#feb6ab'],
    [0.8823529411764706, '#feb5aa'],
    [0.8862745098039215, '#feb4aa'],
    [0.8901960784313725, '#feb4a9'],
    [0.8941176470588235, '#feb3a8'],
    [0.8980392156862745, '#feb2a7'],
    [0.9019607843137255, '#feb1a7'],
    [0.9058823529411765, '#feb1a6'],
    [0.9098039215686274, '#feb0a5'],
    [0.9137254901960784, '#feafa4'],
    [0.9176470588235294, '#feafa3'],
    [0.9215686274509803, '#feaea3'],
    [0.9254901960784314, '#feada2'],
    [0.9294117647058824, '#feada1'],
    [0.9333333333333333, '#feaca0'],
    [0.9372549019607843, '#fdaba0'],
    [0.9411764705882353, '#fdaa9f'],
    [0.9450980392156862, '#fdaa9e'],
    [0.9490196078431372, '#fda99d'],
    [0.9529411764705882, '#fda89d'],
    [0.9568627450980391, '#fda89c'],
    [0.9607843137254902, '#fda79b'],
    [0.9647058823529412, '#fda69a'],
    [0.9686274509803922, '#fda69a'],
    [0.9725490196078431, '#fda599'],
    [0.9764705882352941, '#fda498'],
    [0.9803921568627451, '#fda397'],
    [0.984313725490196, '#fda397'],
    [0.9882352941176471, '#fda296'],
    [0.9921568627450981, '#fda195'],
    [0.996078431372549, '#fca194'],
    [1.0, '#fca094'],
  ],
  CET_CBTL1: [
    [0.0, '#101010'],
    [0.00392156862745098, '#131110'],
    [0.00784313725490196, '#161110'],
    [0.011764705882352941, '#191110'],
    [0.01568627450980392, '#1b1210'],
    [0.0196078431372549, '#1d1210'],
    [0.023529411764705882, '#1f120f'],
    [0.027450980392156862, '#21120f'],
    [0.03137254901960784, '#23130f'],
    [0.03529411764705882, '#25130f'],
    [0.0392156862745098, '#28130f'],
    [0.043137254901960784, '#2a130f'],
    [0.047058823529411764, '#2c130f'],
    [0.050980392156862744, '#2e130e'],
    [0.054901960784313725, '#30130e'],
    [0.058823529411764705, '#32130e'],
    [0.06274509803921569, '#34120e'],
    [0.06666666666666667, '#36120e'],
    [0.07058823529411765, '#38120e'],
    [0.07450980392156863, '#3a120d'],
    [0.0784313725490196, '#3c120d'],
    [0.08235294117647059, '#3e110d'],
    [0.08627450980392157, '#40110d'],
    [0.09019607843137255, '#42110d'],
    [0.09411764705882353, '#44100d'],
    [0.09803921568627451, '#46100c'],
    [0.10196078431372549, '#48100c'],
    [0.10588235294117647, '#4a0f0c'],
    [0.10980392156862745, '#4c0f0c'],
    [0.11372549019607843, '#4e0e0c'],
    [0.11764705882352941, '#510d0c'],
    [0.12156862745098039, '#530d0c'],
    [0.12549019607843137, '#550c0c'],
    [0.12941176470588234, '#560b0c'],
    [0.13333333333333333, '#580a0b'],
    [0.13725490196078433, '#5a0a0b'],
    [0.1411764705882353, '#5c090b'],
    [0.14509803921568626, '#5e080c'],
    [0.14901960784313725, '#60070c'],
    [0.15294117647058825, '#62060c'],
    [0.1568627450980392, '#64060c'],
    [0.16078431372549018, '#66050c'],
    [0.16470588235294117, '#67040c'],
    [0.16862745098039217, '#69040c'],
    [0.17254901960784313, '#6b030d'],
    [0.1764705882352941, '#6d030d'],
    [0.1803921568627451, '#6e020d'],
    [0.1843137254901961, '#70020d'],
    [0.18823529411764706, '#72010e'],
    [0.19215686274509802, '#73010e'],
    [0.19607843137254902, '#75010e'],
    [0.2, '#76010f'],
    [0.20392156862745098, '#78010f'],
    [0.20784313725490194, '#7a000f'],
    [0.21176470588235294, '#7b0010'],
    [0.21568627450980393, '#7d0010'],
    [0.2196078431372549, '#7e0011'],
    [0.22352941176470587, '#800111'],
    [0.22745098039215686, '#810111'],
    [0.23137254901960785, '#830112'],
    [0.23529411764705882, '#840112'],
    [0.2392156862745098, '#860112'],
    [0.24313725490196078, '#870113'],
    [0.24705882352941178, '#890113'],
    [0.25098039215686274, '#8b0213'],
    [0.2549019607843137, '#8c0214'],
    [0.2588235294117647, '#8e0214'],
    [0.2627450980392157, '#8f0215'],
    [0.26666666666666666, '#910215'],
    [0.27058823529411763, '#920315'],
    [0.27450980392156865, '#940316'],
    [0.2784313725490196, '#950316'],
    [0.2823529411764706, '#970317'],
    [0.28627450980392155, '#980417'],
    [0.2901960784313725, '#9a0417'],
    [0.29411764705882354, '#9c0418'],
    [0.2980392156862745, '#9d0418'],
    [0.30196078431372547, '#9f0518'],
    [0.3058823529411765, '#a00519'],
    [0.30980392156862746, '#a20519'],
    [0.3137254901960784, '#a3051a'],
    [0.3176470588235294, '#a5061a'],
    [0.32156862745098036, '#a7061a'],
    [0.3254901960784314, '#a8061b'],
    [0.32941176470588235, '#aa071b'],
    [0.3333333333333333, '#ab071c'],
    [0.33725490196078434, '#ad071c'],
    [0.3411764705882353, '#af081c'],
    [0.34509803921568627, '#b0081d'],
    [0.34901960784313724, '#b2091d'],
    [0.3529411764705882, '#b3091e'],
    [0.3568627450980392, '#b5091e'],
    [0.3607843137254902, '#b70a1e'],
    [0.36470588235294116, '#b80a1f'],
    [0.3686274509803922, '#ba0a1f'],
    [0.37254901960784315, '#bb0b20'],
    [0.3764705882352941, '#bd0b20'],
    [0.3803921568627451, '#bf0c20'],
    [0.38431372549019605, '#c00c21'],
    [0.38823529411764707, '#c20c21'],
    [0.39215686274509803, '#c40d22'],
    [0.396078431372549, '#c50d22'],
    [0.4, '#c70d23'],
    [0.403921568627451, '#c90e23'],
    [0.40784313725490196, '#ca0e23'],
    [0.4117647058823529, '#cc0f24'],
    [0.4156862745098039, '#cd0f24'],
    [0.4196078431372549, '#cf0f25'],
    [0.4235294117647059, '#d11025'],
    [0.42745098039215684, '#d21025'],
    [0.43137254901960786, '#d41026'],
    [0.43529411764705883, '#d61126'],
    [0.4392156862745098, '#d71127'],
    [0.44313725490196076, '#d91227'],
    [0.44705882352941173, '#db1227'],
    [0.45098039215686275, '#dc1228'],
    [0.4549019607843137, '#de1328'],
    [0.4588235294117647, '#e01329'],
    [0.4627450980392157, '#e11429'],
    [0.4666666666666667, '#e3142a'],
    [0.47058823529411764, '#e5152a'],
    [0.4745098039215686, '#e6152b'],
    [0.4784313725490196, '#e8162b'],
    [0.4823529411764706, '#ea162c'],
    [0.48627450980392156, '#eb172c'],
    [0.49019607843137253, '#ed182d'],
    [0.49411764705882355, '#ee192d'],
    [0.4980392156862745, '#f01a2e'],
    [0.5019607843137255, '#f11c2e'],
    [0.5058823529411764, '#f31d2f'],
    [0.5098039215686274, '#f41f30'],
    [0.5137254901960784, '#f52131'],
    [0.5176470588235293, '#f62332'],
    [0.5215686274509804, '#f72533'],
    [0.5254901960784314, '#f82734'],
    [0.5294117647058824, '#f92a35'],
    [0.5333333333333333, '#fa2c36'],
    [0.5372549019607843, '#fb2f38'],
    [0.5411764705882353, '#fb3239'],
    [0.5450980392156862, '#fb353b'],
    [0.5490196078431373, '#fc393d'],
    [0.5529411764705883, '#fc3c3e'],
    [0.5568627450980392, '#fb3f40'],
    [0.5607843137254902, '#fb4343'],
    [0.5647058823529412, '#fb4645'],
    [0.5686274509803921, '#fa4a47'],
    [0.5725490196078431, '#fa4d4a'],
    [0.5764705882352941, '#f9514c'],
    [0.580392156862745, '#f8544f'],
    [0.5843137254901961, '#f75752'],
    [0.5882352941176471, '#f65b54'],
    [0.592156862745098, '#f45e57'],
    [0.596078431372549, '#f3625a'],
    [0.6, '#f1655d'],
    [0.6039215686274509, '#f06860'],
    [0.6078431372549019, '#ee6b63'],
    [0.611764705882353, '#ec6e67'],
    [0.615686274509804, '#ea716a'],
    [0.6196078431372549, '#e9746d'],
    [0.6235294117647059, '#e77770'],
    [0.6274509803921569, '#e47a73'],
    [0.6313725490196078, '#e27d77'],
    [0.6352941176470588, '#e0807a'],
    [0.6392156862745098, '#de837d'],
    [0.6431372549019607, '#db8580'],
    [0.6470588235294118, '#d98884'],
    [0.6509803921568628, '#d78b87'],
    [0.6549019607843137, '#d48d8a'],
    [0.6588235294117647, '#d1908e'],
    [0.6627450980392157, '#cf9291'],
    [0.6666666666666666, '#cc9595'],
    [0.6705882352941176, '#c99798'],
    [0.6745098039215687, '#c69a9b'],
    [0.6784313725490196, '#c39c9f'],
    [0.6823529411764706, '#bf9ea2'],
    [0.6862745098039216, '#bca1a6'],
    [0.6901960784313725, '#b8a3a9'],
    [0.6941176470588235, '#b5a5ac'],
    [0.6980392156862745, '#b1a8b0'],
    [0.7019607843137254, '#adaab3'],
    [0.7058823529411764, '#a9acb7'],
    [0.7098039215686275, '#a5aeba'],
    [0.7137254901960784, '#a0b1be'],
    [0.7176470588235294, '#9bb3c1'],
    [0.7215686274509804, '#97b5c4'],
    [0.7254901960784313, '#92b7c8'],
    [0.7294117647058823, '#8cb9cb'],
    [0.7333333333333333, '#87bbce'],
    [0.7372549019607844, '#81bdd2'],
    [0.7411764705882353, '#7bbfd5'],
    [0.7450980392156863, '#75c1d8'],
    [0.7490196078431373, '#6fc3db'],
    [0.7529411764705882, '#68c5de'],
    [0.7568627450980392, '#62c7e1'],
    [0.7607843137254902, '#5bc8e4'],
    [0.7647058823529411, '#54cae6'],
    [0.7686274509803921, '#4ccce9'],
    [0.7725490196078432, '#45cdeb'],
    [0.7764705882352941, '#3ecfed'],
    [0.7803921568627451, '#37d0ef'],
    [0.7843137254901961, '#30d1f1'],
    [0.788235294117647, '#2ad3f3'],
    [0.792156862745098, '#26d4f4'],
    [0.796078431372549, '#22d5f6'],
    [0.8, '#21d6f7'],
    [0.803921568627451, '#22d8f8'],
    [0.807843137254902, '#25d9f9'],
    [0.8117647058823529, '#29dafa'],
    [0.8156862745098039, '#2edafa'],
    [0.8196078431372549, '#34dbfb'],
    [0.8235294117647058, '#3bdcfb'],
    [0.8274509803921568, '#41ddfc'],
    [0.8313725490196078, '#47defc'],
    [0.8352941176470589, '#4edffc'],
    [0.8392156862745098, '#54dffd'],
    [0.8431372549019608, '#5ae0fd'],
    [0.8470588235294118, '#60e1fd'],
    [0.8509803921568627, '#65e2fd'],
    [0.8549019607843137, '#6be2fd'],
    [0.8588235294117647, '#70e3fd'],
    [0.8627450980392157, '#75e4fd'],
    [0.8666666666666667, '#7be4fd'],
    [0.8705882352941177, '#7fe5fc'],
    [0.8745098039215686, '#84e6fc'],
    [0.8784313725490196, '#89e6fc'],
    [0.8823529411764706, '#8de7fc'],
    [0.8862745098039215, '#92e7fc'],
    [0.8901960784313725, '#96e8fc'],
    [0.8941176470588235, '#9ae9fc'],
    [0.8980392156862745, '#9fe9fc'],
    [0.9019607843137255, '#a3eafc'],
    [0.9058823529411765, '#a7ebfc'],
    [0.9098039215686274, '#abebfb'],
    [0.9137254901960784, '#aeecfb'],
    [0.9176470588235294, '#b2ecfb'],
    [0.9215686274509803, '#b6edfb'],
    [0.9254901960784314, '#baeefb'],
    [0.9294117647058824, '#bdeefb'],
    [0.9333333333333333, '#c1effb'],
    [0.9372549019607843, '#c4effb'],
    [0.9411764705882353, '#c8f0fb'],
    [0.9450980392156862, '#cbf1fa'],
    [0.9490196078431372, '#cff1fa'],
    [0.9529411764705882, '#d2f2fa'],
    [0.9568627450980391, '#d5f2fa'],
    [0.9607843137254902, '#d9f3fa'],
    [0.9647058823529412, '#dcf4fa'],
    [0.9686274509803922, '#dff4fa'],
    [0.9725490196078431, '#e3f5fa'],
    [0.9764705882352941, '#e6f5f9'],
    [0.9803921568627451, '#e9f6f9'],
    [0.984313725490196, '#ecf6f9'],
    [0.9882352941176471, '#eff7f9'],
    [0.9921568627450981, '#f2f8f9'],
    [0.996078431372549, '#f6f8f9'],
    [1.0, '#f9f9f9'],
  ],
  CET_CBTL2: [
    [0.0, '#101010'],
    [0.00392156862745098, '#131110'],
    [0.00784313725490196, '#161110'],
    [0.011764705882352941, '#181210'],
    [0.01568627450980392, '#1a1210'],
    [0.0196078431372549, '#1c1210'],
    [0.023529411764705882, '#1d1310'],
    [0.027450980392156862, '#1f1311'],
    [0.03137254901960784, '#211311'],
    [0.03529411764705882, '#231411'],
    [0.0392156862745098, '#251411'],
    [0.043137254901960784, '#261411'],
    [0.047058823529411764, '#281411'],
    [0.050980392156862744, '#2a1411'],
    [0.054901960784313725, '#2c1511'],
    [0.058823529411764705, '#2d1511'],
    [0.06274509803921569, '#2f1511'],
    [0.06666666666666667, '#311512'],
    [0.07058823529411765, '#321512'],
    [0.07450980392156863, '#341612'],
    [0.0784313725490196, '#361612'],
    [0.08235294117647059, '#371612'],
    [0.08627450980392157, '#391612'],
    [0.09019607843137255, '#3a1613'],
    [0.09411764705882353, '#3c1713'],
    [0.09803921568627451, '#3e1713'],
    [0.10196078431372549, '#3f1713'],
    [0.10588235294117647, '#411713'],
    [0.10980392156862745, '#421714'],
    [0.11372549019607843, '#441814'],
    [0.11764705882352941, '#451814'],
    [0.12156862745098039, '#471814'],
    [0.12549019607843137, '#491815'],
    [0.12941176470588234, '#4a1915'],
    [0.13333333333333333, '#4c1915'],
    [0.13725490196078433, '#4d1915'],
    [0.1411764705882353, '#4f1916'],
    [0.14509803921568626, '#501a16'],
    [0.14901960784313725, '#521a16'],
    [0.15294117647058825, '#531a16'],
    [0.1568627450980392, '#541b17'],
    [0.16078431372549018, '#561b17'],
    [0.16470588235294117, '#571b17'],
    [0.16862745098039217, '#591c18'],
    [0.17254901960784313, '#5a1c18'],
    [0.1764705882352941, '#5b1c19'],
    [0.1803921568627451, '#5d1d19'],
    [0.1843137254901961, '#5e1d19'],
    [0.18823529411764706, '#5f1e1a'],
    [0.19215686274509802, '#611e1a'],
    [0.19607843137254902, '#621f1b'],
    [0.2, '#631f1b'],
    [0.20392156862745098, '#65201c'],
    [0.20784313725490194, '#66201c'],
    [0.21176470588235294, '#67211d'],
    [0.21568627450980393, '#68211d'],
    [0.2196078431372549, '#6a221e'],
    [0.22352941176470587, '#6b231e'],
    [0.22745098039215686, '#6c231f'],
    [0.23137254901960785, '#6d241f'],
    [0.23529411764705882, '#6e2520'],
    [0.2392156862745098, '#6f2521'],
    [0.24313725490196078, '#702621'],
    [0.24705882352941178, '#722722'],
    [0.25098039215686274, '#732823'],
    [0.2549019607843137, '#742923'],
    [0.2588235294117647, '#752924'],
    [0.2627450980392157, '#762a25'],
    [0.26666666666666666, '#762b26'],
    [0.27058823529411763, '#772c26'],
    [0.27450980392156865, '#782d27'],
    [0.2784313725490196, '#792e28'],
    [0.2823529411764706, '#7a2f29'],
    [0.28627450980392155, '#7b302a'],
    [0.2901960784313725, '#7c312b'],
    [0.29411764705882354, '#7c322c'],
    [0.2980392156862745, '#7d332d'],
    [0.30196078431372547, '#7e342e'],
    [0.3058823529411765, '#7e352f'],
    [0.30980392156862746, '#7f3630'],
    [0.3137254901960784, '#7f3831'],
    [0.3176470588235294, '#803932'],
    [0.32156862745098036, '#803a33'],
    [0.3254901960784314, '#813b34'],
    [0.32941176470588235, '#813c35'],
    [0.3333333333333333, '#823e36'],
    [0.33725490196078434, '#823f38'],
    [0.3411764705882353, '#824039'],
    [0.34509803921568627, '#82423a'],
    [0.34901960784313724, '#83433c'],
    [0.3529411764705882, '#83443d'],
    [0.3568627450980392, '#83463e'],
    [0.3607843137254902, '#834740'],
    [0.36470588235294116, '#834841'],
    [0.3686274509803922, '#834a43'],
    [0.37254901960784315, '#824b44'],
    [0.3764705882352941, '#824d46'],
    [0.3803921568627451, '#824e47'],
    [0.38431372549019605, '#824f49'],
    [0.38823529411764707, '#82514a'],
    [0.39215686274509803, '#82524c'],
    [0.396078431372549, '#82544d'],
    [0.4, '#81554f'],
    [0.403921568627451, '#815650'],
    [0.40784313725490196, '#815852'],
    [0.4117647058823529, '#815953'],
    [0.4156862745098039, '#805a55'],
    [0.4196078431372549, '#805c57'],
    [0.4235294117647059, '#805d58'],
    [0.42745098039215684, '#805e5a'],
    [0.43137254901960786, '#7f605b'],
    [0.43529411764705883, '#7f615d'],
    [0.4392156862745098, '#7f625e'],
    [0.44313725490196076, '#7e6360'],
    [0.44705882352941173, '#7e6562'],
    [0.45098039215686275, '#7e6663'],
    [0.4549019607843137, '#7d6765'],
    [0.4588235294117647, '#7d6967'],
    [0.4627450980392157, '#7c6a68'],
    [0.4666666666666667, '#7c6b6a'],
    [0.47058823529411764, '#7b6d6b'],
    [0.4745098039215686, '#7b6e6d'],
    [0.4784313725490196, '#7a6f6f'],
    [0.4823529411764706, '#7a7070'],
    [0.48627450980392156, '#797272'],
    [0.49019607843137253, '#797374'],
    [0.49411764705882355, '#787475'],
    [0.4980392156862745, '#777577'],
    [0.5019607843137255, '#777779'],
    [0.5058823529411764, '#76787a'],
    [0.5098039215686274, '#75797c'],
    [0.5137254901960784, '#747b7e'],
    [0.5176470588235293, '#747c80'],
    [0.5215686274509804, '#737d81'],
    [0.5254901960784314, '#727e83'],
    [0.5294117647058824, '#718085'],
    [0.5333333333333333, '#708187'],
    [0.5372549019607843, '#6f8288'],
    [0.5411764705882353, '#6e838a'],
    [0.5450980392156862, '#6d858c'],
    [0.5490196078431373, '#6c868e'],
    [0.5529411764705883, '#6b878f'],
    [0.5568627450980392, '#6a8991'],
    [0.5607843137254902, '#698a93'],
    [0.5647058823529412, '#678b95'],
    [0.5686274509803921, '#668c97'],
    [0.5725490196078431, '#658e98'],
    [0.5764705882352941, '#638f9a'],
    [0.580392156862745, '#62909c'],
    [0.5843137254901961, '#60919e'],
    [0.5882352941176471, '#5f93a0'],
    [0.592156862745098, '#5d94a1'],
    [0.596078431372549, '#5c95a3'],
    [0.6, '#5a96a5'],
    [0.6039215686274509, '#5898a7'],
    [0.6078431372549019, '#5699a9'],
    [0.611764705882353, '#549aab'],
    [0.615686274509804, '#529bad'],
    [0.6196078431372549, '#4f9dae'],
    [0.6235294117647059, '#4d9eb0'],
    [0.6274509803921569, '#4a9fb2'],
    [0.6313725490196078, '#48a1b4'],
    [0.6352941176470588, '#45a2b6'],
    [0.6392156862745098, '#42a3b8'],
    [0.6431372549019607, '#3ea4ba'],
    [0.6470588235294118, '#3ba6bc'],
    [0.6509803921568628, '#38a7bd'],
    [0.6549019607843137, '#35a8bf'],
    [0.6588235294117647, '#31a9c1'],
    [0.6627450980392157, '#2eaac3'],
    [0.6666666666666666, '#2bacc4'],
    [0.6705882352941176, '#27adc6'],
    [0.6745098039215687, '#24aec7'],
    [0.6784313725490196, '#20afc9'],
    [0.6823529411764706, '#1db0ca'],
    [0.6862745098039216, '#1ab1cc'],
    [0.6901960784313725, '#16b2cd'],
    [0.6941176470588235, '#13b3cf'],
    [0.6980392156862745, '#10b5d0'],
    [0.7019607843137254, '#0db6d1'],
    [0.7058823529411764, '#0ab7d2'],
    [0.7098039215686275, '#09b8d4'],
    [0.7137254901960784, '#08b9d5'],
    [0.7176470588235294, '#07bad6'],
    [0.7215686274509804, '#08bbd7'],
    [0.7254901960784313, '#0abcd8'],
    [0.7294117647058823, '#0dbdda'],
    [0.7333333333333333, '#0fbedb'],
    [0.7372549019607844, '#13bfdc'],
    [0.7411764705882353, '#16c0dd'],
    [0.7450980392156863, '#19c1de'],
    [0.7490196078431373, '#1dc2df'],
    [0.7529411764705882, '#20c3df'],
    [0.7568627450980392, '#24c4e0'],
    [0.7607843137254902, '#27c5e1'],
    [0.7647058823529411, '#2bc6e2'],
    [0.7686274509803921, '#2ec6e3'],
    [0.7725490196078432, '#32c7e4'],
    [0.7764705882352941, '#35c8e4'],
    [0.7803921568627451, '#39c9e5'],
    [0.7843137254901961, '#3ccae6'],
    [0.788235294117647, '#40cbe7'],
    [0.792156862745098, '#43cce7'],
    [0.796078431372549, '#47cde8'],
    [0.8, '#4acee9'],
    [0.803921568627451, '#4ecee9'],
    [0.807843137254902, '#51cfea'],
    [0.8117647058823529, '#54d0ea'],
    [0.8156862745098039, '#58d1eb'],
    [0.8196078431372549, '#5bd2eb'],
    [0.8235294117647058, '#5ed3ec'],
    [0.8274509803921568, '#62d3ec'],
    [0.8313725490196078, '#65d4ed'],
    [0.8352941176470589, '#68d5ed'],
    [0.8392156862745098, '#6cd6ee'],
    [0.8431372549019608, '#6fd7ee'],
    [0.8470588235294118, '#72d7ef'],
    [0.8509803921568627, '#76d8ef'],
    [0.8549019607843137, '#79d9ef'],
    [0.8588235294117647, '#7cdaf0'],
    [0.8627450980392157, '#7fdaf0'],
    [0.8666666666666667, '#83dbf0'],
    [0.8705882352941177, '#86dcf0'],
    [0.8745098039215686, '#89ddf1'],
    [0.8784313725490196, '#8dddf1'],
    [0.8823529411764706, '#90def1'],
    [0.8862745098039215, '#93dff1'],
    [0.8901960784313725, '#96dff2'],
    [0.8941176470588235, '#99e0f2'],
    [0.8980392156862745, '#9de1f2'],
    [0.9019607843137255, '#a0e1f2'],
    [0.9058823529411765, '#a3e2f2'],
    [0.9098039215686274, '#a6e3f2'],
    [0.9137254901960784, '#aae3f2'],
    [0.9176470588235294, '#ade4f2'],
    [0.9215686274509803, '#b0e5f2'],
    [0.9254901960784314, '#b3e5f2'],
    [0.9294117647058824, '#b6e6f2'],
    [0.9333333333333333, '#bae7f2'],
    [0.9372549019607843, '#bde7f2'],
    [0.9411764705882353, '#c0e8f2'],
    [0.9450980392156862, '#c3e8f2'],
    [0.9490196078431372, '#c6e9f2'],
    [0.9529411764705882, '#caeaf2'],
    [0.9568627450980391, '#cdeaf2'],
    [0.9607843137254902, '#d0ebf2'],
    [0.9647058823529412, '#d3ebf2'],
    [0.9686274509803922, '#d6ecf2'],
    [0.9725490196078431, '#daecf2'],
    [0.9764705882352941, '#ddedf1'],
    [0.9803921568627451, '#e0eef1'],
    [0.984313725490196, '#e3eef1'],
    [0.9882352941176471, '#e6eff1'],
    [0.9921568627450981, '#eaeff1'],
    [0.996078431372549, '#edf0f0'],
    [1.0, '#f0f0f0'],
  ],
  CET_CBTL3: [
    [0.0, '#101010'],
    [0.00392156862745098, '#111111'],
    [0.00784313725490196, '#111213'],
    [0.011764705882352941, '#121314'],
    [0.01568627450980392, '#121415'],
    [0.0196078431372549, '#131515'],
    [0.023529411764705882, '#131616'],
    [0.027450980392156862, '#141717'],
    [0.03137254901960784, '#141718'],
    [0.03529411764705882, '#141819'],
    [0.0392156862745098, '#15191a'],
    [0.043137254901960784, '#151a1b'],
    [0.047058823529411764, '#151a1c'],
    [0.050980392156862744, '#161b1d'],
    [0.054901960784313725, '#161c1d'],
    [0.058823529411764705, '#161d1e'],
    [0.06274509803921569, '#171d1f'],
    [0.06666666666666667, '#171e20'],
    [0.07058823529411765, '#171f21'],
    [0.07450980392156863, '#172022'],
    [0.0784313725490196, '#182123'],
    [0.08235294117647059, '#182124'],
    [0.08627450980392157, '#182225'],
    [0.09019607843137255, '#192326'],
    [0.09411764705882353, '#192427'],
    [0.09803921568627451, '#192528'],
    [0.10196078431372549, '#192528'],
    [0.10588235294117647, '#1a2629'],
    [0.10980392156862745, '#1a272a'],
    [0.11372549019607843, '#1a282b'],
    [0.11764705882352941, '#1a292c'],
    [0.12156862745098039, '#1b2a2d'],
    [0.12549019607843137, '#1b2a2e'],
    [0.12941176470588234, '#1b2b2f'],
    [0.13333333333333333, '#1c2c30'],
    [0.13725490196078433, '#1c2d31'],
    [0.1411764705882353, '#1c2e32'],
    [0.14509803921568626, '#1c2e33'],
    [0.14901960784313725, '#1c2f34'],
    [0.15294117647058825, '#1d3035'],
    [0.1568627450980392, '#1d3136'],
    [0.16078431372549018, '#1d3237'],
    [0.16470588235294117, '#1d3338'],
    [0.16862745098039217, '#1e3439'],
    [0.17254901960784313, '#1e343a'],
    [0.1764705882352941, '#1e353b'],
    [0.1803921568627451, '#1e363c'],
    [0.1843137254901961, '#1e373d'],
    [0.18823529411764706, '#1f383e'],
    [0.19215686274509802, '#1f393f'],
    [0.19607843137254902, '#1f3940'],
    [0.2, '#1f3a41'],
    [0.20392156862745098, '#1f3b42'],
    [0.20784313725490194, '#203c43'],
    [0.21176470588235294, '#203d44'],
    [0.21568627450980393, '#203e45'],
    [0.2196078431372549, '#203f46'],
    [0.22352941176470587, '#204047'],
    [0.22745098039215686, '#204048'],
    [0.23137254901960785, '#214149'],
    [0.23529411764705882, '#21424a'],
    [0.2392156862745098, '#21434b'],
    [0.24313725490196078, '#21444c'],
    [0.24705882352941178, '#21454d'],
    [0.25098039215686274, '#21464e'],
    [0.2549019607843137, '#21474f'],
    [0.2588235294117647, '#224850'],
    [0.2627450980392157, '#224851'],
    [0.26666666666666666, '#224952'],
    [0.27058823529411763, '#224a53'],
    [0.27450980392156865, '#224b54'],
    [0.2784313725490196, '#224c55'],
    [0.2823529411764706, '#224d57'],
    [0.28627450980392155, '#224e58'],
    [0.2901960784313725, '#234f59'],
    [0.29411764705882354, '#23505a'],
    [0.2980392156862745, '#23515b'],
    [0.30196078431372547, '#23515c'],
    [0.3058823529411765, '#23525d'],
    [0.30980392156862746, '#23535e'],
    [0.3137254901960784, '#23545f'],
    [0.3176470588235294, '#235560'],
    [0.32156862745098036, '#235661'],
    [0.3254901960784314, '#235762'],
    [0.32941176470588235, '#235863'],
    [0.3333333333333333, '#245964'],
    [0.33725490196078434, '#245a65'],
    [0.3411764705882353, '#245b67'],
    [0.34509803921568627, '#245c68'],
    [0.34901960784313724, '#245c69'],
    [0.3529411764705882, '#245d6a'],
    [0.3568627450980392, '#245e6b'],
    [0.3607843137254902, '#245f6c'],
    [0.36470588235294116, '#24606d'],
    [0.3686274509803922, '#24616e'],
    [0.37254901960784315, '#24626f'],
    [0.3764705882352941, '#246370'],
    [0.3803921568627451, '#246471'],
    [0.38431372549019605, '#246573'],
    [0.38823529411764707, '#246674'],
    [0.39215686274509803, '#246775'],
    [0.396078431372549, '#246876'],
    [0.4, '#246977'],
    [0.403921568627451, '#246a78'],
    [0.40784313725490196, '#246b79'],
    [0.4117647058823529, '#246b7a'],
    [0.4156862745098039, '#246c7b'],
    [0.4196078431372549, '#246d7d'],
    [0.4235294117647059, '#246e7e'],
    [0.42745098039215684, '#246f7f'],
    [0.43137254901960786, '#247080'],
    [0.43529411764705883, '#247181'],
    [0.4392156862745098, '#247282'],
    [0.44313725490196076, '#247383'],
    [0.44705882352941173, '#247484'],
    [0.45098039215686275, '#247586'],
    [0.4549019607843137, '#247687'],
    [0.4588235294117647, '#247788'],
    [0.4627450980392157, '#247889'],
    [0.4666666666666667, '#24798a'],
    [0.47058823529411764, '#247a8b'],
    [0.4745098039215686, '#247b8c'],
    [0.4784313725490196, '#247c8e'],
    [0.4823529411764706, '#247d8f'],
    [0.48627450980392156, '#247e90'],
    [0.49019607843137253, '#237f91'],
    [0.49411764705882355, '#238092'],
    [0.4980392156862745, '#238193'],
    [0.5019607843137255, '#238294'],
    [0.5058823529411764, '#238396'],
    [0.5098039215686274, '#238497'],
    [0.5137254901960784, '#238598'],
    [0.5176470588235293, '#238699'],
    [0.5215686274509804, '#23879a'],
    [0.5254901960784314, '#23889b'],
    [0.5294117647058824, '#23899c'],
    [0.5333333333333333, '#228a9e'],
    [0.5372549019607843, '#228b9f'],
    [0.5411764705882353, '#228ca0'],
    [0.5450980392156862, '#228da1'],
    [0.5490196078431373, '#228ea2'],
    [0.5529411764705883, '#228fa3'],
    [0.5568627450980392, '#2290a5'],
    [0.5607843137254902, '#2291a6'],
    [0.5647058823529412, '#2192a7'],
    [0.5686274509803921, '#2193a8'],
    [0.5725490196078431, '#2194a9'],
    [0.5764705882352941, '#2195aa'],
    [0.580392156862745, '#2196ac'],
    [0.5843137254901961, '#2197ad'],
    [0.5882352941176471, '#2198ae'],
    [0.592156862745098, '#2099af'],
    [0.596078431372549, '#209ab0'],
    [0.6, '#209bb1'],
    [0.6039215686274509, '#209cb3'],
    [0.6078431372549019, '#209db4'],
    [0.611764705882353, '#209eb5'],
    [0.615686274509804, '#209fb6'],
    [0.6196078431372549, '#1fa0b7'],
    [0.6235294117647059, '#1fa1b9'],
    [0.6274509803921569, '#1fa2ba'],
    [0.6313725490196078, '#1fa3bb'],
    [0.6352941176470588, '#1fa4bc'],
    [0.6392156862745098, '#1fa5bd'],
    [0.6431372549019607, '#1ea6be'],
    [0.6470588235294118, '#1ea7c0'],
    [0.6509803921568628, '#1ea8c1'],
    [0.6549019607843137, '#1ea9c2'],
    [0.6588235294117647, '#1eaac3'],
    [0.6627450980392157, '#1eabc4'],
    [0.6666666666666666, '#1eacc6'],
    [0.6705882352941176, '#1eadc7'],
    [0.6745098039215687, '#1daec8'],
    [0.6784313725490196, '#1dafc9'],
    [0.6823529411764706, '#1db0ca'],
    [0.6862745098039216, '#1db1cb'],
    [0.6901960784313725, '#1db2cd'],
    [0.6941176470588235, '#1db3ce'],
    [0.6980392156862745, '#1db4cf'],
    [0.7019607843137254, '#1db5d0'],
    [0.7058823529411764, '#1db6d1'],
    [0.7098039215686275, '#1db7d3'],
    [0.7137254901960784, '#1db8d4'],
    [0.7176470588235294, '#1db9d5'],
    [0.7215686274509804, '#1dbad6'],
    [0.7254901960784313, '#1dbbd7'],
    [0.7294117647058823, '#1ebcd9'],
    [0.7333333333333333, '#1ebeda'],
    [0.7372549019607844, '#1ebfdb'],
    [0.7411764705882353, '#1ec0dc'],
    [0.7450980392156863, '#1ec1dd'],
    [0.7490196078431373, '#1fc2de'],
    [0.7529411764705882, '#1fc3e0'],
    [0.7568627450980392, '#1fc4e1'],
    [0.7607843137254902, '#20c5e2'],
    [0.7647058823529411, '#20c6e3'],
    [0.7686274509803921, '#21c7e4'],
    [0.7725490196078432, '#22c8e5'],
    [0.7764705882352941, '#22c9e7'],
    [0.7803921568627451, '#23cae8'],
    [0.7843137254901961, '#24cbe9'],
    [0.788235294117647, '#25ccea'],
    [0.792156862745098, '#26cdeb'],
    [0.796078431372549, '#27ceec'],
    [0.8, '#29cfed'],
    [0.803921568627451, '#2bd0ee'],
    [0.807843137254902, '#2dd1ef'],
    [0.8117647058823529, '#2fd2f0'],
    [0.8156862745098039, '#32d3f1'],
    [0.8196078431372549, '#35d4f2'],
    [0.8235294117647058, '#39d5f3'],
    [0.8274509803921568, '#3cd6f4'],
    [0.8313725490196078, '#40d7f4'],
    [0.8352941176470589, '#44d8f5'],
    [0.8392156862745098, '#48d8f6'],
    [0.8431372549019608, '#4cd9f6'],
    [0.8470588235294118, '#50daf7'],
    [0.8509803921568627, '#54dbf7'],
    [0.8549019607843137, '#58dcf8'],
    [0.8588235294117647, '#5cddf8'],
    [0.8627450980392157, '#60ddf8'],
    [0.8666666666666667, '#64def9'],
    [0.8705882352941177, '#69dff9'],
    [0.8745098039215686, '#6de0f9'],
    [0.8784313725490196, '#71e0f9'],
    [0.8823529411764706, '#75e1fa'],
    [0.8862745098039215, '#79e2fa'],
    [0.8901960784313725, '#7de2fa'],
    [0.8941176470588235, '#82e3fa'],
    [0.8980392156862745, '#86e4fa'],
    [0.9019607843137255, '#8ae4fa'],
    [0.9058823529411765, '#8ee5fa'],
    [0.9098039215686274, '#92e6fa'],
    [0.9137254901960784, '#96e6fa'],
    [0.9176470588235294, '#9be7fa'],
    [0.9215686274509803, '#9fe7f9'],
    [0.9254901960784314, '#a3e8f9'],
    [0.9294117647058824, '#a7e9f9'],
    [0.9333333333333333, '#abe9f9'],
    [0.9372549019607843, '#afeaf9'],
    [0.9411764705882353, '#b3eaf8'],
    [0.9450980392156862, '#b7ebf8'],
    [0.9490196078431372, '#bbebf7'],
    [0.9529411764705882, '#bfecf7'],
    [0.9568627450980391, '#c4ecf7'],
    [0.9607843137254902, '#c8ecf6'],
    [0.9647058823529412, '#ccedf6'],
    [0.9686274509803922, '#d0edf5'],
    [0.9725490196078431, '#d4eef5'],
    [0.9764705882352941, '#d8eef4'],
    [0.9803921568627451, '#dceef3'],
    [0.984313725490196, '#e0eff3'],
    [0.9882352941176471, '#e4eff2'],
    [0.9921568627450981, '#e8eff2'],
    [0.996078431372549, '#ecf0f1'],
    [1.0, '#f0f0f0'],
  ],
  CET_CBTL4: [
    [0.0, '#101010'],
    [0.00392156862745098, '#131110'],
    [0.00784313725490196, '#151111'],
    [0.011764705882352941, '#171211'],
    [0.01568627450980392, '#191211'],
    [0.0196078431372549, '#1b1311'],
    [0.023529411764705882, '#1d1311'],
    [0.027450980392156862, '#1e1311'],
    [0.03137254901960784, '#201411'],
    [0.03529411764705882, '#221411'],
    [0.0392156862745098, '#231412'],
    [0.043137254901960784, '#251512'],
    [0.047058823529411764, '#271512'],
    [0.050980392156862744, '#291512'],
    [0.054901960784313725, '#2a1512'],
    [0.058823529411764705, '#2c1512'],
    [0.06274509803921569, '#2e1612'],
    [0.06666666666666667, '#2f1613'],
    [0.07058823529411765, '#311613'],
    [0.07450980392156863, '#331613'],
    [0.0784313725490196, '#341713'],
    [0.08235294117647059, '#361713'],
    [0.08627450980392157, '#381713'],
    [0.09019607843137255, '#391713'],
    [0.09411764705882353, '#3b1713'],
    [0.09803921568627451, '#3d1714'],
    [0.10196078431372549, '#3e1714'],
    [0.10588235294117647, '#401814'],
    [0.10980392156862745, '#421814'],
    [0.11372549019607843, '#441814'],
    [0.11764705882352941, '#451814'],
    [0.12156862745098039, '#471814'],
    [0.12549019607843137, '#491814'],
    [0.12941176470588234, '#4a1815'],
    [0.13333333333333333, '#4c1815'],
    [0.13725490196078433, '#4e1815'],
    [0.1411764705882353, '#4f1915'],
    [0.14509803921568626, '#511915'],
    [0.14901960784313725, '#531915'],
    [0.15294117647058825, '#541915'],
    [0.1568627450980392, '#561915'],
    [0.16078431372549018, '#581916'],
    [0.16470588235294117, '#5a1916'],
    [0.16862745098039217, '#5b1916'],
    [0.17254901960784313, '#5d1916'],
    [0.1764705882352941, '#5f1916'],
    [0.1803921568627451, '#601916'],
    [0.1843137254901961, '#621916'],
    [0.18823529411764706, '#641917'],
    [0.19215686274509802, '#651917'],
    [0.19607843137254902, '#671917'],
    [0.2, '#691917'],
    [0.20392156862745098, '#6b1917'],
    [0.20784313725490194, '#6c1917'],
    [0.21176470588235294, '#6e1918'],
    [0.21568627450980393, '#701918'],
    [0.2196078431372549, '#711918'],
    [0.22352941176470587, '#731918'],
    [0.22745098039215686, '#751918'],
    [0.23137254901960785, '#761918'],
    [0.23529411764705882, '#781819'],
    [0.2392156862745098, '#7a1819'],
    [0.24313725490196078, '#7c1819'],
    [0.24705882352941178, '#7d1819'],
    [0.25098039215686274, '#7f1819'],
    [0.2549019607843137, '#81181a'],
    [0.2588235294117647, '#82181a'],
    [0.2627450980392157, '#84181a'],
    [0.26666666666666666, '#86181a'],
    [0.27058823529411763, '#87181a'],
    [0.27450980392156865, '#89181b'],
    [0.2784313725490196, '#8b181b'],
    [0.2823529411764706, '#8d181b'],
    [0.28627450980392155, '#8e181b'],
    [0.2901960784313725, '#90171c'],
    [0.29411764705882354, '#92171c'],
    [0.2980392156862745, '#93171c'],
    [0.30196078431372547, '#95171c'],
    [0.3058823529411765, '#97171c'],
    [0.30980392156862746, '#98171d'],
    [0.3137254901960784, '#9a171d'],
    [0.3176470588235294, '#9c171d'],
    [0.32156862745098036, '#9d171e'],
    [0.3254901960784314, '#9f171e'],
    [0.32941176470588235, '#a1171e'],
    [0.3333333333333333, '#a2171e'],
    [0.33725490196078434, '#a4171f'],
    [0.3411764705882353, '#a6171f'],
    [0.34509803921568627, '#a7171f'],
    [0.34901960784313724, '#a91720'],
    [0.3529411764705882, '#aa1720'],
    [0.3568627450980392, '#ac1720'],
    [0.3607843137254902, '#ae1821'],
    [0.36470588235294116, '#af1821'],
    [0.3686274509803922, '#b11821'],
    [0.37254901960784315, '#b31822'],
    [0.3764705882352941, '#b41822'],
    [0.3803921568627451, '#b61822'],
    [0.38431372549019605, '#b71923'],
    [0.38823529411764707, '#b91923'],
    [0.39215686274509803, '#bb1923'],
    [0.396078431372549, '#bc1924'],
    [0.4, '#be1a24'],
    [0.403921568627451, '#bf1a25'],
    [0.40784313725490196, '#c11b25'],
    [0.4117647058823529, '#c21b25'],
    [0.4156862745098039, '#c41b26'],
    [0.4196078431372549, '#c51c26'],
    [0.4235294117647059, '#c71c27'],
    [0.42745098039215684, '#c81d27'],
    [0.43137254901960786, '#ca1e28'],
    [0.43529411764705883, '#cb1e28'],
    [0.4392156862745098, '#cd1f29'],
    [0.44313725490196076, '#ce1f29'],
    [0.44705882352941173, '#d0202a'],
    [0.45098039215686275, '#d1212a'],
    [0.4549019607843137, '#d3222b'],
    [0.4588235294117647, '#d4222b'],
    [0.4627450980392157, '#d6232c'],
    [0.4666666666666667, '#d7242c'],
    [0.47058823529411764, '#d8252d'],
    [0.4745098039215686, '#da262e'],
    [0.4784313725490196, '#db272e'],
    [0.4823529411764706, '#dc282f'],
    [0.48627450980392156, '#de2930'],
    [0.49019607843137253, '#df2a30'],
    [0.49411764705882355, '#e02b31'],
    [0.4980392156862745, '#e22c32'],
    [0.5019607843137255, '#e32d32'],
    [0.5058823529411764, '#e42e33'],
    [0.5098039215686274, '#e53034'],
    [0.5137254901960784, '#e63135'],
    [0.5176470588235293, '#e83235'],
    [0.5215686274509804, '#e93336'],
    [0.5254901960784314, '#ea3537'],
    [0.5294117647058824, '#eb3638'],
    [0.5333333333333333, '#ec3839'],
    [0.5372549019607843, '#ed393a'],
    [0.5411764705882353, '#ee3b3b'],
    [0.5450980392156862, '#ef3c3c'],
    [0.5490196078431373, '#f03e3d'],
    [0.5529411764705883, '#f13f3e'],
    [0.5568627450980392, '#f1413f'],
    [0.5607843137254902, '#f24340'],
    [0.5647058823529412, '#f34441'],
    [0.5686274509803921, '#f34642'],
    [0.5725490196078431, '#f44844'],
    [0.5764705882352941, '#f44a45'],
    [0.580392156862745, '#f54c46'],
    [0.5843137254901961, '#f54e48'],
    [0.5882352941176471, '#f65049'],
    [0.592156862745098, '#f6524b'],
    [0.596078431372549, '#f6544c'],
    [0.6, '#f6564e'],
    [0.6039215686274509, '#f7584f'],
    [0.6078431372549019, '#f75a51'],
    [0.611764705882353, '#f75b52'],
    [0.615686274509804, '#f85d53'],
    [0.6196078431372549, '#f85f55'],
    [0.6235294117647059, '#f86156'],
    [0.6274509803921569, '#f86358'],
    [0.6313725490196078, '#f96459'],
    [0.6352941176470588, '#f9665b'],
    [0.6392156862745098, '#f9685c'],
    [0.6431372549019607, '#f96a5e'],
    [0.6470588235294118, '#fa6b5f'],
    [0.6509803921568628, '#fa6d61'],
    [0.6549019607843137, '#fa6f62'],
    [0.6588235294117647, '#fa7064'],
    [0.6627450980392157, '#fb7265'],
    [0.6666666666666666, '#fb7467'],
    [0.6705882352941176, '#fb7568'],
    [0.6745098039215687, '#fb776a'],
    [0.6784313725490196, '#fb786b'],
    [0.6823529411764706, '#fc7a6d'],
    [0.6862745098039216, '#fc7c6e'],
    [0.6901960784313725, '#fc7d70'],
    [0.6941176470588235, '#fc7f71'],
    [0.6980392156862745, '#fc8073'],
    [0.7019607843137254, '#fc8274'],
    [0.7058823529411764, '#fc8376'],
    [0.7098039215686275, '#fd8578'],
    [0.7137254901960784, '#fd8779'],
    [0.7176470588235294, '#fd887b'],
    [0.7215686274509804, '#fd8a7c'],
    [0.7254901960784313, '#fd8b7e'],
    [0.7294117647058823, '#fd8d7f'],
    [0.7333333333333333, '#fd8e81'],
    [0.7372549019607844, '#fd9082'],
    [0.7411764705882353, '#fd9184'],
    [0.7450980392156863, '#fe9385'],
    [0.7490196078431373, '#fe9487'],
    [0.7529411764705882, '#fe9689'],
    [0.7568627450980392, '#fe978a'],
    [0.7607843137254902, '#fe998c'],
    [0.7647058823529411, '#fe9a8d'],
    [0.7686274509803921, '#fe9c8f'],
    [0.7725490196078432, '#fe9d90'],
    [0.7764705882352941, '#fe9f92'],
    [0.7803921568627451, '#fea094'],
    [0.7843137254901961, '#fea295'],
    [0.788235294117647, '#fea397'],
    [0.792156862745098, '#fea598'],
    [0.796078431372549, '#fea69a'],
    [0.8, '#fea79c'],
    [0.803921568627451, '#fea99d'],
    [0.807843137254902, '#feaa9f'],
    [0.8117647058823529, '#feaca0'],
    [0.8156862745098039, '#feada2'],
    [0.8196078431372549, '#feafa4'],
    [0.8235294117647058, '#fdb0a5'],
    [0.8274509803921568, '#fdb2a7'],
    [0.8313725490196078, '#fdb3a8'],
    [0.8352941176470589, '#fdb5aa'],
    [0.8392156862745098, '#fdb6ac'],
    [0.8431372549019608, '#fdb7ad'],
    [0.8470588235294118, '#fdb9af'],
    [0.8509803921568627, '#fdbab1'],
    [0.8549019607843137, '#fdbcb2'],
    [0.8588235294117647, '#fcbdb4'],
    [0.8627450980392157, '#fcbfb5'],
    [0.8666666666666667, '#fcc0b7'],
    [0.8705882352941177, '#fcc1b9'],
    [0.8745098039215686, '#fcc3ba'],
    [0.8784313725490196, '#fbc4bc'],
    [0.8823529411764706, '#fbc6be'],
    [0.8862745098039215, '#fbc7bf'],
    [0.8901960784313725, '#fbc9c1'],
    [0.8941176470588235, '#fbcac3'],
    [0.8980392156862745, '#facbc4'],
    [0.9019607843137255, '#facdc6'],
    [0.9058823529411765, '#facec8'],
    [0.9098039215686274, '#fad0c9'],
    [0.9137254901960784, '#f9d1cb'],
    [0.9176470588235294, '#f9d2cd'],
    [0.9215686274509803, '#f9d4ce'],
    [0.9254901960784314, '#f8d5d0'],
    [0.9294117647058824, '#f8d7d2'],
    [0.9333333333333333, '#f8d8d3'],
    [0.9372549019607843, '#f7dad5'],
    [0.9411764705882353, '#f7dbd7'],
    [0.9450980392156862, '#f7dcd8'],
    [0.9490196078431372, '#f6deda'],
    [0.9529411764705882, '#f6dfdc'],
    [0.9568627450980391, '#f5e1dd'],
    [0.9607843137254902, '#f5e2df'],
    [0.9647058823529412, '#f4e3e1'],
    [0.9686274509803922, '#f4e5e2'],
    [0.9725490196078431, '#f4e6e4'],
    [0.9764705882352941, '#f3e8e6'],
    [0.9803921568627451, '#f3e9e8'],
    [0.984313725490196, '#f2eae9'],
    [0.9882352941176471, '#f2eceb'],
    [0.9921568627450981, '#f1eded'],
    [0.996078431372549, '#f1efee'],
    [1.0, '#f0f0f0'],
  ],
  CET_D1: [
    [0.0, '#2050da'],
    [0.00392156862745098, '#2551db'],
    [0.00784313725490196, '#2952db'],
    [0.011764705882352941, '#2d53db'],
    [0.01568627450980392, '#3155db'],
    [0.0196078431372549, '#3456db'],
    [0.023529411764705882, '#3857dc'],
    [0.027450980392156862, '#3b58dc'],
    [0.03137254901960784, '#3d59dc'],
    [0.03529411764705882, '#405adc'],
    [0.0392156862745098, '#435bdd'],
    [0.043137254901960784, '#455cdd'],
    [0.047058823529411764, '#485ddd'],
    [0.050980392156862744, '#4a5fdd'],
    [0.054901960784313725, '#4d60dd'],
    [0.058823529411764705, '#4f61de'],
    [0.06274509803921569, '#5162de'],
    [0.06666666666666667, '#5363de'],
    [0.07058823529411765, '#5664de'],
    [0.07450980392156863, '#5865de'],
    [0.0784313725490196, '#5a67df'],
    [0.08235294117647059, '#5c68df'],
    [0.08627450980392157, '#5e69df'],
    [0.09019607843137255, '#606adf'],
    [0.09411764705882353, '#616be0'],
    [0.09803921568627451, '#636ce0'],
    [0.10196078431372549, '#656ee0'],
    [0.10588235294117647, '#676fe0'],
    [0.10980392156862745, '#6970e0'],
    [0.11372549019607843, '#6b71e1'],
    [0.11764705882352941, '#6c72e1'],
    [0.12156862745098039, '#6e74e1'],
    [0.12549019607843137, '#7075e1'],
    [0.12941176470588234, '#7176e1'],
    [0.13333333333333333, '#7377e2'],
    [0.13725490196078433, '#7578e2'],
    [0.1411764705882353, '#7679e2'],
    [0.14509803921568626, '#787be2'],
    [0.14901960784313725, '#7a7ce2'],
    [0.15294117647058825, '#7b7de2'],
    [0.1568627450980392, '#7d7ee3'],
    [0.16078431372549018, '#7e7fe3'],
    [0.16470588235294117, '#8081e3'],
    [0.16862745098039217, '#8282e3'],
    [0.17254901960784313, '#8383e3'],
    [0.1764705882352941, '#8584e4'],
    [0.1803921568627451, '#8686e4'],
    [0.1843137254901961, '#8887e4'],
    [0.18823529411764706, '#8988e4'],
    [0.19215686274509802, '#8b89e4'],
    [0.19607843137254902, '#8c8ae5'],
    [0.2, '#8e8ce5'],
    [0.20392156862745098, '#8f8de5'],
    [0.20784313725490194, '#908ee5'],
    [0.21176470588235294, '#928fe5'],
    [0.21568627450980393, '#9391e5'],
    [0.2196078431372549, '#9592e6'],
    [0.22352941176470587, '#9693e6'],
    [0.22745098039215686, '#9894e6'],
    [0.23137254901960785, '#9996e6'],
    [0.23529411764705882, '#9a97e6'],
    [0.2392156862745098, '#9c98e7'],
    [0.24313725490196078, '#9d99e7'],
    [0.24705882352941178, '#9e9be7'],
    [0.25098039215686274, '#a09ce7'],
    [0.2549019607843137, '#a19de7'],
    [0.2588235294117647, '#a39ee7'],
    [0.2627450980392157, '#a4a0e8'],
    [0.26666666666666666, '#a5a1e8'],
    [0.27058823529411763, '#a7a2e8'],
    [0.27450980392156865, '#a8a3e8'],
    [0.2784313725490196, '#a9a5e8'],
    [0.2823529411764706, '#aba6e8'],
    [0.28627450980392155, '#aca7e9'],
    [0.2901960784313725, '#ada8e9'],
    [0.29411764705882354, '#afaae9'],
    [0.2980392156862745, '#b0abe9'],
    [0.30196078431372547, '#b1ace9'],
    [0.3058823529411765, '#b2aee9'],
    [0.30980392156862746, '#b4afea'],
    [0.3137254901960784, '#b5b0ea'],
    [0.3176470588235294, '#b6b1ea'],
    [0.32156862745098036, '#b8b3ea'],
    [0.3254901960784314, '#b9b4ea'],
    [0.32941176470588235, '#bab5ea'],
    [0.3333333333333333, '#bbb7ea'],
    [0.33725490196078434, '#bdb8eb'],
    [0.3411764705882353, '#beb9eb'],
    [0.34509803921568627, '#bfbaeb'],
    [0.34901960784313724, '#c0bceb'],
    [0.3529411764705882, '#c2bdeb'],
    [0.3568627450980392, '#c3beeb'],
    [0.3607843137254902, '#c4c0ec'],
    [0.36470588235294116, '#c5c1ec'],
    [0.3686274509803922, '#c7c2ec'],
    [0.37254901960784315, '#c8c4ec'],
    [0.3764705882352941, '#c9c5ec'],
    [0.3803921568627451, '#cac6ec'],
    [0.38431372549019605, '#ccc8ec'],
    [0.38823529411764707, '#cdc9ec'],
    [0.39215686274509803, '#cecaed'],
    [0.396078431372549, '#cfcbed'],
    [0.4, '#d1cded'],
    [0.403921568627451, '#d2ceed'],
    [0.40784313725490196, '#d3cfed'],
    [0.4117647058823529, '#d4d1ed'],
    [0.4156862745098039, '#d5d2ed'],
    [0.4196078431372549, '#d7d3ee'],
    [0.4235294117647059, '#d8d5ee'],
    [0.42745098039215684, '#d9d6ee'],
    [0.43137254901960786, '#dad7ee'],
    [0.43529411764705883, '#dbd9ee'],
    [0.4392156862745098, '#dddaee'],
    [0.44313725490196076, '#dedbee'],
    [0.44705882352941173, '#dfddee'],
    [0.45098039215686275, '#e0deee'],
    [0.4549019607843137, '#e1dfee'],
    [0.4588235294117647, '#e3e0ee'],
    [0.4627450980392157, '#e4e1ee'],
    [0.4666666666666667, '#e5e2ee'],
    [0.47058823529411764, '#e6e3ee'],
    [0.4745098039215686, '#e7e4ee'],
    [0.4784313725490196, '#e8e5ed'],
    [0.4823529411764706, '#e9e6ed'],
    [0.48627450980392156, '#eae6ec'],
    [0.49019607843137253, '#ebe7ec'],
    [0.49411764705882355, '#ece7eb'],
    [0.4980392156862745, '#ece7ea'],
    [0.5019607843137255, '#ede7e9'],
    [0.5058823529411764, '#eee6e8'],
    [0.5098039215686274, '#eee6e6'],
    [0.5137254901960784, '#efe5e5'],
    [0.5176470588235293, '#efe5e4'],
    [0.5215686274509804, '#f0e4e2'],
    [0.5254901960784314, '#f0e3e0'],
    [0.5294117647058824, '#f0e2df'],
    [0.5333333333333333, '#f1e0dd'],
    [0.5372549019607843, '#f1dfdb'],
    [0.5411764705882353, '#f1ded9'],
    [0.5450980392156862, '#f1dcd7'],
    [0.5490196078431373, '#f1dbd6'],
    [0.5529411764705883, '#f1d9d4'],
    [0.5568627450980392, '#f1d8d2'],
    [0.5607843137254902, '#f1d6d0'],
    [0.5647058823529412, '#f1d5ce'],
    [0.5686274509803921, '#f1d3cc'],
    [0.5725490196078431, '#f1d2ca'],
    [0.5764705882352941, '#f1d0c8'],
    [0.580392156862745, '#f1cfc6'],
    [0.5843137254901961, '#f1cdc4'],
    [0.5882352941176471, '#f1ccc2'],
    [0.592156862745098, '#f1cac0'],
    [0.596078431372549, '#f1c9bf'],
    [0.6, '#f1c7bd'],
    [0.6039215686274509, '#f1c5bb'],
    [0.6078431372549019, '#f1c4b9'],
    [0.611764705882353, '#f0c2b7'],
    [0.615686274509804, '#f0c1b5'],
    [0.6196078431372549, '#f0bfb3'],
    [0.6235294117647059, '#f0beb1'],
    [0.6274509803921569, '#f0bcaf'],
    [0.6313725490196078, '#f0bbad'],
    [0.6352941176470588, '#efb9ac'],
    [0.6392156862745098, '#efb7aa'],
    [0.6431372549019607, '#efb6a8'],
    [0.6470588235294118, '#efb4a6'],
    [0.6509803921568628, '#efb3a4'],
    [0.6549019607843137, '#eeb1a2'],
    [0.6588235294117647, '#eeb0a0'],
    [0.6627450980392157, '#eeae9e'],
    [0.6666666666666666, '#eead9d'],
    [0.6705882352941176, '#edab9b'],
    [0.6745098039215687, '#eda999'],
    [0.6784313725490196, '#eda897'],
    [0.6823529411764706, '#eda695'],
    [0.6862745098039216, '#eca593'],
    [0.6901960784313725, '#eca391'],
    [0.6941176470588235, '#eca290'],
    [0.6980392156862745, '#eba08e'],
    [0.7019607843137254, '#eb9f8c'],
    [0.7058823529411764, '#eb9d8a'],
    [0.7098039215686275, '#ea9b88'],
    [0.7137254901960784, '#ea9a87'],
    [0.7176470588235294, '#ea9885'],
    [0.7215686274509804, '#e99783'],
    [0.7254901960784313, '#e99581'],
    [0.7294117647058823, '#e9947f'],
    [0.7333333333333333, '#e8927d'],
    [0.7372549019607844, '#e8907c'],
    [0.7411764705882353, '#e88f7a'],
    [0.7450980392156863, '#e78d78'],
    [0.7490196078431373, '#e78c76'],
    [0.7529411764705882, '#e68a75'],
    [0.7568627450980392, '#e68973'],
    [0.7607843137254902, '#e58771'],
    [0.7647058823529411, '#e5856f'],
    [0.7686274509803921, '#e5846d'],
    [0.7725490196078432, '#e4826c'],
    [0.7764705882352941, '#e4816a'],
    [0.7803921568627451, '#e37f68'],
    [0.7843137254901961, '#e37d66'],
    [0.788235294117647, '#e27c65'],
    [0.792156862745098, '#e27a63'],
    [0.796078431372549, '#e17961'],
    [0.8, '#e1775f'],
    [0.803921568627451, '#e0755e'],
    [0.807843137254902, '#e0745c'],
    [0.8117647058823529, '#df725a'],
    [0.8156862745098039, '#df7158'],
    [0.8196078431372549, '#de6f57'],
    [0.8235294117647058, '#de6d55'],
    [0.8274509803921568, '#dd6c53'],
    [0.8313725490196078, '#dd6a52'],
    [0.8352941176470589, '#dc6850'],
    [0.8392156862745098, '#db674e'],
    [0.8431372549019608, '#db654c'],
    [0.8470588235294118, '#da634b'],
    [0.8509803921568627, '#da6249'],
    [0.8549019607843137, '#d96047'],
    [0.8588235294117647, '#d95e46'],
    [0.8627450980392157, '#d85d44'],
    [0.8666666666666667, '#d75b42'],
    [0.8705882352941177, '#d75940'],
    [0.8745098039215686, '#d6573f'],
    [0.8784313725490196, '#d5563d'],
    [0.8823529411764706, '#d5543b'],
    [0.8862745098039215, '#d4523a'],
    [0.8901960784313725, '#d45038'],
    [0.8941176470588235, '#d34e36'],
    [0.8980392156862745, '#d24d35'],
    [0.9019607843137255, '#d24b33'],
    [0.9058823529411765, '#d14931'],
    [0.9098039215686274, '#d04730'],
    [0.9137254901960784, '#d0452e'],
    [0.9176470588235294, '#cf432c'],
    [0.9215686274509803, '#ce412a'],
    [0.9254901960784314, '#ce3f29'],
    [0.9294117647058824, '#cd3d27'],
    [0.9333333333333333, '#cc3b25'],
    [0.9372549019607843, '#cb3924'],
    [0.9411764705882353, '#cb3722'],
    [0.9450980392156862, '#ca3420'],
    [0.9490196078431372, '#c9321e'],
    [0.9529411764705882, '#c9301d'],
    [0.9568627450980391, '#c82d1b'],
    [0.9607843137254902, '#c72b19'],
    [0.9647058823529412, '#c62817'],
    [0.9686274509803922, '#c62515'],
    [0.9725490196078431, '#c52214'],
    [0.9764705882352941, '#c41f12'],
    [0.9803921568627451, '#c31c10'],
    [0.984313725490196, '#c3180e'],
    [0.9882352941176471, '#c2140c'],
    [0.9921568627450981, '#c10f09'],
    [0.996078431372549, '#c00907'],
    [1.0, '#bf0205'],
  ],
  CET_D10: [
    [0.0, '#00d8ff'],
    [0.00392156862745098, '#00d9ff'],
    [0.00784313725490196, '#0ed9ff'],
    [0.011764705882352941, '#19d9ff'],
    [0.01568627450980392, '#21daff'],
    [0.0196078431372549, '#28daff'],
    [0.023529411764705882, '#2ddaff'],
    [0.027450980392156862, '#32dbff'],
    [0.03137254901960784, '#37dbff'],
    [0.03529411764705882, '#3bdbff'],
    [0.0392156862745098, '#3fdcff'],
    [0.043137254901960784, '#42dcff'],
    [0.047058823529411764, '#46dcff'],
    [0.050980392156862744, '#49dcff'],
    [0.054901960784313725, '#4cddff'],
    [0.058823529411764705, '#4fddff'],
    [0.06274509803921569, '#52ddff'],
    [0.06666666666666667, '#55deff'],
    [0.07058823529411765, '#57deff'],
    [0.07450980392156863, '#5adeff'],
    [0.0784313725490196, '#5ddfff'],
    [0.08235294117647059, '#5fdfff'],
    [0.08627450980392157, '#62dfff'],
    [0.09019607843137255, '#64e0ff'],
    [0.09411764705882353, '#66e0ff'],
    [0.09803921568627451, '#68e0ff'],
    [0.10196078431372549, '#6be0ff'],
    [0.10588235294117647, '#6de1ff'],
    [0.10980392156862745, '#6fe1ff'],
    [0.11372549019607843, '#71e1ff'],
    [0.11764705882352941, '#73e2ff'],
    [0.12156862745098039, '#75e2ff'],
    [0.12549019607843137, '#77e2ff'],
    [0.12941176470588234, '#79e3ff'],
    [0.13333333333333333, '#7be3ff'],
    [0.13725490196078433, '#7de3ff'],
    [0.1411764705882353, '#7fe4ff'],
    [0.14509803921568626, '#81e4ff'],
    [0.14901960784313725, '#82e4ff'],
    [0.15294117647058825, '#84e4ff'],
    [0.1568627450980392, '#86e5ff'],
    [0.16078431372549018, '#88e5ff'],
    [0.16470588235294117, '#89e5ff'],
    [0.16862745098039217, '#8be6ff'],
    [0.17254901960784313, '#8de6ff'],
    [0.1764705882352941, '#8fe6ff'],
    [0.1803921568627451, '#90e7ff'],
    [0.1843137254901961, '#92e7ff'],
    [0.18823529411764706, '#94e7ff'],
    [0.19215686274509802, '#95e7ff'],
    [0.19607843137254902, '#97e8ff'],
    [0.2, '#98e8ff'],
    [0.20392156862745098, '#9ae8ff'],
    [0.20784313725490194, '#9ce9ff'],
    [0.21176470588235294, '#9de9ff'],
    [0.21568627450980393, '#9fe9ff'],
    [0.2196078431372549, '#a0eaff'],
    [0.22352941176470587, '#a2eaff'],
    [0.22745098039215686, '#a3eaff'],
    [0.23137254901960785, '#a5eaff'],
    [0.23529411764705882, '#a6ebff'],
    [0.2392156862745098, '#a8ebff'],
    [0.24313725490196078, '#a9ebff'],
    [0.24705882352941178, '#abecff'],
    [0.25098039215686274, '#acecff'],
    [0.2549019607843137, '#aeecff'],
    [0.2588235294117647, '#afedff'],
    [0.2627450980392157, '#b1edff'],
    [0.26666666666666666, '#b2edff'],
    [0.27058823529411763, '#b3edff'],
    [0.27450980392156865, '#b5eeff'],
    [0.2784313725490196, '#b6eeff'],
    [0.2823529411764706, '#b8eeff'],
    [0.28627450980392155, '#b9efff'],
    [0.2901960784313725, '#baefff'],
    [0.29411764705882354, '#bcefff'],
    [0.2980392156862745, '#bdf0ff'],
    [0.30196078431372547, '#bff0ff'],
    [0.3058823529411765, '#c0f0ff'],
    [0.30980392156862746, '#c1f0ff'],
    [0.3137254901960784, '#c3f1ff'],
    [0.3176470588235294, '#c4f1ff'],
    [0.32156862745098036, '#c5f1ff'],
    [0.3254901960784314, '#c7f2ff'],
    [0.32941176470588235, '#c8f2ff'],
    [0.3333333333333333, '#c9f2ff'],
    [0.33725490196078434, '#cbf2ff'],
    [0.3411764705882353, '#ccf3ff'],
    [0.34509803921568627, '#cdf3ff'],
    [0.34901960784313724, '#cff3ff'],
    [0.3529411764705882, '#d0f4ff'],
    [0.3568627450980392, '#d1f4ff'],
    [0.3607843137254902, '#d3f4ff'],
    [0.36470588235294116, '#d4f5ff'],
    [0.3686274509803922, '#d5f5ff'],
    [0.37254901960784315, '#d6f5ff'],
    [0.3764705882352941, '#d8f5ff'],
    [0.3803921568627451, '#d9f6ff'],
    [0.38431372549019605, '#daf6ff'],
    [0.38823529411764707, '#dbf6ff'],
    [0.39215686274509803, '#ddf7ff'],
    [0.396078431372549, '#def7ff'],
    [0.4, '#dff7ff'],
    [0.403921568627451, '#e0f7ff'],
    [0.40784313725490196, '#e2f8ff'],
    [0.4117647058823529, '#e3f8ff'],
    [0.4156862745098039, '#e4f8ff'],
    [0.4196078431372549, '#e5f9ff'],
    [0.4235294117647059, '#e7f9ff'],
    [0.42745098039215684, '#e8f9ff'],
    [0.43137254901960786, '#e9f9ff'],
    [0.43529411764705883, '#eafaff'],
    [0.4392156862745098, '#ecfaff'],
    [0.44313725490196076, '#edfaff'],
    [0.44705882352941173, '#eefbff'],
    [0.45098039215686275, '#effbff'],
    [0.4549019607843137, '#f0fbff'],
    [0.4588235294117647, '#f2fbff'],
    [0.4627450980392157, '#f3fcff'],
    [0.4666666666666667, '#f4fcff'],
    [0.47058823529411764, '#f5fcff'],
    [0.4745098039215686, '#f6fdff'],
    [0.4784313725490196, '#f8fdff'],
    [0.4823529411764706, '#f9fdff'],
    [0.48627450980392156, '#fafdff'],
    [0.49019607843137253, '#fbfeff'],
    [0.49411764705882355, '#fcfeff'],
    [0.4980392156862745, '#fefeff'],
    [0.5019607843137255, '#fefefe'],
    [0.5058823529411764, '#fefdfe'],
    [0.5098039215686274, '#fefdfe'],
    [0.5137254901960784, '#fefcfe'],
    [0.5176470588235293, '#fefcfe'],
    [0.5215686274509804, '#fefbfe'],
    [0.5254901960784314, '#fefafe'],
    [0.5294117647058824, '#fefafe'],
    [0.5333333333333333, '#fef9fe'],
    [0.5372549019607843, '#fef8fd'],
    [0.5411764705882353, '#fef8fd'],
    [0.5450980392156862, '#fef7fd'],
    [0.5490196078431373, '#fef7fd'],
    [0.5529411764705883, '#fef6fd'],
    [0.5568627450980392, '#fef5fd'],
    [0.5607843137254902, '#fef5fd'],
    [0.5647058823529412, '#fef4fd'],
    [0.5686274509803921, '#fef3fd'],
    [0.5725490196078431, '#fef3fc'],
    [0.5764705882352941, '#fef2fc'],
    [0.580392156862745, '#fef2fc'],
    [0.5843137254901961, '#fef1fc'],
    [0.5882352941176471, '#fef0fc'],
    [0.592156862745098, '#fef0fc'],
    [0.596078431372549, '#feeffc'],
    [0.6, '#feeefc'],
    [0.6039215686274509, '#feeefc'],
    [0.6078431372549019, '#feedfb'],
    [0.611764705882353, '#feecfb'],
    [0.615686274509804, '#feecfb'],
    [0.6196078431372549, '#feebfb'],
    [0.6235294117647059, '#feebfb'],
    [0.6274509803921569, '#feeafb'],
    [0.6313725490196078, '#fee9fb'],
    [0.6352941176470588, '#fee9fb'],
    [0.6392156862745098, '#fee8fb'],
    [0.6431372549019607, '#fee7fa'],
    [0.6470588235294118, '#fee7fa'],
    [0.6509803921568628, '#fee6fa'],
    [0.6549019607843137, '#fee6fa'],
    [0.6588235294117647, '#fee5fa'],
    [0.6627450980392157, '#fee4fa'],
    [0.6666666666666666, '#fee4fa'],
    [0.6705882352941176, '#fee3fa'],
    [0.6745098039215687, '#fee2f9'],
    [0.6784313725490196, '#fee2f9'],
    [0.6823529411764706, '#fee1f9'],
    [0.6862745098039216, '#fee1f9'],
    [0.6901960784313725, '#fee0f9'],
    [0.6941176470588235, '#fedff9'],
    [0.6980392156862745, '#fedff9'],
    [0.7019607843137254, '#fedef9'],
    [0.7058823529411764, '#feddf9'],
    [0.7098039215686275, '#feddf8'],
    [0.7137254901960784, '#fedcf8'],
    [0.7176470588235294, '#fedbf8'],
    [0.7215686274509804, '#fedbf8'],
    [0.7254901960784313, '#fedaf8'],
    [0.7294117647058823, '#fedaf8'],
    [0.7333333333333333, '#fed9f8'],
    [0.7372549019607844, '#fdd8f8'],
    [0.7411764705882353, '#fdd8f8'],
    [0.7450980392156863, '#fdd7f7'],
    [0.7490196078431373, '#fdd6f7'],
    [0.7529411764705882, '#fdd6f7'],
    [0.7568627450980392, '#fdd5f7'],
    [0.7607843137254902, '#fdd4f7'],
    [0.7647058823529411, '#fdd4f7'],
    [0.7686274509803921, '#fdd3f7'],
    [0.7725490196078432, '#fdd3f7'],
    [0.7764705882352941, '#fdd2f6'],
    [0.7803921568627451, '#fdd1f6'],
    [0.7843137254901961, '#fdd1f6'],
    [0.788235294117647, '#fdd0f6'],
    [0.792156862745098, '#fdcff6'],
    [0.796078431372549, '#fdcff6'],
    [0.8, '#fdcef6'],
    [0.803921568627451, '#fdcdf6'],
    [0.807843137254902, '#fdcdf6'],
    [0.8117647058823529, '#fdccf5'],
    [0.8156862745098039, '#fdccf5'],
    [0.8196078431372549, '#fdcbf5'],
    [0.8235294117647058, '#fccaf5'],
    [0.8274509803921568, '#fccaf5'],
    [0.8313725490196078, '#fcc9f5'],
    [0.8352941176470589, '#fcc8f5'],
    [0.8392156862745098, '#fcc8f5'],
    [0.8431372549019608, '#fcc7f4'],
    [0.8470588235294118, '#fcc6f4'],
    [0.8509803921568627, '#fcc6f4'],
    [0.8549019607843137, '#fcc5f4'],
    [0.8588235294117647, '#fcc4f4'],
    [0.8627450980392157, '#fcc4f4'],
    [0.8666666666666667, '#fcc3f4'],
    [0.8705882352941177, '#fcc3f4'],
    [0.8745098039215686, '#fcc2f3'],
    [0.8784313725490196, '#fcc1f3'],
    [0.8823529411764706, '#fcc1f3'],
    [0.8862745098039215, '#fcc0f3'],
    [0.8901960784313725, '#fbbff3'],
    [0.8941176470588235, '#fbbff3'],
    [0.8980392156862745, '#fbbef3'],
    [0.9019607843137255, '#fbbdf3'],
    [0.9058823529411765, '#fbbdf3'],
    [0.9098039215686274, '#fbbcf2'],
    [0.9137254901960784, '#fbbbf2'],
    [0.9176470588235294, '#fbbbf2'],
    [0.9215686274509803, '#fbbaf2'],
    [0.9254901960784314, '#fbb9f2'],
    [0.9294117647058824, '#fbb9f2'],
    [0.9333333333333333, '#fbb8f2'],
    [0.9372549019607843, '#fbb8f2'],
    [0.9411764705882353, '#fbb7f1'],
    [0.9450980392156862, '#fab6f1'],
    [0.9490196078431372, '#fab6f1'],
    [0.9529411764705882, '#fab5f1'],
    [0.9568627450980391, '#fab4f1'],
    [0.9607843137254902, '#fab4f1'],
    [0.9647058823529412, '#fab3f1'],
    [0.9686274509803922, '#fab2f1'],
    [0.9725490196078431, '#fab2f0'],
    [0.9764705882352941, '#fab1f0'],
    [0.9803921568627451, '#fab0f0'],
    [0.984313725490196, '#fab0f0'],
    [0.9882352941176471, '#faaff0'],
    [0.9921568627450981, '#faaef0'],
    [0.996078431372549, '#f9aef0'],
    [1.0, '#f9adf0'],
  ],
  CET_D11: [
    [0.0, '#00b6ff'],
    [0.00392156862745098, '#00b6ff'],
    [0.00784313725490196, '#00b6ff'],
    [0.011764705882352941, '#00b6ff'],
    [0.01568627450980392, '#06b6ff'],
    [0.0196078431372549, '#11b5fe'],
    [0.023529411764705882, '#18b5fe'],
    [0.027450980392156862, '#1eb5fd'],
    [0.03137254901960784, '#23b5fc'],
    [0.03529411764705882, '#28b5fc'],
    [0.0392156862745098, '#2bb5fb'],
    [0.043137254901960784, '#2fb5fa'],
    [0.047058823529411764, '#32b5f9'],
    [0.050980392156862744, '#35b5f9'],
    [0.054901960784313725, '#38b5f8'],
    [0.058823529411764705, '#3bb5f7'],
    [0.06274509803921569, '#3db4f7'],
    [0.06666666666666667, '#40b4f6'],
    [0.07058823529411765, '#42b4f5'],
    [0.07450980392156863, '#44b4f5'],
    [0.0784313725490196, '#46b4f4'],
    [0.08235294117647059, '#48b4f3'],
    [0.08627450980392157, '#4ab4f3'],
    [0.09019607843137255, '#4cb4f2'],
    [0.09411764705882353, '#4eb4f1'],
    [0.09803921568627451, '#50b4f0'],
    [0.10196078431372549, '#52b3f0'],
    [0.10588235294117647, '#54b3ef'],
    [0.10980392156862745, '#55b3ee'],
    [0.11372549019607843, '#57b3ee'],
    [0.11764705882352941, '#58b3ed'],
    [0.12156862745098039, '#5ab3ec'],
    [0.12549019607843137, '#5bb3ec'],
    [0.12941176470588234, '#5db3eb'],
    [0.13333333333333333, '#5eb3ea'],
    [0.13725490196078433, '#60b3ea'],
    [0.1411764705882353, '#61b3e9'],
    [0.14509803921568626, '#62b2e8'],
    [0.14901960784313725, '#64b2e7'],
    [0.15294117647058825, '#65b2e7'],
    [0.1568627450980392, '#66b2e6'],
    [0.16078431372549018, '#68b2e5'],
    [0.16470588235294117, '#69b2e5'],
    [0.16862745098039217, '#6ab2e4'],
    [0.17254901960784313, '#6bb2e3'],
    [0.1764705882352941, '#6cb2e3'],
    [0.1803921568627451, '#6eb2e2'],
    [0.1843137254901961, '#6fb2e1'],
    [0.18823529411764706, '#70b2e1'],
    [0.19215686274509802, '#71b1e0'],
    [0.19607843137254902, '#72b1df'],
    [0.2, '#73b1df'],
    [0.20392156862745098, '#74b1de'],
    [0.20784313725490194, '#75b1dd'],
    [0.21176470588235294, '#76b1dd'],
    [0.21568627450980393, '#77b1dc'],
    [0.2196078431372549, '#78b1db'],
    [0.22352941176470587, '#79b1da'],
    [0.22745098039215686, '#7ab1da'],
    [0.23137254901960785, '#7bb1d9'],
    [0.23529411764705882, '#7cb0d8'],
    [0.2392156862745098, '#7db0d8'],
    [0.24313725490196078, '#7eb0d7'],
    [0.24705882352941178, '#7fb0d6'],
    [0.25098039215686274, '#80b0d6'],
    [0.2549019607843137, '#81b0d5'],
    [0.2588235294117647, '#82b0d4'],
    [0.2627450980392157, '#82b0d4'],
    [0.26666666666666666, '#83b0d3'],
    [0.27058823529411763, '#84b0d2'],
    [0.27450980392156865, '#85b0d2'],
    [0.2784313725490196, '#86afd1'],
    [0.2823529411764706, '#87afd0'],
    [0.28627450980392155, '#87afd0'],
    [0.2901960784313725, '#88afcf'],
    [0.29411764705882354, '#89afce'],
    [0.2980392156862745, '#8aafce'],
    [0.30196078431372547, '#8bafcd'],
    [0.3058823529411765, '#8bafcc'],
    [0.30980392156862746, '#8cafcb'],
    [0.3137254901960784, '#8dafcb'],
    [0.3176470588235294, '#8eafca'],
    [0.32156862745098036, '#8eaec9'],
    [0.3254901960784314, '#8faec9'],
    [0.32941176470588235, '#90aec8'],
    [0.3333333333333333, '#91aec7'],
    [0.33725490196078434, '#91aec7'],
    [0.3411764705882353, '#92aec6'],
    [0.34509803921568627, '#93aec5'],
    [0.34901960784313724, '#93aec5'],
    [0.3529411764705882, '#94aec4'],
    [0.3568627450980392, '#95aec3'],
    [0.3607843137254902, '#96aec3'],
    [0.36470588235294116, '#96aec2'],
    [0.3686274509803922, '#97adc1'],
    [0.37254901960784315, '#98adc1'],
    [0.3764705882352941, '#98adc0'],
    [0.3803921568627451, '#99adbf'],
    [0.38431372549019605, '#9aadbf'],
    [0.38823529411764707, '#9aadbe'],
    [0.39215686274509803, '#9badbd'],
    [0.396078431372549, '#9badbd'],
    [0.4, '#9cadbc'],
    [0.403921568627451, '#9dadbb'],
    [0.40784313725490196, '#9dadba'],
    [0.4117647058823529, '#9eacba'],
    [0.4156862745098039, '#9facb9'],
    [0.4196078431372549, '#9facb8'],
    [0.4235294117647059, '#a0acb8'],
    [0.42745098039215684, '#a0acb7'],
    [0.43137254901960786, '#a1acb6'],
    [0.43529411764705883, '#a1acb6'],
    [0.4392156862745098, '#a2acb5'],
    [0.44313725490196076, '#a3acb4'],
    [0.44705882352941173, '#a3acb4'],
    [0.45098039215686275, '#a4acb3'],
    [0.4549019607843137, '#a4acb2'],
    [0.4588235294117647, '#a5abb2'],
    [0.4627450980392157, '#a6abb1'],
    [0.4666666666666667, '#a6abb0'],
    [0.47058823529411764, '#a7abb0'],
    [0.4745098039215686, '#a7abaf'],
    [0.4784313725490196, '#a8abae'],
    [0.4823529411764706, '#a8abae'],
    [0.48627450980392156, '#a9abad'],
    [0.49019607843137253, '#aaabad'],
    [0.49411764705882355, '#aaaaac'],
    [0.4980392156862745, '#abaaab'],
    [0.5019607843137255, '#acaaab'],
    [0.5058823529411764, '#acaaaa'],
    [0.5098039215686274, '#adaaaa'],
    [0.5137254901960784, '#aeaaa9'],
    [0.5176470588235293, '#aeaaa9'],
    [0.5215686274509804, '#afa9a8'],
    [0.5254901960784314, '#b0a9a7'],
    [0.5294117647058824, '#b0a9a7'],
    [0.5333333333333333, '#b1a9a6'],
    [0.5372549019607843, '#b2a9a6'],
    [0.5411764705882353, '#b2a9a5'],
    [0.5450980392156862, '#b3a8a5'],
    [0.5490196078431373, '#b4a8a4'],
    [0.5529411764705883, '#b5a8a4'],
    [0.5568627450980392, '#b5a8a3'],
    [0.5607843137254902, '#b6a8a3'],
    [0.5647058823529412, '#b7a8a2'],
    [0.5686274509803921, '#b7a7a2'],
    [0.5725490196078431, '#b8a7a2'],
    [0.5764705882352941, '#b9a7a1'],
    [0.580392156862745, '#b9a7a1'],
    [0.5843137254901961, '#baa7a0'],
    [0.5882352941176471, '#bba6a0'],
    [0.592156862745098, '#bba69f'],
    [0.596078431372549, '#bca69f'],
    [0.6, '#bda69e'],
    [0.6039215686274509, '#bda69e'],
    [0.6078431372549019, '#bea59d'],
    [0.611764705882353, '#bfa59d'],
    [0.615686274509804, '#bfa59c'],
    [0.6196078431372549, '#c0a59c'],
    [0.6235294117647059, '#c1a59b'],
    [0.6274509803921569, '#c1a59b'],
    [0.6313725490196078, '#c2a49a'],
    [0.6352941176470588, '#c3a49a'],
    [0.6392156862745098, '#c3a499'],
    [0.6431372549019607, '#c4a499'],
    [0.6470588235294118, '#c5a498'],
    [0.6509803921568628, '#c5a398'],
    [0.6549019607843137, '#c6a397'],
    [0.6588235294117647, '#c7a397'],
    [0.6627450980392157, '#c7a396'],
    [0.6666666666666666, '#c8a396'],
    [0.6705882352941176, '#c8a295'],
    [0.6745098039215687, '#c9a295'],
    [0.6784313725490196, '#caa294'],
    [0.6823529411764706, '#caa294'],
    [0.6862745098039216, '#cba293'],
    [0.6901960784313725, '#cca193'],
    [0.6941176470588235, '#cca192'],
    [0.6980392156862745, '#cda192'],
    [0.7019607843137254, '#cda191'],
    [0.7058823529411764, '#cea191'],
    [0.7098039215686275, '#cfa090'],
    [0.7137254901960784, '#cfa090'],
    [0.7176470588235294, '#d0a08f'],
    [0.7215686274509804, '#d0a08f'],
    [0.7254901960784313, '#d1a08f'],
    [0.7294117647058823, '#d19f8e'],
    [0.7333333333333333, '#d29f8e'],
    [0.7372549019607844, '#d39f8d'],
    [0.7411764705882353, '#d39f8d'],
    [0.7450980392156863, '#d49e8c'],
    [0.7490196078431373, '#d49e8c'],
    [0.7529411764705882, '#d59e8b'],
    [0.7568627450980392, '#d69e8b'],
    [0.7607843137254902, '#d69e8a'],
    [0.7647058823529411, '#d79d8a'],
    [0.7686274509803921, '#d79d89'],
    [0.7725490196078432, '#d89d89'],
    [0.7764705882352941, '#d89d88'],
    [0.7803921568627451, '#d99d88'],
    [0.7843137254901961, '#da9c87'],
    [0.788235294117647, '#da9c87'],
    [0.792156862745098, '#db9c86'],
    [0.796078431372549, '#db9c86'],
    [0.8, '#dc9c85'],
    [0.803921568627451, '#dc9b85'],
    [0.807843137254902, '#dd9b84'],
    [0.8117647058823529, '#dd9b84'],
    [0.8156862745098039, '#de9b83'],
    [0.8196078431372549, '#de9a83'],
    [0.8235294117647058, '#df9a82'],
    [0.8274509803921568, '#e09a82'],
    [0.8313725490196078, '#e09a81'],
    [0.8352941176470589, '#e19a81'],
    [0.8392156862745098, '#e19980'],
    [0.8431372549019608, '#e29980'],
    [0.8470588235294118, '#e2997f'],
    [0.8509803921568627, '#e3997f'],
    [0.8549019607843137, '#e3987e'],
    [0.8588235294117647, '#e4987e'],
    [0.8627450980392157, '#e4987d'],
    [0.8666666666666667, '#e5987d'],
    [0.8705882352941177, '#e5987c'],
    [0.8745098039215686, '#e6977c'],
    [0.8784313725490196, '#e6977c'],
    [0.8823529411764706, '#e7977b'],
    [0.8862745098039215, '#e7977b'],
    [0.8901960784313725, '#e8967a'],
    [0.8941176470588235, '#e9967a'],
    [0.8980392156862745, '#e99679'],
    [0.9019607843137255, '#ea9679'],
    [0.9058823529411765, '#ea9578'],
    [0.9098039215686274, '#eb9578'],
    [0.9137254901960784, '#eb9577'],
    [0.9176470588235294, '#ec9577'],
    [0.9215686274509803, '#ec9476'],
    [0.9254901960784314, '#ed9476'],
    [0.9294117647058824, '#ed9475'],
    [0.9333333333333333, '#ee9475'],
    [0.9372549019607843, '#ee9474'],
    [0.9411764705882353, '#ef9374'],
    [0.9450980392156862, '#ef9373'],
    [0.9490196078431372, '#f09373'],
    [0.9529411764705882, '#f09372'],
    [0.9568627450980391, '#f19272'],
    [0.9607843137254902, '#f19271'],
    [0.9647058823529412, '#f29271'],
    [0.9686274509803922, '#f29270'],
    [0.9725490196078431, '#f39170'],
    [0.9764705882352941, '#f3916f'],
    [0.9803921568627451, '#f4916f'],
    [0.984313725490196, '#f4916e'],
    [0.9882352941176471, '#f4906e'],
    [0.9921568627450981, '#f5906d'],
    [0.996078431372549, '#f5906d'],
    [1.0, '#f6906c'],
  ],
  CET_D12: [
    [0.0, '#00c8ff'],
    [0.00392156862745098, '#00c8fe'],
    [0.00784313725490196, '#00c8fe'],
    [0.011764705882352941, '#00c8fd'],
    [0.01568627450980392, '#00c8fd'],
    [0.0196078431372549, '#00c7fc'],
    [0.023529411764705882, '#00c7fc'],
    [0.027450980392156862, '#0dc7fb'],
    [0.03137254901960784, '#16c7fb'],
    [0.03529411764705882, '#1dc7fa'],
    [0.0392156862745098, '#22c7f9'],
    [0.043137254901960784, '#27c7f9'],
    [0.047058823529411764, '#2cc7f8'],
    [0.050980392156862744, '#2fc7f8'],
    [0.054901960784313725, '#33c6f7'],
    [0.058823529411764705, '#36c6f7'],
    [0.06274509803921569, '#39c6f6'],
    [0.06666666666666667, '#3cc6f5'],
    [0.07058823529411765, '#3fc6f5'],
    [0.07450980392156863, '#42c6f4'],
    [0.0784313725490196, '#44c6f4'],
    [0.08235294117647059, '#47c6f3'],
    [0.08627450980392157, '#49c5f3'],
    [0.09019607843137255, '#4bc5f2'],
    [0.09411764705882353, '#4dc5f2'],
    [0.09803921568627451, '#4fc5f1'],
    [0.10196078431372549, '#51c5f0'],
    [0.10588235294117647, '#53c5f0'],
    [0.10980392156862745, '#55c5ef'],
    [0.11372549019607843, '#57c5ef'],
    [0.11764705882352941, '#59c4ee'],
    [0.12156862745098039, '#5bc4ee'],
    [0.12549019607843137, '#5cc4ed'],
    [0.12941176470588234, '#5ec4ed'],
    [0.13333333333333333, '#60c4ec'],
    [0.13725490196078433, '#61c4eb'],
    [0.1411764705882353, '#63c4eb'],
    [0.14509803921568626, '#64c4ea'],
    [0.14901960784313725, '#66c4ea'],
    [0.15294117647058825, '#67c3e9'],
    [0.1568627450980392, '#69c3e9'],
    [0.16078431372549018, '#6ac3e8'],
    [0.16470588235294117, '#6bc3e8'],
    [0.16862745098039217, '#6dc3e7'],
    [0.17254901960784313, '#6ec3e6'],
    [0.1764705882352941, '#70c3e6'],
    [0.1803921568627451, '#71c3e5'],
    [0.1843137254901961, '#72c2e5'],
    [0.18823529411764706, '#73c2e4'],
    [0.19215686274509802, '#75c2e4'],
    [0.19607843137254902, '#76c2e3'],
    [0.2, '#77c2e3'],
    [0.20392156862745098, '#78c2e2'],
    [0.20784313725490194, '#79c2e1'],
    [0.21176470588235294, '#7bc2e1'],
    [0.21568627450980393, '#7cc1e0'],
    [0.2196078431372549, '#7dc1e0'],
    [0.22352941176470587, '#7ec1df'],
    [0.22745098039215686, '#7fc1df'],
    [0.23137254901960785, '#80c1de'],
    [0.23529411764705882, '#81c1de'],
    [0.2392156862745098, '#82c1dd'],
    [0.24313725490196078, '#83c1dc'],
    [0.24705882352941178, '#84c0dc'],
    [0.25098039215686274, '#85c0db'],
    [0.2549019607843137, '#87c0db'],
    [0.2588235294117647, '#88c0da'],
    [0.2627450980392157, '#89c0da'],
    [0.26666666666666666, '#8ac0d9'],
    [0.27058823529411763, '#8ac0d9'],
    [0.27450980392156865, '#8bc0d8'],
    [0.2784313725490196, '#8cbfd7'],
    [0.2823529411764706, '#8dbfd7'],
    [0.28627450980392155, '#8ebfd6'],
    [0.2901960784313725, '#8fbfd6'],
    [0.29411764705882354, '#90bfd5'],
    [0.2980392156862745, '#91bfd5'],
    [0.30196078431372547, '#92bfd4'],
    [0.3058823529411765, '#93bfd4'],
    [0.30980392156862746, '#94bed3'],
    [0.3137254901960784, '#95bed2'],
    [0.3176470588235294, '#96bed2'],
    [0.32156862745098036, '#96bed1'],
    [0.3254901960784314, '#97bed1'],
    [0.32941176470588235, '#98bed0'],
    [0.3333333333333333, '#99bed0'],
    [0.33725490196078434, '#9abecf'],
    [0.3411764705882353, '#9bbdcf'],
    [0.34509803921568627, '#9bbdce'],
    [0.34901960784313724, '#9cbdcd'],
    [0.3529411764705882, '#9dbdcd'],
    [0.3568627450980392, '#9ebdcc'],
    [0.3607843137254902, '#9fbdcc'],
    [0.36470588235294116, '#a0bdcb'],
    [0.3686274509803922, '#a0bdcb'],
    [0.37254901960784315, '#a1bcca'],
    [0.3764705882352941, '#a2bcca'],
    [0.3803921568627451, '#a3bcc9'],
    [0.38431372549019605, '#a3bcc8'],
    [0.38823529411764707, '#a4bcc8'],
    [0.39215686274509803, '#a5bcc7'],
    [0.396078431372549, '#a6bcc7'],
    [0.4, '#a6bbc6'],
    [0.403921568627451, '#a7bbc6'],
    [0.40784313725490196, '#a8bbc5'],
    [0.4117647058823529, '#a9bbc5'],
    [0.4156862745098039, '#a9bbc4'],
    [0.4196078431372549, '#aabbc4'],
    [0.4235294117647059, '#abbbc3'],
    [0.42745098039215684, '#abbbc2'],
    [0.43137254901960786, '#acbac2'],
    [0.43529411764705883, '#adbac1'],
    [0.4392156862745098, '#aebac1'],
    [0.44313725490196076, '#aebac0'],
    [0.44705882352941173, '#afbac0'],
    [0.45098039215686275, '#b0babf'],
    [0.4549019607843137, '#b0babf'],
    [0.4588235294117647, '#b1babe'],
    [0.4627450980392157, '#b2b9be'],
    [0.4666666666666667, '#b2b9bd'],
    [0.47058823529411764, '#b3b9bd'],
    [0.4745098039215686, '#b4b9bc'],
    [0.4784313725490196, '#b4b9bc'],
    [0.4823529411764706, '#b5b9bc'],
    [0.48627450980392156, '#b5b9bb'],
    [0.49019607843137253, '#b6b8bb'],
    [0.49411764705882355, '#b7b8bb'],
    [0.4980392156862745, '#b7b8bb'],
    [0.5019607843137255, '#b8b8bb'],
    [0.5058823529411764, '#b8b8bb'],
    [0.5098039215686274, '#b9b8bb'],
    [0.5137254901960784, '#b9b7bb'],
    [0.5176470588235293, '#bab7bb'],
    [0.5215686274509804, '#bab7bb'],
    [0.5254901960784314, '#bbb7bb'],
    [0.5294117647058824, '#bbb7bb'],
    [0.5333333333333333, '#bcb7bb'],
    [0.5372549019607843, '#bcb6bc'],
    [0.5411764705882353, '#bdb6bc'],
    [0.5450980392156862, '#bdb6bc'],
    [0.5490196078431373, '#beb6bc'],
    [0.5529411764705883, '#beb6bd'],
    [0.5568627450980392, '#bfb6bd'],
    [0.5607843137254902, '#bfb5bd'],
    [0.5647058823529412, '#c0b5be'],
    [0.5686274509803921, '#c0b5be'],
    [0.5725490196078431, '#c0b5be'],
    [0.5764705882352941, '#c1b5bf'],
    [0.580392156862745, '#c1b4bf'],
    [0.5843137254901961, '#c2b4bf'],
    [0.5882352941176471, '#c2b4c0'],
    [0.592156862745098, '#c3b4c0'],
    [0.596078431372549, '#c3b4c0'],
    [0.6, '#c3b4c1'],
    [0.6039215686274509, '#c4b3c1'],
    [0.6078431372549019, '#c4b3c1'],
    [0.611764705882353, '#c5b3c2'],
    [0.615686274509804, '#c5b3c2'],
    [0.6196078431372549, '#c6b3c2'],
    [0.6235294117647059, '#c6b2c3'],
    [0.6274509803921569, '#c7b2c3'],
    [0.6313725490196078, '#c7b2c4'],
    [0.6352941176470588, '#c7b2c4'],
    [0.6392156862745098, '#c8b2c4'],
    [0.6431372549019607, '#c8b2c5'],
    [0.6470588235294118, '#c9b1c5'],
    [0.6509803921568628, '#c9b1c5'],
    [0.6549019607843137, '#c9b1c6'],
    [0.6588235294117647, '#cab1c6'],
    [0.6627450980392157, '#cab1c6'],
    [0.6666666666666666, '#cbb0c7'],
    [0.6705882352941176, '#cbb0c7'],
    [0.6745098039215687, '#ccb0c7'],
    [0.6784313725490196, '#ccb0c8'],
    [0.6823529411764706, '#ccb0c8'],
    [0.6862745098039216, '#cdafc8'],
    [0.6901960784313725, '#cdafc9'],
    [0.6941176470588235, '#ceafc9'],
    [0.6980392156862745, '#ceafc9'],
    [0.7019607843137254, '#cfafca'],
    [0.7058823529411764, '#cfafca'],
    [0.7098039215686275, '#cfaeca'],
    [0.7137254901960784, '#d0aecb'],
    [0.7176470588235294, '#d0aecb'],
    [0.7215686274509804, '#d1aecb'],
    [0.7254901960784313, '#d1aecc'],
    [0.7294117647058823, '#d1adcc'],
    [0.7333333333333333, '#d2adcc'],
    [0.7372549019607844, '#d2adcd'],
    [0.7411764705882353, '#d3adcd'],
    [0.7450980392156863, '#d3adcd'],
    [0.7490196078431373, '#d4acce'],
    [0.7529411764705882, '#d4acce'],
    [0.7568627450980392, '#d4acce'],
    [0.7607843137254902, '#d5accf'],
    [0.7647058823529411, '#d5accf'],
    [0.7686274509803921, '#d6abcf'],
    [0.7725490196078432, '#d6abd0'],
    [0.7764705882352941, '#d6abd0'],
    [0.7803921568627451, '#d7abd0'],
    [0.7843137254901961, '#d7aad1'],
    [0.788235294117647, '#d8aad1'],
    [0.792156862745098, '#d8aad1'],
    [0.796078431372549, '#d8aad2'],
    [0.8, '#d9aad2'],
    [0.803921568627451, '#d9a9d2'],
    [0.807843137254902, '#daa9d3'],
    [0.8117647058823529, '#daa9d3'],
    [0.8156862745098039, '#daa9d3'],
    [0.8196078431372549, '#dba9d4'],
    [0.8235294117647058, '#dba8d4'],
    [0.8274509803921568, '#dca8d4'],
    [0.8313725490196078, '#dca8d5'],
    [0.8352941176470589, '#dca8d5'],
    [0.8392156862745098, '#dda7d5'],
    [0.8431372549019608, '#dda7d6'],
    [0.8470588235294118, '#dea7d6'],
    [0.8509803921568627, '#dea7d6'],
    [0.8549019607843137, '#dea7d7'],
    [0.8588235294117647, '#dfa6d7'],
    [0.8627450980392157, '#dfa6d7'],
    [0.8666666666666667, '#e0a6d8'],
    [0.8705882352941177, '#e0a6d8'],
    [0.8745098039215686, '#e0a6d8'],
    [0.8784313725490196, '#e1a5d9'],
    [0.8823529411764706, '#e1a5d9'],
    [0.8862745098039215, '#e2a5d9'],
    [0.8901960784313725, '#e2a5da'],
    [0.8941176470588235, '#e2a4da'],
    [0.8980392156862745, '#e3a4da'],
    [0.9019607843137255, '#e3a4db'],
    [0.9058823529411765, '#e4a4db'],
    [0.9098039215686274, '#e4a3db'],
    [0.9137254901960784, '#e4a3dc'],
    [0.9176470588235294, '#e5a3dc'],
    [0.9215686274509803, '#e5a3dc'],
    [0.9254901960784314, '#e6a3dd'],
    [0.9294117647058824, '#e6a2dd'],
    [0.9333333333333333, '#e6a2dd'],
    [0.9372549019607843, '#e7a2de'],
    [0.9411764705882353, '#e7a2de'],
    [0.9450980392156862, '#e8a1de'],
    [0.9490196078431372, '#e8a1df'],
    [0.9529411764705882, '#e8a1df'],
    [0.9568627450980391, '#e9a1df'],
    [0.9607843137254902, '#e9a0e0'],
    [0.9647058823529412, '#e9a0e0'],
    [0.9686274509803922, '#eaa0e0'],
    [0.9725490196078431, '#eaa0e1'],
    [0.9764705882352941, '#eb9fe1'],
    [0.9803921568627451, '#eb9fe1'],
    [0.984313725490196, '#eb9fe2'],
    [0.9882352941176471, '#ec9fe2'],
    [0.9921568627450981, '#ec9ee3'],
    [0.996078431372549, '#ed9ee3'],
    [1.0, '#ed9ee3'],
  ],
  CET_D13: [
    [0.0, '#102c67'],
    [0.00392156862745098, '#112e6a'],
    [0.00784313725490196, '#122f6c'],
    [0.011764705882352941, '#13306e'],
    [0.01568627450980392, '#153170'],
    [0.0196078431372549, '#163372'],
    [0.023529411764705882, '#173474'],
    [0.027450980392156862, '#183576'],
    [0.03137254901960784, '#193679'],
    [0.03529411764705882, '#1a387b'],
    [0.0392156862745098, '#1b397d'],
    [0.043137254901960784, '#1c3a7f'],
    [0.047058823529411764, '#1d3c81'],
    [0.050980392156862744, '#1e3d83'],
    [0.054901960784313725, '#1f3e85'],
    [0.058823529411764705, '#203f87'],
    [0.06274509803921569, '#20418a'],
    [0.06666666666666667, '#21428c'],
    [0.07058823529411765, '#22438e'],
    [0.07450980392156863, '#234590'],
    [0.0784313725490196, '#244692'],
    [0.08235294117647059, '#254894'],
    [0.08627450980392157, '#264996'],
    [0.09019607843137255, '#274a98'],
    [0.09411764705882353, '#274c9b'],
    [0.09803921568627451, '#284d9d'],
    [0.10196078431372549, '#294f9f'],
    [0.10588235294117647, '#2a50a1'],
    [0.10980392156862745, '#2b51a3'],
    [0.11372549019607843, '#2b53a5'],
    [0.11764705882352941, '#2c54a7'],
    [0.12156862745098039, '#2d56a9'],
    [0.12549019607843137, '#2e57ab'],
    [0.12941176470588234, '#2e59ad'],
    [0.13333333333333333, '#2f5aaf'],
    [0.13725490196078433, '#305cb1'],
    [0.1411764705882353, '#305db3'],
    [0.14509803921568626, '#315fb5'],
    [0.14901960784313725, '#3160b7'],
    [0.15294117647058825, '#3262b9'],
    [0.1568627450980392, '#3363bb'],
    [0.16078431372549018, '#3365bd'],
    [0.16470588235294117, '#3367bf'],
    [0.16862745098039217, '#3468c1'],
    [0.17254901960784313, '#346ac3'],
    [0.1764705882352941, '#356bc5'],
    [0.1803921568627451, '#356dc7'],
    [0.1843137254901961, '#356fc9'],
    [0.18823529411764706, '#3670ca'],
    [0.19215686274509802, '#3672cc'],
    [0.19607843137254902, '#3674ce'],
    [0.2, '#3675d0'],
    [0.20392156862745098, '#3677d2'],
    [0.20784313725490194, '#3679d3'],
    [0.21176470588235294, '#367ad5'],
    [0.21568627450980393, '#367cd7'],
    [0.2196078431372549, '#367ed8'],
    [0.22352941176470587, '#3580da'],
    [0.22745098039215686, '#3581dc'],
    [0.23137254901960785, '#3583dd'],
    [0.23529411764705882, '#3485df'],
    [0.2392156862745098, '#3487e0'],
    [0.24313725490196078, '#3389e2'],
    [0.24705882352941178, '#338be3'],
    [0.25098039215686274, '#328ce4'],
    [0.2549019607843137, '#328ee6'],
    [0.2588235294117647, '#3190e7'],
    [0.2627450980392157, '#3192e8'],
    [0.26666666666666666, '#3194e9'],
    [0.27058823529411763, '#3196eb'],
    [0.27450980392156865, '#3297ec'],
    [0.2784313725490196, '#3299ed'],
    [0.2823529411764706, '#339bee'],
    [0.28627450980392155, '#349def'],
    [0.2901960784313725, '#359ff0'],
    [0.29411764705882354, '#37a0f1'],
    [0.2980392156862745, '#39a2f2'],
    [0.30196078431372547, '#3ba4f3'],
    [0.3058823529411765, '#3da6f3'],
    [0.30980392156862746, '#3fa7f4'],
    [0.3137254901960784, '#42a9f5'],
    [0.3176470588235294, '#44abf6'],
    [0.32156862745098036, '#47acf6'],
    [0.3254901960784314, '#4aaef7'],
    [0.32941176470588235, '#4db0f7'],
    [0.3333333333333333, '#50b1f8'],
    [0.33725490196078434, '#54b3f8'],
    [0.3411764705882353, '#57b5f9'],
    [0.34509803921568627, '#5ab6f9'],
    [0.34901960784313724, '#5eb8fa'],
    [0.3529411764705882, '#61b9fa'],
    [0.3568627450980392, '#65bbfa'],
    [0.3607843137254902, '#69bcfa'],
    [0.36470588235294116, '#6cbefb'],
    [0.3686274509803922, '#70c0fb'],
    [0.37254901960784315, '#74c1fb'],
    [0.3764705882352941, '#78c3fb'],
    [0.3803921568627451, '#7cc4fb'],
    [0.38431372549019605, '#7fc6fb'],
    [0.38823529411764707, '#83c7fb'],
    [0.39215686274509803, '#87c9fb'],
    [0.396078431372549, '#8bcafb'],
    [0.4, '#8fcbfb'],
    [0.403921568627451, '#93cdfa'],
    [0.40784313725490196, '#97cefa'],
    [0.4117647058823529, '#9bd0fa'],
    [0.4156862745098039, '#9fd1fa'],
    [0.4196078431372549, '#a3d2f9'],
    [0.4235294117647059, '#a7d4f9'],
    [0.42745098039215684, '#abd5f9'],
    [0.43137254901960786, '#afd7f8'],
    [0.43529411764705883, '#b3d8f8'],
    [0.4392156862745098, '#b6d9f7'],
    [0.44313725490196076, '#badbf7'],
    [0.44705882352941173, '#bedcf7'],
    [0.45098039215686275, '#c2def6'],
    [0.4549019607843137, '#c5dff6'],
    [0.4588235294117647, '#c9e0f5'],
    [0.4627450980392157, '#cce2f5'],
    [0.4666666666666667, '#d0e3f4'],
    [0.47058823529411764, '#d3e4f4'],
    [0.4745098039215686, '#d6e5f3'],
    [0.4784313725490196, '#d8e7f2'],
    [0.4823529411764706, '#dbe8f1'],
    [0.48627450980392156, '#dce9f1'],
    [0.49019607843137253, '#dee9f0'],
    [0.49411764705882355, '#dfeaee'],
    [0.4980392156862745, '#dfeaed'],
    [0.5019607843137255, '#dfebec'],
    [0.5058823529411764, '#deebea'],
    [0.5098039215686274, '#dceae9'],
    [0.5137254901960784, '#daeae7'],
    [0.5176470588235293, '#d8eae5'],
    [0.5215686274509804, '#d5e9e3'],
    [0.5254901960784314, '#d2e8e1'],
    [0.5294117647058824, '#cee7e0'],
    [0.5333333333333333, '#cae7de'],
    [0.5372549019607843, '#c7e6dc'],
    [0.5411764705882353, '#c3e5da'],
    [0.5450980392156862, '#bfe4d8'],
    [0.5490196078431373, '#bbe3d6'],
    [0.5529411764705883, '#b7e2d4'],
    [0.5568627450980392, '#b2e0d2'],
    [0.5607843137254902, '#aedfd0'],
    [0.5647058823529412, '#aadece'],
    [0.5686274509803921, '#a6ddcc'],
    [0.5725490196078431, '#a2dcca'],
    [0.5764705882352941, '#9ddbc8'],
    [0.580392156862745, '#99dac6'],
    [0.5843137254901961, '#95d9c4'],
    [0.5882352941176471, '#91d8c2'],
    [0.592156862745098, '#8dd6c0'],
    [0.596078431372549, '#89d5be'],
    [0.6, '#84d4bc'],
    [0.6039215686274509, '#80d3ba'],
    [0.6078431372549019, '#7cd2b8'],
    [0.611764705882353, '#78d0b6'],
    [0.615686274509804, '#74cfb4'],
    [0.6196078431372549, '#70ceb2'],
    [0.6235294117647059, '#6ccdb0'],
    [0.6274509803921569, '#69cbad'],
    [0.6313725490196078, '#65caab'],
    [0.6352941176470588, '#61c9a9'],
    [0.6392156862745098, '#5dc7a7'],
    [0.6431372549019607, '#5ac6a4'],
    [0.6470588235294118, '#56c4a2'],
    [0.6509803921568628, '#53c3a0'],
    [0.6549019607843137, '#50c29d'],
    [0.6588235294117647, '#4cc09b'],
    [0.6627450980392157, '#49bf99'],
    [0.6666666666666666, '#46bd96'],
    [0.6705882352941176, '#43bc94'],
    [0.6745098039215687, '#40ba91'],
    [0.6784313725490196, '#3db98f'],
    [0.6823529411764706, '#3ab78c'],
    [0.6862745098039216, '#38b68a'],
    [0.6901960784313725, '#35b487'],
    [0.6941176470588235, '#33b385'],
    [0.6980392156862745, '#31b182'],
    [0.7019607843137254, '#2fb080'],
    [0.7058823529411764, '#2dae7d'],
    [0.7098039215686275, '#2bad7a'],
    [0.7137254901960784, '#2aab78'],
    [0.7176470588235294, '#28a975'],
    [0.7215686274509804, '#27a872'],
    [0.7254901960784313, '#26a66f'],
    [0.7294117647058823, '#25a56d'],
    [0.7333333333333333, '#25a36a'],
    [0.7372549019607844, '#24a167'],
    [0.7411764705882353, '#24a065'],
    [0.7450980392156863, '#249e62'],
    [0.7490196078431373, '#249d5f'],
    [0.7529411764705882, '#239b5d'],
    [0.7568627450980392, '#23995a'],
    [0.7607843137254902, '#239857'],
    [0.7647058823529411, '#239655'],
    [0.7686274509803921, '#239452'],
    [0.7725490196078432, '#239350'],
    [0.7764705882352941, '#23914e'],
    [0.7803921568627451, '#228f4b'],
    [0.7843137254901961, '#228e49'],
    [0.788235294117647, '#228c47'],
    [0.792156862745098, '#228b45'],
    [0.796078431372549, '#218943'],
    [0.8, '#218740'],
    [0.803921568627451, '#20863e'],
    [0.807843137254902, '#20843d'],
    [0.8117647058823529, '#1f823b'],
    [0.8156862745098039, '#1f8139'],
    [0.8196078431372549, '#1e7f37'],
    [0.8235294117647058, '#1e7e35'],
    [0.8274509803921568, '#1d7c33'],
    [0.8313725490196078, '#1d7a32'],
    [0.8352941176470589, '#1c7930'],
    [0.8392156862745098, '#1b772e'],
    [0.8431372549019608, '#1b762d'],
    [0.8470588235294118, '#1a742b'],
    [0.8509803921568627, '#19722a'],
    [0.8549019607843137, '#197128'],
    [0.8588235294117647, '#186f27'],
    [0.8627450980392157, '#176e25'],
    [0.8666666666666667, '#176c24'],
    [0.8705882352941177, '#166b23'],
    [0.8745098039215686, '#156921'],
    [0.8784313725490196, '#146720'],
    [0.8823529411764706, '#14661f'],
    [0.8862745098039215, '#13641d'],
    [0.8901960784313725, '#12631c'],
    [0.8941176470588235, '#11611b'],
    [0.8980392156862745, '#10601a'],
    [0.9019607843137255, '#105e19'],
    [0.9058823529411765, '#0f5d17'],
    [0.9098039215686274, '#0e5b16'],
    [0.9137254901960784, '#0d5a15'],
    [0.9176470588235294, '#0c5814'],
    [0.9215686274509803, '#0b5713'],
    [0.9254901960784314, '#0b5512'],
    [0.9294117647058824, '#0a5411'],
    [0.9333333333333333, '#095210'],
    [0.9372549019607843, '#08510f'],
    [0.9411764705882353, '#074f0e'],
    [0.9450980392156862, '#064e0d'],
    [0.9490196078431372, '#064c0c'],
    [0.9529411764705882, '#054b0b'],
    [0.9568627450980391, '#04490a'],
    [0.9607843137254902, '#044809'],
    [0.9647058823529412, '#034608'],
    [0.9686274509803922, '#024508'],
    [0.9725490196078431, '#024307'],
    [0.9764705882352941, '#014206'],
    [0.9803921568627451, '#014005'],
    [0.984313725490196, '#003f04'],
    [0.9882352941176471, '#003d04'],
    [0.9921568627450981, '#003c03'],
    [0.996078431372549, '#003b02'],
    [1.0, '#003901'],
  ],
  CET_D1A: [
    [0.0, '#172971'],
    [0.00392156862745098, '#172a74'],
    [0.00784313725490196, '#182b77'],
    [0.011764705882352941, '#182c7a'],
    [0.01568627450980392, '#182d7d'],
    [0.0196078431372549, '#192e80'],
    [0.023529411764705882, '#192f83'],
    [0.027450980392156862, '#193086'],
    [0.03137254901960784, '#1a3189'],
    [0.03529411764705882, '#1a328c'],
    [0.0392156862745098, '#1a348f'],
    [0.043137254901960784, '#1b3592'],
    [0.047058823529411764, '#1b3695'],
    [0.050980392156862744, '#1b3798'],
    [0.054901960784313725, '#1c389b'],
    [0.058823529411764705, '#1c399e'],
    [0.06274509803921569, '#1c3aa1'],
    [0.06666666666666667, '#1d3ca4'],
    [0.07058823529411765, '#1d3da7'],
    [0.07450980392156863, '#1d3eaa'],
    [0.0784313725490196, '#1e3fad'],
    [0.08235294117647059, '#1e40b0'],
    [0.08627450980392157, '#1e41b3'],
    [0.09019607843137255, '#1f43b6'],
    [0.09411764705882353, '#1f44b9'],
    [0.09803921568627451, '#2045bc'],
    [0.10196078431372549, '#2146bf'],
    [0.10588235294117647, '#2147c2'],
    [0.10980392156862745, '#2249c5'],
    [0.11372549019607843, '#244ac7'],
    [0.11764705882352941, '#254bca'],
    [0.12156862745098039, '#264ccc'],
    [0.12549019607843137, '#284ecf'],
    [0.12941176470588234, '#2a4fd1'],
    [0.13333333333333333, '#2c50d3'],
    [0.13725490196078433, '#2e52d5'],
    [0.1411764705882353, '#3153d7'],
    [0.14509803921568626, '#3354d9'],
    [0.14901960784313725, '#3656da'],
    [0.15294117647058825, '#3957dc'],
    [0.1568627450980392, '#3b59dd'],
    [0.16078431372549018, '#3e5adf'],
    [0.16470588235294117, '#415be0'],
    [0.16862745098039217, '#445de1'],
    [0.17254901960784313, '#475ee2'],
    [0.1764705882352941, '#4960e3'],
    [0.1803921568627451, '#4c61e4'],
    [0.1843137254901961, '#4f63e5'],
    [0.18823529411764706, '#5164e6'],
    [0.19215686274509802, '#5466e7'],
    [0.19607843137254902, '#5767e7'],
    [0.2, '#5969e8'],
    [0.20392156862745098, '#5c6ae9'],
    [0.20784313725490194, '#5e6cea'],
    [0.21176470588235294, '#606deb'],
    [0.21568627450980393, '#636fec'],
    [0.2196078431372549, '#6571ed'],
    [0.22352941176470587, '#6872ed'],
    [0.22745098039215686, '#6a74ee'],
    [0.23137254901960785, '#6c75ef'],
    [0.23529411764705882, '#6e77f0'],
    [0.2392156862745098, '#7078f1'],
    [0.24313725490196078, '#737af2'],
    [0.24705882352941178, '#757bf2'],
    [0.25098039215686274, '#777df3'],
    [0.2549019607843137, '#797ff4'],
    [0.2588235294117647, '#7b80f5'],
    [0.2627450980392157, '#7d82f5'],
    [0.26666666666666666, '#7f83f6'],
    [0.27058823529411763, '#8285f7'],
    [0.27450980392156865, '#8487f8'],
    [0.2784313725490196, '#8688f8'],
    [0.2823529411764706, '#888af9'],
    [0.28627450980392155, '#8a8bf9'],
    [0.2901960784313725, '#8c8dfa'],
    [0.29411764705882354, '#8e8ffa'],
    [0.2980392156862745, '#9090fb'],
    [0.30196078431372547, '#9292fb'],
    [0.3058823529411765, '#9494fc'],
    [0.30980392156862746, '#9695fc'],
    [0.3137254901960784, '#9997fc'],
    [0.3176470588235294, '#9b99fc'],
    [0.32156862745098036, '#9d9bfc'],
    [0.3254901960784314, '#9f9cfc'],
    [0.32941176470588235, '#a19efc'],
    [0.3333333333333333, '#a3a0fc'],
    [0.33725490196078434, '#a5a2fc'],
    [0.3411764705882353, '#a7a3fc'],
    [0.34509803921568627, '#a9a5fc'],
    [0.34901960784313724, '#aba7fb'],
    [0.3529411764705882, '#ada9fb'],
    [0.3568627450980392, '#afabfb'],
    [0.3607843137254902, '#b1acfb'],
    [0.36470588235294116, '#b3aefb'],
    [0.3686274509803922, '#b5b0fa'],
    [0.37254901960784315, '#b7b2fa'],
    [0.3764705882352941, '#b9b4fa'],
    [0.3803921568627451, '#bab5fa'],
    [0.38431372549019605, '#bcb7f9'],
    [0.38823529411764707, '#beb9f9'],
    [0.39215686274509803, '#c0bbf9'],
    [0.396078431372549, '#c2bdf9'],
    [0.4, '#c4bef8'],
    [0.403921568627451, '#c5c0f8'],
    [0.40784313725490196, '#c7c2f8'],
    [0.4117647058823529, '#c9c4f7'],
    [0.4156862745098039, '#cbc6f7'],
    [0.4196078431372549, '#ccc8f7'],
    [0.4235294117647059, '#cec9f7'],
    [0.42745098039215684, '#d0cbf6'],
    [0.43137254901960786, '#d2cdf6'],
    [0.43529411764705883, '#d3cff6'],
    [0.4392156862745098, '#d5d1f5'],
    [0.44313725490196076, '#d7d3f5'],
    [0.44705882352941173, '#d8d4f5'],
    [0.45098039215686275, '#dad6f4'],
    [0.4549019607843137, '#dcd8f4'],
    [0.4588235294117647, '#ddd9f3'],
    [0.4627450980392157, '#dfdbf3'],
    [0.4666666666666667, '#e1dcf2'],
    [0.47058823529411764, '#e2def1'],
    [0.4745098039215686, '#e4dff0'],
    [0.4784313725490196, '#e6e0ef'],
    [0.4823529411764706, '#e7e1ee'],
    [0.48627450980392156, '#e9e2ed'],
    [0.49019607843137253, '#eae2ec'],
    [0.49411764705882355, '#ece2ea'],
    [0.4980392156862745, '#ede2e8'],
    [0.5019607843137255, '#eee2e6'],
    [0.5058823529411764, '#f0e2e4'],
    [0.5098039215686274, '#f1e1e2'],
    [0.5137254901960784, '#f2e0e0'],
    [0.5176470588235293, '#f3dfde'],
    [0.5215686274509804, '#f4dedb'],
    [0.5254901960784314, '#f5dcd9'],
    [0.5294117647058824, '#f5dad6'],
    [0.5333333333333333, '#f6d9d3'],
    [0.5372549019607843, '#f7d7d0'],
    [0.5411764705882353, '#f8d5ce'],
    [0.5450980392156862, '#f8d3cb'],
    [0.5490196078431373, '#f9d1c8'],
    [0.5529411764705883, '#f9cec5'],
    [0.5568627450980392, '#f9ccc2'],
    [0.5607843137254902, '#facabf'],
    [0.5647058823529412, '#fac8bd'],
    [0.5686274509803921, '#fbc5ba'],
    [0.5725490196078431, '#fbc3b7'],
    [0.5764705882352941, '#fbc1b4'],
    [0.580392156862745, '#fbbeb1'],
    [0.5843137254901961, '#fcbcae'],
    [0.5882352941176471, '#fcbaac'],
    [0.592156862745098, '#fcb7a9'],
    [0.596078431372549, '#fcb5a6'],
    [0.6, '#fcb3a3'],
    [0.6039215686274509, '#fcb0a0'],
    [0.6078431372549019, '#fcae9d'],
    [0.611764705882353, '#fcac9b'],
    [0.615686274509804, '#fca998'],
    [0.6196078431372549, '#fca795'],
    [0.6235294117647059, '#fca592'],
    [0.6274509803921569, '#fca290'],
    [0.6313725490196078, '#fca08d'],
    [0.6352941176470588, '#fc9d8a'],
    [0.6392156862745098, '#fc9b87'],
    [0.6431372549019607, '#fc9985'],
    [0.6470588235294118, '#fc9682'],
    [0.6509803921568628, '#fb947f'],
    [0.6549019607843137, '#fb917d'],
    [0.6588235294117647, '#fb8f7a'],
    [0.6627450980392157, '#fb8d77'],
    [0.6666666666666666, '#fa8a75'],
    [0.6705882352941176, '#fa8872'],
    [0.6745098039215687, '#f9856f'],
    [0.6784313725490196, '#f9836d'],
    [0.6823529411764706, '#f8816a'],
    [0.6862745098039216, '#f87e68'],
    [0.6901960784313725, '#f77c66'],
    [0.6941176470588235, '#f77a63'],
    [0.6980392156862745, '#f67761'],
    [0.7019607843137254, '#f5755f'],
    [0.7058823529411764, '#f4735c'],
    [0.7098039215686275, '#f3715a'],
    [0.7137254901960784, '#f26e58'],
    [0.7176470588235294, '#f16c56'],
    [0.7215686274509804, '#f06a54'],
    [0.7254901960784313, '#ef6852'],
    [0.7294117647058823, '#ee6650'],
    [0.7333333333333333, '#ed644e'],
    [0.7372549019607844, '#ec614c'],
    [0.7411764705882353, '#eb5f4a'],
    [0.7450980392156863, '#e95d48'],
    [0.7490196078431373, '#e85b46'],
    [0.7529411764705882, '#e75944'],
    [0.7568627450980392, '#e65642'],
    [0.7607843137254902, '#e45440'],
    [0.7647058823529411, '#e3523e'],
    [0.7686274509803921, '#e2503c'],
    [0.7725490196078432, '#e14e3a'],
    [0.7764705882352941, '#df4b38'],
    [0.7803921568627451, '#de4936'],
    [0.7843137254901961, '#dd4735'],
    [0.788235294117647, '#dc4433'],
    [0.792156862745098, '#da4231'],
    [0.796078431372549, '#d93f2f'],
    [0.8, '#d83d2d'],
    [0.803921568627451, '#d63a2b'],
    [0.807843137254902, '#d53829'],
    [0.8117647058823529, '#d43527'],
    [0.8156862745098039, '#d23326'],
    [0.8196078431372549, '#d13024'],
    [0.8235294117647058, '#d02d22'],
    [0.8274509803921568, '#ce2a20'],
    [0.8313725490196078, '#cd271e'],
    [0.8352941176470589, '#cb241d'],
    [0.8392156862745098, '#c9211b'],
    [0.8431372549019608, '#c81e19'],
    [0.8470588235294118, '#c61b18'],
    [0.8509803921568627, '#c41816'],
    [0.8549019607843137, '#c31515'],
    [0.8588235294117647, '#c11214'],
    [0.8627450980392157, '#bf0f12'],
    [0.8666666666666667, '#bd0c11'],
    [0.8705882352941177, '#ba0910'],
    [0.8745098039215686, '#b8060f'],
    [0.8784313725490196, '#b6040e'],
    [0.8823529411764706, '#b4030e'],
    [0.8862745098039215, '#b1010d'],
    [0.8901960784313725, '#af010c'],
    [0.8941176470588235, '#ac000c'],
    [0.8980392156862745, '#aa000b'],
    [0.9019607843137255, '#a7000b'],
    [0.9058823529411765, '#a4000b'],
    [0.9098039215686274, '#a2000a'],
    [0.9137254901960784, '#9f000a'],
    [0.9176470588235294, '#9c010a'],
    [0.9215686274509803, '#9a010a'],
    [0.9254901960784314, '#970109'],
    [0.9294117647058824, '#940209'],
    [0.9333333333333333, '#920209'],
    [0.9372549019607843, '#8f0309'],
    [0.9411764705882353, '#8c0308'],
    [0.9450980392156862, '#8a0408'],
    [0.9490196078431372, '#870408'],
    [0.9529411764705882, '#840407'],
    [0.9568627450980391, '#820507'],
    [0.9607843137254902, '#7f0506'],
    [0.9647058823529412, '#7c0506'],
    [0.9686274509803922, '#7a0506'],
    [0.9725490196078431, '#770605'],
    [0.9764705882352941, '#750605'],
    [0.9803921568627451, '#720604'],
    [0.984313725490196, '#700604'],
    [0.9882352941176471, '#6d0603'],
    [0.9921568627450981, '#6b0603'],
    [0.996078431372549, '#680702'],
    [1.0, '#660702'],
  ],
  CET_D2: [
    [0.0, '#38960e'],
    [0.00392156862745098, '#3a9711'],
    [0.00784313725490196, '#3c9814'],
    [0.011764705882352941, '#3e9817'],
    [0.01568627450980392, '#40991a'],
    [0.0196078431372549, '#429a1c'],
    [0.023529411764705882, '#449b1f'],
    [0.027450980392156862, '#469b21'],
    [0.03137254901960784, '#479c23'],
    [0.03529411764705882, '#499d25'],
    [0.0392156862745098, '#4b9d28'],
    [0.043137254901960784, '#4d9e2a'],
    [0.047058823529411764, '#4e9f2c'],
    [0.050980392156862744, '#50a02e'],
    [0.054901960784313725, '#52a030'],
    [0.058823529411764705, '#53a132'],
    [0.06274509803921569, '#55a233'],
    [0.06666666666666667, '#57a235'],
    [0.07058823529411765, '#58a337'],
    [0.07450980392156863, '#5aa439'],
    [0.0784313725490196, '#5ca53b'],
    [0.08235294117647059, '#5da53d'],
    [0.08627450980392157, '#5fa63e'],
    [0.09019607843137255, '#60a740'],
    [0.09411764705882353, '#62a742'],
    [0.09803921568627451, '#63a844'],
    [0.10196078431372549, '#65a945'],
    [0.10588235294117647, '#66a947'],
    [0.10980392156862745, '#68aa49'],
    [0.11372549019607843, '#69ab4a'],
    [0.11764705882352941, '#6bac4c'],
    [0.12156862745098039, '#6cac4e'],
    [0.12549019607843137, '#6ead4f'],
    [0.12941176470588234, '#6fae51'],
    [0.13333333333333333, '#71ae53'],
    [0.13725490196078433, '#72af54'],
    [0.1411764705882353, '#74b056'],
    [0.14509803921568626, '#75b158'],
    [0.14901960784313725, '#77b159'],
    [0.15294117647058825, '#78b25b'],
    [0.1568627450980392, '#7ab35d'],
    [0.16078431372549018, '#7bb35e'],
    [0.16470588235294117, '#7cb460'],
    [0.16862745098039217, '#7eb562'],
    [0.17254901960784313, '#7fb563'],
    [0.1764705882352941, '#81b665'],
    [0.1803921568627451, '#82b767'],
    [0.1843137254901961, '#84b868'],
    [0.18823529411764706, '#85b86a'],
    [0.19215686274509802, '#86b96c'],
    [0.19607843137254902, '#88ba6d'],
    [0.2, '#89ba6f'],
    [0.20392156862745098, '#8abb70'],
    [0.20784313725490194, '#8cbc72'],
    [0.21176470588235294, '#8dbd74'],
    [0.21568627450980393, '#8fbd75'],
    [0.2196078431372549, '#90be77'],
    [0.22352941176470587, '#91bf79'],
    [0.22745098039215686, '#93bf7a'],
    [0.23137254901960785, '#94c07c'],
    [0.23529411764705882, '#96c17e'],
    [0.2392156862745098, '#97c17f'],
    [0.24313725490196078, '#98c281'],
    [0.24705882352941178, '#9ac382'],
    [0.25098039215686274, '#9bc484'],
    [0.2549019607843137, '#9cc486'],
    [0.2588235294117647, '#9ec587'],
    [0.2627450980392157, '#9fc689'],
    [0.26666666666666666, '#a0c68b'],
    [0.27058823529411763, '#a2c78c'],
    [0.27450980392156865, '#a3c88e'],
    [0.2784313725490196, '#a4c890'],
    [0.2823529411764706, '#a6c991'],
    [0.28627450980392155, '#a7ca93'],
    [0.2901960784313725, '#a8ca95'],
    [0.29411764705882354, '#aacb96'],
    [0.2980392156862745, '#abcc98'],
    [0.30196078431372547, '#accd99'],
    [0.3058823529411765, '#aecd9b'],
    [0.30980392156862746, '#afce9d'],
    [0.3137254901960784, '#b0cf9e'],
    [0.3176470588235294, '#b2cfa0'],
    [0.32156862745098036, '#b3d0a2'],
    [0.3254901960784314, '#b4d1a3'],
    [0.32941176470588235, '#b6d1a5'],
    [0.3333333333333333, '#b7d2a7'],
    [0.33725490196078434, '#b8d3a8'],
    [0.3411764705882353, '#bad4aa'],
    [0.34509803921568627, '#bbd4ac'],
    [0.34901960784313724, '#bcd5ad'],
    [0.3529411764705882, '#bed6af'],
    [0.3568627450980392, '#bfd6b1'],
    [0.3607843137254902, '#c0d7b2'],
    [0.36470588235294116, '#c2d8b4'],
    [0.3686274509803922, '#c3d8b6'],
    [0.37254901960784315, '#c4d9b7'],
    [0.3764705882352941, '#c6dab9'],
    [0.3803921568627451, '#c7dabb'],
    [0.38431372549019605, '#c8dbbc'],
    [0.38823529411764707, '#cadcbe'],
    [0.39215686274509803, '#cbddc0'],
    [0.396078431372549, '#ccddc1'],
    [0.4, '#cedec3'],
    [0.403921568627451, '#cfdfc5'],
    [0.40784313725490196, '#d0dfc6'],
    [0.4117647058823529, '#d1e0c8'],
    [0.4156862745098039, '#d3e1ca'],
    [0.4196078431372549, '#d4e1cc'],
    [0.4235294117647059, '#d5e2cd'],
    [0.42745098039215684, '#d7e3cf'],
    [0.43137254901960786, '#d8e3d1'],
    [0.43529411764705883, '#d9e4d2'],
    [0.4392156862745098, '#dbe5d4'],
    [0.44313725490196076, '#dce5d6'],
    [0.44705882352941173, '#dde6d7'],
    [0.45098039215686275, '#dee7d9'],
    [0.4549019607843137, '#e0e7db'],
    [0.4588235294117647, '#e1e8dc'],
    [0.4627450980392157, '#e2e8de'],
    [0.4666666666666667, '#e3e9e0'],
    [0.47058823529411764, '#e4e9e1'],
    [0.4745098039215686, '#e6eae3'],
    [0.4784313725490196, '#e7eae4'],
    [0.4823529411764706, '#e8eae5'],
    [0.48627450980392156, '#e8eae7'],
    [0.49019607843137253, '#e9ebe8'],
    [0.49411764705882355, '#eaebe9'],
    [0.4980392156862745, '#ebeaea'],
    [0.5019607843137255, '#ebeaeb'],
    [0.5058823529411764, '#eceaec'],
    [0.5098039215686274, '#ece9ec'],
    [0.5137254901960784, '#ece9ed'],
    [0.5176470588235293, '#ece8ee'],
    [0.5215686274509804, '#ece7ee'],
    [0.5254901960784314, '#ece7ee'],
    [0.5294117647058824, '#ece6ef'],
    [0.5333333333333333, '#ece5ef'],
    [0.5372549019607843, '#ece4ef'],
    [0.5411764705882353, '#ece3ef'],
    [0.5450980392156862, '#ece2ef'],
    [0.5490196078431373, '#ebe1ef'],
    [0.5529411764705883, '#ebe0ef'],
    [0.5568627450980392, '#ebdeef'],
    [0.5607843137254902, '#eaddef'],
    [0.5647058823529412, '#eadcef'],
    [0.5686274509803921, '#eadbef'],
    [0.5725490196078431, '#e9daef'],
    [0.5764705882352941, '#e9d9ef'],
    [0.580392156862745, '#e9d8ef'],
    [0.5843137254901961, '#e8d6ef'],
    [0.5882352941176471, '#e8d5ef'],
    [0.592156862745098, '#e8d4ef'],
    [0.596078431372549, '#e7d3ee'],
    [0.6, '#e7d2ee'],
    [0.6039215686274509, '#e7d1ee'],
    [0.6078431372549019, '#e6d0ee'],
    [0.611764705882353, '#e6ceee'],
    [0.615686274509804, '#e5cdee'],
    [0.6196078431372549, '#e5ccee'],
    [0.6235294117647059, '#e5cbee'],
    [0.6274509803921569, '#e4caee'],
    [0.6313725490196078, '#e4c9ee'],
    [0.6352941176470588, '#e4c8ee'],
    [0.6392156862745098, '#e3c6ee'],
    [0.6431372549019607, '#e3c5ed'],
    [0.6470588235294118, '#e2c4ed'],
    [0.6509803921568628, '#e2c3ed'],
    [0.6549019607843137, '#e2c2ed'],
    [0.6588235294117647, '#e1c1ed'],
    [0.6627450980392157, '#e1c0ed'],
    [0.6666666666666666, '#e0beed'],
    [0.6705882352941176, '#e0bded'],
    [0.6745098039215687, '#e0bced'],
    [0.6784313725490196, '#dfbbed'],
    [0.6823529411764706, '#dfbaed'],
    [0.6862745098039216, '#deb9ed'],
    [0.6901960784313725, '#deb8ec'],
    [0.6941176470588235, '#ddb6ec'],
    [0.6980392156862745, '#ddb5ec'],
    [0.7019607843137254, '#ddb4ec'],
    [0.7058823529411764, '#dcb3ec'],
    [0.7098039215686275, '#dcb2ec'],
    [0.7137254901960784, '#dbb1ec'],
    [0.7176470588235294, '#dbafec'],
    [0.7215686274509804, '#dbaeec'],
    [0.7254901960784313, '#daadec'],
    [0.7294117647058823, '#daacec'],
    [0.7333333333333333, '#d9abeb'],
    [0.7372549019607844, '#d9aaeb'],
    [0.7411764705882353, '#d8a9eb'],
    [0.7450980392156863, '#d8a7eb'],
    [0.7490196078431373, '#d7a6eb'],
    [0.7529411764705882, '#d7a5eb'],
    [0.7568627450980392, '#d7a4eb'],
    [0.7607843137254902, '#d6a3eb'],
    [0.7647058823529411, '#d6a2eb'],
    [0.7686274509803921, '#d5a0eb'],
    [0.7725490196078432, '#d59fea'],
    [0.7764705882352941, '#d49eea'],
    [0.7803921568627451, '#d49dea'],
    [0.7843137254901961, '#d39cea'],
    [0.788235294117647, '#d39bea'],
    [0.792156862745098, '#d39aea'],
    [0.796078431372549, '#d298ea'],
    [0.8, '#d297ea'],
    [0.803921568627451, '#d196ea'],
    [0.807843137254902, '#d195ea'],
    [0.8117647058823529, '#d094e9'],
    [0.8156862745098039, '#d093e9'],
    [0.8196078431372549, '#cf91e9'],
    [0.8235294117647058, '#cf90e9'],
    [0.8274509803921568, '#ce8fe9'],
    [0.8313725490196078, '#ce8ee9'],
    [0.8352941176470589, '#cd8de9'],
    [0.8392156862745098, '#cd8be9'],
    [0.8431372549019608, '#cc8ae9'],
    [0.8470588235294118, '#cc89e8'],
    [0.8509803921568627, '#cb88e8'],
    [0.8549019607843137, '#cb87e8'],
    [0.8588235294117647, '#ca86e8'],
    [0.8627450980392157, '#ca84e8'],
    [0.8666666666666667, '#c983e8'],
    [0.8705882352941177, '#c982e8'],
    [0.8745098039215686, '#c881e8'],
    [0.8784313725490196, '#c880e8'],
    [0.8823529411764706, '#c77ee7'],
    [0.8862745098039215, '#c77de7'],
    [0.8901960784313725, '#c67ce7'],
    [0.8941176470588235, '#c67be7'],
    [0.8980392156862745, '#c57ae7'],
    [0.9019607843137255, '#c578e7'],
    [0.9058823529411765, '#c477e7'],
    [0.9098039215686274, '#c476e7'],
    [0.9137254901960784, '#c375e6'],
    [0.9176470588235294, '#c373e6'],
    [0.9215686274509803, '#c272e6'],
    [0.9254901960784314, '#c271e6'],
    [0.9294117647058824, '#c170e6'],
    [0.9333333333333333, '#c16fe6'],
    [0.9372549019607843, '#c06de6'],
    [0.9411764705882353, '#bf6ce6'],
    [0.9450980392156862, '#bf6be6'],
    [0.9490196078431372, '#be6ae5'],
    [0.9529411764705882, '#be68e5'],
    [0.9568627450980391, '#bd67e5'],
    [0.9607843137254902, '#bd66e5'],
    [0.9647058823529412, '#bc64e5'],
    [0.9686274509803922, '#bc63e5'],
    [0.9725490196078431, '#bb62e5'],
    [0.9764705882352941, '#ba61e4'],
    [0.9803921568627451, '#ba5fe4'],
    [0.984313725490196, '#b95ee4'],
    [0.9882352941176471, '#b95de4'],
    [0.9921568627450981, '#b85be4'],
    [0.996078431372549, '#b85ae4'],
    [1.0, '#b759e4'],
  ],
  CET_D3: [
    [0.0, '#38960e'],
    [0.00392156862745098, '#3a9711'],
    [0.00784313725490196, '#3c9814'],
    [0.011764705882352941, '#3e9817'],
    [0.01568627450980392, '#40991a'],
    [0.0196078431372549, '#429a1c'],
    [0.023529411764705882, '#449b1f'],
    [0.027450980392156862, '#469b21'],
    [0.03137254901960784, '#479c23'],
    [0.03529411764705882, '#499d25'],
    [0.0392156862745098, '#4b9d28'],
    [0.043137254901960784, '#4d9e2a'],
    [0.047058823529411764, '#4e9f2c'],
    [0.050980392156862744, '#50a02e'],
    [0.054901960784313725, '#52a030'],
    [0.058823529411764705, '#53a132'],
    [0.06274509803921569, '#55a233'],
    [0.06666666666666667, '#57a235'],
    [0.07058823529411765, '#58a337'],
    [0.07450980392156863, '#5aa439'],
    [0.0784313725490196, '#5ca53b'],
    [0.08235294117647059, '#5da53d'],
    [0.08627450980392157, '#5fa63e'],
    [0.09019607843137255, '#60a740'],
    [0.09411764705882353, '#62a742'],
    [0.09803921568627451, '#63a844'],
    [0.10196078431372549, '#65a945'],
    [0.10588235294117647, '#66a947'],
    [0.10980392156862745, '#68aa49'],
    [0.11372549019607843, '#69ab4a'],
    [0.11764705882352941, '#6bac4c'],
    [0.12156862745098039, '#6cac4e'],
    [0.12549019607843137, '#6ead4f'],
    [0.12941176470588234, '#6fae51'],
    [0.13333333333333333, '#71ae53'],
    [0.13725490196078433, '#72af54'],
    [0.1411764705882353, '#74b056'],
    [0.14509803921568626, '#75b158'],
    [0.14901960784313725, '#77b159'],
    [0.15294117647058825, '#78b25b'],
    [0.1568627450980392, '#7ab35d'],
    [0.16078431372549018, '#7bb35e'],
    [0.16470588235294117, '#7cb460'],
    [0.16862745098039217, '#7eb562'],
    [0.17254901960784313, '#7fb563'],
    [0.1764705882352941, '#81b665'],
    [0.1803921568627451, '#82b767'],
    [0.1843137254901961, '#84b868'],
    [0.18823529411764706, '#85b86a'],
    [0.19215686274509802, '#86b96c'],
    [0.19607843137254902, '#88ba6d'],
    [0.2, '#89ba6f'],
    [0.20392156862745098, '#8abb70'],
    [0.20784313725490194, '#8cbc72'],
    [0.21176470588235294, '#8dbd74'],
    [0.21568627450980393, '#8fbd75'],
    [0.2196078431372549, '#90be77'],
    [0.22352941176470587, '#91bf79'],
    [0.22745098039215686, '#93bf7a'],
    [0.23137254901960785, '#94c07c'],
    [0.23529411764705882, '#96c17e'],
    [0.2392156862745098, '#97c17f'],
    [0.24313725490196078, '#98c281'],
    [0.24705882352941178, '#9ac382'],
    [0.25098039215686274, '#9bc484'],
    [0.2549019607843137, '#9cc486'],
    [0.2588235294117647, '#9ec587'],
    [0.2627450980392157, '#9fc689'],
    [0.26666666666666666, '#a0c68b'],
    [0.27058823529411763, '#a2c78c'],
    [0.27450980392156865, '#a3c88e'],
    [0.2784313725490196, '#a4c890'],
    [0.2823529411764706, '#a6c991'],
    [0.28627450980392155, '#a7ca93'],
    [0.2901960784313725, '#a8ca95'],
    [0.29411764705882354, '#aacb96'],
    [0.2980392156862745, '#abcc98'],
    [0.30196078431372547, '#accd99'],
    [0.3058823529411765, '#aecd9b'],
    [0.30980392156862746, '#afce9d'],
    [0.3137254901960784, '#b0cf9e'],
    [0.3176470588235294, '#b2cfa0'],
    [0.32156862745098036, '#b3d0a2'],
    [0.3254901960784314, '#b4d1a3'],
    [0.32941176470588235, '#b6d1a5'],
    [0.3333333333333333, '#b7d2a7'],
    [0.33725490196078434, '#b8d3a8'],
    [0.3411764705882353, '#bad4aa'],
    [0.34509803921568627, '#bbd4ac'],
    [0.34901960784313724, '#bcd5ad'],
    [0.3529411764705882, '#bed6af'],
    [0.3568627450980392, '#bfd6b1'],
    [0.3607843137254902, '#c0d7b2'],
    [0.36470588235294116, '#c2d8b4'],
    [0.3686274509803922, '#c3d8b6'],
    [0.37254901960784315, '#c4d9b7'],
    [0.3764705882352941, '#c6dab9'],
    [0.3803921568627451, '#c7dabb'],
    [0.38431372549019605, '#c8dbbc'],
    [0.38823529411764707, '#cadcbe'],
    [0.39215686274509803, '#cbddc0'],
    [0.396078431372549, '#ccddc1'],
    [0.4, '#cedec3'],
    [0.403921568627451, '#cfdfc5'],
    [0.40784313725490196, '#d0dfc6'],
    [0.4117647058823529, '#d1e0c8'],
    [0.4156862745098039, '#d3e1ca'],
    [0.4196078431372549, '#d4e1cc'],
    [0.4235294117647059, '#d5e2cd'],
    [0.42745098039215684, '#d7e3cf'],
    [0.43137254901960786, '#d8e3d1'],
    [0.43529411764705883, '#d9e4d2'],
    [0.4392156862745098, '#dbe5d4'],
    [0.44313725490196076, '#dce5d6'],
    [0.44705882352941173, '#dde6d7'],
    [0.45098039215686275, '#dfe7d9'],
    [0.4549019607843137, '#e0e7da'],
    [0.4588235294117647, '#e1e8dc'],
    [0.4627450980392157, '#e2e8dd'],
    [0.4666666666666667, '#e4e9df'],
    [0.47058823529411764, '#e5e9e0'],
    [0.4745098039215686, '#e6eae1'],
    [0.4784313725490196, '#e7eae3'],
    [0.4823529411764706, '#e8eae4'],
    [0.48627450980392156, '#eaeae4'],
    [0.49019607843137253, '#ebeae5'],
    [0.49411764705882355, '#eceae6'],
    [0.4980392156862745, '#edeae6'],
    [0.5019607843137255, '#eeeae6'],
    [0.5058823529411764, '#eee9e6'],
    [0.5098039215686274, '#efe9e6'],
    [0.5137254901960784, '#f0e8e5'],
    [0.5176470588235293, '#f1e8e5'],
    [0.5215686274509804, '#f1e7e4'],
    [0.5254901960784314, '#f2e6e3'],
    [0.5294117647058824, '#f2e5e2'],
    [0.5333333333333333, '#f3e4e1'],
    [0.5372549019607843, '#f3e3e0'],
    [0.5411764705882353, '#f4e2df'],
    [0.5450980392156862, '#f4e1de'],
    [0.5490196078431373, '#f4e0dc'],
    [0.5529411764705883, '#f5dedb'],
    [0.5568627450980392, '#f5ddda'],
    [0.5607843137254902, '#f5dcd8'],
    [0.5647058823529412, '#f5dbd7'],
    [0.5686274509803921, '#f6dad6'],
    [0.5725490196078431, '#f6d8d4'],
    [0.5764705882352941, '#f6d7d3'],
    [0.580392156862745, '#f6d6d1'],
    [0.5843137254901961, '#f7d5d0'],
    [0.5882352941176471, '#f7d4ce'],
    [0.592156862745098, '#f7d2cd'],
    [0.596078431372549, '#f7d1cc'],
    [0.6, '#f7d0ca'],
    [0.6039215686274509, '#f8cfc9'],
    [0.6078431372549019, '#f8cdc7'],
    [0.611764705882353, '#f8ccc6'],
    [0.615686274509804, '#f8cbc5'],
    [0.6196078431372549, '#f8cac3'],
    [0.6235294117647059, '#f8c9c2'],
    [0.6274509803921569, '#f8c7c0'],
    [0.6313725490196078, '#f9c6bf'],
    [0.6352941176470588, '#f9c5be'],
    [0.6392156862745098, '#f9c4bc'],
    [0.6431372549019607, '#f9c2bb'],
    [0.6470588235294118, '#f9c1b9'],
    [0.6509803921568628, '#f9c0b8'],
    [0.6549019607843137, '#f9bfb7'],
    [0.6588235294117647, '#f9beb5'],
    [0.6627450980392157, '#f9bcb4'],
    [0.6666666666666666, '#f9bbb2'],
    [0.6705882352941176, '#fabab1'],
    [0.6745098039215687, '#fab9b0'],
    [0.6784313725490196, '#fab7ae'],
    [0.6823529411764706, '#fab6ad'],
    [0.6862745098039216, '#fab5ab'],
    [0.6901960784313725, '#fab4aa'],
    [0.6941176470588235, '#fab2a9'],
    [0.6980392156862745, '#fab1a7'],
    [0.7019607843137254, '#fab0a6'],
    [0.7058823529411764, '#faafa5'],
    [0.7098039215686275, '#faada3'],
    [0.7137254901960784, '#faaca2'],
    [0.7176470588235294, '#faaba1'],
    [0.7215686274509804, '#faaa9f'],
    [0.7254901960784313, '#faa89e'],
    [0.7294117647058823, '#faa79c'],
    [0.7333333333333333, '#faa69b'],
    [0.7372549019607844, '#faa59a'],
    [0.7411764705882353, '#faa398'],
    [0.7450980392156863, '#faa297'],
    [0.7490196078431373, '#faa196'],
    [0.7529411764705882, '#f9a094'],
    [0.7568627450980392, '#f99e93'],
    [0.7607843137254902, '#f99d92'],
    [0.7647058823529411, '#f99c90'],
    [0.7686274509803921, '#f99b8f'],
    [0.7725490196078432, '#f9998e'],
    [0.7764705882352941, '#f9988c'],
    [0.7803921568627451, '#f9978b'],
    [0.7843137254901961, '#f9968a'],
    [0.788235294117647, '#f99488'],
    [0.792156862745098, '#f99387'],
    [0.796078431372549, '#f99286'],
    [0.8, '#f89084'],
    [0.803921568627451, '#f88f83'],
    [0.807843137254902, '#f88e82'],
    [0.8117647058823529, '#f88c80'],
    [0.8156862745098039, '#f88b7f'],
    [0.8196078431372549, '#f88a7e'],
    [0.8235294117647058, '#f8897c'],
    [0.8274509803921568, '#f7877b'],
    [0.8313725490196078, '#f7867a'],
    [0.8352941176470589, '#f78579'],
    [0.8392156862745098, '#f78377'],
    [0.8431372549019608, '#f78276'],
    [0.8470588235294118, '#f78175'],
    [0.8509803921568627, '#f67f73'],
    [0.8549019607843137, '#f67e72'],
    [0.8588235294117647, '#f67d71'],
    [0.8627450980392157, '#f67b6f'],
    [0.8666666666666667, '#f67a6e'],
    [0.8705882352941177, '#f5796d'],
    [0.8745098039215686, '#f5776c'],
    [0.8784313725490196, '#f5766a'],
    [0.8823529411764706, '#f57469'],
    [0.8862745098039215, '#f57368'],
    [0.8901960784313725, '#f47266'],
    [0.8941176470588235, '#f47065'],
    [0.8980392156862745, '#f46f64'],
    [0.9019607843137255, '#f46e63'],
    [0.9058823529411765, '#f36c61'],
    [0.9098039215686274, '#f36b60'],
    [0.9137254901960784, '#f3695f'],
    [0.9176470588235294, '#f3685e'],
    [0.9215686274509803, '#f2665c'],
    [0.9254901960784314, '#f2655b'],
    [0.9294117647058824, '#f2635a'],
    [0.9333333333333333, '#f26259'],
    [0.9372549019607843, '#f16057'],
    [0.9411764705882353, '#f15f56'],
    [0.9450980392156862, '#f15d55'],
    [0.9490196078431372, '#f15c54'],
    [0.9529411764705882, '#f05a52'],
    [0.9568627450980391, '#f05951'],
    [0.9607843137254902, '#f05750'],
    [0.9647058823529412, '#ef564f'],
    [0.9686274509803922, '#ef544d'],
    [0.9725490196078431, '#ef524c'],
    [0.9764705882352941, '#ee514b'],
    [0.9803921568627451, '#ee4f4a'],
    [0.984313725490196, '#ee4e48'],
    [0.9882352941176471, '#ed4c47'],
    [0.9921568627450981, '#ed4a46'],
    [0.996078431372549, '#ed4845'],
    [1.0, '#ec4744'],
  ],
  CET_D4: [
    [0.0, '#1881fa'],
    [0.00392156862745098, '#1a80f8'],
    [0.00784313725490196, '#1b7ff6'],
    [0.011764705882352941, '#1c7ef4'],
    [0.01568627450980392, '#1e7df2'],
    [0.0196078431372549, '#1f7cf0'],
    [0.023529411764705882, '#207cee'],
    [0.027450980392156862, '#217bec'],
    [0.03137254901960784, '#227aea'],
    [0.03529411764705882, '#2379e8'],
    [0.0392156862745098, '#2478e6'],
    [0.043137254901960784, '#2477e4'],
    [0.047058823529411764, '#2576e2'],
    [0.050980392156862744, '#2675e0'],
    [0.054901960784313725, '#2775de'],
    [0.058823529411764705, '#2774dd'],
    [0.06274509803921569, '#2873db'],
    [0.06666666666666667, '#2872d9'],
    [0.07058823529411765, '#2971d7'],
    [0.07450980392156863, '#2a70d5'],
    [0.0784313725490196, '#2a6fd3'],
    [0.08235294117647059, '#2b6fd1'],
    [0.08627450980392157, '#2b6ecf'],
    [0.09019607843137255, '#2b6dcd'],
    [0.09411764705882353, '#2c6ccb'],
    [0.09803921568627451, '#2c6bc9'],
    [0.10196078431372549, '#2d6ac7'],
    [0.10588235294117647, '#2d69c6'],
    [0.10980392156862745, '#2d69c4'],
    [0.11372549019607843, '#2e68c2'],
    [0.11764705882352941, '#2e67c0'],
    [0.12156862745098039, '#2e66be'],
    [0.12549019607843137, '#2f65bc'],
    [0.12941176470588234, '#2f64ba'],
    [0.13333333333333333, '#2f63b8'],
    [0.13725490196078433, '#2f63b7'],
    [0.1411764705882353, '#3062b5'],
    [0.14509803921568626, '#3061b3'],
    [0.14901960784313725, '#3060b1'],
    [0.15294117647058825, '#305faf'],
    [0.1568627450980392, '#305ead'],
    [0.16078431372549018, '#305eab'],
    [0.16470588235294117, '#315daa'],
    [0.16862745098039217, '#315ca8'],
    [0.17254901960784313, '#315ba6'],
    [0.1764705882352941, '#315aa4'],
    [0.1803921568627451, '#3159a2'],
    [0.1843137254901961, '#3159a0'],
    [0.18823529411764706, '#31589f'],
    [0.19215686274509802, '#31579d'],
    [0.19607843137254902, '#31569b'],
    [0.2, '#315599'],
    [0.20392156862745098, '#315497'],
    [0.20784313725490194, '#315495'],
    [0.21176470588235294, '#315394'],
    [0.21568627450980393, '#315292'],
    [0.2196078431372549, '#315190'],
    [0.22352941176470587, '#31508e'],
    [0.22745098039215686, '#31508c'],
    [0.23137254901960785, '#314f8b'],
    [0.23529411764705882, '#314e89'],
    [0.2392156862745098, '#314d87'],
    [0.24313725490196078, '#314c85'],
    [0.24705882352941178, '#314c84'],
    [0.25098039215686274, '#314b82'],
    [0.2549019607843137, '#314a80'],
    [0.2588235294117647, '#30497e'],
    [0.2627450980392157, '#30487d'],
    [0.26666666666666666, '#30487b'],
    [0.27058823529411763, '#304779'],
    [0.27450980392156865, '#304677'],
    [0.2784313725490196, '#304576'],
    [0.2823529411764706, '#304474'],
    [0.28627450980392155, '#2f4472'],
    [0.2901960784313725, '#2f4370'],
    [0.29411764705882354, '#2f426f'],
    [0.2980392156862745, '#2f416d'],
    [0.30196078431372547, '#2f406b'],
    [0.3058823529411765, '#2e406a'],
    [0.30980392156862746, '#2e3f68'],
    [0.3137254901960784, '#2e3e66'],
    [0.3176470588235294, '#2e3d65'],
    [0.32156862745098036, '#2e3d63'],
    [0.3254901960784314, '#2d3c61'],
    [0.32941176470588235, '#2d3b60'],
    [0.3333333333333333, '#2d3a5e'],
    [0.33725490196078434, '#2d395c'],
    [0.3411764705882353, '#2c395b'],
    [0.34509803921568627, '#2c3859'],
    [0.34901960784313724, '#2c3757'],
    [0.3529411764705882, '#2b3656'],
    [0.3568627450980392, '#2b3654'],
    [0.3607843137254902, '#2b3552'],
    [0.36470588235294116, '#2a3451'],
    [0.3686274509803922, '#2a334f'],
    [0.37254901960784315, '#2a334e'],
    [0.3764705882352941, '#29324c'],
    [0.3803921568627451, '#29314a'],
    [0.38431372549019605, '#293049'],
    [0.38823529411764707, '#283047'],
    [0.39215686274509803, '#282f46'],
    [0.396078431372549, '#282e44'],
    [0.4, '#272e42'],
    [0.403921568627451, '#272d41'],
    [0.40784313725490196, '#272c3f'],
    [0.4117647058823529, '#262b3e'],
    [0.4156862745098039, '#262b3c'],
    [0.4196078431372549, '#252a3b'],
    [0.4235294117647059, '#252939'],
    [0.42745098039215684, '#242837'],
    [0.43137254901960786, '#242836'],
    [0.43529411764705883, '#242734'],
    [0.4392156862745098, '#232633'],
    [0.44313725490196076, '#232631'],
    [0.44705882352941173, '#222530'],
    [0.45098039215686275, '#22242f'],
    [0.4549019607843137, '#22242d'],
    [0.4588235294117647, '#21232c'],
    [0.4627450980392157, '#21222a'],
    [0.4666666666666667, '#212229'],
    [0.47058823529411764, '#212128'],
    [0.4745098039215686, '#202126'],
    [0.4784313725490196, '#202025'],
    [0.4823529411764706, '#202024'],
    [0.48627450980392156, '#211f23'],
    [0.49019607843137253, '#211f22'],
    [0.49411764705882355, '#211f21'],
    [0.4980392156862745, '#211f21'],
    [0.5019607843137255, '#221f20'],
    [0.5058823529411764, '#231f20'],
    [0.5098039215686274, '#231f1f'],
    [0.5137254901960784, '#241f1f'],
    [0.5176470588235293, '#251f1f'],
    [0.5215686274509804, '#261f1e'],
    [0.5254901960784314, '#271f1e'],
    [0.5294117647058824, '#28201e'],
    [0.5333333333333333, '#2a201e'],
    [0.5372549019607843, '#2b201f'],
    [0.5411764705882353, '#2c211f'],
    [0.5450980392156862, '#2e211f'],
    [0.5490196078431373, '#2f221f'],
    [0.5529411764705883, '#30221f'],
    [0.5568627450980392, '#322220'],
    [0.5607843137254902, '#332320'],
    [0.5647058823529412, '#352320'],
    [0.5686274509803921, '#362420'],
    [0.5725490196078431, '#382421'],
    [0.5764705882352941, '#392521'],
    [0.580392156862745, '#3b2521'],
    [0.5843137254901961, '#3c2622'],
    [0.5882352941176471, '#3d2622'],
    [0.592156862745098, '#3f2622'],
    [0.596078431372549, '#402722'],
    [0.6, '#422723'],
    [0.6039215686274509, '#432823'],
    [0.6078431372549019, '#452823'],
    [0.611764705882353, '#462924'],
    [0.615686274509804, '#482924'],
    [0.6196078431372549, '#492a24'],
    [0.6235294117647059, '#4b2a24'],
    [0.6274509803921569, '#4c2b25'],
    [0.6313725490196078, '#4e2b25'],
    [0.6352941176470588, '#4f2b25'],
    [0.6392156862745098, '#512c26'],
    [0.6431372549019607, '#522c26'],
    [0.6470588235294118, '#542d26'],
    [0.6509803921568628, '#552d27'],
    [0.6549019607843137, '#572e27'],
    [0.6588235294117647, '#582e27'],
    [0.6627450980392157, '#5a2e27'],
    [0.6666666666666666, '#5b2f28'],
    [0.6705882352941176, '#5d2f28'],
    [0.6745098039215687, '#5e3028'],
    [0.6784313725490196, '#603029'],
    [0.6823529411764706, '#613129'],
    [0.6862745098039216, '#633129'],
    [0.6901960784313725, '#643129'],
    [0.6941176470588235, '#66322a'],
    [0.6980392156862745, '#68322a'],
    [0.7019607843137254, '#69332a'],
    [0.7058823529411764, '#6b332b'],
    [0.7098039215686275, '#6c342b'],
    [0.7137254901960784, '#6e342b'],
    [0.7176470588235294, '#6f342c'],
    [0.7215686274509804, '#71352c'],
    [0.7254901960784313, '#72352c'],
    [0.7294117647058823, '#74362c'],
    [0.7333333333333333, '#75362d'],
    [0.7372549019607844, '#77362d'],
    [0.7411764705882353, '#79372d'],
    [0.7450980392156863, '#7a372e'],
    [0.7490196078431373, '#7c382e'],
    [0.7529411764705882, '#7d382e'],
    [0.7568627450980392, '#7f382e'],
    [0.7607843137254902, '#80392f'],
    [0.7647058823529411, '#82392f'],
    [0.7686274509803921, '#843a2f'],
    [0.7725490196078432, '#853a30'],
    [0.7764705882352941, '#873a30'],
    [0.7803921568627451, '#883b30'],
    [0.7843137254901961, '#8a3b31'],
    [0.788235294117647, '#8c3c31'],
    [0.792156862745098, '#8d3c31'],
    [0.796078431372549, '#8f3c31'],
    [0.8, '#903d32'],
    [0.803921568627451, '#923d32'],
    [0.807843137254902, '#943e32'],
    [0.8117647058823529, '#953e33'],
    [0.8156862745098039, '#973e33'],
    [0.8196078431372549, '#983f33'],
    [0.8235294117647058, '#9a3f34'],
    [0.8274509803921568, '#9c3f34'],
    [0.8313725490196078, '#9d4034'],
    [0.8352941176470589, '#9f4034'],
    [0.8392156862745098, '#a14135'],
    [0.8431372549019608, '#a24135'],
    [0.8470588235294118, '#a44135'],
    [0.8509803921568627, '#a54236'],
    [0.8549019607843137, '#a74236'],
    [0.8588235294117647, '#a94236'],
    [0.8627450980392157, '#aa4337'],
    [0.8666666666666667, '#ac4337'],
    [0.8705882352941177, '#ae4437'],
    [0.8745098039215686, '#af4437'],
    [0.8784313725490196, '#b14438'],
    [0.8823529411764706, '#b34538'],
    [0.8862745098039215, '#b44538'],
    [0.8901960784313725, '#b64539'],
    [0.8941176470588235, '#b84639'],
    [0.8980392156862745, '#b94639'],
    [0.9019607843137255, '#bb4739'],
    [0.9058823529411765, '#bd473a'],
    [0.9098039215686274, '#be473a'],
    [0.9137254901960784, '#c0483a'],
    [0.9176470588235294, '#c2483b'],
    [0.9215686274509803, '#c3483b'],
    [0.9254901960784314, '#c5493b'],
    [0.9294117647058824, '#c7493c'],
    [0.9333333333333333, '#c8493c'],
    [0.9372549019607843, '#ca4a3c'],
    [0.9411764705882353, '#cc4a3c'],
    [0.9450980392156862, '#ce4a3d'],
    [0.9490196078431372, '#cf4b3d'],
    [0.9529411764705882, '#d14b3d'],
    [0.9568627450980391, '#d34b3e'],
    [0.9607843137254902, '#d44c3e'],
    [0.9647058823529412, '#d64c3e'],
    [0.9686274509803922, '#d84d3f'],
    [0.9725490196078431, '#da4d3f'],
    [0.9764705882352941, '#db4d3f'],
    [0.9803921568627451, '#dd4e3f'],
    [0.984313725490196, '#df4e40'],
    [0.9882352941176471, '#e04e40'],
    [0.9921568627450981, '#e24f40'],
    [0.996078431372549, '#e44f41'],
    [1.0, '#e64f41'],
  ],
  CET_D6: [
    [0.0, '#0e93fa'],
    [0.00392156862745098, '#1092f8'],
    [0.00784313725490196, '#1291f6'],
    [0.011764705882352941, '#1490f4'],
    [0.01568627450980392, '#168ff2'],
    [0.0196078431372549, '#188ef0'],
    [0.023529411764705882, '#198dee'],
    [0.027450980392156862, '#1b8cec'],
    [0.03137254901960784, '#1c8bea'],
    [0.03529411764705882, '#1d8ae8'],
    [0.0392156862745098, '#1e89e6'],
    [0.043137254901960784, '#1f88e4'],
    [0.047058823529411764, '#2087e2'],
    [0.050980392156862744, '#2186e0'],
    [0.054901960784313725, '#2285de'],
    [0.058823529411764705, '#2384dc'],
    [0.06274509803921569, '#2483da'],
    [0.06666666666666667, '#2482d8'],
    [0.07058823529411765, '#2581d7'],
    [0.07450980392156863, '#2680d5'],
    [0.0784313725490196, '#267fd3'],
    [0.08235294117647059, '#277ed1'],
    [0.08627450980392157, '#287dcf'],
    [0.09019607843137255, '#287bcd'],
    [0.09411764705882353, '#297acb'],
    [0.09803921568627451, '#2979c9'],
    [0.10196078431372549, '#2a78c7'],
    [0.10588235294117647, '#2a77c5'],
    [0.10980392156862745, '#2a76c3'],
    [0.11372549019607843, '#2b75c2'],
    [0.11764705882352941, '#2b74c0'],
    [0.12156862745098039, '#2c73be'],
    [0.12549019607843137, '#2c72bc'],
    [0.12941176470588234, '#2c71ba'],
    [0.13333333333333333, '#2d70b8'],
    [0.13725490196078433, '#2d6fb6'],
    [0.1411764705882353, '#2d6eb4'],
    [0.14509803921568626, '#2d6db3'],
    [0.14901960784313725, '#2e6cb1'],
    [0.15294117647058825, '#2e6baf'],
    [0.1568627450980392, '#2e6aad'],
    [0.16078431372549018, '#2e69ab'],
    [0.16470588235294117, '#2f68a9'],
    [0.16862745098039217, '#2f67a7'],
    [0.17254901960784313, '#2f66a6'],
    [0.1764705882352941, '#2f66a4'],
    [0.1803921568627451, '#2f65a2'],
    [0.1843137254901961, '#2f64a0'],
    [0.18823529411764706, '#2f639e'],
    [0.19215686274509802, '#2f629c'],
    [0.19607843137254902, '#30619b'],
    [0.2, '#306099'],
    [0.20392156862745098, '#305f97'],
    [0.20784313725490194, '#305e95'],
    [0.21176470588235294, '#305d93'],
    [0.21568627450980393, '#305c92'],
    [0.2196078431372549, '#305b90'],
    [0.22352941176470587, '#305a8e'],
    [0.22745098039215686, '#30598c'],
    [0.23137254901960785, '#30588b'],
    [0.23529411764705882, '#305789'],
    [0.2392156862745098, '#305687'],
    [0.24313725490196078, '#305585'],
    [0.24705882352941178, '#305483'],
    [0.25098039215686274, '#305382'],
    [0.2549019607843137, '#2f5280'],
    [0.2588235294117647, '#2f517e'],
    [0.2627450980392157, '#2f507c'],
    [0.26666666666666666, '#2f4f7b'],
    [0.27058823529411763, '#2f4e79'],
    [0.27450980392156865, '#2f4e77'],
    [0.2784313725490196, '#2f4d75'],
    [0.2823529411764706, '#2f4c74'],
    [0.28627450980392155, '#2f4b72'],
    [0.2901960784313725, '#2e4a70'],
    [0.29411764705882354, '#2e496f'],
    [0.2980392156862745, '#2e486d'],
    [0.30196078431372547, '#2e476b'],
    [0.3058823529411765, '#2e4669'],
    [0.30980392156862746, '#2d4568'],
    [0.3137254901960784, '#2d4466'],
    [0.3176470588235294, '#2d4364'],
    [0.32156862745098036, '#2d4263'],
    [0.3254901960784314, '#2d4261'],
    [0.32941176470588235, '#2c415f'],
    [0.3333333333333333, '#2c405e'],
    [0.33725490196078434, '#2c3f5c'],
    [0.3411764705882353, '#2c3e5a'],
    [0.34509803921568627, '#2b3d59'],
    [0.34901960784313724, '#2b3c57'],
    [0.3529411764705882, '#2b3b55'],
    [0.3568627450980392, '#2b3a54'],
    [0.3607843137254902, '#2a3952'],
    [0.36470588235294116, '#2a3951'],
    [0.3686274509803922, '#2a384f'],
    [0.37254901960784315, '#29374d'],
    [0.3764705882352941, '#29364c'],
    [0.3803921568627451, '#29354a'],
    [0.38431372549019605, '#283449'],
    [0.38823529411764707, '#283347'],
    [0.39215686274509803, '#283245'],
    [0.396078431372549, '#273244'],
    [0.4, '#273142'],
    [0.403921568627451, '#273041'],
    [0.40784313725490196, '#262f3f'],
    [0.4117647058823529, '#262e3e'],
    [0.4156862745098039, '#252d3c'],
    [0.4196078431372549, '#252c3a'],
    [0.4235294117647059, '#252c39'],
    [0.42745098039215684, '#242b37'],
    [0.43137254901960786, '#242a36'],
    [0.43529411764705883, '#232934'],
    [0.4392156862745098, '#232833'],
    [0.44313725490196076, '#232731'],
    [0.44705882352941173, '#222730'],
    [0.45098039215686275, '#22262e'],
    [0.4549019607843137, '#21252d'],
    [0.4588235294117647, '#21242c'],
    [0.4627450980392157, '#21242a'],
    [0.4666666666666667, '#202329'],
    [0.47058823529411764, '#202228'],
    [0.4745098039215686, '#202226'],
    [0.4784313725490196, '#202125'],
    [0.4823529411764706, '#202124'],
    [0.48627450980392156, '#202123'],
    [0.49019607843137253, '#202022'],
    [0.49411764705882355, '#202021'],
    [0.4980392156862745, '#202020'],
    [0.5019607843137255, '#212020'],
    [0.5058823529411764, '#21201f'],
    [0.5098039215686274, '#21201f'],
    [0.5137254901960784, '#22201e'],
    [0.5176470588235293, '#23211e'],
    [0.5215686274509804, '#23211d'],
    [0.5254901960784314, '#24211d'],
    [0.5294117647058824, '#25221d'],
    [0.5333333333333333, '#26231d'],
    [0.5372549019607843, '#27231d'],
    [0.5411764705882353, '#28241d'],
    [0.5450980392156862, '#29251d'],
    [0.5490196078431373, '#2a251d'],
    [0.5529411764705883, '#2b261d'],
    [0.5568627450980392, '#2c271d'],
    [0.5607843137254902, '#2d271d'],
    [0.5647058823529412, '#2e281d'],
    [0.5686274509803921, '#2f291e'],
    [0.5725490196078431, '#302a1e'],
    [0.5764705882352941, '#322b1e'],
    [0.580392156862745, '#332b1e'],
    [0.5843137254901961, '#342c1e'],
    [0.5882352941176471, '#352d1e'],
    [0.592156862745098, '#362e1e'],
    [0.596078431372549, '#372e1e'],
    [0.6, '#382f1e'],
    [0.6039215686274509, '#39301f'],
    [0.6078431372549019, '#3a311f'],
    [0.611764705882353, '#3c321f'],
    [0.615686274509804, '#3d331f'],
    [0.6196078431372549, '#3e331f'],
    [0.6235294117647059, '#3f341f'],
    [0.6274509803921569, '#40351f'],
    [0.6313725490196078, '#41361f'],
    [0.6352941176470588, '#42371f'],
    [0.6392156862745098, '#43371f'],
    [0.6431372549019607, '#453820'],
    [0.6470588235294118, '#463920'],
    [0.6509803921568628, '#473a20'],
    [0.6549019607843137, '#483b20'],
    [0.6588235294117647, '#493c20'],
    [0.6627450980392157, '#4a3c20'],
    [0.6666666666666666, '#4b3d20'],
    [0.6705882352941176, '#4c3e20'],
    [0.6745098039215687, '#4e3f20'],
    [0.6784313725490196, '#4f4020'],
    [0.6823529411764706, '#504120'],
    [0.6862745098039216, '#514120'],
    [0.6901960784313725, '#524220'],
    [0.6941176470588235, '#534320'],
    [0.6980392156862745, '#554420'],
    [0.7019607843137254, '#564520'],
    [0.7058823529411764, '#574621'],
    [0.7098039215686275, '#584721'],
    [0.7137254901960784, '#594721'],
    [0.7176470588235294, '#5a4821'],
    [0.7215686274509804, '#5b4921'],
    [0.7254901960784313, '#5d4a21'],
    [0.7294117647058823, '#5e4b21'],
    [0.7333333333333333, '#5f4c21'],
    [0.7372549019607844, '#604d21'],
    [0.7411764705882353, '#614e21'],
    [0.7450980392156863, '#624e21'],
    [0.7490196078431373, '#644f21'],
    [0.7529411764705882, '#655021'],
    [0.7568627450980392, '#665121'],
    [0.7607843137254902, '#675221'],
    [0.7647058823529411, '#685321'],
    [0.7686274509803921, '#6a5421'],
    [0.7725490196078432, '#6b5521'],
    [0.7764705882352941, '#6c5521'],
    [0.7803921568627451, '#6d5621'],
    [0.7843137254901961, '#6e5721'],
    [0.788235294117647, '#705821'],
    [0.792156862745098, '#715921'],
    [0.796078431372549, '#725a21'],
    [0.8, '#735b21'],
    [0.803921568627451, '#745c21'],
    [0.807843137254902, '#765d21'],
    [0.8117647058823529, '#775e21'],
    [0.8156862745098039, '#785e21'],
    [0.8196078431372549, '#795f21'],
    [0.8235294117647058, '#7a6021'],
    [0.8274509803921568, '#7c6120'],
    [0.8313725490196078, '#7d6220'],
    [0.8352941176470589, '#7e6320'],
    [0.8392156862745098, '#7f6420'],
    [0.8431372549019608, '#806520'],
    [0.8470588235294118, '#826620'],
    [0.8509803921568627, '#836720'],
    [0.8549019607843137, '#846820'],
    [0.8588235294117647, '#856820'],
    [0.8627450980392157, '#876920'],
    [0.8666666666666667, '#886a20'],
    [0.8705882352941177, '#896b20'],
    [0.8745098039215686, '#8a6c20'],
    [0.8784313725490196, '#8b6d1f'],
    [0.8823529411764706, '#8d6e1f'],
    [0.8862745098039215, '#8e6f1f'],
    [0.8901960784313725, '#8f701f'],
    [0.8941176470588235, '#90711f'],
    [0.8980392156862745, '#92721f'],
    [0.9019607843137255, '#93731f'],
    [0.9058823529411765, '#94741f'],
    [0.9098039215686274, '#95751e'],
    [0.9137254901960784, '#97751e'],
    [0.9176470588235294, '#98761e'],
    [0.9215686274509803, '#99771e'],
    [0.9254901960784314, '#9a781e'],
    [0.9294117647058824, '#9c791e'],
    [0.9333333333333333, '#9d7a1d'],
    [0.9372549019607843, '#9e7b1d'],
    [0.9411764705882353, '#9f7c1d'],
    [0.9450980392156862, '#a17d1d'],
    [0.9490196078431372, '#a27e1d'],
    [0.9529411764705882, '#a37f1d'],
    [0.9568627450980391, '#a5801c'],
    [0.9607843137254902, '#a6811c'],
    [0.9647058823529412, '#a7821c'],
    [0.9686274509803922, '#a8831c'],
    [0.9725490196078431, '#aa841b'],
    [0.9764705882352941, '#ab851b'],
    [0.9803921568627451, '#ac861b'],
    [0.984313725490196, '#ad871b'],
    [0.9882352941176471, '#af881a'],
    [0.9921568627450981, '#b0891a'],
    [0.996078431372549, '#b1891a'],
    [1.0, '#b38a19'],
  ],
  CET_D7: [
    [0.0, '#1331c1'],
    [0.00392156862745098, '#1832c1'],
    [0.00784313725490196, '#1c32c0'],
    [0.011764705882352941, '#1f33c0'],
    [0.01568627450980392, '#2234bf'],
    [0.0196078431372549, '#2534bf'],
    [0.023529411764705882, '#2735bf'],
    [0.027450980392156862, '#2a36be'],
    [0.03137254901960784, '#2c37be'],
    [0.03529411764705882, '#2e37be'],
    [0.0392156862745098, '#3038bd'],
    [0.043137254901960784, '#3239bd'],
    [0.047058823529411764, '#343abd'],
    [0.050980392156862744, '#363abc'],
    [0.054901960784313725, '#383bbc'],
    [0.058823529411764705, '#393cbc'],
    [0.06274509803921569, '#3b3cbb'],
    [0.06666666666666667, '#3d3dbb'],
    [0.07058823529411765, '#3e3ebb'],
    [0.07450980392156863, '#403fba'],
    [0.0784313725490196, '#413fba'],
    [0.08235294117647059, '#4240b9'],
    [0.08627450980392157, '#4441b9'],
    [0.09019607843137255, '#4542b9'],
    [0.09411764705882353, '#4642b8'],
    [0.09803921568627451, '#4843b8'],
    [0.10196078431372549, '#4944b8'],
    [0.10588235294117647, '#4a44b7'],
    [0.10980392156862745, '#4b45b7'],
    [0.11372549019607843, '#4d46b7'],
    [0.11764705882352941, '#4e47b6'],
    [0.12156862745098039, '#4f47b6'],
    [0.12549019607843137, '#5048b6'],
    [0.12941176470588234, '#5149b5'],
    [0.13333333333333333, '#524ab5'],
    [0.13725490196078433, '#534ab4'],
    [0.1411764705882353, '#544bb4'],
    [0.14509803921568626, '#554cb4'],
    [0.14901960784313725, '#564db3'],
    [0.15294117647058825, '#574db3'],
    [0.1568627450980392, '#584eb3'],
    [0.16078431372549018, '#594fb2'],
    [0.16470588235294117, '#5a50b2'],
    [0.16862745098039217, '#5b50b2'],
    [0.17254901960784313, '#5c51b1'],
    [0.1764705882352941, '#5d52b1'],
    [0.1803921568627451, '#5e52b0'],
    [0.1843137254901961, '#5f53b0'],
    [0.18823529411764706, '#6054b0'],
    [0.19215686274509802, '#6155af'],
    [0.19607843137254902, '#6155af'],
    [0.2, '#6256af'],
    [0.20392156862745098, '#6357ae'],
    [0.20784313725490194, '#6458ae'],
    [0.21176470588235294, '#6558ad'],
    [0.21568627450980393, '#6659ad'],
    [0.2196078431372549, '#665aad'],
    [0.22352941176470587, '#675bac'],
    [0.22745098039215686, '#685bac'],
    [0.23137254901960785, '#695cac'],
    [0.23529411764705882, '#695dab'],
    [0.2392156862745098, '#6a5eab'],
    [0.24313725490196078, '#6b5eab'],
    [0.24705882352941178, '#6c5faa'],
    [0.25098039215686274, '#6c60aa'],
    [0.2549019607843137, '#6d61a9'],
    [0.2588235294117647, '#6e61a9'],
    [0.2627450980392157, '#6f62a9'],
    [0.26666666666666666, '#6f63a8'],
    [0.27058823529411763, '#7064a8'],
    [0.27450980392156865, '#7164a7'],
    [0.2784313725490196, '#7165a7'],
    [0.2823529411764706, '#7266a7'],
    [0.28627450980392155, '#7367a6'],
    [0.2901960784313725, '#7367a6'],
    [0.29411764705882354, '#7468a6'],
    [0.2980392156862745, '#7569a5'],
    [0.30196078431372547, '#756aa5'],
    [0.3058823529411765, '#766aa4'],
    [0.30980392156862746, '#776ba4'],
    [0.3137254901960784, '#776ca4'],
    [0.3176470588235294, '#786da3'],
    [0.32156862745098036, '#786da3'],
    [0.3254901960784314, '#796ea2'],
    [0.32941176470588235, '#7a6fa2'],
    [0.3333333333333333, '#7a70a2'],
    [0.33725490196078434, '#7b70a1'],
    [0.3411764705882353, '#7b71a1'],
    [0.34509803921568627, '#7c72a0'],
    [0.34901960784313724, '#7d73a0'],
    [0.3529411764705882, '#7d73a0'],
    [0.3568627450980392, '#7e749f'],
    [0.3607843137254902, '#7e759f'],
    [0.36470588235294116, '#7f769e'],
    [0.3686274509803922, '#80769e'],
    [0.37254901960784315, '#80779e'],
    [0.3764705882352941, '#81789d'],
    [0.3803921568627451, '#81799d'],
    [0.38431372549019605, '#82799c'],
    [0.38823529411764707, '#827a9c'],
    [0.39215686274509803, '#837b9c'],
    [0.396078431372549, '#837c9b'],
    [0.4, '#847c9b'],
    [0.403921568627451, '#847d9a'],
    [0.40784313725490196, '#857e9a'],
    [0.4117647058823529, '#857f9a'],
    [0.4156862745098039, '#868099'],
    [0.4196078431372549, '#868099'],
    [0.4235294117647059, '#878198'],
    [0.42745098039215684, '#878298'],
    [0.43137254901960786, '#888398'],
    [0.43529411764705883, '#888397'],
    [0.4392156862745098, '#898497'],
    [0.44313725490196076, '#898596'],
    [0.44705882352941173, '#8a8696'],
    [0.45098039215686275, '#8a8695'],
    [0.4549019607843137, '#8b8795'],
    [0.4588235294117647, '#8b8895'],
    [0.4627450980392157, '#8c8994'],
    [0.4666666666666667, '#8c8a94'],
    [0.47058823529411764, '#8d8a93'],
    [0.4745098039215686, '#8d8b93'],
    [0.4784313725490196, '#8e8c92'],
    [0.4823529411764706, '#8e8d92'],
    [0.48627450980392156, '#8e8d92'],
    [0.49019607843137253, '#8f8e91'],
    [0.49411764705882355, '#8f8f91'],
    [0.4980392156862745, '#909090'],
    [0.5019607843137255, '#919090'],
    [0.5058823529411764, '#92918f'],
    [0.5098039215686274, '#93928f'],
    [0.5137254901960784, '#94928e'],
    [0.5176470588235293, '#95938d'],
    [0.5215686274509804, '#96938d'],
    [0.5254901960784314, '#97948c'],
    [0.5294117647058824, '#98958c'],
    [0.5333333333333333, '#99958b'],
    [0.5372549019607843, '#9a968a'],
    [0.5411764705882353, '#9b968a'],
    [0.5450980392156862, '#9c9789'],
    [0.5490196078431373, '#9d9888'],
    [0.5529411764705883, '#9e9888'],
    [0.5568627450980392, '#9f9987'],
    [0.5607843137254902, '#a09a87'],
    [0.5647058823529412, '#a19a86'],
    [0.5686274509803921, '#a29b85'],
    [0.5725490196078431, '#a29b85'],
    [0.5764705882352941, '#a39c84'],
    [0.580392156862745, '#a49d83'],
    [0.5843137254901961, '#a59d83'],
    [0.5882352941176471, '#a69e82'],
    [0.592156862745098, '#a79f82'],
    [0.596078431372549, '#a89f81'],
    [0.6, '#a9a080'],
    [0.6039215686274509, '#aaa080'],
    [0.6078431372549019, '#aba17f'],
    [0.611764705882353, '#aca27e'],
    [0.615686274509804, '#ada27e'],
    [0.6196078431372549, '#aea37d'],
    [0.6235294117647059, '#aea47c'],
    [0.6274509803921569, '#afa47c'],
    [0.6313725490196078, '#b0a57b'],
    [0.6352941176470588, '#b1a67a'],
    [0.6392156862745098, '#b2a67a'],
    [0.6431372549019607, '#b3a779'],
    [0.6470588235294118, '#b4a778'],
    [0.6509803921568628, '#b5a878'],
    [0.6549019607843137, '#b6a977'],
    [0.6588235294117647, '#b6a976'],
    [0.6627450980392157, '#b7aa76'],
    [0.6666666666666666, '#b8ab75'],
    [0.6705882352941176, '#b9ab74'],
    [0.6745098039215687, '#baac73'],
    [0.6784313725490196, '#bbad73'],
    [0.6823529411764706, '#bcad72'],
    [0.6862745098039216, '#bdae71'],
    [0.6901960784313725, '#bdae71'],
    [0.6941176470588235, '#beaf70'],
    [0.6980392156862745, '#bfb06f'],
    [0.7019607843137254, '#c0b06e'],
    [0.7058823529411764, '#c1b16e'],
    [0.7098039215686275, '#c2b26d'],
    [0.7137254901960784, '#c3b26c'],
    [0.7176470588235294, '#c3b36b'],
    [0.7215686274509804, '#c4b46b'],
    [0.7254901960784313, '#c5b46a'],
    [0.7294117647058823, '#c6b569'],
    [0.7333333333333333, '#c7b668'],
    [0.7372549019607844, '#c8b668'],
    [0.7411764705882353, '#c8b767'],
    [0.7450980392156863, '#c9b866'],
    [0.7490196078431373, '#cab865'],
    [0.7529411764705882, '#cbb965'],
    [0.7568627450980392, '#ccba64'],
    [0.7607843137254902, '#ccba63'],
    [0.7647058823529411, '#cdbb62'],
    [0.7686274509803921, '#cebc61'],
    [0.7725490196078432, '#cfbc60'],
    [0.7764705882352941, '#d0bd60'],
    [0.7803921568627451, '#d1be5f'],
    [0.7843137254901961, '#d1be5e'],
    [0.788235294117647, '#d2bf5d'],
    [0.792156862745098, '#d3c05c'],
    [0.796078431372549, '#d4c05b'],
    [0.8, '#d5c15a'],
    [0.803921568627451, '#d5c25a'],
    [0.807843137254902, '#d6c259'],
    [0.8117647058823529, '#d7c358'],
    [0.8156862745098039, '#d8c457'],
    [0.8196078431372549, '#d9c456'],
    [0.8235294117647058, '#d9c555'],
    [0.8274509803921568, '#dac654'],
    [0.8313725490196078, '#dbc653'],
    [0.8352941176470589, '#dcc752'],
    [0.8392156862745098, '#ddc851'],
    [0.8431372549019608, '#ddc850'],
    [0.8470588235294118, '#dec94f'],
    [0.8509803921568627, '#dfca4e'],
    [0.8549019607843137, '#e0ca4d'],
    [0.8588235294117647, '#e1cb4c'],
    [0.8627450980392157, '#e1cc4b'],
    [0.8666666666666667, '#e2cc4a'],
    [0.8705882352941177, '#e3cd49'],
    [0.8745098039215686, '#e4ce48'],
    [0.8784313725490196, '#e4ce47'],
    [0.8823529411764706, '#e5cf46'],
    [0.8862745098039215, '#e6d044'],
    [0.8901960784313725, '#e7d043'],
    [0.8941176470588235, '#e8d142'],
    [0.8980392156862745, '#e8d241'],
    [0.9019607843137255, '#e9d240'],
    [0.9058823529411765, '#ead33e'],
    [0.9098039215686274, '#ebd43d'],
    [0.9137254901960784, '#ebd43c'],
    [0.9176470588235294, '#ecd53a'],
    [0.9215686274509803, '#edd639'],
    [0.9254901960784314, '#eed637'],
    [0.9294117647058824, '#efd736'],
    [0.9333333333333333, '#efd834'],
    [0.9372549019607843, '#f0d833'],
    [0.9411764705882353, '#f1d931'],
    [0.9450980392156862, '#f2da30'],
    [0.9490196078431372, '#f2da2e'],
    [0.9529411764705882, '#f3db2c'],
    [0.9568627450980391, '#f4dc2a'],
    [0.9607843137254902, '#f5dd28'],
    [0.9647058823529412, '#f5dd26'],
    [0.9686274509803922, '#f6de24'],
    [0.9725490196078431, '#f7df22'],
    [0.9764705882352941, '#f8df1f'],
    [0.9803921568627451, '#f8e01d'],
    [0.984313725490196, '#f9e11a'],
    [0.9882352941176471, '#fae116'],
    [0.9921568627450981, '#fbe213'],
    [0.996078431372549, '#fbe30e'],
    [1.0, '#fce309'],
  ],
  CET_D8: [
    [0.0, '#002ad7'],
    [0.00392156862745098, '#002ad6'],
    [0.00784313725490196, '#002bd5'],
    [0.011764705882352941, '#002bd4'],
    [0.01568627450980392, '#002cd3'],
    [0.0196078431372549, '#002cd2'],
    [0.023529411764705882, '#002dd1'],
    [0.027450980392156862, '#002dd0'],
    [0.03137254901960784, '#062ecf'],
    [0.03529411764705882, '#0d2ece'],
    [0.0392156862745098, '#122fce'],
    [0.043137254901960784, '#162fcd'],
    [0.047058823529411764, '#1a30cc'],
    [0.050980392156862744, '#1d30cb'],
    [0.054901960784313725, '#2031ca'],
    [0.058823529411764705, '#2331c9'],
    [0.06274509803921569, '#2532c8'],
    [0.06666666666666667, '#2732c7'],
    [0.07058823529411765, '#2933c6'],
    [0.07450980392156863, '#2b33c5'],
    [0.0784313725490196, '#2d34c4'],
    [0.08235294117647059, '#2f34c3'],
    [0.08627450980392157, '#3135c3'],
    [0.09019607843137255, '#3235c2'],
    [0.09411764705882353, '#3436c1'],
    [0.09803921568627451, '#3536c0'],
    [0.10196078431372549, '#3737bf'],
    [0.10588235294117647, '#3837be'],
    [0.10980392156862745, '#3938bd'],
    [0.11372549019607843, '#3a38bc'],
    [0.11764705882352941, '#3c38bb'],
    [0.12156862745098039, '#3d39ba'],
    [0.12549019607843137, '#3e39b9'],
    [0.12941176470588234, '#3f3ab9'],
    [0.13333333333333333, '#403ab8'],
    [0.13725490196078433, '#413bb7'],
    [0.1411764705882353, '#423bb6'],
    [0.14509803921568626, '#433cb5'],
    [0.14901960784313725, '#443cb4'],
    [0.15294117647058825, '#453db3'],
    [0.1568627450980392, '#463db2'],
    [0.16078431372549018, '#473eb1'],
    [0.16470588235294117, '#483eb0'],
    [0.16862745098039217, '#483fb0'],
    [0.17254901960784313, '#493faf'],
    [0.1764705882352941, '#4a40ae'],
    [0.1803921568627451, '#4b40ad'],
    [0.1843137254901961, '#4b40ac'],
    [0.18823529411764706, '#4c41ab'],
    [0.19215686274509802, '#4d41aa'],
    [0.19607843137254902, '#4e42a9'],
    [0.2, '#4e42a8'],
    [0.20392156862745098, '#4f43a7'],
    [0.20784313725490194, '#5043a7'],
    [0.21176470588235294, '#5044a6'],
    [0.21568627450980393, '#5144a5'],
    [0.2196078431372549, '#5145a4'],
    [0.22352941176470587, '#5245a3'],
    [0.22745098039215686, '#5345a2'],
    [0.23137254901960785, '#5346a1'],
    [0.23529411764705882, '#5446a0'],
    [0.2392156862745098, '#54479f'],
    [0.24313725490196078, '#55479e'],
    [0.24705882352941178, '#55489e'],
    [0.25098039215686274, '#56489d'],
    [0.2549019607843137, '#56499c'],
    [0.2588235294117647, '#57499b'],
    [0.2627450980392157, '#574a9a'],
    [0.26666666666666666, '#584a99'],
    [0.27058823529411763, '#584a98'],
    [0.27450980392156865, '#584b97'],
    [0.2784313725490196, '#594b96'],
    [0.2823529411764706, '#594c96'],
    [0.28627450980392155, '#5a4c95'],
    [0.2901960784313725, '#5a4d94'],
    [0.29411764705882354, '#5a4d93'],
    [0.2980392156862745, '#5b4e92'],
    [0.30196078431372547, '#5b4e91'],
    [0.3058823529411765, '#5b4e90'],
    [0.30980392156862746, '#5c4f8f'],
    [0.3137254901960784, '#5c4f8e'],
    [0.3176470588235294, '#5c508e'],
    [0.32156862745098036, '#5d508d'],
    [0.3254901960784314, '#5d518c'],
    [0.32941176470588235, '#5d518b'],
    [0.3333333333333333, '#5e528a'],
    [0.33725490196078434, '#5e5289'],
    [0.3411764705882353, '#5e5288'],
    [0.34509803921568627, '#5f5387'],
    [0.34901960784313724, '#5f5386'],
    [0.3529411764705882, '#5f5485'],
    [0.3568627450980392, '#5f5485'],
    [0.3607843137254902, '#605584'],
    [0.36470588235294116, '#605583'],
    [0.3686274509803922, '#605582'],
    [0.37254901960784315, '#605681'],
    [0.3764705882352941, '#605680'],
    [0.3803921568627451, '#61577f'],
    [0.38431372549019605, '#61577e'],
    [0.38823529411764707, '#61587d'],
    [0.39215686274509803, '#61587d'],
    [0.396078431372549, '#61587c'],
    [0.4, '#62597b'],
    [0.403921568627451, '#62597a'],
    [0.40784313725490196, '#625a79'],
    [0.4117647058823529, '#625a78'],
    [0.4156862745098039, '#625b77'],
    [0.4196078431372549, '#625b76'],
    [0.4235294117647059, '#625c75'],
    [0.42745098039215684, '#635c75'],
    [0.43137254901960786, '#635c74'],
    [0.43529411764705883, '#635d73'],
    [0.4392156862745098, '#635d72'],
    [0.44313725490196076, '#635e71'],
    [0.44705882352941173, '#635e70'],
    [0.45098039215686275, '#635f6f'],
    [0.4549019607843137, '#635f6e'],
    [0.4588235294117647, '#635f6d'],
    [0.4627450980392157, '#63606c'],
    [0.4666666666666667, '#63606c'],
    [0.47058823529411764, '#64616b'],
    [0.4745098039215686, '#64616a'],
    [0.4784313725490196, '#646169'],
    [0.4823529411764706, '#646268'],
    [0.48627450980392156, '#646267'],
    [0.49019607843137253, '#646366'],
    [0.49411764705882355, '#646365'],
    [0.4980392156862745, '#646464'],
    [0.5019607843137255, '#656463'],
    [0.5058823529411764, '#666463'],
    [0.5098039215686274, '#686462'],
    [0.5137254901960784, '#6a6362'],
    [0.5176470588235293, '#6b6361'],
    [0.5215686274509804, '#6d6360'],
    [0.5254901960784314, '#6f6360'],
    [0.5294117647058824, '#70635f'],
    [0.5333333333333333, '#72635f'],
    [0.5372549019607843, '#73635e'],
    [0.5411764705882353, '#75625d'],
    [0.5450980392156862, '#76625d'],
    [0.5490196078431373, '#78625c'],
    [0.5529411764705883, '#79625b'],
    [0.5568627450980392, '#7b625b'],
    [0.5607843137254902, '#7c625a'],
    [0.5647058823529412, '#7e6159'],
    [0.5686274509803921, '#7f6159'],
    [0.5725490196078431, '#816158'],
    [0.5764705882352941, '#826158'],
    [0.580392156862745, '#846157'],
    [0.5843137254901961, '#856056'],
    [0.5882352941176471, '#866056'],
    [0.592156862745098, '#886055'],
    [0.596078431372549, '#896054'],
    [0.6, '#8b6054'],
    [0.6039215686274509, '#8c5f53'],
    [0.6078431372549019, '#8d5f53'],
    [0.611764705882353, '#8f5f52'],
    [0.615686274509804, '#905f51'],
    [0.6196078431372549, '#915f51'],
    [0.6235294117647059, '#935e50'],
    [0.6274509803921569, '#945e4f'],
    [0.6313725490196078, '#955e4f'],
    [0.6352941176470588, '#975e4e'],
    [0.6392156862745098, '#985d4d'],
    [0.6431372549019607, '#995d4d'],
    [0.6470588235294118, '#9b5d4c'],
    [0.6509803921568628, '#9c5d4c'],
    [0.6549019607843137, '#9d5c4b'],
    [0.6588235294117647, '#9e5c4a'],
    [0.6627450980392157, '#a05c4a'],
    [0.6666666666666666, '#a15c49'],
    [0.6705882352941176, '#a25b48'],
    [0.6745098039215687, '#a35b48'],
    [0.6784313725490196, '#a55b47'],
    [0.6823529411764706, '#a65a46'],
    [0.6862745098039216, '#a75a46'],
    [0.6901960784313725, '#a85a45'],
    [0.6941176470588235, '#aa5944'],
    [0.6980392156862745, '#ab5944'],
    [0.7019607843137254, '#ac5943'],
    [0.7058823529411764, '#ad5842'],
    [0.7098039215686275, '#af5842'],
    [0.7137254901960784, '#b05841'],
    [0.7176470588235294, '#b15740'],
    [0.7215686274509804, '#b25740'],
    [0.7254901960784313, '#b3573f'],
    [0.7294117647058823, '#b5563e'],
    [0.7333333333333333, '#b6563e'],
    [0.7372549019607844, '#b7563d'],
    [0.7411764705882353, '#b8553c'],
    [0.7450980392156863, '#b9553c'],
    [0.7490196078431373, '#bb543b'],
    [0.7529411764705882, '#bc543a'],
    [0.7568627450980392, '#bd543a'],
    [0.7607843137254902, '#be5339'],
    [0.7647058823529411, '#bf5338'],
    [0.7686274509803921, '#c05238'],
    [0.7725490196078432, '#c25237'],
    [0.7764705882352941, '#c35136'],
    [0.7803921568627451, '#c45136'],
    [0.7843137254901961, '#c55035'],
    [0.788235294117647, '#c65034'],
    [0.792156862745098, '#c84f34'],
    [0.796078431372549, '#c94f33'],
    [0.8, '#ca4e32'],
    [0.803921568627451, '#cb4e31'],
    [0.807843137254902, '#cc4d31'],
    [0.8117647058823529, '#cd4d30'],
    [0.8156862745098039, '#ce4c2f'],
    [0.8196078431372549, '#d04c2f'],
    [0.8235294117647058, '#d14b2e'],
    [0.8274509803921568, '#d24b2d'],
    [0.8313725490196078, '#d34a2c'],
    [0.8352941176470589, '#d4492c'],
    [0.8392156862745098, '#d5492b'],
    [0.8431372549019608, '#d7482a'],
    [0.8470588235294118, '#d84729'],
    [0.8509803921568627, '#d94729'],
    [0.8549019607843137, '#da4628'],
    [0.8588235294117647, '#db4527'],
    [0.8627450980392157, '#dc4526'],
    [0.8666666666666667, '#dd4425'],
    [0.8705882352941177, '#df4325'],
    [0.8745098039215686, '#e04324'],
    [0.8784313725490196, '#e14223'],
    [0.8823529411764706, '#e24122'],
    [0.8862745098039215, '#e34021'],
    [0.8901960784313725, '#e43f20'],
    [0.8941176470588235, '#e53f20'],
    [0.8980392156862745, '#e63e1f'],
    [0.9019607843137255, '#e83d1e'],
    [0.9058823529411765, '#e93c1d'],
    [0.9098039215686274, '#ea3b1c'],
    [0.9137254901960784, '#eb3a1b'],
    [0.9176470588235294, '#ec391a'],
    [0.9215686274509803, '#ed3819'],
    [0.9254901960784314, '#ee3718'],
    [0.9294117647058824, '#ef3617'],
    [0.9333333333333333, '#f13516'],
    [0.9372549019607843, '#f23415'],
    [0.9411764705882353, '#f33214'],
    [0.9450980392156862, '#f43113'],
    [0.9490196078431372, '#f53011'],
    [0.9529411764705882, '#f62f10'],
    [0.9568627450980391, '#f72d0f'],
    [0.9607843137254902, '#f82c0e'],
    [0.9647058823529412, '#fa2a0c'],
    [0.9686274509803922, '#fb290b'],
    [0.9725490196078431, '#fc2709'],
    [0.9764705882352941, '#fd2507'],
    [0.9803921568627451, '#fe2406'],
    [0.984313725490196, '#ff2204'],
    [0.9882352941176471, '#ff2002'],
    [0.9921568627450981, '#ff1d01'],
    [0.996078431372549, '#ff1b00'],
    [1.0, '#ff1800'],
  ],
  CET_D9: [
    [0.0, '#247ffe'],
    [0.00392156862745098, '#2980fe'],
    [0.00784313725490196, '#2e81fe'],
    [0.011764705882352941, '#3282fe'],
    [0.01568627450980392, '#3683fe'],
    [0.0196078431372549, '#3a83fe'],
    [0.023529411764705882, '#3d84fe'],
    [0.027450980392156862, '#4085fe'],
    [0.03137254901960784, '#4486fe'],
    [0.03529411764705882, '#4787fe'],
    [0.0392156862745098, '#4988fe'],
    [0.043137254901960784, '#4c89fe'],
    [0.047058823529411764, '#4f8afe'],
    [0.050980392156862744, '#518afe'],
    [0.054901960784313725, '#548bfe'],
    [0.058823529411764705, '#568cfe'],
    [0.06274509803921569, '#598dfe'],
    [0.06666666666666667, '#5b8efe'],
    [0.07058823529411765, '#5d8ffe'],
    [0.07450980392156863, '#5f90fe'],
    [0.0784313725490196, '#6291fe'],
    [0.08235294117647059, '#6491fe'],
    [0.08627450980392157, '#6692fe'],
    [0.09019607843137255, '#6893fe'],
    [0.09411764705882353, '#6a94fe'],
    [0.09803921568627451, '#6c95fe'],
    [0.10196078431372549, '#6e96fe'],
    [0.10588235294117647, '#7097fe'],
    [0.10980392156862745, '#7198fe'],
    [0.11372549019607843, '#7399fe'],
    [0.11764705882352941, '#759afe'],
    [0.12156862745098039, '#779afe'],
    [0.12549019607843137, '#799bfe'],
    [0.12941176470588234, '#7a9cfe'],
    [0.13333333333333333, '#7c9dfe'],
    [0.13725490196078433, '#7e9efe'],
    [0.1411764705882353, '#809ffe'],
    [0.14509803921568626, '#81a0fe'],
    [0.14901960784313725, '#83a1fe'],
    [0.15294117647058825, '#84a2fe'],
    [0.1568627450980392, '#86a3fe'],
    [0.16078431372549018, '#88a4fe'],
    [0.16470588235294117, '#89a4fe'],
    [0.16862745098039217, '#8ba5fe'],
    [0.17254901960784313, '#8ca6fd'],
    [0.1764705882352941, '#8ea7fd'],
    [0.1803921568627451, '#90a8fd'],
    [0.1843137254901961, '#91a9fd'],
    [0.18823529411764706, '#93aafd'],
    [0.19215686274509802, '#94abfd'],
    [0.19607843137254902, '#96acfd'],
    [0.2, '#97adfd'],
    [0.20392156862745098, '#99aefd'],
    [0.20784313725490194, '#9aaffd'],
    [0.21176470588235294, '#9bb0fd'],
    [0.21568627450980393, '#9db1fd'],
    [0.2196078431372549, '#9eb2fd'],
    [0.22352941176470587, '#a0b2fd'],
    [0.22745098039215686, '#a1b3fd'],
    [0.23137254901960785, '#a3b4fd'],
    [0.23529411764705882, '#a4b5fd'],
    [0.2392156862745098, '#a5b6fd'],
    [0.24313725490196078, '#a7b7fd'],
    [0.24705882352941178, '#a8b8fd'],
    [0.25098039215686274, '#aab9fd'],
    [0.2549019607843137, '#abbafd'],
    [0.2588235294117647, '#acbbfd'],
    [0.2627450980392157, '#aebcfd'],
    [0.26666666666666666, '#afbdfd'],
    [0.27058823529411763, '#b0befd'],
    [0.27450980392156865, '#b2bffd'],
    [0.2784313725490196, '#b3c0fd'],
    [0.2823529411764706, '#b4c1fd'],
    [0.28627450980392155, '#b6c2fc'],
    [0.2901960784313725, '#b7c3fc'],
    [0.29411764705882354, '#b8c4fc'],
    [0.2980392156862745, '#bac5fc'],
    [0.30196078431372547, '#bbc6fc'],
    [0.3058823529411765, '#bcc7fc'],
    [0.30980392156862746, '#bdc7fc'],
    [0.3137254901960784, '#bfc8fc'],
    [0.3176470588235294, '#c0c9fc'],
    [0.32156862745098036, '#c1cafc'],
    [0.3254901960784314, '#c3cbfc'],
    [0.32941176470588235, '#c4ccfc'],
    [0.3333333333333333, '#c5cdfc'],
    [0.33725490196078434, '#c6cefc'],
    [0.3411764705882353, '#c8cffc'],
    [0.34509803921568627, '#c9d0fc'],
    [0.34901960784313724, '#cad1fc'],
    [0.3529411764705882, '#cbd2fc'],
    [0.3568627450980392, '#cdd3fc'],
    [0.3607843137254902, '#ced4fb'],
    [0.36470588235294116, '#cfd5fb'],
    [0.3686274509803922, '#d0d6fb'],
    [0.37254901960784315, '#d1d7fb'],
    [0.3764705882352941, '#d3d8fb'],
    [0.3803921568627451, '#d4d9fb'],
    [0.38431372549019605, '#d5dafb'],
    [0.38823529411764707, '#d6dbfb'],
    [0.39215686274509803, '#d7dcfb'],
    [0.396078431372549, '#d9ddfb'],
    [0.4, '#dadefb'],
    [0.403921568627451, '#dbdffb'],
    [0.40784313725490196, '#dce0fb'],
    [0.4117647058823529, '#dde1fb'],
    [0.4156862745098039, '#dfe2fb'],
    [0.4196078431372549, '#e0e3fa'],
    [0.4235294117647059, '#e1e4fa'],
    [0.42745098039215684, '#e2e5fa'],
    [0.43137254901960786, '#e3e6fa'],
    [0.43529411764705883, '#e5e7fa'],
    [0.4392156862745098, '#e6e8fa'],
    [0.44313725490196076, '#e7e9fa'],
    [0.44705882352941173, '#e8eafa'],
    [0.45098039215686275, '#e9ebfa'],
    [0.4549019607843137, '#eaecfa'],
    [0.4588235294117647, '#ecedfa'],
    [0.4627450980392157, '#edeefa'],
    [0.4666666666666667, '#eeeffa'],
    [0.47058823529411764, '#eff0f9'],
    [0.4745098039215686, '#f0f1f9'],
    [0.4784313725490196, '#f1f2f9'],
    [0.4823529411764706, '#f3f3f9'],
    [0.48627450980392156, '#f4f4f9'],
    [0.49019607843137253, '#f5f5f9'],
    [0.49411764705882355, '#f6f6f8'],
    [0.4980392156862745, '#f7f6f7'],
    [0.5019607843137255, '#f8f6f6'],
    [0.5058823529411764, '#f9f5f5'],
    [0.5098039215686274, '#f9f4f3'],
    [0.5137254901960784, '#faf3f2'],
    [0.5176470588235293, '#faf2f0'],
    [0.5215686274509804, '#faf1ee'],
    [0.5254901960784314, '#faefed'],
    [0.5294117647058824, '#fbeeeb'],
    [0.5333333333333333, '#fbede9'],
    [0.5372549019607843, '#fbece8'],
    [0.5411764705882353, '#fbeae6'],
    [0.5450980392156862, '#fbe9e5'],
    [0.5490196078431373, '#fce8e3'],
    [0.5529411764705883, '#fce7e1'],
    [0.5568627450980392, '#fce5e0'],
    [0.5607843137254902, '#fce4de'],
    [0.5647058823529412, '#fce3dc'],
    [0.5686274509803921, '#fce1db'],
    [0.5725490196078431, '#fce0d9'],
    [0.5764705882352941, '#fcdfd8'],
    [0.580392156862745, '#fdded6'],
    [0.5843137254901961, '#fddcd4'],
    [0.5882352941176471, '#fddbd3'],
    [0.592156862745098, '#fddad1'],
    [0.596078431372549, '#fdd9d0'],
    [0.6, '#fdd7ce'],
    [0.6039215686274509, '#fdd6cc'],
    [0.6078431372549019, '#fdd5cb'],
    [0.611764705882353, '#fdd4c9'],
    [0.615686274509804, '#fdd2c8'],
    [0.6196078431372549, '#fdd1c6'],
    [0.6235294117647059, '#fdd0c5'],
    [0.6274509803921569, '#fdcfc3'],
    [0.6313725490196078, '#fdcdc1'],
    [0.6352941176470588, '#fdccc0'],
    [0.6392156862745098, '#fdcbbe'],
    [0.6431372549019607, '#fdcabd'],
    [0.6470588235294118, '#fdc8bb'],
    [0.6509803921568628, '#fdc7ba'],
    [0.6549019607843137, '#fdc6b8'],
    [0.6588235294117647, '#fdc4b6'],
    [0.6627450980392157, '#fdc3b5'],
    [0.6666666666666666, '#fdc2b3'],
    [0.6705882352941176, '#fdc1b2'],
    [0.6745098039215687, '#fdbfb0'],
    [0.6784313725490196, '#fdbeaf'],
    [0.6823529411764706, '#fdbdad'],
    [0.6862745098039216, '#fdbcac'],
    [0.6901960784313725, '#fdbaaa'],
    [0.6941176470588235, '#fdb9a8'],
    [0.6980392156862745, '#fdb8a7'],
    [0.7019607843137254, '#fcb7a5'],
    [0.7058823529411764, '#fcb5a4'],
    [0.7098039215686275, '#fcb4a2'],
    [0.7137254901960784, '#fcb3a1'],
    [0.7176470588235294, '#fcb19f'],
    [0.7215686274509804, '#fcb09e'],
    [0.7254901960784313, '#fcaf9c'],
    [0.7294117647058823, '#fcae9b'],
    [0.7333333333333333, '#fbac99'],
    [0.7372549019607844, '#fbab98'],
    [0.7411764705882353, '#fbaa96'],
    [0.7450980392156863, '#fba995'],
    [0.7490196078431373, '#fba793'],
    [0.7529411764705882, '#fba692'],
    [0.7568627450980392, '#faa590'],
    [0.7607843137254902, '#faa38f'],
    [0.7647058823529411, '#faa28d'],
    [0.7686274509803921, '#faa18c'],
    [0.7725490196078432, '#faa08a'],
    [0.7764705882352941, '#f99e89'],
    [0.7803921568627451, '#f99d87'],
    [0.7843137254901961, '#f99c86'],
    [0.788235294117647, '#f99a84'],
    [0.792156862745098, '#f99983'],
    [0.796078431372549, '#f89881'],
    [0.8, '#f89780'],
    [0.803921568627451, '#f8957e'],
    [0.807843137254902, '#f8947d'],
    [0.8117647058823529, '#f7937b'],
    [0.8156862745098039, '#f7917a'],
    [0.8196078431372549, '#f79078'],
    [0.8235294117647058, '#f78f77'],
    [0.8274509803921568, '#f68d75'],
    [0.8313725490196078, '#f68c74'],
    [0.8352941176470589, '#f68b72'],
    [0.8392156862745098, '#f58a71'],
    [0.8431372549019608, '#f5886f'],
    [0.8470588235294118, '#f5876e'],
    [0.8509803921568627, '#f5866c'],
    [0.8549019607843137, '#f4846b'],
    [0.8588235294117647, '#f4836a'],
    [0.8627450980392157, '#f48268'],
    [0.8666666666666667, '#f38067'],
    [0.8705882352941177, '#f37f65'],
    [0.8745098039215686, '#f37e64'],
    [0.8784313725490196, '#f27c62'],
    [0.8823529411764706, '#f27b61'],
    [0.8862745098039215, '#f27a5f'],
    [0.8901960784313725, '#f1785e'],
    [0.8941176470588235, '#f1775d'],
    [0.8980392156862745, '#f1765b'],
    [0.9019607843137255, '#f0745a'],
    [0.9058823529411765, '#f07358'],
    [0.9098039215686274, '#ef7157'],
    [0.9137254901960784, '#ef7055'],
    [0.9176470588235294, '#ef6f54'],
    [0.9215686274509803, '#ee6d52'],
    [0.9254901960784314, '#ee6c51'],
    [0.9294117647058824, '#ee6a50'],
    [0.9333333333333333, '#ed694e'],
    [0.9372549019607843, '#ed684d'],
    [0.9411764705882353, '#ec664b'],
    [0.9450980392156862, '#ec654a'],
    [0.9490196078431372, '#ec6349'],
    [0.9529411764705882, '#eb6247'],
    [0.9568627450980391, '#eb6046'],
    [0.9607843137254902, '#ea5f44'],
    [0.9647058823529412, '#ea5e43'],
    [0.9686274509803922, '#e95c41'],
    [0.9725490196078431, '#e95b40'],
    [0.9764705882352941, '#e9593f'],
    [0.9803921568627451, '#e8583d'],
    [0.984313725490196, '#e8563c'],
    [0.9882352941176471, '#e7553a'],
    [0.9921568627450981, '#e75339'],
    [0.996078431372549, '#e65138'],
    [1.0, '#e65036'],
  ],
  CET_I1: [
    [0.0, '#36b7ec'],
    [0.00392156862745098, '#37b7eb'],
    [0.00784313725490196, '#38b7ea'],
    [0.011764705882352941, '#38b7e9'],
    [0.01568627450980392, '#39b7e8'],
    [0.0196078431372549, '#39b7e7'],
    [0.023529411764705882, '#3ab7e6'],
    [0.027450980392156862, '#3bb7e6'],
    [0.03137254901960784, '#3bb7e5'],
    [0.03529411764705882, '#3cb7e4'],
    [0.0392156862745098, '#3cb7e3'],
    [0.043137254901960784, '#3db7e2'],
    [0.047058823529411764, '#3db7e1'],
    [0.050980392156862744, '#3eb8e0'],
    [0.054901960784313725, '#3eb8df'],
    [0.058823529411764705, '#3fb8df'],
    [0.06274509803921569, '#3fb8de'],
    [0.06666666666666667, '#40b8dd'],
    [0.07058823529411765, '#40b8dc'],
    [0.07450980392156863, '#41b8db'],
    [0.0784313725490196, '#42b8da'],
    [0.08235294117647059, '#42b8d9'],
    [0.08627450980392157, '#43b8d8'],
    [0.09019607843137255, '#43b8d7'],
    [0.09411764705882353, '#44b8d7'],
    [0.09803921568627451, '#44b8d6'],
    [0.10196078431372549, '#45b8d5'],
    [0.10588235294117647, '#45b8d4'],
    [0.10980392156862745, '#45b9d3'],
    [0.11372549019607843, '#46b9d2'],
    [0.11764705882352941, '#46b9d1'],
    [0.12156862745098039, '#47b9d0'],
    [0.12549019607843137, '#47b9cf'],
    [0.12941176470588234, '#48b9cf'],
    [0.13333333333333333, '#48b9ce'],
    [0.13725490196078433, '#49b9cd'],
    [0.1411764705882353, '#49b9cc'],
    [0.14509803921568626, '#4ab9cb'],
    [0.14901960784313725, '#4ab9ca'],
    [0.15294117647058825, '#4bb9c9'],
    [0.1568627450980392, '#4bb9c8'],
    [0.16078431372549018, '#4cb9c7'],
    [0.16470588235294117, '#4cb9c6'],
    [0.16862745098039217, '#4db9c5'],
    [0.17254901960784313, '#4db9c5'],
    [0.1764705882352941, '#4ebac4'],
    [0.1803921568627451, '#4ebac3'],
    [0.1843137254901961, '#4fbac2'],
    [0.18823529411764706, '#4fbac1'],
    [0.19215686274509802, '#50bac0'],
    [0.19607843137254902, '#50babf'],
    [0.2, '#50babe'],
    [0.20392156862745098, '#51babd'],
    [0.20784313725490194, '#51babc'],
    [0.21176470588235294, '#52babb'],
    [0.21568627450980393, '#52baba'],
    [0.2196078431372549, '#53baba'],
    [0.22352941176470587, '#53bab9'],
    [0.22745098039215686, '#54bab8'],
    [0.23137254901960785, '#54bab7'],
    [0.23529411764705882, '#55bab6'],
    [0.2392156862745098, '#55bab5'],
    [0.24313725490196078, '#56bab4'],
    [0.24705882352941178, '#56bab3'],
    [0.25098039215686274, '#57bab2'],
    [0.2549019607843137, '#57bab1'],
    [0.2588235294117647, '#58bab0'],
    [0.2627450980392157, '#58baaf'],
    [0.26666666666666666, '#59baae'],
    [0.27058823529411763, '#59baad'],
    [0.27450980392156865, '#5abbac'],
    [0.2784313725490196, '#5bbbab'],
    [0.2823529411764706, '#5bbbaa'],
    [0.28627450980392155, '#5cbba9'],
    [0.2901960784313725, '#5cbba9'],
    [0.29411764705882354, '#5dbba8'],
    [0.2980392156862745, '#5dbba7'],
    [0.30196078431372547, '#5ebba6'],
    [0.3058823529411765, '#5ebba5'],
    [0.30980392156862746, '#5fbba4'],
    [0.3137254901960784, '#60bba3'],
    [0.3176470588235294, '#60bba2'],
    [0.32156862745098036, '#61bba1'],
    [0.3254901960784314, '#61bba0'],
    [0.32941176470588235, '#62bb9f'],
    [0.3333333333333333, '#63bb9e'],
    [0.33725490196078434, '#63bb9d'],
    [0.3411764705882353, '#64bb9c'],
    [0.34509803921568627, '#65bb9b'],
    [0.34901960784313724, '#65bb9a'],
    [0.3529411764705882, '#66bb99'],
    [0.3568627450980392, '#66bb98'],
    [0.3607843137254902, '#67bb97'],
    [0.36470588235294116, '#68bb96'],
    [0.3686274509803922, '#69bb95'],
    [0.37254901960784315, '#69bb94'],
    [0.3764705882352941, '#6aba93'],
    [0.3803921568627451, '#6bba92'],
    [0.38431372549019605, '#6bba91'],
    [0.38823529411764707, '#6cba90'],
    [0.39215686274509803, '#6dba8f'],
    [0.396078431372549, '#6eba8e'],
    [0.4, '#6eba8d'],
    [0.403921568627451, '#6fba8c'],
    [0.40784313725490196, '#70ba8b'],
    [0.4117647058823529, '#71ba8a'],
    [0.4156862745098039, '#72ba89'],
    [0.4196078431372549, '#72ba88'],
    [0.4235294117647059, '#73ba87'],
    [0.42745098039215684, '#74ba86'],
    [0.43137254901960786, '#75ba85'],
    [0.43529411764705883, '#76ba84'],
    [0.4392156862745098, '#77ba83'],
    [0.44313725490196076, '#78b982'],
    [0.44705882352941173, '#79b981'],
    [0.45098039215686275, '#7ab981'],
    [0.4549019607843137, '#7bb980'],
    [0.4588235294117647, '#7bb97f'],
    [0.4627450980392157, '#7cb97e'],
    [0.4666666666666667, '#7db97d'],
    [0.47058823529411764, '#7eb97c'],
    [0.4745098039215686, '#7fb97b'],
    [0.4784313725490196, '#80b87a'],
    [0.4823529411764706, '#81b879'],
    [0.48627450980392156, '#82b878'],
    [0.49019607843137253, '#83b878'],
    [0.49411764705882355, '#85b877'],
    [0.4980392156862745, '#86b876'],
    [0.5019607843137255, '#87b875'],
    [0.5058823529411764, '#88b774'],
    [0.5098039215686274, '#89b774'],
    [0.5137254901960784, '#8ab773'],
    [0.5176470588235293, '#8bb772'],
    [0.5215686274509804, '#8cb771'],
    [0.5254901960784314, '#8db671'],
    [0.5294117647058824, '#8eb670'],
    [0.5333333333333333, '#8fb66f'],
    [0.5372549019607843, '#90b66f'],
    [0.5411764705882353, '#91b66e'],
    [0.5450980392156862, '#92b56d'],
    [0.5490196078431373, '#94b56d'],
    [0.5529411764705883, '#95b56c'],
    [0.5568627450980392, '#96b56c'],
    [0.5607843137254902, '#97b56b'],
    [0.5647058823529412, '#98b46b'],
    [0.5686274509803921, '#99b46a'],
    [0.5725490196078431, '#9ab46a'],
    [0.5764705882352941, '#9bb469'],
    [0.580392156862745, '#9cb369'],
    [0.5843137254901961, '#9db368'],
    [0.5882352941176471, '#9eb368'],
    [0.592156862745098, '#9fb367'],
    [0.596078431372549, '#a0b267'],
    [0.6, '#a1b266'],
    [0.6039215686274509, '#a2b266'],
    [0.6078431372549019, '#a3b265'],
    [0.611764705882353, '#a4b165'],
    [0.615686274509804, '#a5b164'],
    [0.6196078431372549, '#a6b164'],
    [0.6235294117647059, '#a7b164'],
    [0.6274509803921569, '#a8b063'],
    [0.6313725490196078, '#a9b063'],
    [0.6352941176470588, '#aab062'],
    [0.6392156862745098, '#abb062'],
    [0.6431372549019607, '#acaf62'],
    [0.6470588235294118, '#adaf61'],
    [0.6509803921568628, '#aeaf61'],
    [0.6549019607843137, '#afaf61'],
    [0.6588235294117647, '#b0ae60'],
    [0.6627450980392157, '#b1ae60'],
    [0.6666666666666666, '#b2ae60'],
    [0.6705882352941176, '#b3ad60'],
    [0.6745098039215687, '#b4ad5f'],
    [0.6784313725490196, '#b5ad5f'],
    [0.6823529411764706, '#b6ad5f'],
    [0.6862745098039216, '#b7ac5f'],
    [0.6901960784313725, '#b8ac5f'],
    [0.6941176470588235, '#b9ac5e'],
    [0.6980392156862745, '#baab5e'],
    [0.7019607843137254, '#bbab5e'],
    [0.7058823529411764, '#bcab5e'],
    [0.7098039215686275, '#bcaa5e'],
    [0.7137254901960784, '#bdaa5e'],
    [0.7176470588235294, '#beaa5d'],
    [0.7215686274509804, '#bfaa5d'],
    [0.7254901960784313, '#c0a95d'],
    [0.7294117647058823, '#c1a95d'],
    [0.7333333333333333, '#c2a95d'],
    [0.7372549019607844, '#c3a85d'],
    [0.7411764705882353, '#c4a85d'],
    [0.7450980392156863, '#c5a85d'],
    [0.7490196078431373, '#c5a75d'],
    [0.7529411764705882, '#c6a75d'],
    [0.7568627450980392, '#c7a75d'],
    [0.7607843137254902, '#c8a65d'],
    [0.7647058823529411, '#c9a65d'],
    [0.7686274509803921, '#caa65d'],
    [0.7725490196078432, '#cba55d'],
    [0.7764705882352941, '#cba55d'],
    [0.7803921568627451, '#cca55d'],
    [0.7843137254901961, '#cda45d'],
    [0.788235294117647, '#cea45d'],
    [0.792156862745098, '#cfa45d'],
    [0.796078431372549, '#d0a35d'],
    [0.8, '#d1a35e'],
    [0.803921568627451, '#d1a35e'],
    [0.807843137254902, '#d2a25e'],
    [0.8117647058823529, '#d3a25e'],
    [0.8156862745098039, '#d4a25e'],
    [0.8196078431372549, '#d5a15e'],
    [0.8235294117647058, '#d5a15e'],
    [0.8274509803921568, '#d6a15f'],
    [0.8313725490196078, '#d7a05f'],
    [0.8352941176470589, '#d8a05f'],
    [0.8392156862745098, '#d9a05f'],
    [0.8431372549019608, '#d99f5f'],
    [0.8470588235294118, '#da9f60'],
    [0.8509803921568627, '#db9e60'],
    [0.8549019607843137, '#dc9e60'],
    [0.8588235294117647, '#dc9e60'],
    [0.8627450980392157, '#dd9d60'],
    [0.8666666666666667, '#de9d61'],
    [0.8705882352941177, '#df9d61'],
    [0.8745098039215686, '#e09c61'],
    [0.8784313725490196, '#e09c61'],
    [0.8823529411764706, '#e19b62'],
    [0.8862745098039215, '#e29b62'],
    [0.8901960784313725, '#e39b62'],
    [0.8941176470588235, '#e39a63'],
    [0.8980392156862745, '#e49a63'],
    [0.9019607843137255, '#e59a63'],
    [0.9058823529411765, '#e59963'],
    [0.9098039215686274, '#e69964'],
    [0.9137254901960784, '#e79864'],
    [0.9176470588235294, '#e89864'],
    [0.9215686274509803, '#e89865'],
    [0.9254901960784314, '#e99765'],
    [0.9294117647058824, '#ea9765'],
    [0.9333333333333333, '#ea9666'],
    [0.9372549019607843, '#eb9666'],
    [0.9411764705882353, '#ec9666'],
    [0.9450980392156862, '#ed9567'],
    [0.9490196078431372, '#ed9567'],
    [0.9529411764705882, '#ee9568'],
    [0.9568627450980391, '#ef9468'],
    [0.9607843137254902, '#ef9468'],
    [0.9647058823529412, '#f09369'],
    [0.9686274509803922, '#f19369'],
    [0.9725490196078431, '#f19269'],
    [0.9764705882352941, '#f2926a'],
    [0.9803921568627451, '#f3926a'],
    [0.984313725490196, '#f3916b'],
    [0.9882352941176471, '#f4916b'],
    [0.9921568627450981, '#f5906b'],
    [0.996078431372549, '#f5906c'],
    [1.0, '#f6906c'],
  ],
  CET_I2: [
    [0.0, '#6fd1ff'],
    [0.00392156862745098, '#6fd1ff'],
    [0.00784313725490196, '#6fd1ff'],
    [0.011764705882352941, '#6fd1ff'],
    [0.01568627450980392, '#6fd1ff'],
    [0.0196078431372549, '#6fd1fe'],
    [0.023529411764705882, '#70d2fd'],
    [0.027450980392156862, '#70d2fc'],
    [0.03137254901960784, '#70d2fc'],
    [0.03529411764705882, '#70d2fb'],
    [0.0392156862745098, '#70d2fa'],
    [0.043137254901960784, '#70d2f9'],
    [0.047058823529411764, '#70d2f8'],
    [0.050980392156862744, '#70d2f8'],
    [0.054901960784313725, '#70d2f7'],
    [0.058823529411764705, '#70d2f6'],
    [0.06274509803921569, '#70d2f5'],
    [0.06666666666666667, '#70d3f4'],
    [0.07058823529411765, '#71d3f4'],
    [0.07450980392156863, '#71d3f3'],
    [0.0784313725490196, '#71d3f2'],
    [0.08235294117647059, '#71d3f1'],
    [0.08627450980392157, '#71d3f0'],
    [0.09019607843137255, '#71d3f0'],
    [0.09411764705882353, '#71d3ef'],
    [0.09803921568627451, '#71d3ee'],
    [0.10196078431372549, '#71d3ed'],
    [0.10588235294117647, '#72d3ec'],
    [0.10980392156862745, '#72d3ec'],
    [0.11372549019607843, '#72d3eb'],
    [0.11764705882352941, '#72d4ea'],
    [0.12156862745098039, '#72d4e9'],
    [0.12549019607843137, '#72d4e8'],
    [0.12941176470588234, '#72d4e7'],
    [0.13333333333333333, '#72d4e7'],
    [0.13725490196078433, '#73d4e6'],
    [0.1411764705882353, '#73d4e5'],
    [0.14509803921568626, '#73d4e4'],
    [0.14901960784313725, '#73d4e3'],
    [0.15294117647058825, '#73d4e3'],
    [0.1568627450980392, '#73d4e2'],
    [0.16078431372549018, '#73d4e1'],
    [0.16470588235294117, '#74d4e0'],
    [0.16862745098039217, '#74d4df'],
    [0.17254901960784313, '#74d5de'],
    [0.1764705882352941, '#74d5de'],
    [0.1803921568627451, '#74d5dd'],
    [0.1843137254901961, '#75d5dc'],
    [0.18823529411764706, '#75d5db'],
    [0.19215686274509802, '#75d5da'],
    [0.19607843137254902, '#75d5d9'],
    [0.2, '#75d5d8'],
    [0.20392156862745098, '#75d5d8'],
    [0.20784313725490194, '#76d5d7'],
    [0.21176470588235294, '#76d5d6'],
    [0.21568627450980393, '#76d5d5'],
    [0.2196078431372549, '#76d5d4'],
    [0.22352941176470587, '#77d5d3'],
    [0.22745098039215686, '#77d5d2'],
    [0.23137254901960785, '#77d5d2'],
    [0.23529411764705882, '#77d6d1'],
    [0.2392156862745098, '#77d6d0'],
    [0.24313725490196078, '#78d6cf'],
    [0.24705882352941178, '#78d6ce'],
    [0.25098039215686274, '#78d6cd'],
    [0.2549019607843137, '#78d6cc'],
    [0.2588235294117647, '#79d6cc'],
    [0.2627450980392157, '#79d6cb'],
    [0.26666666666666666, '#79d6ca'],
    [0.27058823529411763, '#7ad6c9'],
    [0.27450980392156865, '#7ad6c8'],
    [0.2784313725490196, '#7ad6c7'],
    [0.2823529411764706, '#7bd6c6'],
    [0.28627450980392155, '#7bd6c5'],
    [0.2901960784313725, '#7bd6c5'],
    [0.29411764705882354, '#7bd6c4'],
    [0.2980392156862745, '#7cd6c3'],
    [0.30196078431372547, '#7cd6c2'],
    [0.3058823529411765, '#7dd6c1'],
    [0.30980392156862746, '#7dd6c0'],
    [0.3137254901960784, '#7dd6bf'],
    [0.3176470588235294, '#7ed6be'],
    [0.32156862745098036, '#7ed6bd'],
    [0.3254901960784314, '#7ed6bc'],
    [0.32941176470588235, '#7fd6bc'],
    [0.3333333333333333, '#7fd6bb'],
    [0.33725490196078434, '#80d6ba'],
    [0.3411764705882353, '#80d6b9'],
    [0.34509803921568627, '#80d6b8'],
    [0.34901960784313724, '#81d6b7'],
    [0.3529411764705882, '#81d6b6'],
    [0.3568627450980392, '#82d6b5'],
    [0.3607843137254902, '#82d6b4'],
    [0.36470588235294116, '#83d6b3'],
    [0.3686274509803922, '#83d6b2'],
    [0.37254901960784315, '#84d6b2'],
    [0.3764705882352941, '#84d6b1'],
    [0.3803921568627451, '#85d6b0'],
    [0.38431372549019605, '#85d6af'],
    [0.38823529411764707, '#86d6ae'],
    [0.39215686274509803, '#87d6ad'],
    [0.396078431372549, '#87d6ac'],
    [0.4, '#88d6ab'],
    [0.403921568627451, '#88d6aa'],
    [0.40784313725490196, '#89d6a9'],
    [0.4117647058823529, '#8ad6a8'],
    [0.4156862745098039, '#8ad6a7'],
    [0.4196078431372549, '#8bd6a6'],
    [0.4235294117647059, '#8cd6a5'],
    [0.42745098039215684, '#8cd6a5'],
    [0.43137254901960786, '#8dd6a4'],
    [0.43529411764705883, '#8ed6a3'],
    [0.4392156862745098, '#8ed6a2'],
    [0.44313725490196076, '#8fd6a1'],
    [0.44705882352941173, '#90d6a0'],
    [0.45098039215686275, '#91d69f'],
    [0.4549019607843137, '#91d59e'],
    [0.4588235294117647, '#92d59d'],
    [0.4627450980392157, '#93d59c'],
    [0.4666666666666667, '#94d59b'],
    [0.47058823529411764, '#95d59b'],
    [0.4745098039215686, '#95d59a'],
    [0.4784313725490196, '#96d599'],
    [0.4823529411764706, '#97d598'],
    [0.48627450980392156, '#98d597'],
    [0.49019607843137253, '#99d596'],
    [0.49411764705882355, '#9ad495'],
    [0.4980392156862745, '#9bd495'],
    [0.5019607843137255, '#9cd494'],
    [0.5058823529411764, '#9dd493'],
    [0.5098039215686274, '#9ed492'],
    [0.5137254901960784, '#9fd491'],
    [0.5176470588235293, '#a0d491'],
    [0.5215686274509804, '#a1d390'],
    [0.5254901960784314, '#a1d38f'],
    [0.5294117647058824, '#a2d38e'],
    [0.5333333333333333, '#a3d38e'],
    [0.5372549019607843, '#a4d38d'],
    [0.5411764705882353, '#a5d38c'],
    [0.5450980392156862, '#a6d28c'],
    [0.5490196078431373, '#a7d28b'],
    [0.5529411764705883, '#a8d28a'],
    [0.5568627450980392, '#a9d28a'],
    [0.5607843137254902, '#aad289'],
    [0.5647058823529412, '#abd289'],
    [0.5686274509803921, '#add188'],
    [0.5725490196078431, '#aed187'],
    [0.5764705882352941, '#afd187'],
    [0.580392156862745, '#b0d186'],
    [0.5843137254901961, '#b1d086'],
    [0.5882352941176471, '#b2d085'],
    [0.592156862745098, '#b3d085'],
    [0.596078431372549, '#b4d084'],
    [0.6, '#b5d084'],
    [0.6039215686274509, '#b6cf83'],
    [0.6078431372549019, '#b7cf83'],
    [0.611764705882353, '#b8cf82'],
    [0.615686274509804, '#b9cf82'],
    [0.6196078431372549, '#b9cf81'],
    [0.6235294117647059, '#bace81'],
    [0.6274509803921569, '#bbce80'],
    [0.6313725490196078, '#bcce80'],
    [0.6352941176470588, '#bdce7f'],
    [0.6392156862745098, '#becd7f'],
    [0.6431372549019607, '#bfcd7f'],
    [0.6470588235294118, '#c0cd7e'],
    [0.6509803921568628, '#c1cd7e'],
    [0.6549019607843137, '#c2cc7d'],
    [0.6588235294117647, '#c3cc7d'],
    [0.6627450980392157, '#c4cc7d'],
    [0.6666666666666666, '#c5cc7c'],
    [0.6705882352941176, '#c6cb7c'],
    [0.6745098039215687, '#c7cb7b'],
    [0.6784313725490196, '#c8cb7b'],
    [0.6823529411764706, '#c9cb7b'],
    [0.6862745098039216, '#caca7a'],
    [0.6901960784313725, '#cbca7a'],
    [0.6941176470588235, '#ccca7a'],
    [0.6980392156862745, '#ccca79'],
    [0.7019607843137254, '#cdc979'],
    [0.7058823529411764, '#cec979'],
    [0.7098039215686275, '#cfc979'],
    [0.7137254901960784, '#d0c978'],
    [0.7176470588235294, '#d1c878'],
    [0.7215686274509804, '#d2c878'],
    [0.7254901960784313, '#d3c878'],
    [0.7294117647058823, '#d4c777'],
    [0.7333333333333333, '#d5c777'],
    [0.7372549019607844, '#d5c777'],
    [0.7411764705882353, '#d6c777'],
    [0.7450980392156863, '#d7c677'],
    [0.7490196078431373, '#d8c676'],
    [0.7529411764705882, '#d9c676'],
    [0.7568627450980392, '#dac676'],
    [0.7607843137254902, '#dbc576'],
    [0.7647058823529411, '#dcc576'],
    [0.7686274509803921, '#dcc576'],
    [0.7725490196078432, '#ddc476'],
    [0.7764705882352941, '#dec476'],
    [0.7803921568627451, '#dfc475'],
    [0.7843137254901961, '#e0c375'],
    [0.788235294117647, '#e1c375'],
    [0.792156862745098, '#e2c375'],
    [0.796078431372549, '#e2c375'],
    [0.8, '#e3c275'],
    [0.803921568627451, '#e4c275'],
    [0.807843137254902, '#e5c275'],
    [0.8117647058823529, '#e6c175'],
    [0.8156862745098039, '#e7c175'],
    [0.8196078431372549, '#e7c175'],
    [0.8235294117647058, '#e8c075'],
    [0.8274509803921568, '#e9c075'],
    [0.8313725490196078, '#eac075'],
    [0.8352941176470589, '#ebbf75'],
    [0.8392156862745098, '#ebbf76'],
    [0.8431372549019608, '#ecbf76'],
    [0.8470588235294118, '#edbf76'],
    [0.8509803921568627, '#eebe76'],
    [0.8549019607843137, '#efbe76'],
    [0.8588235294117647, '#efbe76'],
    [0.8627450980392157, '#f0bd76'],
    [0.8666666666666667, '#f1bd76'],
    [0.8705882352941177, '#f2bd77'],
    [0.8745098039215686, '#f2bc77'],
    [0.8784313725490196, '#f3bc77'],
    [0.8823529411764706, '#f4bc77'],
    [0.8862745098039215, '#f5bb77'],
    [0.8901960784313725, '#f5bb78'],
    [0.8941176470588235, '#f6bb78'],
    [0.8980392156862745, '#f7ba78'],
    [0.9019607843137255, '#f8ba78'],
    [0.9058823529411765, '#f8ba79'],
    [0.9098039215686274, '#f9b979'],
    [0.9137254901960784, '#fab979'],
    [0.9176470588235294, '#fab979'],
    [0.9215686274509803, '#fbb87a'],
    [0.9254901960784314, '#fcb87a'],
    [0.9294117647058824, '#fcb87a'],
    [0.9333333333333333, '#fdb77b'],
    [0.9372549019607843, '#feb77b'],
    [0.9411764705882353, '#feb77b'],
    [0.9450980392156862, '#ffb67c'],
    [0.9490196078431372, '#ffb67c'],
    [0.9529411764705882, '#ffb67c'],
    [0.9568627450980391, '#ffb57d'],
    [0.9607843137254902, '#ffb57d'],
    [0.9647058823529412, '#ffb57d'],
    [0.9686274509803922, '#ffb47e'],
    [0.9725490196078431, '#ffb47e'],
    [0.9764705882352941, '#ffb47e'],
    [0.9803921568627451, '#ffb37f'],
    [0.984313725490196, '#ffb37f'],
    [0.9882352941176471, '#ffb380'],
    [0.9921568627450981, '#ffb280'],
    [0.996078431372549, '#ffb280'],
    [1.0, '#ffb281'],
  ],
  CET_I3: [
    [0.0, '#13b9e5'],
    [0.00392156862745098, '#18b9e5'],
    [0.00784313725490196, '#1cb9e5'],
    [0.011764705882352941, '#20b9e6'],
    [0.01568627450980392, '#23b8e6'],
    [0.0196078431372549, '#26b8e6'],
    [0.023529411764705882, '#29b8e6'],
    [0.027450980392156862, '#2bb8e6'],
    [0.03137254901960784, '#2eb8e6'],
    [0.03529411764705882, '#30b8e6'],
    [0.0392156862745098, '#32b8e7'],
    [0.043137254901960784, '#34b7e7'],
    [0.047058823529411764, '#36b7e7'],
    [0.050980392156862744, '#38b7e7'],
    [0.054901960784313725, '#3ab7e7'],
    [0.058823529411764705, '#3cb7e7'],
    [0.06274509803921569, '#3eb7e7'],
    [0.06666666666666667, '#3fb6e7'],
    [0.07058823529411765, '#41b6e8'],
    [0.07450980392156863, '#43b6e8'],
    [0.0784313725490196, '#44b6e8'],
    [0.08235294117647059, '#46b6e8'],
    [0.08627450980392157, '#48b6e8'],
    [0.09019607843137255, '#49b5e8'],
    [0.09411764705882353, '#4bb5e8'],
    [0.09803921568627451, '#4cb5e8'],
    [0.10196078431372549, '#4db5e8'],
    [0.10588235294117647, '#4fb5e9'],
    [0.10980392156862745, '#50b5e9'],
    [0.11372549019607843, '#52b5e9'],
    [0.11764705882352941, '#53b4e9'],
    [0.12156862745098039, '#54b4e9'],
    [0.12549019607843137, '#55b4e9'],
    [0.12941176470588234, '#57b4e9'],
    [0.13333333333333333, '#58b4e9'],
    [0.13725490196078433, '#59b4e9'],
    [0.1411764705882353, '#5bb3e9'],
    [0.14509803921568626, '#5cb3ea'],
    [0.14901960784313725, '#5db3ea'],
    [0.15294117647058825, '#5eb3ea'],
    [0.1568627450980392, '#5fb3ea'],
    [0.16078431372549018, '#60b3ea'],
    [0.16470588235294117, '#62b2ea'],
    [0.16862745098039217, '#63b2ea'],
    [0.17254901960784313, '#64b2ea'],
    [0.1764705882352941, '#65b2ea'],
    [0.1803921568627451, '#66b2ea'],
    [0.1843137254901961, '#67b1ea'],
    [0.18823529411764706, '#68b1eb'],
    [0.19215686274509802, '#69b1eb'],
    [0.19607843137254902, '#6bb1eb'],
    [0.2, '#6cb1eb'],
    [0.20392156862745098, '#6db1eb'],
    [0.20784313725490194, '#6eb0eb'],
    [0.21176470588235294, '#6fb0eb'],
    [0.21568627450980393, '#70b0eb'],
    [0.2196078431372549, '#71b0eb'],
    [0.22352941176470587, '#72b0eb'],
    [0.22745098039215686, '#73afeb'],
    [0.23137254901960785, '#74afeb'],
    [0.23529411764705882, '#75afeb'],
    [0.2392156862745098, '#76afeb'],
    [0.24313725490196078, '#77afeb'],
    [0.24705882352941178, '#78afeb'],
    [0.25098039215686274, '#79aeeb'],
    [0.2549019607843137, '#7aaeeb'],
    [0.2588235294117647, '#7baeeb'],
    [0.2627450980392157, '#7caeeb'],
    [0.26666666666666666, '#7daeec'],
    [0.27058823529411763, '#7eadec'],
    [0.27450980392156865, '#7fadec'],
    [0.2784313725490196, '#80adec'],
    [0.2823529411764706, '#81adec'],
    [0.28627450980392155, '#82adec'],
    [0.2901960784313725, '#83acec'],
    [0.29411764705882354, '#84acec'],
    [0.2980392156862745, '#84acec'],
    [0.30196078431372547, '#85acec'],
    [0.3058823529411765, '#86acec'],
    [0.30980392156862746, '#87abec'],
    [0.3137254901960784, '#88abec'],
    [0.3176470588235294, '#89abec'],
    [0.32156862745098036, '#8aabec'],
    [0.3254901960784314, '#8babec'],
    [0.32941176470588235, '#8caaeb'],
    [0.3333333333333333, '#8daaeb'],
    [0.33725490196078434, '#8eaaeb'],
    [0.3411764705882353, '#8faaeb'],
    [0.34509803921568627, '#90aaeb'],
    [0.34901960784313724, '#90a9eb'],
    [0.3529411764705882, '#91a9eb'],
    [0.3568627450980392, '#92a9eb'],
    [0.3607843137254902, '#93a9eb'],
    [0.36470588235294116, '#94a9eb'],
    [0.3686274509803922, '#95a8eb'],
    [0.37254901960784315, '#96a8eb'],
    [0.3764705882352941, '#97a8eb'],
    [0.3803921568627451, '#98a8eb'],
    [0.38431372549019605, '#98a7eb'],
    [0.38823529411764707, '#99a7ea'],
    [0.39215686274509803, '#9aa7ea'],
    [0.396078431372549, '#9ba7ea'],
    [0.4, '#9ca7ea'],
    [0.403921568627451, '#9da6ea'],
    [0.40784313725490196, '#9ea6ea'],
    [0.4117647058823529, '#9fa6ea'],
    [0.4156862745098039, '#9fa6ea'],
    [0.4196078431372549, '#a0a5e9'],
    [0.4235294117647059, '#a1a5e9'],
    [0.42745098039215684, '#a2a5e9'],
    [0.43137254901960786, '#a3a5e9'],
    [0.43529411764705883, '#a4a5e9'],
    [0.4392156862745098, '#a5a4e9'],
    [0.44313725490196076, '#a6a4e8'],
    [0.44705882352941173, '#a6a4e8'],
    [0.45098039215686275, '#a7a4e8'],
    [0.4549019607843137, '#a8a3e8'],
    [0.4588235294117647, '#a9a3e7'],
    [0.4627450980392157, '#aaa3e7'],
    [0.4666666666666667, '#aba3e7'],
    [0.47058823529411764, '#aca3e7'],
    [0.4745098039215686, '#aca2e6'],
    [0.4784313725490196, '#ada2e6'],
    [0.4823529411764706, '#aea2e6'],
    [0.48627450980392156, '#afa2e6'],
    [0.49019607843137253, '#b0a1e5'],
    [0.49411764705882355, '#b0a1e5'],
    [0.4980392156862745, '#b1a1e5'],
    [0.5019607843137255, '#b2a1e4'],
    [0.5058823529411764, '#b3a1e4'],
    [0.5098039215686274, '#b4a0e4'],
    [0.5137254901960784, '#b4a0e3'],
    [0.5176470588235293, '#b5a0e3'],
    [0.5215686274509804, '#b6a0e3'],
    [0.5254901960784314, '#b79fe2'],
    [0.5294117647058824, '#b89fe2'],
    [0.5333333333333333, '#b89fe1'],
    [0.5372549019607843, '#b99fe1'],
    [0.5411764705882353, '#ba9fe1'],
    [0.5450980392156862, '#bb9ee0'],
    [0.5490196078431373, '#bb9ee0'],
    [0.5529411764705883, '#bc9ee0'],
    [0.5568627450980392, '#bd9edf'],
    [0.5607843137254902, '#be9ddf'],
    [0.5647058823529412, '#be9dde'],
    [0.5686274509803921, '#bf9dde'],
    [0.5725490196078431, '#c09dde'],
    [0.5764705882352941, '#c19ddd'],
    [0.580392156862745, '#c19cdd'],
    [0.5843137254901961, '#c29cdc'],
    [0.5882352941176471, '#c39cdc'],
    [0.592156862745098, '#c39cdc'],
    [0.596078431372549, '#c49bdb'],
    [0.6, '#c59bdb'],
    [0.6039215686274509, '#c69bda'],
    [0.6078431372549019, '#c69bda'],
    [0.611764705882353, '#c79bda'],
    [0.615686274509804, '#c89ad9'],
    [0.6196078431372549, '#c89ad9'],
    [0.6235294117647059, '#c99ad8'],
    [0.6274509803921569, '#ca9ad8'],
    [0.6313725490196078, '#ca9ad8'],
    [0.6352941176470588, '#cb99d7'],
    [0.6392156862745098, '#cc99d7'],
    [0.6431372549019607, '#cc99d6'],
    [0.6470588235294118, '#cd99d6'],
    [0.6509803921568628, '#ce98d5'],
    [0.6549019607843137, '#ce98d5'],
    [0.6588235294117647, '#cf98d5'],
    [0.6627450980392157, '#cf98d4'],
    [0.6666666666666666, '#d098d4'],
    [0.6705882352941176, '#d197d3'],
    [0.6745098039215687, '#d197d3'],
    [0.6784313725490196, '#d297d2'],
    [0.6823529411764706, '#d397d2'],
    [0.6862745098039216, '#d397d2'],
    [0.6901960784313725, '#d496d1'],
    [0.6941176470588235, '#d496d1'],
    [0.6980392156862745, '#d596d0'],
    [0.7019607843137254, '#d696d0'],
    [0.7058823529411764, '#d695cf'],
    [0.7098039215686275, '#d795cf'],
    [0.7137254901960784, '#d895ce'],
    [0.7176470588235294, '#d895ce'],
    [0.7215686274509804, '#d995ce'],
    [0.7254901960784313, '#d994cd'],
    [0.7294117647058823, '#da94cd'],
    [0.7333333333333333, '#da94cc'],
    [0.7372549019607844, '#db94cc'],
    [0.7411764705882353, '#dc93cb'],
    [0.7450980392156863, '#dc93cb'],
    [0.7490196078431373, '#dd93ca'],
    [0.7529411764705882, '#dd93ca'],
    [0.7568627450980392, '#de93c9'],
    [0.7607843137254902, '#de92c9'],
    [0.7647058823529411, '#df92c9'],
    [0.7686274509803921, '#e092c8'],
    [0.7725490196078432, '#e092c8'],
    [0.7764705882352941, '#e191c7'],
    [0.7803921568627451, '#e191c7'],
    [0.7843137254901961, '#e291c6'],
    [0.788235294117647, '#e291c6'],
    [0.792156862745098, '#e391c5'],
    [0.796078431372549, '#e390c5'],
    [0.8, '#e490c4'],
    [0.803921568627451, '#e590c4'],
    [0.807843137254902, '#e590c4'],
    [0.8117647058823529, '#e68fc3'],
    [0.8156862745098039, '#e68fc3'],
    [0.8196078431372549, '#e78fc2'],
    [0.8235294117647058, '#e78fc2'],
    [0.8274509803921568, '#e88fc1'],
    [0.8313725490196078, '#e88ec1'],
    [0.8352941176470589, '#e98ec0'],
    [0.8392156862745098, '#e98ec0'],
    [0.8431372549019608, '#ea8ebf'],
    [0.8470588235294118, '#ea8dbf'],
    [0.8509803921568627, '#eb8dbe'],
    [0.8549019607843137, '#eb8dbe'],
    [0.8588235294117647, '#ec8dbd'],
    [0.8627450980392157, '#ec8dbd'],
    [0.8666666666666667, '#ed8cbd'],
    [0.8705882352941177, '#ed8cbc'],
    [0.8745098039215686, '#ee8cbc'],
    [0.8784313725490196, '#ee8cbb'],
    [0.8823529411764706, '#ef8bbb'],
    [0.8862745098039215, '#ef8bba'],
    [0.8901960784313725, '#f08bba'],
    [0.8941176470588235, '#f08bb9'],
    [0.8980392156862745, '#f18bb9'],
    [0.9019607843137255, '#f18ab8'],
    [0.9058823529411765, '#f28ab8'],
    [0.9098039215686274, '#f28ab7'],
    [0.9137254901960784, '#f38ab7'],
    [0.9176470588235294, '#f389b6'],
    [0.9215686274509803, '#f489b6'],
    [0.9254901960784314, '#f489b5'],
    [0.9294117647058824, '#f589b5'],
    [0.9333333333333333, '#f588b5'],
    [0.9372549019607843, '#f688b4'],
    [0.9411764705882353, '#f688b4'],
    [0.9450980392156862, '#f788b3'],
    [0.9490196078431372, '#f787b3'],
    [0.9529411764705882, '#f887b2'],
    [0.9568627450980391, '#f887b2'],
    [0.9607843137254902, '#f887b1'],
    [0.9647058823529412, '#f987b1'],
    [0.9686274509803922, '#f986b0'],
    [0.9725490196078431, '#fa86b0'],
    [0.9764705882352941, '#fa86af'],
    [0.9803921568627451, '#fb86af'],
    [0.984313725490196, '#fb85ae'],
    [0.9882352941176471, '#fc85ae'],
    [0.9921568627450981, '#fc85ad'],
    [0.996078431372549, '#fd85ad'],
    [1.0, '#fd84ac'],
  ],
  CET_L1: [
    [0.0, '#000000'],
    [0.00392156862745098, '#010101'],
    [0.00784313725490196, '#020202'],
    [0.011764705882352941, '#040404'],
    [0.01568627450980392, '#050505'],
    [0.0196078431372549, '#070707'],
    [0.023529411764705882, '#080808'],
    [0.027450980392156862, '#0a0a0a'],
    [0.03137254901960784, '#0b0b0b'],
    [0.03529411764705882, '#0c0c0c'],
    [0.0392156862745098, '#0d0d0d'],
    [0.043137254901960784, '#0e0e0e'],
    [0.047058823529411764, '#101010'],
    [0.050980392156862744, '#111111'],
    [0.054901960784313725, '#121212'],
    [0.058823529411764705, '#121312'],
    [0.06274509803921569, '#131313'],
    [0.06666666666666667, '#141414'],
    [0.07058823529411765, '#151515'],
    [0.07450980392156863, '#161616'],
    [0.0784313725490196, '#171717'],
    [0.08235294117647059, '#171717'],
    [0.08627450980392157, '#181818'],
    [0.09019607843137255, '#191919'],
    [0.09411764705882353, '#1a1a1a'],
    [0.09803921568627451, '#1b1b1b'],
    [0.10196078431372549, '#1b1b1b'],
    [0.10588235294117647, '#1c1c1c'],
    [0.10980392156862745, '#1d1d1d'],
    [0.11372549019607843, '#1e1e1e'],
    [0.11764705882352941, '#1f1f1f'],
    [0.12156862745098039, '#1f1f1f'],
    [0.12549019607843137, '#202020'],
    [0.12941176470588234, '#212121'],
    [0.13333333333333333, '#222222'],
    [0.13725490196078433, '#232323'],
    [0.1411764705882353, '#232323'],
    [0.14509803921568626, '#242424'],
    [0.14901960784313725, '#252525'],
    [0.15294117647058825, '#262626'],
    [0.1568627450980392, '#272727'],
    [0.16078431372549018, '#272727'],
    [0.16470588235294117, '#282828'],
    [0.16862745098039217, '#292929'],
    [0.17254901960784313, '#2a2a2a'],
    [0.1764705882352941, '#2b2b2b'],
    [0.1803921568627451, '#2c2c2c'],
    [0.1843137254901961, '#2c2c2c'],
    [0.18823529411764706, '#2d2d2d'],
    [0.19215686274509802, '#2e2e2e'],
    [0.19607843137254902, '#2f2f2f'],
    [0.2, '#303030'],
    [0.20392156862745098, '#313131'],
    [0.20784313725490194, '#313131'],
    [0.21176470588235294, '#323232'],
    [0.21568627450980393, '#333333'],
    [0.2196078431372549, '#343434'],
    [0.22352941176470587, '#353535'],
    [0.22745098039215686, '#363636'],
    [0.23137254901960785, '#373737'],
    [0.23529411764705882, '#383838'],
    [0.2392156862745098, '#383838'],
    [0.24313725490196078, '#393939'],
    [0.24705882352941178, '#3a3a3a'],
    [0.25098039215686274, '#3b3b3b'],
    [0.2549019607843137, '#3c3c3c'],
    [0.2588235294117647, '#3d3d3d'],
    [0.2627450980392157, '#3e3e3e'],
    [0.26666666666666666, '#3f3f3f'],
    [0.27058823529411763, '#3f3f3f'],
    [0.27450980392156865, '#404040'],
    [0.2784313725490196, '#414141'],
    [0.2823529411764706, '#424242'],
    [0.28627450980392155, '#434343'],
    [0.2901960784313725, '#444444'],
    [0.29411764705882354, '#454545'],
    [0.2980392156862745, '#464646'],
    [0.30196078431372547, '#474747'],
    [0.3058823529411765, '#474747'],
    [0.30980392156862746, '#484848'],
    [0.3137254901960784, '#494949'],
    [0.3176470588235294, '#4a4a4a'],
    [0.32156862745098036, '#4b4b4b'],
    [0.3254901960784314, '#4c4c4c'],
    [0.32941176470588235, '#4d4d4d'],
    [0.3333333333333333, '#4e4e4e'],
    [0.33725490196078434, '#4f4f4f'],
    [0.3411764705882353, '#505050'],
    [0.34509803921568627, '#515151'],
    [0.34901960784313724, '#525252'],
    [0.3529411764705882, '#525252'],
    [0.3568627450980392, '#535353'],
    [0.3607843137254902, '#545454'],
    [0.36470588235294116, '#555555'],
    [0.3686274509803922, '#565656'],
    [0.37254901960784315, '#575757'],
    [0.3764705882352941, '#585858'],
    [0.3803921568627451, '#595959'],
    [0.38431372549019605, '#5a5a5a'],
    [0.38823529411764707, '#5b5b5b'],
    [0.39215686274509803, '#5c5c5c'],
    [0.396078431372549, '#5d5d5d'],
    [0.4, '#5e5e5e'],
    [0.403921568627451, '#5f5f5f'],
    [0.40784313725490196, '#606060'],
    [0.4117647058823529, '#616161'],
    [0.4156862745098039, '#626262'],
    [0.4196078431372549, '#626262'],
    [0.4235294117647059, '#636363'],
    [0.42745098039215684, '#646464'],
    [0.43137254901960786, '#656565'],
    [0.43529411764705883, '#666666'],
    [0.4392156862745098, '#676767'],
    [0.44313725490196076, '#686868'],
    [0.44705882352941173, '#696969'],
    [0.45098039215686275, '#6a6a6a'],
    [0.4549019607843137, '#6b6b6b'],
    [0.4588235294117647, '#6c6c6c'],
    [0.4627450980392157, '#6d6d6d'],
    [0.4666666666666667, '#6e6e6e'],
    [0.47058823529411764, '#6f6f6f'],
    [0.4745098039215686, '#707070'],
    [0.4784313725490196, '#717171'],
    [0.4823529411764706, '#727272'],
    [0.48627450980392156, '#737373'],
    [0.49019607843137253, '#747474'],
    [0.49411764705882355, '#757575'],
    [0.4980392156862745, '#767676'],
    [0.5019607843137255, '#777777'],
    [0.5058823529411764, '#787878'],
    [0.5098039215686274, '#797979'],
    [0.5137254901960784, '#7a7a7a'],
    [0.5176470588235293, '#7b7b7b'],
    [0.5215686274509804, '#7c7c7c'],
    [0.5254901960784314, '#7d7d7d'],
    [0.5294117647058824, '#7e7e7e'],
    [0.5333333333333333, '#7f7f7f'],
    [0.5372549019607843, '#808080'],
    [0.5411764705882353, '#818181'],
    [0.5450980392156862, '#828282'],
    [0.5490196078431373, '#838383'],
    [0.5529411764705883, '#848484'],
    [0.5568627450980392, '#858585'],
    [0.5607843137254902, '#868686'],
    [0.5647058823529412, '#878787'],
    [0.5686274509803921, '#888888'],
    [0.5725490196078431, '#898989'],
    [0.5764705882352941, '#8a8a8a'],
    [0.580392156862745, '#8b8b8b'],
    [0.5843137254901961, '#8c8c8c'],
    [0.5882352941176471, '#8d8d8d'],
    [0.592156862745098, '#8e8e8e'],
    [0.596078431372549, '#8f8f8f'],
    [0.6, '#909090'],
    [0.6039215686274509, '#919191'],
    [0.6078431372549019, '#929292'],
    [0.611764705882353, '#939393'],
    [0.615686274509804, '#949494'],
    [0.6196078431372549, '#959595'],
    [0.6235294117647059, '#969696'],
    [0.6274509803921569, '#979797'],
    [0.6313725490196078, '#989898'],
    [0.6352941176470588, '#999999'],
    [0.6392156862745098, '#9a9a9a'],
    [0.6431372549019607, '#9b9b9b'],
    [0.6470588235294118, '#9c9c9c'],
    [0.6509803921568628, '#9d9d9d'],
    [0.6549019607843137, '#9e9f9f'],
    [0.6588235294117647, '#a0a0a0'],
    [0.6627450980392157, '#a1a1a1'],
    [0.6666666666666666, '#a2a2a2'],
    [0.6705882352941176, '#a3a3a3'],
    [0.6745098039215687, '#a4a4a4'],
    [0.6784313725490196, '#a5a5a5'],
    [0.6823529411764706, '#a6a6a6'],
    [0.6862745098039216, '#a7a7a7'],
    [0.6901960784313725, '#a8a8a8'],
    [0.6941176470588235, '#a9a9a9'],
    [0.6980392156862745, '#aaaaaa'],
    [0.7019607843137254, '#ababab'],
    [0.7058823529411764, '#acacac'],
    [0.7098039215686275, '#adadad'],
    [0.7137254901960784, '#aeaeae'],
    [0.7176470588235294, '#afafaf'],
    [0.7215686274509804, '#b0b0b0'],
    [0.7254901960784313, '#b1b1b1'],
    [0.7294117647058823, '#b2b3b2'],
    [0.7333333333333333, '#b4b4b4'],
    [0.7372549019607844, '#b5b5b5'],
    [0.7411764705882353, '#b6b6b6'],
    [0.7450980392156863, '#b7b7b7'],
    [0.7490196078431373, '#b8b8b8'],
    [0.7529411764705882, '#b9b9b9'],
    [0.7568627450980392, '#bababa'],
    [0.7607843137254902, '#bbbbbb'],
    [0.7647058823529411, '#bcbcbc'],
    [0.7686274509803921, '#bdbdbd'],
    [0.7725490196078432, '#bebebe'],
    [0.7764705882352941, '#bfbfbf'],
    [0.7803921568627451, '#c0c0c0'],
    [0.7843137254901961, '#c1c2c2'],
    [0.788235294117647, '#c3c3c3'],
    [0.792156862745098, '#c4c4c4'],
    [0.796078431372549, '#c5c5c5'],
    [0.8, '#c6c6c6'],
    [0.803921568627451, '#c7c7c7'],
    [0.807843137254902, '#c8c8c8'],
    [0.8117647058823529, '#c9c9c9'],
    [0.8156862745098039, '#cacaca'],
    [0.8196078431372549, '#cbcbcb'],
    [0.8235294117647058, '#cccccc'],
    [0.8274509803921568, '#cdcdcd'],
    [0.8313725490196078, '#cfcfcf'],
    [0.8352941176470589, '#d0d0d0'],
    [0.8392156862745098, '#d1d1d1'],
    [0.8431372549019608, '#d2d2d2'],
    [0.8470588235294118, '#d3d3d3'],
    [0.8509803921568627, '#d4d4d4'],
    [0.8549019607843137, '#d5d5d5'],
    [0.8588235294117647, '#d6d6d6'],
    [0.8627450980392157, '#d7d7d7'],
    [0.8666666666666667, '#d8d8d8'],
    [0.8705882352941177, '#d9dada'],
    [0.8745098039215686, '#dbdbdb'],
    [0.8784313725490196, '#dcdcdc'],
    [0.8823529411764706, '#dddddd'],
    [0.8862745098039215, '#dedede'],
    [0.8901960784313725, '#dfdfdf'],
    [0.8941176470588235, '#e0e0e0'],
    [0.8980392156862745, '#e1e1e1'],
    [0.9019607843137255, '#e2e2e2'],
    [0.9058823529411765, '#e3e4e4'],
    [0.9098039215686274, '#e5e5e5'],
    [0.9137254901960784, '#e6e6e6'],
    [0.9176470588235294, '#e7e7e7'],
    [0.9215686274509803, '#e8e8e8'],
    [0.9254901960784314, '#e9e9e9'],
    [0.9294117647058824, '#eaeaea'],
    [0.9333333333333333, '#ebebeb'],
    [0.9372549019607843, '#ececec'],
    [0.9411764705882353, '#eeeeee'],
    [0.9450980392156862, '#efefef'],
    [0.9490196078431372, '#f0f0f0'],
    [0.9529411764705882, '#f1f1f1'],
    [0.9568627450980391, '#f2f2f2'],
    [0.9607843137254902, '#f3f3f3'],
    [0.9647058823529412, '#f4f4f4'],
    [0.9686274509803922, '#f5f5f5'],
    [0.9725490196078431, '#f7f7f7'],
    [0.9764705882352941, '#f8f8f8'],
    [0.9803921568627451, '#f9f9f9'],
    [0.984313725490196, '#fafafa'],
    [0.9882352941176471, '#fbfbfb'],
    [0.9921568627450981, '#fcfcfc'],
    [0.996078431372549, '#fdfdfd'],
    [1.0, '#feffff'],
  ],
  CET_L10: [
    [0.0, '#659a8f'],
    [0.00392156862745098, '#669a8f'],
    [0.00784313725490196, '#679b8f'],
    [0.011764705882352941, '#679b8e'],
    [0.01568627450980392, '#689b8e'],
    [0.0196078431372549, '#699b8d'],
    [0.023529411764705882, '#6a9c8d'],
    [0.027450980392156862, '#6a9c8c'],
    [0.03137254901960784, '#6b9c8c'],
    [0.03529411764705882, '#6c9c8b'],
    [0.0392156862745098, '#6c9c8b'],
    [0.043137254901960784, '#6d9d8a'],
    [0.047058823529411764, '#6e9d8a'],
    [0.050980392156862744, '#6f9d89'],
    [0.054901960784313725, '#6f9d89'],
    [0.058823529411764705, '#709e88'],
    [0.06274509803921569, '#719e88'],
    [0.06666666666666667, '#729e88'],
    [0.07058823529411765, '#729e87'],
    [0.07450980392156863, '#739f87'],
    [0.0784313725490196, '#749f86'],
    [0.08235294117647059, '#759f86'],
    [0.08627450980392157, '#759f85'],
    [0.09019607843137255, '#769f85'],
    [0.09411764705882353, '#77a084'],
    [0.09803921568627451, '#78a084'],
    [0.10196078431372549, '#78a084'],
    [0.10588235294117647, '#79a083'],
    [0.10980392156862745, '#7aa083'],
    [0.11372549019607843, '#7ba182'],
    [0.11764705882352941, '#7ca182'],
    [0.12156862745098039, '#7ca181'],
    [0.12549019607843137, '#7da181'],
    [0.12941176470588234, '#7ea181'],
    [0.13333333333333333, '#7fa280'],
    [0.13725490196078433, '#80a280'],
    [0.1411764705882353, '#81a27f'],
    [0.14509803921568626, '#81a27f'],
    [0.14901960784313725, '#82a27e'],
    [0.15294117647058825, '#83a37e'],
    [0.1568627450980392, '#84a37e'],
    [0.16078431372549018, '#85a37d'],
    [0.16470588235294117, '#86a37d'],
    [0.16862745098039217, '#87a37c'],
    [0.17254901960784313, '#87a37c'],
    [0.1764705882352941, '#88a37c'],
    [0.1803921568627451, '#89a47b'],
    [0.1843137254901961, '#8aa47b'],
    [0.18823529411764706, '#8ba47b'],
    [0.19215686274509802, '#8ca47a'],
    [0.19607843137254902, '#8da47a'],
    [0.2, '#8ea479'],
    [0.20392156862745098, '#8fa479'],
    [0.20784313725490194, '#90a579'],
    [0.21176470588235294, '#91a578'],
    [0.21568627450980393, '#92a578'],
    [0.2196078431372549, '#93a578'],
    [0.22352941176470587, '#94a577'],
    [0.22745098039215686, '#95a577'],
    [0.23137254901960785, '#96a577'],
    [0.23529411764705882, '#97a576'],
    [0.2392156862745098, '#98a576'],
    [0.24313725490196078, '#99a676'],
    [0.24705882352941178, '#9aa675'],
    [0.25098039215686274, '#9ba675'],
    [0.2549019607843137, '#9da675'],
    [0.2588235294117647, '#9ea674'],
    [0.2627450980392157, '#9fa674'],
    [0.26666666666666666, '#a0a674'],
    [0.27058823529411763, '#a1a674'],
    [0.27450980392156865, '#a3a673'],
    [0.2784313725490196, '#a4a673'],
    [0.2823529411764706, '#a5a673'],
    [0.28627450980392155, '#a6a673'],
    [0.2901960784313725, '#a8a673'],
    [0.29411764705882354, '#a9a672'],
    [0.2980392156862745, '#aaa672'],
    [0.30196078431372547, '#aca672'],
    [0.3058823529411765, '#ada672'],
    [0.30980392156862746, '#afa672'],
    [0.3137254901960784, '#b0a571'],
    [0.3176470588235294, '#b1a571'],
    [0.32156862745098036, '#b3a571'],
    [0.3254901960784314, '#b4a571'],
    [0.32941176470588235, '#b5a571'],
    [0.3333333333333333, '#b6a571'],
    [0.33725490196078434, '#b7a570'],
    [0.3411764705882353, '#b9a570'],
    [0.34509803921568627, '#baa570'],
    [0.34901960784313724, '#bba570'],
    [0.3529411764705882, '#bca570'],
    [0.3568627450980392, '#bda56f'],
    [0.3607843137254902, '#bea56f'],
    [0.36470588235294116, '#bfa56f'],
    [0.3686274509803922, '#c0a56f'],
    [0.37254901960784315, '#c1a56f'],
    [0.3764705882352941, '#c2a56f'],
    [0.3803921568627451, '#c3a56e'],
    [0.38431372549019605, '#c4a66e'],
    [0.38823529411764707, '#c5a66e'],
    [0.39215686274509803, '#c5a66e'],
    [0.396078431372549, '#c6a66e'],
    [0.4, '#c7a66e'],
    [0.403921568627451, '#c8a66d'],
    [0.40784313725490196, '#c8a66d'],
    [0.4117647058823529, '#c9a66d'],
    [0.4156862745098039, '#caa66d'],
    [0.4196078431372549, '#cba76d'],
    [0.4235294117647059, '#cba76d'],
    [0.42745098039215684, '#cca76c'],
    [0.43137254901960786, '#cca76c'],
    [0.43529411764705883, '#cda76c'],
    [0.4392156862745098, '#cea76c'],
    [0.44313725490196076, '#cea86c'],
    [0.44705882352941173, '#cfa86c'],
    [0.45098039215686275, '#cfa86b'],
    [0.4549019607843137, '#d0a86b'],
    [0.4588235294117647, '#d0a86b'],
    [0.4627450980392157, '#d0a96b'],
    [0.4666666666666667, '#d1a96b'],
    [0.47058823529411764, '#d1a96a'],
    [0.4745098039215686, '#d2a96a'],
    [0.4784313725490196, '#d2aa6a'],
    [0.4823529411764706, '#d2aa6a'],
    [0.48627450980392156, '#d3aa6a'],
    [0.49019607843137253, '#d3aa6a'],
    [0.49411764705882355, '#d3ab69'],
    [0.4980392156862745, '#d3ab69'],
    [0.5019607843137255, '#d3ab69'],
    [0.5058823529411764, '#d4ac69'],
    [0.5098039215686274, '#d4ac69'],
    [0.5137254901960784, '#d4ac69'],
    [0.5176470588235293, '#d4ad69'],
    [0.5215686274509804, '#d4ad69'],
    [0.5254901960784314, '#d5ad69'],
    [0.5294117647058824, '#d5ae69'],
    [0.5333333333333333, '#d5ae69'],
    [0.5372549019607843, '#d5ae69'],
    [0.5411764705882353, '#d5af69'],
    [0.5450980392156862, '#d5af69'],
    [0.5490196078431373, '#d6af69'],
    [0.5529411764705883, '#d6b069'],
    [0.5568627450980392, '#d6b069'],
    [0.5607843137254902, '#d6b069'],
    [0.5647058823529412, '#d6b06a'],
    [0.5686274509803921, '#d6b16a'],
    [0.5725490196078431, '#d6b16a'],
    [0.5764705882352941, '#d7b16b'],
    [0.580392156862745, '#d7b26b'],
    [0.5843137254901961, '#d7b26b'],
    [0.5882352941176471, '#d7b26c'],
    [0.592156862745098, '#d7b36c'],
    [0.596078431372549, '#d7b36c'],
    [0.6, '#d7b36d'],
    [0.6039215686274509, '#d7b46d'],
    [0.6078431372549019, '#d7b46e'],
    [0.611764705882353, '#d8b46e'],
    [0.615686274509804, '#d8b56f'],
    [0.6196078431372549, '#d8b570'],
    [0.6235294117647059, '#d8b570'],
    [0.6274509803921569, '#d8b671'],
    [0.6313725490196078, '#d8b672'],
    [0.6352941176470588, '#d8b672'],
    [0.6392156862745098, '#d8b773'],
    [0.6431372549019607, '#d8b774'],
    [0.6470588235294118, '#d8b774'],
    [0.6509803921568628, '#d8b875'],
    [0.6549019607843137, '#d8b876'],
    [0.6588235294117647, '#d8b877'],
    [0.6627450980392157, '#d8b978'],
    [0.6666666666666666, '#d8b979'],
    [0.6705882352941176, '#d8b979'],
    [0.6745098039215687, '#d8ba7a'],
    [0.6784313725490196, '#d8ba7b'],
    [0.6823529411764706, '#d8ba7c'],
    [0.6862745098039216, '#d8bb7d'],
    [0.6901960784313725, '#d8bb7e'],
    [0.6941176470588235, '#d8bb7f'],
    [0.6980392156862745, '#d8bc81'],
    [0.7019607843137254, '#d8bc82'],
    [0.7058823529411764, '#d8bc83'],
    [0.7098039215686275, '#d8bd84'],
    [0.7137254901960784, '#d8bd85'],
    [0.7176470588235294, '#d8bd86'],
    [0.7215686274509804, '#d8be87'],
    [0.7254901960784313, '#d8be88'],
    [0.7294117647058823, '#d8be89'],
    [0.7333333333333333, '#d8bf8a'],
    [0.7372549019607844, '#d8bf8b'],
    [0.7411764705882353, '#d7bf8d'],
    [0.7450980392156863, '#d7c08e'],
    [0.7490196078431373, '#d7c08f'],
    [0.7529411764705882, '#d7c090'],
    [0.7568627450980392, '#d7c191'],
    [0.7607843137254902, '#d7c192'],
    [0.7647058823529411, '#d7c193'],
    [0.7686274509803921, '#d7c294'],
    [0.7725490196078432, '#d7c295'],
    [0.7764705882352941, '#d7c296'],
    [0.7803921568627451, '#d7c297'],
    [0.7843137254901961, '#d7c398'],
    [0.788235294117647, '#d7c39a'],
    [0.792156862745098, '#d7c39b'],
    [0.796078431372549, '#d7c49c'],
    [0.8, '#d7c49d'],
    [0.803921568627451, '#d7c49e'],
    [0.807843137254902, '#d7c59f'],
    [0.8117647058823529, '#d7c5a0'],
    [0.8156862745098039, '#d7c5a1'],
    [0.8196078431372549, '#d7c6a2'],
    [0.8235294117647058, '#d7c6a3'],
    [0.8274509803921568, '#d7c6a4'],
    [0.8313725490196078, '#d7c7a5'],
    [0.8352941176470589, '#d7c7a6'],
    [0.8392156862745098, '#d7c7a8'],
    [0.8431372549019608, '#d7c7a9'],
    [0.8470588235294118, '#d7c8aa'],
    [0.8509803921568627, '#d6c8ab'],
    [0.8549019607843137, '#d6c8ac'],
    [0.8588235294117647, '#d6c9ad'],
    [0.8627450980392157, '#d6c9ae'],
    [0.8666666666666667, '#d6c9af'],
    [0.8705882352941177, '#d6cab0'],
    [0.8745098039215686, '#d6cab1'],
    [0.8784313725490196, '#d6cab2'],
    [0.8823529411764706, '#d6cbb3'],
    [0.8862745098039215, '#d6cbb4'],
    [0.8901960784313725, '#d6cbb6'],
    [0.8941176470588235, '#d6cbb7'],
    [0.8980392156862745, '#d6ccb8'],
    [0.9019607843137255, '#d6ccb9'],
    [0.9058823529411765, '#d6ccba'],
    [0.9098039215686274, '#d6cdbb'],
    [0.9137254901960784, '#d6cdbc'],
    [0.9176470588235294, '#d6cdbd'],
    [0.9215686274509803, '#d5cebe'],
    [0.9254901960784314, '#d5cebf'],
    [0.9294117647058824, '#d5cec0'],
    [0.9333333333333333, '#d5cfc1'],
    [0.9372549019607843, '#d5cfc2'],
    [0.9411764705882353, '#d5cfc4'],
    [0.9450980392156862, '#d5cfc5'],
    [0.9490196078431372, '#d5d0c6'],
    [0.9529411764705882, '#d5d0c7'],
    [0.9568627450980391, '#d5d0c8'],
    [0.9607843137254902, '#d5d1c9'],
    [0.9647058823529412, '#d5d1ca'],
    [0.9686274509803922, '#d4d1cb'],
    [0.9725490196078431, '#d4d2cc'],
    [0.9764705882352941, '#d4d2cd'],
    [0.9803921568627451, '#d4d2ce'],
    [0.984313725490196, '#d4d3cf'],
    [0.9882352941176471, '#d4d3d1'],
    [0.9921568627450981, '#d4d3d2'],
    [0.996078431372549, '#d4d3d3'],
    [1.0, '#d4d4d4'],
  ],
  CET_L11: [
    [0.0, '#6fac5b'],
    [0.00392156862745098, '#71ad5c'],
    [0.00784313725490196, '#72ad5c'],
    [0.011764705882352941, '#73ad5c'],
    [0.01568627450980392, '#75ad5c'],
    [0.0196078431372549, '#76ad5c'],
    [0.023529411764705882, '#77ad5c'],
    [0.027450980392156862, '#79ad5c'],
    [0.03137254901960784, '#7aad5c'],
    [0.03529411764705882, '#7bad5c'],
    [0.0392156862745098, '#7dad5d'],
    [0.043137254901960784, '#7ead5d'],
    [0.047058823529411764, '#7fad5d'],
    [0.050980392156862744, '#80ad5d'],
    [0.054901960784313725, '#82ad5d'],
    [0.058823529411764705, '#83ae5d'],
    [0.06274509803921569, '#84ae5d'],
    [0.06666666666666667, '#85ae5d'],
    [0.07058823529411765, '#87ae5e'],
    [0.07450980392156863, '#88ae5e'],
    [0.0784313725490196, '#89ae5e'],
    [0.08235294117647059, '#8aae5e'],
    [0.08627450980392157, '#8bae5e'],
    [0.09019607843137255, '#8dae5e'],
    [0.09411764705882353, '#8eae5e'],
    [0.09803921568627451, '#8fae5e'],
    [0.10196078431372549, '#90ae5f'],
    [0.10588235294117647, '#91ae5f'],
    [0.10980392156862745, '#93ae5f'],
    [0.11372549019607843, '#94ae5f'],
    [0.11764705882352941, '#95ae5f'],
    [0.12156862745098039, '#96af5f'],
    [0.12549019607843137, '#97af5f'],
    [0.12941176470588234, '#98af5f'],
    [0.13333333333333333, '#99af5f'],
    [0.13725490196078433, '#9aaf60'],
    [0.1411764705882353, '#9caf60'],
    [0.14509803921568626, '#9daf60'],
    [0.14901960784313725, '#9eaf60'],
    [0.15294117647058825, '#9faf60'],
    [0.1568627450980392, '#a0af60'],
    [0.16078431372549018, '#a1af60'],
    [0.16470588235294117, '#a2af60'],
    [0.16862745098039217, '#a3af61'],
    [0.17254901960784313, '#a4af61'],
    [0.1764705882352941, '#a5af61'],
    [0.1803921568627451, '#a6af61'],
    [0.1843137254901961, '#a7af61'],
    [0.18823529411764706, '#a8af61'],
    [0.19215686274509802, '#a9b061'],
    [0.19607843137254902, '#aab061'],
    [0.2, '#acb062'],
    [0.20392156862745098, '#adb062'],
    [0.20784313725490194, '#aeb062'],
    [0.21176470588235294, '#afb062'],
    [0.21568627450980393, '#b0b062'],
    [0.2196078431372549, '#b1b062'],
    [0.22352941176470587, '#b2b062'],
    [0.22745098039215686, '#b3b063'],
    [0.23137254901960785, '#b4b063'],
    [0.23529411764705882, '#b5b063'],
    [0.2392156862745098, '#b6b063'],
    [0.24313725490196078, '#b7b063'],
    [0.24705882352941178, '#b8b063'],
    [0.25098039215686274, '#b8b063'],
    [0.2549019607843137, '#b9b063'],
    [0.2588235294117647, '#bab064'],
    [0.2627450980392157, '#bbb064'],
    [0.26666666666666666, '#bcb164'],
    [0.27058823529411763, '#bdb164'],
    [0.27450980392156865, '#beb164'],
    [0.2784313725490196, '#bfb164'],
    [0.2823529411764706, '#c0b164'],
    [0.28627450980392155, '#c1b164'],
    [0.2901960784313725, '#c2b165'],
    [0.29411764705882354, '#c3b165'],
    [0.2980392156862745, '#c4b165'],
    [0.30196078431372547, '#c5b165'],
    [0.3058823529411765, '#c5b165'],
    [0.30980392156862746, '#c6b165'],
    [0.3137254901960784, '#c7b165'],
    [0.3176470588235294, '#c8b166'],
    [0.32156862745098036, '#c9b166'],
    [0.3254901960784314, '#cab166'],
    [0.32941176470588235, '#cbb166'],
    [0.3333333333333333, '#ccb266'],
    [0.33725490196078434, '#ccb266'],
    [0.3411764705882353, '#cdb266'],
    [0.34509803921568627, '#ceb267'],
    [0.34901960784313724, '#cfb267'],
    [0.3529411764705882, '#d0b267'],
    [0.3568627450980392, '#d1b267'],
    [0.3607843137254902, '#d1b267'],
    [0.36470588235294116, '#d2b267'],
    [0.3686274509803922, '#d3b267'],
    [0.37254901960784315, '#d4b268'],
    [0.3764705882352941, '#d5b268'],
    [0.3803921568627451, '#d5b268'],
    [0.38431372549019605, '#d6b368'],
    [0.38823529411764707, '#d7b368'],
    [0.39215686274509803, '#d8b368'],
    [0.396078431372549, '#d8b368'],
    [0.4, '#d9b369'],
    [0.403921568627451, '#dab369'],
    [0.40784313725490196, '#dbb369'],
    [0.4117647058823529, '#dbb369'],
    [0.4156862745098039, '#dcb369'],
    [0.4196078431372549, '#ddb369'],
    [0.4235294117647059, '#ddb469'],
    [0.42745098039215684, '#deb46a'],
    [0.43137254901960786, '#dfb46a'],
    [0.43529411764705883, '#dfb46a'],
    [0.4392156862745098, '#e0b46a'],
    [0.44313725490196076, '#e0b46a'],
    [0.44705882352941173, '#e1b46a'],
    [0.45098039215686275, '#e1b56b'],
    [0.4549019607843137, '#e2b56b'],
    [0.4588235294117647, '#e2b56b'],
    [0.4627450980392157, '#e3b56b'],
    [0.4666666666666667, '#e3b56b'],
    [0.47058823529411764, '#e4b66b'],
    [0.4745098039215686, '#e4b66b'],
    [0.4784313725490196, '#e5b66c'],
    [0.4823529411764706, '#e5b66c'],
    [0.48627450980392156, '#e5b76c'],
    [0.49019607843137253, '#e6b76c'],
    [0.49411764705882355, '#e6b76c'],
    [0.4980392156862745, '#e6b86d'],
    [0.5019607843137255, '#e6b86d'],
    [0.5058823529411764, '#e6b86d'],
    [0.5098039215686274, '#e6b96d'],
    [0.5137254901960784, '#e6b96d'],
    [0.5176470588235293, '#e6b96e'],
    [0.5215686274509804, '#e6ba6e'],
    [0.5254901960784314, '#e6ba6e'],
    [0.5294117647058824, '#e6ba6f'],
    [0.5333333333333333, '#e6bb6f'],
    [0.5372549019607843, '#e6bb6f'],
    [0.5411764705882353, '#e6bc70'],
    [0.5450980392156862, '#e6bc70'],
    [0.5490196078431373, '#e6bc70'],
    [0.5529411764705883, '#e7bd71'],
    [0.5568627450980392, '#e7bd71'],
    [0.5607843137254902, '#e7bd72'],
    [0.5647058823529412, '#e7be72'],
    [0.5686274509803921, '#e7be73'],
    [0.5725490196078431, '#e7be73'],
    [0.5764705882352941, '#e7bf74'],
    [0.580392156862745, '#e7bf74'],
    [0.5843137254901961, '#e7bf75'],
    [0.5882352941176471, '#e7c076'],
    [0.592156862745098, '#e7c076'],
    [0.596078431372549, '#e7c077'],
    [0.6, '#e7c177'],
    [0.6039215686274509, '#e7c178'],
    [0.6078431372549019, '#e7c179'],
    [0.611764705882353, '#e7c27a'],
    [0.615686274509804, '#e7c27a'],
    [0.6196078431372549, '#e7c37b'],
    [0.6235294117647059, '#e7c37c'],
    [0.6274509803921569, '#e7c37d'],
    [0.6313725490196078, '#e7c47d'],
    [0.6352941176470588, '#e7c47e'],
    [0.6392156862745098, '#e7c47f'],
    [0.6431372549019607, '#e7c580'],
    [0.6470588235294118, '#e7c581'],
    [0.6509803921568628, '#e7c582'],
    [0.6549019607843137, '#e7c683'],
    [0.6588235294117647, '#e7c683'],
    [0.6627450980392157, '#e7c684'],
    [0.6666666666666666, '#e7c785'],
    [0.6705882352941176, '#e7c786'],
    [0.6745098039215687, '#e7c787'],
    [0.6784313725490196, '#e7c888'],
    [0.6823529411764706, '#e7c889'],
    [0.6862745098039216, '#e7c88a'],
    [0.6901960784313725, '#e7c98b'],
    [0.6941176470588235, '#e7c98c'],
    [0.6980392156862745, '#e6c98e'],
    [0.7019607843137254, '#e6ca8f'],
    [0.7058823529411764, '#e6ca90'],
    [0.7098039215686275, '#e6ca91'],
    [0.7137254901960784, '#e6cb92'],
    [0.7176470588235294, '#e6cb93'],
    [0.7215686274509804, '#e6cb94'],
    [0.7254901960784313, '#e6cc95'],
    [0.7294117647058823, '#e6cc96'],
    [0.7333333333333333, '#e6cc98'],
    [0.7372549019607844, '#e6cd99'],
    [0.7411764705882353, '#e6cd9a'],
    [0.7450980392156863, '#e6cd9b'],
    [0.7490196078431373, '#e6ce9c'],
    [0.7529411764705882, '#e6ce9d'],
    [0.7568627450980392, '#e6ce9e'],
    [0.7607843137254902, '#e6cf9f'],
    [0.7647058823529411, '#e6cfa0'],
    [0.7686274509803921, '#e6cfa1'],
    [0.7725490196078432, '#e6d0a3'],
    [0.7764705882352941, '#e5d0a4'],
    [0.7803921568627451, '#e5d0a5'],
    [0.7843137254901961, '#e5d1a6'],
    [0.788235294117647, '#e5d1a7'],
    [0.792156862745098, '#e5d1a8'],
    [0.796078431372549, '#e5d2a9'],
    [0.8, '#e5d2aa'],
    [0.803921568627451, '#e5d2ab'],
    [0.807843137254902, '#e5d3ac'],
    [0.8117647058823529, '#e5d3ad'],
    [0.8156862745098039, '#e5d3af'],
    [0.8196078431372549, '#e5d3b0'],
    [0.8235294117647058, '#e5d4b1'],
    [0.8274509803921568, '#e5d4b2'],
    [0.8313725490196078, '#e5d4b3'],
    [0.8352941176470589, '#e5d5b4'],
    [0.8392156862745098, '#e5d5b5'],
    [0.8431372549019608, '#e5d5b6'],
    [0.8470588235294118, '#e5d6b7'],
    [0.8509803921568627, '#e5d6b8'],
    [0.8549019607843137, '#e5d6b9'],
    [0.8588235294117647, '#e5d7bb'],
    [0.8627450980392157, '#e5d7bc'],
    [0.8666666666666667, '#e4d7bd'],
    [0.8705882352941177, '#e4d8be'],
    [0.8745098039215686, '#e4d8bf'],
    [0.8784313725490196, '#e4d8c0'],
    [0.8823529411764706, '#e4d9c1'],
    [0.8862745098039215, '#e4d9c2'],
    [0.8901960784313725, '#e4d9c3'],
    [0.8941176470588235, '#e4d9c4'],
    [0.8980392156862745, '#e4dac5'],
    [0.9019607843137255, '#e4dac7'],
    [0.9058823529411765, '#e4dac8'],
    [0.9098039215686274, '#e4dbc9'],
    [0.9137254901960784, '#e4dbca'],
    [0.9176470588235294, '#e4dbcb'],
    [0.9215686274509803, '#e4dccc'],
    [0.9254901960784314, '#e4dccd'],
    [0.9294117647058824, '#e3dcce'],
    [0.9333333333333333, '#e3ddcf'],
    [0.9372549019607843, '#e3ddd0'],
    [0.9411764705882353, '#e3ddd1'],
    [0.9450980392156862, '#e3ded3'],
    [0.9490196078431372, '#e3ded4'],
    [0.9529411764705882, '#e3ded5'],
    [0.9568627450980391, '#e3ded6'],
    [0.9607843137254902, '#e3dfd7'],
    [0.9647058823529412, '#e3dfd8'],
    [0.9686274509803922, '#e3dfd9'],
    [0.9725490196078431, '#e3e0da'],
    [0.9764705882352941, '#e2e0db'],
    [0.9803921568627451, '#e2e0dc'],
    [0.984313725490196, '#e2e1dd'],
    [0.9882352941176471, '#e2e1df'],
    [0.9921568627450981, '#e2e1e0'],
    [0.996078431372549, '#e2e2e1'],
    [1.0, '#e2e2e2'],
  ],
  CET_L12: [
    [0.0, '#f0f0f0'],
    [0.00392156862745098, '#eff0f0'],
    [0.00784313725490196, '#efeff0'],
    [0.011764705882352941, '#eeeff0'],
    [0.01568627450980392, '#edeef0'],
    [0.0196078431372549, '#eceef0'],
    [0.023529411764705882, '#ebedf0'],
    [0.027450980392156862, '#ebedf0'],
    [0.03137254901960784, '#eaecf0'],
    [0.03529411764705882, '#e9ecf0'],
    [0.0392156862745098, '#e8ebef'],
    [0.043137254901960784, '#e8ebef'],
    [0.047058823529411764, '#e7eaef'],
    [0.050980392156862744, '#e6eaef'],
    [0.054901960784313725, '#e5e9ef'],
    [0.058823529411764705, '#e5e9ef'],
    [0.06274509803921569, '#e4e9ef'],
    [0.06666666666666667, '#e3e8ef'],
    [0.07058823529411765, '#e2e8ef'],
    [0.07450980392156863, '#e2e7ef'],
    [0.0784313725490196, '#e1e7ef'],
    [0.08235294117647059, '#e0e6ef'],
    [0.08627450980392157, '#dfe6ef'],
    [0.09019607843137255, '#dfe5ef'],
    [0.09411764705882353, '#dee5ee'],
    [0.09803921568627451, '#dde4ee'],
    [0.10196078431372549, '#dce4ee'],
    [0.10588235294117647, '#dbe3ee'],
    [0.10980392156862745, '#dbe3ee'],
    [0.11372549019607843, '#dae2ee'],
    [0.11764705882352941, '#d9e2ee'],
    [0.12156862745098039, '#d8e1ee'],
    [0.12549019607843137, '#d8e1ee'],
    [0.12941176470588234, '#d7e0ee'],
    [0.13333333333333333, '#d6e0ee'],
    [0.13725490196078433, '#d5e0ee'],
    [0.1411764705882353, '#d5dfed'],
    [0.14509803921568626, '#d4dfed'],
    [0.14901960784313725, '#d3deed'],
    [0.15294117647058825, '#d2deed'],
    [0.1568627450980392, '#d2dded'],
    [0.16078431372549018, '#d1dded'],
    [0.16470588235294117, '#d0dced'],
    [0.16862745098039217, '#cfdced'],
    [0.17254901960784313, '#cedbed'],
    [0.1764705882352941, '#cedbed'],
    [0.1803921568627451, '#cddaed'],
    [0.1843137254901961, '#ccdaec'],
    [0.18823529411764706, '#cbd9ec'],
    [0.19215686274509802, '#cbd9ec'],
    [0.19607843137254902, '#cad9ec'],
    [0.2, '#c9d8ec'],
    [0.20392156862745098, '#c8d8ec'],
    [0.20784313725490194, '#c8d7ec'],
    [0.21176470588235294, '#c7d7ec'],
    [0.21568627450980393, '#c6d6ec'],
    [0.2196078431372549, '#c5d6eb'],
    [0.22352941176470587, '#c5d5eb'],
    [0.22745098039215686, '#c4d5eb'],
    [0.23137254901960785, '#c3d4eb'],
    [0.23529411764705882, '#c2d4eb'],
    [0.2392156862745098, '#c1d3eb'],
    [0.24313725490196078, '#c1d3eb'],
    [0.24705882352941178, '#c0d2eb'],
    [0.25098039215686274, '#bfd2eb'],
    [0.2549019607843137, '#bed2ea'],
    [0.2588235294117647, '#bed1ea'],
    [0.2627450980392157, '#bdd1ea'],
    [0.26666666666666666, '#bcd0ea'],
    [0.27058823529411763, '#bbd0ea'],
    [0.27450980392156865, '#bbcfea'],
    [0.2784313725490196, '#bacfea'],
    [0.2823529411764706, '#b9ceea'],
    [0.28627450980392155, '#b8cee9'],
    [0.2901960784313725, '#b8cde9'],
    [0.29411764705882354, '#b7cde9'],
    [0.2980392156862745, '#b6cce9'],
    [0.30196078431372547, '#b5cce9'],
    [0.3058823529411765, '#b5cbe9'],
    [0.30980392156862746, '#b4cbe8'],
    [0.3137254901960784, '#b3cbe8'],
    [0.3176470588235294, '#b2cae8'],
    [0.32156862745098036, '#b2cae8'],
    [0.3254901960784314, '#b1c9e8'],
    [0.32941176470588235, '#b0c9e8'],
    [0.3333333333333333, '#afc8e8'],
    [0.33725490196078434, '#afc8e7'],
    [0.3411764705882353, '#aec7e7'],
    [0.34509803921568627, '#adc7e7'],
    [0.34901960784313724, '#acc6e7'],
    [0.3529411764705882, '#acc6e7'],
    [0.3568627450980392, '#abc5e6'],
    [0.3607843137254902, '#aac5e6'],
    [0.36470588235294116, '#a9c5e6'],
    [0.3686274509803922, '#a9c4e6'],
    [0.37254901960784315, '#a8c4e6'],
    [0.3764705882352941, '#a7c3e5'],
    [0.3803921568627451, '#a6c3e5'],
    [0.38431372549019605, '#a6c2e5'],
    [0.38823529411764707, '#a5c2e5'],
    [0.39215686274509803, '#a4c1e5'],
    [0.396078431372549, '#a4c1e4'],
    [0.4, '#a3c0e4'],
    [0.403921568627451, '#a2c0e4'],
    [0.40784313725490196, '#a1bfe4'],
    [0.4117647058823529, '#a1bfe3'],
    [0.4156862745098039, '#a0bee3'],
    [0.4196078431372549, '#9fbee3'],
    [0.4235294117647059, '#9fbee3'],
    [0.42745098039215684, '#9ebde2'],
    [0.43137254901960786, '#9dbde2'],
    [0.43529411764705883, '#9dbce2'],
    [0.4392156862745098, '#9cbce1'],
    [0.44313725490196076, '#9bbbe1'],
    [0.44705882352941173, '#9bbbe1'],
    [0.45098039215686275, '#9abae0'],
    [0.4549019607843137, '#9abae0'],
    [0.4588235294117647, '#99b9e0'],
    [0.4627450980392157, '#98b9df'],
    [0.4666666666666667, '#98b8df'],
    [0.47058823529411764, '#97b8df'],
    [0.4745098039215686, '#97b7de'],
    [0.4784313725490196, '#96b7de'],
    [0.4823529411764706, '#95b7de'],
    [0.48627450980392156, '#95b6dd'],
    [0.49019607843137253, '#94b6dd'],
    [0.49411764705882355, '#94b5dc'],
    [0.4980392156862745, '#93b5dc'],
    [0.5019607843137255, '#92b4dc'],
    [0.5058823529411764, '#92b4db'],
    [0.5098039215686274, '#91b3db'],
    [0.5137254901960784, '#91b3da'],
    [0.5176470588235293, '#90b2da'],
    [0.5215686274509804, '#8fb2da'],
    [0.5254901960784314, '#8fb1d9'],
    [0.5294117647058824, '#8eb1d9'],
    [0.5333333333333333, '#8eb0d9'],
    [0.5372549019607843, '#8db0d8'],
    [0.5411764705882353, '#8dafd8'],
    [0.5450980392156862, '#8cafd7'],
    [0.5490196078431373, '#8bafd7'],
    [0.5529411764705883, '#8baed6'],
    [0.5568627450980392, '#8aaed6'],
    [0.5607843137254902, '#8aadd6'],
    [0.5647058823529412, '#89add5'],
    [0.5686274509803921, '#89acd5'],
    [0.5725490196078431, '#88acd4'],
    [0.5764705882352941, '#88abd4'],
    [0.580392156862745, '#87abd4'],
    [0.5843137254901961, '#87aad3'],
    [0.5882352941176471, '#86aad3'],
    [0.592156862745098, '#86a9d2'],
    [0.596078431372549, '#85a9d2'],
    [0.6, '#84a8d1'],
    [0.6039215686274509, '#84a8d1'],
    [0.6078431372549019, '#83a7d0'],
    [0.611764705882353, '#83a7d0'],
    [0.615686274509804, '#82a7cf'],
    [0.6196078431372549, '#82a6cf'],
    [0.6235294117647059, '#81a6cf'],
    [0.6274509803921569, '#81a5ce'],
    [0.6313725490196078, '#80a5ce'],
    [0.6352941176470588, '#80a4cd'],
    [0.6392156862745098, '#7fa4cd'],
    [0.6431372549019607, '#7fa3cc'],
    [0.6470588235294118, '#7ea3cc'],
    [0.6509803921568628, '#7ea2cb'],
    [0.6549019607843137, '#7da2cb'],
    [0.6588235294117647, '#7da1ca'],
    [0.6627450980392157, '#7ca1ca'],
    [0.6666666666666666, '#7ca0c9'],
    [0.6705882352941176, '#7ca0c9'],
    [0.6745098039215687, '#7ba0c8'],
    [0.6784313725490196, '#7b9fc8'],
    [0.6823529411764706, '#7a9fc7'],
    [0.6862745098039216, '#799ec7'],
    [0.6901960784313725, '#799ec7'],
    [0.6941176470588235, '#789dc6'],
    [0.6980392156862745, '#789dc6'],
    [0.7019607843137254, '#779cc5'],
    [0.7058823529411764, '#779cc5'],
    [0.7098039215686275, '#769bc4'],
    [0.7137254901960784, '#769bc4'],
    [0.7176470588235294, '#759ac4'],
    [0.7215686274509804, '#749ac3'],
    [0.7254901960784313, '#749ac3'],
    [0.7294117647058823, '#7399c3'],
    [0.7333333333333333, '#7399c2'],
    [0.7372549019607844, '#7298c2'],
    [0.7411764705882353, '#7198c2'],
    [0.7450980392156863, '#7197c1'],
    [0.7490196078431373, '#7097c1'],
    [0.7529411764705882, '#7096c1'],
    [0.7568627450980392, '#6f96c0'],
    [0.7607843137254902, '#6e95c0'],
    [0.7647058823529411, '#6e95c0'],
    [0.7686274509803921, '#6d95bf'],
    [0.7725490196078432, '#6c94bf'],
    [0.7764705882352941, '#6c94bf'],
    [0.7803921568627451, '#6b93be'],
    [0.7843137254901961, '#6a93be'],
    [0.788235294117647, '#6a92be'],
    [0.792156862745098, '#6992bd'],
    [0.796078431372549, '#6891bd'],
    [0.8, '#6791bd'],
    [0.803921568627451, '#6791bd'],
    [0.807843137254902, '#6690bc'],
    [0.8117647058823529, '#6590bc'],
    [0.8156862745098039, '#658fbc'],
    [0.8196078431372549, '#648fbb'],
    [0.8235294117647058, '#638ebb'],
    [0.8274509803921568, '#628ebb'],
    [0.8313725490196078, '#628dbb'],
    [0.8352941176470589, '#618dba'],
    [0.8392156862745098, '#608dba'],
    [0.8431372549019608, '#5f8cba'],
    [0.8470588235294118, '#5f8cba'],
    [0.8509803921568627, '#5e8bb9'],
    [0.8549019607843137, '#5d8bb9'],
    [0.8588235294117647, '#5c8ab9'],
    [0.8627450980392157, '#5b8ab9'],
    [0.8666666666666667, '#5b8ab8'],
    [0.8705882352941177, '#5a89b8'],
    [0.8745098039215686, '#5989b8'],
    [0.8784313725490196, '#5888b8'],
    [0.8823529411764706, '#5788b7'],
    [0.8862745098039215, '#5787b7'],
    [0.8901960784313725, '#5687b7'],
    [0.8941176470588235, '#5586b7'],
    [0.8980392156862745, '#5486b7'],
    [0.9019607843137255, '#5386b6'],
    [0.9058823529411765, '#5285b6'],
    [0.9098039215686274, '#5185b6'],
    [0.9137254901960784, '#5084b6'],
    [0.9176470588235294, '#5084b5'],
    [0.9215686274509803, '#4f83b5'],
    [0.9254901960784314, '#4e83b5'],
    [0.9294117647058824, '#4d83b5'],
    [0.9333333333333333, '#4c82b5'],
    [0.9372549019607843, '#4b82b4'],
    [0.9411764705882353, '#4a81b4'],
    [0.9450980392156862, '#4981b4'],
    [0.9490196078431372, '#4880b4'],
    [0.9529411764705882, '#4780b4'],
    [0.9568627450980391, '#4680b3'],
    [0.9607843137254902, '#457fb3'],
    [0.9647058823529412, '#447fb3'],
    [0.9686274509803922, '#437eb3'],
    [0.9725490196078431, '#427eb3'],
    [0.9764705882352941, '#417eb2'],
    [0.9803921568627451, '#407db2'],
    [0.984313725490196, '#3f7db2'],
    [0.9882352941176471, '#3e7cb2'],
    [0.9921568627450981, '#3c7cb2'],
    [0.996078431372549, '#3b7bb2'],
    [1.0, '#3a7bb1'],
  ],
  CET_L13: [
    [0.0, '#000000'],
    [0.00392156862745098, '#020000'],
    [0.00784313725490196, '#040000'],
    [0.011764705882352941, '#060100'],
    [0.01568627450980392, '#080100'],
    [0.0196078431372549, '#0a0100'],
    [0.023529411764705882, '#0c0200'],
    [0.027450980392156862, '#0e0200'],
    [0.03137254901960784, '#0f0300'],
    [0.03529411764705882, '#110300'],
    [0.0392156862745098, '#130300'],
    [0.043137254901960784, '#140300'],
    [0.047058823529411764, '#160400'],
    [0.050980392156862744, '#170400'],
    [0.054901960784313725, '#180400'],
    [0.058823529411764705, '#1a0400'],
    [0.06274509803921569, '#1b0500'],
    [0.06666666666666667, '#1c0500'],
    [0.07058823529411765, '#1d0500'],
    [0.07450980392156863, '#1e0500'],
    [0.0784313725490196, '#1f0600'],
    [0.08235294117647059, '#200600'],
    [0.08627450980392157, '#210600'],
    [0.09019607843137255, '#220600'],
    [0.09411764705882353, '#230600'],
    [0.09803921568627451, '#240600'],
    [0.10196078431372549, '#250700'],
    [0.10588235294117647, '#260700'],
    [0.10980392156862745, '#270700'],
    [0.11372549019607843, '#280700'],
    [0.11764705882352941, '#290700'],
    [0.12156862745098039, '#2a0800'],
    [0.12549019607843137, '#2b0800'],
    [0.12941176470588234, '#2c0800'],
    [0.13333333333333333, '#2c0800'],
    [0.13725490196078433, '#2d0800'],
    [0.1411764705882353, '#2e0800'],
    [0.14509803921568626, '#2f0800'],
    [0.14901960784313725, '#300900'],
    [0.15294117647058825, '#300900'],
    [0.1568627450980392, '#310900'],
    [0.16078431372549018, '#320900'],
    [0.16470588235294117, '#330900'],
    [0.16862745098039217, '#330900'],
    [0.17254901960784313, '#340900'],
    [0.1764705882352941, '#350a00'],
    [0.1803921568627451, '#350a00'],
    [0.1843137254901961, '#360a00'],
    [0.18823529411764706, '#370a00'],
    [0.19215686274509802, '#380a00'],
    [0.19607843137254902, '#380a00'],
    [0.2, '#390a00'],
    [0.20392156862745098, '#3a0b00'],
    [0.20784313725490194, '#3b0b00'],
    [0.21176470588235294, '#3b0b00'],
    [0.21568627450980393, '#3c0b00'],
    [0.2196078431372549, '#3d0b00'],
    [0.22352941176470587, '#3e0b00'],
    [0.22745098039215686, '#3e0b00'],
    [0.23137254901960785, '#3f0c00'],
    [0.23529411764705882, '#400c00'],
    [0.2392156862745098, '#410c00'],
    [0.24313725490196078, '#420c00'],
    [0.24705882352941178, '#420c00'],
    [0.25098039215686274, '#430c00'],
    [0.2549019607843137, '#440c00'],
    [0.2588235294117647, '#450d00'],
    [0.2627450980392157, '#450d00'],
    [0.26666666666666666, '#460d00'],
    [0.27058823529411763, '#470d00'],
    [0.27450980392156865, '#480d00'],
    [0.2784313725490196, '#480d00'],
    [0.2823529411764706, '#490d00'],
    [0.28627450980392155, '#4a0e00'],
    [0.2901960784313725, '#4b0e00'],
    [0.29411764705882354, '#4b0e00'],
    [0.2980392156862745, '#4c0e00'],
    [0.30196078431372547, '#4d0e00'],
    [0.3058823529411765, '#4e0e00'],
    [0.30980392156862746, '#4f0e00'],
    [0.3137254901960784, '#4f0f00'],
    [0.3176470588235294, '#500f00'],
    [0.32156862745098036, '#510f00'],
    [0.3254901960784314, '#520f00'],
    [0.32941176470588235, '#520f00'],
    [0.3333333333333333, '#530f00'],
    [0.33725490196078434, '#540f00'],
    [0.3411764705882353, '#551000'],
    [0.34509803921568627, '#561000'],
    [0.34901960784313724, '#561000'],
    [0.3529411764705882, '#571000'],
    [0.3568627450980392, '#581000'],
    [0.3607843137254902, '#591000'],
    [0.36470588235294116, '#5a1100'],
    [0.3686274509803922, '#5a1100'],
    [0.37254901960784315, '#5b1100'],
    [0.3764705882352941, '#5c1100'],
    [0.3803921568627451, '#5d1100'],
    [0.38431372549019605, '#5e1100'],
    [0.38823529411764707, '#5e1100'],
    [0.39215686274509803, '#5f1200'],
    [0.396078431372549, '#601200'],
    [0.4, '#611200'],
    [0.403921568627451, '#621200'],
    [0.40784313725490196, '#621200'],
    [0.4117647058823529, '#631200'],
    [0.4156862745098039, '#641200'],
    [0.4196078431372549, '#651300'],
    [0.4235294117647059, '#661300'],
    [0.42745098039215684, '#661300'],
    [0.43137254901960786, '#671300'],
    [0.43529411764705883, '#681300'],
    [0.4392156862745098, '#691300'],
    [0.44313725490196076, '#6a1400'],
    [0.44705882352941173, '#6a1400'],
    [0.45098039215686275, '#6b1400'],
    [0.4549019607843137, '#6c1400'],
    [0.4588235294117647, '#6d1400'],
    [0.4627450980392157, '#6e1400'],
    [0.4666666666666667, '#6e1400'],
    [0.47058823529411764, '#6f1500'],
    [0.4745098039215686, '#701500'],
    [0.4784313725490196, '#711500'],
    [0.4823529411764706, '#721500'],
    [0.48627450980392156, '#731500'],
    [0.49019607843137253, '#731500'],
    [0.49411764705882355, '#741600'],
    [0.4980392156862745, '#751600'],
    [0.5019607843137255, '#761600'],
    [0.5058823529411764, '#771600'],
    [0.5098039215686274, '#771600'],
    [0.5137254901960784, '#781600'],
    [0.5176470588235293, '#791600'],
    [0.5215686274509804, '#7a1700'],
    [0.5254901960784314, '#7b1700'],
    [0.5294117647058824, '#7c1700'],
    [0.5333333333333333, '#7c1700'],
    [0.5372549019607843, '#7d1700'],
    [0.5411764705882353, '#7e1700'],
    [0.5450980392156862, '#7f1800'],
    [0.5490196078431373, '#801800'],
    [0.5529411764705883, '#811800'],
    [0.5568627450980392, '#811800'],
    [0.5607843137254902, '#821800'],
    [0.5647058823529412, '#831800'],
    [0.5686274509803921, '#841900'],
    [0.5725490196078431, '#851900'],
    [0.5764705882352941, '#861900'],
    [0.580392156862745, '#861900'],
    [0.5843137254901961, '#871900'],
    [0.5882352941176471, '#881900'],
    [0.592156862745098, '#891900'],
    [0.596078431372549, '#8a1a00'],
    [0.6, '#8b1a00'],
    [0.6039215686274509, '#8c1a00'],
    [0.6078431372549019, '#8c1a00'],
    [0.611764705882353, '#8d1a00'],
    [0.615686274509804, '#8e1a00'],
    [0.6196078431372549, '#8f1b00'],
    [0.6235294117647059, '#901b00'],
    [0.6274509803921569, '#911b00'],
    [0.6313725490196078, '#911b00'],
    [0.6352941176470588, '#921b00'],
    [0.6392156862745098, '#931b00'],
    [0.6431372549019607, '#941c00'],
    [0.6470588235294118, '#951c00'],
    [0.6509803921568628, '#961c00'],
    [0.6549019607843137, '#971c00'],
    [0.6588235294117647, '#971c00'],
    [0.6627450980392157, '#981c00'],
    [0.6666666666666666, '#991d00'],
    [0.6705882352941176, '#9a1d00'],
    [0.6745098039215687, '#9b1d00'],
    [0.6784313725490196, '#9c1d00'],
    [0.6823529411764706, '#9d1d00'],
    [0.6862745098039216, '#9d1d00'],
    [0.6901960784313725, '#9e1e00'],
    [0.6941176470588235, '#9f1e00'],
    [0.6980392156862745, '#a01e00'],
    [0.7019607843137254, '#a11e00'],
    [0.7058823529411764, '#a21e00'],
    [0.7098039215686275, '#a31e00'],
    [0.7137254901960784, '#a41e00'],
    [0.7176470588235294, '#a41f00'],
    [0.7215686274509804, '#a51f00'],
    [0.7254901960784313, '#a61f00'],
    [0.7294117647058823, '#a71f00'],
    [0.7333333333333333, '#a81f00'],
    [0.7372549019607844, '#a91f00'],
    [0.7411764705882353, '#aa2000'],
    [0.7450980392156863, '#ab2000'],
    [0.7490196078431373, '#ab2000'],
    [0.7529411764705882, '#ac2000'],
    [0.7568627450980392, '#ad2000'],
    [0.7607843137254902, '#ae2000'],
    [0.7647058823529411, '#af2100'],
    [0.7686274509803921, '#b02100'],
    [0.7725490196078432, '#b12100'],
    [0.7764705882352941, '#b22100'],
    [0.7803921568627451, '#b22100'],
    [0.7843137254901961, '#b32100'],
    [0.788235294117647, '#b42200'],
    [0.792156862745098, '#b52200'],
    [0.796078431372549, '#b62200'],
    [0.8, '#b72200'],
    [0.803921568627451, '#b82200'],
    [0.807843137254902, '#b92200'],
    [0.8117647058823529, '#ba2300'],
    [0.8156862745098039, '#ba2300'],
    [0.8196078431372549, '#bb2300'],
    [0.8235294117647058, '#bc2300'],
    [0.8274509803921568, '#bd2300'],
    [0.8313725490196078, '#be2300'],
    [0.8352941176470589, '#bf2400'],
    [0.8392156862745098, '#c02400'],
    [0.8431372549019608, '#c12400'],
    [0.8470588235294118, '#c22400'],
    [0.8509803921568627, '#c22400'],
    [0.8549019607843137, '#c32400'],
    [0.8588235294117647, '#c42500'],
    [0.8627450980392157, '#c52500'],
    [0.8666666666666667, '#c62500'],
    [0.8705882352941177, '#c72500'],
    [0.8745098039215686, '#c82500'],
    [0.8784313725490196, '#c92600'],
    [0.8823529411764706, '#ca2600'],
    [0.8862745098039215, '#cb2600'],
    [0.8901960784313725, '#cb2600'],
    [0.8941176470588235, '#cc2600'],
    [0.8980392156862745, '#cd2600'],
    [0.9019607843137255, '#ce2700'],
    [0.9058823529411765, '#cf2700'],
    [0.9098039215686274, '#d02700'],
    [0.9137254901960784, '#d12700'],
    [0.9176470588235294, '#d22700'],
    [0.9215686274509803, '#d32700'],
    [0.9254901960784314, '#d42800'],
    [0.9294117647058824, '#d52800'],
    [0.9333333333333333, '#d52800'],
    [0.9372549019607843, '#d62800'],
    [0.9411764705882353, '#d72800'],
    [0.9450980392156862, '#d82800'],
    [0.9490196078431372, '#d92900'],
    [0.9529411764705882, '#da2900'],
    [0.9568627450980391, '#db2900'],
    [0.9607843137254902, '#dc2900'],
    [0.9647058823529412, '#dd2900'],
    [0.9686274509803922, '#de2900'],
    [0.9725490196078431, '#df2a00'],
    [0.9764705882352941, '#df2a00'],
    [0.9803921568627451, '#e02a00'],
    [0.984313725490196, '#e12a00'],
    [0.9882352941176471, '#e22a00'],
    [0.9921568627450981, '#e32b00'],
    [0.996078431372549, '#e42b00'],
    [1.0, '#e52b00'],
  ],
  CET_L14: [
    [0.0, '#000000'],
    [0.00392156862745098, '#000000'],
    [0.00784313725490196, '#000100'],
    [0.011764705882352941, '#000200'],
    [0.01568627450980392, '#000300'],
    [0.0196078431372549, '#000400'],
    [0.023529411764705882, '#000500'],
    [0.027450980392156862, '#000600'],
    [0.03137254901960784, '#000700'],
    [0.03529411764705882, '#000800'],
    [0.0392156862745098, '#000900'],
    [0.043137254901960784, '#000a00'],
    [0.047058823529411764, '#000b00'],
    [0.050980392156862744, '#000b00'],
    [0.054901960784313725, '#000c00'],
    [0.058823529411764705, '#000d00'],
    [0.06274509803921569, '#000e00'],
    [0.06666666666666667, '#000e00'],
    [0.07058823529411765, '#000f00'],
    [0.07450980392156863, '#001000'],
    [0.0784313725490196, '#001000'],
    [0.08235294117647059, '#001100'],
    [0.08627450980392157, '#001200'],
    [0.09019607843137255, '#001200'],
    [0.09411764705882353, '#001300'],
    [0.09803921568627451, '#001300'],
    [0.10196078431372549, '#001400'],
    [0.10588235294117647, '#001500'],
    [0.10980392156862745, '#001500'],
    [0.11372549019607843, '#001600'],
    [0.11764705882352941, '#001600'],
    [0.12156862745098039, '#001700'],
    [0.12549019607843137, '#001700'],
    [0.12941176470588234, '#001800'],
    [0.13333333333333333, '#001800'],
    [0.13725490196078433, '#001900'],
    [0.1411764705882353, '#001900'],
    [0.14509803921568626, '#001a00'],
    [0.14901960784313725, '#001a00'],
    [0.15294117647058825, '#001a00'],
    [0.1568627450980392, '#001b00'],
    [0.16078431372549018, '#001b00'],
    [0.16470588235294117, '#001c00'],
    [0.16862745098039217, '#001c00'],
    [0.17254901960784313, '#001d00'],
    [0.1764705882352941, '#001d00'],
    [0.1803921568627451, '#001d00'],
    [0.1843137254901961, '#001e00'],
    [0.18823529411764706, '#001e00'],
    [0.19215686274509802, '#001f00'],
    [0.19607843137254902, '#001f00'],
    [0.2, '#001f00'],
    [0.20392156862745098, '#002000'],
    [0.20784313725490194, '#002000'],
    [0.21176470588235294, '#002100'],
    [0.21568627450980393, '#002100'],
    [0.2196078431372549, '#002100'],
    [0.22352941176470587, '#002200'],
    [0.22745098039215686, '#002200'],
    [0.23137254901960785, '#002300'],
    [0.23529411764705882, '#002300'],
    [0.2392156862745098, '#002400'],
    [0.24313725490196078, '#002400'],
    [0.24705882352941178, '#002400'],
    [0.25098039215686274, '#002500'],
    [0.2549019607843137, '#002500'],
    [0.2588235294117647, '#002600'],
    [0.2627450980392157, '#002600'],
    [0.26666666666666666, '#002600'],
    [0.27058823529411763, '#002700'],
    [0.27450980392156865, '#002700'],
    [0.2784313725490196, '#002800'],
    [0.2823529411764706, '#002800'],
    [0.28627450980392155, '#002900'],
    [0.2901960784313725, '#002900'],
    [0.29411764705882354, '#002900'],
    [0.2980392156862745, '#002a00'],
    [0.30196078431372547, '#002a00'],
    [0.3058823529411765, '#002b00'],
    [0.30980392156862746, '#002b00'],
    [0.3137254901960784, '#002c00'],
    [0.3176470588235294, '#002c00'],
    [0.32156862745098036, '#002c00'],
    [0.3254901960784314, '#002d00'],
    [0.32941176470588235, '#002d00'],
    [0.3333333333333333, '#002e00'],
    [0.33725490196078434, '#002e00'],
    [0.3411764705882353, '#002f00'],
    [0.34509803921568627, '#002f00'],
    [0.34901960784313724, '#003000'],
    [0.3529411764705882, '#003000'],
    [0.3568627450980392, '#003000'],
    [0.3607843137254902, '#003100'],
    [0.36470588235294116, '#003100'],
    [0.3686274509803922, '#003200'],
    [0.37254901960784315, '#003200'],
    [0.3764705882352941, '#003300'],
    [0.3803921568627451, '#003300'],
    [0.38431372549019605, '#003300'],
    [0.38823529411764707, '#003400'],
    [0.39215686274509803, '#003400'],
    [0.396078431372549, '#003500'],
    [0.4, '#003500'],
    [0.403921568627451, '#003600'],
    [0.40784313725490196, '#003600'],
    [0.4117647058823529, '#003700'],
    [0.4156862745098039, '#003700'],
    [0.4196078431372549, '#003700'],
    [0.4235294117647059, '#003800'],
    [0.42745098039215684, '#003800'],
    [0.43137254901960786, '#003900'],
    [0.43529411764705883, '#003900'],
    [0.4392156862745098, '#003a00'],
    [0.44313725490196076, '#003a00'],
    [0.44705882352941173, '#003b00'],
    [0.45098039215686275, '#003b00'],
    [0.4549019607843137, '#003c00'],
    [0.4588235294117647, '#003c00'],
    [0.4627450980392157, '#003c00'],
    [0.4666666666666667, '#003d00'],
    [0.47058823529411764, '#003d00'],
    [0.4745098039215686, '#003e00'],
    [0.4784313725490196, '#003e00'],
    [0.4823529411764706, '#003f00'],
    [0.48627450980392156, '#003f00'],
    [0.49019607843137253, '#004000'],
    [0.49411764705882355, '#004000'],
    [0.4980392156862745, '#004100'],
    [0.5019607843137255, '#004100'],
    [0.5058823529411764, '#004100'],
    [0.5098039215686274, '#004200'],
    [0.5137254901960784, '#004200'],
    [0.5176470588235293, '#004300'],
    [0.5215686274509804, '#004300'],
    [0.5254901960784314, '#004400'],
    [0.5294117647058824, '#004400'],
    [0.5333333333333333, '#004500'],
    [0.5372549019607843, '#004500'],
    [0.5411764705882353, '#004600'],
    [0.5450980392156862, '#004600'],
    [0.5490196078431373, '#004700'],
    [0.5529411764705883, '#004700'],
    [0.5568627450980392, '#004700'],
    [0.5607843137254902, '#004800'],
    [0.5647058823529412, '#004800'],
    [0.5686274509803921, '#004900'],
    [0.5725490196078431, '#004900'],
    [0.5764705882352941, '#004a00'],
    [0.580392156862745, '#004a00'],
    [0.5843137254901961, '#004b00'],
    [0.5882352941176471, '#004b00'],
    [0.592156862745098, '#004c00'],
    [0.596078431372549, '#004c00'],
    [0.6, '#004d00'],
    [0.6039215686274509, '#004d00'],
    [0.6078431372549019, '#004e00'],
    [0.611764705882353, '#004e00'],
    [0.615686274509804, '#004e00'],
    [0.6196078431372549, '#004f00'],
    [0.6235294117647059, '#004f00'],
    [0.6274509803921569, '#005000'],
    [0.6313725490196078, '#005000'],
    [0.6352941176470588, '#005100'],
    [0.6392156862745098, '#005100'],
    [0.6431372549019607, '#005200'],
    [0.6470588235294118, '#005200'],
    [0.6509803921568628, '#005300'],
    [0.6549019607843137, '#005300'],
    [0.6588235294117647, '#005400'],
    [0.6627450980392157, '#005400'],
    [0.6666666666666666, '#005500'],
    [0.6705882352941176, '#005500'],
    [0.6745098039215687, '#005600'],
    [0.6784313725490196, '#005600'],
    [0.6823529411764706, '#005700'],
    [0.6862745098039216, '#005700'],
    [0.6901960784313725, '#005800'],
    [0.6941176470588235, '#005800'],
    [0.6980392156862745, '#005900'],
    [0.7019607843137254, '#005900'],
    [0.7058823529411764, '#005a00'],
    [0.7098039215686275, '#005a00'],
    [0.7137254901960784, '#005a00'],
    [0.7176470588235294, '#005b00'],
    [0.7215686274509804, '#005b00'],
    [0.7254901960784313, '#005c00'],
    [0.7294117647058823, '#005c00'],
    [0.7333333333333333, '#005d00'],
    [0.7372549019607844, '#005d00'],
    [0.7411764705882353, '#005e00'],
    [0.7450980392156863, '#005e00'],
    [0.7490196078431373, '#005f00'],
    [0.7529411764705882, '#005f00'],
    [0.7568627450980392, '#006000'],
    [0.7607843137254902, '#006000'],
    [0.7647058823529411, '#006100'],
    [0.7686274509803921, '#006100'],
    [0.7725490196078432, '#006200'],
    [0.7764705882352941, '#006200'],
    [0.7803921568627451, '#006300'],
    [0.7843137254901961, '#006300'],
    [0.788235294117647, '#006400'],
    [0.792156862745098, '#006400'],
    [0.796078431372549, '#006500'],
    [0.8, '#006500'],
    [0.803921568627451, '#006600'],
    [0.807843137254902, '#006600'],
    [0.8117647058823529, '#006700'],
    [0.8156862745098039, '#006700'],
    [0.8196078431372549, '#006800'],
    [0.8235294117647058, '#006800'],
    [0.8274509803921568, '#006900'],
    [0.8313725490196078, '#006900'],
    [0.8352941176470589, '#006a00'],
    [0.8392156862745098, '#006a00'],
    [0.8431372549019608, '#006b00'],
    [0.8470588235294118, '#006b00'],
    [0.8509803921568627, '#006c00'],
    [0.8549019607843137, '#006c00'],
    [0.8588235294117647, '#006d00'],
    [0.8627450980392157, '#006d00'],
    [0.8666666666666667, '#006e00'],
    [0.8705882352941177, '#006e00'],
    [0.8745098039215686, '#006f00'],
    [0.8784313725490196, '#006f00'],
    [0.8823529411764706, '#007000'],
    [0.8862745098039215, '#007000'],
    [0.8901960784313725, '#007100'],
    [0.8941176470588235, '#007100'],
    [0.8980392156862745, '#007200'],
    [0.9019607843137255, '#007200'],
    [0.9058823529411765, '#007300'],
    [0.9098039215686274, '#007300'],
    [0.9137254901960784, '#007400'],
    [0.9176470588235294, '#007400'],
    [0.9215686274509803, '#007500'],
    [0.9254901960784314, '#007500'],
    [0.9294117647058824, '#007600'],
    [0.9333333333333333, '#007600'],
    [0.9372549019607843, '#007700'],
    [0.9411764705882353, '#007700'],
    [0.9450980392156862, '#007800'],
    [0.9490196078431372, '#007800'],
    [0.9529411764705882, '#007900'],
    [0.9568627450980391, '#007900'],
    [0.9607843137254902, '#007a00'],
    [0.9647058823529412, '#007a00'],
    [0.9686274509803922, '#007b00'],
    [0.9725490196078431, '#007b00'],
    [0.9764705882352941, '#007c00'],
    [0.9803921568627451, '#007c00'],
    [0.984313725490196, '#007d00'],
    [0.9882352941176471, '#007d00'],
    [0.9921568627450981, '#007e00'],
    [0.996078431372549, '#007e00'],
    [1.0, '#007f00'],
  ],
  CET_L15: [
    [0.0, '#000000'],
    [0.00392156862745098, '#000001'],
    [0.00784313725490196, '#000103'],
    [0.011764705882352941, '#000105'],
    [0.01568627450980392, '#000207'],
    [0.0196078431372549, '#000309'],
    [0.023529411764705882, '#01030b'],
    [0.027450980392156862, '#01040d'],
    [0.03137254901960784, '#01040f'],
    [0.03529411764705882, '#010510'],
    [0.0392156862745098, '#010612'],
    [0.043137254901960784, '#020614'],
    [0.047058823529411764, '#020715'],
    [0.050980392156862744, '#020717'],
    [0.054901960784313725, '#020819'],
    [0.058823529411764705, '#02081a'],
    [0.06274509803921569, '#02091c'],
    [0.06666666666666667, '#02091d'],
    [0.07058823529411765, '#030a1f'],
    [0.07450980392156863, '#030a20'],
    [0.0784313725490196, '#030b21'],
    [0.08235294117647059, '#030b23'],
    [0.08627450980392157, '#030c24'],
    [0.09019607843137255, '#030c25'],
    [0.09411764705882353, '#030c27'],
    [0.09803921568627451, '#040d28'],
    [0.10196078431372549, '#040d29'],
    [0.10588235294117647, '#040e2a'],
    [0.10980392156862745, '#040e2b'],
    [0.11372549019607843, '#040e2c'],
    [0.11764705882352941, '#040f2d'],
    [0.12156862745098039, '#040f2e'],
    [0.12549019607843137, '#040f30'],
    [0.12941176470588234, '#041031'],
    [0.13333333333333333, '#051032'],
    [0.13725490196078433, '#051032'],
    [0.1411764705882353, '#051133'],
    [0.14509803921568626, '#051134'],
    [0.14901960784313725, '#051135'],
    [0.15294117647058825, '#051236'],
    [0.1568627450980392, '#051237'],
    [0.16078431372549018, '#051238'],
    [0.16470588235294117, '#051239'],
    [0.16862745098039217, '#05133a'],
    [0.17254901960784313, '#05133b'],
    [0.1764705882352941, '#05133b'],
    [0.1803921568627451, '#06143c'],
    [0.1843137254901961, '#06143d'],
    [0.18823529411764706, '#06143e'],
    [0.19215686274509802, '#06143f'],
    [0.19607843137254902, '#061540'],
    [0.2, '#061540'],
    [0.20392156862745098, '#061541'],
    [0.20784313725490194, '#061542'],
    [0.21176470588235294, '#061643'],
    [0.21568627450980393, '#061644'],
    [0.2196078431372549, '#061645'],
    [0.22352941176470587, '#061745'],
    [0.22745098039215686, '#071746'],
    [0.23137254901960785, '#071747'],
    [0.23529411764705882, '#071748'],
    [0.2392156862745098, '#071849'],
    [0.24313725490196078, '#07184a'],
    [0.24705882352941178, '#07184a'],
    [0.25098039215686274, '#07194b'],
    [0.2549019607843137, '#07194c'],
    [0.2588235294117647, '#07194d'],
    [0.2627450980392157, '#07194e'],
    [0.26666666666666666, '#071a4f'],
    [0.27058823529411763, '#081a50'],
    [0.27450980392156865, '#081a50'],
    [0.2784313725490196, '#081a51'],
    [0.2823529411764706, '#081b52'],
    [0.28627450980392155, '#081b53'],
    [0.2901960784313725, '#081b54'],
    [0.29411764705882354, '#081c55'],
    [0.2980392156862745, '#081c56'],
    [0.30196078431372547, '#081c56'],
    [0.3058823529411765, '#081c57'],
    [0.30980392156862746, '#081d58'],
    [0.3137254901960784, '#081d59'],
    [0.3176470588235294, '#091d5a'],
    [0.32156862745098036, '#091e5b'],
    [0.3254901960784314, '#091e5c'],
    [0.32941176470588235, '#091e5c'],
    [0.3333333333333333, '#091e5d'],
    [0.33725490196078434, '#091f5e'],
    [0.3411764705882353, '#091f5f'],
    [0.34509803921568627, '#091f60'],
    [0.34901960784313724, '#092061'],
    [0.3529411764705882, '#092062'],
    [0.3568627450980392, '#092063'],
    [0.3607843137254902, '#092063'],
    [0.36470588235294116, '#0a2164'],
    [0.3686274509803922, '#0a2165'],
    [0.37254901960784315, '#0a2166'],
    [0.3764705882352941, '#0a2267'],
    [0.3803921568627451, '#0a2268'],
    [0.38431372549019605, '#0a2269'],
    [0.38823529411764707, '#0a236a'],
    [0.39215686274509803, '#0a236b'],
    [0.396078431372549, '#0a236b'],
    [0.4, '#0a236c'],
    [0.403921568627451, '#0a246d'],
    [0.40784313725490196, '#0b246e'],
    [0.4117647058823529, '#0b246f'],
    [0.4156862745098039, '#0b2570'],
    [0.4196078431372549, '#0b2571'],
    [0.4235294117647059, '#0b2572'],
    [0.42745098039215684, '#0b2573'],
    [0.43137254901960786, '#0b2673'],
    [0.43529411764705883, '#0b2674'],
    [0.4392156862745098, '#0b2675'],
    [0.44313725490196076, '#0b2776'],
    [0.44705882352941173, '#0b2777'],
    [0.45098039215686275, '#0c2778'],
    [0.4549019607843137, '#0c2879'],
    [0.4588235294117647, '#0c287a'],
    [0.4627450980392157, '#0c287b'],
    [0.4666666666666667, '#0c287c'],
    [0.47058823529411764, '#0c297c'],
    [0.4745098039215686, '#0c297d'],
    [0.4784313725490196, '#0c297e'],
    [0.4823529411764706, '#0c2a7f'],
    [0.48627450980392156, '#0c2a80'],
    [0.49019607843137253, '#0c2a81'],
    [0.49411764705882355, '#0d2b82'],
    [0.4980392156862745, '#0d2b83'],
    [0.5019607843137255, '#0d2b84'],
    [0.5058823529411764, '#0d2b85'],
    [0.5098039215686274, '#0d2c86'],
    [0.5137254901960784, '#0d2c86'],
    [0.5176470588235293, '#0d2c87'],
    [0.5215686274509804, '#0d2d88'],
    [0.5254901960784314, '#0d2d89'],
    [0.5294117647058824, '#0d2d8a'],
    [0.5333333333333333, '#0d2e8b'],
    [0.5372549019607843, '#0e2e8c'],
    [0.5411764705882353, '#0e2e8d'],
    [0.5450980392156862, '#0e2e8e'],
    [0.5490196078431373, '#0e2f8f'],
    [0.5529411764705883, '#0e2f90'],
    [0.5568627450980392, '#0e2f91'],
    [0.5607843137254902, '#0e3091'],
    [0.5647058823529412, '#0e3092'],
    [0.5686274509803921, '#0e3093'],
    [0.5725490196078431, '#0e3194'],
    [0.5764705882352941, '#0e3195'],
    [0.580392156862745, '#0f3196'],
    [0.5843137254901961, '#0f3297'],
    [0.5882352941176471, '#0f3298'],
    [0.592156862745098, '#0f3299'],
    [0.596078431372549, '#0f329a'],
    [0.6, '#0f339b'],
    [0.6039215686274509, '#0f339c'],
    [0.6078431372549019, '#0f339d'],
    [0.611764705882353, '#0f349e'],
    [0.615686274509804, '#0f349f'],
    [0.6196078431372549, '#0f349f'],
    [0.6235294117647059, '#1035a0'],
    [0.6274509803921569, '#1035a1'],
    [0.6313725490196078, '#1035a2'],
    [0.6352941176470588, '#1036a3'],
    [0.6392156862745098, '#1036a4'],
    [0.6431372549019607, '#1036a5'],
    [0.6470588235294118, '#1036a6'],
    [0.6509803921568628, '#1037a7'],
    [0.6549019607843137, '#1037a8'],
    [0.6588235294117647, '#1037a9'],
    [0.6627450980392157, '#1138aa'],
    [0.6666666666666666, '#1138ab'],
    [0.6705882352941176, '#1138ac'],
    [0.6745098039215687, '#1139ad'],
    [0.6784313725490196, '#1139ae'],
    [0.6823529411764706, '#1139af'],
    [0.6862745098039216, '#113ab0'],
    [0.6901960784313725, '#113ab1'],
    [0.6941176470588235, '#113ab1'],
    [0.6980392156862745, '#113bb2'],
    [0.7019607843137254, '#113bb3'],
    [0.7058823529411764, '#123bb4'],
    [0.7098039215686275, '#123cb5'],
    [0.7137254901960784, '#123cb6'],
    [0.7176470588235294, '#123cb7'],
    [0.7215686274509804, '#123cb8'],
    [0.7254901960784313, '#123db9'],
    [0.7294117647058823, '#123dba'],
    [0.7333333333333333, '#123dbb'],
    [0.7372549019607844, '#123ebc'],
    [0.7411764705882353, '#123ebd'],
    [0.7450980392156863, '#133ebe'],
    [0.7490196078431373, '#133fbf'],
    [0.7529411764705882, '#133fc0'],
    [0.7568627450980392, '#133fc1'],
    [0.7607843137254902, '#1340c2'],
    [0.7647058823529411, '#1340c3'],
    [0.7686274509803921, '#1340c4'],
    [0.7725490196078432, '#1341c5'],
    [0.7764705882352941, '#1341c6'],
    [0.7803921568627451, '#1341c7'],
    [0.7843137254901961, '#1442c8'],
    [0.788235294117647, '#1442c9'],
    [0.792156862745098, '#1442ca'],
    [0.796078431372549, '#1443cb'],
    [0.8, '#1443cc'],
    [0.803921568627451, '#1443cd'],
    [0.807843137254902, '#1444ce'],
    [0.8117647058823529, '#1444cf'],
    [0.8156862745098039, '#1444d0'],
    [0.8196078431372549, '#1444d1'],
    [0.8235294117647058, '#1445d1'],
    [0.8274509803921568, '#1545d2'],
    [0.8313725490196078, '#1545d3'],
    [0.8352941176470589, '#1546d4'],
    [0.8392156862745098, '#1546d5'],
    [0.8431372549019608, '#1546d6'],
    [0.8470588235294118, '#1547d7'],
    [0.8509803921568627, '#1547d8'],
    [0.8549019607843137, '#1547d9'],
    [0.8588235294117647, '#1548da'],
    [0.8627450980392157, '#1548db'],
    [0.8666666666666667, '#1648dc'],
    [0.8705882352941177, '#1649dd'],
    [0.8745098039215686, '#1649de'],
    [0.8784313725490196, '#1649df'],
    [0.8823529411764706, '#164ae0'],
    [0.8862745098039215, '#164ae1'],
    [0.8901960784313725, '#164ae2'],
    [0.8941176470588235, '#164be3'],
    [0.8980392156862745, '#164be4'],
    [0.9019607843137255, '#164be5'],
    [0.9058823529411765, '#174ce6'],
    [0.9098039215686274, '#174ce7'],
    [0.9137254901960784, '#174ce8'],
    [0.9176470588235294, '#174de9'],
    [0.9215686274509803, '#174dea'],
    [0.9254901960784314, '#174deb'],
    [0.9294117647058824, '#174eec'],
    [0.9333333333333333, '#174eed'],
    [0.9372549019607843, '#174eee'],
    [0.9411764705882353, '#174fef'],
    [0.9450980392156862, '#184ff0'],
    [0.9490196078431372, '#184ff1'],
    [0.9529411764705882, '#1850f2'],
    [0.9568627450980391, '#1850f3'],
    [0.9607843137254902, '#1850f4'],
    [0.9647058823529412, '#1851f5'],
    [0.9686274509803922, '#1851f6'],
    [0.9725490196078431, '#1851f7'],
    [0.9764705882352941, '#1852f8'],
    [0.9803921568627451, '#1852f9'],
    [0.984313725490196, '#1952fa'],
    [0.9882352941176471, '#1953fb'],
    [0.9921568627450981, '#1953fc'],
    [0.996078431372549, '#1953fd'],
    [1.0, '#1954ff'],
  ],
  CET_L16: [
    [0.0, '#101010'],
    [0.00392156862745098, '#121114'],
    [0.00784313725490196, '#141118'],
    [0.011764705882352941, '#16111b'],
    [0.01568627450980392, '#17121e'],
    [0.0196078431372549, '#181221'],
    [0.023529411764705882, '#191224'],
    [0.027450980392156862, '#1a1228'],
    [0.03137254901960784, '#1b122b'],
    [0.03529411764705882, '#1b132e'],
    [0.0392156862745098, '#1c1331'],
    [0.043137254901960784, '#1d1335'],
    [0.047058823529411764, '#1e1338'],
    [0.050980392156862744, '#1e133b'],
    [0.054901960784313725, '#1f133e'],
    [0.058823529411764705, '#201342'],
    [0.06274509803921569, '#201345'],
    [0.06666666666666667, '#201348'],
    [0.07058823529411765, '#21134b'],
    [0.07450980392156863, '#21134f'],
    [0.0784313725490196, '#211352'],
    [0.08235294117647059, '#221355'],
    [0.08627450980392157, '#221458'],
    [0.09019607843137255, '#22145c'],
    [0.09411764705882353, '#22145f'],
    [0.09803921568627451, '#221462'],
    [0.10196078431372549, '#221465'],
    [0.10588235294117647, '#221468'],
    [0.10980392156862745, '#22146b'],
    [0.11372549019607843, '#22146e'],
    [0.11764705882352941, '#221471'],
    [0.12156862745098039, '#211574'],
    [0.12549019607843137, '#211577'],
    [0.12941176470588234, '#21157a'],
    [0.13333333333333333, '#20157d'],
    [0.13725490196078433, '#201680'],
    [0.1411764705882353, '#1f1683'],
    [0.14509803921568626, '#1f1686'],
    [0.14901960784313725, '#1e1789'],
    [0.15294117647058825, '#1d178b'],
    [0.1568627450980392, '#1d188e'],
    [0.16078431372549018, '#1c1891'],
    [0.16470588235294117, '#1b1993'],
    [0.16862745098039217, '#1a1996'],
    [0.17254901960784313, '#1a1a98'],
    [0.1764705882352941, '#191b9a'],
    [0.1803921568627451, '#181b9d'],
    [0.1843137254901961, '#171c9f'],
    [0.18823529411764706, '#161da1'],
    [0.19215686274509802, '#151ea3'],
    [0.19607843137254902, '#131fa5'],
    [0.2, '#121fa7'],
    [0.20392156862745098, '#1120a9'],
    [0.20784313725490194, '#1022ab'],
    [0.21176470588235294, '#0f23ac'],
    [0.21568627450980393, '#0e24ae'],
    [0.2196078431372549, '#0d25af'],
    [0.22352941176470587, '#0c26b0'],
    [0.22745098039215686, '#0b27b1'],
    [0.23137254901960785, '#0a29b2'],
    [0.23529411764705882, '#092ab3'],
    [0.2392156862745098, '#092cb4'],
    [0.24313725490196078, '#082db4'],
    [0.24705882352941178, '#082fb5'],
    [0.25098039215686274, '#0830b5'],
    [0.2549019607843137, '#0732b6'],
    [0.2588235294117647, '#0733b6'],
    [0.2627450980392157, '#0735b6'],
    [0.26666666666666666, '#0736b6'],
    [0.27058823529411763, '#0738b7'],
    [0.27450980392156865, '#0639b7'],
    [0.2784313725490196, '#063ab7'],
    [0.2823529411764706, '#063cb7'],
    [0.28627450980392155, '#063db7'],
    [0.2901960784313725, '#063fb7'],
    [0.29411764705882354, '#0640b7'],
    [0.2980392156862745, '#0642b7'],
    [0.30196078431372547, '#0643b7'],
    [0.3058823529411765, '#0645b7'],
    [0.30980392156862746, '#0646b7'],
    [0.3137254901960784, '#0647b7'],
    [0.3176470588235294, '#0649b7'],
    [0.32156862745098036, '#064ab6'],
    [0.3254901960784314, '#064cb6'],
    [0.32941176470588235, '#064db6'],
    [0.3333333333333333, '#074fb5'],
    [0.33725490196078434, '#0750b5'],
    [0.3411764705882353, '#0751b4'],
    [0.34509803921568627, '#0753b3'],
    [0.34901960784313724, '#0754b3'],
    [0.3529411764705882, '#0756b2'],
    [0.3568627450980392, '#0757b1'],
    [0.3607843137254902, '#0659b0'],
    [0.36470588235294116, '#065aaf'],
    [0.3686274509803922, '#065cae'],
    [0.37254901960784315, '#065dad'],
    [0.3764705882352941, '#055fac'],
    [0.3803921568627451, '#0560aa'],
    [0.38431372549019605, '#0662a9'],
    [0.38823529411764707, '#0763a7'],
    [0.39215686274509803, '#0865a6'],
    [0.396078431372549, '#0a66a4'],
    [0.4, '#0c67a3'],
    [0.403921568627451, '#0e69a1'],
    [0.40784313725490196, '#106a9f'],
    [0.4117647058823529, '#136c9d'],
    [0.4156862745098039, '#156d9b'],
    [0.4196078431372549, '#176e9a'],
    [0.4235294117647059, '#197098'],
    [0.42745098039215684, '#1a7196'],
    [0.43137254901960786, '#1c7293'],
    [0.43529411764705883, '#1e7491'],
    [0.4392156862745098, '#20758f'],
    [0.44313725490196076, '#21768d'],
    [0.44705882352941173, '#23788b'],
    [0.45098039215686275, '#247988'],
    [0.4549019607843137, '#257a86'],
    [0.4588235294117647, '#267c84'],
    [0.4627450980392157, '#287d81'],
    [0.4666666666666667, '#297e7f'],
    [0.47058823529411764, '#2a7f7d'],
    [0.4745098039215686, '#2b817a'],
    [0.4784313725490196, '#2b8277'],
    [0.4823529411764706, '#2c8375'],
    [0.48627450980392156, '#2d8572'],
    [0.49019607843137253, '#2e8670'],
    [0.49411764705882355, '#2e876d'],
    [0.4980392156862745, '#2f886a'],
    [0.5019607843137255, '#2f8a67'],
    [0.5058823529411764, '#2f8b65'],
    [0.5098039215686274, '#308c62'],
    [0.5137254901960784, '#308e5f'],
    [0.5176470588235293, '#308f5c'],
    [0.5215686274509804, '#309059'],
    [0.5254901960784314, '#319157'],
    [0.5294117647058824, '#319354'],
    [0.5333333333333333, '#329452'],
    [0.5372549019607843, '#33954f'],
    [0.5411764705882353, '#33964d'],
    [0.5450980392156862, '#34974a'],
    [0.5490196078431373, '#359848'],
    [0.5529411764705883, '#369946'],
    [0.5568627450980392, '#379b44'],
    [0.5607843137254902, '#399c42'],
    [0.5647058823529412, '#3a9d40'],
    [0.5686274509803921, '#3b9e3e'],
    [0.5725490196078431, '#3d9f3c'],
    [0.5764705882352941, '#3ea03a'],
    [0.580392156862745, '#40a138'],
    [0.5843137254901961, '#41a236'],
    [0.5882352941176471, '#43a334'],
    [0.592156862745098, '#45a432'],
    [0.596078431372549, '#47a531'],
    [0.6, '#48a62f'],
    [0.6039215686274509, '#4aa72d'],
    [0.6078431372549019, '#4ca82b'],
    [0.611764705882353, '#4ea92a'],
    [0.615686274509804, '#50aa28'],
    [0.6196078431372549, '#52ab26'],
    [0.6235294117647059, '#54ac25'],
    [0.6274509803921569, '#57ac23'],
    [0.6313725490196078, '#59ad21'],
    [0.6352941176470588, '#5bae20'],
    [0.6392156862745098, '#5daf1e'],
    [0.6431372549019607, '#5fb01c'],
    [0.6470588235294118, '#62b11b'],
    [0.6509803921568628, '#64b219'],
    [0.6549019607843137, '#66b318'],
    [0.6588235294117647, '#69b316'],
    [0.6627450980392157, '#6bb414'],
    [0.6666666666666666, '#6eb513'],
    [0.6705882352941176, '#70b611'],
    [0.6745098039215687, '#72b710'],
    [0.6784313725490196, '#75b70e'],
    [0.6823529411764706, '#77b80d'],
    [0.6862745098039216, '#7ab90b'],
    [0.6901960784313725, '#7cba09'],
    [0.6941176470588235, '#7fbb08'],
    [0.6980392156862745, '#81bb07'],
    [0.7019607843137254, '#84bc06'],
    [0.7058823529411764, '#87bd05'],
    [0.7098039215686275, '#89bd04'],
    [0.7137254901960784, '#8cbe03'],
    [0.7176470588235294, '#8ebf03'],
    [0.7215686274509804, '#91c002'],
    [0.7254901960784313, '#94c002'],
    [0.7294117647058823, '#96c102'],
    [0.7333333333333333, '#99c202'],
    [0.7372549019607844, '#9cc202'],
    [0.7411764705882353, '#9ec302'],
    [0.7450980392156863, '#a1c402'],
    [0.7490196078431373, '#a3c402'],
    [0.7529411764705882, '#a6c503'],
    [0.7568627450980392, '#a8c603'],
    [0.7607843137254902, '#abc603'],
    [0.7647058823529411, '#adc704'],
    [0.7686274509803921, '#b0c804'],
    [0.7725490196078432, '#b2c805'],
    [0.7764705882352941, '#b4c905'],
    [0.7803921568627451, '#b7ca06'],
    [0.7843137254901961, '#b9ca07'],
    [0.788235294117647, '#bccb08'],
    [0.792156862745098, '#becc09'],
    [0.796078431372549, '#c0cd0a'],
    [0.8, '#c2cd0b'],
    [0.803921568627451, '#c5ce0c'],
    [0.807843137254902, '#c7cf0e'],
    [0.8117647058823529, '#c9cf0f'],
    [0.8156862745098039, '#cbd010'],
    [0.8196078431372549, '#cdd112'],
    [0.8235294117647058, '#cfd213'],
    [0.8274509803921568, '#d1d214'],
    [0.8313725490196078, '#d3d316'],
    [0.8352941176470589, '#d5d417'],
    [0.8392156862745098, '#d7d419'],
    [0.8431372549019608, '#d9d51a'],
    [0.8470588235294118, '#dbd61c'],
    [0.8509803921568627, '#ddd71d'],
    [0.8549019607843137, '#dfd81f'],
    [0.8588235294117647, '#e1d821'],
    [0.8627450980392157, '#e3d922'],
    [0.8666666666666667, '#e4da24'],
    [0.8705882352941177, '#e6db26'],
    [0.8745098039215686, '#e8dc28'],
    [0.8784313725490196, '#e9dd29'],
    [0.8823529411764706, '#ebdd2b'],
    [0.8862745098039215, '#ecde2d'],
    [0.8901960784313725, '#eedf2f'],
    [0.8941176470588235, '#efe032'],
    [0.8980392156862745, '#f0e134'],
    [0.9019607843137255, '#f1e236'],
    [0.9058823529411765, '#f2e339'],
    [0.9098039215686274, '#f3e43b'],
    [0.9137254901960784, '#f4e53e'],
    [0.9176470588235294, '#f5e741'],
    [0.9215686274509803, '#f5e845'],
    [0.9254901960784314, '#f6e949'],
    [0.9294117647058824, '#f7ea4d'],
    [0.9333333333333333, '#f8eb52'],
    [0.9372549019607843, '#f8ec57'],
    [0.9411764705882353, '#f9ed5d'],
    [0.9450980392156862, '#faee63'],
    [0.9490196078431372, '#fbef69'],
    [0.9529411764705882, '#fbf070'],
    [0.9568627450980391, '#fcf178'],
    [0.9607843137254902, '#fdf280'],
    [0.9647058823529412, '#fdf288'],
    [0.9686274509803922, '#fef392'],
    [0.9725490196078431, '#fef49b'],
    [0.9764705882352941, '#fff5a6'],
    [0.9803921568627451, '#fff6b1'],
    [0.984313725490196, '#fef6bd'],
    [0.9882352941176471, '#fef7ca'],
    [0.9921568627450981, '#fdf8d9'],
    [0.996078431372549, '#fbf8e8'],
    [1.0, '#f9f9f9'],
  ],
  CET_L17: [
    [0.0, '#feffff'],
    [0.00392156862745098, '#fefefc'],
    [0.00784313725490196, '#fefdf9'],
    [0.011764705882352941, '#fdfcf6'],
    [0.01568627450980392, '#fdfbf3'],
    [0.0196078431372549, '#fcfbf0'],
    [0.023529411764705882, '#fcfaee'],
    [0.027450980392156862, '#fbf9eb'],
    [0.03137254901960784, '#fbf8e8'],
    [0.03529411764705882, '#faf8e5'],
    [0.0392156862745098, '#faf7e2'],
    [0.043137254901960784, '#f9f6e0'],
    [0.047058823529411764, '#f9f5dd'],
    [0.050980392156862744, '#f8f4da'],
    [0.054901960784313725, '#f7f4d7'],
    [0.058823529411764705, '#f7f3d4'],
    [0.06274509803921569, '#f6f2d2'],
    [0.06666666666666667, '#f6f1cf'],
    [0.07058823529411765, '#f5f1cc'],
    [0.07450980392156863, '#f4f0c9'],
    [0.0784313725490196, '#f4efc7'],
    [0.08235294117647059, '#f3eec4'],
    [0.08627450980392157, '#f3eec1'],
    [0.09019607843137255, '#f2edbe'],
    [0.09411764705882353, '#f2ecbc'],
    [0.09803921568627451, '#f2ebbb'],
    [0.10196078431372549, '#f2eab9'],
    [0.10588235294117647, '#f2e9b7'],
    [0.10980392156862745, '#f2e8b6'],
    [0.11372549019607843, '#f2e7b4'],
    [0.11764705882352941, '#f2e6b2'],
    [0.12156862745098039, '#f2e5b1'],
    [0.12549019607843137, '#f2e4af'],
    [0.12941176470588234, '#f2e2ad'],
    [0.13333333333333333, '#f2e1ac'],
    [0.13725490196078433, '#f2e0aa'],
    [0.1411764705882353, '#f2dfa8'],
    [0.14509803921568626, '#f2dea7'],
    [0.14901960784313725, '#f2dda5'],
    [0.15294117647058825, '#f2dca3'],
    [0.1568627450980392, '#f2dba2'],
    [0.16078431372549018, '#f2daa0'],
    [0.16470588235294117, '#f2d99e'],
    [0.16862745098039217, '#f2d89d'],
    [0.17254901960784313, '#f2d79b'],
    [0.1764705882352941, '#f2d699'],
    [0.1803921568627451, '#f1d598'],
    [0.1843137254901961, '#f2d496'],
    [0.18823529411764706, '#f2d395'],
    [0.19215686274509802, '#f2d194'],
    [0.19607843137254902, '#f2d093'],
    [0.2, '#f2cf92'],
    [0.20392156862745098, '#f2ce90'],
    [0.20784313725490194, '#f2cd8f'],
    [0.21176470588235294, '#f2cc8e'],
    [0.21568627450980393, '#f3cb8d'],
    [0.2196078431372549, '#f3c98c'],
    [0.22352941176470587, '#f3c88a'],
    [0.22745098039215686, '#f3c789'],
    [0.23137254901960785, '#f3c688'],
    [0.23529411764705882, '#f3c587'],
    [0.2392156862745098, '#f3c486'],
    [0.24313725490196078, '#f3c284'],
    [0.24705882352941178, '#f3c183'],
    [0.25098039215686274, '#f3c082'],
    [0.2549019607843137, '#f3bf81'],
    [0.2588235294117647, '#f3be80'],
    [0.2627450980392157, '#f4bd7e'],
    [0.26666666666666666, '#f4bb7d'],
    [0.27058823529411763, '#f4ba7c'],
    [0.27450980392156865, '#f4b97b'],
    [0.2784313725490196, '#f4b87a'],
    [0.2823529411764706, '#f4b77a'],
    [0.28627450980392155, '#f4b579'],
    [0.2901960784313725, '#f4b478'],
    [0.29411764705882354, '#f4b378'],
    [0.2980392156862745, '#f4b277'],
    [0.30196078431372547, '#f4b076'],
    [0.3058823529411765, '#f4af75'],
    [0.30980392156862746, '#f4ae75'],
    [0.3137254901960784, '#f4ad74'],
    [0.3176470588235294, '#f4ac73'],
    [0.32156862745098036, '#f5aa73'],
    [0.3254901960784314, '#f5a972'],
    [0.32941176470588235, '#f5a871'],
    [0.3333333333333333, '#f5a771'],
    [0.33725490196078434, '#f5a570'],
    [0.3411764705882353, '#f5a46f'],
    [0.34509803921568627, '#f5a36f'],
    [0.34901960784313724, '#f5a16e'],
    [0.3529411764705882, '#f5a06d'],
    [0.3568627450980392, '#f59f6c'],
    [0.3607843137254902, '#f59e6c'],
    [0.36470588235294116, '#f59c6b'],
    [0.3686274509803922, '#f59b6b'],
    [0.37254901960784315, '#f59a6b'],
    [0.3764705882352941, '#f5996b'],
    [0.3803921568627451, '#f5976a'],
    [0.38431372549019605, '#f4966a'],
    [0.38823529411764707, '#f4956a'],
    [0.39215686274509803, '#f4936a'],
    [0.396078431372549, '#f4926a'],
    [0.4, '#f49169'],
    [0.403921568627451, '#f49069'],
    [0.40784313725490196, '#f48e69'],
    [0.4117647058823529, '#f48d69'],
    [0.4156862745098039, '#f48c68'],
    [0.4196078431372549, '#f48a68'],
    [0.4235294117647059, '#f48968'],
    [0.42745098039215684, '#f38868'],
    [0.43137254901960786, '#f38668'],
    [0.43529411764705883, '#f38567'],
    [0.4392156862745098, '#f38467'],
    [0.44313725490196076, '#f38267'],
    [0.44705882352941173, '#f38167'],
    [0.45098039215686275, '#f38066'],
    [0.4549019607843137, '#f37e66'],
    [0.4588235294117647, '#f27d66'],
    [0.4627450980392157, '#f27c67'],
    [0.4666666666666667, '#f27a67'],
    [0.47058823529411764, '#f17967'],
    [0.4745098039215686, '#f17867'],
    [0.4784313725490196, '#f17767'],
    [0.4823529411764706, '#f07568'],
    [0.48627450980392156, '#f07468'],
    [0.49019607843137253, '#f07368'],
    [0.49411764705882355, '#ef7168'],
    [0.4980392156862745, '#ef7068'],
    [0.5019607843137255, '#ef6f69'],
    [0.5058823529411764, '#ee6d69'],
    [0.5098039215686274, '#ee6c69'],
    [0.5137254901960784, '#ee6b69'],
    [0.5176470588235293, '#ed6969'],
    [0.5215686274509804, '#ed6869'],
    [0.5254901960784314, '#ed6669'],
    [0.5294117647058824, '#ec656a'],
    [0.5333333333333333, '#ec646a'],
    [0.5372549019607843, '#ec626a'],
    [0.5411764705882353, '#eb616a'],
    [0.5450980392156862, '#eb5f6a'],
    [0.5490196078431373, '#ea5e6b'],
    [0.5529411764705883, '#ea5d6b'],
    [0.5568627450980392, '#e95c6c'],
    [0.5607843137254902, '#e95a6c'],
    [0.5647058823529412, '#e8596d'],
    [0.5686274509803921, '#e7586d'],
    [0.5725490196078431, '#e7576d'],
    [0.5764705882352941, '#e6556e'],
    [0.580392156862745, '#e5546e'],
    [0.5843137254901961, '#e5536f'],
    [0.5882352941176471, '#e4516f'],
    [0.592156862745098, '#e45070'],
    [0.596078431372549, '#e34f70'],
    [0.6, '#e24d71'],
    [0.6039215686274509, '#e24c71'],
    [0.6078431372549019, '#e14b71'],
    [0.611764705882353, '#e04972'],
    [0.615686274509804, '#e04872'],
    [0.6196078431372549, '#df4773'],
    [0.6235294117647059, '#de4573'],
    [0.6274509803921569, '#de4474'],
    [0.6313725490196078, '#dd4274'],
    [0.6352941176470588, '#dc4174'],
    [0.6392156862745098, '#db4075'],
    [0.6431372549019607, '#da3f76'],
    [0.6470588235294118, '#d93e76'],
    [0.6509803921568628, '#d83c77'],
    [0.6549019607843137, '#d73b77'],
    [0.6588235294117647, '#d63a78'],
    [0.6627450980392157, '#d53979'],
    [0.6666666666666666, '#d43879'],
    [0.6705882352941176, '#d3377a'],
    [0.6745098039215687, '#d2367b'],
    [0.6784313725490196, '#d1357b'],
    [0.6823529411764706, '#d0337c'],
    [0.6862745098039216, '#cf327c'],
    [0.6901960784313725, '#ce317d'],
    [0.6941176470588235, '#cd307d'],
    [0.6980392156862745, '#cc2f7e'],
    [0.7019607843137254, '#cb2d7f'],
    [0.7058823529411764, '#ca2c7f'],
    [0.7098039215686275, '#c92b80'],
    [0.7137254901960784, '#c82a80'],
    [0.7176470588235294, '#c72881'],
    [0.7215686274509804, '#c62782'],
    [0.7254901960784313, '#c52682'],
    [0.7294117647058823, '#c42583'],
    [0.7333333333333333, '#c22483'],
    [0.7372549019607844, '#c12484'],
    [0.7411764705882353, '#bf2385'],
    [0.7450980392156863, '#be2285'],
    [0.7490196078431373, '#bd2286'],
    [0.7529411764705882, '#bb2187'],
    [0.7568627450980392, '#ba2187'],
    [0.7607843137254902, '#b82088'],
    [0.7647058823529411, '#b71f89'],
    [0.7686274509803921, '#b51f89'],
    [0.7725490196078432, '#b41e8a'],
    [0.7764705882352941, '#b21e8b'],
    [0.7803921568627451, '#b11d8b'],
    [0.7843137254901961, '#af1c8c'],
    [0.788235294117647, '#ae1c8d'],
    [0.792156862745098, '#ac1b8d'],
    [0.796078431372549, '#ab1b8e'],
    [0.8, '#a91a8f'],
    [0.803921568627451, '#a8198f'],
    [0.807843137254902, '#a61990'],
    [0.8117647058823529, '#a41890'],
    [0.8156862745098039, '#a31891'],
    [0.8196078431372549, '#a11892'],
    [0.8235294117647058, '#9f1892'],
    [0.8274509803921568, '#9d1893'],
    [0.8313725490196078, '#9b1993'],
    [0.8352941176470589, '#991994'],
    [0.8392156862745098, '#971995'],
    [0.8431372549019608, '#951a95'],
    [0.8470588235294118, '#931a96'],
    [0.8509803921568627, '#911a96'],
    [0.8549019607843137, '#8f1b97'],
    [0.8588235294117647, '#8d1b97'],
    [0.8627450980392157, '#8b1b98'],
    [0.8666666666666667, '#891c99'],
    [0.8705882352941177, '#871c99'],
    [0.8745098039215686, '#851c9a'],
    [0.8784313725490196, '#821d9a'],
    [0.8823529411764706, '#801d9b'],
    [0.8862745098039215, '#7e1d9b'],
    [0.8901960784313725, '#7c1d9c'],
    [0.8941176470588235, '#791e9d'],
    [0.8980392156862745, '#771e9d'],
    [0.9019607843137255, '#751e9e'],
    [0.9058823529411765, '#721e9e'],
    [0.9098039215686274, '#701f9f'],
    [0.9137254901960784, '#6d209f'],
    [0.9176470588235294, '#6a20a0'],
    [0.9215686274509803, '#6721a0'],
    [0.9254901960784314, '#6522a0'],
    [0.9294117647058824, '#6222a1'],
    [0.9333333333333333, '#5e23a1'],
    [0.9372549019607843, '#5b23a1'],
    [0.9411764705882353, '#5824a2'],
    [0.9450980392156862, '#5524a2'],
    [0.9490196078431372, '#5125a2'],
    [0.9529411764705882, '#4e25a3'],
    [0.9568627450980391, '#4a26a3'],
    [0.9607843137254902, '#4626a4'],
    [0.9647058823529412, '#4227a4'],
    [0.9686274509803922, '#3e27a4'],
    [0.9725490196078431, '#3927a5'],
    [0.9764705882352941, '#3428a5'],
    [0.9803921568627451, '#2e28a5'],
    [0.984313725490196, '#2828a6'],
    [0.9882352941176471, '#2129a6'],
    [0.9921568627450981, '#1829a6'],
    [0.996078431372549, '#0929a7'],
    [1.0, '#002aa7'],
  ],
  CET_L18: [
    [0.0, '#feffff'],
    [0.00392156862745098, '#fefefc'],
    [0.00784313725490196, '#fdfef9'],
    [0.011764705882352941, '#fdfdf7'],
    [0.01568627450980392, '#fcfdf4'],
    [0.0196078431372549, '#fcfcf2'],
    [0.023529411764705882, '#fbfcef'],
    [0.027450980392156862, '#fafbec'],
    [0.03137254901960784, '#fafbea'],
    [0.03529411764705882, '#f9fbe7'],
    [0.0392156862745098, '#f8fae5'],
    [0.043137254901960784, '#f8fae2'],
    [0.047058823529411764, '#f7f9df'],
    [0.050980392156862744, '#f7f9dd'],
    [0.054901960784313725, '#f6f8da'],
    [0.058823529411764705, '#f5f8d8'],
    [0.06274509803921569, '#f5f7d5'],
    [0.06666666666666667, '#f4f7d3'],
    [0.07058823529411765, '#f3f7d0'],
    [0.07450980392156863, '#f2f6cd'],
    [0.0784313725490196, '#f2f6cb'],
    [0.08235294117647059, '#f1f5c8'],
    [0.08627450980392157, '#f0f5c6'],
    [0.09019607843137255, '#f0f4c3'],
    [0.09411764705882353, '#eff4c1'],
    [0.09803921568627451, '#eff3c0'],
    [0.10196078431372549, '#eff2be'],
    [0.10588235294117647, '#eff2bd'],
    [0.10980392156862745, '#eef1bb'],
    [0.11372549019607843, '#eef0ba'],
    [0.11764705882352941, '#eef0b8'],
    [0.12156862745098039, '#eeefb7'],
    [0.12549019607843137, '#edeeb5'],
    [0.12941176470588234, '#edeeb4'],
    [0.13333333333333333, '#ededb2'],
    [0.13725490196078433, '#ececb0'],
    [0.1411764705882353, '#ececaf'],
    [0.14509803921568626, '#ecebad'],
    [0.14901960784313725, '#eceaac'],
    [0.15294117647058825, '#ebeaaa'],
    [0.1568627450980392, '#ebe9a9'],
    [0.16078431372549018, '#ebe9a7'],
    [0.16470588235294117, '#ebe8a6'],
    [0.16862745098039217, '#eae7a4'],
    [0.17254901960784313, '#eae7a3'],
    [0.1764705882352941, '#eae6a1'],
    [0.1803921568627451, '#e9e5a0'],
    [0.1843137254901961, '#e9e59e'],
    [0.18823529411764706, '#e9e49d'],
    [0.19215686274509802, '#e9e39c'],
    [0.19607843137254902, '#e9e29a'],
    [0.2, '#e9e299'],
    [0.20392156862745098, '#e9e198'],
    [0.20784313725490194, '#e8e096'],
    [0.21176470588235294, '#e8e095'],
    [0.21568627450980393, '#e8df94'],
    [0.2196078431372549, '#e8de92'],
    [0.22352941176470587, '#e8dd91'],
    [0.22745098039215686, '#e8dd90'],
    [0.23137254901960785, '#e8dc8e'],
    [0.23529411764705882, '#e8db8d'],
    [0.2392156862745098, '#e7da8c'],
    [0.24313725490196078, '#e7da8a'],
    [0.24705882352941178, '#e7d989'],
    [0.25098039215686274, '#e7d888'],
    [0.2549019607843137, '#e7d886'],
    [0.2588235294117647, '#e7d785'],
    [0.2627450980392157, '#e6d684'],
    [0.26666666666666666, '#e6d583'],
    [0.27058823529411763, '#e6d581'],
    [0.27450980392156865, '#e6d480'],
    [0.2784313725490196, '#e6d37f'],
    [0.2823529411764706, '#e6d27e'],
    [0.28627450980392155, '#e6d27c'],
    [0.2901960784313725, '#e6d17b'],
    [0.29411764705882354, '#e6d07a'],
    [0.2980392156862745, '#e6cf79'],
    [0.30196078431372547, '#e6cf78'],
    [0.3058823529411765, '#e6ce77'],
    [0.30980392156862746, '#e5cd75'],
    [0.3137254901960784, '#e5cc74'],
    [0.3176470588235294, '#e5cc73'],
    [0.32156862745098036, '#e5cb72'],
    [0.3254901960784314, '#e5ca71'],
    [0.32941176470588235, '#e5c970'],
    [0.3333333333333333, '#e5c96e'],
    [0.33725490196078434, '#e5c86d'],
    [0.3411764705882353, '#e5c76c'],
    [0.34509803921568627, '#e5c66b'],
    [0.34901960784313724, '#e5c66a'],
    [0.3529411764705882, '#e5c569'],
    [0.3568627450980392, '#e4c467'],
    [0.3607843137254902, '#e4c366'],
    [0.36470588235294116, '#e4c265'],
    [0.3686274509803922, '#e4c264'],
    [0.37254901960784315, '#e4c163'],
    [0.3764705882352941, '#e4c062'],
    [0.3803921568627451, '#e4bf61'],
    [0.38431372549019605, '#e4be60'],
    [0.38823529411764707, '#e4be5f'],
    [0.39215686274509803, '#e4bd5e'],
    [0.396078431372549, '#e4bc5d'],
    [0.4, '#e4bb5c'],
    [0.403921568627451, '#e4ba5b'],
    [0.40784313725490196, '#e4ba5a'],
    [0.4117647058823529, '#e4b959'],
    [0.4156862745098039, '#e4b858'],
    [0.4196078431372549, '#e4b757'],
    [0.4235294117647059, '#e4b656'],
    [0.42745098039215684, '#e4b655'],
    [0.43137254901960786, '#e4b554'],
    [0.43529411764705883, '#e4b453'],
    [0.4392156862745098, '#e4b352'],
    [0.44313725490196076, '#e3b251'],
    [0.44705882352941173, '#e3b250'],
    [0.45098039215686275, '#e3b14f'],
    [0.4549019607843137, '#e3b04e'],
    [0.4588235294117647, '#e3af4d'],
    [0.4627450980392157, '#e3ae4c'],
    [0.4666666666666667, '#e3ad4b'],
    [0.47058823529411764, '#e3ad4a'],
    [0.4745098039215686, '#e3ac49'],
    [0.4784313725490196, '#e3ab48'],
    [0.4823529411764706, '#e3aa48'],
    [0.48627450980392156, '#e3a947'],
    [0.49019607843137253, '#e3a846'],
    [0.49411764705882355, '#e3a845'],
    [0.4980392156862745, '#e3a744'],
    [0.5019607843137255, '#e3a643'],
    [0.5058823529411764, '#e3a542'],
    [0.5098039215686274, '#e3a441'],
    [0.5137254901960784, '#e3a341'],
    [0.5176470588235293, '#e3a340'],
    [0.5215686274509804, '#e3a23f'],
    [0.5254901960784314, '#e3a13e'],
    [0.5294117647058824, '#e3a03d'],
    [0.5333333333333333, '#e39f3c'],
    [0.5372549019607843, '#e39e3b'],
    [0.5411764705882353, '#e29d3a'],
    [0.5450980392156862, '#e29d3a'],
    [0.5490196078431373, '#e29c39'],
    [0.5529411764705883, '#e29b38'],
    [0.5568627450980392, '#e29a37'],
    [0.5607843137254902, '#e29937'],
    [0.5647058823529412, '#e29836'],
    [0.5686274509803921, '#e29735'],
    [0.5725490196078431, '#e29735'],
    [0.5764705882352941, '#e29634'],
    [0.580392156862745, '#e29533'],
    [0.5843137254901961, '#e29433'],
    [0.5882352941176471, '#e29332'],
    [0.592156862745098, '#e29231'],
    [0.596078431372549, '#e29130'],
    [0.6, '#e29030'],
    [0.6039215686274509, '#e28f2f'],
    [0.6078431372549019, '#e28f2e'],
    [0.611764705882353, '#e28e2e'],
    [0.615686274509804, '#e28d2d'],
    [0.6196078431372549, '#e28c2c'],
    [0.6235294117647059, '#e18b2b'],
    [0.6274509803921569, '#e18a2b'],
    [0.6313725490196078, '#e1892a'],
    [0.6352941176470588, '#e18829'],
    [0.6392156862745098, '#e18729'],
    [0.6431372549019607, '#e18628'],
    [0.6470588235294118, '#e18528'],
    [0.6509803921568628, '#e18527'],
    [0.6549019607843137, '#e18427'],
    [0.6588235294117647, '#e18326'],
    [0.6627450980392157, '#e18226'],
    [0.6666666666666666, '#e18125'],
    [0.6705882352941176, '#e18025'],
    [0.6745098039215687, '#e17f24'],
    [0.6784313725490196, '#e17e24'],
    [0.6823529411764706, '#e17d23'],
    [0.6862745098039216, '#e07c23'],
    [0.6901960784313725, '#e07b22'],
    [0.6941176470588235, '#e07a22'],
    [0.6980392156862745, '#e07921'],
    [0.7019607843137254, '#e07821'],
    [0.7058823529411764, '#e07720'],
    [0.7098039215686275, '#e07620'],
    [0.7137254901960784, '#e0751f'],
    [0.7176470588235294, '#e0741f'],
    [0.7215686274509804, '#e0741e'],
    [0.7254901960784313, '#e0731e'],
    [0.7294117647058823, '#e0721d'],
    [0.7333333333333333, '#df711d'],
    [0.7372549019607844, '#df701d'],
    [0.7411764705882353, '#df6f1d'],
    [0.7450980392156863, '#df6e1c'],
    [0.7490196078431373, '#df6d1c'],
    [0.7529411764705882, '#df6c1c'],
    [0.7568627450980392, '#df6b1c'],
    [0.7607843137254902, '#df6a1b'],
    [0.7647058823529411, '#df691b'],
    [0.7686274509803921, '#de681b'],
    [0.7725490196078432, '#de661b'],
    [0.7764705882352941, '#de651a'],
    [0.7803921568627451, '#de641a'],
    [0.7843137254901961, '#de631a'],
    [0.788235294117647, '#de621a'],
    [0.792156862745098, '#de611a'],
    [0.796078431372549, '#de6019'],
    [0.8, '#de5f19'],
    [0.803921568627451, '#dd5e19'],
    [0.807843137254902, '#dd5d19'],
    [0.8117647058823529, '#dd5c18'],
    [0.8156862745098039, '#dd5b18'],
    [0.8196078431372549, '#dd5a18'],
    [0.8235294117647058, '#dd5918'],
    [0.8274509803921568, '#dd5718'],
    [0.8313725490196078, '#dd5618'],
    [0.8352941176470589, '#dc5518'],
    [0.8392156862745098, '#dc5418'],
    [0.8431372549019608, '#dc5318'],
    [0.8470588235294118, '#dc5218'],
    [0.8509803921568627, '#dc5118'],
    [0.8549019607843137, '#dc4f18'],
    [0.8588235294117647, '#db4e18'],
    [0.8627450980392157, '#db4d18'],
    [0.8666666666666667, '#db4c18'],
    [0.8705882352941177, '#db4b18'],
    [0.8745098039215686, '#db4918'],
    [0.8784313725490196, '#db4818'],
    [0.8823529411764706, '#da4719'],
    [0.8862745098039215, '#da4619'],
    [0.8901960784313725, '#da4419'],
    [0.8941176470588235, '#da4319'],
    [0.8980392156862745, '#da4219'],
    [0.9019607843137255, '#da4019'],
    [0.9058823529411765, '#d93f19'],
    [0.9098039215686274, '#d93d19'],
    [0.9137254901960784, '#d93c19'],
    [0.9176470588235294, '#d93b19'],
    [0.9215686274509803, '#d93919'],
    [0.9254901960784314, '#d83819'],
    [0.9294117647058824, '#d8361a'],
    [0.9333333333333333, '#d8351a'],
    [0.9372549019607843, '#d8331a'],
    [0.9411764705882353, '#d8321a'],
    [0.9450980392156862, '#d7301a'],
    [0.9490196078431372, '#d72e1b'],
    [0.9529411764705882, '#d72d1b'],
    [0.9568627450980391, '#d72b1b'],
    [0.9607843137254902, '#d6291b'],
    [0.9647058823529412, '#d6271b'],
    [0.9686274509803922, '#d6251c'],
    [0.9725490196078431, '#d6231c'],
    [0.9764705882352941, '#d6211c'],
    [0.9803921568627451, '#d51e1c'],
    [0.984313725490196, '#d51c1c'],
    [0.9882352941176471, '#d5191c'],
    [0.9921568627450981, '#d5161c'],
    [0.996078431372549, '#d4121d'],
    [1.0, '#d40f1d'],
  ],
  CET_L19: [
    [0.0, '#feffff'],
    [0.00392156862745098, '#fdfefe'],
    [0.00784313725490196, '#fcfefe'],
    [0.011764705882352941, '#fbfdfe'],
    [0.01568627450980392, '#f9fdfe'],
    [0.0196078431372549, '#f8fcfe'],
    [0.023529411764705882, '#f7fcfe'],
    [0.027450980392156862, '#f6fbfe'],
    [0.03137254901960784, '#f4fbfe'],
    [0.03529411764705882, '#f3fafe'],
    [0.0392156862745098, '#f2fafd'],
    [0.043137254901960784, '#f1f9fd'],
    [0.047058823529411764, '#eff9fd'],
    [0.050980392156862744, '#eef8fd'],
    [0.054901960784313725, '#edf8fd'],
    [0.058823529411764705, '#ebf7fd'],
    [0.06274509803921569, '#eaf7fd'],
    [0.06666666666666667, '#e9f6fd'],
    [0.07058823529411765, '#e8f6fd'],
    [0.07450980392156863, '#e6f5fd'],
    [0.0784313725490196, '#e5f5fc'],
    [0.08235294117647059, '#e4f4fc'],
    [0.08627450980392157, '#e2f4fc'],
    [0.09019607843137255, '#e1f3fc'],
    [0.09411764705882353, '#e0f3fc'],
    [0.09803921568627451, '#dff2fc'],
    [0.10196078431372549, '#def1fc'],
    [0.10588235294117647, '#ddf1fc'],
    [0.10980392156862745, '#dcf0fc'],
    [0.11372549019607843, '#dbf0fc'],
    [0.11764705882352941, '#daeffc'],
    [0.12156862745098039, '#d9effd'],
    [0.12549019607843137, '#d8eefd'],
    [0.12941176470588234, '#d7edfd'],
    [0.13333333333333333, '#d6edfd'],
    [0.13725490196078433, '#d5ecfd'],
    [0.1411764705882353, '#d4ecfd'],
    [0.14509803921568626, '#d3ebfd'],
    [0.14901960784313725, '#d2ebfd'],
    [0.15294117647058825, '#d1eafd'],
    [0.1568627450980392, '#d0e9fd'],
    [0.16078431372549018, '#cfe9fd'],
    [0.16470588235294117, '#cde8fd'],
    [0.16862745098039217, '#cce8fd'],
    [0.17254901960784313, '#cbe7fd'],
    [0.1764705882352941, '#cae6fd'],
    [0.1803921568627451, '#c9e6fd'],
    [0.1843137254901961, '#c8e5fd'],
    [0.18823529411764706, '#c8e5fd'],
    [0.19215686274509802, '#c7e4fd'],
    [0.19607843137254902, '#c7e3fe'],
    [0.2, '#c6e3fe'],
    [0.20392156862745098, '#c5e2fe'],
    [0.20784313725490194, '#c5e1fe'],
    [0.21176470588235294, '#c4e0fe'],
    [0.21568627450980393, '#c3e0fe'],
    [0.2196078431372549, '#c3dffe'],
    [0.22352941176470587, '#c2defe'],
    [0.22745098039215686, '#c1defe'],
    [0.23137254901960785, '#c1ddfe'],
    [0.23529411764705882, '#c0dcfe'],
    [0.2392156862745098, '#c0dcfe'],
    [0.24313725490196078, '#bfdbfe'],
    [0.24705882352941178, '#bedafe'],
    [0.25098039215686274, '#bedafe'],
    [0.2549019607843137, '#bdd9fe'],
    [0.2588235294117647, '#bcd8fe'],
    [0.2627450980392157, '#bcd8fe'],
    [0.26666666666666666, '#bbd7ff'],
    [0.27058823529411763, '#bad6ff'],
    [0.27450980392156865, '#bad6ff'],
    [0.2784313725490196, '#bad5fe'],
    [0.2823529411764706, '#bad4fe'],
    [0.28627450980392155, '#bad3fe'],
    [0.2901960784313725, '#b9d2fe'],
    [0.29411764705882354, '#b9d2fe'],
    [0.2980392156862745, '#b9d1fe'],
    [0.30196078431372547, '#b9d0fe'],
    [0.3058823529411765, '#b9cffe'],
    [0.30980392156862746, '#b9cefe'],
    [0.3137254901960784, '#b9cefe'],
    [0.3176470588235294, '#b9cdfe'],
    [0.32156862745098036, '#b8ccfe'],
    [0.3254901960784314, '#b8cbfd'],
    [0.32941176470588235, '#b8cafd'],
    [0.3333333333333333, '#b8cafd'],
    [0.33725490196078434, '#b8c9fd'],
    [0.3411764705882353, '#b8c8fd'],
    [0.34509803921568627, '#b8c7fd'],
    [0.34901960784313724, '#b8c6fd'],
    [0.3529411764705882, '#b7c6fd'],
    [0.3568627450980392, '#b7c5fd'],
    [0.3607843137254902, '#b7c4fd'],
    [0.36470588235294116, '#b7c3fc'],
    [0.3686274509803922, '#b8c2fc'],
    [0.37254901960784315, '#b8c1fc'],
    [0.3764705882352941, '#b8c0fb'],
    [0.3803921568627451, '#b9c0fb'],
    [0.38431372549019605, '#b9bffb'],
    [0.38823529411764707, '#b9befa'],
    [0.39215686274509803, '#babdfa'],
    [0.396078431372549, '#babcf9'],
    [0.4, '#bbbbf9'],
    [0.403921568627451, '#bbbaf9'],
    [0.40784313725490196, '#bbb9f8'],
    [0.4117647058823529, '#bcb8f8'],
    [0.4156862745098039, '#bcb7f8'],
    [0.4196078431372549, '#bcb6f7'],
    [0.4235294117647059, '#bdb6f7'],
    [0.42745098039215684, '#bdb5f6'],
    [0.43137254901960786, '#bdb4f6'],
    [0.43529411764705883, '#beb3f6'],
    [0.4392156862745098, '#beb2f5'],
    [0.44313725490196076, '#beb1f5'],
    [0.44705882352941173, '#bfb0f5'],
    [0.45098039215686275, '#bfaff4'],
    [0.4549019607843137, '#bfaef4'],
    [0.4588235294117647, '#c0adf3'],
    [0.4627450980392157, '#c1acf2'],
    [0.4666666666666667, '#c1abf2'],
    [0.47058823529411764, '#c2aaf1'],
    [0.4745098039215686, '#c3a9f0'],
    [0.4784313725490196, '#c3a8ef'],
    [0.4823529411764706, '#c4a7ef'],
    [0.48627450980392156, '#c5a6ee'],
    [0.49019607843137253, '#c5a5ed'],
    [0.49411764705882355, '#c6a4ec'],
    [0.4980392156862745, '#c7a3ec'],
    [0.5019607843137255, '#c7a2eb'],
    [0.5058823529411764, '#c8a1ea'],
    [0.5098039215686274, '#c9a0e9'],
    [0.5137254901960784, '#c99fe9'],
    [0.5176470588235293, '#ca9ee8'],
    [0.5215686274509804, '#ca9de7'],
    [0.5254901960784314, '#cb9ce6'],
    [0.5294117647058824, '#cc9be6'],
    [0.5333333333333333, '#cc99e5'],
    [0.5372549019607843, '#cd98e4'],
    [0.5411764705882353, '#cd97e3'],
    [0.5450980392156862, '#ce96e3'],
    [0.5490196078431373, '#cf95e1'],
    [0.5529411764705883, '#cf94e0'],
    [0.5568627450980392, '#d093df'],
    [0.5607843137254902, '#d192de'],
    [0.5647058823529412, '#d291dd'],
    [0.5686274509803921, '#d290db'],
    [0.5725490196078431, '#d38eda'],
    [0.5764705882352941, '#d48dd9'],
    [0.580392156862745, '#d58cd8'],
    [0.5843137254901961, '#d58bd7'],
    [0.5882352941176471, '#d68ad6'],
    [0.592156862745098, '#d789d4'],
    [0.596078431372549, '#d787d3'],
    [0.6, '#d886d2'],
    [0.6039215686274509, '#d985d1'],
    [0.6078431372549019, '#d984d0'],
    [0.611764705882353, '#da83cf'],
    [0.615686274509804, '#da82cd'],
    [0.6196078431372549, '#db80cc'],
    [0.6235294117647059, '#dc7fcb'],
    [0.6274509803921569, '#dc7eca'],
    [0.6313725490196078, '#dd7dc9'],
    [0.6352941176470588, '#dd7cc8'],
    [0.6392156862745098, '#de7ac6'],
    [0.6431372549019607, '#df79c5'],
    [0.6470588235294118, '#df78c3'],
    [0.6509803921568628, '#e077c1'],
    [0.6549019607843137, '#e076c0'],
    [0.6588235294117647, '#e174be'],
    [0.6627450980392157, '#e173bd'],
    [0.6666666666666666, '#e272bb'],
    [0.6705882352941176, '#e271b9'],
    [0.6745098039215687, '#e370b8'],
    [0.6784313725490196, '#e36eb6'],
    [0.6823529411764706, '#e46db5'],
    [0.6862745098039216, '#e46cb3'],
    [0.6901960784313725, '#e56ab2'],
    [0.6941176470588235, '#e569b0'],
    [0.6980392156862745, '#e668ae'],
    [0.7019607843137254, '#e667ad'],
    [0.7058823529411764, '#e765ab'],
    [0.7098039215686275, '#e764aa'],
    [0.7137254901960784, '#e763a8'],
    [0.7176470588235294, '#e861a7'],
    [0.7215686274509804, '#e860a5'],
    [0.7254901960784313, '#e95fa4'],
    [0.7294117647058823, '#e95da2'],
    [0.7333333333333333, '#e95ca0'],
    [0.7372549019607844, '#e95b9e'],
    [0.7411764705882353, '#ea5a9c'],
    [0.7450980392156863, '#ea599a'],
    [0.7490196078431373, '#ea5798'],
    [0.7529411764705882, '#ea5696'],
    [0.7568627450980392, '#ea5594'],
    [0.7607843137254902, '#ea5493'],
    [0.7647058823529411, '#ea5391'],
    [0.7686274509803921, '#eb518f'],
    [0.7725490196078432, '#eb508d'],
    [0.7764705882352941, '#eb4f8b'],
    [0.7803921568627451, '#eb4d89'],
    [0.7843137254901961, '#eb4c87'],
    [0.788235294117647, '#eb4b85'],
    [0.792156862745098, '#eb4984'],
    [0.796078431372549, '#eb4882'],
    [0.8, '#eb4780'],
    [0.803921568627451, '#eb457e'],
    [0.807843137254902, '#eb447c'],
    [0.8117647058823529, '#eb437a'],
    [0.8156862745098039, '#eb4178'],
    [0.8196078431372549, '#eb4076'],
    [0.8235294117647058, '#eb3f74'],
    [0.8274509803921568, '#eb3e72'],
    [0.8313725490196078, '#eb3d70'],
    [0.8352941176470589, '#ea3c6e'],
    [0.8392156862745098, '#ea3b6c'],
    [0.8431372549019608, '#ea3a6a'],
    [0.8470588235294118, '#e93968'],
    [0.8509803921568627, '#e93866'],
    [0.8549019607843137, '#e93764'],
    [0.8588235294117647, '#e83662'],
    [0.8627450980392157, '#e8355f'],
    [0.8666666666666667, '#e8335d'],
    [0.8705882352941177, '#e7325b'],
    [0.8745098039215686, '#e73159'],
    [0.8784313725490196, '#e73057'],
    [0.8823529411764706, '#e62f55'],
    [0.8862745098039215, '#e62e53'],
    [0.8901960784313725, '#e52d51'],
    [0.8941176470588235, '#e52c4f'],
    [0.8980392156862745, '#e52a4d'],
    [0.9019607843137255, '#e4294b'],
    [0.9058823529411765, '#e42849'],
    [0.9098039215686274, '#e32747'],
    [0.9137254901960784, '#e22745'],
    [0.9176470588235294, '#e22642'],
    [0.9215686274509803, '#e12640'],
    [0.9254901960784314, '#e0263e'],
    [0.9294117647058824, '#df253c'],
    [0.9333333333333333, '#de2539'],
    [0.9372549019607843, '#de2537'],
    [0.9411764705882353, '#dd2435'],
    [0.9450980392156862, '#dc2433'],
    [0.9490196078431372, '#db2430'],
    [0.9529411764705882, '#da242e'],
    [0.9568627450980391, '#d9232c'],
    [0.9607843137254902, '#d82329'],
    [0.9647058823529412, '#d82327'],
    [0.9686274509803922, '#d72325'],
    [0.9725490196078431, '#d62222'],
    [0.9764705882352941, '#d52220'],
    [0.9803921568627451, '#d4221d'],
    [0.984313725490196, '#d3211a'],
    [0.9882352941176471, '#d22117'],
    [0.9921568627450981, '#d12114'],
    [0.996078431372549, '#d02111'],
    [1.0, '#d0210e'],
  ],
  CET_L2: [
    [0.0, '#1b1b1b'],
    [0.00392156862745098, '#1c1c1c'],
    [0.00784313725490196, '#1c1c1c'],
    [0.011764705882352941, '#1d1d1d'],
    [0.01568627450980392, '#1e1e1e'],
    [0.0196078431372549, '#1e1e1e'],
    [0.023529411764705882, '#1f1f1f'],
    [0.027450980392156862, '#202020'],
    [0.03137254901960784, '#202020'],
    [0.03529411764705882, '#212121'],
    [0.0392156862745098, '#222222'],
    [0.043137254901960784, '#222222'],
    [0.047058823529411764, '#232323'],
    [0.050980392156862744, '#242424'],
    [0.054901960784313725, '#242424'],
    [0.058823529411764705, '#252525'],
    [0.06274509803921569, '#262626'],
    [0.06666666666666667, '#272727'],
    [0.07058823529411765, '#272727'],
    [0.07450980392156863, '#282828'],
    [0.0784313725490196, '#292929'],
    [0.08235294117647059, '#292929'],
    [0.08627450980392157, '#2a2a2a'],
    [0.09019607843137255, '#2b2b2b'],
    [0.09411764705882353, '#2b2b2b'],
    [0.09803921568627451, '#2c2c2c'],
    [0.10196078431372549, '#2d2d2d'],
    [0.10588235294117647, '#2e2e2e'],
    [0.10980392156862745, '#2e2e2e'],
    [0.11372549019607843, '#2f2f2f'],
    [0.11764705882352941, '#303030'],
    [0.12156862745098039, '#313131'],
    [0.12549019607843137, '#313131'],
    [0.12941176470588234, '#323232'],
    [0.13333333333333333, '#333333'],
    [0.13725490196078433, '#333333'],
    [0.1411764705882353, '#343434'],
    [0.14509803921568626, '#353535'],
    [0.14901960784313725, '#363636'],
    [0.15294117647058825, '#363636'],
    [0.1568627450980392, '#373737'],
    [0.16078431372549018, '#383838'],
    [0.16470588235294117, '#393939'],
    [0.16862745098039217, '#393939'],
    [0.17254901960784313, '#3a3a3a'],
    [0.1764705882352941, '#3b3b3b'],
    [0.1803921568627451, '#3c3c3c'],
    [0.1843137254901961, '#3c3c3c'],
    [0.18823529411764706, '#3d3d3d'],
    [0.19215686274509802, '#3e3e3e'],
    [0.19607843137254902, '#3f3f3f'],
    [0.2, '#3f3f3f'],
    [0.20392156862745098, '#404040'],
    [0.20784313725490194, '#414141'],
    [0.21176470588235294, '#424242'],
    [0.21568627450980393, '#424242'],
    [0.2196078431372549, '#434343'],
    [0.22352941176470587, '#444444'],
    [0.22745098039215686, '#454545'],
    [0.23137254901960785, '#454545'],
    [0.23529411764705882, '#464646'],
    [0.2392156862745098, '#474747'],
    [0.24313725490196078, '#484848'],
    [0.24705882352941178, '#484848'],
    [0.25098039215686274, '#494949'],
    [0.2549019607843137, '#4a4a4a'],
    [0.2588235294117647, '#4b4b4b'],
    [0.2627450980392157, '#4c4c4c'],
    [0.26666666666666666, '#4c4c4c'],
    [0.27058823529411763, '#4d4d4d'],
    [0.27450980392156865, '#4e4e4e'],
    [0.2784313725490196, '#4f4f4f'],
    [0.2823529411764706, '#4f4f4f'],
    [0.28627450980392155, '#505050'],
    [0.2901960784313725, '#515151'],
    [0.29411764705882354, '#525252'],
    [0.2980392156862745, '#535353'],
    [0.30196078431372547, '#535353'],
    [0.3058823529411765, '#545454'],
    [0.30980392156862746, '#555555'],
    [0.3137254901960784, '#565656'],
    [0.3176470588235294, '#575757'],
    [0.32156862745098036, '#575757'],
    [0.3254901960784314, '#585858'],
    [0.32941176470588235, '#595959'],
    [0.3333333333333333, '#5a5a5a'],
    [0.33725490196078434, '#5b5b5b'],
    [0.3411764705882353, '#5b5b5b'],
    [0.34509803921568627, '#5c5c5c'],
    [0.34901960784313724, '#5d5d5d'],
    [0.3529411764705882, '#5e5e5e'],
    [0.3568627450980392, '#5f5f5f'],
    [0.3607843137254902, '#5f5f5f'],
    [0.36470588235294116, '#606060'],
    [0.3686274509803922, '#616161'],
    [0.37254901960784315, '#626262'],
    [0.3764705882352941, '#636363'],
    [0.3803921568627451, '#636363'],
    [0.38431372549019605, '#646464'],
    [0.38823529411764707, '#656565'],
    [0.39215686274509803, '#666666'],
    [0.396078431372549, '#676767'],
    [0.4, '#676767'],
    [0.403921568627451, '#686868'],
    [0.40784313725490196, '#696969'],
    [0.4117647058823529, '#6a6a6a'],
    [0.4156862745098039, '#6b6b6b'],
    [0.4196078431372549, '#6c6c6c'],
    [0.4235294117647059, '#6c6c6c'],
    [0.42745098039215684, '#6d6d6d'],
    [0.43137254901960786, '#6e6e6e'],
    [0.43529411764705883, '#6f6f6f'],
    [0.4392156862745098, '#707070'],
    [0.44313725490196076, '#717171'],
    [0.44705882352941173, '#717171'],
    [0.45098039215686275, '#727272'],
    [0.4549019607843137, '#737373'],
    [0.4588235294117647, '#747474'],
    [0.4627450980392157, '#757575'],
    [0.4666666666666667, '#767676'],
    [0.47058823529411764, '#767676'],
    [0.4745098039215686, '#777777'],
    [0.4784313725490196, '#787878'],
    [0.4823529411764706, '#797979'],
    [0.48627450980392156, '#7a7a7a'],
    [0.49019607843137253, '#7b7b7b'],
    [0.49411764705882355, '#7b7b7b'],
    [0.4980392156862745, '#7c7c7c'],
    [0.5019607843137255, '#7d7d7d'],
    [0.5058823529411764, '#7e7e7e'],
    [0.5098039215686274, '#7f7f7f'],
    [0.5137254901960784, '#808080'],
    [0.5176470588235293, '#818181'],
    [0.5215686274509804, '#818181'],
    [0.5254901960784314, '#828282'],
    [0.5294117647058824, '#838383'],
    [0.5333333333333333, '#848484'],
    [0.5372549019607843, '#858585'],
    [0.5411764705882353, '#868686'],
    [0.5450980392156862, '#878787'],
    [0.5490196078431373, '#878787'],
    [0.5529411764705883, '#888888'],
    [0.5568627450980392, '#898989'],
    [0.5607843137254902, '#8a8a8a'],
    [0.5647058823529412, '#8b8b8b'],
    [0.5686274509803921, '#8c8c8c'],
    [0.5725490196078431, '#8d8d8d'],
    [0.5764705882352941, '#8d8d8d'],
    [0.580392156862745, '#8e8e8e'],
    [0.5843137254901961, '#8f8f8f'],
    [0.5882352941176471, '#909090'],
    [0.592156862745098, '#919191'],
    [0.596078431372549, '#929292'],
    [0.6, '#939393'],
    [0.6039215686274509, '#949494'],
    [0.6078431372549019, '#949494'],
    [0.611764705882353, '#959595'],
    [0.615686274509804, '#969696'],
    [0.6196078431372549, '#979797'],
    [0.6235294117647059, '#989898'],
    [0.6274509803921569, '#999999'],
    [0.6313725490196078, '#9a9a9a'],
    [0.6352941176470588, '#9b9b9b'],
    [0.6392156862745098, '#9b9b9b'],
    [0.6431372549019607, '#9c9c9c'],
    [0.6470588235294118, '#9d9d9d'],
    [0.6509803921568628, '#9e9e9e'],
    [0.6549019607843137, '#9f9f9f'],
    [0.6588235294117647, '#a0a0a0'],
    [0.6627450980392157, '#a1a1a1'],
    [0.6666666666666666, '#a2a2a2'],
    [0.6705882352941176, '#a2a3a3'],
    [0.6745098039215687, '#a3a3a3'],
    [0.6784313725490196, '#a4a4a4'],
    [0.6823529411764706, '#a5a5a5'],
    [0.6862745098039216, '#a6a6a6'],
    [0.6901960784313725, '#a7a7a7'],
    [0.6941176470588235, '#a8a8a8'],
    [0.6980392156862745, '#a9a9a9'],
    [0.7019607843137254, '#aaaaaa'],
    [0.7058823529411764, '#ababab'],
    [0.7098039215686275, '#ababab'],
    [0.7137254901960784, '#acacac'],
    [0.7176470588235294, '#adadad'],
    [0.7215686274509804, '#aeaeae'],
    [0.7254901960784313, '#afafaf'],
    [0.7294117647058823, '#b0b0b0'],
    [0.7333333333333333, '#b1b1b1'],
    [0.7372549019607844, '#b2b2b2'],
    [0.7411764705882353, '#b3b3b3'],
    [0.7450980392156863, '#b4b4b4'],
    [0.7490196078431373, '#b4b4b4'],
    [0.7529411764705882, '#b5b5b5'],
    [0.7568627450980392, '#b6b6b6'],
    [0.7607843137254902, '#b7b7b7'],
    [0.7647058823529411, '#b8b8b8'],
    [0.7686274509803921, '#b9b9b9'],
    [0.7725490196078432, '#bababa'],
    [0.7764705882352941, '#bbbbbb'],
    [0.7803921568627451, '#bcbcbc'],
    [0.7843137254901961, '#bdbdbd'],
    [0.788235294117647, '#bebebe'],
    [0.792156862745098, '#bebebe'],
    [0.796078431372549, '#bfbfbf'],
    [0.8, '#c0c0c0'],
    [0.803921568627451, '#c1c1c1'],
    [0.807843137254902, '#c2c2c2'],
    [0.8117647058823529, '#c3c3c3'],
    [0.8156862745098039, '#c4c4c4'],
    [0.8196078431372549, '#c5c5c5'],
    [0.8235294117647058, '#c6c6c6'],
    [0.8274509803921568, '#c7c7c7'],
    [0.8313725490196078, '#c8c8c8'],
    [0.8352941176470589, '#c9c9c9'],
    [0.8392156862745098, '#c9caca'],
    [0.8431372549019608, '#cacaca'],
    [0.8470588235294118, '#cbcbcb'],
    [0.8509803921568627, '#cccccc'],
    [0.8549019607843137, '#cdcdcd'],
    [0.8588235294117647, '#cecece'],
    [0.8627450980392157, '#cfcfcf'],
    [0.8666666666666667, '#d0d0d0'],
    [0.8705882352941177, '#d1d1d1'],
    [0.8745098039215686, '#d2d2d2'],
    [0.8784313725490196, '#d3d3d3'],
    [0.8823529411764706, '#d4d4d4'],
    [0.8862745098039215, '#d5d5d5'],
    [0.8901960784313725, '#d6d6d6'],
    [0.8941176470588235, '#d7d7d7'],
    [0.8980392156862745, '#d7d7d7'],
    [0.9019607843137255, '#d8d8d8'],
    [0.9058823529411765, '#d9d9d9'],
    [0.9098039215686274, '#dadada'],
    [0.9137254901960784, '#dbdbdb'],
    [0.9176470588235294, '#dcdcdc'],
    [0.9215686274509803, '#dddddd'],
    [0.9254901960784314, '#dedede'],
    [0.9294117647058824, '#dfdfdf'],
    [0.9333333333333333, '#e0e0e0'],
    [0.9372549019607843, '#e1e1e1'],
    [0.9411764705882353, '#e2e2e2'],
    [0.9450980392156862, '#e3e3e3'],
    [0.9490196078431372, '#e4e4e4'],
    [0.9529411764705882, '#e5e5e5'],
    [0.9568627450980391, '#e6e6e6'],
    [0.9607843137254902, '#e7e7e7'],
    [0.9647058823529412, '#e7e8e8'],
    [0.9686274509803922, '#e8e8e8'],
    [0.9725490196078431, '#e9e9e9'],
    [0.9764705882352941, '#eaeaea'],
    [0.9803921568627451, '#ebebeb'],
    [0.984313725490196, '#ececec'],
    [0.9882352941176471, '#ededed'],
    [0.9921568627450981, '#eeeeee'],
    [0.996078431372549, '#efefef'],
    [1.0, '#f0f0f0'],
  ],
  CET_L20: [
    [0.0, '#303030'],
    [0.00392156862745098, '#313032'],
    [0.00784313725490196, '#323035'],
    [0.011764705882352941, '#333137'],
    [0.01568627450980392, '#33313a'],
    [0.0196078431372549, '#34313d'],
    [0.023529411764705882, '#35323f'],
    [0.027450980392156862, '#363242'],
    [0.03137254901960784, '#373344'],
    [0.03529411764705882, '#373347'],
    [0.0392156862745098, '#383349'],
    [0.043137254901960784, '#39344c'],
    [0.047058823529411764, '#3a344e'],
    [0.050980392156862744, '#3a3451'],
    [0.054901960784313725, '#3b3553'],
    [0.058823529411764705, '#3b3556'],
    [0.06274509803921569, '#3c3558'],
    [0.06666666666666667, '#3c365b'],
    [0.07058823529411765, '#3d365d'],
    [0.07450980392156863, '#3d3760'],
    [0.0784313725490196, '#3e3762'],
    [0.08235294117647059, '#3e3765'],
    [0.08627450980392157, '#3f3867'],
    [0.09019607843137255, '#3f386a'],
    [0.09411764705882353, '#40396c'],
    [0.09803921568627451, '#40396e'],
    [0.10196078431372549, '#403971'],
    [0.10588235294117647, '#403a73'],
    [0.10980392156862745, '#413a76'],
    [0.11372549019607843, '#413b78'],
    [0.11764705882352941, '#413b7a'],
    [0.12156862745098039, '#413c7d'],
    [0.12549019607843137, '#423c7f'],
    [0.12941176470588234, '#423d81'],
    [0.13333333333333333, '#423d84'],
    [0.13725490196078433, '#423e86'],
    [0.1411764705882353, '#423e88'],
    [0.14509803921568626, '#423f8a'],
    [0.14901960784313725, '#423f8c'],
    [0.15294117647058825, '#42408f'],
    [0.1568627450980392, '#424091'],
    [0.16078431372549018, '#434193'],
    [0.16470588235294117, '#434195'],
    [0.16862745098039217, '#424297'],
    [0.17254901960784313, '#424299'],
    [0.1764705882352941, '#42439b'],
    [0.1803921568627451, '#42449d'],
    [0.1843137254901961, '#42449f'],
    [0.18823529411764706, '#4245a1'],
    [0.19215686274509802, '#4245a3'],
    [0.19607843137254902, '#4246a5'],
    [0.2, '#4247a7'],
    [0.20392156862745098, '#4147a9'],
    [0.20784313725490194, '#4148ab'],
    [0.21176470588235294, '#4149ac'],
    [0.21568627450980393, '#4149ae'],
    [0.2196078431372549, '#414ab0'],
    [0.22352941176470587, '#404bb2'],
    [0.22745098039215686, '#404cb3'],
    [0.23137254901960785, '#404cb5'],
    [0.23529411764705882, '#3f4db6'],
    [0.2392156862745098, '#3f4eb8'],
    [0.24313725490196078, '#3f4fb9'],
    [0.24705882352941178, '#3e50bb'],
    [0.25098039215686274, '#3e50bc'],
    [0.2549019607843137, '#3e51bd'],
    [0.2588235294117647, '#3d52be'],
    [0.2627450980392157, '#3d53c0'],
    [0.26666666666666666, '#3d54c1'],
    [0.27058823529411763, '#3c55c2'],
    [0.27450980392156865, '#3c56c3'],
    [0.2784313725490196, '#3b57c4'],
    [0.2823529411764706, '#3b58c4'],
    [0.28627450980392155, '#3a59c5'],
    [0.2901960784313725, '#3a5ac6'],
    [0.29411764705882354, '#3a5bc6'],
    [0.2980392156862745, '#395cc7'],
    [0.30196078431372547, '#395dc7'],
    [0.3058823529411765, '#385ec8'],
    [0.30980392156862746, '#385fc8'],
    [0.3137254901960784, '#3760c8'],
    [0.3176470588235294, '#3661c8'],
    [0.32156862745098036, '#3663c8'],
    [0.3254901960784314, '#3564c8'],
    [0.32941176470588235, '#3565c8'],
    [0.3333333333333333, '#3466c7'],
    [0.33725490196078434, '#3368c6'],
    [0.3411764705882353, '#3369c6'],
    [0.34509803921568627, '#326ac5'],
    [0.34901960784313724, '#316cc4'],
    [0.3529411764705882, '#306dc2'],
    [0.3568627450980392, '#2f6ec1'],
    [0.3607843137254902, '#2e70bf'],
    [0.36470588235294116, '#2c72bd'],
    [0.3686274509803922, '#2b73bb'],
    [0.37254901960784315, '#2a75b9'],
    [0.3764705882352941, '#2876b6'],
    [0.3803921568627451, '#2778b4'],
    [0.38431372549019605, '#2679b2'],
    [0.38823529411764707, '#257ab0'],
    [0.39215686274509803, '#247cae'],
    [0.396078431372549, '#237dac'],
    [0.4, '#227ea9'],
    [0.403921568627451, '#2280a7'],
    [0.40784313725490196, '#2281a5'],
    [0.4117647058823529, '#2182a3'],
    [0.4156862745098039, '#2183a1'],
    [0.4196078431372549, '#22859f'],
    [0.4235294117647059, '#22869d'],
    [0.42745098039215684, '#22879b'],
    [0.43137254901960786, '#238899'],
    [0.43529411764705883, '#248997'],
    [0.4392156862745098, '#258a95'],
    [0.44313725490196076, '#268b93'],
    [0.44705882352941173, '#278d91'],
    [0.45098039215686275, '#288e8e'],
    [0.4549019607843137, '#2a8f8c'],
    [0.4588235294117647, '#2b908b'],
    [0.4627450980392157, '#2d9189'],
    [0.4666666666666667, '#2f9287'],
    [0.47058823529411764, '#309385'],
    [0.4745098039215686, '#329383'],
    [0.4784313725490196, '#349481'],
    [0.4823529411764706, '#36957f'],
    [0.48627450980392156, '#38967d'],
    [0.49019607843137253, '#3a977b'],
    [0.49411764705882355, '#3d9879'],
    [0.4980392156862745, '#3f9977'],
    [0.5019607843137255, '#419a75'],
    [0.5058823529411764, '#439a73'],
    [0.5098039215686274, '#469b71'],
    [0.5137254901960784, '#489c6f'],
    [0.5176470588235293, '#4b9d6e'],
    [0.5215686274509804, '#4d9e6c'],
    [0.5254901960784314, '#509e6a'],
    [0.5294117647058824, '#529f68'],
    [0.5333333333333333, '#55a066'],
    [0.5372549019607843, '#58a064'],
    [0.5411764705882353, '#5aa162'],
    [0.5450980392156862, '#5da260'],
    [0.5490196078431373, '#60a25e'],
    [0.5529411764705883, '#63a35d'],
    [0.5568627450980392, '#65a45b'],
    [0.5607843137254902, '#68a459'],
    [0.5647058823529412, '#6ba557'],
    [0.5686274509803921, '#6ea555'],
    [0.5725490196078431, '#71a653'],
    [0.5764705882352941, '#74a651'],
    [0.580392156862745, '#77a74f'],
    [0.5843137254901961, '#79a74e'],
    [0.5882352941176471, '#7ca84c'],
    [0.592156862745098, '#7fa84a'],
    [0.596078431372549, '#81a948'],
    [0.6, '#84a946'],
    [0.6039215686274509, '#86aa44'],
    [0.6078431372549019, '#89aa42'],
    [0.611764705882353, '#8bab40'],
    [0.615686274509804, '#8eab3f'],
    [0.6196078431372549, '#91ac3d'],
    [0.6235294117647059, '#93ac3b'],
    [0.6274509803921569, '#95ad39'],
    [0.6313725490196078, '#98ad37'],
    [0.6352941176470588, '#9aad35'],
    [0.6392156862745098, '#9dae34'],
    [0.6431372549019607, '#9fae32'],
    [0.6470588235294118, '#a2af30'],
    [0.6509803921568628, '#a4af2e'],
    [0.6549019607843137, '#a7b02c'],
    [0.6588235294117647, '#a9b02a'],
    [0.6627450980392157, '#acb028'],
    [0.6666666666666666, '#aeb127'],
    [0.6705882352941176, '#b1b125'],
    [0.6745098039215687, '#b3b123'],
    [0.6784313725490196, '#b6b221'],
    [0.6823529411764706, '#b8b220'],
    [0.6862745098039216, '#bbb21e'],
    [0.6901960784313725, '#bdb31c'],
    [0.6941176470588235, '#c0b31a'],
    [0.6980392156862745, '#c2b319'],
    [0.7019607843137254, '#c5b317'],
    [0.7058823529411764, '#c8b416'],
    [0.7098039215686275, '#cab415'],
    [0.7137254901960784, '#cdb414'],
    [0.7176470588235294, '#cfb413'],
    [0.7215686274509804, '#d2b412'],
    [0.7254901960784313, '#d5b512'],
    [0.7294117647058823, '#d8b511'],
    [0.7333333333333333, '#dab512'],
    [0.7372549019607844, '#ddb512'],
    [0.7411764705882353, '#dfb512'],
    [0.7450980392156863, '#e2b612'],
    [0.7490196078431373, '#e4b613'],
    [0.7529411764705882, '#e6b613'],
    [0.7568627450980392, '#e7b713'],
    [0.7607843137254902, '#e9b713'],
    [0.7647058823529411, '#ebb813'],
    [0.7686274509803921, '#ecb813'],
    [0.7725490196078432, '#edb913'],
    [0.7764705882352941, '#efba13'],
    [0.7803921568627451, '#f0ba13'],
    [0.7843137254901961, '#f1bb14'],
    [0.788235294117647, '#f2bc14'],
    [0.792156862745098, '#f3bd14'],
    [0.796078431372549, '#f4bd14'],
    [0.8, '#f5be14'],
    [0.803921568627451, '#f5bf14'],
    [0.807843137254902, '#f6c014'],
    [0.8117647058823529, '#f7c114'],
    [0.8156862745098039, '#f8c214'],
    [0.8196078431372549, '#f8c314'],
    [0.8235294117647058, '#f9c413'],
    [0.8274509803921568, '#f9c513'],
    [0.8313725490196078, '#fac613'],
    [0.8352941176470589, '#fac713'],
    [0.8392156862745098, '#fbc813'],
    [0.8431372549019608, '#fbc913'],
    [0.8470588235294118, '#fcca13'],
    [0.8509803921568627, '#fccb13'],
    [0.8549019607843137, '#fccc13'],
    [0.8588235294117647, '#fdcd13'],
    [0.8627450980392157, '#fdce13'],
    [0.8666666666666667, '#fdcf12'],
    [0.8705882352941177, '#fdd012'],
    [0.8745098039215686, '#fed112'],
    [0.8784313725490196, '#fed312'],
    [0.8823529411764706, '#fed412'],
    [0.8862745098039215, '#fed512'],
    [0.8901960784313725, '#fed612'],
    [0.8941176470588235, '#fed711'],
    [0.8980392156862745, '#fed811'],
    [0.9019607843137255, '#fed911'],
    [0.9058823529411765, '#fedb11'],
    [0.9098039215686274, '#fedc11'],
    [0.9137254901960784, '#fedd10'],
    [0.9176470588235294, '#fede10'],
    [0.9215686274509803, '#fedf10'],
    [0.9254901960784314, '#fee110'],
    [0.9294117647058824, '#fee210'],
    [0.9333333333333333, '#fee30f'],
    [0.9372549019607843, '#fde40f'],
    [0.9411764705882353, '#fde50f'],
    [0.9450980392156862, '#fde70e'],
    [0.9490196078431372, '#fde80e'],
    [0.9529411764705882, '#fde90e'],
    [0.9568627450980391, '#fcea0e'],
    [0.9607843137254902, '#fcec0d'],
    [0.9647058823529412, '#fced0d'],
    [0.9686274509803922, '#fbee0d'],
    [0.9725490196078431, '#fbef0c'],
    [0.9764705882352941, '#fbf10c'],
    [0.9803921568627451, '#faf20b'],
    [0.984313725490196, '#faf30b'],
    [0.9882352941176471, '#faf40b'],
    [0.9921568627450981, '#f9f60a'],
    [0.996078431372549, '#f9f70a'],
    [1.0, '#f8f809'],
  ],
  CET_L3: [
    [0.0, '#000000'],
    [0.00392156862745098, '#060000'],
    [0.00784313725490196, '#0d0000'],
    [0.011764705882352941, '#120000'],
    [0.01568627450980392, '#160000'],
    [0.0196078431372549, '#190000'],
    [0.023529411764705882, '#1c0000'],
    [0.027450980392156862, '#1f0000'],
    [0.03137254901960784, '#220000'],
    [0.03529411764705882, '#240000'],
    [0.0392156862745098, '#260000'],
    [0.043137254901960784, '#280000'],
    [0.047058823529411764, '#2b0000'],
    [0.050980392156862744, '#2d0000'],
    [0.054901960784313725, '#2e0000'],
    [0.058823529411764705, '#300000'],
    [0.06274509803921569, '#320000'],
    [0.06666666666666667, '#340000'],
    [0.07058823529411765, '#350000'],
    [0.07450980392156863, '#370000'],
    [0.0784313725490196, '#380000'],
    [0.08235294117647059, '#3a0000'],
    [0.08627450980392157, '#3b0000'],
    [0.09019607843137255, '#3d0000'],
    [0.09411764705882353, '#3e0000'],
    [0.09803921568627451, '#400000'],
    [0.10196078431372549, '#410000'],
    [0.10588235294117647, '#430000'],
    [0.10980392156862745, '#440000'],
    [0.11372549019607843, '#460000'],
    [0.11764705882352941, '#470000'],
    [0.12156862745098039, '#490000'],
    [0.12549019607843137, '#4a0000'],
    [0.12941176470588234, '#4c0000'],
    [0.13333333333333333, '#4d0000'],
    [0.13725490196078433, '#4f0000'],
    [0.1411764705882353, '#500000'],
    [0.14509803921568626, '#520000'],
    [0.14901960784313725, '#530000'],
    [0.15294117647058825, '#550000'],
    [0.1568627450980392, '#560000'],
    [0.16078431372549018, '#580000'],
    [0.16470588235294117, '#590100'],
    [0.16862745098039217, '#5b0100'],
    [0.17254901960784313, '#5d0100'],
    [0.1764705882352941, '#5e0100'],
    [0.1803921568627451, '#600100'],
    [0.1843137254901961, '#610100'],
    [0.18823529411764706, '#630100'],
    [0.19215686274509802, '#650100'],
    [0.19607843137254902, '#660100'],
    [0.2, '#680100'],
    [0.20392156862745098, '#690100'],
    [0.20784313725490194, '#6b0100'],
    [0.21176470588235294, '#6d0100'],
    [0.21568627450980393, '#6e0100'],
    [0.2196078431372549, '#700100'],
    [0.22352941176470587, '#710100'],
    [0.22745098039215686, '#730100'],
    [0.23137254901960785, '#750100'],
    [0.23529411764705882, '#760100'],
    [0.2392156862745098, '#780200'],
    [0.24313725490196078, '#7a0200'],
    [0.24705882352941178, '#7b0200'],
    [0.25098039215686274, '#7d0200'],
    [0.2549019607843137, '#7f0200'],
    [0.2588235294117647, '#800200'],
    [0.2627450980392157, '#820200'],
    [0.26666666666666666, '#840200'],
    [0.27058823529411763, '#850200'],
    [0.27450980392156865, '#870200'],
    [0.2784313725490196, '#890200'],
    [0.2823529411764706, '#8a0200'],
    [0.28627450980392155, '#8c0300'],
    [0.2901960784313725, '#8e0300'],
    [0.29411764705882354, '#900300'],
    [0.2980392156862745, '#910300'],
    [0.30196078431372547, '#930300'],
    [0.3058823529411765, '#950300'],
    [0.30980392156862746, '#960300'],
    [0.3137254901960784, '#980300'],
    [0.3176470588235294, '#9a0300'],
    [0.32156862745098036, '#9c0300'],
    [0.3254901960784314, '#9d0400'],
    [0.32941176470588235, '#9f0400'],
    [0.3333333333333333, '#a10400'],
    [0.33725490196078434, '#a20400'],
    [0.3411764705882353, '#a40400'],
    [0.34509803921568627, '#a60400'],
    [0.34901960784313724, '#a80400'],
    [0.3529411764705882, '#a90400'],
    [0.3568627450980392, '#ab0500'],
    [0.3607843137254902, '#ad0500'],
    [0.36470588235294116, '#af0500'],
    [0.3686274509803922, '#b00500'],
    [0.37254901960784315, '#b20500'],
    [0.3764705882352941, '#b40500'],
    [0.3803921568627451, '#b60600'],
    [0.38431372549019605, '#b80600'],
    [0.38823529411764707, '#b90600'],
    [0.39215686274509803, '#bb0600'],
    [0.396078431372549, '#bd0600'],
    [0.4, '#bf0700'],
    [0.403921568627451, '#c00700'],
    [0.40784313725490196, '#c20700'],
    [0.4117647058823529, '#c40700'],
    [0.4156862745098039, '#c60800'],
    [0.4196078431372549, '#c80800'],
    [0.4235294117647059, '#c90800'],
    [0.42745098039215684, '#cb0800'],
    [0.43137254901960786, '#cd0900'],
    [0.43529411764705883, '#cf0900'],
    [0.4392156862745098, '#d10900'],
    [0.44313725490196076, '#d20a00'],
    [0.44705882352941173, '#d40a00'],
    [0.45098039215686275, '#d60a00'],
    [0.4549019607843137, '#d80b00'],
    [0.4588235294117647, '#da0b00'],
    [0.4627450980392157, '#db0c00'],
    [0.4666666666666667, '#dd0c00'],
    [0.47058823529411764, '#df0d00'],
    [0.4745098039215686, '#e10d00'],
    [0.4784313725490196, '#e30e00'],
    [0.4823529411764706, '#e40f00'],
    [0.48627450980392156, '#e60f00'],
    [0.49019607843137253, '#e81000'],
    [0.49411764705882355, '#ea1100'],
    [0.4980392156862745, '#eb1300'],
    [0.5019607843137255, '#ed1400'],
    [0.5058823529411764, '#ee1600'],
    [0.5098039215686274, '#f01800'],
    [0.5137254901960784, '#f11b00'],
    [0.5176470588235293, '#f21d00'],
    [0.5215686274509804, '#f32000'],
    [0.5254901960784314, '#f52300'],
    [0.5294117647058824, '#f62600'],
    [0.5333333333333333, '#f62900'],
    [0.5372549019607843, '#f72c00'],
    [0.5411764705882353, '#f82f00'],
    [0.5450980392156862, '#f93200'],
    [0.5490196078431373, '#f93500'],
    [0.5529411764705883, '#fa3800'],
    [0.5568627450980392, '#fa3b00'],
    [0.5607843137254902, '#fb3d00'],
    [0.5647058823529412, '#fb4000'],
    [0.5686274509803921, '#fb4300'],
    [0.5725490196078431, '#fc4600'],
    [0.5764705882352941, '#fc4900'],
    [0.580392156862745, '#fc4b00'],
    [0.5843137254901961, '#fd4e00'],
    [0.5882352941176471, '#fd5100'],
    [0.592156862745098, '#fd5300'],
    [0.596078431372549, '#fd5600'],
    [0.6, '#fd5800'],
    [0.6039215686274509, '#fe5b00'],
    [0.6078431372549019, '#fe5d00'],
    [0.611764705882353, '#fe5f00'],
    [0.615686274509804, '#fe6200'],
    [0.6196078431372549, '#fe6400'],
    [0.6235294117647059, '#fe6600'],
    [0.6274509803921569, '#fe6800'],
    [0.6313725490196078, '#fe6b00'],
    [0.6352941176470588, '#fe6d00'],
    [0.6392156862745098, '#fe6f00'],
    [0.6431372549019607, '#fe7100'],
    [0.6470588235294118, '#fe7300'],
    [0.6509803921568628, '#fe7500'],
    [0.6549019607843137, '#fe7700'],
    [0.6588235294117647, '#fe7900'],
    [0.6627450980392157, '#fe7c00'],
    [0.6666666666666666, '#ff7e00'],
    [0.6705882352941176, '#ff8000'],
    [0.6745098039215687, '#ff8200'],
    [0.6784313725490196, '#ff8300'],
    [0.6823529411764706, '#ff8500'],
    [0.6862745098039216, '#ff8700'],
    [0.6901960784313725, '#ff8900'],
    [0.6941176470588235, '#ff8b00'],
    [0.6980392156862745, '#ff8d00'],
    [0.7019607843137254, '#ff8f00'],
    [0.7058823529411764, '#ff9100'],
    [0.7098039215686275, '#ff9300'],
    [0.7137254901960784, '#ff9400'],
    [0.7176470588235294, '#ff9600'],
    [0.7215686274509804, '#ff9800'],
    [0.7254901960784313, '#ff9a00'],
    [0.7294117647058823, '#ff9c00'],
    [0.7333333333333333, '#ff9d00'],
    [0.7372549019607844, '#ff9f00'],
    [0.7411764705882353, '#ffa100'],
    [0.7450980392156863, '#ffa300'],
    [0.7490196078431373, '#ffa401'],
    [0.7529411764705882, '#ffa601'],
    [0.7568627450980392, '#ffa801'],
    [0.7607843137254902, '#ffaa01'],
    [0.7647058823529411, '#ffab01'],
    [0.7686274509803921, '#ffad01'],
    [0.7725490196078432, '#ffaf01'],
    [0.7764705882352941, '#ffb001'],
    [0.7803921568627451, '#ffb202'],
    [0.7843137254901961, '#ffb402'],
    [0.788235294117647, '#ffb502'],
    [0.792156862745098, '#ffb702'],
    [0.796078431372549, '#ffb902'],
    [0.8, '#ffba02'],
    [0.803921568627451, '#ffbc03'],
    [0.807843137254902, '#ffbd03'],
    [0.8117647058823529, '#ffbf03'],
    [0.8156862745098039, '#ffc103'],
    [0.8196078431372549, '#ffc204'],
    [0.8235294117647058, '#ffc404'],
    [0.8274509803921568, '#ffc604'],
    [0.8313725490196078, '#ffc704'],
    [0.8352941176470589, '#ffc905'],
    [0.8392156862745098, '#ffca05'],
    [0.8431372549019608, '#ffcc05'],
    [0.8470588235294118, '#ffce06'],
    [0.8509803921568627, '#ffcf06'],
    [0.8549019607843137, '#ffd106'],
    [0.8588235294117647, '#ffd207'],
    [0.8627450980392157, '#ffd407'],
    [0.8666666666666667, '#ffd508'],
    [0.8705882352941177, '#ffd708'],
    [0.8745098039215686, '#ffd909'],
    [0.8784313725490196, '#ffda09'],
    [0.8823529411764706, '#ffdc0a'],
    [0.8862745098039215, '#ffdd0a'],
    [0.8901960784313725, '#ffdf0b'],
    [0.8941176470588235, '#ffe00b'],
    [0.8980392156862745, '#ffe20c'],
    [0.9019607843137255, '#ffe30d'],
    [0.9058823529411765, '#ffe50e'],
    [0.9098039215686274, '#ffe60f'],
    [0.9137254901960784, '#ffe810'],
    [0.9176470588235294, '#ffea11'],
    [0.9215686274509803, '#ffeb12'],
    [0.9254901960784314, '#ffed14'],
    [0.9294117647058824, '#ffee17'],
    [0.9333333333333333, '#fff01a'],
    [0.9372549019607843, '#fff11e'],
    [0.9411764705882353, '#fff324'],
    [0.9450980392156862, '#fff42a'],
    [0.9490196078431372, '#fff532'],
    [0.9529411764705882, '#fff73b'],
    [0.9568627450980391, '#fff847'],
    [0.9607843137254902, '#fff953'],
    [0.9647058823529412, '#fffb62'],
    [0.9686274509803922, '#fffb72'],
    [0.9725490196078431, '#fffc83'],
    [0.9764705882352941, '#fffd95'],
    [0.9803921568627451, '#fffea8'],
    [0.984313725490196, '#fffeba'],
    [0.9882352941176471, '#fffecc'],
    [0.9921568627450981, '#fffede'],
    [0.996078431372549, '#fffeee'],
    [1.0, '#ffffff'],
  ],
  CET_L4: [
    [0.0, '#000000'],
    [0.00392156862745098, '#060000'],
    [0.00784313725490196, '#0c0000'],
    [0.011764705882352941, '#110000'],
    [0.01568627450980392, '#150000'],
    [0.0196078431372549, '#190000'],
    [0.023529411764705882, '#1c0000'],
    [0.027450980392156862, '#1f0000'],
    [0.03137254901960784, '#210000'],
    [0.03529411764705882, '#240000'],
    [0.0392156862745098, '#260000'],
    [0.043137254901960784, '#280000'],
    [0.047058823529411764, '#2a0000'],
    [0.050980392156862744, '#2c0000'],
    [0.054901960784313725, '#2e0000'],
    [0.058823529411764705, '#2f0000'],
    [0.06274509803921569, '#310000'],
    [0.06666666666666667, '#330000'],
    [0.07058823529411765, '#340000'],
    [0.07450980392156863, '#360000'],
    [0.0784313725490196, '#370000'],
    [0.08235294117647059, '#390000'],
    [0.08627450980392157, '#3a0000'],
    [0.09019607843137255, '#3c0000'],
    [0.09411764705882353, '#3d0000'],
    [0.09803921568627451, '#3e0000'],
    [0.10196078431372549, '#400000'],
    [0.10588235294117647, '#410000'],
    [0.10980392156862745, '#430000'],
    [0.11372549019607843, '#440000'],
    [0.11764705882352941, '#460000'],
    [0.12156862745098039, '#470000'],
    [0.12549019607843137, '#490000'],
    [0.12941176470588234, '#4a0000'],
    [0.13333333333333333, '#4c0000'],
    [0.13725490196078433, '#4d0000'],
    [0.1411764705882353, '#4f0000'],
    [0.14509803921568626, '#500000'],
    [0.14901960784313725, '#520000'],
    [0.15294117647058825, '#530000'],
    [0.1568627450980392, '#550000'],
    [0.16078431372549018, '#560000'],
    [0.16470588235294117, '#580000'],
    [0.16862745098039217, '#590100'],
    [0.17254901960784313, '#5b0100'],
    [0.1764705882352941, '#5c0100'],
    [0.1803921568627451, '#5e0100'],
    [0.1843137254901961, '#5f0100'],
    [0.18823529411764706, '#610100'],
    [0.19215686274509802, '#620100'],
    [0.19607843137254902, '#640100'],
    [0.2, '#650100'],
    [0.20392156862745098, '#670100'],
    [0.20784313725490194, '#690100'],
    [0.21176470588235294, '#6a0100'],
    [0.21568627450980393, '#6c0100'],
    [0.2196078431372549, '#6d0100'],
    [0.22352941176470587, '#6f0100'],
    [0.22745098039215686, '#700100'],
    [0.23137254901960785, '#720100'],
    [0.23529411764705882, '#740100'],
    [0.2392156862745098, '#750100'],
    [0.24313725490196078, '#770100'],
    [0.24705882352941178, '#780200'],
    [0.25098039215686274, '#7a0200'],
    [0.2549019607843137, '#7c0200'],
    [0.2588235294117647, '#7d0200'],
    [0.2627450980392157, '#7f0200'],
    [0.26666666666666666, '#800200'],
    [0.27058823529411763, '#820200'],
    [0.27450980392156865, '#840200'],
    [0.2784313725490196, '#850200'],
    [0.2823529411764706, '#870200'],
    [0.28627450980392155, '#890200'],
    [0.2901960784313725, '#8a0200'],
    [0.29411764705882354, '#8c0200'],
    [0.2980392156862745, '#8e0300'],
    [0.30196078431372547, '#8f0300'],
    [0.3058823529411765, '#910300'],
    [0.30980392156862746, '#920300'],
    [0.3137254901960784, '#940300'],
    [0.3176470588235294, '#960300'],
    [0.32156862745098036, '#970300'],
    [0.3254901960784314, '#990300'],
    [0.32941176470588235, '#9b0300'],
    [0.3333333333333333, '#9d0400'],
    [0.33725490196078434, '#9e0400'],
    [0.3411764705882353, '#a00400'],
    [0.34509803921568627, '#a20400'],
    [0.34901960784313724, '#a30400'],
    [0.3529411764705882, '#a50400'],
    [0.3568627450980392, '#a70400'],
    [0.3607843137254902, '#a80400'],
    [0.36470588235294116, '#aa0500'],
    [0.3686274509803922, '#ac0500'],
    [0.37254901960784315, '#ad0500'],
    [0.3764705882352941, '#af0500'],
    [0.3803921568627451, '#b10500'],
    [0.38431372549019605, '#b30500'],
    [0.38823529411764707, '#b40500'],
    [0.39215686274509803, '#b60600'],
    [0.396078431372549, '#b80600'],
    [0.4, '#b90600'],
    [0.403921568627451, '#bb0600'],
    [0.40784313725490196, '#bd0600'],
    [0.4117647058823529, '#bf0700'],
    [0.4156862745098039, '#c00700'],
    [0.4196078431372549, '#c20700'],
    [0.4235294117647059, '#c40700'],
    [0.42745098039215684, '#c60800'],
    [0.43137254901960786, '#c70800'],
    [0.43529411764705883, '#c90800'],
    [0.4392156862745098, '#cb0800'],
    [0.44313725490196076, '#cd0900'],
    [0.44705882352941173, '#ce0900'],
    [0.45098039215686275, '#d00900'],
    [0.4549019607843137, '#d20a00'],
    [0.4588235294117647, '#d40a00'],
    [0.4627450980392157, '#d50a00'],
    [0.4666666666666667, '#d70b00'],
    [0.47058823529411764, '#d90b00'],
    [0.4745098039215686, '#db0b00'],
    [0.4784313725490196, '#dc0c00'],
    [0.4823529411764706, '#de0c00'],
    [0.48627450980392156, '#e00d00'],
    [0.49019607843137253, '#e20d00'],
    [0.49411764705882355, '#e30e00'],
    [0.4980392156862745, '#e50f00'],
    [0.5019607843137255, '#e71000'],
    [0.5058823529411764, '#e81000'],
    [0.5098039215686274, '#ea1200'],
    [0.5137254901960784, '#ec1300'],
    [0.5176470588235293, '#ed1500'],
    [0.5215686274509804, '#ee1700'],
    [0.5254901960784314, '#f01a00'],
    [0.5294117647058824, '#f11d00'],
    [0.5333333333333333, '#f22000'],
    [0.5372549019607843, '#f32300'],
    [0.5411764705882353, '#f32700'],
    [0.5450980392156862, '#f42a00'],
    [0.5490196078431373, '#f52d00'],
    [0.5529411764705883, '#f53000'],
    [0.5568627450980392, '#f63300'],
    [0.5607843137254902, '#f63600'],
    [0.5647058823529412, '#f73900'],
    [0.5686274509803921, '#f73c00'],
    [0.5725490196078431, '#f73f00'],
    [0.5764705882352941, '#f84200'],
    [0.580392156862745, '#f84400'],
    [0.5843137254901961, '#f84700'],
    [0.5882352941176471, '#f84a00'],
    [0.592156862745098, '#f94c00'],
    [0.596078431372549, '#f94f00'],
    [0.6, '#f95100'],
    [0.6039215686274509, '#f95400'],
    [0.6078431372549019, '#fa5600'],
    [0.611764705882353, '#fa5800'],
    [0.615686274509804, '#fa5b00'],
    [0.6196078431372549, '#fa5d00'],
    [0.6235294117647059, '#fa5f00'],
    [0.6274509803921569, '#fa6100'],
    [0.6313725490196078, '#fb6300'],
    [0.6352941176470588, '#fb6600'],
    [0.6392156862745098, '#fb6800'],
    [0.6431372549019607, '#fb6a00'],
    [0.6470588235294118, '#fb6c00'],
    [0.6509803921568628, '#fb6e00'],
    [0.6549019607843137, '#fb7000'],
    [0.6588235294117647, '#fb7200'],
    [0.6627450980392157, '#fc7400'],
    [0.6666666666666666, '#fc7600'],
    [0.6705882352941176, '#fc7800'],
    [0.6745098039215687, '#fc7a00'],
    [0.6784313725490196, '#fc7b00'],
    [0.6823529411764706, '#fc7d00'],
    [0.6862745098039216, '#fc7f00'],
    [0.6901960784313725, '#fc8100'],
    [0.6941176470588235, '#fc8300'],
    [0.6980392156862745, '#fc8500'],
    [0.7019607843137254, '#fc8700'],
    [0.7058823529411764, '#fd8800'],
    [0.7098039215686275, '#fd8a00'],
    [0.7137254901960784, '#fd8c00'],
    [0.7176470588235294, '#fd8e00'],
    [0.7215686274509804, '#fd8f00'],
    [0.7254901960784313, '#fd9100'],
    [0.7294117647058823, '#fd9300'],
    [0.7333333333333333, '#fd9500'],
    [0.7372549019607844, '#fd9600'],
    [0.7411764705882353, '#fd9800'],
    [0.7450980392156863, '#fd9a00'],
    [0.7490196078431373, '#fd9c00'],
    [0.7529411764705882, '#fd9d00'],
    [0.7568627450980392, '#fd9f00'],
    [0.7607843137254902, '#fda100'],
    [0.7647058823529411, '#fda200'],
    [0.7686274509803921, '#fda400'],
    [0.7725490196078432, '#fda600'],
    [0.7764705882352941, '#fea700'],
    [0.7803921568627451, '#fea900'],
    [0.7843137254901961, '#feaa00'],
    [0.788235294117647, '#feac00'],
    [0.792156862745098, '#feae00'],
    [0.796078431372549, '#feaf00'],
    [0.8, '#feb100'],
    [0.803921568627451, '#feb200'],
    [0.807843137254902, '#feb400'],
    [0.8117647058823529, '#feb600'],
    [0.8156862745098039, '#feb700'],
    [0.8196078431372549, '#feb900'],
    [0.8235294117647058, '#feba00'],
    [0.8274509803921568, '#febc00'],
    [0.8313725490196078, '#febe00'],
    [0.8352941176470589, '#febf00'],
    [0.8392156862745098, '#fec100'],
    [0.8431372549019608, '#fec200'],
    [0.8470588235294118, '#fec400'],
    [0.8509803921568627, '#fec500'],
    [0.8549019607843137, '#fec700'],
    [0.8588235294117647, '#fec800'],
    [0.8627450980392157, '#feca00'],
    [0.8666666666666667, '#fecc00'],
    [0.8705882352941177, '#fecd00'],
    [0.8745098039215686, '#fecf00'],
    [0.8784313725490196, '#fed000'],
    [0.8823529411764706, '#fed200'],
    [0.8862745098039215, '#fed300'],
    [0.8901960784313725, '#fed500'],
    [0.8941176470588235, '#fed600'],
    [0.8980392156862745, '#fed800'],
    [0.9019607843137255, '#fed900'],
    [0.9058823529411765, '#fedb00'],
    [0.9098039215686274, '#fedc00'],
    [0.9137254901960784, '#fede00'],
    [0.9176470588235294, '#fedf00'],
    [0.9215686274509803, '#fee100'],
    [0.9254901960784314, '#fee200'],
    [0.9294117647058824, '#fee400'],
    [0.9333333333333333, '#fee500'],
    [0.9372549019607843, '#fee700'],
    [0.9411764705882353, '#fee800'],
    [0.9450980392156862, '#feea00'],
    [0.9490196078431372, '#feeb00'],
    [0.9529411764705882, '#feed00'],
    [0.9568627450980391, '#feee00'],
    [0.9607843137254902, '#fef000'],
    [0.9647058823529412, '#fef100'],
    [0.9686274509803922, '#fef300'],
    [0.9725490196078431, '#fef400'],
    [0.9764705882352941, '#fef600'],
    [0.9803921568627451, '#fef700'],
    [0.984313725490196, '#fef900'],
    [0.9882352941176471, '#fffa00'],
    [0.9921568627450981, '#fffc00'],
    [0.996078431372549, '#fffd00'],
    [1.0, '#ffff00'],
  ],
  CET_L5: [
    [0.0, '#001505'],
    [0.00392156862745098, '#011505'],
    [0.00784313725490196, '#011605'],
    [0.011764705882352941, '#021705'],
    [0.01568627450980392, '#031805'],
    [0.0196078431372549, '#031905'],
    [0.023529411764705882, '#041a05'],
    [0.027450980392156862, '#041a05'],
    [0.03137254901960784, '#051b05'],
    [0.03529411764705882, '#061c05'],
    [0.0392156862745098, '#061d05'],
    [0.043137254901960784, '#071d05'],
    [0.047058823529411764, '#071e05'],
    [0.050980392156862744, '#081f05'],
    [0.054901960784313725, '#082005'],
    [0.058823529411764705, '#082105'],
    [0.06274509803921569, '#092105'],
    [0.06666666666666667, '#092205'],
    [0.07058823529411765, '#092305'],
    [0.07450980392156863, '#092405'],
    [0.0784313725490196, '#092505'],
    [0.08235294117647059, '#092605'],
    [0.08627450980392157, '#092705'],
    [0.09019607843137255, '#092705'],
    [0.09411764705882353, '#092805'],
    [0.09803921568627451, '#092905'],
    [0.10196078431372549, '#092a05'],
    [0.10588235294117647, '#082b05'],
    [0.10980392156862745, '#082c05'],
    [0.11372549019607843, '#082d05'],
    [0.11764705882352941, '#082d04'],
    [0.12156862745098039, '#082e04'],
    [0.12549019607843137, '#082f04'],
    [0.12941176470588234, '#083004'],
    [0.13333333333333333, '#083104'],
    [0.13725490196078433, '#083204'],
    [0.1411764705882353, '#083304'],
    [0.14509803921568626, '#083404'],
    [0.14901960784313725, '#083404'],
    [0.15294117647058825, '#083504'],
    [0.1568627450980392, '#083604'],
    [0.16078431372549018, '#083704'],
    [0.16470588235294117, '#093804'],
    [0.16862745098039217, '#093904'],
    [0.17254901960784313, '#093a04'],
    [0.1764705882352941, '#093b04'],
    [0.1803921568627451, '#0a3c04'],
    [0.1843137254901961, '#0a3c04'],
    [0.18823529411764706, '#0a3d04'],
    [0.19215686274509802, '#0a3e04'],
    [0.19607843137254902, '#0b3f04'],
    [0.2, '#0b4004'],
    [0.20392156862745098, '#0b4104'],
    [0.20784313725490194, '#0b4204'],
    [0.21176470588235294, '#0c4305'],
    [0.21568627450980393, '#0c4405'],
    [0.2196078431372549, '#0c4405'],
    [0.22352941176470587, '#0d4505'],
    [0.22745098039215686, '#0d4605'],
    [0.23137254901960785, '#0d4705'],
    [0.23529411764705882, '#0d4805'],
    [0.2392156862745098, '#0e4905'],
    [0.24313725490196078, '#0e4a05'],
    [0.24705882352941178, '#0e4b05'],
    [0.25098039215686274, '#0e4c05'],
    [0.2549019607843137, '#0f4d05'],
    [0.2588235294117647, '#0f4e05'],
    [0.2627450980392157, '#0f4e05'],
    [0.26666666666666666, '#104f05'],
    [0.27058823529411763, '#105005'],
    [0.27450980392156865, '#105105'],
    [0.2784313725490196, '#105205'],
    [0.2823529411764706, '#105305'],
    [0.28627450980392155, '#115405'],
    [0.2901960784313725, '#115505'],
    [0.29411764705882354, '#115605'],
    [0.2980392156862745, '#115705'],
    [0.30196078431372547, '#125805'],
    [0.3058823529411765, '#125906'],
    [0.30980392156862746, '#125a06'],
    [0.3137254901960784, '#125b06'],
    [0.3176470588235294, '#135c06'],
    [0.32156862745098036, '#135c06'],
    [0.3254901960784314, '#135d06'],
    [0.32941176470588235, '#135e06'],
    [0.3333333333333333, '#145f06'],
    [0.33725490196078434, '#146006'],
    [0.3411764705882353, '#146106'],
    [0.34509803921568627, '#146206'],
    [0.34901960784313724, '#156307'],
    [0.3529411764705882, '#156407'],
    [0.3568627450980392, '#156507'],
    [0.3607843137254902, '#156607'],
    [0.36470588235294116, '#166707'],
    [0.3686274509803922, '#166807'],
    [0.37254901960784315, '#166907'],
    [0.3764705882352941, '#166a07'],
    [0.3803921568627451, '#176b07'],
    [0.38431372549019605, '#176c07'],
    [0.38823529411764707, '#176d08'],
    [0.39215686274509803, '#176e08'],
    [0.396078431372549, '#186f08'],
    [0.4, '#187008'],
    [0.403921568627451, '#187108'],
    [0.40784313725490196, '#187208'],
    [0.4117647058823529, '#197308'],
    [0.4156862745098039, '#197408'],
    [0.4196078431372549, '#197508'],
    [0.4235294117647059, '#197609'],
    [0.42745098039215684, '#1a7709'],
    [0.43137254901960786, '#1a7809'],
    [0.43529411764705883, '#1a7909'],
    [0.4392156862745098, '#1a7a09'],
    [0.44313725490196076, '#1b7b09'],
    [0.44705882352941173, '#1b7c09'],
    [0.45098039215686275, '#1b7d09'],
    [0.4549019607843137, '#1b7e09'],
    [0.4588235294117647, '#1c7f0a'],
    [0.4627450980392157, '#1c800a'],
    [0.4666666666666667, '#1c810a'],
    [0.47058823529411764, '#1c820a'],
    [0.4745098039215686, '#1d830a'],
    [0.4784313725490196, '#1d840a'],
    [0.4823529411764706, '#1d850a'],
    [0.48627450980392156, '#1e860a'],
    [0.49019607843137253, '#1e870a'],
    [0.49411764705882355, '#1e880b'],
    [0.4980392156862745, '#1e890b'],
    [0.5019607843137255, '#1f8a0b'],
    [0.5058823529411764, '#1f8b0b'],
    [0.5098039215686274, '#1f8c0b'],
    [0.5137254901960784, '#1f8d0b'],
    [0.5176470588235293, '#208e0b'],
    [0.5215686274509804, '#208f0b'],
    [0.5254901960784314, '#20900c'],
    [0.5294117647058824, '#20910c'],
    [0.5333333333333333, '#21920c'],
    [0.5372549019607843, '#21930c'],
    [0.5411764705882353, '#21940c'],
    [0.5450980392156862, '#21950c'],
    [0.5490196078431373, '#22960c'],
    [0.5529411764705883, '#22970c'],
    [0.5568627450980392, '#22980c'],
    [0.5607843137254902, '#22990d'],
    [0.5647058823529412, '#239a0d'],
    [0.5686274509803921, '#239b0d'],
    [0.5725490196078431, '#239c0d'],
    [0.5764705882352941, '#239d0d'],
    [0.580392156862745, '#249e0d'],
    [0.5843137254901961, '#249f0d'],
    [0.5882352941176471, '#24a00d'],
    [0.592156862745098, '#25a20e'],
    [0.596078431372549, '#25a30e'],
    [0.6, '#25a40e'],
    [0.6039215686274509, '#25a50e'],
    [0.6078431372549019, '#26a60e'],
    [0.611764705882353, '#26a70e'],
    [0.615686274509804, '#26a80e'],
    [0.6196078431372549, '#26a90e'],
    [0.6235294117647059, '#27aa0e'],
    [0.6274509803921569, '#27ab0f'],
    [0.6313725490196078, '#27ac0f'],
    [0.6352941176470588, '#27ad0f'],
    [0.6392156862745098, '#28ae0f'],
    [0.6431372549019607, '#28af0f'],
    [0.6470588235294118, '#28b00f'],
    [0.6509803921568628, '#29b10f'],
    [0.6549019607843137, '#29b20f'],
    [0.6588235294117647, '#29b40f'],
    [0.6627450980392157, '#29b510'],
    [0.6666666666666666, '#2ab610'],
    [0.6705882352941176, '#2ab710'],
    [0.6745098039215687, '#2ab810'],
    [0.6784313725490196, '#2ab910'],
    [0.6823529411764706, '#2bba10'],
    [0.6862745098039216, '#2bbb10'],
    [0.6901960784313725, '#2bbc10'],
    [0.6941176470588235, '#2bbd11'],
    [0.6980392156862745, '#2cbe11'],
    [0.7019607843137254, '#2cbf11'],
    [0.7058823529411764, '#2cc011'],
    [0.7098039215686275, '#2dc111'],
    [0.7137254901960784, '#2dc311'],
    [0.7176470588235294, '#2dc411'],
    [0.7215686274509804, '#2dc511'],
    [0.7254901960784313, '#2ec611'],
    [0.7294117647058823, '#2ec712'],
    [0.7333333333333333, '#2ec812'],
    [0.7372549019607844, '#2ec912'],
    [0.7411764705882353, '#2fca12'],
    [0.7450980392156863, '#2fcb12'],
    [0.7490196078431373, '#2fcc12'],
    [0.7529411764705882, '#30cd12'],
    [0.7568627450980392, '#30cf12'],
    [0.7607843137254902, '#30d012'],
    [0.7647058823529411, '#30d113'],
    [0.7686274509803921, '#31d213'],
    [0.7725490196078432, '#31d313'],
    [0.7764705882352941, '#31d413'],
    [0.7803921568627451, '#31d513'],
    [0.7843137254901961, '#32d613'],
    [0.788235294117647, '#32d713'],
    [0.792156862745098, '#32d813'],
    [0.796078431372549, '#32da13'],
    [0.8, '#33db14'],
    [0.803921568627451, '#33dc14'],
    [0.807843137254902, '#33dd14'],
    [0.8117647058823529, '#34de14'],
    [0.8156862745098039, '#34df14'],
    [0.8196078431372549, '#34e014'],
    [0.8235294117647058, '#34e114'],
    [0.8274509803921568, '#35e214'],
    [0.8313725490196078, '#35e415'],
    [0.8352941176470589, '#35e515'],
    [0.8392156862745098, '#37e615'],
    [0.8431372549019608, '#38e715'],
    [0.8470588235294118, '#3ae815'],
    [0.8509803921568627, '#3de915'],
    [0.8549019607843137, '#40ea15'],
    [0.8588235294117647, '#44eb15'],
    [0.8627450980392157, '#47ec15'],
    [0.8666666666666667, '#4bed15'],
    [0.8705882352941177, '#4fed15'],
    [0.8745098039215686, '#53ee15'],
    [0.8784313725490196, '#57ef15'],
    [0.8823529411764706, '#5bf015'],
    [0.8862745098039215, '#5ff115'],
    [0.8901960784313725, '#63f115'],
    [0.8941176470588235, '#68f215'],
    [0.8980392156862745, '#6cf315'],
    [0.9019607843137255, '#70f415'],
    [0.9058823529411765, '#74f415'],
    [0.9098039215686274, '#78f515'],
    [0.9137254901960784, '#7df615'],
    [0.9176470588235294, '#81f615'],
    [0.9215686274509803, '#85f715'],
    [0.9254901960784314, '#89f815'],
    [0.9294117647058824, '#8df815'],
    [0.9333333333333333, '#92f915'],
    [0.9372549019607843, '#96f915'],
    [0.9411764705882353, '#9afa15'],
    [0.9450980392156862, '#9efb15'],
    [0.9490196078431372, '#a2fb14'],
    [0.9529411764705882, '#a6fc14'],
    [0.9568627450980391, '#abfc14'],
    [0.9607843137254902, '#affd14'],
    [0.9647058823529412, '#b3fd14'],
    [0.9686274509803922, '#b7fe14'],
    [0.9725490196078431, '#bbfe14'],
    [0.9764705882352941, '#bffe14'],
    [0.9803921568627451, '#c3ff14'],
    [0.984313725490196, '#c7ff14'],
    [0.9882352941176471, '#cbff14'],
    [0.9921568627450981, '#cfff14'],
    [0.996078431372549, '#d4ff14'],
    [1.0, '#d8ff14'],
  ],
  CET_L6: [
    [0.0, '#00004e'],
    [0.00392156862745098, '#000150'],
    [0.00784313725490196, '#000152'],
    [0.011764705882352941, '#000154'],
    [0.01568627450980392, '#000255'],
    [0.0196078431372549, '#000257'],
    [0.023529411764705882, '#000259'],
    [0.027450980392156862, '#00025b'],
    [0.03137254901960784, '#00025d'],
    [0.03529411764705882, '#00025f'],
    [0.0392156862745098, '#010261'],
    [0.043137254901960784, '#010263'],
    [0.047058823529411764, '#020265'],
    [0.050980392156862744, '#030267'],
    [0.054901960784313725, '#030269'],
    [0.058823529411764705, '#04026c'],
    [0.06274509803921569, '#05026e'],
    [0.06666666666666667, '#060270'],
    [0.07058823529411765, '#060272'],
    [0.07450980392156863, '#070274'],
    [0.0784313725490196, '#080276'],
    [0.08235294117647059, '#090178'],
    [0.08627450980392157, '#09017a'],
    [0.09019607843137255, '#0a017c'],
    [0.09411764705882353, '#0b017e'],
    [0.09803921568627451, '#0b0181'],
    [0.10196078431372549, '#0c0183'],
    [0.10588235294117647, '#0d0185'],
    [0.10980392156862745, '#0d0187'],
    [0.11372549019607843, '#0e0189'],
    [0.11764705882352941, '#0e018b'],
    [0.12156862745098039, '#0f018e'],
    [0.12549019607843137, '#0f0190'],
    [0.12941176470588234, '#0f0192'],
    [0.13333333333333333, '#100194'],
    [0.13725490196078433, '#100197'],
    [0.1411764705882353, '#100199'],
    [0.14509803921568626, '#10019b'],
    [0.14901960784313725, '#10019d'],
    [0.15294117647058825, '#1001a0'],
    [0.1568627450980392, '#0f01a2'],
    [0.16078431372549018, '#0f02a4'],
    [0.16470588235294117, '#0e02a7'],
    [0.16862745098039217, '#0e02a9'],
    [0.17254901960784313, '#0d02ab'],
    [0.1764705882352941, '#0c03ae'],
    [0.1803921568627451, '#0b03b0'],
    [0.1843137254901961, '#0a04b2'],
    [0.18823529411764706, '#0a04b5'],
    [0.19215686274509802, '#0904b7'],
    [0.19607843137254902, '#0905b9'],
    [0.2, '#0806bb'],
    [0.20392156862745098, '#0806bd'],
    [0.20784313725490194, '#0707bf'],
    [0.21176470588235294, '#0707c2'],
    [0.21568627450980393, '#0708c4'],
    [0.2196078431372549, '#0709c6'],
    [0.22352941176470587, '#070ac8'],
    [0.22745098039215686, '#070bca'],
    [0.23137254901960785, '#080ccc'],
    [0.23529411764705882, '#080dce'],
    [0.2392156862745098, '#090dd0'],
    [0.24313725490196078, '#0a0ed1'],
    [0.24705882352941178, '#0b0fd3'],
    [0.25098039215686274, '#0c10d5'],
    [0.2549019607843137, '#0d11d7'],
    [0.2588235294117647, '#0e12d9'],
    [0.2627450980392157, '#0f13db'],
    [0.26666666666666666, '#1014dc'],
    [0.27058823529411763, '#1215de'],
    [0.27450980392156865, '#1316e0'],
    [0.2784313725490196, '#1417e1'],
    [0.2823529411764706, '#1618e3'],
    [0.28627450980392155, '#1719e5'],
    [0.2901960784313725, '#181ae6'],
    [0.29411764705882354, '#1a1be7'],
    [0.2980392156862745, '#1b1ce9'],
    [0.30196078431372547, '#1c1eea'],
    [0.3058823529411765, '#1d1feb'],
    [0.30980392156862746, '#1e20ed'],
    [0.3137254901960784, '#1f22ee'],
    [0.3176470588235294, '#2023ef'],
    [0.32156862745098036, '#2124f0'],
    [0.3254901960784314, '#2226f1'],
    [0.32941176470588235, '#2327f2'],
    [0.3333333333333333, '#2429f3'],
    [0.33725490196078434, '#252af4'],
    [0.3411764705882353, '#252cf5'],
    [0.34509803921568627, '#262ef5'],
    [0.34901960784313724, '#272ff6'],
    [0.3529411764705882, '#2831f7'],
    [0.3568627450980392, '#2832f7'],
    [0.3607843137254902, '#2934f8'],
    [0.36470588235294116, '#2a36f8'],
    [0.3686274509803922, '#2a37f9'],
    [0.37254901960784315, '#2b39f9'],
    [0.3764705882352941, '#2b3bf9'],
    [0.3803921568627451, '#2c3dfa'],
    [0.38431372549019605, '#2c3efa'],
    [0.38823529411764707, '#2d40fa'],
    [0.39215686274509803, '#2d42fa'],
    [0.396078431372549, '#2d43fa'],
    [0.4, '#2d45fa'],
    [0.403921568627451, '#2e47fa'],
    [0.40784313725490196, '#2e48fb'],
    [0.4117647058823529, '#2e4afb'],
    [0.4156862745098039, '#2e4cfb'],
    [0.4196078431372549, '#2f4dfb'],
    [0.4235294117647059, '#2f4ffb'],
    [0.42745098039215684, '#2f50fb'],
    [0.43137254901960786, '#2f52fb'],
    [0.43529411764705883, '#2f53fb'],
    [0.4392156862745098, '#2f55fb'],
    [0.44313725490196076, '#2f57fb'],
    [0.44705882352941173, '#2f58fb'],
    [0.45098039215686275, '#2f5afc'],
    [0.4549019607843137, '#2f5bfc'],
    [0.4588235294117647, '#2f5dfc'],
    [0.4627450980392157, '#2f5efc'],
    [0.4666666666666667, '#2f5ffc'],
    [0.47058823529411764, '#2f61fc'],
    [0.4745098039215686, '#2f62fc'],
    [0.4784313725490196, '#2e64fc'],
    [0.4823529411764706, '#2e65fc'],
    [0.48627450980392156, '#2e67fc'],
    [0.49019607843137253, '#2e68fc'],
    [0.49411764705882355, '#2d6afc'],
    [0.4980392156862745, '#2d6bfd'],
    [0.5019607843137255, '#2d6cfd'],
    [0.5058823529411764, '#2c6efd'],
    [0.5098039215686274, '#2c6ffd'],
    [0.5137254901960784, '#2c71fd'],
    [0.5176470588235293, '#2c72fd'],
    [0.5215686274509804, '#2b73fd'],
    [0.5254901960784314, '#2b75fd'],
    [0.5294117647058824, '#2b76fd'],
    [0.5333333333333333, '#2b77fd'],
    [0.5372549019607843, '#2b79fd'],
    [0.5411764705882353, '#2b7afd'],
    [0.5450980392156862, '#2b7bfd'],
    [0.5490196078431373, '#2b7dfd'],
    [0.5529411764705883, '#2b7efd'],
    [0.5568627450980392, '#2b7ffd'],
    [0.5607843137254902, '#2b81fd'],
    [0.5647058823529412, '#2c82fd'],
    [0.5686274509803921, '#2c83fd'],
    [0.5725490196078431, '#2c84fd'],
    [0.5764705882352941, '#2c86fd'],
    [0.580392156862745, '#2d87fd'],
    [0.5843137254901961, '#2d88fd'],
    [0.5882352941176471, '#2e8afd'],
    [0.592156862745098, '#2e8bfd'],
    [0.596078431372549, '#2f8cfd'],
    [0.6, '#2f8dfd'],
    [0.6039215686274509, '#308ffd'],
    [0.6078431372549019, '#3090fd'],
    [0.611764705882353, '#3191fd'],
    [0.615686274509804, '#3292fd'],
    [0.6196078431372549, '#3293fd'],
    [0.6235294117647059, '#3395fd'],
    [0.6274509803921569, '#3396fd'],
    [0.6313725490196078, '#3497fd'],
    [0.6352941176470588, '#3498fd'],
    [0.6392156862745098, '#349afd'],
    [0.6431372549019607, '#359bfc'],
    [0.6470588235294118, '#359cfc'],
    [0.6509803921568628, '#359dfc'],
    [0.6549019607843137, '#359ffc'],
    [0.6588235294117647, '#35a0fc'],
    [0.6627450980392157, '#35a1fc'],
    [0.6666666666666666, '#35a3fc'],
    [0.6705882352941176, '#35a4fc'],
    [0.6745098039215687, '#35a5fc'],
    [0.6784313725490196, '#35a6fc'],
    [0.6823529411764706, '#35a8fc'],
    [0.6862745098039216, '#35a9fc'],
    [0.6901960784313725, '#34aafc'],
    [0.6941176470588235, '#34abfc'],
    [0.6980392156862745, '#34adfc'],
    [0.7019607843137254, '#33aefc'],
    [0.7058823529411764, '#33affc'],
    [0.7098039215686275, '#32b0fc'],
    [0.7137254901960784, '#32b2fc'],
    [0.7176470588235294, '#31b3fc'],
    [0.7215686274509804, '#30b4fc'],
    [0.7254901960784313, '#2fb6fc'],
    [0.7294117647058823, '#2fb7fc'],
    [0.7333333333333333, '#2eb8fc'],
    [0.7372549019607844, '#2db9fc'],
    [0.7411764705882353, '#2dbbfc'],
    [0.7450980392156863, '#2cbcfc'],
    [0.7490196078431373, '#2bbdfc'],
    [0.7529411764705882, '#2bbffb'],
    [0.7568627450980392, '#2ac0fb'],
    [0.7607843137254902, '#2ac1fb'],
    [0.7647058823529411, '#29c2fb'],
    [0.7686274509803921, '#29c4fb'],
    [0.7725490196078432, '#29c5fb'],
    [0.7764705882352941, '#29c6fb'],
    [0.7803921568627451, '#28c7fb'],
    [0.7843137254901961, '#28c8fb'],
    [0.788235294117647, '#28cafb'],
    [0.792156862745098, '#28cbfb'],
    [0.796078431372549, '#28ccfb'],
    [0.8, '#28cdfb'],
    [0.803921568627451, '#29cffb'],
    [0.807843137254902, '#29d0fb'],
    [0.8117647058823529, '#29d1fa'],
    [0.8156862745098039, '#2ad2fa'],
    [0.8196078431372549, '#2ad3fa'],
    [0.8235294117647058, '#2bd5fa'],
    [0.8274509803921568, '#2bd6fa'],
    [0.8313725490196078, '#2cd7fa'],
    [0.8352941176470589, '#2dd8fa'],
    [0.8392156862745098, '#2ed9fa'],
    [0.8431372549019608, '#30dbfa'],
    [0.8470588235294118, '#32dcf9'],
    [0.8509803921568627, '#35ddf9'],
    [0.8549019607843137, '#38def9'],
    [0.8588235294117647, '#3bdff9'],
    [0.8627450980392157, '#3ee0f9'],
    [0.8666666666666667, '#42e1f9'],
    [0.8705882352941177, '#45e2f9'],
    [0.8745098039215686, '#49e3f9'],
    [0.8784313725490196, '#4ce4f9'],
    [0.8823529411764706, '#50e5f9'],
    [0.8862745098039215, '#53e6f8'],
    [0.8901960784313725, '#57e7f8'],
    [0.8941176470588235, '#5ae8f8'],
    [0.8980392156862745, '#5ee9f8'],
    [0.9019607843137255, '#61eaf8'],
    [0.9058823529411765, '#65ebf8'],
    [0.9098039215686274, '#68ecf8'],
    [0.9137254901960784, '#6cecf8'],
    [0.9176470588235294, '#6fedf8'],
    [0.9215686274509803, '#73eef7'],
    [0.9254901960784314, '#76eff7'],
    [0.9294117647058824, '#79f0f7'],
    [0.9333333333333333, '#7df1f7'],
    [0.9372549019607843, '#80f2f7'],
    [0.9411764705882353, '#83f2f7'],
    [0.9450980392156862, '#87f3f7'],
    [0.9490196078431372, '#8af4f7'],
    [0.9529411764705882, '#8df5f6'],
    [0.9568627450980391, '#90f6f6'],
    [0.9607843137254902, '#93f7f6'],
    [0.9647058823529412, '#97f7f6'],
    [0.9686274509803922, '#9af8f6'],
    [0.9725490196078431, '#9df9f6'],
    [0.9764705882352941, '#a0faf6'],
    [0.9803921568627451, '#a3faf6'],
    [0.984313725490196, '#a6fbf6'],
    [0.9882352941176471, '#a9fcf5'],
    [0.9921568627450981, '#adfdf5'],
    [0.996078431372549, '#b0fef5'],
    [1.0, '#b3fef5'],
  ],
  CET_L7: [
    [0.0, '#00024b'],
    [0.00392156862745098, '#00024d'],
    [0.00784313725490196, '#00034f'],
    [0.011764705882352941, '#000351'],
    [0.01568627450980392, '#000453'],
    [0.0196078431372549, '#000455'],
    [0.023529411764705882, '#000457'],
    [0.027450980392156862, '#000459'],
    [0.03137254901960784, '#00045b'],
    [0.03529411764705882, '#00045d'],
    [0.0392156862745098, '#00055f'],
    [0.043137254901960784, '#000561'],
    [0.047058823529411764, '#000564'],
    [0.050980392156862744, '#000566'],
    [0.054901960784313725, '#000568'],
    [0.058823529411764705, '#00056a'],
    [0.06274509803921569, '#00056c'],
    [0.06666666666666667, '#01056e'],
    [0.07058823529411765, '#010570'],
    [0.07450980392156863, '#010572'],
    [0.0784313725490196, '#020575'],
    [0.08235294117647059, '#020577'],
    [0.08627450980392157, '#020579'],
    [0.09019607843137255, '#03057b'],
    [0.09411764705882353, '#03057d'],
    [0.09803921568627451, '#030580'],
    [0.10196078431372549, '#030582'],
    [0.10588235294117647, '#040584'],
    [0.10980392156862745, '#040586'],
    [0.11372549019607843, '#040588'],
    [0.11764705882352941, '#04058b'],
    [0.12156862745098039, '#05058d'],
    [0.12549019607843137, '#05058f'],
    [0.12941176470588234, '#050591'],
    [0.13333333333333333, '#050594'],
    [0.13725490196078433, '#050596'],
    [0.1411764705882353, '#050598'],
    [0.14509803921568626, '#05059a'],
    [0.14901960784313725, '#05069d'],
    [0.15294117647058825, '#05069f'],
    [0.1568627450980392, '#0506a1'],
    [0.16078431372549018, '#0506a4'],
    [0.16470588235294117, '#0506a6'],
    [0.16862745098039217, '#0506a8'],
    [0.17254901960784313, '#0407aa'],
    [0.1764705882352941, '#0407ad'],
    [0.1803921568627451, '#0407af'],
    [0.1843137254901961, '#0407b1'],
    [0.18823529411764706, '#0408b3'],
    [0.19215686274509802, '#0408b6'],
    [0.19607843137254902, '#0508b8'],
    [0.2, '#0509ba'],
    [0.20392156862745098, '#0609bc'],
    [0.20784313725490194, '#0709be'],
    [0.21176470588235294, '#080ac0'],
    [0.21568627450980393, '#090ac2'],
    [0.2196078431372549, '#0a0bc4'],
    [0.22352941176470587, '#0b0bc6'],
    [0.22745098039215686, '#0d0cc8'],
    [0.23137254901960785, '#0e0cca'],
    [0.23529411764705882, '#100dcc'],
    [0.2392156862745098, '#110dce'],
    [0.24313725490196078, '#130ed0'],
    [0.24705882352941178, '#140ed2'],
    [0.25098039215686274, '#160fd4'],
    [0.2549019607843137, '#1710d6'],
    [0.2588235294117647, '#1910d8'],
    [0.2627450980392157, '#1b11da'],
    [0.26666666666666666, '#1c11db'],
    [0.27058823529411763, '#1e12dd'],
    [0.27450980392156865, '#2013df'],
    [0.2784313725490196, '#2113e0'],
    [0.2823529411764706, '#2314e2'],
    [0.28627450980392155, '#2514e4'],
    [0.2901960784313725, '#2715e5'],
    [0.29411764705882354, '#2a15e7'],
    [0.2980392156862745, '#2c16e8'],
    [0.30196078431372547, '#2e16ea'],
    [0.3058823529411765, '#3117eb'],
    [0.30980392156862746, '#3317ec'],
    [0.3137254901960784, '#3618ee'],
    [0.3176470588235294, '#3918ef'],
    [0.32156862745098036, '#3c19f0'],
    [0.3254901960784314, '#3e19f1'],
    [0.32941176470588235, '#4119f2'],
    [0.3333333333333333, '#4419f3'],
    [0.33725490196078434, '#471af4'],
    [0.3411764705882353, '#4a1af5'],
    [0.34509803921568627, '#4d1af6'],
    [0.34901960784313724, '#501bf6'],
    [0.3529411764705882, '#531bf7'],
    [0.3568627450980392, '#561bf8'],
    [0.3607843137254902, '#591bf8'],
    [0.36470588235294116, '#5c1bf9'],
    [0.3686274509803922, '#5f1bfa'],
    [0.37254901960784315, '#621bfa'],
    [0.3764705882352941, '#651cfa'],
    [0.3803921568627451, '#681cfb'],
    [0.38431372549019605, '#6b1cfb'],
    [0.38823529411764707, '#6e1cfb'],
    [0.39215686274509803, '#711cfb'],
    [0.396078431372549, '#741cfc'],
    [0.4, '#771cfc'],
    [0.403921568627451, '#7a1cfc'],
    [0.40784313725490196, '#7d1cfc'],
    [0.4117647058823529, '#801cfc'],
    [0.4156862745098039, '#831cfc'],
    [0.4196078431372549, '#851cfc'],
    [0.4235294117647059, '#881cfd'],
    [0.42745098039215684, '#8b1cfd'],
    [0.43137254901960786, '#8e1cfd'],
    [0.43529411764705883, '#901cfd'],
    [0.4392156862745098, '#931cfd'],
    [0.44313725490196076, '#951cfd'],
    [0.44705882352941173, '#981cfd'],
    [0.45098039215686275, '#9b1cfd'],
    [0.4549019607843137, '#9d1cfd'],
    [0.4588235294117647, '#a01cfd'],
    [0.4627450980392157, '#a21cfd'],
    [0.4666666666666667, '#a51cfd'],
    [0.47058823529411764, '#a71cfd'],
    [0.4745098039215686, '#aa1cfd'],
    [0.4784313725490196, '#ac1cfd'],
    [0.4823529411764706, '#ae1cfe'],
    [0.48627450980392156, '#b11cfe'],
    [0.49019607843137253, '#b31cfe'],
    [0.49411764705882355, '#b61cfe'],
    [0.4980392156862745, '#b81cfe'],
    [0.5019607843137255, '#ba1cfd'],
    [0.5058823529411764, '#bd1cfd'],
    [0.5098039215686274, '#bf1cfd'],
    [0.5137254901960784, '#c11cfd'],
    [0.5176470588235293, '#c31dfd'],
    [0.5215686274509804, '#c61dfd'],
    [0.5254901960784314, '#c81efd'],
    [0.5294117647058824, '#ca1ffd'],
    [0.5333333333333333, '#cc1ffd'],
    [0.5372549019607843, '#ce20fd'],
    [0.5411764705882353, '#d021fd'],
    [0.5450980392156862, '#d122fd'],
    [0.5490196078431373, '#d323fd'],
    [0.5529411764705883, '#d524fd'],
    [0.5568627450980392, '#d726fd'],
    [0.5607843137254902, '#d927fd'],
    [0.5647058823529412, '#da28fd'],
    [0.5686274509803921, '#dc2afd'],
    [0.5725490196078431, '#dd2bfd'],
    [0.5764705882352941, '#df2cfd'],
    [0.580392156862745, '#e12efd'],
    [0.5843137254901961, '#e230fd'],
    [0.5882352941176471, '#e331fd'],
    [0.592156862745098, '#e533fd'],
    [0.596078431372549, '#e634fd'],
    [0.6, '#e836fe'],
    [0.6039215686274509, '#e938fe'],
    [0.6078431372549019, '#ea3afe'],
    [0.611764705882353, '#eb3bfe'],
    [0.615686274509804, '#ed3dfe'],
    [0.6196078431372549, '#ee3ffe'],
    [0.6235294117647059, '#ef41fe'],
    [0.6274509803921569, '#f043fe'],
    [0.6313725490196078, '#f144fe'],
    [0.6352941176470588, '#f246fe'],
    [0.6392156862745098, '#f348fe'],
    [0.6431372549019607, '#f44afe'],
    [0.6470588235294118, '#f54cfe'],
    [0.6509803921568628, '#f54efe'],
    [0.6549019607843137, '#f650fe'],
    [0.6588235294117647, '#f752fe'],
    [0.6627450980392157, '#f854fe'],
    [0.6666666666666666, '#f856fd'],
    [0.6705882352941176, '#f958fd'],
    [0.6745098039215687, '#fa5afd'],
    [0.6784313725490196, '#fa5cfd'],
    [0.6823529411764706, '#fb5efd'],
    [0.6862745098039216, '#fb61fd'],
    [0.6901960784313725, '#fb63fd'],
    [0.6941176470588235, '#fc65fd'],
    [0.6980392156862745, '#fc67fd'],
    [0.7019607843137254, '#fc69fd'],
    [0.7058823529411764, '#fd6bfd'],
    [0.7098039215686275, '#fd6dfd'],
    [0.7137254901960784, '#fd6ffd'],
    [0.7176470588235294, '#fd72fd'],
    [0.7215686274509804, '#fd74fd'],
    [0.7254901960784313, '#fd76fd'],
    [0.7294117647058823, '#fd78fd'],
    [0.7333333333333333, '#fd7afc'],
    [0.7372549019607844, '#fd7cfc'],
    [0.7411764705882353, '#fd7efc'],
    [0.7450980392156863, '#fd80fc'],
    [0.7490196078431373, '#fe82fc'],
    [0.7529411764705882, '#fe84fc'],
    [0.7568627450980392, '#fe86fc'],
    [0.7607843137254902, '#fe88fc'],
    [0.7647058823529411, '#fe8afc'],
    [0.7686274509803921, '#fe8cfc'],
    [0.7725490196078432, '#fe8efc'],
    [0.7764705882352941, '#fe8ffc'],
    [0.7803921568627451, '#fe91fc'],
    [0.7843137254901961, '#fe93fc'],
    [0.788235294117647, '#fe95fc'],
    [0.792156862745098, '#fe97fc'],
    [0.796078431372549, '#fe98fc'],
    [0.8, '#fe9afc'],
    [0.803921568627451, '#fe9cfc'],
    [0.807843137254902, '#fe9efc'],
    [0.8117647058823529, '#fe9ffc'],
    [0.8156862745098039, '#fea1fc'],
    [0.8196078431372549, '#fea3fc'],
    [0.8235294117647058, '#fea5fd'],
    [0.8274509803921568, '#fea6fd'],
    [0.8313725490196078, '#fea8fd'],
    [0.8352941176470589, '#feaafd'],
    [0.8392156862745098, '#feabfd'],
    [0.8431372549019608, '#feadfd'],
    [0.8470588235294118, '#feaefd'],
    [0.8509803921568627, '#feb0fd'],
    [0.8549019607843137, '#feb2fd'],
    [0.8588235294117647, '#feb3fd'],
    [0.8627450980392157, '#feb5fd'],
    [0.8666666666666667, '#feb7fd'],
    [0.8705882352941177, '#feb8fd'],
    [0.8745098039215686, '#febafd'],
    [0.8784313725490196, '#febbfd'],
    [0.8823529411764706, '#febdfd'],
    [0.8862745098039215, '#febefe'],
    [0.8901960784313725, '#fec0fe'],
    [0.8941176470588235, '#fec2fe'],
    [0.8980392156862745, '#fec3fe'],
    [0.9019607843137255, '#fec5fe'],
    [0.9058823529411765, '#fec6fe'],
    [0.9098039215686274, '#fec8fe'],
    [0.9137254901960784, '#fec9fe'],
    [0.9176470588235294, '#fecbfe'],
    [0.9215686274509803, '#feccfe'],
    [0.9254901960784314, '#fecefe'],
    [0.9294117647058824, '#fecffe'],
    [0.9333333333333333, '#fed1fe'],
    [0.9372549019607843, '#fed2fe'],
    [0.9411764705882353, '#fed4fe'],
    [0.9450980392156862, '#fed5fe'],
    [0.9490196078431372, '#fed7fe'],
    [0.9529411764705882, '#fed8fe'],
    [0.9568627450980391, '#fedafe'],
    [0.9607843137254902, '#fedbfe'],
    [0.9647058823529412, '#feddfe'],
    [0.9686274509803922, '#fedefe'],
    [0.9725490196078431, '#fee0fe'],
    [0.9764705882352941, '#fee1fe'],
    [0.9803921568627451, '#fee3fe'],
    [0.984313725490196, '#fee4fe'],
    [0.9882352941176471, '#fee6fe'],
    [0.9921568627450981, '#fee7fe'],
    [0.996078431372549, '#fee9fe'],
    [1.0, '#feeafe'],
  ],
  CET_L8: [
    [0.0, '#000e5c'],
    [0.00392156862745098, '#000f5e'],
    [0.00784313725490196, '#000f60'],
    [0.011764705882352941, '#011061'],
    [0.01568627450980392, '#011063'],
    [0.0196078431372549, '#011165'],
    [0.023529411764705882, '#021166'],
    [0.027450980392156862, '#031268'],
    [0.03137254901960784, '#031269'],
    [0.03529411764705882, '#04126b'],
    [0.0392156862745098, '#05136c'],
    [0.043137254901960784, '#07136e'],
    [0.047058823529411764, '#081470'],
    [0.050980392156862744, '#091471'],
    [0.054901960784313725, '#0b1473'],
    [0.058823529411764705, '#0d1574'],
    [0.06274509803921569, '#0e1576'],
    [0.06666666666666667, '#101677'],
    [0.07058823529411765, '#121678'],
    [0.07450980392156863, '#13167a'],
    [0.0784313725490196, '#15177b'],
    [0.08235294117647059, '#17177d'],
    [0.08627450980392157, '#19177e'],
    [0.09019607843137255, '#1b187f'],
    [0.09411764705882353, '#1d1880'],
    [0.09803921568627451, '#1f1882'],
    [0.10196078431372549, '#211883'],
    [0.10588235294117647, '#231984'],
    [0.10980392156862745, '#251985'],
    [0.11372549019607843, '#271986'],
    [0.11764705882352941, '#291988'],
    [0.12156862745098039, '#2b1a89'],
    [0.12549019607843137, '#2d1a8a'],
    [0.12941176470588234, '#2f1a8b'],
    [0.13333333333333333, '#311a8c'],
    [0.13725490196078433, '#341a8c'],
    [0.1411764705882353, '#361a8d'],
    [0.14509803921568626, '#391a8e'],
    [0.14901960784313725, '#3b1a8f'],
    [0.15294117647058825, '#3d1a8f'],
    [0.1568627450980392, '#401a90'],
    [0.16078431372549018, '#431a90'],
    [0.16470588235294117, '#451a91'],
    [0.16862745098039217, '#481a91'],
    [0.17254901960784313, '#4b1991'],
    [0.1764705882352941, '#4e1992'],
    [0.1803921568627451, '#501992'],
    [0.1843137254901961, '#531892'],
    [0.18823529411764706, '#561892'],
    [0.19215686274509802, '#591792'],
    [0.19607843137254902, '#5b1792'],
    [0.2, '#5e1692'],
    [0.20392156862745098, '#601692'],
    [0.20784313725490194, '#631692'],
    [0.21176470588235294, '#651592'],
    [0.21568627450980393, '#681592'],
    [0.2196078431372549, '#6a1492'],
    [0.22352941176470587, '#6d1491'],
    [0.22745098039215686, '#6f1391'],
    [0.23137254901960785, '#711391'],
    [0.23529411764705882, '#741291'],
    [0.2392156862745098, '#761291'],
    [0.24313725490196078, '#781191'],
    [0.24705882352941178, '#7a1191'],
    [0.25098039215686274, '#7c1090'],
    [0.2549019607843137, '#7f1090'],
    [0.2588235294117647, '#810f90'],
    [0.2627450980392157, '#830f90'],
    [0.26666666666666666, '#850e90'],
    [0.27058823529411763, '#870e8f'],
    [0.27450980392156865, '#890d8f'],
    [0.2784313725490196, '#8b0d8f'],
    [0.2823529411764706, '#8d0c8e'],
    [0.28627450980392155, '#8f0c8e'],
    [0.2901960784313725, '#910c8e'],
    [0.29411764705882354, '#930b8d'],
    [0.2980392156862745, '#950b8d'],
    [0.30196078431372547, '#970b8d'],
    [0.3058823529411765, '#990a8c'],
    [0.30980392156862746, '#9b0a8c'],
    [0.3137254901960784, '#9d0a8c'],
    [0.3176470588235294, '#9f098b'],
    [0.32156862745098036, '#a1098b'],
    [0.3254901960784314, '#a3098a'],
    [0.32941176470588235, '#a5098a'],
    [0.3333333333333333, '#a7098a'],
    [0.33725490196078434, '#a90989'],
    [0.3411764705882353, '#aa0989'],
    [0.34509803921568627, '#ac0988'],
    [0.34901960784313724, '#ae0988'],
    [0.3529411764705882, '#b00988'],
    [0.3568627450980392, '#b20987'],
    [0.3607843137254902, '#b30a87'],
    [0.36470588235294116, '#b50a86'],
    [0.3686274509803922, '#b70a86'],
    [0.37254901960784315, '#b90b85'],
    [0.3764705882352941, '#ba0b85'],
    [0.3803921568627451, '#bc0c85'],
    [0.38431372549019605, '#be0c84'],
    [0.38823529411764707, '#bf0d84'],
    [0.39215686274509803, '#c10d83'],
    [0.396078431372549, '#c30e83'],
    [0.4, '#c40e82'],
    [0.403921568627451, '#c60f82'],
    [0.40784313725490196, '#c81081'],
    [0.4117647058823529, '#c91181'],
    [0.4156862745098039, '#cb1180'],
    [0.4196078431372549, '#cd1280'],
    [0.4235294117647059, '#ce137f'],
    [0.42745098039215684, '#d0147f'],
    [0.43137254901960786, '#d1167e'],
    [0.43529411764705883, '#d3177e'],
    [0.4392156862745098, '#d4187d'],
    [0.44313725490196076, '#d61a7c'],
    [0.44705882352941173, '#d71b7c'],
    [0.45098039215686275, '#d81d7b'],
    [0.4549019607843137, '#da1e7a'],
    [0.4588235294117647, '#db2079'],
    [0.4627450980392157, '#dc2179'],
    [0.4666666666666667, '#de2378'],
    [0.47058823529411764, '#df2577'],
    [0.4745098039215686, '#e02676'],
    [0.4784313725490196, '#e12876'],
    [0.4823529411764706, '#e22a75'],
    [0.48627450980392156, '#e32c74'],
    [0.49019607843137253, '#e42d73'],
    [0.49411764705882355, '#e62f72'],
    [0.4980392156862745, '#e73171'],
    [0.5019607843137255, '#e83370'],
    [0.5058823529411764, '#e9356f'],
    [0.5098039215686274, '#e9366e'],
    [0.5137254901960784, '#ea386d'],
    [0.5176470588235293, '#eb3a6c'],
    [0.5215686274509804, '#ec3c6b'],
    [0.5254901960784314, '#ed3e6a'],
    [0.5294117647058824, '#ee4069'],
    [0.5333333333333333, '#ee4267'],
    [0.5372549019607843, '#ef4466'],
    [0.5411764705882353, '#f04565'],
    [0.5450980392156862, '#f14764'],
    [0.5490196078431373, '#f14963'],
    [0.5529411764705883, '#f24b62'],
    [0.5568627450980392, '#f24d61'],
    [0.5607843137254902, '#f34f60'],
    [0.5647058823529412, '#f3515f'],
    [0.5686274509803921, '#f4525e'],
    [0.5725490196078431, '#f4545d'],
    [0.5764705882352941, '#f5565c'],
    [0.580392156862745, '#f5585b'],
    [0.5843137254901961, '#f65a5a'],
    [0.5882352941176471, '#f65c59'],
    [0.592156862745098, '#f65e58'],
    [0.596078431372549, '#f75f57'],
    [0.6, '#f76157'],
    [0.6039215686274509, '#f76356'],
    [0.6078431372549019, '#f86555'],
    [0.611764705882353, '#f86754'],
    [0.615686274509804, '#f86953'],
    [0.6196078431372549, '#f86a52'],
    [0.6235294117647059, '#f86c52'],
    [0.6274509803921569, '#f86e51'],
    [0.6313725490196078, '#f87050'],
    [0.6352941176470588, '#f87250'],
    [0.6392156862745098, '#f8744f'],
    [0.6431372549019607, '#f8754e'],
    [0.6470588235294118, '#f8774e'],
    [0.6509803921568628, '#f8794d'],
    [0.6549019607843137, '#f87b4c'],
    [0.6588235294117647, '#f87d4c'],
    [0.6627450980392157, '#f87e4b'],
    [0.6666666666666666, '#f8804a'],
    [0.6705882352941176, '#f8824a'],
    [0.6745098039215687, '#f88349'],
    [0.6784313725490196, '#f88548'],
    [0.6823529411764706, '#f88748'],
    [0.6862745098039216, '#f88847'],
    [0.6901960784313725, '#f88a47'],
    [0.6941176470588235, '#f88c46'],
    [0.6980392156862745, '#f88d45'],
    [0.7019607843137254, '#f98f45'],
    [0.7058823529411764, '#f99044'],
    [0.7098039215686275, '#f99243'],
    [0.7137254901960784, '#f99343'],
    [0.7176470588235294, '#f99542'],
    [0.7215686274509804, '#f99641'],
    [0.7254901960784313, '#f99841'],
    [0.7294117647058823, '#f99940'],
    [0.7333333333333333, '#f99b3f'],
    [0.7372549019607844, '#f99c3f'],
    [0.7411764705882353, '#fa9e3e'],
    [0.7450980392156863, '#fa9f3d'],
    [0.7490196078431373, '#faa03d'],
    [0.7529411764705882, '#faa23c'],
    [0.7568627450980392, '#faa33b'],
    [0.7607843137254902, '#faa53b'],
    [0.7647058823529411, '#fba63a'],
    [0.7686274509803921, '#fba73a'],
    [0.7725490196078432, '#fba939'],
    [0.7764705882352941, '#fbaa39'],
    [0.7803921568627451, '#fbab38'],
    [0.7843137254901961, '#fbad38'],
    [0.788235294117647, '#fcae38'],
    [0.792156862745098, '#fcb037'],
    [0.796078431372549, '#fcb137'],
    [0.8, '#fcb237'],
    [0.803921568627451, '#fcb437'],
    [0.807843137254902, '#fcb537'],
    [0.8117647058823529, '#fcb637'],
    [0.8156862745098039, '#fcb837'],
    [0.8196078431372549, '#fcb937'],
    [0.8235294117647058, '#fcbb37'],
    [0.8274509803921568, '#fcbc38'],
    [0.8313725490196078, '#fcbd38'],
    [0.8352941176470589, '#fcbf38'],
    [0.8392156862745098, '#fcc038'],
    [0.8431372549019608, '#fcc238'],
    [0.8470588235294118, '#fcc339'],
    [0.8509803921568627, '#fcc439'],
    [0.8549019607843137, '#fcc639'],
    [0.8588235294117647, '#fcc73a'],
    [0.8627450980392157, '#fcc83a'],
    [0.8666666666666667, '#fcca3a'],
    [0.8705882352941177, '#fccb3b'],
    [0.8745098039215686, '#fccd3b'],
    [0.8784313725490196, '#fbce3b'],
    [0.8823529411764706, '#fbcf3c'],
    [0.8862745098039215, '#fbd13c'],
    [0.8901960784313725, '#fbd23d'],
    [0.8941176470588235, '#fbd33d'],
    [0.8980392156862745, '#fbd53e'],
    [0.9019607843137255, '#fbd63e'],
    [0.9058823529411765, '#fbd83f'],
    [0.9098039215686274, '#fad93f'],
    [0.9137254901960784, '#fada40'],
    [0.9176470588235294, '#fadc40'],
    [0.9215686274509803, '#fadd41'],
    [0.9254901960784314, '#fade41'],
    [0.9294117647058824, '#f9e042'],
    [0.9333333333333333, '#f9e142'],
    [0.9372549019607843, '#f9e243'],
    [0.9411764705882353, '#f9e444'],
    [0.9450980392156862, '#f9e544'],
    [0.9490196078431372, '#f8e745'],
    [0.9529411764705882, '#f8e845'],
    [0.9568627450980391, '#f8e946'],
    [0.9607843137254902, '#f8eb47'],
    [0.9647058823529412, '#f7ec47'],
    [0.9686274509803922, '#f7ed48'],
    [0.9725490196078431, '#f7ef48'],
    [0.9764705882352941, '#f7f049'],
    [0.9803921568627451, '#f6f14a'],
    [0.984313725490196, '#f6f34a'],
    [0.9882352941176471, '#f6f44b'],
    [0.9921568627450981, '#f5f64c'],
    [0.996078431372549, '#f5f74d'],
    [1.0, '#f5f84d'],
  ],
  CET_L9: [
    [0.0, '#0500ab'],
    [0.00392156862745098, '#0502ac'],
    [0.00784313725490196, '#0605ac'],
    [0.011764705882352941, '#0608ac'],
    [0.01568627450980392, '#070aad'],
    [0.0196078431372549, '#070dad'],
    [0.023529411764705882, '#0710ad'],
    [0.027450980392156862, '#0812ae'],
    [0.03137254901960784, '#0814ae'],
    [0.03529411764705882, '#0916ae'],
    [0.0392156862745098, '#0918af'],
    [0.043137254901960784, '#0a19af'],
    [0.047058823529411764, '#0a1baf'],
    [0.050980392156862744, '#0a1db0'],
    [0.054901960784313725, '#0b1eb0'],
    [0.058823529411764705, '#0b20b0'],
    [0.06274509803921569, '#0b21b0'],
    [0.06666666666666667, '#0b23b1'],
    [0.07058823529411765, '#0c24b1'],
    [0.07450980392156863, '#0c26b1'],
    [0.0784313725490196, '#0c27b1'],
    [0.08235294117647059, '#0c28b2'],
    [0.08627450980392157, '#0c2ab2'],
    [0.09019607843137255, '#0d2bb2'],
    [0.09411764705882353, '#0d2db2'],
    [0.09803921568627451, '#0d2eb3'],
    [0.10196078431372549, '#0d2fb3'],
    [0.10588235294117647, '#0d30b3'],
    [0.10980392156862745, '#0d32b3'],
    [0.11372549019607843, '#0d33b3'],
    [0.11764705882352941, '#0d34b4'],
    [0.12156862745098039, '#0d35b4'],
    [0.12549019607843137, '#0d37b4'],
    [0.12941176470588234, '#0d38b4'],
    [0.13333333333333333, '#0d39b4'],
    [0.13725490196078433, '#0d3ab4'],
    [0.1411764705882353, '#0d3cb4'],
    [0.14509803921568626, '#0d3db4'],
    [0.14901960784313725, '#0d3eb4'],
    [0.15294117647058825, '#0d3fb4'],
    [0.1568627450980392, '#0d40b4'],
    [0.16078431372549018, '#0d42b4'],
    [0.16470588235294117, '#0d43b4'],
    [0.16862745098039217, '#0d44b4'],
    [0.17254901960784313, '#0c45b4'],
    [0.1764705882352941, '#0c46b4'],
    [0.1803921568627451, '#0c47b4'],
    [0.1843137254901961, '#0c49b4'],
    [0.18823529411764706, '#0c4ab4'],
    [0.19215686274509802, '#0b4bb4'],
    [0.19607843137254902, '#0b4cb4'],
    [0.2, '#0b4db4'],
    [0.20392156862745098, '#0a4fb3'],
    [0.20784313725490194, '#0a50b3'],
    [0.21176470588235294, '#0a51b3'],
    [0.21568627450980393, '#0a52b2'],
    [0.2196078431372549, '#0953b2'],
    [0.22352941176470587, '#0955b2'],
    [0.22745098039215686, '#0856b1'],
    [0.23137254901960785, '#0857b1'],
    [0.23529411764705882, '#0858b0'],
    [0.2392156862745098, '#0759af'],
    [0.24313725490196078, '#075baf'],
    [0.24705882352941178, '#065cae'],
    [0.25098039215686274, '#065dad'],
    [0.2549019607843137, '#055eac'],
    [0.2588235294117647, '#0560ab'],
    [0.2627450980392157, '#0561aa'],
    [0.26666666666666666, '#0662a9'],
    [0.27058823529411763, '#0763a7'],
    [0.27450980392156865, '#0864a6'],
    [0.2784313725490196, '#0966a5'],
    [0.2823529411764706, '#0b67a3'],
    [0.28627450980392155, '#0d68a2'],
    [0.2901960784313725, '#0f69a1'],
    [0.29411764705882354, '#106a9f'],
    [0.2980392156862745, '#126b9e'],
    [0.30196078431372547, '#146d9c'],
    [0.3058823529411765, '#166e9a'],
    [0.30980392156862746, '#176f99'],
    [0.3137254901960784, '#197097'],
    [0.3176470588235294, '#1b7195'],
    [0.32156862745098036, '#1c7294'],
    [0.3254901960784314, '#1d7392'],
    [0.32941176470588235, '#1f7490'],
    [0.3333333333333333, '#20768e'],
    [0.33725490196078434, '#21778c'],
    [0.3411764705882353, '#23788b'],
    [0.34509803921568627, '#247989'],
    [0.34901960784313724, '#257a87'],
    [0.3529411764705882, '#267b85'],
    [0.3568627450980392, '#277c83'],
    [0.3607843137254902, '#287d81'],
    [0.36470588235294116, '#297e7f'],
    [0.3686274509803922, '#2a7f7d'],
    [0.37254901960784315, '#2a807b'],
    [0.3764705882352941, '#2b8278'],
    [0.3803921568627451, '#2c8376'],
    [0.38431372549019605, '#2c8474'],
    [0.38823529411764707, '#2d8572'],
    [0.39215686274509803, '#2e8670'],
    [0.396078431372549, '#2e876d'],
    [0.4, '#2e886b'],
    [0.403921568627451, '#2f8969'],
    [0.40784313725490196, '#2f8a66'],
    [0.4117647058823529, '#2f8b64'],
    [0.4156862745098039, '#308c62'],
    [0.4196078431372549, '#308d5f'],
    [0.4235294117647059, '#308f5d'],
    [0.42745098039215684, '#30905a'],
    [0.43137254901960786, '#319158'],
    [0.43529411764705883, '#319256'],
    [0.4392156862745098, '#319354'],
    [0.44313725490196076, '#329452'],
    [0.44705882352941173, '#329550'],
    [0.45098039215686275, '#33964e'],
    [0.4549019607843137, '#34974c'],
    [0.4588235294117647, '#35974a'],
    [0.4627450980392157, '#359848'],
    [0.4666666666666667, '#369946'],
    [0.47058823529411764, '#379a44'],
    [0.4745098039215686, '#389b43'],
    [0.4784313725490196, '#399c41'],
    [0.4823529411764706, '#3a9d3f'],
    [0.48627450980392156, '#3b9e3d'],
    [0.49019607843137253, '#3d9f3c'],
    [0.49411764705882355, '#3ea03a'],
    [0.4980392156862745, '#3fa039'],
    [0.5019607843137255, '#41a137'],
    [0.5058823529411764, '#42a235'],
    [0.5098039215686274, '#43a334'],
    [0.5137254901960784, '#45a432'],
    [0.5176470588235293, '#46a531'],
    [0.5215686274509804, '#48a62f'],
    [0.5254901960784314, '#49a62e'],
    [0.5294117647058824, '#4ba72c'],
    [0.5333333333333333, '#4da82b'],
    [0.5372549019607843, '#4ea92a'],
    [0.5411764705882353, '#50aa28'],
    [0.5450980392156862, '#52aa27'],
    [0.5490196078431373, '#54ab25'],
    [0.5529411764705883, '#55ac24'],
    [0.5568627450980392, '#57ad23'],
    [0.5607843137254902, '#59ad21'],
    [0.5647058823529412, '#5bae20'],
    [0.5686274509803921, '#5daf1e'],
    [0.5725490196078431, '#5eb01d'],
    [0.5764705882352941, '#60b01c'],
    [0.580392156862745, '#62b11a'],
    [0.5843137254901961, '#64b219'],
    [0.5882352941176471, '#66b218'],
    [0.592156862745098, '#68b316'],
    [0.596078431372549, '#6ab415'],
    [0.6, '#6cb514'],
    [0.6039215686274509, '#6eb512'],
    [0.6078431372549019, '#70b611'],
    [0.611764705882353, '#72b710'],
    [0.615686274509804, '#74b70e'],
    [0.6196078431372549, '#76b80d'],
    [0.6235294117647059, '#79b90c'],
    [0.6274509803921569, '#7bb90a'],
    [0.6313725490196078, '#7dba09'],
    [0.6352941176470588, '#7fbb08'],
    [0.6392156862745098, '#81bb07'],
    [0.6431372549019607, '#83bc06'],
    [0.6470588235294118, '#85bc05'],
    [0.6509803921568628, '#88bd04'],
    [0.6549019607843137, '#8abe04'],
    [0.6588235294117647, '#8cbe03'],
    [0.6627450980392157, '#8ebf03'],
    [0.6666666666666666, '#90bf02'],
    [0.6705882352941176, '#93c002'],
    [0.6745098039215687, '#95c102'],
    [0.6784313725490196, '#97c102'],
    [0.6823529411764706, '#99c202'],
    [0.6862745098039216, '#9bc202'],
    [0.6901960784313725, '#9ec302'],
    [0.6941176470588235, '#a0c302'],
    [0.6980392156862745, '#a2c402'],
    [0.7019607843137254, '#a4c502'],
    [0.7058823529411764, '#a6c503'],
    [0.7098039215686275, '#a8c603'],
    [0.7137254901960784, '#aac603'],
    [0.7176470588235294, '#acc703'],
    [0.7215686274509804, '#aec704'],
    [0.7254901960784313, '#b0c804'],
    [0.7294117647058823, '#b2c805'],
    [0.7333333333333333, '#b4c905'],
    [0.7372549019607844, '#b6ca06'],
    [0.7411764705882353, '#b8ca07'],
    [0.7450980392156863, '#bacb07'],
    [0.7490196078431373, '#bccb08'],
    [0.7529411764705882, '#becc09'],
    [0.7568627450980392, '#c0cd0a'],
    [0.7607843137254902, '#c2cd0b'],
    [0.7647058823529411, '#c4ce0c'],
    [0.7686274509803921, '#c6ce0d'],
    [0.7725490196078432, '#c8cf0e'],
    [0.7764705882352941, '#c9d00f'],
    [0.7803921568627451, '#cbd010'],
    [0.7843137254901961, '#cdd111'],
    [0.788235294117647, '#cfd113'],
    [0.792156862745098, '#d0d214'],
    [0.796078431372549, '#d2d315'],
    [0.8, '#d4d316'],
    [0.803921568627451, '#d6d417'],
    [0.807843137254902, '#d7d419'],
    [0.8117647058823529, '#d9d51a'],
    [0.8156862745098039, '#dbd61b'],
    [0.8196078431372549, '#dcd61c'],
    [0.8235294117647058, '#ded71e'],
    [0.8274509803921568, '#dfd81f'],
    [0.8313725490196078, '#e1d820'],
    [0.8352941176470589, '#e2d922'],
    [0.8392156862745098, '#e4da23'],
    [0.8431372549019608, '#e5da25'],
    [0.8470588235294118, '#e7db26'],
    [0.8509803921568627, '#e8dc28'],
    [0.8549019607843137, '#e9dd29'],
    [0.8588235294117647, '#ebdd2b'],
    [0.8627450980392157, '#ecde2d'],
    [0.8666666666666667, '#eddf2e'],
    [0.8705882352941177, '#eee030'],
    [0.8745098039215686, '#efe032'],
    [0.8784313725490196, '#f0e134'],
    [0.8823529411764706, '#f1e236'],
    [0.8862745098039215, '#f2e338'],
    [0.8901960784313725, '#f3e43a'],
    [0.8941176470588235, '#f4e53c'],
    [0.8980392156862745, '#f4e63f'],
    [0.9019607843137255, '#f5e741'],
    [0.9058823529411765, '#f5e844'],
    [0.9098039215686274, '#f6e847'],
    [0.9137254901960784, '#f7e94b'],
    [0.9176470588235294, '#f7ea4f'],
    [0.9215686274509803, '#f8eb53'],
    [0.9254901960784314, '#f8ec57'],
    [0.9294117647058824, '#f9ed5c'],
    [0.9333333333333333, '#faee61'],
    [0.9372549019607843, '#faef66'],
    [0.9411764705882353, '#fbef6c'],
    [0.9450980392156862, '#fcf072'],
    [0.9490196078431372, '#fcf179'],
    [0.9529411764705882, '#fdf27f'],
    [0.9568627450980391, '#fdf286'],
    [0.9607843137254902, '#fef38e'],
    [0.9647058823529412, '#fef496'],
    [0.9686274509803922, '#fff49e'],
    [0.9725490196078431, '#fff5a7'],
    [0.9764705882352941, '#fff6b1'],
    [0.9803921568627451, '#fff6bb'],
    [0.984313725490196, '#fef7c6'],
    [0.9882352941176471, '#fdf7d1'],
    [0.9921568627450981, '#fcf8dd'],
    [0.996078431372549, '#fbf8eb'],
    [1.0, '#f9f9f9'],
  ],
  CET_R1: [
    [0.0, '#002ff5'],
    [0.00392156862745098, '#0033f1'],
    [0.00784313725490196, '#0037ee'],
    [0.011764705882352941, '#003aea'],
    [0.01568627450980392, '#023ee7'],
    [0.0196078431372549, '#0941e3'],
    [0.023529411764705882, '#0e44e0'],
    [0.027450980392156862, '#1147dd'],
    [0.03137254901960784, '#144ad9'],
    [0.03529411764705882, '#164dd6'],
    [0.0392156862745098, '#174fd2'],
    [0.043137254901960784, '#1852cf'],
    [0.047058823529411764, '#1954cb'],
    [0.050980392156862744, '#1957c8'],
    [0.054901960784313725, '#1959c4'],
    [0.058823529411764705, '#195bc1'],
    [0.06274509803921569, '#195ebe'],
    [0.06666666666666667, '#1860ba'],
    [0.07058823529411765, '#1862b7'],
    [0.07450980392156863, '#1764b3'],
    [0.0784313725490196, '#1766b0'],
    [0.08235294117647059, '#1768ad'],
    [0.08627450980392157, '#176aaa'],
    [0.09019607843137255, '#176ca6'],
    [0.09411764705882353, '#186ea3'],
    [0.09803921568627451, '#196fa0'],
    [0.10196078431372549, '#1a719d'],
    [0.10588235294117647, '#1c739a'],
    [0.10980392156862745, '#1d7496'],
    [0.11372549019607843, '#1f7693'],
    [0.11764705882352941, '#227790'],
    [0.12156862745098039, '#24788d'],
    [0.12549019607843137, '#267a8a'],
    [0.12941176470588234, '#287b87'],
    [0.13333333333333333, '#2a7d84'],
    [0.13725490196078433, '#2d7e81'],
    [0.1411764705882353, '#2f7f7e'],
    [0.14509803921568626, '#31817b'],
    [0.14901960784313725, '#328278'],
    [0.15294117647058825, '#348375'],
    [0.1568627450980392, '#368472'],
    [0.16078431372549018, '#37866f'],
    [0.16470588235294117, '#38876c'],
    [0.16862745098039217, '#3a8869'],
    [0.17254901960784313, '#3b8a66'],
    [0.1764705882352941, '#3c8b63'],
    [0.1803921568627451, '#3d8c60'],
    [0.1843137254901961, '#3d8e5d'],
    [0.18823529411764706, '#3e8f59'],
    [0.19215686274509802, '#3e9056'],
    [0.19607843137254902, '#3f9253'],
    [0.2, '#3f9350'],
    [0.20392156862745098, '#40944c'],
    [0.20784313725490194, '#409549'],
    [0.21176470588235294, '#409746'],
    [0.21568627450980393, '#409842'],
    [0.2196078431372549, '#41993f'],
    [0.22352941176470587, '#419b3b'],
    [0.22745098039215686, '#419c38'],
    [0.23137254901960785, '#429d35'],
    [0.23529411764705882, '#429e31'],
    [0.2392156862745098, '#439f2e'],
    [0.24313725490196078, '#44a12b'],
    [0.24705882352941178, '#45a227'],
    [0.25098039215686274, '#47a324'],
    [0.2549019607843137, '#48a421'],
    [0.2588235294117647, '#4aa51f'],
    [0.2627450980392157, '#4ca61c'],
    [0.26666666666666666, '#4fa71a'],
    [0.27058823529411763, '#51a817'],
    [0.27450980392156865, '#54a915'],
    [0.2784313725490196, '#57aa14'],
    [0.2823529411764706, '#59ab13'],
    [0.28627450980392155, '#5cab11'],
    [0.2901960784313725, '#5fac11'],
    [0.29411764705882354, '#62ad10'],
    [0.2980392156862745, '#66ae10'],
    [0.30196078431372547, '#69ae10'],
    [0.3058823529411765, '#6caf10'],
    [0.30980392156862746, '#6fb010'],
    [0.3137254901960784, '#72b110'],
    [0.3176470588235294, '#75b110'],
    [0.32156862745098036, '#78b211'],
    [0.3254901960784314, '#7bb311'],
    [0.32941176470588235, '#7eb412'],
    [0.3333333333333333, '#81b412'],
    [0.33725490196078434, '#84b513'],
    [0.3411764705882353, '#87b613'],
    [0.34509803921568627, '#8ab613'],
    [0.34901960784313724, '#8db714'],
    [0.3529411764705882, '#90b814'],
    [0.3568627450980392, '#92b815'],
    [0.3607843137254902, '#95b915'],
    [0.36470588235294116, '#98ba16'],
    [0.3686274509803922, '#9bbb16'],
    [0.37254901960784315, '#9ebb17'],
    [0.3764705882352941, '#a0bc17'],
    [0.3803921568627451, '#a3bd18'],
    [0.38431372549019605, '#a6bd18'],
    [0.38823529411764707, '#a9be19'],
    [0.39215686274509803, '#abbe19'],
    [0.396078431372549, '#aebf19'],
    [0.4, '#b1c01a'],
    [0.403921568627451, '#b4c01a'],
    [0.40784313725490196, '#b6c11b'],
    [0.4117647058823529, '#b9c21b'],
    [0.4156862745098039, '#bcc21c'],
    [0.4196078431372549, '#bec31c'],
    [0.4235294117647059, '#c1c41d'],
    [0.42745098039215684, '#c4c41d'],
    [0.43137254901960786, '#c7c51e'],
    [0.43529411764705883, '#c9c51e'],
    [0.4392156862745098, '#ccc61e'],
    [0.44313725490196076, '#cec71f'],
    [0.44705882352941173, '#d1c71f'],
    [0.45098039215686275, '#d4c820'],
    [0.4549019607843137, '#d6c820'],
    [0.4588235294117647, '#d9c921'],
    [0.4627450980392157, '#dbc921'],
    [0.4666666666666667, '#deca21'],
    [0.47058823529411764, '#e0ca22'],
    [0.4745098039215686, '#e3ca22'],
    [0.4784313725490196, '#e5ca23'],
    [0.4823529411764706, '#e7cb23'],
    [0.48627450980392156, '#eacb23'],
    [0.49019607843137253, '#eccb23'],
    [0.49411764705882355, '#edca24'],
    [0.4980392156862745, '#efca24'],
    [0.5019607843137255, '#f1ca24'],
    [0.5058823529411764, '#f2c924'],
    [0.5098039215686274, '#f4c924'],
    [0.5137254901960784, '#f5c824'],
    [0.5176470588235293, '#f6c724'],
    [0.5215686274509804, '#f7c624'],
    [0.5254901960784314, '#f7c524'],
    [0.5294117647058824, '#f8c423'],
    [0.5333333333333333, '#f8c323'],
    [0.5372549019607843, '#f9c123'],
    [0.5411764705882353, '#f9c023'],
    [0.5450980392156862, '#f9be22'],
    [0.5490196078431373, '#f9bd22'],
    [0.5529411764705883, '#f9bc22'],
    [0.5568627450980392, '#f9ba22'],
    [0.5607843137254902, '#f9b921'],
    [0.5647058823529412, '#f9b721'],
    [0.5686274509803921, '#f9b521'],
    [0.5725490196078431, '#f9b420'],
    [0.5764705882352941, '#f9b220'],
    [0.580392156862745, '#f9b120'],
    [0.5843137254901961, '#f9af1f'],
    [0.5882352941176471, '#f9ae1f'],
    [0.592156862745098, '#f9ac1f'],
    [0.596078431372549, '#f8aa1e'],
    [0.6, '#f8a91e'],
    [0.6039215686274509, '#f8a71e'],
    [0.6078431372549019, '#f8a61d'],
    [0.611764705882353, '#f8a41d'],
    [0.615686274509804, '#f8a21d'],
    [0.6196078431372549, '#f8a11c'],
    [0.6235294117647059, '#f89f1c'],
    [0.6274509803921569, '#f79e1c'],
    [0.6313725490196078, '#f79c1b'],
    [0.6352941176470588, '#f79a1b'],
    [0.6392156862745098, '#f7991b'],
    [0.6431372549019607, '#f7971b'],
    [0.6470588235294118, '#f6961a'],
    [0.6509803921568628, '#f6941a'],
    [0.6549019607843137, '#f6921a'],
    [0.6588235294117647, '#f69119'],
    [0.6627450980392157, '#f68f19'],
    [0.6666666666666666, '#f68d19'],
    [0.6705882352941176, '#f58c19'],
    [0.6745098039215687, '#f58a18'],
    [0.6784313725490196, '#f58818'],
    [0.6823529411764706, '#f58718'],
    [0.6862745098039216, '#f48517'],
    [0.6901960784313725, '#f48317'],
    [0.6941176470588235, '#f48117'],
    [0.6980392156862745, '#f48017'],
    [0.7019607843137254, '#f47e16'],
    [0.7058823529411764, '#f37c16'],
    [0.7098039215686275, '#f37b16'],
    [0.7137254901960784, '#f37916'],
    [0.7176470588235294, '#f37715'],
    [0.7215686274509804, '#f27515'],
    [0.7254901960784313, '#f27315'],
    [0.7294117647058823, '#f27215'],
    [0.7333333333333333, '#f17015'],
    [0.7372549019607844, '#f16e14'],
    [0.7411764705882353, '#f16c14'],
    [0.7450980392156863, '#f16a14'],
    [0.7490196078431373, '#f06914'],
    [0.7529411764705882, '#f06715'],
    [0.7568627450980392, '#f06515'],
    [0.7607843137254902, '#f06315'],
    [0.7647058823529411, '#f06116'],
    [0.7686274509803921, '#ef6017'],
    [0.7725490196078432, '#ef5e18'],
    [0.7764705882352941, '#ef5d19'],
    [0.7803921568627451, '#ef5b1b'],
    [0.7843137254901961, '#ef5a1c'],
    [0.788235294117647, '#ef591e'],
    [0.792156862745098, '#ef5821'],
    [0.796078431372549, '#f05723'],
    [0.8, '#f05626'],
    [0.803921568627451, '#f05629'],
    [0.807843137254902, '#f1562c'],
    [0.8117647058823529, '#f1552f'],
    [0.8156862745098039, '#f25632'],
    [0.8196078431372549, '#f25636'],
    [0.8235294117647058, '#f35639'],
    [0.8274509803921568, '#f3573d'],
    [0.8313725490196078, '#f45841'],
    [0.8352941176470589, '#f55945'],
    [0.8392156862745098, '#f65a49'],
    [0.8431372549019608, '#f65b4d'],
    [0.8470588235294118, '#f75c51'],
    [0.8509803921568627, '#f85d55'],
    [0.8549019607843137, '#f85e59'],
    [0.8588235294117647, '#f9605d'],
    [0.8627450980392157, '#fa6162'],
    [0.8666666666666667, '#fa6266'],
    [0.8705882352941177, '#fb636a'],
    [0.8745098039215686, '#fb656e'],
    [0.8784313725490196, '#fc6672'],
    [0.8823529411764706, '#fc6777'],
    [0.8862745098039215, '#fd697b'],
    [0.8901960784313725, '#fd6a7f'],
    [0.8941176470588235, '#fe6c83'],
    [0.8980392156862745, '#fe6d88'],
    [0.9019607843137255, '#ff6e8c'],
    [0.9058823529411765, '#ff7090'],
    [0.9098039215686274, '#ff7194'],
    [0.9137254901960784, '#ff7299'],
    [0.9176470588235294, '#ff749d'],
    [0.9215686274509803, '#ff75a1'],
    [0.9254901960784314, '#ff77a5'],
    [0.9294117647058824, '#ff78aa'],
    [0.9333333333333333, '#ff79ae'],
    [0.9372549019607843, '#ff7bb2'],
    [0.9411764705882353, '#ff7cb7'],
    [0.9450980392156862, '#ff7dbb'],
    [0.9490196078431372, '#ff7fc0'],
    [0.9529411764705882, '#ff80c4'],
    [0.9568627450980391, '#ff82c8'],
    [0.9607843137254902, '#ff83cd'],
    [0.9647058823529412, '#ff84d1'],
    [0.9686274509803922, '#ff86d6'],
    [0.9725490196078431, '#ff87da'],
    [0.9764705882352941, '#ff89de'],
    [0.9803921568627451, '#ff8ae3'],
    [0.984313725490196, '#ff8ce7'],
    [0.9882352941176471, '#fe8dec'],
    [0.9921568627450981, '#fe8ef0'],
    [0.996078431372549, '#fd90f5'],
    [1.0, '#fd91fa'],
  ],
  CET_R2: [
    [0.0, '#0033f5'],
    [0.00392156862745098, '#0036f2'],
    [0.00784313725490196, '#0039ef'],
    [0.011764705882352941, '#003cec'],
    [0.01568627450980392, '#003eea'],
    [0.0196078431372549, '#0041e7'],
    [0.023529411764705882, '#0043e4'],
    [0.027450980392156862, '#0046e1'],
    [0.03137254901960784, '#0048de'],
    [0.03529411764705882, '#004adc'],
    [0.0392156862745098, '#004cd9'],
    [0.043137254901960784, '#004ed6'],
    [0.047058823529411764, '#0050d3'],
    [0.050980392156862744, '#0052d0'],
    [0.054901960784313725, '#0054ce'],
    [0.058823529411764705, '#0056cb'],
    [0.06274509803921569, '#0058c8'],
    [0.06666666666666667, '#005ac5'],
    [0.07058823529411765, '#005cc2'],
    [0.07450980392156863, '#005ec0'],
    [0.0784313725490196, '#005fbd'],
    [0.08235294117647059, '#0061ba'],
    [0.08627450980392157, '#0063b7'],
    [0.09019607843137255, '#0065b5'],
    [0.09411764705882353, '#0066b2'],
    [0.09803921568627451, '#0068af'],
    [0.10196078431372549, '#0069ac'],
    [0.10588235294117647, '#006baa'],
    [0.10980392156862745, '#006da7'],
    [0.11372549019607843, '#006ea4'],
    [0.11764705882352941, '#006fa2'],
    [0.12156862745098039, '#00719f'],
    [0.12549019607843137, '#00729d'],
    [0.12941176470588234, '#00739a'],
    [0.13333333333333333, '#007597'],
    [0.13725490196078433, '#017695'],
    [0.1411764705882353, '#077793'],
    [0.14509803921568626, '#0e7890'],
    [0.14901960784313725, '#13798e'],
    [0.15294117647058825, '#187a8b'],
    [0.1568627450980392, '#1c7b89'],
    [0.16078431372549018, '#1f7c87'],
    [0.16470588235294117, '#237d84'],
    [0.16862745098039217, '#267e82'],
    [0.17254901960784313, '#287f7f'],
    [0.1764705882352941, '#2b807d'],
    [0.1803921568627451, '#2d817b'],
    [0.1843137254901961, '#2f8278'],
    [0.18823529411764706, '#318376'],
    [0.19215686274509802, '#328473'],
    [0.19607843137254902, '#348571'],
    [0.2, '#35866f'],
    [0.20392156862745098, '#36876c'],
    [0.20784313725490194, '#37886a'],
    [0.21176470588235294, '#388967'],
    [0.21568627450980393, '#398a65'],
    [0.2196078431372549, '#3a8b62'],
    [0.22352941176470587, '#3b8c60'],
    [0.22745098039215686, '#3c8e5d'],
    [0.23137254901960785, '#3c8f5b'],
    [0.23529411764705882, '#3d9058'],
    [0.2392156862745098, '#3d9155'],
    [0.24313725490196078, '#3e9253'],
    [0.24705882352941178, '#3e9350'],
    [0.25098039215686274, '#3e944d'],
    [0.2549019607843137, '#3e954a'],
    [0.2588235294117647, '#3e9647'],
    [0.2627450980392157, '#3f9745'],
    [0.26666666666666666, '#3f9842'],
    [0.27058823529411763, '#3e993e'],
    [0.27450980392156865, '#3e9a3b'],
    [0.2784313725490196, '#3e9b38'],
    [0.2823529411764706, '#3e9c35'],
    [0.28627450980392155, '#3e9d32'],
    [0.2901960784313725, '#3e9e2e'],
    [0.29411764705882354, '#3e9f2b'],
    [0.2980392156862745, '#3fa027'],
    [0.30196078431372547, '#3fa124'],
    [0.3058823529411765, '#40a221'],
    [0.30980392156862746, '#41a31d'],
    [0.3137254901960784, '#42a41a'],
    [0.3176470588235294, '#43a518'],
    [0.32156862745098036, '#45a615'],
    [0.3254901960784314, '#47a713'],
    [0.32941176470588235, '#4aa711'],
    [0.3333333333333333, '#4ca810'],
    [0.33725490196078434, '#4fa90f'],
    [0.3411764705882353, '#51a90e'],
    [0.34509803921568627, '#54aa0e'],
    [0.34901960784313724, '#57ab0e'],
    [0.3529411764705882, '#5aab0e'],
    [0.3568627450980392, '#5cac0f'],
    [0.3607843137254902, '#5fad0f'],
    [0.36470588235294116, '#62ad10'],
    [0.3686274509803922, '#65ae10'],
    [0.37254901960784315, '#67ae10'],
    [0.3764705882352941, '#6aaf11'],
    [0.3803921568627451, '#6cb011'],
    [0.38431372549019605, '#6fb012'],
    [0.38823529411764707, '#71b112'],
    [0.39215686274509803, '#74b113'],
    [0.396078431372549, '#76b213'],
    [0.4, '#79b214'],
    [0.403921568627451, '#7bb314'],
    [0.40784313725490196, '#7eb415'],
    [0.4117647058823529, '#80b415'],
    [0.4156862745098039, '#83b516'],
    [0.4196078431372549, '#85b516'],
    [0.4235294117647059, '#87b617'],
    [0.42745098039215684, '#8ab617'],
    [0.43137254901960786, '#8cb718'],
    [0.43529411764705883, '#8eb818'],
    [0.4392156862745098, '#91b819'],
    [0.44313725490196076, '#93b919'],
    [0.44705882352941173, '#95b91a'],
    [0.45098039215686275, '#97ba1a'],
    [0.4549019607843137, '#9aba1a'],
    [0.4588235294117647, '#9cbb1b'],
    [0.4627450980392157, '#9ebb1b'],
    [0.4666666666666667, '#a0bc1c'],
    [0.47058823529411764, '#a3bc1c'],
    [0.4745098039215686, '#a5bd1d'],
    [0.4784313725490196, '#a7be1d'],
    [0.4823529411764706, '#a9be1e'],
    [0.48627450980392156, '#acbf1e'],
    [0.49019607843137253, '#aebf1f'],
    [0.49411764705882355, '#b0c01f'],
    [0.4980392156862745, '#b2c01f'],
    [0.5019607843137255, '#b4c120'],
    [0.5058823529411764, '#b7c120'],
    [0.5098039215686274, '#b9c221'],
    [0.5137254901960784, '#bbc221'],
    [0.5176470588235293, '#bdc322'],
    [0.5215686274509804, '#bfc322'],
    [0.5254901960784314, '#c1c423'],
    [0.5294117647058824, '#c4c423'],
    [0.5333333333333333, '#c6c523'],
    [0.5372549019607843, '#c8c524'],
    [0.5411764705882353, '#cac624'],
    [0.5450980392156862, '#ccc625'],
    [0.5490196078431373, '#cec725'],
    [0.5529411764705883, '#d0c726'],
    [0.5568627450980392, '#d3c826'],
    [0.5607843137254902, '#d5c827'],
    [0.5647058823529412, '#d7c927'],
    [0.5686274509803921, '#d9c927'],
    [0.5725490196078431, '#dbca28'],
    [0.5764705882352941, '#ddca28'],
    [0.580392156862745, '#dfcb29'],
    [0.5843137254901961, '#e2cb29'],
    [0.5882352941176471, '#e4cb2a'],
    [0.592156862745098, '#e6cc2a'],
    [0.596078431372549, '#e8cc2a'],
    [0.6, '#eacc2b'],
    [0.6039215686274509, '#eccd2b'],
    [0.6078431372549019, '#eecd2b'],
    [0.611764705882353, '#f0cd2c'],
    [0.615686274509804, '#f1cd2c'],
    [0.6196078431372549, '#f3cd2c'],
    [0.6235294117647059, '#f5cc2c'],
    [0.6274509803921569, '#f6cc2c'],
    [0.6313725490196078, '#f7cb2c'],
    [0.6352941176470588, '#f8ca2b'],
    [0.6392156862745098, '#f9c92b'],
    [0.6431372549019607, '#fac82b'],
    [0.6470588235294118, '#fac72a'],
    [0.6509803921568628, '#fbc62a'],
    [0.6549019607843137, '#fbc529'],
    [0.6588235294117647, '#fbc429'],
    [0.6627450980392157, '#fcc228'],
    [0.6666666666666666, '#fcc128'],
    [0.6705882352941176, '#fcc027'],
    [0.6745098039215687, '#fcbe27'],
    [0.6784313725490196, '#fcbd26'],
    [0.6823529411764706, '#fdbc26'],
    [0.6862745098039216, '#fdba25'],
    [0.6901960784313725, '#fdb925'],
    [0.6941176470588235, '#fdb824'],
    [0.6980392156862745, '#fdb623'],
    [0.7019607843137254, '#fdb523'],
    [0.7058823529411764, '#feb422'],
    [0.7098039215686275, '#feb222'],
    [0.7137254901960784, '#feb121'],
    [0.7176470588235294, '#feaf21'],
    [0.7215686274509804, '#feae20'],
    [0.7254901960784313, '#fead20'],
    [0.7294117647058823, '#feab1f'],
    [0.7333333333333333, '#feaa1e'],
    [0.7372549019607844, '#fea81e'],
    [0.7411764705882353, '#fea71d'],
    [0.7450980392156863, '#ffa61d'],
    [0.7490196078431373, '#ffa41c'],
    [0.7529411764705882, '#ffa31c'],
    [0.7568627450980392, '#ffa11b'],
    [0.7607843137254902, '#ffa01a'],
    [0.7647058823529411, '#ff9f1a'],
    [0.7686274509803921, '#ff9d19'],
    [0.7725490196078432, '#ff9c19'],
    [0.7764705882352941, '#ff9a18'],
    [0.7803921568627451, '#ff9918'],
    [0.7843137254901961, '#ff9717'],
    [0.788235294117647, '#ff9617'],
    [0.792156862745098, '#ff9416'],
    [0.796078431372549, '#ff9315'],
    [0.8, '#ff9215'],
    [0.803921568627451, '#ff9014'],
    [0.807843137254902, '#ff8f14'],
    [0.8117647058823529, '#ff8d13'],
    [0.8156862745098039, '#ff8c13'],
    [0.8196078431372549, '#ff8a12'],
    [0.8235294117647058, '#ff8911'],
    [0.8274509803921568, '#ff8711'],
    [0.8313725490196078, '#ff8510'],
    [0.8352941176470589, '#ff8410'],
    [0.8392156862745098, '#ff820f'],
    [0.8431372549019608, '#ff810e'],
    [0.8470588235294118, '#ff7f0e'],
    [0.8509803921568627, '#ff7e0d'],
    [0.8549019607843137, '#ff7c0d'],
    [0.8588235294117647, '#ff7b0c'],
    [0.8627450980392157, '#ff790b'],
    [0.8666666666666667, '#ff770b'],
    [0.8705882352941177, '#ff760a'],
    [0.8745098039215686, '#ff740a'],
    [0.8784313725490196, '#ff7209'],
    [0.8823529411764706, '#ff7108'],
    [0.8862745098039215, '#ff6f08'],
    [0.8901960784313725, '#ff6d07'],
    [0.8941176470588235, '#ff6c07'],
    [0.8980392156862745, '#ff6a06'],
    [0.9019607843137255, '#ff6806'],
    [0.9058823529411765, '#ff6605'],
    [0.9098039215686274, '#ff6405'],
    [0.9137254901960784, '#ff6304'],
    [0.9176470588235294, '#fe6104'],
    [0.9215686274509803, '#fe5f03'],
    [0.9254901960784314, '#fe5d03'],
    [0.9294117647058824, '#fe5b03'],
    [0.9333333333333333, '#fe5902'],
    [0.9372549019607843, '#fe5702'],
    [0.9411764705882353, '#fe5501'],
    [0.9450980392156862, '#fe5301'],
    [0.9490196078431372, '#fe5101'],
    [0.9529411764705882, '#fe4f00'],
    [0.9568627450980391, '#fe4d00'],
    [0.9607843137254902, '#fd4a00'],
    [0.9647058823529412, '#fd4800'],
    [0.9686274509803922, '#fd4600'],
    [0.9725490196078431, '#fd4300'],
    [0.9764705882352941, '#fd4100'],
    [0.9803921568627451, '#fd3e00'],
    [0.984313725490196, '#fd3c00'],
    [0.9882352941176471, '#fc3900'],
    [0.9921568627450981, '#fc3600'],
    [0.996078431372549, '#fc3300'],
    [1.0, '#fc3000'],
  ],
  CET_R3: [
    [0.0, '#085cf8'],
    [0.00392156862745098, '#0e5ef4'],
    [0.00784313725490196, '#1360f0'],
    [0.011764705882352941, '#1663ec'],
    [0.01568627450980392, '#1965e8'],
    [0.0196078431372549, '#1a67e5'],
    [0.023529411764705882, '#1c69e1'],
    [0.027450980392156862, '#1d6bdd'],
    [0.03137254901960784, '#1d6dd9'],
    [0.03529411764705882, '#1d6fd5'],
    [0.0392156862745098, '#1d71d2'],
    [0.043137254901960784, '#1c73ce'],
    [0.047058823529411764, '#1b75ca'],
    [0.050980392156862744, '#1a77c6'],
    [0.054901960784313725, '#1979c3'],
    [0.058823529411764705, '#177abf'],
    [0.06274509803921569, '#167cbb'],
    [0.06666666666666667, '#147eb7'],
    [0.07058823529411765, '#1380b3'],
    [0.07450980392156863, '#1281af'],
    [0.0784313725490196, '#1283ab'],
    [0.08235294117647059, '#1284a7'],
    [0.08627450980392157, '#1486a3'],
    [0.09019607843137255, '#16879f'],
    [0.09411764705882353, '#19899b'],
    [0.09803921568627451, '#1c8a97'],
    [0.10196078431372549, '#208b93'],
    [0.10588235294117647, '#238c8f'],
    [0.10980392156862745, '#268e8a'],
    [0.11372549019607843, '#298f86'],
    [0.11764705882352941, '#2c9082'],
    [0.12156862745098039, '#2f917d'],
    [0.12549019607843137, '#319279'],
    [0.12941176470588234, '#339374'],
    [0.13333333333333333, '#359570'],
    [0.13725490196078433, '#36966b'],
    [0.1411764705882353, '#389767'],
    [0.14509803921568626, '#399862'],
    [0.14901960784313725, '#39995d'],
    [0.15294117647058825, '#3a9a59'],
    [0.1568627450980392, '#3b9b54'],
    [0.16078431372549018, '#3b9d4f'],
    [0.16470588235294117, '#3b9e4a'],
    [0.16862745098039217, '#3c9f46'],
    [0.17254901960784313, '#3ca041'],
    [0.1764705882352941, '#3ca13d'],
    [0.1803921568627451, '#3da238'],
    [0.1843137254901961, '#3ea334'],
    [0.18823529411764706, '#3fa431'],
    [0.19215686274509802, '#40a52d'],
    [0.19607843137254902, '#42a62a'],
    [0.2, '#44a627'],
    [0.20392156862745098, '#46a725'],
    [0.20784313725490194, '#49a823'],
    [0.21176470588235294, '#4ba922'],
    [0.21568627450980393, '#4ea921'],
    [0.2196078431372549, '#51aa20'],
    [0.22352941176470587, '#53ab1f'],
    [0.22745098039215686, '#56ab1f'],
    [0.23137254901960785, '#59ac1e'],
    [0.23529411764705882, '#5cad1e'],
    [0.2392156862745098, '#5fad1e'],
    [0.24313725490196078, '#61ae1d'],
    [0.24705882352941178, '#64ae1d'],
    [0.25098039215686274, '#67af1d'],
    [0.2549019607843137, '#69b01d'],
    [0.2588235294117647, '#6cb01d'],
    [0.2627450980392157, '#6eb11c'],
    [0.26666666666666666, '#71b11c'],
    [0.27058823529411763, '#74b21c'],
    [0.27450980392156865, '#76b21c'],
    [0.2784313725490196, '#78b31c'],
    [0.2823529411764706, '#7bb41b'],
    [0.28627450980392155, '#7db41b'],
    [0.2901960784313725, '#80b51b'],
    [0.29411764705882354, '#82b51b'],
    [0.2980392156862745, '#84b61b'],
    [0.30196078431372547, '#87b61a'],
    [0.3058823529411765, '#89b71a'],
    [0.30980392156862746, '#8bb81a'],
    [0.3137254901960784, '#8eb81a'],
    [0.3176470588235294, '#90b919'],
    [0.32156862745098036, '#92b919'],
    [0.3254901960784314, '#95ba19'],
    [0.32941176470588235, '#97ba19'],
    [0.3333333333333333, '#99bb18'],
    [0.33725490196078434, '#9bbb18'],
    [0.3411764705882353, '#9ebc18'],
    [0.34509803921568627, '#a0bd18'],
    [0.34901960784313724, '#a2bd17'],
    [0.3529411764705882, '#a4be17'],
    [0.3568627450980392, '#a6be17'],
    [0.3607843137254902, '#a9bf17'],
    [0.36470588235294116, '#abbf16'],
    [0.3686274509803922, '#adc016'],
    [0.37254901960784315, '#afc016'],
    [0.3764705882352941, '#b1c115'],
    [0.3803921568627451, '#b4c115'],
    [0.38431372549019605, '#b6c215'],
    [0.38823529411764707, '#b8c214'],
    [0.39215686274509803, '#bac314'],
    [0.396078431372549, '#bcc314'],
    [0.4, '#bec413'],
    [0.403921568627451, '#c1c513'],
    [0.40784313725490196, '#c3c513'],
    [0.4117647058823529, '#c5c612'],
    [0.4156862745098039, '#c7c612'],
    [0.4196078431372549, '#c9c711'],
    [0.4235294117647059, '#cbc711'],
    [0.42745098039215684, '#cdc811'],
    [0.43137254901960786, '#cfc810'],
    [0.43529411764705883, '#d2c910'],
    [0.4392156862745098, '#d4c90f'],
    [0.44313725490196076, '#d6ca0f'],
    [0.44705882352941173, '#d8ca0e'],
    [0.45098039215686275, '#daca0e'],
    [0.4549019607843137, '#dccb0e'],
    [0.4588235294117647, '#decb0d'],
    [0.4627450980392157, '#e0cc0d'],
    [0.4666666666666667, '#e2cc0d'],
    [0.47058823529411764, '#e5cd0e'],
    [0.4745098039215686, '#e7cd0e'],
    [0.4784313725490196, '#e9cd10'],
    [0.4823529411764706, '#ebcd11'],
    [0.48627450980392156, '#edcd13'],
    [0.49019607843137253, '#efcd16'],
    [0.49411764705882355, '#f0cd18'],
    [0.4980392156862745, '#f2cc1c'],
    [0.5019607843137255, '#f4cc1f'],
    [0.5058823529411764, '#f5cb22'],
    [0.5098039215686274, '#f6ca25'],
    [0.5137254901960784, '#f7c929'],
    [0.5176470588235293, '#f8c82c'],
    [0.5215686274509804, '#f9c72f'],
    [0.5254901960784314, '#f9c533'],
    [0.5294117647058824, '#fac436'],
    [0.5333333333333333, '#fac339'],
    [0.5372549019607843, '#fbc13b'],
    [0.5411764705882353, '#fbc03e'],
    [0.5450980392156862, '#fbbe41'],
    [0.5490196078431373, '#fcbd43'],
    [0.5529411764705883, '#fcbc46'],
    [0.5568627450980392, '#fcba48'],
    [0.5607843137254902, '#fcb94a'],
    [0.5647058823529412, '#fcb74c'],
    [0.5686274509803921, '#fcb64f'],
    [0.5725490196078431, '#fdb451'],
    [0.5764705882352941, '#fdb353'],
    [0.580392156862745, '#fdb155'],
    [0.5843137254901961, '#fdb057'],
    [0.5882352941176471, '#fdae59'],
    [0.592156862745098, '#fdad5b'],
    [0.596078431372549, '#fdab5d'],
    [0.6, '#fdaa5f'],
    [0.6039215686274509, '#fea860'],
    [0.6078431372549019, '#fea762'],
    [0.611764705882353, '#fea564'],
    [0.615686274509804, '#fea466'],
    [0.6196078431372549, '#fea268'],
    [0.6235294117647059, '#fea169'],
    [0.6274509803921569, '#fe9f6b'],
    [0.6313725490196078, '#fe9e6d'],
    [0.6352941176470588, '#fe9c6e'],
    [0.6392156862745098, '#fe9b70'],
    [0.6431372549019607, '#fe9972'],
    [0.6470588235294118, '#fe9873'],
    [0.6509803921568628, '#fe9675'],
    [0.6549019607843137, '#fe9477'],
    [0.6588235294117647, '#fe9378'],
    [0.6627450980392157, '#fe917a'],
    [0.6666666666666666, '#fe907b'],
    [0.6705882352941176, '#fe8e7d'],
    [0.6745098039215687, '#fe8c7e'],
    [0.6784313725490196, '#fe8b80'],
    [0.6823529411764706, '#fe8982'],
    [0.6862745098039216, '#fe8883'],
    [0.6901960784313725, '#fe8685'],
    [0.6941176470588235, '#fe8486'],
    [0.6980392156862745, '#fe8288'],
    [0.7019607843137254, '#fe8189'],
    [0.7058823529411764, '#fd7f8b'],
    [0.7098039215686275, '#fd7d8c'],
    [0.7137254901960784, '#fd7c8d'],
    [0.7176470588235294, '#fd7a8f'],
    [0.7215686274509804, '#fd7890'],
    [0.7254901960784313, '#fd7692'],
    [0.7294117647058823, '#fd7593'],
    [0.7333333333333333, '#fd7395'],
    [0.7372549019607844, '#fc7196'],
    [0.7411764705882353, '#fc6f98'],
    [0.7450980392156863, '#fc6d99'],
    [0.7490196078431373, '#fc6b9a'],
    [0.7529411764705882, '#fc699c'],
    [0.7568627450980392, '#fb679d'],
    [0.7607843137254902, '#fb659f'],
    [0.7647058823529411, '#fb63a0'],
    [0.7686274509803921, '#fb61a1'],
    [0.7725490196078432, '#fb5fa3'],
    [0.7764705882352941, '#fa5da4'],
    [0.7803921568627451, '#fa5ba5'],
    [0.7843137254901961, '#fa59a6'],
    [0.788235294117647, '#fa57a7'],
    [0.792156862745098, '#f955a7'],
    [0.796078431372549, '#f953a8'],
    [0.8, '#f951a8'],
    [0.803921568627451, '#f84fa7'],
    [0.807843137254902, '#f84da7'],
    [0.8117647058823529, '#f84ca6'],
    [0.8156862745098039, '#f84aa4'],
    [0.8196078431372549, '#f748a2'],
    [0.8235294117647058, '#f747a0'],
    [0.8274509803921568, '#f7459e'],
    [0.8313725490196078, '#f6449b'],
    [0.8352941176470589, '#f64298'],
    [0.8392156862745098, '#f54195'],
    [0.8431372549019608, '#f54092'],
    [0.8470588235294118, '#f53f8f'],
    [0.8509803921568627, '#f43d8b'],
    [0.8549019607843137, '#f43c88'],
    [0.8588235294117647, '#f33b85'],
    [0.8627450980392157, '#f33a81'],
    [0.8666666666666667, '#f2397e'],
    [0.8705882352941177, '#f2377a'],
    [0.8745098039215686, '#f13677'],
    [0.8784313725490196, '#f03574'],
    [0.8823529411764706, '#f03470'],
    [0.8862745098039215, '#ef336d'],
    [0.8901960784313725, '#ee316a'],
    [0.8941176470588235, '#ee3066'],
    [0.8980392156862745, '#ed2f63'],
    [0.9019607843137255, '#ec2e60'],
    [0.9058823529411765, '#ec2c5c'],
    [0.9098039215686274, '#eb2b59'],
    [0.9137254901960784, '#ea2a56'],
    [0.9176470588235294, '#e92952'],
    [0.9215686274509803, '#e9274f'],
    [0.9254901960784314, '#e8264c'],
    [0.9294117647058824, '#e72548'],
    [0.9333333333333333, '#e62345'],
    [0.9372549019607843, '#e52242'],
    [0.9411764705882353, '#e5203e'],
    [0.9450980392156862, '#e41f3b'],
    [0.9490196078431372, '#e31e38'],
    [0.9529411764705882, '#e21c34'],
    [0.9568627450980391, '#e11b31'],
    [0.9607843137254902, '#e0192d'],
    [0.9647058823529412, '#df172a'],
    [0.9686274509803922, '#de1626'],
    [0.9725490196078431, '#dd1422'],
    [0.9764705882352941, '#dc121e'],
    [0.9803921568627451, '#db101a'],
    [0.984313725490196, '#da0e16'],
    [0.9882352941176471, '#d90c11'],
    [0.9921568627450981, '#d8090c'],
    [0.996078431372549, '#d70706'],
    [1.0, '#d60400'],
  ],
  CET_R4: [
    [0.0, '#03006c'],
    [0.00392156862745098, '#03006f'],
    [0.00784313725490196, '#030072'],
    [0.011764705882352941, '#040075'],
    [0.01568627450980392, '#040078'],
    [0.0196078431372549, '#04007b'],
    [0.023529411764705882, '#04007e'],
    [0.027450980392156862, '#040081'],
    [0.03137254901960784, '#040084'],
    [0.03529411764705882, '#040087'],
    [0.0392156862745098, '#04008a'],
    [0.043137254901960784, '#04008d'],
    [0.047058823529411764, '#040090'],
    [0.050980392156862744, '#040093'],
    [0.054901960784313725, '#040096'],
    [0.058823529411764705, '#040099'],
    [0.06274509803921569, '#05009c'],
    [0.06666666666666667, '#05019f'],
    [0.07058823529411765, '#0501a2'],
    [0.07450980392156863, '#0502a4'],
    [0.0784313725490196, '#0503a7'],
    [0.08235294117647059, '#0604aa'],
    [0.08627450980392157, '#0605ac'],
    [0.09019607843137255, '#0607ae'],
    [0.09411764705882353, '#0709b1'],
    [0.09803921568627451, '#070bb3'],
    [0.10196078431372549, '#080db5'],
    [0.10588235294117647, '#080fb7'],
    [0.10980392156862745, '#0910b9'],
    [0.11372549019607843, '#0912bb'],
    [0.11764705882352941, '#0a14bd'],
    [0.12156862745098039, '#0a16bf'],
    [0.12549019607843137, '#0b18c1'],
    [0.12941176470588234, '#0b19c3'],
    [0.13333333333333333, '#0c1bc4'],
    [0.13725490196078433, '#0c1dc6'],
    [0.1411764705882353, '#0c1ec8'],
    [0.14509803921568626, '#0d20ca'],
    [0.14901960784313725, '#0d22cc'],
    [0.15294117647058825, '#0d23ce'],
    [0.1568627450980392, '#0e25d0'],
    [0.16078431372549018, '#0e26d2'],
    [0.16470588235294117, '#0e28d4'],
    [0.16862745098039217, '#0f29d6'],
    [0.17254901960784313, '#0f2bd8'],
    [0.1764705882352941, '#0f2cda'],
    [0.1803921568627451, '#102ddc'],
    [0.1843137254901961, '#102fde'],
    [0.18823529411764706, '#1030e0'],
    [0.19215686274509802, '#1032e2'],
    [0.19607843137254902, '#1133e3'],
    [0.2, '#1135e5'],
    [0.20392156862745098, '#1136e7'],
    [0.20784313725490194, '#1238e9'],
    [0.21176470588235294, '#1239eb'],
    [0.21568627450980393, '#123bec'],
    [0.2196078431372549, '#133ced'],
    [0.22352941176470587, '#143eee'],
    [0.22745098039215686, '#1540ef'],
    [0.23137254901960785, '#1642f0'],
    [0.23529411764705882, '#1745ef'],
    [0.2392156862745098, '#1947ef'],
    [0.24313725490196078, '#1a4aee'],
    [0.24705882352941178, '#1b4cec'],
    [0.25098039215686274, '#1d4fea'],
    [0.2549019607843137, '#1e52e8'],
    [0.2588235294117647, '#1e55e5'],
    [0.2627450980392157, '#1f58e1'],
    [0.26666666666666666, '#1f5bde'],
    [0.27058823529411763, '#1f5eda'],
    [0.27450980392156865, '#1e61d6'],
    [0.2784313725490196, '#1d64d2'],
    [0.2823529411764706, '#1b67cd'],
    [0.28627450980392155, '#196ac9'],
    [0.2901960784313725, '#176dc5'],
    [0.29411764705882354, '#1470c0'],
    [0.2980392156862745, '#1072bc'],
    [0.30196078431372547, '#0d75b7'],
    [0.3058823529411765, '#0977b3'],
    [0.30980392156862746, '#067aae'],
    [0.3137254901960784, '#057ca9'],
    [0.3176470588235294, '#057ea4'],
    [0.32156862745098036, '#07809f'],
    [0.3254901960784314, '#0b839a'],
    [0.32941176470588235, '#108595'],
    [0.3333333333333333, '#148790'],
    [0.33725490196078434, '#19888a'],
    [0.3411764705882353, '#1d8a85'],
    [0.34509803921568627, '#208c7f'],
    [0.34901960784313724, '#248e79'],
    [0.3529411764705882, '#279073'],
    [0.3568627450980392, '#29916e'],
    [0.3607843137254902, '#2b9368'],
    [0.36470588235294116, '#2d9562'],
    [0.3686274509803922, '#2e975c'],
    [0.37254901960784315, '#2f9855'],
    [0.3764705882352941, '#2f9a4f'],
    [0.3803921568627451, '#2f9c49'],
    [0.38431372549019605, '#309e43'],
    [0.38823529411764707, '#309f3d'],
    [0.39215686274509803, '#31a137'],
    [0.396078431372549, '#31a232'],
    [0.4, '#32a42c'],
    [0.403921568627451, '#34a527'],
    [0.40784313725490196, '#36a722'],
    [0.4117647058823529, '#39a81d'],
    [0.4156862745098039, '#3baa18'],
    [0.4196078431372549, '#3fab14'],
    [0.4235294117647059, '#42ac11'],
    [0.42745098039215684, '#46ad0e'],
    [0.43137254901960786, '#4aae0b'],
    [0.43529411764705883, '#4eaf09'],
    [0.4392156862745098, '#51b108'],
    [0.44313725490196076, '#55b207'],
    [0.44705882352941173, '#59b306'],
    [0.45098039215686275, '#5db406'],
    [0.4549019607843137, '#61b506'],
    [0.4588235294117647, '#64b605'],
    [0.4627450980392157, '#68b705'],
    [0.4666666666666667, '#6bb805'],
    [0.47058823529411764, '#6fb905'],
    [0.4745098039215686, '#72ba05'],
    [0.4784313725490196, '#76bb05'],
    [0.4823529411764706, '#79bc05'],
    [0.48627450980392156, '#7cbd04'],
    [0.49019607843137253, '#80be04'],
    [0.49411764705882355, '#83bf04'],
    [0.4980392156862745, '#86c004'],
    [0.5019607843137255, '#89c104'],
    [0.5058823529411764, '#8dc204'],
    [0.5098039215686274, '#90c304'],
    [0.5137254901960784, '#93c404'],
    [0.5176470588235293, '#96c503'],
    [0.5215686274509804, '#99c603'],
    [0.5254901960784314, '#9cc703'],
    [0.5294117647058824, '#9fc803'],
    [0.5333333333333333, '#a2c903'],
    [0.5372549019607843, '#a5ca03'],
    [0.5411764705882353, '#a8cb03'],
    [0.5450980392156862, '#abcc03'],
    [0.5490196078431373, '#afcd03'],
    [0.5529411764705883, '#b2ce02'],
    [0.5568627450980392, '#b5cf02'],
    [0.5607843137254902, '#b8d002'],
    [0.5647058823529412, '#bbd102'],
    [0.5686274509803921, '#bed202'],
    [0.5725490196078431, '#c0d302'],
    [0.5764705882352941, '#c3d402'],
    [0.580392156862745, '#c6d502'],
    [0.5843137254901961, '#c9d602'],
    [0.5882352941176471, '#ccd701'],
    [0.592156862745098, '#cfd801'],
    [0.596078431372549, '#d2d901'],
    [0.6, '#d5da01'],
    [0.6039215686274509, '#d8db01'],
    [0.6078431372549019, '#dbdc01'],
    [0.611764705882353, '#dedc01'],
    [0.615686274509804, '#e1dd01'],
    [0.6196078431372549, '#e4de01'],
    [0.6235294117647059, '#e6de01'],
    [0.6274509803921569, '#e9de01'],
    [0.6313725490196078, '#ecde02'],
    [0.6352941176470588, '#eede02'],
    [0.6392156862745098, '#f0de02'],
    [0.6431372549019607, '#f2dd03'],
    [0.6470588235294118, '#f4dc03'],
    [0.6509803921568628, '#f5db04'],
    [0.6549019607843137, '#f6da05'],
    [0.6588235294117647, '#f7d905'],
    [0.6627450980392157, '#f8d706'],
    [0.6666666666666666, '#f9d507'],
    [0.6705882352941176, '#f9d308'],
    [0.6745098039215687, '#f9d208'],
    [0.6784313725490196, '#fad009'],
    [0.6823529411764706, '#face0a'],
    [0.6862745098039216, '#facc0b'],
    [0.6901960784313725, '#faca0b'],
    [0.6941176470588235, '#fac80c'],
    [0.6980392156862745, '#fbc60d'],
    [0.7019607843137254, '#fbc40d'],
    [0.7058823529411764, '#fbc20e'],
    [0.7098039215686275, '#fbc00e'],
    [0.7137254901960784, '#fbbe0f'],
    [0.7176470588235294, '#fbbc0f'],
    [0.7215686274509804, '#fbb910'],
    [0.7254901960784313, '#fbb710'],
    [0.7294117647058823, '#fbb511'],
    [0.7333333333333333, '#fbb311'],
    [0.7372549019607844, '#fbb112'],
    [0.7411764705882353, '#fbaf12'],
    [0.7450980392156863, '#fbad12'],
    [0.7490196078431373, '#fbab13'],
    [0.7529411764705882, '#fba913'],
    [0.7568627450980392, '#fba713'],
    [0.7607843137254902, '#fba514'],
    [0.7647058823529411, '#fba314'],
    [0.7686274509803921, '#fba014'],
    [0.7725490196078432, '#fb9e15'],
    [0.7764705882352941, '#fb9c15'],
    [0.7803921568627451, '#fb9a15'],
    [0.7843137254901961, '#fb9815'],
    [0.788235294117647, '#fb9615'],
    [0.792156862745098, '#fb9315'],
    [0.796078431372549, '#fb9115'],
    [0.8, '#fb8f15'],
    [0.803921568627451, '#fb8c15'],
    [0.807843137254902, '#fc8a15'],
    [0.8117647058823529, '#fc8714'],
    [0.8156862745098039, '#fc8514'],
    [0.8196078431372549, '#fc8314'],
    [0.8235294117647058, '#fc8013'],
    [0.8274509803921568, '#fc7d13'],
    [0.8313725490196078, '#fd7b12'],
    [0.8352941176470589, '#fd7812'],
    [0.8392156862745098, '#fd7512'],
    [0.8431372549019608, '#fd7311'],
    [0.8470588235294118, '#fd7011'],
    [0.8509803921568627, '#fd6d11'],
    [0.8549019607843137, '#fd6a10'],
    [0.8588235294117647, '#fe6710'],
    [0.8627450980392157, '#fe640f'],
    [0.8666666666666667, '#fe610f'],
    [0.8705882352941177, '#fe5e0f'],
    [0.8745098039215686, '#fe5b0f'],
    [0.8784313725490196, '#fe580e'],
    [0.8823529411764706, '#fe540e'],
    [0.8862745098039215, '#fe510e'],
    [0.8901960784313725, '#fe4d0d'],
    [0.8941176470588235, '#fe4a0d'],
    [0.8980392156862745, '#fe460d'],
    [0.9019607843137255, '#fd420d'],
    [0.9058823529411765, '#fd3f0d'],
    [0.9098039215686274, '#fc3b0d'],
    [0.9137254901960784, '#fc380d'],
    [0.9176470588235294, '#fb340d'],
    [0.9215686274509803, '#fa310c'],
    [0.9254901960784314, '#f92e0c'],
    [0.9294117647058824, '#f72b0c'],
    [0.9333333333333333, '#f6290c'],
    [0.9372549019607843, '#f4260c'],
    [0.9411764705882353, '#f3240d'],
    [0.9450980392156862, '#f1220d'],
    [0.9490196078431372, '#ef200d'],
    [0.9529411764705882, '#ed1e0d'],
    [0.9568627450980391, '#eb1c0d'],
    [0.9607843137254902, '#e91b0d'],
    [0.9647058823529412, '#e8190d'],
    [0.9686274509803922, '#e6170d'],
    [0.9725490196078431, '#e4150d'],
    [0.9764705882352941, '#e2130d'],
    [0.9803921568627451, '#e0110d'],
    [0.984313725490196, '#de0f0d'],
    [0.9882352941176471, '#dc0d0d'],
    [0.9921568627450981, '#da0a0d'],
    [0.996078431372549, '#d8070d'],
    [1.0, '#d6050d'],
  ],
};

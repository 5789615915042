import React, { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
};

export const AnalysisIcon: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M19 15c-.712.004-1.41.2-2.019.567l-1.548-1.548a3.916 3.916 0 0 0-.484-4.7l.933-2.332c.04 0 .078.012.118.012a3.03 3.03 0 1 0-1.974-.757l-.789 1.973a3.791 3.791 0 0 0-3.256.351L6.707 5.293 6.7 5.287a2.957 2.957 0 0 0-.206-2.954A3 3 0 1 0 4 7a2.96 2.96 0 0 0 1.287-.3l.006.009 3.274 3.272a3.908 3.908 0 0 0 .443 4.649L6.17 18.1a2.53 2.53 0 1 0 1.548 1.269l2.955-3.613a3.82 3.82 0 0 0 3.346-.323l1.548 1.548A3.947 3.947 0 0 0 15 19a4 4 0 1 0 4-4ZM4 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm6 7a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm9 9a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z' />
  </svg>
);

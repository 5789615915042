import { PanConfig } from 'app/screens/Analysis/Analysis.types';

export const isVisualizationCopy = (panConfig: PanConfig, visualizationId: number): boolean =>
  panConfig.some(({ id }) => id === visualizationId);

export const shortName = (name: string, maxWidth: number, isResponsive?: boolean): string => {
  let shortText = name;
  let maxShortTextWidth = maxWidth;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return shortText;
  }

  context.font = '1.4rem Inter';
  const ellipsis = '...';
  const ellipsisWidth = context.measureText(ellipsis).width;
  let { width } = context.measureText(shortText);

  if (window.innerWidth < 1440 && isResponsive) {
    maxShortTextWidth /= 2;
  } else if (window.innerWidth < 1920 && isResponsive) {
    maxShortTextWidth /= 1.5;
  }

  if (width <= maxShortTextWidth) {
    return shortText;
  }

  let { length } = shortText;
  while (width + ellipsisWidth > maxShortTextWidth && length > 0) {
    length -= 1;
    const firstPart = shortText.slice(0, length / 2);
    const secondPart = shortText.slice(shortText.length - length / 2);
    shortText = `${firstPart}${ellipsis} ${secondPart}`;
    width = context.measureText(shortText).width;
  }

  return shortText;
};

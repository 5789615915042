import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Tooltip } from 'app/mui/Tooltip';

export const GroupNameWrapper = (groupName: string): ReactElement => (
  <Box display='flex'>
    <Tooltip title={groupName} placement='bottom-start'>
      <Typography noWrap sx={{ maxWidth: '115px', marginRight: '4px' }} ml={1}>
        {groupName}
      </Typography>
    </Tooltip>{' '}
    group
  </Box>
);

export const DefaultNameWrapper = (groupName: string): ReactElement => (
  <Tooltip title={groupName} placement='bottom-start'>
    <Typography noWrap sx={{ maxWidth: '157px' }} ml={1}>
      {groupName}
    </Typography>
  </Tooltip>
);

import Graph from 'graphology';
import { createEdgeWeightGetter } from 'graphology-utils/getters';
import isGraph from 'graphology-utils/is-graph';
import { ForceAtlas2SynchronousLayoutParameters } from 'graphology-layout-forceatlas2';
import { connectedComponents } from 'graphology-components';

import { WebWorkerHandler } from './handler';
import { WORKERS } from './workers';
import { iterateNoIntercomponentRepel } from './iterateNoIntercomponentRepel';
import { relativeComponentLayout } from './relativeComponentLayout';

// avoid TS errors from import statements when types are not provided.
// tried using declaration files but it didn't work
// eslint-disable-next-line @typescript-eslint/no-var-requires
const DEFAULT_SETTINGS = require('graphology-layout-forceatlas2/defaults');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const helpers = require('graphology-layout-forceatlas2/helpers');

export const initialGraphLayout = async (
  graph: Graph,
  params: ForceAtlas2SynchronousLayoutParameters,
  useWorker = true
): Promise<void> => {
  if (!isGraph(graph))
    throw new Error(
      'graphology-layout-forceatlas2: the given graph is not a valid graphology instance.'
    );
  const { iterations } = params;
  const connComponents = connectedComponents(graph);
  if (typeof iterations !== 'number')
    throw new Error('graphology-layout-forceatlas2: invalid number of iterations.');

  if (iterations <= 0)
    throw new Error(
      'graphology-layout-forceatlas2: you should provide a positive number of iterations.'
    );

  const getEdgeWeight = createEdgeWeightGetter(
    // @ts-ignore
    'getEdgeWeight' in params ? params.getEdgeWeight : 'weight'
  ).fromEntry;

  const outputReducer = typeof params.outputReducer === 'function' ? params.outputReducer : null;

  // Validating settings
  const settings = helpers.assign({}, DEFAULT_SETTINGS, params.settings);
  const validationError = helpers.validateSettings(settings);

  if (validationError) throw new Error(`graphology-layout-forceatlas2: ${validationError.message}`);
  const matrices = helpers.graphToByteArrays(graph, getEdgeWeight);

  if (useWorker) {
    // start iteration process in webworker
    const forceAtlasParams = {
      iterations,
      settings,
      nodes: matrices.nodes.buffer,
      edges: matrices.edges.buffer,
      components: connComponents,
    };
    const connectedComponentHandler = new WebWorkerHandler<
      typeof forceAtlasParams,
      { nodes: Float32Array }
    >(WORKERS.ConnectedComponent);
    const result = await connectedComponentHandler.call(forceAtlasParams, [
      matrices.nodes.buffer,
      matrices.edges.buffer,
    ]);

    const newNodes = new Float32Array(result.nodes);

    connectedComponentHandler.kill();

    const componentParams = {
      iterations: 100,
      nodes: newNodes.buffer,
      components: connComponents,
    };
    const relativeComponentHandler = new WebWorkerHandler<
      typeof componentParams,
      { nodes: Float32Array }
    >(WORKERS.ExplicitLayoutByComponent);
    const result2 = await relativeComponentHandler.call(componentParams, [newNodes.buffer]);

    const matrix = new Float32Array(result2.nodes);
    // Applying
    helpers.assignLayoutChanges(graph, matrix, outputReducer);

    relativeComponentHandler.kill();
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < iterations; i++) {
      iterateNoIntercomponentRepel(settings, matrices.nodes, matrices.edges, connComponents);
    }

    relativeComponentLayout(matrices.nodes, connComponents, 100);

    helpers.assignLayoutChanges(graph, matrices.nodes, outputReducer);
  }
};

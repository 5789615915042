import { FC, useCallback, useEffect } from 'react';
import type Graph from 'graphology';
import type { SigmaNodeEventPayload } from 'sigma/sigma';
import type { Coordinates, MouseCoords } from 'sigma/types';
import { useRegisterEvents, useSigma } from '@react-sigma/core';

import type { ForceAtlasAnimator } from './ForceAtlasAnimator';

type LoadGraphProps = {
  graph: Graph;
  animator: ForceAtlasAnimator;
  animate: boolean;
  selectedNodes: number[];
  setSelectedNodes: (nodes: number[]) => void;
  addSelectedNodes: (nodes: number[]) => void;
};

export const GraphLoader: FC<LoadGraphProps> = ({
  graph,
  animator,
  animate,
  selectedNodes,
  setSelectedNodes,
  addSelectedNodes,
}) => {
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();

  useEffect(() => {
    sigma.setGraph(graph);
  }, [graph, sigma]);

  useEffect(() => {
    graph.updateEachNodeAttributes((key, attr) => {
      const n = Number(key);
      if (selectedNodes.some((node) => node === n)) {
        return { ...attr, highlighted: true };
      }
      return { ...attr, highlighted: false };
    });
  }, [selectedNodes, graph]);

  const handleSelectionClear = useCallback(() => {
    setSelectedNodes([]);

    selectedNodes.forEach((node) => {
      graph.setNodeAttribute(node, 'highlighted', false);
    });
  }, [setSelectedNodes, selectedNodes, graph]);

  const onNodeDoubleClick = (e: SigmaNodeEventPayload): void => {
    e.preventSigmaDefault();
    const nodeValue = Number(e.node);
    const isNodeSelectedAlready = selectedNodes.some((n) => n === nodeValue);
    if (isNodeSelectedAlready) {
      setSelectedNodes(selectedNodes.filter((n) => n !== nodeValue));
    } else {
      addSelectedNodes([nodeValue]);
    }
  };

  useEffect(() => {
    let draggedNode: unknown;
    let isDragging = false;
    let isDraggedNodeSelected = false;
    let previousPosition: Coordinates | null = null;

    registerEvents({
      enterNode: (n) => {
        const nodeLabel = graph.getNodeAttribute(n.node, 'labelToDisplay');
        const currentLabel = graph.getNodeAttribute(n.node, 'label');
        graph.setNodeAttribute(n.node, 'previousLabel', currentLabel);

        graph.setNodeAttribute(n.node, 'label', nodeLabel);
      },
      leaveNode: (n) => {
        const previousLabel = graph.getNodeAttribute(n.node, 'previousLabel');
        graph.setNodeAttribute(n.node, 'label', previousLabel);
      },
      downNode: (e) => {
        isDragging = true;
        draggedNode = e.node;

        const nodeValue = Number(e.node);
        graph.setNodeAttribute(draggedNode, 'highlighted', true);

        isDraggedNodeSelected = selectedNodes.some((n) => n === nodeValue);

        if (isDraggedNodeSelected) {
          selectedNodes.map((node) => graph.updateNodeAttribute(node, 'fixed', () => true));
        } else {
          graph.setNodeAttribute(draggedNode, 'fixed', true);
        }

        previousPosition = sigma.viewportToGraph(e.event);

        if (!animator.isRunning() && animate) {
          animator.start();
        }
        animator.resetNIters();
      },
      mousemovebody: (e: MouseCoords) => {
        if (!isDragging || !draggedNode || !previousPosition) return;
        animator.resetNIters();
        const pos = sigma.viewportToGraph(e);
        const dx = pos.x - previousPosition.x;
        const dy = pos.y - previousPosition.y;
        previousPosition = pos;
        if (isDraggedNodeSelected) {
          selectedNodes.map((node) =>
            graph.updateNodeAttributes(node, (attr) => ({
              ...attr,
              x: attr.x + dx,
              y: attr.y + dy,
            }))
          );
        } else {
          graph.updateNodeAttributes(draggedNode, (attr) => ({
            ...attr,
            x: attr.x + dx,
            y: attr.y + dy,
          }));
        }

        // Prevent sigma to move camera:
        e.preventSigmaDefault();
        e.original.preventDefault();
        e.original.stopPropagation();
      },
      mouseup: () => {
        if (draggedNode) {
          if (isDraggedNodeSelected) {
            selectedNodes.map((node) => graph.removeNodeAttribute(node, 'fixed'));
          } else {
            graph.removeNodeAttribute(draggedNode, 'fixed');
            graph.removeNodeAttribute(draggedNode, 'highlighted');
          }
          previousPosition = null;
        }

        isDragging = false;
        draggedNode = null;
        isDraggedNodeSelected = false;
      },
      mousedown: () => {
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      doubleClickNode: (e) => onNodeDoubleClick(e),
      doubleClickStage: (e) => {
        handleSelectionClear();
        e.preventSigmaDefault();
      },
      wheel: (e) => {
        const camera = sigma.getCamera();
        const cameraState = camera.getState();
        // too large a step makes the motion very unsmooth
        const delta = Math.max(-1.5, Math.min(1.5, e.delta));
        const newRatio = camera.getBoundedRatio(cameraState.ratio * (1 - delta / 8));
        const event = e.original as WheelEvent;

        if (event.deltaMode !== WheelEvent.DOM_DELTA_PIXEL) {
          console.warn(
            'WheelEvent.deltaMode is not DOM_DELTA_PIXEL. Zoom behavior may not be as expected.'
          );
        }

        // shift the center so that the mouse cursor is the fixed point of the zoom
        const newState = sigma.getViewportZoomedState(e, newRatio);
        camera.setState(newState);

        e.preventSigmaDefault(); // this call is supposed to work but doesn't
        // eslint-disable-next-line no-param-reassign
        e.sigmaDefaultPrevented = true; // so we have to set this flag manually
      },
    });
  }, [
    registerEvents,
    setSelectedNodes,
    addSelectedNodes,
    selectedNodes,
    handleSelectionClear,
    graph,
    animate,
    sigma,
    animator,
  ]);

  return null;
};

import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { COLORING_OPTIONS } from 'app/shared/enum/analysis';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import {
  aggregationTypeList,
  defaultColoringOptions,
  noColoringOption,
  normalizationTypeOptions,
} from 'app/screens/Analysis/Analysis.utils';
import {
  AggregationType,
  CategoricalDataColoringFunction,
  ChartDataType,
  ColoringConfig,
  ColoringData,
  ColoringLegend,
  DataColoringFunction,
  DiffColoringValue,
  FeatureItemType,
  LandscapeData,
  NormalizationType,
  OptionType,
  OtherColoringValue,
  RetrieveGraphType,
  SelectedColoringValue,
  SelectedFeatureType,
  SELECTION_SOURCES,
  SelectionSourceType,
} from 'app/screens/Analysis/Analysis.types';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { CategoricalColorMaps, ColorLegendType } from './Coloring.types';
import { getGroup } from './ColoringMenu';

export type ColoringOptions = {
  colorOption: SelectedFeatureType;
  featureOption: SelectedFeatureType | null;
  aggregationType: OptionType;
  normalizationType: OptionType;
  diffOptionOne: OptionType | SelectedFeatureType | null;
  diffOptionTwo: OptionType | SelectedFeatureType | null;
  featureType: string;
  selectType: 'coloring_modal' | 'selection';
};

type UseColoringProps = {
  panelId: string;
  analysisId: number;
  visualizationId: number;
  type: VisualisationType;
  chart?: ChartDataType;
  coloring?: ColoringConfig;
  retrieveGraphData?: RetrieveGraphType;
  onDataUpdate?: (data: LandscapeData) => void;
  onChartColoringUpdate?: (colors: string[] | string, symbols?: string[] | string) => void;
  setInitialColoringData: (graph?: RetrieveGraphType) => void;
};

type UseColoringReturnType = {
  colorOption: SelectedFeatureType;
  featureOption: SelectedFeatureType | null;
  aggregationType: OptionType;
  normalizationType: OptionType;
  diffOptionOne: OptionType | SelectedFeatureType | null;
  diffOptionTwo: OptionType | SelectedFeatureType | null;
  featureType: string;
  legend: ColorLegendType;
  hintTextType: string | null;
  coloringAnchorEl: null | HTMLElement;
  openColoring: boolean;
  showColorPicker: boolean;
  handleColoringOpen: (event: MouseEvent<HTMLDivElement>) => void;
  handleColoringClose: () => void;
  handleColorOption: (option: SelectedFeatureType) => void;
  handleFeatureApply: (option: SelectedFeatureType) => void;
  handleDiffOptionApply: (
    type: keyof ColoringOptions,
    option: OptionType | SelectedFeatureType
  ) => void;
  handleAggregationType: (option: OptionType) => void;
  handleNormalizationType: (option: OptionType) => void;
  handleLegendChange: (update: Partial<ColorLegendType>) => void;
  handleColorPicker: () => void;
  handleColorMapChange: (colorMapType: CategoricalColorMaps) => void;
};

const getColoringValue = (colorOption: COLORING_OPTIONS, colorValue: any) => {
  const isOtherOption = [COLORING_OPTIONS.other].includes(colorOption);
  const isDiffOption = [COLORING_OPTIONS.differentialColoring].includes(colorOption);

  let value;

  if (isOtherOption) {
    value = colorValue as OtherColoringValue;
  } else if (isDiffOption) {
    value = colorValue as DiffColoringValue;
  } else {
    value = [] as SelectedColoringValue;
  }

  return value;
};

export const useColoring = ({
  visualizationId,
  analysisId,
  panelId,
  type,
  coloring,
  chart,
  retrieveGraphData,
  onDataUpdate,
  onChartColoringUpdate,
  setInitialColoringData,
}: UseColoringProps): UseColoringReturnType => {
  const {
    selectedFeatures,
    selectedDataRows,
    featureSelectionSource,
    dataPointsSelectionSource,
    features,
    groups,
    isDefaultColoring,
    getDataColoring,
    getDataDiffColoring,
    getFeatureColoring,
    getFeatureDiffColoring,
    getGraphLayoutData,
    getGraphLayoutColoredData,
    getGraphLayoutCategoricalColoring,
    getChartLayoutColoredData,
    getChartLayoutCategoricalColoring,
    updatePanConfig,
  } = useAnalysis();

  const getOtherColoringParams = (value: OtherColoringValue) => {
    const isFeature = typeof value?.features === 'number';
    const isFeatureGroup = typeof value?.feature_group === 'number';
    const isDataGroup = typeof value?.rows_group === 'number';
    const isGroup = isFeatureGroup || isDataGroup;

    let featureOption = null;

    if (isFeature) {
      featureOption = features.find((f) => f.id === value?.features);
    } else if (isGroup) {
      const defaultFeatureGroup = groups.find(
        (f) => f.is_default && f.type === GroupCreationEnum.FEATURES
      );

      const groupId = value?.feature_group || value?.rows_group;

      const group = groups.find((f) => f.id === groupId) || defaultFeatureGroup;

      if (group) {
        featureOption = getGroup(group);
      }
    }

    return {
      featureOption,
      ...(value?.feature_type ? { featureType: value?.feature_type } : {}),
    };
  };

  const getDiffColoringParams = ({ feature_type, ...value }: DiffColoringValue) => {
    const valueArray = Object.values(value);
    const [diffOptionOne = null, diffOptionTwo = null] = valueArray.map((source) => {
      if (source.type === COLORING_OPTIONS.selectedFeatures) {
        return defaultColoringOptions[0];
      }

      if (source.type === COLORING_OPTIONS.selectedDataPoints) {
        return defaultColoringOptions[1];
      }

      return getOtherColoringParams(source).featureOption;
    });

    return {
      diffOptionOne,
      diffOptionTwo,
      ...(feature_type ? { featureType: feature_type } : {}),
    };
  };

  const getSelectedFeaturesType = (colorOption: SelectedFeatureType) => {
    const isSelectedFeatureOption = colorOption.id === COLORING_OPTIONS.selectedFeatures;

    if (!isSelectedFeatureOption || !selectedFeatures.length) {
      return {};
    }

    const isNumeric = selectedFeatures.some(
      (id) => features.find((f) => f.id === id)?.type === 'numeric'
    );

    return {
      featureType: isNumeric ? 'numeric' : 'category',
    };
  };

  const getDefaultColoringOptions = useCallback((): ColoringOptions => {
    const colorOption =
      defaultColoringOptions.find((c) => c.id === coloring?.coloring_type) || noColoringOption;

    const normalizationType =
      normalizationTypeOptions.find((n) => n.id === coloring?.color_value) ||
      normalizationTypeOptions[2];
    const aggregationType =
      aggregationTypeList.find((n) => n.id === coloring?.aggregation_function) ||
      aggregationTypeList[0];

    const colorValue = getColoringValue(colorOption.id, coloring?.value);
    const otherFeatureValue = getOtherColoringParams(colorValue as OtherColoringValue);
    const diffFeaturesValue = getDiffColoringParams(colorValue as DiffColoringValue);
    const selectedFeaturesType = getSelectedFeaturesType(colorOption);

    return {
      colorOption,
      normalizationType,
      aggregationType,
      selectType: 'coloring_modal',
      featureType: 'numeric',
      ...selectedFeaturesType,
      ...otherFeatureValue,
      ...diffFeaturesValue,
    };
  }, [coloring]);

  const getDefaultLegend = useCallback((): ColorLegendType => {
    const colorMapVariantsArray = [
      CategoricalColorMaps.Default,
      CategoricalColorMaps.Light,
      CategoricalColorMaps.Monochromatic,
    ];
    const savedColorMap = coloring?.legend?.color_map;
    const colorMap =
      savedColorMap && colorMapVariantsArray.includes(savedColorMap)
        ? savedColorMap
        : CategoricalColorMaps.Default;

    return {
      visible: coloring?.legend?.visible ?? true,
      type: 'line',
      colorMap,
    };
  }, [coloring?.legend]);

  const [coloringOptions, setColoringOptions] = useState<ColoringOptions>(
    getDefaultColoringOptions()
  );
  const [legend, setLegend] = useState<ColorLegendType>(getDefaultLegend());
  const [hintTextType, setHintTextType] = useState<string | null>(null);
  const [showColorPicker, setColorPicker] = useState(false);

  const graphTaskRef = useRef<RetrieveGraphType | undefined>(retrieveGraphData);
  const landscapeRef = useRef<LandscapeData | null>(null);
  const isMounted = useRef(false);

  useEffect(() => {
    const isOtherFeature = coloringOptions?.colorOption?.id === COLORING_OPTIONS.other;
    const isExistingGroup = groups.find((group) => group.id === coloringOptions?.featureOption?.id);
    const shouldResetColoring = isOtherFeature && !isExistingGroup;

    if (visualizationId && shouldResetColoring) {
      setColoringOptions(getDefaultColoringOptions());
      setLegend(getDefaultLegend());
      setColorPicker(false);
    }
  }, [visualizationId, groups]);

  const [coloringAnchorEl, setColoringAnchorEl] = useState<null | HTMLElement>(null);
  const openColoring = Boolean(coloringAnchorEl);

  const lastNormalizationType = useRef<OptionType>(coloringOptions.normalizationType);
  const lastAggregationType = useRef<OptionType>(coloringOptions.aggregationType);
  const lastColorOption = useRef<SelectedFeatureType>(coloringOptions.colorOption);
  const lastFeatureOption = useRef<SelectedFeatureType>(coloringOptions.featureOption);
  const lastFeatureType = useRef<string>(coloringOptions.featureType);
  const lastDiffOptionOne = useRef<SelectedFeatureType>(coloringOptions.diffOptionOne);
  const lastDiffOptionTwo = useRef<SelectedFeatureType>(coloringOptions.diffOptionTwo);

  const coloringDataRef = useRef<ColoringData | null>(null);

  const getFeatureValue = (feature: SelectedFeatureType | null) => {
    const isFeatureGroup = feature?.groupType === 'features';
    const isDataGroup = feature?.groupType === 'rows';
    return isFeatureGroup
      ? { feature_group: feature?.id }
      : isDataGroup
      ? { rows_group: feature?.id }
      : { features: feature?.id };
  };

  const getValueToSave = (
    feature?: SelectedFeatureType | null,
    diffValueOne?: OptionType | SelectedFeatureType | null,
    diffValueTwo?: OptionType | SelectedFeatureType | null
  ) => {
    if (feature) {
      return getFeatureValue(feature);
    }

    if (diffValueOne && diffValueTwo) {
      const [firstSource, secondSource] = [diffValueOne, diffValueTwo].map((option) => {
        if (option.id === COLORING_OPTIONS.selectedFeatures) {
          return {
            type: COLORING_OPTIONS.selectedFeatures,
          };
        }

        if (option.id === COLORING_OPTIONS.selectedDataPoints) {
          return {
            type: COLORING_OPTIONS.selectedDataPoints,
          };
        }

        return getFeatureValue(option);
      });

      return {
        firstSource,
        secondSource,
      };
    }
  };

  const saveColoringConfig = (
    options?: Partial<ColoringOptions & { legend: Partial<ColoringLegend> }>
  ) => {
    const isDisabledColoring = coloringOptions.colorOption.id === COLORING_OPTIONS.none;
    const value = getValueToSave(
      coloringOptions.featureOption,
      coloringOptions.diffOptionOne,
      coloringOptions.diffOptionTwo
    );

    const coloringSettings = {
      aggregation_function: coloringOptions.aggregationType.id as AggregationType,
      color_value: coloringOptions.normalizationType.id as NormalizationType,
      coloring_type: coloringOptions.colorOption.id,
      value: value ? { ...value, feature_type: coloringOptions.featureType } : [],
      visualisation_type: type,
      ...(options || {}),
      legend: {
        visible: legend.visible,
        color_map: legend.colorMap,
        ...(options?.legend || {}),
      },
    };

    updatePanConfig(analysisId, panelId, {
      id: visualizationId,
      type,
      coloring: isDisabledColoring ? ({} as ColoringConfig) : coloringSettings,
    });
  };

  const handleLegendChange = (update: Partial<ColorLegendType>) => {
    setLegend((prevState) => ({
      ...prevState,
      ...update,
    }));

    if (typeof update?.visible === 'boolean') {
      saveColoringConfig({ legend: update });
    }
  };

  const resetLegendState = () => {
    handleLegendChange({
      colorBounds: undefined,
      dataBounds: undefined,
      functionName: undefined,
      categories: undefined,
    });
  };

  const setBackupSettings = (options: ColoringOptions) => {
    const {
      colorOption,
      featureOption,
      aggregationType,
      normalizationType,
      featureType,
      diffOptionOne,
      diffOptionTwo,
    } = options;

    lastNormalizationType.current = normalizationType;
    lastAggregationType.current = aggregationType;
    lastColorOption.current = colorOption;
    lastFeatureOption.current = featureOption;
    lastFeatureType.current = featureType;
    lastDiffOptionOne.current = diffOptionOne;
    lastDiffOptionTwo.current = diffOptionTwo;
  };

  const setChartColoring = (
    coloringDataValue: ColoringData,
    chartLayout: ChartDataType,
    isCategory: boolean,
    colorMapType?: CategoricalColorMaps
  ) => {
    if (isCategory) {
      const {
        colors,
        symbols,
        legend: legendBounds,
      } = getChartLayoutCategoricalColoring(chartLayout, coloringDataValue, colorMapType!);

      if (onChartColoringUpdate) {
        onChartColoringUpdate(colors, symbols);
      }

      if (legendBounds) {
        handleLegendChange(legendBounds);
      }
    } else {
      const {
        colors,
        symbols,
        legend: legendBounds,
      } = getChartLayoutColoredData(chartLayout, coloringDataValue);

      if (onChartColoringUpdate) {
        onChartColoringUpdate(colors, symbols);
      }

      if (legendBounds) {
        handleLegendChange(legendBounds);
      }
    }
  };

  const handleChartApplyColoring = useCallback(
    async (chartLayout?: ChartDataType): Promise<void> => {
      if (!chartLayout) {
        return;
      }

      const {
        colorOption,
        aggregationType,
        featureOption,
        featureType,
        normalizationType,
        diffOptionOne,
        diffOptionTwo,
      } = coloringOptions;

      if (isDefaultColoring(colorOption)) {
        setInitialColoringData();
        coloringDataRef.current = null;
        resetLegendState();
      } else {
        const isDiffColoring = colorOption.id === COLORING_OPTIONS.differentialColoring;
        const defaultOption = groups[groups.length - 1]; // TODO: Should be changed for default group when backend ready
        const option =
          colorOption.id === COLORING_OPTIONS.other ? featureOption || defaultOption : colorOption;
        const isCategory = featureType === 'category';
        const aggregation = isCategory ? AggregationType.CARTESIAN : aggregationType.id;
        const normalization = isCategory ? NormalizationType.RAW : normalizationType.id;

        const coloringData = isDiffColoring
          ? ((await getDataDiffColoring(
              [diffOptionOne, diffOptionTwo],
              aggregation as AggregationType,
              normalization as NormalizationType
            )) as ColoringData)
          : ((await getDataColoring(
              option,
              aggregation as AggregationType,
              normalization as NormalizationType
            )) as ColoringData);

        if (coloringData && (coloringData.colorFunction || coloringData.colorData.length)) {
          coloringDataRef.current = coloringData;

          setChartColoring(coloringData, chartLayout, isCategory, legend.colorMap);
        } else {
          coloringDataRef.current = null;
          setInitialColoringData();

          resetLegendState();
        }
      }

      setBackupSettings(coloringOptions);
    },
    [isDefaultColoring, setInitialColoringData, type, coloringOptions, selectedFeatures]
  );

  const setLandscapeColoring = (
    coloringDataValue: ColoringData,
    graph: LandscapeData,
    isCategory: boolean,
    colorMapType?: CategoricalColorMaps
  ) => {
    if (isCategory) {
      const { legend: legendBounds, ...landscapeLayout } = getGraphLayoutCategoricalColoring(
        graph,
        coloringDataValue as ColoringData<CategoricalDataColoringFunction>,
        colorMapType!,
        type === VisualisationType.FEATURE_LANDSCAPE
      );

      if (onDataUpdate) {
        onDataUpdate(landscapeLayout);
      }

      if (legendBounds) {
        handleLegendChange(legendBounds);
      }
    } else {
      const { legend: legendBounds, ...landscapeLayout } = getGraphLayoutColoredData(
        graph,
        coloringDataValue as ColoringData<DataColoringFunction>,
        type === VisualisationType.FEATURE_LANDSCAPE
      ) as LandscapeData;

      if (onDataUpdate) {
        onDataUpdate(landscapeLayout);
      }

      if (legendBounds) {
        handleLegendChange(legendBounds);
      }
    }
  };

  const handleLandscapeApplyColoring = useCallback(
    async (graph?: LandscapeData): Promise<void> => {
      if (!graph) {
        return;
      }

      const {
        colorOption,
        aggregationType,
        normalizationType,
        featureOption,
        featureType,
        diffOptionOne,
        diffOptionTwo,
      } = coloringOptions;

      if (isDefaultColoring(colorOption)) {
        setInitialColoringData(graphTaskRef.current);
        coloringDataRef.current = null;

        resetLegendState();
      } else {
        const isFeatureLandscape = type === VisualisationType.FEATURE_LANDSCAPE;
        const defaultOption = groups[groups.length - 1]; // TODO: Should be changed for default group when backend ready
        const coloringFunc = isFeatureLandscape ? getFeatureColoring : getDataColoring;
        const coloringDiffFunc = isFeatureLandscape ? getFeatureDiffColoring : getDataDiffColoring;
        const option =
          colorOption.id === COLORING_OPTIONS.other ? featureOption || defaultOption : colorOption;
        const isCategory = !isFeatureLandscape && featureType === 'category';
        const aggregation = isCategory ? AggregationType.CARTESIAN : aggregationType.id;
        const normalization = isCategory ? NormalizationType.RAW : normalizationType.id;
        const isDiffColoring = colorOption.id === COLORING_OPTIONS.differentialColoring;

        const coloringData = isDiffColoring
          ? ((await coloringDiffFunc(
              [diffOptionOne, diffOptionTwo],
              aggregation as AggregationType,
              normalization as NormalizationType,
              graph.params.mapper_matrix_config.cols
            )) as ColoringData)
          : ((await coloringFunc(
              option,
              aggregation as AggregationType,
              normalization as NormalizationType,
              graph.params.mapper_matrix_config.cols
            )) as ColoringData);

        if (coloringData && (coloringData.colorFunction || coloringData.colorData.length)) {
          coloringDataRef.current = coloringData;

          setLandscapeColoring(coloringData, graph, isCategory, legend.colorMap);
        } else {
          coloringDataRef.current = null;
          setInitialColoringData(graphTaskRef.current);

          resetLegendState();
        }
      }

      setBackupSettings(coloringOptions);
    },
    [
      isDefaultColoring,
      setInitialColoringData,
      type,
      coloringOptions,
      selectedDataRows,
      selectedFeatures,
    ]
  );

  useEffect(() => {
    if (retrieveGraphData) {
      const layout = getGraphLayoutData(retrieveGraphData.task_result) as LandscapeData;
      graphTaskRef.current = retrieveGraphData;
      landscapeRef.current = layout;

      handleLandscapeApplyColoring(layout);
    }
  }, [retrieveGraphData]);

  useEffect(() => {
    if (chart && isMounted.current) {
      handleChartApplyColoring(chart);
    }
  }, [chart]);

  const isColoringApplyEnabled = useMemo(() => {
    const { colorOption, featureOption, diffOptionOne, diffOptionTwo } = coloringOptions;
    const isOtherColoringEnabled = colorOption.id === COLORING_OPTIONS.other && !!featureOption;
    const isDiffColoringEnabled =
      colorOption.id === COLORING_OPTIONS.differentialColoring &&
      !!diffOptionOne &&
      !!diffOptionTwo;

    return isOtherColoringEnabled || isDiffColoringEnabled;
  }, [coloringOptions]);

  const restoreBackupSettings = () => {
    setColoringOptions((prevState) => ({
      ...prevState,
      colorOption: lastColorOption.current,
      aggregationType: lastAggregationType.current,
      normalizationType: lastNormalizationType.current,
      featureType: lastFeatureType.current || 'numeric',
      featureOption: lastFeatureOption.current,
      diffOptionOne: lastDiffOptionOne.current,
      diffOptionTwo: lastDiffOptionTwo.current,
    }));
  };

  const getColorFunctionValues = (prevFeatureType: string, featureType: string) => {
    const isLastNumeric = prevFeatureType === 'numeric';
    const isCurrentNumeric = featureType === 'numeric';

    if (isLastNumeric && !isCurrentNumeric) {
      return {
        featureType: 'category',
      };
    }

    if (!isLastNumeric && isCurrentNumeric) {
      return {
        featureType: 'numeric',
      };
    }

    return {};
  };

  const handleFeatureTypeChange = (isNumericFeatures: boolean, feature?: SelectedFeatureType) => {
    setColoringOptions((prevState) => {
      const colorFunctionValue = getColorFunctionValues(
        prevState.featureType,
        isNumericFeatures ? 'numeric' : 'category'
      );

      return {
        ...prevState,
        ...colorFunctionValue,
        selectType: feature ? 'coloring_modal' : 'selection',
        ...(feature ? { featureOption: feature } : {}),
      };
    });
  };

  useEffect(() => {
    const isSelectedFeaturesOption =
      coloringOptions.colorOption.id === COLORING_OPTIONS.selectedFeatures;
    const isNumericFeatures = selectedFeatures.some(
      (id) => features.find((f) => f.id === id)?.type === 'numeric'
    );

    if (isSelectedFeaturesOption) {
      if (selectedFeatures.length) {
        handleFeatureTypeChange(isNumericFeatures);
      } else if (coloringOptions.colorOption.id) {
        handleFeatureTypeChange(true);
      }
    }
  }, [coloringOptions.colorOption.id, selectedFeatures]);

  const isCurrentChartSelection = useMemo(
    () =>
      dataPointsSelectionSource?.source === SELECTION_SOURCES.SCATTER_PLOT &&
      dataPointsSelectionSource?.id === chart?.id,
    [chart?.id, dataPointsSelectionSource?.id, dataPointsSelectionSource?.source]
  );

  const handleDebouncedColoring = (
    selection: SelectionSourceType | null,
    option: COLORING_OPTIONS
  ): void => {
    const applyColoring = async (): Promise<void> => {
      const isLandscape = [
        VisualisationType.DATA_LANDSCAPE,
        VisualisationType.FEATURE_LANDSCAPE,
      ].includes(type);
      const isCurrentId = graphTaskRef.current?.visualisation_id === visualizationId;

      if (isLandscape && selection?.source && landscapeRef.current) {
        await handleLandscapeApplyColoring(landscapeRef.current);
      } else if (!isLandscape && !isCurrentChartSelection) {
        await handleChartApplyColoring(chart);
      } else if (isCurrentId) {
        setInitialColoringData();
        resetLegendState();
      }
    };

    if (coloringOptions.colorOption.id === option) {
      applyColoring();
    }
  };

  useDebounce(
    () => {
      if (isColoringApplyEnabled) {
        handleDebouncedColoring(featureSelectionSource, COLORING_OPTIONS.differentialColoring);
      }
    },
    400,
    [
      coloringOptions.colorOption.id,
      featureSelectionSource?.source,
      selectedFeatures,
      isColoringApplyEnabled,
    ]
  );

  useDebounce(
    () => {
      if (isColoringApplyEnabled) {
        handleDebouncedColoring(dataPointsSelectionSource, COLORING_OPTIONS.differentialColoring);
      }
    },
    400,
    [
      coloringOptions.colorOption.id,
      dataPointsSelectionSource?.source,
      selectedDataRows,
      isColoringApplyEnabled,
    ]
  );

  useDebounce(
    () => handleDebouncedColoring(featureSelectionSource, COLORING_OPTIONS.selectedFeatures),
    400,
    [
      coloringOptions.colorOption.id,
      featureSelectionSource?.source,
      selectedFeatures,
      coloringOptions.aggregationType.id,
      coloringOptions.normalizationType.id,
    ]
  );

  useDebounce(
    () => handleDebouncedColoring(dataPointsSelectionSource, COLORING_OPTIONS.selectedDataPoints),
    400,
    [
      coloringOptions.colorOption.id,
      dataPointsSelectionSource?.source,
      selectedDataRows,
      coloringOptions.aggregationType.id,
      coloringOptions.normalizationType.id,
    ]
  );

  useEffect(() => {
    if (coloringOptions.colorOption.id === COLORING_OPTIONS.none) {
      setInitialColoringData(graphTaskRef.current);
      resetLegendState();
    }
  }, [coloringOptions.colorOption.id]);

  const handleAggregationType = (option: OptionType) => {
    setColoringOptions((prevState) => ({
      ...prevState,
      aggregationType: option,
      selectType: 'coloring_modal',
    }));
  };

  const handleNormalizationType = (option: OptionType) => {
    setColoringOptions((prevState) => ({
      ...prevState,
      normalizationType: option,
      selectType: 'coloring_modal',
    }));
  };

  const handleColorOption = (option: SelectedFeatureType) => {
    setColoringOptions((prevState) => {
      const colorFunctionValue = getColorFunctionValues(prevState.featureType, 'numeric');

      return {
        ...prevState,
        colorOption: option,
        featureOption: option.id !== COLORING_OPTIONS.other ? null : prevState.featureOption,
        diffOptionOne:
          option.id !== COLORING_OPTIONS.differentialColoring ? null : prevState.diffOptionOne,
        diffOptionTwo:
          option.id !== COLORING_OPTIONS.differentialColoring ? null : prevState.diffOptionTwo,
        selectType: 'coloring_modal',
        ...colorFunctionValue,
      };
    });
  };

  const handleFeatureGroupApply = (option: SelectedFeatureType): void => {
    const group = groups.find((f) => f.id === option.id);

    if (group) {
      const isNumericFeatures = group.features.some(
        (id) => features.find((f) => f.id === id)?.type === 'numeric'
      );

      handleFeatureTypeChange(isNumericFeatures, option);
    }
  };

  const handleFeatureApply = (option: SelectedFeatureType) => {
    const isFeaturesGroup = option.groupType === 'features';

    if (isFeaturesGroup) {
      handleFeatureGroupApply(option);
    } else {
      setColoringOptions((prevState) => {
        const isDataGroup = option.groupType === 'rows';
        const newFeatureType = isDataGroup ? 'numeric' : option?.type;
        const colorFunctionValue = getColorFunctionValues(prevState.featureType, newFeatureType);

        return {
          ...prevState,
          featureOption: option,
          ...colorFunctionValue,
          selectType: 'coloring_modal',
        };
      });
    }
  };

  const handleDiffOptionApply = (
    optionType: keyof ColoringOptions,
    option: OptionType | SelectedFeatureType
  ) => {
    setColoringOptions((prevState) => {
      const updatedState = {
        ...prevState,
        [optionType]: option,
      };

      const selectedDiffFeatures = [updatedState.diffOptionOne, updatedState.diffOptionTwo].filter(
        (f) => !!f
      );

      const featureTypes = selectedDiffFeatures.map((f) => {
        if (f?.groupType === 'features') {
          const group = groups.find((g) => g.id === f.id);

          if (group) {
            const isNumeric = group.features.some(
              (id) => features.find((j) => j.id === id)?.type === 'numeric'
            );

            return isNumeric ? 'numeric' : 'category';
          }
        }

        if (f?.groupType === 'rows' || f.id === COLORING_OPTIONS.selectedDataPoints) {
          return 'numeric';
        }

        if (f.id === COLORING_OPTIONS.selectedFeatures) {
          const isNumericFeatures = selectedFeatures.some(
            (id) => features.find((ft) => ft.id === id)?.type === 'numeric'
          );

          return isNumericFeatures ? 'numeric' : 'category';
        }

        return f.type;
      });

      const newFeatureType = featureTypes.some((t) => t === 'numeric') ? 'numeric' : 'category';
      const colorFunctionValue = getColorFunctionValues(prevState.featureType, newFeatureType);

      return {
        ...updatedState,
        ...colorFunctionValue,
        selectType: 'coloring_modal',
      };
    });
  };

  const handleColoringOpen = (event: MouseEvent<HTMLDivElement>) =>
    setColoringAnchorEl(event.currentTarget);

  const handleColoringClose = () => {
    setColoringAnchorEl(null);

    const isOtherColoringDisabled =
      coloringOptions.colorOption.id === COLORING_OPTIONS.other && !coloringOptions.featureOption;

    const isDiffColoringDisabled =
      coloringOptions.colorOption.id === COLORING_OPTIONS.differentialColoring &&
      (!coloringOptions.diffOptionOne || !coloringOptions.diffOptionTwo);

    if (isOtherColoringDisabled || isDiffColoringDisabled) {
      restoreBackupSettings();
    } else {
      saveColoringConfig();
    }
  };

  useEffect(() => {
    const isCurrentId = retrieveGraphData?.visualisation_id === visualizationId;
    if (isColoringApplyEnabled && landscapeRef.current && isCurrentId) {
      handleLandscapeApplyColoring(landscapeRef.current);
    }
  }, [
    coloringOptions.diffOptionOne?.id,
    coloringOptions.diffOptionTwo?.id,
    coloringOptions.featureOption?.id,
    coloringOptions.aggregationType.id,
    coloringOptions.normalizationType.id,
    isColoringApplyEnabled,
  ]);

  useEffect(() => {
    if (isColoringApplyEnabled) {
      handleChartApplyColoring(chart);
    }
  }, [
    coloringOptions.diffOptionOne?.id,
    coloringOptions.diffOptionTwo?.id,
    coloringOptions.featureOption?.id,
    coloringOptions.aggregationType.id,
    coloringOptions.normalizationType.id,
    isColoringApplyEnabled,
  ]);

  const getHintTypeBySelected = (
    selected: number[],
    featureList: FeatureItemType[]
  ): string | null => {
    const selectedFeatureTypes = selected.map((id) => featureList.find((f) => f.id === id)?.type);
    const uniqueTypes = Array.from(new Set(selectedFeatureTypes));
    const hasNumeric = uniqueTypes.includes('numeric');
    const isDiff = uniqueTypes.length > 1;
    const hintType = hasNumeric ? 'numeric' : 'category';

    return isDiff ? hintType : null;
  };

  useEffect(() => {
    const { featureOption, diffOptionOne, diffOptionTwo, colorOption } = coloringOptions;

    if (colorOption.id === COLORING_OPTIONS.selectedFeatures) {
      const hintType = getHintTypeBySelected(selectedFeatures, features);

      setHintTextType(hintType);
    } else if (colorOption.id === COLORING_OPTIONS.differentialColoring) {
      const featureIds = [diffOptionOne, diffOptionTwo]
        .map((option) => {
          if (option?.groupType === 'features') {
            const group = groups.find((g) => g.id === option.id);

            return group?.features || [];
          }

          return option?.type ? [option?.id] : [];
        })
        .flat();

      const hintType = getHintTypeBySelected(featureIds, features);

      setHintTextType(hintType);
    } else if (
      colorOption.id === COLORING_OPTIONS.other &&
      featureOption?.groupType === 'features'
    ) {
      const group = groups.find((g) => g.id === featureOption?.id);

      if (group) {
        const hintType = getHintTypeBySelected(group.features, features);

        setHintTextType(hintType);
      }
    } else {
      setHintTextType(null);
    }
  }, [coloringOptions, selectedFeatures, features, groups]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const handleColorPicker = () => {
    setColorPicker((prevState) => !prevState);
  };

  const handleColorMapChange = (colorMapType: CategoricalColorMaps) => {
    handleLegendChange({ colorMap: colorMapType });

    const isCategory = coloringOptions.featureType === 'category';

    if (chart && coloringDataRef.current) {
      setChartColoring(coloringDataRef.current, chart, isCategory, colorMapType);
    } else if (landscapeRef.current && coloringDataRef.current) {
      setLandscapeColoring(coloringDataRef.current, landscapeRef.current, isCategory, colorMapType);
    }

    saveColoringConfig({ legend: { color_map: colorMapType } });
  };

  return {
    ...coloringOptions,
    legend,
    hintTextType,
    coloringAnchorEl,
    openColoring,
    showColorPicker,
    handleColoringOpen,
    handleColoringClose,
    handleColorOption,
    handleFeatureApply,
    handleAggregationType,
    handleNormalizationType,
    handleLegendChange,
    handleDiffOptionApply,
    handleColorPicker,
    handleColorMapChange,
  };
};

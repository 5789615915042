import React, { FC } from 'react';
import { Edit3, Trash2 } from 'react-feather';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

type ProjectActionsProps = {
  openDeleteDialog?: () => void;
  openEditDialog?: () => void;
};

export const ProjectActions: FC<ProjectActionsProps> = ({ openDeleteDialog, openEditDialog }) => (
  <Box width='170px'>
    <MenuItem onClick={openEditDialog}>
      <Edit3 />
      <span>Edit</span>
    </MenuItem>
    <MenuItem onClick={openDeleteDialog}>
      <Trash2 />
      <span>Delete</span>
    </MenuItem>
  </Box>
);

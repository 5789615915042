import { useCallback } from 'react';
import { setAlert, resetAlert, selectAlert } from 'app/store/modules/alert';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import type { Alert } from 'app/store/modules/alert';

type UseAlert = {
  alert: Alert;
  showSuccessMessage: (alert: Alert) => void;
  showErrorMessage: (alert: Alert) => void;
  resetAlert: () => void;
};

export const useAlert = (): UseAlert => {
  const dispatch = useAppDispatch();
  const alert = useAppSelector(selectAlert);

  const onResetAlert = useCallback(() => {
    dispatch(resetAlert());
  }, [dispatch]);

  const showSuccessMessage = useCallback(
    (alertContent: Alert) => {
      dispatch(
        setAlert({
          type: 'success',
          variant: 'filled',
          ...alertContent,
        })
      );
    },
    [dispatch]
  );

  const showErrorMessage = useCallback(
    (alertContent: Alert) => {
      dispatch(
        setAlert({
          type: 'error',
          variant: 'filled',
          withCloseOption: true,
          ...alertContent,
        })
      );
    },
    [dispatch]
  );

  return {
    alert,
    showErrorMessage,
    showSuccessMessage,
    resetAlert: onResetAlert,
  };
};

import { createEdgeWeightGetter } from 'graphology-utils/getters';
import type { ForceAtlas2LayoutParameters } from 'graphology-layout-forceatlas2';
import type Graph from 'graphology';
import { connectedComponents } from 'graphology-components';
import { PPN } from './workers/matrixProps';
import { iterateNoIntercomponentRepel } from './workers/iterateNoIntercomponentRepel';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { assignLayoutChanges, graphToByteArrays } = require('graphology-layout-forceatlas2/helpers');

// avoid TS errors from import statements when types are not provided.
// tried using declaration files but it didn't work

interface GraphByteArrays {
  nodes: Float32Array;
  edges: Float32Array;
}

type ForceAtlasAnimatorConfig = {
  maxiters: number;
  miniters: number;
  convergenceThreshold: number;
};

export const defaultSettings = {
  linLogMode: false,
  outboundAttractionDistribution: false,
  adjustSizes: false,
  edgeWeightInfluence: 1,
  scalingRatio: 1,
  strongGravityMode: false,
  gravity: 1,
  slowDown: 1,
  barnesHutOptimize: false,
  barnesHutTheta: 0.5,
};

export class ForceAtlasAnimator {
  graph: Graph;

  graph_components: string[][];

  params: ForceAtlas2LayoutParameters;

  frameID: number | null;

  running: boolean;

  getEdgeWeight: any;

  graphArrays?: GraphByteArrays;

  outputReducer: null;

  niters: number;

  maxiters: number;

  miniters: number;

  convergenceThreshold: number;

  constructor(
    graph: Graph,
    layoutParams: ForceAtlas2LayoutParameters,
    config: ForceAtlasAnimatorConfig
  ) {
    this.graph = graph;
    this.graph_components = connectedComponents(graph);
    this.params = layoutParams;
    this.frameID = null;
    this.running = false;
    // the default edge weight getter required by graphToByteArrays
    this.getEdgeWeight = createEdgeWeightGetter(undefined).fromEntry;
    this.graphArrays = graphToByteArrays(graph, this.getEdgeWeight);
    this.outputReducer = null;
    this.niters = 0;
    this.maxiters = config.maxiters;
    this.miniters = config.miniters;
    this.convergenceThreshold = config.convergenceThreshold;
  }

  isRunning(): boolean {
    return this.running;
  }

  resetNIters(): void {
    this.niters = 0;
  }

  updateNodePropsArrays(graphArrays: GraphByteArrays): GraphByteArrays {
    // updates the arrays of node positions and properties
    // must guarantee this.graphArrays is defined before calling
    // number of array entries per node
    let i = 0;
    const _graphArrays = graphArrays;
    this.graph.forEachNode((_, attr) => {
      if (attr.fixed) {
        _graphArrays.nodes[i] = attr.x;
        _graphArrays.nodes[i + 1] = attr.y;
      }
      // turns out that keeping the dx/dy state across time points makes for a fairly ugly animation.
      // probably makes it converge to a good stable state faster, though.
      _graphArrays.nodes[i + 2] = 0; // dx
      _graphArrays.nodes[i + 3] = 0; // dy
      _graphArrays.nodes[i + 4] = 0; // old_dx
      _graphArrays.nodes[i + 5] = 0; // old_dy
      _graphArrays.nodes[i + 9] = attr.fixed ? 1 : 0;
      i += PPN;
    });
    return _graphArrays;
  }

  hasConverged(): boolean {
    let i = 0;
    if (!this.graphArrays) {
      return false;
    }
    for (i = 0; i < this.graphArrays.nodes.length; i += PPN) {
      // this is called NODE_CONVERGENCE in the code, and is computed from the node speed, but I don't actually know what it means.
      if (this.graphArrays.nodes[i + 7] > this.convergenceThreshold) {
        return false;
      }
    }
    return true;
  }

  runFrame(): void {
    let graphArrays: GraphByteArrays;
    if (this.graphArrays) {
      graphArrays = this.updateNodePropsArrays(this.graphArrays);
    } else {
      graphArrays = graphToByteArrays(this.graph, this.getEdgeWeight);
      this.graphArrays = graphArrays;
    }
    if (this.params.settings !== undefined) {
      iterateNoIntercomponentRepel(
        this.params.settings,
        graphArrays.nodes,
        graphArrays.edges,
        this.graph_components
      );
      assignLayoutChanges(this.graph, graphArrays.nodes, this.outputReducer);
      this.niters += 1;
      // TODO: add convergence check. there is a nodewise "convergence" value whose meaning I don't understand
      if (this.niters > this.maxiters) {
        this.stop();
      } else if (this.niters > this.miniters && this.hasConverged()) {
        this.stop();
      } else {
        this.frameID = window.requestAnimationFrame(() => this.runFrame());
      }
    } else {
      console.error(`No Settings Provided`);
      // This is very unexpected.
      this.stop();
    }
  }

  stop(): unknown {
    this.running = false;
    this.resetNIters();
    if (this.frameID !== null) {
      window.cancelAnimationFrame(this.frameID);
      this.frameID = null;
    }
    return this;
  }

  start(): void {
    if (this.running) return;
    this.running = true;
    this.runFrame();
  }

  kill(): void {
    this.stop();
    delete this.graphArrays;
  }
}

import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { X } from 'react-feather';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Transition } from 'app/mui/Transition';
import { FormTextArea } from 'app/shared/components/FormTextArea/FormTextArea';
import { Button } from 'app/mui/Button';
import { TextFieldForm } from 'app/mui/TextFieldForm';
import { ProjectActionsPayload } from '../Projects.types';

type ProjectActionsDialogProps = {
  title: string;
  id: number;
  name: string;
  description?: string;
  open: boolean;
  is_private: boolean;
  handleClose: () => void;
  onSubmit: (values: ProjectActionsPayload) => Promise<void>;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'The length of project name must be 100 characters or fewer')
    .required('Project name is required'),
  description: Yup.string().max(
    500,
    'The length of project description must be 500 characters or fewer'
  ),
});

export const ProjectActionsDialog: FC<ProjectActionsDialogProps> = ({
  open,
  title,
  name,
  description,
  id,
  handleClose,
  is_private,
  onSubmit,
}) => {
  const projectNameLabel = title === 'Edit Project' ? 'Project name' : 'New project name';

  const projectDescriptionLabel =
    title === 'Edit Project' ? 'Project description' : 'New project description';

  const isProjectCreation = title === 'Create Project';

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '841px',
        },
      }}
    >
      <Formik
        initialValues={{
          name,
          description: description || '',
          type: is_private,
          id,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values).then(() => {
            setSubmitting(false);
          });
        }}
      >
        {({ submitForm, setFieldTouched }) => (
          <Form>
            <Box p={6} pt={7}>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='h5'>{title}</Typography>
                <X onClick={handleClose} size={24} cursor='pointer' />
              </Box>
              <Field
                required
                component={TextFieldForm}
                label={projectNameLabel}
                type='text'
                onBlur={() => setFieldTouched('name', true)}
                onFocus={() => setFieldTouched('name', false)}
                name='name'
                placeholder={projectNameLabel}
                sx={{ mt: 4 }}
              />
              <Field
                component={FormTextArea}
                id='description'
                label={projectDescriptionLabel}
                placeholder={projectDescriptionLabel}
                name='description'
                sx={{ mt: 3, mb: 4 }}
              />
              <Box display='flex' justifyContent={isProjectCreation ? 'end' : 'space-between'}>
                {!isProjectCreation && (
                  <Button variant='text' onClick={handleClose}>
                    Cancel
                  </Button>
                )}

                <Button variant='contained' onClick={submitForm}>
                  {isProjectCreation ? 'Create Project' : 'Save'}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import type Graph from 'graphology';
import { SigmaContainer, useSigma } from '@react-sigma/core';
import { Settings as SigmaSettings } from 'sigma/settings';
import '@react-sigma/core/lib/react-sigma.min.css';
import './GraphWidget.scss';

import type { ForceAtlasAnimator } from './ForceAtlasAnimator';
import { GraphLoader } from './GraphLoader';
import { GraphLasso } from './GraphLasso';
import { GraphControls } from './GraphControls';

const LASSO_MODIFIER_KEY = 'Alt';

export type GraphWidgetInternalsProps = {
  graph: Graph;
  animator: ForceAtlasAnimator;
  animate: boolean;
  selectedNodes: number[];
  setSelectedNodes: (nodes: number[]) => void;
  addSelectedNodes: (nodes: number[]) => void;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  shouldLayoutUpdate?: boolean;
  setShouldLayoutUpdate?: (shouldLayoutUpdate: boolean) => void;
  clearSigma: boolean;
  setClearSigma: (clearSigma: boolean) => void;
};

export const GraphWidgetInternals: FC<GraphWidgetInternalsProps> = ({
  graph,
  animate,
  animator,
  selectedNodes,
  setSelectedNodes,
  addSelectedNodes,
  isFullScreen,
  toggleFullScreen,
  shouldLayoutUpdate,
  setShouldLayoutUpdate,
  clearSigma,
  setClearSigma,
}) => {
  const [isLassoActive, setIsLassoActive] = useState(false);

  const [isLassoActiveDueToModifierKey, setIsLassoActiveDueToModifierKey] = useState(false);

  const sigma = useSigma();
  // used when size of graph canvas changes (eg data explorer resizes)
  useEffect(() => {
    if (shouldLayoutUpdate && setShouldLayoutUpdate) {
      sigma.scheduleRefresh();
      setShouldLayoutUpdate(false);
    }
  }, [shouldLayoutUpdate, setShouldLayoutUpdate, sigma]);

  // graph canvas also changes size when we enter/exit fullscreen
  useEffect(() => {
    sigma.scheduleRefresh();
  }, [sigma, isFullScreen]);

  // used to restore graph when WebGL contexts have been lost
  useEffect(() => {
    if (sigma && clearSigma) {
      sigma.clear();
      setClearSigma(false);
    }
  }, [clearSigma, setClearSigma, sigma]);

  const handleLassoModifierDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === LASSO_MODIFIER_KEY && !isLassoActive) {
        setIsLassoActive(true);
        setIsLassoActiveDueToModifierKey(true);
      }
    },
    [isLassoActive, setIsLassoActive, setIsLassoActiveDueToModifierKey]
  );

  const handleLassoModifierUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === LASSO_MODIFIER_KEY && isLassoActiveDueToModifierKey) {
        setIsLassoActive(false);
        setIsLassoActiveDueToModifierKey(false);
      }
    },
    [isLassoActiveDueToModifierKey, setIsLassoActive, setIsLassoActiveDueToModifierKey]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleLassoModifierDown);
    return () => {
      document.removeEventListener('keydown', handleLassoModifierDown);
    };
  }, [handleLassoModifierDown]);

  useEffect(() => {
    document.addEventListener('keyup', handleLassoModifierUp);
    return () => {
      document.removeEventListener('keyup', handleLassoModifierUp);
    };
  }, [handleLassoModifierUp]);

  const onClearSelection = useCallback(() => {
    setSelectedNodes([]);
  }, [setSelectedNodes]);

  const onInvertSelection = useCallback(() => {
    const newSelectedNodes: number[] = [];
    graph.forEachNode((node) => {
      const nodeID = Number(node);
      if (!selectedNodes.some((n) => n === nodeID)) {
        newSelectedNodes.push(nodeID);
      }
    });
    setSelectedNodes(newSelectedNodes);
  }, [selectedNodes, setSelectedNodes]);

  return (
    !!graph &&
    !!animator && (
      <>
        <GraphLoader
          graph={graph}
          animator={animator}
          animate={animate}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          addSelectedNodes={addSelectedNodes}
        />
        <GraphLasso
          active={isLassoActive}
          isFullScreen={isFullScreen}
          setSelectedNodes={setSelectedNodes}
          addSelectedNodes={addSelectedNodes}
        />
        <GraphControls
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          isLassoActive={isLassoActive}
          setIsLassoActive={setIsLassoActive}
          onClearSelection={onClearSelection}
          onInvertSelection={onInvertSelection}
        />
      </>
    )
  );
};

export type GraphWidgetProps = GraphWidgetInternalsProps & {
  containerStyle: Partial<CSSProperties>;
  sigmaSettings: Partial<SigmaSettings>;
};

export const GraphWidget: FC<GraphWidgetProps> = ({ containerStyle, sigmaSettings, ...props }) => (
  <SigmaContainer id='graph-container' style={containerStyle} settings={sigmaSettings}>
    <GraphWidgetInternals {...props} />
  </SigmaContainer>
);

import React, { FC, ReactElement } from 'react';
import { Home } from 'react-feather';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { RoutePath, useNavigate } from 'app/navigation';
import { HEADER_HEIGHT } from 'app/shared/styles/constants';
import { Profile } from './Profile/Profie';
import { Logo } from '../Logo/Logo';
import { HeaderWrapper } from './Header.styles';

type HeaderProps = {
  children?: ReactElement;
  isTransparent?: boolean;
  withShadow?: boolean;
  withHome?: boolean;
  withUser?: boolean;
};

export const Header: FC<HeaderProps> = ({
  children,
  isTransparent,
  withShadow,
  withHome,
  withUser,
}) => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper className={classNames({ transparent: isTransparent, shadow: withShadow })}>
      <Box
        px={3}
        width='100%'
        display='flex'
        justifyContent='space-between'
        height={`${HEADER_HEIGHT}px`}
        sx={{
          img: {
            marginRight: 6,
          },
        }}
      >
        <Box display='flex' alignItems='center'>
          <Box>
            <Logo variant='icon' />
          </Box>
          {withHome && <Home cursor='pointer' onClick={() => navigate(RoutePath.PROJECTS)} />}
          {children}
        </Box>
        <Box display='flex' alignItems='center'>
          {withUser && <Profile />}
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

import React, { FC } from 'react';
import styled from '@emotion/styled/macro';
import { StatusEnum } from 'app/shared/enum/status';

const getBackground = (
  status: string
): {
  background: string;
  color: string;
} => {
  if (status === StatusEnum.RAW) {
    return {
      background: '#D7EBE0',
      color: '#33455F',
    };
  }
  if (status === StatusEnum.PROCESSED) {
    return {
      background: '#414C64',
      color: '#fff',
    };
  }
  if (status === StatusEnum.FAILED || status === StatusEnum.UPLOAD_FAILED) {
    return {
      background: '#A70101',
      color: '#fff',
    };
  }

  return {
    background: '#414C64',
    color: '#fff',
  };
};

type StatusProps = {
  status: string;
};

const Wrapper = styled.p`
  display: inline-block;
  padding: 4px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 1px;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: ${(props: StatusProps) => getBackground(props.status).background};
  color: ${(props: StatusProps) => getBackground(props.status).color};
`;

export const Status: FC<StatusProps> = ({ status }) => <Wrapper status={status}>{status}</Wrapper>;

export enum DATASET_STATUSES {
  INIT = 'initial',
  UPLOAD_FAILED = 'upload_failed',
  UPLOADED = 'uploaded',
  PROCESSING = 'processing',
  METADATA_STARTED = 'metadata_extraction_started',
  METADATA_FINISHED = 'metadata_extraction_finished',
  TRANSFORM_STARTED = 'transformation_extraction_started',
  TRANSFORM_FINISHED = 'transformation_extraction_finished',
  PROCESSED = 'processed',
  CANCELED = 'canceled',
}

export enum DATASET_DOMAIN {
  GENOMICS = 'Genomics (Gene Expression)',
  IMAGES = 'Images',
  MIXED = 'Mixed',
}

export enum DATASET_UPLOADING_STATUS {
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  METADATA_EXTRACTION = 'Metadata extraction',
}

export enum FAILED_UPLOAD_STATUSES {
  UPLOADING_FAILED = 'Uploading failed',
  PUBLISHING_FAILED = 'Publishing failed',
}

import { createBreakpoint } from 'react-use';

export const useBreakpoint = createBreakpoint({
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1710,
  mobile: 0,
  tablet: 640,
  laptop: 1024,
  desktop: 1200,
  desktopLarge: 1440,
});

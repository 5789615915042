import { Box, keyframes, styled } from '@mui/material';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoaderBox = styled(Box)`
  width: 100%;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    animation: ${rotate} 2s linear infinite;
  }
`;

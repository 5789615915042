import React, { MouseEvent, ReactElement, ReactNode, FC, useState } from 'react';
import { Box } from '@mui/material';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';

type NestedMenuProps = {
  parentMenuOpen: boolean;
  disabled?: boolean;
  MenuButton: ReactElement;
  children: ReactNode;
  menuClassName?: string;
};

export const NestedMenu: FC<NestedMenuProps> = ({
  parentMenuOpen,
  disabled,
  MenuButton,
  menuClassName,
  children,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorEl);

  const open = openMenu && parentMenuOpen;

  const handleMouseEnter = (e: MouseEvent<HTMLElement>): void => {
    if (!disabled) {
      setMenuAnchorEl(e.currentTarget);
    }
  };
  const handleMouseLeave = (): void => {
    if (!disabled) {
      setMenuAnchorEl(null);
    }
  };

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {MenuButton}
      <DotsMenu
        style={{ pointerEvents: 'none' }}
        className={menuClassName}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        open={open}
        anchorEl={menuAnchorEl}
        onClose={handleMouseLeave}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
      >
        <Box style={{ pointerEvents: 'auto' }}>{children}</Box>
      </DotsMenu>
    </Box>
  );
};

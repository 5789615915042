import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { ANALYSIS_STATUSES, COLORING_OPTIONS } from 'app/shared/enum/analysis';
import { Edge, NodeAttributes } from 'app/shared/components/GraphWidget/types';
import { CategoricalColorMaps, ColorLegendType } from './Coloring/Coloring.types';
import { VisualisationType } from './AnalysisSidebar/Configure/Configure';
import { Dataset } from '../DataLibrary/DataLibrary.types';

export enum SIDEBAR_TABS {
  CONFIGURE = 0,
  EXPLORE = 1,
}

export enum COLORMAP_TYPE {
  sequential = 'sequential',
  diverging = 'diverging',
  discrete = 'discrete',
}

export enum AggregationType {
  MEAN = 'mean',
  MEDIAN = 'median',
  MINIMUM = 'minimum',
  MAXIMUM = 'maximum',
  CARTESIAN = 'cartesian',
}

export enum NormalizationType {
  RAW = 'raw',
  ZSCORE = 'z-score',
  UNIT = 'unit',
  QUANTILE = 'quantile',
}

export type FilterType = {
  method?: string;
  ecc_p?: number;
  column_idx?: number | null;
  n_bins: number;
  bin_method?: string;
  pruning_method?: string;
  pruning_threshold?: number;
  filter_values_idx?: number;
  max_link_distance: number;
  equalize_histogram: boolean;
  parameter?: string;
  group_id?: number;
  group_name?: string;
  metadata_name?: string;
  id?: number;
};

export type MapperMatrixConfigType = {
  transpose: boolean;
  group_id: number;
  cols: number[];
  rows: number[];
  features_group_id: number;
  rows_group_id: number;
};

export type NeighborGraphConfigType = {
  use_default: boolean;
  distance_metric: string;
  K: number | null;
  M: number | null;
  min_neighbors: number | null;
};

export type NerveGraphConfig = {
  L_components: number;
  L_cover: number;
};

export type ColoringLegend = {
  visible: boolean;
  color_map?: CategoricalColorMaps;
  values?: Record<string, string>;
};

export type SelectedColoringValue = any[];

export type OtherColoringValue = {
  type: COLORING_OPTIONS | null;
  features?: number;
  feature_group?: number;
  rows_group?: number;
  feature_type?: string;
};

export type DiffColoringValue = {
  firstSource?: OtherColoringValue;
  secondSource?: OtherColoringValue;
  feature_type?: string;
};

export type ColoringConfig = {
  aggregation_function: AggregationType;
  color_value: NormalizationType;
  coloring_type: COLORING_OPTIONS;
  legend: ColoringLegend;
  value: any;
  visualisation_type: VisualisationType;
};

export type ConfigType = {
  mapper_matrix_config: MapperMatrixConfigType;
  neighbor_graph_config: NeighborGraphConfigType;
  nerve_graph_config: NerveGraphConfig;
  filters: Array<FilterType>;
  coloring?: ColoringConfig;
};

export enum VisualisationsStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  RETRY = 'retrying',
  RETRY_FAILED = 'retry_failed',
  FAILED = 'failed',
}

export type AnalysisConfig = {
  id: number;
  analysis_id: number;
  change_history_id: number;
  name: string;
  config: ConfigType;
  status: VisualisationsStatus;
  graph_id: string;
  type: VisualisationType;
  created_at: string;
  updated_at?: string;
  error?: string;
};

export type HighlightedVisualizationType = {
  id: number;
  panel: string;
};

export type CreateGraphType = {
  analysis_id: number;
  type: string;
  name: string;
  panel?: string;
  config: {
    mapper_matrix_config?: {
      group_id?: number;
      rows?: number[];
      cols?: number[];
      rows_group_id?: number | null;
      features_group_id?: number | null;
    };
    neighbor_graph_config: NeighborGraphConfigType;
    nerve_graph_config: NerveGraphConfig;
    filters?: Array<FilterType>;
  };
};

export type EditGraphType = {
  visualisation_id: number;
  analysis_id: number;
  name: string;
  config: {
    mapper_matrix_config?: {
      transpose?: boolean;
      group_id?: number;
      rows?: number[];
      cols?: number[];
      rows_group_id?: number | null;
      features_group_id?: number | null;
    };
    neighbor_graph_config: NeighborGraphConfigType;
    nerve_graph_config: NerveGraphConfig;
    filters?: Array<FilterType>;
  };
};

export type LandscapeVisualizationType = {
  transpose: boolean;
  rows: number[];
  cols: number[];
  rows_group_id?: number | null;
  features_group_id?: number | null;
};

export type ChartConfigType = {
  data_points: number[];
  feature_ids: number[];
  chart_type: VisualisationType;
  data_filters:
    | {
        column_id: number;
        operator: string;
        value: number | string;
      }[]
    | number[];
  rows_group_id?: number | null;
  coloring?: ColoringConfig;
};

export type ChartDataType = {
  id: number;
  change_history_id: number;
  config: ChartConfigType;
  status: string;
  name: string;
  default_name: string;
  error: string | null;
  graph_id: string | null;
  created_at: string;
  updated_at?: string;
  type: VisualisationType;
  features: {
    feature_id: number;
    feature_name: string;
    values: string[];
  }[];
};

export type GroupType = {
  id: number;
  name: string;
  type: GroupCreationEnum.ROWS | GroupCreationEnum.FEATURES;
  analysis_id: number;
  rows: number[];
  features: number[];
  is_default: boolean;
  has_missing: boolean;
  created_at: string;
  updated_at: string;
};

export type newGroupType = {
  name: string;
  type: string;
};

export type DatasetMetadata = {
  id: number;
  dataset_id: number;
  size: number;
  type_of_file: string;
  extra_metadata: {
    num_of_columns?: number;
    num_of_rows?: number;
    extension?: string;
    min_size?: number;
    max_size?: number;
    avg_size?: number;
    num_of_files?: number;
  };
};

export type Pan = {
  id?: number;
  type?: VisualisationType;
  shouldReload?: boolean;
  is_copy?: boolean;
  coloring?: ColoringConfig;
  error?: string;
};

export type PanConfigResponse = Record<string, Pan>;

export type PanConfig = (Pan & { panel: string })[];

export type PanMenuListItem = {
  id: number;
  name: string;
  type: VisualisationType;
  created_at: string;
  updated_at?: string;
};

export type AnalysisType = {
  id: number;
  name: string;
  description?: string;
  latest_feature_group: string;
  cleaning_steps: string;
  updated_at: string;
  status: ANALYSIS_STATUSES;
  error: string;
  project_id: number;
  created_at: string;
  groups: GroupType[];
  dataset: Dataset;
  creator: {
    firstname: string;
    lastname: string;
  };
  config: {
    default: {
      data_landscape: ConfigType;
      feature_landscape: ConfigType;
    };
    panels: PanConfigResponse;
    visual: {
      data_landscape: AnalysisConfig[];
      feature_landscape: AnalysisConfig[];
      chart: {
        scatter_plot?: ChartDataType[];
        heatmap?: ChartDataType[];
        violin_plot?: ChartDataType[];
        histogram?: ChartDataType[];
      };
    };
  };
};

export type DataExplorerType = {
  columns: Array<{
    column_id: number;
    name: string;
  }>;
  rows: Array<{
    column_id: number | string;
    value: number;
    row_index: number;
  }>;
  rows_page_index: number;
  rows_size: number;
  rows_total: number;
  columns_page_index: number;
  columns_size: number;
  columns_total: number;
};

export type FeatureType = {
  id: number;
  feature_name: string;
  has_missing: boolean;
  type: string;
  count: number;
  unique?: number;
  mode: string;
  freq?: number;
  mean: number;
  std: number;
  min: number;
  percent_25: number;
  percent_50: number;
  percent_75: number;
  max: number;
  non_nan: number;
  nan: number;
  rowType: string;
  analysisId: number;
};

export type FeatureExplorerType = {
  rows: Array<FeatureType>;
  rows_page_index: number;
  rows_total: number;
  rows_size: number;
};

export type AnalysisPayload = {
  id: number;
  name?: string;
  description?: string;
  onSuccess?: () => void;
};

export type EditAnalysis = {
  id: number;
  name: string;
  description: string;
};

export interface LandscapeNodeAttributes extends NodeAttributes {
  data: number[];
  colored_label: string | undefined;
}

export type LandscapeNode = {
  key: number;
  attributes: LandscapeNodeAttributes;
};

export type LandscapeGraphPayload = {
  n_nodes: number;
  nodes: number[][];
  edges: Array<Edge>;
  params: {
    mapper_matrix_config: MapperMatrixConfigType;
    neighbor_graph_config: NeighborGraphConfigType;
    nerve_graph_config: NerveGraphConfig;
    filters: Array<FilterType>;
  };
};

export type LandscapeData = {
  n_nodes: number;
  nodes: LandscapeNode[];
  edges: Array<Edge>;
  legend?: Pick<
    ColorLegendType,
    'type' | 'colorBounds' | 'dataBounds' | 'functionName' | 'categories' | 'colorMap'
  >;
  params: {
    mapper_matrix_config: {
      transpose: boolean;
      rows: number[];
      cols: number[];
    };
    neighbor_graph_config: NeighborGraphConfigType;
    nerve_graph_config: NerveGraphConfig;
    filters: Array<FilterType>;
  };
};
export type RetrieveGraphType = {
  task_status: string;
  visualisation_id: number;
  task_result: LandscapeGraphPayload;
};

export type CreateChartType = {
  feature_ids: number[];
  data_points?: number[];
  chart_type: VisualisationType;
  data_filters:
    | {
        column_id: number;
        operator: string;
        value: number | string;
      }
    | [];
  rows_group_id?: number;
  panel?: string;
  name?: string;
  default_name?: string;
};

export type EditChartType = {
  name: string;
  default_name?: string;
  config: ChartConfigType;
};

export type DataColoringType = {
  feature_ids: number[] | [];
  aggregation_function: AggregationType;
  feature_normalization: NormalizationType;
};

export type FeatureColoringType = DataColoringType & {
  data_points: number[] | [];
};

export type DataColoringResponseFunction = Record<
  string,
  number | 'NaN' | 'Infinity' | '-Infinity' | null | string
>;
export type DataColoringFunction = Record<string, number | null>;
export type CategoricalDataColoringFunction = Record<string, string | null>;

export type NodeColoringFunction = (number | null)[];
export type CategoricalNodeColoringFunction = (string | null)[];

export type NodeColors = {
  nodeColors: string[];
  dataBounds: [number, number];
  colorBounds: string[];
  colorMapType: COLORMAP_TYPE;
  rawNodeValues: (number | null)[];
};

export type RawDataColoringResponseType = {
  function_type: string;
  function_name: string;
  function: DataColoringResponseFunction | Record<string, DataColoringResponseFunction>;
};

export type DataColoringResponseType<T = DataColoringFunction> = {
  function_type: string;
  function_name: string;
  function: T;
};

export type ColoringData<T = any> = {
  colorBy: COLORING_OPTIONS;
  colorFunction: T;
  colorFunctionName?: string;
  colorData: number[];
  scale: COLORMAP_TYPE;
};

export type ColoringRequestParams = {
  datasetId: number;
  featureGroupId: string;
};

export type DataPointsType = {
  id: string | number;
  feature_name: string;
};

export type GroupFeatureType = {
  id: string | number;
  feature_name: string;
  groupType: string;
};

export type SelectedFeatureType = FeatureType | DataPointsType | GroupFeatureType | any;

export type FeatureItemType = Pick<
  FeatureType,
  'id' | 'feature_name' | 'has_missing' | 'min' | 'max' | 'type'
>;

export enum SELECTION_SOURCES {
  LANDSCAPE = 'landscape',
  EXPLORER = 'explorer',
  SCATTER_PLOT = 'scatter-plot',
  HISTOGRAM = 'histogram',
  GROUP = 'group',
}

export type SelectionSourceType = {
  source: SELECTION_SOURCES;
  id?: number;
  name?: string;
};

export type OptionType = {
  id: NormalizationType | AggregationType;
  label: string;
};

export type exportCSVType = {
  analysisId: number;
  groupId?: number;
  groupName?: string;
  featureIds?: number[];
  dataPointIds?: number[];
};

export type exportJSONType = {
  analysisId: number;
  visualizationId: number;
  visualizationName: string;
  projectName: string;
  analysisName: string;
};

export type VisualizationsMapType = {
  [VisualisationType.DATA_LANDSCAPE]: AnalysisConfig[];
  [VisualisationType.FEATURE_LANDSCAPE]: AnalysisConfig[];
  [VisualisationType.SCATTER_PLOT]: ChartDataType[];
  [VisualisationType.VIOLIN_PLOT]: ChartDataType[];
  [VisualisationType.HEATMAP]: ChartDataType[];
  [VisualisationType.HISTOGRAM]: ChartDataType[];
};

export type ComparativeStatisticsPayload = {
  id: number;
  test_type: string;
  in_group: number[];
  comparison_group: string;
  fixed_subset?: number[];
};

export type ComparativeStatistics = {
  columns: {
    column_id: number;
    name: string;
  }[];
  rows: {
    [key: number]: number;
    row_index: string;
  }[];
  sidedness: string;
  test_type: string;
  columns_total: number;
  rows_total: number;
  textual_description: string;
};

export type DescriptiveStatisticsPayload = {
  id: number;
  dataPoints: number[];
};

export type DescriptiveStatistics = {
  columns: {
    column_id: number;
    name: string;
  }[];
  rows: {
    [key: number]: number;
    row_index: string;
  }[];
  rows_total: number;
  columns_total: number;
};

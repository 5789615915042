import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import difference from 'lodash/difference';
import { ChevronLeft } from 'react-feather';
import { Box, Divider, Typography } from '@mui/material';

import { Button } from 'app/mui/Button';
import { StyledTextField } from 'app/mui/TextField';
import { ChartConfigType, ChartDataType } from 'app/screens/Analysis/Analysis.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { Tooltip } from 'app/mui/Tooltip';
import { getDefaultDataPoints } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/SourceData.utils';
import { ScatterPlotSourceDataDialog } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/ScatterPlotSourceDataDialog';
import { HeatmapSourceDataDialog } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/HeatmapSourceDataDialog';
import { HistogramSourceDataDialog } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/HistogramSourceDataDialog';
import { ViolinPlotSourceDataDialog } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/ViolinPlotSourceDataDialog';
import { VisualizationPopover } from 'app/shared/components/VisualizationPopover/VisualizationPopover';
import { useConfigureForms } from './ConfigureForms.hooks';
import { maxNameLength, maxLengthHelperText, nameLimitTooltipText } from './ConfigureLandscape';
import { chartNameMapper, VisualisationType } from '../Configure';
import { DefaultNameWrapper, GroupNameWrapper } from '../ConfigureNameWrapper';

type ConfigureChartProps = {
  analysisId: number;
  handleCloseConfiguration: () => void;
  selectedVisualization: ChartDataType;
};

export const ConfigureChart: FC<ConfigureChartProps> = ({
  analysisId,
  handleCloseConfiguration,
  selectedVisualization,
}) => {
  const { editChart, setChart, features, groups, reloadVisualization, clearSelection } =
    useAnalysis();

  const [isScatterPlotSourceDataModalOpen, setIsScatterPlotSourceDataModalOpen] = useState(false);
  const [isHeatmapSourceDataModalOpen, setIsHeatmapSourceDataModalOpen] = useState(false);
  const [isHistogramSourceDataModalOpen, setIsHistogramSourceDataModalOpen] = useState(false);
  const [isViolinPlotSourceDataModalOpen, setIsViolinPlotSourceDataModalOpen] = useState(false);
  const [visualizationName, setVisualizationName] = useState<string>(selectedVisualization.name);
  const [visualizationDefaultName, setVisualizationDefaultName] = useState<string>(
    selectedVisualization.default_name
  );
  const [chartConfig, setChartConfig] = useState<ChartConfigType>(selectedVisualization.config);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isNoFreeSlot, setIsNoFreeSlot] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const { type } = selectedVisualization;

  const openSourceDataModal = (): void => {
    if (type === VisualisationType.SCATTER_PLOT) {
      setIsScatterPlotSourceDataModalOpen(true);
    }
    if (type === VisualisationType.HEATMAP) {
      setIsHeatmapSourceDataModalOpen(true);
    }
    if (type === VisualisationType.HISTOGRAM) {
      setIsHistogramSourceDataModalOpen(true);
    }
    if (type === VisualisationType.VIOLIN_PLOT) {
      setIsViolinPlotSourceDataModalOpen(true);
    }
  };

  const featureX = features?.find((feature) => feature?.id === chartConfig.feature_ids[0]);

  const featureY = features?.find((feature) => feature?.id === chartConfig.feature_ids[1]);

  const scatterPlotRowGroupId = chartConfig?.rows_group_id;

  const heatmapRowGroupId = chartConfig?.rows_group_id;

  const violinPlotRowGroupId = chartConfig?.rows_group_id;

  const histogramRowGroupId = chartConfig?.rows_group_id;

  const featureHeatmap = features?.filter(
    (feature) => feature && chartConfig?.feature_ids.includes(feature.id)
  ).length;

  const featureHistogram = features?.find((feature) => feature?.id === chartConfig?.feature_ids[0]);

  const featureViolinPlot = features?.filter(
    (feature) => feature && chartConfig?.feature_ids.includes(feature.id)
  ).length;

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    setVisualizationName(event.target.value);
    setIsUpdated(true);
  };

  const closeScatterPlotSourceDataModal = useCallback(() => {
    setIsScatterPlotSourceDataModalOpen(false);
  }, []);

  const closeHeatmapSourceDataModal = useCallback(() => {
    setIsHeatmapSourceDataModalOpen(false);
  }, []);

  const closeHistogramSourceDataModal = useCallback(() => {
    setIsHistogramSourceDataModalOpen(false);
  }, []);

  const closeViolinPlotSourceDataModal = useCallback(() => {
    setIsViolinPlotSourceDataModalOpen(false);
  }, []);

  const { openVisualization, handleOpenPopover, handleClose } = useConfigureForms({
    selectedVisualization,
    analysisId,
    setAnchorEl,
    setIsNoFreeSlot,
  });

  const updateChart = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleOpenPopover(event);
    try {
      if (chartConfig) {
        const response = await editChart(Number(analysisId), selectedVisualization.id, {
          name: visualizationName,
          config: chartConfig,
          default_name: visualizationDefaultName,
        });

        if (response) {
          setIsUpdated(false);
          setChart(type, response);
          reloadVisualization(selectedVisualization.id, true);
          clearSelection(selectedVisualization.id, selectedVisualization.type);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDialogSave = (data: ChartConfigType): void => {
    setChartConfig(data);

    const isHistogram = type === VisualisationType.HISTOGRAM;
    const isFeaturesChanged = difference(
      data.feature_ids,
      selectedVisualization.config.feature_ids
    );
    const isDefaultName = visualizationName === visualizationDefaultName;

    if (isHistogram && isFeaturesChanged.length && isDefaultName) {
      const prevFeature = features?.find(
        (feature) => feature?.id === selectedVisualization.config.feature_ids[0]
      );
      const newFeature = features?.find((feature) => feature?.id === data.feature_ids[0]);
      const updatedName =
        prevFeature && newFeature
          ? visualizationName.replace(prevFeature.feature_name, newFeature.feature_name)
          : visualizationName;

      setVisualizationName(updatedName);
      setVisualizationDefaultName(updatedName);
    }

    setIsUpdated(true);
  };

  const isDisabledByName = visualizationName.length > maxNameLength;

  return (
    <>
      <Box mt={3} mb={2} px={4}>
        <Box
          display='flex'
          alignItems='center'
          mb={3}
          onClick={handleCloseConfiguration}
          sx={{ cursor: 'pointer' }}
        >
          <ChevronLeft />
          <Typography
            variant='subtitle1'
            ml={1}
            sx={{ maxWidth: '200px', overflowWrap: 'break-word' }}
          >
            {selectedVisualization.name}
          </Typography>
        </Box>
        <StyledTextField
          color='secondary'
          error={visualizationName.length > maxNameLength}
          helperText={maxLengthHelperText(visualizationName.length)}
          placeholder='Visualization name'
          label='Visualization name'
          value={visualizationName}
          onChange={handleChangeName}
          fullWidth
        />
      </Box>
      <Box px={4}>
        <Divider />
      </Box>
      <Box mx={4} flexGrow={1} display='flex' flexDirection='column' justifyContent='space-between'>
        <Box my={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Typography variant='subtitle1'>Source Data</Typography>
            <Button variant='outlined' size='small' onClick={openSourceDataModal}>
              Modify
            </Button>
          </Box>
          <Box mt={2}>
            {type === VisualisationType.SCATTER_PLOT && (
              <>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Feature x</Typography>
                  <Box>{featureX && DefaultNameWrapper(featureX.feature_name)}</Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Feature y</Typography>
                  <Box>{featureY && DefaultNameWrapper(featureY.feature_name)}</Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='caption'>Data points</Typography>
                  <Box>
                    {scatterPlotRowGroupId ? (
                      GroupNameWrapper(
                        getDefaultDataPoints(groups, chartConfig, scatterPlotRowGroupId) as string
                      )
                    ) : (
                      <>
                        {getDefaultDataPoints(groups, chartConfig, scatterPlotRowGroupId)} selected
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
            {type === VisualisationType.HEATMAP && (
              <>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Features</Typography>
                  <Box>
                    {featureHeatmap && featureHeatmap > 0 && <>{featureHeatmap} selected</>}
                  </Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='caption'>Data points</Typography>
                  <Box>
                    {heatmapRowGroupId ? (
                      GroupNameWrapper(
                        getDefaultDataPoints(groups, chartConfig, heatmapRowGroupId) as string
                      )
                    ) : (
                      <>{getDefaultDataPoints(groups, chartConfig, heatmapRowGroupId)} selected</>
                    )}
                  </Box>
                </Box>
              </>
            )}
            {type === VisualisationType.VIOLIN_PLOT && (
              <>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Features</Typography>
                  <Box>
                    {featureViolinPlot && featureViolinPlot > 0 && (
                      <>{featureViolinPlot} selected</>
                    )}
                  </Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='caption'>Data points</Typography>
                  <Box>
                    {violinPlotRowGroupId ? (
                      GroupNameWrapper(
                        getDefaultDataPoints(groups, chartConfig, violinPlotRowGroupId) as string
                      )
                    ) : (
                      <>
                        {getDefaultDataPoints(groups, chartConfig, violinPlotRowGroupId)} selected
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
            {type === VisualisationType.HISTOGRAM && (
              <>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                  <Typography variant='caption'>Feature</Typography>
                  <Box>{featureHistogram && DefaultNameWrapper(featureHistogram.feature_name)}</Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography variant='caption'>Data points</Typography>
                  <Box>
                    {histogramRowGroupId ? (
                      GroupNameWrapper(
                        getDefaultDataPoints(groups, chartConfig, histogramRowGroupId) as string
                      )
                    ) : (
                      <>{getDefaultDataPoints(groups, chartConfig, histogramRowGroupId)} selected</>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box position='sticky' bottom={0} py={2}>
          <Tooltip
            title={visualizationName.length > maxNameLength ? nameLimitTooltipText : ''}
            placement='top-start'
          >
            <Box>
              <Button
                onClick={(event) => updateChart(event)}
                aria-describedby={selectedVisualization.id.toString()}
                size='large'
                variant='contained'
                disabled={!isUpdated || isDisabledByName}
                fullWidth
              >
                Update {chartNameMapper[type]}
              </Button>
            </Box>
          </Tooltip>

          <VisualizationPopover
            open={open}
            isOpen={open}
            openVisualization={openVisualization}
            visualizationId={selectedVisualization.id.toString()}
            anchorEl={anchorEl}
            handleClose={handleClose}
            isNoFreeSlot={isNoFreeSlot}
            noFreeSlotText='This visualization has been updated, but it’s still hidden. To bring it back to
                    canvas select it from the menu in any of the visualization slots.'
            freeSlotText='This visualization has been updated, but it’s still hidden. Bring it to
                      canvas?'
          />
        </Box>
      </Box>
      {isScatterPlotSourceDataModalOpen && (
        <ScatterPlotSourceDataDialog
          type={type}
          openDialog={isScatterPlotSourceDataModalOpen}
          handleClose={closeScatterPlotSourceDataModal}
          handleSave={onDialogSave}
          visualization={chartConfig}
        />
      )}
      {isHeatmapSourceDataModalOpen && (
        <HeatmapSourceDataDialog
          type={type}
          openDialog={isHeatmapSourceDataModalOpen}
          handleClose={closeHeatmapSourceDataModal}
          handleSave={onDialogSave}
          visualization={chartConfig}
        />
      )}
      {isHistogramSourceDataModalOpen && (
        <HistogramSourceDataDialog
          type={type}
          openDialog={isHistogramSourceDataModalOpen}
          handleClose={closeHistogramSourceDataModal}
          handleSave={onDialogSave}
          visualization={chartConfig}
        />
      )}
      {isViolinPlotSourceDataModalOpen && (
        <ViolinPlotSourceDataDialog
          type={type}
          openDialog={isViolinPlotSourceDataModalOpen}
          handleClose={closeViolinPlotSourceDataModal}
          handleSave={onDialogSave}
          visualization={chartConfig}
        />
      )}
    </>
  );
};

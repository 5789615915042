import React, { FC } from 'react';
import { Check } from 'react-feather';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import datasetImage from 'app/assets/images/datasets/dataset-image.svg';
import { CroppedText } from 'app/shared/styles/common';
import { useDataLibrary } from 'app/screens/DataLibrary/DataLibrary.hooks';
import { StatusEnum } from 'app/shared/enum/status';
import { Tooltip } from 'app/mui/Tooltip';
import { Card } from './DatasetCardDialog.styles';
import { Status } from '../Status/Status';

type DatasetCardDialogProps = {
  id: number;
  name: string;
  status: string;
  selectDataset: (id: number, name: string) => void;
  selectedDatasetId?: number;
};

export const DatasetCardDialog: FC<DatasetCardDialogProps> = ({
  name,
  id,
  status,
  selectDataset,
  selectedDatasetId,
}) => {
  const theme = useTheme();
  const { showPublishedDataset } = useDataLibrary();

  return (
    <Card
      key={id}
      onClick={() => selectDataset(id, name)}
      className={classNames({ highlight: selectedDatasetId === id })}
      display='flex'
      alignItems='center'
    >
      <Box borderRadius='4px' height='51px' mr={1} component='img' src={datasetImage} alt={name} />
      <Box width='100%'>
        <Box
          mb={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          maxWidth='124px'
          height='20px'
        >
          <Tooltip title={name} placement='bottom-start'>
            <CroppedText maxWidth='170px' variant='caption' fontWeight='600' className='break-text'>
              {name}
            </CroppedText>
          </Tooltip>
          {selectedDatasetId === id && (
            <Box>
              <Check size='16' color={theme.palette.success.main} />
            </Box>
          )}
        </Box>
        <Box display='flex'>
          <Status status={showPublishedDataset(status) ? StatusEnum.RAW : status} />
        </Box>
      </Box>
    </Card>
  );
};

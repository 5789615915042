import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { Popup } from 'app/shared/components/Popup/Popup';
import { Order } from 'app/shared/components/SortSelect/SortSelect';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { getComparator } from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList';
import { EditAnalysis } from 'app/screens/Analysis/Analysis.types';
import { ProjectAnalysis } from 'app/screens/Projects/Projects.types';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { ANALYSIS_STATUSES } from 'app/shared/enum/analysis';
import { SelectedAnalysis } from '../AnalysisLayout';
import { EditAnalysisDialog } from '../EditAnalysisDialog/EditAnalysisDialog';
import { AnalysisItem } from '../AnalysisItem/AnalysisItem';
import { FailedAnalysisItem } from '../FailedAnalysisItem/FailedAnalysisItem';

type AnalysisListProps = {
  analysis?: ProjectAnalysis[];
  order: Order;
  orderBy: string;
  setSelectedAnalysis: Dispatch<SetStateAction<SelectedAnalysis>>;
  handlePreviewOpen: (id: number) => void;
  handlePreviewClose: () => void;
  selectedAnalysis: SelectedAnalysis;
  projectId: number;
};

export const AnalysisList: FC<AnalysisListProps> = ({
  analysis,
  order,
  orderBy,
  setSelectedAnalysis,
  handlePreviewOpen,
  handlePreviewClose,
  selectedAnalysis,
  projectId,
}) => {
  const { deleteAnalysis, editAnalysis } = useAnalysis();
  const { getProjectAnalyses } = useProjects();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const closeEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const onEditAnalysis = (values: EditAnalysis): void => {
    editAnalysis({
      id: values.id,
      name: values.name,
      description: values.description,
      onSuccess: closeEditDialog,
    });
    getProjectAnalyses(projectId);
    handlePreviewClose();
  };

  const closeDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const onDeleteAnalysis = (id: number): void => {
    deleteAnalysis(id, () => {
      getProjectAnalyses(projectId);
    });
    setIsDeleteDialogOpen(false);
    handlePreviewClose();
  };

  return (
    <Box>
      {analysis &&
        analysis
          .slice()
          .sort(getComparator(order, orderBy))
          .map((row, index) => {
            const labelId = `analysis-item-${index}`;

            if (row.status === ANALYSIS_STATUSES.FAILED) {
              return (
                <FailedAnalysisItem
                  key={row.id}
                  row={row}
                  labelId={labelId}
                  setSelectedAnalysis={setSelectedAnalysis}
                  setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                  selectedAnalysis={selectedAnalysis}
                  handlePreviewOpen={handlePreviewOpen}
                />
              );
            }
            return (
              <AnalysisItem
                key={row.id}
                row={row}
                labelId={labelId}
                setSelectedAnalysis={setSelectedAnalysis}
                setIsEditDialogOpen={setIsEditDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                selectedAnalysis={selectedAnalysis}
                handlePreviewOpen={handlePreviewOpen}
              />
            );
          })}
      {selectedAnalysis && selectedAnalysis.id && selectedAnalysis.name && (
        <>
          <EditAnalysisDialog
            open={isEditDialogOpen}
            id={selectedAnalysis.id}
            name={selectedAnalysis.name}
            description={selectedAnalysis.description}
            handleClose={closeEditDialog}
            onSubmit={onEditAnalysis}
          />
          <Popup
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            onConfirm={() => selectedAnalysis.id && onDeleteAnalysis(selectedAnalysis.id)}
            state='Delete'
            title={`Delete ${selectedAnalysis.name}?`}
            description='You will not be able to recover it.'
          />
        </>
      )}
    </Box>
  );
};

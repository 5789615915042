import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Check, PlusSquare } from 'react-feather';
import { Box, InputLabel, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import addToGroup from 'app/assets/images/analysis/add-to-group.svg';
import clearSelectionExplorer from 'app/assets/images/analysis/clear-selection-explorer.svg';
import resetToDefaultIcon from 'app/assets/images/analysis/icon-reset-to-default.svg';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { FormControl } from 'app/mui/FormControl';
import { MenuItemContent } from 'app/shared/components/SortSelect/SortSelect.styles';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { Checkbox } from 'app/mui/Checkbox';
import { VisualisationChart } from 'app/shared/enum/chart';
import { Pan } from 'app/screens/Analysis/Analysis.types';
import { Actions, Line } from './SelectionActions.styles';

type SelectionActionsProps = {
  isFeatureMenu?: boolean;
  setOpenMenuType: Dispatch<SetStateAction<string | undefined>>;
  showSelectedDataRows?: () => void;
  resetDataRows?: () => void;
  showSelectedFeatureRows: () => void;
  resetFeatures: () => void;
  isDataRowsChecked: boolean;
  isDataColumnsChecked: boolean;
  isFeatureRowsChecked: boolean;
  isDataExplorerTab: boolean;
  clearDataRowsSelections?: () => void;
  clearFeatureSelections?: () => void;
  isShowSelectedDataRows?: boolean;
  isShowSelectedFeatureRows?: boolean;
  isShowSelectedColumns?: boolean;
  openGroupModal: () => void;
  handleMenuClose: () => void;
};

const VisualisationOptions = {
  scatterPlot: {
    value: VisualisationChart.scatterPlot,
    name: 'Scatter Plot',
  },
  heatmap: {
    value: VisualisationChart.heatmap,
    name: 'Heatmap',
  },
  violinPlot: {
    value: VisualisationChart.violinPlot,
    name: 'Violin Plot',
  },
  histogram: {
    value: VisualisationChart.histogram,
    name: 'Histogram',
  },
};

export const SelectionActions: FC<SelectionActionsProps> = ({
  isDataRowsChecked,
  setOpenMenuType,
  isDataColumnsChecked,
  isFeatureRowsChecked,
  isDataExplorerTab,
  isFeatureMenu,
  showSelectedDataRows,
  resetDataRows,
  showSelectedFeatureRows,
  resetFeatures,
  clearDataRowsSelections,
  clearFeatureSelections,
  isShowSelectedDataRows,
  isShowSelectedFeatureRows,
  isShowSelectedColumns,
  openGroupModal,
  handleMenuClose,
}) => {
  const theme = useTheme();
  const [visualizationType, setVisualizationType] = useState<string>('');

  const {
    currentAnalysis,
    selectedFeatures,
    selectedDataRows,
    addChart,
    setChart,
    panConfig,
    setHighlightedVisualization,
  } = useAnalysis();

  useEffect(() => {
    if (isFeatureMenu) {
      setOpenMenuType(GroupCreationEnum.FEATURE_GROUP);
    } else setOpenMenuType(GroupCreationEnum.DATA_GROUP);
  }, [setOpenMenuType]);

  const colsCount = (): string => {
    if (isFeatureMenu) {
      if (isDataExplorerTab && selectedFeatures.length > 0) {
        return `${selectedFeatures.length} Cols`;
      }
      if (!isDataExplorerTab && selectedFeatures.length > 0) {
        return `${selectedFeatures.length} Rows`;
      }
      return 'All';
    }
    if (selectedDataRows.length > 0) {
      return `${selectedDataRows.length} Rows`;
    }
    return 'All';
  };

  const onVisualizationChange = (value: string): void => {
    setVisualizationType(value);
  };

  const showSelected = (): void => {
    if (showSelectedDataRows) {
      showSelectedDataRows();
    } else if (showSelectedFeatureRows) {
      showSelectedFeatureRows();
    }

    handleMenuClose();
  };

  const resetToDefault = (): void => {
    if (resetDataRows) {
      resetDataRows();
    } else {
      resetFeatures();
    }

    handleMenuClose();
  };

  const clearSelections = (): void => {
    if (clearDataRowsSelections) {
      clearDataRowsSelections();
    } else if (clearFeatureSelections) {
      clearFeatureSelections();
    }

    handleMenuClose();
  };

  const isChecked =
    (isDataExplorerTab && !isFeatureMenu && isDataRowsChecked) ||
    (isDataExplorerTab && isFeatureMenu && isDataColumnsChecked) ||
    (!isDataExplorerTab && isFeatureMenu && isFeatureRowsChecked);

  const isShowSelectedDisabled =
    (isFeatureMenu && isDataExplorerTab && selectedFeatures.length === 0) ||
    (isFeatureMenu && !isDataExplorerTab && selectedFeatures.length === 0) ||
    (!isFeatureMenu && isDataExplorerTab && selectedDataRows.length === 0) ||
    (!isFeatureMenu && !isDataExplorerTab);

  const isResetToDefaultDisabled =
    (!isFeatureMenu && !isDataExplorerTab) ||
    (isDataExplorerTab && !isFeatureMenu && !isShowSelectedDataRows) ||
    (isDataExplorerTab && isFeatureMenu && !isShowSelectedColumns) ||
    (!isDataExplorerTab && isFeatureMenu && !isShowSelectedFeatureRows);

  const addVisualisation = async (): Promise<void> => {
    if (!visualizationType) return;
    const freePanel = panConfig.find((panel: Pan) => !panel.id);

    const response = await addChart(currentAnalysis.id, {
      feature_ids: selectedFeatures,
      chart_type: visualizationType as VisualisationType,
      data_filters: [], // TODO: Should be filter data
      ...(freePanel ? { panel: freePanel.panel } : {}),
    });

    if (response) {
      if (freePanel) {
        const highlightedVis = {
          id: response.id,
          panel: freePanel.panel,
        };

        setHighlightedVisualization(highlightedVis);
      }

      setChart(visualizationType, response);
      handleMenuClose();
    }
  };

  return (
    <Actions>
      <Box p={1}>
        <Typography variant='caption'>Selected: {colsCount()}</Typography>
      </Box>
      <MenuItem onClick={showSelected} disabled={isShowSelectedDisabled}>
        <Checkbox
          checked={isChecked}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
          sx={{ width: 35, height: 35 }}
        />
        <span>Show Only Selected</span>
      </MenuItem>
      <MenuItem onClick={clearSelections} disabled={isShowSelectedDisabled}>
        <img src={clearSelectionExplorer} alt='Clear selection' />
        <span>Clear Selection</span>
      </MenuItem>
      <MenuItem onClick={resetToDefault} disabled={isResetToDefaultDisabled}>
        <img src={resetToDefaultIcon} alt='Reset to default' />
        <span>Reset to Default</span>
      </MenuItem>
      <Line />
      <MenuItem onClick={openGroupModal}>
        <img src={addToGroup} alt='Add to Group' />
        <span>Create Group</span>
      </MenuItem>

      {isFeatureMenu && (
        <>
          <Line />
          <Box p={1}>
            <FormControl size='small' fullWidth>
              <InputLabel id='visualisation-type'>Visualization type</InputLabel>
              <Select
                labelId='visualisation-type'
                value={visualizationType}
                disabled={selectedFeatures.length === 0}
                placeholder='Visualization type'
                label='Visualization type'
                onChange={(e) => onVisualizationChange(e.target.value)}
                sx={{
                  '& .MuiSelect-select svg': {
                    display: 'none',
                  },
                }}
              >
                <MenuItem
                  value={VisualisationOptions.scatterPlot.value}
                  disabled={selectedFeatures.length !== 2}
                >
                  <MenuItemContent>
                    {VisualisationOptions.scatterPlot.name}
                    {visualizationType === VisualisationOptions.scatterPlot.value && (
                      <Check size='24' color={theme.palette.success.main} />
                    )}
                  </MenuItemContent>
                </MenuItem>
                <MenuItem
                  value={VisualisationOptions.heatmap.value}
                  disabled={selectedFeatures.length < 2 || selectedFeatures.length > 5}
                >
                  <MenuItemContent>
                    {VisualisationOptions.heatmap.name}
                    {visualizationType === VisualisationOptions.heatmap.value && (
                      <Check size='24' color={theme.palette.success.main} />
                    )}
                  </MenuItemContent>
                </MenuItem>
                <MenuItem
                  value={VisualisationOptions.violinPlot.value}
                  disabled={selectedFeatures.length < 1 || selectedFeatures.length > 5}
                >
                  <MenuItemContent>
                    {VisualisationOptions.violinPlot.name}
                    {visualizationType === VisualisationOptions.violinPlot.value && (
                      <Check size='24' color={theme.palette.success.main} />
                    )}
                  </MenuItemContent>
                </MenuItem>
                <MenuItem
                  value={VisualisationOptions.histogram.value}
                  disabled={selectedFeatures.length !== 1}
                >
                  <MenuItemContent>
                    {VisualisationOptions.histogram.name}
                    {visualizationType === VisualisationOptions.histogram.value && (
                      <Check size='24' color={theme.palette.success.main} />
                    )}
                  </MenuItemContent>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <MenuItem onClick={addVisualisation} disabled={selectedFeatures.length === 0}>
            <PlusSquare />
            <span>Add Visualization</span>
          </MenuItem>
        </>
      )}
    </Actions>
  );
};

import React, { FC } from 'react';
import { Plus, ArrowLeft } from 'react-feather';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Button } from 'app/mui/Button';
import { UploadingDatasetStepType } from 'app/screens/DataLibrary/DataLibrary.types';
import { NoResultsWrapper, Thumb } from './NoResults.styles';
import { ProgressBar } from '../ProgressBar/ProgressBar';

type NoResultsProps = {
  thumb: string;
  title: string;
  description?: string;
  buttonText?: string;
  secondButtonText?: string;
  backButtonText?: string;
  onButtonClick?: () => void;
  onSecondButtonClick?: () => void;
  onBackButtonClick?: () => void;
  sampleButtonText?: string;
  onSampleButtonClick?: () => void;
  isDatasetUploading?: boolean;
  uploadingStatus?: UploadingDatasetStepType;
};

export const NoResults: FC<NoResultsProps> = ({
  thumb,
  title,
  description,
  buttonText,
  secondButtonText,
  onButtonClick,
  onSecondButtonClick,
  sampleButtonText,
  onSampleButtonClick,
  backButtonText,
  onBackButtonClick,
  isDatasetUploading,
  uploadingStatus,
}) => (
  <NoResultsWrapper>
    <Thumb>
      <img src={thumb} alt={title} />
    </Thumb>
    <Typography variant='h4' fontWeight='600' color='primary.main'>
      {title}
    </Typography>
    {description && <Typography mt={2}>{description}</Typography>}
    {isDatasetUploading && uploadingStatus && (
      <Box mt={4} width='596px'>
        <ProgressBar activeStatus={uploadingStatus} />
      </Box>
    )}
    <Box mt={6} mb={2}>
      {buttonText && (
        <Button startIcon={<Plus />} onClick={onButtonClick} size='large' variant='contained'>
          {buttonText}
        </Button>
      )}
      {secondButtonText && (
        <Button
          startIcon={<Plus />}
          onClick={onSecondButtonClick}
          size='large'
          variant='outlined'
          sx={{ ml: 2 }}
        >
          {secondButtonText}
        </Button>
      )}
      {backButtonText && (
        <Button
          startIcon={<ArrowLeft />}
          onClick={onBackButtonClick}
          size='large'
          variant='outlined'
          sx={{ ml: 2 }}
        >
          {backButtonText}
        </Button>
      )}
    </Box>
    {sampleButtonText && (
      <Button size='large' variant='text' onClick={onSampleButtonClick}>
        {sampleButtonText}
      </Button>
    )}
  </NoResultsWrapper>
);

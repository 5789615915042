import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { LOADING_STATE } from 'app/store/constants';
import type { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { LayoutEnum } from 'app/shared/enum/layout';
import { RootState } from '../store';

type DatasetSlice = {
  loading: LOADING_STATE;
  datasets: Dataset[];
  datasetSamples: Dataset[];
  layout: LayoutEnum.GRID | LayoutEnum.TABLE;
};

export const fetchDatasets = createAsyncThunk(
  'dataset/fetchDatasets',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('dataset/', {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDatasetSamples = createAsyncThunk(
  'dataset/fetchDatasetSamples',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('dataset/example', {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const initialState = {
  loading: LOADING_STATE.IDLE,
  datasets: [],
  datasetSamples: [],
  layout: LayoutEnum.TABLE,
} as DatasetSlice;

const datasetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    setDataLibraryLayout: (state, action) => {
      state.layout = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDatasets.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchDatasets.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchDatasets.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.datasets = action.payload;
    });
    builder.addCase(fetchDatasetSamples.pending, (state) => {
      state.loading = LOADING_STATE.PENDING;
    });
    builder.addCase(fetchDatasetSamples.rejected, (state) => {
      state.loading = LOADING_STATE.FAILURE;
    });
    builder.addCase(fetchDatasetSamples.fulfilled, (state, action) => {
      state.loading = LOADING_STATE.SUCCESS;
      state.datasetSamples = action.payload;
    });
  },
});

// actions
export const { setDataLibraryLayout } = datasetSlice.actions;

// selectors
export const selectDatasets = (state: RootState): Dataset[] => state.dataset.datasets;
export const selectDatasetSamples = (state: RootState): Dataset[] => state.dataset.datasetSamples;
export const selectDataLibraryLayout = (state: RootState): string => state.dataset.layout;

// reducer
export const datasetReducer = datasetSlice.reducer;

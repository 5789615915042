import React, { FC } from 'react';
import { Trash2 } from 'react-feather';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import failed from 'app/assets/images/visualization/failed-visualization.svg';
import { Button } from 'app/mui/Button';
import styled from '@emotion/styled/macro';

type FailedChartProps = {
  onDeleteChart: () => void;
  errorMessage: string;
};

const StyledFailedChart = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    maxWidth: '30%',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  '& p': {
    color: theme.palette.error.dark,
    width: '335px',
    textAlign: 'center',
  },
}));

export const FailedChart: FC<FailedChartProps> = ({ onDeleteChart, errorMessage }) => {
  const theme = useTheme();

  return (
    <Box height='100%'>
      <StyledFailedChart>
        <Box component='img' src={failed} alt='failed' />
        <Typography variant='body1'>{errorMessage}</Typography>
        <Button
          onClick={onDeleteChart}
          size='large'
          variant='outlined'
          color='error'
          sx={{ mt: 3 }}
        >
          <Trash2 style={{ marginRight: theme.spacing(1) }} /> Delete Visualization
        </Button>
      </StyledFailedChart>
    </Box>
  );
};

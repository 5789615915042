import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  ContentWrapper,
  EllipseBackground,
  PageContent,
  PageWrapper,
} from 'app/shared/styles/common';
import { Sidebar } from 'app/shared/components/Sidebar/Sidebar';
import { Header } from 'app/shared/components/Header/Header';
import { useParams } from 'app/navigation';
import { Datasets } from 'app/shared/components/Datasets/Datasets';
import { Popup } from 'app/shared/components/Popup/Popup';
import { NoResults } from 'app/shared/components/NoResults/NoResults';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import noResultsThumb from 'app/assets/images/thumbnails/no-results-datasets.svg';
import { DEFAULT_TIMEOUT } from 'app/shared/utils/timeout';
import { useAlert } from 'app/shared/hooks/useAlert';
import { LastJsonMessage, SOCKET_ENTITY, useSocket } from 'app/shared/hooks/useSocket';
import { ProjectOverviewLayout } from './ProjectOverviewLayout/ProjectOverviewLayout';
import { ProjectOverviewToolbar } from './ProjectOverviewToolbar/ProjectOverviewToolbar';
import { useProjects } from '../Projects/Projects.hooks';
import DataLibraryPreview from '../DataLibrary/DataLibraryPreview/DataLibraryPreview';
import { SelectedDataset } from '../DataLibrary/DataLibraryList/DataLibraryList';
import { AnalysisLayout, SelectedAnalysis } from '../Analysis/AnalysisLayout/AnalysisLayout';
import AnalysisPreview from '../Analysis/AnalysisLayout/AnalysisPreview/AnalysisPreview';
import { ProjectActionsDialog } from '../Projects/ProjectActionsDialog/ProjectActionsDialog';
import { ProjectActionsPayload } from '../Projects/Projects.types';
import { ManageDatasetAndAnalysis } from '../Analysis/ManageDatasetAndAnalysis/ManageDatasetAndAnalysis';
import { useAnalysis } from '../Analysis/Analysis.hooks';
import { isAnalysisFailed, isAnalysisReady } from '../Analysis/Analysis.utils';
import { AnalysisType } from '../Analysis/Analysis.types';
import { Dataset } from '../DataLibrary/DataLibrary.types';
import { useDataLibrary } from '../DataLibrary/DataLibrary.hooks';

export const ProjectOverview: FC = () => {
  const { id } = useParams();
  const { navigateToProjects } = useNavigation();
  const { showErrorMessage } = useAlert();

  const projectId = Number(id);

  const { project, analyses, getProject, editProject, deleteProject, getProjectAnalyses } =
    useProjects();
  const { showFailedDataset } = useDataLibrary();
  const { resetAnalysis } = useAnalysis();

  const [openDatasetPreview, setOpenDatasetPreview] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState<SelectedDataset>(undefined);
  const [openAnalysisPreview, setOpenAnalysisPreview] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState<SelectedAnalysis>(undefined);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);
  const [isDatasetModalOpen, setIsDatasetModalOpen] = useState(false);

  const handleDatasetPreviewOpen = useCallback(
    (datasetId: number, isExample: boolean | null): void => {
      setOpenAnalysisPreview(false);
      setSelectedAnalysis(undefined);
      setOpenDatasetPreview(true);
      setSelectedDataset((dataset) => ({
        ...dataset,
        id: undefined,
        previewId: datasetId,
        name: undefined,
        isSample: isExample || false,
        description: undefined,
      }));
    },
    []
  );

  const handleDatasetPreviewClose = useCallback((): void => {
    setOpenDatasetPreview(false);
    setSelectedDataset(undefined);
  }, []);

  const handleAnalysisPreviewOpen = useCallback((analysisId: number): void => {
    setOpenDatasetPreview(false);
    setSelectedDataset(undefined);
    setOpenAnalysisPreview(true);
    setSelectedAnalysis((analysis) => ({
      ...analysis,
      id: undefined,
      previewId: analysisId,
      name: undefined,
      description: undefined,
    }));
  }, []);

  const handleAnalysisPreviewClose = useCallback((): void => {
    setOpenAnalysisPreview(false);
    setSelectedAnalysis(undefined);
  }, []);

  const handlePreviewClose = useCallback((): void => {
    handleDatasetPreviewClose();
    handleAnalysisPreviewClose();
  }, []);

  const getProjectInfo = async (projectIdNumeric: number) => {
    await getProject(projectIdNumeric);
    await getProjectAnalyses(projectIdNumeric);
  };

  useEffect(() => {
    if (id) {
      getProjectInfo(projectId);
      handlePreviewClose();
    }

    return () => {
      resetAnalysis();
    };
  }, [id]);

  const { lastJsonMessage } = useSocket();

  useEffect(() => {
    if (lastJsonMessage) {
      const { type, payload } = lastJsonMessage as LastJsonMessage;

      if (type === SOCKET_ENTITY.DATASET) {
        const dataset = payload as Dataset;

        const isCurrentProject = dataset.projects?.some((item) => item.id === projectId);

        if (isCurrentProject) {
          if (showFailedDataset(dataset.status) && dataset.error) {
            showErrorMessage({ title: dataset.error });
          }

          getProjectInfo(projectId);
        }
      }

      if (type === SOCKET_ENTITY.ANALYSIS) {
        const analysis = payload as AnalysisType;

        const isCurrentProject = analysis.project_id === projectId;

        if (isCurrentProject) {
          if (isAnalysisReady(analysis.status) || isAnalysisFailed(analysis.status)) {
            getProjectAnalyses(projectId);

            if (analysis.error) {
              showErrorMessage({ title: analysis.error });
            }
          }
        }
      }
    }
  }, [lastJsonMessage]);

  const closeEditDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const handleSave = async (values: ProjectActionsPayload): Promise<void> => {
    editProject({
      id: values.id,
      name: values.name,
      is_private: values.type,
      description: values.description,
      onSuccess: closeEditDialog,
    });
  };

  const onNavigateToProjects = useCallback(() => {
    setTimeout(() => {
      navigateToProjects();
    }, DEFAULT_TIMEOUT);
  }, []);

  const onDeleteProject = (): void => {
    deleteProject(projectId, onNavigateToProjects);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const handleOpenAnalysisModal = useCallback(() => {
    setIsAnalysisModalOpen(true);
  }, []);

  const closeAnalysisModal = useCallback(() => {
    setIsAnalysisModalOpen(false);
  }, []);

  const handleOpenDatasetModal = useCallback(() => {
    setIsDatasetModalOpen(true);
  }, []);

  const closeDatasetModal = useCallback((reloadProject?: boolean) => {
    setIsDatasetModalOpen(false);

    if (reloadProject) {
      getProjectInfo(projectId);
    }
  }, []);

  return (
    <>
      <Header withUser />
      <PageWrapper>
        <Sidebar />
        <ContentWrapper>
          <PageContent>
            <EllipseBackground />
            {project && (
              <ProjectOverviewLayout
                heading={project.name}
                caption={project.description}
                isPrivate={project.is_private}
                toolbar={
                  <ProjectOverviewToolbar
                    setIsEditDialogOpen={setIsEditDialogOpen}
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                  />
                }
              >
                {project.datasets.length === 0 && project.analyses.length === 0 ? (
                  <NoResults
                    thumb={noResultsThumb}
                    title="This project doesn't contain any artifacts yet"
                    buttonText='Start New Analysis'
                    secondButtonText='Add Dataset'
                    onButtonClick={handleOpenAnalysisModal}
                    onSecondButtonClick={handleOpenDatasetModal}
                  />
                ) : (
                  <>
                    <Datasets
                      datasets={project.datasets}
                      selectedDataset={selectedDataset}
                      setSelectedDataset={setSelectedDataset}
                      handlePreviewOpen={handleDatasetPreviewOpen}
                      handlePreviewClose={handlePreviewClose}
                      projectId={projectId}
                      handleOpenDatasetModal={handleOpenDatasetModal}
                    />
                    <AnalysisLayout
                      analysis={analyses}
                      selectedAnalysis={selectedAnalysis}
                      setSelectedAnalysis={setSelectedAnalysis}
                      handlePreviewOpen={handleAnalysisPreviewOpen}
                      handlePreviewClose={handleAnalysisPreviewClose}
                      projectId={projectId}
                      handleOpenAnalysisModal={handleOpenAnalysisModal}
                    />
                  </>
                )}
              </ProjectOverviewLayout>
            )}
          </PageContent>
          {selectedDataset && (
            <DataLibraryPreview
              open={openDatasetPreview}
              onClose={handleDatasetPreviewClose}
              datasetId={selectedDataset.previewId}
              projectId={projectId}
              isSample={selectedDataset.isSample && selectedDataset.isSample}
            />
          )}
          {selectedAnalysis && (
            <AnalysisPreview
              open={openAnalysisPreview}
              onClose={handleAnalysisPreviewClose}
              analysisId={selectedAnalysis.previewId}
              projectId={projectId}
            />
          )}
        </ContentWrapper>
      </PageWrapper>
      {id && project && (
        <>
          {isAnalysisModalOpen && (
            <ManageDatasetAndAnalysis
              openDialog={isAnalysisModalOpen}
              handleClose={closeAnalysisModal}
              projectId={projectId}
              hasAnalysisNameInput
            />
          )}
          {isDatasetModalOpen && (
            <ManageDatasetAndAnalysis
              openDialog={isDatasetModalOpen}
              handleClose={closeDatasetModal}
              projectId={projectId}
            />
          )}
          <ProjectActionsDialog
            title='Edit Project'
            open={isEditDialogOpen}
            id={projectId}
            name={project.name}
            description={project.description}
            is_private={project.is_private}
            handleClose={closeEditDialog}
            onSubmit={handleSave}
          />
          <Popup
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            onConfirm={onDeleteProject}
            state='Delete'
            title={`Delete ${project.name}?`}
            description='You will not be able to recover it.'
          />
        </>
      )}
    </>
  );
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Plus, Search, ArrowLeft } from 'react-feather';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, DialogWrapper } from 'app/shared/styles/createForm';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { StyledTextField } from 'app/mui/TextField';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { Project } from 'app/screens/Projects/Projects.types';
import { StyledButton } from 'app/screens/Analysis/ManageDatasetAndAnalysis/ManageDatasetAndAnalysis.styles';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { Tooltip } from 'app/mui/Tooltip';
import { DEFAULT_TIMEOUT } from 'app/shared/utils/timeout';
import { UploadForm } from 'app/shared/components/UploadForm/UploadForm';
import { ProjectCardDialog } from '../ProjectCardDialog/ProjectCardDialog';
import { SearchNoResults } from '../SearchNoResults/SearchNoResults';

export const maxNameLength = 100;

export const maxLengthHelperText = (name: string, nameLength: number): string => {
  if (nameLength > maxNameLength) {
    return `The length of ${name} name must be ${maxNameLength} characters or fewer`;
  }
  return '';
};

export const disabledStyles = {
  pointerEvents: 'none',
  opacity: '0.6',
};

type CreateDatasetDialogProps = {
  openDialog: boolean;
  handleClose: () => void;
  selectedDataset?: { id: number; name: string };
};

export const CreateDatasetDialog: FC<CreateDatasetDialogProps> = ({
  openDialog,
  handleClose,
  selectedDataset,
}) => {
  const { createAnalysis } = useAnalysis();
  const { navigateToAnalysis } = useNavigation();
  const { projects, getProjects, addDatasetToProject, createProject } = useProjects();

  const [datasetName, setDatasetName] = useState('');
  const [analysisName, setAnalysisName] = useState('');

  const [isProjectCreation, setIsProjectCreation] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(undefined);
  const [projectNameSearch, setProjectNameSearch] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');

  const [isDatasetUploading, setIsDatasetUploading] = useState<boolean>(false);
  const [isDisabledField, setIsDisabledField] = useState<boolean>(false);
  const [uploadedDataset, setUploadedDataset] = useState<Dataset | undefined>(undefined);
  const [shouldCancelUpload, setShouldCancelUpload] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  const selectProject = (id: number): void => {
    if (id === selectedProjectId) {
      setSelectedProjectId(undefined);
    } else {
      setSelectedProjectId(id);
    }
  };

  const onUploadFailed = (): void => {
    setIsDisabledField(true);
  };

  const onResetAfterCancel = (): void => {
    setIsDatasetUploading(false);
    setIsDisabledField(false);
  };

  const onCreateProject = (): void => {
    setProjectNameSearch('');
    setSelectedProjectId(undefined);
    setIsProjectCreation(true);
  };

  const backToProjectSelection = (): void => {
    setProjectName('');
    setProjectDescription('');
    setIsProjectCreation(false);
  };

  const handleReset = (): void => {
    setProjectName('');
    setProjectNameSearch('');
    setProjectDescription('');
    setSelectedProjectId(undefined);
    setIsProjectCreation(false);
    setIsDatasetUploading(false);
    setShouldCancelUpload(false);
    setIsDisabledField(false);

    setTimeout(() => {
      handleClose();
    }, 50);
  };

  const isAnalysisNameValid = !!analysisName.trim() && analysisName.length <= maxNameLength;
  const isProjectNameValid = !!projectName.trim() && projectName.length <= maxNameLength;

  const currentAnalysisName = useMemo(() => {
    if (analysisName.length > 0 && isAnalysisNameValid) {
      return analysisName;
    }
    if (selectedDataset) {
      return selectedDataset.name;
    }
    return analysisName;
  }, [analysisName, selectedDataset]);

  const startAnalysis = async (): Promise<void> => {
    // new uploaded dataset and existing project
    if (selectedProjectId && uploadedDataset) {
      await addDatasetToProject(selectedProjectId, uploadedDataset.id);
      const response = await createAnalysis(selectedProjectId, uploadedDataset.id, datasetName);

      setTimeout(() => {
        navigateToAnalysis(selectedProjectId, response.id);
      }, DEFAULT_TIMEOUT);
    } else if (selectedProjectId && selectedDataset) {
      // existing dataset and existing project
      await addDatasetToProject(selectedProjectId, selectedDataset.id);
      const response = await createAnalysis(
        selectedProjectId,
        selectedDataset.id,
        currentAnalysisName
      );

      setTimeout(() => {
        navigateToAnalysis(selectedProjectId, response.id);
      }, DEFAULT_TIMEOUT);
    } else if (isProjectCreation && isProjectNameValid && uploadedDataset) {
      // new uploaded dataset and new project
      const newProject = (await createProject({
        name: projectName,
        description: projectDescription,
        onSuccess: () => {},
      })) as Project;

      await addDatasetToProject(newProject.id, uploadedDataset.id);
      const newAnalysis = await createAnalysis(
        newProject.id,
        uploadedDataset.id,
        uploadedDataset.name
      );

      setTimeout(() => {
        navigateToAnalysis(newProject.id, newAnalysis.id);
      }, DEFAULT_TIMEOUT);
    } else if (isProjectCreation && isProjectNameValid && selectedDataset) {
      // existing dataset and new project
      const newProject = (await createProject({
        name: projectName,
        description: projectDescription,
        onSuccess: () => {},
      })) as Project;
      await addDatasetToProject(newProject.id, selectedDataset.id);

      const newAnalysis = await createAnalysis(
        newProject.id,
        selectedDataset.id,
        currentAnalysisName
      );

      setTimeout(() => {
        navigateToAnalysis(newProject.id, newAnalysis.id);
      }, DEFAULT_TIMEOUT);
    }

    handleReset();
  };

  const filteredProjects = projects.filter((proj) =>
    proj.name.toLowerCase().includes(projectNameSearch.toLowerCase())
  );

  const isReadyToAssignProject = Boolean(
    uploadedDataset && ((isProjectCreation && isProjectNameValid) || selectedProjectId)
  );

  const uploadedDatasetWithExistingProject = Boolean(uploadedDataset && selectedProjectId);
  const uploadedDatasetWithNewProject = Boolean(uploadedDataset && isProjectNameValid);
  const existingDatasetWithExistingProject = Boolean(selectedDataset && selectedProjectId);
  const existingDatasetWithNewProject = Boolean(selectedDataset && isProjectNameValid);

  const isReadyToStartAnalysis =
    uploadedDatasetWithExistingProject ||
    uploadedDatasetWithNewProject ||
    existingDatasetWithExistingProject ||
    existingDatasetWithNewProject;

  const assignProject = async () => {
    if (isReadyToAssignProject) {
      if (selectedProjectId && uploadedDataset) {
        await addDatasetToProject(selectedProjectId, uploadedDataset.id);
      } else if (uploadedDataset && isProjectCreation) {
        const newProject = (await createProject({
          name: projectName,
          description: projectDescription,
          onSuccess: () => {},
        })) as Project;

        await addDatasetToProject(newProject.id, uploadedDataset.id);
      }
      handleClose();
    } else {
      handleReset();
    }
  };

  const onUploadStarted = (dataset: Dataset): void => {
    setIsDatasetUploading(true);
    if (!datasetName) {
      setDatasetName(dataset.name);
    }
  };

  const onUploadFinished = (dataset: Dataset | undefined): void => {
    setUploadedDataset(dataset);

    if (!dataset) {
      setDatasetName('');
    }
  };

  return (
    <DialogWrapper open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
      <Box width='1080px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              {selectedDataset ? 'Start Dataset Analysis' : 'Add Dataset'}
            </Typography>
            <StyledCloseIcon onClick={handleClose} size={24} />
          </Box>

          {!selectedDataset && (
            <>
              <Box display='flex' justifyContent='space-between' gap={2} width='100%' mt={3} mb={2}>
                <StyledTextField
                  error={datasetName.length > maxNameLength}
                  helperText={maxLengthHelperText('dataset', datasetName.length)}
                  color='secondary'
                  placeholder='New dataset name'
                  label='New dataset name'
                  value={datasetName}
                  fullWidth
                  disabled={isDatasetUploading || isDisabledField}
                  onChange={(e) => setDatasetName(e.target.value)}
                />
              </Box>
              <UploadForm
                isFormValid
                onUploadingStarted={onUploadStarted}
                onUploadFinished={onUploadFinished}
                onUploadFailed={onUploadFailed}
                onResetAfterCancel={onResetAfterCancel}
                datasetName={datasetName}
                shouldCancelUpload={shouldCancelUpload}
              />
            </>
          )}
          {selectedDataset && (
            <StyledTextField
              required
              helperText={maxLengthHelperText('analysis', analysisName.length)}
              color='secondary'
              placeholder='New analysis name'
              value={analysisName}
              onChange={(e) => setAnalysisName(e.target.value)}
              fullWidth
              sx={{
                mt: 3,
              }}
            />
          )}
          {(isDatasetUploading || selectedDataset) && (
            <Box
              mt={4}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              sx={isDisabledField ? disabledStyles : {}}
            >
              <Typography variant='subtitle1' display='flex'>
                {selectedDataset ? (
                  <>
                    Assign to project<Typography color='error.main'>*</Typography>
                  </>
                ) : (
                  'Assign to project (you can skip it if you are not going to start analysis now)'
                )}
              </Typography>
              {isProjectCreation ? (
                <StyledButton
                  width='214px'
                  variant='outlined'
                  className={classNames({ highlight: isProjectCreation })}
                  onClick={backToProjectSelection}
                >
                  <ArrowLeft size='24px' style={{ marginRight: '8px' }} />
                  <Typography variant='subtitle1' textAlign='left'>
                    Select Existing Project
                  </Typography>
                </StyledButton>
              ) : (
                <StyledButton
                  width='214px'
                  variant='outlined'
                  className={classNames({ highlight: isProjectCreation })}
                  onClick={onCreateProject}
                >
                  <Plus size='24px' style={{ marginRight: '8px' }} />
                  <Typography variant='subtitle1' textAlign='left'>
                    Create New Project
                  </Typography>
                </StyledButton>
              )}
            </Box>
          )}

          <Box>
            {(isDatasetUploading || selectedDataset) && (
              <Box sx={isDisabledField ? disabledStyles : {}}>
                <Box mt={3}>
                  {isProjectCreation ? (
                    <>
                      <StyledTextField
                        required
                        helperText={maxLengthHelperText('project', projectName.length)}
                        color='secondary'
                        placeholder='New project name'
                        label='New project name'
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        fullWidth
                        sx={{
                          mb: 2,
                        }}
                      />
                      <StyledTextField
                        multiline
                        rows={4}
                        id='description'
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                        fullWidth
                        placeholder='New project description'
                        label='New project description'
                      />
                    </>
                  ) : (
                    <Box>
                      <StyledTextField
                        id='outlined-start-adornment'
                        label='Search projects'
                        value={projectNameSearch}
                        placeholder='Search projects'
                        onChange={(e) => setProjectNameSearch(e.target.value)}
                        size='small'
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box display='flex' mt={2}>
                        {filteredProjects.length > 0 ? (
                          filteredProjects
                            .slice(0, 4)
                            .map(
                              (proj) =>
                                proj && (
                                  <ProjectCardDialog
                                    key={proj.id}
                                    id={proj.id}
                                    name={proj.name}
                                    is_private={proj.is_private}
                                    selectProject={selectProject}
                                    selectedProjectId={selectedProjectId}
                                  />
                                )
                            )
                        ) : (
                          <SearchNoResults text='No projects found' />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box display='flex' justifyContent='right' alignItems='center' mt={3}>
            {!selectedDataset && (
              <Button
                variant='outlined'
                onClick={assignProject}
                disabled={false}
                sx={{
                  mr: 3,
                }}
              >
                {isReadyToAssignProject ? 'Assign and Go to Data Library' : 'Go to Data Library'}
              </Button>
            )}
            {!isReadyToStartAnalysis ? (
              <Tooltip
                title={
                  uploadedDataset
                    ? 'Select or create a project where analysis will be saved'
                    : 'Upload a dataset and select or create a project where analysis will be saved'
                }
                placement='top-start'
              >
                <Box>
                  <Button disabled={!isReadyToStartAnalysis} variant='contained'>
                    {selectedDataset ? 'Start Analysis' : 'Assign and start Analysis'}
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Button onClick={startAnalysis} variant='contained'>
                {selectedDataset ? 'Start Analysis' : 'Assign and start Analysis'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
};

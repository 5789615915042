import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  '& .MuiAutocomplete-root': {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      padding: '3px',
    },
  },
}));

export const ChipBlock = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: '0 6px',
  borderRadius: '4px',
  border: '1px solid rgba(18, 34, 57, 0.7)',
  overflow: 'auto',
  '& .MuiChip-root': {
    background: theme.palette.blocksBackground.hover,
  },
}));

import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { X } from 'react-feather';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Transition } from 'app/mui/Transition';
import { FormTextArea } from 'app/shared/components/FormTextArea/FormTextArea';
import { Button } from 'app/mui/Button';
import { TextFieldForm } from 'app/mui/TextFieldForm';
import { EditDataset } from '../DataLibrary.types';

type EditDataLibraryDialogProps = {
  id: number;
  open: boolean;
  name: string;
  description?: string;
  handleClose: () => void;
  onSubmit: (values: EditDataset) => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'The length of dataset name must be 100 characters or fewer')
    .required('Dataset name is required'),
  description: Yup.string().max(
    500,
    'The length of dataset description must be 500 characters or fewer'
  ),
});

export const EditDataLibraryDialog: FC<EditDataLibraryDialogProps> = ({
  open,
  id,
  name,
  description,
  handleClose,
  onSubmit,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    sx={{
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '841px',
      },
    }}
  >
    <Formik
      initialValues={{
        name,
        description: description || '',
        id,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        handleClose();
        setSubmitting(false);
      }}
    >
      {({ submitForm, setFieldTouched }) => (
        <Form>
          <Box p={6} pt={7}>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h5'>Edit Dataset</Typography>
              <X onClick={handleClose} size={24} cursor='pointer' />
            </Box>
            <Field
              component={TextFieldForm}
              label='Dataset name'
              type='text'
              onBlur={() => setFieldTouched('name', true)}
              onFocus={() => setFieldTouched('name', false)}
              name='name'
              placeholder='Dataset name'
              sx={{ mt: 4 }}
            />

            <Field
              component={FormTextArea}
              id='description'
              placeholder='Dataset description'
              name='description'
              sx={{ mt: 3, mb: 4 }}
            />
            <Box display='flex' justifyContent='space-between'>
              <Button variant='text' onClick={handleClose}>
                Cancel
              </Button>

              <Button variant='contained' onClick={submitForm}>
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </Dialog>
);

import React, {
  createRef,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChevronDown, ChevronUp, Filter, Maximize, Minimize, Plus, X } from 'react-feather';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Divider } from '@mui/material';

import { LOADING_STATE } from 'app/store/constants';
import { Header } from 'app/shared/components/Header/Header';
import { AnalysisNavigation } from 'app/shared/components/Header/AnalysisNavigation/AnalysisNavigation';
import { Tab, TabPanel } from 'app/shared/components/Tabs/Tabs';
import { IconButton } from 'app/mui/IconButton';
import { Button } from 'app/mui/Button';
import { Tooltip } from 'app/mui/Tooltip';
import { useParams } from 'app/navigation';
import { useProjects } from 'app/screens/Projects/Projects.hooks';
import { StyledProgress } from 'app/shared/components/ProgressBar/ProgressBar.styles';
import { HEADER_HEIGHT } from 'app/shared/styles/constants';
import { PreviewCircular } from 'app/mui/CircularProgress';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { useAlert } from 'app/shared/hooks/useAlert';
import { useBreakpoint } from 'app/shared/hooks/useBreakpoint';
import type { LastJsonMessage } from 'app/shared/hooks/useSocket';
import { SOCKET_ENTITY, useSocket } from 'app/shared/hooks/useSocket';

import {
  EXPLORER_ROW_HEIGHT,
  EXPLORER_ROW_SIZE,
  EXPLORER_ROW_WIDTH,
  useAnalysis,
} from './Analysis.hooks';
import { DataExplorer } from './Explorer/DataExplorer';
import { FeatureExplorer, featureExplorerColumns } from './Explorer/FeatureExplorer';
import ExplorerFilter from './Explorer/components/ExplorerFilter';
import { AnalysisSidebar } from './AnalysisSidebar/AnalysisSidebar';
import {
  getOperatorDisplayText,
  isAtBottom,
  isFeatureTypeNumeric,
  isOperatorCategorical,
  useScrollDirection,
} from './Explorer/Explorer.utils';
import { LoadingMoreRows } from './Explorer/Explorer.styles';
import {
  AnalysisConfig,
  AnalysisType,
  DataExplorerType,
  FeatureExplorerType,
  SELECTION_SOURCES,
  SIDEBAR_TABS,
  VisualisationsStatus,
} from './Analysis.types';
import type {
  ExplorerBodyType,
  ExplorerFilterColumn,
  ExplorerFilterType,
  ExplorerParams,
  Operator,
} from './Explorer/Explorer.types';
import { AnalysisNoResults } from './AnalysisNoResults/AnalysisNoResults';
import {
  isAnalysisFailed,
  isAnalysisProcessing,
  isAnalysisReady,
  isAnalysisReadyForIngest,
} from './Analysis.utils';
import { Selections } from './Selections/Selections';
import {
  AnalysisChartWrapper,
  AnalysisContainer,
  AnalysisMainWrapper,
  AnalysisSidebarWrapper,
  DataLandscapeWrapper,
  ExplorerFilterWrapper,
  FiltersCountBadge,
  LoaderWrapper,
  Tabs,
} from './Analysis.styles';
import { VisualizationDialog } from './VisualizationDialog/VisualizationDialog';
import { VisualizationPan } from './VisualizationPan/VisualizationPan';
import { VisualisationType } from './AnalysisSidebar/Configure/Configure';
import { Dataset } from '../DataLibrary/DataLibrary.types';
import { DEFAULT_CHART_HEIGHT } from './Visualizations/Wrappers/Chart.config';

enum TABS {
  DATA_EXPLORER = 0,
  FEATURE_EXPLORER = 1,
}

enum PANEL_STATUS {
  initial = 0,
  full = 1,
  hidden = 2,
}

const DATA_EXPLORER_HEIGHT = 73;

// Additional 30px needed to move chart actions below to prevent overlapping with x-axis
const VISUALIZATION_PANEL_HEIGHT = DEFAULT_CHART_HEIGHT + 30;

export type DataExplorerFilterValue = {
  id: string;
  column: ExplorerFilterColumn;
  operator: Operator;
  value: string | string[];
};

export type FeatureExplorerFilterValue = {
  id: string;
  column: ExplorerFilterColumn;
  operator: Operator;
  value: string | string[];
};

export const Analysis: FC = () => {
  const {
    loading,
    currentAnalysis,
    features,
    getFeaturesList,
    getAnalysis,
    getDataExplorer,
    getFeatureExplorer,
    ingestDataset,
    resetAnalysis,
    setSelectedDataFilters,
    setSelectedFeatureFilters,
    selectedDataFilters,
    selectedFeatureFilters,
    selectedDataRows,
    selectedFeatures,
    setSelectedDataRows,
    setSelectedFeatures,
    setDataSelectionSource,
    setFeaturesSelectionSource,
    featureSelectionSource,
    dataPointsSelectionSource,
    panConfig,
    highlightedVisualization,
    setPanConfigToStore,
    setAnalysis,
    updateLandscape,
  } = useAnalysis();

  const [mainPanel, ...rightPanels] = panConfig;

  const { analysisId, projectId } = useParams();
  const analysisIdNumeric = Number(analysisId);
  const projectIdNumeric = Number(projectId);
  const breakpoint = useBreakpoint();
  const { showErrorMessage } = useAlert();

  const [tab, setTab] = useState(TABS.DATA_EXPLORER);
  const [sidebarTab, setSidebarTab] = useState(SIDEBAR_TABS.CONFIGURE);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [featureLoading, setFeatureLoading] = useState<boolean>(false);
  const [loadingMoreRows, setLoadingMoreRows] = useState<boolean>(false);
  const [loadingMoreColumns, setLoadingMoreColumns] = useState<boolean>(false);
  const [explorerStatus, setExplorerStatus] = useState(PANEL_STATUS.initial);
  const [dataExplorer, setDataExplorer] = useState<DataExplorerType | undefined>(undefined);
  const [featureExplorer, setFeatureExplorer] = useState<FeatureExplorerType | undefined>(
    undefined
  );
  const [filter, setFilter] = useState<boolean>(false);
  const [filterForm, setFilterForm] = useState<boolean>(false);
  const [dataFilterValues, setDataFilterValues] = useState<DataExplorerFilterValue[] | []>([]);
  const [featureFilterValues, setFeatureFilterValues] = useState<FeatureExplorerFilterValue[] | []>(
    []
  );
  const [isShowSelectedDataRows, setIsShowSelectedDataRows] = useState<boolean>(false);
  const [isDataRowsChecked, setIsDataRowsChecked] = useState<boolean>(false);
  const [isShowSelectedFeatureRows, setIsShowSelectedFeatureRows] = useState<boolean>(false);
  const [isFeatureRowsChecked, setIsFeatureRowsChecked] = useState<boolean>(false);
  const [isShowSelectedColumns, setIsShowSelectedColumns] = useState<boolean>(false);
  const [isDataColumnsChecked, setIsDataColumnsChecked] = useState<boolean>(false);

  const [isVisualizationModalOpen, setIsVisualizationModalOpen] = useState<boolean>(false);

  const [selectedDataIds, setSelectedDataIds] = useState<number[] | null>(null);
  const [selectedFeatureIds, setSelectedFeatureIds] = useState<number[] | null>(null);

  const [shouldLayoutUpdate, setShouldLayoutUpdate] = useState(false);
  const [currentPanel, setCurrentPanel] = useState<string | null>(null);
  const dataExplorerRowsMatrix = useRef<any[][] | null>(null);
  const dataExplorerColumnsMatrix = useRef<any[][] | null>(null);
  const isFirstRender = useRef(true);

  const { navigateToProjectOverview } = useNavigation();

  const visualizationRefs = rightPanels.reduce(
    (acc: any, value) => ({
      ...acc,
      [value.panel]: createRef(),
    }),
    {}
  );

  const scrollToVisualization = (id: string): void =>
    visualizationRefs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  useEffect(() => {
    if (highlightedVisualization && highlightedVisualization.panel !== mainPanel.panel) {
      scrollToVisualization(highlightedVisualization.panel);
    }
  }, [highlightedVisualization]);

  useEffect(() => {
    if (loading === LOADING_STATE.FAILURE) {
      navigateToProjectOverview(projectIdNumeric);
    }
  }, [loading, projectIdNumeric]);

  const { lastJsonMessage } = useSocket();

  useEffect(() => {
    if (lastJsonMessage) {
      const { type, payload } = lastJsonMessage as LastJsonMessage;

      if (type === SOCKET_ENTITY.DATASET) {
        const dataset = payload as Dataset;

        if (payload.id === currentAnalysis.dataset.id) {
          const analysis = {
            ...currentAnalysis,
            dataset,
          };

          setAnalysis(analysis);
        }
      }

      if (type === SOCKET_ENTITY.ANALYSIS) {
        const analysis = payload as AnalysisType;

        if (payload.id === analysisIdNumeric) {
          setAnalysis(analysis);

          setPanConfigToStore(analysis.config.panels);
        }
      }

      if (type === SOCKET_ENTITY.RETRIEVE_GRAPH) {
        const config = payload as AnalysisConfig;

        if (config.analysis_id === analysisIdNumeric) {
          const isRetry = config.status === VisualisationsStatus.RETRY;
          const isFailed = config.status === VisualisationsStatus.FAILED;
          const isRetryFailed = config.status === VisualisationsStatus.RETRY_FAILED;

          if (isRetry || isFailed || isRetryFailed) {
            updateLandscape(config.id, config.type, config);
          }
        }
      }
    }
  }, [lastJsonMessage, analysisIdNumeric]);

  const panelMaximized = useMemo(() => explorerStatus === PANEL_STATUS.full, [explorerStatus]);

  const panelHidden = useMemo(() => explorerStatus === PANEL_STATUS.hidden, [explorerStatus]);

  const panelInitial = useMemo(() => explorerStatus === PANEL_STATUS.initial, [explorerStatus]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setShouldLayoutUpdate(true);
  }, [filter, panelHidden, panelMaximized, panelInitial]);

  const getDataRowsFromMatrix = () =>
    dataExplorerRowsMatrix.current?.reduce(
      (acc, row) => [
        ...acc,
        ...row.reduce((acc2, row2) => {
          if (!row2) return acc2;

          const arr = acc2.length ? acc2 : [...acc2, ...row2];

          if (arr.length && row2.length) {
            return arr.map((a: any, i: number) => ({
              ...a,
              ...row2[i],
            }));
          }

          return arr;
        }, []),
      ],
      []
    );

  async function fetchDataExplorer(
    id: number,
    filters?: ExplorerBodyType,
    params?: ExplorerParams
  ): Promise<void> {
    try {
      setDataLoading(true);

      const response = (await getDataExplorer(id, filters, params)) as DataExplorerType;

      if (!dataExplorerRowsMatrix.current && !dataExplorerColumnsMatrix.current) {
        const rowsArrays = new Array(
          Math.max(Math.ceil(response.rows_total / EXPLORER_ROW_SIZE), 1)
        );
        const columnsArray = new Array(
          Math.max(Math.ceil(response.columns_total / EXPLORER_ROW_SIZE), 1)
        );
        const rowMatrixColumnsArray = [...columnsArray].fill(null);

        dataExplorerRowsMatrix.current = rowsArrays.fill(rowMatrixColumnsArray);
        dataExplorerColumnsMatrix.current = [...columnsArray].fill([]);
      }

      const updatedRow = [...dataExplorerRowsMatrix.current![response.rows_page_index - 1]];
      updatedRow[response.columns_page_index - 1] = response.rows;

      dataExplorerColumnsMatrix.current![response.columns_page_index - 1] = response.columns;
      dataExplorerRowsMatrix.current![response.rows_page_index - 1] = [...updatedRow];

      setDataExplorer({
        ...response,
        columns: dataExplorerColumnsMatrix.current!.flat(),
        rows: getDataRowsFromMatrix() as any,
      });

      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
    }
  }

  async function fetchFeatureExplorer(
    id: number,
    filters?: ExplorerBodyType,
    params?: ExplorerParams
  ): Promise<void> {
    try {
      setFeatureLoading(true);
      setFeatureExplorer(undefined);

      const response = (await getFeatureExplorer(id, filters, params)) as FeatureExplorerType;
      setFeatureExplorer(response);

      setFeatureLoading(false);
    } catch (error) {
      setFeatureLoading(false);
    }
  }

  const { getProject } = useProjects();

  useEffect(() => {
    getProject(projectIdNumeric);
    getAnalysis(analysisIdNumeric);
    setSelectedDataFilters([]);
    setSelectedFeatureFilters([]);

    return () => {
      resetAnalysis();
    };
  }, [analysisIdNumeric, projectIdNumeric]);

  useEffect(() => {
    if (isAnalysisFailed(currentAnalysis?.status)) {
      navigateToProjectOverview(projectIdNumeric);
      showErrorMessage({ title: currentAnalysis.error });
    }

    if (isAnalysisReadyForIngest(currentAnalysis?.status, currentAnalysis?.dataset?.status)) {
      ingestDataset(projectIdNumeric, currentAnalysis.dataset.id, analysisIdNumeric);
    }

    if (isAnalysisReady(currentAnalysis?.status)) {
      fetchDataExplorer(analysisIdNumeric);
      fetchFeatureExplorer(analysisIdNumeric);
      getFeaturesList(analysisIdNumeric);

      setPanConfigToStore(currentAnalysis.config.panels);
    }
  }, [
    analysisIdNumeric,
    projectIdNumeric,
    currentAnalysis?.status,
    currentAnalysis?.dataset?.status,
  ]);

  const isDataExplorerTab = tab === TABS.DATA_EXPLORER;

  const isFeatureExplorerTab = tab === TABS.FEATURE_EXPLORER;

  const handleTabChange = (event: React.SyntheticEvent, value: number): void => {
    setTab(value);
  };

  const maximizeExplorer = useCallback(() => {
    setExplorerStatus(PANEL_STATUS.full);
    setFilter(false);
  }, []);

  const hideExplorer = useCallback(() => {
    setExplorerStatus(PANEL_STATUS.hidden);
    setFilter(false);
  }, []);

  const resetExplorer = useCallback(() => {
    setExplorerStatus(PANEL_STATUS.initial);
    setFilter(false);
  }, []);

  useEffect(() => {
    if (dataExplorer && explorerStatus === PANEL_STATUS.hidden) {
      const result = [...dataExplorer.columns];
      result.shift();
      const updatedDataExplorer = {
        ...dataExplorer,
        columns: result,
      };
      setDataExplorer(updatedDataExplorer);
    }
  }, [explorerStatus]);

  useEffect(() => {
    const isFirstDataColumnPlaceholder = dataExplorer?.columns[0].column_id === -1;

    if (dataExplorer && !isFirstDataColumnPlaceholder) {
      const result = [...dataExplorer.columns];

      result.unshift({
        column_id: -1,
        name: '',
      });

      setDataExplorer((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            columns: [...result],
          };
        }

        return prevState;
      });
    }
  }, [dataExplorer]);

  const addFilter = useCallback(() => {
    setFilterForm(true);
  }, []);

  const cancelFilter = useCallback(() => {
    setFilterForm(false);
  }, []);

  const fetchFilters = (
    filterData: DataExplorerFilterValue[] | FeatureExplorerFilterValue[]
  ): void => {
    if (isDataExplorerTab) {
      const filters = filterData.map((filterValue: DataExplorerFilterValue) => ({
        column_id: filterValue.column.id,
        operator: filterValue.operator,
        value: isFeatureTypeNumeric(filterValue.column.type)
          ? Number(filterValue.value)
          : filterValue.value,
      })) as ExplorerFilterType[];
      setSelectedDataFilters(filters);
      dataExplorerRowsMatrix.current = null;
      dataExplorerColumnsMatrix.current = null;
      setDataExplorer(undefined);
      fetchDataExplorer(analysisIdNumeric, { data_filters: filters });
    } else if (isFeatureExplorerTab) {
      const filters = filterData.map((filterValue: FeatureExplorerFilterValue) => ({
        field_name: filterValue.column.id,
        operator: filterValue.operator,
        value: isFeatureTypeNumeric(filterValue.column.type)
          ? Number(filterValue.value)
          : filterValue.value,
      })) as ExplorerFilterType[];
      setSelectedFeatureFilters(filters);
      fetchFeatureExplorer(analysisIdNumeric, { data_filters: filters });
    }
  };

  const applyFilter = (column: any, operator: Operator, value: string): void => {
    const filterValue = {
      id: uuidv4(),
      column,
      operator,
      value: isOperatorCategorical(operator) ? value.split(', ') : value,
    };
    if (isDataExplorerTab) {
      setDataFilterValues((prevState: DataExplorerFilterValue[]) => [...prevState, filterValue]);
      fetchFilters([...dataFilterValues, filterValue]);
    } else if (isFeatureExplorerTab) {
      setFeatureFilterValues((prevState: FeatureExplorerFilterValue[]) => [
        ...prevState,
        filterValue,
      ]);
      fetchFilters([...featureFilterValues, filterValue]);
    }
    setFilterForm(false);
  };

  const onDeleteFilterValue = (
    filterItem: DataExplorerFilterValue | FeatureExplorerFilterValue
  ): void => {
    if (isDataExplorerTab) {
      const updatedFilterValues = dataFilterValues.filter(
        (filterValue: DataExplorerFilterValue | FeatureExplorerFilterValue) =>
          filterValue.id !== filterItem.id
      );

      if (updatedFilterValues.length === 0) {
        setDataFilterValues([]);
        setSelectedDataFilters([]);
        dataExplorerRowsMatrix.current = null;
        dataExplorerColumnsMatrix.current = null;
        setDataExplorer(undefined);
        fetchDataExplorer(analysisIdNumeric);
      } else {
        setDataFilterValues(updatedFilterValues);
        fetchFilters(updatedFilterValues);
      }
    } else if (isFeatureExplorerTab) {
      const updatedFilterValues = featureFilterValues.filter(
        (filterValue: DataExplorerFilterValue | FeatureExplorerFilterValue) =>
          filterValue.id !== filterItem.id
      );

      if (updatedFilterValues.length === 0) {
        setFeatureFilterValues([]);
        setSelectedFeatureFilters([]);
        fetchFeatureExplorer(analysisIdNumeric);
      } else {
        setFeatureFilterValues(updatedFilterValues);
        fetchFilters(updatedFilterValues);
      }
    }
  };

  const clearAllFilters = useCallback(() => {
    if (isDataExplorerTab) {
      setDataFilterValues([]);
      setSelectedDataFilters([]);
      dataExplorerRowsMatrix.current = null;
      dataExplorerColumnsMatrix.current = null;
      setDataExplorer(undefined);
      fetchDataExplorer(analysisIdNumeric);
    } else if (isFeatureExplorerTab) {
      setFeatureFilterValues([]);
      setSelectedFeatureFilters([]);
      fetchFeatureExplorer(analysisIdNumeric);
    }
  }, [isDataExplorerTab, isFeatureExplorerTab, analysisIdNumeric]);

  const handleDataExplorerInfiniteScroll = async (rowPage = 1, columnPage = 1): Promise<void> => {
    const { rows_total, rows_size } = dataExplorer as DataExplorerType;
    const totalPages = Math.ceil(rows_total / rows_size);

    if (rowPage > totalPages) return;

    const featureList = selectedFeatures.length ? selectedFeatures : selectedFeatureIds;
    const featureIds = isShowSelectedColumns ? { feature_ids: featureList || [] } : {};

    const rowList = selectedDataRows.length ? selectedDataRows : selectedDataIds;
    const rowIds = isShowSelectedDataRows ? { row_ids: rowList || [] } : {};

    const params = {
      rows_page_index: rowPage + 1,
      columns_page_index: columnPage + 1,
    };

    setDataLoading(true);

    const updatedRow = [...dataExplorerRowsMatrix.current![rowPage]];
    updatedRow[columnPage] = [];

    dataExplorerRowsMatrix.current![rowPage] = [...updatedRow];

    try {
      await fetchDataExplorer(
        analysisIdNumeric,
        { data_filters: selectedDataFilters, ...featureIds, ...rowIds },
        params
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingMoreRows(false);
      setLoadingMoreColumns(false);
      setDataLoading(false);
    }
  };

  const handleScrollDirection = useScrollDirection();

  const isPageLoading = (rowPage: number, columnPage: number) =>
    Array.isArray(dataExplorerRowsMatrix.current?.[rowPage][columnPage]) &&
    !dataExplorerRowsMatrix.current?.[rowPage][columnPage].length;

  const hasPageData = (rowPage: number, columnPage: number) =>
    Array.isArray(dataExplorerRowsMatrix.current?.[rowPage][columnPage]) &&
    !!dataExplorerRowsMatrix.current?.[rowPage][columnPage].length;

  const handleDataExplorerScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const { direction, xPosition, yPosition } = handleScrollDirection(event);
    const defaultPageWidth = EXPLORER_ROW_SIZE * EXPLORER_ROW_WIDTH;
    const defaultPageHeight = EXPLORER_ROW_SIZE * EXPLORER_ROW_HEIGHT;

    const currentRowPageFloat = yPosition / defaultPageHeight;
    const currentColumnPageFloat = xPosition / defaultPageWidth;

    const rowPage = Math.floor(currentRowPageFloat);
    const colPage = Math.floor(currentColumnPageFloat);

    const isEndOfTheRowPage = currentRowPageFloat - rowPage >= 0.9;
    const isEndOfTheColumnPage = currentColumnPageFloat - colPage >= 0.9;

    const isHalfWayY = yPosition - rowPage * defaultPageHeight >= defaultPageHeight / 2;
    const isHalfWayX = xPosition - colPage * defaultPageWidth >= defaultPageWidth / 2;

    const isFirstRowPage = rowPage === 0;
    const isFirstColumnPage = colPage === 0;
    const isLastRowPage = dataExplorerRowsMatrix.current?.length === rowPage + 1;
    const isLastColumnPage = dataExplorerRowsMatrix.current?.[rowPage].length === colPage + 1;

    if (!hasPageData(rowPage, colPage) && !isPageLoading(rowPage, colPage)) {
      setLoadingMoreRows(true);
      await handleDataExplorerInfiniteScroll(rowPage, colPage);

      if (isEndOfTheRowPage) {
        await handleDataExplorerInfiniteScroll(rowPage + 1, colPage);
      }

      if (isEndOfTheColumnPage) {
        await handleDataExplorerInfiniteScroll(rowPage, colPage + 1);
      }

      if (isEndOfTheRowPage && isEndOfTheColumnPage) {
        await handleDataExplorerInfiniteScroll(rowPage + 1, colPage + 1);
      }
      return true;
    }

    switch (direction) {
      case 'top':
        if (
          isFirstRowPage ||
          hasPageData(rowPage - 1, colPage) ||
          isHalfWayY ||
          isPageLoading(rowPage - 1, colPage)
        ) {
          return false;
        }

        setLoadingMoreRows(true);
        await handleDataExplorerInfiniteScroll(rowPage - 1, colPage);

        if (
          isEndOfTheColumnPage &&
          !hasPageData(rowPage - 1, colPage + 1) &&
          !isPageLoading(rowPage - 1, colPage + 1)
        ) {
          await handleDataExplorerInfiniteScroll(rowPage - 1, colPage + 1);
        }

        return true;

      case 'bottom':
        if (
          isLastRowPage ||
          hasPageData(rowPage + 1, colPage) ||
          !isHalfWayY ||
          isPageLoading(rowPage + 1, colPage)
        ) {
          return false;
        }

        setLoadingMoreRows(true);
        await handleDataExplorerInfiniteScroll(rowPage + 1, colPage);

        if (
          isEndOfTheColumnPage &&
          !hasPageData(rowPage + 1, colPage + 1) &&
          !isPageLoading(rowPage + 1, colPage + 1)
        ) {
          await handleDataExplorerInfiniteScroll(rowPage + 1, colPage + 1);
        }

        return true;

      case 'left':
        if (
          isFirstColumnPage ||
          hasPageData(rowPage, colPage - 1) ||
          isHalfWayX ||
          isPageLoading(rowPage, colPage - 1)
        ) {
          return false;
        }

        setLoadingMoreColumns(true);
        await handleDataExplorerInfiniteScroll(rowPage, colPage - 1);

        if (
          isEndOfTheRowPage &&
          !hasPageData(rowPage + 1, colPage - 1) &&
          !isPageLoading(rowPage + 1, colPage - 1)
        ) {
          await handleDataExplorerInfiniteScroll(rowPage + 1, colPage - 1);
        }

        return true;

      case 'right':
        if (
          isLastColumnPage ||
          hasPageData(rowPage, colPage + 1) ||
          !isHalfWayX ||
          isPageLoading(rowPage, colPage + 1)
        ) {
          return false;
        }

        setLoadingMoreColumns(true);
        await handleDataExplorerInfiniteScroll(rowPage, colPage + 1);

        if (
          isEndOfTheRowPage &&
          !hasPageData(rowPage + 1, colPage + 1) &&
          !isPageLoading(rowPage + 1, colPage + 1)
        ) {
          await handleDataExplorerInfiniteScroll(rowPage + 1, colPage + 1);
        }

        return true;

      default:
        return false;
    }
  };

  const handleFeatureExplorerScroll = async (
    event: React.UIEvent<HTMLDivElement>
  ): Promise<void> => {
    if (!featureExplorer) return;

    if (featureLoading || loadingMoreRows || !isAtBottom(event)) return;

    const { rows_total, rows_size, rows_page_index } = featureExplorer as FeatureExplorerType;
    const totalPages = rows_total / rows_size;

    if (rows_page_index > totalPages) return;

    const featureList = selectedFeatures.length ? selectedFeatures : selectedFeatureIds;
    const featureIds = isShowSelectedFeatureRows ? { feature_ids: featureList || [] } : {};

    const params = {
      rows_page_index: rows_page_index + 1,
    };

    setLoadingMoreRows(true);
    setFeatureLoading(true);

    try {
      const response = (await getFeatureExplorer(
        analysisIdNumeric,
        { data_filters: selectedFeatureFilters, ...featureIds },
        params
      )) as FeatureExplorerType;

      const updatedFeatureExplorer = {
        ...featureExplorer,
        rows: [...featureExplorer.rows, ...response.rows],
        rows_page_index: response.rows_page_index,
      };

      setFeatureExplorer(updatedFeatureExplorer);

      setLoadingMoreRows(false);
      setFeatureLoading(false);
    } catch (e) {
      setLoadingMoreRows(false);
      setFeatureLoading(false);
    }
  };

  const isAllDataRowsChecked = useMemo(() => {
    if (!dataExplorer || !dataExplorer.rows.length) return false;

    return dataExplorer.rows.every((row) => selectedDataRows.includes(row.row_index));
  }, [dataExplorer?.rows, selectedDataRows]);

  const isAllDataColumnsChecked = useMemo(() => {
    if (!dataExplorer || !dataExplorer.columns.length) return false;

    return dataExplorer.columns.every((column) =>
      [0, ...selectedFeatures].includes(column.column_id)
    );
  }, [dataExplorer?.rows, selectedFeatures]);

  useEffect(() => {
    if (dataExplorer && isAllDataRowsChecked) {
      setIsDataRowsChecked(true);
    } else setIsDataRowsChecked(false);

    if (dataExplorer && isAllDataColumnsChecked) {
      setIsDataColumnsChecked(true);
    } else setIsDataColumnsChecked(false);
  }, [dataExplorer, isAllDataColumnsChecked, isAllDataRowsChecked]);

  const showSelectedDataRows = async (forceUpdate = false): Promise<void> => {
    const featureIds =
      isShowSelectedColumns && selectedFeatures.length ? { feature_ids: selectedFeatures } : {};
    const isReset = !forceUpdate && isDataRowsChecked;

    if (dataExplorer) {
      dataExplorerRowsMatrix.current = null;
      dataExplorerColumnsMatrix.current = null;
      setDataExplorer(undefined);
      if (isReset) {
        setIsShowSelectedDataRows(false);
        await fetchDataExplorer(analysisIdNumeric, {
          data_filters: selectedDataFilters,
          ...featureIds,
        });

        setSelectedDataIds(null);
      } else {
        setIsShowSelectedDataRows(true);

        await fetchDataExplorer(analysisIdNumeric, {
          data_filters: selectedFeatureFilters,
          row_ids: selectedDataRows,
          ...featureIds,
        });

        setSelectedDataIds(selectedDataRows);
      }
    }
  };

  const isAllFeatureRowsChecked = useMemo(() => {
    if (!featureExplorer || !featureExplorer.rows.length) return false;

    return featureExplorer.rows.every((row) => selectedFeatures.includes(row.id));
  }, [featureExplorer?.rows, selectedFeatures]);

  useEffect(() => {
    if (featureExplorer && isAllFeatureRowsChecked) {
      setIsFeatureRowsChecked(true);
    } else setIsFeatureRowsChecked(false);
  }, [featureExplorer, isAllFeatureRowsChecked]);

  const showSelectedFeatureRows = async (forceUpdate = false): Promise<void> => {
    const featuresNumberSelection = selectedFeatures.map(Number);
    if (featureExplorer && isFeatureExplorerTab) {
      const isReset = !forceUpdate && isFeatureRowsChecked;

      if (isReset) {
        setIsShowSelectedFeatureRows(false);
        await fetchFeatureExplorer(analysisIdNumeric, {
          data_filters: selectedFeatureFilters,
        });

        setSelectedFeatureIds(null);
      } else {
        setIsShowSelectedFeatureRows(true);

        await fetchFeatureExplorer(analysisIdNumeric, {
          data_filters: selectedFeatureFilters,
          feature_ids: featuresNumberSelection,
        });

        setSelectedFeatureIds(featuresNumberSelection);
      }
    }
    if (isDataExplorerTab && dataExplorer) {
      const rowIds =
        isShowSelectedDataRows && selectedDataRows.length ? { row_ids: selectedDataRows } : {};
      const isReset = !forceUpdate && isDataColumnsChecked;

      dataExplorerRowsMatrix.current = null;
      dataExplorerColumnsMatrix.current = null;
      setDataExplorer(undefined);

      if (isReset) {
        setIsShowSelectedColumns(false);
        await fetchDataExplorer(analysisIdNumeric, {
          data_filters: selectedDataFilters,
          ...rowIds,
        });

        setSelectedFeatureIds(null);
      } else {
        setIsShowSelectedColumns(true);

        await fetchDataExplorer(analysisIdNumeric, {
          data_filters: selectedDataFilters,
          feature_ids: featuresNumberSelection,
          ...rowIds,
        });

        setSelectedFeatureIds(featuresNumberSelection);
      }
    }
  };

  useEffect(() => {
    const isExplorerSource = dataPointsSelectionSource?.source === SELECTION_SOURCES.EXPLORER;
    if (isShowSelectedDataRows && !isExplorerSource) {
      showSelectedDataRows(!!selectedDataRows.length);
    }
  }, [isShowSelectedDataRows, dataPointsSelectionSource, selectedDataRows]);

  useEffect(() => {
    const isExplorerSource = featureSelectionSource?.source === SELECTION_SOURCES.EXPLORER;
    const isOnlySelectedChecked = isShowSelectedFeatureRows || isShowSelectedColumns;
    if (isOnlySelectedChecked && !isExplorerSource) {
      showSelectedFeatureRows(!!selectedFeatures.length);
    }
  }, [isShowSelectedFeatureRows, isShowSelectedColumns, featureSelectionSource, selectedFeatures]);

  const resetDataRows = async (): Promise<void> => {
    setSelectedDataRows([]);
    setSelectedDataIds([]);
    setDataSelectionSource(null);

    if (dataExplorer) {
      setIsDataRowsChecked(false);

      if (isShowSelectedDataRows) {
        setIsShowSelectedDataRows(false);

        dataExplorerRowsMatrix.current = null;
        dataExplorerColumnsMatrix.current = null;
        setDataExplorer(undefined);

        const featureIds =
          isShowSelectedColumns && selectedFeatures.length ? { feature_ids: selectedFeatures } : {};

        await fetchDataExplorer(analysisIdNumeric, {
          data_filters: selectedDataFilters,
          ...featureIds,
        });
      }
    }
  };

  const resetFeatures = async (): Promise<void> => {
    setSelectedFeatures([]);
    setSelectedFeatureIds([]);
    setFeaturesSelectionSource(null);

    if (featureExplorer) {
      setIsFeatureRowsChecked(false);

      if (isShowSelectedFeatureRows) {
        setIsShowSelectedFeatureRows(false);

        await fetchFeatureExplorer(analysisIdNumeric, { data_filters: selectedFeatureFilters });
      }
    }

    if (dataExplorer) {
      setIsDataColumnsChecked(false);

      if (isShowSelectedColumns) {
        setIsShowSelectedColumns(false);

        dataExplorerRowsMatrix.current = null;
        dataExplorerColumnsMatrix.current = null;
        setDataExplorer(undefined);

        const rowIds =
          isShowSelectedDataRows && selectedDataRows.length ? { row_ids: selectedDataRows } : {};

        await fetchDataExplorer(analysisIdNumeric, {
          data_filters: selectedDataFilters,
          ...rowIds,
        });
      }
    }
  };

  const handleLandscapeSelection = useCallback(
    (type: VisualisationType, hasNodes: boolean) => {
      if (hasNodes && sidebarTab !== SIDEBAR_TABS.EXPLORE) {
        setSidebarTab(SIDEBAR_TABS.EXPLORE);
      }

      if (type === VisualisationType.DATA_LANDSCAPE) {
        setIsDataRowsChecked(false);
      } else if (type === VisualisationType.FEATURE_LANDSCAPE) {
        setIsFeatureRowsChecked(false);
        setIsDataColumnsChecked(false);
      }
    },
    [dataPointsSelectionSource, featureSelectionSource]
  );

  const clearDataRowsSelection = (): void => {
    setSelectedDataRows([]);
    setDataSelectionSource(null);
  };

  const clearFeaturesSelection = (): void => {
    setSelectedFeatures([]);
    setFeaturesSelectionSource(null);
  };

  const openVisualizationModal = useCallback((panel: string) => {
    setIsVisualizationModalOpen(true);
    setCurrentPanel(panel);
  }, []);

  const closeVisualizationModal = useCallback(() => {
    setIsVisualizationModalOpen(false);
    setCurrentPanel(null);
  }, []);

  const filterColumns = isDataExplorerTab ? features : featureExplorerColumns;

  const isSmallScreen = breakpoint !== 'desktopLarge';

  const explorerHeight = useMemo(() => {
    if (panelMaximized) {
      return window.innerHeight - HEADER_HEIGHT - DATA_EXPLORER_HEIGHT;
    }

    return isSmallScreen ? 291 : 250;
  }, [panelMaximized, isSmallScreen]);

  const currentFilterValue = tab === TABS.DATA_EXPLORER ? dataFilterValues : featureFilterValues;

  if (!currentAnalysis)
    return (
      <LoaderWrapper>
        <PreviewCircular size={70} />
      </LoaderWrapper>
    );

  if (isAnalysisProcessing(currentAnalysis.status)) {
    return <AnalysisNoResults analysis={currentAnalysis} projectId={projectIdNumeric} />;
  }

  if (isAnalysisReady(currentAnalysis.status)) {
    return (
      <>
        <Header withShadow withHome withUser>
          <AnalysisNavigation />
        </Header>
        <AnalysisContainer>
          <AnalysisSidebarWrapper>
            <AnalysisSidebar
              analysisId={analysisIdNumeric}
              tab={sidebarTab}
              setTab={setSidebarTab}
            />
          </AnalysisSidebarWrapper>
          <AnalysisMainWrapper>
            <DataLandscapeWrapper panelMaximized={panelMaximized} panelHidden={panelHidden}>
              <VisualizationPan
                isLargePan
                key={mainPanel.panel}
                id={mainPanel.panel}
                coloring={mainPanel?.coloring}
                error={mainPanel?.error}
                visualizationId={mainPanel?.id}
                visualizationType={mainPanel?.type}
                shouldReload={mainPanel?.shouldReload}
                analysisId={analysisIdNumeric}
                onSelection={handleLandscapeSelection}
                shouldLayoutUpdate={shouldLayoutUpdate}
                setShouldLayoutUpdate={setShouldLayoutUpdate}
                openVisualizationDialog={openVisualizationModal}
              />
            </DataLandscapeWrapper>
            <Box height={panelInitial ? '385px' : 'auto'}>
              <Box
                position='relative'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                flexBasis={panelHidden ? '0%' : '35%'}
                sx={{
                  background: '#F8F8F8',
                  borderBottom: '1px solid #E2E4E7',
                  pt: 1,
                  px: 1,
                  pb: 2,
                }}
              >
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  aria-label='Explorer tabs'
                  variant='scrollable'
                  scrollButtons={false}
                  sx={{
                    display: 'flex',
                    pl: 3,
                  }}
                >
                  <Tab
                    className='explorerTab'
                    label='Data Explorer'
                    sx={{ fontSize: '12px' }}
                    wrapped
                  />
                  <Tab
                    className='explorerTab'
                    label='Feature Explorer'
                    sx={{ fontSize: '12px' }}
                    wrapped
                  />
                </Tabs>

                <Selections
                  isDataExplorerTab={isDataExplorerTab}
                  isDataRowsChecked={isDataRowsChecked}
                  isDataColumnsChecked={isDataColumnsChecked}
                  isFeatureRowsChecked={isFeatureRowsChecked}
                  showSelectedDataRows={showSelectedDataRows}
                  resetDataRows={resetDataRows}
                  showSelectedFeatureRows={showSelectedFeatureRows}
                  resetFeatures={resetFeatures}
                  clearDataRowsSelections={clearDataRowsSelection}
                  clearFeatureSelections={clearFeaturesSelection}
                  isShowSelectedDataRows={isShowSelectedDataRows}
                  isShowSelectedFeatureRows={isShowSelectedFeatureRows}
                  isShowSelectedColumns={isShowSelectedColumns}
                  dataset={currentAnalysis.dataset.dataset_metadata}
                />

                <Box>
                  <IconButton
                    size={isSmallScreen ? 'small' : 'medium'}
                    className={classnames({ hasFilters: currentFilterValue.length })}
                    onClick={() => setFilter((prevState) => !prevState)}
                  >
                    <Filter />
                    {!!currentFilterValue.length && (
                      <FiltersCountBadge>{currentFilterValue.length}</FiltersCountBadge>
                    )}
                  </IconButton>
                  <IconButton
                    size={isSmallScreen ? 'small' : 'medium'}
                    onClick={panelMaximized ? resetExplorer : maximizeExplorer}
                  >
                    {panelMaximized && <Minimize />}
                    {(panelInitial || panelHidden) && <Maximize />}
                  </IconButton>
                  <IconButton
                    size={isSmallScreen ? 'small' : 'medium'}
                    onClick={panelInitial ? hideExplorer : resetExplorer}
                  >
                    {(panelInitial || panelMaximized) && <ChevronDown />}
                    {panelHidden && <ChevronUp />}
                  </IconButton>
                </Box>
              </Box>
              {filter && (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    background: '#F8F8F8',
                    px: 3,
                    py: 2,
                  }}
                >
                  <Box display='flex' alignItems='center' flexWrap='wrap' width='80%'>
                    {currentFilterValue.map((filterValue: any) => {
                      const filterLabel = filterValue.column.feature_name
                        .concat(getOperatorDisplayText(filterValue.operator))
                        .concat(filterValue.value);

                      return (
                        <Tooltip key={filterValue.id} title={filterLabel} placement='top'>
                          <Chip
                            label={filterLabel}
                            variant='outlined'
                            size='small'
                            onDelete={() => onDeleteFilterValue(filterValue)}
                            sx={{
                              mr: 1,
                              my: 1,
                              maxWidth: '200px',
                            }}
                          />
                        </Tooltip>
                      );
                    })}
                    <Box position='relative'>
                      <Button color='primary' size='small' onClick={addFilter} startIcon={<Plus />}>
                        Add filter
                      </Button>
                      {filterForm && (
                        <ExplorerFilterWrapper panelMaximized={panelMaximized}>
                          <ExplorerFilter
                            columns={filterColumns}
                            onCancel={cancelFilter}
                            onApply={applyFilter}
                          />
                        </ExplorerFilterWrapper>
                      )}
                    </Box>
                  </Box>
                  {currentFilterValue.length > 0 && (
                    <Button
                      color='primary'
                      size='small'
                      onClick={clearAllFilters}
                      startIcon={<X />}
                      sx={{
                        mr: 1,
                      }}
                    >
                      Clear all
                    </Button>
                  )}
                </Box>
              )}
              <Box>
                <TabPanel value={tab} index={TABS.DATA_EXPLORER}>
                  {dataLoading && !panelHidden && <StyledProgress />}
                  {dataExplorer && !panelHidden && (
                    <Box position='relative'>
                      <DataExplorer
                        data={dataExplorer}
                        height={explorerHeight}
                        onScroll={handleDataExplorerScroll}
                        selectedRows={selectedDataRows}
                        setSelectedRows={setSelectedDataRows}
                        selectedColumns={selectedFeatures}
                        setSelectedColumns={setSelectedFeatures}
                      />
                      {loadingMoreRows && <LoadingMoreRows>Loading more rows</LoadingMoreRows>}
                      {loadingMoreColumns && (
                        <LoadingMoreRows>Loading more columns</LoadingMoreRows>
                      )}
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={tab} index={TABS.FEATURE_EXPLORER}>
                  {featureLoading && !panelHidden && <StyledProgress />}
                  {featureExplorer && !panelHidden && (
                    <Box position='relative'>
                      <FeatureExplorer
                        data={featureExplorer}
                        height={explorerHeight}
                        onScroll={handleFeatureExplorerScroll}
                        selectedRows={selectedFeatures}
                        setSelectedRows={setSelectedFeatures}
                      />
                      {loadingMoreRows && <LoadingMoreRows>Loading more rows</LoadingMoreRows>}
                    </Box>
                  )}
                </TabPanel>
              </Box>
            </Box>
          </AnalysisMainWrapper>
          <AnalysisChartWrapper>
            {rightPanels.map(({ panel, id, type, shouldReload, coloring, error }) => (
              <Fragment key={panel}>
                <Box
                  position='relative'
                  ref={visualizationRefs[panel]}
                  height={VISUALIZATION_PANEL_HEIGHT}
                >
                  <VisualizationPan
                    id={panel}
                    visualizationId={id}
                    visualizationType={type}
                    coloring={coloring}
                    error={error}
                    analysisId={analysisIdNumeric}
                    onSelection={handleLandscapeSelection}
                    openVisualizationDialog={openVisualizationModal}
                    shouldLayoutUpdate={shouldLayoutUpdate}
                    setShouldLayoutUpdate={setShouldLayoutUpdate}
                    shouldReload={shouldReload}
                  />
                </Box>
                <Divider />
              </Fragment>
            ))}
          </AnalysisChartWrapper>
        </AnalysisContainer>
        {isVisualizationModalOpen && !!currentPanel && (
          <VisualizationDialog
            currentPanel={currentPanel}
            openDialog={isVisualizationModalOpen}
            handleClose={closeVisualizationModal}
          />
        )}
      </>
    );
  }

  return null;
};

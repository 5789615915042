import React, { FC, useState } from 'react';
import { ChevronDown, Settings, Trash2, EyeOff } from 'react-feather';
import classnames from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {
  StyledAccordionDetails,
  StyledAccordionSummary,
} from 'app/screens/Analysis/AnalysisSidebar/AnalysisSidebar.styles';
import { Tooltip } from 'app/mui/Tooltip';
import { AnalysisConfig, ChartDataType, Pan } from 'app/screens/Analysis/Analysis.types';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { useParams } from 'app/navigation';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { VisualizationPopover } from 'app/shared/components/VisualizationPopover/VisualizationPopover';
import { isVisualizationCopy } from 'app/shared/utils/visualization';
import {
  StyledAccordion,
  LandscapeItem,
  VisualizationItem,
} from '../ConfigureVisualizations.styles';

type ConfigureVisualizationsProps = {
  visualizations: AnalysisConfig[] | ChartDataType[] | undefined;
  title: string;
  type: VisualisationType;
  nameSearch: string;
  handleOpenConfiguration: (
    visualization: AnalysisConfig | ChartDataType,
    type: VisualisationType
  ) => void;
  onDelete: (id: number, name: string, type: VisualisationType) => void;
};

export const ConfigureVisualizationItem: FC<ConfigureVisualizationsProps> = ({
  visualizations,
  title,
  type,
  nameSearch,
  handleOpenConfiguration,
  onDelete,
}) => {
  const { analysisId } = useParams();
  const theme = useTheme();

  const { updatePanConfig, panConfig, setHighlightedVisualization } = useAnalysis();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [isNoFreeSlot, setIsNoFreeSlot] = useState<boolean>(false);
  const [visualizationId, setVisualizationId] = useState<number | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setVisualizationId(undefined);
    setAnchorEl(null);
  };

  const openVisualization = (visualisation: AnalysisConfig | ChartDataType) => {
    handleClose();

    const isCopy = isVisualizationCopy(panConfig, visualisation.id);

    const freePanel = isCopy
      ? panConfig.find((panel: Pan) => panel.id === visualisation.id)
      : panConfig.find((panel: Pan) => !panel.id);

    if (freePanel) {
      const highlightedVis = {
        id: visualisation.id,
        panel: freePanel.panel,
      };

      setHighlightedVisualization(highlightedVis);
    }

    if (freePanel && !isCopy) {
      updatePanConfig(Number(analysisId), freePanel.panel, {
        id: visualisation.id,
        type,
        coloring: visualisation.config.coloring,
      });
    }
  };

  const handleOpenPopover = (event: any, visualisation: AnalysisConfig | ChartDataType) => {
    setVisualizationId(visualisation.id);
    setIsNoFreeSlot(false);
    const isCopy = isVisualizationCopy(panConfig, visualisation.id);

    const isFreePanel = !!panConfig.find((panel: Pan) => !panel.id);

    if (!isCopy) {
      if (!isFreePanel) {
        setIsNoFreeSlot(true);
      }
      setAnchorEl(event.currentTarget);
    } else {
      openVisualization(visualisation);
    }
  };

  const open = Boolean(anchorEl);

  // @ts-ignore
  const visualizationsList = visualizations?.filter((visualization: any) =>
    visualization.name?.toLowerCase().includes(nameSearch.toLowerCase())
  );

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const onHighlight = (visId: number) => {
    const isCopy = isVisualizationCopy(panConfig, visId);

    if (isCopy) {
      const visualization = panConfig.find((pan: Pan) => pan.id === visId);

      if (visualization) {
        const highlightedVis = {
          id: visId,
          panel: visualization.panel,
        };

        setHighlightedVisualization(highlightedVis);
      }
    }
  };

  const isHidden = (visId: number) => {
    if (panConfig.some(({ id }) => id === visId)) {
      return false;
    }
    return true;
  };

  const isTabExpanded = !!nameSearch.length || expanded;

  return (
    visualizationsList &&
    !!visualizationsList.length && (
      <LandscapeItem>
        <StyledAccordion expanded={isTabExpanded} disableGutters elevation={0}>
          <StyledAccordionSummary
            className='chevronUp'
            style={{ padding: theme.spacing(0, 0, 1) }}
            expandIcon={<ChevronDown size='20' color={theme.palette.primary.main} />}
            onClick={handleExpand}
          >
            <Typography variant='h6' fontWeight='600'>
              {title}
            </Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {visualizationsList.map((visualization: any) => (
              <VisualizationItem
                key={visualization.id}
                className={classnames({ hidden: isHidden(visualization.id) })}
              >
                <Tooltip key={visualization.id} title={visualization.name} placement='right'>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      height='36px'
                      width='100%'
                      aria-describedby={visualization.id}
                      onClick={(e) => handleOpenPopover(e, visualization)}
                    >
                      {isHidden(visualization.id) && (
                        <EyeOff
                          style={{ marginRight: theme.spacing(1) }}
                          color={theme.palette.action.active}
                          size='20'
                        />
                      )}

                      <Typography noWrap variant='body1' className='break-text'>
                        {visualization.name}
                      </Typography>
                    </Box>

                    <Box className='actions' display='flex'>
                      <Settings
                        size='20'
                        onClick={(event) => {
                          event.stopPropagation();
                          onHighlight(visualization.id);
                          handleOpenConfiguration(visualization, type);
                        }}
                      />
                      <Trash2
                        size='20'
                        style={{ marginLeft: theme.spacing(1) }}
                        color={theme.palette.error.dark}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(visualization.id, visualization.name, visualization.type);
                        }}
                      />
                    </Box>
                  </Box>
                </Tooltip>
                <VisualizationPopover
                  open={open}
                  isOpen={open && visualizationId === visualization.id}
                  openVisualization={() => openVisualization(visualization)}
                  visualizationId={visualization.id}
                  visualizationType={visualization.type}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  isNoFreeSlot={isNoFreeSlot}
                  noFreeSlotText='This visualization has been hidden. To bring it back to canvas select it
                        from the menu in any of the visualization slots.'
                  freeSlotText='This visualization has been hidden.'
                />
              </VisualizationItem>
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      </LandscapeItem>
    )
  );
};

import React, { FC, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GradientBackground } from 'app/shared/styles/common';
import { Header } from 'app/shared/components/Header/Header';
import { ArrowLeft } from 'react-feather';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { UploadContainer } from './UploadContainer';
import { DatasetSamples } from './DatasetSamples/DatasetSamples';

export type SelectedSampleDataset =
  | {
      project_id: number;
      dataset_id: number;
      name: string;
      domain: string;
      size: number;
      status: string;
    }
  | undefined;

export const UploadDataset: FC = () => {
  const { navigateToHome } = useNavigation();

  const [selectedSampleDataset, setSelectedSampleDataset] =
    useState<SelectedSampleDataset>(undefined);
  const [isSamplesScreenOpen, setIsSamplesScreenOpen] = useState(false);

  const handleCloseSamplesScreen = useCallback(() => {
    setIsSamplesScreenOpen(false);
  }, []);

  const handleOpenSamplesScreen = useCallback(() => {
    setIsSamplesScreenOpen(true);
  }, []);

  return (
    <>
      <Header withHome withUser isTransparent />
      <GradientBackground>
        <Box
          mr={3}
          display='flex'
          onClick={navigateToHome}
          sx={{ cursor: 'pointer', zIndex: 1, position: 'absolute', top: '100px', left: '24px' }}
        >
          <ArrowLeft size='25' />
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' width='100%'>
          <Box
            sx={{
              width: '900px',
              background: 'rgba(255, 255, 255, 0.6)',
              backdropFilter: 'blur(90px)',
              borderRadius: '8px',
              border: `1px solid rgba(189, 221, 232, 0.4)`,
              px: 4,
              py: 7,
            }}
          >
            <Typography component='h3' variant='h3' mb={3} textAlign='center'>
              Upload Dataset
            </Typography>
            <UploadContainer
              handleOpenSamplesScreen={handleOpenSamplesScreen}
              selectedSampleDataset={selectedSampleDataset}
              setSelectedSampleDataset={setSelectedSampleDataset}
            />
          </Box>
        </Box>
        {isSamplesScreenOpen && (
          <DatasetSamples
            handleClose={handleCloseSamplesScreen}
            selectedSampleDataset={selectedSampleDataset}
            setSelectedSampleDataset={setSelectedSampleDataset}
          />
        )}
      </GradientBackground>
    </>
  );
};

import { ColorSchemeLibrary } from './scales';

export const scientificColorMaps: ColorSchemeLibrary = {
  bilbao: [
    [0.0, '#ffffff'],
    [0.00392156862745098, '#fefefe'],
    [0.00784313725490196, '#fcfcfc'],
    [0.011764705882352941, '#fbfbfb'],
    [0.01568627450980392, '#fafafa'],
    [0.0196078431372549, '#f8f8f8'],
    [0.023529411764705882, '#f7f7f7'],
    [0.027450980392156862, '#f6f6f6'],
    [0.03137254901960784, '#f5f5f4'],
    [0.03529411764705882, '#f3f3f3'],
    [0.0392156862745098, '#f2f2f2'],
    [0.043137254901960784, '#f1f1f0'],
    [0.047058823529411764, '#efefef'],
    [0.050980392156862744, '#eeeeee'],
    [0.054901960784313725, '#ededec'],
    [0.058823529411764705, '#ececeb'],
    [0.06274509803921569, '#eaeaea'],
    [0.06666666666666667, '#e9e9e8'],
    [0.07058823529411765, '#e8e8e7'],
    [0.07450980392156863, '#e7e6e6'],
    [0.0784313725490196, '#e5e5e4'],
    [0.08235294117647059, '#e4e4e3'],
    [0.08627450980392157, '#e3e3e1'],
    [0.09019607843137255, '#e2e1e0'],
    [0.09411764705882353, '#e1e0df'],
    [0.09803921568627451, '#dfdfdd'],
    [0.10196078431372549, '#dededc'],
    [0.10588235294117647, '#dddcda'],
    [0.10980392156862745, '#dcdbd9'],
    [0.11372549019607843, '#dbdad8'],
    [0.11764705882352941, '#dad9d6'],
    [0.12156862745098039, '#d9d8d5'],
    [0.12549019607843137, '#d7d7d3'],
    [0.12941176470588234, '#d6d5d2'],
    [0.13333333333333333, '#d5d4d0'],
    [0.13725490196078433, '#d4d3cf'],
    [0.1411764705882353, '#d3d2cd'],
    [0.14509803921568626, '#d2d1cc'],
    [0.14901960784313725, '#d2d0ca'],
    [0.15294117647058825, '#d1cfc9'],
    [0.1568627450980392, '#d0cec7'],
    [0.16078431372549018, '#cfcdc6'],
    [0.16470588235294117, '#ceccc4'],
    [0.16862745098039217, '#cdcbc3'],
    [0.17254901960784313, '#cdcac2'],
    [0.1764705882352941, '#ccc9c0'],
    [0.1803921568627451, '#cbc8bf'],
    [0.1843137254901961, '#cac8bd'],
    [0.18823529411764706, '#cac7bc'],
    [0.19215686274509802, '#c9c6ba'],
    [0.19607843137254902, '#c9c5b9'],
    [0.2, '#c8c4b8'],
    [0.20392156862745098, '#c7c4b6'],
    [0.20784313725490194, '#c7c3b5'],
    [0.21176470588235294, '#c6c2b3'],
    [0.21568627450980393, '#c6c2b2'],
    [0.2196078431372549, '#c5c1b1'],
    [0.22352941176470587, '#c5c0af'],
    [0.22745098039215686, '#c4c0ae'],
    [0.23137254901960785, '#c4bfad'],
    [0.23529411764705882, '#c3beab'],
    [0.2392156862745098, '#c3beaa'],
    [0.24313725490196078, '#c2bda9'],
    [0.24705882352941178, '#c2bca7'],
    [0.25098039215686274, '#c2bca6'],
    [0.2549019607843137, '#c1bba5'],
    [0.2588235294117647, '#c1bba3'],
    [0.2627450980392157, '#c0baa2'],
    [0.26666666666666666, '#c0b9a1'],
    [0.27058823529411763, '#c0b99f'],
    [0.27450980392156865, '#bfb89e'],
    [0.2784313725490196, '#bfb89d'],
    [0.2823529411764706, '#beb79c'],
    [0.28627450980392155, '#beb79a'],
    [0.2901960784313725, '#beb699'],
    [0.29411764705882354, '#bdb598'],
    [0.2980392156862745, '#bdb596'],
    [0.30196078431372547, '#bdb495'],
    [0.3058823529411765, '#bcb394'],
    [0.30980392156862746, '#bcb392'],
    [0.3137254901960784, '#bbb291'],
    [0.3176470588235294, '#bbb190'],
    [0.32156862745098036, '#bbb18f'],
    [0.3254901960784314, '#bab08d'],
    [0.32941176470588235, '#baaf8c'],
    [0.3333333333333333, '#b9af8b'],
    [0.33725490196078434, '#b9ae89'],
    [0.3411764705882353, '#b9ad88'],
    [0.34509803921568627, '#b8ac87'],
    [0.34901960784313724, '#b8ab85'],
    [0.3529411764705882, '#b7ab84'],
    [0.3568627450980392, '#b7aa83'],
    [0.3607843137254902, '#b6a981'],
    [0.36470588235294116, '#b6a880'],
    [0.3686274509803922, '#b5a77f'],
    [0.37254901960784315, '#b5a67e'],
    [0.3764705882352941, '#b5a57c'],
    [0.3803921568627451, '#b4a47b'],
    [0.38431372549019605, '#b4a37a'],
    [0.38823529411764707, '#b3a279'],
    [0.39215686274509803, '#b3a178'],
    [0.396078431372549, '#b2a077'],
    [0.4, '#b29f76'],
    [0.403921568627451, '#b29e75'],
    [0.40784313725490196, '#b19d74'],
    [0.4117647058823529, '#b19c73'],
    [0.4156862745098039, '#b19b72'],
    [0.4196078431372549, '#b09a71'],
    [0.4235294117647059, '#b09970'],
    [0.42745098039215684, '#af9870'],
    [0.43137254901960786, '#af976f'],
    [0.43529411764705883, '#af966e'],
    [0.4392156862745098, '#ae956d'],
    [0.44313725490196076, '#ae946d'],
    [0.44705882352941173, '#ae936c'],
    [0.45098039215686275, '#ad926c'],
    [0.4549019607843137, '#ad916b'],
    [0.4588235294117647, '#ad906a'],
    [0.4627450980392157, '#ac906a'],
    [0.4666666666666667, '#ac8f69'],
    [0.47058823529411764, '#ac8e69'],
    [0.4745098039215686, '#ac8d68'],
    [0.4784313725490196, '#ab8c68'],
    [0.4823529411764706, '#ab8b68'],
    [0.48627450980392156, '#ab8a67'],
    [0.49019607843137253, '#ab8967'],
    [0.49411764705882355, '#aa8866'],
    [0.4980392156862745, '#aa8766'],
    [0.5019607843137255, '#aa8665'],
    [0.5058823529411764, '#a98665'],
    [0.5098039215686274, '#a98565'],
    [0.5137254901960784, '#a98464'],
    [0.5176470588235293, '#a98364'],
    [0.5215686274509804, '#a88263'],
    [0.5254901960784314, '#a88163'],
    [0.5294117647058824, '#a88063'],
    [0.5333333333333333, '#a87f62'],
    [0.5372549019607843, '#a77e62'],
    [0.5411764705882353, '#a77e62'],
    [0.5450980392156862, '#a77d61'],
    [0.5490196078431373, '#a77c61'],
    [0.5529411764705883, '#a67b60'],
    [0.5568627450980392, '#a67a60'],
    [0.5607843137254902, '#a67960'],
    [0.5647058823529412, '#a6785f'],
    [0.5686274509803921, '#a5775f'],
    [0.5725490196078431, '#a5775e'],
    [0.5764705882352941, '#a5765e'],
    [0.580392156862745, '#a5755e'],
    [0.5843137254901961, '#a4745d'],
    [0.5882352941176471, '#a4735d'],
    [0.592156862745098, '#a4725d'],
    [0.596078431372549, '#a4715c'],
    [0.6, '#a3705c'],
    [0.6039215686274509, '#a3705b'],
    [0.6078431372549019, '#a36f5b'],
    [0.611764705882353, '#a36e5b'],
    [0.615686274509804, '#a26d5a'],
    [0.6196078431372549, '#a26c5a'],
    [0.6235294117647059, '#a26b59'],
    [0.6274509803921569, '#a26a59'],
    [0.6313725490196078, '#a16959'],
    [0.6352941176470588, '#a16858'],
    [0.6392156862745098, '#a16858'],
    [0.6431372549019607, '#a06757'],
    [0.6470588235294118, '#a06657'],
    [0.6509803921568628, '#a06557'],
    [0.6549019607843137, '#9f6456'],
    [0.6588235294117647, '#9f6356'],
    [0.6627450980392157, '#9f6255'],
    [0.6666666666666666, '#9e6155'],
    [0.6705882352941176, '#9e6054'],
    [0.6745098039215687, '#9e5f54'],
    [0.6784313725490196, '#9d5e53'],
    [0.6823529411764706, '#9d5d53'],
    [0.6862745098039216, '#9d5c52'],
    [0.6901960784313725, '#9c5b52'],
    [0.6941176470588235, '#9c5a51'],
    [0.6980392156862745, '#9b5951'],
    [0.7019607843137254, '#9b5850'],
    [0.7058823529411764, '#9a574f'],
    [0.7098039215686275, '#9a564f'],
    [0.7137254901960784, '#99554e'],
    [0.7176470588235294, '#99544d'],
    [0.7215686274509804, '#98534d'],
    [0.7254901960784313, '#97524c'],
    [0.7294117647058823, '#97514b'],
    [0.7333333333333333, '#96504b'],
    [0.7372549019607844, '#964f4a'],
    [0.7411764705882353, '#954e49'],
    [0.7450980392156863, '#944d48'],
    [0.7490196078431373, '#934b47'],
    [0.7529411764705882, '#934a47'],
    [0.7568627450980392, '#924946'],
    [0.7607843137254902, '#914845'],
    [0.7647058823529411, '#904744'],
    [0.7686274509803921, '#8f4643'],
    [0.7725490196078432, '#8e4542'],
    [0.7764705882352941, '#8d4341'],
    [0.7803921568627451, '#8c4240'],
    [0.7843137254901961, '#8b413f'],
    [0.788235294117647, '#8b403e'],
    [0.792156862745098, '#8a3f3d'],
    [0.796078431372549, '#893e3c'],
    [0.8, '#883c3b'],
    [0.803921568627451, '#873b3a'],
    [0.807843137254902, '#853a39'],
    [0.8117647058823529, '#843938'],
    [0.8156862745098039, '#833837'],
    [0.8196078431372549, '#823736'],
    [0.8235294117647058, '#813634'],
    [0.8274509803921568, '#803433'],
    [0.8313725490196078, '#7f3332'],
    [0.8352941176470589, '#7e3231'],
    [0.8392156862745098, '#7d3130'],
    [0.8431372549019608, '#7c302f'],
    [0.8470588235294118, '#7b2f2e'],
    [0.8509803921568627, '#7a2e2d'],
    [0.8549019607843137, '#782c2c'],
    [0.8588235294117647, '#772b2b'],
    [0.8627450980392157, '#762a2a'],
    [0.8666666666666667, '#752928'],
    [0.8705882352941177, '#742827'],
    [0.8745098039215686, '#732726'],
    [0.8784313725490196, '#722625'],
    [0.8823529411764706, '#702524'],
    [0.8862745098039215, '#6f2323'],
    [0.8901960784313725, '#6e2222'],
    [0.8941176470588235, '#6d2121'],
    [0.8980392156862745, '#6c2020'],
    [0.9019607843137255, '#6b1f1f'],
    [0.9058823529411765, '#691e1e'],
    [0.9098039215686274, '#681d1d'],
    [0.9137254901960784, '#671c1c'],
    [0.9176470588235294, '#661a1b'],
    [0.9215686274509803, '#65191a'],
    [0.9254901960784314, '#641819'],
    [0.9294117647058824, '#621718'],
    [0.9333333333333333, '#611617'],
    [0.9372549019607843, '#601516'],
    [0.9411764705882353, '#5f1315'],
    [0.9450980392156862, '#5d1214'],
    [0.9490196078431372, '#5c1113'],
    [0.9529411764705882, '#5b1012'],
    [0.9568627450980391, '#5a0e11'],
    [0.9607843137254902, '#590d10'],
    [0.9647058823529412, '#570c0f'],
    [0.9686274509803922, '#560a0d'],
    [0.9725490196078431, '#55090c'],
    [0.9764705882352941, '#54070b'],
    [0.9803921568627451, '#530609'],
    [0.984313725490196, '#510508'],
    [0.9882352941176471, '#500406'],
    [0.9921568627450981, '#4f0304'],
    [0.996078431372549, '#4e0103'],
    [1.0, '#4d0001'],
  ],
  imola: [
    [0.0, '#1a33b3'],
    [0.00392156862745098, '#1a34b2'],
    [0.00784313725490196, '#1b35b2'],
    [0.011764705882352941, '#1b35b1'],
    [0.01568627450980392, '#1c36b1'],
    [0.0196078431372549, '#1c37b1'],
    [0.023529411764705882, '#1c37b0'],
    [0.027450980392156862, '#1d38b0'],
    [0.03137254901960784, '#1d39b0'],
    [0.03529411764705882, '#1e39af'],
    [0.0392156862745098, '#1e3aaf'],
    [0.043137254901960784, '#1e3baf'],
    [0.047058823529411764, '#1f3cae'],
    [0.050980392156862744, '#1f3cae'],
    [0.054901960784313725, '#1f3dae'],
    [0.058823529411764705, '#203ead'],
    [0.06274509803921569, '#203ead'],
    [0.06666666666666667, '#203fad'],
    [0.07058823529411765, '#2140ac'],
    [0.07450980392156863, '#2140ac'],
    [0.0784313725490196, '#2141ac'],
    [0.08235294117647059, '#2242ab'],
    [0.08627450980392157, '#2242ab'],
    [0.09019607843137255, '#2243ab'],
    [0.09411764705882353, '#2344aa'],
    [0.09803921568627451, '#2344aa'],
    [0.10196078431372549, '#2345aa'],
    [0.10588235294117647, '#2446a9'],
    [0.10980392156862745, '#2446a9'],
    [0.11372549019607843, '#2447a9'],
    [0.11764705882352941, '#2548a8'],
    [0.12156862745098039, '#2548a8'],
    [0.12549019607843137, '#2549a8'],
    [0.12941176470588234, '#264aa7'],
    [0.13333333333333333, '#264aa7'],
    [0.13725490196078433, '#264ba7'],
    [0.1411764705882353, '#274ca6'],
    [0.14509803921568626, '#274ca6'],
    [0.14901960784313725, '#274da6'],
    [0.15294117647058825, '#284ea5'],
    [0.1568627450980392, '#284ea5'],
    [0.16078431372549018, '#284fa5'],
    [0.16470588235294117, '#2950a4'],
    [0.16862745098039217, '#2950a4'],
    [0.17254901960784313, '#2951a4'],
    [0.1764705882352941, '#2a52a3'],
    [0.1803921568627451, '#2a52a3'],
    [0.1843137254901961, '#2a53a3'],
    [0.18823529411764706, '#2b53a2'],
    [0.19215686274509802, '#2b54a2'],
    [0.19607843137254902, '#2b55a2'],
    [0.2, '#2c55a1'],
    [0.20392156862745098, '#2c56a1'],
    [0.20784313725490194, '#2c57a1'],
    [0.21176470588235294, '#2d57a0'],
    [0.21568627450980393, '#2d58a0'],
    [0.2196078431372549, '#2d59a0'],
    [0.22352941176470587, '#2e599f'],
    [0.22745098039215686, '#2e5a9f'],
    [0.23137254901960785, '#2e5b9f'],
    [0.23529411764705882, '#2f5b9e'],
    [0.2392156862745098, '#2f5c9e'],
    [0.24313725490196078, '#2f5d9e'],
    [0.24705882352941178, '#305d9d'],
    [0.25098039215686274, '#305e9d'],
    [0.2549019607843137, '#305f9c'],
    [0.2588235294117647, '#315f9c'],
    [0.2627450980392157, '#31609c'],
    [0.26666666666666666, '#32609b'],
    [0.27058823529411763, '#32619b'],
    [0.27450980392156865, '#32629b'],
    [0.2784313725490196, '#33629a'],
    [0.2823529411764706, '#33639a'],
    [0.28627450980392155, '#346499'],
    [0.2901960784313725, '#346499'],
    [0.29411764705882354, '#346598'],
    [0.2980392156862745, '#356598'],
    [0.30196078431372547, '#356698'],
    [0.3058823529411765, '#366797'],
    [0.30980392156862746, '#366797'],
    [0.3137254901960784, '#376896'],
    [0.3176470588235294, '#376896'],
    [0.32156862745098036, '#386995'],
    [0.3254901960784314, '#386a95'],
    [0.32941176470588235, '#396a94'],
    [0.3333333333333333, '#396b94'],
    [0.33725490196078434, '#3a6b93'],
    [0.3411764705882353, '#3a6c93'],
    [0.34509803921568627, '#3b6c92'],
    [0.34901960784313724, '#3b6d92'],
    [0.3529411764705882, '#3c6e91'],
    [0.3568627450980392, '#3c6e91'],
    [0.3607843137254902, '#3d6f90'],
    [0.36470588235294116, '#3d6f90'],
    [0.3686274509803922, '#3e708f'],
    [0.37254901960784315, '#3e708f'],
    [0.3764705882352941, '#3f718e'],
    [0.3803921568627451, '#40728d'],
    [0.38431372549019605, '#40728d'],
    [0.38823529411764707, '#41738c'],
    [0.39215686274509803, '#41738c'],
    [0.396078431372549, '#42748b'],
    [0.4, '#42748b'],
    [0.403921568627451, '#43758a'],
    [0.40784313725490196, '#44768a'],
    [0.4117647058823529, '#447689'],
    [0.4156862745098039, '#457789'],
    [0.4196078431372549, '#457788'],
    [0.4235294117647059, '#467888'],
    [0.42745098039215684, '#467987'],
    [0.43137254901960786, '#477987'],
    [0.43529411764705883, '#487a86'],
    [0.4392156862745098, '#487b86'],
    [0.44313725490196076, '#497b85'],
    [0.44705882352941173, '#4a7c85'],
    [0.45098039215686275, '#4a7d84'],
    [0.4549019607843137, '#4b7d84'],
    [0.4588235294117647, '#4c7e83'],
    [0.4627450980392157, '#4c7f83'],
    [0.4666666666666667, '#4d7f82'],
    [0.47058823529411764, '#4e8082'],
    [0.4745098039215686, '#4e8181'],
    [0.4784313725490196, '#4f8181'],
    [0.4823529411764706, '#508281'],
    [0.48627450980392156, '#518380'],
    [0.49019607843137253, '#518480'],
    [0.49411764705882355, '#528580'],
    [0.4980392156862745, '#53857f'],
    [0.5019607843137255, '#54867f'],
    [0.5058823529411764, '#55877f'],
    [0.5098039215686274, '#56887e'],
    [0.5137254901960784, '#56897e'],
    [0.5176470588235293, '#578a7e'],
    [0.5215686274509804, '#588a7d'],
    [0.5254901960784314, '#598b7d'],
    [0.5294117647058824, '#5a8c7d'],
    [0.5333333333333333, '#5b8d7d'],
    [0.5372549019607843, '#5c8e7c'],
    [0.5411764705882353, '#5c8f7c'],
    [0.5450980392156862, '#5d907c'],
    [0.5490196078431373, '#5e917b'],
    [0.5529411764705883, '#5f927b'],
    [0.5568627450980392, '#60927b'],
    [0.5607843137254902, '#61937b'],
    [0.5647058823529412, '#62947a'],
    [0.5686274509803921, '#63957a'],
    [0.5725490196078431, '#64967a'],
    [0.5764705882352941, '#65977a'],
    [0.580392156862745, '#66987a'],
    [0.5843137254901961, '#679979'],
    [0.5882352941176471, '#679a79'],
    [0.592156862745098, '#689b79'],
    [0.596078431372549, '#699c79'],
    [0.6, '#6a9d78'],
    [0.6039215686274509, '#6b9e78'],
    [0.6078431372549019, '#6c9f78'],
    [0.611764705882353, '#6da078'],
    [0.615686274509804, '#6ea177'],
    [0.6196078431372549, '#6fa277'],
    [0.6235294117647059, '#70a377'],
    [0.6274509803921569, '#71a477'],
    [0.6313725490196078, '#72a576'],
    [0.6352941176470588, '#73a676'],
    [0.6392156862745098, '#74a776'],
    [0.6431372549019607, '#75a876'],
    [0.6470588235294118, '#76a975'],
    [0.6509803921568628, '#77aa75'],
    [0.6549019607843137, '#78ab75'],
    [0.6588235294117647, '#79ac75'],
    [0.6627450980392157, '#7aad74'],
    [0.6666666666666666, '#7bae74'],
    [0.6705882352941176, '#7caf74'],
    [0.6745098039215687, '#7db074'],
    [0.6784313725490196, '#7eb173'],
    [0.6823529411764706, '#7fb273'],
    [0.6862745098039216, '#80b373'],
    [0.6901960784313725, '#81b473'],
    [0.6941176470588235, '#82b572'],
    [0.6980392156862745, '#83b672'],
    [0.7019607843137254, '#84b772'],
    [0.7058823529411764, '#85b872'],
    [0.7098039215686275, '#86b971'],
    [0.7137254901960784, '#87ba71'],
    [0.7176470588235294, '#88bb71'],
    [0.7215686274509804, '#89bc71'],
    [0.7254901960784313, '#8abd70'],
    [0.7294117647058823, '#8bbe70'],
    [0.7333333333333333, '#8cbf70'],
    [0.7372549019607844, '#8dc070'],
    [0.7411764705882353, '#8ec16f'],
    [0.7450980392156863, '#90c26f'],
    [0.7490196078431373, '#91c36f'],
    [0.7529411764705882, '#92c46e'],
    [0.7568627450980392, '#93c66e'],
    [0.7607843137254902, '#94c76e'],
    [0.7647058823529411, '#95c86e'],
    [0.7686274509803921, '#96c96d'],
    [0.7725490196078432, '#97ca6d'],
    [0.7764705882352941, '#98cb6d'],
    [0.7803921568627451, '#99cc6d'],
    [0.7843137254901961, '#9bcd6c'],
    [0.788235294117647, '#9cce6c'],
    [0.792156862745098, '#9dcf6c'],
    [0.796078431372549, '#9ed06c'],
    [0.8, '#9fd26b'],
    [0.803921568627451, '#a1d36b'],
    [0.807843137254902, '#a2d46b'],
    [0.8117647058823529, '#a3d56b'],
    [0.8156862745098039, '#a4d66a'],
    [0.8196078431372549, '#a6d76a'],
    [0.8235294117647058, '#a7d86a'],
    [0.8274509803921568, '#a9d96a'],
    [0.8313725490196078, '#aada69'],
    [0.8352941176470589, '#acdb69'],
    [0.8392156862745098, '#addd69'],
    [0.8431372549019608, '#afde69'],
    [0.8470588235294118, '#b0df68'],
    [0.8509803921568627, '#b2e068'],
    [0.8549019607843137, '#b4e168'],
    [0.8588235294117647, '#b5e268'],
    [0.8627450980392157, '#b7e368'],
    [0.8666666666666667, '#b9e468'],
    [0.8705882352941177, '#bbe567'],
    [0.8745098039215686, '#bde667'],
    [0.8784313725490196, '#bfe767'],
    [0.8823529411764706, '#c0e867'],
    [0.8862745098039215, '#c2e967'],
    [0.8901960784313725, '#c4ea67'],
    [0.8941176470588235, '#c6eb67'],
    [0.8980392156862745, '#c8eb67'],
    [0.9019607843137255, '#caec67'],
    [0.9058823529411765, '#cded66'],
    [0.9098039215686274, '#cfee66'],
    [0.9137254901960784, '#d1ef66'],
    [0.9176470588235294, '#d3f066'],
    [0.9215686274509803, '#d5f066'],
    [0.9254901960784314, '#d7f166'],
    [0.9294117647058824, '#d9f266'],
    [0.9333333333333333, '#dbf366'],
    [0.9372549019607843, '#ddf466'],
    [0.9411764705882353, '#dff466'],
    [0.9450980392156862, '#e2f566'],
    [0.9490196078431372, '#e4f666'],
    [0.9529411764705882, '#e6f666'],
    [0.9568627450980391, '#e8f766'],
    [0.9607843137254902, '#eaf866'],
    [0.9647058823529412, '#ecf966'],
    [0.9686274509803922, '#eef966'],
    [0.9725490196078431, '#f0fa66'],
    [0.9764705882352941, '#f2fb66'],
    [0.9803921568627451, '#f5fb66'],
    [0.984313725490196, '#f7fc66'],
    [0.9882352941176471, '#f9fd66'],
    [0.9921568627450981, '#fbfe66'],
    [0.996078431372549, '#fdfe66'],
    [1.0, '#ffff66'],
  ],
  vik: [
    [0.0, '#001261'],
    [0.00392156862745098, '#011462'],
    [0.00784313725490196, '#011563'],
    [0.011764705882352941, '#011764'],
    [0.01568627450980392, '#011865'],
    [0.0196078431372549, '#011a66'],
    [0.023529411764705882, '#021c67'],
    [0.027450980392156862, '#021d68'],
    [0.03137254901960784, '#021f69'],
    [0.03529411764705882, '#02206a'],
    [0.0392156862745098, '#02226b'],
    [0.043137254901960784, '#02236c'],
    [0.047058823529411764, '#02256d'],
    [0.050980392156862744, '#02276e'],
    [0.054901960784313725, '#02286f'],
    [0.058823529411764705, '#022a70'],
    [0.06274509803921569, '#022b71'],
    [0.06666666666666667, '#022d72'],
    [0.07058823529411765, '#022e73'],
    [0.07450980392156863, '#023074'],
    [0.0784313725490196, '#023175'],
    [0.08235294117647059, '#023376'],
    [0.08627450980392157, '#023477'],
    [0.09019607843137255, '#023678'],
    [0.09411764705882353, '#023779'],
    [0.09803921568627451, '#02397a'],
    [0.10196078431372549, '#023a7b'],
    [0.10588235294117647, '#033c7c'],
    [0.10980392156862745, '#033e7d'],
    [0.11372549019607843, '#033f7e'],
    [0.11764705882352941, '#03417f'],
    [0.12156862745098039, '#034280'],
    [0.12549019607843137, '#034481'],
    [0.12941176470588234, '#034582'],
    [0.13333333333333333, '#034783'],
    [0.13725490196078433, '#034984'],
    [0.1411764705882353, '#034a85'],
    [0.14509803921568626, '#044c86'],
    [0.14901960784313725, '#044d87'],
    [0.15294117647058825, '#044f88'],
    [0.1568627450980392, '#055189'],
    [0.16078431372549018, '#05528a'],
    [0.16470588235294117, '#06548b'],
    [0.16862745098039217, '#06568c'],
    [0.17254901960784313, '#07578d'],
    [0.1764705882352941, '#08598f'],
    [0.1803921568627451, '#095b90'],
    [0.1843137254901961, '#0b5d91'],
    [0.18823529411764706, '#0c5e92'],
    [0.19215686274509802, '#0e6093'],
    [0.19607843137254902, '#106294'],
    [0.2, '#116496'],
    [0.20392156862745098, '#136697'],
    [0.20784313725490194, '#156798'],
    [0.21176470588235294, '#176999'],
    [0.21568627450980393, '#196b9a'],
    [0.2196078431372549, '#1c6d9c'],
    [0.22352941176470587, '#1e6f9d'],
    [0.22745098039215686, '#20719e'],
    [0.23137254901960785, '#2373a0'],
    [0.23529411764705882, '#2575a1'],
    [0.2392156862745098, '#2877a2'],
    [0.24313725490196078, '#2b79a4'],
    [0.24705882352941178, '#2d7ba5'],
    [0.25098039215686274, '#307da6'],
    [0.2549019607843137, '#337fa8'],
    [0.2588235294117647, '#3681a9'],
    [0.2627450980392157, '#3983ab'],
    [0.26666666666666666, '#3c85ac'],
    [0.27058823529411763, '#3f87ad'],
    [0.27450980392156865, '#4289af'],
    [0.2784313725490196, '#458bb0'],
    [0.2823529411764706, '#488db2'],
    [0.28627450980392155, '#4b90b3'],
    [0.2901960784313725, '#4e92b4'],
    [0.29411764705882354, '#5194b6'],
    [0.2980392156862745, '#5496b7'],
    [0.30196078431372547, '#5798b9'],
    [0.3058823529411765, '#5a9aba'],
    [0.30980392156862746, '#5d9cbb'],
    [0.3137254901960784, '#619ebd'],
    [0.3176470588235294, '#64a0be'],
    [0.32156862745098036, '#67a2c0'],
    [0.3254901960784314, '#6aa4c1'],
    [0.32941176470588235, '#6da6c2'],
    [0.3333333333333333, '#71a8c4'],
    [0.33725490196078434, '#74aac5'],
    [0.3411764705882353, '#77acc6'],
    [0.34509803921568627, '#7aaec8'],
    [0.34901960784313724, '#7db0c9'],
    [0.3529411764705882, '#80b2ca'],
    [0.3568627450980392, '#84b4cc'],
    [0.3607843137254902, '#87b6cd'],
    [0.36470588235294116, '#8ab8ce'],
    [0.3686274509803922, '#8dbad0'],
    [0.37254901960784315, '#90bcd1'],
    [0.3764705882352941, '#94bed2'],
    [0.3803921568627451, '#97c0d4'],
    [0.38431372549019605, '#9ac2d5'],
    [0.38823529411764707, '#9dc4d6'],
    [0.39215686274509803, '#a0c5d8'],
    [0.396078431372549, '#a3c7d9'],
    [0.4, '#a7c9da'],
    [0.403921568627451, '#aacbdc'],
    [0.40784313725490196, '#adcddd'],
    [0.4117647058823529, '#b0cfde'],
    [0.4156862745098039, '#b3d1df'],
    [0.4196078431372549, '#b6d3e1'],
    [0.4235294117647059, '#bad5e2'],
    [0.42745098039215684, '#bdd6e3'],
    [0.43137254901960786, '#c0d8e4'],
    [0.43529411764705883, '#c3dae5'],
    [0.4392156862745098, '#c6dbe6'],
    [0.44313725490196076, '#c9dde7'],
    [0.44705882352941173, '#ccdfe8'],
    [0.45098039215686275, '#cfe0e8'],
    [0.4549019607843137, '#d2e1e9'],
    [0.4588235294117647, '#d5e3e9'],
    [0.4627450980392157, '#d8e4e9'],
    [0.4666666666666667, '#dbe5e9'],
    [0.47058823529411764, '#dee6e9'],
    [0.4745098039215686, '#e0e6e9'],
    [0.4784313725490196, '#e2e7e8'],
    [0.4823529411764706, '#e5e7e8'],
    [0.48627450980392156, '#e7e7e7'],
    [0.49019607843137253, '#e8e7e5'],
    [0.49411764705882355, '#eae6e4'],
    [0.4980392156862745, '#ebe6e2'],
    [0.5019607843137255, '#ece5e0'],
    [0.5058823529411764, '#ede4de'],
    [0.5098039215686274, '#eee3dc'],
    [0.5137254901960784, '#eee1da'],
    [0.5176470588235293, '#eee0d8'],
    [0.5215686274509804, '#eeded5'],
    [0.5254901960784314, '#eeddd3'],
    [0.5294117647058824, '#eedbd0'],
    [0.5333333333333333, '#eed9cd'],
    [0.5372549019607843, '#edd7cb'],
    [0.5411764705882353, '#edd5c8'],
    [0.5450980392156862, '#ecd3c5'],
    [0.5490196078431373, '#ecd1c3'],
    [0.5529411764705883, '#ebd0c0'],
    [0.5568627450980392, '#eacebd'],
    [0.5607843137254902, '#e9ccba'],
    [0.5647058823529412, '#e9cab8'],
    [0.5686274509803921, '#e8c8b5'],
    [0.5725490196078431, '#e7c6b2'],
    [0.5764705882352941, '#e6c4b0'],
    [0.580392156862745, '#e5c1ad'],
    [0.5843137254901961, '#e4bfaa'],
    [0.5882352941176471, '#e4bea8'],
    [0.592156862745098, '#e3bca5'],
    [0.596078431372549, '#e2baa2'],
    [0.6, '#e1b8a0'],
    [0.6039215686274509, '#e0b69d'],
    [0.6078431372549019, '#dfb49a'],
    [0.611764705882353, '#dfb298'],
    [0.615686274509804, '#deb095'],
    [0.6196078431372549, '#ddae93'],
    [0.6235294117647059, '#dcac90'],
    [0.6274509803921569, '#dbaa8d'],
    [0.6313725490196078, '#dba88b'],
    [0.6352941176470588, '#daa688'],
    [0.6392156862745098, '#d9a486'],
    [0.6431372549019607, '#d8a283'],
    [0.6470588235294118, '#d7a081'],
    [0.6509803921568628, '#d69f7e'],
    [0.6549019607843137, '#d69d7c'],
    [0.6588235294117647, '#d59b79'],
    [0.6627450980392157, '#d49977'],
    [0.6666666666666666, '#d39774'],
    [0.6705882352941176, '#d39572'],
    [0.6745098039215687, '#d29470'],
    [0.6784313725490196, '#d1926d'],
    [0.6823529411764706, '#d0906b'],
    [0.6862745098039216, '#cf8e68'],
    [0.6901960784313725, '#cf8c66'],
    [0.6941176470588235, '#ce8b64'],
    [0.6980392156862745, '#cd8961'],
    [0.7019607843137254, '#cc875f'],
    [0.7058823529411764, '#cc855d'],
    [0.7098039215686275, '#cb835a'],
    [0.7137254901960784, '#ca8258'],
    [0.7176470588235294, '#c98056'],
    [0.7215686274509804, '#c97e53'],
    [0.7254901960784313, '#c87c51'],
    [0.7294117647058823, '#c77b4f'],
    [0.7333333333333333, '#c6794c'],
    [0.7372549019607844, '#c6774a'],
    [0.7411764705882353, '#c57548'],
    [0.7450980392156863, '#c47445'],
    [0.7490196078431373, '#c37243'],
    [0.7529411764705882, '#c27041'],
    [0.7568627450980392, '#c26e3f'],
    [0.7607843137254902, '#c16d3c'],
    [0.7647058823529411, '#c06b3a'],
    [0.7686274509803921, '#bf6938'],
    [0.7725490196078432, '#be6736'],
    [0.7764705882352941, '#be6533'],
    [0.7803921568627451, '#bd6431'],
    [0.7843137254901961, '#bc622f'],
    [0.788235294117647, '#bb602d'],
    [0.792156862745098, '#ba5e2a'],
    [0.796078431372549, '#b85c28'],
    [0.8, '#b75a26'],
    [0.803921568627451, '#b65824'],
    [0.807843137254902, '#b55521'],
    [0.8117647058823529, '#b3531f'],
    [0.8156862745098039, '#b2511d'],
    [0.8196078431372549, '#b04f1b'],
    [0.8235294117647058, '#af4c18'],
    [0.8274509803921568, '#ad4a16'],
    [0.8313725490196078, '#ab4814'],
    [0.8352941176470589, '#a94512'],
    [0.8392156862745098, '#a74310'],
    [0.8431372549019608, '#a5400f'],
    [0.8470588235294118, '#a33e0d'],
    [0.8509803921568627, '#a13c0b'],
    [0.8549019607843137, '#9f390a'],
    [0.8588235294117647, '#9c3709'],
    [0.8627450980392157, '#9a3508'],
    [0.8666666666666667, '#983307'],
    [0.8705882352941177, '#963107'],
    [0.8745098039215686, '#942f06'],
    [0.8784313725490196, '#912d06'],
    [0.8823529411764706, '#8f2b06'],
    [0.8862745098039215, '#8d2906'],
    [0.8901960784313725, '#8b2706'],
    [0.8941176470588235, '#892606'],
    [0.8980392156862745, '#872406'],
    [0.9019607843137255, '#852206'],
    [0.9058823529411765, '#832106'],
    [0.9098039215686274, '#811f06'],
    [0.9137254901960784, '#7f1e06'],
    [0.9176470588235294, '#7e1d06'],
    [0.9215686274509803, '#7c1b06'],
    [0.9254901960784314, '#7a1a06'],
    [0.9294117647058824, '#781806'],
    [0.9333333333333333, '#761706'],
    [0.9372549019607843, '#741506'],
    [0.9411764705882353, '#731406'],
    [0.9450980392156862, '#711307'],
    [0.9490196078431372, '#6f1107'],
    [0.9529411764705882, '#6d1007'],
    [0.9568627450980391, '#6c0e07'],
    [0.9607843137254902, '#6a0d07'],
    [0.9647058823529412, '#680c07'],
    [0.9686274509803922, '#670a07'],
    [0.9725490196078431, '#650907'],
    [0.9764705882352941, '#630707'],
    [0.9803921568627451, '#620607'],
    [0.984313725490196, '#600408'],
    [0.9882352941176471, '#5e0308'],
    [0.9921568627450981, '#5d0208'],
    [0.996078431372549, '#5b0108'],
    [1.0, '#590008'],
  ],
  cork: [
    [0.0, '#2c194c'],
    [0.00392156862745098, '#2c1b4e'],
    [0.00784313725490196, '#2c1d4f'],
    [0.011764705882352941, '#2b1e51'],
    [0.01568627450980392, '#2b2053'],
    [0.0196078431372549, '#2b2254'],
    [0.023529411764705882, '#2b2356'],
    [0.027450980392156862, '#2b2557'],
    [0.03137254901960784, '#2b2659'],
    [0.03529411764705882, '#2b285b'],
    [0.0392156862745098, '#2b295c'],
    [0.043137254901960784, '#2a2b5e'],
    [0.047058823529411764, '#2a2c5f'],
    [0.050980392156862744, '#2a2e61'],
    [0.054901960784313725, '#2a2f62'],
    [0.058823529411764705, '#2a3164'],
    [0.06274509803921569, '#2a3365'],
    [0.06666666666666667, '#293467'],
    [0.07058823529411765, '#293668'],
    [0.07450980392156863, '#29376a'],
    [0.0784313725490196, '#29396b'],
    [0.08235294117647059, '#293a6d'],
    [0.08627450980392157, '#283c6e'],
    [0.09019607843137255, '#283d70'],
    [0.09411764705882353, '#283f72'],
    [0.09803921568627451, '#284073'],
    [0.10196078431372549, '#284275'],
    [0.10588235294117647, '#284376'],
    [0.10980392156862745, '#284578'],
    [0.11372549019607843, '#284679'],
    [0.11764705882352941, '#28487b'],
    [0.12156862745098039, '#284a7c'],
    [0.12549019607843137, '#284b7e'],
    [0.12941176470588234, '#284d7f'],
    [0.13333333333333333, '#294f81'],
    [0.13725490196078433, '#295082'],
    [0.1411764705882353, '#2a5284'],
    [0.14509803921568626, '#2a5385'],
    [0.14901960784313725, '#2b5587'],
    [0.15294117647058825, '#2c5788'],
    [0.1568627450980392, '#2d598a'],
    [0.16078431372549018, '#2e5a8b'],
    [0.16470588235294117, '#2f5c8d'],
    [0.16862745098039217, '#305e8e'],
    [0.17254901960784313, '#325f8f'],
    [0.1764705882352941, '#336191'],
    [0.1803921568627451, '#356392'],
    [0.1843137254901961, '#366493'],
    [0.18823529411764706, '#386695'],
    [0.19215686274509802, '#3a6896'],
    [0.19607843137254902, '#3c6997'],
    [0.2, '#3d6b98'],
    [0.20392156862745098, '#3f6c99'],
    [0.20784313725490194, '#416e9b'],
    [0.21176470588235294, '#43709c'],
    [0.21568627450980393, '#45719d'],
    [0.2196078431372549, '#47739e'],
    [0.22352941176470587, '#49749f'],
    [0.22745098039215686, '#4b76a0'],
    [0.23137254901960785, '#4c77a1'],
    [0.23529411764705882, '#4e79a2'],
    [0.2392156862745098, '#507aa3'],
    [0.24313725490196078, '#527ca4'],
    [0.24705882352941178, '#547da5'],
    [0.25098039215686274, '#567fa6'],
    [0.2549019607843137, '#5880a7'],
    [0.2588235294117647, '#5a82a8'],
    [0.2627450980392157, '#5c83a9'],
    [0.26666666666666666, '#5e85aa'],
    [0.27058823529411763, '#6086ac'],
    [0.27450980392156865, '#6288ad'],
    [0.2784313725490196, '#6489ae'],
    [0.2823529411764706, '#668baf'],
    [0.28627450980392155, '#688db0'],
    [0.2901960784313725, '#6a8eb1'],
    [0.29411764705882354, '#6d90b2'],
    [0.2980392156862745, '#6f92b3'],
    [0.30196078431372547, '#7193b4'],
    [0.3058823529411765, '#7395b6'],
    [0.30980392156862746, '#7597b7'],
    [0.3137254901960784, '#7798b8'],
    [0.3176470588235294, '#7a9ab9'],
    [0.32156862745098036, '#7c9cba'],
    [0.3254901960784314, '#7e9dbb'],
    [0.32941176470588235, '#819fbd'],
    [0.3333333333333333, '#83a1be'],
    [0.33725490196078434, '#85a3bf'],
    [0.3411764705882353, '#88a4c0'],
    [0.34509803921568627, '#8aa6c2'],
    [0.34901960784313724, '#8ca8c3'],
    [0.3529411764705882, '#8faac4'],
    [0.3568627450980392, '#91acc5'],
    [0.3607843137254902, '#94aec7'],
    [0.36470588235294116, '#96afc8'],
    [0.3686274509803922, '#99b1c9'],
    [0.37254901960784315, '#9bb3cb'],
    [0.3764705882352941, '#9eb5cc'],
    [0.3803921568627451, '#a0b7cd'],
    [0.38431372549019605, '#a3b9cf'],
    [0.38823529411764707, '#a5bbd0'],
    [0.39215686274509803, '#a8bdd1'],
    [0.396078431372549, '#aabfd3'],
    [0.4, '#adc1d4'],
    [0.403921568627451, '#b0c3d5'],
    [0.40784313725490196, '#b2c5d7'],
    [0.4117647058823529, '#b5c7d8'],
    [0.4156862745098039, '#b8c9da'],
    [0.4196078431372549, '#bacbdb'],
    [0.4235294117647059, '#bdcddc'],
    [0.42745098039215684, '#c0cfde'],
    [0.43137254901960786, '#c2d1df'],
    [0.43529411764705883, '#c5d3e1'],
    [0.4392156862745098, '#c8d5e2'],
    [0.44313725490196076, '#cad7e3'],
    [0.44705882352941173, '#cdd9e5'],
    [0.45098039215686275, '#d0dbe6'],
    [0.4549019607843137, '#d2dde7'],
    [0.4588235294117647, '#d5dfe8'],
    [0.4627450980392157, '#d7e1e9'],
    [0.4666666666666667, '#dae3ea'],
    [0.47058823529411764, '#dce5eb'],
    [0.4745098039215686, '#dee6ec'],
    [0.4784313725490196, '#e0e8ed'],
    [0.4823529411764706, '#e2e9ed'],
    [0.48627450980392156, '#e3ebed'],
    [0.49019607843137253, '#e5eced'],
    [0.49411764705882355, '#e6eded'],
    [0.4980392156862745, '#e6eded'],
    [0.5019607843137255, '#e6edec'],
    [0.5058823529411764, '#e6eeeb'],
    [0.5098039215686274, '#e6eeea'],
    [0.5137254901960784, '#e5ede8'],
    [0.5176470588235293, '#e4ede7'],
    [0.5215686274509804, '#e3ece5'],
    [0.5254901960784314, '#e2ebe3'],
    [0.5294117647058824, '#e0eae1'],
    [0.5333333333333333, '#dee9df'],
    [0.5372549019607843, '#dce8dd'],
    [0.5411764705882353, '#dae7db'],
    [0.5450980392156862, '#d8e5d9'],
    [0.5490196078431373, '#d6e4d6'],
    [0.5529411764705883, '#d4e2d4'],
    [0.5568627450980392, '#d2e1d2'],
    [0.5607843137254902, '#cfdfcf'],
    [0.5647058823529412, '#cddecd'],
    [0.5686274509803921, '#cbdccb'],
    [0.5725490196078431, '#c8dbc8'],
    [0.5764705882352941, '#c6d9c6'],
    [0.580392156862745, '#c4d7c3'],
    [0.5843137254901961, '#c1d6c1'],
    [0.5882352941176471, '#bfd4bf'],
    [0.592156862745098, '#bcd3bc'],
    [0.596078431372549, '#bad1ba'],
    [0.6, '#b7cfb7'],
    [0.6039215686274509, '#b5ceb5'],
    [0.6078431372549019, '#b2ccb2'],
    [0.611764705882353, '#b0cab0'],
    [0.615686274509804, '#aec9ad'],
    [0.6196078431372549, '#abc7ab'],
    [0.6235294117647059, '#a9c5a8'],
    [0.6274509803921569, '#a6c4a6'],
    [0.6313725490196078, '#a4c2a3'],
    [0.6352941176470588, '#a1c0a1'],
    [0.6392156862745098, '#9fbf9f'],
    [0.6431372549019607, '#9cbd9c'],
    [0.6470588235294118, '#9abb9a'],
    [0.6509803921568628, '#97ba97'],
    [0.6549019607843137, '#95b895'],
    [0.6588235294117647, '#92b792'],
    [0.6627450980392157, '#90b590'],
    [0.6666666666666666, '#8eb38d'],
    [0.6705882352941176, '#8bb28b'],
    [0.6745098039215687, '#89b088'],
    [0.6784313725490196, '#86ae86'],
    [0.6823529411764706, '#84ad84'],
    [0.6862745098039216, '#82ab81'],
    [0.6901960784313725, '#7faa7f'],
    [0.6941176470588235, '#7da87d'],
    [0.6980392156862745, '#7ba77a'],
    [0.7019607843137254, '#78a578'],
    [0.7058823529411764, '#76a376'],
    [0.7098039215686275, '#74a273'],
    [0.7137254901960784, '#71a071'],
    [0.7176470588235294, '#6f9f6f'],
    [0.7215686274509804, '#6d9d6c'],
    [0.7254901960784313, '#6b9c6a'],
    [0.7294117647058823, '#689a68'],
    [0.7333333333333333, '#669966'],
    [0.7372549019607844, '#649764'],
    [0.7411764705882353, '#629661'],
    [0.7450980392156863, '#60955f'],
    [0.7490196078431373, '#5d935d'],
    [0.7529411764705882, '#5b925b'],
    [0.7568627450980392, '#599059'],
    [0.7607843137254902, '#578f57'],
    [0.7647058823529411, '#558d55'],
    [0.7686274509803921, '#538c52'],
    [0.7725490196078432, '#518b50'],
    [0.7764705882352941, '#4f894e'],
    [0.7803921568627451, '#4d884c'],
    [0.7843137254901961, '#4b864a'],
    [0.788235294117647, '#498548'],
    [0.792156862745098, '#478446'],
    [0.796078431372549, '#458244'],
    [0.8, '#438142'],
    [0.803921568627451, '#417f40'],
    [0.807843137254902, '#3f7e3e'],
    [0.8117647058823529, '#3d7d3c'],
    [0.8156862745098039, '#3b7b3a'],
    [0.8196078431372549, '#397a38'],
    [0.8235294117647058, '#377836'],
    [0.8274509803921568, '#357734'],
    [0.8313725490196078, '#337532'],
    [0.8352941176470589, '#317430'],
    [0.8392156862745098, '#2f722e'],
    [0.8431372549019608, '#2d702c'],
    [0.8470588235294118, '#2b6f2a'],
    [0.8509803921568627, '#2a6d28'],
    [0.8549019607843137, '#286b26'],
    [0.8588235294117647, '#266a25'],
    [0.8627450980392157, '#256823'],
    [0.8666666666666667, '#236621'],
    [0.8705882352941177, '#226420'],
    [0.8745098039215686, '#20631e'],
    [0.8784313725490196, '#1f611d'],
    [0.8823529411764706, '#1e5f1b'],
    [0.8862745098039215, '#1d5d1a'],
    [0.8901960784313725, '#1c5b19'],
    [0.8941176470588235, '#1b5917'],
    [0.8980392156862745, '#1a5816'],
    [0.9019607843137255, '#195615'],
    [0.9058823529411765, '#185414'],
    [0.9098039215686274, '#185213'],
    [0.9137254901960784, '#175012'],
    [0.9176470588235294, '#174e12'],
    [0.9215686274509803, '#164c11'],
    [0.9254901960784314, '#164b10'],
    [0.9294117647058824, '#15490f'],
    [0.9333333333333333, '#15470f'],
    [0.9372549019607843, '#14450e'],
    [0.9411764705882353, '#14430d'],
    [0.9450980392156862, '#13410d'],
    [0.9490196078431372, '#13400c'],
    [0.9529411764705882, '#133e0c'],
    [0.9568627450980391, '#123c0b'],
    [0.9607843137254902, '#123a0a'],
    [0.9647058823529412, '#12380a'],
    [0.9686274509803922, '#113709'],
    [0.9725490196078431, '#113508'],
    [0.9764705882352941, '#113308'],
    [0.9803921568627451, '#103107'],
    [0.984313725490196, '#103006'],
    [0.9882352941176471, '#102e05'],
    [0.9921568627450981, '#102c05'],
    [0.996078431372549, '#0f2b04'],
    [1.0, '#0f2903'],
  ],
  batlow: [
    [0.0, '#011959'],
    [0.00392156862745098, '#021b59'],
    [0.00784313725490196, '#031c5a'],
    [0.011764705882352941, '#041e5a'],
    [0.01568627450980392, '#051f5a'],
    [0.0196078431372549, '#06215b'],
    [0.023529411764705882, '#07225b'],
    [0.027450980392156862, '#07245b'],
    [0.03137254901960784, '#08255b'],
    [0.03529411764705882, '#09275c'],
    [0.0392156862745098, '#0a285c'],
    [0.043137254901960784, '#0a2a5c'],
    [0.047058823529411764, '#0b2b5c'],
    [0.050980392156862744, '#0b2d5d'],
    [0.054901960784313725, '#0c2e5d'],
    [0.058823529411764705, '#0c2f5d'],
    [0.06274509803921569, '#0d315d'],
    [0.06666666666666667, '#0d325e'],
    [0.07058823529411765, '#0d335e'],
    [0.07450980392156863, '#0e355e'],
    [0.0784313725490196, '#0e365e'],
    [0.08235294117647059, '#0e375e'],
    [0.08627450980392157, '#0f385f'],
    [0.09019607843137255, '#0f395f'],
    [0.09411764705882353, '#0f3b5f'],
    [0.09803921568627451, '#0f3c5f'],
    [0.10196078431372549, '#103d5f'],
    [0.10588235294117647, '#103e5f'],
    [0.10980392156862745, '#103f60'],
    [0.11372549019607843, '#104060'],
    [0.11764705882352941, '#114160'],
    [0.12156862745098039, '#114260'],
    [0.12549019607843137, '#114360'],
    [0.12941176470588234, '#114460'],
    [0.13333333333333333, '#124561'],
    [0.13725490196078433, '#124661'],
    [0.1411764705882353, '#124761'],
    [0.14509803921568626, '#124861'],
    [0.14901960784313725, '#134961'],
    [0.15294117647058825, '#134a61'],
    [0.1568627450980392, '#134b61'],
    [0.16078431372549018, '#144c62'],
    [0.16470588235294117, '#144d62'],
    [0.16862745098039217, '#144e62'],
    [0.17254901960784313, '#154f62'],
    [0.1764705882352941, '#154f62'],
    [0.1803921568627451, '#165062'],
    [0.1843137254901961, '#165162'],
    [0.18823529411764706, '#175262'],
    [0.19215686274509802, '#175362'],
    [0.19607843137254902, '#185462'],
    [0.2, '#185562'],
    [0.20392156862745098, '#195662'],
    [0.20784313725490194, '#195762'],
    [0.21176470588235294, '#1a5762'],
    [0.21568627450980393, '#1b5862'],
    [0.2196078431372549, '#1b5962'],
    [0.22352941176470587, '#1c5a62'],
    [0.22745098039215686, '#1d5b62'],
    [0.23137254901960785, '#1e5c62'],
    [0.23529411764705882, '#1e5d62'],
    [0.2392156862745098, '#1f5d61'],
    [0.24313725490196078, '#205e61'],
    [0.24705882352941178, '#215f61'],
    [0.25098039215686274, '#226061'],
    [0.2549019607843137, '#236060'],
    [0.2588235294117647, '#246160'],
    [0.2627450980392157, '#256260'],
    [0.26666666666666666, '#26635f'],
    [0.27058823529411763, '#27635f'],
    [0.27450980392156865, '#28645f'],
    [0.2784313725490196, '#2a655e'],
    [0.2823529411764706, '#2b655e'],
    [0.28627450980392155, '#2c665d'],
    [0.2901960784313725, '#2d675d'],
    [0.29411764705882354, '#2f675c'],
    [0.2980392156862745, '#30685c'],
    [0.30196078431372547, '#31695b'],
    [0.3058823529411765, '#33695a'],
    [0.30980392156862746, '#346a5a'],
    [0.3137254901960784, '#356a59'],
    [0.3176470588235294, '#376b58'],
    [0.32156862745098036, '#386c58'],
    [0.3254901960784314, '#3a6c57'],
    [0.32941176470588235, '#3b6d56'],
    [0.3333333333333333, '#3c6d56'],
    [0.33725490196078434, '#3e6e55'],
    [0.3411764705882353, '#3f6e54'],
    [0.34509803921568627, '#416f53'],
    [0.34901960784313724, '#426f52'],
    [0.3529411764705882, '#447052'],
    [0.3568627450980392, '#457051'],
    [0.3607843137254902, '#477150'],
    [0.36470588235294116, '#48714f'],
    [0.3686274509803922, '#4a724e'],
    [0.37254901960784315, '#4c724d'],
    [0.3764705882352941, '#4d734d'],
    [0.3803921568627451, '#4f734c'],
    [0.38431372549019605, '#50744b'],
    [0.38823529411764707, '#52744a'],
    [0.39215686274509803, '#537549'],
    [0.396078431372549, '#557548'],
    [0.4, '#577647'],
    [0.403921568627451, '#587646'],
    [0.40784313725490196, '#5a7745'],
    [0.4117647058823529, '#5b7745'],
    [0.4156862745098039, '#5d7844'],
    [0.4196078431372549, '#5f7843'],
    [0.4235294117647059, '#607942'],
    [0.42745098039215684, '#627941'],
    [0.43137254901960786, '#637a40'],
    [0.43529411764705883, '#657a3f'],
    [0.4392156862745098, '#677b3e'],
    [0.44313725490196076, '#687b3e'],
    [0.44705882352941173, '#6a7b3d'],
    [0.45098039215686275, '#6c7c3c'],
    [0.4549019607843137, '#6d7c3b'],
    [0.4588235294117647, '#6f7d3a'],
    [0.4627450980392157, '#717d39'],
    [0.4666666666666667, '#737e38'],
    [0.47058823529411764, '#747e38'],
    [0.4745098039215686, '#767f37'],
    [0.4784313725490196, '#787f36'],
    [0.4823529411764706, '#798035'],
    [0.48627450980392156, '#7b8034'],
    [0.49019607843137253, '#7d8134'],
    [0.49411764705882355, '#7f8133'],
    [0.4980392156862745, '#818232'],
    [0.5019607843137255, '#828231'],
    [0.5058823529411764, '#848331'],
    [0.5098039215686274, '#868330'],
    [0.5137254901960784, '#88842f'],
    [0.5176470588235293, '#8a842f'],
    [0.5215686274509804, '#8c852e'],
    [0.5254901960784314, '#8e852e'],
    [0.5294117647058824, '#8f862d'],
    [0.5333333333333333, '#91862d'],
    [0.5372549019607843, '#93872c'],
    [0.5411764705882353, '#95872c'],
    [0.5450980392156862, '#97882c'],
    [0.5490196078431373, '#99882c'],
    [0.5529411764705883, '#9b892b'],
    [0.5568627450980392, '#9d892b'],
    [0.5607843137254902, '#9f892b'],
    [0.5647058823529412, '#a18a2b'],
    [0.5686274509803921, '#a38a2c'],
    [0.5725490196078431, '#a58b2c'],
    [0.5764705882352941, '#a78b2c'],
    [0.580392156862745, '#a98c2c'],
    [0.5843137254901961, '#ab8c2d'],
    [0.5882352941176471, '#ad8c2d'],
    [0.592156862745098, '#af8d2e'],
    [0.596078431372549, '#b18d2f'],
    [0.6, '#b38e2f'],
    [0.6039215686274509, '#b58e30'],
    [0.6078431372549019, '#b78e31'],
    [0.611764705882353, '#b98f32'],
    [0.615686274509804, '#bb8f33'],
    [0.6196078431372549, '#bd8f34'],
    [0.6235294117647059, '#be9035'],
    [0.6274509803921569, '#c09036'],
    [0.6313725490196078, '#c29037'],
    [0.6352941176470588, '#c49138'],
    [0.6392156862745098, '#c6913a'],
    [0.6431372549019607, '#c8913b'],
    [0.6470588235294118, '#ca923c'],
    [0.6509803921568628, '#cb923e'],
    [0.6549019607843137, '#cd923f'],
    [0.6588235294117647, '#cf9340'],
    [0.6627450980392157, '#d19342'],
    [0.6666666666666666, '#d29343'],
    [0.6705882352941176, '#d49445'],
    [0.6745098039215687, '#d69446'],
    [0.6784313725490196, '#d89448'],
    [0.6823529411764706, '#d9954a'],
    [0.6862745098039216, '#db954b'],
    [0.6901960784313725, '#dd954d'],
    [0.6941176470588235, '#de964f'],
    [0.6980392156862745, '#e09651'],
    [0.7019607843137254, '#e19752'],
    [0.7058823529411764, '#e39754'],
    [0.7098039215686275, '#e49756'],
    [0.7137254901960784, '#e69858'],
    [0.7176470588235294, '#e7985a'],
    [0.7215686274509804, '#e9995c'],
    [0.7254901960784313, '#ea995e'],
    [0.7294117647058823, '#eb9a60'],
    [0.7333333333333333, '#ed9a62'],
    [0.7372549019607844, '#ee9b64'],
    [0.7411764705882353, '#ef9b67'],
    [0.7450980392156863, '#f09c69'],
    [0.7490196078431373, '#f19d6b'],
    [0.7529411764705882, '#f29d6d'],
    [0.7568627450980392, '#f39e70'],
    [0.7607843137254902, '#f49f72'],
    [0.7647058823529411, '#f59f74'],
    [0.7686274509803921, '#f6a077'],
    [0.7725490196078432, '#f7a179'],
    [0.7764705882352941, '#f8a17b'],
    [0.7803921568627451, '#f8a27e'],
    [0.7843137254901961, '#f9a380'],
    [0.788235294117647, '#f9a382'],
    [0.792156862745098, '#faa485'],
    [0.796078431372549, '#faa587'],
    [0.8, '#fba689'],
    [0.803921568627451, '#fba68c'],
    [0.807843137254902, '#fca78e'],
    [0.8117647058823529, '#fca890'],
    [0.8156862745098039, '#fca993'],
    [0.8196078431372549, '#fca995'],
    [0.8235294117647058, '#fdaa97'],
    [0.8274509803921568, '#fdab9a'],
    [0.8313725490196078, '#fdac9c'],
    [0.8352941176470589, '#fdac9e'],
    [0.8392156862745098, '#fdada0'],
    [0.8431372549019608, '#fdaea2'],
    [0.8470588235294118, '#fdafa5'],
    [0.8509803921568627, '#fdafa7'],
    [0.8549019607843137, '#fdb0a9'],
    [0.8588235294117647, '#fdb1ab'],
    [0.8627450980392157, '#fdb2ad'],
    [0.8666666666666667, '#fdb2af'],
    [0.8705882352941177, '#fdb3b1'],
    [0.8745098039215686, '#fdb4b4'],
    [0.8784313725490196, '#fdb4b6'],
    [0.8823529411764706, '#fdb5b8'],
    [0.8862745098039215, '#fdb6ba'],
    [0.8901960784313725, '#fdb7bc'],
    [0.8941176470588235, '#fdb7be'],
    [0.8980392156862745, '#fdb8c0'],
    [0.9019607843137255, '#fdb9c2'],
    [0.9058823529411765, '#fdbac4'],
    [0.9098039215686274, '#fdbac7'],
    [0.9137254901960784, '#fdbbc9'],
    [0.9176470588235294, '#fdbccb'],
    [0.9215686274509803, '#fdbccd'],
    [0.9254901960784314, '#fcbdcf'],
    [0.9294117647058824, '#fcbed1'],
    [0.9333333333333333, '#fcbfd3'],
    [0.9372549019607843, '#fcbfd6'],
    [0.9411764705882353, '#fcc0d8'],
    [0.9450980392156862, '#fcc1da'],
    [0.9490196078431372, '#fcc2dc'],
    [0.9529411764705882, '#fcc3df'],
    [0.9568627450980391, '#fcc3e1'],
    [0.9607843137254902, '#fcc4e3'],
    [0.9647058823529412, '#fcc5e5'],
    [0.9686274509803922, '#fbc6e8'],
    [0.9725490196078431, '#fbc6ea'],
    [0.9764705882352941, '#fbc7ec'],
    [0.9803921568627451, '#fbc8ef'],
    [0.984313725490196, '#fbc9f1'],
    [0.9882352941176471, '#fbcaf3'],
    [0.9921568627450981, '#fbcaf6'],
    [0.996078431372549, '#facbf8'],
    [1.0, '#faccfa'],
  ],
  turku: [
    [0.0, '#000000'],
    [0.00392156862745098, '#020202'],
    [0.00784313725490196, '#040403'],
    [0.011764705882352941, '#060605'],
    [0.01568627450980392, '#070707'],
    [0.0196078431372549, '#090908'],
    [0.023529411764705882, '#0b0b0a'],
    [0.027450980392156862, '#0d0d0c'],
    [0.03137254901960784, '#0e0e0d'],
    [0.03529411764705882, '#10100e'],
    [0.0392156862745098, '#111110'],
    [0.043137254901960784, '#121211'],
    [0.047058823529411764, '#141312'],
    [0.050980392156862744, '#151513'],
    [0.054901960784313725, '#161614'],
    [0.058823529411764705, '#171715'],
    [0.06274509803921569, '#181816'],
    [0.06666666666666667, '#191917'],
    [0.07058823529411765, '#1a1a18'],
    [0.07450980392156863, '#1b1b19'],
    [0.0784313725490196, '#1c1c19'],
    [0.08235294117647059, '#1d1d1a'],
    [0.08627450980392157, '#1e1e1b'],
    [0.09019607843137255, '#1f1f1c'],
    [0.09411764705882353, '#20201d'],
    [0.09803921568627451, '#21211e'],
    [0.10196078431372549, '#22221f'],
    [0.10588235294117647, '#23231f'],
    [0.10980392156862745, '#242420'],
    [0.11372549019607843, '#252521'],
    [0.11764705882352941, '#262622'],
    [0.12156862745098039, '#272723'],
    [0.12549019607843137, '#282823'],
    [0.12941176470588234, '#292924'],
    [0.13333333333333333, '#2a2a25'],
    [0.13725490196078433, '#2b2b26'],
    [0.1411764705882353, '#2c2c27'],
    [0.14509803921568626, '#2d2d27'],
    [0.14901960784313725, '#2e2e28'],
    [0.15294117647058825, '#2f2f29'],
    [0.1568627450980392, '#30302a'],
    [0.16078431372549018, '#32312a'],
    [0.16470588235294117, '#33322b'],
    [0.16862745098039217, '#34332c'],
    [0.17254901960784313, '#35342d'],
    [0.1764705882352941, '#36362d'],
    [0.1803921568627451, '#37372e'],
    [0.1843137254901961, '#38382f'],
    [0.18823529411764706, '#393930'],
    [0.19215686274509802, '#3a3a30'],
    [0.19607843137254902, '#3b3b31'],
    [0.2, '#3c3c32'],
    [0.20392156862745098, '#3d3d32'],
    [0.20784313725490194, '#3e3e33'],
    [0.21176470588235294, '#3f3f34'],
    [0.21568627450980393, '#404034'],
    [0.2196078431372549, '#414135'],
    [0.22352941176470587, '#424235'],
    [0.22745098039215686, '#434336'],
    [0.23137254901960785, '#444437'],
    [0.23529411764705882, '#454537'],
    [0.2392156862745098, '#464638'],
    [0.24313725490196078, '#474738'],
    [0.24705882352941178, '#484839'],
    [0.25098039215686274, '#49493a'],
    [0.2549019607843137, '#4b4a3a'],
    [0.2588235294117647, '#4c4b3b'],
    [0.2627450980392157, '#4d4c3b'],
    [0.26666666666666666, '#4e4d3c'],
    [0.27058823529411763, '#4f4e3c'],
    [0.27450980392156865, '#504f3d'],
    [0.2784313725490196, '#51503d'],
    [0.2823529411764706, '#52513e'],
    [0.28627450980392155, '#53523e'],
    [0.2901960784313725, '#54533f'],
    [0.29411764705882354, '#55543f'],
    [0.2980392156862745, '#565640'],
    [0.30196078431372547, '#575740'],
    [0.3058823529411765, '#585841'],
    [0.30980392156862746, '#595941'],
    [0.3137254901960784, '#5a5a42'],
    [0.3176470588235294, '#5b5b42'],
    [0.32156862745098036, '#5c5c43'],
    [0.3254901960784314, '#5d5d43'],
    [0.32941176470588235, '#5e5e44'],
    [0.3333333333333333, '#5f5f44'],
    [0.33725490196078434, '#606045'],
    [0.3411764705882353, '#616145'],
    [0.34509803921568627, '#626246'],
    [0.34901960784313724, '#636346'],
    [0.3529411764705882, '#646447'],
    [0.3568627450980392, '#656547'],
    [0.3607843137254902, '#666647'],
    [0.36470588235294116, '#676748'],
    [0.3686274509803922, '#686848'],
    [0.37254901960784315, '#6a6949'],
    [0.3764705882352941, '#6b6a49'],
    [0.3803921568627451, '#6c6b4a'],
    [0.38431372549019605, '#6d6c4a'],
    [0.38823529411764707, '#6e6d4b'],
    [0.39215686274509803, '#6f6e4b'],
    [0.396078431372549, '#706f4c'],
    [0.4, '#71704c'],
    [0.403921568627451, '#72714d'],
    [0.40784313725490196, '#73724d'],
    [0.4117647058823529, '#75734e'],
    [0.4156862745098039, '#76744e'],
    [0.4196078431372549, '#77754f'],
    [0.4235294117647059, '#78764f'],
    [0.42745098039215684, '#797750'],
    [0.43137254901960786, '#7b7950'],
    [0.43529411764705883, '#7c7a51'],
    [0.4392156862745098, '#7d7b51'],
    [0.44313725490196076, '#7e7c52'],
    [0.44705882352941173, '#7f7d52'],
    [0.45098039215686275, '#817e53'],
    [0.4549019607843137, '#827f53'],
    [0.4588235294117647, '#838054'],
    [0.4627450980392157, '#858154'],
    [0.4666666666666667, '#868255'],
    [0.47058823529411764, '#878356'],
    [0.4745098039215686, '#898456'],
    [0.4784313725490196, '#8a8557'],
    [0.4823529411764706, '#8b8658'],
    [0.48627450980392156, '#8d8758'],
    [0.49019607843137253, '#8e8859'],
    [0.49411764705882355, '#908a59'],
    [0.4980392156862745, '#918b5a'],
    [0.5019607843137255, '#938c5b'],
    [0.5058823529411764, '#948d5b'],
    [0.5098039215686274, '#968e5c'],
    [0.5137254901960784, '#978f5d'],
    [0.5176470588235293, '#99905e'],
    [0.5215686274509804, '#9a915e'],
    [0.5254901960784314, '#9c925f'],
    [0.5294117647058824, '#9d9360'],
    [0.5333333333333333, '#9f9461'],
    [0.5372549019607843, '#a19461'],
    [0.5411764705882353, '#a29562'],
    [0.5450980392156862, '#a49663'],
    [0.5490196078431373, '#a59764'],
    [0.5529411764705883, '#a79864'],
    [0.5568627450980392, '#a99965'],
    [0.5607843137254902, '#aa9a66'],
    [0.5647058823529412, '#ac9a67'],
    [0.5686274509803921, '#ad9b68'],
    [0.5725490196078431, '#af9c68'],
    [0.5764705882352941, '#b09d69'],
    [0.580392156862745, '#b29d6a'],
    [0.5843137254901961, '#b39e6b'],
    [0.5882352941176471, '#b59f6c'],
    [0.592156862745098, '#b69f6c'],
    [0.596078431372549, '#b8a06d'],
    [0.6, '#b9a06e'],
    [0.6039215686274509, '#bba16f'],
    [0.6078431372549019, '#bca170'],
    [0.611764705882353, '#bea270'],
    [0.615686274509804, '#bfa271'],
    [0.6196078431372549, '#c0a272'],
    [0.6235294117647059, '#c2a373'],
    [0.6274509803921569, '#c3a374'],
    [0.6313725490196078, '#c4a474'],
    [0.6352941176470588, '#c6a475'],
    [0.6392156862745098, '#c7a476'],
    [0.6431372549019607, '#c8a477'],
    [0.6470588235294118, '#c9a578'],
    [0.6509803921568628, '#cba578'],
    [0.6549019607843137, '#cca579'],
    [0.6588235294117647, '#cda57a'],
    [0.6627450980392157, '#cea67b'],
    [0.6666666666666666, '#cfa67c'],
    [0.6705882352941176, '#d0a67c'],
    [0.6745098039215687, '#d1a67d'],
    [0.6784313725490196, '#d2a67e'],
    [0.6823529411764706, '#d3a67f'],
    [0.6862745098039216, '#d4a780'],
    [0.6901960784313725, '#d6a780'],
    [0.6941176470588235, '#d7a781'],
    [0.6980392156862745, '#d8a782'],
    [0.7019607843137254, '#d9a783'],
    [0.7058823529411764, '#daa784'],
    [0.7098039215686275, '#dba885'],
    [0.7137254901960784, '#dba886'],
    [0.7176470588235294, '#dca887'],
    [0.7215686274509804, '#dda888'],
    [0.7254901960784313, '#dea889'],
    [0.7294117647058823, '#dfa98a'],
    [0.7333333333333333, '#e0a98b'],
    [0.7372549019607844, '#e1a98c'],
    [0.7411764705882353, '#e2a98d'],
    [0.7450980392156863, '#e3aa8e'],
    [0.7490196078431373, '#e4aa8f'],
    [0.7529411764705882, '#e5aa90'],
    [0.7568627450980392, '#e6ab92'],
    [0.7607843137254902, '#e7ab93'],
    [0.7647058823529411, '#e8ac94'],
    [0.7686274509803921, '#e9ac95'],
    [0.7725490196078432, '#e9ad97'],
    [0.7764705882352941, '#eaad98'],
    [0.7803921568627451, '#ebae99'],
    [0.7843137254901961, '#ecae9b'],
    [0.788235294117647, '#edaf9c'],
    [0.792156862745098, '#eeb09e'],
    [0.796078431372549, '#efb09f'],
    [0.8, '#efb1a1'],
    [0.803921568627451, '#f0b2a2'],
    [0.807843137254902, '#f1b3a3'],
    [0.8117647058823529, '#f2b3a5'],
    [0.8156862745098039, '#f2b4a7'],
    [0.8196078431372549, '#f3b5a8'],
    [0.8235294117647058, '#f4b6aa'],
    [0.8274509803921568, '#f4b7ab'],
    [0.8313725490196078, '#f5b8ad'],
    [0.8352941176470589, '#f6b9ae'],
    [0.8392156862745098, '#f6bab0'],
    [0.8431372549019608, '#f7bbb1'],
    [0.8470588235294118, '#f7bcb3'],
    [0.8509803921568627, '#f8bdb4'],
    [0.8549019607843137, '#f8beb6'],
    [0.8588235294117647, '#f9bfb7'],
    [0.8627450980392157, '#f9c0b9'],
    [0.8666666666666667, '#fac1ba'],
    [0.8705882352941177, '#fac2bc'],
    [0.8745098039215686, '#fbc3bd'],
    [0.8784313725490196, '#fbc4bf'],
    [0.8823529411764706, '#fbc5c0'],
    [0.8862745098039215, '#fcc6c1'],
    [0.8901960784313725, '#fcc7c3'],
    [0.8941176470588235, '#fcc8c4'],
    [0.8980392156862745, '#fdc9c6'],
    [0.9019607843137255, '#fdcbc7'],
    [0.9058823529411765, '#fdccc8'],
    [0.9098039215686274, '#fdcdca'],
    [0.9137254901960784, '#fdcecb'],
    [0.9176470588235294, '#fecfcc'],
    [0.9215686274509803, '#fed0ce'],
    [0.9254901960784314, '#fed1cf'],
    [0.9294117647058824, '#fed2d0'],
    [0.9333333333333333, '#fed3d1'],
    [0.9372549019607843, '#fed4d3'],
    [0.9411764705882353, '#fed6d4'],
    [0.9450980392156862, '#ffd7d5'],
    [0.9490196078431372, '#ffd8d6'],
    [0.9529411764705882, '#ffd9d7'],
    [0.9568627450980391, '#ffdad9'],
    [0.9607843137254902, '#ffdbda'],
    [0.9647058823529412, '#ffdcdb'],
    [0.9686274509803922, '#ffdddc'],
    [0.9725490196078431, '#ffdedd'],
    [0.9764705882352941, '#ffdfdf'],
    [0.9803921568627451, '#ffe0e0'],
    [0.984313725490196, '#ffe2e1'],
    [0.9882352941176471, '#ffe3e2'],
    [0.9921568627450981, '#ffe4e3'],
    [0.996078431372549, '#ffe5e5'],
    [1.0, '#ffe6e6'],
  ],
  vikO: [
    [0.0, '#4f1a3d'],
    [0.00392156862745098, '#4e1a3e'],
    [0.00784313725490196, '#4e1b3f'],
    [0.011764705882352941, '#4d1b41'],
    [0.01568627450980392, '#4c1c42'],
    [0.0196078431372549, '#4b1d43'],
    [0.023529411764705882, '#4b1d44'],
    [0.027450980392156862, '#4a1e46'],
    [0.03137254901960784, '#491f47'],
    [0.03529411764705882, '#482049'],
    [0.0392156862745098, '#48214a'],
    [0.043137254901960784, '#47214b'],
    [0.047058823529411764, '#46224d'],
    [0.050980392156862744, '#45234e'],
    [0.054901960784313725, '#452450'],
    [0.058823529411764705, '#442551'],
    [0.06274509803921569, '#432653'],
    [0.06666666666666667, '#432754'],
    [0.07058823529411765, '#422856'],
    [0.07450980392156863, '#412958'],
    [0.0784313725490196, '#402a59'],
    [0.08235294117647059, '#3f2c5b'],
    [0.08627450980392157, '#3f2d5d'],
    [0.09019607843137255, '#3e2e5e'],
    [0.09411764705882353, '#3d2f60'],
    [0.09803921568627451, '#3d3162'],
    [0.10196078431372549, '#3c3263'],
    [0.10588235294117647, '#3b3365'],
    [0.10980392156862745, '#3a3567'],
    [0.11372549019607843, '#3a3668'],
    [0.11764705882352941, '#39386a'],
    [0.12156862745098039, '#38396c'],
    [0.12549019607843137, '#383b6e'],
    [0.12941176470588234, '#373c6f'],
    [0.13333333333333333, '#363e71'],
    [0.13725490196078433, '#363f73'],
    [0.1411764705882353, '#354174'],
    [0.14509803921568626, '#354376'],
    [0.14901960784313725, '#344478'],
    [0.15294117647058825, '#34467a'],
    [0.1568627450980392, '#34487b'],
    [0.16078431372549018, '#34497d'],
    [0.16470588235294117, '#334b7f'],
    [0.16862745098039217, '#334d80'],
    [0.17254901960784313, '#334f82'],
    [0.1764705882352941, '#335184'],
    [0.1803921568627451, '#335285'],
    [0.1843137254901961, '#345487'],
    [0.18823529411764706, '#345689'],
    [0.19215686274509802, '#34588a'],
    [0.19607843137254902, '#355a8c'],
    [0.2, '#355c8d'],
    [0.20392156862745098, '#365e8f'],
    [0.20784313725490194, '#375f90'],
    [0.21176470588235294, '#386192'],
    [0.21568627450980393, '#396394'],
    [0.2196078431372549, '#3a6595'],
    [0.22352941176470587, '#3b6797'],
    [0.22745098039215686, '#3c6998'],
    [0.23137254901960785, '#3d6b9a'],
    [0.23529411764705882, '#3f6d9b'],
    [0.2392156862745098, '#406f9c'],
    [0.24313725490196078, '#42719e'],
    [0.24705882352941178, '#43739f'],
    [0.25098039215686274, '#4575a1'],
    [0.2549019607843137, '#4777a2'],
    [0.2588235294117647, '#4979a4'],
    [0.2627450980392157, '#4b7ba5'],
    [0.26666666666666666, '#4d7da6'],
    [0.27058823529411763, '#4f7fa8'],
    [0.27450980392156865, '#5181a9'],
    [0.2784313725490196, '#5383ab'],
    [0.2823529411764706, '#5585ac'],
    [0.28627450980392155, '#5787ad'],
    [0.2901960784313725, '#5a89af'],
    [0.29411764705882354, '#5c8bb0'],
    [0.2980392156862745, '#5e8db1'],
    [0.30196078431372547, '#618fb2'],
    [0.3058823529411765, '#6391b4'],
    [0.30980392156862746, '#6693b5'],
    [0.3137254901960784, '#6895b6'],
    [0.3176470588235294, '#6b97b7'],
    [0.32156862745098036, '#6d98b8'],
    [0.3254901960784314, '#709aba'],
    [0.32941176470588235, '#729cbb'],
    [0.3333333333333333, '#759ebc'],
    [0.33725490196078434, '#78a0bd'],
    [0.3411764705882353, '#7aa2be'],
    [0.34509803921568627, '#7da4bf'],
    [0.34901960784313724, '#80a5c0'],
    [0.3529411764705882, '#83a7c1'],
    [0.3568627450980392, '#85a9c2'],
    [0.3607843137254902, '#88aac2'],
    [0.36470588235294116, '#8bacc3'],
    [0.3686274509803922, '#8eaec4'],
    [0.37254901960784315, '#90afc5'],
    [0.3764705882352941, '#93b1c5'],
    [0.3803921568627451, '#96b2c6'],
    [0.38431372549019605, '#99b4c6'],
    [0.38823529411764707, '#9bb5c7'],
    [0.39215686274509803, '#9eb6c7'],
    [0.396078431372549, '#a1b8c8'],
    [0.4, '#a4b9c8'],
    [0.403921568627451, '#a6bac8'],
    [0.40784313725490196, '#a9bbc8'],
    [0.4117647058823529, '#abbcc8'],
    [0.4156862745098039, '#aebdc8'],
    [0.4196078431372549, '#b0bec8'],
    [0.4235294117647059, '#b3bfc8'],
    [0.42745098039215684, '#b5c0c8'],
    [0.43137254901960786, '#b8c0c7'],
    [0.43529411764705883, '#bac1c7'],
    [0.4392156862745098, '#bcc1c6'],
    [0.44313725490196076, '#bec2c6'],
    [0.44705882352941173, '#c0c2c5'],
    [0.45098039215686275, '#c2c2c4'],
    [0.4549019607843137, '#c4c2c3'],
    [0.4588235294117647, '#c6c3c2'],
    [0.4627450980392157, '#c8c3c1'],
    [0.4666666666666667, '#c9c2c0'],
    [0.47058823529411764, '#cbc2bf'],
    [0.4745098039215686, '#cdc2be'],
    [0.4784313725490196, '#cec2bc'],
    [0.4823529411764706, '#cfc1bb'],
    [0.48627450980392156, '#d1c1b9'],
    [0.49019607843137253, '#d2c0b8'],
    [0.49411764705882355, '#d3c0b6'],
    [0.4980392156862745, '#d4bfb5'],
    [0.5019607843137255, '#d5beb3'],
    [0.5058823529411764, '#d5bdb1'],
    [0.5098039215686274, '#d6bdaf'],
    [0.5137254901960784, '#d7bcad'],
    [0.5176470588235293, '#d7bbac'],
    [0.5215686274509804, '#d8baaa'],
    [0.5254901960784314, '#d8b8a8'],
    [0.5294117647058824, '#d9b7a6'],
    [0.5333333333333333, '#d9b6a4'],
    [0.5372549019607843, '#d9b5a1'],
    [0.5411764705882353, '#d9b49f'],
    [0.5450980392156862, '#d9b29d'],
    [0.5490196078431373, '#d9b19b'],
    [0.5529411764705883, '#d9af99'],
    [0.5568627450980392, '#d9ae97'],
    [0.5607843137254902, '#d9ac94'],
    [0.5647058823529412, '#d9ab92'],
    [0.5686274509803921, '#d9a990'],
    [0.5725490196078431, '#d8a88d'],
    [0.5764705882352941, '#d8a68b'],
    [0.580392156862745, '#d8a589'],
    [0.5843137254901961, '#d7a387'],
    [0.5882352941176471, '#d7a184'],
    [0.592156862745098, '#d6a082'],
    [0.596078431372549, '#d59e80'],
    [0.6, '#d59c7d'],
    [0.6039215686274509, '#d49a7b'],
    [0.6078431372549019, '#d49879'],
    [0.611764705882353, '#d39776'],
    [0.615686274509804, '#d29574'],
    [0.6196078431372549, '#d19372'],
    [0.6235294117647059, '#d0916f'],
    [0.6274509803921569, '#d08f6d'],
    [0.6313725490196078, '#cf8d6b'],
    [0.6352941176470588, '#ce8b68'],
    [0.6392156862745098, '#cd8a66'],
    [0.6431372549019607, '#cc8864'],
    [0.6470588235294118, '#cb8661'],
    [0.6509803921568628, '#ca845f'],
    [0.6549019607843137, '#c8825d'],
    [0.6588235294117647, '#c7805b'],
    [0.6627450980392157, '#c67e58'],
    [0.6666666666666666, '#c57c56'],
    [0.6705882352941176, '#c47954'],
    [0.6745098039215687, '#c27752'],
    [0.6784313725490196, '#c1754f'],
    [0.6823529411764706, '#c0734d'],
    [0.6862745098039216, '#be714b'],
    [0.6901960784313725, '#bd6f49'],
    [0.6941176470588235, '#bb6d47'],
    [0.6980392156862745, '#ba6a45'],
    [0.7019607843137254, '#b86843'],
    [0.7058823529411764, '#b76641'],
    [0.7098039215686275, '#b5643f'],
    [0.7137254901960784, '#b3623d'],
    [0.7176470588235294, '#b25f3b'],
    [0.7215686274509804, '#b05d39'],
    [0.7254901960784313, '#ae5b37'],
    [0.7294117647058823, '#ac5935'],
    [0.7333333333333333, '#aa5633'],
    [0.7372549019607844, '#a95432'],
    [0.7411764705882353, '#a75230'],
    [0.7450980392156863, '#a5502f'],
    [0.7490196078431373, '#a34d2d'],
    [0.7529411764705882, '#a14b2c'],
    [0.7568627450980392, '#9f492a'],
    [0.7607843137254902, '#9d4729'],
    [0.7647058823529411, '#9b4528'],
    [0.7686274509803921, '#994327'],
    [0.7725490196078432, '#974126'],
    [0.7764705882352941, '#953e25'],
    [0.7803921568627451, '#933c24'],
    [0.7843137254901961, '#913b23'],
    [0.788235294117647, '#8f3922'],
    [0.792156862745098, '#8d3721'],
    [0.796078431372549, '#8c3521'],
    [0.8, '#8a3320'],
    [0.803921568627451, '#883120'],
    [0.807843137254902, '#86301f'],
    [0.8117647058823529, '#842e1f'],
    [0.8156862745098039, '#822c1f'],
    [0.8196078431372549, '#812b1e'],
    [0.8235294117647058, '#7f291e'],
    [0.8274509803921568, '#7d281e'],
    [0.8313725490196078, '#7c271e'],
    [0.8352941176470589, '#7a251e'],
    [0.8392156862745098, '#79241e'],
    [0.8431372549019608, '#77231e'],
    [0.8470588235294118, '#76221f'],
    [0.8509803921568627, '#74211f'],
    [0.8549019607843137, '#73201f'],
    [0.8588235294117647, '#721f1f'],
    [0.8627450980392157, '#701e20'],
    [0.8666666666666667, '#6f1d20'],
    [0.8705882352941177, '#6e1c20'],
    [0.8745098039215686, '#6c1b21'],
    [0.8784313725490196, '#6b1a21'],
    [0.8823529411764706, '#6a1a22'],
    [0.8862745098039215, '#691922'],
    [0.8901960784313725, '#681923'],
    [0.8941176470588235, '#671823'],
    [0.8980392156862745, '#661824'],
    [0.9019607843137255, '#651725'],
    [0.9058823529411765, '#641725'],
    [0.9098039215686274, '#631626'],
    [0.9137254901960784, '#621627'],
    [0.9176470588235294, '#611627'],
    [0.9215686274509803, '#601628'],
    [0.9254901960784314, '#5f1629'],
    [0.9294117647058824, '#5e152a'],
    [0.9333333333333333, '#5d152b'],
    [0.9372549019607843, '#5c152b'],
    [0.9411764705882353, '#5b152c'],
    [0.9450980392156862, '#5a152d'],
    [0.9490196078431372, '#5a152e'],
    [0.9529411764705882, '#59152f'],
    [0.9568627450980391, '#581630'],
    [0.9607843137254902, '#571631'],
    [0.9647058823529412, '#561632'],
    [0.9686274509803922, '#561633'],
    [0.9725490196078431, '#551634'],
    [0.9764705882352941, '#541735'],
    [0.9803921568627451, '#531736'],
    [0.984313725490196, '#531737'],
    [0.9882352941176471, '#521838'],
    [0.9921568627450981, '#511839'],
    [0.996078431372549, '#50193a'],
    [1.0, '#50193c'],
  ],
  broc: [
    [0.0, '#2c1a4c'],
    [0.00392156862745098, '#2c1b4e'],
    [0.00784313725490196, '#2c1d4f'],
    [0.011764705882352941, '#2b1e51'],
    [0.01568627450980392, '#2b2052'],
    [0.0196078431372549, '#2b2154'],
    [0.023529411764705882, '#2b2355'],
    [0.027450980392156862, '#2b2457'],
    [0.03137254901960784, '#2b2658'],
    [0.03529411764705882, '#2b275a'],
    [0.0392156862745098, '#2b295b'],
    [0.043137254901960784, '#2b2a5d'],
    [0.047058823529411764, '#2a2c5e'],
    [0.050980392156862744, '#2a2d60'],
    [0.054901960784313725, '#2a2e61'],
    [0.058823529411764705, '#2a3063'],
    [0.06274509803921569, '#2a3164'],
    [0.06666666666666667, '#2a3366'],
    [0.07058823529411765, '#293567'],
    [0.07450980392156863, '#293669'],
    [0.0784313725490196, '#29386a'],
    [0.08235294117647059, '#29396c'],
    [0.08627450980392157, '#293b6d'],
    [0.09019607843137255, '#293c6f'],
    [0.09411764705882353, '#293e71'],
    [0.09803921568627451, '#283f72'],
    [0.10196078431372549, '#284174'],
    [0.10588235294117647, '#284375'],
    [0.10980392156862745, '#284477'],
    [0.11372549019607843, '#284678'],
    [0.11764705882352941, '#28477a'],
    [0.12156862745098039, '#29497c'],
    [0.12549019607843137, '#294b7d'],
    [0.12941176470588234, '#294c7f'],
    [0.13333333333333333, '#294e80'],
    [0.13725490196078433, '#2a5082'],
    [0.1411764705882353, '#2a5183'],
    [0.14509803921568626, '#2b5385'],
    [0.14901960784313725, '#2c5586'],
    [0.15294117647058825, '#2d5788'],
    [0.1568627450980392, '#2e5889'],
    [0.16078431372549018, '#2f5a8b'],
    [0.16470588235294117, '#305c8c'],
    [0.16862745098039217, '#315e8e'],
    [0.17254901960784313, '#335f8f'],
    [0.1764705882352941, '#346191'],
    [0.1803921568627451, '#366392'],
    [0.1843137254901961, '#376593'],
    [0.18823529411764706, '#396695'],
    [0.19215686274509802, '#3b6896'],
    [0.19607843137254902, '#3d6a97'],
    [0.2, '#3f6b99'],
    [0.20392156862745098, '#416d9a'],
    [0.20784313725490194, '#436f9b'],
    [0.21176470588235294, '#45719c'],
    [0.21568627450980393, '#47729e'],
    [0.2196078431372549, '#49749f'],
    [0.22352941176470587, '#4b76a0'],
    [0.22745098039215686, '#4d78a1'],
    [0.23137254901960785, '#5079a3'],
    [0.23529411764705882, '#527ba4'],
    [0.2392156862745098, '#547da5'],
    [0.24313725490196078, '#567fa6'],
    [0.24705882352941178, '#5880a7'],
    [0.25098039215686274, '#5b82a9'],
    [0.2549019607843137, '#5d84aa'],
    [0.2588235294117647, '#5f85ab'],
    [0.2627450980392157, '#6187ac'],
    [0.26666666666666666, '#6489ad'],
    [0.27058823529411763, '#668baf'],
    [0.27450980392156865, '#688cb0'],
    [0.2784313725490196, '#6b8eb1'],
    [0.2823529411764706, '#6d90b2'],
    [0.28627450980392155, '#6f92b3'],
    [0.2901960784313725, '#7193b5'],
    [0.29411764705882354, '#7495b6'],
    [0.2980392156862745, '#7697b7'],
    [0.30196078431372547, '#7899b8'],
    [0.3058823529411765, '#7b9bba'],
    [0.30980392156862746, '#7d9cbb'],
    [0.3137254901960784, '#7f9ebc'],
    [0.3176470588235294, '#82a0bd'],
    [0.32156862745098036, '#84a2be'],
    [0.3254901960784314, '#86a3c0'],
    [0.32941176470588235, '#89a5c1'],
    [0.3333333333333333, '#8ba7c2'],
    [0.33725490196078434, '#8da9c3'],
    [0.3411764705882353, '#90aac5'],
    [0.34509803921568627, '#92acc6'],
    [0.34901960784313724, '#94aec7'],
    [0.3529411764705882, '#97b0c8'],
    [0.3568627450980392, '#99b2ca'],
    [0.3607843137254902, '#9bb3cb'],
    [0.36470588235294116, '#9eb5cc'],
    [0.3686274509803922, '#a0b7cd'],
    [0.37254901960784315, '#a2b9ce'],
    [0.3764705882352941, '#a5bbd0'],
    [0.3803921568627451, '#a7bcd1'],
    [0.38431372549019605, '#aabed2'],
    [0.38823529411764707, '#acc0d3'],
    [0.39215686274509803, '#aec2d5'],
    [0.396078431372549, '#b1c4d6'],
    [0.4, '#b3c5d7'],
    [0.403921568627451, '#b5c7d8'],
    [0.40784313725490196, '#b8c9da'],
    [0.4117647058823529, '#bacbdb'],
    [0.4156862745098039, '#bdcddc'],
    [0.4196078431372549, '#bfcfdd'],
    [0.4235294117647059, '#c2d0df'],
    [0.42745098039215684, '#c4d2e0'],
    [0.43137254901960786, '#c6d4e1'],
    [0.43529411764705883, '#c9d6e2'],
    [0.4392156862745098, '#cbd8e4'],
    [0.44313725490196076, '#ced9e5'],
    [0.44705882352941173, '#d0dbe6'],
    [0.45098039215686275, '#d2dde7'],
    [0.4549019607843137, '#d5dfe8'],
    [0.4588235294117647, '#d7e0e9'],
    [0.4627450980392157, '#d9e2ea'],
    [0.4666666666666667, '#dce4eb'],
    [0.47058823529411764, '#dee5ec'],
    [0.4745098039215686, '#e0e7ec'],
    [0.4784313725490196, '#e2e8ed'],
    [0.4823529411764706, '#e4eaed'],
    [0.48627450980392156, '#e6ebed'],
    [0.49019607843137253, '#e7eced'],
    [0.49411764705882355, '#e9eded'],
    [0.4980392156862745, '#eaeeec'],
    [0.5019607843137255, '#ebeeec'],
    [0.5058823529411764, '#ecefeb'],
    [0.5098039215686274, '#edefe9'],
    [0.5137254901960784, '#edefe8'],
    [0.5176470588235293, '#eeefe7'],
    [0.5215686274509804, '#eeefe5'],
    [0.5254901960784314, '#eeefe3'],
    [0.5294117647058824, '#edeee1'],
    [0.5333333333333333, '#edeedf'],
    [0.5372549019607843, '#eceddd'],
    [0.5411764705882353, '#ececdb'],
    [0.5450980392156862, '#ebebd9'],
    [0.5490196078431373, '#eaead6'],
    [0.5529411764705883, '#e9e9d4'],
    [0.5568627450980392, '#e8e8d2'],
    [0.5607843137254902, '#e7e7cf'],
    [0.5647058823529412, '#e6e6cd'],
    [0.5686274509803921, '#e5e5cb'],
    [0.5725490196078431, '#e4e4c8'],
    [0.5764705882352941, '#e3e2c6'],
    [0.580392156862745, '#e1e1c4'],
    [0.5843137254901961, '#e0e0c1'],
    [0.5882352941176471, '#dfdfbf'],
    [0.592156862745098, '#dedebd'],
    [0.596078431372549, '#ddddba'],
    [0.6, '#dcdbb8'],
    [0.6039215686274509, '#dadab5'],
    [0.6078431372549019, '#d9d9b3'],
    [0.611764705882353, '#d8d8b1'],
    [0.615686274509804, '#d7d7ae'],
    [0.6196078431372549, '#d5d5ac'],
    [0.6235294117647059, '#d4d4aa'],
    [0.6274509803921569, '#d3d3a7'],
    [0.6313725490196078, '#d2d2a5'],
    [0.6352941176470588, '#d0d0a2'],
    [0.6392156862745098, '#cfcfa0'],
    [0.6431372549019607, '#cece9e'],
    [0.6470588235294118, '#cccc9b'],
    [0.6509803921568628, '#cbcb99'],
    [0.6549019607843137, '#c9c996'],
    [0.6588235294117647, '#c8c894'],
    [0.6627450980392157, '#c6c691'],
    [0.6666666666666666, '#c5c58f'],
    [0.6705882352941176, '#c3c38c'],
    [0.6745098039215687, '#c1c18a'],
    [0.6784313725490196, '#c0bf87'],
    [0.6823529411764706, '#bebe85'],
    [0.6862745098039216, '#bcbc83'],
    [0.6901960784313725, '#baba80'],
    [0.6941176470588235, '#b8b87e'],
    [0.6980392156862745, '#b6b67c'],
    [0.7019607843137254, '#b4b479'],
    [0.7058823529411764, '#b2b277'],
    [0.7098039215686275, '#b0b075'],
    [0.7137254901960784, '#aeae73'],
    [0.7176470588235294, '#acac71'],
    [0.7215686274509804, '#aaaa6f'],
    [0.7254901960784313, '#a8a86d'],
    [0.7294117647058823, '#a6a66b'],
    [0.7333333333333333, '#a3a369'],
    [0.7372549019607844, '#a1a167'],
    [0.7411764705882353, '#9f9f65'],
    [0.7450980392156863, '#9d9d63'],
    [0.7490196078431373, '#9b9b62'],
    [0.7529411764705882, '#999960'],
    [0.7568627450980392, '#97975e'],
    [0.7607843137254902, '#95955c'],
    [0.7647058823529411, '#93935b'],
    [0.7686274509803921, '#919159'],
    [0.7725490196078432, '#8f8f57'],
    [0.7764705882352941, '#8d8d56'],
    [0.7803921568627451, '#8b8b54'],
    [0.7843137254901961, '#898952'],
    [0.788235294117647, '#878751'],
    [0.792156862745098, '#85854f'],
    [0.796078431372549, '#83834d'],
    [0.8, '#81814c'],
    [0.803921568627451, '#7f7f4a'],
    [0.807843137254902, '#7d7d48'],
    [0.8117647058823529, '#7b7b47'],
    [0.8156862745098039, '#797945'],
    [0.8196078431372549, '#777744'],
    [0.8235294117647058, '#757542'],
    [0.8274509803921568, '#747440'],
    [0.8313725490196078, '#72723f'],
    [0.8352941176470589, '#70703d'],
    [0.8392156862745098, '#6e6e3c'],
    [0.8431372549019608, '#6c6c3a'],
    [0.8470588235294118, '#6a6a38'],
    [0.8509803921568627, '#686837'],
    [0.8549019607843137, '#666635'],
    [0.8588235294117647, '#646434'],
    [0.8627450980392157, '#626232'],
    [0.8666666666666667, '#616031'],
    [0.8705882352941177, '#5f5f2f'],
    [0.8745098039215686, '#5d5d2d'],
    [0.8784313725490196, '#5b5b2c'],
    [0.8823529411764706, '#59592a'],
    [0.8862745098039215, '#575729'],
    [0.8901960784313725, '#555527'],
    [0.8941176470588235, '#545426'],
    [0.8980392156862745, '#525224'],
    [0.9019607843137255, '#505023'],
    [0.9058823529411765, '#4e4e21'],
    [0.9098039215686274, '#4c4c20'],
    [0.9137254901960784, '#4b4b1f'],
    [0.9176470588235294, '#49491d'],
    [0.9215686274509803, '#47471c'],
    [0.9254901960784314, '#45451a'],
    [0.9294117647058824, '#434319'],
    [0.9333333333333333, '#424218'],
    [0.9372549019607843, '#404016'],
    [0.9411764705882353, '#3e3e15'],
    [0.9450980392156862, '#3c3d14'],
    [0.9490196078431372, '#3b3b12'],
    [0.9529411764705882, '#393911'],
    [0.9568627450980391, '#373810'],
    [0.9607843137254902, '#36360f'],
    [0.9647058823529412, '#34340e'],
    [0.9686274509803922, '#32330c'],
    [0.9725490196078431, '#31310b'],
    [0.9764705882352941, '#2f300a'],
    [0.9803921568627451, '#2e2e08'],
    [0.984313725490196, '#2c2c07'],
    [0.9882352941176471, '#2b2b05'],
    [0.9921568627450981, '#292904'],
    [0.996078431372549, '#282802'],
    [1.0, '#262600'],
  ],
  batlowK: [
    [0.0, '#030405'],
    [0.00392156862745098, '#040608'],
    [0.00784313725490196, '#05080a'],
    [0.011764705882352941, '#060a0d'],
    [0.01568627450980392, '#070c0f'],
    [0.0196078431372549, '#080e11'],
    [0.023529411764705882, '#090f13'],
    [0.027450980392156862, '#0a1115'],
    [0.03137254901960784, '#0b1216'],
    [0.03529411764705882, '#0c1418'],
    [0.0392156862745098, '#0d1519'],
    [0.043137254901960784, '#0e161b'],
    [0.047058823529411764, '#0f181c'],
    [0.050980392156862744, '#0f191e'],
    [0.054901960784313725, '#101a20'],
    [0.058823529411764705, '#101b21'],
    [0.06274509803921569, '#111d23'],
    [0.06666666666666667, '#111e24'],
    [0.07058823529411765, '#121f26'],
    [0.07450980392156863, '#122028'],
    [0.0784313725490196, '#122229'],
    [0.08235294117647059, '#13232b'],
    [0.08627450980392157, '#13252d'],
    [0.09019607843137255, '#14262e'],
    [0.09411764705882353, '#152730'],
    [0.09803921568627451, '#152932'],
    [0.10196078431372549, '#162a33'],
    [0.10588235294117647, '#162c35'],
    [0.10980392156862745, '#172d37'],
    [0.11372549019607843, '#182f38'],
    [0.11764705882352941, '#18303a'],
    [0.12156862745098039, '#19323b'],
    [0.12549019607843137, '#1a333d'],
    [0.12941176470588234, '#1b353e'],
    [0.13333333333333333, '#1c3640'],
    [0.13725490196078433, '#1d3841'],
    [0.1411764705882353, '#1d3942'],
    [0.14509803921568626, '#1e3a44'],
    [0.14901960784313725, '#1f3c45'],
    [0.15294117647058825, '#203d46'],
    [0.1568627450980392, '#213f47'],
    [0.16078431372549018, '#224048'],
    [0.16470588235294117, '#234149'],
    [0.16862745098039217, '#25434a'],
    [0.17254901960784313, '#26444b'],
    [0.1764705882352941, '#27454c'],
    [0.1803921568627451, '#28464d'],
    [0.1843137254901961, '#29484d'],
    [0.18823529411764706, '#2a494e'],
    [0.19215686274509802, '#2b4a4f'],
    [0.19607843137254902, '#2c4b4f'],
    [0.2, '#2d4c50'],
    [0.20392156862745098, '#2f4d50'],
    [0.20784313725490194, '#304e51'],
    [0.21176470588235294, '#314f51'],
    [0.21568627450980393, '#325051'],
    [0.2196078431372549, '#335151'],
    [0.22352941176470587, '#345251'],
    [0.22745098039215686, '#355352'],
    [0.23137254901960785, '#365452'],
    [0.23529411764705882, '#375552'],
    [0.2392156862745098, '#395552'],
    [0.24313725490196078, '#3a5652'],
    [0.24705882352941178, '#3b5751'],
    [0.25098039215686274, '#3c5851'],
    [0.2549019607843137, '#3d5851'],
    [0.2588235294117647, '#3e5951'],
    [0.2627450980392157, '#3f5a51'],
    [0.26666666666666666, '#405b50'],
    [0.27058823529411763, '#415b50'],
    [0.27450980392156865, '#425c50'],
    [0.2784313725490196, '#435d50'],
    [0.2823529411764706, '#455d4f'],
    [0.28627450980392155, '#465e4f'],
    [0.2901960784313725, '#475e4e'],
    [0.29411764705882354, '#485f4e'],
    [0.2980392156862745, '#49604e'],
    [0.30196078431372547, '#4a604d'],
    [0.3058823529411765, '#4b614d'],
    [0.30980392156862746, '#4c614c'],
    [0.3137254901960784, '#4d624c'],
    [0.3176470588235294, '#4e634b'],
    [0.32156862745098036, '#50634b'],
    [0.3254901960784314, '#51644a'],
    [0.32941176470588235, '#52644a'],
    [0.3333333333333333, '#536549'],
    [0.33725490196078434, '#546548'],
    [0.3411764705882353, '#556648'],
    [0.34509803921568627, '#576747'],
    [0.34901960784313724, '#586747'],
    [0.3529411764705882, '#596846'],
    [0.3568627450980392, '#5a6846'],
    [0.3607843137254902, '#5b6945'],
    [0.36470588235294116, '#5d6a44'],
    [0.3686274509803922, '#5e6a44'],
    [0.37254901960784315, '#5f6b43'],
    [0.3764705882352941, '#606b43'],
    [0.3803921568627451, '#626c42'],
    [0.38431372549019605, '#636d41'],
    [0.38823529411764707, '#646d41'],
    [0.39215686274509803, '#666e40'],
    [0.396078431372549, '#676e40'],
    [0.4, '#686f3f'],
    [0.403921568627451, '#6a703e'],
    [0.40784313725490196, '#6b703e'],
    [0.4117647058823529, '#6c713d'],
    [0.4156862745098039, '#6e723d'],
    [0.4196078431372549, '#6f723c'],
    [0.4235294117647059, '#70733b'],
    [0.42745098039215684, '#72733b'],
    [0.43137254901960786, '#73743a'],
    [0.43529411764705883, '#75753a'],
    [0.4392156862745098, '#767539'],
    [0.44313725490196076, '#787639'],
    [0.44705882352941173, '#797738'],
    [0.45098039215686275, '#7b7737'],
    [0.4549019607843137, '#7c7837'],
    [0.4588235294117647, '#7e7936'],
    [0.4627450980392157, '#807936'],
    [0.4666666666666667, '#817a36'],
    [0.47058823529411764, '#837b35'],
    [0.4745098039215686, '#847b35'],
    [0.4784313725490196, '#867c34'],
    [0.4823529411764706, '#887d34'],
    [0.48627450980392156, '#897d34'],
    [0.49019607843137253, '#8b7e33'],
    [0.49411764705882355, '#8d7f33'],
    [0.4980392156862745, '#8e7f33'],
    [0.5019607843137255, '#908032'],
    [0.5058823529411764, '#928132'],
    [0.5098039215686274, '#948132'],
    [0.5137254901960784, '#968232'],
    [0.5176470588235293, '#978332'],
    [0.5215686274509804, '#998332'],
    [0.5254901960784314, '#9b8432'],
    [0.5294117647058824, '#9d8432'],
    [0.5333333333333333, '#9f8532'],
    [0.5372549019607843, '#a08632'],
    [0.5411764705882353, '#a28632'],
    [0.5450980392156862, '#a48733'],
    [0.5490196078431373, '#a68833'],
    [0.5529411764705883, '#a88833'],
    [0.5568627450980392, '#aa8934'],
    [0.5607843137254902, '#ac8934'],
    [0.5647058823529412, '#ae8a35'],
    [0.5686274509803921, '#af8a35'],
    [0.5725490196078431, '#b18b36'],
    [0.5764705882352941, '#b38b37'],
    [0.580392156862745, '#b58c37'],
    [0.5843137254901961, '#b78d38'],
    [0.5882352941176471, '#b98d39'],
    [0.592156862745098, '#bb8e3a'],
    [0.596078431372549, '#bc8e3b'],
    [0.6, '#be8f3c'],
    [0.6039215686274509, '#c08f3d'],
    [0.6078431372549019, '#c2903e'],
    [0.611764705882353, '#c4903f'],
    [0.615686274509804, '#c59040'],
    [0.6196078431372549, '#c79141'],
    [0.6235294117647059, '#c99142'],
    [0.6274509803921569, '#cb9244'],
    [0.6313725490196078, '#cc9245'],
    [0.6352941176470588, '#ce9346'],
    [0.6392156862745098, '#d09348'],
    [0.6431372549019607, '#d19449'],
    [0.6470588235294118, '#d3944b'],
    [0.6509803921568628, '#d5954c'],
    [0.6549019607843137, '#d6954e'],
    [0.6588235294117647, '#d8964f'],
    [0.6627450980392157, '#d99651'],
    [0.6666666666666666, '#db9653'],
    [0.6705882352941176, '#dc9754'],
    [0.6745098039215687, '#de9756'],
    [0.6784313725490196, '#df9858'],
    [0.6823529411764706, '#e1985a'],
    [0.6862745098039216, '#e2995b'],
    [0.6901960784313725, '#e3995d'],
    [0.6941176470588235, '#e49a5f'],
    [0.6980392156862745, '#e69a61'],
    [0.7019607843137254, '#e79b63'],
    [0.7058823529411764, '#e89b65'],
    [0.7098039215686275, '#e99c67'],
    [0.7137254901960784, '#ea9c69'],
    [0.7176470588235294, '#ec9d6a'],
    [0.7215686274509804, '#ed9e6c'],
    [0.7254901960784313, '#ee9e6e'],
    [0.7294117647058823, '#ef9f70'],
    [0.7333333333333333, '#f09f73'],
    [0.7372549019607844, '#f0a075'],
    [0.7411764705882353, '#f1a077'],
    [0.7450980392156863, '#f2a179'],
    [0.7490196078431373, '#f3a27b'],
    [0.7529411764705882, '#f4a27d'],
    [0.7568627450980392, '#f4a37f'],
    [0.7607843137254902, '#f5a381'],
    [0.7647058823529411, '#f6a483'],
    [0.7686274509803921, '#f6a585'],
    [0.7725490196078432, '#f7a587'],
    [0.7764705882352941, '#f7a689'],
    [0.7803921568627451, '#f8a78b'],
    [0.7843137254901961, '#f8a78d'],
    [0.788235294117647, '#f9a88f'],
    [0.792156862745098, '#f9a991'],
    [0.796078431372549, '#faa993'],
    [0.8, '#faaa95'],
    [0.803921568627451, '#faaa97'],
    [0.807843137254902, '#fbab99'],
    [0.8117647058823529, '#fbac9b'],
    [0.8156862745098039, '#fbac9d'],
    [0.8196078431372549, '#fcad9f'],
    [0.8235294117647058, '#fcaea1'],
    [0.8274509803921568, '#fcaea3'],
    [0.8313725490196078, '#fcafa5'],
    [0.8352941176470589, '#fcb0a7'],
    [0.8392156862745098, '#fcb0a9'],
    [0.8431372549019608, '#fdb1ab'],
    [0.8470588235294118, '#fdb2ad'],
    [0.8509803921568627, '#fdb2af'],
    [0.8549019607843137, '#fdb3b1'],
    [0.8588235294117647, '#fdb3b3'],
    [0.8627450980392157, '#fdb4b4'],
    [0.8666666666666667, '#fdb5b6'],
    [0.8705882352941177, '#fdb5b8'],
    [0.8745098039215686, '#fdb6ba'],
    [0.8784313725490196, '#fdb7bc'],
    [0.8823529411764706, '#fdb7be'],
    [0.8862745098039215, '#fdb8c0'],
    [0.8901960784313725, '#fdb9c2'],
    [0.8941176470588235, '#fdb9c4'],
    [0.8980392156862745, '#fdbac6'],
    [0.9019607843137255, '#fdbbc8'],
    [0.9058823529411765, '#fdbbca'],
    [0.9098039215686274, '#fdbccb'],
    [0.9137254901960784, '#fdbdcd'],
    [0.9176470588235294, '#fdbdcf'],
    [0.9215686274509803, '#fdbed1'],
    [0.9254901960784314, '#fdbfd3'],
    [0.9294117647058824, '#fdbfd5'],
    [0.9333333333333333, '#fdc0d7'],
    [0.9372549019607843, '#fdc1d9'],
    [0.9411764705882353, '#fcc1db'],
    [0.9450980392156862, '#fcc2dd'],
    [0.9490196078431372, '#fcc3df'],
    [0.9529411764705882, '#fcc4e1'],
    [0.9568627450980391, '#fcc4e3'],
    [0.9607843137254902, '#fcc5e5'],
    [0.9647058823529412, '#fcc6e8'],
    [0.9686274509803922, '#fcc6ea'],
    [0.9725490196078431, '#fcc7ec'],
    [0.9764705882352941, '#fbc8ee'],
    [0.9803921568627451, '#fbc9f0'],
    [0.984313725490196, '#fbc9f2'],
    [0.9882352941176471, '#fbcaf4'],
    [0.9921568627450981, '#fbcbf6'],
    [0.996078431372549, '#fbcbf8'],
    [1.0, '#faccfa'],
  ],
  bam: [
    [0.0, '#65024b'],
    [0.00392156862745098, '#67054e'],
    [0.00784313725490196, '#6a0750'],
    [0.011764705882352941, '#6c0a52'],
    [0.01568627450980392, '#6e0d54'],
    [0.0196078431372549, '#701057'],
    [0.023529411764705882, '#721259'],
    [0.027450980392156862, '#75145b'],
    [0.03137254901960784, '#77175d'],
    [0.03529411764705882, '#79195f'],
    [0.0392156862745098, '#7b1b61'],
    [0.043137254901960784, '#7d1d63'],
    [0.047058823529411764, '#7f1f66'],
    [0.050980392156862744, '#812168'],
    [0.054901960784313725, '#84236a'],
    [0.058823529411764705, '#86256c'],
    [0.06274509803921569, '#88276e'],
    [0.06666666666666667, '#8a2870'],
    [0.07058823529411765, '#8c2a72'],
    [0.07450980392156863, '#8e2c74'],
    [0.0784313725490196, '#902e76'],
    [0.08235294117647059, '#923078'],
    [0.08627450980392157, '#93317a'],
    [0.09019607843137255, '#95337c'],
    [0.09411764705882353, '#97357e'],
    [0.09803921568627451, '#99377f'],
    [0.10196078431372549, '#9b3881'],
    [0.10588235294117647, '#9d3a83'],
    [0.10980392156862745, '#9f3c85'],
    [0.11372549019607843, '#a03e87'],
    [0.11764705882352941, '#a23f88'],
    [0.12156862745098039, '#a4418a'],
    [0.12549019607843137, '#a5438c'],
    [0.12941176470588234, '#a7448e'],
    [0.13333333333333333, '#a9468f'],
    [0.13725490196078433, '#aa4891'],
    [0.1411764705882353, '#ac4993'],
    [0.14509803921568626, '#ad4b94'],
    [0.14901960784313725, '#af4d96'],
    [0.15294117647058825, '#b04e97'],
    [0.1568627450980392, '#b25099'],
    [0.16078431372549018, '#b3529a'],
    [0.16470588235294117, '#b5549c'],
    [0.16862745098039217, '#b6559d'],
    [0.17254901960784313, '#b8579f'],
    [0.1764705882352941, '#b959a0'],
    [0.1803921568627451, '#ba5aa2'],
    [0.1843137254901961, '#bc5ca3'],
    [0.18823529411764706, '#bd5ea5'],
    [0.19215686274509802, '#bf60a6'],
    [0.19607843137254902, '#c062a8'],
    [0.2, '#c164a9'],
    [0.20392156862745098, '#c266ab'],
    [0.20784313725490194, '#c468ac'],
    [0.21176470588235294, '#c56aae'],
    [0.21568627450980393, '#c66caf'],
    [0.2196078431372549, '#c86eb0'],
    [0.22352941176470587, '#c970b2'],
    [0.22745098039215686, '#ca72b3'],
    [0.23137254901960785, '#cb74b5'],
    [0.23529411764705882, '#cc76b6'],
    [0.2392156862745098, '#ce78b7'],
    [0.24313725490196078, '#cf7ab9'],
    [0.24705882352941178, '#d07cba'],
    [0.25098039215686274, '#d17fbc'],
    [0.2549019607843137, '#d281bd'],
    [0.2588235294117647, '#d383be'],
    [0.2627450980392157, '#d485c0'],
    [0.26666666666666666, '#d587c1'],
    [0.27058823529411763, '#d68ac2'],
    [0.27450980392156865, '#d78cc4'],
    [0.2784313725490196, '#d88ec5'],
    [0.2823529411764706, '#d991c6'],
    [0.28627450980392155, '#da93c8'],
    [0.2901960784313725, '#db95c9'],
    [0.29411764705882354, '#dc97ca'],
    [0.2980392156862745, '#dd9acb'],
    [0.30196078431372547, '#de9ccd'],
    [0.3058823529411765, '#df9ece'],
    [0.30980392156862746, '#dfa1cf'],
    [0.3137254901960784, '#e0a3d0'],
    [0.3176470588235294, '#e1a5d1'],
    [0.32156862745098036, '#e2a7d3'],
    [0.3254901960784314, '#e3aad4'],
    [0.32941176470588235, '#e4acd5'],
    [0.3333333333333333, '#e4aed6'],
    [0.33725490196078434, '#e5b0d7'],
    [0.3411764705882353, '#e6b3d8'],
    [0.34509803921568627, '#e7b5da'],
    [0.34901960784313724, '#e7b7db'],
    [0.3529411764705882, '#e8b9dc'],
    [0.3568627450980392, '#e9bbdd'],
    [0.3607843137254902, '#eabede'],
    [0.36470588235294116, '#eac0df'],
    [0.3686274509803922, '#ebc2e0'],
    [0.37254901960784315, '#ecc4e1'],
    [0.3764705882352941, '#ecc6e2'],
    [0.3803921568627451, '#edc8e3'],
    [0.38431372549019605, '#eecae4'],
    [0.38823529411764707, '#eecce5'],
    [0.39215686274509803, '#efcee6'],
    [0.396078431372549, '#efd0e7'],
    [0.4, '#f0d1e8'],
    [0.403921568627451, '#f1d3e9'],
    [0.40784313725490196, '#f1d5e9'],
    [0.4117647058823529, '#f2d7ea'],
    [0.4156862745098039, '#f2d8eb'],
    [0.4196078431372549, '#f3daec'],
    [0.4235294117647059, '#f3dcec'],
    [0.42745098039215684, '#f3dded'],
    [0.43137254901960786, '#f4dfee'],
    [0.43529411764705883, '#f4e0ee'],
    [0.4392156862745098, '#f5e2ef'],
    [0.44313725490196076, '#f5e3ef'],
    [0.44705882352941173, '#f5e4f0'],
    [0.45098039215686275, '#f5e6f0'],
    [0.4549019607843137, '#f6e7f0'],
    [0.4588235294117647, '#f6e8f1'],
    [0.4627450980392157, '#f6e9f1'],
    [0.4666666666666667, '#f6eaf1'],
    [0.47058823529411764, '#f6ebf1'],
    [0.4745098039215686, '#f6ecf1'],
    [0.4784313725490196, '#f6edf1'],
    [0.4823529411764706, '#f6eef1'],
    [0.48627450980392156, '#f6eff1'],
    [0.49019607843137253, '#f6eff1'],
    [0.49411764705882355, '#f6f0f1'],
    [0.4980392156862745, '#f6f1f1'],
    [0.5019607843137255, '#f6f1f0'],
    [0.5058823529411764, '#f6f2f0'],
    [0.5098039215686274, '#f5f2f0'],
    [0.5137254901960784, '#f5f2ef'],
    [0.5176470588235293, '#f5f3ef'],
    [0.5215686274509804, '#f4f3ee'],
    [0.5254901960784314, '#f4f3ed'],
    [0.5294117647058824, '#f3f3ed'],
    [0.5333333333333333, '#f3f3ec'],
    [0.5372549019607843, '#f2f3eb'],
    [0.5411764705882353, '#f2f3ea'],
    [0.5450980392156862, '#f1f3e9'],
    [0.5490196078431373, '#f0f3e8'],
    [0.5529411764705883, '#eff3e6'],
    [0.5568627450980392, '#eff3e5'],
    [0.5607843137254902, '#eef2e4'],
    [0.5647058823529412, '#edf2e2'],
    [0.5686274509803921, '#ecf2e0'],
    [0.5725490196078431, '#ebf1df'],
    [0.5764705882352941, '#eaf1dd'],
    [0.580392156862745, '#e8f0db'],
    [0.5843137254901961, '#e7f0d9'],
    [0.5882352941176471, '#e6efd7'],
    [0.592156862745098, '#e5efd5'],
    [0.596078431372549, '#e3eed3'],
    [0.6, '#e2edd1'],
    [0.6039215686274509, '#e0eccf'],
    [0.6078431372549019, '#dfeccc'],
    [0.611764705882353, '#ddebca'],
    [0.615686274509804, '#dceac7'],
    [0.6196078431372549, '#dae9c5'],
    [0.6235294117647059, '#d8e8c2'],
    [0.6274509803921569, '#d6e7bf'],
    [0.6313725490196078, '#d4e6bd'],
    [0.6352941176470588, '#d2e4ba'],
    [0.6392156862745098, '#d0e3b7'],
    [0.6431372549019607, '#cee2b4'],
    [0.6470588235294118, '#cce1b1'],
    [0.6509803921568628, '#cadfae'],
    [0.6549019607843137, '#c8deab'],
    [0.6588235294117647, '#c6dda8'],
    [0.6627450980392157, '#c3dba5'],
    [0.6666666666666666, '#c1daa2'],
    [0.6705882352941176, '#bfd89f'],
    [0.6745098039215687, '#bcd79b'],
    [0.6784313725490196, '#bad598'],
    [0.6823529411764706, '#b7d395'],
    [0.6862745098039216, '#b5d292'],
    [0.6901960784313725, '#b2d08f'],
    [0.6941176470588235, '#b0ce8c'],
    [0.6980392156862745, '#adcd89'],
    [0.7019607843137254, '#abcb86'],
    [0.7058823529411764, '#a8c983'],
    [0.7098039215686275, '#a6c780'],
    [0.7137254901960784, '#a3c67d'],
    [0.7176470588235294, '#a1c47a'],
    [0.7215686274509804, '#9ec277'],
    [0.7254901960784313, '#9cc074'],
    [0.7294117647058823, '#99be72'],
    [0.7333333333333333, '#97bc6f'],
    [0.7372549019607844, '#94bb6d'],
    [0.7411764705882353, '#92b96a'],
    [0.7450980392156863, '#8fb768'],
    [0.7490196078431373, '#8db565'],
    [0.7529411764705882, '#8ab363'],
    [0.7568627450980392, '#88b161'],
    [0.7607843137254902, '#86b05e'],
    [0.7647058823529411, '#83ae5c'],
    [0.7686274509803921, '#81ac5a'],
    [0.7725490196078432, '#7faa58'],
    [0.7764705882352941, '#7ca856'],
    [0.7803921568627451, '#7aa754'],
    [0.7843137254901961, '#78a552'],
    [0.788235294117647, '#76a350'],
    [0.792156862745098, '#74a24f'],
    [0.796078431372549, '#72a04d'],
    [0.8, '#709e4b'],
    [0.803921568627451, '#6d9c49'],
    [0.807843137254902, '#6b9b48'],
    [0.8117647058823529, '#699946'],
    [0.8156862745098039, '#679845'],
    [0.8196078431372549, '#659643'],
    [0.8235294117647058, '#649441'],
    [0.8274509803921568, '#629340'],
    [0.8313725490196078, '#60913e'],
    [0.8352941176470589, '#5e903d'],
    [0.8392156862745098, '#5c8e3c'],
    [0.8431372549019608, '#5a8d3a'],
    [0.8470588235294118, '#588b39'],
    [0.8509803921568627, '#578a37'],
    [0.8549019607843137, '#558836'],
    [0.8588235294117647, '#538735'],
    [0.8627450980392157, '#518533'],
    [0.8666666666666667, '#508432'],
    [0.8705882352941177, '#4e8231'],
    [0.8745098039215686, '#4c812f'],
    [0.8784313725490196, '#4b7f2e'],
    [0.8823529411764706, '#497e2d'],
    [0.8862745098039215, '#477c2b'],
    [0.8901960784313725, '#457b2a'],
    [0.8941176470588235, '#447929'],
    [0.8980392156862745, '#427827'],
    [0.9019607843137255, '#407626'],
    [0.9058823529411765, '#3e7525'],
    [0.9098039215686274, '#3d7323'],
    [0.9137254901960784, '#3b7122'],
    [0.9176470588235294, '#397021'],
    [0.9215686274509803, '#376e1f'],
    [0.9254901960784314, '#356d1e'],
    [0.9294117647058824, '#336b1c'],
    [0.9333333333333333, '#32691b'],
    [0.9372549019607843, '#306819'],
    [0.9411764705882353, '#2e6618'],
    [0.9450980392156862, '#2c6416'],
    [0.9490196078431372, '#2a6315'],
    [0.9529411764705882, '#286113'],
    [0.9568627450980391, '#265f12'],
    [0.9607843137254902, '#245d10'],
    [0.9647058823529412, '#225c0e'],
    [0.9686274509803922, '#205a0d'],
    [0.9725490196078431, '#1e580b'],
    [0.9764705882352941, '#1b5609'],
    [0.9803921568627451, '#195507'],
    [0.984313725490196, '#175306'],
    [0.9882352941176471, '#155104'],
    [0.9921568627450981, '#124f03'],
    [0.996078431372549, '#0f4d01'],
    [1.0, '#0d4c00'],
  ],
  tokyo: [
    [0.0, '#1a0e34'],
    [0.00392156862745098, '#1c0f34'],
    [0.00784313725490196, '#1d1035'],
    [0.011764705882352941, '#1f1036'],
    [0.01568627450980392, '#211137'],
    [0.0196078431372549, '#221138'],
    [0.023529411764705882, '#241239'],
    [0.027450980392156862, '#251239'],
    [0.03137254901960784, '#27133a'],
    [0.03529411764705882, '#28133b'],
    [0.0392156862745098, '#2a143c'],
    [0.043137254901960784, '#2b143d'],
    [0.047058823529411764, '#2d153e'],
    [0.050980392156862744, '#2e153f'],
    [0.054901960784313725, '#30163f'],
    [0.058823529411764705, '#311640'],
    [0.06274509803921569, '#331741'],
    [0.06666666666666667, '#341842'],
    [0.07058823529411765, '#361843'],
    [0.07450980392156863, '#371944'],
    [0.0784313725490196, '#391a45'],
    [0.08235294117647059, '#3a1a46'],
    [0.08627450980392157, '#3c1b47'],
    [0.09019607843137255, '#3d1c47'],
    [0.09411764705882353, '#3f1c48'],
    [0.09803921568627451, '#401d49'],
    [0.10196078431372549, '#421e4a'],
    [0.10588235294117647, '#431f4b'],
    [0.10980392156862745, '#45204c'],
    [0.11372549019607843, '#46204d'],
    [0.11764705882352941, '#48214e'],
    [0.12156862745098039, '#4a224f'],
    [0.12549019607843137, '#4b2350'],
    [0.12941176470588234, '#4d2451'],
    [0.13333333333333333, '#4e2552'],
    [0.13725490196078433, '#502653'],
    [0.1411764705882353, '#512754'],
    [0.14509803921568626, '#532855'],
    [0.14901960784313725, '#542956'],
    [0.15294117647058825, '#562a57'],
    [0.1568627450980392, '#572b58'],
    [0.16078431372549018, '#592c59'],
    [0.16470588235294117, '#5a2d59'],
    [0.16862745098039217, '#5c2e5a'],
    [0.17254901960784313, '#5d2f5b'],
    [0.1764705882352941, '#5f305c'],
    [0.1803921568627451, '#60315d'],
    [0.1843137254901961, '#61325e'],
    [0.18823529411764706, '#63335f'],
    [0.19215686274509802, '#643560'],
    [0.19607843137254902, '#663661'],
    [0.2, '#673762'],
    [0.20392156862745098, '#683863'],
    [0.20784313725490194, '#693964'],
    [0.21176470588235294, '#6b3a64'],
    [0.21568627450980393, '#6c3c65'],
    [0.2196078431372549, '#6d3d66'],
    [0.22352941176470587, '#6e3e67'],
    [0.22745098039215686, '#703f68'],
    [0.23137254901960785, '#714068'],
    [0.23529411764705882, '#724269'],
    [0.2392156862745098, '#73436a'],
    [0.24313725490196078, '#74446b'],
    [0.24705882352941178, '#75456c'],
    [0.25098039215686274, '#76466c'],
    [0.2549019607843137, '#77486d'],
    [0.2588235294117647, '#78496e'],
    [0.2627450980392157, '#794a6e'],
    [0.26666666666666666, '#7a4b6f'],
    [0.27058823529411763, '#7a4c70'],
    [0.27450980392156865, '#7b4e70'],
    [0.2784313725490196, '#7c4f71'],
    [0.2823529411764706, '#7d5072'],
    [0.28627450980392155, '#7e5172'],
    [0.2901960784313725, '#7e5273'],
    [0.29411764705882354, '#7f5373'],
    [0.2980392156862745, '#805474'],
    [0.30196078431372547, '#805574'],
    [0.3058823529411765, '#815775'],
    [0.30980392156862746, '#815875'],
    [0.3137254901960784, '#825976'],
    [0.3176470588235294, '#835a76'],
    [0.32156862745098036, '#835b77'],
    [0.3254901960784314, '#845c77'],
    [0.32941176470588235, '#845d78'],
    [0.3333333333333333, '#855e78'],
    [0.33725490196078434, '#855f79'],
    [0.3411764705882353, '#866079'],
    [0.34509803921568627, '#86617a'],
    [0.34901960784313724, '#86627a'],
    [0.3529411764705882, '#87637a'],
    [0.3568627450980392, '#87647b'],
    [0.3607843137254902, '#87657b'],
    [0.36470588235294116, '#88667b'],
    [0.3686274509803922, '#88677c'],
    [0.37254901960784315, '#89687c'],
    [0.3764705882352941, '#89697d'],
    [0.3803921568627451, '#896a7d'],
    [0.38431372549019605, '#896b7d'],
    [0.38823529411764707, '#8a6c7e'],
    [0.39215686274509803, '#8a6d7e'],
    [0.396078431372549, '#8a6e7e'],
    [0.4, '#8b6f7f'],
    [0.403921568627451, '#8b707f'],
    [0.40784313725490196, '#8b717f'],
    [0.4117647058823529, '#8b7280'],
    [0.4156862745098039, '#8c7380'],
    [0.4196078431372549, '#8c7480'],
    [0.4235294117647059, '#8c7580'],
    [0.42745098039215684, '#8c7681'],
    [0.43137254901960786, '#8c7781'],
    [0.43529411764705883, '#8d7781'],
    [0.4392156862745098, '#8d7882'],
    [0.44313725490196076, '#8d7982'],
    [0.44705882352941173, '#8d7a82'],
    [0.45098039215686275, '#8d7b82'],
    [0.4549019607843137, '#8e7c83'],
    [0.4588235294117647, '#8e7d83'],
    [0.4627450980392157, '#8e7e83'],
    [0.4666666666666667, '#8e7f84'],
    [0.47058823529411764, '#8e8084'],
    [0.4745098039215686, '#8e8184'],
    [0.4784313725490196, '#8f8284'],
    [0.4823529411764706, '#8f8385'],
    [0.48627450980392156, '#8f8385'],
    [0.49019607843137253, '#8f8485'],
    [0.49411764705882355, '#8f8585'],
    [0.4980392156862745, '#8f8686'],
    [0.5019607843137255, '#908786'],
    [0.5058823529411764, '#908886'],
    [0.5098039215686274, '#908986'],
    [0.5137254901960784, '#908a87'],
    [0.5176470588235293, '#908b87'],
    [0.5215686274509804, '#908c87'],
    [0.5254901960784314, '#918d87'],
    [0.5294117647058824, '#918d88'],
    [0.5333333333333333, '#918e88'],
    [0.5372549019607843, '#918f88'],
    [0.5411764705882353, '#919088'],
    [0.5450980392156862, '#919189'],
    [0.5490196078431373, '#919289'],
    [0.5529411764705883, '#929389'],
    [0.5568627450980392, '#929489'],
    [0.5607843137254902, '#92958a'],
    [0.5647058823529412, '#92968a'],
    [0.5686274509803921, '#92978a'],
    [0.5725490196078431, '#92988a'],
    [0.5764705882352941, '#93988b'],
    [0.580392156862745, '#93998b'],
    [0.5843137254901961, '#939a8b'],
    [0.5882352941176471, '#939b8b'],
    [0.592156862745098, '#939c8c'],
    [0.596078431372549, '#939d8c'],
    [0.6, '#949e8c'],
    [0.6039215686274509, '#949f8c'],
    [0.6078431372549019, '#94a08d'],
    [0.611764705882353, '#94a18d'],
    [0.615686274509804, '#94a28d'],
    [0.6196078431372549, '#94a38d'],
    [0.6235294117647059, '#95a48e'],
    [0.6274509803921569, '#95a58e'],
    [0.6313725490196078, '#95a58e'],
    [0.6352941176470588, '#95a68f'],
    [0.6392156862745098, '#95a78f'],
    [0.6431372549019607, '#96a88f'],
    [0.6470588235294118, '#96a98f'],
    [0.6509803921568628, '#96aa90'],
    [0.6549019607843137, '#96ab90'],
    [0.6588235294117647, '#97ac90'],
    [0.6627450980392157, '#97ad90'],
    [0.6666666666666666, '#97ae91'],
    [0.6705882352941176, '#97af91'],
    [0.6745098039215687, '#98b091'],
    [0.6784313725490196, '#98b192'],
    [0.6823529411764706, '#98b292'],
    [0.6862745098039216, '#99b392'],
    [0.6901960784313725, '#99b493'],
    [0.6941176470588235, '#99b593'],
    [0.6980392156862745, '#9ab693'],
    [0.7019607843137254, '#9ab794'],
    [0.7058823529411764, '#9ab894'],
    [0.7098039215686275, '#9bb994'],
    [0.7137254901960784, '#9bbb95'],
    [0.7176470588235294, '#9cbc95'],
    [0.7215686274509804, '#9cbd95'],
    [0.7254901960784313, '#9dbe96'],
    [0.7294117647058823, '#9dbf96'],
    [0.7333333333333333, '#9ec097'],
    [0.7372549019607844, '#9fc197'],
    [0.7411764705882353, '#9fc298'],
    [0.7450980392156863, '#a0c498'],
    [0.7490196078431373, '#a1c599'],
    [0.7529411764705882, '#a1c699'],
    [0.7568627450980392, '#a2c79a'],
    [0.7607843137254902, '#a3c99b'],
    [0.7647058823529411, '#a4ca9b'],
    [0.7686274509803921, '#a5cb9c'],
    [0.7725490196078432, '#a6cc9d'],
    [0.7764705882352941, '#a7ce9d'],
    [0.7803921568627451, '#a8cf9e'],
    [0.7843137254901961, '#a9d09f'],
    [0.788235294117647, '#aad2a0'],
    [0.792156862745098, '#acd3a0'],
    [0.796078431372549, '#add4a1'],
    [0.8, '#aed6a2'],
    [0.803921568627451, '#b0d7a3'],
    [0.807843137254902, '#b1d8a4'],
    [0.8117647058823529, '#b2daa5'],
    [0.8156862745098039, '#b4dba6'],
    [0.8196078431372549, '#b6dda7'],
    [0.8235294117647058, '#b7dea8'],
    [0.8274509803921568, '#b9dfa9'],
    [0.8313725490196078, '#bae1aa'],
    [0.8352941176470589, '#bce2ab'],
    [0.8392156862745098, '#bee3ac'],
    [0.8431372549019608, '#c0e5ae'],
    [0.8470588235294118, '#c1e6af'],
    [0.8509803921568627, '#c3e7b0'],
    [0.8549019607843137, '#c5e8b1'],
    [0.8588235294117647, '#c7eab2'],
    [0.8627450980392157, '#c9ebb4'],
    [0.8666666666666667, '#caecb5'],
    [0.8705882352941177, '#ccedb6'],
    [0.8745098039215686, '#ceeeb7'],
    [0.8784313725490196, '#d0efb8'],
    [0.8823529411764706, '#d2f0ba'],
    [0.8862745098039215, '#d4f1bb'],
    [0.8901960784313725, '#d5f2bc'],
    [0.8941176470588235, '#d7f3bd'],
    [0.8980392156862745, '#d9f3be'],
    [0.9019607843137255, '#daf4bf'],
    [0.9058823529411765, '#dcf5c1'],
    [0.9098039215686274, '#def6c2'],
    [0.9137254901960784, '#e0f6c3'],
    [0.9176470588235294, '#e1f7c4'],
    [0.9215686274509803, '#e3f8c5'],
    [0.9254901960784314, '#e4f8c6'],
    [0.9294117647058824, '#e6f9c7'],
    [0.9333333333333333, '#e7f9c8'],
    [0.9372549019607843, '#e9fac9'],
    [0.9411764705882353, '#eafaca'],
    [0.9450980392156862, '#ecfacb'],
    [0.9490196078431372, '#edfbcc'],
    [0.9529411764705882, '#effbcd'],
    [0.9568627450980391, '#f0fcce'],
    [0.9607843137254902, '#f1fccf'],
    [0.9647058823529412, '#f3fcd0'],
    [0.9686274509803922, '#f4fdd1'],
    [0.9725490196078431, '#f5fdd2'],
    [0.9764705882352941, '#f7fdd3'],
    [0.9803921568627451, '#f8fdd4'],
    [0.984313725490196, '#f9fed5'],
    [0.9882352941176471, '#fbfed6'],
    [0.9921568627450981, '#fcfed7'],
    [0.996078431372549, '#fdfed8'],
    [1.0, '#fefed8'],
  ],
  roma: [
    [0.0, '#7e1700'],
    [0.00392156862745098, '#7f1a01'],
    [0.00784313725490196, '#801d02'],
    [0.011764705882352941, '#812003'],
    [0.01568627450980392, '#822204'],
    [0.0196078431372549, '#832504'],
    [0.023529411764705882, '#842705'],
    [0.027450980392156862, '#852a06'],
    [0.03137254901960784, '#862c06'],
    [0.03529411764705882, '#872e07'],
    [0.0392156862745098, '#883008'],
    [0.043137254901960784, '#8a3208'],
    [0.047058823529411764, '#8b3409'],
    [0.050980392156862744, '#8c360a'],
    [0.054901960784313725, '#8d380b'],
    [0.058823529411764705, '#8e3a0b'],
    [0.06274509803921569, '#8f3c0c'],
    [0.06666666666666667, '#903e0d'],
    [0.07058823529411765, '#90400e'],
    [0.07450980392156863, '#91420f'],
    [0.0784313725490196, '#92440f'],
    [0.08235294117647059, '#934610'],
    [0.08627450980392157, '#944711'],
    [0.09019607843137255, '#954912'],
    [0.09411764705882353, '#964b12'],
    [0.09803921568627451, '#974d13'],
    [0.10196078431372549, '#984e14'],
    [0.10588235294117647, '#995014'],
    [0.10980392156862745, '#995215'],
    [0.11372549019607843, '#9a5316'],
    [0.11764705882352941, '#9b5517'],
    [0.12156862745098039, '#9c5717'],
    [0.12549019607843137, '#9d5818'],
    [0.12941176470588234, '#9e5a19'],
    [0.13333333333333333, '#9e5c19'],
    [0.13725490196078433, '#9f5d1a'],
    [0.1411764705882353, '#a05f1b'],
    [0.14509803921568626, '#a1611c'],
    [0.14901960784313725, '#a2621c'],
    [0.15294117647058825, '#a2641d'],
    [0.1568627450980392, '#a3651e'],
    [0.16078431372549018, '#a4671e'],
    [0.16470588235294117, '#a5681f'],
    [0.16862745098039217, '#a66a20'],
    [0.17254901960784313, '#a66c20'],
    [0.1764705882352941, '#a76d21'],
    [0.1803921568627451, '#a86f22'],
    [0.1843137254901961, '#a97023'],
    [0.18823529411764706, '#a97223'],
    [0.19215686274509802, '#aa7324'],
    [0.19607843137254902, '#ab7525'],
    [0.2, '#ac7726'],
    [0.20392156862745098, '#ad7826'],
    [0.20784313725490194, '#ad7a27'],
    [0.21176470588235294, '#ae7c28'],
    [0.21568627450980393, '#af7d29'],
    [0.2196078431372549, '#b07f2a'],
    [0.22352941176470587, '#b0802b'],
    [0.22745098039215686, '#b1822c'],
    [0.23137254901960785, '#b2842d'],
    [0.23529411764705882, '#b3862e'],
    [0.2392156862745098, '#b4872f'],
    [0.24313725490196078, '#b58930'],
    [0.24705882352941178, '#b58b31'],
    [0.25098039215686274, '#b68c32'],
    [0.2549019607843137, '#b78e33'],
    [0.2588235294117647, '#b89034'],
    [0.2627450980392157, '#b99235'],
    [0.26666666666666666, '#ba9437'],
    [0.27058823529411763, '#ba9538'],
    [0.27450980392156865, '#bb9739'],
    [0.2784313725490196, '#bc993b'],
    [0.2823529411764706, '#bd9b3c'],
    [0.28627450980392155, '#be9d3e'],
    [0.2901960784313725, '#bf9f40'],
    [0.29411764705882354, '#c0a141'],
    [0.2980392156862745, '#c1a343'],
    [0.30196078431372547, '#c1a545'],
    [0.3058823529411765, '#c2a647'],
    [0.30980392156862746, '#c3a848'],
    [0.3137254901960784, '#c4aa4a'],
    [0.3176470588235294, '#c5ac4c'],
    [0.32156862745098036, '#c6ae4f'],
    [0.3254901960784314, '#c7b051'],
    [0.32941176470588235, '#c7b253'],
    [0.3333333333333333, '#c8b455'],
    [0.33725490196078434, '#c9b658'],
    [0.3411764705882353, '#cab85a'],
    [0.34509803921568627, '#cbba5d'],
    [0.34901960784313724, '#cbbc5f'],
    [0.3529411764705882, '#ccbe62'],
    [0.3568627450980392, '#cdc064'],
    [0.3607843137254902, '#cec267'],
    [0.36470588235294116, '#cec46a'],
    [0.3686274509803922, '#cfc66d'],
    [0.37254901960784315, '#cfc86f'],
    [0.3764705882352941, '#d0ca72'],
    [0.3803921568627451, '#d0cc75'],
    [0.38431372549019605, '#d1cd78'],
    [0.38823529411764707, '#d1cf7b'],
    [0.39215686274509803, '#d2d17e'],
    [0.396078431372549, '#d2d381'],
    [0.4, '#d2d484'],
    [0.403921568627451, '#d2d687'],
    [0.40784313725490196, '#d2d78a'],
    [0.4117647058823529, '#d2d98d'],
    [0.4156862745098039, '#d2da90'],
    [0.4196078431372549, '#d2dc93'],
    [0.4235294117647059, '#d2dd96'],
    [0.42745098039215684, '#d2de98'],
    [0.43137254901960786, '#d1df9b'],
    [0.43529411764705883, '#d1e19e'],
    [0.4392156862745098, '#d1e2a1'],
    [0.44313725490196076, '#d0e3a3'],
    [0.44705882352941173, '#d0e4a6'],
    [0.45098039215686275, '#cfe5a8'],
    [0.4549019607843137, '#cee5ab'],
    [0.4588235294117647, '#cde6ad'],
    [0.4627450980392157, '#cce7b0'],
    [0.4666666666666667, '#cce7b2'],
    [0.47058823529411764, '#cbe8b4'],
    [0.4745098039215686, '#c9e9b6'],
    [0.4784313725490196, '#c8e9b8'],
    [0.4823529411764706, '#c7e9ba'],
    [0.48627450980392156, '#c6eabc'],
    [0.49019607843137253, '#c4eabe'],
    [0.49411764705882355, '#c3eac0'],
    [0.4980392156862745, '#c1eac2'],
    [0.5019607843137255, '#c0eac3'],
    [0.5058823529411764, '#beeac5'],
    [0.5098039215686274, '#bdeac6'],
    [0.5137254901960784, '#bbeac8'],
    [0.5176470588235293, '#b9eac9'],
    [0.5215686274509804, '#b7eaca'],
    [0.5254901960784314, '#b5eacc'],
    [0.5294117647058824, '#b3e9cd'],
    [0.5333333333333333, '#b1e9ce'],
    [0.5372549019607843, '#afe8cf'],
    [0.5411764705882353, '#ade8d0'],
    [0.5450980392156862, '#abe7d1'],
    [0.5490196078431373, '#a9e7d2'],
    [0.5529411764705883, '#a6e6d2'],
    [0.5568627450980392, '#a4e5d3'],
    [0.5607843137254902, '#a2e5d4'],
    [0.5647058823529412, '#9fe4d4'],
    [0.5686274509803921, '#9de3d5'],
    [0.5725490196078431, '#9be2d5'],
    [0.5764705882352941, '#98e1d6'],
    [0.580392156862745, '#95e0d6'],
    [0.5843137254901961, '#93dfd6'],
    [0.5882352941176471, '#90ded7'],
    [0.592156862745098, '#8eddd7'],
    [0.596078431372549, '#8bdcd7'],
    [0.6, '#89dad7'],
    [0.6039215686274509, '#86d9d7'],
    [0.6078431372549019, '#83d8d7'],
    [0.611764705882353, '#81d7d7'],
    [0.615686274509804, '#7ed5d7'],
    [0.6196078431372549, '#7bd4d7'],
    [0.6235294117647059, '#79d2d7'],
    [0.6274509803921569, '#76d1d7'],
    [0.6313725490196078, '#74cfd6'],
    [0.6352941176470588, '#71ced6'],
    [0.6392156862745098, '#6eccd6'],
    [0.6431372549019607, '#6ccbd6'],
    [0.6470588235294118, '#69c9d5'],
    [0.6509803921568628, '#67c7d5'],
    [0.6549019607843137, '#64c6d5'],
    [0.6588235294117647, '#62c4d4'],
    [0.6627450980392157, '#60c3d4'],
    [0.6666666666666666, '#5dc1d3'],
    [0.6705882352941176, '#5bbfd3'],
    [0.6745098039215687, '#59bdd2'],
    [0.6784313725490196, '#57bcd2'],
    [0.6823529411764706, '#55bad1'],
    [0.6862745098039216, '#53b8d1'],
    [0.6901960784313725, '#51b7d0'],
    [0.6941176470588235, '#4fb5d0'],
    [0.6980392156862745, '#4db3cf'],
    [0.7019607843137254, '#4bb2ce'],
    [0.7058823529411764, '#49b0ce'],
    [0.7098039215686275, '#47aecd'],
    [0.7137254901960784, '#46accc'],
    [0.7176470588235294, '#44abcc'],
    [0.7215686274509804, '#43a9cb'],
    [0.7254901960784313, '#41a7cb'],
    [0.7294117647058823, '#40a6ca'],
    [0.7333333333333333, '#3ea4c9'],
    [0.7372549019607844, '#3da2c9'],
    [0.7411764705882353, '#3ca1c8'],
    [0.7450980392156863, '#3a9fc7'],
    [0.7490196078431373, '#399dc7'],
    [0.7529411764705882, '#389cc6'],
    [0.7568627450980392, '#379ac5'],
    [0.7607843137254902, '#3698c5'],
    [0.7647058823529411, '#3597c4'],
    [0.7686274509803921, '#3495c3'],
    [0.7725490196078432, '#3394c3'],
    [0.7764705882352941, '#3292c2'],
    [0.7803921568627451, '#3190c1'],
    [0.7843137254901961, '#308fc1'],
    [0.788235294117647, '#308dc0'],
    [0.792156862745098, '#2f8cbf'],
    [0.796078431372549, '#2e8abf'],
    [0.8, '#2d88be'],
    [0.803921568627451, '#2d87bd'],
    [0.807843137254902, '#2c85bd'],
    [0.8117647058823529, '#2b84bc'],
    [0.8156862745098039, '#2b82bb'],
    [0.8196078431372549, '#2a81bb'],
    [0.8235294117647058, '#297fba'],
    [0.8274509803921568, '#297eb9'],
    [0.8313725490196078, '#287cb9'],
    [0.8352941176470589, '#287ab8'],
    [0.8392156862745098, '#2779b7'],
    [0.8431372549019608, '#2677b7'],
    [0.8470588235294118, '#2676b6'],
    [0.8509803921568627, '#2574b5'],
    [0.8549019607843137, '#2573b5'],
    [0.8588235294117647, '#2471b4'],
    [0.8627450980392157, '#246fb3'],
    [0.8666666666666667, '#236eb3'],
    [0.8705882352941177, '#236cb2'],
    [0.8745098039215686, '#226ab1'],
    [0.8784313725490196, '#2269b0'],
    [0.8823529411764706, '#2167b0'],
    [0.8862745098039215, '#2166af'],
    [0.8901960784313725, '#2064ae'],
    [0.8941176470588235, '#2062ae'],
    [0.8980392156862745, '#1f60ad'],
    [0.9019607843137255, '#1e5fac'],
    [0.9058823529411765, '#1e5dab'],
    [0.9098039215686274, '#1d5bab'],
    [0.9137254901960784, '#1d5aaa'],
    [0.9176470588235294, '#1c58a9'],
    [0.9215686274509803, '#1b56a8'],
    [0.9254901960784314, '#1b54a8'],
    [0.9294117647058824, '#1a53a7'],
    [0.9333333333333333, '#1951a6'],
    [0.9372549019607843, '#194fa5'],
    [0.9411764705882353, '#184da4'],
    [0.9450980392156862, '#174ca4'],
    [0.9490196078431372, '#164aa3'],
    [0.9529411764705882, '#1548a2'],
    [0.9568627450980391, '#1446a1'],
    [0.9607843137254902, '#1344a0'],
    [0.9647058823529412, '#1242a0'],
    [0.9686274509803922, '#11409f'],
    [0.9725490196078431, '#0f3f9e'],
    [0.9764705882352941, '#0e3d9d'],
    [0.9803921568627451, '#0c3b9c'],
    [0.984313725490196, '#0b399c'],
    [0.9882352941176471, '#09379b'],
    [0.9921568627450981, '#07359a'],
    [0.996078431372549, '#053399'],
    [1.0, '#033198'],
  ],
  batlowW: [
    [0.0, '#011959'],
    [0.00392156862745098, '#021b59'],
    [0.00784313725490196, '#031c5a'],
    [0.011764705882352941, '#041e5a'],
    [0.01568627450980392, '#051f5a'],
    [0.0196078431372549, '#06215b'],
    [0.023529411764705882, '#07225b'],
    [0.027450980392156862, '#07245b'],
    [0.03137254901960784, '#08255b'],
    [0.03529411764705882, '#09275c'],
    [0.0392156862745098, '#0a285c'],
    [0.043137254901960784, '#0a2a5c'],
    [0.047058823529411764, '#0b2b5c'],
    [0.050980392156862744, '#0b2d5d'],
    [0.054901960784313725, '#0c2e5d'],
    [0.058823529411764705, '#0c2f5d'],
    [0.06274509803921569, '#0d315d'],
    [0.06666666666666667, '#0d325e'],
    [0.07058823529411765, '#0d335e'],
    [0.07450980392156863, '#0e355e'],
    [0.0784313725490196, '#0e365e'],
    [0.08235294117647059, '#0e375e'],
    [0.08627450980392157, '#0f385f'],
    [0.09019607843137255, '#0f3a5f'],
    [0.09411764705882353, '#0f3b5f'],
    [0.09803921568627451, '#0f3c5f'],
    [0.10196078431372549, '#103d5f'],
    [0.10588235294117647, '#103e5f'],
    [0.10980392156862745, '#103f60'],
    [0.11372549019607843, '#104060'],
    [0.11764705882352941, '#104160'],
    [0.12156862745098039, '#114260'],
    [0.12549019607843137, '#114360'],
    [0.12941176470588234, '#114460'],
    [0.13333333333333333, '#114561'],
    [0.13725490196078433, '#124661'],
    [0.1411764705882353, '#124761'],
    [0.14509803921568626, '#124761'],
    [0.14901960784313725, '#124861'],
    [0.15294117647058825, '#134961'],
    [0.1568627450980392, '#134a61'],
    [0.16078431372549018, '#134b61'],
    [0.16470588235294117, '#144c62'],
    [0.16862745098039217, '#144d62'],
    [0.17254901960784313, '#144e62'],
    [0.1764705882352941, '#154f62'],
    [0.1803921568627451, '#155062'],
    [0.1843137254901961, '#165062'],
    [0.18823529411764706, '#165162'],
    [0.19215686274509802, '#165262'],
    [0.19607843137254902, '#175362'],
    [0.2, '#175462'],
    [0.20392156862745098, '#185562'],
    [0.20784313725490194, '#185662'],
    [0.21176470588235294, '#195762'],
    [0.21568627450980393, '#1a5862'],
    [0.2196078431372549, '#1a5862'],
    [0.22352941176470587, '#1b5962'],
    [0.22745098039215686, '#1c5a62'],
    [0.23137254901960785, '#1c5b62'],
    [0.23529411764705882, '#1d5c62'],
    [0.2392156862745098, '#1e5d62'],
    [0.24313725490196078, '#1f5d62'],
    [0.24705882352941178, '#1f5e62'],
    [0.25098039215686274, '#205f61'],
    [0.2549019607843137, '#216061'],
    [0.2588235294117647, '#226161'],
    [0.2627450980392157, '#236161'],
    [0.26666666666666666, '#246261'],
    [0.27058823529411763, '#256360'],
    [0.27450980392156865, '#266460'],
    [0.2784313725490196, '#276460'],
    [0.2823529411764706, '#28655f'],
    [0.28627450980392155, '#2a665f'],
    [0.2901960784313725, '#2b675e'],
    [0.29411764705882354, '#2c675e'],
    [0.2980392156862745, '#2d685e'],
    [0.30196078431372547, '#2e695d'],
    [0.3058823529411765, '#30695d'],
    [0.30980392156862746, '#316a5c'],
    [0.3137254901960784, '#326b5c'],
    [0.3176470588235294, '#346b5b'],
    [0.32156862745098036, '#356c5a'],
    [0.3254901960784314, '#366c5a'],
    [0.32941176470588235, '#386d59'],
    [0.3333333333333333, '#396e59'],
    [0.33725490196078434, '#3a6e58'],
    [0.3411764705882353, '#3c6f57'],
    [0.34509803921568627, '#3d7056'],
    [0.34901960784313724, '#3f7056'],
    [0.3529411764705882, '#407155'],
    [0.3568627450980392, '#427154'],
    [0.3607843137254902, '#437254'],
    [0.36470588235294116, '#457353'],
    [0.3686274509803922, '#467352'],
    [0.37254901960784315, '#487451'],
    [0.3764705882352941, '#497451'],
    [0.3803921568627451, '#4b7550'],
    [0.38431372549019605, '#4c754f'],
    [0.38823529411764707, '#4e764e'],
    [0.39215686274509803, '#4f774d'],
    [0.396078431372549, '#51774d'],
    [0.4, '#53784c'],
    [0.403921568627451, '#54784b'],
    [0.40784313725490196, '#56794a'],
    [0.4117647058823529, '#577a49'],
    [0.4156862745098039, '#597a49'],
    [0.4196078431372549, '#5b7b48'],
    [0.4235294117647059, '#5c7b47'],
    [0.42745098039215684, '#5e7c46'],
    [0.43137254901960786, '#5f7c45'],
    [0.43529411764705883, '#617d45'],
    [0.4392156862745098, '#637e44'],
    [0.44313725490196076, '#647e43'],
    [0.44705882352941173, '#667f42'],
    [0.45098039215686275, '#687f41'],
    [0.4549019607843137, '#698040'],
    [0.4588235294117647, '#6b8140'],
    [0.4627450980392157, '#6d813f'],
    [0.4666666666666667, '#6e823e'],
    [0.47058823529411764, '#70823d'],
    [0.4745098039215686, '#72833d'],
    [0.4784313725490196, '#74843c'],
    [0.4823529411764706, '#75843b'],
    [0.48627450980392156, '#77853a'],
    [0.49019607843137253, '#798539'],
    [0.49411764705882355, '#7b8639'],
    [0.4980392156862745, '#7c8738'],
    [0.5019607843137255, '#7e8737'],
    [0.5058823529411764, '#808837'],
    [0.5098039215686274, '#828936'],
    [0.5137254901960784, '#848935'],
    [0.5176470588235293, '#868a35'],
    [0.5215686274509804, '#888b34'],
    [0.5254901960784314, '#8a8b34'],
    [0.5294117647058824, '#8c8c33'],
    [0.5333333333333333, '#8e8d33'],
    [0.5372549019607843, '#908d33'],
    [0.5411764705882353, '#928e32'],
    [0.5450980392156862, '#948f32'],
    [0.5490196078431373, '#968f32'],
    [0.5529411764705883, '#989032'],
    [0.5568627450980392, '#9a9132'],
    [0.5607843137254902, '#9c9232'],
    [0.5647058823529412, '#9e9233'],
    [0.5686274509803921, '#a09333'],
    [0.5725490196078431, '#a29433'],
    [0.5764705882352941, '#a49434'],
    [0.580392156862745, '#a79535'],
    [0.5843137254901961, '#a99636'],
    [0.5882352941176471, '#ab9737'],
    [0.592156862745098, '#ad9738'],
    [0.596078431372549, '#af9839'],
    [0.6, '#b1993a'],
    [0.6039215686274509, '#b3993b'],
    [0.6078431372549019, '#b59a3d'],
    [0.611764705882353, '#b79b3f'],
    [0.615686274509804, '#b99b40'],
    [0.6196078431372549, '#bb9c42'],
    [0.6235294117647059, '#bd9d44'],
    [0.6274509803921569, '#bf9d46'],
    [0.6313725490196078, '#c19e48'],
    [0.6352941176470588, '#c39f4a'],
    [0.6392156862745098, '#c59f4c'],
    [0.6431372549019607, '#c6a04e'],
    [0.6470588235294118, '#c8a050'],
    [0.6509803921568628, '#caa152'],
    [0.6549019607843137, '#cba154'],
    [0.6588235294117647, '#cda256'],
    [0.6627450980392157, '#cea258'],
    [0.6666666666666666, '#d0a35a'],
    [0.6705882352941176, '#d1a35c'],
    [0.6745098039215687, '#d3a45e'],
    [0.6784313725490196, '#d4a460'],
    [0.6823529411764706, '#d5a562'],
    [0.6862745098039216, '#d7a564'],
    [0.6901960784313725, '#d8a566'],
    [0.6941176470588235, '#d9a669'],
    [0.6980392156862745, '#daa66b'],
    [0.7019607843137254, '#dba76d'],
    [0.7058823529411764, '#dca76f'],
    [0.7098039215686275, '#dda770'],
    [0.7137254901960784, '#dfa872'],
    [0.7176470588235294, '#e0a874'],
    [0.7215686274509804, '#e1a976'],
    [0.7254901960784313, '#e2a978'],
    [0.7294117647058823, '#e3a97a'],
    [0.7333333333333333, '#e4aa7c'],
    [0.7372549019607844, '#e5aa7e'],
    [0.7411764705882353, '#e5ab80'],
    [0.7450980392156863, '#e6ab82'],
    [0.7490196078431373, '#e7ab84'],
    [0.7529411764705882, '#e8ac86'],
    [0.7568627450980392, '#e9ac88'],
    [0.7607843137254902, '#eaad89'],
    [0.7647058823529411, '#ebad8b'],
    [0.7686274509803921, '#ecae8d'],
    [0.7725490196078432, '#edaf8f'],
    [0.7764705882352941, '#eeaf91'],
    [0.7803921568627451, '#eeb094'],
    [0.7843137254901961, '#efb196'],
    [0.788235294117647, '#f0b198'],
    [0.792156862745098, '#f1b29a'],
    [0.796078431372549, '#f2b39c'],
    [0.8, '#f3b49e'],
    [0.803921568627451, '#f3b5a1'],
    [0.807843137254902, '#f4b6a3'],
    [0.8117647058823529, '#f5b7a6'],
    [0.8156862745098039, '#f6b9a8'],
    [0.8196078431372549, '#f7baaa'],
    [0.8235294117647058, '#f7bbad'],
    [0.8274509803921568, '#f8bdaf'],
    [0.8313725490196078, '#f9beb2'],
    [0.8352941176470589, '#f9c0b5'],
    [0.8392156862745098, '#fac1b7'],
    [0.8431372549019608, '#fac3ba'],
    [0.8470588235294118, '#fbc5bc'],
    [0.8509803921568627, '#fcc6bf'],
    [0.8549019607843137, '#fcc8c1'],
    [0.8588235294117647, '#fccac4'],
    [0.8627450980392157, '#fdccc6'],
    [0.8666666666666667, '#fdcdc9'],
    [0.8705882352941177, '#fdcfcb'],
    [0.8745098039215686, '#fed1cd'],
    [0.8784313725490196, '#fed3cf'],
    [0.8823529411764706, '#fed5d2'],
    [0.8862745098039215, '#fed6d4'],
    [0.8901960784313725, '#fed8d6'],
    [0.8941176470588235, '#ffdad8'],
    [0.8980392156862745, '#ffdbda'],
    [0.9019607843137255, '#ffdddb'],
    [0.9058823529411765, '#ffdfdd'],
    [0.9098039215686274, '#ffe0df'],
    [0.9137254901960784, '#ffe2e1'],
    [0.9176470588235294, '#ffe3e2'],
    [0.9215686274509803, '#ffe5e4'],
    [0.9254901960784314, '#ffe6e6'],
    [0.9294117647058824, '#ffe8e7'],
    [0.9333333333333333, '#ffe9e9'],
    [0.9372549019607843, '#ffeaea'],
    [0.9411764705882353, '#ffeceb'],
    [0.9450980392156862, '#ffeded'],
    [0.9490196078431372, '#ffefee'],
    [0.9529411764705882, '#fff0f0'],
    [0.9568627450980391, '#fff1f1'],
    [0.9607843137254902, '#fff2f2'],
    [0.9647058823529412, '#fff4f3'],
    [0.9686274509803922, '#fff5f5'],
    [0.9725490196078431, '#fff6f6'],
    [0.9764705882352941, '#fff7f7'],
    [0.9803921568627451, '#fff8f8'],
    [0.984313725490196, '#fffaf9'],
    [0.9882352941176471, '#fffbfb'],
    [0.9921568627450981, '#fffcfc'],
    [0.996078431372549, '#fffdfd'],
    [1.0, '#fffefe'],
  ],
  nuuk: [
    [0.0, '#05598c'],
    [0.00392156862745098, '#075a8c'],
    [0.00784313725490196, '#095a8c'],
    [0.011764705882352941, '#0b5a8b'],
    [0.01568627450980392, '#0d5b8b'],
    [0.0196078431372549, '#0e5b8b'],
    [0.023529411764705882, '#105b8a'],
    [0.027450980392156862, '#115b8a'],
    [0.03137254901960784, '#135c89'],
    [0.03529411764705882, '#145c89'],
    [0.0392156862745098, '#155c89'],
    [0.043137254901960784, '#175c88'],
    [0.047058823529411764, '#185d88'],
    [0.050980392156862744, '#195d88'],
    [0.054901960784313725, '#1a5d87'],
    [0.058823529411764705, '#1b5e87'],
    [0.06274509803921569, '#1c5e87'],
    [0.06666666666666667, '#1e5e86'],
    [0.07058823529411765, '#1f5f86'],
    [0.07450980392156863, '#205f86'],
    [0.0784313725490196, '#215f86'],
    [0.08235294117647059, '#226085'],
    [0.08627450980392157, '#236085'],
    [0.09019607843137255, '#246085'],
    [0.09411764705882353, '#256185'],
    [0.09803921568627451, '#266184'],
    [0.10196078431372549, '#276184'],
    [0.10588235294117647, '#286284'],
    [0.10980392156862745, '#296284'],
    [0.11372549019607843, '#2a6383'],
    [0.11764705882352941, '#2b6383'],
    [0.12156862745098039, '#2c6383'],
    [0.12549019607843137, '#2d6483'],
    [0.12941176470588234, '#2e6483'],
    [0.13333333333333333, '#2f6583'],
    [0.13725490196078433, '#306582'],
    [0.1411764705882353, '#326682'],
    [0.14509803921568626, '#336682'],
    [0.14901960784313725, '#346782'],
    [0.15294117647058825, '#356782'],
    [0.1568627450980392, '#366882'],
    [0.16078431372549018, '#376882'],
    [0.16470588235294117, '#386982'],
    [0.16862745098039217, '#396982'],
    [0.17254901960784313, '#3b6a82'],
    [0.1764705882352941, '#3c6a82'],
    [0.1803921568627451, '#3d6b82'],
    [0.1843137254901961, '#3e6c82'],
    [0.18823529411764706, '#3f6c82'],
    [0.19215686274509802, '#406d82'],
    [0.19607843137254902, '#426d82'],
    [0.2, '#436e82'],
    [0.20392156862745098, '#446f82'],
    [0.20784313725490194, '#456f82'],
    [0.21176470588235294, '#467083'],
    [0.21568627450980393, '#487183'],
    [0.2196078431372549, '#497183'],
    [0.22352941176470587, '#4a7283'],
    [0.22745098039215686, '#4b7383'],
    [0.23137254901960785, '#4d7384'],
    [0.23529411764705882, '#4e7484'],
    [0.2392156862745098, '#4f7584'],
    [0.24313725490196078, '#517584'],
    [0.24705882352941178, '#527685'],
    [0.25098039215686274, '#537785'],
    [0.2549019607843137, '#557885'],
    [0.2588235294117647, '#567886'],
    [0.2627450980392157, '#577986'],
    [0.26666666666666666, '#587a86'],
    [0.27058823529411763, '#5a7a87'],
    [0.27450980392156865, '#5b7b87'],
    [0.2784313725490196, '#5c7c87'],
    [0.2823529411764706, '#5e7d88'],
    [0.28627450980392155, '#5f7d88'],
    [0.2901960784313725, '#607e88'],
    [0.29411764705882354, '#627f89'],
    [0.2980392156862745, '#638089'],
    [0.30196078431372547, '#64818a'],
    [0.3058823529411765, '#66818a'],
    [0.30980392156862746, '#67828a'],
    [0.3137254901960784, '#68838b'],
    [0.3176470588235294, '#6a848b'],
    [0.32156862745098036, '#6b848c'],
    [0.3254901960784314, '#6c858c'],
    [0.32941176470588235, '#6e868c'],
    [0.3333333333333333, '#6f878d'],
    [0.33725490196078434, '#70878d'],
    [0.3411764705882353, '#72888e'],
    [0.34509803921568627, '#73898e'],
    [0.34901960784313724, '#748a8f'],
    [0.3529411764705882, '#768b8f'],
    [0.3568627450980392, '#778b8f'],
    [0.3607843137254902, '#788c90'],
    [0.36470588235294116, '#798d90'],
    [0.3686274509803922, '#7b8e91'],
    [0.37254901960784315, '#7c8e91'],
    [0.3764705882352941, '#7d8f91'],
    [0.3803921568627451, '#7f9092'],
    [0.38431372549019605, '#809192'],
    [0.38823529411764707, '#819192'],
    [0.39215686274509803, '#829293'],
    [0.396078431372549, '#849393'],
    [0.4, '#859493'],
    [0.403921568627451, '#869494'],
    [0.40784313725490196, '#879594'],
    [0.4117647058823529, '#899694'],
    [0.4156862745098039, '#8a9795'],
    [0.4196078431372549, '#8b9795'],
    [0.4235294117647059, '#8c9895'],
    [0.42745098039215684, '#8d9996'],
    [0.43137254901960786, '#8e9a96'],
    [0.43529411764705883, '#909a96'],
    [0.4392156862745098, '#919b96'],
    [0.44313725490196076, '#929c96'],
    [0.44705882352941173, '#939c97'],
    [0.45098039215686275, '#949d97'],
    [0.4549019607843137, '#959e97'],
    [0.4588235294117647, '#969e97'],
    [0.4627450980392157, '#979f97'],
    [0.4666666666666667, '#98a097'],
    [0.47058823529411764, '#99a097'],
    [0.4745098039215686, '#9aa198'],
    [0.4784313725490196, '#9ba298'],
    [0.4823529411764706, '#9ca298'],
    [0.48627450980392156, '#9da398'],
    [0.49019607843137253, '#9ea498'],
    [0.49411764705882355, '#9fa498'],
    [0.4980392156862745, '#a0a598'],
    [0.5019607843137255, '#a1a698'],
    [0.5058823529411764, '#a2a698'],
    [0.5098039215686274, '#a2a798'],
    [0.5137254901960784, '#a3a798'],
    [0.5176470588235293, '#a4a897'],
    [0.5215686274509804, '#a5a897'],
    [0.5254901960784314, '#a6a997'],
    [0.5294117647058824, '#a6aa97'],
    [0.5333333333333333, '#a7aa97'],
    [0.5372549019607843, '#a8ab97'],
    [0.5411764705882353, '#a9ab97'],
    [0.5450980392156862, '#a9ac97'],
    [0.5490196078431373, '#aaac96'],
    [0.5529411764705883, '#abad96'],
    [0.5568627450980392, '#abad96'],
    [0.5607843137254902, '#acae96'],
    [0.5647058823529412, '#adae95'],
    [0.5686274509803921, '#adaf95'],
    [0.5725490196078431, '#aeaf95'],
    [0.5764705882352941, '#aeb095'],
    [0.580392156862745, '#afb094'],
    [0.5843137254901961, '#b0b194'],
    [0.5882352941176471, '#b0b194'],
    [0.592156862745098, '#b1b194'],
    [0.596078431372549, '#b1b293'],
    [0.6, '#b2b293'],
    [0.6039215686274509, '#b2b393'],
    [0.6078431372549019, '#b3b392'],
    [0.611764705882353, '#b3b492'],
    [0.615686274509804, '#b4b492'],
    [0.6196078431372549, '#b4b591'],
    [0.6235294117647059, '#b5b591'],
    [0.6274509803921569, '#b5b591'],
    [0.6313725490196078, '#b6b690'],
    [0.6352941176470588, '#b6b690'],
    [0.6392156862745098, '#b7b790'],
    [0.6431372549019607, '#b7b78f'],
    [0.6470588235294118, '#b7b78f'],
    [0.6509803921568628, '#b8b88e'],
    [0.6549019607843137, '#b8b88e'],
    [0.6588235294117647, '#b9b98e'],
    [0.6627450980392157, '#b9b98d'],
    [0.6666666666666666, '#bab98d'],
    [0.6705882352941176, '#baba8d'],
    [0.6745098039215687, '#baba8c'],
    [0.6784313725490196, '#bbbb8c'],
    [0.6823529411764706, '#bbbb8b'],
    [0.6862745098039216, '#bcbb8b'],
    [0.6901960784313725, '#bcbc8b'],
    [0.6941176470588235, '#bdbc8a'],
    [0.6980392156862745, '#bdbd8a'],
    [0.7019607843137254, '#bdbd8a'],
    [0.7058823529411764, '#bebd89'],
    [0.7098039215686275, '#bebe89'],
    [0.7137254901960784, '#bfbe88'],
    [0.7176470588235294, '#bfbf88'],
    [0.7215686274509804, '#c0bf88'],
    [0.7254901960784313, '#c0c087'],
    [0.7294117647058823, '#c0c087'],
    [0.7333333333333333, '#c1c187'],
    [0.7372549019607844, '#c1c186'],
    [0.7411764705882353, '#c2c186'],
    [0.7450980392156863, '#c2c286'],
    [0.7490196078431373, '#c3c285'],
    [0.7529411764705882, '#c3c385'],
    [0.7568627450980392, '#c4c385'],
    [0.7607843137254902, '#c4c485'],
    [0.7647058823529411, '#c5c484'],
    [0.7686274509803921, '#c5c584'],
    [0.7725490196078432, '#c6c684'],
    [0.7764705882352941, '#c7c684'],
    [0.7803921568627451, '#c7c784'],
    [0.7843137254901961, '#c8c783'],
    [0.788235294117647, '#c8c883'],
    [0.792156862745098, '#c9c983'],
    [0.796078431372549, '#cac983'],
    [0.8, '#caca83'],
    [0.803921568627451, '#cbcb83'],
    [0.807843137254902, '#cccb83'],
    [0.8117647058823529, '#cdcc83'],
    [0.8156862745098039, '#cdcd83'],
    [0.8196078431372549, '#cece83'],
    [0.8235294117647058, '#cfcf84'],
    [0.8274509803921568, '#d0cf84'],
    [0.8313725490196078, '#d1d084'],
    [0.8352941176470589, '#d2d184'],
    [0.8392156862745098, '#d2d285'],
    [0.8431372549019608, '#d3d385'],
    [0.8470588235294118, '#d4d486'],
    [0.8509803921568627, '#d5d586'],
    [0.8549019607843137, '#d6d687'],
    [0.8588235294117647, '#d7d787'],
    [0.8627450980392157, '#d8d888'],
    [0.8666666666666667, '#d9d988'],
    [0.8705882352941177, '#dbda89'],
    [0.8745098039215686, '#dcdb8a'],
    [0.8784313725490196, '#dddd8b'],
    [0.8823529411764706, '#dede8c'],
    [0.8862745098039215, '#dfdf8d'],
    [0.8901960784313725, '#e0e08e'],
    [0.8941176470588235, '#e1e18f'],
    [0.8980392156862745, '#e3e290'],
    [0.9019607843137255, '#e4e391'],
    [0.9058823529411765, '#e5e592'],
    [0.9098039215686274, '#e6e693'],
    [0.9137254901960784, '#e7e794'],
    [0.9176470588235294, '#e8e895'],
    [0.9215686274509803, '#e9e997'],
    [0.9254901960784314, '#ebea98'],
    [0.9294117647058824, '#eceb99'],
    [0.9333333333333333, '#eded9a'],
    [0.9372549019607843, '#eeee9c'],
    [0.9411764705882353, '#efef9d'],
    [0.9450980392156862, '#f0f09e'],
    [0.9490196078431372, '#f1f1a0'],
    [0.9529411764705882, '#f2f2a1'],
    [0.9568627450980391, '#f3f3a3'],
    [0.9607843137254902, '#f4f4a4'],
    [0.9647058823529412, '#f5f5a5'],
    [0.9686274509803922, '#f6f6a7'],
    [0.9725490196078431, '#f7f7a8'],
    [0.9764705882352941, '#f8f8aa'],
    [0.9803921568627451, '#f9f9ab'],
    [0.984313725490196, '#fafaad'],
    [0.9882352941176471, '#fbfbae'],
    [0.9921568627450981, '#fcfcaf'],
    [0.996078431372549, '#fdfdb1'],
    [1.0, '#fefeb2'],
  ],
  lisbon: [
    [0.0, '#e6e5ff'],
    [0.00392156862745098, '#e3e3fd'],
    [0.00784313725490196, '#e0e2fc'],
    [0.011764705882352941, '#dde0fa'],
    [0.01568627450980392, '#dbdef9'],
    [0.0196078431372549, '#d8dcf7'],
    [0.023529411764705882, '#d5daf5'],
    [0.027450980392156862, '#d3d8f4'],
    [0.03137254901960784, '#d0d6f2'],
    [0.03529411764705882, '#cdd4f1'],
    [0.0392156862745098, '#cbd2ef'],
    [0.043137254901960784, '#c8d0ed'],
    [0.047058823529411764, '#c5ceec'],
    [0.050980392156862744, '#c3ccea'],
    [0.054901960784313725, '#c0cae9'],
    [0.058823529411764705, '#bdc8e7'],
    [0.06274509803921569, '#bbc6e5'],
    [0.06666666666666667, '#b8c4e4'],
    [0.07058823529411765, '#b5c2e2'],
    [0.07450980392156863, '#b3c0e1'],
    [0.0784313725490196, '#b0bedf'],
    [0.08235294117647059, '#adbcde'],
    [0.08627450980392157, '#abbadc'],
    [0.09019607843137255, '#a8b9da'],
    [0.09411764705882353, '#a5b7d9'],
    [0.09803921568627451, '#a3b5d7'],
    [0.10196078431372549, '#a0b3d6'],
    [0.10588235294117647, '#9eb1d4'],
    [0.10980392156862745, '#9bafd3'],
    [0.11372549019607843, '#98add1'],
    [0.11764705882352941, '#96abcf'],
    [0.12156862745098039, '#93a9ce'],
    [0.12549019607843137, '#90a7cc'],
    [0.12941176470588234, '#8ea5cb'],
    [0.13333333333333333, '#8ba3c9'],
    [0.13725490196078433, '#89a2c8'],
    [0.1411764705882353, '#86a0c6'],
    [0.14509803921568626, '#849ec4'],
    [0.14901960784313725, '#819cc3'],
    [0.15294117647058825, '#7e9ac1'],
    [0.1568627450980392, '#7c98c0'],
    [0.16078431372549018, '#7996be'],
    [0.16470588235294117, '#7794bd'],
    [0.16862745098039217, '#7492bb'],
    [0.17254901960784313, '#7290b9'],
    [0.1764705882352941, '#6f8eb8'],
    [0.1803921568627451, '#6d8db6'],
    [0.1843137254901961, '#6a8bb5'],
    [0.18823529411764706, '#6889b3'],
    [0.19215686274509802, '#6587b1'],
    [0.19607843137254902, '#6285b0'],
    [0.2, '#6083ae'],
    [0.20392156862745098, '#5e81ac'],
    [0.20784313725490194, '#5b7fab'],
    [0.21176470588235294, '#597da9'],
    [0.21568627450980393, '#567ba7'],
    [0.2196078431372549, '#5479a6'],
    [0.22352941176470587, '#5177a4'],
    [0.22745098039215686, '#4f75a2'],
    [0.23137254901960785, '#4c73a0'],
    [0.23529411764705882, '#4a729e'],
    [0.2392156862745098, '#48709d'],
    [0.24313725490196078, '#456e9b'],
    [0.24705882352941178, '#436c99'],
    [0.25098039215686274, '#416a97'],
    [0.2549019607843137, '#3f6895'],
    [0.2588235294117647, '#3d6693'],
    [0.2627450980392157, '#3a6491'],
    [0.26666666666666666, '#38628f'],
    [0.27058823529411763, '#36608c'],
    [0.27450980392156865, '#345e8a'],
    [0.2784313725490196, '#325c88'],
    [0.2823529411764706, '#305a86'],
    [0.28627450980392155, '#2f5984'],
    [0.2901960784313725, '#2d5782'],
    [0.29411764705882354, '#2b557f'],
    [0.2980392156862745, '#2a537d'],
    [0.30196078431372547, '#28517b'],
    [0.3058823529411765, '#274f78'],
    [0.30980392156862746, '#254e76'],
    [0.3137254901960784, '#244c74'],
    [0.3176470588235294, '#224a71'],
    [0.32156862745098036, '#21486f'],
    [0.3254901960784314, '#20476d'],
    [0.32941176470588235, '#1f456a'],
    [0.3333333333333333, '#1e4368'],
    [0.33725490196078434, '#1d4266'],
    [0.3411764705882353, '#1c4064'],
    [0.34509803921568627, '#1b3f61'],
    [0.34901960784313724, '#1a3d5f'],
    [0.3529411764705882, '#193c5d'],
    [0.3568627450980392, '#193a5a'],
    [0.3607843137254902, '#183958'],
    [0.36470588235294116, '#173756'],
    [0.3686274509803922, '#173653'],
    [0.37254901960784315, '#163451'],
    [0.3764705882352941, '#16334f'],
    [0.3803921568627451, '#15314d'],
    [0.38431372549019605, '#15304b'],
    [0.38823529411764707, '#142e48'],
    [0.39215686274509803, '#142d46'],
    [0.396078431372549, '#132c44'],
    [0.4, '#132a42'],
    [0.403921568627451, '#132940'],
    [0.40784313725490196, '#12283e'],
    [0.4117647058823529, '#12273c'],
    [0.4156862745098039, '#12253a'],
    [0.4196078431372549, '#122437'],
    [0.4235294117647059, '#112335'],
    [0.42745098039215684, '#112233'],
    [0.43137254901960786, '#112132'],
    [0.43529411764705883, '#112030'],
    [0.4392156862745098, '#111f2e'],
    [0.44313725490196076, '#111e2c'],
    [0.44705882352941173, '#111d2a'],
    [0.45098039215686275, '#111c28'],
    [0.4549019607843137, '#111b27'],
    [0.4588235294117647, '#121b25'],
    [0.4627450980392157, '#121a24'],
    [0.4666666666666667, '#121a22'],
    [0.47058823529411764, '#121921'],
    [0.4745098039215686, '#131920'],
    [0.4784313725490196, '#13191e'],
    [0.4823529411764706, '#14181d'],
    [0.48627450980392156, '#14181c'],
    [0.49019607843137253, '#15181b'],
    [0.49411764705882355, '#15181a'],
    [0.4980392156862745, '#16191a'],
    [0.5019607843137255, '#171919'],
    [0.5058823529411764, '#181919'],
    [0.5098039215686274, '#181a18'],
    [0.5137254901960784, '#191a18'],
    [0.5176470588235293, '#1a1b18'],
    [0.5215686274509804, '#1b1c17'],
    [0.5254901960784314, '#1c1c17'],
    [0.5294117647058824, '#1e1d17'],
    [0.5333333333333333, '#1f1e18'],
    [0.5372549019607843, '#201f18'],
    [0.5411764705882353, '#212018'],
    [0.5450980392156862, '#232118'],
    [0.5490196078431373, '#242319'],
    [0.5529411764705883, '#252419'],
    [0.5568627450980392, '#27251a'],
    [0.5607843137254902, '#28261a'],
    [0.5647058823529412, '#2a281b'],
    [0.5686274509803921, '#2b291c'],
    [0.5725490196078431, '#2d2b1c'],
    [0.5764705882352941, '#2f2c1d'],
    [0.580392156862745, '#302d1e'],
    [0.5843137254901961, '#322f1f'],
    [0.5882352941176471, '#333020'],
    [0.592156862745098, '#353220'],
    [0.596078431372549, '#373421'],
    [0.6, '#383522'],
    [0.6039215686274509, '#3a3723'],
    [0.6078431372549019, '#3c3824'],
    [0.611764705882353, '#3e3a25'],
    [0.615686274509804, '#3f3c26'],
    [0.6196078431372549, '#413d27'],
    [0.6235294117647059, '#433f28'],
    [0.6274509803921569, '#454029'],
    [0.6313725490196078, '#46422a'],
    [0.6352941176470588, '#48442b'],
    [0.6392156862745098, '#4a452c'],
    [0.6431372549019607, '#4c472d'],
    [0.6470588235294118, '#4d492e'],
    [0.6509803921568628, '#4f4b2f'],
    [0.6549019607843137, '#514c31'],
    [0.6588235294117647, '#534e32'],
    [0.6627450980392157, '#555033'],
    [0.6666666666666666, '#575134'],
    [0.6705882352941176, '#585335'],
    [0.6745098039215687, '#5a5536'],
    [0.6784313725490196, '#5c5737'],
    [0.6823529411764706, '#5e5838'],
    [0.6862745098039216, '#605a39'],
    [0.6901960784313725, '#625c3a'],
    [0.6941176470588235, '#645e3c'],
    [0.6980392156862745, '#66603d'],
    [0.7019607843137254, '#68613e'],
    [0.7058823529411764, '#69633f'],
    [0.7098039215686275, '#6b6540'],
    [0.7137254901960784, '#6d6741'],
    [0.7176470588235294, '#6f6943'],
    [0.7215686274509804, '#716b44'],
    [0.7254901960784313, '#736c45'],
    [0.7294117647058823, '#756e46'],
    [0.7333333333333333, '#777047'],
    [0.7372549019607844, '#797249'],
    [0.7411764705882353, '#7b744a'],
    [0.7450980392156863, '#7d764b'],
    [0.7490196078431373, '#7f784c'],
    [0.7529411764705882, '#817a4e'],
    [0.7568627450980392, '#837b4f'],
    [0.7607843137254902, '#857d50'],
    [0.7647058823529411, '#877f52'],
    [0.7686274509803921, '#898153'],
    [0.7725490196078432, '#8b8355'],
    [0.7764705882352941, '#8d8556'],
    [0.7803921568627451, '#8f8758'],
    [0.7843137254901961, '#918959'],
    [0.788235294117647, '#948b5b'],
    [0.792156862745098, '#968d5c'],
    [0.796078431372549, '#988f5e'],
    [0.8, '#9a9160'],
    [0.803921568627451, '#9c9361'],
    [0.807843137254902, '#9e9663'],
    [0.8117647058823529, '#a09865'],
    [0.8156862745098039, '#a29a67'],
    [0.8196078431372549, '#a49c69'],
    [0.8235294117647058, '#a69e6a'],
    [0.8274509803921568, '#a8a06c'],
    [0.8313725490196078, '#aba26e'],
    [0.8352941176470589, '#ada470'],
    [0.8392156862745098, '#afa772'],
    [0.8431372549019608, '#b1a975'],
    [0.8470588235294118, '#b3ab77'],
    [0.8509803921568627, '#b5ad79'],
    [0.8549019607843137, '#b7af7b'],
    [0.8588235294117647, '#b9b17d'],
    [0.8627450980392157, '#bbb380'],
    [0.8666666666666667, '#bdb682'],
    [0.8705882352941177, '#bfb884'],
    [0.8745098039215686, '#c1ba87'],
    [0.8784313725490196, '#c3bc89'],
    [0.8823529411764706, '#c5be8b'],
    [0.8862745098039215, '#c7c08e'],
    [0.8901960784313725, '#c9c390'],
    [0.8941176470588235, '#cbc593'],
    [0.8980392156862745, '#cdc795'],
    [0.9019607843137255, '#cfc998'],
    [0.9058823529411765, '#d1cb9a'],
    [0.9098039215686274, '#d3cd9d'],
    [0.9137254901960784, '#d5cf9f'],
    [0.9176470588235294, '#d7d2a2'],
    [0.9215686274509803, '#d9d4a4'],
    [0.9254901960784314, '#dad6a7'],
    [0.9294117647058824, '#dcd8a9'],
    [0.9333333333333333, '#dedaac'],
    [0.9372549019607843, '#e0dcaf'],
    [0.9411764705882353, '#e2deb1'],
    [0.9450980392156862, '#e4e1b4'],
    [0.9490196078431372, '#e6e3b6'],
    [0.9529411764705882, '#e8e5b9'],
    [0.9568627450980391, '#eae7bc'],
    [0.9607843137254902, '#ece9be'],
    [0.9647058823529412, '#eeebc1'],
    [0.9686274509803922, '#f0eec3'],
    [0.9725490196078431, '#f1f0c6'],
    [0.9764705882352941, '#f3f2c9'],
    [0.9803921568627451, '#f5f4cb'],
    [0.984313725490196, '#f7f6ce'],
    [0.9882352941176471, '#f9f8d1'],
    [0.9921568627450981, '#fbfbd3'],
    [0.996078431372549, '#fdfdd6'],
    [1.0, '#ffffd9'],
  ],
  oleron: [
    [0.0, '#1a2659'],
    [0.00392156862745098, '#1b285b'],
    [0.00784313725490196, '#1d295c'],
    [0.011764705882352941, '#1e2b5e'],
    [0.01568627450980392, '#202c5f'],
    [0.0196078431372549, '#212e61'],
    [0.023529411764705882, '#232f62'],
    [0.027450980392156862, '#243164'],
    [0.03137254901960784, '#263265'],
    [0.03529411764705882, '#283467'],
    [0.0392156862745098, '#293568'],
    [0.043137254901960784, '#2b376a'],
    [0.047058823529411764, '#2c386b'],
    [0.050980392156862744, '#2e3a6d'],
    [0.054901960784313725, '#2f3b6f'],
    [0.058823529411764705, '#313d70'],
    [0.06274509803921569, '#323f72'],
    [0.06666666666666667, '#344073'],
    [0.07058823529411765, '#354275'],
    [0.07450980392156863, '#374376'],
    [0.0784313725490196, '#394578'],
    [0.08235294117647059, '#3a477a'],
    [0.08627450980392157, '#3c487b'],
    [0.09019607843137255, '#3d4a7d'],
    [0.09411764705882353, '#3f4b7e'],
    [0.09803921568627451, '#414d80'],
    [0.10196078431372549, '#424f82'],
    [0.10588235294117647, '#445083'],
    [0.10980392156862745, '#455285'],
    [0.11372549019607843, '#475487'],
    [0.11764705882352941, '#495588'],
    [0.12156862745098039, '#4a578a'],
    [0.12549019607843137, '#4c598c'],
    [0.12941176470588234, '#4e5a8d'],
    [0.13333333333333333, '#4f5c8f'],
    [0.13725490196078433, '#515e91'],
    [0.1411764705882353, '#535f92'],
    [0.14509803921568626, '#546194'],
    [0.14901960784313725, '#566396'],
    [0.15294117647058825, '#586497'],
    [0.1568627450980392, '#596699'],
    [0.16078431372549018, '#5b689b'],
    [0.16470588235294117, '#5d699c'],
    [0.16862745098039217, '#5e6b9e'],
    [0.17254901960784313, '#606da0'],
    [0.1764705882352941, '#626fa2'],
    [0.1803921568627451, '#6470a3'],
    [0.1843137254901961, '#6572a5'],
    [0.18823529411764706, '#6774a7'],
    [0.19215686274509802, '#6975a9'],
    [0.19607843137254902, '#6b77aa'],
    [0.2, '#6c79ac'],
    [0.20392156862745098, '#6e7bae'],
    [0.20784313725490194, '#707db0'],
    [0.21176470588235294, '#727eb1'],
    [0.21568627450980393, '#7380b3'],
    [0.2196078431372549, '#7582b5'],
    [0.22352941176470587, '#7784b7'],
    [0.22745098039215686, '#7985b8'],
    [0.23137254901960785, '#7a87ba'],
    [0.23529411764705882, '#7c89bc'],
    [0.2392156862745098, '#7e8bbe'],
    [0.24313725490196078, '#808dc0'],
    [0.24705882352941178, '#828ec1'],
    [0.25098039215686274, '#8390c3'],
    [0.2549019607843137, '#8592c5'],
    [0.2588235294117647, '#8794c7'],
    [0.2627450980392157, '#8996c9'],
    [0.26666666666666666, '#8b97ca'],
    [0.27058823529411763, '#8d99cc'],
    [0.27450980392156865, '#8e9bce'],
    [0.2784313725490196, '#909dd0'],
    [0.2823529411764706, '#929fd2'],
    [0.28627450980392155, '#94a1d3'],
    [0.2901960784313725, '#96a2d5'],
    [0.29411764705882354, '#98a4d7'],
    [0.2980392156862745, '#99a6d9'],
    [0.30196078431372547, '#9ba8db'],
    [0.3058823529411765, '#9daadc'],
    [0.30980392156862746, '#9facde'],
    [0.3137254901960784, '#a1ade0'],
    [0.3176470588235294, '#a3afe1'],
    [0.32156862745098036, '#a4b1e3'],
    [0.3254901960784314, '#a6b3e5'],
    [0.32941176470588235, '#a8b5e6'],
    [0.3333333333333333, '#aab7e8'],
    [0.33725490196078434, '#acb8e9'],
    [0.3411764705882353, '#adbaea'],
    [0.34509803921568627, '#afbcec'],
    [0.34901960784313724, '#b1bded'],
    [0.3529411764705882, '#b2bfee'],
    [0.3568627450980392, '#b4c1ef'],
    [0.3607843137254902, '#b6c2f0'],
    [0.36470588235294116, '#b7c4f1'],
    [0.3686274509803922, '#b9c6f2'],
    [0.37254901960784315, '#bac7f3'],
    [0.3764705882352941, '#bcc9f3'],
    [0.3803921568627451, '#bdcaf4'],
    [0.38431372549019605, '#bfcbf4'],
    [0.38823529411764707, '#c0cdf5'],
    [0.39215686274509803, '#c2cef5'],
    [0.396078431372549, '#c3d0f6'],
    [0.4, '#c4d1f6'],
    [0.403921568627451, '#c6d2f7'],
    [0.40784313725490196, '#c7d4f7'],
    [0.4117647058823529, '#c8d5f8'],
    [0.4156862745098039, '#cad6f8'],
    [0.4196078431372549, '#cbd8f8'],
    [0.4235294117647059, '#ccd9f9'],
    [0.42745098039215684, '#cedaf9'],
    [0.43137254901960786, '#cfdcf9'],
    [0.43529411764705883, '#d0ddfa'],
    [0.4392156862745098, '#d2defa'],
    [0.44313725490196076, '#d3e0fa'],
    [0.44705882352941173, '#d4e1fb'],
    [0.45098039215686275, '#d6e2fb'],
    [0.4549019607843137, '#d7e4fb'],
    [0.4588235294117647, '#d8e5fc'],
    [0.4627450980392157, '#dae6fc'],
    [0.4666666666666667, '#dbe8fc'],
    [0.47058823529411764, '#dce9fd'],
    [0.4745098039215686, '#deeafd'],
    [0.4784313725490196, '#dfecfd'],
    [0.4823529411764706, '#e0edfe'],
    [0.48627450980392156, '#e2eefe'],
    [0.49019607843137253, '#e3f0fe'],
    [0.49411764705882355, '#e4f1ff'],
    [0.4980392156862745, '#e6f2ff'],
    [0.5019607843137255, '#1a4c00'],
    [0.5058823529411764, '#1d4d00'],
    [0.5098039215686274, '#1f4e00'],
    [0.5137254901960784, '#224f00'],
    [0.5176470588235293, '#254f00'],
    [0.5215686274509804, '#275000'],
    [0.5254901960784314, '#2a5100'],
    [0.5294117647058824, '#2c5100'],
    [0.5333333333333333, '#2f5200'],
    [0.5372549019607843, '#315300'],
    [0.5411764705882353, '#335400'],
    [0.5450980392156862, '#355400'],
    [0.5490196078431373, '#385500'],
    [0.5529411764705883, '#3a5600'],
    [0.5568627450980392, '#3c5600'],
    [0.5607843137254902, '#3e5700'],
    [0.5647058823529412, '#405700'],
    [0.5686274509803921, '#425800'],
    [0.5725490196078431, '#445900'],
    [0.5764705882352941, '#465900'],
    [0.580392156862745, '#495a01'],
    [0.5843137254901961, '#4b5b01'],
    [0.5882352941176471, '#4d5c01'],
    [0.592156862745098, '#4f5c02'],
    [0.596078431372549, '#515d02'],
    [0.6, '#535e02'],
    [0.6039215686274509, '#555f03'],
    [0.6078431372549019, '#576004'],
    [0.611764705882353, '#5a6005'],
    [0.615686274509804, '#5c6106'],
    [0.6196078431372549, '#5e6207'],
    [0.6235294117647059, '#606309'],
    [0.6274509803921569, '#63640a'],
    [0.6313725490196078, '#65660c'],
    [0.6352941176470588, '#67670e'],
    [0.6392156862745098, '#6a6810'],
    [0.6431372549019607, '#6c6912'],
    [0.6470588235294118, '#6e6a14'],
    [0.6509803921568628, '#716c16'],
    [0.6549019607843137, '#736d18'],
    [0.6588235294117647, '#756e1a'],
    [0.6627450980392157, '#78701d'],
    [0.6666666666666666, '#7a711f'],
    [0.6705882352941176, '#7c7221'],
    [0.6745098039215687, '#7e7423'],
    [0.6784313725490196, '#817525'],
    [0.6823529411764706, '#837628'],
    [0.6862745098039216, '#85782a'],
    [0.6901960784313725, '#87792c'],
    [0.6941176470588235, '#8a7b2e'],
    [0.6980392156862745, '#8c7c31'],
    [0.7019607843137254, '#8e7d33'],
    [0.7058823529411764, '#907f35'],
    [0.7098039215686275, '#928037'],
    [0.7137254901960784, '#94823a'],
    [0.7176470588235294, '#97833c'],
    [0.7215686274509804, '#99843e'],
    [0.7254901960784313, '#9b8640'],
    [0.7294117647058823, '#9d8743'],
    [0.7333333333333333, '#9f8945'],
    [0.7372549019607844, '#a18a47'],
    [0.7411764705882353, '#a38c49'],
    [0.7450980392156863, '#a68d4c'],
    [0.7490196078431373, '#a88f4e'],
    [0.7529411764705882, '#aa9050'],
    [0.7568627450980392, '#ac9253'],
    [0.7607843137254902, '#ae9355'],
    [0.7647058823529411, '#b19557'],
    [0.7686274509803921, '#b39759'],
    [0.7725490196078432, '#b5985c'],
    [0.7764705882352941, '#b79a5e'],
    [0.7803921568627451, '#ba9c60'],
    [0.7843137254901961, '#bc9d63'],
    [0.788235294117647, '#be9f65'],
    [0.792156862745098, '#c1a167'],
    [0.796078431372549, '#c3a36a'],
    [0.8, '#c5a46c'],
    [0.803921568627451, '#c7a66e'],
    [0.807843137254902, '#caa871'],
    [0.8117647058823529, '#ccaa73'],
    [0.8156862745098039, '#ceac75'],
    [0.8196078431372549, '#d1ad78'],
    [0.8235294117647058, '#d3af7a'],
    [0.8274509803921568, '#d5b17c'],
    [0.8313725490196078, '#d7b37f'],
    [0.8352941176470589, '#d9b581'],
    [0.8392156862745098, '#dcb684'],
    [0.8431372549019608, '#deb886'],
    [0.8470588235294118, '#e0ba89'],
    [0.8509803921568627, '#e2bc8b'],
    [0.8549019607843137, '#e4be8e'],
    [0.8588235294117647, '#e5c090'],
    [0.8627450980392157, '#e7c293'],
    [0.8666666666666667, '#e9c495'],
    [0.8705882352941177, '#eac598'],
    [0.8745098039215686, '#ecc79a'],
    [0.8784313725490196, '#edc99d'],
    [0.8823529411764706, '#eecb9f'],
    [0.8862745098039215, '#f0cda2'],
    [0.8901960784313725, '#f1cea4'],
    [0.8941176470588235, '#f2d0a7'],
    [0.8980392156862745, '#f2d2a9'],
    [0.9019607843137255, '#f3d4ab'],
    [0.9058823529411765, '#f4d5ae'],
    [0.9098039215686274, '#f5d7b0'],
    [0.9137254901960784, '#f5d9b2'],
    [0.9176470588235294, '#f6dab5'],
    [0.9215686274509803, '#f6dcb7'],
    [0.9254901960784314, '#f7deb9'],
    [0.9294117647058824, '#f7dfbc'],
    [0.9333333333333333, '#f7e1be'],
    [0.9372549019607843, '#f8e2c0'],
    [0.9411764705882353, '#f8e4c3'],
    [0.9450980392156862, '#f8e6c5'],
    [0.9490196078431372, '#f9e7c7'],
    [0.9529411764705882, '#f9e9c9'],
    [0.9568627450980391, '#f9eacc'],
    [0.9607843137254902, '#faecce'],
    [0.9647058823529412, '#faeed0'],
    [0.9686274509803922, '#faefd3'],
    [0.9725490196078431, '#fbf1d5'],
    [0.9764705882352941, '#fbf3d7'],
    [0.9803921568627451, '#fbf4da'],
    [0.984313725490196, '#fbf6dc'],
    [0.9882352941176471, '#fcf8de'],
    [0.9921568627450981, '#fcf9e1'],
    [0.996078431372549, '#fcfbe3'],
    [1.0, '#fdfde6'],
  ],
  vanimo: [
    [0.0, '#ffcdfd'],
    [0.00392156862745098, '#fdcafb'],
    [0.00784313725490196, '#fcc7f9'],
    [0.011764705882352941, '#fac4f7'],
    [0.01568627450980392, '#f9c1f4'],
    [0.0196078431372549, '#f7bef2'],
    [0.023529411764705882, '#f6bbf0'],
    [0.027450980392156862, '#f4b9ee'],
    [0.03137254901960784, '#f3b6ec'],
    [0.03529411764705882, '#f1b3ea'],
    [0.0392156862745098, '#f0b0e8'],
    [0.043137254901960784, '#eeade6'],
    [0.047058823529411764, '#ecabe4'],
    [0.050980392156862744, '#eba8e2'],
    [0.054901960784313725, '#e9a5e0'],
    [0.058823529411764705, '#e8a2de'],
    [0.06274509803921569, '#e6a0dc'],
    [0.06666666666666667, '#e59dda'],
    [0.07058823529411765, '#e39ad8'],
    [0.07450980392156863, '#e298d6'],
    [0.0784313725490196, '#e095d4'],
    [0.08235294117647059, '#de93d2'],
    [0.08627450980392157, '#dd90d0'],
    [0.09019607843137255, '#db8ece'],
    [0.09411764705882353, '#da8bcc'],
    [0.09803921568627451, '#d889ca'],
    [0.10196078431372549, '#d786c8'],
    [0.10588235294117647, '#d584c6'],
    [0.10980392156862745, '#d381c4'],
    [0.11372549019607843, '#d27fc2'],
    [0.11764705882352941, '#d07dc0'],
    [0.12156862745098039, '#cf7abf'],
    [0.12549019607843137, '#cd78bd'],
    [0.12941176470588234, '#cb76bb'],
    [0.13333333333333333, '#ca73b9'],
    [0.13725490196078433, '#c871b7'],
    [0.1411764705882353, '#c76fb5'],
    [0.14509803921568626, '#c56db3'],
    [0.14901960784313725, '#c36bb2'],
    [0.15294117647058825, '#c269b0'],
    [0.1568627450980392, '#c067ae'],
    [0.16078431372549018, '#be65ac'],
    [0.16470588235294117, '#bd63aa'],
    [0.16862745098039217, '#bb61a8'],
    [0.17254901960784313, '#b95fa7'],
    [0.1764705882352941, '#b85da5'],
    [0.1803921568627451, '#b65ba3'],
    [0.1843137254901961, '#b45aa1'],
    [0.18823529411764706, '#b2589f'],
    [0.19215686274509802, '#b1569e'],
    [0.19607843137254902, '#af549c'],
    [0.2, '#ad539a'],
    [0.20392156862745098, '#ab5198'],
    [0.20784313725490194, '#a95096'],
    [0.21176470588235294, '#a74e94'],
    [0.21568627450980393, '#a54c92'],
    [0.2196078431372549, '#a34b90'],
    [0.22352941176470587, '#a1498e'],
    [0.22745098039215686, '#9f488c'],
    [0.23137254901960785, '#9d468a'],
    [0.23529411764705882, '#9b4488'],
    [0.2392156862745098, '#994386'],
    [0.24313725490196078, '#964184'],
    [0.24705882352941178, '#944082'],
    [0.25098039215686274, '#923e80'],
    [0.2549019607843137, '#903d7e'],
    [0.2588235294117647, '#8d3c7b'],
    [0.2627450980392157, '#8b3a79'],
    [0.26666666666666666, '#883977'],
    [0.27058823529411763, '#863775'],
    [0.27450980392156865, '#833672'],
    [0.2784313725490196, '#813570'],
    [0.2823529411764706, '#7e336e'],
    [0.28627450980392155, '#7c326b'],
    [0.2901960784313725, '#793169'],
    [0.29411764705882354, '#763067'],
    [0.2980392156862745, '#742e64'],
    [0.30196078431372547, '#712d62'],
    [0.3058823529411765, '#6e2c60'],
    [0.30980392156862746, '#6c2b5d'],
    [0.3137254901960784, '#692a5b'],
    [0.3176470588235294, '#662959'],
    [0.32156862745098036, '#642756'],
    [0.3254901960784314, '#612654'],
    [0.32941176470588235, '#5e2551'],
    [0.3333333333333333, '#5c244f'],
    [0.33725490196078434, '#59234d'],
    [0.3411764705882353, '#56224a'],
    [0.34509803921568627, '#542148'],
    [0.34901960784313724, '#512146'],
    [0.3529411764705882, '#4f2044'],
    [0.3568627450980392, '#4c1f41'],
    [0.3607843137254902, '#491e3f'],
    [0.36470588235294116, '#471d3d'],
    [0.3686274509803922, '#451c3b'],
    [0.37254901960784315, '#421c39'],
    [0.3764705882352941, '#401b37'],
    [0.3803921568627451, '#3e1a35'],
    [0.38431372549019605, '#3b1a33'],
    [0.38823529411764707, '#391931'],
    [0.39215686274509803, '#37192f'],
    [0.396078431372549, '#35182d'],
    [0.4, '#33172c'],
    [0.403921568627451, '#31172a'],
    [0.40784313725490196, '#2f1728'],
    [0.4117647058823529, '#2e1627'],
    [0.4156862745098039, '#2c1625'],
    [0.4196078431372549, '#2a1524'],
    [0.4235294117647059, '#291523'],
    [0.42745098039215684, '#281521'],
    [0.43137254901960786, '#261520'],
    [0.43529411764705883, '#25141f'],
    [0.4392156862745098, '#24141e'],
    [0.44313725490196076, '#23141d'],
    [0.44705882352941173, '#22141c'],
    [0.45098039215686275, '#21141b'],
    [0.4549019607843137, '#20141a'],
    [0.4588235294117647, '#1f1419'],
    [0.4627450980392157, '#1e1418'],
    [0.4666666666666667, '#1d1418'],
    [0.47058823529411764, '#1d1417'],
    [0.4745098039215686, '#1c1416'],
    [0.4784313725490196, '#1c1416'],
    [0.4823529411764706, '#1b1415'],
    [0.48627450980392156, '#1b1415'],
    [0.49019607843137253, '#1a1414'],
    [0.49411764705882355, '#1a1514'],
    [0.4980392156862745, '#1a1513'],
    [0.5019607843137255, '#1a1513'],
    [0.5058823529411764, '#191613'],
    [0.5098039215686274, '#191612'],
    [0.5137254901960784, '#191612'],
    [0.5176470588235293, '#191712'],
    [0.5215686274509804, '#191712'],
    [0.5254901960784314, '#191811'],
    [0.5294117647058824, '#191811'],
    [0.5333333333333333, '#1a1911'],
    [0.5372549019607843, '#1a1a11'],
    [0.5411764705882353, '#1a1a11'],
    [0.5450980392156862, '#1a1b11'],
    [0.5490196078431373, '#1b1c11'],
    [0.5529411764705883, '#1b1d11'],
    [0.5568627450980392, '#1b1e11'],
    [0.5607843137254902, '#1c1f11'],
    [0.5647058823529412, '#1c2011'],
    [0.5686274509803921, '#1d2112'],
    [0.5725490196078431, '#1d2212'],
    [0.5764705882352941, '#1e2412'],
    [0.580392156862745, '#1f2512'],
    [0.5843137254901961, '#1f2612'],
    [0.5882352941176471, '#202713'],
    [0.592156862745098, '#212913'],
    [0.596078431372549, '#222a13'],
    [0.6, '#232c14'],
    [0.6039215686274509, '#232d14'],
    [0.6078431372549019, '#242f14'],
    [0.611764705882353, '#253014'],
    [0.615686274509804, '#263215'],
    [0.6196078431372549, '#273415'],
    [0.6235294117647059, '#293516'],
    [0.6274509803921569, '#2a3716'],
    [0.6313725490196078, '#2b3916'],
    [0.6352941176470588, '#2c3b17'],
    [0.6392156862745098, '#2d3c17'],
    [0.6431372549019607, '#2e3e18'],
    [0.6470588235294118, '#2f4018'],
    [0.6509803921568628, '#314218'],
    [0.6549019607843137, '#324419'],
    [0.6588235294117647, '#334619'],
    [0.6627450980392157, '#34471a'],
    [0.6666666666666666, '#36491a'],
    [0.6705882352941176, '#374b1b'],
    [0.6745098039215687, '#384d1b'],
    [0.6784313725490196, '#3a4f1c'],
    [0.6823529411764706, '#3b511c'],
    [0.6862745098039216, '#3c531d'],
    [0.6901960784313725, '#3d551d'],
    [0.6941176470588235, '#3f561e'],
    [0.6980392156862745, '#40581f'],
    [0.7019607843137254, '#415a1f'],
    [0.7058823529411764, '#435c20'],
    [0.7098039215686275, '#445e20'],
    [0.7137254901960784, '#456021'],
    [0.7176470588235294, '#476221'],
    [0.7215686274509804, '#486322'],
    [0.7254901960784313, '#496523'],
    [0.7294117647058823, '#4b6723'],
    [0.7333333333333333, '#4c6924'],
    [0.7372549019607844, '#4d6b24'],
    [0.7411764705882353, '#4e6c25'],
    [0.7450980392156863, '#506e25'],
    [0.7490196078431373, '#517026'],
    [0.7529411764705882, '#527227'],
    [0.7568627450980392, '#547427'],
    [0.7607843137254902, '#557528'],
    [0.7647058823529411, '#567729'],
    [0.7686274509803921, '#577929'],
    [0.7725490196078432, '#597b2a'],
    [0.7764705882352941, '#5a7c2a'],
    [0.7803921568627451, '#5b7e2b'],
    [0.7843137254901961, '#5c802c'],
    [0.788235294117647, '#5e812d'],
    [0.792156862745098, '#5f832d'],
    [0.796078431372549, '#60852e'],
    [0.8, '#62872f'],
    [0.803921568627451, '#63882f'],
    [0.807843137254902, '#648a30'],
    [0.8117647058823529, '#658c31'],
    [0.8156862745098039, '#678e32'],
    [0.8196078431372549, '#689033'],
    [0.8235294117647058, '#6a9234'],
    [0.8274509803921568, '#6b9335'],
    [0.8313725490196078, '#6c9536'],
    [0.8352941176470589, '#6e9737'],
    [0.8392156862745098, '#6f9938'],
    [0.8431372549019608, '#719b39'],
    [0.8470588235294118, '#729d3b'],
    [0.8509803921568627, '#749f3c'],
    [0.8549019607843137, '#75a13d'],
    [0.8588235294117647, '#77a33f'],
    [0.8627450980392157, '#79a640'],
    [0.8666666666666667, '#7aa842'],
    [0.8705882352941177, '#7caa43'],
    [0.8745098039215686, '#7eac45'],
    [0.8784313725490196, '#7fae47'],
    [0.8823529411764706, '#81b049'],
    [0.8862745098039215, '#83b34b'],
    [0.8901960784313725, '#85b54d'],
    [0.8941176470588235, '#87b74f'],
    [0.8980392156862745, '#88ba51'],
    [0.9019607843137255, '#8abc53'],
    [0.9058823529411765, '#8cbe55'],
    [0.9098039215686274, '#8ec158'],
    [0.9137254901960784, '#90c35a'],
    [0.9176470588235294, '#92c65d'],
    [0.9215686274509803, '#94c860'],
    [0.9254901960784314, '#96cb62'],
    [0.9294117647058824, '#98cd65'],
    [0.9333333333333333, '#9ad068'],
    [0.9372549019607843, '#9cd26b'],
    [0.9411764705882353, '#9ed56e'],
    [0.9450980392156862, '#a0d772'],
    [0.9490196078431372, '#a2da75'],
    [0.9529411764705882, '#a4dd78'],
    [0.9568627450980391, '#a6df7c'],
    [0.9607843137254902, '#a9e27f'],
    [0.9647058823529412, '#abe483'],
    [0.9686274509803922, '#ade786'],
    [0.9725490196078431, '#afea8a'],
    [0.9764705882352941, '#b1ec8e'],
    [0.9803921568627451, '#b3ef92'],
    [0.984313725490196, '#b5f295'],
    [0.9882352941176471, '#b7f599'],
    [0.9921568627450981, '#baf79d'],
    [0.996078431372549, '#bcfaa1'],
    [1.0, '#befda5'],
  ],
  lajolla: [
    [0.0, '#ffffcc'],
    [0.00392156862745098, '#fffeca'],
    [0.00784313725490196, '#fffec8'],
    [0.011764705882352941, '#fffdc7'],
    [0.01568627450980392, '#fffcc5'],
    [0.0196078431372549, '#fffcc3'],
    [0.023529411764705882, '#fefbc1'],
    [0.027450980392156862, '#fefac0'],
    [0.03137254901960784, '#fefabe'],
    [0.03529411764705882, '#fef9bc'],
    [0.0392156862745098, '#fef9ba'],
    [0.043137254901960784, '#fef8b8'],
    [0.047058823529411764, '#fef7b7'],
    [0.050980392156862744, '#fef7b5'],
    [0.054901960784313725, '#fef6b3'],
    [0.058823529411764705, '#fdf5b1'],
    [0.06274509803921569, '#fdf5af'],
    [0.06666666666666667, '#fdf4ae'],
    [0.07058823529411765, '#fdf3ac'],
    [0.07450980392156863, '#fdf3aa'],
    [0.0784313725490196, '#fdf2a8'],
    [0.08235294117647059, '#fdf1a6'],
    [0.08627450980392157, '#fcf0a5'],
    [0.09019607843137255, '#fcf0a3'],
    [0.09411764705882353, '#fcefa1'],
    [0.09803921568627451, '#fcee9f'],
    [0.10196078431372549, '#fcee9d'],
    [0.10588235294117647, '#fced9c'],
    [0.10980392156862745, '#fbec9a'],
    [0.11372549019607843, '#fbeb98'],
    [0.11764705882352941, '#fbea96'],
    [0.12156862745098039, '#fbea94'],
    [0.12549019607843137, '#fbe992'],
    [0.12941176470588234, '#fae890'],
    [0.13333333333333333, '#fae78f'],
    [0.13725490196078433, '#fae68d'],
    [0.1411764705882353, '#fae58b'],
    [0.14509803921568626, '#fae489'],
    [0.14901960784313725, '#f9e387'],
    [0.15294117647058825, '#f9e285'],
    [0.1568627450980392, '#f9e184'],
    [0.16078431372549018, '#f9e082'],
    [0.16470588235294117, '#f8df80'],
    [0.16862745098039217, '#f8de7e'],
    [0.17254901960784313, '#f8dd7c'],
    [0.1764705882352941, '#f8dc7b'],
    [0.1803921568627451, '#f7da79'],
    [0.1843137254901961, '#f7d977'],
    [0.18823529411764706, '#f7d875'],
    [0.19215686274509802, '#f6d774'],
    [0.19607843137254902, '#f6d672'],
    [0.2, '#f6d470'],
    [0.20392156862745098, '#f6d36f'],
    [0.20784313725490194, '#f5d26d'],
    [0.21176470588235294, '#f5d06c'],
    [0.21568627450980393, '#f5cf6a'],
    [0.2196078431372549, '#f4ce69'],
    [0.22352941176470587, '#f4cc68'],
    [0.22745098039215686, '#f4cb66'],
    [0.23137254901960785, '#f3ca65'],
    [0.23529411764705882, '#f3c864'],
    [0.2392156862745098, '#f3c763'],
    [0.24313725490196078, '#f2c562'],
    [0.24705882352941178, '#f2c461'],
    [0.25098039215686274, '#f2c360'],
    [0.2549019607843137, '#f1c15f'],
    [0.2588235294117647, '#f1c05e'],
    [0.2627450980392157, '#f1bf5d'],
    [0.26666666666666666, '#f1bd5c'],
    [0.27058823529411763, '#f0bc5b'],
    [0.27450980392156865, '#f0bb5b'],
    [0.2784313725490196, '#f0b95a'],
    [0.2823529411764706, '#efb859'],
    [0.28627450980392155, '#efb759'],
    [0.2901960784313725, '#efb558'],
    [0.29411764705882354, '#eeb458'],
    [0.2980392156862745, '#eeb357'],
    [0.30196078431372547, '#eeb257'],
    [0.3058823529411765, '#eeb057'],
    [0.30980392156862746, '#edaf56'],
    [0.3137254901960784, '#edae56'],
    [0.3176470588235294, '#edad56'],
    [0.32156862745098036, '#edab55'],
    [0.3254901960784314, '#ecaa55'],
    [0.32941176470588235, '#eca955'],
    [0.3333333333333333, '#eca855'],
    [0.33725490196078434, '#eca754'],
    [0.3411764705882353, '#eba554'],
    [0.34509803921568627, '#eba454'],
    [0.34901960784313724, '#eba354'],
    [0.3529411764705882, '#eba254'],
    [0.3568627450980392, '#eaa153'],
    [0.3607843137254902, '#ea9f53'],
    [0.36470588235294116, '#ea9e53'],
    [0.3686274509803922, '#ea9d53'],
    [0.37254901960784315, '#e99c53'],
    [0.3764705882352941, '#e99b53'],
    [0.3803921568627451, '#e99a53'],
    [0.38431372549019605, '#e99852'],
    [0.38823529411764707, '#e89752'],
    [0.39215686274509803, '#e89652'],
    [0.396078431372549, '#e89552'],
    [0.4, '#e79452'],
    [0.403921568627451, '#e79352'],
    [0.40784313725490196, '#e79152'],
    [0.4117647058823529, '#e79052'],
    [0.4156862745098039, '#e68f52'],
    [0.4196078431372549, '#e68e52'],
    [0.4235294117647059, '#e68d51'],
    [0.42745098039215684, '#e68c51'],
    [0.43137254901960786, '#e58a51'],
    [0.43529411764705883, '#e58951'],
    [0.4392156862745098, '#e58851'],
    [0.44313725490196076, '#e48751'],
    [0.44705882352941173, '#e48651'],
    [0.45098039215686275, '#e48451'],
    [0.4549019607843137, '#e38351'],
    [0.4588235294117647, '#e38251'],
    [0.4627450980392157, '#e38150'],
    [0.4666666666666667, '#e28050'],
    [0.47058823529411764, '#e27e50'],
    [0.4745098039215686, '#e17d50'],
    [0.4784313725490196, '#e17c50'],
    [0.4823529411764706, '#e07b50'],
    [0.48627450980392156, '#e07950'],
    [0.49019607843137253, '#df7850'],
    [0.49411764705882355, '#df774f'],
    [0.4980392156862745, '#de764f'],
    [0.5019607843137255, '#de744f'],
    [0.5058823529411764, '#dd734f'],
    [0.5098039215686274, '#dd724f'],
    [0.5137254901960784, '#dc704f'],
    [0.5176470588235293, '#db6f4f'],
    [0.5215686274509804, '#da6e4e'],
    [0.5254901960784314, '#da6c4e'],
    [0.5294117647058824, '#d96b4e'],
    [0.5333333333333333, '#d86a4e'],
    [0.5372549019607843, '#d7694e'],
    [0.5411764705882353, '#d6674e'],
    [0.5450980392156862, '#d5664d'],
    [0.5490196078431373, '#d4654d'],
    [0.5529411764705883, '#d3634d'],
    [0.5568627450980392, '#d2624d'],
    [0.5607843137254902, '#d0614c'],
    [0.5647058823529412, '#cf5f4c'],
    [0.5686274509803921, '#ce5e4c'],
    [0.5725490196078431, '#cd5d4c'],
    [0.5764705882352941, '#cb5c4b'],
    [0.580392156862745, '#ca5b4b'],
    [0.5843137254901961, '#c8594b'],
    [0.5882352941176471, '#c7584b'],
    [0.592156862745098, '#c5574a'],
    [0.596078431372549, '#c4564a'],
    [0.6, '#c2554a'],
    [0.6039215686274509, '#c15449'],
    [0.6078431372549019, '#bf5349'],
    [0.611764705882353, '#bd5248'],
    [0.615686274509804, '#bc5148'],
    [0.6196078431372549, '#ba5048'],
    [0.6235294117647059, '#b84f47'],
    [0.6274509803921569, '#b64e47'],
    [0.6313725490196078, '#b54d46'],
    [0.6352941176470588, '#b34d46'],
    [0.6392156862745098, '#b14c46'],
    [0.6431372549019607, '#af4b45'],
    [0.6470588235294118, '#ae4a45'],
    [0.6509803921568628, '#ac4a44'],
    [0.6549019607843137, '#aa4944'],
    [0.6588235294117647, '#a84843'],
    [0.6627450980392157, '#a64743'],
    [0.6666666666666666, '#a54742'],
    [0.6705882352941176, '#a34642'],
    [0.6745098039215687, '#a14641'],
    [0.6784313725490196, '#9f4541'],
    [0.6823529411764706, '#9d4440'],
    [0.6862745098039216, '#9c443f'],
    [0.6901960784313725, '#9a433f'],
    [0.6941176470588235, '#98433e'],
    [0.6980392156862745, '#96423e'],
    [0.7019607843137254, '#94423d'],
    [0.7058823529411764, '#93413c'],
    [0.7098039215686275, '#91413c'],
    [0.7137254901960784, '#8f403b'],
    [0.7176470588235294, '#8d403a'],
    [0.7215686274509804, '#8b3f3a'],
    [0.7254901960784313, '#8a3f39'],
    [0.7294117647058823, '#883e38'],
    [0.7333333333333333, '#863e37'],
    [0.7372549019607844, '#843d37'],
    [0.7411764705882353, '#833d36'],
    [0.7450980392156863, '#813c35'],
    [0.7490196078431373, '#7f3b34'],
    [0.7529411764705882, '#7d3b34'],
    [0.7568627450980392, '#7b3a33'],
    [0.7607843137254902, '#7a3a32'],
    [0.7647058823529411, '#783931'],
    [0.7686274509803921, '#763931'],
    [0.7725490196078432, '#743830'],
    [0.7764705882352941, '#73382f'],
    [0.7803921568627451, '#71372e'],
    [0.7843137254901961, '#6f372d'],
    [0.788235294117647, '#6d362d'],
    [0.792156862745098, '#6c362c'],
    [0.796078431372549, '#6a352b'],
    [0.8, '#68352a'],
    [0.803921568627451, '#673429'],
    [0.807843137254902, '#653328'],
    [0.8117647058823529, '#633328'],
    [0.8156862745098039, '#613227'],
    [0.8196078431372549, '#603226'],
    [0.8235294117647058, '#5e3125'],
    [0.8274509803921568, '#5c3124'],
    [0.8313725490196078, '#5b3024'],
    [0.8352941176470589, '#593023'],
    [0.8392156862745098, '#572f22'],
    [0.8431372549019608, '#562f21'],
    [0.8470588235294118, '#542e20'],
    [0.8509803921568627, '#522e20'],
    [0.8549019607843137, '#512d1f'],
    [0.8588235294117647, '#4f2c1e'],
    [0.8627450980392157, '#4e2c1d'],
    [0.8666666666666667, '#4c2b1d'],
    [0.8705882352941177, '#4a2b1c'],
    [0.8745098039215686, '#492a1b'],
    [0.8784313725490196, '#472a1a'],
    [0.8823529411764706, '#45291a'],
    [0.8862745098039215, '#442919'],
    [0.8901960784313725, '#422818'],
    [0.8941176470588235, '#412817'],
    [0.8980392156862745, '#3f2717'],
    [0.9019607843137255, '#3e2716'],
    [0.9058823529411765, '#3c2615'],
    [0.9098039215686274, '#3a2615'],
    [0.9137254901960784, '#392514'],
    [0.9176470588235294, '#372513'],
    [0.9215686274509803, '#362413'],
    [0.9254901960784314, '#342412'],
    [0.9294117647058824, '#332311'],
    [0.9333333333333333, '#312311'],
    [0.9372549019607843, '#302210'],
    [0.9411764705882353, '#2e220f'],
    [0.9450980392156862, '#2d210e'],
    [0.9490196078431372, '#2b210d'],
    [0.9529411764705882, '#2a200d'],
    [0.9568627450980391, '#291f0c'],
    [0.9607843137254902, '#271f0b'],
    [0.9647058823529412, '#261e0a'],
    [0.9686274509803922, '#241e09'],
    [0.9725490196078431, '#231d08'],
    [0.9764705882352941, '#221d07'],
    [0.9803921568627451, '#201c06'],
    [0.984313725490196, '#1f1c05'],
    [0.9882352941176471, '#1e1b04'],
    [0.9921568627450981, '#1c1b03'],
    [0.996078431372549, '#1b1a02'],
    [1.0, '#1a1a01'],
  ],
  buda: [
    [0.0, '#b301b3'],
    [0.00392156862745098, '#b303b2'],
    [0.00784313725490196, '#b305b1'],
    [0.011764705882352941, '#b307b0'],
    [0.01568627450980392, '#b309af'],
    [0.0196078431372549, '#b30bae'],
    [0.023529411764705882, '#b30dae'],
    [0.027450980392156862, '#b30fad'],
    [0.03137254901960784, '#b311ac'],
    [0.03529411764705882, '#b312ab'],
    [0.0392156862745098, '#b314aa'],
    [0.043137254901960784, '#b316a9'],
    [0.047058823529411764, '#b317a9'],
    [0.050980392156862744, '#b318a8'],
    [0.054901960784313725, '#b31aa7'],
    [0.058823529411764705, '#b31ba6'],
    [0.06274509803921569, '#b31ca6'],
    [0.06666666666666667, '#b31ea5'],
    [0.07058823529411765, '#b31fa4'],
    [0.07450980392156863, '#b320a4'],
    [0.0784313725490196, '#b321a3'],
    [0.08235294117647059, '#b323a2'],
    [0.08627450980392157, '#b324a2'],
    [0.09019607843137255, '#b325a1'],
    [0.09411764705882353, '#b326a0'],
    [0.09803921568627451, '#b327a0'],
    [0.10196078431372549, '#b3289f'],
    [0.10588235294117647, '#b3299f'],
    [0.10980392156862745, '#b32b9e'],
    [0.11372549019607843, '#b32c9e'],
    [0.11764705882352941, '#b32d9d'],
    [0.12156862745098039, '#b32e9d'],
    [0.12549019607843137, '#b32f9c'],
    [0.12941176470588234, '#b3309c'],
    [0.13333333333333333, '#b4319b'],
    [0.13725490196078433, '#b4329b'],
    [0.1411764705882353, '#b4339a'],
    [0.14509803921568626, '#b4349a'],
    [0.14901960784313725, '#b43599'],
    [0.15294117647058825, '#b43699'],
    [0.1568627450980392, '#b53798'],
    [0.16078431372549018, '#b53898'],
    [0.16470588235294117, '#b53998'],
    [0.16862745098039217, '#b53a97'],
    [0.17254901960784313, '#b63b97'],
    [0.1764705882352941, '#b63c96'],
    [0.1803921568627451, '#b63d96'],
    [0.1843137254901961, '#b63e96'],
    [0.18823529411764706, '#b73f95'],
    [0.19215686274509802, '#b74095'],
    [0.19607843137254902, '#b74195'],
    [0.2, '#b84294'],
    [0.20392156862745098, '#b84394'],
    [0.20784313725490194, '#b84494'],
    [0.21176470588235294, '#b84593'],
    [0.21568627450980393, '#b94693'],
    [0.2196078431372549, '#b94793'],
    [0.22352941176470587, '#b94892'],
    [0.22745098039215686, '#ba4992'],
    [0.23137254901960785, '#ba4a92'],
    [0.23529411764705882, '#ba4b91'],
    [0.2392156862745098, '#bb4b91'],
    [0.24313725490196078, '#bb4c91'],
    [0.24705882352941178, '#bb4d90'],
    [0.25098039215686274, '#bc4e90'],
    [0.2549019607843137, '#bc4f90'],
    [0.2588235294117647, '#bc508f'],
    [0.2627450980392157, '#bc518f'],
    [0.26666666666666666, '#bd528f'],
    [0.27058823529411763, '#bd538f'],
    [0.27450980392156865, '#bd548e'],
    [0.2784313725490196, '#be558e'],
    [0.2823529411764706, '#be558e'],
    [0.28627450980392155, '#be568d'],
    [0.2901960784313725, '#bf578d'],
    [0.29411764705882354, '#bf588d'],
    [0.2980392156862745, '#bf598c'],
    [0.30196078431372547, '#bf5a8c'],
    [0.3058823529411765, '#c05b8c'],
    [0.30980392156862746, '#c05c8c'],
    [0.3137254901960784, '#c05c8b'],
    [0.3176470588235294, '#c15d8b'],
    [0.32156862745098036, '#c15e8b'],
    [0.3254901960784314, '#c15f8a'],
    [0.32941176470588235, '#c2608a'],
    [0.3333333333333333, '#c2618a'],
    [0.33725490196078434, '#c2628a'],
    [0.3411764705882353, '#c26389'],
    [0.34509803921568627, '#c36389'],
    [0.34901960784313724, '#c36489'],
    [0.3529411764705882, '#c36588'],
    [0.3568627450980392, '#c46688'],
    [0.3607843137254902, '#c46788'],
    [0.36470588235294116, '#c46888'],
    [0.3686274509803922, '#c46987'],
    [0.37254901960784315, '#c56987'],
    [0.3764705882352941, '#c56a87'],
    [0.3803921568627451, '#c56b86'],
    [0.38431372549019605, '#c66c86'],
    [0.38823529411764707, '#c66d86'],
    [0.39215686274509803, '#c66e86'],
    [0.396078431372549, '#c66e85'],
    [0.4, '#c76f85'],
    [0.403921568627451, '#c77085'],
    [0.40784313725490196, '#c77184'],
    [0.4117647058823529, '#c77284'],
    [0.4156862745098039, '#c87384'],
    [0.4196078431372549, '#c87384'],
    [0.4235294117647059, '#c87483'],
    [0.42745098039215684, '#c97583'],
    [0.43137254901960786, '#c97683'],
    [0.43529411764705883, '#c97783'],
    [0.4392156862745098, '#c97882'],
    [0.44313725490196076, '#ca7982'],
    [0.44705882352941173, '#ca7982'],
    [0.45098039215686275, '#ca7a81'],
    [0.4549019607843137, '#ca7b81'],
    [0.4588235294117647, '#cb7c81'],
    [0.4627450980392157, '#cb7d81'],
    [0.4666666666666667, '#cb7e80'],
    [0.47058823529411764, '#cb7e80'],
    [0.4745098039215686, '#cc7f80'],
    [0.4784313725490196, '#cc8080'],
    [0.4823529411764706, '#cc817f'],
    [0.48627450980392156, '#cc827f'],
    [0.49019607843137253, '#cd837f'],
    [0.49411764705882355, '#cd847f'],
    [0.4980392156862745, '#cd847e'],
    [0.5019607843137255, '#cd857e'],
    [0.5058823529411764, '#ce867e'],
    [0.5098039215686274, '#ce877e'],
    [0.5137254901960784, '#ce887e'],
    [0.5176470588235293, '#ce897d'],
    [0.5215686274509804, '#cf8a7d'],
    [0.5254901960784314, '#cf8a7d'],
    [0.5294117647058824, '#cf8b7d'],
    [0.5333333333333333, '#cf8c7c'],
    [0.5372549019607843, '#cf8d7c'],
    [0.5411764705882353, '#d08e7c'],
    [0.5450980392156862, '#d08f7c'],
    [0.5490196078431373, '#d0907c'],
    [0.5529411764705883, '#d0917b'],
    [0.5568627450980392, '#d1917b'],
    [0.5607843137254902, '#d1927b'],
    [0.5647058823529412, '#d1937b'],
    [0.5686274509803921, '#d1947a'],
    [0.5725490196078431, '#d1957a'],
    [0.5764705882352941, '#d2967a'],
    [0.580392156862745, '#d2977a'],
    [0.5843137254901961, '#d2977a'],
    [0.5882352941176471, '#d29879'],
    [0.592156862745098, '#d29979'],
    [0.596078431372549, '#d39a79'],
    [0.6, '#d39b79'],
    [0.6039215686274509, '#d39c78'],
    [0.6078431372549019, '#d39d78'],
    [0.611764705882353, '#d49e78'],
    [0.615686274509804, '#d49e78'],
    [0.6196078431372549, '#d49f78'],
    [0.6235294117647059, '#d4a077'],
    [0.6274509803921569, '#d4a177'],
    [0.6313725490196078, '#d5a277'],
    [0.6352941176470588, '#d5a377'],
    [0.6392156862745098, '#d5a477'],
    [0.6431372549019607, '#d5a576'],
    [0.6470588235294118, '#d6a576'],
    [0.6509803921568628, '#d6a676'],
    [0.6549019607843137, '#d6a776'],
    [0.6588235294117647, '#d6a875'],
    [0.6627450980392157, '#d6a975'],
    [0.6666666666666666, '#d7aa75'],
    [0.6705882352941176, '#d7ab75'],
    [0.6745098039215687, '#d7ac75'],
    [0.6784313725490196, '#d7ad74'],
    [0.6823529411764706, '#d8ad74'],
    [0.6862745098039216, '#d8ae74'],
    [0.6901960784313725, '#d8af74'],
    [0.6941176470588235, '#d8b073'],
    [0.6980392156862745, '#d8b173'],
    [0.7019607843137254, '#d9b273'],
    [0.7058823529411764, '#d9b373'],
    [0.7098039215686275, '#d9b473'],
    [0.7137254901960784, '#d9b572'],
    [0.7176470588235294, '#dab572'],
    [0.7215686274509804, '#dab672'],
    [0.7254901960784313, '#dab772'],
    [0.7294117647058823, '#dab871'],
    [0.7333333333333333, '#dab971'],
    [0.7372549019607844, '#dbba71'],
    [0.7411764705882353, '#dbbb71'],
    [0.7450980392156863, '#dbbc71'],
    [0.7490196078431373, '#dbbd70'],
    [0.7529411764705882, '#dcbe70'],
    [0.7568627450980392, '#dcbf70'],
    [0.7607843137254902, '#dcbf70'],
    [0.7647058823529411, '#dcc06f'],
    [0.7686274509803921, '#ddc16f'],
    [0.7725490196078432, '#ddc26f'],
    [0.7764705882352941, '#ddc36f'],
    [0.7803921568627451, '#ddc46e'],
    [0.7843137254901961, '#ddc56e'],
    [0.788235294117647, '#dec66e'],
    [0.792156862745098, '#dec76e'],
    [0.796078431372549, '#dec86e'],
    [0.8, '#dec96d'],
    [0.803921568627451, '#dfca6d'],
    [0.807843137254902, '#dfca6d'],
    [0.8117647058823529, '#dfcb6d'],
    [0.8156862745098039, '#dfcc6c'],
    [0.8196078431372549, '#e0cd6c'],
    [0.8235294117647058, '#e0ce6c'],
    [0.8274509803921568, '#e0cf6c'],
    [0.8313725490196078, '#e0d06b'],
    [0.8352941176470589, '#e0d16b'],
    [0.8392156862745098, '#e1d26b'],
    [0.8431372549019608, '#e1d36b'],
    [0.8470588235294118, '#e1d46b'],
    [0.8509803921568627, '#e2d56a'],
    [0.8549019607843137, '#e2d66a'],
    [0.8588235294117647, '#e2d76a'],
    [0.8627450980392157, '#e2d86a'],
    [0.8666666666666667, '#e3d969'],
    [0.8705882352941177, '#e3da69'],
    [0.8745098039215686, '#e3db69'],
    [0.8784313725490196, '#e4dc69'],
    [0.8823529411764706, '#e4dd69'],
    [0.8862745098039215, '#e5de68'],
    [0.8901960784313725, '#e5df68'],
    [0.8941176470588235, '#e5e068'],
    [0.8980392156862745, '#e6e168'],
    [0.9019607843137255, '#e7e268'],
    [0.9058823529411765, '#e7e368'],
    [0.9098039215686274, '#e8e467'],
    [0.9137254901960784, '#e8e567'],
    [0.9176470588235294, '#e9e667'],
    [0.9215686274509803, '#eae767'],
    [0.9254901960784314, '#ebe867'],
    [0.9294117647058824, '#ebe967'],
    [0.9333333333333333, '#eceb67'],
    [0.9372549019607843, '#edec67'],
    [0.9411764705882353, '#eeed67'],
    [0.9450980392156862, '#efee66'],
    [0.9490196078431372, '#f0ef66'],
    [0.9529411764705882, '#f1f066'],
    [0.9568627450980391, '#f2f266'],
    [0.9607843137254902, '#f3f366'],
    [0.9647058823529412, '#f4f466'],
    [0.9686274509803922, '#f6f566'],
    [0.9725490196078431, '#f7f666'],
    [0.9764705882352941, '#f8f866'],
    [0.9803921568627451, '#f9f966'],
    [0.984313725490196, '#fafa66'],
    [0.9882352941176471, '#fbfb66'],
    [0.9921568627450981, '#fdfd66'],
    [0.996078431372549, '#fefe66'],
    [1.0, '#ffff66'],
  ],
  corkO: [
    [0.0, '#3f3e3a'],
    [0.00392156862745098, '#3f3e3b'],
    [0.00784313725490196, '#3f3e3c'],
    [0.011764705882352941, '#3f3d3d'],
    [0.01568627450980392, '#3e3d3e'],
    [0.0196078431372549, '#3e3d3f'],
    [0.023529411764705882, '#3e3d40'],
    [0.027450980392156862, '#3e3d41'],
    [0.03137254901960784, '#3e3d42'],
    [0.03529411764705882, '#3e3d43'],
    [0.0392156862745098, '#3e3d44'],
    [0.043137254901960784, '#3e3d45'],
    [0.047058823529411764, '#3e3d46'],
    [0.050980392156862744, '#3e3d47'],
    [0.054901960784313725, '#3e3d48'],
    [0.058823529411764705, '#3e3d49'],
    [0.06274509803921569, '#3e3d4b'],
    [0.06666666666666667, '#3e3d4c'],
    [0.07058823529411765, '#3e3e4d'],
    [0.07450980392156863, '#3e3e4f'],
    [0.0784313725490196, '#3e3f50'],
    [0.08235294117647059, '#3e3f52'],
    [0.08627450980392157, '#3e4053'],
    [0.09019607843137255, '#3e4055'],
    [0.09411764705882353, '#3e4156'],
    [0.09803921568627451, '#3e4158'],
    [0.10196078431372549, '#3e425a'],
    [0.10588235294117647, '#3e435b'],
    [0.10980392156862745, '#3e445d'],
    [0.11372549019607843, '#3e455f'],
    [0.11764705882352941, '#3f4661'],
    [0.12156862745098039, '#3f4762'],
    [0.12549019607843137, '#3f4864'],
    [0.12941176470588234, '#404966'],
    [0.13333333333333333, '#404a68'],
    [0.13725490196078433, '#404b6a'],
    [0.1411764705882353, '#414c6c'],
    [0.14509803921568626, '#414e6e'],
    [0.14901960784313725, '#424f70'],
    [0.15294117647058825, '#425072'],
    [0.1568627450980392, '#435274'],
    [0.16078431372549018, '#445376'],
    [0.16470588235294117, '#445578'],
    [0.16862745098039217, '#45567a'],
    [0.17254901960784313, '#46587c'],
    [0.1764705882352941, '#47597e'],
    [0.1803921568627451, '#475b80'],
    [0.1843137254901961, '#485d82'],
    [0.18823529411764706, '#495e84'],
    [0.19215686274509802, '#4a6086'],
    [0.19607843137254902, '#4b6287'],
    [0.2, '#4d6389'],
    [0.20392156862745098, '#4e658b'],
    [0.20784313725490194, '#4f678d'],
    [0.21176470588235294, '#50698f'],
    [0.21568627450980393, '#516a91'],
    [0.2196078431372549, '#536c92'],
    [0.22352941176470587, '#546e94'],
    [0.22745098039215686, '#567096'],
    [0.23137254901960785, '#577197'],
    [0.23529411764705882, '#587399'],
    [0.2392156862745098, '#5a759b'],
    [0.24313725490196078, '#5c779c'],
    [0.24705882352941178, '#5d789e'],
    [0.25098039215686274, '#5f7a9f'],
    [0.2549019607843137, '#607ca1'],
    [0.2588235294117647, '#627ea2'],
    [0.2627450980392157, '#647fa4'],
    [0.26666666666666666, '#6581a5'],
    [0.27058823529411763, '#6783a6'],
    [0.27450980392156865, '#6985a8'],
    [0.2784313725490196, '#6a86a9'],
    [0.2823529411764706, '#6c88ab'],
    [0.28627450980392155, '#6e8aac'],
    [0.2901960784313725, '#708cad'],
    [0.29411764705882354, '#728dae'],
    [0.2980392156862745, '#738fb0'],
    [0.30196078431372547, '#7591b1'],
    [0.3058823529411765, '#7792b2'],
    [0.30980392156862746, '#7994b3'],
    [0.3137254901960784, '#7b96b4'],
    [0.3176470588235294, '#7c97b6'],
    [0.32156862745098036, '#7e99b7'],
    [0.3254901960784314, '#809bb8'],
    [0.32941176470588235, '#829cb9'],
    [0.3333333333333333, '#849eba'],
    [0.33725490196078434, '#86a0bb'],
    [0.3411764705882353, '#87a1bc'],
    [0.34509803921568627, '#89a3bd'],
    [0.34901960784313724, '#8ba4be'],
    [0.3529411764705882, '#8da6bf'],
    [0.3568627450980392, '#8fa7c0'],
    [0.3607843137254902, '#90a9c0'],
    [0.36470588235294116, '#92abc1'],
    [0.3686274509803922, '#94acc2'],
    [0.37254901960784315, '#96aec3'],
    [0.3764705882352941, '#97afc3'],
    [0.3803921568627451, '#99b1c4'],
    [0.38431372549019605, '#9ab2c5'],
    [0.38823529411764707, '#9cb3c5'],
    [0.39215686274509803, '#9eb5c6'],
    [0.396078431372549, '#9fb6c6'],
    [0.4, '#a1b8c7'],
    [0.403921568627451, '#a2b9c7'],
    [0.40784313725490196, '#a3bac7'],
    [0.4117647058823529, '#a5bbc8'],
    [0.4156862745098039, '#a6bdc8'],
    [0.4196078431372549, '#a7bec8'],
    [0.4235294117647059, '#a8bfc8'],
    [0.42745098039215684, '#a9c0c8'],
    [0.43137254901960786, '#aac1c8'],
    [0.43529411764705883, '#abc2c8'],
    [0.4392156862745098, '#acc3c8'],
    [0.44313725490196076, '#adc4c8'],
    [0.44705882352941173, '#aec5c7'],
    [0.45098039215686275, '#aec6c7'],
    [0.4549019607843137, '#afc6c7'],
    [0.4588235294117647, '#afc7c6'],
    [0.4627450980392157, '#b0c8c5'],
    [0.4666666666666667, '#b0c8c5'],
    [0.47058823529411764, '#b0c9c4'],
    [0.4745098039215686, '#b0c9c3'],
    [0.4784313725490196, '#b0cac2'],
    [0.4823529411764706, '#b0cac1'],
    [0.48627450980392156, '#b0cac0'],
    [0.49019607843137253, '#b0cabf'],
    [0.49411764705882355, '#b0cabe'],
    [0.4980392156862745, '#afcbbd'],
    [0.5019607843137255, '#afcbbc'],
    [0.5058823529411764, '#aecabb'],
    [0.5098039215686274, '#adcab9'],
    [0.5137254901960784, '#adcab8'],
    [0.5176470588235293, '#accab6'],
    [0.5215686274509804, '#abcab5'],
    [0.5254901960784314, '#aac9b3'],
    [0.5294117647058824, '#a9c9b2'],
    [0.5333333333333333, '#a8c8b0'],
    [0.5372549019607843, '#a7c8af'],
    [0.5411764705882353, '#a6c7ad'],
    [0.5450980392156862, '#a5c7ab'],
    [0.5490196078431373, '#a3c6aa'],
    [0.5529411764705883, '#a2c5a8'],
    [0.5568627450980392, '#a1c4a6'],
    [0.5607843137254902, '#9fc4a4'],
    [0.5647058823529412, '#9ec3a3'],
    [0.5686274509803921, '#9cc2a1'],
    [0.5725490196078431, '#9bc19f'],
    [0.5764705882352941, '#99c09d'],
    [0.580392156862745, '#98bf9b'],
    [0.5843137254901961, '#96be99'],
    [0.5882352941176471, '#95bd97'],
    [0.592156862745098, '#93bc95'],
    [0.596078431372549, '#91bb93'],
    [0.6, '#90ba91'],
    [0.6039215686274509, '#8eb98f'],
    [0.6078431372549019, '#8cb78d'],
    [0.611764705882353, '#8bb68b'],
    [0.615686274509804, '#89b589'],
    [0.6196078431372549, '#87b487'],
    [0.6235294117647059, '#86b285'],
    [0.6274509803921569, '#84b183'],
    [0.6313725490196078, '#82b081'],
    [0.6352941176470588, '#80ae7f'],
    [0.6392156862745098, '#7fad7d'],
    [0.6431372549019607, '#7dac7b'],
    [0.6470588235294118, '#7baa79'],
    [0.6509803921568628, '#7aa977'],
    [0.6549019607843137, '#78a775'],
    [0.6588235294117647, '#76a673'],
    [0.6627450980392157, '#75a471'],
    [0.6666666666666666, '#73a36f'],
    [0.6705882352941176, '#71a16c'],
    [0.6745098039215687, '#709f6a'],
    [0.6784313725490196, '#6e9e68'],
    [0.6823529411764706, '#6c9c66'],
    [0.6862745098039216, '#6b9a64'],
    [0.6901960784313725, '#699962'],
    [0.6941176470588235, '#689760'],
    [0.6980392156862745, '#66955e'],
    [0.7019607843137254, '#65945c'],
    [0.7058823529411764, '#63925a'],
    [0.7098039215686275, '#629058'],
    [0.7137254901960784, '#608e56'],
    [0.7176470588235294, '#5f8d54'],
    [0.7215686274509804, '#5e8b52'],
    [0.7254901960784313, '#5c8950'],
    [0.7294117647058823, '#5b874e'],
    [0.7333333333333333, '#5a864c'],
    [0.7372549019607844, '#59844a'],
    [0.7411764705882353, '#578249'],
    [0.7450980392156863, '#568047'],
    [0.7490196078431373, '#557f45'],
    [0.7529411764705882, '#547d44'],
    [0.7568627450980392, '#537b42'],
    [0.7607843137254902, '#527941'],
    [0.7647058823529411, '#51783f'],
    [0.7686274509803921, '#50763e'],
    [0.7725490196078432, '#4f743c'],
    [0.7764705882352941, '#4f733b'],
    [0.7803921568627451, '#4e713a'],
    [0.7843137254901961, '#4d6f38'],
    [0.788235294117647, '#4c6e37'],
    [0.792156862745098, '#4c6c36'],
    [0.796078431372549, '#4b6b35'],
    [0.8, '#4a6934'],
    [0.803921568627451, '#4a6833'],
    [0.807843137254902, '#496632'],
    [0.8117647058823529, '#496532'],
    [0.8156862745098039, '#486431'],
    [0.8196078431372549, '#486230'],
    [0.8235294117647058, '#476130'],
    [0.8274509803921568, '#47602f'],
    [0.8313725490196078, '#465e2f'],
    [0.8352941176470589, '#465d2e'],
    [0.8392156862745098, '#465c2e'],
    [0.8431372549019608, '#455b2d'],
    [0.8470588235294118, '#45592d'],
    [0.8509803921568627, '#45582d'],
    [0.8549019607843137, '#45572d'],
    [0.8588235294117647, '#44562c'],
    [0.8627450980392157, '#44552c'],
    [0.8666666666666667, '#44542c'],
    [0.8705882352941177, '#44532c'],
    [0.8745098039215686, '#43522c'],
    [0.8784313725490196, '#43512c'],
    [0.8823529411764706, '#43502c'],
    [0.8862745098039215, '#434f2c'],
    [0.8901960784313725, '#434f2c'],
    [0.8941176470588235, '#424e2d'],
    [0.8980392156862745, '#424d2d'],
    [0.9019607843137255, '#424c2d'],
    [0.9058823529411765, '#424b2d'],
    [0.9098039215686274, '#424b2e'],
    [0.9137254901960784, '#424a2e'],
    [0.9176470588235294, '#42492e'],
    [0.9215686274509803, '#41482f'],
    [0.9254901960784314, '#41482f'],
    [0.9294117647058824, '#41472f'],
    [0.9333333333333333, '#414730'],
    [0.9372549019607843, '#414630'],
    [0.9411764705882353, '#414531'],
    [0.9450980392156862, '#414531'],
    [0.9490196078431372, '#414432'],
    [0.9529411764705882, '#404432'],
    [0.9568627450980391, '#404333'],
    [0.9607843137254902, '#404333'],
    [0.9647058823529412, '#404234'],
    [0.9686274509803922, '#404234'],
    [0.9725490196078431, '#404135'],
    [0.9764705882352941, '#404136'],
    [0.9803921568627451, '#404036'],
    [0.984313725490196, '#3f4037'],
    [0.9882352941176471, '#3f3f38'],
    [0.9921568627450981, '#3f3f38'],
    [0.996078431372549, '#3f3f39'],
    [1.0, '#3f3e3a'],
  ],
  devon: [
    [0.0, '#2c1a4c'],
    [0.00392156862745098, '#2c1b4d'],
    [0.00784313725490196, '#2c1c4e'],
    [0.011764705882352941, '#2b1c4f'],
    [0.01568627450980392, '#2b1d50'],
    [0.0196078431372549, '#2b1e51'],
    [0.023529411764705882, '#2b1f52'],
    [0.027450980392156862, '#2b2053'],
    [0.03137254901960784, '#2b2154'],
    [0.03529411764705882, '#2b2255'],
    [0.0392156862745098, '#2b2356'],
    [0.043137254901960784, '#2b2457'],
    [0.047058823529411764, '#2b2558'],
    [0.050980392156862744, '#2b2658'],
    [0.054901960784313725, '#2b2759'],
    [0.058823529411764705, '#2a285a'],
    [0.06274509803921569, '#2a295b'],
    [0.06666666666666667, '#2a295c'],
    [0.07058823529411765, '#2a2a5d'],
    [0.07450980392156863, '#2a2b5e'],
    [0.0784313725490196, '#2a2c5f'],
    [0.08235294117647059, '#2a2d60'],
    [0.08627450980392157, '#2a2e61'],
    [0.09019607843137255, '#2a2f62'],
    [0.09411764705882353, '#293063'],
    [0.09803921568627451, '#293164'],
    [0.10196078431372549, '#293265'],
    [0.10588235294117647, '#293366'],
    [0.10980392156862745, '#293467'],
    [0.11372549019607843, '#293568'],
    [0.11764705882352941, '#293669'],
    [0.12156862745098039, '#29376a'],
    [0.12549019607843137, '#29386a'],
    [0.12941176470588234, '#28396b'],
    [0.13333333333333333, '#28396c'],
    [0.13725490196078433, '#283a6d'],
    [0.1411764705882353, '#283b6e'],
    [0.14509803921568626, '#283c6f'],
    [0.14901960784313725, '#283d70'],
    [0.15294117647058825, '#283e71'],
    [0.1568627450980392, '#283f72'],
    [0.16078431372549018, '#284073'],
    [0.16470588235294117, '#274174'],
    [0.16862745098039217, '#274275'],
    [0.17254901960784313, '#274376'],
    [0.1764705882352941, '#274478'],
    [0.1803921568627451, '#274579'],
    [0.1843137254901961, '#27467a'],
    [0.18823529411764706, '#27477b'],
    [0.19215686274509802, '#27487c'],
    [0.19607843137254902, '#27497d'],
    [0.2, '#274a7e'],
    [0.20392156862745098, '#274c7f'],
    [0.20784313725490194, '#274d81'],
    [0.21176470588235294, '#274e82'],
    [0.21568627450980393, '#274f83'],
    [0.2196078431372549, '#275084'],
    [0.22352941176470587, '#275186'],
    [0.22745098039215686, '#275287'],
    [0.23137254901960785, '#275388'],
    [0.23529411764705882, '#28548a'],
    [0.2392156862745098, '#28558b'],
    [0.24313725490196078, '#28568c'],
    [0.24705882352941178, '#28578e'],
    [0.25098039215686274, '#29588f'],
    [0.2549019607843137, '#295990'],
    [0.2588235294117647, '#295a92'],
    [0.2627450980392157, '#2a5b93'],
    [0.26666666666666666, '#2a5c95'],
    [0.27058823529411763, '#2b5d96'],
    [0.27450980392156865, '#2b5d98'],
    [0.2784313725490196, '#2c5e99'],
    [0.2823529411764706, '#2c5f9a'],
    [0.28627450980392155, '#2d609c'],
    [0.2901960784313725, '#2e619d'],
    [0.29411764705882354, '#2e629f'],
    [0.2980392156862745, '#2f62a0'],
    [0.30196078431372547, '#3063a2'],
    [0.3058823529411765, '#3064a3'],
    [0.30980392156862746, '#3165a4'],
    [0.3137254901960784, '#3265a6'],
    [0.3176470588235294, '#3266a7'],
    [0.32156862745098036, '#3367a9'],
    [0.3254901960784314, '#3468aa'],
    [0.32941176470588235, '#3568ab'],
    [0.3333333333333333, '#3669ad'],
    [0.33725490196078434, '#376aae'],
    [0.3411764705882353, '#386ab0'],
    [0.34509803921568627, '#396bb1'],
    [0.34901960784313724, '#3a6cb2'],
    [0.3529411764705882, '#3b6db4'],
    [0.3568627450980392, '#3c6db5'],
    [0.3607843137254902, '#3d6eb7'],
    [0.36470588235294116, '#3e6fb8'],
    [0.3686274509803922, '#3f70b9'],
    [0.37254901960784315, '#4171bb'],
    [0.3764705882352941, '#4272bc'],
    [0.3803921568627451, '#4472bd'],
    [0.38431372549019605, '#4573bf'],
    [0.38823529411764707, '#4774c0'],
    [0.39215686274509803, '#4875c1'],
    [0.396078431372549, '#4a76c3'],
    [0.4, '#4c77c4'],
    [0.403921568627451, '#4d78c5'],
    [0.40784313725490196, '#4f79c6'],
    [0.4117647058823529, '#517ac8'],
    [0.4156862745098039, '#537bc9'],
    [0.4196078431372549, '#557cca'],
    [0.4235294117647059, '#577ccb'],
    [0.42745098039215684, '#597dcc'],
    [0.43137254901960786, '#5b7ecd'],
    [0.43529411764705883, '#5d7fce'],
    [0.4392156862745098, '#5f80cf'],
    [0.44313725490196076, '#6181d0'],
    [0.44705882352941173, '#6382d1'],
    [0.45098039215686275, '#6583d2'],
    [0.4549019607843137, '#6784d3'],
    [0.4588235294117647, '#6985d4'],
    [0.4627450980392157, '#6b86d5'],
    [0.4666666666666667, '#6d87d6'],
    [0.47058823529411764, '#6f88d7'],
    [0.4745098039215686, '#7188d8'],
    [0.4784313725490196, '#7389d8'],
    [0.4823529411764706, '#758ad9'],
    [0.48627450980392156, '#778bda'],
    [0.49019607843137253, '#798cdb'],
    [0.49411764705882355, '#7b8ddc'],
    [0.4980392156862745, '#7d8edc'],
    [0.5019607843137255, '#7e8fdd'],
    [0.5058823529411764, '#808fde'],
    [0.5098039215686274, '#8290df'],
    [0.5137254901960784, '#8491df'],
    [0.5176470588235293, '#8692e0'],
    [0.5215686274509804, '#8893e1'],
    [0.5254901960784314, '#8a94e1'],
    [0.5294117647058824, '#8c95e2'],
    [0.5333333333333333, '#8d95e3'],
    [0.5372549019607843, '#8f96e3'],
    [0.5411764705882353, '#9197e4'],
    [0.5450980392156862, '#9398e5'],
    [0.5490196078431373, '#9599e5'],
    [0.5529411764705883, '#969ae6'],
    [0.5568627450980392, '#989be7'],
    [0.5607843137254902, '#9a9be7'],
    [0.5647058823529412, '#9b9ce8'],
    [0.5686274509803921, '#9d9de8'],
    [0.5725490196078431, '#9e9ee9'],
    [0.5764705882352941, '#a09fe9'],
    [0.580392156862745, '#a2a0ea'],
    [0.5843137254901961, '#a3a1ea'],
    [0.5882352941176471, '#a4a2eb'],
    [0.592156862745098, '#a6a3eb'],
    [0.596078431372549, '#a7a4ec'],
    [0.6, '#a8a5ec'],
    [0.6039215686274509, '#aaa5ed'],
    [0.6078431372549019, '#aba6ed'],
    [0.611764705882353, '#aca7ed'],
    [0.615686274509804, '#ada8ee'],
    [0.6196078431372549, '#aea9ee'],
    [0.6235294117647059, '#afaaee'],
    [0.6274509803921569, '#b0abee'],
    [0.6313725490196078, '#b1acef'],
    [0.6352941176470588, '#b2adef'],
    [0.6392156862745098, '#b3adef'],
    [0.6431372549019607, '#b4aeef'],
    [0.6470588235294118, '#b5aff0'],
    [0.6509803921568628, '#b6b0f0'],
    [0.6549019607843137, '#b7b1f0'],
    [0.6588235294117647, '#b8b2f0'],
    [0.6627450980392157, '#b9b3f0'],
    [0.6666666666666666, '#bab3f1'],
    [0.6705882352941176, '#bab4f1'],
    [0.6745098039215687, '#bbb5f1'],
    [0.6784313725490196, '#bcb6f1'],
    [0.6823529411764706, '#bdb7f1'],
    [0.6862745098039216, '#beb8f2'],
    [0.6901960784313725, '#beb9f2'],
    [0.6941176470588235, '#bfb9f2'],
    [0.6980392156862745, '#c0baf2'],
    [0.7019607843137254, '#c1bbf2'],
    [0.7058823529411764, '#c2bcf2'],
    [0.7098039215686275, '#c2bdf3'],
    [0.7137254901960784, '#c3bef3'],
    [0.7176470588235294, '#c4bff3'],
    [0.7215686274509804, '#c5c0f3'],
    [0.7254901960784313, '#c6c0f3'],
    [0.7294117647058823, '#c6c1f3'],
    [0.7333333333333333, '#c7c2f4'],
    [0.7372549019607844, '#c8c3f4'],
    [0.7411764705882353, '#c9c4f4'],
    [0.7450980392156863, '#cac5f4'],
    [0.7490196078431373, '#cac6f4'],
    [0.7529411764705882, '#cbc6f4'],
    [0.7568627450980392, '#ccc7f4'],
    [0.7607843137254902, '#cdc8f5'],
    [0.7647058823529411, '#cec9f5'],
    [0.7686274509803921, '#cecaf5'],
    [0.7725490196078432, '#cfcbf5'],
    [0.7764705882352941, '#d0ccf5'],
    [0.7803921568627451, '#d1cdf5'],
    [0.7843137254901961, '#d2cdf6'],
    [0.788235294117647, '#d2cef6'],
    [0.792156862745098, '#d3cff6'],
    [0.796078431372549, '#d4d0f6'],
    [0.8, '#d5d1f6'],
    [0.803921568627451, '#d6d2f6'],
    [0.807843137254902, '#d6d3f7'],
    [0.8117647058823529, '#d7d4f7'],
    [0.8156862745098039, '#d8d4f7'],
    [0.8196078431372549, '#d9d5f7'],
    [0.8235294117647058, '#dad6f7'],
    [0.8274509803921568, '#dad7f7'],
    [0.8313725490196078, '#dbd8f8'],
    [0.8352941176470589, '#dcd9f8'],
    [0.8392156862745098, '#dddaf8'],
    [0.8431372549019608, '#dedbf8'],
    [0.8470588235294118, '#dfdcf8'],
    [0.8509803921568627, '#dfdcf8'],
    [0.8549019607843137, '#e0ddf9'],
    [0.8588235294117647, '#e1def9'],
    [0.8627450980392157, '#e2dff9'],
    [0.8666666666666667, '#e3e0f9'],
    [0.8705882352941177, '#e3e1f9'],
    [0.8745098039215686, '#e4e2fa'],
    [0.8784313725490196, '#e5e3fa'],
    [0.8823529411764706, '#e6e4fa'],
    [0.8862745098039215, '#e7e4fa'],
    [0.8901960784313725, '#e8e5fa'],
    [0.8941176470588235, '#e8e6fa'],
    [0.8980392156862745, '#e9e7fb'],
    [0.9019607843137255, '#eae8fb'],
    [0.9058823529411765, '#ebe9fb'],
    [0.9098039215686274, '#eceafb'],
    [0.9137254901960784, '#edebfb'],
    [0.9176470588235294, '#edecfb'],
    [0.9215686274509803, '#eeedfc'],
    [0.9254901960784314, '#efeefc'],
    [0.9294117647058824, '#f0eefc'],
    [0.9333333333333333, '#f1effc'],
    [0.9372549019607843, '#f2f0fc'],
    [0.9411764705882353, '#f2f1fc'],
    [0.9450980392156862, '#f3f2fd'],
    [0.9490196078431372, '#f4f3fd'],
    [0.9529411764705882, '#f5f4fd'],
    [0.9568627450980391, '#f6f5fd'],
    [0.9607843137254902, '#f7f6fd'],
    [0.9647058823529412, '#f7f7fd'],
    [0.9686274509803922, '#f8f8fe'],
    [0.9725490196078431, '#f9f9fe'],
    [0.9764705882352941, '#faf9fe'],
    [0.9803921568627451, '#fbfafe'],
    [0.984313725490196, '#fcfbfe'],
    [0.9882352941176471, '#fcfcfe'],
    [0.9921568627450981, '#fdfdff'],
    [0.996078431372549, '#fefeff'],
    [1.0, '#ffffff'],
  ],
  davos: [
    [0.0, '#00054a'],
    [0.00392156862745098, '#00074c'],
    [0.00784313725490196, '#00094d'],
    [0.011764705882352941, '#000b4f'],
    [0.01568627450980392, '#010c50'],
    [0.0196078431372549, '#010e51'],
    [0.023529411764705882, '#020f53'],
    [0.027450980392156862, '#021054'],
    [0.03137254901960784, '#031256'],
    [0.03529411764705882, '#041357'],
    [0.0392156862745098, '#041459'],
    [0.043137254901960784, '#05165a'],
    [0.047058823529411764, '#05175b'],
    [0.050980392156862744, '#06185d'],
    [0.054901960784313725, '#071a5e'],
    [0.058823529411764705, '#071b60'],
    [0.06274509803921569, '#081c61'],
    [0.06666666666666667, '#091e62'],
    [0.07058823529411765, '#0a1f64'],
    [0.07450980392156863, '#0b2065'],
    [0.0784313725490196, '#0b2267'],
    [0.08235294117647059, '#0c2368'],
    [0.08627450980392157, '#0d2469'],
    [0.09019607843137255, '#0e266b'],
    [0.09411764705882353, '#0e276c'],
    [0.09803921568627451, '#0f286d'],
    [0.10196078431372549, '#102a6f'],
    [0.10588235294117647, '#112b70'],
    [0.10980392156862745, '#112c71'],
    [0.11372549019607843, '#122e73'],
    [0.11764705882352941, '#132f74'],
    [0.12156862745098039, '#133075'],
    [0.12549019607843137, '#143277'],
    [0.12941176470588234, '#153378'],
    [0.13333333333333333, '#163479'],
    [0.13725490196078433, '#17367a'],
    [0.1411764705882353, '#17377c'],
    [0.14509803921568626, '#18387d'],
    [0.14901960784313725, '#193a7e'],
    [0.15294117647058825, '#1a3b7f'],
    [0.1568627450980392, '#1a3c80'],
    [0.16078431372549018, '#1b3e82'],
    [0.16470588235294117, '#1c3f83'],
    [0.16862745098039217, '#1d4084'],
    [0.17254901960784313, '#1e4185'],
    [0.1764705882352941, '#1e4386'],
    [0.1803921568627451, '#1f4487'],
    [0.1843137254901961, '#204588'],
    [0.18823529411764706, '#214789'],
    [0.19215686274509802, '#22488a'],
    [0.19607843137254902, '#23498b'],
    [0.2, '#234a8c'],
    [0.20392156862745098, '#244c8d'],
    [0.20784313725490194, '#254d8e'],
    [0.21176470588235294, '#264e8f'],
    [0.21568627450980393, '#274f90'],
    [0.2196078431372549, '#285190'],
    [0.22352941176470587, '#295291'],
    [0.22745098039215686, '#2a5392'],
    [0.23137254901960785, '#2a5493'],
    [0.23529411764705882, '#2b5693'],
    [0.2392156862745098, '#2c5794'],
    [0.24313725490196078, '#2d5895'],
    [0.24705882352941178, '#2e5995'],
    [0.25098039215686274, '#2f5a96'],
    [0.2549019607843137, '#305b97'],
    [0.2588235294117647, '#315c97'],
    [0.2627450980392157, '#325e98'],
    [0.26666666666666666, '#335f98'],
    [0.27058823529411763, '#346099'],
    [0.27450980392156865, '#356199'],
    [0.2784313725490196, '#36629a'],
    [0.2823529411764706, '#37639a'],
    [0.28627450980392155, '#38649a'],
    [0.2901960784313725, '#39659b'],
    [0.29411764705882354, '#3a669b'],
    [0.2980392156862745, '#3a679b'],
    [0.30196078431372547, '#3b689c'],
    [0.3058823529411765, '#3c699c'],
    [0.30980392156862746, '#3d6a9c'],
    [0.3137254901960784, '#3e6b9c'],
    [0.3176470588235294, '#3f6c9d'],
    [0.32156862745098036, '#406d9d'],
    [0.3254901960784314, '#416e9d'],
    [0.32941176470588235, '#426f9d'],
    [0.3333333333333333, '#43709d'],
    [0.33725490196078434, '#44709d'],
    [0.3411764705882353, '#45719d'],
    [0.34509803921568627, '#46729d'],
    [0.34901960784313724, '#47739d'],
    [0.3529411764705882, '#48749d'],
    [0.3568627450980392, '#49759d'],
    [0.3607843137254902, '#4a769d'],
    [0.36470588235294116, '#4b769d'],
    [0.3686274509803922, '#4c779d'],
    [0.37254901960784315, '#4d789d'],
    [0.3764705882352941, '#4e799d'],
    [0.3803921568627451, '#4f799d'],
    [0.38431372549019605, '#507a9c'],
    [0.38823529411764707, '#517b9c'],
    [0.39215686274509803, '#527c9c'],
    [0.396078431372549, '#537c9c'],
    [0.4, '#547d9c'],
    [0.403921568627451, '#557e9b'],
    [0.40784313725490196, '#567f9b'],
    [0.4117647058823529, '#567f9b'],
    [0.4156862745098039, '#57809b'],
    [0.4196078431372549, '#58819a'],
    [0.4235294117647059, '#59819a'],
    [0.42745098039215684, '#5a829a'],
    [0.43137254901960786, '#5b8399'],
    [0.43529411764705883, '#5c8399'],
    [0.4392156862745098, '#5d8499'],
    [0.44313725490196076, '#5e8598'],
    [0.44705882352941173, '#5f8598'],
    [0.45098039215686275, '#608698'],
    [0.4549019607843137, '#618697'],
    [0.4588235294117647, '#628797'],
    [0.4627450980392157, '#638897'],
    [0.4666666666666667, '#648896'],
    [0.47058823529411764, '#658996'],
    [0.4745098039215686, '#658a95'],
    [0.4784313725490196, '#668a95'],
    [0.4823529411764706, '#678b95'],
    [0.48627450980392156, '#688b94'],
    [0.49019607843137253, '#698c94'],
    [0.49411764705882355, '#6a8d93'],
    [0.4980392156862745, '#6b8d93'],
    [0.5019607843137255, '#6c8e93'],
    [0.5058823529411764, '#6d8e92'],
    [0.5098039215686274, '#6e8f92'],
    [0.5137254901960784, '#6f9091'],
    [0.5176470588235293, '#709091'],
    [0.5215686274509804, '#719191'],
    [0.5254901960784314, '#729190'],
    [0.5294117647058824, '#739290'],
    [0.5333333333333333, '#74938f'],
    [0.5372549019607843, '#74938f'],
    [0.5411764705882353, '#75948f'],
    [0.5450980392156862, '#76958e'],
    [0.5490196078431373, '#77958e'],
    [0.5529411764705883, '#78968d'],
    [0.5568627450980392, '#79968d'],
    [0.5607843137254902, '#7a978d'],
    [0.5647058823529412, '#7b988c'],
    [0.5686274509803921, '#7c988c'],
    [0.5725490196078431, '#7d998c'],
    [0.5764705882352941, '#7e9a8b'],
    [0.580392156862745, '#7f9a8b'],
    [0.5843137254901961, '#809b8b'],
    [0.5882352941176471, '#819c8a'],
    [0.592156862745098, '#829d8a'],
    [0.596078431372549, '#839d8a'],
    [0.6, '#849e89'],
    [0.6039215686274509, '#869f89'],
    [0.6078431372549019, '#87a089'],
    [0.611764705882353, '#88a089'],
    [0.615686274509804, '#89a188'],
    [0.6196078431372549, '#8aa288'],
    [0.6235294117647059, '#8ba388'],
    [0.6274509803921569, '#8ca388'],
    [0.6313725490196078, '#8da488'],
    [0.6352941176470588, '#8fa588'],
    [0.6392156862745098, '#90a687'],
    [0.6431372549019607, '#91a787'],
    [0.6470588235294118, '#92a887'],
    [0.6509803921568628, '#93a987'],
    [0.6549019607843137, '#95aa87'],
    [0.6588235294117647, '#96ab87'],
    [0.6627450980392157, '#97ac87'],
    [0.6666666666666666, '#99ad88'],
    [0.6705882352941176, '#9aae88'],
    [0.6745098039215687, '#9baf88'],
    [0.6784313725490196, '#9db088'],
    [0.6823529411764706, '#9eb188'],
    [0.6862745098039216, '#a0b289'],
    [0.6901960784313725, '#a1b389'],
    [0.6941176470588235, '#a3b489'],
    [0.6980392156862745, '#a4b68a'],
    [0.7019607843137254, '#a6b78a'],
    [0.7058823529411764, '#a8b88b'],
    [0.7098039215686275, '#a9b98b'],
    [0.7137254901960784, '#abbb8c'],
    [0.7176470588235294, '#adbc8c'],
    [0.7215686274509804, '#aebd8d'],
    [0.7254901960784313, '#b0bf8e'],
    [0.7294117647058823, '#b2c08f'],
    [0.7333333333333333, '#b4c190'],
    [0.7372549019607844, '#b6c391'],
    [0.7411764705882353, '#b8c492'],
    [0.7450980392156863, '#b9c693'],
    [0.7490196078431373, '#bbc794'],
    [0.7529411764705882, '#bdc995'],
    [0.7568627450980392, '#bfca96'],
    [0.7607843137254902, '#c1cc98'],
    [0.7647058823529411, '#c3cd99'],
    [0.7686274509803921, '#c5cf9b'],
    [0.7725490196078432, '#c7d09c'],
    [0.7764705882352941, '#c9d29e'],
    [0.7803921568627451, '#cbd39f'],
    [0.7843137254901961, '#cdd5a1'],
    [0.788235294117647, '#cfd6a2'],
    [0.792156862745098, '#d0d8a4'],
    [0.796078431372549, '#d2d9a6'],
    [0.8, '#d4dba8'],
    [0.803921568627451, '#d6dca9'],
    [0.807843137254902, '#d8deab'],
    [0.8117647058823529, '#d9dfad'],
    [0.8156862745098039, '#dbe0af'],
    [0.8196078431372549, '#dde2b1'],
    [0.8235294117647058, '#dee3b3'],
    [0.8274509803921568, '#e0e4b5'],
    [0.8313725490196078, '#e1e5b6'],
    [0.8352941176470589, '#e3e7b8'],
    [0.8392156862745098, '#e4e8ba'],
    [0.8431372549019608, '#e6e9bc'],
    [0.8470588235294118, '#e7eabe'],
    [0.8509803921568627, '#e8ebc0'],
    [0.8549019607843137, '#eaecc2'],
    [0.8588235294117647, '#ebedc4'],
    [0.8627450980392157, '#eceec6'],
    [0.8666666666666667, '#edefc7'],
    [0.8705882352941177, '#eeefc9'],
    [0.8745098039215686, '#eff0cb'],
    [0.8784313725490196, '#f0f1cd'],
    [0.8823529411764706, '#f1f2cf'],
    [0.8862745098039215, '#f2f2d0'],
    [0.8901960784313725, '#f3f3d2'],
    [0.8941176470588235, '#f3f4d4'],
    [0.8980392156862745, '#f4f4d6'],
    [0.9019607843137255, '#f5f5d7'],
    [0.9058823529411765, '#f5f6d9'],
    [0.9098039215686274, '#f6f6db'],
    [0.9137254901960784, '#f7f7dd'],
    [0.9176470588235294, '#f7f7de'],
    [0.9215686274509803, '#f8f8e0'],
    [0.9254901960784314, '#f8f8e1'],
    [0.9294117647058824, '#f9f9e3'],
    [0.9333333333333333, '#f9f9e5'],
    [0.9372549019607843, '#faf9e6'],
    [0.9411764705882353, '#fafae8'],
    [0.9450980392156862, '#fbfae9'],
    [0.9490196078431372, '#fbfbeb'],
    [0.9529411764705882, '#fbfbec'],
    [0.9568627450980391, '#fcfbee'],
    [0.9607843137254902, '#fcfcf0'],
    [0.9647058823529412, '#fcfcf1'],
    [0.9686274509803922, '#fcfcf3'],
    [0.9725490196078431, '#fdfdf4'],
    [0.9764705882352941, '#fdfdf6'],
    [0.9803921568627451, '#fdfdf7'],
    [0.984313725490196, '#fefdf9'],
    [0.9882352941176471, '#fefefa'],
    [0.9921568627450981, '#fefefc'],
    [0.996078431372549, '#fefefd'],
    [1.0, '#fefefe'],
  ],
  bamako: [
    [0.0, '#00404c'],
    [0.00392156862745098, '#01404c'],
    [0.00784313725490196, '#02414c'],
    [0.011764705882352941, '#02414b'],
    [0.01568627450980392, '#03414b'],
    [0.0196078431372549, '#04424b'],
    [0.023529411764705882, '#04424a'],
    [0.027450980392156862, '#05424a'],
    [0.03137254901960784, '#06434a'],
    [0.03529411764705882, '#064349'],
    [0.0392156862745098, '#074449'],
    [0.043137254901960784, '#084449'],
    [0.047058823529411764, '#094448'],
    [0.050980392156862744, '#094548'],
    [0.054901960784313725, '#0a4547'],
    [0.058823529411764705, '#0b4647'],
    [0.06274509803921569, '#0b4647'],
    [0.06666666666666667, '#0c4646'],
    [0.07058823529411765, '#0d4746'],
    [0.07450980392156863, '#0d4746'],
    [0.0784313725490196, '#0e4845'],
    [0.08235294117647059, '#0f4845'],
    [0.08627450980392157, '#0f4844'],
    [0.09019607843137255, '#104944'],
    [0.09411764705882353, '#114944'],
    [0.09803921568627451, '#114a43'],
    [0.10196078431372549, '#124a43'],
    [0.10588235294117647, '#124a43'],
    [0.10980392156862745, '#134b42'],
    [0.11372549019607843, '#144b42'],
    [0.11764705882352941, '#144c41'],
    [0.12156862745098039, '#154c41'],
    [0.12549019607843137, '#154c41'],
    [0.12941176470588234, '#164d40'],
    [0.13333333333333333, '#174d40'],
    [0.13725490196078433, '#174e40'],
    [0.1411764705882353, '#184e3f'],
    [0.14509803921568626, '#194f3f'],
    [0.14901960784313725, '#194f3e'],
    [0.15294117647058825, '#1a4f3e'],
    [0.1568627450980392, '#1a503e'],
    [0.16078431372549018, '#1b503d'],
    [0.16470588235294117, '#1c513d'],
    [0.16862745098039217, '#1c513c'],
    [0.17254901960784313, '#1d523c'],
    [0.1764705882352941, '#1e523c'],
    [0.1803921568627451, '#1e523b'],
    [0.1843137254901961, '#1f533b'],
    [0.18823529411764706, '#20533a'],
    [0.19215686274509802, '#20543a'],
    [0.19607843137254902, '#21543a'],
    [0.2, '#225539'],
    [0.20392156862745098, '#225539'],
    [0.20784313725490194, '#235538'],
    [0.21176470588235294, '#245638'],
    [0.21568627450980393, '#245637'],
    [0.2196078431372549, '#255737'],
    [0.22352941176470587, '#265737'],
    [0.22745098039215686, '#265836'],
    [0.23137254901960785, '#275836'],
    [0.23529411764705882, '#285935'],
    [0.2392156862745098, '#285935'],
    [0.24313725490196078, '#295a34'],
    [0.24705882352941178, '#2a5a34'],
    [0.25098039215686274, '#2b5a34'],
    [0.2549019607843137, '#2b5b33'],
    [0.2588235294117647, '#2c5b33'],
    [0.2627450980392157, '#2d5c32'],
    [0.26666666666666666, '#2d5c32'],
    [0.27058823529411763, '#2e5d31'],
    [0.27450980392156865, '#2f5d31'],
    [0.2784313725490196, '#305e31'],
    [0.2823529411764706, '#305e30'],
    [0.28627450980392155, '#315f30'],
    [0.2901960784313725, '#325f2f'],
    [0.29411764705882354, '#33602f'],
    [0.2980392156862745, '#33602e'],
    [0.30196078431372547, '#34612e'],
    [0.3058823529411765, '#35612d'],
    [0.30980392156862746, '#36622d'],
    [0.3137254901960784, '#36622d'],
    [0.3176470588235294, '#37632c'],
    [0.32156862745098036, '#38632c'],
    [0.3254901960784314, '#39642b'],
    [0.32941176470588235, '#39642b'],
    [0.3333333333333333, '#3a652a'],
    [0.33725490196078434, '#3b652a'],
    [0.3411764705882353, '#3c6629'],
    [0.34509803921568627, '#3d6629'],
    [0.34901960784313724, '#3d6728'],
    [0.3529411764705882, '#3e6728'],
    [0.3568627450980392, '#3f6827'],
    [0.3607843137254902, '#406827'],
    [0.36470588235294116, '#416927'],
    [0.3686274509803922, '#416926'],
    [0.37254901960784315, '#426a26'],
    [0.3764705882352941, '#436a25'],
    [0.3803921568627451, '#446b25'],
    [0.38431372549019605, '#456b24'],
    [0.38823529411764707, '#466c24'],
    [0.39215686274509803, '#466d23'],
    [0.396078431372549, '#476d23'],
    [0.4, '#486e22'],
    [0.403921568627451, '#496e22'],
    [0.40784313725490196, '#4a6f21'],
    [0.4117647058823529, '#4b6f21'],
    [0.4156862745098039, '#4c7020'],
    [0.4196078431372549, '#4c7120'],
    [0.4235294117647059, '#4d711f'],
    [0.42745098039215684, '#4e721f'],
    [0.43137254901960786, '#4f721e'],
    [0.43529411764705883, '#50731d'],
    [0.4392156862745098, '#51731d'],
    [0.44313725490196076, '#52741c'],
    [0.44705882352941173, '#53751c'],
    [0.45098039215686275, '#54751b'],
    [0.4549019607843137, '#55761b'],
    [0.4588235294117647, '#56771a'],
    [0.4627450980392157, '#57771a'],
    [0.4666666666666667, '#587819'],
    [0.47058823529411764, '#597819'],
    [0.4745098039215686, '#5a7918'],
    [0.4784313725490196, '#5b7a17'],
    [0.4823529411764706, '#5c7a17'],
    [0.48627450980392156, '#5d7b16'],
    [0.49019607843137253, '#5e7c16'],
    [0.49411764705882355, '#5f7c15'],
    [0.4980392156862745, '#607d15'],
    [0.5019607843137255, '#617e14'],
    [0.5058823529411764, '#627e13'],
    [0.5098039215686274, '#637f13'],
    [0.5137254901960784, '#648012'],
    [0.5176470588235293, '#658012'],
    [0.5215686274509804, '#678111'],
    [0.5254901960784314, '#688210'],
    [0.5294117647058824, '#698210'],
    [0.5333333333333333, '#6a830f'],
    [0.5372549019607843, '#6b840e'],
    [0.5411764705882353, '#6c840e'],
    [0.5450980392156862, '#6e850d'],
    [0.5490196078431373, '#6f860c'],
    [0.5529411764705883, '#70860c'],
    [0.5568627450980392, '#71870b'],
    [0.5607843137254902, '#73880a'],
    [0.5647058823529412, '#748809'],
    [0.5686274509803921, '#758909'],
    [0.5725490196078431, '#768908'],
    [0.5764705882352941, '#788a07'],
    [0.580392156862745, '#798a07'],
    [0.5843137254901961, '#7a8b06'],
    [0.5882352941176471, '#7c8b06'],
    [0.592156862745098, '#7d8c05'],
    [0.596078431372549, '#7e8c05'],
    [0.6, '#808d04'],
    [0.6039215686274509, '#818d04'],
    [0.6078431372549019, '#828d04'],
    [0.611764705882353, '#838e03'],
    [0.615686274509804, '#858e03'],
    [0.6196078431372549, '#868e03'],
    [0.6235294117647059, '#878e03'],
    [0.6274509803921569, '#898f03'],
    [0.6313725490196078, '#8a8f03'],
    [0.6352941176470588, '#8b8f03'],
    [0.6392156862745098, '#8d8f03'],
    [0.6431372549019607, '#8e9003'],
    [0.6470588235294118, '#8f9003'],
    [0.6509803921568628, '#919004'],
    [0.6549019607843137, '#929104'],
    [0.6588235294117647, '#939105'],
    [0.6627450980392157, '#959106'],
    [0.6666666666666666, '#969206'],
    [0.6705882352941176, '#989207'],
    [0.6745098039215687, '#999308'],
    [0.6784313725490196, '#9b9309'],
    [0.6823529411764706, '#9c940b'],
    [0.6862745098039216, '#9e950c'],
    [0.6901960784313725, '#a0950d'],
    [0.6941176470588235, '#a1960f'],
    [0.6980392156862745, '#a39710'],
    [0.7019607843137254, '#a59811'],
    [0.7058823529411764, '#a69913'],
    [0.7098039215686275, '#a89a14'],
    [0.7137254901960784, '#aa9b16'],
    [0.7176470588235294, '#ab9c18'],
    [0.7215686274509804, '#ad9d19'],
    [0.7254901960784313, '#af9e1b'],
    [0.7294117647058823, '#b09f1c'],
    [0.7333333333333333, '#b2a01e'],
    [0.7372549019607844, '#b4a120'],
    [0.7411764705882353, '#b6a321'],
    [0.7450980392156863, '#b7a423'],
    [0.7490196078431373, '#b9a525'],
    [0.7529411764705882, '#bba627'],
    [0.7568627450980392, '#bca829'],
    [0.7607843137254902, '#bea92a'],
    [0.7647058823529411, '#c0aa2c'],
    [0.7686274509803921, '#c1ab2e'],
    [0.7725490196078432, '#c3ad30'],
    [0.7764705882352941, '#c5ae32'],
    [0.7803921568627451, '#c6af34'],
    [0.7843137254901961, '#c8b036'],
    [0.788235294117647, '#c9b238'],
    [0.792156862745098, '#cbb33a'],
    [0.796078431372549, '#ccb43b'],
    [0.8, '#cdb53d'],
    [0.803921568627451, '#cfb73f'],
    [0.807843137254902, '#d0b841'],
    [0.8117647058823529, '#d1b943'],
    [0.8156862745098039, '#d3ba45'],
    [0.8196078431372549, '#d4bb47'],
    [0.8235294117647058, '#d5bc49'],
    [0.8274509803921568, '#d6bd4b'],
    [0.8313725490196078, '#d8be4d'],
    [0.8352941176470589, '#d9bf4f'],
    [0.8392156862745098, '#dac051'],
    [0.8431372549019608, '#dbc152'],
    [0.8470588235294118, '#dcc254'],
    [0.8509803921568627, '#ddc356'],
    [0.8549019607843137, '#dec458'],
    [0.8588235294117647, '#dfc55a'],
    [0.8627450980392157, '#e0c65c'],
    [0.8666666666666667, '#e1c75e'],
    [0.8705882352941177, '#e2c85f'],
    [0.8745098039215686, '#e3c961'],
    [0.8784313725490196, '#e4ca63'],
    [0.8823529411764706, '#e5cb65'],
    [0.8862745098039215, '#e6cc67'],
    [0.8901960784313725, '#e7cd68'],
    [0.8941176470588235, '#e7ce6a'],
    [0.8980392156862745, '#e8cf6c'],
    [0.9019607843137255, '#e9d06e'],
    [0.9058823529411765, '#ead06f'],
    [0.9098039215686274, '#ebd171'],
    [0.9137254901960784, '#ecd273'],
    [0.9176470588235294, '#edd375'],
    [0.9215686274509803, '#eed476'],
    [0.9254901960784314, '#efd578'],
    [0.9294117647058824, '#efd67a'],
    [0.9333333333333333, '#f0d77c'],
    [0.9372549019607843, '#f1d87d'],
    [0.9411764705882353, '#f2d87f'],
    [0.9450980392156862, '#f3d981'],
    [0.9490196078431372, '#f4da83'],
    [0.9529411764705882, '#f5db84'],
    [0.9568627450980391, '#f6dc86'],
    [0.9607843137254902, '#f6dd88'],
    [0.9647058823529412, '#f7de89'],
    [0.9686274509803922, '#f8df8b'],
    [0.9725490196078431, '#f9df8d'],
    [0.9764705882352941, '#fae08f'],
    [0.9803921568627451, '#fbe190'],
    [0.984313725490196, '#fce292'],
    [0.9882352941176471, '#fce394'],
    [0.9921568627450981, '#fde496'],
    [0.996078431372549, '#fee597'],
    [1.0, '#ffe599'],
  ],
  fes: [
    [0.0, '#0d0d0d'],
    [0.00392156862745098, '#0f0f0f'],
    [0.00784313725490196, '#121212'],
    [0.011764705882352941, '#141414'],
    [0.01568627450980392, '#161616'],
    [0.0196078431372549, '#181818'],
    [0.023529411764705882, '#191919'],
    [0.027450980392156862, '#1b1b1b'],
    [0.03137254901960784, '#1d1d1d'],
    [0.03529411764705882, '#1f1f1f'],
    [0.0392156862745098, '#212121'],
    [0.043137254901960784, '#222222'],
    [0.047058823529411764, '#242424'],
    [0.050980392156862744, '#262626'],
    [0.054901960784313725, '#282828'],
    [0.058823529411764705, '#292929'],
    [0.06274509803921569, '#2b2b2b'],
    [0.06666666666666667, '#2d2d2d'],
    [0.07058823529411765, '#2e2e2e'],
    [0.07450980392156863, '#303030'],
    [0.0784313725490196, '#323232'],
    [0.08235294117647059, '#343434'],
    [0.08627450980392157, '#353535'],
    [0.09019607843137255, '#373737'],
    [0.09411764705882353, '#393939'],
    [0.09803921568627451, '#3b3b3b'],
    [0.10196078431372549, '#3c3c3c'],
    [0.10588235294117647, '#3e3e3e'],
    [0.10980392156862745, '#404040'],
    [0.11372549019607843, '#414141'],
    [0.11764705882352941, '#434343'],
    [0.12156862745098039, '#454545'],
    [0.12549019607843137, '#464646'],
    [0.12941176470588234, '#484848'],
    [0.13333333333333333, '#494949'],
    [0.13725490196078433, '#4b4b4b'],
    [0.1411764705882353, '#4d4d4d'],
    [0.14509803921568626, '#4e4e4e'],
    [0.14901960784313725, '#505050'],
    [0.15294117647058825, '#525252'],
    [0.1568627450980392, '#535353'],
    [0.16078431372549018, '#555555'],
    [0.16470588235294117, '#565656'],
    [0.16862745098039217, '#585858'],
    [0.17254901960784313, '#595959'],
    [0.1764705882352941, '#5b5b5b'],
    [0.1803921568627451, '#5c5c5c'],
    [0.1843137254901961, '#5e5e5e'],
    [0.18823529411764706, '#606060'],
    [0.19215686274509802, '#616161'],
    [0.19607843137254902, '#636363'],
    [0.2, '#646464'],
    [0.20392156862745098, '#656565'],
    [0.20784313725490194, '#676767'],
    [0.21176470588235294, '#686868'],
    [0.21568627450980393, '#6a6a6a'],
    [0.2196078431372549, '#6b6b6b'],
    [0.22352941176470587, '#6d6d6d'],
    [0.22745098039215686, '#6e6e6e'],
    [0.23137254901960785, '#707070'],
    [0.23529411764705882, '#717171'],
    [0.2392156862745098, '#727272'],
    [0.24313725490196078, '#747474'],
    [0.24705882352941178, '#757575'],
    [0.25098039215686274, '#777777'],
    [0.2549019607843137, '#787878'],
    [0.2588235294117647, '#797979'],
    [0.2627450980392157, '#7b7b7b'],
    [0.26666666666666666, '#7c7c7c'],
    [0.27058823529411763, '#7e7e7e'],
    [0.27450980392156865, '#7f7f7f'],
    [0.2784313725490196, '#818181'],
    [0.2823529411764706, '#828282'],
    [0.28627450980392155, '#848484'],
    [0.2901960784313725, '#858585'],
    [0.29411764705882354, '#878787'],
    [0.2980392156862745, '#888888'],
    [0.30196078431372547, '#8a8a8a'],
    [0.3058823529411765, '#8b8b8b'],
    [0.30980392156862746, '#8d8d8d'],
    [0.3137254901960784, '#8e8e8e'],
    [0.3176470588235294, '#909090'],
    [0.32156862745098036, '#929292'],
    [0.3254901960784314, '#939393'],
    [0.32941176470588235, '#959595'],
    [0.3333333333333333, '#979797'],
    [0.33725490196078434, '#999999'],
    [0.3411764705882353, '#9a9a9a'],
    [0.34509803921568627, '#9c9c9c'],
    [0.34901960784313724, '#9e9e9e'],
    [0.3529411764705882, '#a0a0a0'],
    [0.3568627450980392, '#a1a1a1'],
    [0.3607843137254902, '#a3a3a3'],
    [0.36470588235294116, '#a5a5a5'],
    [0.3686274509803922, '#a7a7a7'],
    [0.37254901960784315, '#a9a9a9'],
    [0.3764705882352941, '#ababab'],
    [0.3803921568627451, '#adadad'],
    [0.38431372549019605, '#afafaf'],
    [0.38823529411764707, '#b1b1b1'],
    [0.39215686274509803, '#b3b3b3'],
    [0.396078431372549, '#b5b5b5'],
    [0.4, '#b7b7b7'],
    [0.403921568627451, '#b9b9b9'],
    [0.40784313725490196, '#bbbbbb'],
    [0.4117647058823529, '#bdbdbd'],
    [0.4156862745098039, '#bfbfbf'],
    [0.4196078431372549, '#c1c1c1'],
    [0.4235294117647059, '#c3c3c3'],
    [0.42745098039215684, '#c5c5c5'],
    [0.43137254901960786, '#c8c8c8'],
    [0.43529411764705883, '#cacaca'],
    [0.4392156862745098, '#cccccc'],
    [0.44313725490196076, '#cecece'],
    [0.44705882352941173, '#d1d1d1'],
    [0.45098039215686275, '#d3d3d3'],
    [0.4549019607843137, '#d5d5d5'],
    [0.4588235294117647, '#d8d8d8'],
    [0.4627450980392157, '#dadada'],
    [0.4666666666666667, '#dddddd'],
    [0.47058823529411764, '#dfdfdf'],
    [0.4745098039215686, '#e2e2e2'],
    [0.4784313725490196, '#e4e4e4'],
    [0.4823529411764706, '#e7e7e7'],
    [0.48627450980392156, '#e9e9e9'],
    [0.49019607843137253, '#ececec'],
    [0.49411764705882355, '#eeeeee'],
    [0.4980392156862745, '#f1f1f1'],
    [0.5019607843137255, '#024026'],
    [0.5058823529411764, '#054126'],
    [0.5098039215686274, '#094225'],
    [0.5137254901960784, '#0c4324'],
    [0.5176470588235293, '#0f4423'],
    [0.5215686274509804, '#124623'],
    [0.5254901960784314, '#154722'],
    [0.5294117647058824, '#184822'],
    [0.5333333333333333, '#1b4921'],
    [0.5372549019607843, '#1e4a21'],
    [0.5411764705882353, '#214b20'],
    [0.5450980392156862, '#244c20'],
    [0.5490196078431373, '#274e20'],
    [0.5529411764705883, '#2b4f20'],
    [0.5568627450980392, '#2e5020'],
    [0.5607843137254902, '#315120'],
    [0.5647058823529412, '#345220'],
    [0.5686274509803921, '#375320'],
    [0.5725490196078431, '#3a5420'],
    [0.5764705882352941, '#3d5520'],
    [0.580392156862745, '#3f5521'],
    [0.5843137254901961, '#425621'],
    [0.5882352941176471, '#455722'],
    [0.592156862745098, '#485822'],
    [0.596078431372549, '#4a5922'],
    [0.6, '#4d5923'],
    [0.6039215686274509, '#505a23'],
    [0.6078431372549019, '#525b24'],
    [0.611764705882353, '#555b24'],
    [0.615686274509804, '#575c25'],
    [0.6196078431372549, '#5a5d25'],
    [0.6235294117647059, '#5c5d26'],
    [0.6274509803921569, '#5e5e26'],
    [0.6313725490196078, '#615e26'],
    [0.6352941176470588, '#635f27'],
    [0.6392156862745098, '#656027'],
    [0.6431372549019607, '#686028'],
    [0.6470588235294118, '#6a6128'],
    [0.6509803921568628, '#6c6129'],
    [0.6549019607843137, '#6f6229'],
    [0.6588235294117647, '#716229'],
    [0.6627450980392157, '#73632a'],
    [0.6666666666666666, '#75642a'],
    [0.6705882352941176, '#78642b'],
    [0.6745098039215687, '#7a652b'],
    [0.6784313725490196, '#7c652c'],
    [0.6823529411764706, '#7f662c'],
    [0.6862745098039216, '#81672d'],
    [0.6901960784313725, '#83672d'],
    [0.6941176470588235, '#86682e'],
    [0.6980392156862745, '#88682e'],
    [0.7019607843137254, '#8b692f'],
    [0.7058823529411764, '#8d6a2f'],
    [0.7098039215686275, '#906b30'],
    [0.7137254901960784, '#926b30'],
    [0.7176470588235294, '#956c31'],
    [0.7215686274509804, '#976d32'],
    [0.7254901960784313, '#9a6e33'],
    [0.7294117647058823, '#9c6f34'],
    [0.7333333333333333, '#9f7035'],
    [0.7372549019607844, '#a17137'],
    [0.7411764705882353, '#a47338'],
    [0.7450980392156863, '#a6743a'],
    [0.7490196078431373, '#a8763c'],
    [0.7529411764705882, '#ab773e'],
    [0.7568627450980392, '#ad7940'],
    [0.7607843137254902, '#af7b42'],
    [0.7647058823529411, '#b17d45'],
    [0.7686274509803921, '#b37f47'],
    [0.7725490196078432, '#b5814a'],
    [0.7764705882352941, '#b6834d'],
    [0.7803921568627451, '#b88550'],
    [0.7843137254901961, '#b98753'],
    [0.788235294117647, '#ba8956'],
    [0.792156862745098, '#bc8b59'],
    [0.796078431372549, '#bd8e5c'],
    [0.8, '#be905f'],
    [0.803921568627451, '#bf9262'],
    [0.807843137254902, '#c09465'],
    [0.8117647058823529, '#c19769'],
    [0.8156862745098039, '#c2996c'],
    [0.8196078431372549, '#c39b6f'],
    [0.8235294117647058, '#c49d72'],
    [0.8274509803921568, '#c59f75'],
    [0.8313725490196078, '#c6a278'],
    [0.8352941176470589, '#c6a47c'],
    [0.8392156862745098, '#c7a67f'],
    [0.8431372549019608, '#c8a882'],
    [0.8470588235294118, '#c9ab85'],
    [0.8509803921568627, '#caad89'],
    [0.8549019607843137, '#cbaf8c'],
    [0.8588235294117647, '#ccb18f'],
    [0.8627450980392157, '#cdb392'],
    [0.8666666666666667, '#ceb696'],
    [0.8705882352941177, '#cfb899'],
    [0.8745098039215686, '#d0ba9c'],
    [0.8784313725490196, '#d1bca0'],
    [0.8823529411764706, '#d2bea3'],
    [0.8862745098039215, '#d2c0a6'],
    [0.8901960784313725, '#d3c2aa'],
    [0.8941176470588235, '#d4c4ad'],
    [0.8980392156862745, '#d5c6b0'],
    [0.9019607843137255, '#d6c8b4'],
    [0.9058823529411765, '#d7cab7'],
    [0.9098039215686274, '#d8ccba'],
    [0.9137254901960784, '#d9cebd'],
    [0.9176470588235294, '#dacfc1'],
    [0.9215686274509803, '#dbd1c4'],
    [0.9254901960784314, '#dcd3c7'],
    [0.9294117647058824, '#ddd4ca'],
    [0.9333333333333333, '#ded6cd'],
    [0.9372549019607843, '#dfd7cf'],
    [0.9411764705882353, '#e0d9d2'],
    [0.9450980392156862, '#e1dad5'],
    [0.9490196078431372, '#e2dbd8'],
    [0.9529411764705882, '#e2ddda'],
    [0.9568627450980391, '#e3dedd'],
    [0.9607843137254902, '#e4dfe0'],
    [0.9647058823529412, '#e5e0e2'],
    [0.9686274509803922, '#e6e2e5'],
    [0.9725490196078431, '#e7e3e8'],
    [0.9764705882352941, '#e8e4ea'],
    [0.9803921568627451, '#e8e6ed'],
    [0.984313725490196, '#e9e7f0'],
    [0.9882352941176471, '#eae9f3'],
    [0.9921568627450981, '#ebeaf6'],
    [0.996078431372549, '#ecebf9'],
    [1.0, '#ededfc'],
  ],
  oslo: [
    [0.0, '#010101'],
    [0.00392156862745098, '#010203'],
    [0.00784313725490196, '#020405'],
    [0.011764705882352941, '#020507'],
    [0.01568627450980392, '#030609'],
    [0.0196078431372549, '#04070b'],
    [0.023529411764705882, '#04080d'],
    [0.027450980392156862, '#05090f'],
    [0.03137254901960784, '#050a10'],
    [0.03529411764705882, '#060b12'],
    [0.0392156862745098, '#060c13'],
    [0.043137254901960784, '#070d15'],
    [0.047058823529411764, '#080e16'],
    [0.050980392156862744, '#080f17'],
    [0.054901960784313725, '#091018'],
    [0.058823529411764705, '#091119'],
    [0.06274509803921569, '#0a121b'],
    [0.06666666666666667, '#0b131c'],
    [0.07058823529411765, '#0b131d'],
    [0.07450980392156863, '#0b141e'],
    [0.0784313725490196, '#0c151f'],
    [0.08235294117647059, '#0c1620'],
    [0.08627450980392157, '#0d1622'],
    [0.09019607843137255, '#0d1723'],
    [0.09411764705882353, '#0d1824'],
    [0.09803921568627451, '#0d1925'],
    [0.10196078431372549, '#0d1927'],
    [0.10588235294117647, '#0d1a28'],
    [0.10980392156862745, '#0d1b29'],
    [0.11372549019607843, '#0e1b2a'],
    [0.11764705882352941, '#0e1c2c'],
    [0.12156862745098039, '#0e1d2d'],
    [0.12549019607843137, '#0e1e2e'],
    [0.12941176470588234, '#0e1e2f'],
    [0.13333333333333333, '#0e1f31'],
    [0.13725490196078433, '#0e2032'],
    [0.1411764705882353, '#0f2133'],
    [0.14509803921568626, '#0f2135'],
    [0.14901960784313725, '#0f2236'],
    [0.15294117647058825, '#0f2337'],
    [0.1568627450980392, '#0f2439'],
    [0.16078431372549018, '#0f253a'],
    [0.16470588235294117, '#10263c'],
    [0.16862745098039217, '#10263d'],
    [0.17254901960784313, '#10273e'],
    [0.1764705882352941, '#102840'],
    [0.1803921568627451, '#102941'],
    [0.1843137254901961, '#112a43'],
    [0.18823529411764706, '#112b44'],
    [0.19215686274509802, '#112b45'],
    [0.19607843137254902, '#112c47'],
    [0.2, '#122d48'],
    [0.20392156862745098, '#122e4a'],
    [0.20784313725490194, '#122f4b'],
    [0.21176470588235294, '#12304c'],
    [0.21568627450980393, '#13314e'],
    [0.2196078431372549, '#13324f'],
    [0.22352941176470587, '#133251'],
    [0.22745098039215686, '#143352'],
    [0.23137254901960785, '#143454'],
    [0.23529411764705882, '#143555'],
    [0.2392156862745098, '#143657'],
    [0.24313725490196078, '#153758'],
    [0.24705882352941178, '#15385a'],
    [0.25098039215686274, '#15395b'],
    [0.2549019607843137, '#163a5d'],
    [0.2588235294117647, '#163b5e'],
    [0.2627450980392157, '#173b60'],
    [0.26666666666666666, '#173c61'],
    [0.27058823529411763, '#173d63'],
    [0.27450980392156865, '#183e64'],
    [0.2784313725490196, '#183f66'],
    [0.2823529411764706, '#194067'],
    [0.28627450980392155, '#194169'],
    [0.2901960784313725, '#19426a'],
    [0.29411764705882354, '#1a436c'],
    [0.2980392156862745, '#1a446d'],
    [0.30196078431372547, '#1b456f'],
    [0.3058823529411765, '#1b4670'],
    [0.30980392156862746, '#1c4772'],
    [0.3137254901960784, '#1c4873'],
    [0.3176470588235294, '#1d4875'],
    [0.32156862745098036, '#1d4976'],
    [0.3254901960784314, '#1e4a78'],
    [0.32941176470588235, '#1e4b7a'],
    [0.3333333333333333, '#1f4c7b'],
    [0.33725490196078434, '#204d7d'],
    [0.3411764705882353, '#204e7e'],
    [0.34509803921568627, '#214f80'],
    [0.34901960784313724, '#215081'],
    [0.3529411764705882, '#225183'],
    [0.3568627450980392, '#235285'],
    [0.3607843137254902, '#235386'],
    [0.36470588235294116, '#245488'],
    [0.3686274509803922, '#255589'],
    [0.37254901960784315, '#26568b'],
    [0.3764705882352941, '#26578c'],
    [0.3803921568627451, '#27588e'],
    [0.38431372549019605, '#285990'],
    [0.38823529411764707, '#295a91'],
    [0.39215686274509803, '#2a5b93'],
    [0.396078431372549, '#2b5c94'],
    [0.4, '#2c5d96'],
    [0.403921568627451, '#2d5e98'],
    [0.40784313725490196, '#2e5f99'],
    [0.4117647058823529, '#2f609b'],
    [0.4156862745098039, '#30619c'],
    [0.4196078431372549, '#31629e'],
    [0.4235294117647059, '#3263a0'],
    [0.42745098039215684, '#3364a1'],
    [0.43137254901960786, '#3465a3'],
    [0.43529411764705883, '#3666a4'],
    [0.4392156862745098, '#3767a6'],
    [0.44313725490196076, '#3869a8'],
    [0.44705882352941173, '#3a6aa9'],
    [0.45098039215686275, '#3b6bab'],
    [0.4549019607843137, '#3d6cac'],
    [0.4588235294117647, '#3e6dae'],
    [0.4627450980392157, '#406eaf'],
    [0.4666666666666667, '#4170b1'],
    [0.47058823529411764, '#4371b2'],
    [0.4745098039215686, '#4472b3'],
    [0.4784313725490196, '#4673b5'],
    [0.4823529411764706, '#4775b6'],
    [0.48627450980392156, '#4976b8'],
    [0.49019607843137253, '#4b77b9'],
    [0.49411764705882355, '#4c78ba'],
    [0.4980392156862745, '#4e7abb'],
    [0.5019607843137255, '#507bbc'],
    [0.5058823529411764, '#517cbd'],
    [0.5098039215686274, '#537dbe'],
    [0.5137254901960784, '#547ebf'],
    [0.5176470588235293, '#567fc0'],
    [0.5215686274509804, '#5881c1'],
    [0.5254901960784314, '#5982c2'],
    [0.5294117647058824, '#5b83c3'],
    [0.5333333333333333, '#5c84c3'],
    [0.5372549019607843, '#5e85c4'],
    [0.5411764705882353, '#6086c5'],
    [0.5450980392156862, '#6187c5'],
    [0.5490196078431373, '#6388c6'],
    [0.5529411764705883, '#6489c6'],
    [0.5568627450980392, '#658ac7'],
    [0.5607843137254902, '#678bc7'],
    [0.5647058823529412, '#688cc7'],
    [0.5686274509803921, '#6a8dc8'],
    [0.5725490196078431, '#6b8ec8'],
    [0.5764705882352941, '#6c8fc8'],
    [0.580392156862745, '#6e90c8'],
    [0.5843137254901961, '#6f90c9'],
    [0.5882352941176471, '#7091c9'],
    [0.592156862745098, '#7292c9'],
    [0.596078431372549, '#7393c9'],
    [0.6, '#7494c9'],
    [0.6039215686274509, '#7594c9'],
    [0.6078431372549019, '#7795c9'],
    [0.611764705882353, '#7896c9'],
    [0.615686274509804, '#7997ca'],
    [0.6196078431372549, '#7a98ca'],
    [0.6235294117647059, '#7b98ca'],
    [0.6274509803921569, '#7d99ca'],
    [0.6313725490196078, '#7e9aca'],
    [0.6352941176470588, '#7f9bca'],
    [0.6392156862745098, '#809bca'],
    [0.6431372549019607, '#819cca'],
    [0.6470588235294118, '#839dca'],
    [0.6509803921568628, '#849eca'],
    [0.6549019607843137, '#859eca'],
    [0.6588235294117647, '#869fca'],
    [0.6627450980392157, '#87a0ca'],
    [0.6666666666666666, '#89a0ca'],
    [0.6705882352941176, '#8aa1ca'],
    [0.6745098039215687, '#8ba2c9'],
    [0.6784313725490196, '#8ca3c9'],
    [0.6823529411764706, '#8da3c9'],
    [0.6862745098039216, '#8ea4c9'],
    [0.6901960784313725, '#90a5c9'],
    [0.6941176470588235, '#91a6c9'],
    [0.6980392156862745, '#92a6c9'],
    [0.7019607843137254, '#93a7c9'],
    [0.7058823529411764, '#94a8c9'],
    [0.7098039215686275, '#96a9c9'],
    [0.7137254901960784, '#97a9c9'],
    [0.7176470588235294, '#98aac9'],
    [0.7215686274509804, '#99abc9'],
    [0.7254901960784313, '#9aacc9'],
    [0.7294117647058823, '#9cacc9'],
    [0.7333333333333333, '#9dadc9'],
    [0.7372549019607844, '#9eaec9'],
    [0.7411764705882353, '#9fafc9'],
    [0.7450980392156863, '#a0afc9'],
    [0.7490196078431373, '#a2b0ca'],
    [0.7529411764705882, '#a3b1ca'],
    [0.7568627450980392, '#a4b2ca'],
    [0.7607843137254902, '#a5b3ca'],
    [0.7647058823529411, '#a7b4ca'],
    [0.7686274509803921, '#a8b4ca'],
    [0.7725490196078432, '#a9b5ca'],
    [0.7764705882352941, '#aab6ca'],
    [0.7803921568627451, '#acb7ca'],
    [0.7843137254901961, '#adb8cb'],
    [0.788235294117647, '#aeb9cb'],
    [0.792156862745098, '#b0bacb'],
    [0.796078431372549, '#b1bbcb'],
    [0.8, '#b2bccc'],
    [0.803921568627451, '#b4bdcc'],
    [0.807843137254902, '#b5bdcc'],
    [0.8117647058823529, '#b6becd'],
    [0.8156862745098039, '#b8bfcd'],
    [0.8196078431372549, '#b9c1cd'],
    [0.8235294117647058, '#bac2ce'],
    [0.8274509803921568, '#bcc3ce'],
    [0.8313725490196078, '#bdc4cf'],
    [0.8352941176470589, '#bfc5cf'],
    [0.8392156862745098, '#c0c6d0'],
    [0.8431372549019608, '#c2c7d1'],
    [0.8470588235294118, '#c3c8d1'],
    [0.8509803921568627, '#c5c9d2'],
    [0.8549019607843137, '#c6cbd3'],
    [0.8588235294117647, '#c7ccd3'],
    [0.8627450980392157, '#c9cdd4'],
    [0.8666666666666667, '#caced5'],
    [0.8705882352941177, '#ccd0d6'],
    [0.8745098039215686, '#ced1d7'],
    [0.8784313725490196, '#cfd2d8'],
    [0.8823529411764706, '#d1d3d9'],
    [0.8862745098039215, '#d2d5da'],
    [0.8901960784313725, '#d4d6db'],
    [0.8941176470588235, '#d5d8dc'],
    [0.8980392156862745, '#d7d9dd'],
    [0.9019607843137255, '#d8dade'],
    [0.9058823529411765, '#dadcdf'],
    [0.9098039215686274, '#dbdde0'],
    [0.9137254901960784, '#dddee1'],
    [0.9176470588235294, '#dee0e2'],
    [0.9215686274509803, '#e0e1e4'],
    [0.9254901960784314, '#e2e3e5'],
    [0.9294117647058824, '#e3e4e6'],
    [0.9333333333333333, '#e5e6e7'],
    [0.9372549019607843, '#e6e7e9'],
    [0.9411764705882353, '#e8e9ea'],
    [0.9450980392156862, '#e9eaeb'],
    [0.9490196078431372, '#ebeced'],
    [0.9529411764705882, '#ecedee'],
    [0.9568627450980391, '#eeeeef'],
    [0.9607843137254902, '#eff0f1'],
    [0.9647058823529412, '#f1f1f2'],
    [0.9686274509803922, '#f3f3f4'],
    [0.9725490196078431, '#f4f4f5'],
    [0.9764705882352941, '#f6f6f6'],
    [0.9803921568627451, '#f7f7f8'],
    [0.984313725490196, '#f9f9f9'],
    [0.9882352941176471, '#fafafb'],
    [0.9921568627450981, '#fcfcfc'],
    [0.996078431372549, '#fdfdfe'],
    [1.0, '#ffffff'],
  ],
  lapaz: [
    [0.0, '#1a0c64'],
    [0.00392156862745098, '#1b0e65'],
    [0.00784313725490196, '#1b0f66'],
    [0.011764705882352941, '#1b1067'],
    [0.01568627450980392, '#1c1268'],
    [0.0196078431372549, '#1c1368'],
    [0.023529411764705882, '#1c1469'],
    [0.027450980392156862, '#1d166a'],
    [0.03137254901960784, '#1d176b'],
    [0.03529411764705882, '#1d186c'],
    [0.0392156862745098, '#1e196d'],
    [0.043137254901960784, '#1e1a6d'],
    [0.047058823529411764, '#1e1c6e'],
    [0.050980392156862744, '#1f1d6f'],
    [0.054901960784313725, '#1f1e70'],
    [0.058823529411764705, '#1f1f71'],
    [0.06274509803921569, '#202071'],
    [0.06666666666666667, '#202172'],
    [0.07058823529411765, '#202373'],
    [0.07450980392156863, '#202474'],
    [0.0784313725490196, '#212575'],
    [0.08235294117647059, '#212675'],
    [0.08627450980392157, '#212776'],
    [0.09019607843137255, '#212877'],
    [0.09411764705882353, '#222978'],
    [0.09803921568627451, '#222a79'],
    [0.10196078431372549, '#222b79'],
    [0.10588235294117647, '#222c7a'],
    [0.10980392156862745, '#232d7b'],
    [0.11372549019607843, '#232f7c'],
    [0.11764705882352941, '#23307c'],
    [0.12156862745098039, '#23317d'],
    [0.12549019607843137, '#24327e'],
    [0.12941176470588234, '#24337f'],
    [0.13333333333333333, '#24347f'],
    [0.13725490196078433, '#243580'],
    [0.1411764705882353, '#253681'],
    [0.14509803921568626, '#253782'],
    [0.14901960784313725, '#253882'],
    [0.15294117647058825, '#253983'],
    [0.1568627450980392, '#263a84'],
    [0.16078431372549018, '#263b84'],
    [0.16470588235294117, '#263c85'],
    [0.16862745098039217, '#263d86'],
    [0.17254901960784313, '#273e87'],
    [0.1764705882352941, '#273f87'],
    [0.1803921568627451, '#274088'],
    [0.1843137254901961, '#274189'],
    [0.18823529411764706, '#284289'],
    [0.19215686274509802, '#28438a'],
    [0.19607843137254902, '#28458b'],
    [0.2, '#28468b'],
    [0.20392156862745098, '#29478c'],
    [0.20784313725490194, '#29488c'],
    [0.21176470588235294, '#29498d'],
    [0.21568627450980393, '#2a4a8e'],
    [0.2196078431372549, '#2a4b8e'],
    [0.22352941176470587, '#2a4c8f'],
    [0.22745098039215686, '#2b4d90'],
    [0.23137254901960785, '#2b4e90'],
    [0.23529411764705882, '#2b4f91'],
    [0.2392156862745098, '#2c5091'],
    [0.24313725490196078, '#2c5192'],
    [0.24705882352941178, '#2c5292'],
    [0.25098039215686274, '#2d5393'],
    [0.2549019607843137, '#2d5494'],
    [0.2588235294117647, '#2d5594'],
    [0.2627450980392157, '#2e5695'],
    [0.26666666666666666, '#2e5795'],
    [0.27058823529411763, '#2e5896'],
    [0.27450980392156865, '#2f5996'],
    [0.2784313725490196, '#2f5a97'],
    [0.2823529411764706, '#305b97'],
    [0.28627450980392155, '#305c98'],
    [0.2901960784313725, '#305d98'],
    [0.29411764705882354, '#315e99'],
    [0.2980392156862745, '#315e99'],
    [0.30196078431372547, '#325f99'],
    [0.3058823529411765, '#32609a'],
    [0.30980392156862746, '#33619a'],
    [0.3137254901960784, '#33629b'],
    [0.3176470588235294, '#34639b'],
    [0.32156862745098036, '#34649c'],
    [0.3254901960784314, '#35659c'],
    [0.32941176470588235, '#35669c'],
    [0.3333333333333333, '#36679d'],
    [0.33725490196078434, '#37689d'],
    [0.3411764705882353, '#37699d'],
    [0.34509803921568627, '#386a9e'],
    [0.34901960784313724, '#386b9e'],
    [0.3529411764705882, '#396c9e'],
    [0.3568627450980392, '#3a6d9f'],
    [0.3607843137254902, '#3a6e9f'],
    [0.36470588235294116, '#3b6f9f'],
    [0.3686274509803922, '#3c70a0'],
    [0.37254901960784315, '#3c70a0'],
    [0.3764705882352941, '#3d71a0'],
    [0.3803921568627451, '#3e72a1'],
    [0.38431372549019605, '#3e73a1'],
    [0.38823529411764707, '#3f74a1'],
    [0.39215686274509803, '#4075a1'],
    [0.396078431372549, '#4176a2'],
    [0.4, '#4277a2'],
    [0.403921568627451, '#4278a2'],
    [0.40784313725490196, '#4379a2'],
    [0.4117647058823529, '#447aa2'],
    [0.4156862745098039, '#457aa2'],
    [0.4196078431372549, '#467ba3'],
    [0.4235294117647059, '#477ca3'],
    [0.42745098039215684, '#487da3'],
    [0.43137254901960786, '#497ea3'],
    [0.43529411764705883, '#4a7fa3'],
    [0.4392156862745098, '#4b80a3'],
    [0.44313725490196076, '#4c80a3'],
    [0.44705882352941173, '#4d81a3'],
    [0.45098039215686275, '#4e82a3'],
    [0.4549019607843137, '#4f83a4'],
    [0.4588235294117647, '#5084a4'],
    [0.4627450980392157, '#5185a4'],
    [0.4666666666666667, '#5285a4'],
    [0.47058823529411764, '#5386a4'],
    [0.4745098039215686, '#5487a4'],
    [0.4784313725490196, '#5588a4'],
    [0.4823529411764706, '#5688a4'],
    [0.48627450980392156, '#5789a4'],
    [0.49019607843137253, '#588aa3'],
    [0.49411764705882355, '#5a8ba3'],
    [0.4980392156862745, '#5b8ba3'],
    [0.5019607843137255, '#5c8ca3'],
    [0.5058823529411764, '#5d8da3'],
    [0.5098039215686274, '#5e8ea3'],
    [0.5137254901960784, '#608ea3'],
    [0.5176470588235293, '#618fa3'],
    [0.5215686274509804, '#6290a3'],
    [0.5254901960784314, '#6390a2'],
    [0.5294117647058824, '#6591a2'],
    [0.5333333333333333, '#6692a2'],
    [0.5372549019607843, '#6792a2'],
    [0.5411764705882353, '#6893a2'],
    [0.5450980392156862, '#6a94a1'],
    [0.5490196078431373, '#6b94a1'],
    [0.5529411764705883, '#6c95a1'],
    [0.5568627450980392, '#6e95a1'],
    [0.5607843137254902, '#6f96a1'],
    [0.5647058823529412, '#7097a0'],
    [0.5686274509803921, '#7297a0'],
    [0.5725490196078431, '#7398a0'],
    [0.5764705882352941, '#74989f'],
    [0.580392156862745, '#76999f'],
    [0.5843137254901961, '#77999f'],
    [0.5882352941176471, '#789a9f'],
    [0.592156862745098, '#7a9a9e'],
    [0.596078431372549, '#7b9b9e'],
    [0.6, '#7c9b9e'],
    [0.6039215686274509, '#7e9c9d'],
    [0.6078431372549019, '#7f9c9d'],
    [0.611764705882353, '#809d9d'],
    [0.615686274509804, '#829d9c'],
    [0.6196078431372549, '#839e9c'],
    [0.6235294117647059, '#859e9c'],
    [0.6274509803921569, '#869e9b'],
    [0.6313725490196078, '#879f9b'],
    [0.6352941176470588, '#899f9a'],
    [0.6392156862745098, '#8aa09a'],
    [0.6431372549019607, '#8ba09a'],
    [0.6470588235294118, '#8da099'],
    [0.6509803921568628, '#8ea199'],
    [0.6549019607843137, '#90a199'],
    [0.6588235294117647, '#91a298'],
    [0.6627450980392157, '#92a298'],
    [0.6666666666666666, '#94a298'],
    [0.6705882352941176, '#95a398'],
    [0.6745098039215687, '#97a397'],
    [0.6784313725490196, '#98a497'],
    [0.6823529411764706, '#99a497'],
    [0.6862745098039216, '#9ba496'],
    [0.6901960784313725, '#9ca596'],
    [0.6941176470588235, '#9ea596'],
    [0.6980392156862745, '#9fa696'],
    [0.7019607843137254, '#a1a696'],
    [0.7058823529411764, '#a2a696'],
    [0.7098039215686275, '#a4a795'],
    [0.7137254901960784, '#a5a795'],
    [0.7176470588235294, '#a7a895'],
    [0.7215686274509804, '#a8a895'],
    [0.7254901960784313, '#aaa995'],
    [0.7294117647058823, '#aba995'],
    [0.7333333333333333, '#adaa95'],
    [0.7372549019607844, '#aeaa95'],
    [0.7411764705882353, '#b0ab95'],
    [0.7450980392156863, '#b2ab96'],
    [0.7490196078431373, '#b3ac96'],
    [0.7529411764705882, '#b5ad96'],
    [0.7568627450980392, '#b7ad96'],
    [0.7607843137254902, '#b8ae97'],
    [0.7647058823529411, '#baaf97'],
    [0.7686274509803921, '#bcb098'],
    [0.7725490196078432, '#beb098'],
    [0.7764705882352941, '#bfb199'],
    [0.7803921568627451, '#c1b299'],
    [0.7843137254901961, '#c3b39a'],
    [0.788235294117647, '#c5b49b'],
    [0.792156862745098, '#c7b59c'],
    [0.796078431372549, '#c8b69c'],
    [0.8, '#cab79d'],
    [0.803921568627451, '#ccb89e'],
    [0.807843137254902, '#ceb99f'],
    [0.8117647058823529, '#d0baa0'],
    [0.8156862745098039, '#d2bba2'],
    [0.8196078431372549, '#d3bca3'],
    [0.8235294117647058, '#d5bda4'],
    [0.8274509803921568, '#d7bea6'],
    [0.8313725490196078, '#d9c0a7'],
    [0.8352941176470589, '#dac1a8'],
    [0.8392156862745098, '#dcc2aa'],
    [0.8431372549019608, '#dec3ab'],
    [0.8470588235294118, '#dfc5ad'],
    [0.8509803921568627, '#e1c6af'],
    [0.8549019607843137, '#e3c7b0'],
    [0.8588235294117647, '#e4c9b2'],
    [0.8627450980392157, '#e6cab4'],
    [0.8666666666666667, '#e7cbb5'],
    [0.8705882352941177, '#e8ccb7'],
    [0.8745098039215686, '#eaceb9'],
    [0.8784313725490196, '#ebcfbb'],
    [0.8823529411764706, '#ecd0bd'],
    [0.8862745098039215, '#edd2be'],
    [0.8901960784313725, '#efd3c0'],
    [0.8941176470588235, '#f0d4c2'],
    [0.8980392156862745, '#f1d5c4'],
    [0.9019607843137255, '#f2d7c6'],
    [0.9058823529411765, '#f3d8c8'],
    [0.9098039215686274, '#f4d9c9'],
    [0.9137254901960784, '#f4dacb'],
    [0.9176470588235294, '#f5dbcd'],
    [0.9215686274509803, '#f6ddcf'],
    [0.9254901960784314, '#f7ded1'],
    [0.9294117647058824, '#f7dfd3'],
    [0.9333333333333333, '#f8e0d4'],
    [0.9372549019607843, '#f9e1d6'],
    [0.9411764705882353, '#f9e2d8'],
    [0.9450980392156862, '#fae3da'],
    [0.9490196078431372, '#fae5dc'],
    [0.9529411764705882, '#fbe6dd'],
    [0.9568627450980391, '#fbe7df'],
    [0.9607843137254902, '#fbe8e1'],
    [0.9647058823529412, '#fce9e3'],
    [0.9686274509803922, '#fceae5'],
    [0.9725490196078431, '#fcebe6'],
    [0.9764705882352941, '#fdece8'],
    [0.9803921568627451, '#fdedea'],
    [0.984313725490196, '#fdeeec'],
    [0.9882352941176471, '#feefed'],
    [0.9921568627450981, '#fef0ef'],
    [0.996078431372549, '#fef1f1'],
    [1.0, '#fef2f3'],
  ],
  brocO: [
    [0.0, '#372f38'],
    [0.00392156862745098, '#362f39'],
    [0.00784313725490196, '#362f3a'],
    [0.011764705882352941, '#362f3b'],
    [0.01568627450980392, '#362f3c'],
    [0.0196078431372549, '#36303d'],
    [0.023529411764705882, '#36303f'],
    [0.027450980392156862, '#363040'],
    [0.03137254901960784, '#363041'],
    [0.03529411764705882, '#363143'],
    [0.0392156862745098, '#363144'],
    [0.043137254901960784, '#363246'],
    [0.047058823529411764, '#363247'],
    [0.050980392156862744, '#363349'],
    [0.054901960784313725, '#36334a'],
    [0.058823529411764705, '#36344c'],
    [0.06274509803921569, '#36354e'],
    [0.06666666666666667, '#363550'],
    [0.07058823529411765, '#363651'],
    [0.07450980392156863, '#363753'],
    [0.0784313725490196, '#363855'],
    [0.08235294117647059, '#363957'],
    [0.08627450980392157, '#373a59'],
    [0.09019607843137255, '#373b5b'],
    [0.09411764705882353, '#373c5c'],
    [0.09803921568627451, '#373d5e'],
    [0.10196078431372549, '#373f60'],
    [0.10588235294117647, '#384062'],
    [0.10980392156862745, '#384164'],
    [0.11372549019607843, '#384366'],
    [0.11764705882352941, '#394468'],
    [0.12156862745098039, '#39456a'],
    [0.12549019607843137, '#3a476c'],
    [0.12941176470588234, '#3a486f'],
    [0.13333333333333333, '#3b4a71'],
    [0.13725490196078433, '#3b4c73'],
    [0.1411764705882353, '#3c4d75'],
    [0.14509803921568626, '#3d4f77'],
    [0.14901960784313725, '#3e5179'],
    [0.15294117647058825, '#3e527b'],
    [0.1568627450980392, '#3f547c'],
    [0.16078431372549018, '#40567e'],
    [0.16470588235294117, '#415780'],
    [0.16862745098039217, '#425982'],
    [0.17254901960784313, '#435b84'],
    [0.1764705882352941, '#445d86'],
    [0.1803921568627451, '#455e88'],
    [0.1843137254901961, '#47608a'],
    [0.18823529411764706, '#48628b'],
    [0.19215686274509802, '#49648d'],
    [0.19607843137254902, '#4a668f'],
    [0.2, '#4c6790'],
    [0.20392156862745098, '#4d6992'],
    [0.20784313725490194, '#4f6b94'],
    [0.21176470588235294, '#506d95'],
    [0.21568627450980393, '#526f97'],
    [0.2196078431372549, '#537099'],
    [0.22352941176470587, '#55729a'],
    [0.22745098039215686, '#56749c'],
    [0.23137254901960785, '#58769d'],
    [0.23529411764705882, '#5a789f'],
    [0.2392156862745098, '#5b79a0'],
    [0.24313725490196078, '#5d7ba2'],
    [0.24705882352941178, '#5f7da3'],
    [0.25098039215686274, '#617fa5'],
    [0.2549019607843137, '#6381a6'],
    [0.2588235294117647, '#6482a7'],
    [0.2627450980392157, '#6684a9'],
    [0.26666666666666666, '#6886aa'],
    [0.27058823529411763, '#6a88ab'],
    [0.27450980392156865, '#6c89ad'],
    [0.2784313725490196, '#6e8bae'],
    [0.2823529411764706, '#708daf'],
    [0.28627450980392155, '#728fb1'],
    [0.2901960784313725, '#7490b2'],
    [0.29411764705882354, '#7692b3'],
    [0.2980392156862745, '#7894b4'],
    [0.30196078431372547, '#7a96b6'],
    [0.3058823529411765, '#7c97b7'],
    [0.30980392156862746, '#7e99b8'],
    [0.3137254901960784, '#809bb9'],
    [0.3176470588235294, '#829dba'],
    [0.32156862745098036, '#849ebb'],
    [0.3254901960784314, '#86a0bd'],
    [0.32941176470588235, '#88a2be'],
    [0.3333333333333333, '#8aa3bf'],
    [0.33725490196078434, '#8ca5c0'],
    [0.3411764705882353, '#8ea7c1'],
    [0.34509803921568627, '#91a8c2'],
    [0.34901960784313724, '#93aac3'],
    [0.3529411764705882, '#95acc4'],
    [0.3568627450980392, '#97adc5'],
    [0.3607843137254902, '#99afc6'],
    [0.36470588235294116, '#9bb1c7'],
    [0.3686274509803922, '#9db2c8'],
    [0.37254901960784315, '#9fb4c8'],
    [0.3764705882352941, '#a1b5c9'],
    [0.3803921568627451, '#a3b7ca'],
    [0.38431372549019605, '#a5b8cb'],
    [0.38823529411764707, '#a7bacc'],
    [0.39215686274509803, '#a9bbcc'],
    [0.396078431372549, '#abbdcd'],
    [0.4, '#adbecd'],
    [0.403921568627451, '#afc0ce'],
    [0.40784313725490196, '#b1c1ce'],
    [0.4117647058823529, '#b3c2cf'],
    [0.4156862745098039, '#b5c4cf'],
    [0.4196078431372549, '#b7c5d0'],
    [0.4235294117647059, '#b8c6d0'],
    [0.42745098039215684, '#bac8d0'],
    [0.43137254901960786, '#bcc9d0'],
    [0.43529411764705883, '#bdcad0'],
    [0.4392156862745098, '#bfcbd0'],
    [0.44313725490196076, '#c1ccd0'],
    [0.44705882352941173, '#c2cdd0'],
    [0.45098039215686275, '#c4ced0'],
    [0.4549019607843137, '#c5cfcf'],
    [0.4588235294117647, '#c6cfcf'],
    [0.4627450980392157, '#c7d0ce'],
    [0.4666666666666667, '#c9d1ce'],
    [0.47058823529411764, '#cad1cd'],
    [0.4745098039215686, '#cbd2cc'],
    [0.4784313725490196, '#cbd2cb'],
    [0.4823529411764706, '#ccd3cb'],
    [0.48627450980392156, '#cdd3ca'],
    [0.49019607843137253, '#ced3c8'],
    [0.49411764705882355, '#ced3c7'],
    [0.4980392156862745, '#cfd3c6'],
    [0.5019607843137255, '#cfd3c5'],
    [0.5058823529411764, '#cfd3c3'],
    [0.5098039215686274, '#cfd3c2'],
    [0.5137254901960784, '#cfd3c0'],
    [0.5176470588235293, '#cfd3be'],
    [0.5215686274509804, '#cfd2bd'],
    [0.5254901960784314, '#cfd2bb'],
    [0.5294117647058824, '#cfd1b9'],
    [0.5333333333333333, '#ced0b7'],
    [0.5372549019607843, '#ced0b5'],
    [0.5411764705882353, '#cdcfb3'],
    [0.5450980392156862, '#cdceb1'],
    [0.5490196078431373, '#cccdaf'],
    [0.5529411764705883, '#cbccad'],
    [0.5568627450980392, '#cacbab'],
    [0.5607843137254902, '#c9caa9'],
    [0.5647058823529412, '#c8c9a6'],
    [0.5686274509803921, '#c7c8a4'],
    [0.5725490196078431, '#c6c6a2'],
    [0.5764705882352941, '#c5c5a0'],
    [0.580392156862745, '#c4c49d'],
    [0.5843137254901961, '#c2c29b'],
    [0.5882352941176471, '#c1c199'],
    [0.592156862745098, '#bfbf96'],
    [0.596078431372549, '#bebe94'],
    [0.6, '#bcbc92'],
    [0.6039215686274509, '#bbbb90'],
    [0.6078431372549019, '#b9b98d'],
    [0.611764705882353, '#b8b78b'],
    [0.615686274509804, '#b6b689'],
    [0.6196078431372549, '#b4b486'],
    [0.6235294117647059, '#b3b284'],
    [0.6274509803921569, '#b1b082'],
    [0.6313725490196078, '#afaf7f'],
    [0.6352941176470588, '#adad7d'],
    [0.6392156862745098, '#abab7b'],
    [0.6431372549019607, '#aaa979'],
    [0.6470588235294118, '#a8a777'],
    [0.6509803921568628, '#a6a574'],
    [0.6549019607843137, '#a4a472'],
    [0.6588235294117647, '#a2a270'],
    [0.6627450980392157, '#a0a06e'],
    [0.6666666666666666, '#9e9e6c'],
    [0.6705882352941176, '#9d9c6a'],
    [0.6745098039215687, '#9b9a68'],
    [0.6784313725490196, '#999866'],
    [0.6823529411764706, '#979664'],
    [0.6862745098039216, '#959462'],
    [0.6901960784313725, '#939260'],
    [0.6941176470588235, '#91915e'],
    [0.6980392156862745, '#8f8f5c'],
    [0.7019607843137254, '#8d8d5a'],
    [0.7058823529411764, '#8b8b59'],
    [0.7098039215686275, '#898957'],
    [0.7137254901960784, '#888755'],
    [0.7176470588235294, '#868553'],
    [0.7215686274509804, '#848351'],
    [0.7254901960784313, '#828150'],
    [0.7294117647058823, '#807f4e'],
    [0.7333333333333333, '#7e7e4c'],
    [0.7372549019607844, '#7c7c4b'],
    [0.7411764705882353, '#7b7a49'],
    [0.7450980392156863, '#797848'],
    [0.7490196078431373, '#777646'],
    [0.7529411764705882, '#757445'],
    [0.7568627450980392, '#737243'],
    [0.7607843137254902, '#727142'],
    [0.7647058823529411, '#706f41'],
    [0.7686274509803921, '#6e6d3f'],
    [0.7725490196078432, '#6c6b3e'],
    [0.7764705882352941, '#6b693d'],
    [0.7803921568627451, '#69683b'],
    [0.7843137254901961, '#67663a'],
    [0.788235294117647, '#666439'],
    [0.792156862745098, '#646238'],
    [0.796078431372549, '#626137'],
    [0.8, '#615f36'],
    [0.803921568627451, '#5f5d35'],
    [0.807843137254902, '#5e5c34'],
    [0.8117647058823529, '#5c5a33'],
    [0.8156862745098039, '#5b5932'],
    [0.8196078431372549, '#595731'],
    [0.8235294117647058, '#585530'],
    [0.8274509803921568, '#56542f'],
    [0.8313725490196078, '#55522e'],
    [0.8352941176470589, '#54512e'],
    [0.8392156862745098, '#524f2d'],
    [0.8431372549019608, '#514e2d'],
    [0.8470588235294118, '#504d2c'],
    [0.8509803921568627, '#4f4b2b'],
    [0.8549019607843137, '#4d4a2b'],
    [0.8588235294117647, '#4c482b'],
    [0.8627450980392157, '#4b472a'],
    [0.8666666666666667, '#4a462a'],
    [0.8705882352941177, '#49452a'],
    [0.8745098039215686, '#484329'],
    [0.8784313725490196, '#474229'],
    [0.8823529411764706, '#464129'],
    [0.8862745098039215, '#454029'],
    [0.8901960784313725, '#443f29'],
    [0.8941176470588235, '#433e29'],
    [0.8980392156862745, '#423d29'],
    [0.9019607843137255, '#423c29'],
    [0.9058823529411765, '#413b29'],
    [0.9098039215686274, '#403a29'],
    [0.9137254901960784, '#3f3929'],
    [0.9176470588235294, '#3f3829'],
    [0.9215686274509803, '#3e382a'],
    [0.9254901960784314, '#3e372a'],
    [0.9294117647058824, '#3d362a'],
    [0.9333333333333333, '#3c352b'],
    [0.9372549019607843, '#3c352b'],
    [0.9411764705882353, '#3b342b'],
    [0.9450980392156862, '#3b342c'],
    [0.9490196078431372, '#3b332c'],
    [0.9529411764705882, '#3a332d'],
    [0.9568627450980391, '#3a322e'],
    [0.9607843137254902, '#39322e'],
    [0.9647058823529412, '#39312f'],
    [0.9686274509803922, '#393130'],
    [0.9725490196078431, '#383030'],
    [0.9764705882352941, '#383031'],
    [0.9803921568627451, '#383032'],
    [0.984313725490196, '#383033'],
    [0.9882352941176471, '#372f34'],
    [0.9921568627450981, '#372f35'],
    [0.996078431372549, '#372f36'],
    [1.0, '#372f37'],
  ],
  hawaii: [
    [0.0, '#8c0273'],
    [0.00392156862745098, '#8d0472'],
    [0.00784313725490196, '#8d0671'],
    [0.011764705882352941, '#8d0870'],
    [0.01568627450980392, '#8d0a6f'],
    [0.0196078431372549, '#8e0d6e'],
    [0.023529411764705882, '#8e0e6d'],
    [0.027450980392156862, '#8e106c'],
    [0.03137254901960784, '#8e126b'],
    [0.03529411764705882, '#8e136a'],
    [0.0392156862745098, '#8f1569'],
    [0.043137254901960784, '#8f1668'],
    [0.047058823529411764, '#8f1867'],
    [0.050980392156862744, '#8f1966'],
    [0.054901960784313725, '#8f1a65'],
    [0.058823529411764705, '#901c64'],
    [0.06274509803921569, '#901d63'],
    [0.06666666666666667, '#901e63'],
    [0.07058823529411765, '#901f62'],
    [0.07450980392156863, '#902061'],
    [0.0784313725490196, '#902160'],
    [0.08235294117647059, '#91235f'],
    [0.08627450980392157, '#91245e'],
    [0.09019607843137255, '#91255d'],
    [0.09411764705882353, '#91265c'],
    [0.09803921568627451, '#91275b'],
    [0.10196078431372549, '#91285a'],
    [0.10588235294117647, '#92295a'],
    [0.10980392156862745, '#922a59'],
    [0.11372549019607843, '#922b58'],
    [0.11764705882352941, '#922c57'],
    [0.12156862745098039, '#922d56'],
    [0.12549019607843137, '#922e55'],
    [0.12941176470588234, '#922f54'],
    [0.13333333333333333, '#933054'],
    [0.13725490196078433, '#933153'],
    [0.1411764705882353, '#933252'],
    [0.14509803921568626, '#933351'],
    [0.14901960784313725, '#933450'],
    [0.15294117647058825, '#933550'],
    [0.1568627450980392, '#93364f'],
    [0.16078431372549018, '#94374e'],
    [0.16470588235294117, '#94384d'],
    [0.16862745098039217, '#94394d'],
    [0.17254901960784313, '#943a4c'],
    [0.1764705882352941, '#943b4b'],
    [0.1803921568627451, '#943c4a'],
    [0.1843137254901961, '#943d4a'],
    [0.18823529411764706, '#943e49'],
    [0.19215686274509802, '#953f48'],
    [0.19607843137254902, '#954047'],
    [0.2, '#954147'],
    [0.20392156862745098, '#954246'],
    [0.20784313725490194, '#954345'],
    [0.21176470588235294, '#954445'],
    [0.21568627450980393, '#954544'],
    [0.2196078431372549, '#964643'],
    [0.22352941176470587, '#964742'],
    [0.22745098039215686, '#964842'],
    [0.23137254901960785, '#964941'],
    [0.23529411764705882, '#964a40'],
    [0.2392156862745098, '#964b40'],
    [0.24313725490196078, '#964c3f'],
    [0.24705882352941178, '#964d3e'],
    [0.25098039215686274, '#974e3e'],
    [0.2549019607843137, '#974f3d'],
    [0.2588235294117647, '#97503c'],
    [0.2627450980392157, '#97513c'],
    [0.26666666666666666, '#97523b'],
    [0.27058823529411763, '#97533a'],
    [0.27450980392156865, '#97533a'],
    [0.2784313725490196, '#975439'],
    [0.2823529411764706, '#985538'],
    [0.28627450980392155, '#985638'],
    [0.2901960784313725, '#985737'],
    [0.29411764705882354, '#985836'],
    [0.2980392156862745, '#985936'],
    [0.30196078431372547, '#985a35'],
    [0.3058823529411765, '#985c34'],
    [0.30980392156862746, '#995d34'],
    [0.3137254901960784, '#995e33'],
    [0.3176470588235294, '#995f32'],
    [0.32156862745098036, '#996032'],
    [0.3254901960784314, '#996131'],
    [0.32941176470588235, '#996230'],
    [0.3333333333333333, '#996330'],
    [0.33725490196078434, '#99642f'],
    [0.3411764705882353, '#9a652e'],
    [0.34509803921568627, '#9a662e'],
    [0.34901960784313724, '#9a672d'],
    [0.3529411764705882, '#9a682c'],
    [0.3568627450980392, '#9a692c'],
    [0.3607843137254902, '#9a6a2b'],
    [0.36470588235294116, '#9a6b2a'],
    [0.3686274509803922, '#9b6c2a'],
    [0.37254901960784315, '#9b6d29'],
    [0.3764705882352941, '#9b6f28'],
    [0.3803921568627451, '#9b7028'],
    [0.38431372549019605, '#9b7127'],
    [0.38823529411764707, '#9b7226'],
    [0.39215686274509803, '#9b7326'],
    [0.396078431372549, '#9b7425'],
    [0.4, '#9c7524'],
    [0.403921568627451, '#9c7724'],
    [0.40784313725490196, '#9c7823'],
    [0.4117647058823529, '#9c7923'],
    [0.4156862745098039, '#9c7a22'],
    [0.4196078431372549, '#9c7b21'],
    [0.4235294117647059, '#9c7d21'],
    [0.42745098039215684, '#9c7e20'],
    [0.43137254901960786, '#9c7f20'],
    [0.43529411764705883, '#9c801f'],
    [0.4392156862745098, '#9d811f'],
    [0.44313725490196076, '#9d831e'],
    [0.44705882352941173, '#9d841e'],
    [0.45098039215686275, '#9d851d'],
    [0.4549019607843137, '#9d871d'],
    [0.4588235294117647, '#9d881d'],
    [0.4627450980392157, '#9d891c'],
    [0.4666666666666667, '#9d8a1c'],
    [0.47058823529411764, '#9d8c1c'],
    [0.4745098039215686, '#9d8d1c'],
    [0.4784313725490196, '#9d8e1c'],
    [0.4823529411764706, '#9d901c'],
    [0.48627450980392156, '#9c911c'],
    [0.49019607843137253, '#9c921c'],
    [0.49411764705882355, '#9c941c'],
    [0.4980392156862745, '#9c951c'],
    [0.5019607843137255, '#9c961c'],
    [0.5058823529411764, '#9c981d'],
    [0.5098039215686274, '#9b991d'],
    [0.5137254901960784, '#9b9a1e'],
    [0.5176470588235293, '#9b9c1f'],
    [0.5215686274509804, '#9b9d1f'],
    [0.5254901960784314, '#9a9f20'],
    [0.5294117647058824, '#9aa021'],
    [0.5333333333333333, '#9aa122'],
    [0.5372549019607843, '#99a323'],
    [0.5411764705882353, '#99a424'],
    [0.5450980392156862, '#98a526'],
    [0.5490196078431373, '#98a627'],
    [0.5529411764705883, '#97a828'],
    [0.5568627450980392, '#97a92a'],
    [0.5607843137254902, '#96aa2b'],
    [0.5647058823529412, '#95ab2d'],
    [0.5686274509803921, '#95ad2e'],
    [0.5725490196078431, '#94ae30'],
    [0.5764705882352941, '#93af32'],
    [0.580392156862745, '#93b033'],
    [0.5843137254901961, '#92b135'],
    [0.5882352941176471, '#91b337'],
    [0.592156862745098, '#91b439'],
    [0.596078431372549, '#90b53b'],
    [0.6, '#8fb63c'],
    [0.6039215686274509, '#8eb73e'],
    [0.6078431372549019, '#8db840'],
    [0.611764705882353, '#8cb942'],
    [0.615686274509804, '#8cba44'],
    [0.6196078431372549, '#8bbb46'],
    [0.6235294117647059, '#8abc48'],
    [0.6274509803921569, '#89bd4a'],
    [0.6313725490196078, '#88be4c'],
    [0.6352941176470588, '#87be4e'],
    [0.6392156862745098, '#86bf50'],
    [0.6431372549019607, '#85c052'],
    [0.6470588235294118, '#84c154'],
    [0.6509803921568628, '#83c257'],
    [0.6549019607843137, '#82c359'],
    [0.6588235294117647, '#82c35b'],
    [0.6627450980392157, '#81c45d'],
    [0.6666666666666666, '#80c55f'],
    [0.6705882352941176, '#7fc661'],
    [0.6745098039215687, '#7ec663'],
    [0.6784313725490196, '#7dc765'],
    [0.6823529411764706, '#7cc867'],
    [0.6862745098039216, '#7bc969'],
    [0.6901960784313725, '#7ac96c'],
    [0.6941176470588235, '#79ca6e'],
    [0.6980392156862745, '#78cb70'],
    [0.7019607843137254, '#77cc72'],
    [0.7058823529411764, '#76cc74'],
    [0.7098039215686275, '#75cd76'],
    [0.7137254901960784, '#74ce79'],
    [0.7176470588235294, '#73ce7b'],
    [0.7215686274509804, '#72cf7d'],
    [0.7254901960784313, '#71d07f'],
    [0.7294117647058823, '#70d081'],
    [0.7333333333333333, '#70d183'],
    [0.7372549019607844, '#6fd286'],
    [0.7411764705882353, '#6ed288'],
    [0.7450980392156863, '#6dd38a'],
    [0.7490196078431373, '#6cd48c'],
    [0.7529411764705882, '#6bd48e'],
    [0.7568627450980392, '#6ad591'],
    [0.7607843137254902, '#69d693'],
    [0.7647058823529411, '#68d695'],
    [0.7686274509803921, '#68d797'],
    [0.7725490196078432, '#67d899'],
    [0.7764705882352941, '#66d89c'],
    [0.7803921568627451, '#65d99e'],
    [0.7843137254901961, '#64daa0'],
    [0.788235294117647, '#64daa2'],
    [0.792156862745098, '#63dba5'],
    [0.796078431372549, '#62dca7'],
    [0.8, '#62dca9'],
    [0.803921568627451, '#61ddab'],
    [0.807843137254902, '#61deae'],
    [0.8117647058823529, '#60deb0'],
    [0.8156862745098039, '#60dfb2'],
    [0.8196078431372549, '#60e0b4'],
    [0.8235294117647058, '#5fe0b7'],
    [0.8274509803921568, '#5fe1b9'],
    [0.8313725490196078, '#5fe2bb'],
    [0.8352941176470589, '#5fe2bd'],
    [0.8392156862745098, '#5fe3c0'],
    [0.8431372549019608, '#5fe4c2'],
    [0.8470588235294118, '#60e4c4'],
    [0.8509803921568627, '#60e5c6'],
    [0.8549019607843137, '#61e5c8'],
    [0.8588235294117647, '#61e6ca'],
    [0.8627450980392157, '#62e7cd'],
    [0.8666666666666667, '#63e7cf'],
    [0.8705882352941177, '#64e8d1'],
    [0.8745098039215686, '#66e8d3'],
    [0.8784313725490196, '#67e9d5'],
    [0.8823529411764706, '#68e9d7'],
    [0.8862745098039215, '#6aead9'],
    [0.8901960784313725, '#6cebdb'],
    [0.8941176470588235, '#6debdc'],
    [0.8980392156862745, '#6febde'],
    [0.9019607843137255, '#71ece0'],
    [0.9058823529411765, '#73ece2'],
    [0.9098039215686274, '#76ede3'],
    [0.9137254901960784, '#78ede5'],
    [0.9176470588235294, '#7aeee7'],
    [0.9215686274509803, '#7deee8'],
    [0.9254901960784314, '#7feeea'],
    [0.9294117647058824, '#82efeb'],
    [0.9333333333333333, '#84efec'],
    [0.9372549019607843, '#87efee'],
    [0.9411764705882353, '#8af0ef'],
    [0.9450980392156862, '#8df0f0'],
    [0.9490196078431372, '#8ff0f1'],
    [0.9529411764705882, '#92f0f3'],
    [0.9568627450980391, '#95f1f4'],
    [0.9607843137254902, '#98f1f5'],
    [0.9647058823529412, '#9af1f6'],
    [0.9686274509803922, '#9df1f7'],
    [0.9725490196078431, '#a0f1f8'],
    [0.9764705882352941, '#a3f1f9'],
    [0.9803921568627451, '#a6f1f9'],
    [0.984313725490196, '#a8f2fa'],
    [0.9882352941176471, '#abf2fb'],
    [0.9921568627450981, '#aef2fc'],
    [0.996078431372549, '#b1f2fd'],
    [1.0, '#b3f2fd'],
  ],
  tofino: [
    [0.0, '#ded9ff'],
    [0.00392156862745098, '#dbd7fe'],
    [0.00784313725490196, '#d9d5fd'],
    [0.011764705882352941, '#d6d3fb'],
    [0.01568627450980392, '#d3d1fa'],
    [0.0196078431372549, '#d0cff9'],
    [0.023529411764705882, '#cecdf8'],
    [0.027450980392156862, '#cbcbf7'],
    [0.03137254901960784, '#c8caf6'],
    [0.03529411764705882, '#c6c8f4'],
    [0.0392156862745098, '#c3c6f3'],
    [0.043137254901960784, '#c0c4f2'],
    [0.047058823529411764, '#bec2f1'],
    [0.050980392156862744, '#bbc0f0'],
    [0.054901960784313725, '#b8beef'],
    [0.058823529411764705, '#b6bced'],
    [0.06274509803921569, '#b3bbec'],
    [0.06666666666666667, '#b0b9eb'],
    [0.07058823529411765, '#aeb7ea'],
    [0.07450980392156863, '#abb5e9'],
    [0.0784313725490196, '#a8b3e8'],
    [0.08235294117647059, '#a6b1e6'],
    [0.08627450980392157, '#a3afe5'],
    [0.09019607843137255, '#a0ade4'],
    [0.09411764705882353, '#9eace3'],
    [0.09803921568627451, '#9baae2'],
    [0.10196078431372549, '#98a8e1'],
    [0.10588235294117647, '#96a6df'],
    [0.10980392156862745, '#93a4de'],
    [0.11372549019607843, '#90a2dd'],
    [0.11764705882352941, '#8ea0dc'],
    [0.12156862745098039, '#8b9fdb'],
    [0.12549019607843137, '#889dd9'],
    [0.12941176470588234, '#869bd8'],
    [0.13333333333333333, '#8399d7'],
    [0.13725490196078433, '#8097d5'],
    [0.1411764705882353, '#7e95d4'],
    [0.14509803921568626, '#7b93d3'],
    [0.14901960784313725, '#7991d1'],
    [0.15294117647058825, '#768fd0'],
    [0.1568627450980392, '#738dce'],
    [0.16078431372549018, '#718bcd'],
    [0.16470588235294117, '#6e89cb'],
    [0.16862745098039217, '#6b87ca'],
    [0.17254901960784313, '#6985c8'],
    [0.1764705882352941, '#6683c6'],
    [0.1803921568627451, '#6481c5'],
    [0.1843137254901961, '#617fc3'],
    [0.18823529411764706, '#5f7dc1'],
    [0.19215686274509802, '#5c7bbf'],
    [0.19607843137254902, '#5a79bd'],
    [0.2, '#5777ba'],
    [0.20392156862745098, '#5575b8'],
    [0.20784313725490194, '#5373b6'],
    [0.21176470588235294, '#5171b4'],
    [0.21568627450980393, '#4f6fb1'],
    [0.2196078431372549, '#4c6daf'],
    [0.22352941176470587, '#4a6bac'],
    [0.22745098039215686, '#4969aa'],
    [0.23137254901960785, '#4767a7'],
    [0.23529411764705882, '#4565a5'],
    [0.2392156862745098, '#4363a2'],
    [0.24313725490196078, '#4262a0'],
    [0.24705882352941178, '#40609d'],
    [0.25098039215686274, '#3e5e9a'],
    [0.2549019607843137, '#3d5c98'],
    [0.2588235294117647, '#3c5a95'],
    [0.2627450980392157, '#3a5993'],
    [0.26666666666666666, '#395790'],
    [0.27058823529411763, '#38558d'],
    [0.27450980392156865, '#37548b'],
    [0.2784313725490196, '#355288'],
    [0.2823529411764706, '#345086'],
    [0.28627450980392155, '#334f83'],
    [0.2901960784313725, '#324d80'],
    [0.29411764705882354, '#314c7e'],
    [0.2980392156862745, '#304a7b'],
    [0.30196078431372547, '#2f4979'],
    [0.3058823529411765, '#2e4776'],
    [0.30980392156862746, '#2d4574'],
    [0.3137254901960784, '#2c4471'],
    [0.3176470588235294, '#2b426f'],
    [0.32156862745098036, '#2a416c'],
    [0.3254901960784314, '#293f6a'],
    [0.32941176470588235, '#283e67'],
    [0.3333333333333333, '#273c65'],
    [0.33725490196078434, '#263b62'],
    [0.3411764705882353, '#253a60'],
    [0.34509803921568627, '#24385d'],
    [0.34901960784313724, '#23375b'],
    [0.3529411764705882, '#223559'],
    [0.3568627450980392, '#223456'],
    [0.3607843137254902, '#213254'],
    [0.36470588235294116, '#203151'],
    [0.3686274509803922, '#1f304f'],
    [0.37254901960784315, '#1e2e4d'],
    [0.3764705882352941, '#1d2d4a'],
    [0.3803921568627451, '#1c2b48'],
    [0.38431372549019605, '#1c2a46'],
    [0.38823529411764707, '#1b2943'],
    [0.39215686274509803, '#1a2841'],
    [0.396078431372549, '#19263f'],
    [0.4, '#19253d'],
    [0.403921568627451, '#18243b'],
    [0.40784313725490196, '#172338'],
    [0.4117647058823529, '#162136'],
    [0.4156862745098039, '#162034'],
    [0.4196078431372549, '#151f32'],
    [0.4235294117647059, '#151e30'],
    [0.42745098039215684, '#141d2e'],
    [0.43137254901960786, '#131c2c'],
    [0.43529411764705883, '#131b2a'],
    [0.4392156862745098, '#121a28'],
    [0.44313725490196076, '#121926'],
    [0.44705882352941173, '#111824'],
    [0.45098039215686275, '#111823'],
    [0.4549019607843137, '#111721'],
    [0.4588235294117647, '#101620'],
    [0.4627450980392157, '#10161e'],
    [0.4666666666666667, '#0f151d'],
    [0.47058823529411764, '#0f151b'],
    [0.4745098039215686, '#0e151a'],
    [0.4784313725490196, '#0e1519'],
    [0.4823529411764706, '#0d1518'],
    [0.48627450980392156, '#0d1517'],
    [0.49019607843137253, '#0d1516'],
    [0.49411764705882355, '#0d1515'],
    [0.4980392156862745, '#0d1514'],
    [0.5019607843137255, '#0d1613'],
    [0.5058823529411764, '#0d1613'],
    [0.5098039215686274, '#0d1712'],
    [0.5137254901960784, '#0d1712'],
    [0.5176470588235293, '#0d1812'],
    [0.5215686274509804, '#0e1912'],
    [0.5254901960784314, '#0e1a11'],
    [0.5294117647058824, '#0f1b12'],
    [0.5333333333333333, '#0f1c12'],
    [0.5372549019607843, '#101c12'],
    [0.5411764705882353, '#101e12'],
    [0.5450980392156862, '#111f12'],
    [0.5490196078431373, '#112013'],
    [0.5529411764705883, '#112113'],
    [0.5568627450980392, '#122214'],
    [0.5607843137254902, '#122314'],
    [0.5647058823529412, '#132515'],
    [0.5686274509803921, '#132615'],
    [0.5725490196078431, '#142716'],
    [0.5764705882352941, '#142916'],
    [0.580392156862745, '#152a17'],
    [0.5843137254901961, '#152c18'],
    [0.5882352941176471, '#162d18'],
    [0.592156862745098, '#162f19'],
    [0.596078431372549, '#17301a'],
    [0.6, '#18321a'],
    [0.6039215686274509, '#18331b'],
    [0.6078431372549019, '#19351c'],
    [0.611764705882353, '#1a361c'],
    [0.615686274509804, '#1a381d'],
    [0.6196078431372549, '#1b391e'],
    [0.6235294117647059, '#1c3b1f'],
    [0.6274509803921569, '#1d3d20'],
    [0.6313725490196078, '#1d3e20'],
    [0.6352941176470588, '#1e4021'],
    [0.6392156862745098, '#1f4222'],
    [0.6431372549019607, '#204323'],
    [0.6470588235294118, '#204524'],
    [0.6509803921568628, '#214725'],
    [0.6549019607843137, '#224825'],
    [0.6588235294117647, '#234a26'],
    [0.6627450980392157, '#234c27'],
    [0.6666666666666666, '#244d28'],
    [0.6705882352941176, '#254f29'],
    [0.6745098039215687, '#26512a'],
    [0.6784313725490196, '#27532b'],
    [0.6823529411764706, '#27542c'],
    [0.6862745098039216, '#28562c'],
    [0.6901960784313725, '#29582d'],
    [0.6941176470588235, '#2a5a2e'],
    [0.6980392156862745, '#2b5b2f'],
    [0.7019607843137254, '#2c5d30'],
    [0.7058823529411764, '#2c5f31'],
    [0.7098039215686275, '#2d6132'],
    [0.7137254901960784, '#2e6233'],
    [0.7176470588235294, '#2f6434'],
    [0.7215686274509804, '#306635'],
    [0.7254901960784313, '#316836'],
    [0.7294117647058823, '#326a37'],
    [0.7333333333333333, '#336c38'],
    [0.7372549019607844, '#346d39'],
    [0.7411764705882353, '#356f3a'],
    [0.7450980392156863, '#36713b'],
    [0.7490196078431373, '#37733c'],
    [0.7529411764705882, '#38753d'],
    [0.7568627450980392, '#39773e'],
    [0.7607843137254902, '#3a793f'],
    [0.7647058823529411, '#3b7b40'],
    [0.7686274509803921, '#3d7d41'],
    [0.7725490196078432, '#3e7f42'],
    [0.7764705882352941, '#3f8144'],
    [0.7803921568627451, '#418345'],
    [0.7843137254901961, '#428546'],
    [0.788235294117647, '#448747'],
    [0.792156862745098, '#468949'],
    [0.796078431372549, '#488b4a'],
    [0.8, '#4a8d4b'],
    [0.803921568627451, '#4c8f4d'],
    [0.807843137254902, '#4e914e'],
    [0.8117647058823529, '#509350'],
    [0.8156862745098039, '#529551'],
    [0.8196078431372549, '#559753'],
    [0.8235294117647058, '#579a54'],
    [0.8274509803921568, '#5a9c56'],
    [0.8313725490196078, '#5c9e57'],
    [0.8352941176470589, '#5fa059'],
    [0.8392156862745098, '#62a25a'],
    [0.8431372549019608, '#64a45c'],
    [0.8470588235294118, '#67a65e'],
    [0.8509803921568627, '#6aa85f'],
    [0.8549019607843137, '#6da961'],
    [0.8588235294117647, '#70ab63'],
    [0.8627450980392157, '#73ad64'],
    [0.8666666666666667, '#76af66'],
    [0.8705882352941177, '#79b167'],
    [0.8745098039215686, '#7cb369'],
    [0.8784313725490196, '#7fb46b'],
    [0.8823529411764706, '#82b66c'],
    [0.8862745098039215, '#85b86e'],
    [0.8901960784313725, '#88b970'],
    [0.8941176470588235, '#8bbb71'],
    [0.8980392156862745, '#8ebd73'],
    [0.9019607843137255, '#91be74'],
    [0.9058823529411765, '#94c076'],
    [0.9098039215686274, '#97c278'],
    [0.9137254901960784, '#9ac379'],
    [0.9176470588235294, '#9dc57b'],
    [0.9215686274509803, '#a0c67c'],
    [0.9254901960784314, '#a3c87e'],
    [0.9294117647058824, '#a6ca7f'],
    [0.9333333333333333, '#a9cb81'],
    [0.9372549019607843, '#accd83'],
    [0.9411764705882353, '#afce84'],
    [0.9450980392156862, '#b2d086'],
    [0.9490196078431372, '#b5d187'],
    [0.9529411764705882, '#b7d389'],
    [0.9568627450980391, '#bad48a'],
    [0.9607843137254902, '#bdd68c'],
    [0.9647058823529412, '#c0d88d'],
    [0.9686274509803922, '#c3d98f'],
    [0.9725490196078431, '#c6db91'],
    [0.9764705882352941, '#c9dc92'],
    [0.9803921568627451, '#ccde94'],
    [0.984313725490196, '#cfdf95'],
    [0.9882352941176471, '#d2e197'],
    [0.9921568627450981, '#d5e298'],
    [0.996078431372549, '#d8e49a'],
    [1.0, '#dbe69b'],
  ],
  berlin: [
    [0.0, '#9eb0ff'],
    [0.00392156862745098, '#9cb0fe'],
    [0.00784313725490196, '#9ab0fd'],
    [0.011764705882352941, '#98affc'],
    [0.01568627450980392, '#95affb'],
    [0.0196078431372549, '#93affa'],
    [0.023529411764705882, '#91aef9'],
    [0.027450980392156862, '#8eaef7'],
    [0.03137254901960784, '#8caef6'],
    [0.03529411764705882, '#8aaef5'],
    [0.0392156862745098, '#87adf4'],
    [0.043137254901960784, '#85adf3'],
    [0.047058823529411764, '#82adf2'],
    [0.050980392156862744, '#80acf1'],
    [0.054901960784313725, '#7eacf0'],
    [0.058823529411764705, '#7bacee'],
    [0.06274509803921569, '#79abed'],
    [0.06666666666666667, '#76abec'],
    [0.07058823529411765, '#74aaeb'],
    [0.07450980392156863, '#71aae9'],
    [0.0784313725490196, '#6fa9e8'],
    [0.08235294117647059, '#6ca9e6'],
    [0.08627450980392157, '#6aa8e5'],
    [0.09019607843137255, '#67a8e3'],
    [0.09411764705882353, '#65a7e2'],
    [0.09803921568627451, '#62a6e0'],
    [0.10196078431372549, '#60a5df'],
    [0.10588235294117647, '#5da5dd'],
    [0.10980392156862745, '#5ba4db'],
    [0.11372549019607843, '#58a3d9'],
    [0.11764705882352941, '#56a2d7'],
    [0.12156862745098039, '#54a0d5'],
    [0.12549019607843137, '#519fd3'],
    [0.12941176470588234, '#4f9ed1'],
    [0.13333333333333333, '#4d9dcf'],
    [0.13725490196078433, '#4b9bcd'],
    [0.1411764705882353, '#489aca'],
    [0.14509803921568626, '#4698c8'],
    [0.14901960784313725, '#4497c6'],
    [0.15294117647058825, '#4395c3'],
    [0.1568627450980392, '#4194c1'],
    [0.16078431372549018, '#3f92be'],
    [0.16470588235294117, '#3e90bc'],
    [0.16862745098039217, '#3c8eb9'],
    [0.17254901960784313, '#3b8db7'],
    [0.1764705882352941, '#398bb4'],
    [0.1803921568627451, '#3889b2'],
    [0.1843137254901961, '#3787af'],
    [0.18823529411764706, '#3685ad'],
    [0.19215686274509802, '#3584aa'],
    [0.19607843137254902, '#3382a8'],
    [0.2, '#3280a6'],
    [0.20392156862745098, '#327ea3'],
    [0.20784313725490194, '#317ca1'],
    [0.21176470588235294, '#307a9e'],
    [0.21568627450980393, '#2f789c'],
    [0.2196078431372549, '#2e7699'],
    [0.22352941176470587, '#2d7597'],
    [0.22745098039215686, '#2c7394'],
    [0.23137254901960785, '#2c7192'],
    [0.23529411764705882, '#2b6f8f'],
    [0.2392156862745098, '#2a6d8d'],
    [0.24313725490196078, '#296b8b'],
    [0.24705882352941178, '#296988'],
    [0.25098039215686274, '#286886'],
    [0.2549019607843137, '#276683'],
    [0.2588235294117647, '#276481'],
    [0.2627450980392157, '#26627f'],
    [0.26666666666666666, '#25607c'],
    [0.27058823529411763, '#245e7a'],
    [0.27450980392156865, '#245d78'],
    [0.2784313725490196, '#235b75'],
    [0.2823529411764706, '#225973'],
    [0.28627450980392155, '#225771'],
    [0.2901960784313725, '#21556e'],
    [0.29411764705882354, '#20546c'],
    [0.2980392156862745, '#20526a'],
    [0.30196078431372547, '#1f5068'],
    [0.3058823529411765, '#1e4e65'],
    [0.30980392156862746, '#1e4d63'],
    [0.3137254901960784, '#1d4b61'],
    [0.3176470588235294, '#1c495f'],
    [0.32156862745098036, '#1c475c'],
    [0.3254901960784314, '#1b465a'],
    [0.32941176470588235, '#1a4458'],
    [0.3333333333333333, '#1a4256'],
    [0.33725490196078434, '#194153'],
    [0.3411764705882353, '#193f51'],
    [0.34509803921568627, '#183d4f'],
    [0.34901960784313724, '#173c4d'],
    [0.3529411764705882, '#173a4b'],
    [0.3568627450980392, '#163849'],
    [0.3607843137254902, '#163747'],
    [0.36470588235294116, '#153544'],
    [0.3686274509803922, '#153342'],
    [0.37254901960784315, '#143240'],
    [0.3764705882352941, '#14303e'],
    [0.3803921568627451, '#132f3c'],
    [0.38431372549019605, '#132d3a'],
    [0.38823529411764707, '#122c38'],
    [0.39215686274509803, '#122a36'],
    [0.396078431372549, '#122934'],
    [0.4, '#112732'],
    [0.403921568627451, '#112630'],
    [0.40784313725490196, '#11242e'],
    [0.4117647058823529, '#11232c'],
    [0.4156862745098039, '#11212a'],
    [0.4196078431372549, '#112028'],
    [0.4235294117647059, '#101f26'],
    [0.42745098039215684, '#101d25'],
    [0.43137254901960786, '#101c23'],
    [0.43529411764705883, '#111b21'],
    [0.4392156862745098, '#111a20'],
    [0.44313725490196076, '#11191e'],
    [0.44705882352941173, '#11181c'],
    [0.45098039215686275, '#11161b'],
    [0.4549019607843137, '#111519'],
    [0.4588235294117647, '#111418'],
    [0.4627450980392157, '#111317'],
    [0.4666666666666667, '#121215'],
    [0.47058823529411764, '#121214'],
    [0.4745098039215686, '#131112'],
    [0.4784313725490196, '#141011'],
    [0.4823529411764706, '#140f10'],
    [0.48627450980392156, '#150e0e'],
    [0.49019607843137253, '#160e0d'],
    [0.49411764705882355, '#170d0b'],
    [0.4980392156862745, '#180c0a'],
    [0.5019607843137255, '#190c09'],
    [0.5058823529411764, '#1a0c08'],
    [0.5098039215686274, '#1b0b07'],
    [0.5137254901960784, '#1c0b06'],
    [0.5176470588235293, '#1d0b05'],
    [0.5215686274509804, '#1e0b04'],
    [0.5254901960784314, '#200b04'],
    [0.5294117647058824, '#210b03'],
    [0.5333333333333333, '#220c02'],
    [0.5372549019607843, '#230c02'],
    [0.5411764705882353, '#240c02'],
    [0.5450980392156862, '#250c01'],
    [0.5490196078431373, '#260d01'],
    [0.5529411764705883, '#270d01'],
    [0.5568627450980392, '#280d01'],
    [0.5607843137254902, '#2a0e01'],
    [0.5647058823529412, '#2b0e01'],
    [0.5686274509803921, '#2c0e00'],
    [0.5725490196078431, '#2d0e00'],
    [0.5764705882352941, '#2f0e00'],
    [0.580392156862745, '#300f00'],
    [0.5843137254901961, '#310f00'],
    [0.5882352941176471, '#330f00'],
    [0.592156862745098, '#340f00'],
    [0.596078431372549, '#351000'],
    [0.6, '#371000'],
    [0.6039215686274509, '#381000'],
    [0.6078431372549019, '#391100'],
    [0.611764705882353, '#3b1100'],
    [0.615686274509804, '#3c1101'],
    [0.6196078431372549, '#3e1201'],
    [0.6235294117647059, '#3f1201'],
    [0.6274509803921569, '#411201'],
    [0.6313725490196078, '#421301'],
    [0.6352941176470588, '#441301'],
    [0.6392156862745098, '#451401'],
    [0.6431372549019607, '#471401'],
    [0.6470588235294118, '#481502'],
    [0.6509803921568628, '#4a1502'],
    [0.6549019607843137, '#4b1602'],
    [0.6588235294117647, '#4d1602'],
    [0.6627450980392157, '#4f1703'],
    [0.6666666666666666, '#501803'],
    [0.6705882352941176, '#521804'],
    [0.6745098039215687, '#541905'],
    [0.6784313725490196, '#561a05'],
    [0.6823529411764706, '#571b06'],
    [0.6862745098039216, '#591c07'],
    [0.6901960784313725, '#5b1d08'],
    [0.6941176470588235, '#5d1e09'],
    [0.6980392156862745, '#5f1f0a'],
    [0.7019607843137254, '#61200b'],
    [0.7058823529411764, '#63210c'],
    [0.7098039215686275, '#65230e'],
    [0.7137254901960784, '#68240f'],
    [0.7176470588235294, '#6a2510'],
    [0.7215686274509804, '#6c2711'],
    [0.7254901960784313, '#6e2813'],
    [0.7294117647058823, '#702a14'],
    [0.7333333333333333, '#732b16'],
    [0.7372549019607844, '#752d17'],
    [0.7411764705882353, '#772f19'],
    [0.7450980392156863, '#79301b'],
    [0.7490196078431373, '#7b321c'],
    [0.7529411764705882, '#7d341e'],
    [0.7568627450980392, '#803620'],
    [0.7607843137254902, '#823722'],
    [0.7647058823529411, '#843924'],
    [0.7686274509803921, '#863b26'],
    [0.7725490196078432, '#883d28'],
    [0.7764705882352941, '#8a3f2a'],
    [0.7803921568627451, '#8c402c'],
    [0.7843137254901961, '#8e422e'],
    [0.788235294117647, '#904430'],
    [0.792156862745098, '#924632'],
    [0.796078431372549, '#944834'],
    [0.8, '#964a36'],
    [0.803921568627451, '#984c39'],
    [0.807843137254902, '#9a4d3b'],
    [0.8117647058823529, '#9c4f3d'],
    [0.8156862745098039, '#9e513f'],
    [0.8196078431372549, '#a05341'],
    [0.8235294117647058, '#a25544'],
    [0.8274509803921568, '#a45746'],
    [0.8313725490196078, '#a65948'],
    [0.8352941176470589, '#a85a4a'],
    [0.8392156862745098, '#aa5c4c'],
    [0.8431372549019608, '#ac5e4f'],
    [0.8470588235294118, '#ae6051'],
    [0.8509803921568627, '#b06253'],
    [0.8549019607843137, '#b26455'],
    [0.8588235294117647, '#b46658'],
    [0.8627450980392157, '#b6685a'],
    [0.8666666666666667, '#b86a5c'],
    [0.8705882352941177, '#ba6b5f'],
    [0.8745098039215686, '#bc6d61'],
    [0.8784313725490196, '#be6f63'],
    [0.8823529411764706, '#c07165'],
    [0.8862745098039215, '#c27368'],
    [0.8901960784313725, '#c4756a'],
    [0.8941176470588235, '#c6776c'],
    [0.8980392156862745, '#c8796f'],
    [0.9019607843137255, '#ca7b71'],
    [0.9058823529411765, '#cc7d73'],
    [0.9098039215686274, '#ce7f76'],
    [0.9137254901960784, '#d08178'],
    [0.9176470588235294, '#d2837a'],
    [0.9215686274509803, '#d5857d'],
    [0.9254901960784314, '#d7877f'],
    [0.9294117647058824, '#d98982'],
    [0.9333333333333333, '#db8b84'],
    [0.9372549019607843, '#dd8d86'],
    [0.9411764705882353, '#df8f89'],
    [0.9450980392156862, '#e1918b'],
    [0.9490196078431372, '#e3938e'],
    [0.9529411764705882, '#e59590'],
    [0.9568627450980391, '#e79792'],
    [0.9607843137254902, '#ea9995'],
    [0.9647058823529412, '#ec9b97'],
    [0.9686274509803922, '#ee9d9a'],
    [0.9725490196078431, '#f09f9c'],
    [0.9764705882352941, '#f2a19f'],
    [0.9803921568627451, '#f4a3a1'],
    [0.984313725490196, '#f6a5a3'],
    [0.9882352941176471, '#f9a7a6'],
    [0.9921568627450981, '#fba9a8'],
    [0.996078431372549, '#fdabab'],
    [1.0, '#ffadad'],
  ],
  romaO: [
    [0.0, '#733957'],
    [0.00392156862745098, '#743956'],
    [0.00784313725490196, '#753954'],
    [0.011764705882352941, '#753853'],
    [0.01568627450980392, '#763851'],
    [0.0196078431372549, '#773850'],
    [0.023529411764705882, '#77384f'],
    [0.027450980392156862, '#78384d'],
    [0.03137254901960784, '#79384c'],
    [0.03529411764705882, '#79384b'],
    [0.0392156862745098, '#7a3849'],
    [0.043137254901960784, '#7b3848'],
    [0.047058823529411764, '#7c3847'],
    [0.050980392156862744, '#7c3946'],
    [0.054901960784313725, '#7d3945'],
    [0.058823529411764705, '#7e3943'],
    [0.06274509803921569, '#7e3942'],
    [0.06666666666666667, '#7f3a41'],
    [0.07058823529411765, '#803a40'],
    [0.07450980392156863, '#813b3f'],
    [0.0784313725490196, '#813b3e'],
    [0.08235294117647059, '#823c3d'],
    [0.08627450980392157, '#833c3c'],
    [0.09019607843137255, '#843d3b'],
    [0.09411764705882353, '#843d3a'],
    [0.09803921568627451, '#853e39'],
    [0.10196078431372549, '#863f38'],
    [0.10588235294117647, '#874037'],
    [0.10980392156862745, '#874037'],
    [0.11372549019607843, '#884136'],
    [0.11764705882352941, '#894235'],
    [0.12156862745098039, '#8a4334'],
    [0.12549019607843137, '#8b4433'],
    [0.12941176470588234, '#8c4533'],
    [0.13333333333333333, '#8c4632'],
    [0.13725490196078433, '#8d4731'],
    [0.1411764705882353, '#8e4831'],
    [0.14509803921568626, '#8f4930'],
    [0.14901960784313725, '#904a30'],
    [0.15294117647058825, '#914c2f'],
    [0.1568627450980392, '#924d2f'],
    [0.16078431372549018, '#934e2e'],
    [0.16470588235294117, '#94502e'],
    [0.16862745098039217, '#94512d'],
    [0.17254901960784313, '#95522d'],
    [0.1764705882352941, '#96542d'],
    [0.1803921568627451, '#97552c'],
    [0.1843137254901961, '#98572c'],
    [0.18823529411764706, '#99582c'],
    [0.19215686274509802, '#9a5a2c'],
    [0.19607843137254902, '#9b5b2c'],
    [0.2, '#9c5d2b'],
    [0.20392156862745098, '#9d5f2b'],
    [0.20784313725490194, '#9e602b'],
    [0.21176470588235294, '#9f622b'],
    [0.21568627450980393, '#a0642c'],
    [0.2196078431372549, '#a2662c'],
    [0.22352941176470587, '#a3672c'],
    [0.22745098039215686, '#a4692c'],
    [0.23137254901960785, '#a56b2d'],
    [0.23529411764705882, '#a66d2d'],
    [0.2392156862745098, '#a76f2d'],
    [0.24313725490196078, '#a8712e'],
    [0.24705882352941178, '#a9732e'],
    [0.25098039215686274, '#aa752f'],
    [0.2549019607843137, '#ab7730'],
    [0.2588235294117647, '#ad7930'],
    [0.2627450980392157, '#ae7b31'],
    [0.26666666666666666, '#af7d32'],
    [0.27058823529411763, '#b07f33'],
    [0.27450980392156865, '#b18134'],
    [0.2784313725490196, '#b28335'],
    [0.2823529411764706, '#b48636'],
    [0.28627450980392155, '#b58837'],
    [0.2901960784313725, '#b68a39'],
    [0.29411764705882354, '#b78c3a'],
    [0.2980392156862745, '#b88e3b'],
    [0.30196078431372547, '#b9913d'],
    [0.3058823529411765, '#bb933f'],
    [0.30980392156862746, '#bc9540'],
    [0.3137254901960784, '#bd9842'],
    [0.3176470588235294, '#be9a44'],
    [0.32156862745098036, '#bf9c46'],
    [0.3254901960784314, '#c19f47'],
    [0.32941176470588235, '#c2a149'],
    [0.3333333333333333, '#c3a34b'],
    [0.33725490196078434, '#c4a54e'],
    [0.3411764705882353, '#c5a850'],
    [0.34509803921568627, '#c6aa52'],
    [0.34901960784313724, '#c8ac54'],
    [0.3529411764705882, '#c9af57'],
    [0.3568627450980392, '#cab159'],
    [0.3607843137254902, '#cbb35c'],
    [0.36470588235294116, '#ccb55e'],
    [0.3686274509803922, '#cdb861'],
    [0.37254901960784315, '#ceba63'],
    [0.3764705882352941, '#cfbc66'],
    [0.3803921568627451, '#cfbe68'],
    [0.38431372549019605, '#d0c06b'],
    [0.38823529411764707, '#d1c26e'],
    [0.39215686274509803, '#d2c470'],
    [0.396078431372549, '#d2c673'],
    [0.4, '#d3c876'],
    [0.403921568627451, '#d4c978'],
    [0.40784313725490196, '#d4cb7b'],
    [0.4117647058823529, '#d4cd7e'],
    [0.4156862745098039, '#d5ce81'],
    [0.4196078431372549, '#d5d083'],
    [0.4235294117647059, '#d5d186'],
    [0.42745098039215684, '#d6d388'],
    [0.43137254901960786, '#d6d48b'],
    [0.43529411764705883, '#d6d58e'],
    [0.4392156862745098, '#d6d790'],
    [0.44313725490196076, '#d6d893'],
    [0.44705882352941173, '#d5d995'],
    [0.45098039215686275, '#d5da98'],
    [0.4549019607843137, '#d5db9a'],
    [0.4588235294117647, '#d4dc9c'],
    [0.4627450980392157, '#d4dd9f'],
    [0.4666666666666667, '#d3dda1'],
    [0.47058823529411764, '#d3dea3'],
    [0.4745098039215686, '#d2dfa5'],
    [0.4784313725490196, '#d1dfa7'],
    [0.4823529411764706, '#d0e0a9'],
    [0.48627450980392156, '#cfe0ab'],
    [0.49019607843137253, '#cee0ad'],
    [0.49411764705882355, '#cde1af'],
    [0.4980392156862745, '#cce1b1'],
    [0.5019607843137255, '#cbe1b3'],
    [0.5058823529411764, '#cae1b5'],
    [0.5098039215686274, '#c8e1b6'],
    [0.5137254901960784, '#c7e1b8'],
    [0.5176470588235293, '#c5e1b9'],
    [0.5215686274509804, '#c4e1bb'],
    [0.5254901960784314, '#c2e1bc'],
    [0.5294117647058824, '#c1e1be'],
    [0.5333333333333333, '#bfe1bf'],
    [0.5372549019607843, '#bde0c0'],
    [0.5411764705882353, '#bbe0c2'],
    [0.5450980392156862, '#b9dfc3'],
    [0.5490196078431373, '#b8dfc4'],
    [0.5529411764705883, '#b6dec5'],
    [0.5568627450980392, '#b4dec6'],
    [0.5607843137254902, '#b1ddc7'],
    [0.5647058823529412, '#afdcc8'],
    [0.5686274509803921, '#addcc8'],
    [0.5725490196078431, '#abdbc9'],
    [0.5764705882352941, '#a9daca'],
    [0.580392156862745, '#a7d9cb'],
    [0.5843137254901961, '#a4d8cb'],
    [0.5882352941176471, '#a2d7cc'],
    [0.592156862745098, '#a0d6cc'],
    [0.596078431372549, '#9dd5cd'],
    [0.6, '#9bd4cd'],
    [0.6039215686274509, '#99d3ce'],
    [0.6078431372549019, '#96d1ce'],
    [0.611764705882353, '#94d0ce'],
    [0.615686274509804, '#92cfce'],
    [0.6196078431372549, '#8fcecf'],
    [0.6235294117647059, '#8dcccf'],
    [0.6274509803921569, '#8bcbcf'],
    [0.6313725490196078, '#88c9cf'],
    [0.6352941176470588, '#86c8cf'],
    [0.6392156862745098, '#84c6cf'],
    [0.6431372549019607, '#81c5cf'],
    [0.6470588235294118, '#7fc3cf'],
    [0.6509803921568628, '#7dc2ce'],
    [0.6549019607843137, '#7bc0ce'],
    [0.6588235294117647, '#78bece'],
    [0.6627450980392157, '#76bdce'],
    [0.6666666666666666, '#74bbcd'],
    [0.6705882352941176, '#72b9cd'],
    [0.6745098039215687, '#70b8cd'],
    [0.6784313725490196, '#6eb6cc'],
    [0.6823529411764706, '#6cb4cc'],
    [0.6862745098039216, '#6ab2cb'],
    [0.6901960784313725, '#68b1cb'],
    [0.6941176470588235, '#67afca'],
    [0.6980392156862745, '#65adca'],
    [0.7019607843137254, '#63abc9'],
    [0.7058823529411764, '#62a9c9'],
    [0.7098039215686275, '#60a8c8'],
    [0.7137254901960784, '#5fa6c7'],
    [0.7176470588235294, '#5da4c7'],
    [0.7215686274509804, '#5ca2c6'],
    [0.7254901960784313, '#5aa0c5'],
    [0.7294117647058823, '#599ec4'],
    [0.7333333333333333, '#589cc4'],
    [0.7372549019607844, '#579bc3'],
    [0.7411764705882353, '#5699c2'],
    [0.7450980392156863, '#5597c1'],
    [0.7490196078431373, '#5495c0'],
    [0.7529411764705882, '#5393bf'],
    [0.7568627450980392, '#5291be'],
    [0.7607843137254902, '#528fbd'],
    [0.7647058823529411, '#518dbc'],
    [0.7686274509803921, '#508bbb'],
    [0.7725490196078432, '#508aba'],
    [0.7764705882352941, '#4f88b9'],
    [0.7803921568627451, '#4f86b8'],
    [0.7843137254901961, '#4f84b7'],
    [0.788235294117647, '#4f82b6'],
    [0.792156862745098, '#4e80b4'],
    [0.796078431372549, '#4e7eb3'],
    [0.8, '#4e7cb2'],
    [0.803921568627451, '#4e7ab0'],
    [0.807843137254902, '#4f78af'],
    [0.8117647058823529, '#4f76ae'],
    [0.8156862745098039, '#4f75ac'],
    [0.8196078431372549, '#4f73ab'],
    [0.8235294117647058, '#5071a9'],
    [0.8274509803921568, '#506fa8'],
    [0.8313725490196078, '#516da6'],
    [0.8352941176470589, '#516ba4'],
    [0.8392156862745098, '#5269a3'],
    [0.8431372549019608, '#5267a1'],
    [0.8470588235294118, '#53669f'],
    [0.8509803921568627, '#54649e'],
    [0.8549019607843137, '#54629c'],
    [0.8588235294117647, '#55609a'],
    [0.8627450980392157, '#565f98'],
    [0.8666666666666667, '#575d96'],
    [0.8705882352941177, '#575b94'],
    [0.8745098039215686, '#585993'],
    [0.8784313725490196, '#595891'],
    [0.8823529411764706, '#5a568f'],
    [0.8862745098039215, '#5b558d'],
    [0.8901960784313725, '#5c538b'],
    [0.8941176470588235, '#5c5289'],
    [0.8980392156862745, '#5d5087'],
    [0.9019607843137255, '#5e4f85'],
    [0.9058823529411765, '#5f4d83'],
    [0.9098039215686274, '#604c81'],
    [0.9137254901960784, '#614b7f'],
    [0.9176470588235294, '#62497d'],
    [0.9215686274509803, '#63487b'],
    [0.9254901960784314, '#634779'],
    [0.9294117647058824, '#644677'],
    [0.9333333333333333, '#654576'],
    [0.9372549019607843, '#664474'],
    [0.9411764705882353, '#674372'],
    [0.9450980392156862, '#684270'],
    [0.9490196078431372, '#68416e'],
    [0.9529411764705882, '#69406c'],
    [0.9568627450980391, '#6a3f6b'],
    [0.9607843137254902, '#6b3f69'],
    [0.9647058823529412, '#6c3e67'],
    [0.9686274509803922, '#6c3d65'],
    [0.9725490196078431, '#6d3d64'],
    [0.9764705882352941, '#6e3c62'],
    [0.9803921568627451, '#6f3b60'],
    [0.984313725490196, '#6f3b5f'],
    [0.9882352941176471, '#703a5d'],
    [0.9921568627450981, '#713a5c'],
    [0.996078431372549, '#723a5a'],
    [1.0, '#723959'],
  ],
  bukavu: [
    [0.0, '#1a3333'],
    [0.00392156862745098, '#1a3435'],
    [0.00784313725490196, '#1a3537'],
    [0.011764705882352941, '#1a3539'],
    [0.01568627450980392, '#1b363b'],
    [0.0196078431372549, '#1b373d'],
    [0.023529411764705882, '#1b383f'],
    [0.027450980392156862, '#1b3941'],
    [0.03137254901960784, '#1b3a43'],
    [0.03529411764705882, '#1c3b45'],
    [0.0392156862745098, '#1c3c47'],
    [0.043137254901960784, '#1c3d49'],
    [0.047058823529411764, '#1c3e4c'],
    [0.050980392156862744, '#1d3e4e'],
    [0.054901960784313725, '#1d3f50'],
    [0.058823529411764705, '#1d4052'],
    [0.06274509803921569, '#1e4255'],
    [0.06666666666666667, '#1e4357'],
    [0.07058823529411765, '#1e445a'],
    [0.07450980392156863, '#1e455c'],
    [0.0784313725490196, '#1f465f'],
    [0.08235294117647059, '#1f4762'],
    [0.08627450980392157, '#1f4865'],
    [0.09019607843137255, '#204a68'],
    [0.09411764705882353, '#204b6b'],
    [0.09803921568627451, '#204c6e'],
    [0.10196078431372549, '#214e71'],
    [0.10588235294117647, '#214f74'],
    [0.10980392156862745, '#225178'],
    [0.11372549019607843, '#22527b'],
    [0.11764705882352941, '#22547f'],
    [0.12156862745098039, '#235582'],
    [0.12549019607843137, '#235786'],
    [0.12941176470588234, '#245989'],
    [0.13333333333333333, '#245b8c'],
    [0.13725490196078433, '#255c8f'],
    [0.1411764705882353, '#255e92'],
    [0.14509803921568626, '#266095'],
    [0.14901960784313725, '#276297'],
    [0.15294117647058825, '#27649a'],
    [0.1568627450980392, '#28669c'],
    [0.16078431372549018, '#29689f'],
    [0.16470588235294117, '#2969a1'],
    [0.16862745098039217, '#2a6ba3'],
    [0.17254901960784313, '#2a6da6'],
    [0.1764705882352941, '#2b6fa8'],
    [0.1803921568627451, '#2c71aa'],
    [0.1843137254901961, '#2c73ad'],
    [0.18823529411764706, '#2d74af'],
    [0.19215686274509802, '#2d76b1'],
    [0.19607843137254902, '#2e78b4'],
    [0.2, '#2f7ab6'],
    [0.20392156862745098, '#2f7cb8'],
    [0.20784313725490194, '#307eba'],
    [0.21176470588235294, '#317fbd'],
    [0.21568627450980393, '#3281bf'],
    [0.2196078431372549, '#3283c0'],
    [0.22352941176470587, '#3485c2'],
    [0.22745098039215686, '#3587c4'],
    [0.23137254901960785, '#3689c5'],
    [0.23529411764705882, '#388ac6'],
    [0.2392156862745098, '#3a8cc6'],
    [0.24313725490196078, '#3b8ec7'],
    [0.24705882352941178, '#3d90c7'],
    [0.25098039215686274, '#3f92c8'],
    [0.2549019607843137, '#4193c8'],
    [0.2588235294117647, '#4395c8'],
    [0.2627450980392157, '#4597c8'],
    [0.26666666666666666, '#4799c8'],
    [0.27058823529411763, '#499ac8'],
    [0.27450980392156865, '#4b9cc8'],
    [0.2784313725490196, '#4c9ec9'],
    [0.2823529411764706, '#4ea0c9'],
    [0.28627450980392155, '#50a1c9'],
    [0.2901960784313725, '#52a3c9'],
    [0.29411764705882354, '#54a5c9'],
    [0.2980392156862745, '#56a6c9'],
    [0.30196078431372547, '#58a8c9'],
    [0.3058823529411765, '#59aac9'],
    [0.30980392156862746, '#5babca'],
    [0.3137254901960784, '#5dadca'],
    [0.3176470588235294, '#5faeca'],
    [0.32156862745098036, '#61b0ca'],
    [0.3254901960784314, '#62b2ca'],
    [0.32941176470588235, '#64b3ca'],
    [0.3333333333333333, '#66b5ca'],
    [0.33725490196078434, '#68b7ca'],
    [0.3411764705882353, '#6ab8cb'],
    [0.34509803921568627, '#6bbacb'],
    [0.34901960784313724, '#6dbbcb'],
    [0.3529411764705882, '#6fbdcb'],
    [0.3568627450980392, '#71bfcb'],
    [0.3607843137254902, '#72c0cb'],
    [0.36470588235294116, '#74c2cb'],
    [0.3686274509803922, '#76c3cb'],
    [0.37254901960784315, '#78c5cc'],
    [0.3764705882352941, '#7ac7cc'],
    [0.3803921568627451, '#7cc8cc'],
    [0.38431372549019605, '#7fcacc'],
    [0.38823529411764707, '#81cccd'],
    [0.39215686274509803, '#84cdcd'],
    [0.396078431372549, '#87cfce'],
    [0.4, '#8ad1cf'],
    [0.403921568627451, '#8ed3d0'],
    [0.40784313725490196, '#92d5d1'],
    [0.4117647058823529, '#95d7d1'],
    [0.4156862745098039, '#99d9d2'],
    [0.4196078431372549, '#9ddad3'],
    [0.4235294117647059, '#a0dcd4'],
    [0.42745098039215684, '#a4ded5'],
    [0.43137254901960786, '#a8e0d6'],
    [0.43529411764705883, '#ace2d7'],
    [0.4392156862745098, '#afe4d8'],
    [0.44313725490196076, '#b3e6d9'],
    [0.44705882352941173, '#b6e7da'],
    [0.45098039215686275, '#bae9db'],
    [0.4549019607843137, '#beebdc'],
    [0.4588235294117647, '#c1eddc'],
    [0.4627450980392157, '#c5efdd'],
    [0.4666666666666667, '#c8f0de'],
    [0.47058823529411764, '#ccf2df'],
    [0.4745098039215686, '#cff4e0'],
    [0.4784313725490196, '#d3f6e1'],
    [0.4823529411764706, '#d6f7e2'],
    [0.48627450980392156, '#daf9e3'],
    [0.49019607843137253, '#ddfbe3'],
    [0.49411764705882355, '#e0fce4'],
    [0.4980392156862745, '#e4fee5'],
    [0.5019607843137255, '#014026'],
    [0.5058823529411764, '#024125'],
    [0.5098039215686274, '#034224'],
    [0.5137254901960784, '#044423'],
    [0.5176470588235293, '#054522'],
    [0.5215686274509804, '#064621'],
    [0.5254901960784314, '#074720'],
    [0.5294117647058824, '#08491f'],
    [0.5333333333333333, '#094a1e'],
    [0.5372549019607843, '#0b4b1d'],
    [0.5411764705882353, '#0c4d1c'],
    [0.5450980392156862, '#0e4e1b'],
    [0.5490196078431373, '#0f501a'],
    [0.5529411764705883, '#115119'],
    [0.5568627450980392, '#135318'],
    [0.5607843137254902, '#155417'],
    [0.5647058823529412, '#175616'],
    [0.5686274509803921, '#1a5815'],
    [0.5725490196078431, '#1c5914'],
    [0.5764705882352941, '#1f5b13'],
    [0.580392156862745, '#225d13'],
    [0.5843137254901961, '#255e12'],
    [0.5882352941176471, '#286012'],
    [0.592156862745098, '#2b6212'],
    [0.596078431372549, '#2f6312'],
    [0.6, '#326513'],
    [0.6039215686274509, '#366613'],
    [0.6078431372549019, '#396714'],
    [0.611764705882353, '#3d6915'],
    [0.615686274509804, '#406a16'],
    [0.6196078431372549, '#446b17'],
    [0.6235294117647059, '#476c19'],
    [0.6274509803921569, '#4b6d1a'],
    [0.6313725490196078, '#4e6e1b'],
    [0.6352941176470588, '#526f1d'],
    [0.6392156862745098, '#556f1e'],
    [0.6431372549019607, '#587020'],
    [0.6470588235294118, '#5b7121'],
    [0.6509803921568628, '#5e7123'],
    [0.6549019607843137, '#617224'],
    [0.6588235294117647, '#647225'],
    [0.6627450980392157, '#677327'],
    [0.6666666666666666, '#697328'],
    [0.6705882352941176, '#6c7429'],
    [0.6745098039215687, '#6f742b'],
    [0.6784313725490196, '#71752c'],
    [0.6823529411764706, '#74752d'],
    [0.6862745098039216, '#76762e'],
    [0.6901960784313725, '#78762f'],
    [0.6941176470588235, '#7b7631'],
    [0.6980392156862745, '#7d7732'],
    [0.7019607843137254, '#7f7733'],
    [0.7058823529411764, '#827834'],
    [0.7098039215686275, '#847835'],
    [0.7137254901960784, '#867836'],
    [0.7176470588235294, '#887938'],
    [0.7215686274509804, '#8b7939'],
    [0.7254901960784313, '#8d7a3a'],
    [0.7294117647058823, '#8f7a3b'],
    [0.7333333333333333, '#917b3c'],
    [0.7372549019607844, '#937b3e'],
    [0.7411764705882353, '#967c3f'],
    [0.7450980392156863, '#987c40'],
    [0.7490196078431373, '#9a7d42'],
    [0.7529411764705882, '#9c7e43'],
    [0.7568627450980392, '#9e7e45'],
    [0.7607843137254902, '#a17f47'],
    [0.7647058823529411, '#a38049'],
    [0.7686274509803921, '#a5824b'],
    [0.7725490196078432, '#a7834d'],
    [0.7764705882352941, '#aa844f'],
    [0.7803921568627451, '#ac8652'],
    [0.7843137254901961, '#ae8754'],
    [0.788235294117647, '#b08957'],
    [0.792156862745098, '#b28b5a'],
    [0.796078431372549, '#b48d5d'],
    [0.8, '#b68f60'],
    [0.803921568627451, '#b79163'],
    [0.807843137254902, '#b99366'],
    [0.8117647058823529, '#bb9569'],
    [0.8156862745098039, '#bc976c'],
    [0.8196078431372549, '#be996f'],
    [0.8235294117647058, '#bf9c72'],
    [0.8274509803921568, '#c09e76'],
    [0.8313725490196078, '#c2a079'],
    [0.8352941176470589, '#c3a27c'],
    [0.8392156862745098, '#c4a57f'],
    [0.8431372549019608, '#c5a782'],
    [0.8470588235294118, '#c6a985'],
    [0.8509803921568627, '#c8ac89'],
    [0.8549019607843137, '#c9ae8c'],
    [0.8588235294117647, '#cab08f'],
    [0.8627450980392157, '#cbb292'],
    [0.8666666666666667, '#ccb596'],
    [0.8705882352941177, '#cdb799'],
    [0.8745098039215686, '#ceb99c'],
    [0.8784313725490196, '#cfbba0'],
    [0.8823529411764706, '#d0bda3'],
    [0.8862745098039215, '#d2c0a6'],
    [0.8901960784313725, '#d3c2aa'],
    [0.8941176470588235, '#d4c4ad'],
    [0.8980392156862745, '#d5c6b0'],
    [0.9019607843137255, '#d6c8b4'],
    [0.9058823529411765, '#d7cab7'],
    [0.9098039215686274, '#d8ccba'],
    [0.9137254901960784, '#d9cdbe'],
    [0.9176470588235294, '#dacfc1'],
    [0.9215686274509803, '#dbd1c4'],
    [0.9254901960784314, '#dcd3c7'],
    [0.9294117647058824, '#ddd4ca'],
    [0.9333333333333333, '#ded6cd'],
    [0.9372549019607843, '#dfd7d0'],
    [0.9411764705882353, '#e0d9d2'],
    [0.9450980392156862, '#e1dad5'],
    [0.9490196078431372, '#e2dbd8'],
    [0.9529411764705882, '#e3dddb'],
    [0.9568627450980391, '#e3dedd'],
    [0.9607843137254902, '#e4dfe0'],
    [0.9647058823529412, '#e5e1e3'],
    [0.9686274509803922, '#e6e2e5'],
    [0.9725490196078431, '#e7e3e8'],
    [0.9764705882352941, '#e8e5eb'],
    [0.9803921568627451, '#e9e6ed'],
    [0.984313725490196, '#e9e7f0'],
    [0.9882352941176471, '#eae9f3'],
    [0.9921568627450981, '#ebeaf6'],
    [0.996078431372549, '#ececf9'],
    [1.0, '#ededfc'],
  ],
  grayC: [
    [0.0, '#ffffff'],
    [0.00392156862745098, '#fefefe'],
    [0.00784313725490196, '#fdfdfd'],
    [0.011764705882352941, '#fcfcfc'],
    [0.01568627450980392, '#fafafa'],
    [0.0196078431372549, '#f9f9f9'],
    [0.023529411764705882, '#f8f8f8'],
    [0.027450980392156862, '#f7f7f7'],
    [0.03137254901960784, '#f6f6f6'],
    [0.03529411764705882, '#f5f5f5'],
    [0.0392156862745098, '#f4f4f4'],
    [0.043137254901960784, '#f3f3f3'],
    [0.047058823529411764, '#f1f1f1'],
    [0.050980392156862744, '#f0f0f0'],
    [0.054901960784313725, '#efefef'],
    [0.058823529411764705, '#eeeeee'],
    [0.06274509803921569, '#ededed'],
    [0.06666666666666667, '#ececec'],
    [0.07058823529411765, '#ebebeb'],
    [0.07450980392156863, '#eaeaea'],
    [0.0784313725490196, '#e8e8e8'],
    [0.08235294117647059, '#e7e7e7'],
    [0.08627450980392157, '#e6e6e6'],
    [0.09019607843137255, '#e5e5e5'],
    [0.09411764705882353, '#e4e4e4'],
    [0.09803921568627451, '#e3e3e3'],
    [0.10196078431372549, '#e2e2e2'],
    [0.10588235294117647, '#e1e1e1'],
    [0.10980392156862745, '#e0e0e0'],
    [0.11372549019607843, '#dedede'],
    [0.11764705882352941, '#dddddd'],
    [0.12156862745098039, '#dcdcdc'],
    [0.12549019607843137, '#dbdbdb'],
    [0.12941176470588234, '#dadada'],
    [0.13333333333333333, '#d9d9d9'],
    [0.13725490196078433, '#d8d8d8'],
    [0.1411764705882353, '#d7d7d7'],
    [0.14509803921568626, '#d6d6d6'],
    [0.14901960784313725, '#d5d5d5'],
    [0.15294117647058825, '#d3d3d3'],
    [0.1568627450980392, '#d2d2d2'],
    [0.16078431372549018, '#d1d1d1'],
    [0.16470588235294117, '#d0d0d0'],
    [0.16862745098039217, '#cfcfcf'],
    [0.17254901960784313, '#cecece'],
    [0.1764705882352941, '#cdcdcd'],
    [0.1803921568627451, '#cccccc'],
    [0.1843137254901961, '#cbcbcb'],
    [0.18823529411764706, '#cacaca'],
    [0.19215686274509802, '#c9c9c9'],
    [0.19607843137254902, '#c7c7c7'],
    [0.2, '#c6c6c6'],
    [0.20392156862745098, '#c5c5c5'],
    [0.20784313725490194, '#c4c4c4'],
    [0.21176470588235294, '#c3c3c3'],
    [0.21568627450980393, '#c2c2c2'],
    [0.2196078431372549, '#c1c1c1'],
    [0.22352941176470587, '#c0c0c0'],
    [0.22745098039215686, '#bfbfbf'],
    [0.23137254901960785, '#bebebe'],
    [0.23529411764705882, '#bdbdbd'],
    [0.2392156862745098, '#bcbcbc'],
    [0.24313725490196078, '#bababa'],
    [0.24705882352941178, '#b9b9b9'],
    [0.25098039215686274, '#b8b8b8'],
    [0.2549019607843137, '#b7b7b7'],
    [0.2588235294117647, '#b6b6b6'],
    [0.2627450980392157, '#b5b5b5'],
    [0.26666666666666666, '#b4b4b4'],
    [0.27058823529411763, '#b3b3b3'],
    [0.27450980392156865, '#b2b2b2'],
    [0.2784313725490196, '#b1b1b1'],
    [0.2823529411764706, '#b0b0b0'],
    [0.28627450980392155, '#afafaf'],
    [0.2901960784313725, '#aeaeae'],
    [0.29411764705882354, '#adadad'],
    [0.2980392156862745, '#acacac'],
    [0.30196078431372547, '#ababab'],
    [0.3058823529411765, '#a9a9a9'],
    [0.30980392156862746, '#a8a8a8'],
    [0.3137254901960784, '#a7a7a7'],
    [0.3176470588235294, '#a6a6a6'],
    [0.32156862745098036, '#a5a5a5'],
    [0.3254901960784314, '#a4a4a4'],
    [0.32941176470588235, '#a3a3a3'],
    [0.3333333333333333, '#a2a2a2'],
    [0.33725490196078434, '#a1a1a1'],
    [0.3411764705882353, '#a0a0a0'],
    [0.34509803921568627, '#9f9f9f'],
    [0.34901960784313724, '#9e9e9e'],
    [0.3529411764705882, '#9d9d9d'],
    [0.3568627450980392, '#9c9c9c'],
    [0.3607843137254902, '#9b9b9b'],
    [0.36470588235294116, '#9a9a9a'],
    [0.3686274509803922, '#999999'],
    [0.37254901960784315, '#989898'],
    [0.3764705882352941, '#979797'],
    [0.3803921568627451, '#969696'],
    [0.38431372549019605, '#959595'],
    [0.38823529411764707, '#949494'],
    [0.39215686274509803, '#939393'],
    [0.396078431372549, '#929292'],
    [0.4, '#919191'],
    [0.403921568627451, '#909090'],
    [0.40784313725490196, '#8f8f8f'],
    [0.4117647058823529, '#8d8d8d'],
    [0.4156862745098039, '#8c8c8c'],
    [0.4196078431372549, '#8b8b8b'],
    [0.4235294117647059, '#8a8a8a'],
    [0.42745098039215684, '#898989'],
    [0.43137254901960786, '#888888'],
    [0.43529411764705883, '#878787'],
    [0.4392156862745098, '#868686'],
    [0.44313725490196076, '#858585'],
    [0.44705882352941173, '#848484'],
    [0.45098039215686275, '#838383'],
    [0.4549019607843137, '#828282'],
    [0.4588235294117647, '#818181'],
    [0.4627450980392157, '#808080'],
    [0.4666666666666667, '#7f7f7f'],
    [0.47058823529411764, '#7e7e7e'],
    [0.4745098039215686, '#7d7d7d'],
    [0.4784313725490196, '#7c7c7c'],
    [0.4823529411764706, '#7b7b7b'],
    [0.48627450980392156, '#7a7a7a'],
    [0.49019607843137253, '#797979'],
    [0.49411764705882355, '#787878'],
    [0.4980392156862745, '#777777'],
    [0.5019607843137255, '#767676'],
    [0.5058823529411764, '#757575'],
    [0.5098039215686274, '#747474'],
    [0.5137254901960784, '#737373'],
    [0.5176470588235293, '#727272'],
    [0.5215686274509804, '#727272'],
    [0.5254901960784314, '#717171'],
    [0.5294117647058824, '#707070'],
    [0.5333333333333333, '#6f6f6f'],
    [0.5372549019607843, '#6e6e6e'],
    [0.5411764705882353, '#6d6d6d'],
    [0.5450980392156862, '#6c6c6c'],
    [0.5490196078431373, '#6b6b6b'],
    [0.5529411764705883, '#6a6a6a'],
    [0.5568627450980392, '#696969'],
    [0.5607843137254902, '#686868'],
    [0.5647058823529412, '#676767'],
    [0.5686274509803921, '#666666'],
    [0.5725490196078431, '#656565'],
    [0.5764705882352941, '#646464'],
    [0.580392156862745, '#636363'],
    [0.5843137254901961, '#626262'],
    [0.5882352941176471, '#616161'],
    [0.592156862745098, '#606060'],
    [0.596078431372549, '#5f5f5f'],
    [0.6, '#5e5e5e'],
    [0.6039215686274509, '#5d5d5d'],
    [0.6078431372549019, '#5c5c5c'],
    [0.611764705882353, '#5b5b5b'],
    [0.615686274509804, '#5a5a5a'],
    [0.6196078431372549, '#5a5a5a'],
    [0.6235294117647059, '#595959'],
    [0.6274509803921569, '#585858'],
    [0.6313725490196078, '#575757'],
    [0.6352941176470588, '#565656'],
    [0.6392156862745098, '#555555'],
    [0.6431372549019607, '#545454'],
    [0.6470588235294118, '#535353'],
    [0.6509803921568628, '#525252'],
    [0.6549019607843137, '#515151'],
    [0.6588235294117647, '#505050'],
    [0.6627450980392157, '#4f4f4f'],
    [0.6666666666666666, '#4e4e4e'],
    [0.6705882352941176, '#4d4d4d'],
    [0.6745098039215687, '#4d4d4d'],
    [0.6784313725490196, '#4c4c4c'],
    [0.6823529411764706, '#4b4b4b'],
    [0.6862745098039216, '#4a4a4a'],
    [0.6901960784313725, '#494949'],
    [0.6941176470588235, '#484848'],
    [0.6980392156862745, '#474747'],
    [0.7019607843137254, '#464646'],
    [0.7058823529411764, '#454545'],
    [0.7098039215686275, '#444444'],
    [0.7137254901960784, '#434343'],
    [0.7176470588235294, '#434343'],
    [0.7215686274509804, '#424242'],
    [0.7254901960784313, '#414141'],
    [0.7294117647058823, '#404040'],
    [0.7333333333333333, '#3f3f3f'],
    [0.7372549019607844, '#3e3e3e'],
    [0.7411764705882353, '#3d3d3d'],
    [0.7450980392156863, '#3c3c3c'],
    [0.7490196078431373, '#3c3c3c'],
    [0.7529411764705882, '#3b3b3b'],
    [0.7568627450980392, '#3a3a3a'],
    [0.7607843137254902, '#393939'],
    [0.7647058823529411, '#383838'],
    [0.7686274509803921, '#373737'],
    [0.7725490196078432, '#363636'],
    [0.7764705882352941, '#353535'],
    [0.7803921568627451, '#353535'],
    [0.7843137254901961, '#343434'],
    [0.788235294117647, '#333333'],
    [0.792156862745098, '#323232'],
    [0.796078431372549, '#313131'],
    [0.8, '#303030'],
    [0.803921568627451, '#2f2f2f'],
    [0.807843137254902, '#2f2f2f'],
    [0.8117647058823529, '#2e2e2e'],
    [0.8156862745098039, '#2d2d2d'],
    [0.8196078431372549, '#2c2c2c'],
    [0.8235294117647058, '#2b2b2b'],
    [0.8274509803921568, '#2a2a2a'],
    [0.8313725490196078, '#2a2a2a'],
    [0.8352941176470589, '#292929'],
    [0.8392156862745098, '#282828'],
    [0.8431372549019608, '#272727'],
    [0.8470588235294118, '#262626'],
    [0.8509803921568627, '#252525'],
    [0.8549019607843137, '#252525'],
    [0.8588235294117647, '#242424'],
    [0.8627450980392157, '#232323'],
    [0.8666666666666667, '#222222'],
    [0.8705882352941177, '#212121'],
    [0.8745098039215686, '#212121'],
    [0.8784313725490196, '#202020'],
    [0.8823529411764706, '#1f1f1f'],
    [0.8862745098039215, '#1e1e1e'],
    [0.8901960784313725, '#1d1d1d'],
    [0.8941176470588235, '#1d1d1d'],
    [0.8980392156862745, '#1c1c1c'],
    [0.9019607843137255, '#1b1b1b'],
    [0.9058823529411765, '#1a1a1a'],
    [0.9098039215686274, '#1a1a1a'],
    [0.9137254901960784, '#191919'],
    [0.9176470588235294, '#181818'],
    [0.9215686274509803, '#171717'],
    [0.9254901960784314, '#161616'],
    [0.9294117647058824, '#161616'],
    [0.9333333333333333, '#151515'],
    [0.9372549019607843, '#141414'],
    [0.9411764705882353, '#131313'],
    [0.9450980392156862, '#121212'],
    [0.9490196078431372, '#111111'],
    [0.9529411764705882, '#101010'],
    [0.9568627450980391, '#0f0f0f'],
    [0.9607843137254902, '#0e0e0e'],
    [0.9647058823529412, '#0d0d0d'],
    [0.9686274509803922, '#0b0b0b'],
    [0.9725490196078431, '#0a0a0a'],
    [0.9764705882352941, '#090909'],
    [0.9803921568627451, '#070707'],
    [0.984313725490196, '#060606'],
    [0.9882352941176471, '#040404'],
    [0.9921568627450981, '#030303'],
    [0.996078431372549, '#010101'],
    [1.0, '#000000'],
  ],
  bamO: [
    [0.0, '#4f3043'],
    [0.00392156862745098, '#502f45'],
    [0.00784313725490196, '#512f46'],
    [0.011764705882352941, '#533047'],
    [0.01568627450980392, '#543049'],
    [0.0196078431372549, '#56304b'],
    [0.023529411764705882, '#58304d'],
    [0.027450980392156862, '#5a314e'],
    [0.03137254901960784, '#5c3250'],
    [0.03529411764705882, '#5e3252'],
    [0.0392156862745098, '#603354'],
    [0.043137254901960784, '#623457'],
    [0.047058823529411764, '#643559'],
    [0.050980392156862744, '#67365b'],
    [0.054901960784313725, '#69375d'],
    [0.058823529411764705, '#6b385f'],
    [0.06274509803921569, '#6d3961'],
    [0.06666666666666667, '#703a63'],
    [0.07058823529411765, '#723b65'],
    [0.07450980392156863, '#743d67'],
    [0.0784313725490196, '#763e69'],
    [0.08235294117647059, '#793f6b'],
    [0.08627450980392157, '#7b416d'],
    [0.09019607843137255, '#7d426f'],
    [0.09411764705882353, '#7f4471'],
    [0.09803921568627451, '#814573'],
    [0.10196078431372549, '#834675'],
    [0.10588235294117647, '#854877'],
    [0.10980392156862745, '#874979'],
    [0.11372549019607843, '#894b7b'],
    [0.11764705882352941, '#8b4c7d'],
    [0.12156862745098039, '#8d4e7e'],
    [0.12549019607843137, '#8f4f80'],
    [0.12941176470588234, '#915182'],
    [0.13333333333333333, '#935284'],
    [0.13725490196078433, '#945485'],
    [0.1411764705882353, '#965587'],
    [0.14509803921568626, '#985789'],
    [0.14901960784313725, '#9a588a'],
    [0.15294117647058825, '#9b5a8c'],
    [0.1568627450980392, '#9d5b8d'],
    [0.16078431372549018, '#9f5d8f'],
    [0.16470588235294117, '#a05e91'],
    [0.16862745098039217, '#a26092'],
    [0.17254901960784313, '#a36194'],
    [0.1764705882352941, '#a56395'],
    [0.1803921568627451, '#a76597'],
    [0.1843137254901961, '#a86698'],
    [0.18823529411764706, '#aa689a'],
    [0.19215686274509802, '#ab699b'],
    [0.19607843137254902, '#ad6b9d'],
    [0.2, '#ae6d9e'],
    [0.20392156862745098, '#b06ea0'],
    [0.20784313725490194, '#b170a1'],
    [0.21176470588235294, '#b372a3'],
    [0.21568627450980393, '#b574a4'],
    [0.2196078431372549, '#b676a6'],
    [0.22352941176470587, '#b877a7'],
    [0.22745098039215686, '#b979a9'],
    [0.23137254901960785, '#bb7baa'],
    [0.23529411764705882, '#bc7dac'],
    [0.2392156862745098, '#be7fad'],
    [0.24313725490196078, '#bf81af'],
    [0.24705882352941178, '#c083b0'],
    [0.25098039215686274, '#c285b2'],
    [0.2549019607843137, '#c387b3'],
    [0.2588235294117647, '#c589b5'],
    [0.2627450980392157, '#c68bb6'],
    [0.26666666666666666, '#c78db8'],
    [0.27058823529411763, '#c98fb9'],
    [0.27450980392156865, '#ca92ba'],
    [0.2784313725490196, '#cb94bc'],
    [0.2823529411764706, '#cc96bd'],
    [0.28627450980392155, '#cd98be'],
    [0.2901960784313725, '#cf9abf'],
    [0.29411764705882354, '#d09cc1'],
    [0.2980392156862745, '#d19ec2'],
    [0.30196078431372547, '#d2a0c3'],
    [0.3058823529411765, '#d2a3c4'],
    [0.30980392156862746, '#d3a5c5'],
    [0.3137254901960784, '#d4a7c6'],
    [0.3176470588235294, '#d5a9c7'],
    [0.32156862745098036, '#d6abc7'],
    [0.3254901960784314, '#d6adc8'],
    [0.32941176470588235, '#d7aec9'],
    [0.3333333333333333, '#d7b0c9'],
    [0.33725490196078434, '#d8b2ca'],
    [0.3411764705882353, '#d8b4ca'],
    [0.34509803921568627, '#d9b5cb'],
    [0.34901960784313724, '#d9b7cb'],
    [0.3529411764705882, '#d9b8cb'],
    [0.3568627450980392, '#d9bacc'],
    [0.3607843137254902, '#dabbcc'],
    [0.36470588235294116, '#dabccc'],
    [0.3686274509803922, '#dabecc'],
    [0.37254901960784315, '#dabfcc'],
    [0.3764705882352941, '#dac0cc'],
    [0.3803921568627451, '#dac1cc'],
    [0.38431372549019605, '#dac2cc'],
    [0.38823529411764707, '#dac3cc'],
    [0.39215686274509803, '#dac4cb'],
    [0.396078431372549, '#d9c5cb'],
    [0.4, '#d9c5cb'],
    [0.403921568627451, '#d9c6cb'],
    [0.40784313725490196, '#d9c7ca'],
    [0.4117647058823529, '#d9c7ca'],
    [0.4156862745098039, '#d8c8ca'],
    [0.4196078431372549, '#d8c9c9'],
    [0.4235294117647059, '#d8c9c9'],
    [0.42745098039215684, '#d8cac8'],
    [0.43137254901960786, '#d7cac8'],
    [0.43529411764705883, '#d7cac7'],
    [0.4392156862745098, '#d7cbc7'],
    [0.44313725490196076, '#d6cbc7'],
    [0.44705882352941173, '#d6cbc6'],
    [0.45098039215686275, '#d6ccc6'],
    [0.4549019607843137, '#d5ccc5'],
    [0.4588235294117647, '#d5ccc4'],
    [0.4627450980392157, '#d4ccc4'],
    [0.4666666666666667, '#d4cdc3'],
    [0.47058823529411764, '#d4cdc2'],
    [0.4745098039215686, '#d3cdc2'],
    [0.4784313725490196, '#d2cdc1'],
    [0.4823529411764706, '#d2cdc0'],
    [0.48627450980392156, '#d1cdbf'],
    [0.49019607843137253, '#d1cdbe'],
    [0.49411764705882355, '#d0cdbd'],
    [0.4980392156862745, '#cfcdbc'],
    [0.5019607843137255, '#cecdbb'],
    [0.5058823529411764, '#cdcdba'],
    [0.5098039215686274, '#cccdb9'],
    [0.5137254901960784, '#cbccb7'],
    [0.5176470588235293, '#caccb6'],
    [0.5215686274509804, '#c9ccb4'],
    [0.5254901960784314, '#c8cbb2'],
    [0.5294117647058824, '#c6cbb1'],
    [0.5333333333333333, '#c5caaf'],
    [0.5372549019607843, '#c3c9ad'],
    [0.5411764705882353, '#c2c8aa'],
    [0.5450980392156862, '#c0c7a8'],
    [0.5490196078431373, '#bec6a6'],
    [0.5529411764705883, '#bcc5a4'],
    [0.5568627450980392, '#bac4a1'],
    [0.5607843137254902, '#b8c29e'],
    [0.5647058823529412, '#b6c19c'],
    [0.5686274509803921, '#b4bf99'],
    [0.5725490196078431, '#b2be97'],
    [0.5764705882352941, '#b0bc94'],
    [0.580392156862745, '#adba91'],
    [0.5843137254901961, '#abb88f'],
    [0.5882352941176471, '#a9b68c'],
    [0.592156862745098, '#a7b58a'],
    [0.596078431372549, '#a5b387'],
    [0.6, '#a2b185'],
    [0.6039215686274509, '#a0af83'],
    [0.6078431372549019, '#9ead80'],
    [0.611764705882353, '#9cab7e'],
    [0.615686274509804, '#9aa97c'],
    [0.6196078431372549, '#98a77a'],
    [0.6235294117647059, '#96a578'],
    [0.6274509803921569, '#94a376'],
    [0.6313725490196078, '#92a174'],
    [0.6352941176470588, '#919f72'],
    [0.6392156862745098, '#8f9e70'],
    [0.6431372549019607, '#8d9c6e'],
    [0.6470588235294118, '#8b9a6d'],
    [0.6509803921568628, '#8a986b'],
    [0.6549019607843137, '#889669'],
    [0.6588235294117647, '#869468'],
    [0.6627450980392157, '#859366'],
    [0.6666666666666666, '#839165'],
    [0.6705882352941176, '#828f63'],
    [0.6745098039215687, '#808d62'],
    [0.6784313725490196, '#7f8c61'],
    [0.6823529411764706, '#7d8a5f'],
    [0.6862745098039216, '#7c885e'],
    [0.6901960784313725, '#7b875d'],
    [0.6941176470588235, '#79855c'],
    [0.6980392156862745, '#78835b'],
    [0.7019607843137254, '#77825a'],
    [0.7058823529411764, '#758058'],
    [0.7098039215686275, '#747f57'],
    [0.7137254901960784, '#737d56'],
    [0.7176470588235294, '#727c55'],
    [0.7215686274509804, '#717a54'],
    [0.7254901960784313, '#6f7953'],
    [0.7294117647058823, '#6e7752'],
    [0.7333333333333333, '#6d7552'],
    [0.7372549019607844, '#6c7451'],
    [0.7411764705882353, '#6b7250'],
    [0.7450980392156863, '#6a714f'],
    [0.7490196078431373, '#696f4e'],
    [0.7529411764705882, '#676e4d'],
    [0.7568627450980392, '#666c4c'],
    [0.7607843137254902, '#656b4c'],
    [0.7647058823529411, '#64694b'],
    [0.7686274509803921, '#63684a'],
    [0.7725490196078432, '#626649'],
    [0.7764705882352941, '#616548'],
    [0.7803921568627451, '#606348'],
    [0.7843137254901961, '#5f6247'],
    [0.788235294117647, '#5e6046'],
    [0.792156862745098, '#5d5f46'],
    [0.796078431372549, '#5c5d45'],
    [0.8, '#5b5c44'],
    [0.803921568627451, '#5a5b44'],
    [0.807843137254902, '#595943'],
    [0.8117647058823529, '#585842'],
    [0.8156862745098039, '#575642'],
    [0.8196078431372549, '#575541'],
    [0.8235294117647058, '#565441'],
    [0.8274509803921568, '#555340'],
    [0.8313725490196078, '#545140'],
    [0.8352941176470589, '#53503f'],
    [0.8392156862745098, '#534f3f'],
    [0.8431372549019608, '#524e3f'],
    [0.8470588235294118, '#514d3e'],
    [0.8509803921568627, '#514c3e'],
    [0.8549019607843137, '#504b3d'],
    [0.8588235294117647, '#50493d'],
    [0.8627450980392157, '#4f483d'],
    [0.8666666666666667, '#4f483c'],
    [0.8705882352941177, '#4e473c'],
    [0.8745098039215686, '#4e463c'],
    [0.8784313725490196, '#4d453c'],
    [0.8823529411764706, '#4d443b'],
    [0.8862745098039215, '#4c433b'],
    [0.8901960784313725, '#4c423b'],
    [0.8941176470588235, '#4b413b'],
    [0.8980392156862745, '#4b403b'],
    [0.9019607843137255, '#4b403b'],
    [0.9058823529411765, '#4a3f3a'],
    [0.9098039215686274, '#4a3e3a'],
    [0.9137254901960784, '#4a3d3a'],
    [0.9176470588235294, '#4a3c3a'],
    [0.9215686274509803, '#493c3a'],
    [0.9254901960784314, '#493b3a'],
    [0.9294117647058824, '#493a3a'],
    [0.9333333333333333, '#49393a'],
    [0.9372549019607843, '#49393a'],
    [0.9411764705882353, '#49383a'],
    [0.9450980392156862, '#48373a'],
    [0.9490196078431372, '#48363b'],
    [0.9529411764705882, '#48363b'],
    [0.9568627450980391, '#48353b'],
    [0.9607843137254902, '#49343b'],
    [0.9647058823529412, '#49343c'],
    [0.9686274509803922, '#49333c'],
    [0.9725490196078431, '#49323d'],
    [0.9764705882352941, '#4a323d'],
    [0.9803921568627451, '#4a313e'],
    [0.984313725490196, '#4b313e'],
    [0.9882352941176471, '#4b313f'],
    [0.9921568627450981, '#4c3040'],
    [0.996078431372549, '#4d3041'],
    [1.0, '#4e3042'],
  ],
  acton: [
    [0.0, '#2e214d'],
    [0.00392156862745098, '#2f224d'],
    [0.00784313725490196, '#30234e'],
    [0.011764705882352941, '#31244f'],
    [0.01568627450980392, '#322550'],
    [0.0196078431372549, '#332651'],
    [0.023529411764705882, '#342652'],
    [0.027450980392156862, '#352753'],
    [0.03137254901960784, '#362854'],
    [0.03529411764705882, '#372955'],
    [0.0392156862745098, '#382a56'],
    [0.043137254901960784, '#392b57'],
    [0.047058823529411764, '#3a2c58'],
    [0.050980392156862744, '#3b2d58'],
    [0.054901960784313725, '#3c2e59'],
    [0.058823529411764705, '#3d2f5a'],
    [0.06274509803921569, '#3e305b'],
    [0.06666666666666667, '#3f305c'],
    [0.07058823529411765, '#41315d'],
    [0.07450980392156863, '#42325e'],
    [0.0784313725490196, '#43335f'],
    [0.08235294117647059, '#443460'],
    [0.08627450980392157, '#453561'],
    [0.09019607843137255, '#463662'],
    [0.09411764705882353, '#473763'],
    [0.09803921568627451, '#483864'],
    [0.10196078431372549, '#493964'],
    [0.10588235294117647, '#4a3a65'],
    [0.10980392156862745, '#4b3b66'],
    [0.11372549019607843, '#4c3c67'],
    [0.11764705882352941, '#4d3d68'],
    [0.12156862745098039, '#4f3d69'],
    [0.12549019607843137, '#503e6a'],
    [0.12941176470588234, '#513f6b'],
    [0.13333333333333333, '#52406c'],
    [0.13725490196078433, '#53416d'],
    [0.1411764705882353, '#54426e'],
    [0.14509803921568626, '#55436f'],
    [0.14901960784313725, '#564470'],
    [0.15294117647058825, '#584571'],
    [0.1568627450980392, '#594672'],
    [0.16078431372549018, '#5a4772'],
    [0.16470588235294117, '#5b4873'],
    [0.16862745098039217, '#5c4974'],
    [0.17254901960784313, '#5e4975'],
    [0.1764705882352941, '#5f4a76'],
    [0.1803921568627451, '#604b77'],
    [0.1843137254901961, '#614c78'],
    [0.18823529411764706, '#634d79'],
    [0.19215686274509802, '#644e7a'],
    [0.19607843137254902, '#654f7b'],
    [0.2, '#66507b'],
    [0.20392156862745098, '#68507c'],
    [0.20784313725490194, '#69517d'],
    [0.21176470588235294, '#6a527e'],
    [0.21568627450980393, '#6b537f'],
    [0.2196078431372549, '#6d5480'],
    [0.22352941176470587, '#6e5480'],
    [0.22745098039215686, '#6f5581'],
    [0.23137254901960785, '#715682'],
    [0.23529411764705882, '#725783'],
    [0.2392156862745098, '#735783'],
    [0.24313725490196078, '#755884'],
    [0.24705882352941178, '#765985'],
    [0.25098039215686274, '#775a86'],
    [0.2549019607843137, '#795a86'],
    [0.2588235294117647, '#7a5b87'],
    [0.2627450980392157, '#7b5b88'],
    [0.26666666666666666, '#7d5c88'],
    [0.27058823529411763, '#7e5d89'],
    [0.27450980392156865, '#7f5d89'],
    [0.2784313725490196, '#815e8a'],
    [0.2823529411764706, '#825e8b'],
    [0.28627450980392155, '#835f8b'],
    [0.2901960784313725, '#855f8c'],
    [0.29411764705882354, '#86608c'],
    [0.2980392156862745, '#87608d'],
    [0.30196078431372547, '#88618d'],
    [0.3058823529411765, '#8a618e'],
    [0.30980392156862746, '#8b618e'],
    [0.3137254901960784, '#8c628e'],
    [0.3176470588235294, '#8d628f'],
    [0.32156862745098036, '#8f628f'],
    [0.3254901960784314, '#906390'],
    [0.32941176470588235, '#916390'],
    [0.3333333333333333, '#926390'],
    [0.33725490196078434, '#946490'],
    [0.3411764705882353, '#956491'],
    [0.34509803921568627, '#966491'],
    [0.34901960784313724, '#976491'],
    [0.3529411764705882, '#986491'],
    [0.3568627450980392, '#996592'],
    [0.3607843137254902, '#9b6592'],
    [0.36470588235294116, '#9c6592'],
    [0.3686274509803922, '#9d6592'],
    [0.37254901960784315, '#9e6592'],
    [0.3764705882352941, '#9f6593'],
    [0.3803921568627451, '#a06593'],
    [0.38431372549019605, '#a16593'],
    [0.38823529411764707, '#a26693'],
    [0.39215686274509803, '#a46693'],
    [0.396078431372549, '#a56693'],
    [0.4, '#a66694'],
    [0.403921568627451, '#a76694'],
    [0.40784313725490196, '#a86694'],
    [0.4117647058823529, '#a96694'],
    [0.4156862745098039, '#aa6694'],
    [0.4196078431372549, '#ab6694'],
    [0.4235294117647059, '#ac6694'],
    [0.42745098039215684, '#ad6795'],
    [0.43137254901960786, '#af6795'],
    [0.43529411764705883, '#b06795'],
    [0.4392156862745098, '#b16795'],
    [0.44313725490196076, '#b26795'],
    [0.44705882352941173, '#b36795'],
    [0.45098039215686275, '#b46896'],
    [0.4549019607843137, '#b56896'],
    [0.4588235294117647, '#b76896'],
    [0.4627450980392157, '#b86996'],
    [0.4666666666666667, '#b96997'],
    [0.47058823529411764, '#ba6997'],
    [0.4745098039215686, '#bb6a98'],
    [0.4784313725490196, '#bd6a98'],
    [0.4823529411764706, '#be6b98'],
    [0.48627450980392156, '#bf6b99'],
    [0.49019607843137253, '#c06c99'],
    [0.49411764705882355, '#c16d9a'],
    [0.4980392156862745, '#c36d9a'],
    [0.5019607843137255, '#c46e9b'],
    [0.5058823529411764, '#c56f9c'],
    [0.5098039215686274, '#c6709c'],
    [0.5137254901960784, '#c7709d'],
    [0.5176470588235293, '#c8719e'],
    [0.5215686274509804, '#c9729e'],
    [0.5254901960784314, '#ca739f'],
    [0.5294117647058824, '#cb74a0'],
    [0.5333333333333333, '#cc75a1'],
    [0.5372549019607843, '#cd76a1'],
    [0.5411764705882353, '#ce77a2'],
    [0.5450980392156862, '#cf78a3'],
    [0.5490196078431373, '#d079a4'],
    [0.5529411764705883, '#d07aa4'],
    [0.5568627450980392, '#d17ba5'],
    [0.5607843137254902, '#d17ca6'],
    [0.5647058823529412, '#d27da7'],
    [0.5686274509803921, '#d27ea7'],
    [0.5725490196078431, '#d37fa8'],
    [0.5764705882352941, '#d380a9'],
    [0.580392156862745, '#d381aa'],
    [0.5843137254901961, '#d482aa'],
    [0.5882352941176471, '#d483ab'],
    [0.592156862745098, '#d484ac'],
    [0.596078431372549, '#d485ac'],
    [0.6, '#d486ad'],
    [0.6039215686274509, '#d587ae'],
    [0.6078431372549019, '#d588ae'],
    [0.611764705882353, '#d589af'],
    [0.615686274509804, '#d58ab0'],
    [0.6196078431372549, '#d58bb0'],
    [0.6235294117647059, '#d58cb1'],
    [0.6274509803921569, '#d58cb2'],
    [0.6313725490196078, '#d58db2'],
    [0.6352941176470588, '#d58eb3'],
    [0.6392156862745098, '#d58fb3'],
    [0.6431372549019607, '#d590b4'],
    [0.6470588235294118, '#d491b5'],
    [0.6509803921568628, '#d491b5'],
    [0.6549019607843137, '#d492b6'],
    [0.6588235294117647, '#d493b6'],
    [0.6627450980392157, '#d494b7'],
    [0.6666666666666666, '#d495b8'],
    [0.6705882352941176, '#d495b8'],
    [0.6745098039215687, '#d496b9'],
    [0.6784313725490196, '#d497b9'],
    [0.6823529411764706, '#d498ba'],
    [0.6862745098039216, '#d499bb'],
    [0.6901960784313725, '#d49abb'],
    [0.6941176470588235, '#d49abc'],
    [0.6980392156862745, '#d49bbc'],
    [0.7019607843137254, '#d49cbd'],
    [0.7058823529411764, '#d39dbd'],
    [0.7098039215686275, '#d39ebe'],
    [0.7137254901960784, '#d39fbf'],
    [0.7176470588235294, '#d39fbf'],
    [0.7215686274509804, '#d3a0c0'],
    [0.7254901960784313, '#d3a1c1'],
    [0.7294117647058823, '#d3a2c1'],
    [0.7333333333333333, '#d3a3c2'],
    [0.7372549019607844, '#d3a4c2'],
    [0.7411764705882353, '#d3a5c3'],
    [0.7450980392156863, '#d3a6c4'],
    [0.7490196078431373, '#d4a6c4'],
    [0.7529411764705882, '#d4a7c5'],
    [0.7568627450980392, '#d4a8c5'],
    [0.7607843137254902, '#d4a9c6'],
    [0.7647058823529411, '#d4aac7'],
    [0.7686274509803921, '#d4abc7'],
    [0.7725490196078432, '#d4acc8'],
    [0.7764705882352941, '#d4adc9'],
    [0.7803921568627451, '#d4aec9'],
    [0.7843137254901961, '#d4afca'],
    [0.788235294117647, '#d5afcb'],
    [0.792156862745098, '#d5b0cb'],
    [0.796078431372549, '#d5b1cc'],
    [0.8, '#d5b2cd'],
    [0.803921568627451, '#d5b3cd'],
    [0.807843137254902, '#d5b4ce'],
    [0.8117647058823529, '#d6b5ce'],
    [0.8156862745098039, '#d6b6cf'],
    [0.8196078431372549, '#d6b7d0'],
    [0.8235294117647058, '#d6b8d0'],
    [0.8274509803921568, '#d6b9d1'],
    [0.8313725490196078, '#d7bad2'],
    [0.8352941176470589, '#d7bbd2'],
    [0.8392156862745098, '#d7bcd3'],
    [0.8431372549019608, '#d7bdd4'],
    [0.8470588235294118, '#d8bed5'],
    [0.8509803921568627, '#d8bfd5'],
    [0.8549019607843137, '#d8c0d6'],
    [0.8588235294117647, '#d9c1d7'],
    [0.8627450980392157, '#d9c2d7'],
    [0.8666666666666667, '#d9c3d8'],
    [0.8705882352941177, '#dac4d9'],
    [0.8745098039215686, '#dac5d9'],
    [0.8784313725490196, '#dac6da'],
    [0.8823529411764706, '#dac7db'],
    [0.8862745098039215, '#dbc8db'],
    [0.8901960784313725, '#dbc9dc'],
    [0.8941176470588235, '#dbcadd'],
    [0.8980392156862745, '#dccbdd'],
    [0.9019607843137255, '#dcccde'],
    [0.9058823529411765, '#ddcddf'],
    [0.9098039215686274, '#ddcee0'],
    [0.9137254901960784, '#ddcfe0'],
    [0.9176470588235294, '#ded0e1'],
    [0.9215686274509803, '#ded1e2'],
    [0.9254901960784314, '#ded2e2'],
    [0.9294117647058824, '#dfd3e3'],
    [0.9333333333333333, '#dfd4e4'],
    [0.9372549019607843, '#dfd5e4'],
    [0.9411764705882353, '#e0d6e5'],
    [0.9450980392156862, '#e0d7e6'],
    [0.9490196078431372, '#e1d8e7'],
    [0.9529411764705882, '#e1d9e7'],
    [0.9568627450980391, '#e1dae8'],
    [0.9607843137254902, '#e2dbe9'],
    [0.9647058823529412, '#e2dce9'],
    [0.9686274509803922, '#e3ddea'],
    [0.9725490196078431, '#e3deeb'],
    [0.9764705882352941, '#e3dfeb'],
    [0.9803921568627451, '#e4e0ec'],
    [0.984313725490196, '#e4e1ed'],
    [0.9882352941176471, '#e4e2ee'],
    [0.9921568627450981, '#e5e3ee'],
    [0.996078431372549, '#e5e4ef'],
    [1.0, '#e6e6f0'],
  ],
};

import React, { FC } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const MuiCircularProgress: FC<CircularProgressProps> = ({ size = 20, ...props }) => (
  <CircularProgress {...props} size={size} />
);

export const PreviewCircular = styled(CircularProgress)(() => ({
  display: 'block',
  margin: '0 auto',
}));

export { MuiCircularProgress as CircularProgress };

import React, { FC } from 'react';
import styled from '@emotion/styled/macro';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import loader from 'app/assets/images/analysis/loader.gif';
import { StyledProgress } from 'app/shared/components/ProgressBar/ProgressBar.styles';

const StyledLoader = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: '64px',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  '& p': {
    color: '#737373',
    width: '370px',
    textAlign: 'center',
    opacity: 0,
  },
  '&:hover': {
    '& p': {
      opacity: 1,
      transition: '0.6s',
    },
  },
}));
export const VisualizationLoader: FC = () => (
  <Box position='absolute' width='100%' height='100%' zIndex={110} bgcolor='white'>
    <StyledProgress />
    <StyledLoader>
      <Box component='img' src={loader} alt='loader' />
      <Typography variant='body1'>
        Generating visualization. This can take some time, depending on dataset size.
      </Typography>
    </StyledLoader>
  </Box>
);

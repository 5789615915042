import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  keyframes,
  TableContainer,
} from '@mui/material';

const highlighted = keyframes({
  '0%': { background: 'rgba(0, 209, 255, 0.05)' },
  '40%': { background: '#F8F8F8' },
  '80%': { background: 'rgba(0, 209, 255, 0.05)' },
  '100%': { background: '#F8F8F8' },
});

export const GroupItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  boxSizing: 'border-box',
  cursor: 'pointer',
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
  height: '48px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  '& svg': {
    display: 'none',
  },
  '&:hover': {
    background: theme.palette.state.hoverBackground,
    borderBottom: `1px solid ${theme.palette.blocksBackground.dark}`,
    '& svg': {
      display: 'block',
      stroke: theme.palette.state.hoverBorder,
    },
  },
  '&.selected': {
    background: '#BDDDE8',
    borderBottom: `1px solid ${theme.palette.blocksBackground.dark}`,
    '& svg': {
      display: 'block',
      stroke: theme.palette.blocksBackground.dark,
    },
  },
  '&.highlight': {
    animationName: highlighted,
    animationDuration: '2s',
  },
  '&:first-of-type': {
    borderTop: 'none',
  },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflow: 'hidden',
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  borderRadius: '4px',
  '& th, & td': {
    padding: theme.spacing(1),
    textAlign: 'center',
    maxWidth: '38px',
    wordWrap: 'break-word',
    '&:not(:first-of-type):not(:last-child)': {
      borderLeft: `1px solid ${theme.palette.action.disabledBackground}`,
      borderRight: `1px solid ${theme.palette.action.disabledBackground}`,
    },
  },
  '& th': {
    fontSize: '1.2rem',
    fontWeight: '500',
    borderRight: `1px solid ${theme.palette.action.disabledBackground}`,
  },
  '& tbody tr': {
    borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
  },
  '& svg path, svg line, svg polyline': {
    strokeWidth: '1.5px',
  },
  '& .delete': {
    cursor: 'pointer',
  },
}));

export const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: '20px',
  height: 'auto',
  padding: 0,
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '&.chevronUp .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
    margin: `0 0 0 ${theme.spacing(0.5)}`,
    fontSize: '12px',
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontSize: '12px',
  padding: `${theme.spacing(1)} 0`,
}));

export const SelectionSourceBox = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  background: '#D7EBE0',
  borderRadius: '8px',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
}));

export const FeatureName = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
  padding: theme.spacing(1, 0),
  '&:first-of-type': {
    borderTop: 'none',
  },
}));

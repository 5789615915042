import React, { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { MoreVertical } from 'react-feather';
import classNames from 'classnames';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import { IconButton } from 'app/mui/IconButton';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';
import { StatusEnum } from 'app/shared/enum/status';
import { Status } from 'app/shared/components/Status/Status';
import { SelectedDataset } from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList';
import { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { DataLibraryActions } from 'app/screens/DataLibrary/DataLibraryActions/DataLibraryActions';
import { useDataLibrary } from 'app/screens/DataLibrary/DataLibrary.hooks';
import { ProjectDatasets } from 'app/screens/Projects/Projects.types';
import { DatasetItem } from './DatasetItem.stylex';

type DatasetCardProps = {
  row: ProjectDatasets;
  labelId: string;
  selectedDataset?: SelectedDataset;
  setSelectedDataset: Dispatch<SetStateAction<any>>;
  startAnalysis: () => Promise<void>;
  setIsEditDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDetachDialogOpen: Dispatch<SetStateAction<boolean>>;
  handlePreviewOpen: (id: number, isExample: boolean | null) => void;
};

export const DatasetCard: FC<DatasetCardProps> = ({
  row,
  labelId,
  selectedDataset,
  setSelectedDataset,
  startAnalysis,
  setIsEditDialogOpen,
  setIsDetachDialogOpen,
  handlePreviewOpen,
}) => {
  const { showPublishedDataset } = useDataLibrary();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLElement>,
    datasetId: number,
    name: string,
    description: string
  ): void => {
    setSelectedDataset((dataset: Dataset) => ({
      ...dataset,
      id: datasetId,
      name,
      description,
    }));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onEditDialogOpen = useCallback(() => {
    setIsEditDialogOpen(true);
    handleClose();
  }, []);

  const onDetachDialogOpen = useCallback(() => {
    setIsDetachDialogOpen(true);
    handleClose();
  }, []);

  const datasetActions = useMemo(
    () => (row.is_example ? {} : { onEditDataset: onEditDialogOpen }),
    [row.is_example]
  );

  return (
    <>
      <DatasetItem
        className={classNames({ highlight: row.id === selectedDataset?.previewId })}
        onClick={() => handlePreviewOpen(row.id, row.is_example)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            cursor: 'pointer',
            flex: 1,
            minWidth: 0,
          }}
        >
          <Box ml={2} display='flex' flex={1} minWidth={0} flexDirection='column' rowGap='12px'>
            <Box>
              <Tooltip title={row.name} placement='bottom-start'>
                <Typography noWrap variant='subtitle1' id={labelId} className='break-text'>
                  {row.name}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box ml={2}>
            <Status status={showPublishedDataset(row.status) ? StatusEnum.RAW : row.status} />
          </Box>
          <Box>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleClickMenu(event, row.id, row.name, row.description);
              }}
            >
              <MoreVertical />
            </IconButton>
          </Box>
        </Box>
      </DatasetItem>
      <DotsMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <DataLibraryActions
          onCreateAnalysis={startAnalysis}
          onDetachDataset={onDetachDialogOpen}
          {...datasetActions}
        />
      </DotsMenu>
    </>
  );
};

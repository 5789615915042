import React, { FC, useEffect, useState } from 'react';

import Plot from 'app/shared/components/Plotly/index.js';
import { ChartDataType } from 'app/screens/Analysis/Analysis.types';

import { violinPlotConfig, DEFAULT_CHART_HEIGHT, RESET_SCALE_ICON } from '../Wrappers/Chart.config';

type Props = {
  chart: ChartDataType;
  width: number;
  height?: number;
  isGenerateSvg: boolean;
  onSvgGenerate: (chart: ChartDataType) => void;
};

export const ViolinPlot: FC<Props> = ({
  chart,
  width,
  height = DEFAULT_CHART_HEIGHT,
  isGenerateSvg,
  onSvgGenerate,
}) => {
  const [chartLayout, setChartLayout] = useState({
    height,
    width,
    yaxis: {
      zeroline: false,
    },
  });

  const data = chart.features.map((feature) => ({
    type: 'violin',
    x: feature.feature_name,
    y: feature.values,
    name: feature.feature_name,
    points: 'none',
    box: {
      visible: true,
    },
    meanline: {
      visible: true,
    },
  })) as any;

  useEffect(() => {
    setChartLayout((prevState) => ({
      ...prevState,
      width,
      height,
    }));
  }, [width, height]);

  useEffect(() => {
    if (isGenerateSvg && chart) {
      onSvgGenerate(chart);
    }
  }, [isGenerateSvg, chart]);

  return (
    <div className='chart-container'>
      {
        // @ts-ignore
        <Plot
          divId={`${chart.type}_${chart.id}`}
          data={data}
          layout={chartLayout}
          config={{
            modeBarButtons: [
              ['blaiZoomIn', 'blaiZoomOut'],
              [
                {
                  name: 'Reset scale',
                  title: 'Reset scale',
                  icon: {
                    svg: RESET_SCALE_ICON,
                  },
                  click: () => {
                    setChartLayout({
                      height,
                      width,
                      yaxis: {
                        zeroline: false,
                      },
                    });
                  },
                },
              ],
            ],
            doubleClick: false,
            ...violinPlotConfig,
          }}
        />
      }
    </div>
  );
};

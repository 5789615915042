import React, { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
};

export const Invert: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6ZM7.53846 16C7.53846 11.3268 11.3268 7.53846 16 7.53846C16.0002 7.53846 15.9998 7.53846 16 7.53846L16.0006 12.4497C15.059 12.4497 14.156 12.8238 13.4902 13.4896C12.8244 14.1554 12.4503 15.0584 12.4503 16C12.4503 16.9416 12.8244 17.8446 13.4902 18.5104C14.156 19.1762 15.059 19.5503 16.0006 19.5503V24.4615C16.0004 24.4615 16.0008 24.4615 16.0006 24.4615C11.3274 24.4615 7.53846 20.6732 7.53846 16ZM16.0006 19.5503C16.9422 19.5503 17.8452 19.1762 18.5111 18.5104C19.1769 17.8446 19.5509 16.9416 19.5509 16C19.5509 15.0584 19.1769 14.1554 18.5111 13.4896C17.8452 12.8238 16.9422 12.4497 16.0006 12.4497V19.5503Z'
      fill='#122239'
    />
    <path
      d='M16 7.53846C11.3268 7.53846 7.53846 11.3268 7.53846 16C7.53846 20.6732 11.3274 24.4615 16.0006 24.4615M16 7.53846C15.9998 7.53846 16.0002 7.53846 16 7.53846ZM16 7.53846L16.0006 12.4497M16.0006 12.4497C15.059 12.4497 14.156 12.8238 13.4902 13.4896C12.8244 14.1554 12.4503 15.0584 12.4503 16C12.4503 16.9416 12.8244 17.8446 13.4902 18.5104C14.156 19.1762 15.059 19.5503 16.0006 19.5503M16.0006 12.4497C16.9422 12.4497 17.8452 12.8238 18.5111 13.4896C19.1769 14.1554 19.5509 15.0584 19.5509 16C19.5509 16.9416 19.1769 17.8446 18.5111 18.5104C17.8452 19.1762 16.9422 19.5503 16.0006 19.5503M16.0006 12.4497V19.5503M16.0006 19.5503V24.4615M16.0006 24.4615C16.0008 24.4615 16.0004 24.4615 16.0006 24.4615ZM6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16Z'
      stroke='#122239'
      strokeWidth='0.5'
    />
  </svg>
);

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSigma } from '@react-sigma/core';

import type { Point } from './types';

type GraphLassoProps = {
  active: boolean;
  isFullScreen: boolean;
  setSelectedNodes: (nodes: number[]) => void;
  addSelectedNodes: (nodes: number[]) => void;
};

export const GraphLasso: FC<GraphLassoProps> = ({
  active,
  isFullScreen,
  setSelectedNodes,
  addSelectedNodes,
}) => {
  const sigma = useSigma();
  const lassoCanvasRef = useRef<HTMLCanvasElement>(null);
  const [width, setWidth] = useState<number>(() => sigma.getContainer().offsetWidth);
  const [height, setHeight] = useState<number>(() => sigma.getContainer().offsetHeight);

  const getLassoCanvas = useCallback((): HTMLCanvasElement | null => {
    if (active) return lassoCanvasRef.current;
    return null;
  }, [active]);

  const isDrawing = useRef(false);
  const drawnPoints = useRef<Point[]>([]);

  const onDrawingStart = (event: React.MouseEvent): void => {
    event.preventDefault();
    const lassoCanvas = getLassoCanvas();

    if (!lassoCanvas) return;

    isDrawing.current = true;
    drawnPoints.current = [];

    const drawingRectangle = lassoCanvas.getBoundingClientRect();

    drawnPoints.current.push({
      x: event.clientX - drawingRectangle.left,
      y: event.clientY - drawingRectangle.top,
    });

    event.stopPropagation();
  };

  const onDrawing = (event: React.MouseEvent | any): void => {
    const lassoCanvas = getLassoCanvas();

    if (!lassoCanvas) return;

    if (isDrawing.current) {
      let x;
      let y;
      const drawingRectangle = lassoCanvas.getBoundingClientRect();
      const drawingContext = lassoCanvas.getContext('2d') as CanvasRenderingContext2D;

      switch (event.type) {
        case 'touchmove':
          x = event.touches[0].clientX;
          y = event.touches[0].clientY;
          break;
        default:
          x = event.clientX;
          y = event.clientY;
          break;
      }

      drawnPoints.current.push({
        x: x - drawingRectangle.left,
        y: y - drawingRectangle.top,
      });

      // Drawing styles
      drawingContext.strokeStyle = 'black';
      drawingContext.lineWidth = 2;
      drawingContext.fillStyle = 'rgba(200, 200, 200, 0.25)';
      drawingContext.lineJoin = 'round';
      drawingContext.lineCap = 'round';

      // Clear the canvas
      drawingContext.clearRect(0, 0, drawingContext.canvas.width, drawingContext.canvas.height);

      // Redraw the complete path for a smoother effect
      // Even smoother with quadratic curves
      let sourcePoint = drawnPoints.current[0];
      let destinationPoint = drawnPoints.current[1];
      const pointsLength = drawnPoints.current.length;
      const getMiddlePointCoordinates = (firstPoint: Point, secondPoint: Point): Point => ({
        x: firstPoint.x + (secondPoint.x - firstPoint.x) / 2,
        y: firstPoint.y + (secondPoint.y - firstPoint.y) / 2,
      });

      drawingContext.beginPath();
      drawingContext.moveTo(sourcePoint.x, sourcePoint.y);

      for (let i = 1; i < pointsLength; i += 1) {
        const middlePoint = getMiddlePointCoordinates(sourcePoint, destinationPoint);
        drawingContext.quadraticCurveTo(sourcePoint.x, sourcePoint.y, middlePoint.x, middlePoint.y);
        sourcePoint = drawnPoints.current[i];
        destinationPoint = drawnPoints.current[i + 1];
      }

      drawingContext.lineTo(sourcePoint.x, sourcePoint.y);
      drawingContext.stroke();

      drawingContext.fill();
      event.stopPropagation();
    }
  };

  const onDrawingEnd = useCallback(
    (event: React.MouseEvent): void => {
      const lassoCanvas = getLassoCanvas();
      const viewRectangle = sigma.viewRectangle();
      const graph = sigma.getGraph();

      if (!lassoCanvas) return;
      isDrawing.current = false;

      // @ts-ignore - There are no other ways to get visible nodes
      const quadtreeRectangle = sigma.quadtree.rectangle(
        viewRectangle.x1,
        1 - viewRectangle.y1,
        viewRectangle.x2,
        1 - viewRectangle.y2,
        viewRectangle.height
      );
      const visibleNodes = Array.from(new Set(quadtreeRectangle));

      const drawingContext = lassoCanvas.getContext('2d') as CanvasRenderingContext2D;

      const newSelectedNodes: number[] = [];

      visibleNodes.forEach((key) => {
        const nodeValue = Number(key);
        const { x, y } = graph.getNodeAttributes(nodeValue);
        const coordinates = sigma.graphToViewport({ x, y });
        if (drawingContext.isPointInPath(coordinates.x, coordinates.y)) {
          newSelectedNodes.push(nodeValue);
        }
      });

      if (event.shiftKey) {
        addSelectedNodes(newSelectedNodes);
      } else {
        setSelectedNodes(newSelectedNodes);
      }

      drawingContext.clearRect(0, 0, lassoCanvas.width, lassoCanvas.height);
      // this resets the active path so it doesn't stick around
      drawingContext.beginPath();
      drawnPoints.current = [];

      event.stopPropagation();
    },
    [addSelectedNodes, getLassoCanvas, setSelectedNodes, sigma]
  );

  const onLassoScroll = (e: React.WheelEvent | WheelEvent): void => {
    const mouseCaptor = sigma.getMouseCaptor();
    mouseCaptor.handleWheel(e as WheelEvent);
  };

  useEffect(() => {
    if (lassoCanvasRef.current) {
      lassoCanvasRef.current.addEventListener('wheel', onLassoScroll, { passive: false });

      return () => {
        lassoCanvasRef.current?.removeEventListener('wheel', onLassoScroll);
      };
    }
  }, [active]);

  useEffect(() => {
    setWidth(sigma.getContainer().offsetWidth);
    setHeight(sigma.getContainer().offsetHeight);
  }, [sigma, isFullScreen]);

  if (!active) return null;

  return (
    <canvas
      ref={lassoCanvasRef}
      width={width}
      height={height}
      style={{ position: 'absolute', top: '0px', cursor: 'crosshair' }}
      onMouseDown={onDrawingStart}
      onMouseMove={onDrawing}
      onMouseUp={onDrawingEnd}
    />
  );
};

import React, { CSSProperties, HTMLAttributes, ReactNode, useMemo } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete';
import {
  StyledVirtualPopper,
  VirtualizedListBox,
} from 'app/shared/components/VirtualizedListBox/VirtualizedListBox';

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderOption'> {
  useVirtual?: boolean;
  optionHeight?: number;
  maxHeight?: number;
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState,
    style?: CSSProperties
  ) => React.ReactNode;
}

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  useVirtual,
  renderOption,
  optionHeight,
  maxHeight,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const virtualProps = useMemo(
    () =>
      useVirtual
        ? {
            disableListWrap: true,
            PopperComponent: StyledVirtualPopper,
            ListboxComponent: VirtualizedListBox({ optionHeight, maxHeight }),
            renderOption: (
              optionProp: HTMLAttributes<HTMLLIElement>,
              option: T,
              state: AutocompleteRenderOptionState
            ) => [optionProp, option, state, renderOption] as ReactNode,
          }
        : {
            renderOption,
          },
    [useVirtual, optionHeight, maxHeight]
  );

  return <MuiAutocomplete {...props} {...virtualProps} />;
};

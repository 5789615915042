import React from 'react';

export const DataLandscapeMetric = [
  {
    name: 'euclidean',
    displayName: 'Euclidean',
    description: (
      <p>
        Square root of sum of squared differences in each coordinate (L<sup>2</sup> distance).
      </p>
    ),
  },
  {
    name: 'cosine',
    displayName: 'Cosine',
    description:
      'Normalized dot product of vectors. Vectors forming a small angle are closer. Scale invariant.',
  },
  {
    name: 'l1',
    displayName: 'Manhattan',
    description: (
      <p>
        Sum of absolute differences in each coordinate (L<sup>1</sup> distance).
      </p>
    ),
  },
  {
    name: 'linf',
    displayName: 'Chebyshev',
    description: (
      <p>
        Maximum absolute difference in coordinates (L<sup>∞</sup> distance).
      </p>
    ),
  },
  {
    name: 'hamming',
    displayName: 'Hamming',
    description:
      'Number of coordinates with different values. Most applicable to categorical features.',
  },
  {
    name: 'braycurtis',
    displayName: 'Bray–Curtis',
    description:
      'Similar to a weighted version of the Jaccard distance appropriate for abundance counts of species or taxa.',
  },
  {
    name: 'canberra',
    displayName: 'Canberra',
    description:
      'Rescaled version of Manhattan distance appropriate for abundance counts of species or taxa.',
  },
  {
    name: 'jaccard',
    displayName: 'Jaccard/Tanimoto',
    description:
      'Assumes binary features, treated as sets. Size of intersection divided by size of union.',
  },
  {
    name: 'correlation',
    displayName: 'Correlation',
    description:
      'Pearson correlation coefficient: captures whether one feature linearly varies with another. Scale invariant. (Caution advised for data landscapes).',
  },
];

export const FeatureLandscapeMetric = [
  {
    name: 'euclidean',
    displayName: 'Euclidean',
    description: (
      <p>
        Square root of sum of squared differences in each coordinate (L<sup>2</sup> distance).
      </p>
    ),
  },
  {
    name: 'l1',
    displayName: 'Manhattan',
    description: (
      <p>
        Sum of absolute differences in each coordinate (L<sup>1</sup> distance).
      </p>
    ),
  },
  {
    name: 'linf',
    displayName: 'Chebyshev',
    description: (
      <p>
        Maximum absolute difference in coordinates (L<sup>∞</sup> distance).
      </p>
    ),
  },
  {
    name: 'spearmanr',
    displayName: 'Spearman R',
    description:
      'Spearman rank-correlation coefficient: captures whether one feature tends to increase together with another without assuming a linear relationship. Scale invariant.',
  },
  {
    name: 'correlation',
    displayName: 'Correlation',
    description:
      'Pearson correlation coefficient: captures whether one feature linearly varies with another. Scale invariant. (Caution advised for data landscapes).',
  },
];

import React from 'react';

interface PanHeightContextType {
  panHeight: number | null;
  setPanHeight: React.Dispatch<React.SetStateAction<number>>;
}

export const PanHeightContext = React.createContext<PanHeightContextType>({
  panHeight: null,
  setPanHeight: () => {},
});

import React, { FC, memo, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Drawer } from 'app/mui/Drawer';
import { formatDate } from 'app/shared/utils/date';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { PreviewCircular } from 'app/mui/CircularProgress';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import {
  DrawerItem,
  DrawerSubHeading,
} from 'app/screens/DataLibrary/DataLibraryPreview/DataLibraryPreview';
import { Status } from 'app/shared/components/Status/Status';
import { isAnalysisProcessing } from 'app/screens/Analysis/Analysis.utils';

export type AnalysisPreviewProps = {
  open: boolean;
  onClose: () => void;
  analysisId?: number;
  projectId: number;
};

const AnalysisPreview: FC<AnalysisPreviewProps> = ({ open, onClose, analysisId }) => {
  const { navigateToDatasetPreview } = useNavigation();
  const { analysisPreview, getAnalysisPreview } = useAnalysis();

  useEffect(() => {
    if (analysisId) {
      getAnalysisPreview(analysisId);
    }
  }, [open, getAnalysisPreview, analysisId]);

  return (
    <Drawer title={analysisPreview ? analysisPreview.name : ''} open={open} onClose={onClose}>
      {analysisPreview ? (
        <>
          <DrawerItem>
            <DrawerSubHeading>Status:</DrawerSubHeading>
            <Status
              status={
                isAnalysisProcessing(analysisPreview.status) ? 'Processing' : analysisPreview.status
              }
            />
          </DrawerItem>
          {analysisPreview?.error && (
            <DrawerItem>
              <DrawerSubHeading>Failure Cause:</DrawerSubHeading>
              <Typography>{analysisPreview.error}</Typography>
            </DrawerItem>
          )}
          <DrawerItem>
            <DrawerSubHeading>Based on Dataset:</DrawerSubHeading>
            <Box
              display='flex'
              alignItems='center'
              minWidth='170px'
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              color='#0052C8'
              onClick={() =>
                navigateToDatasetPreview(
                  analysisPreview.dataset.id,
                  analysisPreview.dataset.is_example
                )
              }
            >
              <Typography noWrap variant='body1' mr={1}>
                {analysisPreview.dataset.name}
              </Typography>
            </Box>
          </DrawerItem>
          <DrawerItem>
            <DrawerSubHeading>Last Updated:</DrawerSubHeading>
            <Typography>{lastUpdated(analysisPreview.updated_at)}</Typography>
          </DrawerItem>
          <DrawerItem>
            <DrawerSubHeading>Creation Date:</DrawerSubHeading>
            <Typography>{formatDate(analysisPreview.created_at)}</Typography>
          </DrawerItem>

          <DrawerItem>
            <DrawerSubHeading>Author:</DrawerSubHeading>
            <Typography>
              {analysisPreview.creator?.firstname} {analysisPreview.creator?.lastname}
            </Typography>
          </DrawerItem>
          <DrawerItem>
            <DrawerSubHeading>Description:</DrawerSubHeading>
            <Box display='flex' alignItems='center'>
              <Typography
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {analysisPreview.description || '--'}
              </Typography>
            </Box>
          </DrawerItem>
        </>
      ) : (
        <PreviewCircular size={30} />
      )}
    </Drawer>
  );
};

export default memo(AnalysisPreview);

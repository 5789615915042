import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import { Accordion } from '@mui/material';

export const LandscapeItem = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(0, 4),
}));

export const PopoverContent = styled(Box)(({ theme }) => ({
  background: theme.palette.blocksBackground.dark,
  padding: theme.spacing(2, 1),
  color: theme.palette.primary.contrastText,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '370px',
  '& .MuiTypography-body1': {
    minWidth: '120px',
    marginLeft: theme.spacing(1),
  },
}));

export const VisualizationItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '36px',
  paddingRight: theme.spacing(1),

  '& p': {
    maxWidth: '200px',
    marginLeft: theme.spacing(3),
  },
  '&.hidden': {
    padding: theme.spacing(1, 1, 1, 0),
    '& p': {
      marginLeft: theme.spacing(0),
    },
  },
  '& .actions': {
    display: 'none',
  },
  '&:hover': {
    background: '#E4F5FF',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    '& .actions': {
      display: 'flex',
    },
    '& p': {
      maxWidth: '140px',
    },
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(2, 0, 1),
  borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
  '&:before': {
    display: 'none',
  },
}));

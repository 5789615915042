import React, { FC } from 'react';

type Props = {
  width?: number;
  height?: number;
};

export const Lasso: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 26C10.379 25.5343 9.875 24.9303 9.52786 24.2361C9.18073 23.5418 9 22.7762 9 22M7.3 18C6.45485 16.8376 5.99974 15.4372 6 14C6 9.6 10.5 6 16 6C21.5 6 26 9.6 26 14C26 18.4 21.5 22 16 22C14.2809 22.0287 12.5758 21.6877 11 21'
      stroke='#122239'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 22C9.53043 22 10.0391 21.7893 10.4142 21.4142C10.7893 21.0391 11 20.5304 11 20C11 19.4696 10.7893 18.9609 10.4142 18.5858C10.0391 18.2107 9.53043 18 9 18C8.46957 18 7.96086 18.2107 7.58579 18.5858C7.21071 18.9609 7 19.4696 7 20C7 20.5304 7.21071 21.0391 7.58579 21.4142C7.96086 21.7893 8.46957 22 9 22V22Z'
      stroke='#122239'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

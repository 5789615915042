import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  width: '100%',
  top: 0,
  zIndex: 3,
  '&.transparent': {
    background: 'none',
  },
  '&.shadow': {
    boxShadow: '0px 1px 0px rgba(18, 34, 57, 0.1)',
  },
}));

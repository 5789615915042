import styled from '@emotion/styled/macro';
import { X } from 'react-feather';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

export const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '1170px',
  },
}));

export const VisualizationDialogWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '1170px',
    maxHeight: '710px',
  },
}));

export const StyledCloseIcon = styled(X)({
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.15)',
  },
});

export const TypeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '50%',
  boxSizing: 'border-box',
  borderRadius: '2px',
  cursor: 'pointer',
  border: '1px dashed',
  borderColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.state.hoverBackground,
    '& .visible': {
      transform: 'scale(1.15)',
    },
  },
}));

export const LockIcon = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  '& svg': {
    display: 'block',
  },
}));

import styled from '@emotion/styled/macro';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButton } from '@mui/material';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  padding: '4px',
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.grey['300']}`,
  borderRadius: '8px',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  '&.small': {
    border: 'none',
    padding: 0,
    '& .MuiButtonBase-root': {
      height: '32px',
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey['300']}`,
      color: theme.palette.text.primary,
      borderRadius: '8px',
    },
  },
  '& .MuiButtonBase-root': {
    height: '32px',
    textTransform: 'inherit',
    border: 'none',
    width: '50%',
    '&:hover': {
      background: theme.palette.blocksBackground.hover,
      borderRadius: '8px',
      border: `1px solid ${theme.palette.grey['300']}`,
    },
    '&.Mui-selected': {
      background: theme.palette.blocksBackground.hover,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '8px',
    },
  },
}));

export const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.Mui-selected': {
    pointerEvents: 'none',
  },
}));

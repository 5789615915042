import React, { ReactElement, useMemo } from 'react';
import { FilterType } from 'app/screens/Analysis/Analysis.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { VisualisationType } from './Configure';
import { DefaultNameWrapper, GroupNameWrapper } from './ConfigureNameWrapper';
import {
  Row,
  SourceDataLandscapesOptions,
} from '../SourceDataDialog/DataLandscapeSourceDataDialog';
import {
  getDefaultLandscapeDataPoints,
  getSelectedLandscapeDataPoints,
} from '../SourceDataDialog/SourceData.utils';

type ConfigureHookParams = {
  dataRow?: Row;
  featureRow?: Row;
  dataRowDataLandscape?: Row;
  dataRowFeatureLandscape?: Row;
  customDataRows?: number[] | undefined;
  type?: string;
};

type ConfigureHook = {
  rowsGroupId: number | null | undefined;
  featuresGroupId: number | null | undefined;
  getDataLandscapeSourceDataDataPointOption: () => void;
  getFeatureLandscapeSourceDataDataPointOption: () => void;
  removeItems: (currentFilters: FilterType[], row: FilterType) => FilterType[];
};

export const useConfigure = ({
  dataRow,
  featureRow,
  type,
  dataRowDataLandscape,
  dataRowFeatureLandscape,
  customDataRows,
}: ConfigureHookParams): ConfigureHook => {
  const { selectedDataRows, groups, dataLandscapeVisualization, featureLandscapeVisualization } =
    useAnalysis();

  const dataLandscapeRowGroupId = dataLandscapeVisualization?.rows_group_id;
  const dataLandscapeColsGroupId = dataLandscapeVisualization?.features_group_id;
  const featureLandscapeRowGroupId = featureLandscapeVisualization?.rows_group_id;
  const featureLandscapeColsGroupId = featureLandscapeVisualization?.features_group_id;

  const isSourceDataGroupOptionDataPoints =
    !(
      dataRow &&
      dataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA &&
      dataRow.id === 0
    ) &&
    !(dataRow && dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA) &&
    !(dataRow && dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM);

  const isSourceDataGroupOptionFeatures =
    !(
      featureRow &&
      featureRow.feature_name === SourceDataLandscapesOptions.ALL_FEATURES &&
      featureRow.id === 0
    ) &&
    !(featureRow && featureRow.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES) &&
    !(featureRow && featureRow.feature_name === SourceDataLandscapesOptions.CUSTOM);

  const rowsGroupId = useMemo(() => {
    if (isSourceDataGroupOptionDataPoints && dataRow) {
      return dataRow.id;
    }
    if (!isSourceDataGroupOptionDataPoints && dataRow) {
      return null;
    }
    if (type === VisualisationType.DATA_LANDSCAPE && dataLandscapeVisualization) {
      return dataLandscapeRowGroupId;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE && featureLandscapeVisualization) {
      return featureLandscapeRowGroupId;
    }
    return null;
  }, [dataRow]);

  const featuresGroupId = useMemo(() => {
    if (isSourceDataGroupOptionFeatures && featureRow) {
      return featureRow.id;
    }
    if (!isSourceDataGroupOptionFeatures && featureRow) {
      return null;
    }
    if (type === VisualisationType.DATA_LANDSCAPE && dataLandscapeVisualization) {
      return dataLandscapeColsGroupId;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE && featureLandscapeVisualization) {
      return featureLandscapeColsGroupId;
    }
    return null;
  }, [featureRow]);

  const getDataLandscapeSourceDataDataPointOption = (): ReactElement | string => {
    if (dataRowDataLandscape) {
      return DefaultNameWrapper(
        getSelectedLandscapeDataPoints(dataRowDataLandscape, selectedDataRows, customDataRows)
      );
    }
    if (dataLandscapeRowGroupId) {
      return GroupNameWrapper(
        getDefaultLandscapeDataPoints(
          dataLandscapeRowGroupId,
          groups,
          dataLandscapeVisualization
        ) as string
      );
    }
    return `${getDefaultLandscapeDataPoints(
      dataLandscapeRowGroupId,
      groups,
      dataLandscapeVisualization
    )} selected`;
  };

  const getFeatureLandscapeSourceDataDataPointOption = (): ReactElement | string => {
    if (dataRowFeatureLandscape) {
      return DefaultNameWrapper(
        getSelectedLandscapeDataPoints(dataRowFeatureLandscape, selectedDataRows, customDataRows)
      );
    }
    if (featureLandscapeRowGroupId) {
      return GroupNameWrapper(
        getDefaultLandscapeDataPoints(
          featureLandscapeRowGroupId,
          groups,
          featureLandscapeVisualization
        ) as string
      );
    }
    return `${getDefaultLandscapeDataPoints(
      featureLandscapeRowGroupId,
      groups,
      featureLandscapeVisualization
    )} selected`;
  };

  const removeItems = (currentFilters: FilterType[], row: FilterType): FilterType[] => {
    currentFilters.forEach((filter, index) => {
      if (filter === row) {
        currentFilters.splice(index, 1);
      }
    });

    return currentFilters;
  };

  return {
    rowsGroupId,
    featuresGroupId,
    getDataLandscapeSourceDataDataPointOption,
    getFeatureLandscapeSourceDataDataPointOption,
    removeItems,
  };
};

import React, { FC, memo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import initialIcon from 'app/assets/images/auth/initial.svg';
import successIcon from 'app/assets/images/auth/success.svg';
import errorIcon from 'app/assets/images/auth/error.svg';

const iconStyles = {
  width: 17,
  height: 17,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const PASSWORD_REGEX: { regex: RegExp; text: string }[] = [
  {
    regex: /[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}/,
    text: 'at least 8 characters',
  },
  { regex: /(?=.*[a-z])/, text: 'lower case letter' },
  { regex: /(?=.*[A-Z])/, text: 'upper case letter' },
  { regex: /(?=.*[0-9])/, text: 'number' },
  { regex: /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/, text: 'special character' },
];

type PasswordRegexType = {
  password: string;
  isValidated: boolean;
};

const PasswordRegex: FC<PasswordRegexType> = ({ password, isValidated }) => (
  <>
    {PASSWORD_REGEX.map((condition) => (
      <Box display='flex' alignItems='center' key={condition.text}>
        {condition.regex.test(password) ? (
          <Box sx={iconStyles}>
            <img src={successIcon} alt='Success' />
          </Box>
        ) : (
          <Box sx={iconStyles}>
            {isValidated ? (
              <img src={errorIcon} alt='Error' />
            ) : (
              <img src={initialIcon} alt='Unchecked' />
            )}
          </Box>
        )}
        <Typography color='common.black' ml={1}>
          {condition.text}
        </Typography>
      </Box>
    ))}
  </>
);

export default memo(PasswordRegex);

import React, { FC, useMemo } from 'react';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { AnalysisConfig, ChartDataType } from 'app/screens/Analysis/Analysis.types';
import { ConfigureLandscape } from 'app/screens/Analysis/AnalysisSidebar/Configure/ConfigureForms/ConfigureLandscape';
import { ConfigureChart } from 'app/screens/Analysis/AnalysisSidebar/Configure/ConfigureForms/ConfigureChart';

type ConfigureProps = {
  analysisId: number;
  handleCloseConfiguration: () => void;
  selectedVisualization: AnalysisConfig | ChartDataType;
};

export enum VisualisationType {
  DATA_LANDSCAPE = 'data_landscape',
  FEATURE_LANDSCAPE = 'feature_landscape',
  SCATTER_PLOT = 'scatter_plot',
  HEATMAP = 'heatmap',
  VIOLIN_PLOT = 'violin_plot',
  HISTOGRAM = 'histogram',
}

export const chartNameMapper = {
  [VisualisationType.DATA_LANDSCAPE]: 'Data Landscape',
  [VisualisationType.FEATURE_LANDSCAPE]: 'Feature Landscape',
  [VisualisationType.SCATTER_PLOT]: 'Scatter Plot',
  [VisualisationType.HEATMAP]: 'Heatmap',
  [VisualisationType.VIOLIN_PLOT]: 'Violin Plot',
  [VisualisationType.HISTOGRAM]: 'Histogram',
};

export const Configure: FC<ConfigureProps> = ({
  analysisId,
  handleCloseConfiguration,
  selectedVisualization,
}) => {
  const isLandscape = [
    VisualisationType.DATA_LANDSCAPE,
    VisualisationType.FEATURE_LANDSCAPE,
  ].includes(selectedVisualization.type);
  const isChart = [
    VisualisationType.SCATTER_PLOT,
    VisualisationType.HEATMAP,
    VisualisationType.HISTOGRAM,
    VisualisationType.VIOLIN_PLOT,
  ].includes(selectedVisualization.type);

  const { dataLandscapeVisualizations, featureLandscapeVisualizations } = useAnalysis();

  const landscape = useMemo(
    () =>
      [...dataLandscapeVisualizations, ...featureLandscapeVisualizations].find(
        (item) => item.type === selectedVisualization.type && item.id === selectedVisualization.id
      ) || null,
    [
      dataLandscapeVisualizations,
      featureLandscapeVisualizations,
      selectedVisualization.type,
      selectedVisualization.id,
    ]
  );

  return (
    <>
      {isLandscape && landscape && (
        <ConfigureLandscape
          key={landscape.id}
          analysisId={analysisId}
          handleCloseConfiguration={handleCloseConfiguration}
          selectedVisualization={landscape as AnalysisConfig}
        />
      )}
      {isChart && (
        <ConfigureChart
          key={selectedVisualization.id}
          analysisId={analysisId}
          handleCloseConfiguration={handleCloseConfiguration}
          selectedVisualization={selectedVisualization as ChartDataType}
        />
      )}
    </>
  );
};

export class WebWorkerHandler<T, R> {
  resolver: ((value: R) => void) | undefined;

  worker: Worker | undefined;

  constructor(getWorker: () => Worker) {
    this.worker = getWorker();
    this.worker.onmessage = ({ data }: { data: R }) => {
      this.resolver?.(data);
    };
  }

  call(data: T, buffer: Transferable[]): Promise<R> {
    this.worker?.postMessage(data, buffer);
    return new Promise((resolve) => {
      this.resolver = resolve;
    });
  }

  kill(): void {
    this.worker?.terminate();
    this.worker = undefined;
  }
}

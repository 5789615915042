import React, { FC } from 'react';
import { X } from 'react-feather';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Transition } from 'app/mui/Transition';
import { IconButton } from 'app/mui/IconButton';
import { DialogWrapper } from 'app/shared/styles/createForm';
import { Loader } from 'app/shared/components/Loader/Loader';

import { DescriptiveStatistics } from './DescriptiveStatistics';
import { StatisticsError } from './StatisticsError';
import { DESCRIPTIVE_STATISTICS_ERROR } from './Statistics';

type DescriptiveStatisticsModalProps = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  error?: string;
};

export const DescriptiveStatisticsModal: FC<DescriptiveStatisticsModalProps> = ({
  open,
  isLoading,
  onClose,
  error,
}) => {
  const showDescriptiveStatistics = !isLoading && !error;

  return (
    <DialogWrapper open={open} onClose={onClose} TransitionComponent={Transition}>
      <Box width={624} height={340} m={6}>
        <Box display='flex' alignItems='center' justifyContent='space-between' mb={3}>
          <Typography component='h5' variant='h5'>
            Descriptive statistics
          </Typography>
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </Box>
        <Box pb={3}>
          {isLoading && <Loader />}
          {error && <StatisticsError error={DESCRIPTIVE_STATISTICS_ERROR} />}
          {showDescriptiveStatistics && <DescriptiveStatistics className='modal' />}
        </Box>
      </Box>
    </DialogWrapper>
  );
};

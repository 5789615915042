import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Check } from 'react-feather';
import styled from '@emotion/styled/macro';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { StyledTextField } from 'app/mui/TextField';
import { Autocomplete } from 'app/mui/Autocomplete';
import { CroppedText } from 'app/shared/styles/common';
import {
  Row,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { PartitionRow } from '../DataPartitionsDialog/DataPartitionsDialog';

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': { padding: '3px' },
  '& .MuiChip-root': {
    background: theme.palette.blocksBackground.hover,
  },
}));

type SourceDataSearchSelectProps = {
  rows: VisualizationRow[] | PartitionRow[];
  rowLabel: string;
  onRowChange: (value: any) => void;
  multiple?: boolean;
  limit?: number;
  defaultValue?: any;
  defaultValues?: VisualizationRow[];
};

export const SourceDataSearchSelect: FC<SourceDataSearchSelectProps> = ({
  rows,
  rowLabel,
  onRowChange,
  multiple,
  limit,
  defaultValue,
  defaultValues,
}) => {
  const theme = useTheme();
  const [valueSelected, setValueSelected] = useState<Row>(defaultValue || undefined);
  const [valuesSelected, setValuesSelected] = useState<any[]>(defaultValues || []);

  useEffect(() => {
    if (multiple && defaultValues) {
      onRowChange(valuesSelected);
    } else if (defaultValue) {
      onRowChange(valueSelected);
    }
  }, []);

  useEffect(() => {
    if (multiple && limit) {
      onRowChange([]);
    }
  }, [multiple]);

  useEffect(() => {
    if (multiple) {
      setValuesSelected(defaultValues || []);
      onRowChange(defaultValues || []);
    } else {
      setValueSelected(defaultValue || undefined);
      onRowChange(defaultValue || undefined);
    }
  }, [rows, multiple]);

  const shiftSelect = (event: any, value: VisualizationRow[] | any): void => {
    if (event.shiftKey) {
      const last2 = value.slice(-2);
      if (last2.length > 1 && last2[1].id > last2[0].id) {
        const shiftRows = rows.filter(
          (row) => row?.id > last2[0].id && row?.id < last2[1].id && !value.includes(row)
        );
        const selectedRows = value.concat(shiftRows).slice(0, limit);

        setValuesSelected(selectedRows);
        onRowChange(selectedRows);
      }
      if (last2.length > 1 && last2[1].id < last2[0].id) {
        const shiftRows = rows.filter(
          (row) => row?.id < last2[0].id && row?.id > last2[1].id && !value.includes(row)
        );
        const selectedRows = value.concat(shiftRows).slice(0, limit);

        setValuesSelected(selectedRows);
        onRowChange(selectedRows);
      }
    }
  };

  const handleOnChange = (event: SyntheticEvent<Element, Event>, value: any): void => {
    if (multiple && limit) {
      if (value.length <= limit) {
        setValuesSelected(value);
        onRowChange(value);
        shiftSelect(event, value);
      }
    } else {
      setValueSelected(value);
      onRowChange(value);
    }
  };

  return (
    <Wrapper>
      <Autocomplete
        useVirtual
        multiple={multiple}
        id='source-data-select'
        options={rows}
        disableCloseOnSelect={multiple}
        value={multiple ? valuesSelected : valueSelected}
        onChange={(event, value) => handleOnChange(event, value)}
        getOptionLabel={(option) => option.feature_name}
        renderOption={(props, option, { selected }, style = {}) => (
          <li
            {...props}
            style={{ height: '40px', display: 'flex', justifyContent: 'space-between', ...style }}
          >
            <CroppedText>{option.feature_name}</CroppedText>
            <Checkbox
              icon={<Box />}
              checkedIcon={<Check size='24' color={theme.palette.success.main} />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          </li>
        )}
        renderInput={(params) => (
          <StyledTextField {...params} placeholder={rowLabel} label={rowLabel} />
        )}
      />
    </Wrapper>
  );
};

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { configureAmplify } from 'app/shared/utils/amplify';
import { injectStore } from 'app/shared/utils/api';
import { App } from './App';
import { store } from './app/store/store';

configureAmplify();

const container = document.getElementById('root') as Element;
const root = createRoot(container);

const persistor = persistStore(store);

injectStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

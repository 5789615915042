import React, { FC } from 'react';
import { Briefcase, MoreVertical } from 'react-feather';
import { Box } from '@mui/material';
import { IconButton } from 'app/mui/IconButton';
import { ProjectActions } from 'app/screens/Projects/ProjectActions/ProjectActions';
import { CroppedText } from 'app/shared/styles/common';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { SVGBox, Wrapper } from './ProjectCardPreview.styles';
import { DotsMenu } from '../DotsMenu/DotsMenu';

type ProjectCardPreviewProps = {
  id: number;
  name: string;
  showMenuActions?: boolean;
};

export const ProjectCardPreview: FC<ProjectCardPreviewProps> = ({ name, id, showMenuActions }) => {
  const { navigateToProjectOverview } = useNavigation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProject = Boolean(anchorEl);
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Wrapper key={id} onClick={() => navigateToProjectOverview(id)}>
      <SVGBox>
        <Briefcase size='20' />
        <CroppedText maxWidth='170px'>{name}</CroppedText>
      </SVGBox>
      {showMenuActions && (
        <Box>
          <IconButton onClick={(event) => handleClickMenu(event)}>
            <MoreVertical />
          </IconButton>
          <DotsMenu anchorEl={anchorEl} open={openProject} onClose={handleClose}>
            <ProjectActions />
          </DotsMenu>
        </Box>
      )}
    </Wrapper>
  );
};

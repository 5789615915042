import React, { FC, ReactNode } from 'react';
import { ArrowLeft } from 'react-feather';
import { Box, Typography } from '@mui/material';
import { CircularProgress } from 'app/mui/CircularProgress';
import { Status } from 'app/shared/components/Status/Status';
import { Tooltip } from 'app/mui/Tooltip';
import { useNavigation } from 'app/shared/hooks/useNavigation';

interface AnalysisNoResultsLayoutProps {
  projectId: number;
  heading: string;
  children: ReactNode;
  status: string;
}

export const AnalysisNoResultsLayout: FC<AnalysisNoResultsLayoutProps> = ({
  heading,
  children,
  status,
  projectId,
}) => {
  const { navigateToProjectOverview } = useNavigation();

  return (
    <>
      <Box display='flex' alignItems='center' mb={2} zIndex={1}>
        <Box display='flex' alignItems='center'>
          <Box
            mr={3}
            display='flex'
            onClick={() => navigateToProjectOverview(projectId)}
            sx={{ cursor: 'pointer' }}
          >
            <ArrowLeft size='24' />
          </Box>
          <Typography component='h4' variant='h4'>
            {heading}
          </Typography>
          <Box ml={2}>
            <Status status={status} />
          </Box>
          <Tooltip title='Processing' placement='bottom-start'>
            <Box px={1} ml={2}>
              <CircularProgress />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Box zIndex={1}>{children}</Box>
    </>
  );
};

import React, { FC } from 'react';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { VisualizationItem } from 'app/screens/Analysis/Analysis.styles';
import { visualizations, VisualizationType } from './VisualizationType.constants';

type VisualizationTypesProps = {
  type: string;
  onTypeChange: (value: string) => void;
};

export const VisualizationTypes: FC<VisualizationTypesProps> = ({ type, onTypeChange }) => (
  <Box mt={3}>
    <Typography variant='subtitle1'>Visualization type</Typography>
    <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='8px' mt={2}>
      {visualizations.map((visualization: VisualizationType) => (
        <VisualizationItem
          key={visualization.type}
          onClick={() => onTypeChange(visualization.type)}
          className={classNames({ selected: type === visualization.type })}
        >
          <Box component='img' src={visualization.img} alt='Scatter Plot' />
          <Typography variant='caption'>{visualization.name}</Typography>
        </VisualizationItem>
      ))}
    </Box>
  </Box>
);

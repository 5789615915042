import React, { FC, useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, VisualizationDialogWrapper } from 'app/shared/styles/createForm';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { ChartConfigType } from 'app/screens/Analysis/Analysis.types';
import { useParams } from 'app/navigation';
import { CustomSubsetSelect } from 'app/shared/components/CustomSubsetSelect/CustomSubsetSelect';
import {
  SourceDataLandscapesOptions,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { DEFAULT_TIMEOUT } from 'app/shared/utils/timeout';
import { useChartSourceData } from './SourceData.hooks';
import { mapperMatrixConfigRows } from './SourceData.utils';
import { SourceDataSearchSelect } from './SourceDataSearchSelect';
import { VisualisationType } from '../Configure/Configure';

type ViolinPlotSourceDataDialogProps = {
  openDialog: boolean;
  handleSave: (data: ChartConfigType) => void;
  handleClose: () => void;
  type: VisualisationType;
  visualization: ChartConfigType;
};

export const ViolinPlotSourceDataDialog: FC<ViolinPlotSourceDataDialogProps> = ({
  openDialog,
  handleClose,
  type,
  handleSave,
  visualization,
}) => {
  const { analysisId } = useParams();

  const { features, dataPointsCount, groups, selectedDataRows, getGroup } = useAnalysis();

  const {
    dataPointSubset,
    dataPoints,
    features: selectedFeatures,
    dataRow,
    customDataRows,
    defaultDataPoints,
    defaultCustomDataPoints,
    setFeatures,
    onDataRowChange,
    onCustomDataRowsChange,
    isSourceDataGroupOption,
  } = useChartSourceData({
    visualization,
    groups,
    dataPointsCount,
  });

  const defaultFeatures = features?.filter(
    (row) => row && visualization?.feature_ids.includes(row.id)
  );

  useEffect(() => {
    setTimeout(() => {
      setFeatures(defaultFeatures);
    }, DEFAULT_TIMEOUT);
  }, [features]);

  const visualizationFeatures = (): number[] | [] => {
    if (selectedFeatures) {
      return selectedFeatures.map((row) => row.id);
    }
    return [];
  };

  const handleSaveSourceData = async (): Promise<void> => {
    if (dataPointsCount) {
      const data_points = await mapperMatrixConfigRows(
        dataRow,
        customDataRows,
        Number(analysisId),
        selectedDataRows,
        getGroup,
        dataPointsCount
      );
      if (dataRow) {
        handleSave({
          data_points: data_points as any[],
          feature_ids: visualizationFeatures(),
          chart_type: type as VisualisationType,
          data_filters: [] as any, // TODO: Should be filter data
          rows_group_id: isSourceDataGroupOption ? dataRow.id : null,
        });
      }
    }

    handleClose();
  };

  const numericFeatures = useMemo(() => features.filter((f) => f.type === 'numeric'), [features]);

  const isSaveDisabled =
    (selectedFeatures && (selectedFeatures.length > 5 || selectedFeatures.length < 1)) ||
    (dataRow?.feature_name === SourceDataLandscapesOptions.SELECTED_DATA &&
      selectedDataRows.length === 0) ||
    (dataRow?.feature_name === SourceDataLandscapesOptions.CUSTOM && customDataRows.length === 0);

  return (
    <VisualizationDialogWrapper
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box width='841px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              Modify Violin Plot Source Data
            </Typography>
            <StyledCloseIcon onClick={handleClose} size={24} />
          </Box>
          {dataPointSubset && dataPoints && numericFeatures && (
            <>
              <Box mt={3}>
                <SourceDataSearchSelect
                  multiple
                  limit={5}
                  rows={numericFeatures}
                  rowLabel='Feature (from 1 to 5 should be selected)'
                  onRowChange={(value) => setFeatures(value)}
                  defaultValues={defaultFeatures}
                />
              </Box>
              <Box mt={3}>
                <CustomSubsetSelect
                  rows={dataPoints}
                  onRowsChange={(value: VisualizationRow[]) => onCustomDataRowsChange(value)}
                  label='Data points'
                  defaultValues={defaultCustomDataPoints}
                  showSelect={dataRow?.feature_name === SourceDataLandscapesOptions.CUSTOM}
                  maxHeight={210}
                >
                  <SourceDataSearchSelect
                    rows={dataPointSubset}
                    rowLabel='Data points subset'
                    onRowChange={(value) => onDataRowChange(value)}
                    defaultValue={defaultDataPoints}
                  />
                </CustomSubsetSelect>
              </Box>
            </>
          )}

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSaveSourceData} variant='contained' disabled={isSaveDisabled}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </VisualizationDialogWrapper>
  );
};

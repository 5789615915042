import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IconButton } from 'app/mui/IconButton';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, DialogWrapper } from 'app/shared/styles/createForm';
import { StyledTextField } from 'app/mui/TextField';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { useParams } from 'app/navigation';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from 'app/screens/Analysis/CreateGroupDialog/CreateGroupDialog.styles';
import InputAdornment from '@mui/material/InputAdornment';
import { VisualisationType } from '../Configure/Configure';
import { SourceDataSearchSelect } from '../SourceDataDialog/SourceDataSearchSelect';
import { Row, VisualizationRow } from '../SourceDataDialog/DataLandscapeSourceDataDialog';

export type PartitionRow = {
  feature_name: string;
  id: number | string;
};

type DataPartitionsDialogProps = {
  openDialog: boolean;
  handleClose: () => void;
  type: string;
  onDataPartitionFeatureChange: (value: Row) => void;
  onDataPartitionDataChange: (value: Row) => void;
  onDataPartitionGlobalChange: (value: Row) => void;
  onDataPartitionMetadataChange: (value: PartitionRow) => void;
  handleChangeBins: (event: ChangeEvent<HTMLInputElement>) => void;
  binsDecrement: () => void;
  binsIncrement: () => void;
  handleChangeEqualize: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeDistance: (event: ChangeEvent<HTMLInputElement>) => void;
  clearFields: () => void;
  distanceDecrement: () => void;
  distanceIncrement: () => void;
  bins: string;
  distance: string;
  checkedEqualize: boolean;
  addFilter: (partitionType: string) => void;
  dataPartitionFeature: Row;
  dataPartitionData: Row;
  dataPartitionGlobal: Row;
  dataPartitionMetadata: PartitionRow | undefined;
};

export enum DataPartitionEnum {
  FEATURE = 'Feature',
  DATA = 'Data',
  METADATA = 'Metadata',
  GLOBAL = 'Global',
}

const parameterOptions = [
  {
    feature_name: 'L1 eccentricity',
    id: 0,
  },
  {
    feature_name: 'L-inf eccentricity',
    id: 1,
  },
  {
    feature_name: 'Gaussian density',
    id: 2,
  },
];

export const metadataRows = [
  {
    feature_name: 'Сount',
    id: 'count',
  },
  {
    feature_name: 'Mean',
    id: 'mean',
  },
  {
    feature_name: 'Std',
    id: 'std',
  },
  {
    feature_name: 'Min',
    id: 'min',
  },
  {
    feature_name: '25%',
    id: 'percent_25',
  },
  {
    feature_name: '50%',
    id: 'percent_50',
  },
  {
    feature_name: '75%',
    id: 'percent_75',
  },
  {
    feature_name: 'Max',
    id: 'max',
  },
  {
    feature_name: 'Non-0 count',
    id: 'non_nan',
  },
  {
    feature_name: 'NaN count',
    id: 'nan',
  },
];

export const DataPartitionsDialog: FC<DataPartitionsDialogProps> = ({
  openDialog,
  handleClose,
  type,
  onDataPartitionFeatureChange,
  onDataPartitionDataChange,
  onDataPartitionGlobalChange,
  onDataPartitionMetadataChange,
  handleChangeBins,
  binsDecrement,
  binsIncrement,
  handleChangeEqualize,
  handleChangeDistance,
  clearFields,
  distanceDecrement,
  distanceIncrement,
  bins,
  distance,
  checkedEqualize,
  addFilter,
  dataPartitionFeature,
  dataPartitionData,
  dataPartitionGlobal,
  dataPartitionMetadata,
}) => {
  const { analysisId } = useParams();
  const { groups } = useAnalysis();

  const isFeatureLandscapeType = type === VisualisationType.FEATURE_LANDSCAPE;

  const [groupType, setGroupType] = useState<string>(
    isFeatureLandscapeType ? DataPartitionEnum.DATA : DataPartitionEnum.FEATURE
  );
  const [metadata] = useState<PartitionRow[]>(metadataRows);
  const [dataGroups, setDataGroups] = useState<VisualizationRow[] | undefined>(undefined);
  const [parameter] = useState<VisualizationRow[]>(parameterOptions);

  const { getFeaturesList, features } = useAnalysis();

  useEffect(() => {
    getFeaturesList(Number(analysisId));

    const featureGroupList = groups
      .filter((group) => group.type === GroupCreationEnum.ROWS)
      .map((group) => ({
        feature_name: group.name,
        id: group.id,
      }));
    setDataGroups(featureGroupList);
  }, []);

  const handleChangeGroupType = (event: React.MouseEvent<HTMLElement>, newType: string): void => {
    setGroupType(newType);
  };

  const handleSave = (): void => {
    handleClose();
    addFilter(groupType);
    clearFields();
  };

  const isAddPartitionDisabled =
    (groupType === DataPartitionEnum.DATA && !dataPartitionData) ||
    (groupType === DataPartitionEnum.FEATURE && !dataPartitionFeature) ||
    (groupType === DataPartitionEnum.METADATA && !dataPartitionMetadata) ||
    (groupType === DataPartitionEnum.GLOBAL && !dataPartitionGlobal);

  return (
    <DialogWrapper open={openDialog} onClose={handleClose} TransitionComponent={Transition}>
      <Box width='841px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              {isFeatureLandscapeType ? 'Add Feature Partition' : 'Add Data Partition'}
            </Typography>
            <StyledCloseIcon onClick={handleClose} size={24} />
          </Box>

          <StyledToggleButtonGroup
            value={groupType}
            exclusive
            onChange={handleChangeGroupType}
            aria-label='text alignment'
          >
            {type === VisualisationType.DATA_LANDSCAPE && (
              <StyledToggleButton
                value={DataPartitionEnum.FEATURE}
                aria-label={DataPartitionEnum.FEATURE}
              >
                {DataPartitionEnum.FEATURE}
              </StyledToggleButton>
            )}
            {type === VisualisationType.FEATURE_LANDSCAPE && (
              <StyledToggleButton
                value={DataPartitionEnum.DATA}
                aria-label={DataPartitionEnum.DATA}
              >
                {DataPartitionEnum.DATA}
              </StyledToggleButton>
            )}
            {type === VisualisationType.FEATURE_LANDSCAPE && (
              <StyledToggleButton
                value={DataPartitionEnum.METADATA}
                aria-label={DataPartitionEnum.METADATA}
              >
                {DataPartitionEnum.METADATA}
              </StyledToggleButton>
            )}
            <StyledToggleButton
              value={DataPartitionEnum.GLOBAL}
              aria-label={DataPartitionEnum.GLOBAL}
            >
              {DataPartitionEnum.GLOBAL}
            </StyledToggleButton>
          </StyledToggleButtonGroup>

          {features && (
            <Box mt={3}>
              {type === VisualisationType.DATA_LANDSCAPE &&
                groupType === DataPartitionEnum.FEATURE && (
                  <SourceDataSearchSelect
                    rows={features}
                    rowLabel='Feature (only 1 should be selected)'
                    onRowChange={onDataPartitionFeatureChange}
                    defaultValue={dataPartitionFeature}
                  />
                )}
              {type === VisualisationType.FEATURE_LANDSCAPE && (
                <>
                  {groupType === DataPartitionEnum.DATA && dataGroups && (
                    <SourceDataSearchSelect
                      rows={dataGroups}
                      rowLabel='Data group'
                      onRowChange={onDataPartitionDataChange}
                      defaultValue={dataPartitionData}
                    />
                  )}
                  {groupType === DataPartitionEnum.METADATA && (
                    <SourceDataSearchSelect
                      rows={metadata}
                      rowLabel='Feature (only 1 should be selected)'
                      onRowChange={onDataPartitionMetadataChange}
                      defaultValue={dataPartitionMetadata}
                    />
                  )}
                </>
              )}
              {groupType === DataPartitionEnum.GLOBAL && (
                <SourceDataSearchSelect
                  rows={parameter}
                  rowLabel='Parameter'
                  onRowChange={onDataPartitionGlobalChange}
                  defaultValue={dataPartitionGlobal}
                />
              )}
            </Box>
          )}

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <StyledTextField
              id='outlined-end-adornment'
              type='number'
              className='largeNumber'
              value={bins}
              label='Number of bins'
              onChange={handleChangeBins}
              size='small'
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      className='configArrows'
                      disabled={Number(bins) <= 1}
                      onClick={binsDecrement}
                    >
                      <ChevronLeft size={16} className='arrow' />
                    </IconButton>
                    <IconButton className='configArrows' onClick={binsIncrement}>
                      <ChevronRight size={16} className='arrow' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <StyledTextField
              id='outlined-end-adornment'
              type='number'
              className='largeNumber'
              value={distance}
              label='Max link distance'
              onChange={handleChangeDistance}
              size='small'
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      className='configArrows'
                      disabled={Number(distance) <= 0}
                      onClick={distanceDecrement}
                    >
                      <ChevronLeft size={16} className='arrow' />
                    </IconButton>
                    <IconButton className='configArrows' onClick={distanceIncrement}>
                      <ChevronRight size={16} className='arrow' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={<Checkbox value={checkedEqualize} onChange={handleChangeEqualize} />}
              label='Equalize histogram'
            />
          </Box>

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant='contained' disabled={isAddPartitionDisabled}>
              {isFeatureLandscapeType ? 'Add Feature Partition' : 'Add Data Partition'}
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
};

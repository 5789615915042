export enum GroupCreationEnum {
  FEATURE_GROUP = 'Feature Group',
  DATA_GROUP = 'Data Points Group',
  DATA_ROWS = 'Data Explorer rows',
  FILTERED_DATA_ROWS = 'Data Explorer filter results',
  FEATURE_ROWS = 'Features',
  FILTERED_FEATURE_ROWS = 'Feature Explorer filter results',
  ROWS = 'rows',
  FEATURES = 'features',
  CURRENTLY_SELECTED = 'Currently selected',
  FILTER_RESULTS = 'Filter results',
  CUSTOM = 'Custom',
}

export enum GroupTypeEnum {
  FEATURES = 'Features',
  DATA_POINTS = 'Data Points',
}

import { Amplify } from 'aws-amplify';

export const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  });
};

// CognitoUserSession: node_modules/amazon-cognito-identity-js/index.d.ts
export const getJWTTokenFromUserSession = (userSession: any): string =>
  userSession.getAccessToken().getJwtToken();

import React, { FC } from 'react';
import DataGrid from 'react-data-grid';
import classNames from 'classnames';

import { useStatistics } from './Statistics.hooks';
import './Statistics.scss';

type DescriptiveStatistics = {
  className?: string;
  isSmallTable?: boolean;
};

export const DescriptiveStatistics: FC<DescriptiveStatistics> = ({
  className,
  isSmallTable = false,
}) => {
  const { descriptiveStatisticsColumns, descriptiveStatisticsRows } = useStatistics({
    isSmallTable,
  });

  return (
    <DataGrid
      columns={descriptiveStatisticsColumns}
      rows={descriptiveStatisticsRows}
      className={classNames('rdg-light statistics-table', className)}
    />
  );
};

import React, { FC, useState, useMemo, useCallback } from 'react';
import DataGrid, { SortColumn } from 'react-data-grid';
import { Check, Info, X } from 'react-feather';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Transition } from 'app/mui/Transition';
import { IconButton } from 'app/mui/IconButton';
import { FormControl } from 'app/mui/FormControl';
import { Tooltip } from 'app/mui/Tooltip';
import { DialogWrapper } from 'app/shared/styles/createForm';
import { Loader } from 'app/shared/components/Loader/Loader';
import { MenuItemContent } from 'app/shared/components/SortSelect/SortSelect.styles';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { SortIcon } from 'app/screens/Analysis/Explorer/components/SortIcon';
import { shortName } from 'app/shared/utils/visualization';

import {
  SIDEDNESS_OPTIONS,
  STATISTICAL_TEST_OPTIONS,
  COMPARATIVE_STATISTICS_ERROR,
} from './Statistics';
import type { ComparativeStatisticsGroupOption } from './Statistics';
import { useStatistics, ProcessedRow, SORT_COLUMN_KEY } from './Statistics.hooks';
import { StatisticsError } from './StatisticsError';

type ComparativeStatisticsModalProps = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  groupAOptions: ComparativeStatisticsGroupOption[];
  groupBOptions: ComparativeStatisticsGroupOption[];
  groupA: string;
  groupB: string;
  statisticalTest: string;
  handleGroupAChange: (e: SelectChangeEvent<string>) => void;
  handleGroupBChange: (e: SelectChangeEvent<string>) => void;
  handleStatisticalTestChange: (e: SelectChangeEvent<string>) => void;
  error?: string;
};

export const ComparativeStatisticsModal: FC<ComparativeStatisticsModalProps> = ({
  open,
  onClose,
  isLoading,
  groupAOptions,
  groupBOptions,
  groupA,
  groupB,
  statisticalTest,
  handleGroupAChange,
  handleGroupBChange,
  handleStatisticalTestChange,
  error,
}) => {
  const theme = useTheme();
  const { comparativeStatistics } = useAnalysis();
  const { comparativeStatisticsColumns, comparativeStatisticsRows } = useStatistics();

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const onSortColumnsChange = useCallback((_sortColumns: SortColumn[]) => {
    setSortColumns(_sortColumns.slice(-1));
  }, []);

  const sortedRows = useMemo((): readonly ProcessedRow[] => {
    if (sortColumns.length === 0) return comparativeStatisticsRows;

    const { direction } = sortColumns[0];

    const sortedComparativeStatisticsRows = [...comparativeStatisticsRows];

    sortedComparativeStatisticsRows.sort(
      (a, b) => Number(a[SORT_COLUMN_KEY]) - Number(b[SORT_COLUMN_KEY])
    );

    return direction === 'DESC'
      ? sortedComparativeStatisticsRows.reverse()
      : sortedComparativeStatisticsRows;
  }, [comparativeStatisticsRows, sortColumns]);

  const showComparativeStatistics = !isLoading && !error;

  return (
    <DialogWrapper open={open} onClose={onClose} TransitionComponent={Transition}>
      <Box width={624} height={550} m={6}>
        <Box display='flex' alignItems='center' justifyContent='space-between' mb={3}>
          <Typography component='h5' variant='h5'>
            Comparative statistics
          </Typography>
          <IconButton onClick={onClose}>
            <X />
          </IconButton>
        </Box>
        <Box display='flex' mb={2}>
          <FormControl className='initial' fullWidth>
            <InputLabel>Group A</InputLabel>
            <Select
              value={groupA}
              onChange={handleGroupAChange}
              label='Group A'
              sx={{
                '& .MuiSelect-select svg': {
                  display: 'none',
                },
              }}
            >
              {groupAOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <MenuItemContent>
                    {shortName(option.name, 280)}
                    <Box display='flex'>
                      {groupA === option.id.toString() && (
                        <Check size='24' color={theme.palette.success.main} />
                      )}
                    </Box>
                  </MenuItemContent>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className='initial'
            fullWidth
            sx={{
              marginLeft: 1,
            }}
          >
            <InputLabel>Group B</InputLabel>
            <Select
              value={groupB}
              onChange={handleGroupBChange}
              label='Group A'
              sx={{
                '& .MuiSelect-select svg': {
                  display: 'none',
                },
              }}
            >
              {groupBOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <MenuItemContent>
                    {shortName(option.name, 280)}
                    <Box display='flex'>
                      {groupB === option.id.toString() && (
                        <Check size='24' color={theme.palette.success.main} />
                      )}
                    </Box>
                  </MenuItemContent>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display='flex' mb={2}>
          <FormControl className='initial' fullWidth>
            <InputLabel>Statistical Test</InputLabel>
            <Select
              value={statisticalTest}
              onChange={handleStatisticalTestChange}
              label='Statistical test'
              sx={{
                '& .MuiSelect-select svg': {
                  display: 'none',
                },
              }}
            >
              {STATISTICAL_TEST_OPTIONS.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <MenuItemContent>
                    {option.name}
                    <Box display='flex'>
                      {statisticalTest === option.id && (
                        <Check size='24' color={theme.palette.success.main} />
                      )}
                      <Box sx={{ pl: 1 }}>
                        <Tooltip title={option.description}>
                          <Info size={24} />
                        </Tooltip>
                      </Box>
                    </Box>
                  </MenuItemContent>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className='initial'
            fullWidth
            sx={{
              marginLeft: 1,
            }}
          >
            <InputLabel>Sidedness</InputLabel>
            <Select value={comparativeStatistics?.sidedness} label='Sidedness' disabled>
              {SIDEDNESS_OPTIONS.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <MenuItemContent>{option.name}</MenuItemContent>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isLoading && (
          <Box height={320} display='flex'>
            <Loader />
          </Box>
        )}
        {error && (
          <Box height={320} display='flex'>
            <StatisticsError error={COMPARATIVE_STATISTICS_ERROR} />
          </Box>
        )}
        {showComparativeStatistics && (
          <>
            <Typography component='p' mb={2}>
              {comparativeStatistics?.textual_description}
            </Typography>
            <Box pb={2}>
              <DataGrid
                columns={comparativeStatisticsColumns}
                rows={sortedRows}
                sortColumns={sortColumns}
                onSortColumnsChange={onSortColumnsChange}
                renderers={{ sortStatus: SortIcon }}
                className='rdg-light statistics-table modal'
              />
            </Box>
          </>
        )}

        {showComparativeStatistics && (
          <Typography component='p' variant='caption' color='secondary.main' pb={6}>
            Comparative statistics computed on numerical features
          </Typography>
        )}
      </Box>
    </DialogWrapper>
  );
};

import {
  forceSimulation,
  forceManyBody,
  forceCenter,
  forceCollide,
  SimulationNodeDatum,
} from 'd3-force';
import { polygonHull, polygonCentroid } from 'd3-polygon';
import { NODE_X, NODE_Y, PPN, NODE_SIZE } from './matrixProps';

function circle_pack_components(
  components: string[][],
  nodes: Float32Array,
  numIterations: number
) {
  const node2componentID: Record<number, number> = {};

  components.forEach((component, index) => {
    component.forEach((node_id) => {
      const n = Number(node_id);
      node2componentID[n] = index;
    });
  });

  // componentPositions is an array of positions of each point
  const componentPositions = components.map((component): [number, number][] =>
    component.map((node_id) => {
      const j = Number(node_id) * PPN;
      return [nodes[j + NODE_X], nodes[j + NODE_Y]] as [number, number];
    })
  );

  const polygons = componentPositions.map((component) => polygonHull(component));

  const polygonCentroids = polygons.map((shape, i) => {
    if (shape === null) {
      // if shape == null.
      // CASE 1 componentPosition.length == 1
      // CASE 2 componentPosition.length == 2.
      const componentPosition = componentPositions[i];
      if (componentPosition.length === 1) {
        return componentPosition[0];
      }

      const pt1x = componentPosition[0][0];
      const pt2x = componentPosition[1][0];

      const pt1y = componentPosition[0][1];
      const pt2y = componentPosition[1][1];

      return [(pt1x + pt2x) / 2, (pt1y + pt2y) / 2];
    }

    return polygonCentroid(shape);
  });

  const componentRadiuses = polygons.map((polygon, i) => {
    const center = polygonCentroids[i];

    let maxRadius = 0;

    if (polygon === null) {
      const component = componentPositions[i];
      if (component.length === 1) {
        // Singleton radius
        maxRadius = 5;
      } else {
        const dx = component[1][0] - component[0][0];
        const dy = component[1][1] - component[0][1];
        maxRadius = Math.sqrt(dx ** 2 + dy ** 2) / 2;
      }
    } else {
      // Find the max distance from center to edge.
      for (let j = 0; j < polygon.length; j += 1) {
        const pos = polygon[j];
        const dx = pos[0] - center[0];
        const dy = pos[1] - center[1];
        const dist = Math.sqrt(dx ** 2 + dy ** 2);
        maxRadius = Math.max(dist, maxRadius);
      }
    }
    return maxRadius;
  });

  // This i is the index in the map.
  // Little bit weird if you're not used to js.

  const componentsToCirclePack = components.map((d, i) => {
    const cp = Object.create(d);
    cp.radius = componentRadiuses[i];
    return cp;
  });

  const simulation = forceSimulation<SimulationNodeDatum & { radius: number }>(
    componentsToCirclePack
  )
    .force('charge', forceManyBody())
    .force('center', forceCenter(0, 0))
    .force(
      'collision',
      forceCollide<SimulationNodeDatum & { radius: number }>().radius((d) => d.radius)
    );

  simulation.stop();

  simulation.tick(numIterations);

  const verticesInComponents = components
    .map((component) => component.map((node_id) => Number(node_id)))
    .flat();

  return {
    vertices: verticesInComponents,
    node_to_component_id: node2componentID,
    components_to_circle_pack: componentsToCirclePack,
    polygon_centroids_initial: polygonCentroids,
  };
}

export const relativeComponentLayout = (
  node_matrix: Float32Array,
  components: string[][],
  iterations: number
) => {
  /* eslint-disable no-param-reassign */
  const nonSingletonComponents = components.filter((component) => component.length > 1);
  const singletonComponents = components.filter((component) => component.length === 1);
  const out = circle_pack_components(nonSingletonComponents, node_matrix, iterations);

  let minX = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;

  let minY = Number.MAX_VALUE;
  let maxY = Number.MIN_VALUE;

  out.vertices.forEach((i) => {
    const componentID = out.node_to_component_id[i];
    const componentCentroid = out.polygon_centroids_initial[componentID];
    const xPosition =
      out.components_to_circle_pack[componentID].x +
      node_matrix[i * PPN + NODE_X] -
      componentCentroid[0];

    const yPosition =
      out.components_to_circle_pack[componentID].y +
      node_matrix[i * PPN + NODE_Y] -
      componentCentroid[1];
    node_matrix[i * PPN + NODE_X] = xPosition;
    node_matrix[i * PPN + NODE_Y] = yPosition;

    const size = node_matrix[i * PPN + NODE_SIZE];

    maxX = Math.max(maxX, xPosition + size);
    minX = Math.min(minX, xPosition - size);

    maxY = Math.max(maxY, yPosition + size);
    minY = Math.min(minY, yPosition - size);
  });

  const singletonInfo = circle_pack_components(singletonComponents, node_matrix, iterations);

  let minXS = Number.MAX_VALUE;
  let maxXS = Number.MIN_VALUE;

  let minYS = Number.MAX_VALUE;
  let maxYS = Number.MIN_VALUE;

  const numNonSingletonNodes = nonSingletonComponents.reduce((acc, x) => acc + x.length, 0);

  singletonInfo.vertices.forEach((i) => {
    const componentID = singletonInfo.node_to_component_id[i];
    const xPosition = singletonInfo.components_to_circle_pack[componentID].x;
    const yPosition = singletonInfo.components_to_circle_pack[componentID].y;
    const size = node_matrix[i * PPN + NODE_SIZE];
    maxXS = Math.max(xPosition + size, maxXS);
    minXS = Math.min(xPosition - size, minXS);

    maxYS = Math.max(yPosition + size, maxYS);
    minYS = Math.min(yPosition - size, minYS);
  });

  if (numNonSingletonNodes === 0) {
    singletonInfo.vertices.forEach((i) => {
      const componentID = singletonInfo.node_to_component_id[i];
      const xPosition = singletonInfo.components_to_circle_pack[componentID].x;
      const yPosition = singletonInfo.components_to_circle_pack[componentID].y;
      // Simple case just use results of circle packing.
      node_matrix[i * PPN + NODE_X] = xPosition;
      node_matrix[i * PPN + NODE_Y] = yPosition;
    });
  } else {
    const midY = (maxY + minY) / 2;
    // scale by number of nodes
    const widthSingletonsRegion = maxXS - minXS;
    const heightSingletonsRegion = maxYS - minYS;

    const widthNonSingletonsRegion = maxX - minX;
    const heightNonSingletonsRegion = maxY - minY;

    const areaNonSingletonRegion = widthNonSingletonsRegion * heightNonSingletonsRegion;
    const areaSingletonRegion = widthSingletonsRegion * heightSingletonsRegion;
    const ratio = singletonComponents.length / numNonSingletonNodes;
    const scaleFactor = Math.sqrt((ratio * areaNonSingletonRegion) / areaSingletonRegion);

    maxXS = -Infinity;
    minXS = Infinity;
    singletonInfo.vertices.forEach((i) => {
      const componentID = singletonInfo.node_to_component_id[i];
      const xPosition = singletonInfo.components_to_circle_pack[componentID].x;
      const yPosition = singletonInfo.components_to_circle_pack[componentID].y;
      node_matrix[i * PPN + NODE_X] = minX - xPosition * scaleFactor;
      // Set Node.y position to be the middle y value the nonSingletonComponent area.
      node_matrix[i * PPN + NODE_Y] = midY + yPosition * scaleFactor;
      const size = node_matrix[i * PPN + NODE_SIZE];
      maxXS = Math.max(minX - xPosition * scaleFactor + size, maxXS);
      minXS = Math.min(minX - xPosition * scaleFactor - size, minXS);
    });

    const horizontalOverlap = maxXS - minX;
    const horizontalMargin = 0.1 * Math.max(maxXS - minXS, maxX - minX);
    singletonInfo.vertices.forEach((i) => {
      // Offset here by horizontal overlap.
      node_matrix[i * PPN + NODE_X] -= horizontalOverlap + horizontalMargin;
    });
  }
};

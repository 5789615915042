import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

// Will be needed later when we will support more than 1 domain (right now it's only Genomics)
export const Domain = styled(Box)(({ theme }) => ({
  background: theme.palette.info.light,
  color: theme.palette.primary.contrastText,
  fontWeight: '500',
  fontSize: '1.2rem',
  lineHeight: '22px',
  padding: theme.spacing(0, 1),
  borderRadius: '4px',
  marginRight: theme.spacing(1),
  '&.failed': {
    background: theme.palette.text.disabled,
  },
}));

export const ListRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  cursor: 'pointer',
  padding: theme.spacing(3, 1),
  boxShadow: 'inset 0px -1px 0px rgba(18, 34, 57, 0.12)',
  ' :hover': { background: theme.palette.blocksBackground.hover },
  '&.highlight': { boxShadow: 'inset 0px -1px 0px #122239' },
  '&.highlight, &.publishing': { background: theme.palette.blocksBackground.hover },
  '&.highlight.failed': { background: theme.palette.error.light },
  '&.highlight.failed .info': { color: '#973838' },
}));

export const ListRowInfo = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

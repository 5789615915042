import React, { FC, useEffect, useMemo, useState } from 'react';
import { ChevronDown, Plus, Search, Download, Trash2 } from 'react-feather';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { Popup } from 'app/shared/components/Popup/Popup';
import { CreateGroupDialog } from 'app/screens/Analysis/CreateGroupDialog/CreateGroupDialog';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { StyledTextField } from 'app/mui/TextField';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from 'app/screens/Analysis/CreateGroupDialog/CreateGroupDialog.styles';
import { shortName } from 'app/shared/utils/visualization';
import { GroupType, SELECTION_SOURCES, SIDEBAR_TABS } from 'app/screens/Analysis/Analysis.types';
import { GroupCreationEnum, GroupTypeEnum } from 'app/shared/enum/sidebar-selections';
import { Tooltip } from 'app/mui/Tooltip';
import { Statistics } from 'app/screens/Analysis/AnalysisSidebar/Statistics/Statistics';
import { dataPointSources, featureSources, RESET_LAST_CREATED_TIMEOUT } from './Explore.constants';
import {
  FeatureName,
  GroupItem,
  SelectionSourceBox,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '../AnalysisSidebar.styles';

type ExploreProps = {
  tab: SIDEBAR_TABS;
};

export const Explore: FC<ExploreProps> = ({ tab }) => {
  const theme = useTheme();

  const {
    lastCreatedGroup,
    setLastCreatedGroup,
    totalRows,
    getGroups,
    selectedDataRows,
    selectedFeatures,
    groups,
    features,
    dataPointsSelectionSource,
    featureSelectionSource,
    setFeaturesSelectionSource,
    setDataSelectionSource,
    setSelectedDataRows,
    setSelectedFeatures,
    exportCSV,
    deleteGroup,
  } = useAnalysis();

  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState<boolean>(false);
  const [isOpenDeleteGroupModal, setIsOpenDeleteGroupModal] = useState<boolean>(false);
  const [groupToDelete, setGroupToDelete] = useState<GroupType | undefined>(undefined);
  const [groupSearch, setGroupSearch] = useState<string>('');
  const [groupType, setGroupType] = useState<string>(GroupTypeEnum.FEATURES);
  const [selectedDataGroup, setSelectedDataGroup] = useState<GroupType | undefined>(undefined);
  const [selectedFeatureGroup, setSelectedFeatureGroup] = useState<GroupType | undefined>(
    undefined
  );
  const [expandedGroups, setExpandedGroups] = useState<boolean>(true);

  const [expandedSelectedItems, setExpandedSelectedItems] = useState<boolean>(true);
  const [isTabToggled, setIsTabToggled] = useState<boolean>(false);

  const isFeatureGroup =
    lastCreatedGroup?.type === GroupTypeEnum.FEATURES ||
    lastCreatedGroup?.type === GroupCreationEnum.FILTERED_FEATURE_ROWS;

  const { analysisId } = useParams();

  useEffect(() => {
    if (tab === SIDEBAR_TABS.EXPLORE) {
      setIsTabToggled(true);
    }
  }, [tab]);

  useEffect(() => {
    if (isTabToggled) {
      if ((selectedDataRows.length > 0 || selectedFeatures.length > 0) && !lastCreatedGroup) {
        setExpandedGroups(false);
      }
    }
  }, [isTabToggled]);

  useEffect(() => {
    if (lastCreatedGroup) {
      setExpandedGroups(true);
      if (isFeatureGroup) {
        setGroupType(GroupTypeEnum.FEATURES);
      } else {
        setGroupType(GroupTypeEnum.DATA_POINTS);
      }
      setTimeout(() => {
        setLastCreatedGroup(undefined);
      }, RESET_LAST_CREATED_TIMEOUT);
    }
  }, [lastCreatedGroup]);

  useEffect(() => {
    if (selectedDataRows.length > 0 || selectedFeatures.length > 0) {
      setExpandedSelectedItems(true);
    }
  }, [selectedDataRows, selectedFeatures]);

  useEffect(() => {
    getGroups(Number(analysisId));
  }, []);

  useEffect(() => {
    if (selectedDataGroup && dataPointsSelectionSource?.source !== SELECTION_SOURCES.GROUP) {
      setSelectedDataGroup(undefined);
    }
  }, [selectedDataGroup, dataPointsSelectionSource]);

  useEffect(() => {
    if (selectedFeatureGroup && featureSelectionSource?.source !== SELECTION_SOURCES.GROUP) {
      setSelectedFeatureGroup(undefined);
    }
  }, [selectedFeatureGroup, featureSelectionSource]);

  const openCreateGroupModal = (): void => {
    setIsOpenCreateGroupModal(true);
  };

  const closeCreateGroupModal = (): void => {
    setIsOpenCreateGroupModal(false);
  };

  const openDeleteGroupModal = (group: GroupType): void => {
    setIsOpenDeleteGroupModal(true);
    setGroupToDelete(group);
  };

  const closeDeleteGroupModal = (): void => {
    setIsOpenDeleteGroupModal(false);
    setGroupToDelete(undefined);
  };

  const onDeleteGroup = (group: GroupType): void => {
    deleteGroup(Number(analysisId), group);
    closeDeleteGroupModal();
  };

  const handleChangeSearchGroup = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setGroupSearch(event.target.value);
  };

  const handleChangeGroupType = (event: React.MouseEvent<HTMLElement>, newType: string): void => {
    setGroupType(newType);
  };

  const onSelectedGroupChange = async (group: GroupType): Promise<void> => {
    const isFeaturesGroup = group.type === GroupCreationEnum.FEATURES;

    if (isFeaturesGroup) {
      setFeaturesSelectionSource({
        source: SELECTION_SOURCES.GROUP,
      });

      const isAlreadySelectedGroup = group.id === selectedFeatureGroup?.id;
      const nodes = !isAlreadySelectedGroup ? group?.features : [];

      if (isAlreadySelectedGroup) {
        setSelectedFeatureGroup(undefined);
        setSelectedFeatures([]);

        return;
      }

      setSelectedFeatureGroup(group);

      if (!nodes?.length) {
        setSelectedFeatures(features.map((f) => f.id));
      } else {
        setSelectedFeatures(nodes);
      }
    } else {
      setDataSelectionSource({
        source: SELECTION_SOURCES.GROUP,
      });

      const isAlreadySelectedGroup = group.id === selectedDataGroup?.id;
      const nodes = !isAlreadySelectedGroup ? group?.rows : [];

      if (isAlreadySelectedGroup) {
        setSelectedDataGroup(undefined);
        setSelectedDataRows([]);

        return;
      }

      setSelectedDataGroup(group);

      if (!nodes?.length) {
        setSelectedDataRows(Array.from(Array(totalRows).keys()));
      } else {
        setSelectedDataRows(nodes);
      }
    }
  };

  const sortedSelectedDataRows = useMemo(
    () => [...selectedDataRows].sort((a, b) => (a > b ? 1 : -1)),
    [selectedDataRows]
  );

  const selectedFeaturesNames = useMemo(
    () =>
      [...selectedFeatures]
        .sort((a, b) => (a > b ? 1 : -1))
        .map((id) => features.find((feature) => feature.id === id)?.feature_name),
    [selectedFeatures, features]
  );

  const filteredGroups = useMemo(
    () =>
      groups.filter((group: GroupType) =>
        group.name.toLowerCase().includes(groupSearch.toLowerCase())
      ),
    [groups, groupSearch]
  );

  return (
    <Box p={4}>
      <StyledAccordion expanded={expandedGroups} disableGutters elevation={0}>
        <StyledAccordionSummary
          className='chevronUp'
          style={{ paddingBottom: theme.spacing(1) }}
          expandIcon={
            <ChevronDown
              onClick={() => setExpandedGroups((prevState) => !prevState)}
              size='20'
              color={theme.palette.primary.main}
            />
          }
        >
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography
              variant='h6'
              fontWeight='600'
              width='100%'
              onClick={() => setExpandedGroups((prevState) => !prevState)}
            >
              Groups
            </Typography>
            <Plus onClick={openCreateGroupModal} color={theme.palette.action.active} />
          </Box>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledTextField
            id='outlined-start-adornment'
            label='Search group'
            value={groupSearch}
            onChange={handleChangeSearchGroup}
            size='small'
            fullWidth
            placeholder='Search group'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <StyledToggleButtonGroup
            value={groupType}
            exclusive
            onChange={handleChangeGroupType}
            aria-label='text alignment'
            className='small'
          >
            <StyledToggleButton value={GroupTypeEnum.FEATURES} aria-label={GroupTypeEnum.FEATURES}>
              {GroupTypeEnum.FEATURES}
            </StyledToggleButton>
            <StyledToggleButton
              value={GroupTypeEnum.DATA_POINTS}
              aria-label={GroupTypeEnum.DATA_POINTS}
            >
              {GroupTypeEnum.DATA_POINTS}
            </StyledToggleButton>
          </StyledToggleButtonGroup>

          <Box>
            {filteredGroups
              .filter((group: GroupType) =>
                groupType === GroupTypeEnum.FEATURES
                  ? group.type === GroupCreationEnum.FEATURES
                  : group.type === GroupCreationEnum.ROWS
              )
              .map((group: GroupType) => (
                <GroupItem
                  key={group.name}
                  className={classNames({
                    selected:
                      selectedDataGroup?.id === group?.id || selectedFeatureGroup?.id === group?.id,
                    highlight: lastCreatedGroup?.name === group?.name,
                  })}
                >
                  <Box
                    onClick={() => onSelectedGroupChange(group)}
                    height='48px'
                    display='flex'
                    alignItems='center'
                    width='185px'
                  >
                    <Tooltip title={group.name} placement='bottom-start'>
                      <Typography noWrap sx={{ maxWidth: '185px' }} variant='body1'>
                        {group.name}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Download
                    size='24'
                    onClick={() =>
                      exportCSV({
                        analysisId: Number(analysisId),
                        groupId: group.id,
                        groupName: group.name,
                      })
                    }
                  />
                  <Box ml={1}>
                    <Trash2 size={24} onClick={() => openDeleteGroupModal(group)} />
                  </Box>
                </GroupItem>
              ))}
          </Box>
        </StyledAccordionDetails>
      </StyledAccordion>

      {(selectedFeatures.length > 0 || selectedDataRows.length > 0) && (
        <>
          <Box
            sx={{
              boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
              height: '1px',
              mb: theme.spacing(1),
            }}
          />
          <StyledAccordion expanded={expandedSelectedItems} disableGutters elevation={0}>
            <StyledAccordionSummary
              className='chevronUp'
              style={{ paddingBottom: theme.spacing(2) }}
              expandIcon={
                <ChevronDown
                  onClick={() => setExpandedSelectedItems((prevState) => !prevState)}
                  size='20'
                  color={theme.palette.primary.main}
                />
              }
            >
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  width='100%'
                  onClick={() => setExpandedSelectedItems((prevState) => !prevState)}
                >
                  Selected Items
                </Typography>
                <Download
                  color={theme.palette.action.active}
                  onClick={() =>
                    exportCSV({
                      analysisId: Number(analysisId),
                      featureIds: selectedFeatures,
                      dataPointIds: selectedDataRows,
                    })
                  }
                />
              </Box>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {selectedFeatures.length > 0 && (
                <StyledAccordion disableGutters elevation={0} sx={{ mb: 3 }}>
                  <StyledAccordionSummary
                    className='chevronUp'
                    expandIcon={<ChevronDown size={20} />}
                  >
                    <Typography variant='subtitle1' sx={{ mb: 1 }}>
                      Features: {selectedFeatures.length}
                    </Typography>
                    {!!featureSelectionSource && (
                      <SelectionSourceBox>
                        Source:{' '}
                        {featureSelectionSource?.name ||
                          featureSources[featureSelectionSource.source]}
                      </SelectionSourceBox>
                    )}
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    <Box maxHeight='296px' overflow='auto'>
                      {selectedFeaturesNames.map(
                        (name) =>
                          name && (
                            <FeatureName key={name}>
                              <Tooltip title={name} placement='right'>
                                <Typography
                                  noWrap
                                  sx={{
                                    width: 'fit-content',
                                  }}
                                >
                                  {shortName(name, 220)}
                                </Typography>
                              </Tooltip>
                            </FeatureName>
                          )
                      )}
                    </Box>
                  </StyledAccordionDetails>
                </StyledAccordion>
              )}
              {selectedDataRows.length > 0 && (
                <StyledAccordion disableGutters elevation={0}>
                  <StyledAccordionSummary
                    className='chevronUp'
                    expandIcon={<ChevronDown size={20} />}
                  >
                    <Typography variant='subtitle1' sx={{ mb: 1 }}>
                      Data points: {selectedDataRows.length}
                    </Typography>
                    {!!dataPointsSelectionSource && (
                      <SelectionSourceBox>
                        Source:{' '}
                        {dataPointsSelectionSource?.name ||
                          dataPointSources[dataPointsSelectionSource.source]}
                      </SelectionSourceBox>
                    )}
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    <Box maxHeight='296px' overflow='auto'>
                      {sortedSelectedDataRows.map(
                        (name: number) =>
                          name !== undefined && (
                            <FeatureName key={name}>
                              <Typography>{name}</Typography>
                            </FeatureName>
                          )
                      )}
                    </Box>
                  </StyledAccordionDetails>
                </StyledAccordion>
              )}
            </StyledAccordionDetails>
          </StyledAccordion>
        </>
      )}
      <Divider
        sx={{
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
      />

      <Statistics />

      {isOpenCreateGroupModal && (
        <CreateGroupDialog
          openDialog={isOpenCreateGroupModal}
          handleClose={closeCreateGroupModal}
        />
      )}
      {isOpenDeleteGroupModal && groupToDelete && (
        <Popup
          open={isOpenDeleteGroupModal}
          onClose={closeDeleteGroupModal}
          onConfirm={() => onDeleteGroup(groupToDelete)}
          state='Delete'
          title={`Delete '${groupToDelete.name}' group?`}
          description='You will not be able to recover it'
        />
      )}
    </Box>
  );
};

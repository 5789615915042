import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { Layout } from 'app/shared/components/Layout/Layout';
import { DATE_SORTING_FIELD } from 'app/shared/utils/date';
import { Order, SortSelect } from 'app/shared/components/SortSelect/SortSelect';
import noResultsThumb from 'app/assets/images/thumbnails/no-results-datasets.svg';
import { NoResults } from 'app/shared/components/NoResults/NoResults';
import { ProjectAnalysis } from 'app/screens/Projects/Projects.types';
import { AnalysisList } from './AnalysisList/AnalysisList';
import { useAnalysis } from '../Analysis.hooks';

export type SelectedAnalysis =
  | {
      id: number | undefined;
      previewId: number | undefined;
      name: string | undefined;
      description: string | undefined;
    }
  | undefined;

type AnalysisLayoutProps = {
  projectId: number;
  analysis: ProjectAnalysis[];
  selectedAnalysis: SelectedAnalysis;
  setSelectedAnalysis: Dispatch<SetStateAction<SelectedAnalysis>>;
  handlePreviewOpen: (id: number) => void;
  handlePreviewClose: () => void;
  handleOpenAnalysisModal: () => void;
};

export const AnalysisLayout: FC<AnalysisLayoutProps> = ({
  projectId,
  analysis,
  selectedAnalysis,
  setSelectedAnalysis,
  handlePreviewOpen,
  handlePreviewClose,
  handleOpenAnalysisModal,
}) => {
  const { layout, setLayout } = useAnalysis();

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState(DATE_SORTING_FIELD);

  const onViewChange = useCallback(
    (view: string) => {
      setLayout(view);
    },
    [setLayout]
  );

  return (
    <Box mt={6}>
      <Layout
        heading='Analyses'
        onViewChange={onViewChange}
        type={layout}
        onButtonClick={handleOpenAnalysisModal}
        buttonTitle='Start New Analysis'
        isEmpty={analysis.length === 0}
        itemsCount={analysis.length}
        sortSelect={
          <SortSelect order={order} orderBy={orderBy} setOrder={setOrder} setOrderBy={setOrderBy} />
        }
      >
        {analysis.length > 0 ? (
          <AnalysisList
            analysis={analysis}
            order={order}
            orderBy={orderBy}
            selectedAnalysis={selectedAnalysis}
            setSelectedAnalysis={setSelectedAnalysis}
            handlePreviewOpen={handlePreviewOpen}
            handlePreviewClose={handlePreviewClose}
            projectId={projectId}
          />
        ) : (
          <NoResults
            thumb={noResultsThumb}
            title='No analyses created yet'
            description='Start new analysis from published dataset in our library'
            buttonText='Start New Analysis'
            onButtonClick={handleOpenAnalysisModal}
          />
        )}
      </Layout>
    </Box>
  );
};

import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link2, DownloadCloud, Check } from 'react-feather';
import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { StyledTextField } from 'app/mui/TextField';
import { Button } from 'app/mui/Button';
import { formatBytes } from 'app/shared/utils/formatBytes';
import {
  DATASET_STATUSES,
  DATASET_DOMAIN,
  FAILED_UPLOAD_STATUSES,
  DATASET_UPLOADING_STATUS,
} from 'app/shared/enum/dataset';
import { Tooltip } from 'app/mui/Tooltip';
import { useAlert } from 'app/shared/hooks/useAlert';
import { ProgressBar } from 'app/shared/components/ProgressBar/ProgressBar';
import { datasetFileName, uploadedFileExtension } from 'app/shared/utils/dataset';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { Dataset } from 'app/screens/DataLibrary/DataLibrary.types';
import { DatasetUploadResponse } from 'app/screens/UploadDataset/UploadDataset.types';
import { useDataLibrary } from 'app/screens/DataLibrary/DataLibrary.hooks';
import { FailedUploadCard } from 'app/shared/components/Datasets/FailedUploadCard/FailedUploadCard';
import { LastJsonMessage, SOCKET_ENTITY, useSocket } from 'app/shared/hooks/useSocket';

export const dropzoneStyles = {
  style: {
    height: '191px',
    maxHeight: '210px',
    padding: '32px 24px',
    border: `1px dashed #A5ABB4`,
    borderRadius: '4px',
    '&.disabled': { display: 'none' },
  },
};

const UPLOADING_TEXT =
  'It’s safe to proceed or go to Data Library, dataset uploading will be completed in the background.';
const UPLOADED_TEXT =
  'Dataset is ready for analysis if it’s assigned to a project. You can find it in Data Library.';

type Props = {
  isFormValid: boolean;
  onUploadingStarted: (dataset: Dataset) => void;
  onUploadFinished: (dataset: Dataset | undefined) => void;
  onUploadFailed?: () => void;
  onResetAfterCancel?: () => void;
  onDatasetDeletion?: () => void;
  projectId?: number;
  projectName?: string;
  datasetName?: string;
  shouldCancelUpload?: boolean;
  uploadedDatasetText?: string;
  uploadingDatasetText?: string;
};

enum FORM_TYPE {
  initial,
  error,
  uploaded,
}

export type DatasetErrorType = {
  type: string;
  message: string;
};

export const UploadForm: FC<Props> = ({
  isFormValid,
  onUploadingStarted,
  onUploadFinished,
  projectId,
  projectName = '',
  datasetName = '',
  shouldCancelUpload,
  onUploadFailed,
  onDatasetDeletion,
  onResetAfterCancel,
  uploadedDatasetText = UPLOADED_TEXT,
  uploadingDatasetText = UPLOADING_TEXT,
}) => {
  const { showSuccessMessage, showErrorMessage, resetAlert } = useAlert();

  const [formType, setFormType] = useState(FORM_TYPE.initial);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [datasetUrl, setDatasetUrl] = useState<string>('');
  const [dataset, setDataset] = useState<Dataset | undefined>(undefined);
  const [isDatasetUploaded, setIsDatasetUploaded] = useState<boolean>(false);
  const [isDatasetPublished, setIsDatasetPublished] = useState<boolean>(false);
  const [datasetSize, setDatasetSize] = useState<number | undefined>(undefined);
  const [datasetId, setDatasetId] = useState<number | undefined>(undefined);
  const [isUploadViaUrl, setIsUploadViaUrl] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>('');
  const [datasetError, setDatasetError] = useState<DatasetErrorType | undefined>(undefined);

  const {
    cancelUpload,
    deleteDataset,
    showPublishedDataset,
    showFailedDataset,
    showMetadataExtraction,
  } = useDataLibrary();

  const { lastJsonMessage } = useSocket();

  useEffect(() => {
    if (lastJsonMessage) {
      const { type, payload } = lastJsonMessage as LastJsonMessage;
      const currentDataset = payload as Dataset;

      const isCurrentDataset = dataset?.id === currentDataset?.id;

      if (type === SOCKET_ENTITY.DATASET && isCurrentDataset) {
        if (showMetadataExtraction(currentDataset.status)) {
          setDataset(currentDataset);
        }
        if (showPublishedDataset(currentDataset.status)) {
          setDataset(currentDataset);
        }

        if (showFailedDataset(currentDataset.status)) {
          if (currentDataset.error) {
            if (onUploadFailed) {
              onUploadFailed();
            }
            setDatasetError({
              type: FAILED_UPLOAD_STATUSES.UPLOADING_FAILED,
              message: currentDataset.error,
            });
          }
        }
      }
    }
  }, [lastJsonMessage]);

  const onFileUploadingStarted = (initialDataset: Dataset): void => {
    setDataset(initialDataset);

    if (onUploadingStarted) {
      onUploadingStarted(initialDataset);
    }
  };

  const onFileUploadFinished = (uploadedDataset: Dataset): void => {
    setDataset(uploadedDataset);
    setIsDatasetUploaded(true);
    onUploadFinished(uploadedDataset);
  };

  const uploadViaUrl = async (): Promise<void> => {
    setIsUploadViaUrl(true);
    const uploadUrl = projectId
      ? `project/${projectId}/upload-dataset-external`
      : 'dataset/upload-external';
    const hasProjectName = projectName ? { project_name: projectName } : {};

    try {
      const response: Dataset = await api
        .post(uploadUrl, {
          headers: getCommonHeaders(),
          json: {
            filename: datasetName,
            dataset_url: datasetUrl,
            domain: DATASET_DOMAIN.GENOMICS,
            ...hasProjectName,
          },
        })
        .json();

      setDatasetId(response.id);

      onFileUploadingStarted(response);
      onFileUploadFinished(response);
    } catch (error: any) {
      const parsedError = await error.response.json();
      setUploadError(parsedError.detail);
      resetAlert();
    }
  };

  const request = useMemo(() => new XMLHttpRequest(), [file]);

  const onReset = useCallback(() => {
    setFormType(FORM_TYPE.initial);
    setFile(undefined);
    setDatasetUrl('');
    setDataset(undefined);
    setDatasetError(undefined);
    setIsDatasetUploaded(false);
    setIsDatasetPublished(false);
    setIsUploadViaUrl(false);
    setDatasetSize(undefined);
    setDatasetId(undefined);
    onUploadFinished(undefined);
    if (onResetAfterCancel) {
      onResetAfterCancel();
    }
  }, []);

  const onCancelUpload = (): void => {
    request.abort();

    if (datasetId) {
      cancelUpload(datasetId);
    }
    onReset();
  };

  const onDelete = async (): Promise<void> => {
    if (datasetId) {
      await deleteDataset(datasetId, () => {
        if (onDatasetDeletion) {
          onDatasetDeletion();
        }
      });
    }
    onReset();
  };

  useEffect(() => {
    if (shouldCancelUpload) {
      onCancelUpload();
    }
  }, [shouldCancelUpload]);

  useEffect(() => {
    if (dataset && dataset.dataset_metadata) {
      setDatasetSize(dataset.dataset_metadata.size);
    }
    if (dataset && dataset.status === DATASET_STATUSES.METADATA_FINISHED) {
      setTimeout(() => {
        setIsDatasetPublished(true);
      }, 1000);
    }
  }, [dataset]);

  const uploadFile = async (): Promise<any> => {
    if (!file) return;

    setIsDatasetUploaded(false);
    setDatasetSize(file.size);
    const uploadUrl = projectId ? `project/${projectId}/upload-dataset` : 'dataset/upload';
    const hasProjectName = projectName ? { project_name: projectName } : {};

    try {
      const uploadedDataset = (await api
        .post(uploadUrl, {
          headers: getCommonHeaders(),
          json: {
            filename: datasetFileName(datasetName, file.name),
            extension: uploadedFileExtension(file.name),
            domain: DATASET_DOMAIN.GENOMICS,
            ...hasProjectName,
          },
        })
        .json()) as DatasetUploadResponse;

      onFileUploadingStarted(uploadedDataset.dataset);

      setDatasetId(uploadedDataset.dataset.id);

      const formData = new FormData();

      Object.entries(uploadedDataset.url_to_upload.fields).forEach(([k, v]): void =>
        formData.append(k, v)
      );
      formData.append('file', file);

      request.open('POST', uploadedDataset.url_to_upload.url);

      request.addEventListener('error', () => {
        showErrorMessage({
          title: 'An error occurred',
        });
      });

      request.addEventListener('load', async () => {
        if (request.status === 204) {
          await api
            .put(`dataset/${uploadedDataset.dataset.id}`, {
              headers: getCommonHeaders(),
              json: {
                ...dataset,
                status: DATASET_STATUSES.UPLOADED,
              },
            })
            .json();

          onFileUploadFinished({
            ...uploadedDataset.dataset,
            status: DATASET_STATUSES.UPLOADED,
          });

          showSuccessMessage({
            title: 'Dataset uploaded',
          });

          setFile(undefined);
        }
      });

      request.send(formData);
    } catch (error) {
      setFormType(FORM_TYPE.initial);
      setFile(undefined);
      return error;
    }
  };

  useEffect(() => {
    async function upload(): Promise<void> {
      await uploadFile();
    }

    upload();
  }, [file]);

  const onDrop = useCallback((acceptedFile: File[]) => {
    setFile(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const isUploadingViaFile = file && dataset && !isUploadViaUrl;
  const isUploadingExternal = dataset && isUploadViaUrl;

  useEffect(() => {
    if ((isUploadingViaFile || isUploadingExternal) && !datasetError) {
      setFormType(FORM_TYPE.uploaded);
    } else if (datasetError) {
      setFormType(FORM_TYPE.error);
      setDataset(undefined);
    }
  }, [file, dataset, datasetError]);

  const errorStyles = useMemo(() => {
    if (datasetError)
      return {
        border: '1px dashed #A70101',
        background: '#FFF5F5',
      };

    return {};
  }, [datasetError]);

  const uploadingStyles = useMemo(() => {
    if (formType === FORM_TYPE.uploaded)
      return {
        background: '#F2FCFF',
      };

    return {};
  }, [formType]);

  const uploadingStatus = useMemo(() => {
    if (!isDatasetUploaded) {
      return { step: 1, status: DATASET_UPLOADING_STATUS.UPLOADING };
    }
    if (
      dataset &&
      (dataset.status === DATASET_STATUSES.UPLOADED ||
        dataset.status === DATASET_STATUSES.PROCESSING)
    ) {
      return { step: 2, status: DATASET_UPLOADING_STATUS.PROCESSING };
    }
    if (dataset && dataset.status === DATASET_STATUSES.METADATA_STARTED) {
      return { step: 3, status: DATASET_UPLOADING_STATUS.METADATA_EXTRACTION };
    }
    return { step: 3, status: DATASET_UPLOADING_STATUS.METADATA_EXTRACTION };
  }, [dataset]);

  const onDatasetUrlChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setDatasetUrl(event.target.value);
    setUploadError('');
  };

  return (
    <>
      {!isFormValid && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ ...dropzoneStyles.style }}
        >
          <Typography variant='h6'>Please complete mandatory fields before uploading</Typography>
        </Box>
      )}
      {isFormValid && (
        <Box
          {...getRootProps({
            ...dropzoneStyles,
          })}
          sx={{ ...errorStyles, ...uploadingStyles }}
        >
          {formType === FORM_TYPE.initial && (
            <>
              <Typography variant='h5' component='div' mb={1}>
                Drag & drop dataset file
              </Typography>
              <Typography variant='body1' mb={3}>
                Only <b>.csv</b> files are supported, including those compressed into .zip archives.
              </Typography>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button variant='contained' startIcon={<DownloadCloud />} onClick={open}>
                  Browse
                </Button>
                <Typography variant='body1' component='div' mx={3}>
                  or
                </Typography>
                <StyledTextField
                  placeholder='Link to web archive'
                  value={datasetUrl}
                  error={Boolean(uploadError)}
                  helperText={uploadError}
                  onChange={onDatasetUrlChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Link2 size={16} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: '100%',
                    maxWidth: '593px',
                    height: '40px',
                  }}
                />
                <Box ml={2} width='140px' minWidth='140px'>
                  <Button
                    onClick={uploadViaUrl}
                    disabled={datasetUrl.length === 0}
                    variant='outlined'
                    sx={{
                      width: '100%',
                    }}
                  >
                    Confirm Link
                  </Button>
                </Box>
              </Box>
              <input {...getInputProps()} />
            </>
          )}
          {formType === FORM_TYPE.error && datasetError && (
            <FailedUploadCard error={datasetError} onDelete={onDelete} />
          )}
          {formType === FORM_TYPE.uploaded && (
            <>
              <Box mb={4}>
                <Box display='flex' alignItems='center' mb={1}>
                  <Typography variant='h5' mr={2}>
                    {isDatasetUploaded && isDatasetPublished
                      ? 'Dataset is successfully uploaded'
                      : 'Uploading dataset to Data Library... '}
                  </Typography>
                  {isDatasetUploaded && isDatasetPublished && (
                    <Check
                      size='2rem'
                      style={{
                        borderRadius: '50%',
                        background: '#1CB359',
                        color: '#fff',
                        padding: '2px',
                      }}
                    />
                  )}
                </Box>
                <Typography variant='body1'>
                  {isDatasetUploaded && isDatasetPublished
                    ? uploadedDatasetText
                    : uploadingDatasetText}
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box maxWidth='200px' minWidth='110px'>
                  <>
                    {dataset && !file && (
                      <>
                        <Tooltip title={dataset.name} placement='bottom-start'>
                          <Typography noWrap variant='body1' className='break-text'>
                            {dataset.name}
                          </Typography>
                        </Tooltip>
                        {datasetSize && (
                          <Typography variant='caption' color='text.secondary'>
                            {formatBytes(datasetSize, 2)}
                          </Typography>
                        )}
                      </>
                    )}
                    {file && (
                      <>
                        <Tooltip title={file.name} placement='bottom-start'>
                          <Typography noWrap variant='body1' className='break-text'>
                            {file.name}
                          </Typography>
                        </Tooltip>
                        <Typography variant='caption' color='text.secondary'>
                          {formatBytes(file.size, 2)}
                        </Typography>
                      </>
                    )}
                  </>
                </Box>
                {!isDatasetPublished && (
                  <>
                    <Box width='100%' mx={6}>
                      <ProgressBar activeStatus={uploadingStatus} />
                    </Box>
                    <Button onClick={onCancelUpload} variant='outlined'>
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

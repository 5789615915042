import { useAppSelector } from 'app/store/hooks';
import { selectUser, User } from 'app/store/modules/account';

interface IUseHeader {
  user: User;
}

export const useHeader = (): IUseHeader => {
  const user = useAppSelector(selectUser);

  return {
    user,
  };
};

import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Tab, TabPanel, Tabs } from 'app/shared/components/Tabs/Tabs';
import { useDataLibrary } from 'app/screens/DataLibrary/DataLibrary.hooks';
import { DataLibrarySamplesList } from 'app/screens/DataLibrary/DataLibrarySamplesList/DataLibrarySamplesList';
import DataLibraryPreview from 'app/screens/DataLibrary/DataLibraryPreview/DataLibraryPreview';
import { Order } from 'app/shared/components/SortSelect/SortSelect';
import { DATE_SORTING_FIELD } from 'app/shared/utils/date';
import { SamplesLayout } from '../SamplesLayout/SamplesLayout';
import { SelectedSampleDataset } from '../UploadDataset';

type DatasetSamplesProps = {
  handleClose: () => void;
  selectedSampleDataset: SelectedSampleDataset;
  setSelectedSampleDataset: any;
};

const order: Order = 'desc';
const orderBy = DATE_SORTING_FIELD;
const projectId = 1;

export const DatasetSamples: FC<DatasetSamplesProps> = ({
  handleClose,
  selectedSampleDataset,
  setSelectedSampleDataset,
}) => {
  const { getDatasetSamples, datasetSamples } = useDataLibrary();
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    getDatasetSamples();
  }, []);

  const handleSamplePreviewOpen = (id: number): void => {
    setOpenPreview(true);
    const sampleDataset = datasetSamples.find((n) => n.id === id);
    if (sampleDataset) {
      setSelectedSampleDataset((dataset: SelectedSampleDataset) => ({
        ...dataset,
        project_id: projectId,
        dataset_id: id,
        name: sampleDataset.name,
        domain: sampleDataset.domain,
        size: sampleDataset.dataset_metadata.size,
        status: sampleDataset.status,
      }));
    }
  };

  const handlePreviewClose = (): void => {
    setOpenPreview(false);
    setSelectedSampleDataset(undefined);
  };

  const onCancelButton = (): void => {
    setSelectedSampleDataset(undefined);
    handleClose();
  };

  const selectedDataset = selectedSampleDataset && {
    id: selectedSampleDataset.dataset_id,
    name: selectedSampleDataset.name,
  };

  return (
    <Box
      position='absolute'
      sx={{
        background: '#fff',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        '&.visible': {
          display: 'flex',
        },
      }}
    >
      <Box py={6} px={22} display='flex' width='100%'>
        <Box width='100%'>
          <SamplesLayout
            heading='Data Library'
            caption='Data library contains all datasets you have access to.'
            onSelectButton={handleClose}
            onCancelButton={onCancelButton}
            selectedSampleDataset={selectedSampleDataset}
          >
            <Box display='flex' mt={3}>
              <Box width='100%' pr={4}>
                <Box display='flex' justifyContent='space-between'>
                  <Tabs
                    value={0}
                    aria-label='Data Library tabs'
                    variant='scrollable'
                    scrollButtons={false}
                  >
                    <Tab
                      label='Sample Datasets'
                      icon={<span>{datasetSamples?.length}</span>}
                      iconPosition='end'
                    />
                  </Tabs>
                </Box>
                <TabPanel value={0} index={0}>
                  <DataLibrarySamplesList
                    order={order}
                    orderBy={orderBy}
                    selectedDataset={selectedDataset}
                    handlePreviewOpen={handleSamplePreviewOpen}
                  />
                </TabPanel>
              </Box>
              {selectedSampleDataset && (
                <DataLibraryPreview
                  open={openPreview}
                  onClose={handlePreviewClose}
                  datasetId={selectedSampleDataset.dataset_id}
                  isSample
                />
              )}
            </Box>
          </SamplesLayout>
        </Box>
      </Box>
    </Box>
  );
};

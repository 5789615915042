import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  '&.MuiIconButton-colorPrimary': {
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent',
      color: theme.palette.hover.primary,
    },
    '.MuiTableCell-body &': {
      '&:not(:hover)': {
        color: theme.palette.action.active,
      },
    },
    '.MuiTableRow-root.Mui-selected &': {
      '&:not(:hover)': {
        color: theme.palette.primary.main,
      },
    },
  },
  '&.MuiIconButton-colorSecondary': {
    color: theme.palette.secondary.main,
  },
  '&.MuiIconButton-colorSuccess': {
    color: theme.palette.success.main,
  },
  '&.MuiIconButton-colorError': {
    color: theme.palette.error.main,
  },
  '&.MuiIconButton-colorInfo': {
    color: theme.palette.secondary.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
  '&.MuiIconButton-sizeLarge svg': {
    width: '36px',
    height: '36px',
  },
  '&.MuiIconButton-sizeMedium svg': {
    width: '24px',
    height: '24px',
  },
  '&.MuiIconButton-sizeSmall svg': {
    width: '22px',
    height: '22px',
  },
  '&.configArrows': {
    padding: 0,
    '& svg': {
      width: '16px',
      height: '16px',
    },
    '&.Mui-disabled svg': {
      color: theme.palette.action.disabled,
    },
  },
  '&.hasFilters': {
    background: '#BDDDE8',
    color: '#075678',
    borderRadius: '4px',
    position: 'relative',
  },
}));

export { StyledIconButton as IconButton };

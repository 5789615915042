import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import datasetImage from 'app/assets/images/datasets/dataset-image.svg';
import { formatBytes } from 'app/shared/utils/formatBytes';
import { Order } from 'app/shared/components/SortSelect/SortSelect';
import { Status } from 'app/shared/components/Status/Status';
import { StatusEnum } from 'app/shared/enum/status';
import { Button } from 'app/mui/Button';
import { CreateDatasetDialog } from 'app/shared/components/CreateDatasetDialog/CreateDatasetDialog';
import { useDataLibrary } from '../DataLibrary.hooks';
import { ListRow, ListRowInfo } from '../DataLibraryList/DataLibraryList.styles';
import { getComparator } from '../DataLibraryList/DataLibraryList';

type DataLibrarySamplesListProps = {
  order: Order;
  orderBy: string;
  handlePreviewOpen: (id: number) => void;
  selectSampleDataset?: (id: number, name: string) => void;
  selectedDataset?: { id: number; name: string };
};

export const DataLibrarySamplesList: FC<DataLibrarySamplesListProps> = ({
  order,
  orderBy,
  handlePreviewOpen,
  selectSampleDataset,
  selectedDataset,
}) => {
  const { datasetSamples, showPublishedDataset } = useDataLibrary();
  const [isStartAnalysisModalOpen, setIsStartAnalysisModalOpen] = useState(false);

  const closeAnalysisModal = useCallback(() => {
    setIsStartAnalysisModalOpen(false);
  }, []);

  const handleOpenAnalysisModal = useCallback(() => {
    setIsStartAnalysisModalOpen(true);
  }, []);

  return (
    <Box>
      {datasetSamples &&
        datasetSamples
          .slice()
          .sort(getComparator(order, orderBy))
          .map((row, index) => {
            const labelId = `table-checkbox-${index}`;

            return (
              <ListRow
                key={row.id}
                className={classNames({ highlight: row.id === selectedDataset?.id })}
                onClick={() => handlePreviewOpen(row.id)}
              >
                <ListRowInfo>
                  <Box borderRadius='4px' component='img' src={datasetImage} alt={row.name} />
                  <Box
                    sx={{
                      ml: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '12px',
                    }}
                  >
                    <Box display='flex'>
                      <Tooltip title={row.name} placement='bottom-start'>
                        <Typography component='h5' variant='h5' id={labelId} className='break-text'>
                          {row.name}
                        </Typography>
                      </Tooltip>
                      <Box ml={2}>
                        <Status
                          status={showPublishedDataset(row.status) ? StatusEnum.RAW : row.status}
                        />
                      </Box>
                    </Box>
                    <Typography variant='body1' color='text.secondary'>
                      {formatBytes(row.dataset_metadata?.size)}
                    </Typography>
                  </Box>
                </ListRowInfo>
                {selectSampleDataset && (
                  <Box display='flex' alignItems='center'>
                    <Button
                      variant='outlined'
                      onClick={(event) => {
                        event.stopPropagation();
                        selectSampleDataset(row.id, row.name);
                        handleOpenAnalysisModal();
                      }}
                    >
                      Start Analysis
                    </Button>
                  </Box>
                )}
              </ListRow>
            );
          })}
      {isStartAnalysisModalOpen && selectedDataset && (
        <CreateDatasetDialog
          openDialog={isStartAnalysisModalOpen}
          handleClose={closeAnalysisModal}
          selectedDataset={selectedDataset}
        />
      )}
    </Box>
  );
};

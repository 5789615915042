import useWebSocket from 'react-use-websocket';
import type { JsonValue } from 'react-use-websocket/dist/lib/types';

import { webSocketUrl } from '../utils/api';

export enum SOCKET_ENTITY {
  DATASET = 'dataset',
  ANALYSIS = 'analysis',
  RETRIEVE_GRAPH = 'retrieve-graph',
}

export type LastJsonMessage = {
  type: SOCKET_ENTITY;
  payload: Record<string, unknown>;
};

type UseSocket = {
  lastJsonMessage: JsonValue | null;
};

export const useSocket = (): UseSocket => {
  const { lastJsonMessage } = useWebSocket(webSocketUrl(), { share: true });

  return {
    lastJsonMessage,
  };
};

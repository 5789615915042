import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';

import { StyledTab } from './Tabs.styles';

interface TabPanelProps extends BoxProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...rest } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      <Typography component='div' height='100%'>
        {children}
      </Typography>
    </Box>
  );
};

export { StyledTab as Tab };

export { Tabs };

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { OPTIONS } from 'app/shared/enum/analysis';
import {
  ChartConfigType,
  FeatureItemType,
  GroupType,
  LandscapeVisualizationType,
} from 'app/screens/Analysis/Analysis.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import {
  dataLandscapeDefaultDataPoints,
  dataLandscapeDefaultFeatures,
  Row,
  SourceDataLandscapesOptions,
  VisualizationRow,
} from './DataLandscapeSourceDataDialog';
import {
  getDefaultCustomDataPoints,
  getDefaultCustomLandscapeDataPoints,
  getDefaultCustomLandscapeFeatures,
  getDefaultSelectValue,
} from './SourceData.utils';
import { VisualisationType } from '../Configure/Configure';

type ChartSourceDataHookParams = {
  groups: GroupType[];
  visualization?: ChartConfigType;
  dataPointsCount?: number;
  sourceDataDataRow?: Row;
  sourceDataFeatureRow?: Row;
  type?: string;
};

type ChartSourceDataHook = {
  isSourceDataGroupOption: boolean;
  customDataRows: number[];
  featureX?: VisualizationRow;
  featureY?: VisualizationRow;
  feature?: VisualizationRow;
  features?: FeatureItemType[];
  dataRow?: VisualizationRow;
  featureRow?: VisualizationRow[];
  dataPoints?: any[];
  dataPointSubset?: VisualizationRow[];
  defaultDataPoints?: VisualizationRow;
  defaultCustomDataPoints?: any[];
  onDataRowChange: (value: Row) => void;
  onFeatureRowChange: (value: SetStateAction<VisualizationRow[] | undefined>) => void;
  onCustomDataRowsChange: (value: VisualizationRow[] | [] | any) => void;
  setFeature: Dispatch<SetStateAction<VisualizationRow | undefined>>;
  setFeatures: Dispatch<SetStateAction<FeatureItemType[] | undefined>>;
  setFeatureX: Dispatch<SetStateAction<VisualizationRow | undefined>>;
  setFeatureY: Dispatch<SetStateAction<VisualizationRow | undefined>>;
  sourceDataRowsGroupId: number | null | undefined;
  featuresGroupId: number | null | undefined;
};

export const useChartSourceData = ({
  visualization,
  groups,
  dataPointsCount,
  sourceDataDataRow,
  sourceDataFeatureRow,
  type,
}: ChartSourceDataHookParams): ChartSourceDataHook => {
  const [dataPointSubset, setDataPointSubset] = useState<VisualizationRow[] | undefined>(undefined);
  const [dataPoints, setDataPoints] = useState<any[] | undefined>(undefined);

  const [featureX, setFeatureX] = useState<VisualizationRow | undefined>(undefined);
  const [featureY, setFeatureY] = useState<VisualizationRow | undefined>(undefined);
  const [feature, setFeature] = useState<VisualizationRow | undefined>(undefined);
  const [features, setFeatures] = useState<FeatureItemType[] | undefined>(undefined);
  const [featureRow, setFeatureRow] = useState<VisualizationRow[] | undefined>(undefined);
  const [dataRow, setDataRow] = useState<VisualizationRow | undefined>(undefined);
  const [customDataRows, setCustomDataRows] = useState<number[]>([]);

  const rowGroupId = visualization?.rows_group_id;

  const defaultDataPoints = getDefaultSelectValue(rowGroupId, dataPointSubset, visualization);

  const defaultCustomDataPoints = getDefaultCustomDataPoints(rowGroupId, dataPoints, visualization);
  const { dataLandscapeVisualization, featureLandscapeVisualization } = useAnalysis();

  useEffect(() => {
    if (defaultCustomDataPoints) {
      const setCustomDataRowsIds = defaultCustomDataPoints.map((row) => row.id);
      setCustomDataRows(setCustomDataRowsIds);
    }
  }, [dataPoints]);

  useEffect(() => {
    if (dataPointsCount) {
      const allDataPoints = Array.from(Array(dataPointsCount).keys()).map((dataPoint) => ({
        feature_name: dataPoint.toString(),
        id: dataPoint,
      }));
      setDataPoints(allDataPoints);
    }
  }, [dataPointsCount]);

  useEffect(() => {
    const dataGroupList = groups
      .filter((group) => group.type === GroupCreationEnum.ROWS)
      .map((group) => ({
        feature_name: group.name,
        id: group.id,
      }));
    const dataOptions = dataLandscapeDefaultDataPoints.concat(dataGroupList);
    setDataPointSubset(dataOptions);
  }, []);

  const isSourceDataGroupOption =
    !(dataRow && dataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA) &&
    !(dataRow && dataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA) &&
    !(dataRow && dataRow.feature_name === SourceDataLandscapesOptions.CUSTOM);

  const isSourceDataGroupOptionDataPoints =
    !(
      sourceDataDataRow &&
      sourceDataDataRow.feature_name === SourceDataLandscapesOptions.ALL_DATA &&
      sourceDataDataRow.id === 0
    ) &&
    !(
      sourceDataDataRow &&
      sourceDataDataRow.feature_name === SourceDataLandscapesOptions.SELECTED_DATA
    ) &&
    !(sourceDataDataRow && sourceDataDataRow.feature_name === SourceDataLandscapesOptions.CUSTOM);

  const isSourceDataGroupOptionFeatures =
    !(
      sourceDataFeatureRow &&
      sourceDataFeatureRow.feature_name === SourceDataLandscapesOptions.ALL_FEATURES &&
      sourceDataFeatureRow.id === 0
    ) &&
    !(
      sourceDataFeatureRow &&
      sourceDataFeatureRow.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES
    ) &&
    !(
      sourceDataFeatureRow &&
      sourceDataFeatureRow.feature_name === SourceDataLandscapesOptions.CUSTOM
    );

  const dataLandscapeRowGroupId = dataLandscapeVisualization?.rows_group_id;
  const dataLandscapeColsGroupId = dataLandscapeVisualization?.features_group_id;
  const featureLandscapeRowGroupId = featureLandscapeVisualization?.rows_group_id;
  const featureLandscapeColsGroupId = featureLandscapeVisualization?.features_group_id;

  const sourceDataRowsGroupId = useMemo(() => {
    if (isSourceDataGroupOptionDataPoints && sourceDataDataRow) {
      return sourceDataDataRow.id;
    }
    if (!isSourceDataGroupOptionDataPoints && sourceDataDataRow) {
      return null;
    }
    if (type === VisualisationType.DATA_LANDSCAPE && dataLandscapeVisualization) {
      return dataLandscapeRowGroupId;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE && featureLandscapeVisualization) {
      return featureLandscapeRowGroupId;
    }
    return null;
  }, [sourceDataDataRow]);

  const featuresGroupId = useMemo(() => {
    if (isSourceDataGroupOptionFeatures && sourceDataFeatureRow) {
      return sourceDataFeatureRow.id;
    }
    if (!isSourceDataGroupOptionFeatures && sourceDataFeatureRow) {
      return null;
    }
    if (type === VisualisationType.DATA_LANDSCAPE && dataLandscapeVisualization) {
      return dataLandscapeColsGroupId;
    }
    if (type === VisualisationType.FEATURE_LANDSCAPE && featureLandscapeVisualization) {
      return featureLandscapeColsGroupId;
    }
    return null;
  }, [sourceDataFeatureRow]);

  const onDataRowChange = (value: Row): void => {
    setDataRow(value);
  };

  const onFeatureRowChange = (value: SetStateAction<VisualizationRow[] | undefined>): void => {
    setFeatureRow(value);
  };

  const onCustomDataRowsChange = (value: VisualizationRow[] | [] | any): void => {
    if (value.includes(OPTIONS.selectAll)) {
      setCustomDataRows([]);
    } else {
      const selectedRows = value.map((row: VisualizationRow) => row.id);
      setCustomDataRows(selectedRows);
    }
  };

  return {
    feature,
    features,
    featureX,
    featureY,
    dataPoints,
    featureRow,
    dataRow,
    customDataRows,
    dataPointSubset,
    defaultDataPoints,
    defaultCustomDataPoints,
    onDataRowChange,
    onFeatureRowChange,
    onCustomDataRowsChange,
    sourceDataRowsGroupId,
    featuresGroupId,
    isSourceDataGroupOption,
    setFeature,
    setFeatures,
    setFeatureX,
    setFeatureY,
  };
};

type LandscapeSourceDataHookParams = {
  dataRow: Row;
  featureRow: Row;
  features: FeatureItemType[];
  groups: GroupType[];
  selectedDataRows: number[];
  selectedFeatures: number[];
  visualization?: LandscapeVisualizationType;
  customDataRows?: number[];
  customFeatureRows?: number[];
  dataPointsCount?: number;
};

type LandscapeSourceDataHook = {
  isLandscapeSourceDataDisabled: boolean;
  dataPointSubset?: VisualizationRow[];
  featureSubset?: VisualizationRow[];
  dataPoints?: any[];
  dataRowValue?: Row;
  featureRowValue?: Row;
  customDataRowsValue?: VisualizationRow[];
  customFeatureRowsValue?: VisualizationRow[];
  defaultDataPointsLandscape?: VisualizationRow;
  defaultFeaturesLandscape?: VisualizationRow;
  defaultCustomDataPointsLandscape?: any[];
  defaultCustomFeaturesLandscape?: any[];
  onDataRowChange: (value: Row) => void;
  onFeatureRowChange: (value: Row) => void;
  onCustomDataRowsChange: (value: VisualizationRow[]) => void;
  onCustomFeatureRowsChange: (value: VisualizationRow[]) => void;
  setDataPointSubset: Dispatch<SetStateAction<VisualizationRow[] | undefined>>;
  setFeatureSubset: Dispatch<SetStateAction<VisualizationRow[] | undefined>>;
};

export const useLandscapeSourceData = ({
  visualization,
  dataPointsCount,
  groups,
  dataRow,
  featureRow,
  customDataRows,
  customFeatureRows,
  features,
  selectedDataRows,
  selectedFeatures,
}: LandscapeSourceDataHookParams): LandscapeSourceDataHook => {
  const [dataPointSubset, setDataPointSubset] = useState<VisualizationRow[] | undefined>(undefined);
  const [featureSubset, setFeatureSubset] = useState<VisualizationRow[] | undefined>(undefined);
  const [dataPoints, setDataPoints] = useState<any[] | undefined>(undefined);

  const [dataRowValue, setDataRowValue] = useState<Row | undefined>(undefined);
  const [featureRowValue, setFeatureRowValue] = useState<Row | undefined>(undefined);

  const [customDataRowsValue, setCustomDataRowsValue] = useState<VisualizationRow[] | undefined>(
    undefined
  );
  const [customFeatureRowsValue, setCustomFeatureRowsValue] = useState<
    VisualizationRow[] | undefined
  >(undefined);

  const rowGroupIdDataLandscape = visualization?.rows_group_id;
  const colsGroupIdDataLandscape = visualization?.features_group_id;

  const defaultDataPointsLandscape = getDefaultSelectValue(
    rowGroupIdDataLandscape,
    dataPointSubset,
    visualization,
    dataRow
  );
  const defaultFeaturesLandscape = getDefaultSelectValue(
    colsGroupIdDataLandscape,
    featureSubset,
    visualization,
    featureRow
  );
  const defaultCustomDataPointsLandscape = getDefaultCustomLandscapeDataPoints(
    rowGroupIdDataLandscape,
    dataPoints,
    visualization,
    customDataRows
  );
  const defaultCustomFeaturesLandscape = getDefaultCustomLandscapeFeatures(
    colsGroupIdDataLandscape,
    features,
    visualization,
    customFeatureRows
  );

  useEffect(() => {
    if (dataPointsCount) {
      const allDataPoints = Array.from(Array(dataPointsCount).keys()).map((dataPoint) => ({
        feature_name: dataPoint.toString(),
        id: dataPoint,
      }));
      setDataPoints(allDataPoints);
    }
  }, [dataPointsCount]);

  useEffect(() => {
    const featureGroupList = groups
      .filter((group) => group.type === GroupCreationEnum.FEATURES)
      .map((group) => ({
        feature_name: group.name,
        id: group.id,
      }));
    const featureOptions = dataLandscapeDefaultFeatures.concat(featureGroupList);
    setFeatureSubset(featureOptions);

    const dataGroupList = groups
      .filter((group) => group.type === GroupCreationEnum.ROWS)
      .map((group) => ({
        feature_name: group.name,
        id: group.id,
      }));
    const dataOptions = dataLandscapeDefaultDataPoints.concat(dataGroupList);
    setDataPointSubset(dataOptions);
  }, []);

  const onDataRowChange = (value: Row): void => {
    setDataRowValue(value);
  };

  const onFeatureRowChange = (value: Row): void => {
    setFeatureRowValue(value);
  };

  const onCustomDataRowsChange = (value: VisualizationRow[]): void => {
    setCustomDataRowsValue(value);
  };

  const onCustomFeatureRowsChange = (value: VisualizationRow[]): void => {
    setCustomFeatureRowsValue(value);
  };

  const isLandscapeSourceDataDisabled = useMemo(
    () =>
      (dataRowValue?.feature_name === SourceDataLandscapesOptions.SELECTED_DATA &&
        selectedDataRows.length === 0) ||
      (featureRowValue?.feature_name === SourceDataLandscapesOptions.SELECTED_FEATURES &&
        selectedFeatures.length === 0) ||
      (dataRowValue?.feature_name === SourceDataLandscapesOptions.CUSTOM &&
        (!customDataRowsValue || customDataRowsValue?.length === 0)) ||
      (featureRowValue?.feature_name === SourceDataLandscapesOptions.CUSTOM &&
        (!customFeatureRowsValue || customFeatureRowsValue?.length === 0)),
    [dataRowValue, featureRowValue, customFeatureRowsValue, customDataRowsValue]
  );

  return {
    dataPointSubset,
    featureSubset,
    dataPoints,
    dataRowValue,
    featureRowValue,
    customDataRowsValue,
    customFeatureRowsValue,
    defaultDataPointsLandscape,
    defaultFeaturesLandscape,
    defaultCustomDataPointsLandscape,
    defaultCustomFeaturesLandscape,
    isLandscapeSourceDataDisabled,
    onDataRowChange,
    onFeatureRowChange,
    onCustomDataRowsChange,
    onCustomFeatureRowsChange,
    setDataPointSubset,
    setFeatureSubset,
  };
};

import React, { FC, useEffect, useState } from 'react';
import Plot from 'app/shared/components/Plotly/index.js';

import { ChartDataType } from 'app/screens/Analysis/Analysis.types';
import { heatmapConfig, DEFAULT_CHART_HEIGHT, RESET_SCALE_ICON } from '../Wrappers/Chart.config';

type Props = {
  chart: ChartDataType;
  width: number;
  height?: number;
  isGenerateSvg: boolean;
  onSvgGenerate: (chart: ChartDataType) => void;
};

export const Heatmap: FC<Props> = ({
  chart,
  width,
  height = DEFAULT_CHART_HEIGHT,
  isGenerateSvg,
  onSvgGenerate,
}) => {
  const [chartLayout, setChartLayout] = useState({
    height,
    width,
  });

  const yValues = chart.features.map((feature) => feature.feature_name);
  const zValues = chart.features.map((feature) => feature.values.map((value) => Number(value)));

  useEffect(() => {
    if (isGenerateSvg && chart) {
      onSvgGenerate(chart);
    }
  }, [isGenerateSvg, chart]);

  useEffect(() => {
    setChartLayout({
      width,
      height,
    });
  }, [width, height]);

  return (
    <div className='chart-container'>
      {
        // @ts-ignore
        <Plot
          divId={`${chart.type}_${chart.id}`}
          data={[
            {
              y: yValues,
              z: zValues,
              type: 'heatmap',
              colorscale: 'Viridis',
              colorbar: {
                thickness: 11,
              },
            },
          ]}
          layout={chartLayout}
          config={{
            modeBarButtons: [
              ['blaiZoomIn', 'blaiZoomOut'],
              [
                {
                  name: 'Reset scale',
                  title: 'Reset scale',
                  icon: {
                    svg: RESET_SCALE_ICON,
                  },
                  click: () => {
                    setChartLayout({
                      height,
                      width,
                    });
                  },
                },
              ],
            ],
            doubleClick: false,
            ...heatmapConfig,
          }}
        />
      }
    </div>
  );
};

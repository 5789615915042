import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Trash2, Edit3 } from 'react-feather';
import classNames from 'classnames';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import { IconButton } from 'app/mui/IconButton';
import datasetImage from 'app/assets/images/datasets/dataset-image.svg';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { formatBytes } from 'app/shared/utils/formatBytes';
import { StatusEnum } from 'app/shared/enum/status';
import { Status } from 'app/shared/components/Status/Status';
import { Button } from 'app/mui/Button';
import { Dataset } from '../DataLibrary.types';
import { ListRow } from '../DataLibraryList/DataLibraryList.styles';
import { SelectedDataset } from '../DataLibraryList/DataLibraryList';
import { useDataLibrary } from '../DataLibrary.hooks';

type PublishedDatasetProps = {
  setSelectedDataset: Dispatch<SetStateAction<any>>;
  setIsEditDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  row: Dataset;
  selectedDataset: SelectedDataset;
  handlePreviewOpen: (id: number) => void;
  labelId: string;
  handleOpenAnalysisModal: () => void;
};

export const PublishedDataset: FC<PublishedDatasetProps> = ({
  setSelectedDataset,
  setIsEditDialogOpen,
  setIsDeleteDialogOpen,
  row,
  selectedDataset,
  handlePreviewOpen,
  labelId,
  handleOpenAnalysisModal,
}) => {
  const { showPublishedDataset } = useDataLibrary();

  const handleClickMenu = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    name: string,
    description: string
  ): void => {
    setSelectedDataset((dataset: Dataset) => ({
      ...dataset,
      id,
      name,
      description,
    }));
  };

  const onEditDialogOpen = useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);

  const openDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  const isDatasetSelected = row.id === selectedDataset?.previewId;

  return (
    <ListRow
      className={classNames({ highlight: isDatasetSelected })}
      onClick={() => handlePreviewOpen(row.id)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box borderRadius='4px' component='img' src={datasetImage} alt={row.name} />
        <Box
          sx={{
            ml: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            minWidth: 0,
          }}
        >
          <Box display='flex'>
            <Tooltip title={row.name} placement='bottom-start'>
              <Typography noWrap component='h5' variant='h5' id={labelId} className='break-text'>
                {row.name}
              </Typography>
            </Tooltip>
            <Box ml={2}>
              <Status status={showPublishedDataset(row.status) ? StatusEnum.RAW : row.status} />
            </Box>
          </Box>
          <Typography component='div' variant='body1' color='text.secondary' display='flex'>
            {formatBytes(row.dataset_metadata?.size)}
            <Typography mx={2}>&bull;</Typography>
            Updated {lastUpdated(row.updated_at)}
          </Typography>
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        <Box display='flex' marginLeft='24px'>
          <Button
            variant='outlined'
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu(event, row.id, row.name, row.description);
              handleOpenAnalysisModal();
            }}
          >
            Start Analysis
          </Button>
        </Box>
        <Box display='flex' marginLeft='24px'>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu(event, row.id, row.name, row.description);
              onEditDialogOpen();
            }}
          >
            <Edit3 />
          </IconButton>
        </Box>
        <Box display='flex' marginLeft='24px'>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu(event, row.id, row.name, row.description);
              openDeleteDialog();
            }}
          >
            <Trash2 />
          </IconButton>
        </Box>
      </Box>
    </ListRow>
  );
};

import { Dispatch, SetStateAction } from 'react';
import { AnalysisConfig, ChartDataType, Pan } from 'app/screens/Analysis/Analysis.types';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { isVisualizationCopy } from 'app/shared/utils/visualization';

type ConfigureHookParams = {
  selectedVisualization: ChartDataType | AnalysisConfig;
  analysisId: number;
  setIsNoFreeSlot: Dispatch<SetStateAction<boolean>>;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

type ConfigureHook = {
  openVisualization: () => void;
  handleOpenPopover: (event: any) => void;
  handleClose: () => void;
};

export const useConfigureForms = ({
  selectedVisualization,
  analysisId,
  setIsNoFreeSlot,
  setAnchorEl,
}: ConfigureHookParams): ConfigureHook => {
  const { panConfig, updatePanConfig, setHighlightedVisualization } = useAnalysis();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openVisualization = () => {
    handleClose();

    const isCopy = isVisualizationCopy(panConfig, selectedVisualization.id);

    const freePanel = isCopy
      ? panConfig.find((panel: Pan) => panel.id === selectedVisualization?.id)
      : panConfig.find((panel: Pan) => !panel.id);

    if (freePanel) {
      const highlightedVis = {
        id: selectedVisualization.id,
        panel: freePanel.panel,
      };

      setHighlightedVisualization(highlightedVis);
    }

    if (freePanel && !isCopy) {
      updatePanConfig(Number(analysisId), freePanel.panel, {
        id: selectedVisualization.id,
        type: selectedVisualization.type,
        coloring: selectedVisualization.config.coloring,
      });
    }
  };

  const handleOpenPopover = (event: any) => {
    setIsNoFreeSlot(false);
    const isCopy = isVisualizationCopy(panConfig, selectedVisualization.id);

    const isFreePanel = !!panConfig.find((panel: Pan) => !panel.id);

    if (!isCopy) {
      if (!isFreePanel) {
        setIsNoFreeSlot(true);
      }
      setAnchorEl(event.currentTarget);
    } else {
      openVisualization();
    }
  };

  return {
    openVisualization,
    handleOpenPopover,
    handleClose,
  };
};

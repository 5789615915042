import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import { accountReducer } from 'app/store/modules/account';
import { datasetReducer } from 'app/store/modules/dataset';
import { projectsReducer } from 'app/store/modules/project';
import { analysisReducer } from 'app/store/modules/analysis';
import { alertReducer } from 'app/store/modules/alert';

const reducers = combineReducers({
  account: accountReducer,
  dataset: datasetReducer,
  projects: projectsReducer,
  analysis: analysisReducer,
  alert: alertReducer,
});

const rootReducer = (state: any, action: any): any => reducers(state, action);

export const persistConfig = {
  key: 'bluelight',
  storage,
  blacklist: ['analysis'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<any, any, any>;
export type StoreType = typeof store;

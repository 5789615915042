import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Edit3, Trash2 } from 'react-feather';
import classNames from 'classnames';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CircularProgress } from 'app/mui/CircularProgress';
import { Tooltip } from 'app/mui/Tooltip';
import { useParams } from 'app/navigation';
import { IconButton } from 'app/mui/IconButton';
import analysisImage from 'app/assets/images/analysis/analysis-image.svg';
import {
  ListRow,
  ListRowInfo,
} from 'app/screens/DataLibrary/DataLibraryList/DataLibraryList.styles';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { ProjectAnalysis } from 'app/screens/Projects/Projects.types';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { DATASET_STATUSES } from 'app/shared/enum/dataset';
import { ANALYSIS_STATUSES } from 'app/shared/enum/analysis';
import { Button } from 'app/mui/Button';
import { SelectedAnalysis } from '../AnalysisLayout';

type AnalysisItemProps = {
  labelId: string;
  row: ProjectAnalysis;
  setSelectedAnalysis: Dispatch<SetStateAction<any>>;
  setIsEditDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  selectedAnalysis: SelectedAnalysis;
  handlePreviewOpen: (id: number) => void;
};

export const AnalysisItem: FC<AnalysisItemProps> = ({
  row,
  labelId,
  setSelectedAnalysis,
  setIsEditDialogOpen,
  setIsDeleteDialogOpen,
  selectedAnalysis,
  handlePreviewOpen,
}) => {
  const { navigateToAnalysis } = useNavigation();
  const { id } = useParams();

  const handleClickMenu = (
    event: React.MouseEvent<HTMLElement>,
    rowId: number,
    name: string,
    description: string
  ): void => {
    setSelectedAnalysis((analysis: SelectedAnalysis) => ({
      ...analysis,
      id: rowId,
      name,
      description,
    }));
  };

  const onOpen = useCallback((projectId: number, analysisId: number) => {
    navigateToAnalysis(projectId, analysisId);
  }, []);

  const onEdit = useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);

  const onDelete = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  const isAnalysisPublished =
    row.status === ANALYSIS_STATUSES.READY &&
    row.dataset.status === DATASET_STATUSES.METADATA_FINISHED;

  const isAnalysisSelected = row.id === selectedAnalysis?.previewId;

  return (
    <ListRow
      className={classNames({
        highlight: isAnalysisSelected || !isAnalysisPublished,
      })}
      onClick={() => handlePreviewOpen(row.id)}
    >
      <ListRowInfo>
        <Box borderRadius='4px' component='img' src={analysisImage} alt={row.name} />
        <Box
          sx={{
            ml: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            flexGrow: 1,
            minWidth: 0,
          }}
        >
          <Tooltip title={row.name} placement='bottom-start'>
            <Typography noWrap component='h5' variant='h5' id={labelId} className='break-text'>
              {row.name}
            </Typography>
          </Tooltip>
          <Typography component='div' variant='body1' color='text.secondary' display='flex'>
            <Typography noWrap>{row.dataset.name}</Typography>
            <Typography mx={2}>&bull;</Typography>
            <Typography flexShrink={0}>Updated {lastUpdated(row.updated_at)}</Typography>
          </Typography>
        </Box>
      </ListRowInfo>
      <Box display='flex' alignItems='center'>
        {!isAnalysisPublished && (
          <>
            <Typography mr={2} variant='caption'>
              Processing...
            </Typography>
            <Tooltip title='Processing' placement='bottom-start'>
              <Box px={1}>
                <CircularProgress />
              </Box>
            </Tooltip>
          </>
        )}
        <Box display='flex' marginLeft='24px'>
          <Button
            variant='outlined'
            onClick={(event) => {
              event.stopPropagation();
              onOpen(Number(id), row.id);
            }}
          >
            Explore Analysis
          </Button>
        </Box>
        {isAnalysisPublished && (
          <Box display='flex' marginLeft='24px'>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleClickMenu(event, row.id, row.name, row.description);
                onEdit();
              }}
            >
              <Edit3 />
            </IconButton>
          </Box>
        )}
        <Box display='flex' marginLeft='24px'>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleClickMenu(event, row.id, row.name, row.description);
              onDelete();
            }}
          >
            <Trash2 />
          </IconButton>
        </Box>
      </Box>
    </ListRow>
  );
};

import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { StyledCloseIcon, VisualizationDialogWrapper } from 'app/shared/styles/createForm';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { ChartConfigType } from 'app/screens/Analysis/Analysis.types';
import { useParams } from 'app/navigation';
import { CustomSubsetSelect } from 'app/shared/components/CustomSubsetSelect/CustomSubsetSelect';
import {
  SourceDataLandscapesOptions,
  VisualizationRow,
} from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { useChartSourceData } from './SourceData.hooks';
import { mapperMatrixConfigRows } from './SourceData.utils';
import { SourceDataSearchSelect } from './SourceDataSearchSelect';
import { VisualisationType } from '../Configure/Configure';

type ScatterPlotSourceDataDialogProps = {
  openDialog: boolean;
  handleSave: (data: ChartConfigType) => void;
  handleClose: () => void;
  type: string;
  visualization: ChartConfigType;
};

export const ScatterPlotSourceDataDialog: FC<ScatterPlotSourceDataDialogProps> = ({
  openDialog,
  handleClose,
  type,
  handleSave,
  visualization,
}) => {
  const { analysisId } = useParams();

  const { features, dataPointsCount, groups, selectedDataRows, getGroup } = useAnalysis();

  const {
    dataPointSubset,
    dataPoints,
    featureX,
    featureY,
    dataRow,
    customDataRows,
    defaultDataPoints,
    defaultCustomDataPoints,
    setFeatureX,
    setFeatureY,
    onDataRowChange,
    onCustomDataRowsChange,
    isSourceDataGroupOption,
  } = useChartSourceData({
    visualization,
    groups,
    dataPointsCount,
  });

  const defaultFeatureX = features?.find(
    (feature) => feature?.id === visualization?.feature_ids[0]
  );

  const defaultFeatureY = features?.find(
    (feature) => feature?.id === visualization?.feature_ids[1]
  );

  const visualizationFeatures = (): number[] | [] => {
    if (featureX && featureY) {
      return [featureX.id, featureY.id];
    }
    return [];
  };

  const handleSaveSourceData = async (): Promise<void> => {
    if (dataPointsCount) {
      const data_points = await mapperMatrixConfigRows(
        dataRow,
        customDataRows,
        Number(analysisId),
        selectedDataRows,
        getGroup,
        dataPointsCount
      );

      if (dataRow) {
        handleSave({
          data_points: data_points as any[],
          feature_ids: visualizationFeatures(),
          chart_type: type as VisualisationType,
          data_filters: [] as any, // TODO: Should be filter data
          rows_group_id: isSourceDataGroupOption ? dataRow.id : null,
          coloring: visualization?.coloring || undefined,
        });
      }
    }

    handleClose();
  };

  const isSaveDisabled =
    !featureX ||
    !featureY ||
    (dataRow?.feature_name === SourceDataLandscapesOptions.SELECTED_DATA &&
      selectedDataRows.length === 0) ||
    (dataRow?.feature_name === SourceDataLandscapesOptions.CUSTOM && customDataRows.length === 0);

  return (
    <VisualizationDialogWrapper
      open={openDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box width='841px'>
        <Box p={6}>
          <Box display='flex' justifyContent='space-between'>
            <Typography component='h5' variant='h5'>
              Modify Scatter Plot Source Data
            </Typography>
            <StyledCloseIcon onClick={handleClose} size={24} />
          </Box>
          {dataPointSubset && dataPoints && features && (
            <>
              <Box mt={3} display='flex' justifyContent='space-between' gap='24px'>
                <SourceDataSearchSelect
                  rows={features}
                  rowLabel='Feature x'
                  onRowChange={(value) => setFeatureX(value)}
                  defaultValue={defaultFeatureX}
                />
                <SourceDataSearchSelect
                  rows={features}
                  rowLabel='Feature y'
                  onRowChange={(value) => setFeatureY(value)}
                  defaultValue={defaultFeatureY}
                />
              </Box>
              <Box mt={3}>
                <CustomSubsetSelect
                  rows={dataPoints}
                  onRowsChange={(value: VisualizationRow[]) => onCustomDataRowsChange(value)}
                  label='Data points'
                  defaultValues={defaultCustomDataPoints}
                  showSelect={dataRow?.feature_name === SourceDataLandscapesOptions.CUSTOM}
                  maxHeight={210}
                >
                  <SourceDataSearchSelect
                    rows={dataPointSubset}
                    rowLabel='Data points subset'
                    onRowChange={(value) => onDataRowChange(value)}
                    defaultValue={defaultDataPoints}
                  />
                </CustomSubsetSelect>
              </Box>
            </>
          )}

          <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSaveSourceData} variant='contained' disabled={isSaveDisabled}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </VisualizationDialogWrapper>
  );
};

import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';

export const Plus = styled(Box)(() => ({
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '4px',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SelectionButton = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const Line = styled(Box)(({ theme }) => ({
  height: '23px',
  margin: theme.spacing(0, 2),
  width: '1px',
  background: theme.palette.action.disabled,
  [theme.breakpoints.down('desktopLarge')]: {
    margin: theme.spacing(0, 1),
  },
}));

export const Typography = styled(MuiTypography)(({ theme }) => ({
  '&.count': {
    fontWeight: '600',
  },
  '&.name': {
    color: theme.palette.text.secondary,
    fontWeight: '400',
  },

  [theme.breakpoints.down('desktopLarge')]: {
    '&.count': {
      fontSize: '1.2rem',
    },
    '&.name': {
      fontSize: '1.2rem',
    },
  },
}));

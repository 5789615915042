import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: '600',
  textTransform: 'none',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: '48px',
  transition: 'color .3s, background-color .3s',

  [theme.breakpoints.up('tablet')]: {
    marginRight: theme.spacing(3),
    '&:last-child': {
      marginRight: '0',
    },
  },

  [theme.breakpoints.down('desktopLarge')]: {
    '&.explorerTab': {
      marginRight: theme.spacing(1),
      width: 80,
      minWidth: 80,
    },
  },

  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.state.hoverBackground,
    '.MuiTab-iconWrapper': {
      color: theme.palette.text.primary,
    },
  },
  '&.Mui-selected': {
    '.MuiTab-iconWrapper': {
      color: theme.palette.text.primary,
    },
  },
  '&.Mui-disabled': {
    '.MuiTab-iconWrapper': {
      color: theme.palette.text.disabled,
    },
  },
  '.MuiTab-iconWrapper': {
    margin: `0 0 0 ${theme.spacing(1)}`,
    backgroundColor: theme.palette.grey.A100,
    height: '24px',
    minWidth: '34px',
    padding: '0 5px',
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '1.4rem',
    lineHeight: '24px',
    fontWeight: '700',
    color: theme.palette.text.secondary,
  },
}));

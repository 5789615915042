import { mapValues } from 'lodash';
import { useCallback, useRef } from 'react';
import Rand from 'rand-seed';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { LOADING_STATE } from 'app/store/constants';
import { useAlert } from 'app/shared/hooks/useAlert';
import { categoricalMaps } from 'app/shared/styles/categoricalMaps';
import {
  fetchAnalyses,
  fetchAnalysisPreview,
  fetchCurrentAnalysis,
  fetchFeatureList,
  fetchGroups,
  setComparativeStatistics,
  setDescriptiveStatistics,
  resetAnalysisState,
  retrieveSelectedVisualizationChart,
  retrieveSelectedVisualizationGraph,
  selectAnalysis,
  selectAnalysisLayout,
  selectAnalysisLoading,
  selectAnalysisPreview,
  selectChartConfig,
  selectComparativeStatistics,
  selectCurrentAnalysis,
  selectCurrentAnalysisDataset,
  selectDataLandscapePartition,
  selectDataLandscapeVisualization,
  selectDataLandscapeVisualizations,
  selectDataPointsCount,
  selectDataPointsSelectionSource,
  selectDataRowsTotal,
  selectDefaultDataLandscapeConfig,
  selectDefaultFeatureLandscapeConfig,
  selectDescriptiveStatistics,
  selectFeatureLandscapePartition,
  selectFeatureLandscapeVisualization,
  selectFeatureLandscapeVisualizations,
  selectFeatureRowsTotal,
  selectFeatures,
  selectFeaturesSelectionSource,
  selectGroups,
  selectHeatmapVisualization,
  selectHeatmapVisualizations,
  selectHighlightedVisualization,
  selectHistogramVisualization,
  selectHistogramVisualizations,
  selectLastCreatedGroup,
  selectPanConfig,
  selectScatterPlotVisualization,
  selectScatterPlotVisualizations,
  selectSelectedDataExplorerFilters,
  selectSelectedDataExplorerRows,
  selectSelectedFeatureExplorerFilters,
  selectSelectedFeatureExplorerRows,
  selectViolinPlotVisualization,
  selectViolinPlotVisualizations,
  setAnalysisLayout,
  setCreatedExplorerGroup,
  setCurrentAnalysis,
  setDataLandscapes,
  setDataPointsSelectionSource,
  setDataRowsTotal,
  setFeatureLandscapes,
  setFeatureRowsTotal,
  setFeatureSelectionSource,
  setPanConfig,
  setSelectedChartVisualization,
  setSelectedDataExplorerFilters,
  setSelectedDataExplorerRows,
  setSelectedFeatureExplorerFilters,
  setSelectedFeatureExplorerRows,
  setSelectedGraphVisualization,
  setVisualizationHighlighted,
} from 'app/store/modules/analysis';
import type {
  ExplorerBodyType,
  ExplorerFilterType,
  ExplorerParams,
} from 'app/screens/Analysis/Explorer/Explorer.types';
import { COLORING_OPTIONS } from 'app/shared/enum/analysis';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { groupCreationJson } from './Analysis.utils';
import { CategoricalColorMaps, ChartColoringType } from './Coloring/Coloring.types';

import {
  AggregationType,
  AnalysisConfig,
  AnalysisPayload,
  AnalysisType,
  CategoricalDataColoringFunction,
  ChartConfigType,
  ChartDataType,
  ColoringData,
  ColoringRequestParams,
  COLORMAP_TYPE,
  ComparativeStatistics,
  ComparativeStatisticsPayload,
  ConfigType,
  CreateChartType,
  CreateGraphType,
  DataColoringFunction,
  DataColoringResponseFunction,
  DataColoringResponseType,
  DataColoringType,
  DataExplorerType,
  DescriptiveStatistics,
  DescriptiveStatisticsPayload,
  EditChartType,
  EditGraphType,
  exportCSVType,
  exportJSONType,
  FeatureColoringType,
  FeatureExplorerType,
  FeatureItemType,
  FeatureType,
  FilterType,
  GroupType,
  HighlightedVisualizationType,
  LandscapeData,
  LandscapeGraphPayload,
  LandscapeVisualizationType,
  newGroupType,
  NormalizationType,
  Pan,
  PanConfig,
  PanConfigResponse,
  RawDataColoringResponseType,
  RetrieveGraphType,
  SelectedFeatureType,
  SELECTION_SOURCES,
  SelectionSourceType,
} from './Analysis.types';

import {
  coloringConfig,
  colorMap,
  DEFAULT_NODE_COLOR,
  DEFAULT_NODE_SIZE,
  getColorMap,
  getDataColoringBounds,
  getDivergingColoringBound,
  getNodeCategoricalColors,
  getNodeColors,
  getPrescaledColorDataValues,
} from './Coloring/Coloring.utils';
import { Dataset } from '../DataLibrary/DataLibrary.types';

type GroupParams = {
  analysisId: number;
  name: string;
  group_type: string;
  rows: { index: number; has_missing: boolean }[];
  filters: ExplorerFilterType[];
};

interface IUseAnalysis {
  layout: string;
  loading: LOADING_STATE;
  analysis: AnalysisType[];
  currentAnalysis: AnalysisType;
  analysisPreview: AnalysisType;
  totalRows: number;
  dataPointsCount: number | undefined;
  totalFeatures: number;
  selectedDataRows: number[];
  selectedFeatures: number[];
  selectedGraph: AnalysisConfig;
  selectedChart: ChartDataType;
  highlightedVisualization: HighlightedVisualizationType;
  panConfig: PanConfig;
  selectedDataFilters: ExplorerFilterType[];
  selectedFeatureFilters: ExplorerFilterType[];
  defaultDataLandscapeConfig: ConfigType;
  defaultFeatureLandscapeConfig: ConfigType;
  chartConfig: Record<string, any>;
  dataLandscapeVisualization: LandscapeVisualizationType;
  dataLandscapePartition: FilterType[];
  featureLandscapeVisualization: LandscapeVisualizationType;
  featureLandscapePartition: FilterType[];
  scatterPlotVisualization: ChartConfigType | undefined;
  heatmapVisualization: ChartConfigType | undefined;
  violinPlotVisualization: ChartConfigType | undefined;
  histogramVisualization: ChartConfigType | undefined;
  deleteVisualization: (analysisId: number, id: number) => void;
  features: FeatureItemType[];
  setAnalysis: (analysis: AnalysisType) => void;
  updateLandscape: (id: number, type: VisualisationType, data: Partial<AnalysisConfig>) => void;
  updateLandscapeConfig: (id: number, type: VisualisationType, params: Partial<ConfigType>) => void;
  setLayout: (type: string) => void;
  setSelectedDataRows: (rows: number[]) => void;
  setSelectedFeatures: (rows: number[]) => void;
  setSelectedGraph: (visualization?: AnalysisConfig) => void;
  setSelectedChart: (visualization?: ChartDataType) => void;
  setHighlightedVisualization: (visualization?: HighlightedVisualizationType) => void;
  setSelectedDataFilters: (data: ExplorerFilterType[]) => void;
  setSelectedFeatureFilters: (data: ExplorerFilterType[]) => void;
  resetAnalysis: () => void;
  getDataExplorer: (
    id: number,
    filters?: ExplorerBodyType,
    params?: ExplorerParams
  ) => Promise<unknown>;
  getFeatureExplorer: (
    id: number,
    filters?: ExplorerBodyType,
    params?: ExplorerParams
  ) => Promise<unknown>;
  getFeaturesList: (id: number) => void;
  getAnalyses: () => void;
  getAnalysis: (id: number) => void;
  getAnalysisPreview: (id: number) => void;
  deleteAnalysis: (id: number, onAnalysisDeleted?: () => void) => void;
  createAnalysis: (project_id: number, dataset_id: number, name: string) => Promise<AnalysisType>;
  editAnalysis: (data: AnalysisPayload) => void;
  ingestDataset: (project_id: number, dataset_id: number, analysis_id: number) => void;
  createGroup: ({ analysisId, name, group_type, rows, filters }: GroupParams) => void;
  deleteGroup: (analysisId: number, group: GroupType) => void;
  getGroups: (id: number) => void;
  groups: GroupType[];
  getGroup: (analysis_id: number, group_id: number) => Promise<unknown>;
  setLastCreatedGroup: (group: newGroupType | undefined) => void;
  lastCreatedGroup: newGroupType | undefined;
  createGraph: (graph: CreateGraphType) => Promise<unknown>;
  editGraph: (graph: EditGraphType) => Promise<unknown>;
  getMetadata: (analysisId: number) => Promise<unknown>;
  retrieveGraph: (analysisId: number, visualisationIds?: string) => Promise<unknown>;
  addChart: (analysisId: number, data: CreateChartType) => Promise<ChartDataType>;
  editChart: (
    analysisId: number,
    visualizationId: number,
    data: EditChartType
  ) => Promise<ChartDataType>;
  getChart: (analysisId: number, visualisationIds?: string) => Promise<unknown>;
  setChart: (type: string, data: ChartDataType) => void;
  exportCSV: (csvData: exportCSVType) => void;
  exportJSON: (jsonData: exportJSONType) => void;
  getDataColoring: (
    feature: SelectedFeatureType,
    aggregationType: AggregationType,
    normalizationType: NormalizationType
  ) => Promise<ColoringData | undefined>;
  getDataDiffColoring: (
    coloringFeatures: SelectedFeatureType[],
    aggregationType: AggregationType,
    normalizationType: NormalizationType
  ) => Promise<ColoringData | undefined>;
  getFeatureColoring: (
    feature: SelectedFeatureType,
    aggregationType: AggregationType,
    normalizationType: NormalizationType,
    mapperMatrixConfigCols: number[]
  ) => Promise<ColoringData>;
  getFeatureDiffColoring: (
    coloringFeatures: SelectedFeatureType[],
    aggregationType: AggregationType,
    normalizationType: NormalizationType,
    mapperMatrixConfigCols: number[]
  ) => Promise<ColoringData | undefined>;
  getGraphLayoutData: (data: LandscapeGraphPayload) => LandscapeData;
  getChartLayoutColoredData: (
    data: ChartDataType,
    coloringData: ColoringData<DataColoringFunction>
  ) => ChartColoringType;
  getChartLayoutCategoricalColoring: (
    data: ChartDataType,
    coloringData: ColoringData<CategoricalDataColoringFunction>,
    colorMapType: CategoricalColorMaps
  ) => ChartColoringType;
  getGraphLayoutColoredData: (
    data: LandscapeData,
    coloringData: ColoringData<DataColoringFunction>,
    isFeatureLandscape?: boolean
  ) => LandscapeData;
  getGraphLayoutCategoricalColoring: (
    data: LandscapeData,
    coloringData: ColoringData,
    colorMapType: CategoricalColorMaps,
    isFeatureLandscape: boolean
  ) => LandscapeData;
  setDataSelectionSource: (data: SelectionSourceType | null) => void;
  setFeaturesSelectionSource: (data: SelectionSourceType | null) => void;
  featureSelectionSource: SelectionSourceType | null;
  dataPointsSelectionSource: SelectionSourceType | null;
  currentAnalysisDataset: Dataset;
  dataLandscapeVisualizations: AnalysisConfig[];
  featureLandscapeVisualizations: AnalysisConfig[];
  scatterPlotVisualizations: ChartDataType[] | undefined;
  histogramVisualizations: ChartDataType[] | undefined;
  heatmapVisualizations: ChartDataType[] | undefined;
  violinPlotVisualizations: ChartDataType[] | undefined;
  isDefaultColoring: (feature: SelectedFeatureType) => boolean;
  updatePanConfig: (analysisId: number, panelId: string, pan?: Pan) => Promise<unknown>;
  reloadVisualization: (visualizationId: number, shouldReload: boolean) => void;
  setPanConfigToStore: (config: PanConfigResponse) => void;
  removePanelError: (panelId: string) => void;
  isFeatureWithMissingValues: (
    userSelectedFeatures: number[],
    allFeatures: FeatureItemType[]
  ) => boolean;
  clearSelection: (id?: number, type?: VisualisationType) => void;
  getComparativeStatistics: (payload: ComparativeStatisticsPayload) => Promise<void>;
  comparativeStatistics: ComparativeStatistics | null;
  getDescriptiveStatistics: (payload: DescriptiveStatisticsPayload) => Promise<void>;
  descriptiveStatistics: DescriptiveStatistics | null;
}

export const EXPLORER_ROW_SIZE = 100;
export const EXPLORER_ROW_WIDTH = 120;
export const EXPLORER_ROW_HEIGHT = 35;

const EXPLORER_PAGE_INDEX = 1;

const SELECTED_FUNCTION_NAME = 'selected';

const { alwaysUseSequential } = coloringConfig;

export const useAnalysis = (): IUseAnalysis => {
  const { showSuccessMessage } = useAlert();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectAnalysisLoading);
  const layout = useAppSelector(selectAnalysisLayout);
  const analysis = useAppSelector(selectAnalysis);
  const currentAnalysis = useAppSelector(selectCurrentAnalysis);
  const totalRows = useAppSelector(selectDataRowsTotal);
  const dataPointsCount = useAppSelector(selectDataPointsCount);
  const totalFeatures = useAppSelector(selectFeatureRowsTotal);
  const selectedDataRows = useAppSelector(selectSelectedDataExplorerRows);
  const selectedFeatures = useAppSelector(selectSelectedFeatureExplorerRows);
  const selectedGraph = useAppSelector(retrieveSelectedVisualizationGraph);
  const selectedChart = useAppSelector(retrieveSelectedVisualizationChart);
  const highlightedVisualization = useAppSelector(selectHighlightedVisualization);
  const selectedDataFilters = useAppSelector(selectSelectedDataExplorerFilters);
  const selectedFeatureFilters = useAppSelector(selectSelectedFeatureExplorerFilters);
  const groups = useAppSelector(selectGroups);
  const lastCreatedGroup = useAppSelector(selectLastCreatedGroup);
  const defaultDataLandscapeConfig = useAppSelector(selectDefaultDataLandscapeConfig);
  const defaultFeatureLandscapeConfig = useAppSelector(selectDefaultFeatureLandscapeConfig);
  const dataLandscapeVisualization = useAppSelector(selectDataLandscapeVisualization);
  const dataLandscapePartition = useAppSelector(selectDataLandscapePartition);
  const featureLandscapeVisualization = useAppSelector(selectFeatureLandscapeVisualization);
  const featureLandscapePartition = useAppSelector(selectFeatureLandscapePartition);
  const scatterPlotVisualization = useAppSelector(selectScatterPlotVisualization);
  const heatmapVisualization = useAppSelector(selectHeatmapVisualization);
  const violinPlotVisualization = useAppSelector(selectViolinPlotVisualization);
  const histogramVisualization = useAppSelector(selectHistogramVisualization);
  const chartConfig = useAppSelector(selectChartConfig);
  const features = useAppSelector(selectFeatures);
  const featureSelectionSource = useAppSelector(selectFeaturesSelectionSource);
  const dataPointsSelectionSource = useAppSelector(selectDataPointsSelectionSource);
  const currentAnalysisDataset = useAppSelector(selectCurrentAnalysisDataset);
  const dataLandscapeVisualizations = useAppSelector(selectDataLandscapeVisualizations);
  const featureLandscapeVisualizations = useAppSelector(selectFeatureLandscapeVisualizations);
  const heatmapVisualizations = useAppSelector(selectHeatmapVisualizations);
  const histogramVisualizations = useAppSelector(selectHistogramVisualizations);
  const scatterPlotVisualizations = useAppSelector(selectScatterPlotVisualizations);
  const violinPlotVisualizations = useAppSelector(selectViolinPlotVisualizations);
  const analysisPreview = useAppSelector(selectAnalysisPreview);
  const panConfig = useAppSelector(selectPanConfig);
  const comparativeStatistics = useAppSelector(selectComparativeStatistics);
  const descriptiveStatistics = useAppSelector(selectDescriptiveStatistics);

  const dataColoringAbortSignal = useRef<AbortController | null>(null);
  const featureColoringAbortSignal = useRef<AbortController | null>(null);
  const comparativeStatisticsAbortSignal = useRef<AbortController | null>(null);
  const descriptiveStatisticsAbortSignal = useRef<AbortController | null>(null);

  const setLayout = useCallback(
    (type: string) => {
      dispatch(setAnalysisLayout(type));
    },
    [dispatch]
  );

  const setSelectedDataRows = useCallback(
    (row: number[]) => {
      dispatch(setSelectedDataExplorerRows(row));
    },
    [dispatch]
  );

  const setSelectedGraph = useCallback(
    (visualization?: AnalysisConfig) => {
      dispatch(setSelectedGraphVisualization(visualization));
    },
    [dispatch]
  );

  const setSelectedChart = useCallback(
    (visualization?: ChartDataType) => {
      dispatch(setSelectedChartVisualization(visualization));
    },
    [dispatch]
  );

  const setHighlightedVisualization = useCallback(
    (visualization?: HighlightedVisualizationType) => {
      dispatch(setVisualizationHighlighted(visualization));
    },
    [dispatch]
  );

  const setLastCreatedGroup = useCallback(
    (group: newGroupType | undefined) => {
      dispatch(setCreatedExplorerGroup(group));
    },
    [dispatch]
  );

  const setSelectedFeatures = useCallback(
    (row: number[]) => {
      dispatch(setSelectedFeatureExplorerRows(row));
    },
    [dispatch]
  );

  const setSelectedDataFilters = useCallback(
    (data: ExplorerFilterType[]) => {
      dispatch(setSelectedDataExplorerFilters(data));
    },
    [dispatch]
  );

  const setSelectedFeatureFilters = useCallback(
    (data: ExplorerFilterType[]) => {
      dispatch(setSelectedFeatureExplorerFilters(data));
    },
    [dispatch]
  );

  const setDataSelectionSource = useCallback(
    (data: SelectionSourceType | null) => {
      dispatch(setDataPointsSelectionSource(data));
    },
    [dispatch]
  );

  const setFeaturesSelectionSource = useCallback(
    (data?: SelectionSourceType | null) => {
      dispatch(setFeatureSelectionSource(data));
    },
    [dispatch]
  );

  const resetAnalysis = useCallback(() => {
    dispatch(resetAnalysisState());
  }, [dispatch]);

  const setAnalysis = useCallback(
    (analysisType: AnalysisType) => {
      dispatch(setCurrentAnalysis(analysisType));

      dispatch(setDataLandscapes(analysisType.config.visual.data_landscape));
      dispatch(setFeatureLandscapes(analysisType.config.visual.feature_landscape));
    },
    [dispatch]
  );

  const updateLandscape = useCallback(
    (id: number, type: VisualisationType, data: Partial<AnalysisConfig>) => {
      const isDataLandscape = type === VisualisationType.DATA_LANDSCAPE;
      const list = isDataLandscape ? dataLandscapeVisualizations : featureLandscapeVisualizations;
      const setLandscapes = isDataLandscape ? setDataLandscapes : setFeatureLandscapes;

      const updatedList = list.map((item) => (item.id === id ? { ...item, ...data } : item));

      dispatch(setLandscapes(updatedList));
    },
    [dispatch, dataLandscapeVisualizations, featureLandscapeVisualizations]
  );

  const updateLandscapeConfig = useCallback(
    (id: number, type: VisualisationType, params: Partial<ConfigType>) => {
      const isDataLandscape = type === VisualisationType.DATA_LANDSCAPE;
      const list = isDataLandscape ? dataLandscapeVisualizations : featureLandscapeVisualizations;
      const setLandscapes = isDataLandscape ? setDataLandscapes : setFeatureLandscapes;

      const updatedList = list.map((item) =>
        item.id === id ? { ...item, config: { ...item.config, ...params } } : item
      );

      dispatch(setLandscapes(updatedList));
    },
    [dispatch, dataLandscapeVisualizations, featureLandscapeVisualizations]
  );

  const getDataExplorer = useCallback(
    async (id: number, filters?: ExplorerBodyType, params?: ExplorerParams) => {
      const rows_size = params?.rows_size || EXPLORER_ROW_SIZE;
      const rows_page_index = params?.rows_page_index || EXPLORER_PAGE_INDEX;
      const columns_size = params?.columns_size || EXPLORER_ROW_SIZE;
      const columns_page_index = params?.columns_page_index || EXPLORER_PAGE_INDEX;
      const body: ExplorerBodyType = {
        data_filters: [],
        ...(filters || {}),
      };

      const response: DataExplorerType = await api
        .post(`analysis/${id}/data-explorer`, {
          headers: getCommonHeaders(),
          json: body,
          searchParams: {
            rows_size,
            rows_page_index,
            columns_size,
            columns_page_index,
          },
        })
        .json();

      dispatch(setDataRowsTotal(response.rows_total));

      return response;
    },
    []
  );

  const getFeatureExplorer = useCallback(
    async (id: number, filters?: ExplorerBodyType, params?: any) => {
      const rows_size = params?.rows_size || EXPLORER_ROW_SIZE;
      const rows_page_index = params?.rows_page_index || EXPLORER_PAGE_INDEX;

      const searchParams = {
        rows_size,
        rows_page_index,
      };

      const body: ExplorerBodyType = {
        data_filters: [],
        ...(filters || {}),
      };

      const response: FeatureExplorerType = await api
        .post(`analysis/${id}/feature-explorer`, {
          headers: getCommonHeaders(),
          json: body,
          searchParams,
        })
        .json();

      dispatch(setFeatureRowsTotal(response.rows_total));

      return response;
    },
    []
  );

  const getFeaturesList = useCallback(
    async (id: number) => {
      dispatch(fetchFeatureList(id));
    },
    [dispatch]
  );

  const getAnalyses = useCallback(() => {
    dispatch(fetchAnalyses());
  }, [dispatch]);

  const getAnalysis = useCallback(
    async (id: number) => {
      dispatch(fetchCurrentAnalysis(id));
    },
    [dispatch]
  );

  const getAnalysisPreview = useCallback(
    async (id: number) => {
      dispatch(fetchAnalysisPreview(id));
    },
    [dispatch]
  );

  const onDeleteAnalysis = useCallback(async (id: number, onAnalysisDeleted?: () => void) => {
    try {
      await api
        .delete(`analysis/?ids=${id}`, {
          headers: getCommonHeaders(),
        })
        .json();

      if (onAnalysisDeleted) {
        onAnalysisDeleted();
      }

      dispatch(setCurrentAnalysis(undefined));

      showSuccessMessage({
        title: 'Analysis deleted',
      });
    } catch (error) {
      return error;
    }
  }, []);

  const onCreateAnalysis = useCallback(
    async (project_id: number, dataset_id: number, name: string): Promise<AnalysisType> => {
      try {
        const response: AnalysisType = await api
          .post(`analysis/${project_id}`, {
            headers: getCommonHeaders(),
            json: {
              dataset_id,
              name,
            },
          })
          .json();

        showSuccessMessage({
          title: 'Creating Analysis',
        });

        return response;
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const getGroups = useCallback((analysisId: number) => {
    dispatch(fetchGroups(analysisId));
  }, []);

  const getGroup = useCallback(async (analysis_id: number, group_id: number) => {
    const response = await api
      .get(`analysis/${analysis_id}/group/${group_id}`, {
        headers: getCommonHeaders(),
      })
      .json();

    return response;
  }, []);

  const onEditAnalysis = useCallback(async (data: AnalysisPayload) => {
    try {
      const { id, name, description } = data;

      await api
        .put(`analysis/${id}`, {
          headers: getCommonHeaders(),
          json: {
            name,
            description,
          },
        })
        .json();

      showSuccessMessage({
        title: 'Analysis edited',
      });
    } catch (error) {
      return error;
    }
  }, []);

  const onIngestDataset = useCallback(
    async (project_id: number, dataset_id: number, analysis_id: number) => {
      try {
        await api
          .post(
            `project/${project_id}/analysis/${analysis_id}/dataset/${dataset_id}/ingest-dataset`,
            {
              headers: getCommonHeaders(),
            }
          )
          .json();
      } catch (error) {
        return error;
      }
    },
    []
  );

  const onDeleteVisualization = useCallback(async (analysisId: number, id: number) => {
    try {
      await api
        .delete(`analysis/${analysisId}/visualisations?ids=${id}`, {
          headers: getCommonHeaders(),
        })
        .json();

      await getAnalysis(analysisId);

      showSuccessMessage({
        title: 'Visualization deleted',
      });
    } catch (error) {
      return error;
    }
  }, []);

  const onCreateGroup = useCallback(
    async ({ analysisId, name, group_type, rows, filters }: GroupParams) => {
      try {
        await api
          .post(`analysis/${analysisId}/group`, {
            headers: getCommonHeaders(),
            json: groupCreationJson(name, group_type, rows, filters),
          })
          .json();

        getGroups(analysisId);

        const createdGroup = {
          name,
          type: group_type,
        };
        setLastCreatedGroup(createdGroup);

        showSuccessMessage({
          title: 'Group created',
        });
      } catch (error) {
        return error;
      }
    },
    []
  );

  const onDeleteGroup = useCallback(
    async (analysisId: number, group: GroupType) => {
      try {
        await api
          .delete(`analysis/${analysisId}/group?ids=${group.id}`, {
            headers: getCommonHeaders(),
          })
          .json();

        getGroups(analysisId);

        const shouldClearDataExplorer =
          dataPointsSelectionSource &&
          dataPointsSelectionSource.source === SELECTION_SOURCES.GROUP &&
          group.type === GroupCreationEnum.ROWS;

        const shouldClearFeatureExplorer =
          featureSelectionSource &&
          featureSelectionSource.source === SELECTION_SOURCES.GROUP &&
          group.type === GroupCreationEnum.FEATURES;

        if (shouldClearDataExplorer) {
          setSelectedDataRows([]);
          setDataSelectionSource(null);
        } else if (shouldClearFeatureExplorer) {
          setSelectedFeatures([]);
          setFeaturesSelectionSource(null);
        }

        showSuccessMessage({
          title: 'Group deleted',
        });
      } catch (error) {
        return error;
      }
    },
    [dataPointsSelectionSource, featureSelectionSource]
  );

  const createGraph = useCallback(async (graph: CreateGraphType) => {
    try {
      const response = await api
        .post('external/commands/create-graph', {
          headers: getCommonHeaders(),
          json: graph,
        })
        .json();

      showSuccessMessage({
        title: 'Visualization has been created',
      });

      return response;
    } catch (error) {
      return error;
    }
  }, []);

  const editGraph = useCallback(async (graph: EditGraphType) => {
    try {
      const { analysis_id } = graph;

      const response = await api
        .put('external/commands/graph', {
          headers: getCommonHeaders(),
          json: graph,
        })
        .json();

      await getAnalysis(analysis_id);

      showSuccessMessage({
        title: 'Visualization has been edited',
      });

      return response;
    } catch (error) {
      return error;
    }
  }, []);

  const getMetadata = useCallback(async (analysisId: number) => {
    try {
      const response = await api
        .get(`external/queries/get-metadata`, {
          headers: getCommonHeaders(),
          searchParams: {
            analysis_id: analysisId,
          },
        })
        .json();

      return response;
    } catch (error) {
      return error;
    }
  }, []);

  const retrieveGraph = useCallback(async (analysisId: number, visualisationIds?: string) => {
    try {
      const response = await api
        .get(`external/queries/retrieve-graph`, {
          headers: getCommonHeaders(),
          searchParams: visualisationIds
            ? {
                analysis_id: analysisId,
                visualisation_ids: visualisationIds,
              }
            : {
                analysis_id: analysisId,
              },
        })
        .json();

      return response as RetrieveGraphType[];
    } catch (error) {
      return error;
    }
  }, []);

  const addChart = useCallback(
    async (analysisId: number, data: CreateChartType): Promise<ChartDataType> => {
      try {
        const response = (await api
          .post(`analysis/${analysisId}/add-chart`, {
            headers: getCommonHeaders(),
            json: data,
          })
          .json()) as ChartDataType;

        showSuccessMessage({
          title: 'Visualization created',
        });

        return response;
      } catch (error: any) {
        return error;
      }
    },
    []
  );

  const editChart = useCallback(
    async (
      analysisId: number,
      visualizationsId: number,
      data: EditChartType
    ): Promise<ChartDataType> => {
      try {
        const { name, config } = data;

        setSelectedChart({ ...selectedChart, name, config: config as ChartConfigType });

        const response = (await api
          .put(`analysis/${analysisId}/visualisation/${visualizationsId}/chart`, {
            headers: getCommonHeaders(),
            json: data,
          })
          .json()) as ChartDataType;

        showSuccessMessage({
          title: 'Visualization has been edited',
        });

        return response;
      } catch (error) {
        throw error;
      }
    },
    []
  );

  const getChart = useCallback(async (analysisId: number, visualisationIds?: string) => {
    try {
      const response = await api
        .get(`analysis/${analysisId}/get-chart`, {
          headers: getCommonHeaders(),
          searchParams: visualisationIds
            ? {
                visualisation_ids: visualisationIds,
              }
            : {},
        })
        .json();

      return response;
    } catch (error) {
      return error;
    }
  }, []);

  const exportCSV = useCallback(async (csvData: exportCSVType) => {
    try {
      showSuccessMessage({
        title: 'Downloading',
      });

      const feature_ids =
        csvData.featureIds && csvData.featureIds.length > 0 ? csvData.featureIds : undefined;
      const data_points =
        csvData.dataPointIds && csvData.dataPointIds.length > 0 ? csvData.dataPointIds : undefined;

      const response = await api.post(`analysis/${csvData.analysisId}/export-to-csv`, {
        headers: getCommonHeaders(),
        json: {
          group_id: csvData.groupId,
          feature_ids,
          data_points,
        },
      });

      const textData = await response.text();
      const blob = new Blob([textData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.download = `${csvData.groupName || 'selectedItems'}.csv`;
      link.href = url;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const exportJSON = useCallback(async (jsonData: exportJSONType) => {
    try {
      showSuccessMessage({
        title: 'Downloading',
      });

      const { analysisId, analysisName, visualizationName, projectName, visualizationId } =
        jsonData;

      const response = await api.post(`analysis/${analysisId}/export-graph`, {
        headers: getCommonHeaders(),
        json: {
          visualisation_id: visualizationId,
        },
      });

      const textData = await response.text();
      const blob = new Blob([textData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.download = `${visualizationName}-${analysisName}-${projectName}.json`;
      link.href = url;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const setChart = (type: string, data: ChartDataType): void => {
    // @ts-ignore
    const chart = currentAnalysis.config.visual.chart[type];

    const currentVisualisation = [data, ...(chart?.length ? chart : [])].reduce((acc, current) => {
      const duplicate = acc.find((visualization: ChartDataType) => visualization.id === current.id);
      if (!duplicate) {
        return acc.concat([current]);
      }
      return acc;
    }, []);

    dispatch(
      setCurrentAnalysis({
        ...currentAnalysis,
        config: {
          ...currentAnalysis.config,
          visual: {
            ...currentAnalysis.config.visual,
            chart: {
              ...currentAnalysis.config.visual.chart,
              [type]: currentVisualisation,
            },
          },
        },
      })
    );
  };

  const fetchDataColoring = useCallback(
    async (datasetId: number, featureGroupId: string, data: DataColoringType) => {
      try {
        if (dataColoringAbortSignal.current) {
          dataColoringAbortSignal.current?.abort();
        }

        dataColoringAbortSignal.current = new AbortController();

        const isCategorical = data.aggregation_function === AggregationType.CARTESIAN;

        const response = (await api
          .post(`external/reverse-proxy/data-coloring/dataset/${datasetId}/fg/${featureGroupId}/`, {
            headers: getCommonHeaders(),
            json: data,
            signal: dataColoringAbortSignal.current?.signal,
          })
          .json()) as RawDataColoringResponseType;

        // convert stringified NaN/Infinity into Numbers
        const functionKeys = Object.keys(response.function);
        const isOneFeature = functionKeys.length === 1;
        const functionMap =
          isCategorical && isOneFeature ? response.function[functionKeys[0]] : response.function;

        dataColoringAbortSignal.current = null;

        return {
          ...response,
          function: mapValues(functionMap as DataColoringResponseFunction, (x) => {
            if (isCategorical) {
              return String(x);
            }

            return x === null ? null : Number(x);
          }),
        };
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          dataColoringAbortSignal.current = null;
        }

        return error;
      }
    },
    []
  );

  const fetchFeatureColoring = useCallback(
    async (datasetId: number, featureGroupId: string, data: FeatureColoringType) => {
      try {
        if (featureColoringAbortSignal.current) {
          featureColoringAbortSignal.current?.abort();
        }

        featureColoringAbortSignal.current = new AbortController();

        const response = (await api
          .post(
            `external/reverse-proxy/feature-coloring/dataset/${datasetId}/fg/${featureGroupId}/`,
            {
              headers: getCommonHeaders(),
              json: data,
              signal: featureColoringAbortSignal.current?.signal,
            }
          )
          .json()) as RawDataColoringResponseType;

        featureColoringAbortSignal.current = null;

        // convert stringified NaN/Infinity into Numbers
        return {
          ...response,
          function: mapValues(response.function, (x) => (x === null ? null : Number(x))),
        };
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          featureColoringAbortSignal.current = null;
        }

        return error;
      }
    },
    []
  );

  const getColoringRequestParams = (): ColoringRequestParams => {
    const request = currentAnalysis.latest_feature_group.split('.');

    const datasetId = Number(request[0]);
    const featureGroupId = request[1];

    return {
      datasetId,
      featureGroupId,
    };
  };

  const getFeatureIdsForColoring = (feature: SelectedFeatureType): number[] => {
    if (feature?.id === COLORING_OPTIONS.selectedFeatures) {
      return selectedFeatures;
    }

    return [feature.id];
  };

  const getGroupData = async (feature: SelectedFeatureType): Promise<number[]> => {
    if (feature.groupType === GroupCreationEnum.FEATURES) {
      const response = (await getGroup(currentAnalysis.id, feature.id)) as GroupType;
      return response.features;
    }

    if (feature.groupType === GroupCreationEnum.ROWS) {
      const response = (await getGroup(currentAnalysis.id, feature.id)) as GroupType;
      return response.rows;
    }

    return [];
  };

  const getDataDiffColoring = async (
    coloringFeatures: SelectedFeatureType[],
    aggregationType: AggregationType,
    normalizationType: NormalizationType
  ): Promise<ColoringData | undefined> => {
    const { datasetId, featureGroupId } = getColoringRequestParams();

    const isDataPointsColoring = coloringFeatures.every(
      (f) =>
        f?.id === COLORING_OPTIONS.selectedDataPoints || f?.groupType === GroupCreationEnum.ROWS
    );
    if (isDataPointsColoring) {
      const dataPoints = await Promise.all(
        coloringFeatures.map((f) => {
          if (f.id === COLORING_OPTIONS.selectedDataPoints) {
            return selectedDataRows;
          }

          return getGroupData(f);
        })
      );

      return {
        colorBy: COLORING_OPTIONS.selectedDataPoints,
        colorFunction: {},
        colorData: Array.from(new Set(dataPoints.flat())),
        colorFunctionName: SELECTED_FUNCTION_NAME,
        scale: COLORMAP_TYPE.sequential,
      };
    }

    const featureIdsMatrix = await Promise.all(
      coloringFeatures.map((f) => {
        if (
          f?.id === COLORING_OPTIONS.selectedDataPoints ||
          f?.groupType === GroupCreationEnum.ROWS
        ) {
          return [];
        }

        if (f?.groupType === GroupCreationEnum.FEATURES) {
          return getGroupData(f);
        }

        return getFeatureIdsForColoring(f);
      })
    );

    const featureIds = Array.from(new Set(featureIdsMatrix.flat()));

    if (featureIds.length > 0) {
      const dataColoring = (await fetchDataColoring(datasetId, featureGroupId, {
        feature_ids: featureIds,
        aggregation_function: aggregationType,
        feature_normalization: normalizationType,
      })) as DataColoringResponseType;

      if (dataColoring) {
        return {
          colorBy: COLORING_OPTIONS.selectedFeatures,
          colorFunction: dataColoring.function,
          colorFunctionName: dataColoring.function_name,
          colorData: [],
          scale:
            normalizationType === NormalizationType.RAW || alwaysUseSequential
              ? COLORMAP_TYPE.sequential
              : COLORMAP_TYPE.diverging,
        };
      }
    }
  };

  const getDataColoring = async (
    feature: SelectedFeatureType,
    aggregationType: AggregationType,
    normalizationType: NormalizationType
  ): Promise<ColoringData | undefined> => {
    const { datasetId, featureGroupId } = getColoringRequestParams();

    if (feature.id === COLORING_OPTIONS.selectedDataPoints) {
      return {
        colorBy: COLORING_OPTIONS.selectedDataPoints,
        colorFunction: {},
        colorData: selectedDataRows,
        colorFunctionName: SELECTED_FUNCTION_NAME,
        scale: COLORMAP_TYPE.sequential,
      };
    }

    if (feature.groupType === GroupCreationEnum.ROWS) {
      const dataPointGroup = await getGroupData(feature);

      return {
        colorBy: COLORING_OPTIONS.selectedDataPoints,
        colorFunction: {},
        colorData: dataPointGroup,
        colorFunctionName: SELECTED_FUNCTION_NAME,
        scale: COLORMAP_TYPE.sequential,
      };
    }

    const isSequentialNormalizationFunc = [NormalizationType.UNIT, NormalizationType.RAW].includes(
      normalizationType
    );

    if (feature.groupType === GroupCreationEnum.FEATURES) {
      const featureGroup = await getGroupData(feature);

      const dataColoring = (await fetchDataColoring(datasetId, featureGroupId, {
        feature_ids: featureGroup,
        aggregation_function: aggregationType,
        feature_normalization: normalizationType,
      })) as DataColoringResponseType;

      return {
        colorBy: COLORING_OPTIONS.selectedFeatures,
        colorFunction: dataColoring.function,
        colorFunctionName: dataColoring.function_name,
        colorData: [],
        scale:
          alwaysUseSequential || isSequentialNormalizationFunc
            ? COLORMAP_TYPE.sequential
            : COLORMAP_TYPE.diverging,
      };
    }

    const featureIds = getFeatureIdsForColoring(feature);
    if (featureIds.length > 0) {
      const dataColoring = (await fetchDataColoring(datasetId, featureGroupId, {
        feature_ids: featureIds,
        aggregation_function: aggregationType,
        feature_normalization: normalizationType,
      })) as DataColoringResponseType;

      if (dataColoring) {
        return {
          colorBy: COLORING_OPTIONS.selectedFeatures,
          colorFunction: dataColoring.function,
          colorFunctionName: dataColoring.function_name,
          colorData: [],
          scale:
            alwaysUseSequential || isSequentialNormalizationFunc
              ? COLORMAP_TYPE.sequential
              : COLORMAP_TYPE.diverging,
        };
      }
    }
  };

  const getFeatureDiffColoring = async (
    coloringFeatures: SelectedFeatureType[],
    aggregationType: AggregationType,
    normalizationType: NormalizationType,
    mapperMatrixConfigCols: number[]
  ): Promise<ColoringData | undefined> => {
    const { datasetId, featureGroupId } = getColoringRequestParams();

    const isFeaturesColoring = coloringFeatures.every(
      (f) =>
        f.id === COLORING_OPTIONS.selectedFeatures ||
        f.groupType === GroupCreationEnum.FEATURES ||
        !!f.type
    );

    if (isFeaturesColoring) {
      const featureIds = await Promise.all(
        coloringFeatures.map((f) => {
          if (f.groupType === GroupCreationEnum.FEATURES) {
            return getGroupData(f);
          }

          return getFeatureIdsForColoring(f);
        })
      );

      return {
        colorBy: COLORING_OPTIONS.selectedFeatures,
        colorFunction: {},
        colorData: Array.from(new Set(featureIds.flat())),
        colorFunctionName: SELECTED_FUNCTION_NAME,
        scale: COLORMAP_TYPE.sequential,
      };
    }

    const dataPointsMatrix = await Promise.all(
      coloringFeatures.map((f) => {
        if (
          f.id === COLORING_OPTIONS.selectedFeatures ||
          f.groupType === GroupCreationEnum.FEATURES ||
          f.type
        ) {
          return [];
        }

        if (f.groupType === GroupCreationEnum.ROWS) {
          return getGroupData(f);
        }

        return selectedDataRows;
      })
    );

    const dataPoints = Array.from(new Set(dataPointsMatrix.flat()));

    if (dataPoints.length > 0) {
      const featureColoring = (await fetchFeatureColoring(datasetId, featureGroupId, {
        data_points: dataPoints,
        feature_ids: mapperMatrixConfigCols,
        aggregation_function: aggregationType,
        feature_normalization: normalizationType,
      })) as DataColoringResponseType;

      if (featureColoring) {
        return {
          colorBy: COLORING_OPTIONS.selectedDataPoints,
          colorFunction: featureColoring.function,
          colorFunctionName: featureColoring.function_name,
          colorData: [],
          scale:
            alwaysUseSequential || normalizationType === NormalizationType.RAW
              ? COLORMAP_TYPE.sequential
              : COLORMAP_TYPE.diverging,
        };
      }
    }
  };

  const getFeatureColoring = async (
    feature: SelectedFeatureType,
    aggregationType: AggregationType,
    normalizationType: NormalizationType,
    mapperMatrixConfigCols: number[]
  ): Promise<ColoringData> => {
    const { datasetId, featureGroupId } = getColoringRequestParams();

    const isSequentialNormalizationFunc = [NormalizationType.UNIT, NormalizationType.RAW].includes(
      normalizationType
    );

    if (feature.id === COLORING_OPTIONS.selectedDataPoints) {
      const featureColoring = (await fetchFeatureColoring(datasetId, featureGroupId, {
        data_points: selectedDataRows,
        feature_ids: mapperMatrixConfigCols,
        aggregation_function: aggregationType,
        feature_normalization: normalizationType,
      })) as DataColoringResponseType;

      return {
        colorBy: COLORING_OPTIONS.selectedDataPoints,
        colorFunction: featureColoring.function,
        colorFunctionName: featureColoring.function_name,
        colorData: [],
        scale:
          alwaysUseSequential || isSequentialNormalizationFunc
            ? COLORMAP_TYPE.sequential
            : COLORMAP_TYPE.diverging,
      };
    }

    if (feature.groupType === GroupCreationEnum.ROWS) {
      const dataPointsGroup = await getGroupData(feature);

      const featureColoring = (await fetchFeatureColoring(datasetId, featureGroupId, {
        data_points: dataPointsGroup,
        feature_ids: mapperMatrixConfigCols,
        aggregation_function: aggregationType,
        feature_normalization: normalizationType,
      })) as DataColoringResponseType;

      return {
        colorBy: COLORING_OPTIONS.selectedDataPoints,
        colorFunction: featureColoring.function,
        colorFunctionName: featureColoring.function_name,
        colorData: [],
        scale:
          alwaysUseSequential || isSequentialNormalizationFunc
            ? COLORMAP_TYPE.sequential
            : COLORMAP_TYPE.diverging,
      };
    }

    if (feature.groupType === GroupCreationEnum.FEATURES) {
      const featureGroup = await getGroupData(feature);

      return {
        colorBy: COLORING_OPTIONS.selectedFeatures,
        colorFunction: {},
        colorData: featureGroup,
        colorFunctionName: SELECTED_FUNCTION_NAME,
        scale: COLORMAP_TYPE.sequential,
      };
    }

    return {
      colorBy: COLORING_OPTIONS.selectedFeatures,
      colorFunction: {},
      colorData: getFeatureIdsForColoring(feature),
      colorFunctionName: SELECTED_FUNCTION_NAME,
      scale: COLORMAP_TYPE.sequential,
    };
  };

  const getGraphLayoutData = useCallback((data: LandscapeGraphPayload): LandscapeData => {
    const rand = new Rand('blai');
    const nodesWithAttributes = data.nodes.map((node, index) => {
      const attributes = {
        x: rand.next(),
        y: rand.next(),
        data: node,
        size: DEFAULT_NODE_SIZE,
        color: DEFAULT_NODE_COLOR,
        border: DEFAULT_NODE_COLOR,
        type: 'border',
        colored_label: undefined,
      };

      return {
        key: index,
        attributes,
      };
    });

    return {
      ...data,
      nodes: nodesWithAttributes,
    };
  }, []);

  const getChartLayoutCategoricalColoring = (
    data: ChartDataType,
    coloringData: ColoringData<CategoricalDataColoringFunction>,
    colorMapType: CategoricalColorMaps
  ): ChartColoringType => {
    const uniqueValues = Array.from(new Set(Object.values(coloringData.colorFunction)));
    const colorMapFunction = getColorMap(categoricalMaps[colorMapType]);
    const isMonochromatic = colorMapType === CategoricalColorMaps.Monochromatic;
    const coloredData = Object.values(
      coloringData.colorFunction as CategoricalDataColoringFunction
    ).map((x) => {
      const isBorder = isMonochromatic && x === null;

      return {
        color: colorMapFunction(x !== null ? uniqueValues.findIndex((i) => i === x) : 10),
        symbol: isBorder ? 'circle-open' : 'circle',
      };
    });

    return {
      colors: coloredData.map((i) => i.color),
      symbols: coloredData.map((i) => i.symbol),
      legend: {
        type: 'categories',
        functionName: coloringData.colorFunctionName,
        categories: uniqueValues
          .map((value, index) => ({
            value,
            color: colorMapFunction(index),
          }))
          .concat({
            value: 'no dominant category',
            color: colorMapFunction(10),
          }),
      },
    };
  };

  const getChartLayoutColoredData = (
    data: ChartDataType,
    coloringData: ColoringData<DataColoringFunction>
  ): ChartColoringType => {
    const colorMapType = coloringData.scale;
    if (coloringData.colorBy === COLORING_OPTIONS.selectedDataPoints) {
      const colors = new Array(data.features[0].values.length)
        .fill(null)
        .map((_, index) => (coloringData.colorData.includes(index) ? 1 : 0))
        .map((x) => colorMap(x, COLORMAP_TYPE.sequential));

      return {
        colors,
        symbols: 'circle',
        legend: {
          type: 'line',
          dataBounds: [0, 1],
          colorBounds: coloringConfig.sequentialColorScheme.map((colorPair) => colorPair[1]),
          functionName: coloringData.colorFunctionName,
        },
      };
    }

    if (coloringData.colorBy === COLORING_OPTIONS.selectedFeatures) {
      if (coloringData.colorFunction) {
        const scaledColoringData = getPrescaledColorDataValues(coloringData);
        const colors = Object.values(scaledColoringData.colorFunction as DataColoringFunction).map(
          (x) => colorMap(x, colorMapType)
        );

        const useSequential = coloringData.scale === COLORMAP_TYPE.sequential;
        const divergingBound = getDivergingColoringBound(Object.values(coloringData.colorFunction));
        const dataBounds = useSequential
          ? getDataColoringBounds(coloringData.colorFunction)
          : [-divergingBound, divergingBound];
        const colorScale = useSequential
          ? coloringConfig.sequentialColorScheme
          : coloringConfig.divergingColorScheme;
        const colorBounds =
          dataBounds[0] === dataBounds[1]
            ? [colorMap(0.5, COLORMAP_TYPE.sequential)]
            : colorScale.map((colorPair) => colorPair[1]);

        return {
          colors,
          symbols: 'circle',
          legend: {
            type: 'line',
            dataBounds,
            colorBounds,
            functionName: coloringData.colorFunctionName,
          },
        };
      }
    }

    return {
      colors: DEFAULT_NODE_COLOR,
      symbols: 'circle',
      legend: {
        type: 'line',
      },
    };
  };

  const getGraphLayoutCategoricalColoring = (
    data: LandscapeData,
    coloringData: ColoringData<CategoricalDataColoringFunction>,
    colorMapType: CategoricalColorMaps,
    isFeatureLandscape: boolean
  ): LandscapeData => {
    const { nodeColors, categories, rawNodeValues } = getNodeCategoricalColors(
      coloringData,
      colorMapType,
      data.nodes,
      isFeatureLandscape
    );

    if (isFeatureLandscape) {
      console.warn(
        `You are attempting to color a feature landscape by a categorical. Results undefined.`
      );
    }
    const coloredNodes = data.nodes.map((node, nodeIndex) => {
      const { color, type } = nodeColors[nodeIndex];

      const dataPoints = node.attributes.data;

      const datapointCountLabel = `data point${dataPoints.length === 1 ? `` : `s`}`;

      const matchingDataPoints = dataPoints.filter(
        (dp) => coloringData.colorFunction[dp] === rawNodeValues[nodeIndex]
      );

      const fractionalLabel = `${matchingDataPoints.length}/${dataPoints.length}`;
      const percentageDataPoints =
        (100 *
          dataPoints.filter((dp) => coloringData.colorFunction[dp] === rawNodeValues[nodeIndex])
            .length) /
        dataPoints.length;

      const percentageMode = isFeatureLandscape
        ? ''
        : `${fractionalLabel} (${Math.round(percentageDataPoints)}%)`;

      const modeText =
        rawNodeValues[nodeIndex] == null
          ? `No Dominant Category`
          : `${coloringData.colorFunctionName} = ${rawNodeValues[nodeIndex]}, ${percentageMode}`;
      const label = `${modeText} ${datapointCountLabel}`;

      return {
        key: node.key,
        attributes: {
          ...node.attributes,
          color,
          border: type === 'border' ? 'rgba(0, 0, 0)' : color,
          colored_label:
            rawNodeValues[nodeIndex] == null
              ? `No Dominant Category, ${dataPoints.length} ${datapointCountLabel}`
              : label,
        },
      };
    });

    return {
      ...data,
      nodes: coloredNodes,
      legend: {
        type: 'categories',
        colorMap: colorMapType,
        categories,
        functionName: coloringData.colorFunctionName,
      },
    };
  };

  const getGraphLayoutColoredData = (
    data: LandscapeData,
    coloringData: ColoringData<DataColoringFunction>,
    isFeatureLandscape = false
  ): LandscapeData => {
    const { nodeColors, dataBounds, colorBounds, rawNodeValues } = getNodeColors(
      coloringData,
      data.nodes,
      isFeatureLandscape,
      coloringConfig.scaleBeforeAggregation
    );

    const coloredNodes = data.nodes.map((node, nodeIndex) => {
      const color = nodeColors[nodeIndex];

      const elementsPerNode = node.attributes.data.length;
      let text = '';
      if (elementsPerNode === 1 && isFeatureLandscape) {
        const dp = node.attributes.data[0];
        const feature = features[dp] as FeatureType;
        text = feature.feature_name;
      } else {
        text = `${elementsPerNode} ${isFeatureLandscape ? 'feature' : 'data point'}${
          node.attributes.data.length > 1 ? 's' : ''
        }`;
      }

      const dataPointLabel = `${coloringData.colorFunctionName} = ${rawNodeValues[nodeIndex]}, ${text}`;

      return {
        key: node.key,
        attributes: {
          ...node.attributes,
          color,
          border: color,
          colored_label: isFeatureLandscape
            ? `${text}, ${coloringData.colorFunctionName} = ${rawNodeValues[nodeIndex]}`
            : dataPointLabel,
        },
      };
    });

    return {
      ...data,
      nodes: coloredNodes,
      legend: {
        type: 'line',
        dataBounds,
        colorBounds,
        functionName: coloringData.colorFunctionName,
      },
    };
  };

  const isDefaultColoring = useCallback(
    (feature: SelectedFeatureType) =>
      Boolean(
        feature.id === COLORING_OPTIONS.selectedDataPoints && selectedDataRows?.length === 0
      ) ||
      Boolean(feature.id === COLORING_OPTIONS.selectedFeatures && selectedFeatures?.length === 0) ||
      feature.id === COLORING_OPTIONS.none,
    [selectedDataRows?.length, selectedFeatures?.length]
  );

  const updatePanConfig = async (
    analysisId: number,
    panelId: string,
    pan?: Pan
  ): Promise<unknown> => {
    try {
      const updatedConfig = panConfig.reduce((acc, { panel, error, ...config }) => {
        if (panelId === panel) {
          return {
            ...acc,
            [panelId]: {
              ...(pan || {}),
            },
          };
        }

        return {
          ...acc,
          [panel]: {
            ...config,
          },
        };
      }, {} as Partial<PanConfigResponse>);

      await api.post(`analysis/${analysisId}/save-analysis`, {
        headers: getCommonHeaders(),
        json: updatedConfig,
      });
    } catch (error) {
      return error;
    }
  };

  const removePanelError = (panelId: string): void => {
    const updatedPanConfig = panConfig.map((pan) => {
      const { error, ...config } = pan;
      if (panelId === pan.panel) {
        return config;
      }

      return pan;
    });

    dispatch(setPanConfig(updatedPanConfig));
  };

  const setPanConfigToStore = (config: PanConfigResponse): void => {
    const panelsArray = Object.keys(config).map((name) => {
      if (config[name]?.error) {
        const currentPan = panConfig.find((p) => p.panel === name)!;

        return {
          ...currentPan,
          ...config[name],
        };
      }

      const currentPanError = panConfig.find((p) => p.panel === name)?.error;

      return {
        panel: name,
        ...config[name],
        ...(currentPanError ? { error: currentPanError } : {}),
      };
    });

    dispatch(setPanConfig(panelsArray));
  };

  const reloadVisualization = useCallback(
    (visualizationId: number, shouldReload: boolean) => {
      const updatedConfig = panConfig.map((panel) => {
        if (panel.id === visualizationId) {
          return {
            ...panel,
            shouldReload,
          };
        }

        return panel;
      });

      dispatch(setPanConfig(updatedConfig));
    },
    [dispatch, panConfig]
  );

  const isFeatureWithMissingValues = useCallback(
    (userSelectedFeatures: number[], allFeatures: FeatureItemType[]) => {
      const allFeaturesMap = allFeatures.reduce(
        (acc, row) => ({
          ...acc,
          [row.id]: row.has_missing,
        }),
        {}
      ) as Record<number, boolean>;

      return userSelectedFeatures.some((userFeature: number) => allFeaturesMap[userFeature]);
    },
    []
  );

  const clearSelection = (id?: number, type?: VisualisationType): void => {
    const isFeatureSelection = type === VisualisationType.FEATURE_LANDSCAPE;
    const isCurrentVisualizationSelection = isFeatureSelection
      ? !!featureSelectionSource?.id && featureSelectionSource?.id === id
      : !!dataPointsSelectionSource?.id && dataPointsSelectionSource?.id === id;

    if (isCurrentVisualizationSelection) {
      if (isFeatureSelection) {
        setSelectedFeatures([]);
        setFeaturesSelectionSource(null);
      } else {
        setSelectedDataRows([]);
        setDataSelectionSource(null);
      }
    }
  };

  const getComparativeStatistics = async (payload: ComparativeStatisticsPayload): Promise<void> => {
    const { id, test_type, in_group, comparison_group, fixed_subset } = payload;

    try {
      if (comparativeStatisticsAbortSignal.current) {
        comparativeStatisticsAbortSignal.current?.abort();
      }

      comparativeStatisticsAbortSignal.current = new AbortController();

      const response = await api
        .post(`analysis/${id}/comparative-statistics`, {
          headers: getCommonHeaders(),
          json: {
            test_type,
            in_group,
            comparison_group,
            fixed_subset,
          },
          signal: comparativeStatisticsAbortSignal.current?.signal,
        })
        .json();

      dispatch(setComparativeStatistics(response));

      comparativeStatisticsAbortSignal.current = null;
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        comparativeStatisticsAbortSignal.current = null;
      }

      throw error;
    }
  };

  const getDescriptiveStatistics = async (payload: DescriptiveStatisticsPayload) => {
    const { id, dataPoints } = payload;

    try {
      if (descriptiveStatisticsAbortSignal.current) {
        descriptiveStatisticsAbortSignal.current?.abort();
      }

      descriptiveStatisticsAbortSignal.current = new AbortController();

      const response = await api
        .post(`analysis/${id}/descriptive-statistics`, {
          headers: getCommonHeaders(),
          json: {
            in_group: dataPoints,
          },
          signal: descriptiveStatisticsAbortSignal.current?.signal,
        })
        .json();

      dispatch(setDescriptiveStatistics(response));

      descriptiveStatisticsAbortSignal.current = null;
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        descriptiveStatisticsAbortSignal.current = null;
      }

      throw error;
    }
  };

  return {
    layout,
    loading,
    analysis,
    currentAnalysis,
    selectedDataRows,
    selectedFeatures,
    selectedGraph,
    selectedChart,
    highlightedVisualization,
    selectedDataFilters,
    selectedFeatureFilters,
    groups,
    lastCreatedGroup,
    defaultDataLandscapeConfig,
    defaultFeatureLandscapeConfig,
    chartConfig,
    dataLandscapeVisualization,
    dataLandscapePartition,
    featureLandscapeVisualization,
    featureLandscapePartition,
    scatterPlotVisualization,
    heatmapVisualization,
    violinPlotVisualization,
    histogramVisualization,
    features,
    setLayout,
    setAnalysis,
    updateLandscape,
    updateLandscapeConfig,
    setSelectedDataRows,
    setSelectedFeatures,
    setSelectedChart,
    setSelectedGraph,
    setHighlightedVisualization,
    setSelectedDataFilters,
    setSelectedFeatureFilters,
    setLastCreatedGroup,
    resetAnalysis,
    getDataExplorer,
    getFeatureExplorer,
    getFeaturesList,
    totalRows,
    dataPointsCount,
    totalFeatures,
    getAnalyses,
    getAnalysis,
    deleteVisualization: onDeleteVisualization,
    deleteAnalysis: onDeleteAnalysis,
    createAnalysis: onCreateAnalysis,
    editAnalysis: onEditAnalysis,
    ingestDataset: onIngestDataset,
    createGroup: onCreateGroup,
    deleteGroup: onDeleteGroup,
    getGroups,
    getGroup,
    createGraph,
    editGraph,
    getMetadata,
    retrieveGraph,
    addChart,
    editChart,
    getChart,
    setChart,
    exportCSV,
    exportJSON,
    getDataColoring,
    getDataDiffColoring,
    getFeatureColoring,
    getFeatureDiffColoring,
    getGraphLayoutData,
    getGraphLayoutColoredData,
    getGraphLayoutCategoricalColoring,
    getChartLayoutColoredData,
    getChartLayoutCategoricalColoring,
    setDataSelectionSource,
    setFeaturesSelectionSource,
    featureSelectionSource,
    dataPointsSelectionSource,
    currentAnalysisDataset,
    dataLandscapeVisualizations,
    featureLandscapeVisualizations,
    scatterPlotVisualizations,
    histogramVisualizations,
    heatmapVisualizations,
    violinPlotVisualizations,
    isDefaultColoring,
    analysisPreview,
    getAnalysisPreview,
    panConfig,
    updatePanConfig,
    reloadVisualization,
    removePanelError,
    setPanConfigToStore,
    isFeatureWithMissingValues,
    clearSelection,
    getComparativeStatistics,
    comparativeStatistics,
    getDescriptiveStatistics,
    descriptiveStatistics,
  };
};

import React, { FC } from 'react';
import { Trash2 } from 'react-feather';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tooltip } from 'app/mui/Tooltip';
import classNames from 'classnames';
import { DATASET_STATUSES } from 'app/shared/enum/dataset';
import datasetImage from 'app/assets/images/datasets/failed-dataset-image.svg';
import { lastUpdated } from 'app/shared/utils/lastUpdated';
import { formatBytes } from 'app/shared/utils/formatBytes';
import { StatusEnum } from 'app/shared/enum/status';
import { Status } from 'app/shared/components/Status/Status';
import { Dataset } from '../DataLibrary.types';
import { ListRow } from '../DataLibraryList/DataLibraryList.styles';
import { SelectedDataset } from '../DataLibraryList/DataLibraryList';

type FailedDatasetProps = {
  openDeleteDialog: (id: number, name: string) => void;
  row: Dataset;
  labelId: string;
  selectedDataset: SelectedDataset;
  handlePreviewOpen: (id: number) => void;
};

export const FailedDataset: FC<FailedDatasetProps> = ({
  openDeleteDialog,
  row,
  labelId,
  selectedDataset,
  handlePreviewOpen,
}) => {
  const isDatasetSelected = row.id === selectedDataset?.previewId;

  return (
    <ListRow
      className={classNames('failed', { highlight: isDatasetSelected })}
      onClick={() => {
        handlePreviewOpen(row.id);
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box borderRadius='4px' component='img' src={datasetImage} alt={row.name} />
        <Box
          sx={{
            ml: 2,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            minWidth: 0,
          }}
        >
          <Box display='flex'>
            <Tooltip title={row.name} placement='bottom-start'>
              <Typography noWrap component='h5' variant='h5' id={labelId} className='break-text'>
                {row.name}
              </Typography>
            </Tooltip>
            <Box ml={2}>
              <Status
                status={
                  row.status === DATASET_STATUSES.UPLOAD_FAILED ? StatusEnum.FAILED : row.status
                }
              />
            </Box>
          </Box>
          <Typography
            component='div'
            className='info'
            variant='body1'
            color='text.secondary'
            display='flex'
          >
            {formatBytes(row.dataset_metadata?.size)}
            <Typography mx={2}>&bull;</Typography>
            Updated {lastUpdated(row.updated_at)}
          </Typography>
        </Box>
      </Box>
      <Box display='flex' alignItems='center'>
        <Box
          p={1}
          onClick={(e) => {
            e.stopPropagation();
            openDeleteDialog(row.id, row.name);
          }}
          sx={{ cursor: 'pointer', zIndex: 2 }}
        >
          <Trash2 />
        </Box>
      </Box>
    </ListRow>
  );
};

import { SortIconProps } from 'react-data-grid';
import React, { ReactElement } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Box } from '@mui/material';
import { Sort } from 'app/shared/icons/Sort';

export const SortIcon = ({ sortDirection }: SortIconProps): ReactElement | null => {
  if (!sortDirection)
    return (
      <Box mt={0.5}>
        <Sort width={16} height={16} />
      </Box>
    );

  if (sortDirection === 'ASC')
    return (
      <Box mt={0.5}>
        <ChevronUp size={16} />
      </Box>
    );

  return (
    <Box mt={0.5}>
      <ChevronDown size={16} />
    </Box>
  );
};

import { ExtendButtonBase, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem, { MenuItemTypeMap } from '@mui/material/MenuItem';
import { BoxTypeMap } from '@mui/material/Box/Box';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const highlighted = keyframes({
  '100%': { border: `2px solid #00D1FF` },
});

export const Wrapper = styled(Box)<{ isEmpty: boolean }>(({ isEmpty }) => ({
  width: '100%',
  height: '100%',
  display: isEmpty ? 'flex' : 'block',
  justifyContent: isEmpty ? 'center' : 'unset',
  alignItems: isEmpty ? 'center' : 'unset',
  position: 'relative',
  border: '2px solid transparent',
  '&.highlight': {
    animationName: highlighted,
    animationDuration: '4s',
    overflow: 'hidden',
  },
})) as OverridableComponent<BoxTypeMap<{ isEmpty: boolean }>>;

export const ImageBox = styled(Box)<{ isLarge: boolean }>(({ theme, isLarge }) => ({
  height: isLarge ? '200px' : '120px',
  [theme.breakpoints.down('desktopLarge')]: {
    height: isLarge ? '140px' : '80px',
  },
})) as OverridableComponent<BoxTypeMap<{ isLarge: boolean }>>;

export const MenuBox = styled(Box)<{ isEmpty: boolean }>(({ isEmpty }) => ({
  position: 'absolute',
  width: isEmpty ? '100%' : 'auto',
  top: '10px',
  margin: isEmpty ? '0' : '0 10px',
  display: 'flex',
  justifyContent: isEmpty ? 'center' : 'flex-start',
  alignItems: 'center',
  zIndex: 1,
})) as OverridableComponent<BoxTypeMap<{ isEmpty: boolean }>>;

export const MenuButton = styled(Box)<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: isEmpty ? '15px' : '14px',
  fontWeight: isEmpty ? 600 : 400,
  color: theme.palette.primary.light,
  cursor: 'pointer',
  '&.truncated': {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})) as OverridableComponent<BoxTypeMap<{ isEmpty: boolean }>>;

export const Actions = styled(Box)(({ theme }) => ({
  width: '274px',
  '& img': {
    width: '28px',
    marginRight: theme.spacing(1),
    padding: '4px',
  },
  '& li': {
    height: '40px',
  },
}));

export const ListItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  '&.MuiMenuItem-root': {
    '&.list': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '36px',
    },
    padding: theme.spacing(2),
    backgroundColor: isSelected ? theme.palette.grey[100] : 'white',
    '& span.text': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&.delete': {
      color: theme.palette.error.dark,
      '& span': {
        color: theme.palette.error.dark,
      },
    },
  },
})) as ExtendButtonBase<MenuItemTypeMap<{ isSelected?: boolean }>>;

export const ErrorStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  gap: theme.spacing(3),
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(5px)',
  zIndex: 1000,
}));

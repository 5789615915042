import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import MuiTabs from '@mui/material/Tabs';
import { HEADER_HEIGHT } from 'app/shared/styles/constants';

export const LoaderWrapper = styled(Box)(() => ({
  position: 'absolute',
  height: '100vh',
  top: '60px',
  width: '100%',
  zIndex: '999',
  display: 'flex',
  alignItems: 'center',
}));

export const AnalysisContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
  paddingTop: '60px',
}));

export const AnalysisSidebarWrapper = styled(Box)(({ theme }) => ({
  width: '10%',
  minWidth: '320px',
  background: theme.palette.background.default,
  borderRight: `1px solid #818A8C`,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('desktopLarge')]: {
    minWidth: '300px',
  },
}));

export const AnalysisMainWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '60%',
  minWidth: '0',
  borderRight: '1px solid rgba(18, 34, 57, 0.1)',
  flexGrow: 1,
}));

export const AnalysisChartWrapper = styled(Box)(() => ({
  width: '30%',
  minWidth: '300px',
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  overflowY: 'scroll',
  flexShrink: 0,
}));

export const DataLandscapeWrapper = styled(Box)<{ panelMaximized: boolean; panelHidden: boolean }>(
  ({ panelMaximized, panelHidden }) => ({
    display: panelMaximized ? 'none' : 'block',
    flexBasis: panelHidden ? '100%' : '65%',
    position: 'relative',

    '.js-plotly-plot .plotly .modebar ': {
      bottom: 0,
    },
  })
);

export const ColoringLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  height: '30px',
  top: '10px',
  right: '10px',
  zIndex: 1,
  background: theme.palette.background.default,
  padding: '4px 8px 4px 8px',
  borderRadius: '4px',
  color: theme.palette.primary.light,
  cursor: 'pointer',
  '&.truncated': {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const ExplorerFilterWrapper = styled(Box)<{ panelMaximized: boolean }>(
  ({ panelMaximized }) => ({
    width: '236px',
    position: 'absolute',
    left: '-160px',
    bottom: panelMaximized ? '-440px' : '45px',
    padding: '8px 16px 16px 16px',
    background: '#FFFFFF',
    boxShadow:
      '0px 5px 5px -3px rgb(18 34 57 / 20%), 0px 8px 10px 1px rgb(18 34 57 / 14%), 0px 3px 14px 2px rgb(18 34 57 / 12%)',
    borderRadius: '4px',
    zIndex: 1000,
  })
);

export const VisualizationItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.grey['300']}`,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  width: '202px',
  cursor: 'pointer',
  '& img': {
    marginRight: theme.spacing(1),
  },
  '&.selected': {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.blocksBackground.hover,
  },
}));

export const FiltersCountBadge = styled(Box)({
  width: '16px',
  height: '16px',
  position: 'absolute',
  borderRadius: '50%',
  color: 'white',
  background: '#075678',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: '-4px',
  top: '-4px',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: 500,
});

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  [theme.breakpoints.down('desktopLarge')]: {
    paddingLeft: theme.spacing(0),
    '& .MuiTabs-indicator': {
      width: '80px!important',
    },
  },
}));

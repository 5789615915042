import React, {
  createContext,
  forwardRef,
  useContext,
  ReactElement,
  useMemo,
  useCallback,
} from 'react';

import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { autocompleteClasses, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';

const LISTBOX_PADDING = 16;
const OVERSCAN_COUNT = 100;
const MAX_VISIBLE_ITEMS = 8;
const DIVIDER_HEIGHT = 21;
const DEFAULT_OPTION_HEIGHT = 40;
const DEFAULT_MAX_AUTOCOMPLETE_LIST_HEIGHT = 230;

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const [optionProps, option, state, renderOption] = data[index];

  return renderOption(optionProps, option, state, style);
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

interface VirtualizedListBoxProps {
  optionHeight?: number;
  maxHeight?: number;
}

export const VirtualizedListBox = ({
  optionHeight = DEFAULT_OPTION_HEIGHT,
  maxHeight = DEFAULT_MAX_AUTOCOMPLETE_LIST_HEIGHT,
}: VirtualizedListBoxProps) =>
  forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
    const { children, ...other } = props;
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;

    const getChildSize = useCallback(
      ([, option]: any) => (option.isDivider ? DIVIDER_HEIGHT : optionHeight),
      []
    );

    const getItemSize = useCallback(
      (index: number) => getChildSize(itemData[index] as any),
      [getChildSize, itemData]
    );

    const itemKey = useCallback((index: number, data: any) => {
      const [, option] = data[index];

      return option.feature_name;
    }, []);

    const listHeight = useMemo(() => {
      if (itemCount > MAX_VISIBLE_ITEMS) {
        const itemsHeight = MAX_VISIBLE_ITEMS * optionHeight;

        return maxHeight <= itemsHeight ? maxHeight : itemsHeight;
      }

      return itemData.map(getChildSize).reduce((a, b) => a + b, 0) + LISTBOX_PADDING;
    }, [itemCount, itemData, getChildSize]);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={listHeight}
            width='100%'
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={getItemSize}
            overscanCount={OVERSCAN_COUNT}
            itemCount={itemCount}
            itemKey={itemKey}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

export const StyledVirtualPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      position: 'relative',
      padding: 0,
      margin: 0,
    },
  },
});

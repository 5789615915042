import React, { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { X } from 'react-feather';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Transition } from 'app/mui/Transition';
import { Button } from 'app/mui/Button';
import { TextFieldForm } from 'app/mui/TextFieldForm';
import { EditAnalysis } from 'app/screens/Analysis/Analysis.types';
import { FormTextArea } from 'app/shared/components/FormTextArea/FormTextArea';

type EditAnalysisDialogProps = {
  id: number;
  open: boolean;
  name: string;
  description?: string;
  handleClose: () => void;
  onSubmit: (values: EditAnalysis) => void;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, 'The length of analysis name must be 100 characters or fewer')
    .required('Analysis name is required'),
  description: Yup.string().max(
    500,
    'The length of analysis description must be 500 characters or fewer'
  ),
});

export const EditAnalysisDialog: FC<EditAnalysisDialogProps> = ({
  open,
  id,
  name,
  description,
  handleClose,
  onSubmit,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    sx={{
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '841px',
      },
    }}
  >
    <Formik
      initialValues={{
        name,
        description: description || '',
        id,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        handleClose();
        setSubmitting(false);
      }}
    >
      {({ submitForm, setFieldTouched }) => (
        <Form>
          <Box p={6} pt={7}>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='h5'>Edit Analysis</Typography>
              <X onClick={handleClose} size={24} cursor='pointer' />
            </Box>
            <Field
              component={TextFieldForm}
              label='Analysis name'
              type='text'
              onBlur={() => setFieldTouched('name', true)}
              onFocus={() => setFieldTouched('name', false)}
              name='name'
              placeholder='Analysis name'
              sx={{ mt: 4 }}
            />
            <Field
              component={FormTextArea}
              id='description'
              placeholder='Analysis description'
              name='description'
              sx={{ mt: 3, mb: 4 }}
            />
            <Box display='flex' justifyContent='space-between'>
              <Button variant='text' onClick={handleClose}>
                Cancel
              </Button>

              <Button variant='contained' onClick={submitForm}>
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </Dialog>
);

import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { MoreVertical } from 'react-feather';
import { Box } from '@mui/material';
import { IconButton } from 'app/mui/IconButton';
import { ProjectActions } from 'app/screens/Projects/ProjectActions/ProjectActions';
import { DotsMenu } from 'app/shared/components/DotsMenu/DotsMenu';

type ProjectOverviewToolbarProps = {
  setIsEditDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export const ProjectOverviewToolbar: FC<ProjectOverviewToolbarProps> = ({
  setIsEditDialogOpen,
  setIsDeleteDialogOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProject = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onEditDialogOpen = useCallback(() => {
    handleClose();
    setIsEditDialogOpen(true);
  }, []);

  const openDeleteDialog = useCallback(() => {
    handleClose();
    setIsDeleteDialogOpen(true);
  }, []);

  return (
    <Box ml={3}>
      <IconButton onClick={(event) => handleClickMenu(event)}>
        <MoreVertical />
      </IconButton>
      <DotsMenu anchorEl={anchorEl} open={openProject} onClose={handleClose}>
        <ProjectActions openDeleteDialog={openDeleteDialog} openEditDialog={onEditDialogOpen} />
      </DotsMenu>
    </Box>
  );
};

import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Check } from 'react-feather';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { StyledTextField } from 'app/mui/TextField';
import { Autocomplete } from 'app/mui/Autocomplete';
import { CroppedText } from 'app/shared/styles/common';
import { theme } from 'app/shared/styles/theme';
import { OPTIONS } from 'app/shared/enum/analysis';
import { VisualizationRow } from 'app/screens/Analysis/AnalysisSidebar/SourceDataDialog/DataLandscapeSourceDataDialog';
import { ChipBlock, Wrapper } from './CustomSubsetSelect.styles';

const icon = <Box />;
const checkedIcon = <Check size='24' color={theme.palette.success.main} />;

type CustomSubsetSelectProps = {
  rows: any[];
  onRowsChange: any;
  label: string;
  defaultValues?: any;
  showSelect?: boolean;
  maxHeight?: number;
};

export const CustomSubsetSelect: FC<PropsWithChildren<CustomSubsetSelectProps>> = ({
  children,
  rows,
  onRowsChange,
  label,
  defaultValues,
  showSelect = false,
  maxHeight = 115,
}) => {
  const [valueSelected, setValueSelected] = useState<any[]>([]);

  useEffect(() => {
    if (defaultValues) {
      setValueSelected(defaultValues);
      onRowsChange(defaultValues);
    } else {
      setValueSelected([]);
    }
  }, []);

  const shiftSelect = (event: any, value: VisualizationRow[]): void => {
    if (event.shiftKey) {
      const last2 = value.slice(-2);
      if (last2.length > 1 && last2[1].id > last2[0].id) {
        const shiftRows = rows.filter(
          (row) => row?.id > last2[0].id && row?.id < last2[1].id && !value.includes(row)
        );
        const allSelectedRows = value.concat(shiftRows);
        const selectedRows = allSelectedRows.filter(
          (item: VisualizationRow, index: number) =>
            allSelectedRows.findIndex((row) => row.id === item.id) === index
        );

        setValueSelected(selectedRows);
        onRowsChange(selectedRows);
      }
      if (last2.length > 1 && last2[1].id < last2[0].id) {
        const shiftRows = rows.filter(
          (row) => row?.id < last2[0].id && row?.id > last2[1].id && !value.includes(row)
        );
        const allSelectedRows = value.concat(shiftRows);
        const selectedRows = allSelectedRows.filter(
          (item: VisualizationRow, index: number) =>
            allSelectedRows.findIndex((row) => row.id === item.id) === index
        );

        setValueSelected(selectedRows);
        onRowsChange(selectedRows);
      }
    }
  };

  const handleOnChange = (event: any, value: any): void => {
    const isSelectAll = value?.find((item: any) => item.id === OPTIONS.selectAll);

    setValueSelected(value);
    onRowsChange(value);
    shiftSelect(event, value);
    if (isSelectAll) {
      if (rows.length === valueSelected.length) {
        setValueSelected([]);
        onRowsChange([]);
      } else {
        setValueSelected(rows);
        onRowsChange(rows);
      }
    }
  };

  const handleChipDelete = (id: number): void => {
    const selected = valueSelected.filter((item) => item.id !== id);

    setValueSelected(selected);
    onRowsChange(selected);
  };

  const filter = createFilterOptions();

  const inputPlaceholder = valueSelected.length ? '' : label;
  const inputStartAdornment = !valueSelected.length ? null : (
    <Box ml='6px' color='initial'>
      {valueSelected.length} selected
    </Box>
  );

  return (
    <>
      <Wrapper>
        {children}
        {showSelect && (
          <Autocomplete
            useVirtual
            multiple
            id='checkboxes-tags-demo'
            options={rows}
            disableCloseOnSelect
            value={valueSelected}
            limitTags={0}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => handleOnChange(event, value)}
            getOptionLabel={(option) => option.feature_name}
            renderOption={(props, option, { selected }, style = {}) => (
              <li
                {...props}
                style={{
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  ...style,
                }}
              >
                <CroppedText>{option.feature_name}</CroppedText>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              </li>
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return [{ feature_name: 'All', id: OPTIONS.selectAll }, ...filtered];
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder={inputPlaceholder}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: inputStartAdornment,
                }}
              />
            )}
          />
        )}
      </Wrapper>
      {showSelect && !!valueSelected.length && (
        <ChipBlock maxHeight={maxHeight}>
          {valueSelected.map((item) => (
            <Chip
              key={item.id}
              label={item.feature_name}
              onDelete={() => handleChipDelete(item.id)}
              sx={{
                mr: 1,
                my: 0.5,
              }}
            />
          ))}
        </ChipBlock>
      )}
    </>
  );
};

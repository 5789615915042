import { useCallback } from 'react';
import { api, getCommonHeaders } from 'app/shared/utils/api';
import { useAppDispatch, useAppSelector } from 'app/store/hooks';
import { useAlert } from 'app/shared/hooks/useAlert';
import {
  selectProjects,
  selectProjectsLoading,
  selectProjectsLayout,
  setProjectsLayout,
  fetchProjects,
  fetchProject,
  fetchAnalysesByProject,
  selectProject,
  selectProjectAnalyses,
} from 'app/store/modules/project';
import type { Project, ProjectAnalysis, ProjectPayload } from './Projects.types';

interface UseProjects {
  loading: boolean;
  projects: Project[];
  project: Project;
  analyses: ProjectAnalysis[];
  editProject: (project: ProjectPayload) => Promise<unknown>;
  deleteProject: (id: number, onNavigateToProjects?: () => void) => void;
  detachDataset: (projectId: number, datasetId: number) => Promise<unknown>;
  addDatasetToProject: (projectId: number, datasetId: number) => void;
  createProject: (project: ProjectPayload) => Promise<unknown>;
  layout: string;
  setLayout: (type: string) => void;
  getProjects: () => void;
  getProject: (id: number) => void;
  getProjectAnalyses: (id: number) => void;
}

export const useProjects = (): UseProjects => {
  const dispatch = useAppDispatch();
  const { showSuccessMessage } = useAlert();

  const loading = useAppSelector(selectProjectsLoading);
  const layout = useAppSelector(selectProjectsLayout);
  const projects = useAppSelector(selectProjects);
  const project = useAppSelector(selectProject);
  const analyses = useAppSelector(selectProjectAnalyses);

  const setLayout = useCallback(
    (type: string) => {
      dispatch(setProjectsLayout(type));
    },
    [dispatch]
  );

  const getProjects = useCallback(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const getProject = useCallback(
    (id: number) => {
      dispatch(fetchProject(id));
    },
    [dispatch]
  );

  const getProjectAnalyses = useCallback(
    (id: number) => {
      dispatch(fetchAnalysesByProject(id));
    },
    [dispatch]
  );

  const onCreateProject = useCallback(async (currentProject: ProjectPayload) => {
    try {
      const response = await api
        .post(`project/`, {
          headers: getCommonHeaders(),
          json: {
            name: currentProject.name,
            description: currentProject.description,
            is_private: currentProject.is_private,
          },
        })
        .json();

      getProjects();
      showSuccessMessage({
        title: `Project "${currentProject.name}" has been created`,
      });

      if (currentProject.onSuccess) {
        currentProject.onSuccess();
      }
      return response;
    } catch (error) {
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditProject = useCallback(async (currentProject: ProjectPayload) => {
    try {
      const { name, description, is_private, id, onSuccess } = currentProject;
      const response = await api
        .put(`project/${id}`, {
          headers: getCommonHeaders(),
          json: {
            name: `${name}`,
            description: `${description}`,
            is_private: `${is_private}`,
          },
        })
        .json();

      if (id) {
        getProject(id);
      }

      getProjects();
      showSuccessMessage({
        title: 'Project has been edited',
      });
      onSuccess();
      return response;
    } catch (error) {
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteProject = useCallback(async (id: number, onNavigateToProjects?: () => void) => {
    try {
      await api
        .delete(`project/?ids=${id}`, {
          headers: getCommonHeaders(),
        })
        .json();

      showSuccessMessage({
        title: 'Project has been deleted.',
      });
      if (onNavigateToProjects) {
        onNavigateToProjects();
        getProjects();
      } else {
        getProjects();
      }
    } catch (error) {
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDetachDataset = useCallback(async (projectId: number, datasetId: number) => {
    try {
      await api
        .delete(`project/${projectId}/dataset/${datasetId}/detach`, {
          headers: getCommonHeaders(),
        })
        .json();

      getProject(projectId);
      getProjectAnalyses(projectId);

      showSuccessMessage({
        title: 'Dataset has been detached.',
      });
    } catch (error) {
      return error;
    }
  }, []);

  const onAddDatasetToProject = useCallback(async (projectId: number, datasetId: number) => {
    try {
      await api
        .post(`project/${projectId}/add-dataset`, {
          headers: getCommonHeaders(),
          json: {
            dataset_id: datasetId,
          },
        })
        .json();

      showSuccessMessage({
        title: 'Dataset has been added to project.',
      });
      getProject(projectId);
    } catch (error) {
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    projects,
    project,
    analyses,
    layout,
    setLayout,
    getProjects,
    getProject,
    getProjectAnalyses,
    editProject: onEditProject,
    deleteProject: onDeleteProject,
    detachDataset: onDetachDataset,
    addDatasetToProject: onAddDatasetToProject,
    createProject: onCreateProject,
  };
};

import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

export const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '.MuiBackdrop-root': {
    backgroundColor: theme.palette.overlay.main,
  },
  '.MuiDialogContentText-root': {
    fontSize: '1.4rem',
  },
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: '500px',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
  fontSize: '1.8rem',
  fontWeight: '700',
  color: theme.palette.text.primary,
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  fontSize: '1.4rem',
  padding: `0 ${theme.spacing(4)}  ${theme.spacing(2)}`,
  textAlign: 'center',
}));

export const StyledDialogContentText = styled(DialogContentText)(() => ({}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  justifyContent: 'center',
  '.MuiButton-root:not(:first-of-type)': {
    marginLeft: theme.spacing(3),
  },
}));

export const IconWrap = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  display: 'flex',
  justifyContent: 'center',
  '&:empty': {
    padding: `${theme.spacing(3)} 0 0`,
  },
  '& svg': {
    display: 'block',
  },
}));

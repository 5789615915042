export enum VisualisationChart {
  scatterPlot = 'scatter_plot',
  heatmap = 'heatmap',
  violinPlot = 'violin_plot',
  histogram = 'histogram',
  featureCategory = 'feature_category',
  kernelDensity = 'kernel density estimate',
  umap2D = 'UMAP-2D',
  umap3D = 'UMAP-3D',
  tsne2D = 't-SNE 2D',
  tsne3D = 't-SNE 3D',
}

import React, { FC, useCallback, useMemo } from 'react';
import {
  PageWrapper,
  PageContent,
  EllipseBackground,
  ContentWrapper,
} from 'app/shared/styles/common';
import { Sidebar } from 'app/shared/components/Sidebar/Sidebar';
import { Header } from 'app/shared/components/Header/Header';
import { useNavigation } from 'app/shared/hooks/useNavigation';
import { NoResults } from 'app/shared/components/NoResults/NoResults';
import noResultsThumb from 'app/assets/images/thumbnails/no-results-datasets.svg';
import { DATASET_STATUSES, DATASET_UPLOADING_STATUS } from 'app/shared/enum/dataset';
import { ANALYSIS_STATUSES } from 'app/shared/enum/analysis';
import { AnalysisNoResultsLayout } from './AnalysisNoResultsLayout';
import { AnalysisType } from '../Analysis.types';
import { isAnalysisProcessing } from '../Analysis.utils';
import { useAnalysis } from '../Analysis.hooks';

type AnalysisNoResultsProps = {
  projectId: number;
  analysis: AnalysisType;
};

export const AnalysisNoResults: FC<AnalysisNoResultsProps> = ({ projectId, analysis }) => {
  const { navigateToProjectOverview } = useNavigation();
  const { currentAnalysis } = useAnalysis();

  const navigateToProject = (): void => {
    navigateToProjectOverview(projectId);
  };

  const uploadingStatus = useMemo(() => {
    if (
      currentAnalysis.dataset.status === DATASET_STATUSES.UPLOADED ||
      currentAnalysis.dataset.status === DATASET_STATUSES.PROCESSING
    ) {
      return { step: 2, status: DATASET_UPLOADING_STATUS.PROCESSING };
    }
    if (currentAnalysis.dataset.status === DATASET_STATUSES.METADATA_STARTED) {
      return { step: 3, status: DATASET_UPLOADING_STATUS.METADATA_EXTRACTION };
    }
    return { step: 3, status: DATASET_UPLOADING_STATUS.METADATA_EXTRACTION };
  }, [currentAnalysis.dataset.status]);

  const isDatasetRow =
    currentAnalysis.dataset.status === DATASET_STATUSES.METADATA_FINISHED ||
    currentAnalysis.dataset.status === DATASET_STATUSES.PROCESSED;

  const isDatasetUploading = currentAnalysis.dataset.status !== DATASET_STATUSES.METADATA_FINISHED;

  return (
    <>
      <Header withUser />
      <PageWrapper>
        <Sidebar />
        <ContentWrapper>
          <PageContent>
            <EllipseBackground />
            <AnalysisNoResultsLayout
              status={
                isAnalysisProcessing(analysis.status)
                  ? ANALYSIS_STATUSES.NOT_READY
                  : analysis.status
              }
              heading={analysis.name}
              projectId={projectId}
            >
              <NoResults
                thumb={noResultsThumb}
                title={isDatasetRow ? 'Loading analysis' : 'Dataset is being uploaded'}
                description={
                  isDatasetRow
                    ? 'You will be automatically redirected to the analysis screen.'
                    : 'This can take up to several minutes, depending on dataset size. You will be automatically redirected to the analysis screen.'
                }
                isDatasetUploading={isDatasetUploading}
                uploadingStatus={uploadingStatus}
                backButtonText='Back to Project'
                onBackButtonClick={navigateToProject}
              />
            </AnalysisNoResultsLayout>
          </PageContent>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

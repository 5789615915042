import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from 'app/mui/Button';
import { SelectedSampleDataset } from '../UploadDataset';

interface SamplesLayoutProps {
  heading: string;
  children: ReactNode;
  caption?: string;
  onCancelButton: () => void;
  onSelectButton: () => void;
  selectedSampleDataset?: SelectedSampleDataset;
}

export const SamplesLayout: FC<SamplesLayoutProps> = ({
  heading,
  onCancelButton,
  onSelectButton,
  caption,
  children,
  selectedSampleDataset,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box display='flex' alignItems='center' mb={2}>
        <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <Typography component='h4' variant='h4'>
              {heading}
            </Typography>
          </Box>
          <Box mr={2}>
            <Button
              variant='outlined'
              onClick={onCancelButton}
              sx={{ marginRight: theme.spacing(3) }}
            >
              Cancel
            </Button>
            <Button variant='contained' onClick={onSelectButton} disabled={!selectedSampleDataset}>
              Use Selected Dataset
            </Button>
          </Box>
        </Box>
      </Box>
      {caption && (
        <Typography variant='body1' mb={3}>
          {caption}
        </Typography>
      )}
      <Box>{children}</Box>
    </>
  );
};

import React, { FC } from 'react';

type Props = {
  width?: number | string;
  height?: number | string;
};

export const Palette: FC<Props> = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_11284_28605)'>
      <path
        d='M9.99935 18.3346C8.905 18.3346 7.82137 18.1191 6.81032 17.7003C5.79927 17.2815 4.88061 16.6677 4.10679 15.8939C2.54399 14.3311 1.66602 12.2114 1.66602 10.0013C1.66602 7.79116 2.54399 5.67155 4.10679 4.10875C5.6696 2.54594 7.78921 1.66797 9.99935 1.66797C14.5827 1.66797 18.3327 5.0013 18.3327 9.16797C18.3327 10.4941 17.8059 11.7658 16.8682 12.7035C15.9305 13.6412 14.6588 14.168 13.3327 14.168H11.8327C11.5827 14.168 11.416 14.3346 11.416 14.5846C11.416 14.668 11.4993 14.7513 11.4993 14.8346C11.8327 15.2513 11.9993 15.7513 11.9993 16.2513C12.0827 17.418 11.166 18.3346 9.99935 18.3346ZM9.99935 3.33464C8.23124 3.33464 6.53555 4.03701 5.2853 5.28726C4.03506 6.5375 3.33268 8.23319 3.33268 10.0013C3.33268 11.7694 4.03506 13.4651 5.2853 14.7153C6.53555 15.9656 8.23124 16.668 9.99935 16.668C10.2493 16.668 10.416 16.5013 10.416 16.2513C10.416 16.0846 10.3327 16.0013 10.3327 15.918C9.99935 15.5013 9.83268 15.0846 9.83268 14.5846C9.83268 13.418 10.7493 12.5013 11.916 12.5013H13.3327C14.2167 12.5013 15.0646 12.1501 15.6897 11.525C16.3148 10.8999 16.666 10.052 16.666 9.16797C16.666 5.91797 13.666 3.33464 9.99935 3.33464ZM5.41602 8.33464C6.08268 8.33464 6.66602 8.91797 6.66602 9.58464C6.66602 10.2513 6.08268 10.8346 5.41602 10.8346C4.74935 10.8346 4.16602 10.2513 4.16602 9.58464C4.16602 8.91797 4.74935 8.33464 5.41602 8.33464ZM7.91602 5.0013C8.58268 5.0013 9.16602 5.58464 9.16602 6.2513C9.16602 6.91797 8.58268 7.5013 7.91602 7.5013C7.24935 7.5013 6.66602 6.91797 6.66602 6.2513C6.66602 5.58464 7.24935 5.0013 7.91602 5.0013ZM12.0827 5.0013C12.7493 5.0013 13.3327 5.58464 13.3327 6.2513C13.3327 6.91797 12.7493 7.5013 12.0827 7.5013C11.416 7.5013 10.8327 6.91797 10.8327 6.2513C10.8327 5.58464 11.416 5.0013 12.0827 5.0013ZM14.5827 8.33464C15.2493 8.33464 15.8327 8.91797 15.8327 9.58464C15.8327 10.2513 15.2493 10.8346 14.5827 10.8346C13.916 10.8346 13.3327 10.2513 13.3327 9.58464C13.3327 8.91797 13.916 8.33464 14.5827 8.33464Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_11284_28605'>
        <rect width={width} height={height} fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
);

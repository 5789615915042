import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { RootState } from 'app/store/store';
import { getJWTTokenFromUserSession } from 'app/shared/utils/amplify';
import { setAccessToken } from 'app/shared/utils/token';
import { api, getCommonHeaders } from 'app/shared/utils/api';

export type UserRequest = {
  email: string;
  password: string;
};

export type User = {
  firstname: string;
  lastname: string;
  id: number;
  cognito_uuid: string;
  created_at: string;
  updated_at: string;
  email: string;
  first_login: boolean;
};

type AccountSlice = {
  user: User;
  cognitoUser: any;
  currentEmail: string;
};

export const fetchUserData = createAsyncThunk(
  'account/fetchUserData',
  async ({ email, password }: UserRequest, { rejectWithValue }) => {
    try {
      const response = await Auth.signIn(email, password);

      // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(response, password)
          .then((user) => JSON.stringify(user))
          .catch((error) => rejectWithValue(error));
      }

      const accessToken = getJWTTokenFromUserSession(response.getSignInUserSession());
      setAccessToken(accessToken);

      return accessToken;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCurrentUser = createAsyncThunk(
  'account/fetchCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('user/get-current', {
        headers: getCommonHeaders(),
      });

      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = { user: {}, currentEmail: '' } as AccountSlice;

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetAccount: () => initialState,
    setCognitoUser: (state, action) => {
      state.cognitoUser = action.payload;
    },
    setCurrentEmail: (state, action) => {
      state.currentEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

// actions
export const { resetAccount, setCognitoUser, setCurrentEmail } = accountSlice.actions;

// selectors
export const selectUser = (state: RootState): User => state.account.user;
export const selectCognitoUser = (state: RootState): any => state.account.cognitoUser;
export const selectCurrentEmail = (state: RootState): string => state.account.currentEmail;

// reducer
export const accountReducer = accountSlice.reducer;

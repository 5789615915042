import { useMemo } from 'react';
import { GroupCreationEnum } from 'app/shared/enum/sidebar-selections';
import { DATASET_STATUSES } from 'app/shared/enum/dataset';
import { ANALYSIS_STATUSES, COLORING_OPTIONS } from 'app/shared/enum/analysis';
import { VisualisationType } from 'app/screens/Analysis/AnalysisSidebar/Configure/Configure';
import { useAnalysis } from 'app/screens/Analysis/Analysis.hooks';
import { ExplorerFilterType } from './Explorer/Explorer.types';
import { AggregationType, NormalizationType, VisualizationsMapType } from './Analysis.types';

export const isAnalysisProcessing = (analysisStatus: string): boolean =>
  analysisStatus === ANALYSIS_STATUSES.NOT_READY ||
  analysisStatus === ANALYSIS_STATUSES.INGEST_STARTED ||
  analysisStatus === ANALYSIS_STATUSES.INGEST_FINISHED;

export const isAnalysisReadyForIngest = (analysisStatus: string, datasetStatus: string): boolean =>
  Boolean(
    analysisStatus === ANALYSIS_STATUSES.NOT_READY &&
      datasetStatus === DATASET_STATUSES.METADATA_FINISHED
  );

export const isAnalysisReady = (analysisStatus: string): boolean =>
  Boolean(analysisStatus === ANALYSIS_STATUSES.READY);

export const isAnalysisFailed = (analysisStatus: string): boolean =>
  Boolean(analysisStatus === ANALYSIS_STATUSES.FAILED);

export const groupCreationJson = (
  name: string,
  group_type: string,
  rows: { index: number; has_missing: boolean }[],
  filters: ExplorerFilterType[]
):
  | {
      name: string;
      group_type: string;
      rows?: any;
      filters?: any;
    }
  | Record<string, unknown> => {
  switch (group_type) {
    case GroupCreationEnum.DATA_ROWS:
      return {
        name,
        group_type: GroupCreationEnum.ROWS,
        rows,
      };
    case GroupCreationEnum.FEATURE_ROWS:
      return {
        name,
        group_type: GroupCreationEnum.FEATURES,
        features: rows,
      };
    case GroupCreationEnum.FILTERED_DATA_ROWS:
      return {
        name,
        group_type: GroupCreationEnum.ROWS,
        filters,
      };
    case GroupCreationEnum.FILTERED_FEATURE_ROWS:
      return {
        name,
        group_type: GroupCreationEnum.FEATURES,
        filters,
      };

    default:
      return {};
  }
};

export const noColoringOption = {
  id: COLORING_OPTIONS.none,
  feature_name: 'No coloring',
};

export const defaultColoringOptions = [
  {
    id: COLORING_OPTIONS.selectedFeatures,
    feature_name: 'Selected features',
  },
  {
    id: COLORING_OPTIONS.selectedDataPoints,
    feature_name: 'Selected data points',
  },
  {
    id: COLORING_OPTIONS.other,
    feature_name: 'Other',
  },
];

export const normalizationTypeOptions = [
  {
    id: NormalizationType.RAW,
    label: 'Raw values',
  },
  {
    id: NormalizationType.UNIT,
    label: 'Min-max normalization',
  },
  {
    id: NormalizationType.ZSCORE,
    label: 'Z-score',
  },
];

export const aggregationTypeOptions = [
  {
    id: AggregationType.MEAN,
    label: 'Mean',
  },
  {
    id: AggregationType.MEDIAN,
    label: 'Median',
  },
];

export const cartesianAggType = {
  id: AggregationType.CARTESIAN,
  label: 'Cartesian',
};

export const aggregationTypeList = [...aggregationTypeOptions, cartesianAggType];

export const MISSING_VALUE_ERROR_MESSAGE =
  'Columns with missing values are not allowed as inputs for data or feature landscapes.';

export const handleNumericValueChange = (
  value: string,
  minValue: number,
  handler: (value: string) => void
): number => {
  const updateValue = Number(value) <= minValue ? minValue : Number(value);
  handler(updateValue.toString());

  return updateValue;
};

export const handleNumericDecrementChange = (
  value: string,
  minValue: number,
  handler: (value: string) => void
): number => {
  const updateValue = Number(value) > minValue ? Number(value) - 1 : minValue;
  handler(updateValue.toString());

  return updateValue;
};

export const useVisualizationsMap = (
  types: VisualisationType[] = [
    VisualisationType.DATA_LANDSCAPE,
    VisualisationType.FEATURE_LANDSCAPE,
    VisualisationType.SCATTER_PLOT,
    VisualisationType.VIOLIN_PLOT,
    VisualisationType.HEATMAP,
    VisualisationType.HISTOGRAM,
  ]
): VisualizationsMapType => {
  const {
    dataLandscapeVisualizations,
    featureLandscapeVisualizations,
    scatterPlotVisualizations,
    violinPlotVisualizations,
    heatmapVisualizations,
    histogramVisualizations,
  } = useAnalysis();

  const dataLandscapes = types.includes(VisualisationType.DATA_LANDSCAPE)
    ? dataLandscapeVisualizations
    : null;
  const featureLandscapes = types.includes(VisualisationType.FEATURE_LANDSCAPE)
    ? featureLandscapeVisualizations
    : null;
  const scatterPlots = types.includes(VisualisationType.SCATTER_PLOT)
    ? scatterPlotVisualizations
    : null;
  const violinPlots = types.includes(VisualisationType.VIOLIN_PLOT)
    ? violinPlotVisualizations
    : null;
  const heatmaps = types.includes(VisualisationType.HEATMAP) ? heatmapVisualizations : null;
  const histograms = types.includes(VisualisationType.HISTOGRAM) ? histogramVisualizations : null;

  return useMemo(
    () => ({
      [VisualisationType.DATA_LANDSCAPE]: dataLandscapes || [],
      [VisualisationType.FEATURE_LANDSCAPE]: featureLandscapes || [],
      [VisualisationType.SCATTER_PLOT]: scatterPlots || [],
      [VisualisationType.VIOLIN_PLOT]: violinPlots || [],
      [VisualisationType.HEATMAP]: heatmaps || [],
      [VisualisationType.HISTOGRAM]: histograms || [],
    }),
    [dataLandscapes, featureLandscapes, scatterPlots, violinPlots, heatmaps, histograms]
  );
};

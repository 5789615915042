import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { ArrowLeft, Lock, Unlock } from 'react-feather';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Status } from 'app/shared/components/Status/Status';
import { Tooltip } from 'app/mui/Tooltip';
import { ShowMoreButton } from 'app/shared/components/ShowMoreButton/ShowMoreButton';
import { ProjectTypes } from 'app/screens/Projects/Projects.types';
import { useNavigation } from 'app/shared/hooks/useNavigation';

const CAPTION_LIMIT = 90;

interface ProjectOverviewLayoutProps {
  heading: string;
  children: ReactNode;
  isPrivate: boolean;
  caption?: string;
  toolbar?: ReactElement<any, any>;
}

export const ProjectOverviewLayout: FC<ProjectOverviewLayoutProps> = ({
  heading,
  caption,
  children,
  isPrivate,
  toolbar,
}) => {
  const { navigateToProjects } = useNavigation();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  // Will be needed later
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const projectTypeJSX = (
    <Box ml={2}>
      <Tooltip title={isPrivate ? ProjectTypes.Private : ProjectTypes.Public} placement='bottom'>
        {isPrivate ? (
          <Lock color={theme.palette.action.active} size={24} />
        ) : (
          <Unlock color={theme.palette.action.active} size={24} />
        )}
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Box display='flex' alignItems='center' mb={2} zIndex={1}>
        <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' flex={1} minWidth={0} pr={3}>
            <Box mr={3} display='flex' onClick={navigateToProjects} sx={{ cursor: 'pointer' }}>
              <ArrowLeft size='24' />
            </Box>
            <Typography noWrap component='h4' variant='h4'>
              {heading}
            </Typography>
          </Box>
          {toolbar && (
            <Box display='flex' alignItems='center'>
              {toolbar}
            </Box>
          )}
        </Box>
      </Box>
      {caption && (
        <Box mb={6} zIndex={1} maxWidth='700px'>
          <Typography variant='body1' style={{ wordWrap: 'break-word' }}>
            {expanded ? caption : `${caption.substring(0, CAPTION_LIMIT)}`}
          </Typography>
          {caption.length > CAPTION_LIMIT && (
            <Box mt={3}>
              <ShowMoreButton expanded={expanded} setExpanded={setExpanded} withText />
            </Box>
          )}
        </Box>
      )}
      <Box zIndex={1}>{children}</Box>
    </>
  );
};

import { SELECTION_SOURCES } from 'app/screens/Analysis/Analysis.types';

export const featureSources: Partial<Record<SELECTION_SOURCES, string>> = {
  [SELECTION_SOURCES.EXPLORER]: 'Data/Feature Explorer',
  [SELECTION_SOURCES.LANDSCAPE]: 'Feature Landscape',
  [SELECTION_SOURCES.GROUP]: 'Group',
};

export const dataPointSources: Record<SELECTION_SOURCES, string> = {
  [SELECTION_SOURCES.EXPLORER]: 'Data Explorer',
  [SELECTION_SOURCES.LANDSCAPE]: 'Data Landscape',
  [SELECTION_SOURCES.SCATTER_PLOT]: 'Scatter Plot',
  [SELECTION_SOURCES.HISTOGRAM]: 'Histogram',
  [SELECTION_SOURCES.GROUP]: 'Group',
};

export const RESET_LAST_CREATED_TIMEOUT = 2000;
